import { ClientDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'
import { OfferUtils, SubscriptionUtils } from '@neo-commons/libraries'
import { ClientActions, ClientSelectors, OfferSelectors, SubscriptionActions, SubscriptionSelectors } from '@neo-commons/store'

import { createWizardPolicy, invokeActionCreator } from '../../utils'

const machineId = 'businessSubscription'

/*
 * Steps
 */

export enum BusinessSubscriptionStep {
  Offers = 'Offers',
  KYCPedagogy = 'KYCPedagogy',
  PosPedagogy = 'PosPedagogy',
  TaxDomiciliation = 'TaxDomiciliation',
  LegalStatus = 'LegalStatus',
  Kbis = 'Kbis',
  Receipts = 'Receipts',
  IdentifyAffiliatesPedagogy = 'IdentifyAffiliatesPedagogy',
  IdentifyAffiliates = 'IdentifyAffiliates',
  IdentifyYourself = 'IdentifyYourself',
  DocumentsChoice = 'DocumentsChoice',
  VerifyAffiliatesDocumentsPedagogy = 'VerifyAffiliatesDocumentsPedagogy',
  VerifyAffiliatesDocuments = 'VerifyAffiliatesDocuments',
  ElectronicSignature = 'ElectronicSignature',
  ElectronicSignatureFailure = 'ElectronicSignatureFailure',
  SubscriptionSuccess = 'SubscriptionSuccess'
}

/*
 * Guards
 */
const hasSubscribingSocleSubscription = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  return subscription !== undefined
}

const hasSubscriptionPrepareOffer = (context) => {
  const offer = context?.store.getState().subscription?.prepare?.offer
  return (offer !== undefined || hasSubscribingSocleSubscription(context))
}

const hasNotChoiceOfferAcquisition = (context) => {
  const offerUuid = context?.store.getState().subscription?.prepare?.offerUuid
  const offers = OfferSelectors.list(context?.store.getState())
  return !OfferUtils.isOfferAcquisition(offers.find((offer) => offer.uuid === offerUuid)) ||
    hasSubscribingSocleSubscription(context)
}

const doesNotNeedKbisOrHasAffiliates = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  const client: ClientDto = ClientSelectors.defaultOne(context?.store.getState())
  return !SubscriptionUtils.withKbis(subscription) ||
    SubscriptionUtils.isKbisFilled(subscription) ||
    client?.holder?.affiliates?.length !== 0
}

const hasSentAllReceipts = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  return !SubscriptionUtils.hasKycDocumentsIncomplete(subscription) ||
    SubscriptionUtils.hasKycDocumentsInvalid(subscription)
}

const hasAtLeastOneAffiliate = (context) => {
  const client: ClientDto = ClientSelectors.defaultOne(context?.store.getState())
  return client?.holder?.affiliates?.length !== 0
}

const isAffiliateEntryOk = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  return SubscriptionUtils.isAffiliateEntryOk(subscription)
}

const hasOnlyOneAffiliateOrIsAffiliateEntryOk = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  const client: ClientDto = ClientSelectors.defaultOne(context?.store.getState())
  return client?.holder?.affiliates?.length === 1 || SubscriptionUtils.isAffiliateEntryOk(subscription)
}

const hasSentAllKycSubscriberDocuments = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  return !SubscriptionUtils.hasKycAffiliateDocumentsIncomplete(subscription, subscription?.subscriber?.personUuid!) ||
    SubscriptionUtils.hasKycAffiliateDocumentsInvalid(subscription, subscription?.subscriber?.personUuid!)
}

const hasSentAllKycAffiliatesDocuments = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  const client: ClientDto = ClientSelectors.defaultOne(context?.store.getState())
  return client?.holder?.affiliates?.reduce((
    // eslint-disable-next-line no-mixed-operators
    acc, affiliate) => acc && !SubscriptionUtils.hasKycAffiliateDocumentsIncomplete(subscription, affiliate?.person?.uuid!) ||
    SubscriptionUtils.hasKycAffiliateDocumentsInvalid(subscription, affiliate?.person?.uuid!), true) ?? false
}

const areAllKycDocumentsOk = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  const client: ClientDto = ClientSelectors.defaultOne(context?.store.getState())
  return SubscriptionUtils.areAllKycAffiliateDocumentsOk(subscription, client?.holder?.affiliates) || SubscriptionUtils.isSignatureOk(subscription)
}

const hasASignature = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  return SubscriptionUtils.isSignatureOk(subscription)
}

export const businessSubscriptionSteps = {
  [BusinessSubscriptionStep.Offers]: {
    path: 'offers',
    fulfill: invokeActionCreator(SubscriptionActions.prepare),
    bypassIf: hasSubscriptionPrepareOffer,
    nextStep: BusinessSubscriptionStep.KYCPedagogy,
    meta: {
      screenTitle: 'app:pages:subscription:global:offers:title',
      canGoBack: true,
    },
  },
  [BusinessSubscriptionStep.KYCPedagogy]: {
    path: 'kyc-pedagogy',
    bypassIf: hasSubscribingSocleSubscription,
    nextStep: BusinessSubscriptionStep.PosPedagogy,
    meta: {
      screenTitle: 'app:pages:subscription:global:offers:title',
      canGoBack: true,
    },
  },
  [BusinessSubscriptionStep.PosPedagogy]: {
    path: 'pos-pedagogy',
    bypassIf: hasNotChoiceOfferAcquisition,
    nextStep: BusinessSubscriptionStep.TaxDomiciliation,
    meta: {
      screenTitle: 'app:pages:subscription:global:offers:title',
      canGoBack: true,
    },
  },
  [BusinessSubscriptionStep.TaxDomiciliation]: {
    path: 'taxDomiciliation',
    fulfill: invokeActionCreator(ClientActions.prepare),
    bypassIf: hasSubscribingSocleSubscription,
    nextStep: BusinessSubscriptionStep.LegalStatus,
    meta: {
      screenTitle: 'app:pages:subscription:business:taxDomiciliation:title',
      canGoBack: true,
    },
  },
  [BusinessSubscriptionStep.LegalStatus]: {
    path: 'legalStatus',
    fulfill: invokeActionCreator(SubscriptionActions.create),
    bypassIf: hasSubscribingSocleSubscription,
    nextStep: BusinessSubscriptionStep.Kbis,
    meta: {
      screenTitle: 'app:pages:subscription:business:legalStatus:title',
      canGoBack: true,
    },
  },
  [BusinessSubscriptionStep.Kbis]: {
    path: 'kbis',
    bypassIf: doesNotNeedKbisOrHasAffiliates,
    nextStep: BusinessSubscriptionStep.Receipts,
    history: {
      resetStack: true,
      preventStack: true,
    },
    meta: {
      screenTitle: 'app:pages:subscription:business:kbis:title',
      canGoBack: false,
    },
  },
  [BusinessSubscriptionStep.Receipts]: {
    path: 'receipts',
    bypassIf: hasSentAllReceipts,
    nextStep: BusinessSubscriptionStep.IdentifyAffiliatesPedagogy,
    meta: {
      screenTitle: 'app:pages:subscription:business:receipts:title',
      canGoBack: false,
    },
    history: {
      preventStack: true,
    },
  },
  [BusinessSubscriptionStep.IdentifyAffiliatesPedagogy]: {
    path: 'identifyMembersPedagogy',
    bypassIf: hasAtLeastOneAffiliate,
    fulfill: invokeActionCreator(ClientActions.list),
    nextStep: BusinessSubscriptionStep.IdentifyAffiliates,
    history: {
      resetStack: true,
    },
    meta: {
      screenTitle: 'app:pages:subscription:business:identifyAffiliates:title',
      canGoBack: false,
    },
  },
  [BusinessSubscriptionStep.IdentifyAffiliates]: {
    path: 'identifyMembers',
    bypassIf: isAffiliateEntryOk,
    nextStep: BusinessSubscriptionStep.IdentifyYourself,
    meta: {
      screenTitle: 'app:pages:subscription:business:identifyAffiliates:title',
      canGoBack: true,
    },
  },
  [BusinessSubscriptionStep.IdentifyYourself]: {
    path: 'identifyYourself',
    bypassIf: hasOnlyOneAffiliateOrIsAffiliateEntryOk,
    fulfill: invokeActionCreator(SubscriptionActions.list),
    nextStep: BusinessSubscriptionStep.DocumentsChoice,
    meta: {
      screenTitle: 'app:pages:subscription:business:identifyYourself:title',
      canGoBack: true,
    },
    history: {
      resetStack: true,
      preventStack: true,
    },
  },
  [BusinessSubscriptionStep.DocumentsChoice]: {
    path: 'documentsChoice',
    bypassIf: hasSentAllKycSubscriberDocuments,
    nextStep: BusinessSubscriptionStep.VerifyAffiliatesDocumentsPedagogy,
    meta: {
      screenTitle: 'app:pages:subscription:individual:documentChoice:title',
      canGoBack: false,
    },
    history: {
      preventStack: true,
    },
  },
  [BusinessSubscriptionStep.VerifyAffiliatesDocumentsPedagogy]: {
    path: 'verifyAffiliatesDocumentsPedagogy',
    bypassIf: hasSentAllKycAffiliatesDocuments,
    nextStep: BusinessSubscriptionStep.VerifyAffiliatesDocuments,
    meta: {
      screenTitle: 'app:pages:subscription:business:identifyAffiliates:title',
      canGoBack: false,
    },
    history: {
      preventStack: true,
    },
  },
  [BusinessSubscriptionStep.VerifyAffiliatesDocuments]: {
    path: 'verifyAffiliatesDocuments',
    bypassIf: hasSentAllKycAffiliatesDocuments,
    nextStep: BusinessSubscriptionStep.ElectronicSignature,
    meta: {
      screenTitle: 'app:pages:subscription:business:identifyAffiliates:title',
      canGoBack: true,
    },
  },
  [BusinessSubscriptionStep.ElectronicSignature]: {
    path: 'electronicSignature',
    bypassIf: hasASignature,
    fulfill: invokeActionCreator(SubscriptionActions.list),
    nextStep: BusinessSubscriptionStep.SubscriptionSuccess,
    onErrorRedirectStep: BusinessSubscriptionStep.ElectronicSignatureFailure,
    meta: {
      screenTitle: 'app:pages:subscription:global:electronicSignature:title',
      canGoBack: false,
    },
    history: {
      preventStack: true,
    },
  },
  [BusinessSubscriptionStep.ElectronicSignatureFailure]: {
    path: 'electronicSignatureFailure',
    nextStep: BusinessSubscriptionStep.ElectronicSignature,
    meta: {
      screenTitle: 'app:pages:subscription:global:electronicSignatureFailure:title',
      canGoBack: false,
    },
  },
  [BusinessSubscriptionStep.SubscriptionSuccess]: {
    path: 'subscriptionSuccess',
    nextStep: 'final',
    meta: {
      screenTitle: 'app:pages:subscription:global:subscriptionSuccess:title',
      canGoBack: false,
    },
  },
}

export type BusinessSubscriptionSteps = typeof businessSubscriptionSteps

/*
 * Policy initialization
 */
export const BusinessSubscriptionPolicy = createWizardPolicy({
  machineId: machineId,
  steps: businessSubscriptionSteps,
  initialStep: BusinessSubscriptionStep.Offers,
  basePath: '/subscription/business',
})
