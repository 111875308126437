import React from 'react'
import { Redirect, useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CardSelectors, CreditCardData } from '@neo-commons/store'

import { PageDetails } from '@neo-web/componentsOld'

import { Routes } from '../../../../../../Routes/Routes'

import { ShowDebitCardInfo } from './ShowDebitCardInfo'

export const Details: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)

  return (
    <>
      {!selectedCard && <Redirect to={Routes.Services.index.path} />}
      {selectedCard && (
        <PageDetails
          title={t('app:pages:debitCard:services:cardInfos:myCardDetails')}
          onBack={() => history.push(Routes.Services.index.path)}
          component={
            <ShowDebitCardInfo card={selectedCard} />
          }
        />
      )}
    </>
  )
}
