import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BankAccountSelectors } from '@neo-commons/store'
import { BankAccountUtils } from '@neo-commons/libraries'

import { PageDetails, Page } from '@neo-web/componentsOld'

import { Routes } from '../../../../../Routes/Routes'

import { Contracts } from './Contracts'
import { Statements } from './Statements'
import { Invoices } from './Invoices'

export enum DocumentType {
  STATEMENT = 'statement',
  CONTRACT = 'contract',
  INVOICE = 'invoice',
}

export const InvoiceAndContractDetail: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const selectedBankAccount = useSelector(BankAccountSelectors.selected)

  return (
    <PageDetails
      title={t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:title')}
      subtitle={BankAccountUtils.getName(selectedBankAccount)}
      onBack={() => history.push(Routes.Profile.index.path)}
      onClose={() => history.push(Routes.Profile.index.path)}
      component={
        <Page pages={[{
          path: Routes.Profile.invoices.path,
          component: <Invoices />,
          name: t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:invoices'),
        }, {
          path: Routes.Profile.statementsDetail.path,
          component: <Statements />,
          name: t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:statements'),
        }, {
          path: Routes.Profile.invoiceAndContractDetail.path,
          component: <Contracts />,
          name: t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:contracts'),
        }]}
        />
      }
    />
  )
}
