import { manuallyResetPromiseCounter, trackPromise } from 'react-promise-tracker'

import { ActionsObservable, combineEpics, ofType } from 'redux-observable'
import { switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { AnyAction } from 'redux'

import { AlertType } from '@neo-commons/store'

import signup from './Signup/epics'
import credit from './Credit/epics'
import auth from './Auth/epics'
import subscription from './Subscription/epics'
import userSecretQuestion from './UserSecretQuestion/epics'
import kycSubscriptionEpic from './KycSubscription/epics'
import { RootTypes } from './Root'
import bankAccount from './BankAccount/epics'
import client from './Client/epics'
import config from './Config/epics'
import transaction from './Transaction/epics'
import signIn from './SignIn/epics'
import preference from './Preference/epics'
import recipient from './Recipient/epics'
import address from './Address/epics'
import transfer from './Transfer/epics'
import contact from './Contact/epics'
import card from './Card/epics'
import offer from './Offer/epics'
import device from './Device/epics'
import user from './User/epics'
import recoverPasscode from './RecoverPasscode/epics'
import sca from './Sca/epics'
import deviceRecover from './DeviceRecover/epics'
import link from './Link/epics'
import document from './Document/epics'
import documentUpload from './DocumentUpload/epics'
import pos from './Pos/epics'
import telecollection from './Telecollection/epics'
import agentClientRegister from './AgentClientRegister/epics'
import advanceEligibility from './AdvanceEligibility/epics'

export const displayAlertGlobal$ =
  (actions$: ActionsObservable<AnyAction>, actionsType: string[]) => actions$.pipe(
    ofType(
      ...actionsType
    ),
    switchMap((action: AnyAction) =>
      of({
        type: RootTypes.DISPLAY_ALERT,
        alert: {
          type: action.alertType ?? AlertType.ERROR,
          title: action.message ? action.message
            : action.successMessage ? action.successMessage : action.errorMessage,
        },
      })
    )
  )

export const loading$ =
  (actions$: ActionsObservable<AnyAction>, actionsType: string[]) => actions$.pipe(
    ofType(
      ...actionsType
    ),
    switchMap((action: AnyAction) => {
      if (action.type.includes('REQUEST') && !action.type.includes('REQUESTED')) {
        return trackPromise(actions$.toPromise(), action.type)
      } else {
        manuallyResetPromiseCounter(action.type.replace(/SUCCESS|FAILURE|FAILED|REQUESTED|CANCELLED/gi, 'REQUEST'))
        return []
      }
    },
    ))

export const refreshSold$ =
  (actions$: ActionsObservable<AnyAction>, actionsType: string[]) => actions$.pipe(
    ofType(
      ...actionsType,
    ),
    switchMap(() => of({
      type: RootTypes.ROOT_UPDATE,
      config: { refreshSold: true },
    })),
  )

export const refreshSubAccounts$ =
  (actions$: ActionsObservable<AnyAction>, actionsType: string[]) => actions$.pipe(
    ofType(
      ...actionsType,
    ),
    switchMap(() => of({
      type: RootTypes.ROOT_UPDATE,
      config: { refreshSubAccounts: true },
    })),
  )

export const Epics: any = combineEpics(
  ...signup,
  ...credit,
  ...bankAccount,
  ...auth,
  ...subscription,
  ...client,
  ...config,
  ...subscription,
  ...userSecretQuestion,
  ...transaction,
  ...kycSubscriptionEpic,
  ...signIn,
  ...preference,
  ...address,
  ...recipient,
  ...transfer,
  ...contact,
  ...card,
  ...offer,
  ...device,
  ...user,
  ...recoverPasscode,
  ...sca,
  ...deviceRecover,
  ...link,
  ...document,
  ...documentUpload,
  ...pos,
  ...telecollection,
  ...agentClientRegister,
  ...advanceEligibility,
)
