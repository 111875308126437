import { StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

import { createStylesheet } from '../../utils'

import { BankAccountCardSkin } from './../BankAccountCard'

const styles = createStylesheet(({ Skin, props }) => {
  return StyleSheet.create({
    cardContainer: {
      padding: 0,
      ...Skin.select([
        BankAccountCardSkin.SECONDARY,
      ], {
        backgroundColor: Colors.backgroundDarker,
      }),
    },
    container: {
      overflow: 'hidden',
      padding: 16,
      paddingTop: 10,
      justifyContent: 'center',
      ...(props.disabled ? {
        backgroundColor: Colors.white,
      } : {}),

      ...(props.isSelected ? {
        backgroundColor: Colors.primary,
      } : {}),
    },
    subContainer: {
      display: 'flex',
      flexDirection: 'row',

      ...Skin.select([
        BankAccountCardSkin.CENTERED,
      ], {
        justifyContent: 'space-between',
        paddingLeft: 0,
        paddingRight: 0,
      }),
    },
    centerArea: {
      flex: 1,
      justifyContent: 'flex-start',
      paddingVertical: 1,
      ...Skin.select([
        BankAccountCardSkin.CENTERED,
      ], {
        justifyContent: 'center',
        alignItems: 'center',
      }),
    },
    leftArea: {
      justifyContent: 'center',
      marginRight: 14,
      ...Skin.select([
        BankAccountCardSkin.CENTERED,
      ], {
        justifyContent: 'flex-start',
        paddingLeft: 11,
        paddingTop: 13,
        marginRight: 0,
      }),
    },
    title: {
      ...Typo.bold,
      color: Colors.black,
      display: 'flex',

      ...Skin.select([
        BankAccountCardSkin.CENTERED,
      ], {
        fontWeight: 'normal',
        ...Typo.menuItemTitle,
        fontSize: Typo.size.regular,
      }),

      ...(props.isSelected ? {
        color: Colors.white,
      } : {}),
    },
    subtitle: {
      ...Typo.itemSubtitle,
      color: Colors.black,
      display: 'flex',
      marginTop: 4,

      ...Skin.select([
        BankAccountCardSkin.CENTERED,
      ], {
        marginBottom: 12,
      }),
      ...(props.isSelected ? {
        color: Colors.white,
      } : {}),
    },
    priceContainerStyle: {
      paddingVertical: 11,
      justifyContent: 'center',
      display: 'flex',

      ...Skin.select([
        BankAccountCardSkin.CENTERED,
      ], {
        width: 41,
      }),
    },
    priceCentered: {
      ...Typo.input,
      fontSize: Typo.size.h4,
      padding: 15,

      ...Skin.select([
        BankAccountCardSkin.CENTERED,
      ], {
        marginBottom: 20,
        marginTop: 7,
      }),

      ...(props.isSelected ? {
        color: Colors.white,
      } : {}),
    },
    priceTitle: {
      color: Colors.black,
      display: 'flex',
      ...Skin.select([
        BankAccountCardSkin.CENTERED,
      ], {
        display: 'none',
      }),

      ...(props.isSelected ? {
        color: Colors.white,
      } : {}),
    },
    priceCenteredContainerStyle: {
      ...Skin.select([
        BankAccountCardSkin.DEFAULT,
        BankAccountCardSkin.SECONDARY,
      ], {
        display: 'none',
      }),
    },
    priceTitleCentered: {
      padding: 15,
      marginBottom: 20,
      marginTop: 7,

      ...Skin.select([
        BankAccountCardSkin.DEFAULT,
        BankAccountCardSkin.SECONDARY,
      ], {
        display: 'none',
      }),

      ...(props.isSelected ? {
        color: Colors.white,
      } : {}),
    },
  })
})

export default styles
