import { orderBy } from 'lodash'
import { Contact, PaginateItem } from '@neo-commons/store'
import { ContactChannelDtoTypeEnum, ContactChannelFormatDto } from '@afone/neo-core-client/dist/models'

import { TextUtils } from './TextUtils'

/**
* Differents treatment for list.
*/
export class ContactUtils {
  /**
  * Group the contacts in SectionListData by the first letter of firstname.
  *
  * @param contacts - list of contact.
  * @returns list of contact sort by first letter.
  * @example
  * const groupContactFiltered = ContactUtils.groupContactByFirstLetter()
  *
  */
  public static groupContactByFirstLetter = (contacts: Contact[]): PaginateItem<Contact>[] => {
    return orderBy(
      contacts.reduce((cumulate: PaginateItem<Contact>[], contact: Contact): PaginateItem<Contact>[] => {
        const firstLetterContact = TextUtils.normalizeText((contact?.familyName?.[0] ?? '')).toUpperCase()

        const existing = cumulate.find(group => group.title === firstLetterContact)
        if (existing) {
          (existing.data as Contact[]).push(contact)
          existing.data = orderBy(existing.data, 'familyName', 'asc')
        } else {
          cumulate.push({ title: firstLetterContact, data: [contact] })
        }

        return cumulate
      }, [])
      , 'title', 'asc')
  }

  /**
   * Format all value for API before sending
   *
   * @param contact - contact to format.
   * @returns contact - with good format
   *
   */
  public static formatContactForApi = (contact: Contact): Contact => {
    contact.channels!.map((channel) => {
      channel.type = ContactChannelDtoTypeEnum.STRING
      channel.format = ContactChannelFormatDto.IBAN
    })
    if (contact.givenName) {
      contact.givenName = contact.givenName.trim()
      contact.givenName = contact.givenName!.charAt(0).toUpperCase() + contact.givenName!.slice(1).toLowerCase()
    }
    contact.familyName = contact.familyName!.trim()
    contact.familyName = contact.familyName!.charAt(0).toUpperCase() + contact.familyName!.slice(1).toLowerCase()

    return contact
  }
}
