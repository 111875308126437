import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { ProgressStep, QuitTunnelModal } from '@neo-web/componentsOld'
import { useResponsive } from '@neo-web/hooks'

import logo from '../../Resources/Images/site/dark-logo.svg'
import { version } from '../../../../package.json'
import { SelectLang } from '../Select/SelectLang'

import './Tunnel.scss'

interface TunnelProps {
  component: React.ReactNode
  progressBar?: { value: number, maxValue: number, minValue: number }
  title?: string
  showVersion?: boolean
  showSelectLang?: boolean
  scrollable?: boolean
  onClose?: () => void
  onBack?: () => void
  quitTunnelDirect?: boolean
  footer?: boolean
  overrideStyle?: React.CSSProperties
}

export const Tunnel: React.FC<TunnelProps> = (props: TunnelProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const configState = useSelector((state: StateTypes) => state.config)
  const isTabletOrMobile = useResponsive()

  const [showModalQuitTunnel, setShowModalQuitTunnel] = useState(false)

  return (
    <>
      <QuitTunnelModal
        show={showModalQuitTunnel}
        onClose={() => {
          setShowModalQuitTunnel(false)
        }}
      />
      <div className='Tunnel' style={props.overrideStyle}>
        {/* ------------------ HEADER -------------- */}
        <div className='Tunnel_header'>
          <div className='Tunnel_header_button'>
            {props.onBack &&
              <Icon
                name='arrow-left'
                type={IconTypes.FEATHER}
                size={24}
                onPress={props.onBack}
                containerStyle={{ alignSelf: 'flex-start', marginLeft: 6 }}
              />}
          </div>
          {props.title
            ? <Typography2 typeface='h2' style={{ alignSelf: 'center' }}>{props.title}</Typography2>
            : (
              <div
                className='Tunnel_header_logo'
                onClick={() =>
                  props.quitTunnelDirect
                    ? history.push(Routes.SignIn.index.path)
                    : setShowModalQuitTunnel(true)}
              >
                <img width='124px' height='auto' src={logo} alt='Logo' />
                {props.showVersion &&
                  <span className='Tunnel_header_version'>
                    {version}
                  </span>}
              </div>
            )}
          <div className='Tunnel_header_button'>
            {!props.showSelectLang && props.onClose &&
              <Icon
                name='x'
                type={IconTypes.FEATHER}
                size={24}
                onPress={props.onClose}
                color={Colors.black}
                containerStyle={{ alignSelf: 'flex-end', marginRight: 6 }}
              />}
            {props.showSelectLang &&
              <SelectLang />}
          </div>
        </div>
        {props.progressBar && (
          <ProgressStep currentValue={props.progressBar.value} maxValue={props.progressBar.maxValue} minValue={props.progressBar.minValue} />
        )}
        <div className='Tunnel_body'>
          {/* ------------------ CONTAINER -------------- */}
          {props.component &&
            <div className={`Tunnel_container ${props.scrollable ? 'scroll' : ''}`}>
              {props.component}
            </div>}
          {/* ------------------- FOOTER ---------------- */}
          {!isTabletOrMobile
            ? props.footer &&
              <div className='Tunnel_footer'>
                <div className='Tunnel_footer_container'>
                  <div className='Tunnel_footer_button'>
                    <Button
                      title={t('app:footer:default:customerService')}
                      skin={ButtonSkin.LINK}
                      onPress={() => {
                        history.push(Routes.PublicLink.clientService.path)
                      }}
                    />
                  </div>
                  <div className='Tunnel_footer_vr' />
                  <div className='Tunnel_footer_button'>
                    <Button
                      title={t('app:footer:default:FAQ')}
                      skin={ButtonSkin.LINK}
                      onPress={() => {
                        window.open(configState?.data?.currentConfig?.faq, '_blank')
                      }}
                    />
                  </div>
                  {/* TODO : Waiting specs */}
                  {/* <div className='Tunnel_footer_vr' />
                <div className='Tunnel_footer_button'>
                  <Button
                    title={t('app:footer:default:opposition')}
                    skin={ButtonSkin.LINK}
                    onPress={() => {
                      console.log('note implemented')
                    }}
                  />
                </div> */}
                </div>
              </div>
            : <></>}
        </div>
      </div>
    </>
  )
}
