import React from 'react'
import './OurPartners.scss'
import { useTranslation } from 'react-i18next'

import { Tunnel } from '@neo-web/componentsOld'

import AriadNext from '../../../Resources/Images/site/partners/ariad-next.png'
import BudgetInsight from '../../../Resources/Images/site/partners/budget-insight.png'
import Idemia from '../../../Resources/Images/site/partners/idemia.png'
import Mastercard from '../../../Resources/Images/site/partners/mastercard.png'
import Netcetera from '../../../Resources/Images/site/partners/netcetera.png'
import Webhelp from '../../../Resources/Images/site/partners/webhelp.png'
import Yousign from '../../../Resources/Images/site/partners/yousign.png'

export const OurPartners = () => {
  const ourPartners: { logo: string; name: string; link: string; }[] = [{
    logo: AriadNext,
    name: 'AriadNext',
    link: 'https://fr.ariadnext.com/',
  }, {
    logo: BudgetInsight,
    name: 'BudgetInsight',
    link: 'https://www.budget-insight.com/',
  }, {
    logo: Idemia,
    name: 'Idemia',
    link: 'https://www.idemia.com/fr/',
  }, {
    logo: Mastercard,
    name: 'Mastercard',
    link: 'https://www.mastercard.fr/fr-fr.html',
  }, {
    logo: Netcetera,
    name: 'Netcetera',
    link: 'https://www.netcetera.com/home.html',
  }, {
    logo: Webhelp,
    name: 'Webhelp',
    link: 'https://webhelp.com/fr/',
  }, {
    logo: Yousign,
    name: 'Yousign',
    link: 'https://yousign.com/fr-fr',
  }]

  const { t } = useTranslation()

  return (
    <Tunnel
      title={t('app:pages:publicLink:partners:ourPartners')}
      component={
        <div className='OurPartners'>
          {ourPartners.map((partner, index) =>
            <a href={partner.link} key={index} target='_blank' rel='noreferrer'>
              <img src={partner.logo} alt={partner.name} />
            </a>)}
        </div>
      }
    />
  )
}
