import React from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'

import { store } from 'Store'
import { Routes } from 'Routes/Routes'

import { useWizardPolicy } from '@neo-commons/hooks'
import { ResetPasscodePolicy, ResetPasscodeStep } from '@neo-commons/policies'

import { PreviousCode } from './Step1/PreviousCode'
import { NewCode } from './Step2/NewCode'
import { PasswordReset } from './Step3/PasswordReset'
import { Success } from './Success/Success'
import { ResetCodeLayout } from './ResetCodeLayout/ResetCodeLayout'
import './ResetCode.scss'

export const ResetCode: React.FC = () => {
  const history = useHistory()

  const { currentStep, steps: wizardSteps, stepper, goBack } = useWizardPolicy(store, ResetPasscodePolicy, {
    [ResetPasscodeStep.Code]: PreviousCode,
    [ResetPasscodeStep.NewCode]: NewCode,
    [ResetPasscodeStep.NewCodeConfirm]: PasswordReset,
    [ResetPasscodeStep.Success]: Success,
  }, {
    onDone: async () => {
      history.replace(Routes.Profile.security.path)
    },
  })

  return (
    <>
      {currentStep?.path &&
        <Redirect
          to={{
            pathname: currentStep?.path,
          }}
        />}

      <ResetCodeLayout
        stepper={stepper}
        goBack={() => currentStep?.key !== ResetPasscodeStep.Code
          ? goBack() : history.push(Routes.Profile.security.path)}
      >
        {wizardSteps.map((value, index) => {
          return (
            <Route
              key={index}
              exact
              path={value.path}
              component={value.component}
            />
          )
        })}
      </ResetCodeLayout>
    </>
  )
}
