import React, { FunctionComponent, useState } from 'react'
import { View, Text, Animated, ViewStyle, TouchableOpacity } from 'react-native'

import i18next from 'i18next'
import { times } from 'lodash'
import { Colors } from '@neo-commons/styles'
import { KeypadUtils } from '@neo-commons/libraries'
import { PasswordKeypadData } from '@neo-commons/hooks'

import { Icon } from '..'
import { IconTypes } from '../Icon/Icon'

import styles from './styles'

interface PinProps {
  value?: string,
  modeVisible?: boolean,
  isImage: boolean,
}
const Pin: FunctionComponent<PinProps> = ({ value, modeVisible, isImage }) => {
  const stylePin = (!modeVisible && value) ? styles.pinFill : styles.pinBorder
  const styleText = (modeVisible && value) ? styles.pinText : { opacity: 0 }
  return (
    <View style={stylePin}>
      {(isImage && value)
        ? modeVisible ? <img src={value} /> : <></>
        : <Text style={styleText}>{value}</Text>}
    </View>
  )
}

interface PasscodeInputProps {
  pinLimit: number,
  codePin?: PasswordKeypadData|string,
  displayError?: boolean,
  errorMessage?: string,
  containerStyle?: ViewStyle,
  isImage?: boolean,
}
const PasscodeInputComponent: FunctionComponent<PasscodeInputProps> =
  ({
    containerStyle,
    errorMessage,
    pinLimit,
    displayError,
    codePin,
    isImage = false,
  }) => {
    const animated = new Animated.Value(0)

    const [pinModeVisible, setPinModeVisible] = useState(false)

    return (
      <View style={[styles.wrapWiew, containerStyle]}>
        <Animated.View style={{ transform: [{ translateX: animated }] }}>
          <View style={styles.dotsContainer}>
            {times(pinLimit, i =>
              <Pin
                key={i} value={codePin && isImage
                  ? (codePin as PasswordKeypadData)?.password[i]
                    ? KeypadUtils.getImageUri((codePin as PasswordKeypadData)?.password[i]?.image)
                    : undefined
                  : (codePin as string)?.[i]}
                isImage={isImage} modeVisible={pinModeVisible}
              />)}
            <View>
              <TouchableOpacity style={styles.eyes} onPress={() => setPinModeVisible(!pinModeVisible)}>
                <Icon
                  name={pinModeVisible ? 'visibility' : 'visibility-off'}
                  color={Colors.primary}
                  size={20}
                  type={IconTypes.MATERIAL}
                />
              </TouchableOpacity>
            </View>
          </View>
        </Animated.View>
        {displayError && <Text style={styles.error}>{errorMessage ?? i18next.t('errors:badCode')}</Text>}
      </View>
    )
  }

export const PasscodeInput = PasscodeInputComponent
