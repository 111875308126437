import qs from 'querystring'

import axios, { AxiosResponse } from 'axios'
import { SchemeDto } from '@afone/neo-core-client/dist/models'
import { BinData, ComnpaySchemeType } from '@neo-commons/libraries'

export interface CreateTokenDto {
  bin: string;
  expirationDate: string;
  cvv: string;
  scheme: SchemeDto,
  owner: string,
  isMyCard?: boolean,
  saveCard?: boolean,
  schemesChoices?: SchemeDto
}

export interface BinInfosDto {
  empty?: boolean,
  nbBins: number,
  bin: BinData[]
}

export interface TokenizedExternalCard {
  serialNumber: string,
  tokenRef: string,
  cardExpirationDate: number,
  cardType: ComnpaySchemeType,
  countryCode?: string,
  pro?: boolean
  truncatedCardNumber: string
}

export class ComnpayApi {
  static apiUrl: string
  static serialNumber: string
  static origin: string

  static setApiUrl (url: string) {
    this.apiUrl = url
  }

  static configure (configuration: { apiUrl: string, serialNumber: string, origin: string }) {
    this.apiUrl = configuration.apiUrl
    this.serialNumber = configuration.serialNumber
    this.origin = configuration.origin
  }

  static async createToken (card: CreateTokenDto): Promise<AxiosResponse> {
    const params = {
      serialNumber: this.serialNumber,
      cardType: card.scheme,
      cardNumber: card.bin,
      cardExpirationDate: card.expirationDate.replace('/', ''),
      cvv: card.cvv,
      origin: this.origin,
    }
    return axios.post(
      `${this.apiUrl}/rest/token/create`,
      qs.stringify(params),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  }

  static async findBinInfos (bin: string): Promise<AxiosResponse<BinInfosDto>> {
    const params = {
      serialNumber: this.serialNumber,
      bin,
      origin: this.origin,
    }
    return axios.post(
      `${this.apiUrl}/rest/binInfo/find`,
      qs.stringify(params),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  }
}
