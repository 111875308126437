import { Platform, StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignContent: 'center',
    alignSelf: 'center',
    height: 80,
    width: 250,
  },
  hidden: {
    width: 1,
    height: 1,
    overflow: 'hidden',
  },
  inputWrap: {
    flex: 1,
    marginRight: 20,
  },
  input: {
    textAlign: 'center',
    opacity: 1,
    fontSize: 36,
    marginRight: 5,
    paddingHorizontal: 5,
    paddingVertical: 10,
    paddingBottom: 16,
    color: Colors.black,
    ...Platform.select({
      web: {
        // reset appearance to fix firefox design
        appearance: 'textfield',
      },
    }),
  },
  focused: { borderBottomWidth: 2, borderBottomColor: Colors.primary },
  otpContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})
