import { createStore, applyMiddleware, Store, Reducer, Action } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { persistReducer, persistStore, Persistor } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import * as Sentry from '@sentry/react'
import { loadUser } from 'redux-oidc'

import { agentClientUserManager } from '../Config/AgentClientUserManager'
import { config, ENV } from '../Config/EnvConfig'

import { Epics } from './Epics'

// creates the store
interface StoreConfiguration {
  store: Store,
  persistor: Persistor,
}

const epicMiddleware = createEpicMiddleware()

export default (rootReducer: Reducer): StoreConfiguration => {
  /* ------------- Redux Configuration ------------- */
  const logger = ({ getState }: any) => {
    return (next: any) => (action: Action) => {
      // Call the next dispatch method in the middleware chain.
      const returnValue = next(action)
      // This will likely be the action itself, unless
      // a middleware further in chain changed it.
      if (config(ENV.REDUX_LOG) === 'true') {
        console.groupCollapsed('Action detected : ' + action.type)
        console.log('will dispatch', action)
        console.log('state after dispatch', getState())
        console.groupEnd()
      }
      return returnValue
    }
  }

  const middlewares = [thunkMiddleware, logger, epicMiddleware]

  /* ------------- Assemble Middleware ------------- */
  const enhancers = [applyMiddleware(...middlewares)]

  const sentryReduxEnhancer = Sentry.createReduxEnhancer()

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const store =
    createStore(persistedReducer, composeWithDevTools(...enhancers, sentryReduxEnhancer))
  const persistor = persistStore(store)
  loadUser(store, agentClientUserManager)

  epicMiddleware.run(Epics)

  return {
    store,
    persistor,
  }
}
