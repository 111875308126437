import React from 'react'

import { Badge, BadgeSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import './PriceCard.scss'

export enum PriceCardSkin {
  DEFAULT = 'default',
  SECONDARY = 'secondary'
}

interface PriceCardProps {
  skin: PriceCardSkin
  price?: string
  icon?: typeof Icon | HTMLImageElement
  menu?: React.ReactElement,
  title?: string
  subTitle?: string
  buttons?: React.ReactElement
  selected?: boolean
  isError?: boolean
  isSuspended?: boolean
  onClick?: () => void
  className?: string
  isLarge?: boolean
  onBadgePress?: () => void
  badgeTitle?: string
}

export const PriceCard: React.FC<PriceCardProps> = ({
  icon,
  price,
  menu,
  title,
  subTitle,
  buttons,
  skin,
  onClick,
  className = '',
  isLarge,
  selected,
  isError,
  isSuspended,
  onBadgePress,
  badgeTitle,
}) => {
  return (
    <div
      className={`PriceCard ${skin!} ${isLarge ? 'isLarge' : ''} ${className} ${selected ? 'selected' : ''}  ${className} `}
      onClick={isLarge ? undefined : onClick}
    >
      {isLarge
        ? (
          <>
            <div className='PriceCard_top'>
              {icon}
              {isSuspended &&
                <div className='PriceCard_top-badge'>
                  <Badge value={badgeTitle} skin={BadgeSkin.ERROR} />
                  {onBadgePress && <Icon name='info' type={IconTypes.FEATHER} size={16} color={Colors.error} onPress={onBadgePress} />}
                </div>}
              {menu &&
                <div className='PriceCard_top-menu'>
                  {menu}
                </div>}
            </div>
            <div className={`PriceCard_middle ${isSuspended && 'blurred'}`}>
              <div className='PriceCard_middle-price'>
                <Typography typeface='titleH4'>
                  {price}
                </Typography>
              </div>
              <div className='PriceCard_middle-title'>
                <Typography typeface='smallText'>
                  {title}
                </Typography>
              </div>
              <div className='PriceCard_middle-subTitle'>
                <Typography typeface='smallTextLight' style={{ color: isError && Colors.error }}>
                  {subTitle}
                </Typography>
              </div>
            </div>
            <div className={`PriceCard_bottom ${buttons?.props?.children?.length ?? 'column'}`}>
              {buttons}
            </div>
          </>
        ) : (
          <>
            <div className='PriceCard_left'>
              {icon}
            </div>
            <div className={`PriceCard_middle ${isSuspended && 'blurred'}`}>
              <div className='PriceCard_middle-title'>
                <Typography typeface='bold'>
                  {title}
                </Typography>
              </div>
              <div className='PriceCard_middle-subTitle'>
                <Typography typeface='smallTextLight'>
                  {subTitle}
                </Typography>
              </div>
            </div>
            <div className='PriceCard_bottom'>
              <Typography typeface='bold'>
                {price}
              </Typography>
            </div>
          </>
        )}
    </div>
  )
}
