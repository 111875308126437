import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Typography2 } from '@neo-commons/components'
import { SignInActions, SignupActions } from '@neo-commons/store'

import { Modal } from '@neo-web/componentsOld'

import './QuitTunnelModal.scss'

interface QuitTunnelModalProps {
  show: boolean
  onClose: any
  redirectLink?: string
}

export const QuitTunnelModal: React.FC<QuitTunnelModalProps> = ({ show, onClose, redirectLink }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <Modal
      open={show}
      onClose={onClose}
    >
      <div className='QuitTunnelModal_container'>
        <div className='QuitTunnelModal_title'>
          <Typography2 typeface='h4'>
            {t('app:pages:subscription:quitTunnel:modal:title')}
          </Typography2>
        </div>
        <div className='QuitTunnelModal_subTitle'>
          <Typography2 typeface='body1'>
            {t('app:pages:subscription:quitTunnel:modal:description')}
          </Typography2>
        </div>
        <div className='QuitTunnelModal_action'>
          <Button
            skin={ButtonSkin.OUTLINE_PRIMARY}
            title={t('app:pages:subscription:quitTunnel:modal:buttonContinue')}
            onPress={onClose}
          />
          <Button
            skin={ButtonSkin.PRIMARY}
            title={t('app:pages:subscription:quitTunnel:modal:buttonQuit')}
            onPress={async () => {
              await dispatch(SignupActions.reset())
              await dispatch(SignInActions.signOut())
              if (redirectLink) {
                history.push(redirectLink)
              } else {
                history.push(Routes.SignIn.index.path)
              }
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
