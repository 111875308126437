import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { StateTypes } from 'Store'

import { BankAccountActions, BankAccountSelectors } from '@neo-commons/store'
import { ClosingAccountStep, ClosingAccountSteps, StepComponentProps, FinalRedirectStep } from '@neo-commons/policies'

import { FailureContent } from '@neo-web/componentsOld'

type FailClosingAccountProps = StepComponentProps<ClosingAccountSteps[ClosingAccountStep.ClosingAccountFailure]>

export const FailClosingAccount: React.FC<FailClosingAccountProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const recipientAccount = useSelector((state: StateTypes) => state.bankAccount.recipientAccountData)
  const bankAccount = useSelector((state: StateTypes) => state.bankAccount)

  useEffect(() => {
    if (!bankAccount?.closingBankAccountAmount) {
      try {
        dispatch(BankAccountActions.closeBankAccount({
          uuid: selectedBankAccount?.uuid ?? '',
          accountClosureDetails: recipientAccount,
          simulation: true,
        }))
      } catch {}
    }
  }, [])

  return (
    <FailureContent
      title={t('app:pages:myProfile:closingAccount:failClosingAccount:title')}
      description={t('app:pages:myProfile:closingAccount:failClosingAccount:content',
        { amount: bankAccount?.closingBankAccountAmount ?? '' })}
      buttonBack={{ action: () => nextStep({ finalRedirectStep: FinalRedirectStep.SYNTHESIS }) }}
      buttonAction={{
        text: t('app:pages:myProfile:closingAccount:failClosingAccount:button:credit'),
        action: () => nextStep({ finalRedirectStep: FinalRedirectStep.CREDIT }),
      }}
    />
  )
}
