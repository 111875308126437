import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'
import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { BankAccountUtils, ClientUtils, NumbersUtils } from '@neo-commons/libraries'
import { Button, ButtonSkin, Icon, IconTypes, Stepper2, Typography } from '@neo-commons/components'
import { BankAccountActions, BankAccountSelectors, ClientSelectors, SubscriptionActions } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { AggregationConnectorDto, AccountDto, ClientDto } from '@afone/neo-core-client/dist/models'

import { useIcon } from '@neo-web/hooks'
import { JustifyContent, PageContent, PageDetails, PriceCard, PriceCardSkin } from '@neo-web/componentsOld'

// ?error=access_denied ==> return error screen
// ?connection_id=1706 ==> return success screen

export const AggregationFallback: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const search = useLocation().search
  const error = new URLSearchParams(search).get('error')
  const mainAccounts = useSelector(BankAccountSelectors.listMain)
  const aggregatedAccounts = mainAccounts.filter(account => account?.type === 'AGGREGATED' && !account?.disabled)
  const clients: Array<ClientDto> = useSelector(ClientSelectors.list)
  const selectedConnector: AggregationConnectorDto =
    useSelector((state: StateTypes) => state.bankAccount.selectedConnector)

  useEffect(() => {
    const getBankAccounts = async () => {
      AnalyticsHandler.track(
        AnalyticsEvents.eligibility.bankAggregated,
        {
          account_type: 'B2C',
          bank_name: selectedConnector?.name,
        })
      await dispatch(BankAccountActions.reset())
      await dispatch(BankAccountActions.list())
      const mainAccount = mainAccounts.find(account => BankAccountUtils.isMain(account))
      if (mainAccount) {
        dispatch(BankAccountActions.setSelected(mainAccount.uuid))
      }
      dispatch(SubscriptionActions.list())
    }
    getBankAccounts()
  }, [])

  return (
    <PageDetails
      onBack={false}
      onClose={async () => {
        history.push(error ? Routes.Services.aggregationSearch.path : Routes.Synthesis.index.path)
      }}
      title={t('app:pages:services:aggregation:menu')}
      component={
        <PageContent
          title={error
            ? t('app:global:connecting')
            : t('app:pages:external:aggregation:verified:title')}
          justifyContent={JustifyContent.START}
        >
          <Stepper2 currentStep={2} />
          <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 24 }}>
            {error ? (
              <>
                <Typography typeface='body1'>
                  {t('app:global:patientlyWaiting')}
                </Typography>
                <br />
                <Icon name='alert-circle' type={IconTypes.FEATHER} size={32} color={Colors.secondary} />
                <Typography typeface='h3'>
                  {t('app:pages:external:aggregation:cancelled:title')}
                </Typography>
                <Typography typeface='body1'>
                  {t('app:pages:external:aggregation:cancelled:subtitle')}
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
                  <Button
                    title={t('app:global:login')}
                    skin={ButtonSkin.DEFAULT_LIGHT}
                    onPress={() => history.push(Routes.Services.aggregationSearch.path)}
                  />
                </div>
              </>)
              : (
                <>
                  <Typography typeface='body1'>
                    {t('app:pages:external:aggregation:verified:subtitle')}
                  </Typography>
                  <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                    <Button
                      title={t('app:global:terminate')}
                      skin={ButtonSkin.PRIMARY}
                      onPress={() => history.push(Routes.Synthesis.index.path)}
                    />
                  </div>
                  {aggregatedAccounts.map((bankAccount: AccountDto,) => {
                    const client: ClientDto = ClientUtils.getClientFromBankAccount(bankAccount, clients)
                    return (
                      <PriceCard
                        key={bankAccount.uuid}
                        skin={PriceCardSkin.DEFAULT}
                        icon={useIcon(bankAccount, client?.type)}
                        price={NumbersUtils
                          .displayPriceForHumanWithCurrency(bankAccount.balance, bankAccount?.currencyCode)}
                        title={BankAccountUtils.getName(bankAccount)}
                      />
                    )
                  })}
                </>
              )}
          </div>
        </PageContent>
      }
    />
  )
}
