import React, { ReactElement } from 'react'

import {
  Carousel,
  ItemCarousel,
} from '@neo-web/componentsOld'

interface ChoicesPaymentSolutionProps {
  itemsCarousel: ItemCarousel[],
  promoModal?: ReactElement,
}

export const OfferCarousel: React.FC<ChoicesPaymentSolutionProps> = (props: ChoicesPaymentSolutionProps) => {
  const { itemsCarousel, promoModal } = props

  return (
    <>
      {promoModal}
      <Carousel height={800} shadow items={itemsCarousel} />
    </>
  )
}
