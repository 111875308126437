import React, { useState } from 'react'
import { View } from 'react-native'

import { Colors, Commons } from '@neo-commons/styles'
import i18next from 'i18next'

import { Icon, Input, IconTypes } from '..'

import styles from './styles'

export interface SearchParentProps<E> {
  itemFilter?: (item: E, text: string) => boolean,
  onItemsFiltered?: (filteredItems: E[], text: string) => void,
  value?: string,
  filter?: (text: string) => void,
}

interface SearchProps<E extends unknown> extends SearchParentProps<E> {
  items: E[],
}

export const SearchBar = <T extends unknown>({ items, itemFilter, onItemsFiltered, filter, value }: SearchProps<T>) => {
  const [searchValue, setSearchValue] = useState('')

  const handleFilter = (text: string) => {
    const safeText = typeof text === 'string' ? text : ''

    if (itemFilter && items?.length > 0) {
      const filteredItems = items.filter((item: any) => itemFilter(item, safeText))

      if (onItemsFiltered) {
        onItemsFiltered(filteredItems, text)
      }
    }

    setSearchValue(safeText)
  }

  const inputValue = filter ? value : searchValue

  return (
    <View accessibilityLabel='SearchInput' style={[Commons.row, styles.searchContainer]}>
      <Icon
        name='search'
        type={IconTypes.FEATHER}
        size={20}
        color={Colors.gray2}
        iconStyle={{ alignSelf: 'center' }}
      />
      <Input
        value={inputValue ?? ''}
        onChangeText={(text: string) => (filter ? filter(text) : handleFilter(text))}
        placeholder={i18next.t('app:global:search')}
        hasClear
        autoFocus
        accessibilityLabel='SearchInput-Field'
        containerStyle={styles.searchInput}
        inputStyle={styles.input}
        rightIconContainerStyle={{ top: 10, paddingTop: 0 }}
      />
    </View>
  )
}
