import React, { FunctionComponent, useEffect, useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { VirtualKeyboard } from 'react-native-screen-keyboard'
import { useTranslation } from 'react-i18next'

import { Colors } from '@neo-commons/styles'

import { Icon, IconTypes } from '..'
import { Typography } from '../Typography/Typography'

import styles from './styles'

export interface RandomNumericKeyboardProps {
  onKeyPress: (value: any) => void,
  onBiometricsPress?: () => void,
  onValidatePress?: () => void,
  onDeleteCharPress: () => void,
  disableValidate?: boolean,
  withoutValidate?: boolean,
  withBiometrics?: boolean,
  disableDeleteChar?: boolean,
  onClear?: () => void,
  biometricsAuthenticating?: boolean,
  matrix?: any[][],
}

export const RandomNumericKeyboard: FunctionComponent<RandomNumericKeyboardProps> = ({
  onKeyPress,
  onDeleteCharPress,
  onValidatePress,
  onBiometricsPress,
  disableValidate,
  disableDeleteChar,
  biometricsAuthenticating = false,
  matrix,
}) => {
  const { t } = useTranslation()
  const [matrixFunc, setMatrixFunc] = useState<any[][] | null>(null)

  useEffect(() => {
    setMatrixFunc([
      [null, null, null],
      [null, null, null],
      [null, null, null],
      [() => { !disableDeleteChar && onDeleteCharPress && onDeleteCharPress() }, null, () => { !disableValidate && onValidatePress && onValidatePress() }]])
  }, [disableDeleteChar, disableValidate, onDeleteCharPress, onValidatePress, onBiometricsPress])

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <VirtualKeyboard
          onRef={() => { /* */
          }}
          onKeyDown={(key: any) => onKeyPress(key)}
          keyboard={matrix}
          testID='keyboard'
          keyboardFunc={matrixFunc}
          vibration={Platform.OS === 'android'}
          keyTextStyle={StyleSheet.flatten([styles.keyText])}
          keyboardStyle={StyleSheet.flatten([styles.keyboard])}
          keyStyle={StyleSheet.flatten([styles.key])}
        />
        {biometricsAuthenticating &&
          <View style={styles.biometrics}>
            <Icon name='fingerprint' type={IconTypes.MATERIAL_COMMUNITY} size={50} color={Colors.secondary} />
            <Typography typeface='smallText' style={{ textAlign: 'center', marginTop: 10 }}>
              {t('neo-commons:authentication:useFingerprintOrFacialRecognition')}
            </Typography>
          </View>}
      </View>
    </View>
  )
}
