import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

const styles = StyleSheet.create({
  icon: {
    backgroundColor: 'transparent',
  },
  iconContainer: {
  },
  roundedBackground: {
    backgroundColor: Colors.ghostBeige,
    borderRadius: 100,
    alignSelf: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 18,
  },
  editIcon: {
    width: '0.813rem',
    height: '0.813rem',
  },
  editIconContainer: {
    width: 'max-content',
    margin: 'auto',
    borderColor: Colors.white,
    borderRadius: 12,
    borderWidth: 2,
    backgroundColor: Colors.gray,
    paddingVertical: '0.25rem',
    paddingHorizontal: '0.25rem',
    left: 20,
    bottom: 15,
  },
})

export default styles
