import { Platform, StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    ...Platform.select({
      web: {
        zIndex: 150,
      },
      ios: {
        zIndex: 150,
      },
    }),
  },
  input: {
    marginBottom: 0,
  },
  listWrapper: {
    width: '100%',
  },
  scrollWrapper: {
    position: 'absolute',
    zIndex: 12,
    ...Platform.select({
      web: {
        zIndex: 0,
      },
    }),
    width: '100%',
    top: 0,
  },
  scrollContainer: {
    position: 'relative',
    width: '100%',
    alignSelf: 'center',
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    elevation: 3,
    shadowRadius: 4,
    shadowOpacity: 0.1,
    backgroundColor: Colors.white,
    borderTopWidth: 0,
    borderColor: Colors.bgGray,
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
  },
  scrollView: {
    width: '100%',
    maxHeight: 46 * 5,
  },
  rowUnderline: {
    width: '100%',
    height: 0.8,
    backgroundColor: Colors.gray,
  },
  rowTouchable: {
    width: '100%',
    height: 46,
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    ...Platform.select({
      web: {
        zIndex: 100,
      },
    }),
  },
  rowText: {
    fontFamily: Typo.type.regular,
    fontSize: Typo.size.medium,
    color: Colors.black,
  },
})
