import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AddressActions, AddressTypes, ConfigSelectors } from '@neo-commons/store'
import { CountryUtils } from '@neo-commons/libraries'

import { AddressCreateOrReplaceDto, AddressDto, AddressTypeDto, CountryDto, UserDto } from '@afone/neo-core-client/dist/models'

import { UseAddressForm } from '@neo-web/hooks'
import { AddressButtonWhisper, LazyLoadingContainer } from '@neo-web/componentsOld'

import './MyAddressContent.scss'

export interface MyAddressContentProps {
  address: AddressDto
  user: UserDto
  onCloseModal: () => void
}

interface AddressDataType{
  address: string|null,
  zipCode: string|null,
  cityName: string|null,
  additionalAddress: string|null,
  countryData: { name: string, isoCodeAlpha2: string},
}

export const MyAddressContent: React.FC<MyAddressContentProps> = (props: MyAddressContentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const allCountries: CountryDto[] = useSelector(ConfigSelectors.getCurrentConfig)?.countries ?? []
  const countries = CountryUtils.filterByAddressAuthorized(allCountries)

  const {
    postalCode,
    city,
    line1,
    country,
    line2,
  } = props.address ?? {}
  const selectedCountry: CountryDto =
    country ? countries.find(country => country.name === props.address?.country) : null
  const [addressData, renderAddressForm] = UseAddressForm({
    userData: props.user,
    countries,
    values: {
      countryData: selectedCountry,
      zipCode: postalCode ?? '',
      address: line1 ?? '',
      additionalAddress: line2 ?? '',
      cityName: city ?? '',
    },
  })

  const createOrReplaceUserAddress = async (addressSelected: AddressDataType) => {
    const { address, zipCode, cityName, countryData, additionalAddress } = addressSelected
    const addressToAdd: AddressCreateOrReplaceDto = {
      uuid: props.address?.uuid,
      line1: address,
      line2: additionalAddress ?? '',
      postalCode: zipCode,
      city: cityName,
      fullName: props?.user?.person?.firstName ? props?.user?.person?.firstName + ' ' + props?.user?.person?.lastName : '',
      countryCode: countryData?.isoCodeAlpha2,
      type: AddressTypeDto.FISCAL,
    }
    try {
      await dispatch(AddressActions.createOrReplaceUserAddress(addressToAdd))
      props.onCloseModal()
    } catch (error) {}
  }

  const isButtonDisabled = (): boolean => {
    const data = addressData as AddressDataType
    return !data.address ||
      !data.zipCode ||
      !data.cityName ||
      !data.countryData
  }

  return (
    <LazyLoadingContainer
      events={[
        AddressTypes.UPDATE_ADDRESS_REQUEST,
        AddressTypes.CREATE_ADDRESS_REQUEST,
      ]}
    >
      <div className='MyAddressContent_container'>
        <div className='MyAddressContent_address'>
          {renderAddressForm}
          <div className='MyAddressContent_button'>
            <AddressButtonWhisper
              originAddress={addressData}
              onSelect={createOrReplaceUserAddress}
              buttonDisabled={isButtonDisabled()}
              buttonTitle={t('app:global:validate')}
            />
          </div>
        </div>
      </div>
    </LazyLoadingContainer>
  )
}
