import { map } from 'lodash'

import {
  IndividualSubscriptionPolicy,
} from '@neo-commons/policies'

export const IndividualRoutes = {
  index: {
    path: IndividualSubscriptionPolicy.basePath,
    name: 'individualSubscription',
    available: true,
  },
  ...map({
    ...IndividualSubscriptionPolicy.steps,
  }, ({ path, meta }) => ({
    path,
    name: meta.screenTitle,
    available: true,
  })),
}
