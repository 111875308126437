import { StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

const styles = StyleSheet.create({
  ribContainer: {
    backgroundColor: 'white',
    borderRadius: 15,
    paddingVertical: 14,
    paddingHorizontal: 20,
    alignItems: 'center',
    width: '100%',
    borderColor: Colors.gray,
    borderWidth: 1,
  },
  ribTitle: {
    ...Typo.content,
    color: Colors.black,
  },
  ribContent: {
    fontFamily: Typo.type.bold,
    fontSize: 14,
    color: Colors.black,
    marginTop: 20,
  },
  icon: {
    position: 'absolute',
    right: 15,
    top: 15,
  },
})

export default styles
