import React, { FunctionComponent, useCallback, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'

import i18next from 'i18next'
import { AsYouType, CountryCode, getExampleNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import { Colors } from '@neo-commons/styles'
import { SyncValidatorFunction, PhoneNumberUtils } from '@neo-commons/libraries'

import { Input, InputProps } from '..'

import styles from './styles'

interface PhoneInputProps extends InputProps {
  onPressPrefix: () => void,
  prefix: CountryCode,
  phoneRegex?: string,
  phoneLabel?: string,
  testID?: string,
  canEditCountry?: boolean
}

export const PhoneInputComponent: FunctionComponent<PhoneInputProps> = ({ canEditCountry = true, ...props }) => {
  const getPhoneValidator: SyncValidatorFunction = useCallback((text) => {
    const isValid = PhoneNumberUtils.isAllowForApp(text, props.prefix)
    return {
      isValid: isValid,
      errorMessage: i18next.t('validation:badPhoneNumber'),
    }
  }, [props.prefix])

  const phoneFormatter = useCallback((value: string) =>
    PhoneNumberUtils.isAllowForApp(value, props.prefix) ? new AsYouType(props.prefix).input(value) : value
  , [props.prefix])

  const [labelStyle, setLabelStyle] = useState(styles.defaultPhoneLabel)

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.prefixInput} onPress={() => props.onPressPrefix()} disabled={!canEditCountry}>
        <Text accessibilityLabel='InputCountryPhoneNumber' style={styles.prefix}>{props.prefix}</Text>
      </TouchableOpacity>
      <View style={styles.phoneNumberInput}>
        <Text
          numberOfLines={1}
          ellipsizeMode='tail'
          style={labelStyle}
        >
          {props.phoneLabel}
        </Text>
        <Input
          {...props}
          onFocus={() => setLabelStyle(styles.focusedPhoneLabel)}
          onBlur={() => setLabelStyle(styles.defaultPhoneLabel)}
          testID={props.testID || 'phone'}
          labelStyle={{ fontSize: '12px !important', textAlign: 'left !important' }}
          placeholder={props.placeholder ?? getExampleNumber(props.prefix, examples)?.formatNational()}
          keyboardType='phone-pad'
          validators={[getPhoneValidator]}
          inputStyle={{ color: Colors.black, backgroundColor: Colors.transparent }}
          format={phoneFormatter}
        />
      </View>
    </View>
  )
}

export const PhoneInput = PhoneInputComponent
