import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import {
  Button,
  ButtonSkin,
  EditItemCard,
  EditItemCardSkin,
  Typography2,
} from '@neo-commons/components'
import { CloseProjectStep, CloseProjectSteps, StepComponentProps } from '@neo-commons/policies'
import {
  BankAccountActions,
  BankAccountSelectors,
} from '@neo-commons/store'
import { NumbersUtils } from '@neo-commons/libraries'

import { AccountDto } from '@afone/neo-core-client/dist/models'

import {
  JustifyContent,
  PageContent,
} from '@neo-web/componentsOld'

import './Summary.scss'

type CloseProjectSummaryProps = StepComponentProps<CloseProjectSteps[CloseProjectStep.Summary]>

export const Summary: React.FC<CloseProjectSummaryProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectedMain = useSelector(BankAccountSelectors.selectedMain)
  const selectedAccount: AccountDto = useSelector((state: StateTypes) => state.bankAccount.selectedBankAccountToClose)

  useEffect(() => { dispatch(BankAccountActions.ribInfos(selectedMain?.uuid)) }, [])

  return (
    <PageContent
      justifyContent={JustifyContent.BETWEEN}
      footer={
        <div className='CloseSummary_footer'>
          <Typography2 typeface='body2'>{t('app:pages:services:closeProject:summary:price', { price: NumbersUtils.displayPriceForHuman(0) })} </Typography2>
          <Button
            containerStyle={{ width: '100%' }}
            skin={ButtonSkin.PRIMARY}
            title={t('app:global:confirm')}
            onPress={() => nextStep({
              uuid: selectedAccount?.uuid,
              accountClosureDetails: {
                recipientIban: selectedMain?.rib?.iban,
                recipientName: selectedMain?.rib?.holder,
                recipientBic: selectedMain?.rib?.bic,
              },
            }).onDone(() => AnalyticsHandler.track(
              AnalyticsEvents.Account.subAccountDeleted,
              { sub_account_id: selectedAccount?.uuid, sub_account_name: selectedAccount?.name }
            ))}
          />
        </div>
      }
    >
      <EditItemCard
        containerStyle={{ padding: '24px', marginBottom: '16px' }}
        label={t('app:pages:services:closeProject:summary:accountToClose').toUpperCase()}
        skin={EditItemCardSkin.DELIVERY}
        hideButton
        payload={{ title: selectedAccount.name, subtitles: [NumbersUtils.displayPriceForHuman(selectedAccount.balance)] }}
      />
      <EditItemCard
        containerStyle={{ padding: '24px' }}
        label={t('app:pages:services:closeProject:summary:recipientAccount').toUpperCase()}
        skin={EditItemCardSkin.DELIVERY}
        hideButton
        payload={{ title: selectedMain.name, subtitles: [selectedMain.iban] }}
      />
    </PageContent>
  )
}
