import { TransactionActions } from '@neo-commons/store'

import { createWizardPolicy, invokeActionCreator } from '../../utils'

const machineId = 'transactionRefund'

/*
 * Steps
 */

export enum TransactionRefundStep {
  Amount = 'Amount',
  Summary = 'Summary',
  Success = 'Success',
  Failure = 'Failure',
}

export const transactionRefundSteps = {
  [TransactionRefundStep.Amount]: {
    path: 'amount',
    nextStep: TransactionRefundStep.Summary,
    fulfill: invokeActionCreator(TransactionActions.refundPrepare),
    meta: {
      headerTitle: 'neo-commons:pages:synthesis:transaction:refund:title',
    },
  },
  [TransactionRefundStep.Summary]: {
    path: 'summary',
    nextStep: TransactionRefundStep.Success,
    editableSteps: [
      TransactionRefundStep.Amount,
    ],
    fulfill: invokeActionCreator(TransactionActions.refund),
    onErrorRedirectStep: TransactionRefundStep.Failure,
    meta: {
      headerTitle: 'neo-commons:pages:synthesis:transaction:refund:summary',
    },
  },
  [TransactionRefundStep.Success]: {
    path: 'success',
    nextStep: 'final',
    history: {
      preventStack: true,
    },
  },
  [TransactionRefundStep.Failure]: {
    path: 'failure',
    nextStep: 'final',
    history: {
      preventStack: true,
    },
  },
}

export type TransactionRefundSteps = typeof transactionRefundSteps

/*
 * Policy initialization
 */
export const transactionRefundPolicy = createWizardPolicy({
  machineId: machineId,
  steps: transactionRefundSteps,
  initialStep: TransactionRefundStep.Amount,
  basePath: '/synthesis/transaction/refund',
})
