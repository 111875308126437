import { Platform, StyleSheet } from 'react-native'
import { RFPercentage } from 'react-native-responsive-fontsize'

import { Colors } from '@neo-commons/styles'

import { createStylesheet } from '../utils'

export const CREDIT_CARD_ASPECT_RATIO = 1.59

const styles = createStylesheet(({ props }) => {
  return StyleSheet.create({
    font: {
      color: Colors.black,
      textAlign: 'right',
      fontSize: RFPercentage(1.3),
      ...Platform.select({
        web: {
          fontSize: 12,
        },
      }),
    },
    container: {
      borderRadius: 10,
      marginBottom: 5,
      ...(!props.selected ? {
        borderColor: Colors.transparent,
        borderWidth: 3,
        margin: 2,
      } : {}),
    },
    cardImage: {
      resizeMode: 'contain',
      aspectRatio: CREDIT_CARD_ASPECT_RATIO,
      height: '100%',
      borderRadius: 10,
      overflow: 'hidden',

      ...(props.card?.plasticInfo?.productImage && props.card.productImage ? {
        position: 'absolute',
      } : {}),

      ...(!props.card?.plasticInfo?.productImage && props.selected ? {
        borderColor: Colors.primary,
      } : {}),

      ...(!props.selected ? {
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowRadius: 4,
        shadowOpacity: 0.3,
        elevation: 3,
        shadowColor: Colors.black,
      } : {}),
    },
    digital: {
      zIndex: -1,
      ...(props.card?.plastic && props.card?.plasticInfo?.productImage ? {
        marginLeft: '25%',
      } : {}),
      aspectRatio: CREDIT_CARD_ASPECT_RATIO,
      resizeMode: 'contain',
      borderRadius: 10,
      overflow: 'hidden',

      ...(props.selected ? {
        borderWidth: 0,
      } : {}),
    },
    infoPaymentCardPhysical: {
      position: 'absolute',
      top: '32%',
      ...Platform.select({
        web: {
          top: '40%',
        },
      }),
      textAlign: 'right',
      right: 10,
      justifyContent: 'center',
    },
    infoPaymentCardDigital: {
      position: 'absolute',
      top: '37%',
      ...Platform.select({
        web: {
          top: '38%',
        },
      }),
      textAlign: 'right',
      right: 10,
      justifyContent: 'center',
    },
    overlay: {
      backgroundColor: '#FFFFFFCF',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      borderRadius: 10,
      textAlign: 'center',
    },
    selected: {
      borderColor: Colors.primary,
      borderWidth: 2,
    },
  })
})

export default styles
