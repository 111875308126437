import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { StepComponentProps } from '@neo-commons/policies'
import { POSOrderStep, POSOrderSteps } from '@neo-commons/policies/src/POS/Order'
import {
  PosActions,
  PosSelectors,
  PreparedProvisioning,
  PreparePOSOrder,
  ProvisioningSelectors,
} from '@neo-commons/store'
import { ProvisioningStatus } from '@neo-commons/libraries'

import { Loader } from '@neo-web/componentsOld'

type POSOrderModelChoiceProps = StepComponentProps<POSOrderSteps[POSOrderStep.Process]>

export const Process: React.FC<POSOrderModelChoiceProps> = ({ nextStep }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const preparedPOSOrder: PreparePOSOrder = useSelector(PosSelectors.prepare)
  const preparedProvisioning: PreparedProvisioning = useSelector(ProvisioningSelectors.prepare)

  const [tryNumber, setTryNumber] = useState(0)

  const listen = async () => {
    if (tryNumber >= 8) {
      nextStep()
    }

    try {
      await dispatch(PosActions.getOrderFees())
      if (preparedPOSOrder.missingBalance === 0) {
        nextStep()
      } else {
        setTimeout(() => setTryNumber(tryNumber + 1), 5000)
      }
    } catch (error) {
      if (error.status === 402 && preparedProvisioning.provisioningStatus === ProvisioningStatus.SUCCESS) {
        setTimeout(() => setTryNumber(tryNumber + 1), 5000)
      } else {
        nextStep()
      }
    }
  }

  useEffect(() => {
    if (preparedPOSOrder.missingBalance === 0) {
      nextStep()
    } else {
      (async () => await listen())()
    }
  }, [tryNumber])

  return (
    <div className='PosOrder'>
      <Loader isShow text={t('app:pages:services:pos:order:processing')} />
    </div>
  )
}
