import { SignInActions, UserSecretQuestionActions } from '@neo-commons/store'

import { invokeActionCreator, createWizardPolicy } from '../../utils'

/*
 * Guards
 */
const isSecretQuestionAnswerd = (context) => {
  return !!context?.store.getState().userSecretQuestion.answer
}
const isPhoneVerified = (context) => {
  return context.store.getState().signIn.ui.isPhoneVerified
}

const machineId = 'recoverPassword'

export enum RecoverPasswordStep {
  SecretQuestion = 'SecretQuestion',
  Sms = 'Sms',
  Code = 'Code',
  CodeConfirm = 'CodeConfirm',
  Validate = 'Validate',
}

export const recoverPasswordSteps = {
  [RecoverPasswordStep.SecretQuestion]: {
    path: '',
    nextStep: RecoverPasswordStep.Sms,
    fulfill: invokeActionCreator(UserSecretQuestionActions.checkSecretQuestion),
    skipIf: isSecretQuestionAnswerd,
    meta: {
      screenTitle: 'app:pages:register:step1:phone:titleMessage',
    },
  },
  [RecoverPasswordStep.Sms]: {
    path: 'sms',
    nextStep: RecoverPasswordStep.Code,
    fulfill: invokeActionCreator(SignInActions.verifyPhone),
    skipIf: isPhoneVerified,
    meta: {
      screenTitle: 'app:pages:register:step1:sms:titleMessage',
    },
  },
  [RecoverPasswordStep.Code]: {
    path: 'code',
    nextStep: RecoverPasswordStep.CodeConfirm,
    fulfill: invokeActionCreator(SignInActions.recoverPasswordCheck),
    meta: {
      screenTitle: 'app:pages:register:step1:validate:titleMessage',
    },
  },
  [RecoverPasswordStep.CodeConfirm]: {
    path: 'confirm',
    nextStep: RecoverPasswordStep.Validate,
    fulfill: invokeActionCreator(SignInActions.recoverPasswordWithSecretAndOtpPhone),
    meta: {
      screenTitle: 'app:pages:register:step1:validate:titleMessage',
    },
  },
  [RecoverPasswordStep.Validate]: {
    path: 'validate',
    history: {
      preventStack: true,
      resetStack: true,
    },
    nextStep: 'final',
    meta: {
      screenTitle: 'app:pages:register:step1:validate:titleMessage',
    },
  },
}

export type RecoverPasswordSteps = typeof recoverPasswordSteps

/*
 * Policy initialization
 */
export const RecoverPasswordPolicy = createWizardPolicy({
  machineId: machineId,
  steps: recoverPasswordSteps,
  initialStep: RecoverPasswordStep.SecretQuestion,
  basePath: '/signIn/recover-password',
})
