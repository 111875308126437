import { AnyAction, Reducer } from 'redux'
import { NeobankApi } from '@neo-commons/services'
import { CreditCardDto } from '@afone/neo-core-client/dist/models'

import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { State, initialResourceState } from '../utils'

const {
  resourceActionTypes: ExternalCardActionTypes,
  resourceReducer: ExternalCardResourceReducer,
  resourceAction: ExternalCardAction,
  resourceSelector: ExternalCardResourceSelector,
} = ResourceStateFactory<CreditCardDto, 'externalCard'>((state: State) => state.externalCard, 'externalCard')

/* %%%%%%%%%%%%%%%%%% *\
    Action Types.
\* %%%%%%%%%%%%%%%%%% */

export const ExternalCardTypes = {
  ...ExternalCardActionTypes,
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */

const getExternalCardList = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: ExternalCardTypes.LIST_EXTERNALCARD_REQUEST })
    const accountUuid = getState().bankAccount.list!.selectedId

    if (!accountUuid) {
      throw new Error()
    }

    try {
      const payload = await NeobankApi.getInstance().externalCardApi.getCreditCardsByAccount(accountUuid)
      const data = payload.status === 204 ? [] : payload.data
      dispatch({ type: ExternalCardTypes.LIST_EXTERNALCARD_SUCCESS, data })
    } catch (error) {
      dispatch({ type: ExternalCardActionTypes.LIST_EXTERNALCARD_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

export const ExternalCardActions = {
  ...ExternalCardAction,
  getExternalCardList,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type ExternalCardState = ResourceState<CreditCardDto>

const initialState: ExternalCardState = {
  ...initialResourceState,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

export const ExternalCardSelectors = {
  ...ExternalCardResourceSelector,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const externalCard: Reducer = (
  state: ExternalCardState = initialState,
  action: AnyAction
): ExternalCardState => {
  return {
    ...ExternalCardResourceReducer(state, action, {
      identifier: 'uuid',
      initialState,
    }),
  }
}
