import * as React from 'react'
import { Redirect, RouteProps } from 'react-router'
import { useSelector } from 'react-redux'

import { Routes } from '../../../Routes/Routes'
import { SignInPolicy } from '../../../Policies'
import { StateTypes } from '../../../Store'

import { Route } from './Route'

interface PrivateRouteProps extends RouteProps {
  component?: React.FC
  children?: React.ReactNode
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
  let result = <Redirect to={Routes.SignIn.index.path} />
  const signInState = useSelector((state: StateTypes) => state.signIn)
  const isAuthenticated = SignInPolicy.isAuthenticated(signInState)
  if (isAuthenticated) {
    result = <Route {...props} />
  }

  return result
}

export default PrivateRoute
