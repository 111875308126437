
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import dayjs from 'dayjs'

import {
  Button,
  ButtonSkin,
  IconTypes,
  Typography,
  Icon,
  Card,
  PaymentCards,
  CardSkin,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import {
  CardSelectors,
  PrepareCardOpposition,
  CardActions,
  CreditCardData,
  SubscriptionActions,
} from '@neo-commons/store'
import { OppositionStep, OppositionSteps, StepComponentProps } from '@neo-commons/policies'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'
import { useResponsive } from '@neo-web/hooks'

import './OppositionSummary.scss'
import { OrderNewCard } from '../../Order/OrderNewCard'

type SummaryProps = StepComponentProps<OppositionSteps[OppositionStep.Summary]>

export const OppositionSummary: React.FC<SummaryProps> = ({ nextStep }: SummaryProps) => {
  const { t } = useTranslation()
  const isMobileOrTablet = useResponsive()
  const dispatch = useDispatch()
  const preparedCardOpposition: PrepareCardOpposition = useSelector(CardSelectors.preparedCardOpposition)
  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)

  const iconAndTitle =
    <div className='OppositionSummary_container-summary-iconAndTitle'>
      <div>
        <Icon
          name='check-circle'
          type={IconTypes.FEATHER}
          size={40}
          color={Colors.secondary}
        />
      </div>
      <div>
        <Typography typeface='bigTitle'>
          {t('app:pages:services:debitCard:opposition:oppositionSummary:title')}
        </Typography>
      </div>
    </div>

  const oppositionDate = useMemo(() => {
    return dayjs(preparedCardOpposition?.workflowCardOpposition?.oppositionDate)
  }, [preparedCardOpposition])

  useEffect(() => {
    dispatch(SubscriptionActions.reset)
    dispatch(SubscriptionActions.list)
  }, [])

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      footer={
        <Button
          skin={ButtonSkin.OUTLINE_PRIMARY}
          title={t('app:pages:services:debitCard:opposition:oppositionSummary:goBackToServices')}
          onPress={async () => {
            await dispatch(CardActions.reset())
            nextStep()
          }}
        />
      }
    >
      <div className='OppositionSummary'>
        <div className='OppositionSummary_container'>
          <div className='OppositionSummary_container-summary'>
            {isMobileOrTablet && iconAndTitle}
            <div>
              <div className='OppositionSummary_container-summary-card'>
                <Card
                  skin={CardSkin.DEFAULT}
                  containerStyle={{ padding: 20, marginBottom: 0, width: isMobileOrTablet ? '100%' : 350 }}
                >
                  <PaymentCards card={selectedCard} />
                </Card>
              </div>
            </div>
            <div className='OppositionSummary_container-summary-info'>
              {!isMobileOrTablet && iconAndTitle}
              <Typography typeface='subtitle'>
                {t(
                  'app:pages:services:debitCard:opposition:oppositionSummary:oppositionDate',
                  { date: oppositionDate.format('DD/MM/YYYY') }
                )}
              </Typography>
              <br />
              <Typography typeface='subtitle'>
                {t(
                  'app:pages:services:debitCard:opposition:oppositionSummary:oppositionNumber',
                  { number: preparedCardOpposition?.workflowCardOpposition?.oppositionNumber }
                )}
              </Typography>
            </div>
          </div>
          <div className='OppositionSummary_container-divider' />
          <div className='OppositionSummary_container-orderNewCard'>
            <OrderNewCard />
          </div>
        </div>
      </div>
    </TunnelContent>
  )
}
