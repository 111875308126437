import { map } from 'lodash'

import { posOrderPolicy } from '@neo-commons/policies/src/POS/Order'

export const PosOrderRoutes = {
  index: {
    path: posOrderPolicy.basePath,
    name: 'app:pages:services:pos:order:title',
    available: true,
  },
  ...map(posOrderPolicy.steps, ({ path, meta }) => ({
    path,
    name: meta && meta.headerTitle,
    available: true,
  })),
}
