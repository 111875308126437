import React, { useEffect, useState } from 'react'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import { Loader } from '@neo-web/componentsOld'
import './PDFViewer.scss'

interface PDFViewerProps {
  file: string,
}

export const PDFViewer: React.FC<PDFViewerProps> = (props: PDFViewerProps) => {
  const [pages, setPages] = useState([])
  const [numPages, setNumPages] = useState(null)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  useEffect(() => {
    setPages(Array.from(Array(numPages + 1).keys()))
  }, [numPages])

  return (
    <div className='PDFViewer_container'>
      <Document
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Loader />}
        file={props.file}
      >
        {pages.map((value, index) => value > 0 &&
          <Page loading={<Loader />} pageNumber={value} key={index} scale={1.5} />
        )}
      </Document>
    </div>
  )
}
