import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { CardTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    CardTypes.LIST_CARD_SUCCESS,
    CardTypes.LIST_CARD_FAILURE,
    CardTypes.LIST_CARD_REQUEST,
    CardTypes.CREATE_CARD_REQUEST,
    CardTypes.CREATE_CARD_SUCCESS,
    CardTypes.CREATE_CARD_FAILURE,
    CardTypes.GET_ENCRYPTED_CARD_DATA_REQUEST,
    CardTypes.GET_ENCRYPTED_CARD_DATA_SUCCESS,
    CardTypes.GET_ENCRYPTED_CARD_DATA_FAILURE,
    CardTypes.UPDATE_CARD_REQUEST,
    CardTypes.UPDATE_CARD_SUCCESS,
    CardTypes.UPDATE_CARD_FAILURE,
    CardTypes.ACTIVATE_CARD_REQUEST,
    CardTypes.ACTIVATE_CARD_SUCCESS,
    CardTypes.ACTIVATE_CARD_FAILURE,
    CardTypes.VALIDATE_CARD_OPPOSITION_REQUEST,
    CardTypes.VALIDATE_CARD_OPPOSITION_SUCCESS,
    CardTypes.VALIDATE_CARD_OPPOSITION_FAILURE,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    CardTypes.CREATE_CARD_FAILURE,
    CardTypes.CHECK_SECRET_QUESTION_ANSWER_FAILURE,
    CardTypes.CREATE_OPPOSITION_WORKFLOW_FAILURE,
    CardTypes.UPDATE_CARD_SUCCESS,
    CardTypes.UPDATE_CARD_FAILURE,
    CardTypes.UPDATE_CARD_LIMITS_SUCCESS,
    CardTypes.UPDATE_CARD_LIMITS_FAILURE,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
]
