interface ApiResponse<E> {
  data: E[]
}

export class PaginationUtils<E> {
  private pageNum: number
  private pageSize: number
  private hasNextValue: boolean
  fetchPageCallback: (pageNum: number, pageSize: number) => Promise<ApiResponse<E>>

  constructor (pageNum = 1, pageSize = 20) {
    this.pageNum = (pageNum - 1)
    this.pageSize = pageSize
    this.hasNextValue = true
  }

  public fetchPageHandler = (fetchPageCallback: (pageNum: number, pageSize: number) => Promise<{data: E[]}>): PaginationUtils<E> => {
    this.fetchPageCallback = fetchPageCallback
    return this
  }

  public next = async () => {
    this.pageNum += 1
    try {
      const payload: {data: E[]} = await this.fetchPageCallback(this.pageNum, this.pageSize)
      this.hasNextValue = payload.data.length === this.pageSize
      return payload
    } catch (error) {
      if (error.status === 404) {
        this.pageNum -= 1
        this.hasNextValue = false
      }
      throw error
    }
  }

  public previous = async () => {
    this.pageNum -= 1
    if (this.pageNum < 0) {
      this.pageNum = 0
    }
    return await this.fetchPageCallback(this.pageNum, this.pageSize)
  }

  public hasNext = (): boolean => {
    return this.hasNextValue
  }

  public hasPrevious = (): boolean => {
    return this.pageNum > 0
  }

  public nextAll = async () => {
    this.pageNum = 0
    this.hasNextValue = true
    const results: ApiResponse<E> = { data: [] }
    while (this.hasNext()) {
      results.data = [...results.data, ...(await this.next()).data]
    }
    return results
  }
}
