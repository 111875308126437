import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

import { createStylesheet } from '../utils'

const styles = createStylesheet(({ props }) => {
  return StyleSheet.create({
    container: {
      overflow: 'hidden',
      borderRadius: 15,
      padding: 0,
      minHeight: 56,
      paddingLeft: 16,
      paddingRight: 8,
      marginBottom: 10,
      display: 'flex',
      flexDirection: 'row',
    },
    textsArea: {
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 10,
      paddingBottom: 10,
      flex: 1,
    },
    subtitle: {
      display: 'flex',
      marginTop: 4,
      color: Colors.darkGreyText,

      ...(props.selected ? {
        color: Colors.white,
      } : {}),
    },
    amountArea: {
      alignSelf: 'center',
      fontSize: 16,
      color: Colors.black,

      ...(props.selected ? {
        color: Colors.white,
      } : {}),
    },
  })
})

export default styles
