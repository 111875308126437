import React from 'react'

import { Typography } from '@neo-commons/components'

import './Alert.scss'

export interface AlertProps {
  icon?: React.ReactElement,
  title?: string,
  subTitle?: string,
  button?: React.ReactElement,
}

export const Alert: React.FC<AlertProps> = (props) => {
  return (
    <div className='Alert_container'>
      <div className='Alert_content'>
        {props.icon &&
          <div className='Alert_content_icon'>
            {props.icon}
          </div>}
        <div className='Alert_content_description'>
          {props.title &&
            <Typography typeface='bold'>
              {props.title}
            </Typography>}
          {props.subTitle &&
            <Typography typeface='subtitle'>
              {props.subTitle}
            </Typography>}
        </div>
      </div>
      <div className='Alert_button'>
        {props.button}
      </div>
    </div>
  )
}
