import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { Button, Icon, IconTypes, MenuItemCard, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

import './AggregationKnowMore.scss'

export const AggregationKnowMore: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const ACPRLink = 'https://acpr.banque-france.fr/'
  const FAQLink = 'https://noelse.com/faq/docs/lagregation-est-elle-securisee/'

  const getDetails = (iconName: string, translationName: string) => {
    return (
      <div className='AggregationKnowMore_containerFlex'>
        <div className='AggregationKnowMore_leftArea'>
          <Icon type={IconTypes.FEATHER} name={iconName} size={20} color={Colors.black} />
        </div>
        <div className='AggregationKnowMore_centerArea'>
          <Typography typeface='bold'>
            {t(`app:pages:services:aggregation:information:more:${translationName}`)}
          </Typography>
          <br />
          <Typography typeface='body1'>
            {t(`app:pages:services:aggregation:information:more:${translationName}Details`)}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <PageDetails
      onBack={() => history.goBack()}
      onClose={() => history.push(Routes.Services.aggregation.path)}
      title={t('app:pages:services:aggregation:menu')}
      component={
        <PageContent
          title={t('app:pages:services:aggregation:information:title')}
          justifyContent={JustifyContent.CENTER}
        >
          <div className='AggregationKnowMore_containerBasic'>
            <Typography
              typeface='h5'
              style={{ padding: 0, margin: 0 }}
            >
              {t('app:pages:services:aggregation:information:subTitle')}
              <a href={ACPRLink} target='_blank' rel='noreferrer' style={{ fontWeight: 'bold' }}> ACPR </a>
              {t('app:pages:services:aggregation:information:subTitleBis')}
            </Typography>
            <Typography typeface='body1'>
              {t('app:pages:services:aggregation:information:subTitleInfo')}
            </Typography>
          </div>

          <div className='AggregationKnowMore_container'>
            <Typography typeface='bold'>
              {t('app:pages:services:aggregation:information:more:title')}
            </Typography>
            {getDetails('lock', 'identifier')}
            {getDetails('info', 'accountInformations')}
            {getDetails('refresh-ccw', 'accountsUpdated')}
          </div>

          <div className='AggregationKnowMore_containerBasic'>
            <Typography typeface='h3'>
              {t('app:pages:services:aggregation:information:moreInfo')}
            </Typography>
          </div>
          <MenuItemCard
            containerStyle={{ height: 75, marginBottom: 16, borderTopWidth: 0 }}
            title={t('app:pages:services:aggregation:information:secure')}
            chevronPosition='center'
            icon={<Icon type={IconTypes.MATERIAL} name='security' color={Colors.secondary} size={35} />}
            onPress={() => window.open(FAQLink, '_blank').focus()}
          />

          <Button
            title={t('app:global:letsGo')}
            flat
            onPress={() => history.push(Routes.Services.aggregationSearch.path)}
          />
        </PageContent>
      }
    />
  )
}
