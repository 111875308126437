import { ActionsObservable, ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { AnalyticsHandler } from 'Services/Analytics'

import {
  SignInTypes,
  UserTypes,
  ClientTypes,
  SubscriptionTypes,
  TransactionTypes,
  SignupTypes,
  BankAccountTypes,
  DeviceTypes,
  CardTypes,
  ContactTypes,
  TransferTypes,
  ExternalCardTypes,
  OfferTypes,
  DocumentTypes,
  ContractTypes,
  NotificationTypes,
  StatementTypes,
  InvoiceTypes,
  PosTypes,
  TelecollectionTypes,
  AdvanceTypes,
  OrdersTypes,
  AddressTypes,
  ProvisioningTypes,
  DocumentUploadTypes,
  RecoverPasscodeTypes,
} from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const resetState$ = (actions$: ActionsObservable<AnyAction>) => {
  AnalyticsHandler.reset()
  return actions$.pipe(ofType(SignInTypes.SIGN_OUT_SUCCESS), mergeMap(() => [
    { type: SignupTypes.RESET },
    { type: AddressTypes.RESET },
    { type: DocumentUploadTypes.RESET },
    { type: ProvisioningTypes.RESET },
    { type: RecoverPasscodeTypes.RESET },
    { type: ClientTypes.RESET },
    { type: SubscriptionTypes.RESET },
    { type: UserTypes.RESET },
    { type: TransactionTypes.RESET },
    { type: BankAccountTypes.RESET },
    { type: DeviceTypes.RESET },
    { type: CardTypes.RESET },
    { type: ContactTypes.RESET },
    { type: TransferTypes.RESET },
    { type: ExternalCardTypes.RESET },
    { type: OfferTypes.RESET },
    { type: ContractTypes.RESET },
    { type: InvoiceTypes.RESET },
    { type: DocumentTypes.RESET },
    { type: NotificationTypes.RESET },
    { type: StatementTypes.RESET },
    { type: PosTypes.RESET },
    { type: TelecollectionTypes.RESET },
    { type: OrdersTypes.RESET },
    { type: AdvanceTypes.RESET },
  ]))
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    SignInTypes.GET_UUID_FAILURE,
  ])
}

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    SignInTypes.CONFIRM_PASSWORD_CHECK_REQUEST,
    SignInTypes.CONFIRM_PASSWORD_CHECK_FAILURE,
    SignInTypes.CONFIRM_PASSWORD_CHECK_SUCCESS,
  ])
}

export default [
  resetState$,
  displayDropdownAlert$,
  reactiveLoader$,
]
