import React from 'react'
import { TextStyle } from 'react-native'

import { Icon, MenuItemCard, Typography2 } from '@neo-commons/components'
import { Colors, Typo } from '@neo-commons/styles'

import './CardMenuList.scss'

export type CardMenuItem = {
  title: string;
  content?: string;
  onPress: () => void;
  subtitleStyle?: TextStyle;
  disabled?: boolean;
  titleNumber?: number;
  hidden?: boolean;
  icon?: React.ReactElement<typeof Icon>
  separation?: boolean,
}

type CardMenuListProps = {
  title?: string;
  menus: CardMenuItem[];
}

export const CardMenuList: React.FC<CardMenuListProps> = ({ title, menus }) => {
  return (
    <div className='CardMenuList'>
      {title && (
        <div className='CardDetails_title'>
          <Typography2 typeface='overline'>{title}</Typography2>
        </div>
      )}
      <>
        {menus.map((menu, index) =>
          <div key={index}>
            <div className={'CardMenuList_card ' + (menu.disabled && 'disable')}>
              {!menu.hidden &&
                <MenuItemCard
                  icon={menu.icon}
                  title={menu.title}
                  titleStyle={{ ...Typo.input, display: 'flex' }}
                  titleNumber={menu.titleNumber}
                  subtitle={menu?.content}
                  subtitleStyle={menu.subtitleStyle}
                  chevronPosition='center'
                  chevronColor={Colors.darkGreyText}
                  onPress={() => menu.onPress()}
                  disabled={menu?.disabled ? menu.disabled : false}
                />}
            </div>
            {menu?.separation && <hr />}
          </div>
        )}
      </>
    </div>
  )
}
