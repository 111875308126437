import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { TransactionTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    TransactionTypes.LIST_TRANSACTION_FAILURE,
    TransactionTypes.LIST_TRANSACTION_SUCCESS,
    TransactionTypes.LIST_TRANSACTION_REQUEST,
    TransactionTypes.BYID_TRANSACTION_FAILURE,
    TransactionTypes.BYID_TRANSACTION_REQUEST,
    TransactionTypes.BYID_TRANSACTION_SUCCESS,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    TransactionTypes.UPDATE_TRANSACTION_FAILURE,
    TransactionTypes.UPDATE_TRANSACTION_REQUEST,
    TransactionTypes.UPDATE_TRANSACTION_SUCCESS,
    TransactionTypes.BYID_TRANSACTION_FAILURE,
    TransactionTypes.CREATE_REFUND_FAILURE,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
]
