import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  badgesContainer: {
    alignItems: 'center',
  },
  badges: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  badge: {
    marginHorizontal: 15,
    marginVertical: 5,
  },
})
