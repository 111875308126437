import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, ButtonSkin, ContactCard, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Contact, ContactActions, ContactSelectors, ContactTypes } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { ContactUtils } from '@neo-commons/libraries'

import { ContactDto } from '@afone/neo-core-client/dist/models'

import { PaginationList } from '@neo-web/componentsOld'

import { StateTypes } from '../../../../../Store'
import { AppConfig } from '../../../../../Config/AppConfig'
import { Routes } from '../../../../../Routes/Routes'

import './List.scss'

interface ListProps {
  onSelectContact: (contact: ContactDto) => void,
  selectContactUuid?: string,
  createRouting?: string,
}

export const List: React.FC<ListProps> = (props: ListProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const contactState = useSelector((state: StateTypes) => state?.contact)
  const contactList: Contact[] = useSelector(ContactSelectors.list)
  const list = useSelector((state: StateTypes) => state?.contact.list)

  const [contactListFiltered, setContactListFiltered] = useState<Contact[]>([])

  const loadContacts = async () => {
    try {
      await dispatch(ContactActions.list())
      await dispatch(ContactActions.prepareFilter({
        ...list,
        page: AppConfig.DEFAULT_PAGE_NUMBER,
      }))
    } catch (e) {
      console.error('failed to get contact')
    }
  }

  const getNewContacts = async () => {
    await dispatch(ContactActions.prepareFilter({
      ...list,
      page: ++list.page,
    }))
    await loadContacts()
  }

  useEffect(() => {
    if (!contactState.loading && !list?.loadedOnce) {
      (async () => {
        await loadContacts()
      })()
    }
  }, [])

  useEffect(() => {
    setContactListFiltered(contactList)
  }, [contactList])

  const onContactSelected = (contactDto: ContactDto) => {
    (async () => {
      try {
        dispatch(ContactActions.setSelected(contactDto.uuid))
        props.onSelectContact(contactDto)
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const filter = async (text: string) => {
    setContactListFiltered(contactList.filter(
      (contact: Contact) =>
        (contact.familyName.toLowerCase().includes(text.toLowerCase()) ||
        contact.givenName.toLowerCase().includes(text.toLowerCase()))
    ))
  }

  const contactNavbar =
    <>
      {/* @TODO uncomment the button when the feature is developped
      <Button
        title={t('app:pages:payments:contact:list:import')}
        disabled
        small
        flat
        skin={!isTabletOrMobile ? ButtonSkin.DEFAULT : ButtonSkin.DEFAULT_LIGHT}
        leftIcon={
          <Icon
            name='download'
            type={IconTypes.FEATHER}
            size={20}
            color={Colors.primary}
          />
        }
      /> */}
      <Button
        title={t('app:pages:payments:contact:list:new')}
        skin={ButtonSkin.PRIMARY}
        small
        flat
        leftIcon={
          <Icon
            name='user'
            type={IconTypes.FEATHER}
            size={20}
            color={Colors.white}
          />
        }
        onPressOut={() => history.push(props.createRouting ?? Routes.Payments.contactCreate.path)}
      />
    </>

  return (
    <div className='ContactList_container'>
      <div className='ContactList_body'>
        <PaginationList
          search
          state={contactState}
          stateList={contactListFiltered}
          onFilter={filter}
          navBar={contactNavbar}
          items={(item) =>
            <div key={item.uuid}>
              <ContactCard
                contact={item}
                onPress={(item) => onContactSelected(item)}
                selected={item.uuid === props.selectContactUuid}
              />
            </div>}
          groupData={ContactUtils.groupContactByFirstLetter(contactListFiltered)}
          eventLoading={ContactTypes.LIST_CONTACT_REQUEST}
          next={() => getNewContacts()}
          refreshFunction={loadContacts}
        />

        {contactList.length === 0 &&
          <div className='ContactList_empty'>
            <div className='ContactList_empty_item'>
              <Icon type={IconTypes.NEOFONT} name='contact_empty' size={64} color={Colors.secondary} />
            </div>
            <div className='ContactList_empty_item'>
              <Typography typeface='bold'>
                {t('app:pages:payments:contact:list:empty:title')}
              </Typography>
            </div>
            <div className='ContactList_empty_item'>
              <Typography typeface='content'>
                {t('app:pages:payments:contact:list:empty:text')}
              </Typography>
            </div>
          </div>}
      </div>

    </div>
  )
}
