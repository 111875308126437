import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { AppConfig } from 'Config/AppConfig'

import {
  BankAccountUtils,
  ClientUtils,
  Formatters,
  NumbersUtils,
  ProcessType,
  Validators,
} from '@neo-commons/libraries'
import { BadgeList, BadgeProps, BadgeSkin, Button, ButtonSkin, MoneyInput, Typography } from '@neo-commons/components'
import {
  BankAccount,
  BankAccountSelectors,
  ClientSelectors,
  ExternalCardActions,
  OfferSelectors,
  PreparedProvisioning,
  ProvisioningActions,
  ProvisioningSelectors,
  SubscriptionSelectors,
} from '@neo-commons/store'

import { AccountStatusDto, ClientDto, OfferDto, OfferTypeDto, SuspensionReasonDto } from '@afone/neo-core-client/dist/models'

import { AmountHiddenInput, JustifyContent, PageContent } from '@neo-web/componentsOld'

import './Amount.scss'

interface AmountProps {
  initData?: {
    amount?: number,
    productCode?: string,
  }
  nextStep: () => void
}

export const Amount: React.FC<AmountProps> = (props: AmountProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [individualAmountFees, setIndividualAmountFees] = useState(undefined)
  const [proPercentageFees, setProPercentageFee] = useState(undefined)

  const preparedProvisioning: PreparedProvisioning = useSelector(ProvisioningSelectors.prepare)
  const bankAccountSelected: BankAccount = useSelector(BankAccountSelectors.selected)
  const subscription = useSelector(SubscriptionSelectors.byId(bankAccountSelected?.subscriptionUuid))
  const minimumFirstProvisioningAmount = subscription?.offer?.minCredit
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const socleOffers: OfferDto[] = useSelector(OfferSelectors.listByClientType(client?.type, OfferTypeDto.SOCLE))

  const isFirstCredit = bankAccountSelected.status === AccountStatusDto.SUSPENDED &&
    bankAccountSelected?.suspension?.reason === SuspensionReasonDto.NEW_ACCOUNT_WITHOUT_FUNDS

  useEffect(() => {
    if (socleOffers && socleOffers.length) {
      const products = socleOffers[0]?.products
      const individualPrices = products?.filter(product => product.productCode === 'NEO-PRD-CB-IN')?.[0]?.prices
      const individualFees = individualPrices?.filter(price => price.priceCode === 'NEO-PRI-CB-IN')?.[0]

      const proPrices = products?.filter(product => product.productCode === 'NEO-PRD-CB-IN-PRO')?.[0]?.prices
      const proFees = proPrices?.filter(price => price.priceCode === 'NEO-PRI-CB-IN-PRO')?.[0]

      setIndividualAmountFees(individualFees?.amount)
      setProPercentageFee(((proFees?.percentage ?? 0) * 100))
    }
  }, [socleOffers])

  const minimumProvisioningAmount = useSelector(
    (state: StateTypes) =>
      state?.config?.data?.currentConfig?.minimumProvisioningAmount ??
      AppConfig.MIN_CREDIT_AMOUNT
  )

  const maximumProvisioningAmount = useSelector(
    (state: StateTypes) =>
      state?.config?.data?.currentConfig?.maximumProvisioningAmount ??
      AppConfig.MAX_CREDIT_AMOUNT
  )

  const validators = [
    !isFirstCredit
      ? Validators.isGreaterThan(minimumProvisioningAmount,
        t('app:pages:credit:step1:amount:minimumCreditError', { amount: minimumProvisioningAmount }))
      : Validators.isGreaterThan(minimumFirstProvisioningAmount,
        t('app:pages:credit:step1:amount:minimumCreditError', { amount: minimumFirstProvisioningAmount })),
    Validators.isLowerThan(maximumProvisioningAmount,
      t('app:pages:credit:step1:amount:maximumCreditError', { amount: maximumProvisioningAmount })),
  ]

  const stateValue = preparedProvisioning?.amount
    ? preparedProvisioning?.amount?.toString()
    : (props?.initData?.amount ? props?.initData?.amount?.toFixed(2).toString() : '')

  const [value, setValue] = useState(stateValue)
  const [isValid, setIsValid] = useState(false)

  const badges: BadgeProps[] = [
    { value: minimumProvisioningAmount.toString(), skin: BadgeSkin.DEFAULT },
    { value: (minimumProvisioningAmount * 2).toString(), skin: BadgeSkin.DEFAULT },
    { value: (minimumProvisioningAmount * 5).toString(), skin: BadgeSkin.DEFAULT },
    { value: (minimumProvisioningAmount * 10).toString(), skin: BadgeSkin.DEFAULT },
  ]

  const navigateToCreditCardInfos = () => {
    try {
      (async () => {
        await dispatch(ProvisioningActions.prepare({
          ...preparedProvisioning,
          amount: NumbersUtils.amountFormatToNumber(value),
          bankAccountUuid: bankAccountSelected.uuid,
          bankAccountLabel: BankAccountUtils.getName(bankAccountSelected),
          processType: props?.initData?.productCode ? ProcessType.PAYMENT : ProcessType.PROVISIONING,
          productCode: props?.initData?.productCode ?? null,
        }))
      })()
      props.nextStep()
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      try {
        await dispatch(ExternalCardActions.getExternalCardList())
        if (props?.initData?.amount) {
          navigateToCreditCardInfos()
        }
      } catch (e) {}
    })()
  }, [])

  return (
    <PageContent
      withBackground
      style={{ textAlign: 'center' }}
      justifyContent={JustifyContent.BETWEEN}
      title={t('app:pages:credit:step1:amount:titleMessage')}
      footer={
        <Button
          title={t('app:global:validate')}
          skin={ButtonSkin.PRIMARY}
          disabled={!isValid}
          onPressOut={() => {
            isValid && navigateToCreditCardInfos()
          }}
        />
      }
    >
      <div className='Amount_feesDisclaimer'>
        <Typography typeface='content'>
          {ClientUtils.isClientTypeCorporate(client?.type)
            ? t('app:pages:credit:step1:amount:feesDisclaimerPro',
              {
                pourcentPro: proPercentageFees,
              })
            : t('app:pages:credit:step1:amount:feesDisclaimer',
              {
                amountPart: NumbersUtils.displayPriceForHuman(individualAmountFees),
                pourcentPro: proPercentageFees,
              }
            )}
        </Typography>
      </div>

      <AmountHiddenInput value={value} isFirstCredit={isFirstCredit}>
        <MoneyInput
          autoFocus
          value={value}
          errorStyle={{ minWidth: 280, marginLeft: 'calc(-140px + 50%)', justifyContent: 'center' }}
          currency={t('app:global:currency')}
          validators={validators}
          fullErrorMode={!isValid && value !== ''}
          onChangeText={text => setValue(Formatters.formatMoney(text))}
          placeholder={!isFirstCredit ? minimumProvisioningAmount?.toString() : minimumFirstProvisioningAmount?.toString()}
          isValid={isValid}
          onValidationChecked={(status) => setIsValid(status.isValid)}
        />
      </AmountHiddenInput>

      <div className='pb-xs'>
        {t('app:pages:credit:step1:amount:chooseMessage')}
      </div>

      <BadgeList
        badges={badges}
        onItemPress={value => setValue(value)}
      />

      <div className='Amount_minMaxCreditAllowed'>
        <Typography typeface='content'>
          {t('app:pages:credit:step1:amount:minMaxCreditAllowed', { minFirstCredit: minimumFirstProvisioningAmount, minAmount: minimumProvisioningAmount, maxAmount: maximumProvisioningAmount })}
        </Typography>
      </div>
    </PageContent>
  )
}
