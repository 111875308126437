const COLORS: { [key: string]: string } = {
  secondary: '#e1d3a8',
  primary: '#89adf7',
  background: '#f6f5f3',
  white: '#fff',
  error: '#EB6868',
  secondaryLight: '#f0ead4',
  secondaryDarker: '#c0ac76',
  success: '#4aab91',
  warning: '#ebcb68',
  grey2: '#e7e7e7',
  textColor: '#131313',
  textSecondary: '#8d8d8d',
  textLightGray: '#bdbdbd',
  disable: '#d2d2d2',
}

export default COLORS
