import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, Route, useHistory, useLocation } from 'react-router-dom'

import { TransferPolicy } from 'Policies/TransferPolicy'
import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import {
  BankAccountSelectors,
  BankTransferBenificiaryTypeEnum,
  ContactActions,
  ContactSelectors,
  ContactTypes,
  Transfer,
  TransferActions,
  TransferSelectors,
} from '@neo-commons/store'

import { AccountDto } from '@afone/neo-core-client/models'

import { InformationModal, Page, PageDetails, PageDetailsProps } from '@neo-web/componentsOld'

import { List } from '../Contact/List'
import { Create } from '../Contact/Create'

import { Result } from './Result'
import { Amount } from './Amount'
import { Beneficiary } from './SelectAccount/Beneficiary'
import { CreditIssuers } from './SelectAccount/CreditIssuers'
import { Summary } from './Summary'
import { SelectTransfer } from './SelectTransfer'
import { Scheduled } from './Scheduled'
import { Target } from './SelectAccount/Target'
import { ComingUp } from './ComingUp'

export const Transfers: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const transfers: Transfer[] = useSelector(TransferSelectors.list)
  const transfer = useSelector((state: StateTypes) => state?.transfer)

  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const preparedTransfer = useSelector(TransferSelectors.getPreparedTransfer)
  const contactSelected = useSelector(ContactSelectors.selected)
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts())

  const backTransferWorkflow = (value: string) => {
    history.push(transfer.prepare.isUpdating ? generatePath(
      Routes.Payments.transferUpdate.path,
      { id: transfer.prepare.uuid }
    ) : value)
  }

  const nbTransfers: string = (transfers.length > 0 ? '(' : '') + transfers.length + (transfers.length > 0 ? ')' : '')

  const selectContact = () => {
    dispatch(TransferActions.prepare({
      ...transfer,
      contactChannelUuid: undefined,
      benificiaryType: BankTransferBenificiaryTypeEnum.Recipient,
      recipientAccountUuid: undefined,
      recipientAccountName: undefined,
    }))
  }

  const stepsContent: { path: string, content: PageDetailsProps }[] = [
    {
      path: Routes.Payments.bankTransfer.path,
      content: {
        title: t('app:pages:payments:transfer:home:title'),
        onBack: () => history.push(Routes.Payments.index.path),
        component: (
          <Page pages={[{
            path: Routes.Payments.bankTransfer.path,
            component: <SelectTransfer />,
            name: t('app:pages:payments:transfer:home:menu'),
          }, {
            path: Routes.Payments.nextTransfer.path,
            component: <ComingUp />,
            name: t('app:pages:payments:transfer:next:menu', { value: nbTransfers }),
          }]}
          />
        ),
      },
    },
    {
      path: Routes.Payments.nextTransfer.path,
      content: {
        title: t('app:pages:payments:transfer:home:title'),
        onBack: () => backTransferWorkflow(Routes.Payments.index.path),
        component: (
          <Page pages={[{
            path: Routes.Payments.bankTransfer.path,
            component: <SelectTransfer />,
            name: t('app:pages:payments:transfer:home:menu'),
          }, {
            path: Routes.Payments.nextTransfer.path,
            component: <ComingUp />,
            name: t('app:pages:payments:transfer:next:menu', { value: nbTransfers }),
          }]}
          />
        ),
      },
    },
    {
      path: Routes.Payments.account.path,
      content: {
        title: t('app:pages:payments:transfer:source:title'),
        onBack: () => backTransferWorkflow(Routes.Payments.bankTransfer.path),
        component: <CreditIssuers />,
      },
    },
    {
      path: Routes.Payments.contactCreate.path,
      content: {
        title: t('app:pages:payments:contact:create:title'),
        onBack: () => backTransferWorkflow(Routes.Payments.contact.path),
        component: (
          <Create
            onCreate={() => {
              selectContact()
              history.push(Routes.Payments.target.path)
            }}
          />
        ),
      },
    },
    {
      path: Routes.Payments.beneficiary.path,
      content: {
        title: t('app:pages:payments:transfer:contact:title'),
        onBack: () => bankAccounts.length > 1
          ? backTransferWorkflow(Routes.Payments.account.path)
          : backTransferWorkflow(Routes.Payments.bankTransfer.path),
        component: (
          <Page pages={[{
            path: Routes.Payments.beneficiary.path,
            component: <Beneficiary />,
            name: t('app:pages:payments:transfer:menu:account'),
          },
          {
            path: Routes.Payments.contact.path,
            component: <></>,
            name: t('app:pages:payments:transfer:menu:contact'),
          }]}
          />
        ),
      },
    },
    {
      path: Routes.Payments.contact.path,
      content: {
        title: t('app:pages:payments:transfer:contact:title'),
        onBack: () => backTransferWorkflow(Routes.Payments.account.path),
        component: (
          <Page pages={[{
            path: Routes.Payments.beneficiary.path,
            component: <></>,
            name: t('app:pages:payments:transfer:menu:account'),
          },
          {
            path: Routes.Payments.contact.path,
            component: TransferPolicy.isIssuerSecondary(transfer)
              ? (
                <InformationModal
                  show
                  onClose={() => {
                    history.push(Routes.Payments.beneficiary.path)
                  }}
                  title={t('app:pages:payments:transfer:fundInfo')}
                  descriptionAlign='center'
                  description={t('app:pages:payments:transfer:fundDesc')}
                />
              )
              : (
                <List
                  onSelectContact={() => {
                    selectContact()
                    history.push(Routes.Payments.target.path)
                  }}
                />
              ),
            name: t('app:pages:payments:transfer:menu:contact'),
          }]}
          />
        ),
      },
    },
    {
      path: Routes.Payments.target.path,
      content: {
        title: t('app:pages:payments:transfer:target:title'),
        onBack: () => backTransferWorkflow(Routes.Payments.contact.path),
        component: <Target />,
        subtitle: contactSelected && `${contactSelected?.givenName} ${contactSelected?.familyName}`,
      },
    },
    {
      path: Routes.Payments.amount.path,
      content: {
        title: t('app:pages:payments:transfer:pay:title'),
        onBack: () => {
          preparedTransfer?.beneficiaryType === BankTransferBenificiaryTypeEnum.Recipient
            ? backTransferWorkflow(Routes.Payments.target.path) : backTransferWorkflow(Routes.Payments.beneficiary.path)
        },
        component: <Amount />,
      },
    },
    {
      path: Routes.Payments.summary.path,
      content: {
        title: t('app:pages:payments:transfer:summary:title'),
        onBack: () => backTransferWorkflow(
          TransferPolicy.isPunctual(transfer) ? Routes.Payments.amount.path
            : TransferPolicy.isRepeated(transfer) ? Routes.Payments.recurring.path
              : Routes.Payments.scheduled.path),
        component: <Summary />,
      },
    },
    {
      path: Routes.Payments.scheduled.path,
      content: {
        title: t('app:pages:payments:transfer:scheduled:title'),
        onBack: () => backTransferWorkflow(Routes.Payments.amount.path),
        component: <Scheduled />,
      },
    },
    {
      path: Routes.Payments.recurring.path,
      content: {
        title: t('app:pages:payments:transfer:reccuring:title'),
        onBack: () => backTransferWorkflow(Routes.Payments.amount.path),
        component: <Scheduled />,
      },
    },
    {
      path: Routes.Payments.result.path,
      content: {
        title: t('app:pages:payments:transfer:result:title'),
        onBack: () => history.push(Routes.Payments.bankTransfer.path),
        component: <Result />,
      },
    },
  ]

  useEffect(() => {
    const currentStep: PageDetailsProps = stepsContent.find(step => step.path === location.pathname).content
    if (history.action === 'POP' && typeof currentStep.onBack === 'function') {
      currentStep.onBack()
    }
  }, [history.action])

  useEffect(() => {
    try {
      if (!TransferPolicy.canStep1(transfer) && transfer?.list.shouldRefresh) {
        dispatch({ type: ContactTypes.RESET })

        dispatch(ContactActions.list())
        dispatch(TransferActions.getRecurringTransfers())
      }
    } catch (error) {
      console.log(error)
    }
  }, [selectedBankAccount])

  return (
    <>
      {stepsContent.map((value, index) => {
        return (
          <Route key={value.path} exact path={value.path}>
            <PageDetails
              key={index}
              {...value.content}
            />
          </Route>
        )
      })}
    </>
  )
}
