import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { SecretQuestionStep, SecretQuestionSteps, StepComponentProps } from '@neo-commons/policies'

import { PageDetails, SuccessContent } from '@neo-web/componentsOld'

type SecretQuestionSuccessProps = StepComponentProps<SecretQuestionSteps[SecretQuestionStep.Success]>

export const SecretQuestionSuccess: React.FC<SecretQuestionSuccessProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <PageDetails
      onBack={false}
      title={t('app:pages:myProfile:security:secretQuestion:summary')}
      component={
        <SuccessContent
          title={t('app:pages:myProfile:security:secretQuestion:step2:secretQuestionSuccess')}
          buttonAction={{
            text: t('app:pages:myProfile:security:secretQuestion:step2:backButton'),
            action: () => history.push(Routes.Profile.preference.path),
          }}
        />
      }
    />

  )
}
