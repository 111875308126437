import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonSkin, PaymentCards } from '@neo-commons/components'

import { CardDto, CardStatusDto } from '@afone/neo-core-client/dist/models'

import { ActivateCardPolicy } from '../../../../../Policies/DebitCard/ActivateCardPolicy'

import './CarouselItem.scss'

export interface CarouselItemProps {
  card: CardDto,
  isSelected?: boolean,
  onDisplayNumber: () => void
}

export const CarouselItem: React.FC<CarouselItemProps> = (props) => {
  const {
    card,
    onDisplayNumber,
    isSelected,
  } = props
  const { t } = useTranslation()

  const getTitleButton = (): string => {
    let result = 'app:pages:debitCard:services:showNumbers'

    if (ActivateCardPolicy.canActivate(card)) {
      result = 'app:pages:services:debitCard:activate'
    }
    return t(result)
  }
  return (
    <div className='CarouselItem'>
      {card &&
        <div className='CarouselItem_container-card'>
          <PaymentCards
            selected={isSelected}
            card={card}
            showStatus
          />
        </div>}
      {card.status !== CardStatusDto.DESACTIVATED && isSelected &&
        <div className='CarouselItem_container-button'>
          <Button
            onPress={onDisplayNumber}
            skin={ButtonSkin.DEFAULT}
            flat
            title={getTitleButton()}
          />
        </div>}
    </div>
  )
}
