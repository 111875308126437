import { StyleSheet, Platform } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

export default StyleSheet.create({
  container: {
    display: 'flex',
    maxWidth: 300,
    flexDirection: 'row',
    paddingTop: 0,
    paddingHorizontal: 0,
    marginHorizontal: 0,
    alignItems: 'flex-end',
    alignSelf: 'center',
  },

  prefixInput: {
    flex: 0.13,
    padding: 0,
    marginRight: 10,
    alignSelf: 'flex-start',
    ...Platform.select({
      ios: {
        top: 30,
        paddingBottom: 2,
      },
      android: {
        top: 30,
        paddingBottom: 1,
      },
      default: {
        // other platforms, web for example
        top: 32,
      },
    }),
    borderBottomWidth: 1,
    borderBottomColor: Colors.black,
  },

  phoneNumberInput: {
    flex: 0.87,
    padding: 0,
  },

  prefix: {
    ...Typo.button,
    fontSize: 16,
    color: Colors.black,
    padding: 0,
    margin: 0,
    textAlign: 'center',
    paddingBottom: 3,
  },

  defaultPhoneLabel: {
    position: 'absolute',
    color: Colors.darkGreyText,
  },

  focusedPhoneLabel: {
    position: 'absolute',
    color: Colors.primary,
  },
})
