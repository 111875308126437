import { RoutesInterface } from './Routes'

export class AdvanceEligibilityRoutes {
  public static index: RoutesInterface = {
    path: '/advanceEligibility',
    name: 'neo-commons:eligibility:title',
    available: true,
  }

  public static searchBank: RoutesInterface = {
    path: AdvanceEligibilityRoutes.index.path + '/searchBank',
    name: 'neo-commons:eligibility:connexion',
    available: true,
  }

  public static terms: RoutesInterface = {
    path: AdvanceEligibilityRoutes.index.path + '/terms',
    name: 'neo-commons:eligibility:terms:title',
    available: true,
  }

  public static aggregationFallback: RoutesInterface = {
    path: AdvanceEligibilityRoutes.index.path + '/aggregation/fallback',
    name: 'neo-commons:eligibility:aggregation:success:title',
    available: true,
  }

  public static pending: RoutesInterface = {
    path: AdvanceEligibilityRoutes.index.path + '/pending',
    name: 'neo-commons:eligibility:pending:title',
    available: true,
  }

  public static success: RoutesInterface = {
    path: AdvanceEligibilityRoutes.index.path + '/success',
    name: 'neo-commons:eligibility:success:title',
    available: true,
  }

  public static failure: RoutesInterface = {
    path: AdvanceEligibilityRoutes.index.path + '/failure',
    name: 'neo-commons:eligibility:failure:title',
    available: true,
  }

  public static error: RoutesInterface = {
    path: AdvanceEligibilityRoutes.index.path + '/error',
    name: 'neo-commons:eligibility:error:title',
    available: true,
  }
}
