import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { OidcProvider } from 'redux-oidc'
import { Routes } from 'Routes/Routes'

import { agentClientUserManager } from '../../../../Config/AgentClientUserManager'
import { store } from '../../../../Store'

import { RedirectLogin } from './RedirectLogin'
import { LoginCallback } from './LoginCallback'
import { LogoutCallback } from './LogoutCallback'
import { Unauthorized } from './Unauthorized'

export const AgentClientRegister: React.FC = () => {
  useEffect(() => {
    window.sessionStorage.setItem('source', 'sva')
  }, [])
  return (
    <OidcProvider store={store} userManager={agentClientUserManager}>
      <Switch>
        <Route exact path={Routes.SvaRegisterRoutes.redirectLogin.path} component={RedirectLogin} />
        <Route exact path={Routes.SvaRegisterRoutes.login.path} component={LoginCallback} />
        <Route exact path={Routes.SvaRegisterRoutes.logout.path} component={LogoutCallback} />
        <Route exact path={Routes.SvaRegisterRoutes.unauthorized.path} component={Unauthorized} />

        <Route exact path={Routes.SvaRegisterRoutes.index.path}>
          <Redirect exact to={Routes.SvaRegisterRoutes.redirectLogin.path} />
        </Route>
      </Switch>
    </OidcProvider>
  )
}
