import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import {
  BankTransferBenificiaryTypeEnum,
  BankTransferTypeEnum,
  TransferActions, TransferSelectors,
} from '@neo-commons/store'
import { Typo } from '@neo-commons/styles'
import { RichTypography } from '@neo-commons/components'

import { ContactChannelFormatDto } from '@afone/neo-core-client/dist/models'

import { SuccessContent } from '@neo-web/componentsOld'

export const Result: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const transfer = useSelector((state: StateTypes) => state.transfer)
  const transferDeleteType = useSelector(TransferSelectors.getTransferTypeByUuid(transfer?.list?.selectedId))

  const contactChannel = useSelector(TransferSelectors.getContactChannel(transfer.prepare.contactChannelUuid!),
    (oldState) => oldState !== null)

  const generateTitle = () => {
    let result = ''

    // isDelete allows us to know if we're in the removing transfer tunnel or not
    const transferType = transfer.isDelete ? transferDeleteType : transfer?.prepare?.transferType

    switch (transferType) {
      case BankTransferTypeEnum.Punctual:
        result = 'app:pages:payments:transfer:result:punctual:title'
        break
      case BankTransferTypeEnum.Scheduled:
        if (transfer.isDelete) {
          result = 'app:pages:payments:transfer:result:delete:scheduled:title'
        } else if (transfer.prepare.isUpdating) {
          result = 'app:pages:payments:transfer:result:update:scheduled:title'
        } else {
          result = 'app:pages:payments:transfer:result:scheduled:title'
        }
        break
      case BankTransferTypeEnum.Repeated:
        if (transfer.isDelete) {
          result = 'app:pages:payments:transfer:result:delete:recurrence:title'
        } else if (transfer.prepare.isUpdating) {
          result = 'app:pages:payments:transfer:result:update:recurrence:title'
        } else {
          result = 'app:pages:payments:transfer:result:recurrence:title'
        }
        break
    }
    return result
  }

  const generateSubTitle = () => {
    const result = { data: '', content: { text: '', variable: '' } }

    if (!transfer.isDelete && !transfer?.prepare?.isUpdating) {
      switch (transfer?.prepare?.transferType) {
        case BankTransferTypeEnum.Punctual:
          if (transfer?.prepare?.benificiaryType === BankTransferBenificiaryTypeEnum.Internal) {
            result.content.text = 'neo-commons:transfer:punctual:internal'
          } else if (contactChannel?.channel?.format === ContactChannelFormatDto.IBAN) {
            result.data = contactChannel?.channel?.applicationUser ? `${contactChannel?.contact?.givenName} ${contactChannel?.contact?.familyName}`
              : ''
            result.content.text = contactChannel?.channel?.applicationUser ? 'neo-commons:transfer:punctual:noelse'
              : 'neo-commons:transfer:punctual:notNoelse'
            result.content.variable = contactChannel?.channel?.applicationUser ? ''
              : `${contactChannel?.contact?.givenName} ${contactChannel?.contact?.familyName}`
          } else {
            // case ContactChannelFormatDto.PHONE or ContactChannelFormatDto.EMAIL
            result.data = `${contactChannel?.contact?.givenName} ${contactChannel?.contact?.familyName}`
            result.content.text = contactChannel?.channel?.applicationUser ? 'neo-commons:transfer:punctual:noelse'
              : 'neo-commons:transfer:punctual:commons'
          }
          break
        case BankTransferTypeEnum.Scheduled:
          if (transfer?.prepare?.benificiaryType === BankTransferBenificiaryTypeEnum.Internal) {
            result.content.text = 'neo-commons:transfer:scheduled:internal'
          } else if (contactChannel?.channel?.format === ContactChannelFormatDto.IBAN) {
            result.data = contactChannel?.channel?.applicationUser ? `${contactChannel?.contact?.givenName} ${contactChannel?.contact?.familyName}`
              : ''
            result.content.text = contactChannel?.channel?.applicationUser ? 'neo-commons:transfer:scheduled:noelse'
              : 'neo-commons:transfer:scheduled:notNoelse'
          } else {
            // case ContactChannelFormatDto.PHONE or ContactChannelFormatDto.EMAIL
            result.data = `${contactChannel?.contact?.givenName} ${contactChannel?.contact?.familyName}`
            result.content.text = contactChannel?.channel?.applicationUser ? 'neo-commons:transfer:scheduled:noelse'
              : 'neo-commons:transfer:scheduled:commons'
          }
          break
        case BankTransferTypeEnum.Repeated:
          if (transfer?.prepare?.benificiaryType === BankTransferBenificiaryTypeEnum.Internal) {
            result.content.text = 'neo-commons:transfer:repeat:internal'
          } else if (contactChannel?.channel?.format === ContactChannelFormatDto.IBAN) {
            result.data = contactChannel?.channel?.applicationUser ? `${contactChannel?.contact?.givenName} ${contactChannel?.contact?.familyName}`
              : ''
            result.content.text = contactChannel?.channel?.applicationUser ? 'neo-commons:transfer:repeat:noelse'
              : 'neo-commons:transfer:repeat:notNoelse'
          } else {
            // case ContactChannelFormatDto.PHONE or ContactChannelFormatDto.EMAIL
            result.data = `${contactChannel?.contact?.givenName} ${contactChannel?.contact?.familyName}`
            result.content.text = contactChannel?.channel?.applicationUser ? 'neo-commons:transfer:repeat:noelse'
              : 'neo-commons:transfer:repeat:commons'
          }
          break
      }
    }

    return result
  }

  const subtitle = generateSubTitle()

  useEffect(() => {
    try {
      if (transfer.list.shouldRefresh || !transfer.list.loadedOnce) {
        dispatch(TransferActions.getRecurringTransfers())
      }
    } catch (_) {}
  }, [])

  return (
    <SuccessContent
      title={t(generateTitle())}
      description={!transfer.isDelete && !transfer?.prepare?.isUpdating && (
        <RichTypography
          containerStyle={{ textAlign: 'center', width: 440 }}
          texts={[subtitle.data, t(subtitle.content.text, { name: subtitle.content.variable })]}
          styles={[Typo.bold, Typo.content]}
        />)}
      buttonBack={{
        action: () => {
          dispatch(TransferActions.reset())
          history.push(Routes.Synthesis.index.path)
        },
      }}
      buttonAction={{
        text: t('app:pages:payments:transfer:result:transfer'),
        action: () => history.push(Routes.Payments.bankTransfer.path),
      }}
    />
  )
}
