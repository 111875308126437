import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Image, Pressable, StyleSheet, View } from 'react-native'

import { StateTypes } from '@neo-customer-front/applications/neo-web/src/Store'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { SelectionMode } from '@neo-commons/libraries'
import { BusinessSubscriptionStep, BusinessSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import {
  Button,
  ButtonSkin,
  CountryList,
  Flags,
  Icon,
  IconTypes,
  Stepper2,
  Typography2,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { CountryDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './TaxDomiciliation.scss'

const styles = StyleSheet.create({
  label: {
    display: 'flex',
    position: 'relative',
    width: 'fit-content',
    left: 15,
    top: 28,
    backgroundColor: Colors.white,
    paddingHorizontal: 4,
    color: Colors.gray2,
    marginTop: -24,
  },
  country: {
    marginRight: 8,
    paddingHorizontal: 16,
    paddingTop: 10,
    paddingBottom: 8,
    borderWidth: 1,
    borderColor: '#AAAAAA',
    borderRadius: 12,
  },
})
type TaxDomiciliationProps = StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.TaxDomiciliation]>

export const TaxDomiciliation: React.FC<TaxDomiciliationProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const countries =
    useSelector((state: StateTypes) => state.config.data.currentConfig.countries ?? [])
  const [selectedCountry, setSelectedCountry] = useState<CountryDto>()
  const [isOpen, setIsOpen] = useState(false)

  const userState = useSelector((state: StateTypes) => state.user.data)
  const flag = Flags[selectedCountry?.isoCodeAlpha3 ?? 'Unknown'] ?? Flags.Unknown

  const onPrepareClient = (country: CountryDto) => {
    (async () => {
      try {
        nextStep({ taxDomiciliationCountryCode: country.isoCodeAlpha2, taxUsPayer: false }).onDone(() => {
          AnalyticsHandler.track(
            AnalyticsEvents.signUp.userCountrySelected,
            { residence_country_code: country.isoCodeAlpha2 }
          )
          AnalyticsHandler.identify(
            userState ? userState.uuid : null,
            { first_account_created_residence_country_code: country.isoCodeAlpha2 }
          )
        })
      } catch (error) {}
    })()
  }

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      scrollableBody
      header={
        <div className='TaxDomiciliation_header'>
          <Typography2 typeface='h3'>
            {t('neo-commons:subscription:business:taxDomiciliation:title')}
          </Typography2>
          <Stepper2 currentStep={0} />
          <div className='TaxResidence_header_title'>
            <Typography2 typeface='h4'>
              {t('neo-commons:subscription:business:taxDomiciliation:titleMessage')}
            </Typography2>
          </div>
        </div>
      }
      footer={
        selectedCountry && (
          <Button
            skin={ButtonSkin.PRIMARY}
            flat
            title={t('app:global:validate')}
            onPress={() => onPrepareClient(selectedCountry)}
          />)
      }
    >
      <div className='TaxDomiciliation_container'>
        <div className='TaxDomiciliation_container-custom-dropdown'>
          <Pressable
            onPress={() => setIsOpen(!isOpen)}
            style={styles.country}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {
                selectedCountry &&
                  <Image
                    style={{ width: 20, height: 20, marginTop: 2, marginRight: 8 }}
                    source={flag}
                  />
              }
              <Typography2
                typeface='body1'
                style={selectedCountry ? {} : { color: Colors.lightGray }}
              >
                {selectedCountry ? selectedCountry.name : t('neo-commons:global:select')}
              </Typography2>
              <View
                style={{ marginLeft: 'auto' }}
              >
                <Icon
                  color={Colors.gray2}
                  name='chevron-down'
                  type={IconTypes.ENTYPO}
                  size={24}
                />
              </View>
            </div>
          </Pressable>
          {isOpen &&
            <div
              className='custom-dropdown'
            >
              <CountryList
                countries={countries}
                selectionMode={SelectionMode.PRO_TAX_DOMICILIATION}
                hidePrefix
                onCountrySelectedAction={(country) => {
                  setIsOpen(false)
                  setSelectedCountry(country)
                }}
              />
            </div>}
        </div>
      </div>
    </TunnelContent>
  )
}
