import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { Colors } from '@neo-commons/styles'
import { Icon, IconTypes } from '@neo-commons/components'
import { PosActions, PosSelectors, SubscriptionSelectors } from '@neo-commons/store'
import { POSUtils } from '@neo-commons/libraries'

import {
  PosReturnReasonDto,
  PosReturnDetailDto,
  PosDto,
  PosStatusDto,
  SubscriptionDto,
} from '@afone/neo-core-client/dist/models'

import { CardMenuItem, CardMenuList, JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

export const ManagePos: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const configState = useSelector((state: StateTypes) => state.config)

  const pos: PosDto[] = useSelector(PosSelectors.listPos())
  const subscriptions: SubscriptionDto[] = useSelector(SubscriptionSelectors.list)
  const posList = POSUtils.filterPosByStatus(pos, subscriptions, PosStatusDto.ACTIVE)
  const returns: PosReturnDetailDto[] = useSelector(PosSelectors.listReturns) ?? []

  useEffect(() => {
    (async () => {
      try {
        await dispatch(PosActions.listReturns())
      } catch (error) {}
    })()
  }, [])

  const menuCards: CardMenuItem[] = [
    {
      title: t('app:pages:services:pos:managePos:reassign:title'),
      onPress: () => history.push(Routes.Services.reassignPos.path),
    }, {
      title: t('app:pages:services:pos:managePos:reassign:defective'),
      disabled: !posList.length,
      onPress: () => (async () => {
        await dispatch(PosActions.prepareReturn({ returnReason: PosReturnReasonDto.FAILURE }))
        history.push(Routes.Services.PosReturnRoutes.index.path)
      })(),
    }, {
      title: t('app:pages:services:pos:managePos:reassign:lost'),
      disabled: !posList.length,
      onPress: () => history.push(Routes.Services.PosLostRoutes.index.path),
    }, {
      title: t('app:pages:services:pos:managePos:reassign:return'),
      disabled: !posList.length,
      onPress: () => (async () => {
        await dispatch(PosActions.prepareReturn({ returnReason: PosReturnReasonDto.TERMINATION }))
        history.push(Routes.Services.PosReturnRoutes.index.path)
      })(),
    }, {
      title: t('app:pages:services:pos:managePos:reassign:returnedPos'),
      disabled: !returns.length,
      onPress: () => history.push(Routes.Services.returnedList.path),
    }, {
      title: t('app:pages:services:pos:managePos:reassign:endService'),
      disabled: true,
      onPress: () => null,
    },
  ]

  const assistanceCard: CardMenuItem[] = [
    {
      icon: <Icon name='help-circle' color={Colors.primary} size={24} type={IconTypes.FEATHER} />,
      title: t('app:pages:services:pos:managePos:assistance:title'),
      onPress: () => {
        const faq = t('app:pages:services:pos:managePos:assistance:link') ?? configState?.data?.currentConfig?.faq
        window.open(faq, '_blank')
      },
    },
  ]

  return (
    <PageDetails
      title={t('app:pages:services:pos:managePos:title')}
      onBack={() => history.push(Routes.Services.pos.path)}
      component={(
        <PageContent justifyContent={JustifyContent.START}>
          <CardMenuList
            menus={menuCards}
          />
          <CardMenuList
            title={t('app:pages:services:pos:managePos:assistance:cardMenuTitle')}
            menus={assistanceCard}
          />
        </PageContent>
      )}
    />
  )
}
