import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { AppConfig } from 'Config/AppConfig'

import { ResetPasscodeStep, ResetPasscodeSteps, StepComponentProps } from '@neo-commons/policies'
import { SignupActions } from '@neo-commons/store'
import { PasswordKeypadData, usePasswordKeypad } from '@neo-commons/hooks'

import { KeypadFieldDto } from '@afone/neo-core-client/dist/models'

import { CodeConfirm } from '@neo-web/componentsOld'

type NewCodeProps = StepComponentProps<ResetPasscodeSteps[ResetPasscodeStep.NewCode]>

export const NewCode: React.FC<NewCodeProps> = (props:NewCodeProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const recoverPassword = useSelector((state: StateTypes) => state?.recoverPassCode)
  const signInData = useSelector((state: StateTypes) => state?.signIn?.data)
  const passwordKeypadData = usePasswordKeypad({
    phone: signInData?.phone,
    phoneCountryCode: signInData?.countryCode,
    field: KeypadFieldDto.PASSWORD,
    passwordSize: AppConfig.PASS_CODE_SIZE,
  })

  const onValidate = (passwordKeypadData: PasswordKeypadData) => {
    (async () => {
      try {
        const code = passwordKeypadData.password.map(e => e.value)
        await dispatch(SignupActions.passwordCheck({ password: code }))

        props.nextStep({
          ...recoverPassword,
          newPassword: code,
        })
      } catch (error) {
        passwordKeypadData.reset(error)
      }
    })()
  }

  return (
    <div className='PreviousCode_container'>
      <div className='PreviousCode_item'>
        <CodeConfirm
          title={t('app:pages:myProfile:codeReset:newCode:title')}
          onValidate={onValidate}
          keypadData={passwordKeypadData}
        />
      </div>
    </div>
  )
}
