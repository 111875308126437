import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Panel, PanelGroup } from 'rsuite'
import dayjs from 'dayjs'

import { Icon, IconTypes, Typography } from '@neo-commons/components'
import { DeviceActions, DeviceSelectors } from '@neo-commons/store'

import { DeviceDto, OsTypeDto } from '@afone/neo-core-client/dist/models'

import { DeviceActivity } from './DeviceActivity/DeviceActivity'

import './DevicesList.scss'

interface DevicesListProps {
  devices: DeviceDto[]
}

export const DevicesList: React.FC<DevicesListProps> = (
  props: DevicesListProps
) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const deviceSelected = useSelector(DeviceSelectors.selected)

  return (
    <PanelGroup
      accordion
      className='DevicesList'
      onSelect={(eventKey: string) => {
        if (deviceSelected !== '' && eventKey === deviceSelected?.uuid) {
          dispatch(DeviceActions.setSelected(''))
        } else {
          dispatch(DeviceActions.setSelected(eventKey))
        }
      }}
      activeKey={deviceSelected?.uuid ?? ''}
    >
      {props.devices.map((device, index) =>
        <Panel
          className='classic-box box-shadow'
          header={
            <>
              <span className='DevicesList_icon'>
                <Icon
                  type={IconTypes.FEATHER}
                  name={device.os === OsTypeDto.WEB ? 'monitor' : 'smartphone'}
                  size={25}
                />
              </span>
              <span className='DevicesList_title'>
                <Typography typeface='bold'>
                  {device.name}
                </Typography>
              </span>
              <br />
              <span className='DevicesList_subTitle'>
                {t('app:components:devicesList:lastLogin')}
                {dayjs(device.lastLoginDate).fromNow()}
              </span>
            </>
          }
          key={index}
          eventKey={device.uuid}
        >
          {deviceSelected !== '' && deviceSelected?.uuid === device.uuid &&
            <DeviceActivity deviceUuid={deviceSelected.uuid} />}
        </Panel>
      )}
    </PanelGroup>
  )
}
