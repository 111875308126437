
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'

import {
  Button,
  ButtonSkin,
  Input,
} from '@neo-commons/components'
import { Formatters, ValidatorResponseType, Validators } from '@neo-commons/libraries'

import { ContactChannelDto } from '@afone/neo-core-client/dist/models'

export interface CreateCanalContentProps {
  channel?: ContactChannelDto,
  save: (contact: ContactChannelDto, indexEdit?: number) => void,
  remove?: (indexEdit: number) => void,
  indexEdit?: number,
  disableDelete?: boolean
}

export const CreateCanalContent: React.FC<CreateCanalContentProps> = (
  { channel, save, remove, indexEdit, disableDelete }) => {
  const { t } = useTranslation()

  const [name, setName] = useState(channel?.label ?? '')
  const [value, setValue] = useState(channel?.value ?? '')
  const [isValueCorrect, setIsValueCorrect] = useState(false)

  return (
    <>
      <FlexboxGrid.Item colspan={24}>
        <Input
          label={t('app:pages:payments:contact:create:form:channel:name')}
          value={name}
          onChangeText={(value) => setName(value)}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24}>
        <Input
          isRequired
          hasClear
          label={t('app:pages:payments:contact:create:form:channel:value')}
          onChangeText={(val: string) => setValue(val)}
          validators={[Validators.isCorrectIban]}
          onValidationChecked={(value: ValidatorResponseType) => {
            setIsValueCorrect(value.isValid)
          }}
          format={Formatters.formatIban}
          value={value}
          clearButtonMode='while-editing'
          blurOnSubmit
        />
      </FlexboxGrid.Item>
      {(channel && !disableDelete) &&
        <FlexboxGrid.Item colspan={24} className='EditModal_saveButton'>
          <Button
            skin={ButtonSkin.OUTLINE_SECONDARY}
            title={t('app:pages:payments:contact:create:form:channel:delete')}
            onPress={() => { remove(indexEdit) }}
          />
        </FlexboxGrid.Item>}
      <FlexboxGrid.Item colspan={24} className='EditModal_saveButton'>
        <Button
          skin={ButtonSkin.PRIMARY}
          disabled={!name || !isValueCorrect}
          title={t('app:global:save')}
          onPress={() => { (name && isValueCorrect) && save({ ...channel, label: name, value: value }, indexEdit) }}
        />
      </FlexboxGrid.Item>
    </>
  )
}
