import React, { useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { FlexboxGrid } from 'rsuite'
import { Routes } from 'Routes/Routes'
import i18n from 'i18next'
import dayjs from 'dayjs'

import { Button, ButtonSkin } from '@neo-commons/components'
import { ConfigActions, UserActions } from '@neo-commons/store'
import { NeobankApi } from '@neo-commons/services'

import { useResponsive } from '@neo-web/hooks'
import { PageDetails, RSSelect } from '@neo-web/componentsOld'

import { StateTypes } from '../../../../../Store'
import { resources } from '../../../../../I18n'

import '../PreferenceContact/PreferenceContact.scss'
import './Language.scss'

export const Language = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isTabletOrMobile = useResponsive()
  const userState = useSelector((state: StateTypes) => state.user.data)
  const [languageSelected, setLanguageSelected] = useState(userState?.locale)

  const changeLanguage = async () => {
    try {
      await dispatch(UserActions.updateUser({
        locale: languageSelected,
      }))
      NeobankApi.getInstance().setLanguage(languageSelected)
      i18n.changeLanguage(languageSelected)
      dayjs.locale(languageSelected.split('_')[0])
      await dispatch(ConfigActions.getConfig())
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <PageDetails
      component={
        <FlexboxGrid className='Language' justify='center' align='middle'>
          <div className='Language_section-container'>
            <div className={!isTabletOrMobile ? 'classic-box' : ''}>
              <FlexboxGrid.Item colspan={24}>
                <RSSelect
                  data={Object.entries(resources).map(value =>
                    ({
                      label: t(value[1].label),
                      value: value[1].value,
                    })
                  )}
                  onChange={value => {
                    setLanguageSelected(value)
                  }}
                  appearance='subtle'
                  defaultValue={languageSelected}
                  cleanable={false}
                  searchable={false}
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={24} className='Language_item'>
                <Button
                  skin={ButtonSkin.PRIMARY}
                  disabled={!languageSelected}
                  title={t('app:global:validate')}
                  onPress={() => { changeLanguage() }}
                />
              </FlexboxGrid.Item>
            </div>
          </div>
        </FlexboxGrid>
      }
      onBack={() => history.push(Routes.Profile.preference.path)}
      title={t('app:pages:myProfile:preferenceAndSecurity:language:title')}
    />
  )
}

export default withTranslation()(Language)
