import { StyleSheet } from 'react-native'

import { Colors, Commons, Spacing, Typo } from '@neo-commons/styles'

const styles = StyleSheet.create({

  container: {
    flex: 1,
    backgroundColor: Colors.white,
    borderRadius: 15,
    overflow: 'hidden',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 16,
    ...Commons.shadow,
  },

  centerItems: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  title: {
    color: Colors.black,
    marginBottom: 12,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  subTitle: {
    color: Colors.black,
  },

  deliveryContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },

  centerTitle: {
    textAlign: 'center',
  },

  ctnText: {
    padding: 16,
    alignSelf: 'center',
    maxWidth: 250,
  },

  fees: {
    marginVertical: 8,
    width: '100%',
  },

  subLabel: {
    ...Typo.infoRegular,
    marginTop: 8,
  },

  amount: {
    ...Typo.infoRegular,
    fontSize: 55,
  },

  containerBtn: {
    marginRight: 16,
  },

  btnStyle: {
    ...Typo.button,
    textAlign: 'center',
    color: Colors.black,

    borderRadius: Spacing.button.radius,
    backgroundColor: Colors.bgGray,
    borderColor: Colors.bgGray,
    borderWidth: Spacing.button.borderWidth,
    elevation: Spacing.android.clickableElevation,
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginTop: 16,
  },

  buttonArea: {
    marginVertical: 12,
    marginTop: 12,
    marginBottom: 8,
  },

  deliveryAmount: {
    paddingLeft: 12,
  },

  defaultTextArea: {
    flex: 1,
    textAlign: 'left',
  },

  custom: {
    width: '100%',
    paddingHorizontal: 20,
  },

  customTextDigital: {
    width: '100%',
    paddingHorizontal: 20,
    textAlign: 'center',
  },

  customDigital: {
    width: '285px',
    paddingHorizontal: 5,
  },
})
export default styles
