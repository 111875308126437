import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { SidenavMenu, NavbarMenu } from '@neo-web/componentsOld'

import './PrimaryMenu.scss'

interface PrimaryMenuProps {
  onClose: () => void
  isOpen: boolean
  isTabletOrMobile: boolean
}

export const PrimaryMenu: React.FC<PrimaryMenuProps> = (props: PrimaryMenuProps) => {
  const history = useHistory()

  const toggleMenu = (isOpen: boolean) => {
    history.listen(() => {
      if (isOpen) {
        props.onClose()
      }
    })
  }

  useEffect(() => {
    toggleMenu(props.isOpen)
  }, [props.isOpen])

  return (
    <div className={`PrimaryMenu_container ${props.isOpen ? '' : 'PrimaryMenu_container_closed'}`}>
      {props.isTabletOrMobile ? (
        <SidenavMenu isOpen={props.isOpen} onClose={props.onClose} />
      ) : (
        <NavbarMenu />
      )}
    </div>
  )
}
