import dayjs from 'dayjs'
import i18next from 'i18next'
/**
   * Standardize utilisation of dates and dayjs.
   *
   */
export class DateUtils {
  /**
   * Get the next days.
   *
   * @returns {Date} Return the next day.
   * @example
   * minDate={DateUtils.getTomorrow()}
   *
   */
  public static getTomorrow (): Date {
    return this.getDateAfterAddDays()
  }

  /**
   * Add some day to the given day.
   *
   * @param {Date} startDate - date of start
   * @param {number} n - day to add
   * @returns {Date} Return the day with n.
   * @example
   * minDate={DateUtils.getDateAfterAddDays()}
   *
   */
  public static getDateAfterAddDays (startDate = this.getNow(), n = 1): Date {
    const currentDate = this.getNow()
    return new Date(currentDate.setDate(startDate.getDate() + n))
  }

  /**
   * Get the today date.
   *
   * @returns {Date} Return the today day.
   * @example
   * startDate: isScheduled ? statScreen.startDate : DateUtils.getNow()
   *
   */
  public static getNow (): Date {
    return new Date()
  }

  /**
   * Transforme day to string for display.
   *
   * @param {Date} objDate - date to transform, default undefined
   * @returns {string} Return the day for display.
   * @example
   * DateUtils.objDateToHumanDate(repeatedTransferEntitie?.nextDate ?? new Date())
   *
   */
  public static objDateToHumanDate (objDate: Date | undefined): string {
    if (!dayjs(objDate).isValid()) {
      return '-'
    }

    return dayjs(objDate).format(i18next.t('commons:dateFormat'))
  }

  /**
   * Transforme Iso8601 to string for display.
   *
   * @param {string} dateString - Iso8601 to transform, default undefined
   * @returns {string} Return the day for display.
   * @example
   * DateUtils.Iso8601ToHumanDate(onGoingTransfer?.transfer?.scheduledDate)
   *
   */
  public static Iso8601ToHumanDate (dateString: string | undefined): string {
    if (!dayjs(dayjs(dateString).toISOString()).isValid()) {
      return '-'
    }

    return dayjs(dateString).format(i18next.t('commons:dateFormat'))
  }

  /**
   * Transforme Iso8601 to Date object.
   *
   * @param {string} dateString - Iso8601 to transform
   * @returns {Date} Return the day in Date object.
   *
   */
  public static Iso8601ToObjDate (dateString: string): Date {
    if (typeof (dateString) !== 'string' || !dayjs(dayjs(dateString).toISOString()).isValid()) {
      return this.getDefaultDate()
    }

    return new Date(dateString)
  }

  /**
   * Get the default date.
   *
   * @returns {Date} Return the default date.
   * @example
   * startDate: item?.startDate ?? DateUtils.getDefaultDate()
   *
   */
  public static getDefaultDate (): Date {
    return new Date('1970')
  }

  /**
   * Get a boolean if the date is passed or not.
   *
   * @param {string} dateString - date to check
   * @returns {boolean} Date is passed or not.
   *
   */
  public static checkPassedDate (dateString: string): boolean {
    if (dateString) {
      return dayjs(dateString).isAfter(dayjs())
    }
    return false
  }

  /*
   * Convert date to OpenAPI's date-time
   */
  public static toDateTime (date: string): string {
    return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ')
  }

  /*
   * Get diff of minutes with second
   */
  public static getTimeRemaining (now, to: string, format = 'mm:ss') {
    const duration = dayjs(to).diff(dayjs(now))
    return dayjs.duration(duration).format(format)
  }

  /*
   * Convert date to specified format
   */
  public static getDateToFormat (dateFormat: string, date?: string | Date): string {
    if (!date) date = this.getNow()
    return dayjs(date).format(dateFormat)
  }

  /*
   * Get date less the amount of months specified
   */
  public static getDateSubtractMonths (date: string | Date, nbMonths: number, dateFormat?: string): string {
    if (!dateFormat) dateFormat = 'YYYY-MM-DD'
    return dayjs(date).subtract(nbMonths, 'months').format(dateFormat)
  }

  /*
   * Get date less the amount of days/months/years specified
   */
  public static getDateSubtract (nb: number, unit: dayjs.ManipulateType, date: string | Date, dateFormat?: string): string {
    if (!dateFormat) dateFormat = 'YYYY-MM-DD'
    return dayjs(date).subtract(nb, unit).format(dateFormat)
  }

  /**
   * Get month number from month name
   */
  public static getMonthNumber (monthName: string): number{
    const months = dayjs.months()
    const index = months.findIndex(month => month.toLowerCase() === monthName.toLowerCase())
    return index !== -1 ? index : 0;
  }
}
