
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { MenuItemCard, Typography } from '@neo-commons/components'
import { CardActions, ClientSelectors, PrepareCardOrder } from '@neo-commons/store'
import { CreditCardUtils } from '@neo-commons/libraries'

import { ClientDto } from '@afone/neo-core-client/dist/models'

import './OrderNewCard.scss'

export const OrderNewCard: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const cardOwnerUuid: string|undefined = useMemo(() => CreditCardUtils.getDefaultCardOwnerUuid(client), undefined)

  return (
    <div className='orderNewCard'>
      <Typography typeface='bold' style={{ textAlign: 'center' }}>
        {t('app:pages:services:debitCard:opposition:oppositionSummary:doYouWantToOrderANewCard')}
      </Typography>
      <div className='orderNewCard-buttons'>
        <MenuItemCard
          disabled
          containerStyle={{ marginHorizontal: 20, marginVertical: 20 }}
          title={t('app:pages:services:debitCard:opposition:oppositionSummary:orderCardUrgently')}
          subtitle={t('app:pages:services:debitCard:opposition:oppositionSummary:receiveYourCardWithin24hours')}
          chevronPosition='center'
        />
        <MenuItemCard
          containerStyle={{ marginHorizontal: 20, marginVertical: 20 }}
          title={t('app:pages:services:debitCard:opposition:oppositionSummary:orderNewCard')}
          subtitle={t('app:pages:services:debitCard:opposition:oppositionSummary:startingAt') + ' 00,00€'}
          chevronPosition='center'
          onPress={() => {
            if (cardOwnerUuid) {
              history.push(Routes.Services.choicesDebitCard.path)
              dispatch(CardActions.prepare({ personUuid: cardOwnerUuid } as PrepareCardOrder))
            } else {
              dispatch(CardActions.prepare({} as PrepareCardOrder))
              history.push(Routes.Services.order.path)
            }
          }}
        />
      </div>
    </div>
  )
}
