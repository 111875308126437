import React, { ReactElement } from 'react'

import { Icon, Typography } from '@neo-commons/components'

import { CardDetails } from '@neo-web/componentsOld'

import './CardInfo.scss'

interface CardInfoProps {
  icon: ReactElement<typeof Icon>
  title?: string,
  text?: string,
  cardTitle?: string,
}

export const CardInfo: React.FC<CardInfoProps> = (props) => {
  return (
    <CardDetails title={props?.cardTitle}>
      <div className='CardInfo'>
        <div className='CardInfo_icon'>
          {props.icon}
        </div>
        <div className='CardInfo_content'>
          {props?.title &&
            <Typography typeface='bold'>
              {props.title}
            </Typography>}
          {props?.text &&
            <Typography typeface='content'>
              {props.text}
            </Typography>}
        </div>
      </div>
    </CardDetails>
  )
}
