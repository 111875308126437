import { AnyAction, Reducer } from 'redux'
import { NeobankApi } from '@neo-commons/services'
import { DocumentDto, FileDto } from '@afone/neo-core-client/dist/models'
import i18next from 'i18next'

import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { initialResourceState, State } from '../utils'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Document = DocumentDto & FileDto

const {
  resourceActionTypes: DocumentActionTypes,
  resourceReducer: DocumentResourceReducer,
  resourceAction: DocumentAction,
  resourceSelector: DocumentResourceSelector,
} = ResourceStateFactory<Document, 'document'>(state => state.document, 'document')

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

export const DocumentTypes = {
  ...DocumentActionTypes,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

export const DocumentSelectors = {
  ...DocumentResourceSelector,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */
const errorKeyTranslate = 'errors:unknownTechnicalIssue'

const getDocumentByUuid = function (documentUuid: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DocumentTypes.BYID_DOCUMENT_REQUEST })

    try {
      const documentResponse = await NeobankApi.getInstance().documentApi.getDocumentByUuid(documentUuid)
      const documentContentResponse = await NeobankApi.getInstance().documentApi.getDocumentContentByUuid(documentUuid)

      dispatch({ type: DocumentTypes.BYID_DOCUMENT_SUCCESS, data: { ...documentResponse.data, base64: documentContentResponse.data } })
    } catch (error) {
      const errorMessage = error.message ?? errorKeyTranslate
      dispatch({ type: DocumentTypes.BYID_DOCUMENT_FAILURE, errorMessage })
    }
  }
}

const getContractsByPersonAndDocumentUuid = function (personUuid: string, documentUuid: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DocumentTypes.BYID_DOCUMENT_REQUEST })

    try {
      const documentContentResponse = await NeobankApi.getInstance().personApi.getKycPageContentByUuid(personUuid, documentUuid)

      dispatch({ type: DocumentTypes.BYID_DOCUMENT_SUCCESS, data: { uuid: documentUuid, base64: documentContentResponse.data } })
    } catch (error) {
      const errorMessage = error.message ?? errorKeyTranslate
      dispatch({ type: DocumentTypes.BYID_DOCUMENT_FAILURE, errorMessage })
    }
  }
}

const getTransactionProofById = (id: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: DocumentTypes.BYID_DOCUMENT_REQUEST })

    try {
      const bankAccountUuid = getState().bankAccount.list!.selectedId
      if (!bankAccountUuid) throw new Error()

      const getTransactionReponse =
        await NeobankApi.getInstance().transactionApi.getAccountTransactionProofById(bankAccountUuid, id)

      dispatch({ type: DocumentTypes.BYID_DOCUMENT_SUCCESS, data: { ...getTransactionReponse.data, uuid: id } })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({
        type: DocumentTypes.BYID_DOCUMENT_FAILURE,
        errorMessage,
      })
    }
  }
}

export const DocumentActions = {
  ...DocumentAction,
  getDocumentByUuid,
  getContractsByPersonAndDocumentUuid,
  getTransactionProofById,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type DocumentState = ResourceState<Document>

const initialState: DocumentState = {
  ...initialResourceState,
  list: {
    ...initialResourceState.list,
    filter: {
      userUuid: undefined,
      iban: undefined,
      name: undefined,
    },
  },
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const document: Reducer = (state = initialState, action: AnyAction): DocumentState => {
  switch (action.type) {
    default:
      return {
        ...DocumentResourceReducer(state, action, {
          identifier: 'uuid',
          isPaginate: true,
          initialState,
        }),
      }
  }
}
