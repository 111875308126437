import React from 'react'

import { Drawer, DrawerProps as DrawerPropsRs } from 'rsuite'

import './PageModal.scss'

export interface PageModalProps extends DrawerPropsRs {
  show: boolean
  children: React.ReactNode
}

export const PageModal: React.FC<PageModalProps> = ({
  show,
  children,
  ...props
}) => {
  return (
    <Drawer {...props} className='PageModal' placement='right' size='lg' open={show} backdrop>
      {children}
    </Drawer>
  )
}
