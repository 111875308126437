import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { StateTypes } from 'Store'

import {
  BankAccountCard,
  BankAccountCardSkin,
  Button,
  ButtonSkin,
  Typography2,
} from '@neo-commons/components'
import {
  BankAccountSelectors,
  ClientSelectors,

} from '@neo-commons/store'
import { CloseProjectStep, CloseProjectSteps, StepComponentProps } from '@neo-commons/policies'

import {
  AccountDto, ClientDto,
} from '@afone/neo-core-client/dist/models'

import {
  JustifyContent,
  PageContent,
} from '@neo-web/componentsOld'

import circleArrowDownIcon from '../../../../../Resources/Images/site/circle-arrow-down.svg'

type CloseProjectTransferProps = StepComponentProps<CloseProjectSteps[CloseProjectStep.Transfer]>

export const Transfer: React.FC<CloseProjectTransferProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const selectedAccount: AccountDto = useSelector((state: StateTypes) => state.bankAccount.selectedBankAccountToClose)
  const selectedMain: AccountDto = useSelector(BankAccountSelectors.selectedMain)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)

  return (
    <PageContent
      justifyContent={JustifyContent.BETWEEN}
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:confirm')}
          onPress={nextStep}
        />
      }
    >
      <div className='CloseProjectList_subtitle'>
        <Typography2 typeface='h2'>{t('app:pages:services:closeProject:transfer:title')}</Typography2>
      </div>
      <div style={{ position: 'relative' }}>
        <BankAccountCard
          bankAccount={selectedAccount}
          client={client}
          skin={BankAccountCardSkin.SECONDARY}
        />
        <img
          style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 1, transform: 'translate(-50%, -50%)' }}
          src={circleArrowDownIcon}
          alt='icon-circle-arrow-down'
          height={40}
        />
        <BankAccountCard
          bankAccount={selectedMain}
          client={client}
          skin={BankAccountCardSkin.DEFAULT}
        />
      </div>
    </PageContent>
  )
}
