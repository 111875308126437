import React, { useState } from 'react'
import { Image } from 'react-native'
import { useTranslation } from 'react-i18next'

import { PosProductData, POSUtils } from '@neo-commons/libraries'
import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  Typography,
} from '@neo-commons/components'

import { ProductDto } from '@afone/neo-core-client/dist/models'

import { PosModelDetail } from './PosModelDetail'

import './PosModelCard.scss'

interface SelectionCardProps {
  product: ProductDto,
  quantity: number,
  setQuantity: (ope: string, pro: ProductDto) => void,
  maxQuantityReached: boolean,
}

export const PosModelCard: React.FC<SelectionCardProps> = (props: SelectionCardProps) => {
  const { product, quantity, setQuantity, maxQuantityReached } = props
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const deposit = POSUtils.getPosProductDescription(product).header?.[0]

  return (
    <>
      <PosModelDetail
        display={isOpen}
        close={() => {
          setIsOpen(false)
        }}
        product={product}
      />
      <div className='PosModelCard classic-box box-border'>
        <div className='PosModelCard_image'>
          <Image
            source={{ uri: (product.data as PosProductData).image }}
            style={{ height: '100%' }}
          />
        </div>
        <div className='PosModelCard_content'>
          {product.name &&
            <Typography typeface='bold'>
              {product.name}
            </Typography>}
          {deposit &&
            <>
              <br />
              <Typography typeface='subtitle'>
                <b>{deposit?.title}</b> {deposit?.description}
              </Typography>
            </>}
          <div className='PosModelCard_counter'>
            <Button
              small
              overrideStyleButton={{ paddingHorizontal: 11 }}
              title={<Icon name='minus' type={IconTypes.ANT_DESIGN} size={16} />}
              disabled={quantity === 0}
              onPress={() => setQuantity('sub', product)}
              shouldDeactivateOnPressDebounce
            />
            <span className='PosModelCard_counter_num'>
              <Typography typeface='bold'>{quantity}</Typography>
            </span>
            <Button
              small
              overrideStyleButton={{ paddingHorizontal: 11 }}
              title={<Icon name='plus' type={IconTypes.ANT_DESIGN} size={16} />}
              disabled={maxQuantityReached}
              onPress={() => setQuantity('add', product)}
              shouldDeactivateOnPressDebounce
            />
          </div>
          <Button
            small
            title={t('app:pages:services:pos:order:posModelDetail')}
            skin={ButtonSkin.LINK}
            onPress={() => setIsOpen(true)}
          />
        </div>
      </div>
    </>
  )
}
