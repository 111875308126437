import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { FailureContent } from '@neo-web/componentsOld'

interface CreditFailureProps {
  message: string;
}

export const CreditFailure: React.FC<CreditFailureProps> = (props: CreditFailureProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <FailureContent
      title={t('app:pages:credit:failure:title:failed')}
      description={props?.message}
      buttonBack={{ action: () => history.push(Routes.Synthesis.index.path) }}
      buttonAction={{ action: () => history.push(Routes.Credit.creditChoices.path) }}
    />
  )
}
