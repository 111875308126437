import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { ContactActions, ContactSelectors } from '@neo-commons/store'

import { ContactDto } from '@afone/neo-core-client/dist/models'

import { PageDetails } from '@neo-web/componentsOld'

import { Create } from './Create'
import './Action.scss'

export const Action: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const param: any = useParams()

  const contact: ContactDto = useSelector(ContactSelectors.byId(param.id)) ?? null

  useEffect(() => {
    (async () => {
      try {
        if (param.id) {
          await dispatch(ContactActions.getContactByUuid(param.id))
        }
      } catch (e) {
        console.error('failed to get contact')
      }
    })()
  }, [dispatch, param.id])

  return (
    <>
      <Route
        path={Routes.Payments.contactAction.path} component={() => (
          <PageDetails
            title={t('app:pages:payments:contact:create:title')}
            onBack={() => history.push(Routes.Payments.contactHome.path)}
            component={<Create contact={contact} onCreate={() => history.push(Routes.Payments.contactHome.path)} />}
          />
        )}
      />
      {contact &&
        <Route
          path={Routes.Payments.contactUpdate.path} component={() => (
            <PageDetails
              title={`${contact.givenName} ${contact.familyName}`}
              onBack={() => history.push(Routes.Payments.contactHome.path)}
              component={<Create contact={contact} onCreate={() => history.push(Routes.Payments.contactHome.path)} />}
            />
          )}
        />}
    </>
  )
}
