import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { Responsive } from 'Config/Responsive'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import './PageDetails.scss'

export interface PageDetailsProps {
  component: React.ReactNode;
  title?: string;
  onClose?: () => void;
  onBack?: (() => void) | boolean;
  subtitle?: string;
  headerIcon?: string | React.ReactElement<typeof Icon>;
}

export const PageDetails: React.FC<PageDetailsProps> = (props: PageDetailsProps) => {
  const history = useHistory()
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.WITH_TABLET}px)` })
  const { headerIcon } = props

  const displayBack = useMemo(() => typeof props.onBack === 'function' || props.onBack === undefined, [props.onBack])

  const onBack = () => {
    if (props.onBack === undefined) {
      history.goBack()
    } else if (typeof props.onBack === 'function') {
      props.onBack()
    }
  }

  const iconBack = (
    <Icon
      type={IconTypes.FEATHER}
      name='arrow-left'
      size={25}
      onPress={onBack}
    />
  )
  const iconClose = (
    <Icon
      type={IconTypes.FEATHER}
      name='x'
      onPress={props.onClose}
      size={25}
    />
  )
  const getHeaderIcon = () => {
    if (!headerIcon) return

    return (typeof headerIcon === 'string' ? <Icon type={IconTypes.NEOFONT} name={headerIcon} size={32} color={Colors.black} /> : headerIcon)
  }

  return (
    <div className='PageDetails'>
      <div className='PageDetails_header'>
        <div className='PageDetails_header_icon'>
          {displayBack
            ? !isTabletOrMobile ? (
              <Button
                skin={ButtonSkin.DEFAULT}
                flat
                small
                title={iconBack}
                onPress={onBack}
              />
            ) : iconBack : ''}
        </div>
        <div className='PageDetails_title'>
          {props.title &&
            <div className='PageDetails_titleContent'>
              {getHeaderIcon()}
              <Typography typeface='titleH4'>
                {props.title}
              </Typography>
            </div>}

          {props.subtitle &&
            <Typography typeface='subtitle'>
              {props.subtitle}
            </Typography>}
        </div>
        <div className='PageDetails_header_icon'>
          {props.onClose && (
            !isTabletOrMobile ? (
              <Button
                skin={ButtonSkin.DEFAULT}
                small
                flat
                title={iconClose}
                onPress={props.onClose}
              />
            ) : iconClose
          )}
        </div>
      </div>
      <div className='PageDetails_body-container'>
        {props.component}
      </div>
    </div>
  )
}
