import { Reducer, AnyAction } from 'redux'
import i18next from 'i18next'
import { NeobankApi } from '@neo-commons/services'
import {
  LoginInfoDto,
  OtpPhoneTypeDto,
  ValidatePasswordDto,
  ChangeForgottenPasswordDto,
} from '@afone/neo-core-client/dist/models'
import { AxiosResponse } from 'axios'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'

import { Dispatch } from '../../utils/resourceState'
import { State } from '../../utils'
import { LOCK_DEVICE_CODE } from '../deviceRecovery'
import { SubscriptionActions } from '../subscription'

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types
\* %%%%%%%%%%%%%%%%%% */

const SIGN_IN_PREPARE = 'signIn/SIGN_IN_PREPARE'

const GET_OTP_PHONE_REQUEST = 'signIn/GET_OTP_PHONE_REQUEST'
const GET_OTP_PHONE_SUCCESS = 'signIn/GET_OTP_PHONE_SUCCESS'
const GET_OTP_PHONE_FAILURE = 'signIn/GET_OTP_PHONE_FAILURE'

const VERIFY_PHONE_REQUEST = 'signIn/VERIFY_PHONE_REQUEST'
const VERIFY_PHONE_SUCCESS = 'signIn/VERIFY_PHONE_SUCCESS'
const VERIFY_PHONE_FAILURE = 'signIn/VERIFY_PHONE_FAILURE'

const GET_UUID_REQUEST = 'signIn/GET_UUID_REQUEST'
const GET_UUID_SUCCESS = 'signIn/GET_UUID_SUCCESS'
const GET_UUID_FAILURE = 'signIn/GET_UUID_FAILURE'

const CONFIRM_PASSWORD_CHECK_REQUEST = 'signIn/CONFIRM_PASSWORD_CHECK_REQUEST'
const CONFIRM_PASSWORD_CHECK_SUCCESS = 'signIn/CONFIRM_PASSWORD_CHECK_SUCCESS'
const CONFIRM_PASSWORD_CHECK_FAILURE = 'signIn/CONFIRM_PASSWORD_CHECK_FAILURE'

const RECOVER_PASSWORD_REQUEST = 'signIn/RECOVER_PASSWORD_REQUEST'
const RECOVER_PASSWORD_SUCCESS = 'signIn/RECOVER_PASSWORD_SUCCESS'
const RECOVER_PASSWORD_FAILURE = 'signIn/RECOVER_PASSWORD_FAILURE'

const RECOVER_PASSWORD_CHECK_REQUEST = 'signIn/RECOVER_PASSWORD_CHECK_REQUEST'
const RECOVER_PASSWORD_CHECK_SUCCESS = 'signIn/RECOVER_PASSWORD_CHECK_SUCCESS'
const RECOVER_PASSWORD_CHECK_FAILURE = 'signIn/RECOVER_PASSWORD_CHECK_FAILURE'

const SIGN_IN_REQUEST = 'signIn/SIGN_IN_REQUEST'
const SIGN_IN_SUCCESS = 'signIn/SIGN_IN_SUCCESS'
const SIGN_IN_FAILURE = 'signIn/SIGN_IN_FAILURE'

const REFRESH_REQUEST = 'signIn/REFRESH_REQUEST'
const REFRESH_SUCCESS = 'signIn/REFRESH_SUCCESS'
const REFRESH_FAILURE = 'signIn/REFRESH_FAILURE'

const SIGN_OUT_REQUEST = 'signIn/SIGN_OUT_REQUEST'
const SIGN_OUT_SUCCESS = 'signIn/SIGN_OUT_SUCCESS'
const SIGN_OUT_FAILURE = 'signIn/SIGN_OUT_FAILURE'

const CONFIRM_CODE_REQUEST = 'signIn/CONFIRM_CODE_REQUEST'
const CONFIRM_CODE_SUCCESS = 'signIn/CONFIRM_CODE_SUCCESS'
const CONFIRM_CODE_FAILURE = 'signIn/CONFIRM_CODE_FAILURE'
const CONFIRM_CODE_MAX_FAILURE = 'signIn/CONFIRM_CODE_MAX_FAILURE'

const SET_CURRENT_DEVICE = 'signIn/SET_CURRENT_DEVICE'

const USER_CHANGE = 'signIn/USER_CHANGE'
const RESET = 'signIn/RESET'

export const SignInTypes = {
  SIGN_IN_PREPARE,

  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,

  CONFIRM_CODE_REQUEST,
  CONFIRM_CODE_SUCCESS,
  CONFIRM_CODE_FAILURE,
  CONFIRM_CODE_MAX_FAILURE,

  RECOVER_PASSWORD_REQUEST,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAILURE,

  CONFIRM_PASSWORD_CHECK_REQUEST,
  CONFIRM_PASSWORD_CHECK_SUCCESS,
  CONFIRM_PASSWORD_CHECK_FAILURE,

  RECOVER_PASSWORD_CHECK_REQUEST,
  RECOVER_PASSWORD_CHECK_SUCCESS,
  RECOVER_PASSWORD_CHECK_FAILURE,

  GET_OTP_PHONE_REQUEST,
  GET_OTP_PHONE_SUCCESS,
  GET_OTP_PHONE_FAILURE,

  VERIFY_PHONE_REQUEST,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_FAILURE,

  GET_UUID_REQUEST,
  GET_UUID_SUCCESS,
  GET_UUID_FAILURE,

  REFRESH_REQUEST,
  REFRESH_SUCCESS,
  REFRESH_FAILURE,

  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,

  SET_CURRENT_DEVICE,

  USER_CHANGE,
  RESET,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators
\* %%%%%%%%%%%%%%%%%% */
const prepare = function (phone: string, phoneCountryCode: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SIGN_IN_PREPARE, phone, phoneCountryCode })
  }
}

const errorKeyI18n = 'errors:internalTechnicalIssue'

const getOtpPhoneVerify = function (phone: string, token: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { signIn } = getState()
    dispatch({ type: GET_OTP_PHONE_REQUEST })

    try {
      const response = await NeobankApi.getInstance().otpPhoneApi.createOtpPhone({
        phoneCountryCode: signIn?.data?.countryCode!,
        phone: phone,
        type: OtpPhoneTypeDto.CHANGE_PASSWORD,
        token,
      })
      dispatch({
        type: GET_OTP_PHONE_SUCCESS,
        phoneUuid: response.data.uuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyI18n)
      dispatch({ type: GET_OTP_PHONE_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const verifyPhone = function (code: string) {
  const verifyPhoneErrorTransKey = 'errors:verifyPhoneError'
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: VERIFY_PHONE_REQUEST })

    if (!code) {
      dispatch({ type: VERIFY_PHONE_FAILURE, errorMessage: i18next.t(verifyPhoneErrorTransKey) })
      throw new Error(i18next.t(verifyPhoneErrorTransKey))
    }

    try {
      await NeobankApi.getInstance().otpPhoneApi.updateOtpPhoneByUuid(getState().signIn?.data?.phoneUuid!, { code })
      dispatch({ type: VERIFY_PHONE_SUCCESS })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyI18n)
      dispatch({ type: VERIFY_PHONE_FAILURE, errorMessage })
      throw new Error(i18next.t(verifyPhoneErrorTransKey))
    }
  }
}

const recoverPasswordWithSecretAndOtpPhone = function (changeForgottenPasswordDto: ChangeForgottenPasswordDto) {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { signIn } = getState()
    dispatch({ type: RECOVER_PASSWORD_REQUEST })

    try {
      await NeobankApi.getInstance().otpPhoneApi.changeForgottenPasswordWithOtpPhone(
        signIn?.data?.phoneUuid!,
        changeForgottenPasswordDto
      )
      dispatch({
        type: RECOVER_PASSWORD_SUCCESS,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyI18n)
      dispatch({ type: RECOVER_PASSWORD_FAILURE, errorMessage })
      throw error
    }
  }
}

const recoverPasswordCheck = function (validatePassword: ValidatePasswordDto) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RECOVER_PASSWORD_CHECK_REQUEST })

    if (!validatePassword) {
      dispatch({ type: RECOVER_PASSWORD_CHECK_FAILURE, errorMessage: i18next.t('errors:registerError') })
      throw new Error(i18next.t('errors:registerError'))
    } else {
      try {
        await NeobankApi.getInstance().userApi.validatePassword(validatePassword)
        dispatch({ type: RECOVER_PASSWORD_CHECK_SUCCESS, password: validatePassword.password })
        return validatePassword.password
      } catch (error) {
        const errorMessage = error.message ?? i18next.t(errorKeyI18n)
        dispatch({ type: RECOVER_PASSWORD_CHECK_FAILURE, errorMessage })
        throw error
      }
    }
  }
}

const getUuidByPhone = function (phone: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GET_UUID_REQUEST })
    try {
      const getUserUuidByPhone = await NeobankApi.getInstance().phoneApi.getPhoneInformationByNumber(phone)
      dispatch({ type: GET_UUID_SUCCESS, userUuid: getUserUuidByPhone.data.userUuid })
      return getUserUuidByPhone.data.userUuid
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyI18n)
      dispatch({ type: GET_UUID_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const confirmPassword = function (confirmPassword: string[]) {
  return async (dispatch: Dispatch) => {
    // This does nothing anymore but store the confirmPassword because we can't compare the new keypads
    try {
      dispatch({
        type: CONFIRM_PASSWORD_CHECK_SUCCESS,
        confirmPassword,
      })
    } catch (error) {
      const errorMessage = error.message
      dispatch({ type: CONFIRM_PASSWORD_CHECK_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const signIn = function (password: string[]) {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { signIn } = getState()
    dispatch({ type: SIGN_IN_REQUEST })

    try {
      const currentDeviceUuid = signIn.currentDeviceUuid
      if (!currentDeviceUuid) {
        throw new Error()
      }

      let { phone, countryCode } = signIn?.data as { phone: string, countryCode: string }
      if (phone && countryCode) {
        const parsed = parsePhoneNumber(phone, countryCode as CountryCode)
        phone = parsed?.number as string
      }

      const signInResponse: AxiosResponse<LoginInfoDto> = await NeobankApi.getInstance().authenticationApi.login({
        phone,
        password,
        device: currentDeviceUuid,
      })

      NeobankApi.getInstance().setAuthToken(signInResponse.data.token)

      await dispatch(SubscriptionActions.list())

      dispatch({
        type: SIGN_IN_SUCCESS,
        user: signInResponse.data,
        token: signInResponse.data.token,
      })
      return signInResponse.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyI18n)
      dispatch({ type: SIGN_IN_FAILURE, errorMessage, code: error.code })
      throw error
    }
  }
}

const confirmCode = function (password: string[]) {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { signIn } = getState()
    dispatch({ type: CONFIRM_CODE_REQUEST })

    try {
      const currentDeviceUuid = signIn.currentDeviceUuid
      if (!currentDeviceUuid) {
        throw new Error()
      }

      const signInResponse: AxiosResponse<LoginInfoDto> = await NeobankApi.getInstance().authenticationApi.login({
        phone: signIn?.data?.user?.phone?.internationalFormat!,
        password: password,
        device: currentDeviceUuid,
      })

      dispatch({ type: CONFIRM_CODE_SUCCESS })

      return signInResponse.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyI18n)
      if (error.code === 429) {
        dispatch({ type: CONFIRM_CODE_MAX_FAILURE, errorMessage })
      } else {
        dispatch({ type: CONFIRM_CODE_FAILURE, errorMessage })
      }

      throw new Error(errorMessage)
    }
  }
}

const signOut = function () {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SIGN_OUT_REQUEST })

    try {
      dispatch({ type: SIGN_OUT_SUCCESS })
      NeobankApi.getInstance().setAuthToken(undefined)
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: SIGN_OUT_FAILURE, errorMessage })
    }
  }
}

const setCurrentDevice = (payload: { deviceUuid: string, uniqueDeviceId?: string }) => {
  return async (dispatch: Dispatch) => {
    const { deviceUuid } = payload
    const uniqueDeviceId = payload.uniqueDeviceId
    dispatch({ type: SET_CURRENT_DEVICE, deviceUuid, uniqueDeviceId })
  }
}

const reset = function () {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET })
  }
}

export const SignInActions = {
  prepare,
  getOtpPhoneVerify,
  verifyPhone,
  confirmCode,
  getUuidByPhone,
  recoverPasswordWithSecretAndOtpPhone,
  recoverPasswordCheck,
  confirmPassword,
  signIn,
  signOut,
  setCurrentDevice,
  reset,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export interface SignInState {
  ui: {
    isSignedIn: boolean,
    isLoading: boolean,
    triggerPhoneVerifyLoading: boolean,
    verifyPhoneLoading: boolean,
    isPhoneVerified: boolean,
  },
  data: {
    token: string | null,
    user: LoginInfoDto | null,
    countryCode: string | null,
    phone: string | null,
    phoneUuid: string | null,
    subscriptionStatus: string,
    id: string | null,
    code: string[] | null,
  }
  currentDeviceUuid: string | null
  uniqueDeviceId: string | null
}

const initialState: SignInState = {
  ui: {
    isSignedIn: false,
    isLoading: false,
    triggerPhoneVerifyLoading: false,
    verifyPhoneLoading: false,
    isPhoneVerified: false,
  },
  data: {
    token: null,
    phone: null,
    countryCode: null,
    phoneUuid: null,
    user: null,
    subscriptionStatus: '',
    id: null,
    code: null,
  },
  currentDeviceUuid: null,
  uniqueDeviceId: null,
}

export const SignIn: Reducer = (state: SignInState = initialState, action: AnyAction): SignInState => {
  switch (action.type) {
    case RECOVER_PASSWORD_CHECK_SUCCESS:
      return {
        ...initialState,
        data: {
          ...state.data,
          code: action.password,
        },
        currentDeviceUuid: state.currentDeviceUuid,
        uniqueDeviceId: state.uniqueDeviceId,
      }
    case GET_OTP_PHONE_REQUEST:
      return {
        ...initialState,
        data: {
          ...state.data,
        },
        ui: {
          ...initialState.ui,
          triggerPhoneVerifyLoading: true,
        },
        currentDeviceUuid: state.currentDeviceUuid,
        uniqueDeviceId: state.uniqueDeviceId,
      }
    case GET_OTP_PHONE_SUCCESS:
      return {
        ...initialState,
        data: {
          ...state.data,
          phoneUuid: action.phoneUuid,
        },
        ui: {
          ...initialState.ui,
          triggerPhoneVerifyLoading: false,
        },
        currentDeviceUuid: state.currentDeviceUuid,
        uniqueDeviceId: state.uniqueDeviceId,
      }
    case GET_OTP_PHONE_FAILURE:
      return {
        ...initialState,
        ui: {
          ...initialState.ui,
          triggerPhoneVerifyLoading: false,
        },
      }
    case VERIFY_PHONE_REQUEST:
      return {
        ...initialState,
        data: {
          ...state.data,
        },
        ui: {
          ...initialState.ui,
          verifyPhoneLoading: true,
        },
        currentDeviceUuid: state.currentDeviceUuid,
        uniqueDeviceId: state.uniqueDeviceId,
      }
    case VERIFY_PHONE_SUCCESS:
      return {
        ...initialState,
        data: {
          ...state.data,
        },
        ui: {
          ...initialState.ui,
          isPhoneVerified: true,
          verifyPhoneLoading: false,
        },
        currentDeviceUuid: state.currentDeviceUuid,
        uniqueDeviceId: state.uniqueDeviceId,
      }
    case VERIFY_PHONE_FAILURE:
      return {
        ...initialState,
        data: {
          ...state.data,
        },
        ui: {
          ...initialState.ui,
          isPhoneVerified: false,
          verifyPhoneLoading: false,
        },
      }
    case GET_UUID_SUCCESS:
      return {
        ...initialState,
        data: {
          ...state.data,
          id: action.userUuid,
        },
        currentDeviceUuid: state.currentDeviceUuid,
        uniqueDeviceId: state.uniqueDeviceId,
      }
    case SIGN_IN_PREPARE:
      return {
        ...initialState,
        data: {
          ...state.data,
          countryCode: action.phoneCountryCode,
          phone: action.phone.replace(/ /g, ''),
        },
        currentDeviceUuid: state.currentDeviceUuid,
        uniqueDeviceId: state.uniqueDeviceId,
      }
    case SIGN_IN_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: true,
        },
      }
    case SIGN_IN_SUCCESS:
      return {
        ...initialState,
        data: {
          ...state.data,
          user: action.user,
          token: action.token,
        },
        ui: {
          ...initialState.ui,
          isLoading: false,
        },
        currentDeviceUuid: state.currentDeviceUuid,
        uniqueDeviceId: state.uniqueDeviceId,
      }

    case SIGN_IN_FAILURE:
      if (action?.code === LOCK_DEVICE_CODE) {
        return {
          ...initialState,
          currentDeviceUuid: state.currentDeviceUuid,
          uniqueDeviceId: state.uniqueDeviceId,
        }
      } else {
        return {
          ...initialState,
          data: {
            ...initialState.data,
            phone: state.data.phone,
            phoneUuid: state.data.phoneUuid,
          },
          ui: {
            ...state.ui,
            isSignedIn: false,
            isLoading: false,
          },
          currentDeviceUuid: state.currentDeviceUuid,
          uniqueDeviceId: state.uniqueDeviceId,
        }
      }
    case SIGN_OUT_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: true,
        },
      }
    case GET_UUID_FAILURE:
    case SIGN_OUT_SUCCESS:
      return {
        ...initialState,
        data: {
          ...initialState.data,
          phone: state.data.phone,
          countryCode: state.data.countryCode,
          phoneUuid: state.data.phoneUuid,
          user: {
            ...initialState.data.user,
            lastName: state.data.user?.lastName,
            firstName: state.data.user?.firstName,
          },
        },
        currentDeviceUuid: state.currentDeviceUuid,
        uniqueDeviceId: state.uniqueDeviceId,
      }
    case SIGN_OUT_FAILURE:
    case RECOVER_PASSWORD_CHECK_FAILURE:
    case RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: false,
        },
      }
    case SET_CURRENT_DEVICE:
      return {
        ...state,
        currentDeviceUuid: action.deviceUuid,
        uniqueDeviceId: action.uniqueDeviceId,
      }
    case USER_CHANGE:
      return {
        ...initialState,
        uniqueDeviceId: state.uniqueDeviceId,
      }
    case RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
