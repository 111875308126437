import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'
import { Routes } from 'Routes/Routes'

import { CustomerServiceCountryCardList, Typography } from '@neo-commons/components'

import { Tunnel } from '@neo-web/componentsOld'
import { UseTextWithBoldWords } from '@neo-web/hooks'

import './AlertRecoverPassword.scss'

export const AlertSecretQuestion = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Tunnel
      quitTunnelDirect
      title={t('app:pages:recoverPassword:alert:titleMessage')}
      onBack={() => history.push(Routes.SignIn.code.path)}
      component={(
        <div className='AlertRecoverPassword classic-box'>
          <div className='AlertRecoverPassword_container'>
            <FlexboxGrid.Item colspan={24} className='AlertRecoverPassword_text'>
              <Typography typeface='bigTitle'>
                {t('app:pages:recoverPassword:alert:secretQuestion:titleMessage')}
              </Typography>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={24} className='AlertRecoverPassword_text'>
              {UseTextWithBoldWords({
                text: t('app:pages:recoverPassword:alert:secretQuestion:subTitleMessage'),
              })}
            </FlexboxGrid.Item>
            <CustomerServiceCountryCardList
              itemContainerStyle={{ marginBottom: 5 }}
            />
          </div>
        </div>
      )}
    />
  )
}
