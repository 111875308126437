import React from 'react'
import { Image } from 'react-native'

import { Typography } from '@neo-commons/components'
import { PosProductData, POSUtils } from '@neo-commons/libraries'

import { ProductDto } from '@afone/neo-core-client/dist/models'

import { Modal, OfferDescription, OfferDescriptionItem } from '@neo-web/componentsOld'

import './PosModelDetail.scss'

interface PosModelDetailProps {
  display: boolean;
  close: () => void;
  product: ProductDto,
}

export const PosModelDetail: React.FC<PosModelDetailProps> = (props: PosModelDetailProps) => {
  const { product } = props

  const image = (product.data as PosProductData).image
  const deposit = POSUtils.getPosProductDescription(product).header?.[0]
  const commission = POSUtils.getPosProductDescription(product).header?.[1]

  return (
    <Modal
      open={props.display}
      title={product.name}
      onClose={() => props.close()}
      size='sm'
    >
      <div className='PosModelDetail'>
        <div className='PosModelDetail_header'>
          <Typography typeface='info'>
            <b>{deposit?.title}</b> {deposit?.description}
            {commission?.description &&
              <><br />{commission?.description}</>}
          </Typography>
        </div>
        {image && (
          <Image
            source={{ uri: image }}
            style={{ height: 132, width: 132, marginHorizontal: 'auto', marginVertical: 30, borderRadius: 15 }}
          />
        )}
        <OfferDescription
          descriptions={POSUtils.getPosProductDescription(product).body as OfferDescriptionItem[]}
        />
      </div>
    </Modal>
  )
}
