import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import i18next from 'i18next'
import dayjs from 'dayjs'

import { SwitchInput, Typography } from '@neo-commons/components'
import { NumbersUtils, TransactionUtils } from '@neo-commons/libraries'

import { TransactionDto } from '@afone/neo-core-client/dist/models'

import { CardDetails, CardDetailsItem } from '@neo-web/componentsOld'

interface TransactionDetailsProps {
  transaction: TransactionDto,
  onChecked: (timestamp: boolean) => void,
  currency?: string,
}

export const TransactionDetails: React.FC<TransactionDetailsProps> = (props: TransactionDetailsProps) => {
  const { transaction, onChecked, currency } = props
  const { t } = useTranslation()

  const [timestamp, setTimestamp] = useState<boolean>(!!transaction?.checkedDate)

  const cardTruncatedNumber = transaction.card?.truncatedNumber
  const cardNumberString = !!cardTruncatedNumber && t('app:global:card') + ' ****' + cardTruncatedNumber?.substring(cardTruncatedNumber.length - 4)

  const amount = TransactionUtils.isFee(transaction)
    ? (transaction?.price?.feesTotal && NumbersUtils.displaySignedPriceForHuman(transaction.price.feesTotal))
    : transaction?.price?.amount && NumbersUtils.displayPriceForHumanWithCurrency(transaction.price.amount, currency)

  const listItems: CardDetailsItem[] = [
    {
      value: amount,
    }, {
      value: transaction?.thirdparty?.label && transaction.thirdparty.label,
    }, {
      value: TransactionUtils.isFee(transaction)
        ? (transaction?.feeLabel && transaction.feeLabel)
        : (transaction?.iban ? transaction.iban : cardNumberString),
    }, {
      label: t('app:pages:synthesis:transaction:detail:operationDate'),
      value: transaction.transactionDate && i18next.t('neo-commons:transaction:dateDetails', {
        date: dayjs(transaction.transactionDate).format('L'),
        hours: dayjs(transaction.transactionDate).format('HH:mm'),
        interpolation: { escapeValue: false },
      }),
    },
  ]

  return (
    <CardDetails
      title={t('app:pages:synthesis:transaction:detail:title')}
      list={listItems}
    >
      {transaction?.transfered &&
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }} data-testid='switch-timestamp'>
          <SwitchInput
            value={timestamp}
            onChange={value => {
              setTimestamp(value)
              onChecked(value)
            }}
          />
          <Typography typeface='smallText' style={{ paddingLeft: 8 }}>
            {t('app:pages:synthesis:transaction:detail:timestamp')}
          </Typography>
        </div>}
    </CardDetails>
  )
}
