import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'
import { StateTypes } from 'Store'
import { AppConfig } from 'Config/AppConfig'
import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'
import { PasswordKeypad } from 'UI/Components/Auth/PasswordKeypad'

import { Button, ButtonSkin, Typography } from '@neo-commons/components'
import { SignInActions } from '@neo-commons/store'
import { RecoverPasswordStep, RecoverPasswordSteps, StepComponentProps } from '@neo-commons/policies'
import { usePasswordKeypad } from '@neo-commons/hooks'

import { KeypadFieldDto } from '@afone/neo-core-client/dist/models'

type CodeProps = StepComponentProps<RecoverPasswordSteps[RecoverPasswordStep.CodeConfirm]>
export const CodeConfirm: React.FC<CodeProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userSecretQuestion = useSelector((state: StateTypes) => state.userSecretQuestion)
  const signInData = useSelector((state: StateTypes) => state?.signIn?.data)
  const confirmPasswordKeypadData = usePasswordKeypad({
    phone: signInData?.phone,
    phoneCountryCode: signInData?.countryCode,
    field: KeypadFieldDto.CONFIRM_PASSWORD,
    passwordSize: AppConfig.PASS_CODE_SIZE,
  })

  const onValidate = async () => {
    try {
      await dispatch(SignInActions.confirmPassword(confirmPasswordKeypadData.password.map(e => e.value)))
      nextStep({
        secretQuestionAnswer: userSecretQuestion.answer,
        password: signInData.code,
        confirmPassword: confirmPasswordKeypadData.password.map(e => e.value),
        userUuid: signInData.id,
      })
      AnalyticsHandler.track(AnalyticsEvents.signIn.forgotPasswordSuccess)
    } catch (error) {
      confirmPasswordKeypadData.reset(error)
    }
  }

  return (
    <FlexboxGrid justify='space-between' align='middle' className='text-center'>

      <FlexboxGrid.Item colspan={24} className='pb-xl'>
        <Typography typeface='bigTitle'>
          {t('app:pages:recoverPassword:step3:codeConfirm:titleMessage')}
        </Typography>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24}>
        <PasswordKeypad
          {...confirmPasswordKeypadData}
          withoutValidate
        />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24}>
        <Button
          title={t('app:global:validate')} skin={ButtonSkin.PRIMARY}
          disabled={!confirmPasswordKeypadData.isFilled}
          onPressOut={() => {
            onValidate()
          }}
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}
