import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { SelectPicker } from 'rsuite'
import { Routes } from 'Routes/Routes'

import { Typography, Input, Button, ButtonSkin } from '@neo-commons/components'
import { SecretQuestionSelectors } from '@neo-commons/store'
import { SecretQuestionStep, SecretQuestionSteps, StepComponentProps } from '@neo-commons/policies'
import { Validators } from '@neo-commons/libraries'

import { SecretQuestionDto } from '@afone/neo-core-client/dist/models'

import { useResponsive } from '@neo-web/hooks'
import { PageDetails } from '@neo-web/componentsOld'

import { AppConfig } from '../../../../../../../Config/AppConfig'

import './EditSecretQuestion.scss'

type DataItemType = {
  value: string
  label: React.ReactNode
  children?: Array<DataItemType>
  groupBy?: string
}

type EditSecretQuestionProps = StepComponentProps<SecretQuestionSteps[SecretQuestionStep.SecretQuestion]>

export const EditSecretQuestion : React.FC<EditSecretQuestionProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const isTabletOrMobile = useResponsive()
  const history = useHistory()

  const [secretQuestionUuid, setSecretQuestionUuid] = useState('')
  const secretQuestionList = useSelector(SecretQuestionSelectors.list)
  const [secretQuestionAnswer, setSecretQuestionAnswer] = useState('')
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false)

  const onEditSecretQuestion = async () => {
    if (secretQuestionUuid) {
      try {
        await nextStep({ secretQuestionUuid, secretQuestionAnswer })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const checkValidAnswer = () => {
    return !(Boolean(secretQuestionUuid) && Boolean(secretQuestionAnswer)) ||
      !isAnswerCorrect
  }

  return (
    <PageDetails
      component={
        <div className='EditSecretQuestion text-center'>
          <div className='EditSecretQuestion_section-container'>
            <div className={!isTabletOrMobile ? 'classic-box' : ''}>

              <Typography typeface='bigTitle'>
                {t('app:pages:myProfile:security:secretQuestion:step1:title')}
              </Typography>
              <div className='EditSecretQuestion_item'>
                <SelectPicker
                  data={secretQuestionList.map(
                    (secretQuestion: SecretQuestionDto) =>
                      ({
                        value: secretQuestion.uuid,
                        label: secretQuestion.question,
                      } as DataItemType)
                  )}
                  onChange={(secretQuestionUuid: string) => setSecretQuestionUuid(secretQuestionUuid)}
                  appearance='subtle'
                  searchable={false}
                  placeholder={t('app:pages:register:step4:secretQuestion:select')}
                />
              </div>
              <div className='EditSecretQuestion_item'>
                <Input
                  isRequired
                  label={t('app:pages:register:step4:secretQuestion:answer')}
                  hasClear
                  blurOnSubmit
                  value={secretQuestionAnswer}
                  clearButtonMode='while-editing'
                  onChangeText={(value: string) => setSecretQuestionAnswer(value)}
                  validators={[Validators.minLength(AppConfig.MIN_LENGTH_SECRET_QUESTION), Validators.maxLength(AppConfig.MAX_LENGTH_SECRET_QUESTION)]}
                  onValidationChecked={(status) => {
                    setIsAnswerCorrect(status.isValid)
                  }}
                />
              </div>
              <div className='EditSecretQuestion_item'>
                <Button
                  title={t('app:global:validate')}
                  skin={ButtonSkin.PRIMARY}
                  disabled={checkValidAnswer()}
                  onPressOut={() => {
                    if (!checkValidAnswer()) { onEditSecretQuestion() }
                  }}
                />
              </div>

            </div>
          </div>
        </div>
      }
      onBack={() => history.push(Routes.Profile.security.path)}
      title={t('app:pages:myProfile:security:secretQuestion:editSecretQuestion')}
    />
  )
}
