import React from 'react'

import { Slider } from 'UI/Components/RsuitJS/Slider'

import { Card, CardSkin, ProgressBar, Typography } from '@neo-commons/components'
import { NumbersUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import './LimitCard.scss'

interface LimitCardProps {
  title?: string;
  price?: number;
  bottomText?: string;
  limitBar?: {
    current: number;
    max: number;
    isSelector?: boolean;
    error?: boolean;
  };
  onChange?: (value) => void;
}

export const LimitCard: React.FC<LimitCardProps> = ({
  title,
  price,
  bottomText,
  limitBar,
  onChange,
}) => {
  return (
    <div className='LimitCard_container'>
      <Card
        skin={CardSkin.PRIMARY_DARKER_BORDERED}
        withShadow
      >
        {title &&
          <Typography typeface='subtitleLight'>
            {title}
          </Typography>}
        {limitBar && !limitBar.isSelector &&
          <div className='LimitCard_limitBar'>
            <ProgressBar
              leftLabel={NumbersUtils.displayPriceForHuman(limitBar.current, undefined, false)}
              rightLabel={NumbersUtils.displayPriceForHuman(limitBar.max, undefined, false)}
              rightLabelStyle={{ color: Colors.primary }}
              max={limitBar.max}
              value={limitBar.current}
            />
          </div>}
        {limitBar && limitBar.isSelector &&
          <div className='LimitCard_limitBar'>
            <Slider
              leftLabel={NumbersUtils.displayPriceForHuman(0, undefined, false)}
              rightLabel={NumbersUtils.displayPriceForHuman(limitBar.max, undefined, false)}
              max={limitBar.max}
              value={limitBar.current}
              valueDisplayer={() => NumbersUtils.displayPriceForHuman(limitBar.current, undefined, false)}
              onChange={onChange}
            />
          </div>}
        {price !== undefined &&
          <div className='LimitCard_price'>
            <Typography typeface='appName'>
              {NumbersUtils.displayPriceForHuman(price, undefined, false)}
            </Typography>
          </div>}
        {bottomText &&
          <Typography typeface='subtitleLight'>
            {bottomText}
          </Typography>}
      </Card>
    </div>
  )
}
