import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

const styles = StyleSheet.create({
  card: {
    paddingVertical: 8,
    marginBottom: 0,
  },
  select: {
    backgroundColor: Colors.primary,
    paddingVertical: 8,
    marginBottom: 0,
    color: Colors.white,
  },
  image: {
    width: 30,
    height: 30,
  },
  valueStyle: {
    fontSize: 14,
    top: 4,
  },
})

export default styles
