export class ErrorUtils {
  /**
   * Get string of key translate message from code
   *
   * @param errorCode - Error code of message.
   * @returns Return key of translate message.
   * @example
   * const errorMessage = i18next.t(ErrorUtils.getTranslateKeyByErrorCode(401))
   * console.log(t(errorMessage))
   * //Output : 'Access Unauthorized'
   *
   */
  public static getTranslateKeyByErrorCode (errorCode: number): string {
    let keyTranslate: string
    switch (errorCode) {
      case 401:
        keyTranslate = 'errors:codes:401'
        break
      case 402:
        keyTranslate = 'errors:codes:402'
        break
      case 403:
        keyTranslate = 'errors:codes:403'
        break
      default:
        keyTranslate = 'errors:internalTechnicalIssue'
    }

    return keyTranslate
  }
}
