import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { POSLostStep, POSLostSteps, StepComponentProps } from '@neo-commons/policies'
import { BankAccountSelectors, PosActions } from '@neo-commons/store'

import { AccountDto } from '@afone/neo-core-client/dist/models'

import { PageContent, SuccessContent } from '@neo-web/componentsOld'

type POSLostProps = StepComponentProps<POSLostSteps[POSLostStep.Success]>

export const Success: React.FC<POSLostProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts())

  return (
    <PageContent>
      <SuccessContent
        title={t('neo-commons:pages:services:pos:lost:success:title')}
        description={t('neo-commons:pages:services:pos:lost:success:description')}
        buttonBack={{
          action: () => {
            dispatch(PosActions.list(bankAccounts))
            history.push(Routes.Synthesis.index.path)
          },
        }}
      />
    </PageContent>
  )
}
