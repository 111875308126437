import React from 'react'
import { useTranslation } from 'react-i18next'

import CustomIcon from 'UI/Components/CustomIcon'

import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  Typography,
  Typography2,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { OfferDto } from '@afone/neo-core-client/dist/models'

import './NewOfferCard.scss'

interface NewOfferCardProps {
  offer: OfferDto
  onOfferSelect: (offer) => void
  openDiscountCodeModal: () => void
}

export const NewOfferCard: React.FC<NewOfferCardProps> = (props: NewOfferCardProps) => {
  const { t } = useTranslation()
  const { offer, onOfferSelect, openDiscountCodeModal } = props
  let content: any = null
  try {
    content = JSON.parse(offer.description[0])
  } catch (_) {}

  return (
    <div className='NewOfferCard'>
      {content?.headband && (
        <div className='NewOfferCard_containerBadge' style={{ backgroundColor: content.mainColor }}>
          <p className='NewOfferCard_badge'>{content.headband}</p>
        </div>)}
      <div className='NewOfferCard_row'>
        <div>
          <Typography2 typeface='h4'>{offer.name}</Typography2><br />
          <Typography2 typeface='body2' style={{ marginTop: -16, color: content.mainColor }}>
            {'★'.repeat(content?.rating)}
          </Typography2>
        </div>
        <div style={{ textAlign: 'right' }}>
          <Typography typeface='h4'>{offer.price}</Typography><br />
          <Typography typeface='body2' style={[{ textTransform: 'uppercase', marginTop: 4, fontSize: 12 }]}>
            {content?.engagement}
          </Typography>
        </div>
      </div>
      <div className='NewOfferCard_optionContainer'>
        {content.features &&
          content.features.map((content, index) => {
            return (
              <div key={index} className='NewOfferCard_optionRow'>
                {content &&
                  <div className='NewOfferCard_optionName' style={{ opacity: content.status === 'INCLUDED' ? 1 : 0.7 }}>
                    <div style={{ maxWidth: '80%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}>
                        <CustomIcon
                          name={content.leftIcon}
                          size={24}
                          color={content.status === 'INCLUDED' ? Colors.primary : Colors.gray4}
                        />
                      </div>
                      <div style={{ color: Colors.black }}>
                        <p style={{ fontFamily: 'Quicksand-Bold', fontSize: 15 }}>{content.label}</p>
                        <p style={{ fontFamily: 'Quicksand-Medium', fontSize: 13, marginTop: -4 }}>
                          {content.subLabel}
                        </p>
                      </div>
                    </div>
                    <div>
                      {content?.price ? (
                        <p style={{ fontFamily: 'Quicksand-Bold', fontSize: 15 }}>{content.price}</p>
                      ) : (
                        <Icon
                          type={IconTypes.FEATHER}
                          name='check'
                          color={content.status === 'INCLUDED' ? Colors.greener : Colors.gray4}
                          size={20}
                          iconStyle={{ alignSelf: 'center' }}
                        />
                      )}
                    </div>
                  </div>}
              </div>
            )
          })}
      </div>

      <div className='NewOfferCard_selectOffer'>
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:pages:subscription:global:offers:chooseOffer')}
          onPress={() => onOfferSelect(offer)}
        />
      </div>

      <div className='NewOfferCard_discountCode'>
        {offer?.discountCodeUsable
          ? <Typography2 typeface='buttonMedium'>{offer?.discount?.description}</Typography2>
          : (
            <Button
              skin={ButtonSkin.PRIMARY_LINK}
              title={t('app:pages:subscription:global:offers:discountCodeLink')}
              onPress={() => {
                openDiscountCodeModal()
              }}
            />
          )}
      </div>
    </div>
  )
}
