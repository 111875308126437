import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'
import CustomIcon from 'UI/Components/CustomIcon'

import { BankAccountActions, BankAccountSelectors, SubscriptionSelectors } from '@neo-commons/store'
import { SubscriptionUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { AccountDto } from '@afone/neo-core-client/dist/models'

import { CardMenuItem, CardMenuList, JustifyContent, PageContent } from '@neo-web/componentsOld'

export const Profile: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listMain)
  const mainSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  const pressContractsAndInvoices = async () => {
    const bankAccountsLength = bankAccounts?.length
    if (bankAccountsLength === 1) {
      await dispatch(BankAccountActions.setSelected(bankAccounts[0].uuid))
      history.push(Routes.Profile.invoiceAndContractDetail.path)
    } else if (bankAccountsLength > 1) {
      await dispatch(BankAccountActions.setSelected(null))
      history.push(Routes.Profile.choicesAccount.path)
    }
  }

  const menuCards: CardMenuItem[] = [
    {
      icon: <CustomIcon name='fileText' size={24} color={Colors.primary} />,
      title: t('app:pages:myProfile:menu:contractAndInvoice'),
      content: undefined,
      onPress: pressContractsAndInvoices,
      hidden: !SubscriptionUtils.isActive(mainSubscription),
    },
    {
      icon: <CustomIcon name='settings' size={24} color={Colors.primary} />,
      title: t('app:pages:myProfile:menu:preferencesAndSecurity'),
      content: undefined,
      onPress: () => {
        history.push(Routes.Profile.preference.path)
      },
    },
    {
      icon: <CustomIcon name='gift' size={24} color={Colors.primary} />,
      title: t('app:pages:myProfile:menu:offers'),
      content: undefined,
      hidden: !!mainSubscription,
      onPress: () => {
        history.push(Routes.Subscription.index.path)
      },
    },
    {
      icon: <CustomIcon name='user' size={26} color={Colors.primary} />,
      title: t('app:pages:myProfile:menu:myInformation'),
      content: undefined,
      onPress: () => history.push(Routes.Profile.information.path),
    },
    {
      icon: <CustomIcon name='infoBubble' size={24} color={Colors.primary} />,
      title: t('app:pages:myProfile:menu:helpAndEmergency'),
      content: undefined,
      hidden: !SubscriptionUtils.isActive(mainSubscription),
      onPress: () => history.push(Routes.Profile.helpAndEmergency.path),
      disabled: false,
    },
    {
      icon: <CustomIcon name='help' size={24} color={Colors.primary} />,
      title: t('app:pages:myProfile:menu:about'),
      content: undefined,
      onPress: () => history.push(Routes.Profile.about.path),
      disabled: false,
    },
  ]

  return (
    <PageContent justifyContent={JustifyContent.START}>
      <CardMenuList
        menus={menuCards}
      />
    </PageContent>
  )
}
