import { AnyAction } from 'redux'
import { ActionsObservable } from 'redux-observable'

import { SubscriptionTypes } from '@neo-commons/store'

import { displayAlertGlobal$ } from '../Epics'

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    SubscriptionTypes.SUBMIT_SIRET_FAILURE,
    // KycSubscriptionTypes.CREATE_KYC_SUBSCRIPTION_FAILURE,
    // KycSubscriptionTypes.GET_KYC_DOCUMENT_FAILURE,
    // KycSubscriptionTypes.GET_KYC_SUBSCRIPTION_DETAILS_FAILURE,
    // KycSubscriptionTypes.GET_KYC_SUBSCRIPTION_LIST_FAILURE,
  ])
}

export default [
  displayDropdownAlert$,
]
