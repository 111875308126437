import React from 'react'
import { Route } from 'react-router-dom'

import { Nav } from 'rsuite'

import { NavItemCustom } from '@neo-web/componentsOld'
import './Page.scss'

export type componentPage = {
  path: string;
  component: React.ReactNode;
  name?: string;
  isAvailable?: boolean;
  isHidden?: boolean;
  pathIsNotExact?: boolean;
}

interface PageProps {
  pages: componentPage[]
}

export const Page: React.FC<PageProps> = (props: PageProps) => {
  const { pages } = props
  return (
    <div className='Page'>
      {pages.filter(page => page.name).length > 0 &&
        <div className='Page_header-container'>
          <Nav appearance='subtle'>
            {pages.map((page, index) => {
              return (
                !page.isHidden &&
                  <NavItemCustom
                    key={index}
                    active={!page.pathIsNotExact}
                    label={page.name}
                    to={page.path}
                    disabled={page.isAvailable === undefined ? false : !page.isAvailable}
                  />
              )
            })}
          </Nav>
        </div>}
      {pages.map((page, index) => {
        return (
          <Route
            key={index}
            exact={!page.pathIsNotExact}
            path={page.path}
            component={() => (
              <div className='Page_body-container'>
                {page.component}
              </div>
            )}
          />
        )
      })}
    </div>
  )
}
