// @format
import { StyleSheet, ViewStyle, TextStyle } from 'react-native'

import { Typo, Colors } from '@neo-commons/styles'

interface NOPickerStyle {
  container: ViewStyle,
  btnAction: ViewStyle,
  btnDisabled: ViewStyle,
  text: TextStyle
}

const styles = StyleSheet.create<NOPickerStyle>({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.white,
    borderRadius: 15,
    marginHorizontal: 0,
    paddingHorizontal: 10,
    paddingVertical: 8,
    justifyContent: 'space-around',
  },
  btnAction: {
    justifyContent: 'center',
    zIndex: 1,
  },
  btnDisabled: {
    opacity: 0.1,
  },
  text: {
    fontFamily: Typo.type.bold,
    color: Colors.black,
    fontSize: 14,
    textAlign: 'center',
    width: 100,
    textTransform: 'capitalize',
    alignSelf: 'center',
  },
})
export default styles
