import React, { FunctionComponent } from 'react'
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native'

import { Colors } from '@neo-commons/styles'

import styles from './styles'

/**
 * RadioInput Properties
 */
export interface RadioInputProps{
  /** Button title */
  text?: string,
  /** Boolean status */
  value?: boolean,
  /** Container Style */
  style?: ViewStyle,
  /** On Radio Input press action */
  onPress: (value: boolean) => void,
  /** Is radio button disabled ?  */
  disabled?: boolean
}

export const RadioInput: FunctionComponent<RadioInputProps> = ({ value, onPress, text, style, disabled }) => {
  const disabledColor = Colors.darkGray
  const primaryButtonColor = value ? Colors.primary : !disabled ? Colors.ghostBlack : disabledColor
  const primaryTextColor = value ? Colors.primary : !disabled ? Colors.black : disabledColor
  const outerButton = [styles.outerButton, { borderColor: primaryButtonColor }, style]
  const innerButton = [styles.innerButton, { backgroundColor: primaryButtonColor }]
  const textButton = [styles.label, { color: primaryTextColor }]

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={() => onPress(!value)}
      style={styles.container}
      accessibilityLabel='radioInput'
    >
      <View style={outerButton}>
        {
          value
            ? <View style={innerButton} />
            : null
        }
      </View>
      {text && <Text style={textButton}>{text}</Text>}
    </TouchableOpacity>
  )
}
