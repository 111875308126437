import React from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { SuccessFailure, SuccessFailureProps } from './SuccessFailure'

export const SuccessContent: React.FC<SuccessFailureProps> = (props) => {
  const { t } = useTranslation()

  return (
    <SuccessFailure
      {...props}
      icon={props?.icon
        ? props.icon
        : <Icon color={Colors.secondary} name='check-circle' size={40} type={IconTypes.FEATHER} />}
      title={props?.title ? props.title : t('app:global:screen:success:title')}
      description={props?.description &&
        <div className='SuccessFailure_description'>
          {typeof props.description === 'string'
            ? <Typography typeface='subtitle'>{props.description}</Typography>
            : props.description}
        </div>}
    />
  )
}
