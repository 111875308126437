import React, { FunctionComponent } from 'react'
import { Text, TextStyle, View, ViewStyle } from 'react-native'

import * as typo from '@neo-commons/styles/src/Typo'
import { NumbersUtils } from '@neo-commons/libraries'

import styles from './styles'

interface OfferTypographyProps {
  style?: TextStyle[] | TextStyle,
  label: string,
  value: number,
  isOffer: boolean,
  offerLabel: string,
  accessibilityLabel?: string,
  labelStyleOverride?: ViewStyle,
  valueStyleOverride?: ViewStyle
}

export const OfferTypography: FunctionComponent<OfferTypographyProps> = (
  {
    label, value, isOffer, offerLabel, style,
    accessibilityLabel, labelStyleOverride, valueStyleOverride,
  }) => {
  const isOfferStyle = isOffer ? styles.offerValue : {}

  return (
    <View style={styles.container}>
      <View style={[styles.labelStyle, labelStyleOverride]}>
        <Text style={[typo.subtitle ?? {}, style]}>
          {label}
        </Text>
      </View>
      <View style={[styles.valueLabel, valueStyleOverride]}>
        <Text accessibilityLabel={accessibilityLabel} style={[typo.subtitle, isOfferStyle]}>
          {NumbersUtils.displayPriceForHuman(value)}
        </Text>
        {isOffer &&
          <Text style={[typo.subtitle, styles.offerText]}>
            {offerLabel}
          </Text>}
      </View>
    </View>
  )
}
