import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { FlexboxGrid } from 'rsuite'
import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Typography } from '@neo-commons/components'

import { Modal } from '@neo-web/componentsOld'

import './EditModal.scss'

interface EditModalProps {
  onClose?: () => void
  title: string
  show: boolean
  content? : React.ReactNode
  footer? : React.ReactNode
}

export const EditModal: React.FC<EditModalProps> = ({
  onClose,
  title,
  show,
  content,
  footer,
}: EditModalProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const alertContent =
    <FlexboxGrid justify='center' className='text-center alert-content'>
      <FlexboxGrid.Item colspan={24}>
        <Typography typeface='info'>
          {t('app:pages:myProfile:components:editModal:subTitle')}
        </Typography>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24}>
        <Typography typeface='info'>
          {t('app:pages:myProfile:components:editModal:content')}
        </Typography>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24} className='EditModal_button'>
        <Button
          skin={ButtonSkin.PRIMARY}
          disabled
          title={t('app:global:takeMeet')}
          onPress={() => {
            // TODO
            console.log('todo')
          }}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24} className='EditModal_button'>
        <Button
          skin={ButtonSkin.OUTLINE_PRIMARY}
          title={t('app:global:callClientService')}
          onPress={() => {
            // TODO
            history.push(Routes.Profile.clientService.path)
          }}
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>

  return (
    <Modal
      displayCross
      dialogClassName='EditModal'
      open={show} onClose={onClose} title={title}
      footer={footer}
    >
      {content || alertContent}
    </Modal>
  )
}
