import React, { FunctionComponent, useEffect, useState } from 'react'
import { Text, View } from 'react-native'

import dayjs from 'dayjs'
import { DateUtils } from '@neo-commons/libraries'

import styles from './styles'

interface TimerProps {
  endDate: string,
  textTimer?: string,
  startDate: string,
  onTimerEnd?: () => void,
  accessibilityLabel?: string,
}

export const Timer: FunctionComponent<TimerProps> = (
  {
    startDate,
    endDate,
    textTimer,
    onTimerEnd,
    accessibilityLabel,
  }) => {
  const [currentTime, setCurrentTime] = useState(dayjs(startDate) ?? dayjs())
  const [timeRemaining, setTimeRemaining] = useState(DateUtils
    .getTimeRemaining(startDate, endDate))
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isEnd) {
        setCurrentTime(dayjs(currentTime).add(1, 'second'))
      }
    }, 1000)
    return () => {
      clearTimeout(timeoutId)
    }
  })

  useEffect(() => {
    const time = DateUtils.getTimeRemaining(currentTime, endDate)
    setTimeRemaining(time)
    if (time === '00:00') {
      setIsEnd(true)
      onTimerEnd && onTimerEnd()
    }
  }, [currentTime, onTimerEnd])

  return (
    <View accessibilityLabel={accessibilityLabel}>
      <Text>
        <Text style={styles.TextTimer}> {textTimer} </Text>
        <Text style={styles.TimerCount}>{timeRemaining}</Text>
      </Text>
    </View>)
}
