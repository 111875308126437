import React from 'react'
// import { useTranslation } from 'react-i18next'

import { Icon, IconTypes, Typography2 } from '@neo-commons/components'

import { OfferDto } from '@afone/neo-core-client/dist/models'

import './NewOfferMinCard.scss'

interface NewOfferMinCardProps {
  offer: OfferDto
  onPress: (offer) => void
}
export const NewOfferMinCard: React.FC<NewOfferMinCardProps> = (props: NewOfferMinCardProps) => {
  const { offer, onPress } = props

  let content: any = null
  try {
    content = JSON.parse(offer.description[0])
  } catch (_) {}

  return (
    <div className='NewOfferMinCard' key={offer.uuid} onClick={() => onPress(offer)}>
      <div className='NewOfferMinCard_row'>
        <h4 className='NewOfferMinCard_text'>{offer.name}</h4>
        <button
          className='NewOfferMinCard_button'
        >
          <span className='NewOfferMinCard_price'>{offer?.price}</span>
          <Icon
            name='chevron-right'
            color='#000'
            type={IconTypes.FEATHER}
            size={18}
          />
        </button>
      </div>
      <Typography2 typeface='body2' style={{ marginTop: -6, color: content?.mainColor }}>
        {'★'.repeat(content?.rating)}
      </Typography2>
      <p className='NewOfferMinCard_text'>{content?.description}</p>
    </div>
  )
}
