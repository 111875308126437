import { AnyAction, Reducer } from 'redux'
import { omit } from 'lodash'
import i18next from 'i18next'
import { AccountTypeDto, TransactionDto, TransactionRefundCreateDto, TransactionUpdateDto } from '@afone/neo-core-client/dist/models'
import { NeobankApi } from '@neo-commons/services'
import { createSelector } from 'reselect'

import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { AlertType, getInitialResourceState, initialResourceState, State } from '../utils'
import { BankAccountTypes } from '../states/bankAccount'

const {
  resourceActionTypes: TransactionActionTypes,
  resourceReducer: TransactionResourceReducer,
  resourceAction: TransactionAction,
  resourceSelector: TransactionResourceSelector,
} = ResourceStateFactory<TransactionDto, 'transaction'>(state => state.transaction, 'transaction')

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

export const TransactionTypes = {
  ...TransactionActionTypes,

  PREPARE_REFUND_REQUEST: 'transaction/PREPARE_REFUND_REQUEST',
  PREPARE_REFUND_SUCCESS: 'transaction/PREPARE_REFUND_SUCCESS',
  PREPARE_REFUND_FAILURE: 'transaction/PREPARE_REFUND_FAILURE',
  PREPARE_REFUND_RESET: 'transaction/PREPARE_REFUND_RESET',

  CREATE_REFUND_REQUEST: 'transaction/CREATE_REFUND_REQUEST',
  CREATE_REFUND_SUCCESS: 'transaction/CREATE_REFUND_SUCCESS',
  CREATE_REFUND_FAILURE: 'transaction/CREATE_REFUND_FAILURE',
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

function getTransactionList (page = 0) {
  const perPage = 20

  return async (dispatch: Dispatch, getState: () => State) => {
    const bankAccountUuid = getState().bankAccount.list!.selectedId
    if (bankAccountUuid === 'prospect-account' ||
        bankAccountUuid === 'prospect-project' ||
        bankAccountUuid === 'total-balance') {
      return
    }
    try {
      if (!bankAccountUuid) throw new Error()

      dispatch({ type: TransactionTypes.LIST_TRANSACTION_REQUEST })

      const responses = await Promise.all([
        page === 0 ? NeobankApi.getInstance().bankAccountApi.getAccountByUuid(bankAccountUuid) : undefined,
        NeobankApi.getInstance().transactionApi.getAccountTransactions(
          bankAccountUuid, perPage, page, undefined, '2000-01-01'
        ),
      ])

      const getAccountResponse = responses[0]
      const getAccountTransactionsResponse = responses[1]
      const data = getAccountTransactionsResponse.status === 204 ? [] : getAccountTransactionsResponse.data
      const paginationEnded = getAccountTransactionsResponse.status === 204

      if (getAccountResponse && getAccountResponse?.data?.type !== (AccountTypeDto.AGGREGATED || AccountTypeDto.PROJECT)) {
        dispatch({
          type: BankAccountTypes.BYID_BANKACCOUNT_SUCCESS,
          data: getAccountResponse.data,
        })
      }

      dispatch({
        type: TransactionTypes.LIST_TRANSACTION_SUCCESS,
        data,
        page,
        perPage,
        paginationEnded,
        listIdentifier: bankAccountUuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      if (bankAccountUuid !== getState().bankAccount.list!.selectedId) {
        return
      }
      dispatch({
        type: TransactionTypes.LIST_TRANSACTION_FAILURE,
        errorMessage,
      })
    }
  }
}

function getTransactionById (id) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: TransactionTypes.BYID_TRANSACTION_REQUEST })

    try {
      const bankAccountUuid = getState().bankAccount.list!.selectedId
      if (!bankAccountUuid) throw new Error()

      const getTransactionReponse =
        await NeobankApi.getInstance().transactionApi.getAccountTransactionById(bankAccountUuid, id)

      dispatch({
        type: TransactionTypes.BYID_TRANSACTION_SUCCESS,
        data: getTransactionReponse.data,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({
        type: TransactionTypes.BYID_TRANSACTION_FAILURE,
        errorMessage,
      })
    }
  }
}

function updateTransaction (id, transactionUpdate: TransactionUpdateDto) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: TransactionTypes.UPDATE_TRANSACTION_REQUEST })

    try {
      const bankAccountUuid = getState().bankAccount.list!.selectedId
      if (!bankAccountUuid) throw new Error()

      const getUpdateReponse =
        await NeobankApi.getInstance().transactionApi.updateTransaction(bankAccountUuid, id, transactionUpdate)

      dispatch({
        type: TransactionTypes.UPDATE_TRANSACTION_SUCCESS,
        data: getUpdateReponse.data,
        errorMessage: i18next.t('neo-commons:transaction:updated'),
        alertType: AlertType.SUCCESS,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({
        type: TransactionTypes.UPDATE_TRANSACTION_FAILURE,
        errorMessage,
      })
    }
  }
}

function refundPrepare (prepare: PrepareRefund) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: TransactionTypes.PREPARE_REFUND_REQUEST })

    try {
      dispatch({
        type: TransactionTypes.PREPARE_REFUND_SUCCESS,
        prepareRefund: {
          transaction: prepare?.transaction,
          amount: prepare?.amount,
        },
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({
        type: TransactionTypes.PREPARE_REFUND_FAILURE,
        errorMessage,
      })
    }
  }
}

function refund (payload: {
  prepare: PrepareRefund,
  xValidationOnly?: number,
}) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: TransactionTypes.CREATE_REFUND_REQUEST })

    try {
      const requestResponse = await NeobankApi.getInstance().transactionApi.createTransactionRefund(
        payload.prepare?.transaction?.accountUuid ?? '',
        payload.prepare?.transaction?.id ?? '',
        payload.xValidationOnly === 1 ? 1 : 0,
        { amount: payload.prepare?.amount ?? 0 } as TransactionRefundCreateDto,
      )
      dispatch({ type: TransactionTypes.CREATE_REFUND_SUCCESS })
      return requestResponse
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({
        type: TransactionTypes.CREATE_REFUND_FAILURE,
        errorMessage,
      })
      throw error
    }
  }
}

function refundReset () {
  return async (dispatch: Dispatch) => {
    dispatch({ type: TransactionTypes.PREPARE_REFUND_RESET })
  }
}

export const TransactionActions = {
  ...TransactionAction,
  getTransactionList,
  getTransactionById,
  updateTransaction,
  refundPrepare,
  refundReset,
  refund,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export interface PrepareRefund {
  transaction?: TransactionDto
  amount?: number
}

type BaseTransactionState = Omit<ResourceState<TransactionDto>, 'create' | 'update'>
type TransactionStateList = BaseTransactionState['list'] & { paginationEnded: boolean }
export type TransactionState = Omit<ResourceState<TransactionDto>, 'create' | 'update'>
  & { list: TransactionStateList }
  & { prepareRefund?: PrepareRefund }

const initialState: TransactionState = {
  ...omit(initialResourceState, 'create', 'update'),
  list: {
    ...getInitialResourceState().list,
    paginationEnded: false,
  },
  prepareRefund: undefined,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */
const transactionSelector = state => state.transaction

export const TransactionSelectors = {
  ...TransactionResourceSelector,
  prepareRefund: createSelector(
    transactionSelector,
    resource => resource.prepareRefund),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const transaction: Reducer = (state = initialState, action: AnyAction): TransactionState => {
  switch (action.type) {
    case TransactionTypes.PREPARE_REFUND_SUCCESS:
      return {
        ...state,
        prepareRefund: action.prepareRefund,
      }

    case TransactionTypes.PREPARE_REFUND_RESET:
      return {
        ...state,
        prepareRefund: { ...initialState.prepareRefund },
      }

    default:
      return {
        ...TransactionResourceReducer(state, action, {
          identifier: 'id',
          isPaginate: true,
          initialState,
        }),
      }
  }
}
