import React, { ReactElement, ReactNode } from 'react'

import { Button, ButtonSkin, Icon, IconTypes, Typeface, Typography, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import './CardDetails.scss'

export interface CardDetailsItem {
  label?: string,
  icon?: ReactElement<typeof Icon>
  value: ReactElement<typeof Typography> | string,
  typeface?: Typeface,
  show?: boolean,
  action?: () => void,
  actionIcon?: ReactElement<typeof Icon>,
}

export enum CardDetailsSkin {
  PRIMARY = 'skin-primary',
  SECONDARY = 'skin-secondary',
}

interface CardDetailsProps {
  title?: string,
  list?: CardDetailsItem[],
  justifyBetween?: boolean,
  children?: ReactNode,
  skin?: CardDetailsSkin,
}

export const CardDetails: React.FC<CardDetailsProps> = (props) => {
  return (
    <div className='CardDetails'>
      {props?.title && (
        <div className='CardDetails_title'>
          <Typography2 typeface='overline'>{props.title}</Typography2>
        </div>
      )}
      <div className={`CardDetails_box classic-box box-border ${props?.skin ? props.skin : ''}`}>
        {props?.list?.map((item, index) => (item.value || item?.show) && (
          <div
            key={index}
            className={`CardDetails_item ${props.justifyBetween ? 'between' : ''} ${item?.icon ? 'hasIcon' : ''}`}
          >
            {item?.icon && (
              <div className='CardDetails_item_icon'>{item.icon}</div>
            )}
            {item?.label && (
              <Typography typeface={props.justifyBetween ? 'subtitle' : 'subtitleGrey'}>{item.label}</Typography>
            )}
            {typeof item.value === 'string'
              ? <Typography typeface={item?.typeface ? item.typeface : 'smallText'}>{item.value}</Typography>
              : item.value}
            {item?.action && (
              <div className='CardDetails_item_edit'>
                <Button
                  skin={ButtonSkin.TRANSPARENT}
                  onPress={() => item.action()}
                  title={item?.actionIcon ??
                    <Icon name='edit-3' color={Colors.primary} type={IconTypes.FEATHER} size={18} />}
                />
              </div>
            )}
          </div>
        ))}
        {props?.children}
      </div>
    </div>
  )
}
