import React, { FunctionComponent } from 'react'
import { TouchableOpacity, Text, View, TouchableOpacityProps, ViewStyle } from 'react-native'

import { debounce } from 'lodash'

import { Icon } from '..'
import { useStylesheet } from '../utils'

import styles from './styles'

export enum ButtonSkin {
  DEFAULT='DEFAULT',
  DEFAULT_LIGHT='DEFAULT_LIGHT',
  OUTLINE_LIGHT='OUTLINE_LIGHT',
  PRIMARY='PRIMARY',
  OUTLINE_PRIMARY='OUTLINE_PRIMARY',
  SECONDARY='SECONDARY',
  OUTLINE_SECONDARY='OUTLINE_SECONDARY',
  OUTLINE_RED='OUTLINE_RED',
  OUTLINE_GRAY='OUTLINE_GRAY',
  LINK='LINK',
  PRIMARY_LINK='PRIMARY_LINK',
  TRANSPARENT='TRANSPARENT',
  TRANSPARENT_PRIMARY='TRANSPARENT_PRIMARY',
}
/**
 * Button Properties
 */
export interface ButtonProps extends TouchableOpacityProps {
  /**  Button title */
  title: string | React.ReactElement<typeof Icon>,
  /**  Button subtitle */
  subtitle?: string | React.ReactElement<typeof Icon>,
  /** Button style */
  skin?: ButtonSkin,
  /** Button right icon. Use Icons */
  rightIcon?: React.ReactElement<typeof Icon>
  /** Button left icon. Use Icons */
  leftIcon?: React.ReactElement<typeof Icon>
  /** Is button text wrapping ? */
  small?: boolean
  /** Button container style */
  containerStyle?: ViewStyle | ViewStyle[]
  /** Is Button flat design ? */
  flat?: boolean
  /** Text color */
  textColor?: string
  /** Override Style button */
  overrideStyleButton?: ViewStyle
  /** Ovveride Text style button */
  overrideTextStyleButton?: ViewStyle
  /** Text color */
  additionalButtonStyle?: ViewStyle
  /** Delay of the onPressDebounce */
  shouldDeactivateOnPressDebounce?: boolean
}

export const Button: FunctionComponent<ButtonProps> = ({
  flat,
  skin = ButtonSkin.PRIMARY,
  small,
  leftIcon,
  rightIcon,
  disabled,
  containerStyle,
  textColor,
  title,
  subtitle,
  overrideStyleButton = {},
  overrideTextStyleButton = {},
  additionalButtonStyle = {},
  shouldDeactivateOnPressDebounce,
  ...props
}: ButtonProps) => {
  const s = useStylesheet(styles,
    {
      skin,
      props: {
        skin,
        disabled,
        small,
        flat,
        additionalButtonStyle,
        overrideStyleButton,
        overrideTextStyleButton,
        subtitle,
        textColor,
        leftIcon,
        rightIcon,
      },
    })

  const onPressDebounce = (onPress) => {
    onPress && onPress()
  }

  const onPressCallBack = React.useCallback(debounce(onPressDebounce, shouldDeactivateOnPressDebounce ? 0 : 1000,
    {
      leading: true,
      trailing: false,
    }), [])

  return (
    <View style={containerStyle}>
      <TouchableOpacity
        {...props}
        onPress={() => (props.onPress && !disabled) ? onPressCallBack(props.onPress) : null}
        onPressOut={() => (props.onPressOut && !disabled) ? onPressCallBack(props.onPressOut) : null}
        onPressIn={() => (props.onPressIn && !disabled) ? onPressCallBack(props.onPressIn) : null}
        style={s.button}
        activeOpacity={disabled ? 1 : 0.5}
      >
        <View style={s.textContainer}>
          {leftIcon &&
            <View style={s.leftIconContainer} accessibilityLabel='leftIcon'>
              {leftIcon}
            </View>}
          <Text style={s.title}>{title}</Text>
          {subtitle &&
            <Text style={s.subtitle}>{subtitle}</Text>}
          {rightIcon &&
            <View style={s.rightIconContainer} accessibilityLabel='rightIcon'>
              {rightIcon}
            </View>}
        </View>
      </TouchableOpacity>
    </View>
  )
}
