import { RoutesInterface } from './Routes'

const index = 'app:pages:credit:step0:titleMessage'

export class CreditRoutes {
  public static index: RoutesInterface = {
    path: '/credit',
    name: index,
    available: true,
  }

  public static creditChoices: RoutesInterface = {
    path: CreditRoutes.index.path + '/choices',
    name: index,
    available: true,
  }

  public static debitCard: RoutesInterface = {
    path: CreditRoutes.index.path + '/debitCard',
    name: index,
    available: true,
  }

  public static transfer: RoutesInterface = {
    path: CreditRoutes.index.path + '/transfer',
    name: index,
    available: true,
  }

  public static amount: RoutesInterface = {
    path: CreditRoutes.debitCard.path + '/amount',
    name: 'app:pages:credit:step1:amount:titleMessage',
    available: true,
  }

  public static chooseCard: RoutesInterface = {
    path: CreditRoutes.debitCard.path + '/chooseCard',
    name: 'app:pages:credit:chooseDebitCard:title',
    available: true,
  }

  public static cvv: RoutesInterface = {
    path: CreditRoutes.debitCard.path + '/cvv',
    name: 'app:pages:credit:cvvCheck:title',
    available: true,
  }

  public static summary: RoutesInterface = {
    path: CreditRoutes.debitCard.path + '/summary',
    name: 'app:pages:credit:step3:titleMessage',
    available: true,
  }

  public static validate: RoutesInterface = {
    path: CreditRoutes.debitCard.path + '/validate',
    name: 'app:global:bravo',
    available: true,
  }

  public static failure: RoutesInterface = {
    path: CreditRoutes.debitCard.path + '/failure',
    name: 'app:global:failure',
    available: true,
  }

  public static addNewCard: RoutesInterface = {
    path: CreditRoutes.debitCard.path + '/addNewCard',
    name: 'app:pages:credit:step2:cbInfos:titleMessage',
    available: true,
  }
}
