import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { TunnelContent, TunnelJustifyContent } from 'UI/Components/Layout/TunnelContent'
import { Routes } from 'Routes/Routes'

import { DocumentUploadActions, SubscriptionSelectors } from '@neo-commons/store'
import { Icon, IconTypes, MenuItemCard } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { EnumUtils, SubscriptionUtils } from '@neo-commons/libraries'

import { SubscriptionDto } from '@afone/neo-core-client/dist/models'

import './DocumentsInvalid.scss'

export const DocumentsInvalid: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const kycDocuments = useMemo(() => SubscriptionUtils.getKycDocuments(subscription), [subscription])

  useEffect(() => {
    if (!SubscriptionUtils.isKycStepInProgress(subscription)) {
      history.goBack()
    }
  }, [subscription])

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      icon={<Icon name='info' type={IconTypes.FEATHER} size={50} color={Colors.secondary} />}
      subtitle={t('app:pages:subscription:global:reasonDocumentsProcessingFailure:title')}
      description={t('app:pages:subscription:global:reasonDocumentsProcessingFailure:description')}
    >
      <div className='DocumentsInvalid_container'>
        {kycDocuments?.map((document, index) =>
          <div className='DocumentsInvalid_container_item' key={index}>
            <MenuItemCard
              title={t(EnumUtils.getKycTypeDtoKey(document.type))}
              subtitle={document?.reason ? t(EnumUtils.getReasonDtoKey(document.reason)) : undefined}
              onPress={async () => {
                await dispatch(DocumentUploadActions.prepare({
                  name: t(EnumUtils.getKycTypeDtoKey(document.type)),
                  documentType: document.type,
                }))
                history.push(Routes.DocumentUpload.index.path)
              }}
            />
          </div>
        )}
      </div>
    </TunnelContent>
  )
}
