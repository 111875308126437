import React from 'react'
import { Route } from 'react-router-dom'

import { Container, FlexboxGrid } from 'rsuite'

import { Routes } from '../../../Routes/Routes'
import {
  Start3DS,
  ConfirmEmail,
  KYCRetrieveDocument,
  DocumentChoiceExternal,
  LinkExpired,
  ActionLink,
  HandleSignatureCallback,
} from '../../Pages/External'

export const ExternalPage: React.FC = () => {
  const pages: { path: string, content: React.ReactNode }[] = [
    { path: Routes.External.start3ds.path, content: <Start3DS /> },
    { path: Routes.External.confirmEmail.path, content: <ConfirmEmail /> },
    { path: Routes.External.linkHandler.path, content: <ActionLink /> },
    { path: Routes.External.KYCRetrieveDocument.path, content: <KYCRetrieveDocument /> },
    { path: Routes.External.linkExpired.path, content: <LinkExpired /> },
    { path: Routes.External.documentsUploadAffiliate.path, content: <DocumentChoiceExternal /> },
    { path: Routes.External.signature.path, content: <HandleSignatureCallback /> },
  ]
  return (
    <div className='show-container'>
      <Container>
        <FlexboxGrid justify='center'>
          <FlexboxGrid.Item colspan={24}>
            {pages.map((page, index) => {
              return (
                <Route path={page.path} key={index}>
                  {page.content}
                </Route>
              )
            })}
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Container>
    </div>
  )
}
