import React, { FunctionComponent } from 'react'
import { Image } from 'react-native'

import { AccountDto, AccountTypeDto, ClientDto, ClientTypeDto } from '@afone/neo-core-client/dist/models'
import { Colors } from '@neo-commons/styles'
import { BankAccountUtils } from '@neo-commons/libraries'

import { Icon, IconTypes } from '..'
import { BankAccountLogo } from '../../assets/images'

import { BankAccountCardLarge } from './BankAccountCardLarge/BankAccountCardLarge'
import { BankAccountCardSmall } from './BankAccountCardSmall/BankAccountCardSmall'

export enum BankAccountCardSkin {
  DEFAULT = 'DEFAULT',
  CENTERED = 'CENTERED',
  SECONDARY = 'SECONDARY'
}
export enum BankAccountAction {
  CREDIT = 'CREDIT',
  IBAN = 'IBAN',
  TRANSFER = 'TRANSFER',
  COLLECT = 'COLLECT'
}

interface BankAccountCardProps {
  bankAccount: Partial<AccountDto>
  client: ClientDto,
  isLarge?: boolean,
  isDisabled?: boolean,
  isSelected?: boolean,
  withoutIcon?: boolean,
  skin?: BankAccountCardSkin,
  onPress?: () => void,
  onActionPress?: (action: BankAccountAction) => void
}

export const BankAccountCard: FunctionComponent<BankAccountCardProps> = (
  {
    bankAccount,
    client,
    isLarge = false,
    isDisabled,
    isSelected,
    withoutIcon,
    skin,
    onPress,
    onActionPress,
  }
) => {
  const getIcon = (): Image | typeof Icon => {
    let result

    switch (bankAccount?.type) {
      case AccountTypeDto.MAIN: {
        if (client?.type === ClientTypeDto.CORPORATE) {
          result =
            <Image style={{ height: 24, width: 24 }} source={isSelected ? BankAccountLogo.NoelseProWhite : BankAccountLogo.NoelsePro} />
        } else {
          result =
            <Icon
              name='dark-logo'
              color={isSelected ? Colors.white : (isDisabled ? Colors.darkGreyText : Colors.black)}
              type={IconTypes.NEOFONT}
              size={24}
            />
        }
        break
      }
      case AccountTypeDto.AGGREGATED: {
        result =
          <Icon
            name='dark-logo'
            color={Colors.black}
            type={IconTypes.NEOFONT}
            size={24}
          />
          // TODO : Ajouter le lien du compte agrégé (une fois le back prêt)
        // <Image style={{ height: 24, width: 24 }} source={{ uri: 'https://reactnative.dev/img/tiny_logo.png' }} />
        break
      }
      case AccountTypeDto.PROJECT: {
        result =
          <Icon
            name={bankAccount.icon ? BankAccountUtils.getFrontIconString(bankAccount.icon) : 'euro'}
            color={Colors.white}
            type={IconTypes.MATERIAL}
            withBackground
            containerStyle={{
              backgroundColor: Colors.secondary,
              alignSelf: 'center',
              alignItems: 'center',
              paddingHorizontal: 4,
              paddingVertical: 4,
            }}
            size={16}
          />
        break
      }
      case AccountTypeDto.POS: {
        result =
          <Icon
            name='tpe'
            color={Colors.white}
            withBackground
            type={IconTypes.NEOFONT}
            containerStyle={{
              backgroundColor: Colors.secondary,
              alignSelf: 'center',
              alignItems: 'center',
              paddingHorizontal: 4,
              paddingVertical: 4,
            }}
            size={16}
          />
        break
      }
    }
    return result
  }
  return (
    <>
      {isLarge
        ? (
          <BankAccountCardLarge
            bankAccount={bankAccount}
            clientType={client?.type}
            icon={getIcon()}
            isDisabled={isDisabled}
            skin={skin}
            onActionPress={onActionPress}
          />
        )
        : (
          <BankAccountCardSmall
            bankAccount={bankAccount}
            icon={getIcon()}
            isSelected={isSelected}
            withoutIcon={withoutIcon}
            skin={skin}
            onPress={onPress}
          />
        )}
    </>
  )
}
