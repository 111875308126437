import { OrderUpdateType, PrepareCardOrder } from '@neo-commons/store'

import { AbstractPolicy } from '../AbstractPolicy'
import { Routes } from '../../Routes/Routes'

export class OrderPolicy extends AbstractPolicy {
  public static orderRedirect (): string {
    const preparedCardOrder: PrepareCardOrder = this.state.card.preparedCardOrder

    switch (true) {
      case this.canStep7(preparedCardOrder) :
        return Routes.Services.validate.path
      case !this.canStep7(preparedCardOrder) && this.canStep6(preparedCardOrder) &&
            preparedCardOrder?.updateType === OrderUpdateType.NONE:
        return Routes.Services.summary.path
      case (!this.canStep6(preparedCardOrder) && this.canStep5(preparedCardOrder)) ||
           (this.canStep5(preparedCardOrder) && preparedCardOrder?.updateType === OrderUpdateType.ADDRESS):
        return Routes.Services.address.path
      case (!this.canStep5(preparedCardOrder) && this.canStep4(preparedCardOrder)) ||
           (this.canStep4(preparedCardOrder) && preparedCardOrder?.updateType === OrderUpdateType.DELIVERY_MODE):
        return Routes.Services.deliveryMode.path
      // Step 2 is skipped if isBundle is true
      case (!this.canStep4(preparedCardOrder) && this.canStep3(preparedCardOrder)) ||
           (this.canStep3(preparedCardOrder) && preparedCardOrder?.updateType === OrderUpdateType.PIN_CODE) ||
           (this.hasAlreadyDigital(preparedCardOrder) && preparedCardOrder?.updateType === OrderUpdateType.PIN_CODE):
        return Routes.Services.code.path
      case (!this.canStep3(preparedCardOrder) && this.canStep2(preparedCardOrder) &&
            preparedCardOrder?.updateType === OrderUpdateType.NONE) ||
           (this.canStep2(preparedCardOrder) && preparedCardOrder?.updateType === OrderUpdateType.PHYSICAL_PROMO):
        return Routes.Services.physicalPromo.path
      case (this.canStep1(preparedCardOrder) && !this.hasAlreadyDigital(preparedCardOrder)) ||
           (this.canStep1(preparedCardOrder) && preparedCardOrder?.updateType === OrderUpdateType.CHOICES_DEBIT_CARD):
        return Routes.Services.choicesDebitCard.path
      case this.canStep1(preparedCardOrder) && this.hasAlreadyDigital(preparedCardOrder):
        return Routes.Services.physical.path
      case this.canStep0(preparedCardOrder):
        return Routes.Services.selectCardOwner.path
      default:
        return Routes.Services.index.path
    }
  }

  public static canStep0 (preparedCardOrder: PrepareCardOrder): boolean {
    return preparedCardOrder !== undefined
  }

  public static canStep1 (preparedCardOrder: PrepareCardOrder): boolean {
    return preparedCardOrder?.withPhysicalCard || preparedCardOrder?.personUuid !== undefined
  }

  public static canStep2 (preparedCardOrder: PrepareCardOrder): boolean {
    return !this.isBundle(preparedCardOrder) && preparedCardOrder?.offer !== undefined
  }

  public static canStep3 (preparedCardOrder: PrepareCardOrder): boolean {
    return this.isBundle(preparedCardOrder)
  }

  public static canStep4 (preparedCardOrder: PrepareCardOrder): boolean {
    return preparedCardOrder?.updateType === OrderUpdateType.DELIVERY_MODE || preparedCardOrder?.keypadDataCardPin?.isFilled
  }

  public static canStep5 (preparedCardOrder: PrepareCardOrder): boolean {
    return preparedCardOrder?.deliveryData?.mode !== undefined
  }

  public static canStep6 (preparedCardOrder: PrepareCardOrder): boolean {
    return (preparedCardOrder?.deliveryData?.user?.firstname?.length > 0 &&
      preparedCardOrder?.deliveryData?.user?.lastname?.length > 0 &&
      preparedCardOrder?.deliveryData?.address?.country?.name.length > 0 &&
      preparedCardOrder?.deliveryData?.address?.city?.length > 0 &&
      preparedCardOrder?.deliveryData?.address?.street?.length > 0 &&
      preparedCardOrder?.deliveryData?.address?.zipCode?.length > 0) ||
      (!preparedCardOrder?.withPhysicalCard && !preparedCardOrder?.cardUuid && this.canStep2(preparedCardOrder))
  }

  public static canStep7 (preparedCardOrder: PrepareCardOrder): boolean {
    return preparedCardOrder?.ui?.isCgvAccepted
  }

  private static isBundle (preparedCardOrder: PrepareCardOrder): boolean {
    return preparedCardOrder?.isBundle
  }

  private static hasAlreadyDigital (preparedCardOrder: PrepareCardOrder): boolean {
    return !!preparedCardOrder?.cardUuid
  }
}
