import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FlexboxGrid } from 'rsuite'
import { StateTypes } from 'Store'
import { PasswordKeypad } from 'UI/Components/Auth/PasswordKeypad'
import { AppConfig } from 'Config/AppConfig'

import { Button, ButtonSkin, Typography } from '@neo-commons/components'
import { RecoverPasswordStep, RecoverPasswordSteps, StepComponentProps } from '@neo-commons/policies'
import { usePasswordKeypad } from '@neo-commons/hooks'

import { KeypadFieldDto, ValidatePasswordDto } from '@afone/neo-core-client/dist/models'

type CodeProps = StepComponentProps<RecoverPasswordSteps[RecoverPasswordStep.Code]>
export const Code: React.FC<CodeProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const signInData = useSelector((state: StateTypes) => state?.signIn?.data)
  const passwordKeypadData = usePasswordKeypad({
    phone: signInData?.phone,
    phoneCountryCode: signInData?.countryCode,
    field: KeypadFieldDto.PASSWORD,
    passwordSize: AppConfig.PASS_CODE_SIZE,
  })

  const onValidate = async () => {
    try {
      const validatePassword: ValidatePasswordDto = {
        password: passwordKeypadData.password.map(e => e.value),
        phone: passwordKeypadData.phone,
        field: passwordKeypadData.field,
      }
      nextStep(validatePassword)
      passwordKeypadData.reset()
    } catch (error) {
      passwordKeypadData.reset(error)
    }
  }

  return (
    <FlexboxGrid justify='space-between' align='middle' className='text-center'>

      <FlexboxGrid.Item colspan={24} className='pb-xl'>
        <Typography typeface='bigTitle'>
          {t('app:pages:recoverPassword:step3:code:titleMessage')}
        </Typography>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24} className='py-md'>
        <div className='div-center'>
          <PasswordKeypad
            {...passwordKeypadData}
            withoutValidate
          />
        </div>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24}>
        <Button
          title={t('app:global:validate')} skin={ButtonSkin.PRIMARY}
          disabled={!passwordKeypadData.isFilled}
          onPressOut={onValidate}
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}
