import { Platform, StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

export default StyleSheet.create({
  currencyText: {
    ...Typo.info,
    fontSize: 55,
    maxHeight: 60,
    height: 60,
    lineHeight: 60,
    color: Colors.black,
    paddingLeft: 5,
    ...Platform.select({
      native: {
        lineHeight: 56,
      },
    }),
  },
  currencyTextRed: {
    ...Typo.info,
    fontSize: 55,
    maxHeight: 60,
    height: 60,
    color: Colors.error,
    lineHeight: 60,
    paddingLeft: 5,
    ...Platform.select({
      native: {
        lineHeight: 56,
      },
    }),
  },
  container: {
    paddingLeft: 0,
    margin: 0,
    height: 60,
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 80,
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    margin: 0,
    alignItems: 'flex-end',
  },
  input: {
    ...Typo.infoRegular,
    width: '100%',
    fontSize: 55,
    maxHeight: 60,
    height: 60,
    textAlign: 'right',
    marginRight: 0,
    marginTop: 0,
    paddingTop: 0,
    borderBottomWidth: 0,
    ...Platform.select({
      native: {
        width: undefined,
        minWidth: 20,
      },
    }),
  },
  inputRed: {
    ...Typo.infoRegular,
    width: '100%',
    fontSize: 55,
    maxHeight: 60,
    height: 60,
    textAlign: 'right',
    marginRight: 0,
    marginTop: 0,
    paddingTop: 0,
    color: Colors.error,
    borderBottomWidth: 0,
    ...Platform.select({
      native: {
        width: undefined,
        minWidth: 20,
      },
    }),
  },
  inputOnFocus: {
    backgroundColor: Colors.primary,
  },
  inputOnError: {
    ...Platform.select({
      native: {
        width: '100%',
        textAlign: 'center',
      },
    }),
  },
  inputErrorStyle: {
    display: 'flex',
  },
})
