import * as React from 'react'
import { Route as RouteReactRouter, RouteProps as RoutePropsReactRouter } from 'react-router'
import { useLocation, matchPath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { AppConfigTxt } from 'Config/AppConfig'

import { ComingSoonModal } from '@neo-web/componentsOld'

import { StateTypes } from '../../../Store'

interface RouteProps extends RoutePropsReactRouter {
  component?: React.FC
}

export const Route: React.FC<RouteProps> = (props: RouteProps) => {
  let result
  const routeAvailableState = useSelector((state: StateTypes) => state.routes?.route.available)
  const loadingState = useSelector((state: StateTypes) => state.routes?.ui.loading)
  const location = useLocation()
  const [previousUrl, setPreviousUrl] = useState(location.pathname)
  const { t } = useTranslation()

  const previous = () => {
    if (previousUrl !== location.pathname) {
      setPreviousUrl(previousUrl)
    }
  }

  useEffect(() => {
    previous()
  }, [location.pathname])

  const isInAvailableRoute = routeAvailableState.filter((value) => {
    const match = matchPath(location.pathname, {
      path: value.path ?? value,
      strict: true,
      sensitive: true,
    })
    return match && match.isExact
  })

  if (isInAvailableRoute.length > 0) {
    const slug = isInAvailableRoute[0].slug
    const routeName = isInAvailableRoute[0].name
    let pageTitle = (slug ? t(slug) + (routeName ? ' - ' : '') : '') + (routeName !== 'origin' ? t(routeName) : '')
    pageTitle += (pageTitle ? ' | ' : '') + AppConfigTxt.NOELSE

    result =
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <RouteReactRouter {...props} />
      </>
  } else {
    result = (
      <>
        {(!loadingState && routeAvailableState.length > 0) && <RouteReactRouter component={ComingSoonModal} />}
      </>
    )
  }

  return result
}
