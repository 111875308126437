import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { ExternalRoutes } from 'Routes/ExternalRoutes'
import { RegisterRoutes } from 'Routes/RegisterRoutes'

import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  Typography,
} from '@neo-commons/components'
import { Dispatch, LinkActions, LinkActionTypes, SignupActions } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { NeobankApi } from '@neo-commons/services'

import { LinkTypeDto, ErrorCodeDto } from '@afone/neo-core-client'

import { UrlUtils } from '@neo-web/utils'
import { LazyLoadingContainer, Tunnel } from '@neo-web/componentsOld'

import { ActionObject, MailActionPolicy } from '../../../../Policies/External/MailActionPolicy'
import { Routes } from '../../../../Routes/Routes'

import './ActionLink.scss'

const DEFAULT_NEGATIVE_ANSWER = 'no'

export const ActionLink: React.FC = () => {
  const param: any = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()
  const history = useHistory()
  const location = useLocation()

  const [action, setAction] = useState<any>()
  const [actionObject, setActionObject] = useState<ActionObject|undefined>(undefined)
  const [isExpired, setIsExpired] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      try {
        const result: any = await dispatch(LinkActions.getLink(param.id))
        const action = {
          type: result.type,
          confirm:
            UrlUtils.deserialize(location.search).confirm === DEFAULT_NEGATIVE_ANSWER,
        }

        setAction({ ...result, ...action })
      } catch (error) {
        const codeError = error?.cause
        if (codeError === ErrorCodeDto.C4000) {
          setIsExpired(true)
          setActionObject({
            title: t('app:pages:mail:expiry:title'),
            subTitle: error?.message,
            buttonText: t('app:pages:mail:expiry:newMail'),
          })
        } else {
          setActionObject({
            title: t('app:pages:mail:expiry:title'),
            subTitle: error,
            buttonText: t('app:pages:mail:expiry:buttonText'),
          })
        }
      }
    })()
  }, [param.id, dispatch, location])

  useEffect(() => {
    if (action) {
      (async () => {
        try {
          switch (action.type) {
            case LinkTypeDto.CREATE_OFFLINE_USER:
              await dispatch(SignupActions.startCompletingOfflineRegistration({
                firstName: action.data.firstName,
                lastName: action.data.lastName,
                phone: action.data.phone,
                otpEmailUuid: action.data.otpEmailUuid,
                token: action.token,
              }))
              history.push(RegisterRoutes.index.path)
              break
            case LinkTypeDto.KYC_DOCUMENT_REQUEST_LINK:
              await NeobankApi.getInstance().setAuthToken(action.token)
              history.push(ExternalRoutes.documentsUploadAffiliate.path)
              break
            default:
              NeobankApi.getInstance().setAuthToken(action.token)
              setActionObject(await MailActionPolicy.processMailAction(
                {
                  ...action,
                }))
              NeobankApi.getInstance().setAuthToken(null)
              break
          }
          dispatch(LinkActions.successLink())
        } catch (e) {
          console.error('failed to handle link')
        }
      })()
    }
  }, [action])

  return (
    <Tunnel
      component={(
        <LazyLoadingContainer events={[LinkActionTypes.BYID_LINK_REQUEST]}>
          <div className='EmailHandler_container'>
            {actionObject &&
              <>
                <div className='EmailHandler_item'>
                  <Icon
                    color={Colors.secondary}
                    name='check-circle'
                    size={40}
                    type={IconTypes.FEATHER}
                  />
                </div>
                <div className='EmailHandler_item'>
                  <Typography typeface='bigTitle'>
                    {t(actionObject.title, { device: actionObject.data })}
                  </Typography>
                </div>

                <div className='EmailHandler_item'>
                  <Typography typeface='subtitle'>
                    {actionObject.subTitle}
                  </Typography>
                </div>
                <div className='EmailHandler_action'>
                  {!isExpired &&
                    <Button
                      title={t(actionObject.buttonText)}
                      skin={ButtonSkin.PRIMARY}
                      onPress={() => history.push(Routes.Synthesis.index.path)}
                    />}
                </div>
              </>}
          </div>
        </LazyLoadingContainer>
      )}
    />
  )
}
