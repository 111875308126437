import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  Typography,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Modal } from '@neo-web/componentsOld'

interface CloseAccountModalProps {
  open: boolean,
  onClose: () => void,
  onValidate?: () => void,
}

export const CloseAccountModal: React.FC<CloseAccountModalProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={props.open}
      displayCross
      backdrop='static'
      onClose={() => props.onClose()}
      title={t('neo-commons:pages:services:pos:return:summary:modalLastPos:title')}
      titleIcon={<Icon color={Colors.secondary} name='alert-circle' size={40} type={IconTypes.FEATHER} />}
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          containerStyle={{ marginTop: 12 }}
          title={t('neo-commons:pages:services:pos:return:summary:modalLastPos:button')}
          onPress={() => props.onValidate()}
        />
      }
    >
      <Typography typeface='content'>
        {t('neo-commons:pages:services:pos:return:summary:modalLastPos:text1')}
        <br /><br />
        {t('neo-commons:pages:services:pos:return:summary:modalLastPos:text2')}
      </Typography>
    </Modal>
  )
}
