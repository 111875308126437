import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, useHistory } from 'react-router-dom'

import { store } from 'Store'
import { Routes } from 'Routes/Routes'

import { posOrderPolicy, POSOrderStep } from '@neo-commons/policies'
import { useWizardPolicy } from '@neo-commons/hooks'
import { OfferSelectors, PosActions } from '@neo-commons/store'
import { POSUtils } from '@neo-commons/libraries'

import { OfferDto, OfferTypeDto } from '@afone/neo-core-client/dist/models'

import { PageDetails } from '@neo-web/componentsOld'

import {
  PosSelection,
  AddressCheck,
  AddressForm,
  Summary,
  Process,
  Failure,
  Success,
  RenamePosAccount,
} from './index'

import './Order.scss'

export const Order: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const offers: OfferDto[] = useSelector(OfferSelectors.listByType(OfferTypeDto.POS))

  const { currentStep, steps: wizardSteps, goBack, context } = useWizardPolicy(store, posOrderPolicy, {
    [POSOrderStep.ModelChoice]: PosSelection,
    [POSOrderStep.AddressCheck]: AddressCheck,
    [POSOrderStep.AddressForm]: AddressForm,
    [POSOrderStep.Summary]: Summary,
    [POSOrderStep.Process]: Process,
    [POSOrderStep.Failure]: Failure,
    [POSOrderStep.Success]: Success,
    [POSOrderStep.RenameAccount]: RenamePosAccount,
  }, {
    onDone: () => null,
  })

  const previousStep = () => {
    if (context?.stack && context?.stack?.length > 0) goBack()
    else history.push(Routes.Services.pos.path)
  }

  const closeTunnel = () => {
    (async () => {
      try {
        await dispatch(PosActions.resetPrepare())
        POSUtils.reinitProductQuantity(offers)
        history.push(Routes.Services.pos.path)
      } catch (error) {}
    })()
  }

  return (
    <PageDetails
      component={
        <>
          {currentStep?.path && (
            <Redirect
              to={{
                pathname: currentStep?.path,
              }}
            />
          )}
          {wizardSteps.map((value) =>
            <Route
              key={value.navigationKey}
              exact
              path={value.path}
              component={value.component}
            />
          )}
        </>
      }
      onBack={() => previousStep()}
      onClose={() => closeTunnel()}
      title={currentStep?.meta?.headerTitle ? t(currentStep.meta.headerTitle) : t('app:pages:services:pos:order:title')}
    />
  )
}
