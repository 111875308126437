import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { StateTypes } from 'Store'

import { ClientUtils, EnumUtils } from '@neo-commons/libraries'
import { Icon, IconTypes, MenuItemCard, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { Client, ClientSelectors, DocumentUploadActions, Subscription, SubscriptionSelectors } from '@neo-commons/store'

import { KycTypeDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import { Routes } from '../../../../../Routes/Routes'

import './ElectronicSignatureFallback.scss'

export const ElectronicSignatureFallback: React.FC = () => {
  const documentsType = [KycTypeDto.ID, KycTypeDto.PASSPORT, KycTypeDto.RESIDENCE_PERMIT]
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const client: Client = useSelector(ClientSelectors.defaultOne)
  const socleSubscription: Subscription = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const signatureLink: string = useSelector((state: StateTypes) => state?.subscription?.signatureLink)

  useEffect(() => {
    if (signatureLink) {
      history.push(Routes.Subscription.SeaCgu.electronicSignature.path)
    }
  }, [socleSubscription])

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      icon={<Icon name='idcard-passport' type={IconTypes.NEOFONT} size={40} color={Colors.secondary} />}
      subtitle={t('app:pages:subscription:global:electronicSignatureDocumentsChoice:title')}
    >
      <div className='ElectronicSignatureFallback_container'>
        <div className='ElectronicSignatureFallback_container_description'>
          <Typography2 typeface='body1'>
            {t('app:pages:subscription:global:electronicSignatureDocumentsChoice:description')}
          </Typography2>
        </div>
        {documentsType.map((type, index) =>
          <div className='ElectronicSignatureFallback_container_item' key={index}>
            <MenuItemCard
              title={t(EnumUtils.getKycTypeDtoKey(type))}
              onPress={async () => {
                await dispatch(DocumentUploadActions.prepare({
                  name: type,
                  documentType: KycTypeDto.SIGNATURE_ADVANCED,
                }, ClientUtils.isClientTypeCorporate(client.type) ? client?.holder?.uuid : undefined))
                history.push(Routes.DocumentUpload.index.path)
              }}
            />
          </div>
        )}
      </div>
    </TunnelContent>
  )
}
