import { AbstractPolicy } from 'Policies/AbstractPolicy'
import { Routes } from 'Routes/Routes'

import { ClientUtils, SubscriptionUtils } from '@neo-commons/libraries'
import { SubscriptionSelectors, ClientSelectors } from '@neo-commons/store'

import { ClientDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'

export class SubscriptionPolicy extends AbstractPolicy {
  public static apply = (): string => {
    let result
    if (SubscriptionPolicy.isOldSubscription()) {
      result = Routes.Subscription.SeaCgu.index.path
    } else {
      if (SubscriptionPolicy.isSubscribing()) {
        if (SubscriptionPolicy.isIndividual()) {
          result = Routes.Subscription.Individual.index.path
        } else if (SubscriptionPolicy.isBusiness()) {
          result = Routes.Subscription.Business.index.path
        } else {
          result = Routes.Subscription.subscriptionChoice.path
        }
      } else if (SubscriptionPolicy.isCancel()) {
        if (!SubscriptionPolicy.hasSignatureValidated()) {
          result = Routes.Subscription.subscriptionFailure.path
        } else {
          result = Routes.Subscription.subscriptionChoice.path
        }
      } else if (SubscriptionPolicy.isActive()) {
        result = Routes.Origin.path
      } else {
        result = Routes.Subscription.subscriptionChoice.path
      }
    }
    return result
  }

  private static getSubscription (): SubscriptionDto {
    return SubscriptionSelectors.defaultOneSocle(this.state)
  }

  private static getClient (): ClientDto {
    return ClientSelectors.defaultOne(this.state)
  }

  public static isActive (): boolean {
    return SubscriptionUtils.isActive(this.getSubscription())
  }

  public static isSubscribing (): boolean {
    return SubscriptionUtils.isSubscribing(this.getSubscription())
  }

  public static isCancel (): boolean {
    return SubscriptionUtils.isCanceled(this.getSubscription())
  }

  public static isIndividual (): boolean {
    return ClientUtils.isClientTypeIndividual(this.getClient()?.type)
  }

  public static isBusiness (): boolean {
    return ClientUtils.isClientTypeCorporate(this.getClient()?.type)
  }

  public static hasSignatureValidated (): boolean {
    return SubscriptionUtils.isSignatureOk(this.getSubscription())
  }

  public static isOldSubscription (): boolean {
    return SubscriptionUtils.isUpdateClientSeaCgu(this.getSubscription())
  }

  public static hasNotClient (): boolean {
    return !SubscriptionPolicy.isIndividual() && !SubscriptionPolicy.isBusiness()
  }
}
