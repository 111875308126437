import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Tunnel } from '@neo-web/componentsOld'

import { AdvanceEligibilityError } from './AdvanceEligibilityError/AdvanceEligibilityError'
import { AdvanceEligibilitySearchBank } from './AdvanceEligibilitySearchBank/AdvanceEligibilitySearchBank'
import { AdvanceEligibilityPending } from './AdvanceEligibilityPending/AdvanceEligibilityPending'
import { AdvanceEligibilitySuccess } from './AdvanceEligibilitySuccess/AdvanceEligibilitySuccess'
import { AdvanceEligibilityFailure } from './AdvanceEligibilityFailure/AdvanceEligibilityFailure'
import { AdvanceEligibilityPedagogy } from './AdvanceEligibilityPedagogy/AdvanceEligibilityPedagogy'
import { AdvanceEligibilityTerms } from './AdvanceEligibilityTerms/AdvanceEligibilityTerms'
import { AdvanceEligibilityAggregationFallback } from './AdvanceEligibilityAggregationFallback/AdvanceEligibilityAggregationFallback'

export const AdvanceEligibility: React.FC = () => {
  const history = useHistory()

  return (
    <Tunnel
      footer
      onBack={() => history.goBack()}
      onClose={() => history.push(Routes.Synthesis.index.path)}
      component={
        <Switch>
          <Route exact path={Routes.AdvanceEligibility.index.path} component={AdvanceEligibilityPedagogy} />
          <Route exact path={Routes.AdvanceEligibility.terms.path} component={AdvanceEligibilityTerms} />
          <Route exact path={Routes.AdvanceEligibility.searchBank.path} component={AdvanceEligibilitySearchBank} />
          <Route exact path={Routes.AdvanceEligibility.pending.path} component={AdvanceEligibilityPending} />
          <Route exact path={Routes.AdvanceEligibility.success.path} component={AdvanceEligibilitySuccess} />
          <Route exact path={Routes.AdvanceEligibility.failure.path} component={AdvanceEligibilityFailure} />
          <Route exact path={Routes.AdvanceEligibility.error.path} component={AdvanceEligibilityError} />
          <Route exact path={Routes.AdvanceEligibility.aggregationFallback.path} component={AdvanceEligibilityAggregationFallback} />
        </Switch>
      }
    />
  )
}
