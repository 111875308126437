import React from 'react'

import i18n from 'i18next'

import { BankAccount } from '@neo-commons/store'
import { Button, ButtonSkin } from '@neo-commons/components'
import { BankAccountUtils } from '@neo-commons/libraries'

import { AccountDto } from '@afone/neo-core-client/dist/models'

export enum BankAccountCardSkin {
  DEFAULT = 'DEFAULT',
  SECONDARY = 'SECONDARY'
}
export enum BankAccountAction {
  CREDIT = 'CREDIT',
  IBAN = 'IBAN',
  TRANSFER = 'TRANSFER',
  COLLECT = 'COLLECT'
}

export const useButton = (
  bankAccount: BankAccount,
  skin?: BankAccountCardSkin,
  onClick?: (action: BankAccountAction) => void
) : React.ReactElement => {
  const isProspectAccount = BankAccountUtils.isProspectAccount(bankAccount)

  const getButton = (title: any, action: BankAccountAction, isDisabled?: boolean) => {
    return (
      <Button
        skin={skin === BankAccountCardSkin.SECONDARY
          ? ButtonSkin.OUTLINE_LIGHT
          : (isDisabled ? ButtonSkin.OUTLINE_GRAY : ButtonSkin.OUTLINE_SECONDARY)}
        title={title}
        disabled={isDisabled}
        onPress={() => (onClick && !isDisabled)
          ? onClick(action)
          : null}
      />
    )
  }

  let result

  switch (true) {
    case BankAccountUtils.isMain(bankAccount as AccountDto) :
      result =
        <>
          {getButton(i18n.t('neo-commons:account:credit'), BankAccountAction.CREDIT, isProspectAccount)}
          {getButton(i18n.t('neo-commons:account:myIban'), BankAccountAction.IBAN, isProspectAccount)}
        </>
      break
    case BankAccountUtils.isAggregated(bankAccount as AccountDto) :
      result = !bankAccount.lastUpdate &&
        <>
          {getButton(i18n.t('neo-commons:account:myIban'), BankAccountAction.IBAN, isProspectAccount)}
          {getButton(i18n.t('neo-commons:account:transfer'), BankAccountAction.TRANSFER, isProspectAccount)}
        </>
      break
    case BankAccountUtils.isProject(bankAccount as AccountDto) :
      result =
        <>
          {getButton(i18n.t('neo-commons:account:transfer'), BankAccountAction.TRANSFER, isProspectAccount)}
          {getButton(i18n.t('neo-commons:account:credit'), BankAccountAction.CREDIT, isProspectAccount)}
        </>
      break
    case BankAccountUtils.isPos(bankAccount as AccountDto) :
      result =
        <>
          {getButton(i18n.t('neo-commons:account:collect'), BankAccountAction.COLLECT)}
        </>
      break
  }

  return result
}
