import { StyleSheet } from 'react-native'

import { Typo, Colors } from '@neo-commons/styles'

const styles = StyleSheet.create({
  sectionsContainer: {

  },
  letterGroup: {
    ...Typo.bigTitle,
    fontSize: 16,
    paddingLeft: 16,
    textAlignVertical: 'center',
    color: Colors.black,
    paddingTop: 8,
    paddingBottom: 8,
  },
  container: {
    width: '100%',
  },
})

export default styles
