import { createUserManager } from 'redux-oidc'
import { UserManager, UserManagerSettings } from 'oidc-client'

import { UrlUtils } from '@neo-commons/libraries'

import { ENV, config } from '../Config/EnvConfig'
import { SvaRegisterRoutes } from '../Routes/SvaRegisterRoutes'

export const agentClientUserManagerConfig: UserManagerSettings = {
  authority: config(ENV.FA_AUTHORITY),
  client_id: config(ENV.FA_CLIENT_ID),
  response_type: 'code',
  scope: 'openid offline_access',
  redirect_uri: `${UrlUtils.getRootUrl()}${SvaRegisterRoutes.login.path}`,
  post_logout_redirect_uri: `${UrlUtils.getRootUrl()}${SvaRegisterRoutes.logout.path}`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  revokeAccessTokenOnSignout: true,
  loadUserInfo: true,
  acr_values: `tenantId:${config(ENV.FA_TENANT_ID)}`,
}

export const agentClientUserManager: UserManager = createUserManager(agentClientUserManagerConfig)
