import { AnyAction, Reducer } from 'redux'
import { omit, uniq } from 'lodash'
import produce from 'immer'
import { createSelector } from 'reselect'
import { NeobankApi } from '@neo-commons/services'
import {
  AddressCreateDto,
  AddressTypeDto,
  OrderDto,
  OrderCreateDto,
  OrderTypeDto,
} from '@afone/neo-core-client'

import { initialResourceState, State } from '../utils'
import { Dispatch, ResourceState } from '../utils/resourceState'

import { Client, ClientSelectors } from './client'

export const OrdersTypes = {
  LIST_ORDERS_REQUEST: 'orders/LIST_ORDERS_REQUEST',
  LIST_ORDERS_SUCCESS: 'orders/LIST_ORDERS_SUCCESS',
  LIST_ORDERS_ERROR: 'orders/LIST_ORDERS_ERROR',
  CREATE_ADVANCE_ORDER_REQUEST: 'orders/CREATE_ADVANCE_ORDER_REQUEST',
  CREATE_ADVANCE_ORDER_SUCCESS: 'orders/CREATE_ADVANCE_ORDER_SUCCESS',
  CREATE_ADVANCE_ORDER_ERROR: 'orders/CREATE_ADVANCE_ORDER_ERROR',
  CREATE_CARD_ORDER_REQUEST: 'orders/CREATE_CARD_ORDER_REQUEST',
  CREATE_CARD_ORDER_SUCCESS: 'orders/CREATE_CARD_ORDER_SUCCESS',
  CREATE_CARD_ORDER_ERROR: 'orders/CREATE_CARD_ORDER_ERROR',
  RESET: 'orders/RESET',
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */

const listOrders = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: OrdersTypes.LIST_ORDERS_REQUEST })
    try {
      const response =
        await NeobankApi.getInstance().orderApi.getOrders(10, 0)
      dispatch({ type: OrdersTypes.LIST_ORDERS_SUCCESS, data: response?.data })
    } catch (error) {
      dispatch({ type: OrdersTypes.LIST_ORDERS_ERROR, data: error.data })
      throw error
    }
  }
}

const createAdvanceOrder = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: OrdersTypes.CREATE_ADVANCE_ORDER_REQUEST })
      const orderCreate: OrderCreateDto = {
        type: OrderTypeDto.ADVANCE,
      }
      const payload = await NeobankApi.getInstance().orderApi.createOrder(orderCreate)
      dispatch({ type: OrdersTypes.CREATE_ADVANCE_ORDER_SUCCESS, data: payload?.data })
    } catch (error) {
      dispatch({ type: OrdersTypes.CREATE_ADVANCE_ORDER_ERROR, data: error.data })
      throw error
    }
  }
}

const createCardOrder = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: OrdersTypes.CREATE_CARD_ORDER_REQUEST })
    const preparedCardOrder = getState().card.preparedCardOrder
    const client: Client = ClientSelectors.defaultOne(getState())
    const deliveryAddress: AddressCreateDto = {
      type: AddressTypeDto.DELIVERY,
      city: preparedCardOrder?.deliveryData?.address?.city ?? '',
      countryCode: preparedCardOrder?.deliveryData?.address?.country.isoCodeAlpha2 ?? '',
      postalCode: preparedCardOrder?.deliveryData?.address?.zipCode ?? '',
      line1: preparedCardOrder?.deliveryData?.address?.street ?? '',
      fullName: preparedCardOrder?.deliveryData?.user?.lastname ?? '' + ' ' + preparedCardOrder?.deliveryData?.user?.firstname ?? '',
    }
    const orderCreate: OrderCreateDto = {
      type: OrderTypeDto.CARD,
      clientUuid: client?.uuid,
      offerUuid: preparedCardOrder?.offer?.uuid,
      discountCode: preparedCardOrder?.promoCode,
      deliveryCode: preparedCardOrder?.deliveryData?.mode?.data?.deliveryCode ?? '',
      deliveryAddress,
    }
    try {
      const payload = await NeobankApi.getInstance().orderApi.createOrder(orderCreate)
      dispatch({ type: OrdersTypes.CREATE_CARD_ORDER_SUCCESS, data: payload?.data })
    } catch (error) {
      dispatch({ type: OrdersTypes.CREATE_CARD_ORDER_ERROR, data: error.data })
      throw error
    }
  }
}

const reset = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: OrdersTypes.RESET })
  }
}

export const OrdersActions = {
  listOrders,
  createAdvanceOrder,
  createCardOrder,
  reset,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type OrdersState = Omit<ResourceState<OrderDto>, 'create' | 'update' | 'prepare'>

const initialState: OrdersState = {
  ...omit(initialResourceState, 'create', 'update', 'prepare'),
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const ordersSelector = (state: State) => state.orders

export const OrdersSelectors = {
  list: createSelector(
    ordersSelector,
    resource => resource.list.ids.map((id: string) => resource.data[id])
  ),
  isLoading: createSelector(
    ordersSelector,
    resource => resource.loading
  ),
  byType: (type: string) => createSelector(
    ordersSelector,
    resource => resource.data[type] ?? null
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const orders: Reducer = (state: OrdersState = initialState, action: AnyAction) => {
  switch (action.type) {
    case OrdersTypes.LIST_ORDERS_REQUEST:
    case OrdersTypes.CREATE_ADVANCE_ORDER_REQUEST:
    case OrdersTypes.CREATE_CARD_ORDER_REQUEST:
      return produce(state, newState => {
        newState.loading = true
      })
    case OrdersTypes.LIST_ORDERS_SUCCESS:
      if (!action.data) {
        return produce(state, newState => {
          newState.loading = false
          newState.list.loadedOnce = true
        })
      }
      return produce(state, newState => {
        newState.loading = false
        newState.list.loadedOnce = true
        /*
         * We redo a data by taking the list of what we received and overwriting the
         * data we had before for the same uuid This is necessary if we do not want to
         * lose the data of LIST_CARDS_LIMIT and if we want to overload the old data with new
         */
        newState.data = action.data?.reduce((acc, item) => ({
          ...acc,
          [item.type]: {
            ...state.data[item.type],
            ...item,
          },
        }), {}) ?? {}
        newState.list = {
          ...state.list,
          ids: uniq(action.data?.map(item => item.type) ?? []),
          loadedOnce: true,
          shouldRefresh: false,
        }
        if (action.data.length === 1 && newState.list.selectedId !== action.data[0].type) {
          newState.list.selectedId = action.data[0].type
        }
      })
    case OrdersTypes.CREATE_ADVANCE_ORDER_SUCCESS:
    case OrdersTypes.CREATE_CARD_ORDER_SUCCESS:
      if (!action.data) {
        return produce(state, newState => {
          newState.loading = false
          newState.list.loadedOnce = true
        })
      }
      return produce(state, newState => {
        newState.loading = false
        newState.list.loadedOnce = true
        newState.list.ids = uniq([...state.list.ids, action.data.type])
        newState.data[action.data.type] = action.data
      })
    case OrdersTypes.LIST_ORDERS_ERROR:
    case OrdersTypes.CREATE_ADVANCE_ORDER_ERROR:
    case OrdersTypes.CREATE_CARD_ORDER_ERROR:
      return produce(state, newState => {
        newState.loading = false
        newState.list.loadedOnce = true
      })
    case OrdersTypes.RESET:
      return initialState
    default:
      return state
  }
}
