import { RoutesInterface } from '../Routes'

const invoiceAndContractTitle = 'app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:title'

export class ProfileRoutes {
  public static index: RoutesInterface = {
    path: '/profile',
    name: 'app:pages:myProfile:subMenu:profile',
    available: true,
  }

  public static information: RoutesInterface = {
    path: ProfileRoutes.index.path + '/informations',
    name: 'app:pages:myProfile:myInfo:myInformation',
    available: true,
  }

  public static userProfile: RoutesInterface = {
    path: ProfileRoutes.index.path + '/informations/user',
    name: 'Profil de l\'utilisateur',
    available: true,
  }

  public static companyProfile: RoutesInterface = {
    path: ProfileRoutes.index.path + '/informations/company',
    name: 'Profil de l\'entreprise',
    available: true,
  }

  public static affiliateProfile: RoutesInterface = {
    path: ProfileRoutes.index.path + '/informations/company/affiliate/:id',
    name: 'Profil de l\'affilie',
    available: true,
  }

  public static preference: RoutesInterface = {
    path: ProfileRoutes.index.path + '/preference',
    name: 'app:pages:myProfile:menu:preferencesAndSecurity',
    available: true,
  }

  public static security: RoutesInterface = {
    path: ProfileRoutes.preference.path + '/security',
    name: 'app:pages:myProfile:security:detailsSecurity:title',
    available: true,
  }

  public static helpAndEmergency: RoutesInterface = {
    path: ProfileRoutes.index.path + '/helpAndEmergency',
    name: 'app:pages:profile:help:home:page:title',
    available: true,
  }

  public static contact: RoutesInterface = {
    path: ProfileRoutes.preference.path + '/contact',
    name: 'contact',
    available: true,
  }

  public static clientService: RoutesInterface = {
    path: ProfileRoutes.index.path + '/client-service',
    name: 'app:pages:profile:help:home:clientservice',
    available: true,
  }

  public static editCode: RoutesInterface = {
    path: ProfileRoutes.security.path + '/edit-code',
    name: 'app:pages:myProfile:security:security:editCode',
    available: false,
  }

  public static appointment: RoutesInterface = {
    path: ProfileRoutes.index.path + '/appointment',
    name: 'appointment',
    available: false,
  }

  public static notification: RoutesInterface = {
    path: ProfileRoutes.preference.path + '/notification',
    name: 'notification',
    available: true,
  }

  public static deviceAndBrowser: RoutesInterface = {
    path: ProfileRoutes.preference.path + '/device-and-browser',
    name: 'app:pages:myProfile:preferenceAndSecurity:devices:title',
    available: true,
  }

  public static fraud: RoutesInterface = {
    path: ProfileRoutes.helpAndEmergency.path + '/fraud',
    name: 'app:pages:profile:help:home:fraud:title',
    available: false,
  }

  public static opposition: RoutesInterface = {
    path: ProfileRoutes.helpAndEmergency.path + '/opposition',
    name: 'app:pages:profile:help:home:emergency:title',
    available: false,
  }

  public static language: RoutesInterface = {
    path: ProfileRoutes.preference.path + '/language',
    name: 'app:pages:myProfile:preferenceAndSecurity:language:title',
    available: true,
  }

  public static about: RoutesInterface = {
    path: ProfileRoutes.index.path + '/about',
    name: 'app:pages:myProfile:menu:about',
    available: true,
  }

  public static invoiceAndContract: RoutesInterface = {
    path: ProfileRoutes.index.path + '/invoiceAndContract',
    name: 'app:pages:myProfile:menu:contractAndInvoice',
    available: true,
  }

  public static choicesAccount: RoutesInterface = {
    path: ProfileRoutes.invoiceAndContract.path + '/choicesAccount',
    name: 'app:pages:myProfile:contractAndInvoice:choicesAccount',
    available: true,
  }

  public static invoiceAndContractDetail: RoutesInterface = {
    path: ProfileRoutes.invoiceAndContract.path + '/invoiceAndContractDetail',
    name: invoiceAndContractTitle,
    available: true,
  }

  public static statementsDetail: RoutesInterface = {
    path: ProfileRoutes.invoiceAndContract.path + '/statementsDetail',
    name: invoiceAndContractTitle,
    available: true,
  }

  public static documentDetail: RoutesInterface = {
    path: ProfileRoutes.invoiceAndContractDetail.path + '/:type/:documentUuid/:documentDate?',
    name: 'app:global:pages:detail',
    available: true,
  }

  public static invoices: RoutesInterface = {
    path: ProfileRoutes.invoiceAndContract.path + '/invoices',
    name: invoiceAndContractTitle,
    available: true,
  }

  public static impossibleToCloseAccount: RoutesInterface = {
    path: ProfileRoutes.invoiceAndContract.path + '/close/mainAccount/notPossible',
    name: invoiceAndContractTitle,
    available: true,
  }
}
