import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StepComponentProps, BusinessSubscriptionSteps, BusinessSubscriptionStep } from '@neo-commons/policies'
import { Button, ButtonSkin, Typography2, RadioInput } from '@neo-commons/components'
import { ClientActions, ClientSelectors } from '@neo-commons/store'

import { ClientDto, PersonAffiliationDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent } from '@neo-web/componentsOld'

import './IdentifyYourself.scss'

type IdentifyYourselfProps = StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.IdentifyYourself]>

export const IdentifyYourself: React.FC<IdentifyYourselfProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const affiliates = useMemo(() => client?.holder?.affiliates, [client])
  const [affiliateSelected, setAffiliateSelected] = useState<PersonAffiliationDto>(affiliates[0])

  const affiliatesList = useMemo(() => {
    return client?.holder?.affiliates.map((affiliate, index) =>
      <RadioInput
        key={index}
        text={affiliate?.person?.fullName}
        value={affiliate?.uuid === affiliateSelected?.uuid}
        onPress={() => setAffiliateSelected(affiliate)}
      />
    )
  }, [client, affiliateSelected])

  return (
    <TunnelContent
      header={
        <Typography2 typeface='h4'>{t('app:pages:subscription:business:identifyYourself:title')}</Typography2>
      }
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:continue')}
          onPress={async () => {
            await dispatch(ClientActions.attachMe({ affiliateUuid: affiliateSelected?.uuid }))
            nextStep()
          }}
        />
      }
    >
      <div className='IdentityYourself_container'>
        {affiliatesList}
      </div>
    </TunnelContent>
  )
}
