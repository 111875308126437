import React from 'react'
import { Trans } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'

import { Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Animation, Modal } from '@neo-web/componentsOld'

export interface InformationModalProps {
  onClose?: () => void,
  show: boolean,
  confetti?: boolean,
  showInfoIcon?: boolean,
  extraIcon?: React.ReactElement,
  title?: string,
  description?: string,
  description2?: string,
  descriptionAlign?: 'left' | 'center' | 'right',
  button?: React.ReactElement,
}

export const InformationModal: React.FC<InformationModalProps> = (
  props: InformationModalProps
) => {
  return (
    <Modal autoFocus displayCross open={props.show} onClose={props.onClose}>
      {props.confetti && <Animation zIndexOverride={1} />}
      <FlexboxGrid justify='center' className='text-center InformationModal'>
        {props.showInfoIcon !== false &&
          <FlexboxGrid.Item colspan={24} className='pb-s'>
            <Icon
              name='alert-circle'
              type={IconTypes.FEATHER}
              size={40}
              color={Colors.secondary}
            />
          </FlexboxGrid.Item>}
        {props.title && (
          <FlexboxGrid.Item colspan={24} className='pb-s'>
            <Typography typeface='bigTitle'>{props.title}</Typography>
          </FlexboxGrid.Item>
        )}
        {props.description && (
          <FlexboxGrid.Item
            colspan={22}
            className={`pt-xs pb-xl ${
              props.descriptionAlign ? 'text-' + props.descriptionAlign : ''
            }`}
          >
            <Typography typeface='content'>
              <Trans>{props.description}</Trans>
            </Typography>
          </FlexboxGrid.Item>
        )}
        {props.extraIcon &&
          <FlexboxGrid.Item colspan={24} className='pb-s'>
            {props.extraIcon}
          </FlexboxGrid.Item>}
        {props.description2 && (
          <FlexboxGrid.Item
            colspan={22}
            className={`pt-xs pb-xl ${
              props.descriptionAlign ? 'text-' + props.descriptionAlign : ''
            }`}
          >
            <Typography typeface='content'>
              <Trans>{props.description2}</Trans>
            </Typography>
          </FlexboxGrid.Item>
        )}
        {props.button &&
          <FlexboxGrid.Item colspan={24} style={{ zIndex: 2 }}>
            {props.button}
          </FlexboxGrid.Item>}
      </FlexboxGrid>
    </Modal>
  )
}
