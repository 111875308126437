import React, { FunctionComponent, useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'
import { ServicesRoutes } from 'Routes/Services'

import { CardUpdateType, CreditCardUtils, NumbersUtils } from '@neo-commons/libraries'
import { MenuItemCard, ToggleCard, ToggleCardSkin, Typography, Typography2 } from '@neo-commons/components'
import {
  CardActions,
  CardSelectors,
  CreditCardData,
  CardTypes,
  PrepareCardOrder,
  OfferSelectors,
  OfferActions,
  Dispatch,
  SubscriptionSelectors,
  ClientSelectors,
} from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import {
  CardDto,
  CardStatusDto,
  CardStatusUpdateDto,
  ClientDto,
  OfferDto,
  OfferTypeDto,
  ProductDto,
  UsageLimitCodeDto,
} from '@afone/neo-core-client/dist/models'

import { useDecryptData } from '@neo-web/hooks'
import { LazyLoadingContainer, PageContent } from '@neo-web/componentsOld'

import { SettingsCarousel } from '../../Components/Settings/SettingsCarousel'

import { CardOpposed } from './CardOpposed'
import { LimitType } from './Limits/Limits'

import './Settings.scss'

export enum SensitiveInfo {
  CARD = 'CARD',
  PIN = 'PIN',
  ORDER = 'ORDER',
}

export const Settings: FunctionComponent = () => {
  const dispatch = useDispatch<Dispatch>()
  const history = useHistory()
  const { t } = useTranslation()
  const cards: CardDto[] = useSelector(CardSelectors.list)
  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)
  const [showSca, setShowSca] = useState(false)
  const offers: OfferDto[] = useSelector(OfferSelectors.listByType(OfferTypeDto.CARD))

  const selectedCardOffer = offers?.find(offer => offer.offerCode === selectedCard?.offerCode)
  const cardProduct = (selectedCardOffer?.products as (ProductDto & {data: {productType?: string}})[])?.filter(product => product?.data?.productType === 'plasticCard')
  const physicCardPrice = useSelector(SubscriptionSelectors.getProductPriceByCode(cardProduct?.[0]?.productCode))
  const client: ClientDto = useSelector(ClientSelectors.defaultOne, (prev, current) => prev?.uuid === current?.uuid)
  const cardOwnerUuid: string|undefined = CreditCardUtils.getDefaultCardOwnerUuid(client)

  const goToDetailRoute = (type: SensitiveInfo, uuidCard: string) => {
    (async () => {
      try {
        let url = ServicesRoutes.debitCard.path

        if (selectedCard?.status !== CardStatusDto.ACTIVE &&
          selectedCard?.status !== CardStatusDto.ACTIVE_NO_RENEWAL &&
          selectedCard?.status !== CardStatusDto.DO_NOT_HONOUR) {
          throw Error('Card is not active.')
        }
        switch (type) {
          case SensitiveInfo.CARD:
            url = generatePath(ServicesRoutes.debitCardDetail.path, { id: uuidCard })
            break
          case SensitiveInfo.PIN:
            url = generatePath(ServicesRoutes.debitCardPin.path, { id: uuidCard })
            break
        }
        history.push(url)
      } catch (error) {}
    })()
  }

  const onEncryptDone = (error?: string) => {
    if (error) {
      setShowSca(false)
      return
    }
    goToDetailRoute(SensitiveInfo.PIN, selectedCard.uuid)
  }

  useDecryptData(showSca, onEncryptDone)

  useEffect(() => {
    (async () => {
      try {
        await dispatch(OfferActions.listForClientUuid())
      } catch (error) {}
    })()
  }, [])

  useEffect(() => {
    (async () => {
      try {
        await dispatch(CardActions.getLimits(selectedCard?.uuid))
      } catch (error) {}
    })()
  }, [selectedCard?.uuid])

  return (
    <LazyLoadingContainer events={[CardTypes.GET_ENCRYPTED_CARD_DATA_REQUEST]}>
      <PageContent style={{ maxWidth: 800 }}>
        {selectedCard?.status === CardStatusDto.DESACTIVATED
          ? (
            <>
              {cards?.length > 1 && <SettingsCarousel cards={cards} onDisplayNumber={goToDetailRoute} />}
              <CardOpposed />
            </>
          )
          : (
            <>
              <SettingsCarousel cards={cards} onDisplayNumber={goToDetailRoute} />
              <div className='Settings_container'>
                {selectedCard?.status === CardStatusDto.DO_NOT_HONOUR &&
                  <div className='Settings_container-blocked'>
                    <Typography2 typeface='body2'>
                      {t('app:pages:debitCard:blocked:description')}
                    </Typography2>
                  </div>}
                <div className='Settings_container-title'>
                  <Typography typeface='content'>
                    {t('app:pages:debitCard:services:security')}
                  </Typography>
                </div>
                <div className='Settings_container-menu'>
                  {selectedCard?.plastic &&
                    <MenuItemCard
                      title={t('app:pages:debitCard:services:showCodePin')}
                      chevronPosition='center'
                      disabled={selectedCard?.status !== CardStatusDto.ACTIVE &&
                      selectedCard?.status !== CardStatusDto.ACTIVE_NO_RENEWAL}
                      chevronColor={Colors.darkGreyText}
                      onPress={() => {
                        setShowSca(true)
                      }}
                    />}
                </div>
                <div className='Settings_container-menu'>
                  <ToggleCard
                    title={t('app:pages:debitCard:services:blockTheCardTemporarily')}
                    skin={ToggleCardSkin.DEFAULT}
                    value={selectedCard?.status !== CardStatusDto.ACTIVE &&
                    selectedCard?.status !== CardStatusDto.ACTIVE_NO_RENEWAL}
                    onValueChange={() => {
                      (async () => {
                        try {
                          await dispatch(
                            CardActions.update(
                              selectedCard?.uuid,
                              {
                                status: selectedCard?.status === CardStatusDto.DO_NOT_HONOUR
                                  ? CardStatusUpdateDto.ACTIVE
                                  : CardStatusUpdateDto.DO_NOT_HONOUR,
                              },
                              CardUpdateType.BLOCK
                            )
                          )
                        } catch (e) {}
                      })()
                    }}
                  />
                </div>
                <div className='Settings_container-menu'>
                  <MenuItemCard
                    title={t('app:pages:services:debitCard:opposition:intro:pageTitle')}
                    chevronPosition='center'
                    chevronColor={Colors.darkGreyText}
                    onPress={async () => {
                      await dispatch(CardActions.prepareOpposition({}))
                      history.push(Routes.Opposition.index.path)
                    }}
                  />
                </div>
                <div className='Settings_container-menu'>
                  <div className='Settings_container-title'>
                    <Typography typeface='content'>
                      {t('app:pages:debitCard:services:limits:title')}
                    </Typography>
                  </div>
                  <div className='Settings_container-menu'>
                    <MenuItemCard
                      title={t('app:pages:debitCard:services:limits:managePayment:title')}
                      disabled={selectedCard?.status === CardStatusDto.DO_NOT_HONOUR}
                      extra={
                        <div className='Settings_extra'>
                          <div className='Settings_extra-subtitle'>
                            {t('app:pages:debitCard:services:limits:managePayment:weekly')}&nbsp;
                            <span className='Settings_extra-price'>
                              {CreditCardUtils.getMaxUsage(selectedCard, UsageLimitCodeDto.ALL_WEEKLY)}
                            </span>
                          </div>
                          <div>
                            {t('app:pages:debitCard:services:limits:managePayment:monthly')}&nbsp;
                            <span className='Settings_extra-price'>
                              {CreditCardUtils.getMaxUsage(selectedCard, UsageLimitCodeDto.ALL_MONTHLY)}
                            </span>
                          </div>
                        </div>
                      }
                      onPress={() =>
                        history.push(generatePath(Routes.Services.limits.path,
                          { id: selectedCard.uuid, type: LimitType.PAYMENT }))}
                      chevronPosition='center'
                      chevronColor={Colors.darkGreyText}
                    />
                    {selectedCard?.plastic &&
                      <MenuItemCard
                        title={t('app:pages:debitCard:services:limits:modifyWithdrawal:title')}
                        disabled={selectedCard?.status === CardStatusDto.DO_NOT_HONOUR}
                        extra={
                          <div className='Settings_extra'>
                            <div className='Settings_extra-subtitle'>
                              {t('app:pages:debitCard:services:limits:managePayment:weekly')}&nbsp;
                              <span className='Settings_extra-price'>
                                {CreditCardUtils.getMaxUsage(selectedCard, UsageLimitCodeDto.ATM_WEEKLY)}
                              </span>
                            </div>
                          </div>
                        }
                        onPress={() =>
                          history.push(generatePath(Routes.Services.limits.path,
                            { id: selectedCard.uuid, type: LimitType.WITHDRAWAL }))}
                        chevronPosition='center'
                        chevronColor={Colors.darkGreyText}
                      />}
                  </div>
                </div>
                {!selectedCard?.plastic && !selectedCard?.plasticInfo?.plasticStatus &&
                  <>
                    <div className='Settings_container-title'>
                      <Typography typeface='content'>
                        {t('app:pages:debitCard:services:cardOrderAndTermination')}
                      </Typography>
                    </div>
                    <div className='Settings_container-menu'>
                      <MenuItemCard
                        title={t('app:pages:debitCard:services:orderPhysicalCard')}
                        subtitle={(physicCardPrice !== null && physicCardPrice !== undefined)
                          ? t('app:pages:debitCard:services:fromTo', {
                            price: NumbersUtils.displayPriceForHuman(physicCardPrice),
                          })
                          : ''}
                        chevronPosition='center'
                        chevronColor={Colors.darkGreyText}
                        disabled={selectedCard?.status === CardStatusDto.DO_NOT_HONOUR}
                        onPress={() => {
                          dispatch(CardActions.prepare({
                            cardUuid: selectedCard.uuid,
                            personUuid: cardOwnerUuid,
                            withPhysicalCard: true,
                            offer: selectedCardOffer,
                          } as PrepareCardOrder))
                          history.push(ServicesRoutes.physical.path)
                        }}
                      />
                    </div>
                  </>}
              </div>
            </>
          )}
      </PageContent>
    </LazyLoadingContainer>
  )
}
