import React from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { store } from 'Store'

import { CardActions } from '@neo-commons/store'
import { OppositionStep, OppositionPolicy } from '@neo-commons/policies'
import { useWizardPolicy } from '@neo-commons/hooks'

import { Tunnel } from '@neo-web/componentsOld'

import { Routes } from '../../../../../Routes/Routes'

import { Intro, Reason, ConfirmOpposition, OppositionSummary } from '.'

import './Opposition.scss'

export const Opposition: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { currentStep, steps: wizardSteps } = useWizardPolicy(store, OppositionPolicy, {
    [OppositionStep.Intro]: Intro,
    [OppositionStep.Reason]: Reason,
    [OppositionStep.Confirm]: ConfirmOpposition,
    [OppositionStep.Summary]: OppositionSummary,
  }, {
    onDone: async () => {
      dispatch(CardActions.prepareOpposition({}))
      history.replace(Routes.Services.index.path)
    },
  })

  return (
    <Tunnel
      title={t('app:pages:services:debitCard:opposition:intro:pageTitle')}
      onClose={() => history.push(Routes.Services.index.path)}
      component={
        <>
          {currentStep?.path &&
            <Redirect
              to={{
                pathname: currentStep?.path,
              }}
            />}
          {currentStep?.path && wizardSteps.map((value, index) => {
            return (
              <Route
                key={index}
                exact
                path={value.path}
                component={value.component}
              />
            )
          })}
        </>
      }
    />
  )
}
