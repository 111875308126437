import { RoutesInterface } from '../Routes'

export class SubscriptionRoutes {
  public static index: RoutesInterface = {
    path: '/subscription',
    name: '',
    available: true,
  }

  public static subscriptionChoice: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/choice',
    name: 'app:pages:subscription:global:subscriptionChoice:title',
    available: true,
  }

  public static notEuropeanTaxResident: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/individual/notEuropeanTaxResident',
    name: 'app:pages:subscription:individual:notEuropeanTaxResident:title',
    available: true,
  }

  public static keepInformed: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/individual/keepInformed',
    name: 'app:pages:subscription:individual:keepInform:title',
    available: true,
  }

  public static usPersonFallback: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/individual/usPersonFallback',
    name: 'app:pages:subscription:individual:usPersonFallback:title',
    available: true,
  }

  public static documentsInvalid: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/individual/documentsProcessingFailure',
    name: 'app:pages:subscription:global:documentsProcessingFailure:title',
    available: true,
  }

  public static electronicSignatureDocumentsChoice: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/individual/electronicSignatureDocumentsChoice',
    name: 'app:pages:subscription:global:electronicSignatureDocumentsChoice:title',
    available: true,
  }

  public static taxDomiciliationNotEligibleBusiness: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/business/taxDomiciliationNotEligible',
    name: 'app:pages:subscription:business:notTaxDomiciliation:title',
    available: true,
  }

  public static electronicSignatureDocumentsChoiceBusiness: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/business/electronicSignatureDocumentsChoice',
    name: 'app:pages:subscription:global:electronicSignatureDocumentsChoice:title',
    available: true,
  }

  public static documentsInvalidBusiness: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/business/documentsProcessingFailure',
    name: 'app:pages:subscription:global:documentsProcessingFailure:title',
    available: true,
  }

  public static subscriptionFailureBusiness: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/business/subscriptionFailure',
    name: 'app:pages:subscription:global:subscriptionFailure:title',
    available: true,
  }

  public static subscriptionFailure: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/failure',
    name: 'app:pages:subscription:global:subscriptionFailure:title',
    available: true,
  }

  public static subscriptionError: RoutesInterface = {
    path: SubscriptionRoutes.index.path + '/subscription-error',
    name: 'errors:codes:500',
    available: true,
  }
}
