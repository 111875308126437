import { Platform, StyleSheet } from 'react-native'

import { Colors, Typo2 } from '@neo-commons/styles'

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: '100%',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputContainer: {
    borderBottomWidth: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    ...Typo2.input,
    flex: 1,
    fontSize: 16,
    minHeight: 32,
    height: 32,
    paddingBottom: 0,
    paddingTop: 2,
    paddingLeft: 0,
    paddingRight: 32,
    marginBottom: 6,
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
  },
  label: {
    ...Typo2.overline,
    display: 'flex',
    top: 5,
    fontStyle: 'normal',
    color: Colors.black,
    ...Platform.select({
      web: {
        transitionProperty: 'all',
        transitionDuration: '0.15s',
        transitionTimingFunction: 'ease-in-out',
      },
    }),
  },
  leftIconContainer: {
    display: 'flex',
    marginRight: 16,
    marginLeft: 8,
  },
  mainContainer: {
    display: 'flex',
    flex: 1,
  },
  icon: {
    color: Colors.primary,
  },
  iconContainer: {
    position: 'absolute',
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    minWidth: 24,
  },
})

export default styles
