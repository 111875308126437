import { StyleSheet } from 'react-native'

import { Typo, Colors } from '@neo-commons/styles'

export default StyleSheet.create({
  container: {
    marginTop: 10,
    overflow: 'hidden',
  },
  content: {
    margin: -2,
    overflow: 'hidden',
  },
  keyText: {
    ...Typo.bigTitle,
    color: Colors.black,
  },
  key: {
    borderRadius: 100,
    overflow: 'hidden',
    marginHorizontal: 8,
    borderTopColor: Colors.transparent,
    borderBottomColor: Colors.transparent,
    borderRightColor: Colors.transparent,
    borderLeftColor: Colors.transparent,
    backgroundColor: Colors.transparent,
  },
  keyboard: {
    marginLeft: 40,
    marginRight: 40,
    height: 300,
    backgroundColor: Colors.transparent,
  },
  biometrics: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: Colors.white,
    borderWidth: 2,
    borderColor: Colors.secondary,
    paddingHorizontal: 20,
  },
})
