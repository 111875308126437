import React, { useState } from 'react'

import { Colors } from '@neo-commons/styles'

import { AggregationConnectorDto } from '@afone/neo-core-client/models'

import './AggregationLogo.scss'

export const AggregationLogo: React.FC<{ bank: AggregationConnectorDto, size:number }> = ({ bank, size }) => {
  const [isUndefined, setIsUndefined] = useState(false)

  const onError = () => {
    setIsUndefined(true)
  }

  function getInitials (string: string): string {
    if (!string) {
      return ''
    }
    const words = string.split(' ')
    const initials = words.slice(0, 3).map(w => w[0]).join('').toUpperCase()
    return initials || ''
  }

  if (isUndefined) {
    return (
      <div
        className='AggregationLogo'
        style={{
          backgroundColor: (bank?.color || Colors.secondary),
          borderRadius: 100,
          color: Colors.white,
        }}
      >
        {getInitials(bank?.name)}
      </div>
    )
  }

  if (bank?.icon) {
    return <img width={size} height={size} src={bank?.icon} alt={bank?.name} onError={onError} />
  } else {
    return (
      <div
        className='AggregationLogo'
        style={{
          backgroundColor: (bank?.color || Colors.secondary),
          borderRadius: 100,
          color: Colors.white,
        }}
      >
        {getInitials(bank?.name)}
      </div>
    )
  }
}
