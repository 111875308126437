import { Platform, StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

const styles = StyleSheet.create({
  steps: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stepItem: {
    flexGrow: 1,
    flexShrink: 1,
    position: 'relative',
    alignItems: 'center',
    textAlign: 'center',
  },
  stepIconContent: {
    borderColor: Colors.transparent,
    flexDirection: 'row',
  },
  stepIcon: {
    color: Colors.secondary,
  },
  stepDividerContainer: {
    paddingHorizontal: 15,
    flex: 1,
    width: '80%',
    top: -17,
    left: '50.0%',
  },
  stepDivider: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.black,
    borderStyle: 'solid',
    width: '100%',
  },
  stepContent: {
    maxWidth: '80px',
  },
  stepTitle: {
    textAlign: 'center',
    color: Colors.black,
    ...Platform.select({
      web: {
        overflowWrap: 'initial',
        whiteSpace: 'initial',
      },
    }),
  },
  stepTitleTypography: {
    display: 'flex',
  },
  stepDescription: {
    color: Colors.ghostBlack,
  },
})

export default styles
