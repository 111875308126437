import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { EditModal } from 'UI/Pages/MyProfile/Components/EditModal'

import { Button, ButtonSkin, Card, CardSkin, Field, Input, Typography } from '@neo-commons/components'
import { ContactActions, ContactTypes } from '@neo-commons/store'

import { ContactChannelDto, ContactDto } from '@afone/neo-core-client/dist/models'

import { useResponsive } from '@neo-web/hooks'
import { LazyLoadingContainer, ConfirmModal } from '@neo-web/componentsOld'

import { ContactChannelList } from './ContactChannelList'
import { LabelContactContent } from './LabelContactContent'

import './Create.scss'

type CreateProps = {
  onCreate: (contact: ContactDto) => void,
  contact?: ContactDto,
}

export const Create: React.FC<CreateProps> = (props: CreateProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTabletOrMobile = useResponsive()
  const [firstName, setFirstName] = useState(props.contact?.givenName ?? '')
  const [name, setName] = useState(props.contact?.familyName ?? '')
  const [showEditModal, setShowEditModal] = useState(false)
  const [channels, setChannels] = useState<ContactChannelDto[]>(props.contact?.channels ?? [])
  const [editModalTitle, setEditModalTitle] = useState('')
  const [editModalContent, setEditModalContent] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const saveContact = (contact: ContactChannelDto, indexEdit?: number) => {
    if (indexEdit >= 0) {
      const newChannels = channels

      newChannels[indexEdit] = contact

      setChannels(newChannels)
    } else {
      channels.push(contact)
    }

    setShowEditModal(false)
  }

  const openModalEdit = (title: string, editModalContent: ReactElement) => {
    setEditModalTitle(title)
    setEditModalContent(editModalContent)

    setShowEditModal(true)
  }

  const submit = async () => {
    try {
      let contact
      if (!props.contact) {
        contact = await dispatch(
          ContactActions.create({
            channels,
            familyName: name,
            givenName: firstName,
          }))
        await dispatch(ContactActions.setSelected(contact.uuid))
      } else {
        contact = await dispatch(
          ContactActions.update({
            ...props.contact,
            channels,
            familyName: name,
            givenName: firstName,
          }))
      }
      if (props.onCreate) props.onCreate(contact)
      await dispatch(ContactActions.list())
    } catch (errorMessage) {
      console.warn('Failed to send contact')
    }
  }

  const deleteContact = async () => {
    try {
      const contact: any = await dispatch(ContactActions.remove(props.contact!))

      if (props.onCreate) props.onCreate(contact)
    } catch (errorMessage) {
      console.warn('Failed to send contact')
    }
  }

  const isFormValid = (): boolean => {
    return Boolean(name.trim()) && channels.length > 0
  }

  const removeContact = (indexEdit: number) => {
    const newChannels = channels

    newChannels.splice(indexEdit, 1)

    setChannels(newChannels)

    setShowEditModal(false)
  }

  return (
    <LazyLoadingContainer
      events={[
        ContactTypes.BYID_CONTACT_REQUEST,
        ContactTypes.CREATE_CONTACT_REQUEST,
        ContactTypes.DELETE_CONTACT_REQUEST,
      ]}
    >
      <EditModal
        title={editModalTitle}
        show={showEditModal}
        onClose={() => {
          setShowEditModal(false)
        }}
        content={editModalContent}
      />
      <ConfirmModal
        title={t('app:pages:payments:contact:form:confirm:delete')}
        cancelText={t('app:pages:payments:contact:form:confirm:button:cancel')}
        confirmText={t('app:pages:payments:contact:form:confirm:button:delete')}
        show={showDeleteModal}
        onConfirm={() => deleteContact()}
        onClose={() => setShowDeleteModal(false)}
      />
      <div className='ContactCreate_container'>
        <div className='MyInfo_title'>
          <Typography typeface='header'>
            {t('app:pages:payments:contact:create:form:name:label')}
          </Typography>
        </div>
        <Card
          skin={isTabletOrMobile ? CardSkin.DEFAULT : CardSkin.PRIMARY_DARKER_BORDERED}
          withShadow
        >
          <div className='ContactCreate_block'>
            {props.contact ? (
              <>
                <Field
                  label={t('app:pages:payments:contact:create:form:name:firstname')}
                  value={firstName}
                  onEditPress={() => openModalEdit(t('app:pages:payments:contact:form:label:edit'),
                    <LabelContactContent
                      familyName={name}
                      givenName={firstName}
                      save={(familyName, givenName) => {
                        setName(familyName)
                        setFirstName(givenName)

                        setShowEditModal(false)
                      }}
                    />)}
                  valueStyle={{ textAlign: 'left' }}
                />
                <Field
                  label={t('app:pages:payments:contact:create:form:name:name')}
                  value={name}
                  valueStyle={{ textAlign: 'left' }}
                />
              </>
            ) : (
              <>
                <Input
                  label={t('app:pages:payments:contact:create:form:name:firstname')}
                  value={firstName}
                  onChangeText={(text) => setFirstName(text)}
                />
                <Input
                  label={t('app:pages:payments:contact:create:form:name:name')}
                  isRequired
                  value={name}
                  onChangeText={(text) => setName(text)}
                />
              </>
            )}

          </div>
        </Card>

        <ContactChannelList
          channels={channels}
          onPressOut={(title, element) => {
            openModalEdit(
              title,
              element)
          }}
          saveContact={saveContact}
          removeContact={removeContact}
          contact={props.contact}
        />

        {props.contact &&
          <div className='TransferSummary_action'>
            <Button
              title={t('app:pages:payments:contact:form:delete')}
              skin={ButtonSkin.OUTLINE_SECONDARY}
              onPressOut={async () => {
                setShowDeleteModal(true)
              }}
            />
          </div>}

        <div className='TransferSummary_action'>
          <Button
            title={t('app:pages:payments:contact:create:form:save')}
            skin={ButtonSkin.PRIMARY}
            disabled={!isFormValid()}
            onPressOut={() =>
              (isFormValid()) && submit()}
          />
        </div>
      </div>
    </LazyLoadingContainer>
  )
}
