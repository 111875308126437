import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { StepComponentProps, BusinessSubscriptionSteps, BusinessSubscriptionStep } from '@neo-commons/policies'
import { Icon, IconTypes, MenuItemCard, Stepper2, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { DocumentUploadActions, SubscriptionSelectors } from '@neo-commons/store'
import { EnumUtils, SubscriptionUtils } from '@neo-commons/libraries'

import { SubscriptionDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './Receipts.scss'

type ReceiptsProps = StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.Receipts]>

export const Receipts: React.FC<ReceiptsProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const isLoadingSubscription: boolean = useSelector((state: StateTypes) => state.subscription?.loading)
  const kycDocumentsIncomplete = SubscriptionUtils.getKycDocumentsIncomplete(subscription)

  useEffect(() => {
    (async () => {
      if (!SubscriptionUtils.getKycDocumentsIncomplete(subscription).length) {
        nextStep()
      }
    })()
  }, [subscription])

  return (
    <TunnelContent
      loading={isLoadingSubscription}
      justifyContent={TunnelJustifyContent.START}
      header={
        <div className='Receipts_header'>
          <Typography2 typeface='h3'>
            {t('neo-commons:subscription:business:legalStatus:title')}
          </Typography2>
          <Stepper2 currentStep={0} />
        </div>
      }
      icon={<Icon name='zoom_kyc' type={IconTypes.NEOFONT} size={50} color={Colors.secondary} />}
      subtitle={t('app:pages:subscription:business:receipts:title')}
      description={t('app:pages:subscription:business:receipts:description')}
    >
      <div className='Receipts_container'>
        {kycDocumentsIncomplete.map((it, index) => {
          return (
            <div className='Receipts_container_item' key={index}>
              <MenuItemCard
                title={t(EnumUtils.getKycTypeDtoKey(it.type))}
                onPress={() => {
                  dispatch(DocumentUploadActions.prepare({
                    documentType: it.type,
                    name: t(EnumUtils.getKycTypeDtoKey(it.type)),
                  }))
                  history.push(Routes.DocumentUpload.index.path)
                }}
              />
            </div>
          )
        })}
      </div>
    </TunnelContent>
  )
}
