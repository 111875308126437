import { AbstractPolicy } from 'Policies/AbstractPolicy'

import { ProvisioningState } from '@neo-commons/store'

import { AccountDto } from '@afone/neo-core-client/dist/models'

import { Routes } from '../Routes/Routes'

export class CreditPolicy extends AbstractPolicy {
  public static creditRedirect (creditCardProvisioning: ProvisioningState, bankAccount: AccountDto): string {
    let redirect = Routes.Credit.index.path

    switch (true) {
      case !CreditPolicy.checkBankAccountSelected(bankAccount):
        redirect = Routes.Synthesis.index.path
        break
      case CreditPolicy.checkBankAccountSelected(bankAccount):
        redirect = Routes.Credit.amount.path
        break
      case !CreditPolicy.canStep2(creditCardProvisioning):
        redirect = Routes.Credit.amount.path
        break
      case !CreditPolicy.canStep3(creditCardProvisioning) &&
            CreditPolicy.canStep2(creditCardProvisioning) &&
            CreditPolicy.hasExternalCards():
        redirect = Routes.Credit.chooseCard.path
        break
      case CreditPolicy.canStep3(creditCardProvisioning):
        redirect = Routes.Credit.summary.path
        break
      case CreditPolicy.canStepValidate(creditCardProvisioning):
        redirect = Routes.Credit.validate.path
        break
    }
    return redirect
  }

  public static hasExternalCards (): boolean {
    const externalCards = this.state.externalCard.list
    return externalCards?.ids.length !== 0
  }

  public static canStep2 (creditCardProvisioning: ProvisioningState): boolean {
    return creditCardProvisioning.prepare?.amount > 0
  }

  public static canStep3 (creditCardProvisioning: ProvisioningState): boolean {
    const savedData = creditCardProvisioning.prepare?.card?.saved?.cvv
    const newData = creditCardProvisioning.prepare?.card?.new?.tokenizedData

    return (savedData !== undefined || newData !== undefined)
  }

  public static creditCardProvisioningOnSuccess (creditCardProvisioning: ProvisioningState): boolean {
    let result = false
    const card = creditCardProvisioning.prepare?.card?.new?.tokenizedData
    const amount = creditCardProvisioning.prepare?.amount
    result = Boolean(card) && Boolean(amount)

    return result && CreditPolicy.canStep3(creditCardProvisioning)
  }

  public static canStepValidate (creditCardProvisioning: ProvisioningState): boolean {
    let result = false
    const savedData = creditCardProvisioning.prepare?.card?.saved
    const newData = creditCardProvisioning.prepare?.card?.new

    result =
      savedData?.uuid !== undefined ||
      newData?.formData?.scheme !== undefined
    return result
  }

  public static checkBankAccountSelected (bankAccount: AccountDto): boolean {
    return bankAccount !== undefined && bankAccount?.uuid !== undefined
  }
}
