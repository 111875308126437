import { StyleSheet, TextStyle, ViewStyle } from 'react-native'

import { Typo } from '@neo-commons/styles'

interface RadioButtonScreenStyle {
  container: ViewStyle,
  label: TextStyle,
  outerButton: ViewStyle,
  innerButton: ViewStyle,
}

export default StyleSheet.create<RadioButtonScreenStyle>({
  container: {
    flexDirection: 'row',
    paddingVertical: 10,
  },
  outerButton: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerButton: {
    height: 12,
    width: 12,
    borderRadius: 6,
  },
  label: {
    ...Typo.transactionAmount,
    fontSize: 16,
    paddingHorizontal: 15,
    bottom: 1,
  },
})
