import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Pressable, StyleSheet, View } from 'react-native'

import { debounce } from 'lodash'
import { AutoComplete, Input } from 'rsuite'

import { Country } from '@neo-commons/store'
import { CountryList, Flags, Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { AddressUtils, SelectionMode } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import {
  AutocompleteModeDto,
  AutocompletePredictionDto,
  CountryDto,
} from '@afone/neo-core-client/dist/models'

import './AddressForm.scss'

const styles = StyleSheet.create({
  label: {
    display: 'flex',
    position: 'relative',
    width: 'fit-content',
    left: 15,
    top: 28,
    backgroundColor: Colors.white,
    paddingHorizontal: 4,
    color: Colors.gray2,
    marginTop: -24,
  },
  country: {
    marginRight: 8,
    paddingHorizontal: 16,
    paddingTop: 10,
    paddingBottom: 8,
    borderWidth: 1,
    borderColor: '#AAAAAA',
    borderRadius: 12,
  },
})

export interface AddressFormProps {
  countries: CountryDto[],
  getFormData: (object) => any,
  values?: {
    countryData: Country,
    zipCode: string,
    address: string,
    additionalAddress: string,
    cityName: string,
  },
}

export type AddressDataType = {
  address: string | null,
  zipCode: string | null,
  cityName: string | null,
  additionalAddress: string | null,
  countryData: Country | null,
}

export const AddressForm: React.FC<AddressFormProps> = ({ countries, values, getFormData }) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [countryData, setCountryData] =
    useState<CountryDto>(countries.find(c => c.isoCodeAlpha3 === 'FRA'))
  const flag = Flags[countryData?.isoCodeAlpha3 ?? 'Unknown'] ?? Flags.Unknown

  const [zipCodeData, setZipCodeData] = useState(null)
  const [cityNameData, setCityNameData] = useState(null)
  const [addressData, setAddressData] = useState(null)

  const [zipCode, setZipCode] = useState(values?.zipCode)
  const [cityName, setCityName] = useState(values?.cityName)
  const [address, setAddress] = useState(values?.address)
  const [additionalAddress, setAdditionalAddress] = useState(values?.additionalAddress)

  const [zipCodeFocus, setZipCodeFocus] = useState(false)
  const [cityNameFocus, setCityNameFocus] = useState(false)
  const [addressFocus, setAddressFocus] = useState(false)
  const [additionalAddressFocus, setAdditionalAddressFocus] = useState(false)

  const inputLine2Ref: any = useRef<HTMLInputElement>()

  const updateStateByMode = (value, mode, mapper: {mode: AutocompleteModeDto, setState: React.Dispatch<any>}[]) =>
    mapper.find(m => m.mode === mode).setState(value)

  const autocompleteCall = async (text: string, autocompleteMode: AutocompleteModeDto) => {
    const res: any = await AddressUtils.autoCompleteAddress(text, autocompleteMode, countryData?.isoCodeAlpha2)
    if (res?.length) {
      updateStateByMode(res?.map(el => { return { value: el, label: el.description } }), autocompleteMode, [
        { mode: AutocompleteModeDto.ADDRESS, setState: setAddressData },
        { mode: AutocompleteModeDto.POSTAL_CODE, setState: setZipCodeData },
        { mode: AutocompleteModeDto.CITY, setState: setCityNameData },
      ])
    }
    return res?.data
  }

  const debounceAutocomplete = useCallback(
    debounce(autocompleteCall, 300), [countryData]
  )

  const splitAddress = async (autocompletePrediction: AutocompletePredictionDto) => {
    const { resAddressLongName, resZipCode, resCity } =
      await AddressUtils.callDetailsByPlaceId(autocompletePrediction.placeId)

    if (resAddressLongName !== 'undefined') {
      setAddress(resAddressLongName)
    }
    resZipCode && setZipCode(resZipCode?.longName)
    resCity && setCityName(resCity?.longName)
  }

  const handleChangeAutocomplete = (value, event, autocompleteMode: AutocompleteModeDto, maxLength) => {
    if (event.type === 'change' && value?.length <= maxLength) {
      value && debounceAutocomplete(value, autocompleteMode)

      updateStateByMode(value, autocompleteMode, [
        { mode: AutocompleteModeDto.ADDRESS, setState: setAddress },
        { mode: AutocompleteModeDto.POSTAL_CODE, setState: setZipCode },
        { mode: AutocompleteModeDto.CITY, setState: setCityName },
      ])
    }

    if (event.type === 'click') {
      splitAddress(value)
    }
  }

  useEffect(() => {
    const data = {
      countryData,
      cityName,
      address,
      zipCode,
      additionalAddress,
    }
    getFormData(data)
  }, [countryData, cityName, address, zipCode, additionalAddress])

  useEffect(() => {
    inputLine2Ref.current.maxLength = 60
  }, [])

  return (
    <>
      <div className='AddressForm_container-custom-dropdown'>
        <Pressable
          onPress={() => setIsOpen(!isOpen)}
          style={styles.country}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Image style={{ width: 20, height: 20, marginTop: 2, marginRight: 8 }} source={flag} />
            <Typography2 typeface='body1'>{countryData.name}</Typography2>
            <View
              style={{ marginLeft: 'auto' }}
            >
              <Icon
                color={Colors.gray2}
                name='chevron-down'
                type={IconTypes.ENTYPO}
                size={24}
              />
            </View>
          </div>
        </Pressable>
        {isOpen &&
          <div
            className='custom-dropdown'
          >
            <CountryList
              countries={countries}
              selectionMode={SelectionMode.LIVE_COUNTRY}
              hidePrefix
              onCountrySelectedAction={(country) => {
                setIsOpen(false)
                setCountryData(country)
              }}
            />
          </div>}
      </div>
      {(addressFocus || address !== '') &&
        <Typography2 style={styles.label} typeface='body3'>
          {t('neo-commons:address:line1')}
        </Typography2>}
      <AutoComplete
        className='AddressForm_autoComplete'
        placeholder={!addressFocus && t('neo-commons:address:line1')}
        onBlur={() => setAddressFocus(false)}
        onFocus={() => setAddressFocus(true)}
        data={addressData} value={address}
        onChange={(value, event) => handleChangeAutocomplete(value, event, 'address', 60)}
      />
      {(additionalAddressFocus || additionalAddress !== '') &&
        <Typography2 style={styles.label} typeface='body3'>
          {t('neo-commons:address:line2')}
        </Typography2>}
      <Input
        ref={inputLine2Ref}
        className='AddressForm_input'
        placeholder={!additionalAddressFocus && t('neo-commons:address:line2')}
        onBlur={() => setAdditionalAddressFocus(false)}
        onFocus={() => setAdditionalAddressFocus(true)}
        value={additionalAddress}
        onChange={(value) => setAdditionalAddress(value)}
      />
      {(zipCodeFocus || zipCode !== '') &&
        <Typography2 style={styles.label} typeface='body3'>
          {t('neo-commons:address:zipCode')}
        </Typography2>}
      <AutoComplete
        className='AddressForm_autoComplete'
        placeholder={!zipCodeFocus && t('neo-commons:address:zipCode')}
        onBlur={() => setZipCodeFocus(false)}
        onFocus={() => setZipCodeFocus(true)}
        data={zipCodeData} value={zipCode}
        onChange={(value, event) => handleChangeAutocomplete(value, event, 'postal_code', 20)}
      />
      {(cityNameFocus || cityName !== '') &&
        <Typography2 style={styles.label} typeface='body3'>
          {t('neo-commons:address:city')}
        </Typography2>}
      <AutoComplete
        className='AddressForm_autoComplete'
        placeholder={!cityNameFocus && t('neo-commons:address:city')}
        onBlur={() => setCityNameFocus(false)}
        onFocus={() => setCityNameFocus(true)}
        data={cityNameData} value={cityName}
        onChange={(value, event) => handleChangeAutocomplete(value, event, 'city', 50)}
      />
    </>
  )
}
