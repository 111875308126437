import { ClientUtils } from '@neo-commons/libraries'
import { PosActions } from '@neo-commons/store'
import { AddressTypeDto, ClientDto } from '@afone/neo-core-client/dist/models'

import { invokeActionCreator, createWizardPolicy } from '../../utils'

const machineId = 'posOrder'

/*
 * Guards
 */

const hasNoAddress = (context) => {
  const client = Object.values(context?.store.getState().client.data as ClientDto[])[0]
  const addresses = ClientUtils.getClientAddresses(client, AddressTypeDto.DELIVERY)
  return addresses.length === 0
}

const isAddressFilled = (context) => {
  return !!context?.store.getState().pos.prepare?.deliveryAddress
}

const hasPos = (context) => {
  return context?.store.getState().pos?.list?.ids?.length > 0 ?? false
}

/*
 * Steps
 */

export enum POSOrderStep {
  ModelChoice = 'ModelChoice',
  AddressCheck = 'AddressCheck',
  AddressForm = 'AddressForm',
  Summary = 'Summary',
  Process = 'Process',
  Failure = 'Failure',
  Success = 'Success',
  RenameAccount = 'RenameAccount',
}

export const posOrderSteps = {
  [POSOrderStep.ModelChoice]: {
    path: 'modelChoice',
    nextStep: POSOrderStep.AddressCheck,
    fulfill: invokeActionCreator(PosActions.selectPos),
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:order:title',
    },
  },
  [POSOrderStep.AddressCheck]: {
    path: 'addressCheck',
    nextStep: POSOrderStep.AddressForm,
    skipIf: isAddressFilled,
    bypassIf: hasNoAddress,
    fulfill: invokeActionCreator(PosActions.prepare),
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:order:title',
    },
  },
  [POSOrderStep.AddressForm]: {
    path: 'addressForm',
    nextStep: POSOrderStep.Summary,
    bypassIf: isAddressFilled,
    fulfill: invokeActionCreator(PosActions.prepare),
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:order:title',
    },
  },
  [POSOrderStep.Summary]: {
    path: 'summary',
    nextStep: POSOrderStep.Process,
    editableSteps: [
      POSOrderStep.ModelChoice,
      POSOrderStep.AddressCheck,
    ],
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:order:summary',
    },
  },
  [POSOrderStep.Process]: {
    path: 'waiting',
    nextStep: POSOrderStep.Success,
    onErrorRedirectStep: POSOrderStep.Failure,
    fulfill: invokeActionCreator(PosActions.orderPos),
    history: {
      preventStack: true,
    },
  },
  [POSOrderStep.Failure]: {
    path: 'failure',
    nextStep: 'final',
    history: {
      preventStack: true,
    },
  },
  [POSOrderStep.Success]: {
    path: 'success',
    nextStep: POSOrderStep.RenameAccount,
    history: {
      preventStack: true,
    },
  },
  [POSOrderStep.RenameAccount]: {
    path: 'renameAccount',
    nextStep: 'final',
    bypassIf: hasPos,
    history: {
      preventStack: true,
    },
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:order:title',
    },
  },
}

export type POSOrderSteps = typeof posOrderSteps

/*
 * Policy initialization
 */
export const posOrderPolicy = createWizardPolicy({
  machineId: machineId,
  steps: posOrderSteps,
  initialStep: POSOrderStep.ModelChoice,
  basePath: '/services/pos/order',
})
