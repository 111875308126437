
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { FlexboxGrid } from 'rsuite'

import {
  Button,
  ButtonSkin,
  Input,
} from '@neo-commons/components'
import { ClientActions, ClientTypes } from '@neo-commons/store'

import { ClientDto } from '@afone/neo-core-client/dist/models'

import { LazyLoadingContainer } from '@neo-web/componentsOld'

export interface RealActivityFieldProps {
  client: ClientDto
  onCloseModal: () => void
}

export const RealActivityField: React.FC<RealActivityFieldProps> = ({
  client,
  onCloseModal,
} : RealActivityFieldProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [realActivity, setRealActivity] = useState<string>(client?.holder?.realActivity)

  const updateClientRealActivity = () => {
    (async () => {
      try {
        await dispatch(ClientActions.updateHolder(client, {
          ...client?.holder,
          realActivity: realActivity,
        }))
        onCloseModal()
      } catch (error) {}
    })()
  }

  return (
    <LazyLoadingContainer events={[ClientTypes.UPDATE_CLIENT_HOLDER_REQUEST]}>
      <FlexboxGrid.Item colspan={24}>
        <Input
          label={t('app:pages:myProfile:informations:companyProfile:otherInfos:realActivity')}
          value={realActivity}
          onChangeText={(value) => setRealActivity(value)}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24} className='EditModal_saveButton'>
        <Button
          skin={ButtonSkin.PRIMARY}
          disabled={!realActivity}
          title={t('app:global:save')}
          onPress={() => { updateClientRealActivity() }}
        />
      </FlexboxGrid.Item>
    </LazyLoadingContainer>
  )
}
