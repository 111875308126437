import { StyleSheet } from 'react-native'

import { Commons, Typo, Colors } from '@neo-commons/styles'

export default StyleSheet.create({
  row: {
    ...Commons.row,
    alignItems: 'center',
    paddingTop: 10,
    width: '100%',
  },
  linearGradiant: {
    marginTop: 2,
    borderRadius: 5,
    width: '85%',
    height: 10,
  },
  steps: {
    ...Typo.infoRegular,
    fontSize: 12,
    marginLeft: 15,
  },
  leftLabel: {
    ...Typo.infoRegular,
  },
  rightLabel: {
    ...Typo.infoRegular,
    color: Colors.ghostBlack,
  },
  progressBarContainer: {
    alignSelf: 'center',
    flex: 1,
    marginLeft: 20,
    marginRight: 20,
    paddingBottom: 8,
  },
  progressBarBackground: {
    position: 'absolute', left: 0, top: 0, height: 10, width: '100%', borderRadius: 100,
  },
  progressBarForeground: {
    position: 'absolute', left: 0, top: 0, height: 10, borderRadius: 100,
  },
})
