import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { AppConfig, AppConfigTxt } from 'Config/AppConfig'
import { Routes } from 'Routes/Routes'

import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  MenuItemCard,
  Typography,
} from '@neo-commons/components'
import { OfferUtils } from '@neo-commons/libraries'
import { ProjectStep, projectSteps } from '@neo-commons/policies'
import {
  BankAccountActions,
  BankAccountSelectors,
  ClientSelectors,
  OfferActions,
  OfferSelectors,
  SubscriptionSelectors,
} from '@neo-commons/store'
import Colors from '@neo-commons/styles/src/Colors'
import { size } from '@neo-commons/styles/src/Typo'

import {
  AccountDto,
  AccountTypeDto,
  ClientDto,
  ClientTypeDto,
  OfferDto,
  OfferTypeDto,
} from '@afone/neo-core-client/dist/models'

import {
  Modal,
  JustifyContent,
  PageContent,
} from '@neo-web/componentsOld'

import './Projects.scss'

export const ProjectMenu: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const client: ClientDto = useSelector(ClientSelectors.byId(selectedAccount?.clientUuid))
  const mainSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  const socleOffer = useSelector(OfferSelectors.getByUuid(mainSubscription?.offerUuid ?? mainSubscription?.offer?.uuid))

  const projectOffer: OfferDto[] = useSelector(OfferSelectors.listByClientType(
    client?.type === ClientTypeDto.INDIVIDUAL ? ClientTypeDto.INDIVIDUAL : ClientTypeDto.CORPORATE,
    OfferTypeDto.PROJECT)
  )

  const preparedSubAccountOrderState = useSelector((state: StateTypes) => state?.bankAccount.preparedSubAccountOrder)
  const offerState = useSelector((state: StateTypes) => state.offer)

  const [openModal, setOpenModal] = useState(false)
  const [offerPrice, setOfferPrice] = useState(projectOffer[0]?.price)
  const [offerProjectNumberPerSubscription, setOfferProjectNumberPerSubscription] = useState(AppConfig.PROJECT_NUMBER_PER_SUBSCRIPTION)

  useEffect(() => {
    (async () => {
      try {
        if (!offerState.loading) {
          await dispatch(OfferActions.listForClientUuid())
        }
      } catch (error) {}
    })()
  }, [])

  const handleCreateProject = () => {
    if (preparedSubAccountOrderState?.cost?.amount === 0 || OfferUtils.isOfferAdvance(socleOffer)) {
      setOpenModal(false)
      history.push(projectSteps[ProjectStep.Name].path)
    } else {
      setOfferPrice((projectOffer[0]?.price))
      setOfferProjectNumberPerSubscription((projectOffer[0]?.data as any)?.maxAccountPerSubscription)
      setOpenModal(true)
    }
  }
  useEffect(() => {
    (async () => {
      await dispatch(
        BankAccountActions.createSubAccount({
          mainAccountUuid: selectedAccount?.uuid,
          xValidationOnly: 1,
          subAccountCreationRequestDto: {
            accountType: AccountTypeDto.PROJECT,
            icon: AppConfigTxt.DEFAULT_PROJECT_ICON,
          },
        })
      )
    })()
  }, [])

  return (
    <>
      <PageContent
        justifyContent={JustifyContent.START}
      >
        {openModal &&
          <Modal
            open={openModal}
            displayCross
            onClose={() => {
              setOpenModal(false)
            }}
            size='sm'
            title={t('app:pages:services:project:listProjects:modal:title')}
            footer={
              <Button
                skin={ButtonSkin.PRIMARY}
                disabled={false}
                title={t('app:pages:services:project:listProjects:modal:buttonText',
                  { maxAccountPerSubscription: offerProjectNumberPerSubscription })}
                overrideStyleButton={{ width: 'fit-content', alignSelf: 'center' }}
                onPress={() => {
                  setOpenModal(false)
                  history.push(projectSteps[ProjectStep.Name].path)
                }}
              />
            }
          >
            <div className='ProjectMenu_modalDescription'>
              <Typography typeface='contentLightGrey'>
                {t('app:pages:services:project:listProjects:modal:description',
                  {
                    amount: offerPrice,
                    maxAccountPerSubscription: offerProjectNumberPerSubscription,
                    interpolation: { escapeValue: false },
                  }
                )}
              </Typography>
            </div>
          </Modal>}

        <MenuItemCard
          title={t('app:pages:services:project:listProjects:createProject')}
          titleStyle={{ fontSize: size.regular }}
          icon={<Icon type={IconTypes.FEATHER} name='plus-circle' size={24} color={Colors.primary} />}
          chevronPosition='center'
          onPress={handleCreateProject}
          disabled={!preparedSubAccountOrderState}
        />
        <hr />
        <MenuItemCard
          title={t('app:pages:services:project:listProjects:list')}
          titleStyle={{ fontSize: size.regular }}
          icon={<Icon name='piggy-bank' type={IconTypes.NEOFONT} size={24} color={Colors.primary} />}
          chevronPosition='center'
          onPress={() => history.push(Routes.Services.ProjectsRoutes.listProjects.path)}
        />
        <MenuItemCard
          title={t('app:pages:services:project:listProjects:closeProject')}
          titleStyle={{ fontSize: size.regular }}
          chevronPosition='center'
          onPress={() => history.push(Routes.Services.ProjectsRoutes.closeProjectList.path)}
        />
      </PageContent>
    </>
  )
}
