import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { UserSecretQuestionTypes } from '@neo-commons/store'

import { displayAlertGlobal$ } from '../Epics'

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    UserSecretQuestionTypes.CHECK_SECRET_QUESTION_FAILURE,
    UserSecretQuestionTypes.BLOCKED,
  ])
}

export default [
  displayDropdownAlert$,
]
