import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { Animation, Dropdown } from 'rsuite'
import { AppConfig } from 'Config/AppConfig'
import { Responsive } from 'Config/Responsive'
import { config, ENV } from 'Config/EnvConfig'
import { Routes } from 'Routes/Routes'
import dayjs from 'dayjs'

import { BankAccountUtils, ClientUtils, EnumUtils, NumbersUtils, Validators } from '@neo-commons/libraries'
import {
  BankAccount,
  BankAccountActions,
  BankAccountTypes,
  CardTypes,
  ClientActions,
  ClientSelectors,
  TransactionTypes,
} from '@neo-commons/store'
import { Button, ButtonSkin, Icon, IconTypes, Input, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { AccountDto, AccountStatusDto, ClientDto } from '@afone/neo-core-client/dist/models'

import { BankAccountAction, BankAccountCardSkin, useButton, useIcon, useSkin } from '@neo-web/hooks'
import { AccountSuspendedModal, LazyLoadingContainer, Modal, PriceCard } from '@neo-web/componentsOld'

import './BankAccountPreview.scss'

interface BankAccountPreviewProps {
  selectedBankAccount: AccountDto
  listBankAccounts: BankAccount[]
  onIbanClick: () => void
  selectedMain?: AccountDto
  listSecondaryAccounts?: BankAccount[]
  listMainAccounts?: BankAccount[]
  displayTop?: boolean
  onTransferClick?: () => void
  onCollectClick?: () => void
}

export const BankAccountPreview: React.FC<BankAccountPreviewProps> = ({
  selectedMain,
  selectedBankAccount,
  listSecondaryAccounts,
  listMainAccounts,
  listBankAccounts,
  displayTop,
  onIbanClick,
  onTransferClick,
  onCollectClick,
}: BankAccountPreviewProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const [bankAccountListHeight, setBankAccountListHeight] = useState(0)
  const [isSecondarySet, setIsSecondarySet] = useState(false)
  const showMoreElement = { projects: false, accounts: false }
  const [showMore, setShowMore] = useState(showMoreElement)
  const [showEditModal, setShowEditModal] = useState(false)
  const [openSuspendedModal, setOpenSuspendedModal] = useState(false)
  const [bankAccountName, setBankAccountName] = useState<string>('')
  const [isAccountNameValid, setIsAccountNameValid] = useState(false)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)

  const isMobile = useMediaQuery({ query: `(max-width: ${Responsive.WITH_MOBILE}px)` })
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.WITH_TABLET}px)` })

  const menuIcon =
    <Icon
      name='more-vertical'
      color={isSecondarySet ? Colors.white : Colors.primary}
      type={IconTypes.FEATHER}
      size={24}
    />

  const menu =
    <Dropdown icon={menuIcon} noCaret placement={isMobile ? 'bottomEnd' : 'bottomStart'}>
      {BankAccountUtils.isMain(selectedBankAccount) &&
        <Dropdown.Item onSelect={() =>
          history.push(Routes.Profile.invoiceAndContractDetail.path)}
        >
          {t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:title')}
        </Dropdown.Item>}
      {!BankAccountUtils.isAggregated(selectedBankAccount) &&
        <Dropdown.Item onSelect={() => setShowEditModal(true)}>
          {t(BankAccountUtils.isMain(selectedBankAccount)
            ? 'app:pages:synthesis:renameAccount'
            : 'app:pages:synthesis:renameSubAccount')}
        </Dropdown.Item>}
      <Dropdown.Item onSelect={() =>
        history.push(generatePath(Routes.BankAccount.detail.path, { id: selectedBankAccount.uuid }))}
      >
        {t(BankAccountUtils.isMainOrAggregated(selectedBankAccount)
          ? 'app:pages:synthesis:aboutAccount'
          : 'app:pages:synthesis:aboutSubAccount')}
      </Dropdown.Item>
      {BankAccountUtils.isAggregated(selectedBankAccount) &&
        <Dropdown.Item onSelect={() => history.push(Routes.Services.aggregatedAccounts.path)}>
          {t('app:pages:synthesis:showWhichAccounts:title')}
        </Dropdown.Item>}
    </Dropdown>

  const addBtn = (
    <div className='BankAccountPreview_sidebar-btnAdd'>
      <Button
        skin={ButtonSkin.PRIMARY}
        title={t('app:global:addAccount')}
        onPress={() => history.push(Routes.BankAccount.create.path)}
      />
    </div>)

  const updateBankAccount = () => {
    (async () => {
      try {
        selectedBankAccount.name = bankAccountName
        await dispatch(BankAccountActions.update(selectedBankAccount.uuid, selectedBankAccount))
        setShowEditModal(false)
      } catch (error) {}
    })()
  }

  const getReconnectUrl = async ({
    bankAccountSelectedUuid,
    reconnect,
  }: {
    bankAccountSelectedUuid: string
    reconnect?: boolean
  }) => {
    const redirectUri = encodeURI(`${config(ENV.COMNPAY_ORIGIN)}services/aggregation/fallback`)
    try {
      const response = await dispatch(BankAccountActions.getAggregation(
        {
          reconnect,
          redirectUri,
          bankAccountUuid: bankAccountSelectedUuid,
          connectorId: '',
          xValidationOnly: 0,
        })) as any
      window.location.href = response?.data
    } catch (e) {
      if (e.status === 410) { // Powens is expired, AIIA is the new provider
        history.push(Routes.Services.aggregationSearch.path)
      } else {
        console.log(e)
      }
    }
  }

  const getSubtitle = (bankAccount: AccountDto, client: ClientDto) => {
    let subtitle: string
    if (BankAccountUtils.isSecondary(bankAccount) || BankAccountUtils.isAggregated(bankAccount)) {
      if (bankAccount?.lastUpdate) {
        subtitle = t('app:pages:synthesis:accountDisconnected', { date: dayjs(bankAccount?.lastUpdate).format('L') })
      } else {
        subtitle = t(EnumUtils.getAccountTypeDtoKey(bankAccount.type))
      }
    } else if (BankAccountUtils.isMain(bankAccount)) {
      if (client && ClientUtils.isClientTypeCorporate(client.type)) {
        subtitle = t('neo-commons:account:pro')
      } else {
        subtitle = t('neo-commons:account:part')
      }
    } else {
      subtitle = t(EnumUtils.getAccountTypeDtoKey(bankAccount.type))
    }
    return subtitle
  }

  const displayBankAccounts = (
    bankAccounts,
    skinButtons: BankAccountCardSkin,
    isLarge?: boolean,
    isShowMore?: boolean,
    onShowMore?: (value:boolean) => void) => {
    const nbAccountVisible = BankAccountUtils.isSecondary(bankAccounts[0])
      ? isSecondarySet ? 1
        : AppConfig.DEFAULT_ACCOUNT_DISPLAY
      : AppConfig.DEFAULT_ACCOUNT_DISPLAY

    return (
      <>
        {bankAccounts.map((bankAccount: AccountDto, i: number) => {
          const card =
            <PriceCard
              key={i}
              menu={menu}
              isLarge={bankAccount.uuid === selectedBankAccount.uuid ?? isLarge}
              skin={useSkin(bankAccount)}
              icon={useIcon(bankAccount, client?.type)}
              isSuspended={bankAccount?.status === AccountStatusDto.SUSPENDED || bankAccount?.status === AccountStatusDto.ACTION_NEEDED}
              onBadgePress={bankAccount?.status === AccountStatusDto.SUSPENDED ? () => setOpenSuspendedModal(true) : undefined}
              badgeTitle={
                bankAccount?.status === AccountStatusDto.SUSPENDED
                  ? t('neo-commons:aggregation:accountSuspended')
                  : bankAccount?.status === AccountStatusDto.ACTION_NEEDED
                    ? t('neo-commons:aggregation:accountExpired')
                    : t('neo-commons:aggregation:accountLocked')
              }
              price={isLarge
                ? NumbersUtils.displayPriceForHumanWithCurrency(bankAccount.balance, bankAccount?.currencyCode)
                : ''}
              title={isLarge
                ? BankAccountUtils.getName(bankAccount)
                : NumbersUtils.displayPriceForHumanWithCurrency(bankAccount.balance, bankAccount?.currencyCode)}
              subTitle={isLarge ? getSubtitle(bankAccount, client) : BankAccountUtils.getName(bankAccount)}
              onClick={async () => {
                try {
                  dispatch({ type: TransactionTypes.RESET })
                  dispatch({ type: CardTypes.RESET })
                  await dispatch(BankAccountActions.setSelected(bankAccount.uuid))
                  if (BankAccountUtils.isMain(bankAccount)) {
                    setIsSecondarySet(false)
                  } else {
                    setIsSecondarySet(true)
                  }
                } catch (error) {}
              }}
              isError={!!bankAccount?.lastUpdate}
              buttons={
                bankAccount.status === AccountStatusDto.ACTION_NEEDED
                  ? (
                    <Button
                      skin={ButtonSkin.OUTLINE_SECONDARY}
                      title={t('neo-commons:aggregation:reconnect')}
                      onPress={() => getReconnectUrl({
                        bankAccountSelectedUuid: bankAccount.uuid as string,
                        reconnect: true as boolean,
                      }) as any}
                    />)
                  : (useButton(bankAccount,
                    skinButtons,
                    (action) => {
                      switch (action) {
                        case BankAccountAction.CREDIT:
                          history.push(Routes.Credit.creditChoices.path, { background: location })
                          break
                        case BankAccountAction.IBAN:
                          onIbanClick && onIbanClick()
                          break
                        case BankAccountAction.TRANSFER:
                          onTransferClick && onTransferClick()
                          break
                        case BankAccountAction.COLLECT:
                          onCollectClick && onCollectClick()
                          break
                      }
                    }))
              }
            />

          const render = (cardContent: any) => {
            const isLast = bankAccounts[bankAccounts.length - 1].uuid === bankAccount.uuid
            let content = null

            content = (
              <div>
                {content}
                {cardContent}
                {isLast && showMore.accounts &&
                  BankAccountUtils.isMainOrAggregated(bankAccounts[bankAccounts.length - 1]) && addBtn}
              </div>
            )

            return (
              i >= nbAccountVisible &&
                <div key={i}>
                  <Animation.Collapse in={isShowMore}>
                    <div>
                      {content}
                    </div>
                  </Animation.Collapse>
                  {isLast &&
                    <div
                      className='BankAccountPreview_sidebar-showMoreBtn'
                      onClick={() => onShowMore(!isShowMore)}
                    >
                      <Typography typeface='smallText'>
                        {isShowMore ? t('app:pages:synthesis:sidebar:showLess')
                          : t('app:pages:synthesis:sidebar:showMore')}
                      </Typography>
                    </div>}
                </div>
            )
          }

          return (nbAccountVisible <= i ? render(card) : card)
        })}
      </>
    )
  }

  useEffect(() => {
    if (selectedBankAccount) {
      BankAccountUtils.isSecondary(selectedBankAccount) ? setIsSecondarySet(true) : setIsSecondarySet(false)
      setBankAccountName(selectedBankAccount.name)
    }
  }, [isSecondarySet, selectedBankAccount])

  useEffect(() => {
    if (!client) {
      (async () => {
        try {
          await dispatch(ClientActions.list())
        } catch (e) {}
      })()
    }
  }, [])

  useLayoutEffect(() => {
    setTimeout(() => {
      if (isTabletOrMobile) {
        const titleHeight = document.getElementsByClassName('BankAccountsList_title')[0]?.clientHeight ?? 0
        const headerHeight = document.getElementsByClassName('BankAccountPreview_header')[0]?.clientHeight ?? 0
        setBankAccountListHeight(titleHeight + headerHeight + 85)
      } else {
        const logoHeight = document.getElementsByClassName('navbar-brand')[0]?.clientHeight ?? 0
        const headerHeight = document.getElementsByClassName('BankAccountPreview_header')[0]?.clientHeight ?? 0
        setBankAccountListHeight(logoHeight + headerHeight - 10)
      }
    }, 100)
  }, [])

  return (
    <div className='BankAccountPreview'>
      {selectedBankAccount && selectedBankAccount.uuid &&
        <>
          {displayTop
            ? displayBankAccounts(
              [selectedBankAccount],
              BankAccountUtils.isSecondary(selectedBankAccount)
                ? BankAccountCardSkin.SECONDARY
                : BankAccountCardSkin.DEFAULT,
              true,
            )
            : (
              <>
                <LazyLoadingContainer events={[BankAccountTypes.LIST_BANK_ACCOUNT_REQUEST]}>
                  <div className='BankAccountPreview_header'>
                    {displayBankAccounts(
                      [selectedMain],
                      BankAccountCardSkin.DEFAULT,
                      !isSecondarySet,
                    )}
                    {isSecondarySet && displayBankAccounts(
                      [selectedBankAccount],
                      BankAccountCardSkin.SECONDARY,
                      isSecondarySet,
                    )}
                  </div>
                </LazyLoadingContainer>
                <div
                  className='BankAccountPreview_sidebar'
                  style={{ height: `calc(100vh - ${bankAccountListHeight ?? 500}px` }}
                >
                  {displayBankAccounts(
                    isSecondarySet
                      ? listBankAccounts.filter(bankAccount =>
                        bankAccount?.parentUuid && bankAccount.parentUuid === selectedMain.uuid &&
                        bankAccount.uuid !== selectedBankAccount.uuid,
                      )
                      : listSecondaryAccounts,
                    BankAccountCardSkin.DEFAULT,
                    BankAccountUtils.isSecondary(selectedBankAccount) ? false : isSecondarySet,
                    showMore.projects,
                    () => setShowMore({ projects: !showMore.projects, accounts: showMore.accounts })
                  )}
                  {listMainAccounts?.length > 1 ? (
                    <div className='BankAccountPreview_sidebar-title'>
                      <Typography typeface='subtitle'>
                        {t('app:pages:synthesis:myOtherBanksAccounts')}
                      </Typography>
                    </div>
                  ) : addBtn}
                  {displayBankAccounts(
                    listMainAccounts.filter(bankAccount => bankAccount.uuid !== selectedBankAccount.uuid &&
                          selectedMain.uuid !== bankAccount.uuid),
                    BankAccountCardSkin.DEFAULT,
                    false,
                    showMore.accounts,
                    () => setShowMore({ projects: showMore.projects, accounts: !showMore.accounts })
                  )}
                  {listMainAccounts?.length > 1 && listMainAccounts?.length < 4 && addBtn}
                </div>
              </>
            )}
        </>}

      <Modal
        className='BankAccountPreview_modal'
        open={showEditModal}
        displayCross
        onClose={() => setShowEditModal(false)}
        title={t(BankAccountUtils.isMain(selectedBankAccount)
          ? 'app:pages:synthesis:bankAccount:rename'
          : 'app:pages:synthesis:bankSubAccount:rename')}
      >
        <Input
          label={t('app:global:field:accountName')}
          value={bankAccountName}
          maxLength={50}
          isRequired
          validators={[Validators.isNotEmpty, Validators.noEmojis]}
          onValidationChecked={value => setIsAccountNameValid(value.isValid)}
          onChangeText={(text) => setBankAccountName(text)}
          withTextCounter
        />
        <div className='BankAccountPreview_modal-btn'>
          <Button
            disabled={!isAccountNameValid}
            skin={ButtonSkin.PRIMARY}
            title={t('app:global:validate')}
            onPress={() => updateBankAccount()}
          />
        </div>
      </Modal>
      <AccountSuspendedModal
        open={openSuspendedModal} onClose={() => setOpenSuspendedModal(false)}
        description={i18n.exists(`neo-commons:accountSuspended:modal:${selectedBankAccount?.suspension?.reason}`)
          ? t(`neo-commons:accountSuspended:modal:${selectedBankAccount?.suspension?.reason}`)
          : t('neo-commons:accountSuspended:modal:default')}
      />
    </div>
  )
}
