import { AnyAction, Reducer } from 'redux'
import i18next from 'i18next'
import {
  AddressDto,
  ClientCreateDto,
  ClientDto,
  PersonAffiliationDto,
  PersonDto,
} from '@afone/neo-core-client/dist/models'
import { NeobankApi } from '@neo-commons/services'
import { createSelector } from 'reselect'
import { omit } from 'lodash'
import produce from 'immer'

import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { AlertType, initialResourceState, State } from '../utils'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Client = ClientDto

const {
  resourceActionTypes: ClientActionTypes,
  resourceReducer: ClientResourceReducer,
  resourceAction: ClientAction,
  resourceSelector: ClientResourceSelector,
} = ResourceStateFactory<Client, 'client'>((state: State) => state.client, 'client')

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const clientSelector = state => state.client

export const ClientSelectors = {
  ...ClientResourceSelector,
  defaultOne: createSelector(clientSelector, resource => resource.data[Object.keys(resource.data)[0]] ?? undefined),
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

export const ClientTypes = {
  ...ClientActionTypes,

  ADD_AFFILIATE_REQUEST: 'client/ADD_AFFILIATE_REQUEST',
  ADD_AFFILIATE_SUCCESS: 'client/ADD_AFFILIATE_SUCCESS',
  ADD_AFFILIATE_FAILURE: 'client/ADD_AFFILIATE_FAILURE',

  UPDATE_AFFILIATE_REQUEST: 'client/UPDATE_AFFILIATE_REQUEST',
  UPDATE_AFFILIATE_SUCCESS: 'client/UPDATE_AFFILIATE_SUCCESS',
  UPDATE_AFFILIATE_FAILURE: 'client/UPDATE_AFFILIATE_FAILURE',

  DELETE_AFFILIATE_REQUEST: 'client/DELETE_AFFILIATE_REQUEST',
  DELETE_AFFILIATE_SUCCESS: 'client/DELETE_AFFILIATE_SUCCESS',
  DELETE_AFFILIATE_FAILURE: 'client/DELETE_AFFILIATE_FAILURE',

  ATTACH_ME_REQUEST: 'client/ATTACH_ME_REQUEST',
  ATTACH_ME_SUCCESS: 'client/ATTACH_ME_SUCCESS',
  ATTACH_ME_FAILURE: 'client/ATTACH_ME_FAILURE',

  CREATE_ADDRESS_REQUEST: 'client/CREATE_ADDRESS_REQUEST',
  CREATE_ADDRESS_SUCCESS: 'client/CREATE_ADDRESS_SUCCESS',
  CREATE_ADDRESS_FAILURE: 'client/CREATE_ADDRESS_FAILURE',

  UPDATE_CLIENT_HOLDER_REQUEST: 'client/UPDATE_CLIENT_HOLDER_REQUEST',
  UPDATE_CLIENT_HOLDER_SUCCESS: 'client/UPDATE_CLIENT_HOLDER_SUCCESS',
  UPDATE_CLIENT_HOLDER_FAILURE: 'client/UPDATE_CLIENT_HOLDER_FAILURE',
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

const list = function () {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ClientTypes.LIST_CLIENT_REQUEST })

    try {
      const getClientsResponse = await NeobankApi.getInstance().clientApi.getClients()
      const clients = getClientsResponse.status === 204 ? [] : getClientsResponse.data
      dispatch({ type: ClientTypes.LIST_CLIENT_SUCCESS, data: clients })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: ClientTypes.LIST_CLIENT_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

function create (payload?: Partial<ClientCreateDto>) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: ClientTypes.CREATE_CLIENT_REQUEST })

    try {
      const prepared = getState().client.prepare
      const client = { ...prepared, ...(payload ?? {}) } as ClientCreateDto
      const createClientResponse = await NeobankApi.getInstance().clientApi.createClient(client)

      dispatch({ type: ClientTypes.CREATE_CLIENT_SUCCESS, data: createClientResponse.data })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: ClientTypes.CREATE_CLIENT_FAILURE, errorMessage })
      throw error
    }
  }
}

const update = function (updateRequest?: Partial<ClientDto>) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: ClientTypes.UPDATE_CLIENT_REQUEST })

    try {
      const clientUpdate = {
        ...getState().client.update,
        ...updateRequest,
      }

      const defaultClientUuid = ClientSelectors.defaultOne(getState()).uuid
      const toUpdate = omit(clientUpdate, 'uuid')
      if (!defaultClientUuid) {
        throw new Error()
      }

      const updateClientByUuidResponse = await NeobankApi.getInstance().clientApi.updateClientByUuid(
        defaultClientUuid,
        toUpdate
      )
      const updatedClient = updateClientByUuidResponse.data

      dispatch({
        type: ClientTypes.UPDATE_CLIENT_SUCCESS,
        data: updatedClient,
        alertType: AlertType.SUCCESS,
        successMessage: i18next.t('neo-commons:app:global:profile:update:success'),
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: ClientTypes.UPDATE_CLIENT_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const addAffiliate = (client: ClientDto, affiliate: PersonAffiliationDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ClientTypes.ADD_AFFILIATE_REQUEST })
    try {
      const response = await NeobankApi.getInstance().clientApi.addAffiliatePerson(
        client.uuid!,
        {
          types: affiliate.types,
          person: {
            firstName: affiliate.person!.firstName,
            lastName: affiliate.person!.lastName,
            phone: affiliate.person!.phone!.internationalFormat!,
            email: affiliate.person!.email!,
          },
        }
      )
      dispatch({ type: ClientTypes.ADD_AFFILIATE_SUCCESS, data: response.data })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: ClientTypes.ADD_AFFILIATE_FAILURE, errorMessage })
    }
  }
}

const updateAffiliate = (client: ClientDto, affiliate: PersonAffiliationDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ClientTypes.UPDATE_AFFILIATE_REQUEST })
    try {
      const response = await NeobankApi.getInstance().clientApi.updateAffiliatePersonByUuid(
        client.uuid!,
        affiliate.uuid!,
        {
          types: affiliate.canUpdate ? affiliate.types : undefined,
          person: {
            firstName: affiliate.canUpdate ? affiliate.person!.firstName : undefined,
            lastName: affiliate.canUpdate ? affiliate.person!.lastName : undefined,
            phone: affiliate.person!.phone!.internationalFormat!,
            email: affiliate.person!.email!,
          },
        }
      )
      // TODO: Back response doesn't contain all properties
      const updatedClient: ClientDto = produce(client, newClient => { newClient.holder!.affiliates = response.data.holder?.affiliates })
      dispatch({ type: ClientTypes.UPDATE_AFFILIATE_SUCCESS, data: updatedClient })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: ClientTypes.UPDATE_AFFILIATE_FAILURE, errorMessage })
    }
  }
}

const deleteAffiliate = (client: ClientDto, affiliate: PersonAffiliationDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ClientTypes.DELETE_AFFILIATE_REQUEST })
    try {
      await NeobankApi.getInstance().clientApi.deleteAffiliatePersonByUuid(client.uuid!, affiliate.uuid!)
      const updatedClient: ClientDto = produce(client, newClient => {
        newClient.holder!.affiliates = client.holder?.affiliates!.filter((aff) => aff.uuid !== affiliate.uuid)
      })
      dispatch({ type: ClientTypes.DELETE_AFFILIATE_SUCCESS, data: updatedClient })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: ClientTypes.DELETE_AFFILIATE_FAILURE, errorMessage })
    }
  }
}

const attachMe = (payload: { affiliateUuid: string}) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: ClientTypes.ATTACH_ME_REQUEST })
    try {
      const client: Client = ClientSelectors.defaultOne(getState())
      await NeobankApi.getInstance().clientApi.attachMe(client.uuid!, payload.affiliateUuid)
      dispatch({ type: ClientTypes.ATTACH_ME_SUCCESS, clientUuid: client.uuid, affiliateUuid: payload.affiliateUuid })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: ClientTypes.ATTACH_ME_FAILURE, errorMessage })
    }
  }
}

const prepare = (data: Partial<ClientCreateDto>) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ClientActionTypes.PREPARE,
      data,
    })
  }
}

const createAddress = (address: AddressDto) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: ClientTypes.CREATE_ADDRESS_REQUEST })
    try {
      const client: Client = ClientSelectors.defaultOne(getState())
      const createdAddress = await NeobankApi.getInstance().addressApi.createAddress(client.uuid!, 0, address)
      dispatch({
        type: ClientTypes.CREATE_ADDRESS_SUCCESS,
        clientUuid: client.uuid,
        address: createdAddress.data,
      })
      return createdAddress.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: ClientTypes.CREATE_ADDRESS_FAILURE, errorMessage })
      return address
    }
  }
}

/*
 * Update client's holder
 * It requires its own function on client state because the holder is updated with personApi
 */
const updateHolder = (client: ClientDto, holder: PersonDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ClientTypes.UPDATE_CLIENT_HOLDER_REQUEST })

    try {
      const updatePersonResponse = await NeobankApi.getInstance().personApi.updatePersonByUuid(
        holder.uuid!, // holder is nullable in Dto but it can't be in reality
        holder,
      )
      dispatch({
        type: ClientTypes.UPDATE_CLIENT_HOLDER_SUCCESS,
        successMessage: i18next.t('neo-commons:app:global:profile:update:success'),
        data: {
          ...client,
          holder: updatePersonResponse.data,
        },
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: ClientTypes.UPDATE_CLIENT_HOLDER_FAILURE, errorMessage })
    }
  }
}

export const ClientActions = {
  ...ClientAction,
  list,
  create,
  update,
  prepare,
  createAffiliate: addAffiliate,
  updateAffiliate,
  deleteAffiliate,
  attachMe,
  createAddress,
  updateHolder,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type ClientState = Omit<ResourceState<Client>, 'create' | 'prepare'> & {
  prepare?: Partial<ClientCreateDto>
}

const initialState: ClientState = {
  ...initialResourceState,
  prepare: undefined,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const client: Reducer = (state: ClientState = initialState, action: AnyAction): ClientState => {
  switch (action.type) {
    case ClientTypes.ATTACH_ME_REQUEST:
    case ClientTypes.DELETE_AFFILIATE_REQUEST:
    case ClientTypes.UPDATE_AFFILIATE_REQUEST:
    case ClientTypes.ADD_AFFILIATE_REQUEST:
    case ClientTypes.CREATE_ADDRESS_REQUEST:
    case ClientTypes.UPDATE_CLIENT_HOLDER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ClientTypes.ATTACH_ME_FAILURE:
    case ClientTypes.ATTACH_ME_SUCCESS:
    case ClientTypes.DELETE_AFFILIATE_FAILURE:
    case ClientTypes.UPDATE_AFFILIATE_FAILURE:
    case ClientTypes.ADD_AFFILIATE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case ClientTypes.DELETE_AFFILIATE_SUCCESS:
    case ClientTypes.UPDATE_AFFILIATE_SUCCESS:
    case ClientTypes.ADD_AFFILIATE_SUCCESS:
    case ClientTypes.UPDATE_CLIENT_HOLDER_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        newState.data[action.data.uuid] = action.data
      })
    case ClientTypes.UPDATE_CLIENT_HOLDER_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case ClientTypes.CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.clientUuid]: {
            ...state.data[action.clientUuid],
            holder: {
              ...state.data[action.clientUuid].holder,
              addresses: (state?.data?.[action.clientUuid].holder?.addresses ?? []).concat(action.address),
            },
          },
        },
      }
    default:
      return {
        ...ClientResourceReducer(state, action, {
          identifier: 'uuid',
          isPaginate: true,
          initialState,
        }),
      }
  }
}
