import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { ENV, config } from 'Config/EnvConfig'

import { UrlUtils } from '@neo-web/utils'

export const Start3DS = () => {
  const url = useLocation().search
  const query = UrlUtils.deserialize(url)
  let form = null

  useEffect(() => {
    form.submit()
  })

  return (
    <div className='content-center'>
      <form action={query.actionUrl} method='post' ref={el => (form = el)}>
        {query.pareq
          ? (
            <>
              <input type='hidden' name='MD' value={query.md} />
              <input type='hidden' name='PaReq' value={query.pareq} />
              <input type='hidden' name='TermUrl' value={config(ENV.THREEDS_TERM_URL)} />
            </>
          ) : (
            <input type='hidden' name='creq' value={query.creq} />
          )}
      </form>
    </div>
  )
}
