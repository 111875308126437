import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import { Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import {
  BankAccountSelectors,
  BankTransferTypeEnum,
  OfferSelectors,
  Subscription,
  SubscriptionSelectors,
  TransferActions,
} from '@neo-commons/store'
import { OfferUtils } from '@neo-commons/libraries'

import { AccountDto } from '@afone/neo-core-client/models'

import { CardMenuItem, CardMenuList, JustifyContent, PageContent } from '@neo-web/componentsOld'

import './SelectTransfer.scss'

export const SelectTransfer: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const transfer = useSelector((state: StateTypes) => state.transfer)
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts())
  const mainSubscription: Subscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  const socleOffer = useSelector(OfferSelectors.getByUuid(mainSubscription?.offerUuid ?? mainSubscription?.offer?.uuid))

  const goToTransferProcess = async (transferType: BankTransferTypeEnum) => {
    dispatch(TransferActions.resetPrepare())

    if (bankAccounts.length === 1) {
      await dispatch(TransferActions.prepare({
        transferType: transferType,
        accountUuid: bankAccounts[0]?.uuid,
      }))
      history.push(Routes.Payments.beneficiary.path)
    } else {
      dispatch(TransferActions.prepare({
        transferType: transferType,
      }))
      history.push(Routes.Payments.account.path)
    }
  }

  const menuCards: CardMenuItem[] = [
    {
      title: t('app:pages:payments:pay:transfer:normal:title'),
      content: OfferUtils.isOfferAdvance(socleOffer)
        ? t('app:pages:payments:pay:transfer:advanceOffer:normal:content')
        : t('app:pages:payments:pay:transfer:normal:content'),
      disabled: false,
      onPress: () => goToTransferProcess(BankTransferTypeEnum.Punctual),
    },
    {
      title: t('app:pages:payments:pay:transfer:planned:title'),
      content: OfferUtils.isOfferAdvance(socleOffer)
        ? t('app:pages:payments:pay:transfer:advanceOffer:planned:content')
        : t('app:pages:payments:pay:transfer:planned:content'),
      onPress: () => goToTransferProcess(BankTransferTypeEnum.Scheduled),
    },
    {
      title: t('app:pages:payments:pay:transfer:recurrence:title'),
      content: OfferUtils.isOfferAdvance(socleOffer)
        ? t('app:pages:payments:pay:transfer:advanceOffer:recurrence:content')
        : t('app:pages:payments:pay:transfer:recurrence:content'),
      onPress: () => goToTransferProcess(BankTransferTypeEnum.Repeated),
    },
    {
      title: t('app:pages:payments:pay:transfer:instant:title'),
      content: OfferUtils.isOfferAdvance(socleOffer)
        ? t('app:pages:payments:pay:transfer:advanceOffer:instant:content')
        : t('app:pages:payments:pay:transfer:instant:content'),
      onPress: () => history.push(Routes.Payments.instant.path),
    },
  ]

  useEffect(() => {
    try {
      if (transfer.isDelete) {
        dispatch(TransferActions.reset())
      }
      if (transfer.list.shouldRefresh || !transfer.list.loadedOnce) {
        dispatch(TransferActions.getRecurringTransfers())
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <PageContent justifyContent={JustifyContent.START}>
      <CardMenuList
        menus={menuCards}
      />
      <div className='SelectTransfer_internalTransfer'>
        <Icon
          type={IconTypes.FEATHER}
          color={Colors.secondary}
          name='heart'
          size={20}
        />
        <Typography2 typeface='buttonMedium'>{t('app:pages:payments:pay:transfer:internal')}</Typography2>
      </div>
    </PageContent>
  )
}
