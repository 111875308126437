import _ from 'lodash'

import { Validators } from './Validators'
import data from './data/currencies.json'

export enum Local {
  FR = 'fr-FR',
  US = 'en-US'
}

/**
 * Generic methods use in NoElse for format value to display.
 *
 */
export class NumbersUtils {
  /**
   * Get a number and locality for return a string of price to display,
   * like 10.00 into 10,00€.
   *
   * @param {number} number - Number to convert.
   * @param {Local} local - Locality for money acronym.
   * @returns {string} Return the string format.
   * @example
   * NumbersUtils.displayPriceForHuman(selectedBankAccount?.balance!)
   *
   */
  public static displayPriceForHuman (
    number?: number,
    local?: Local,
    alwaysKeepDecimals = true,
    sign = false,
    forceCurrency?: string,
    currencyDisplay?: string
  ): string {
    // Checks.
    if (_.isNil(number)) {
      return ' '
    }
    if (_.isNil(local)) {
      local = Local.FR
    }

    // Currency Processing.
    let localInside = Local.US
    let currency = 'USD'

    if (local === Local.FR) {
      localInside = local
      currency = 'EUR'
    } else if (local === Local.US) {
      localInside = local
      currency = 'USD'
    }

    if (forceCurrency) currency = forceCurrency

    const formatter = new Intl.NumberFormat(localInside, {
      style: 'currency',
      currency: currency,
      ...(currencyDisplay ? { currencyDisplay: currencyDisplay } : {}),
    })

    let formatNumber = alwaysKeepDecimals ? formatter.format(number) : formatter.format(number).replace('.00', '').replace(',00', '')

    if (sign && number !== 0) {
      if (number > 0) {
        formatNumber = '+ ' + formatNumber
      } else {
        formatNumber = '- ' + formatNumber.split('-')[1]
      }
    }

    return formatNumber
  }

  /**
   * Returns a formatted and signed price from number, like '+ 10,00€' from 10.
   *
   * @param {number} number - Number to convert.
   * @returns {string} Returns the formatted string.
   *
   */
  public static displaySignedPriceForHuman (number ?: number): string {
    return NumbersUtils.displayPriceForHuman(number, undefined, true, true)
  }

  /**
   * Returns a formatted price from number with the wanted currency.
   *
   * @param {number} number - Number to convert.
   * @param {string} currency - Currency alpha code to use.
   * @returns {string} Returns the formatted string.
   *
   */
  public static displayPriceForHumanWithCurrency (number?: number, currency?: string): string {
    const price = NumbersUtils.displayPriceForHuman(number, undefined, true, false, currency, 'code')
    const currencySymbol = currency &&
      (data.currencies?.[currency as string]['symbol-alt-narrow'] ?? data.currencies?.[currency as string].symbol)
    return currency ? price.replace(currency, currencySymbol) : number?.toString() ?? ''
  }

  /**
   * Replace the . In the string into a float,
   * like '10.00' into 10,00.
   *
   * @param {string} text - String to convert.
   * @returns {number} Return the number format.
   *
   */
  public static stringToFloat (text: string): number {
    if (typeof (text) !== 'string') {
      return 0
    }

    return parseFloat(text.replace(',', '.')) || 0
  }

  /**
   * Replace the account format string into a number.
   *
   * @param {string} text - String to convert.
   * @returns {number} Return the number format.
   *
   */
  public static amountFormatToNumber (text: string): number {
    if (typeof (text) !== 'string') {
      return 0
    } else if (text.trim() === '' || !Validators.isAmountFormat(text).isValid) {
      return 0
    }

    const formattedNumber = text.replace(',', '.')
    const hasDecimals = formattedNumber.match(/\./)
    const decimalPrecision = hasDecimals ? formattedNumber.split('.')[1].length : 0
    if (hasDecimals && decimalPrecision === 0) {
      formattedNumber.replace('.', '')
    }

    const parsedNumber = parseFloat(formattedNumber)
    if (Number.isNaN(parsedNumber)) { return 0 }

    return parseFloat(parsedNumber.toFixed(2)) // force decimal to not exceed 2
  }

  /**
   * Get a credit card numbers and return a crypted card number,
   * like '1111 2222 3333 4444' into '**** **** **** 4444'.
   *
   * @param {string} value - Card number to convert.
   * @returns {string} Return the number format.
   *
   */
  public static hideCreditCardNumbers = (value: string) => {
    const masked = '**** **** **** '
    if (!Validators.isNotEmpty(value).isValid) {
      return ''
    }
    const valueWithoutSpace = value.trim()
    const visible = valueWithoutSpace.substring((valueWithoutSpace.length) - 4, valueWithoutSpace.length)
    return (masked + visible)
  }

  /**
   * Check if the money input is correct.
   *
   * @param {string} value - Card number to convert.
   * @returns {void}
   *
   */
  public static checkMoneyInput = (value: string, action: (value: string) => void) => {
    // eslint-disable-next-line no-useless-escape
    const moneyRegex = RegExp('^[0-9]+(\.[0-9]{0,2})?$')
    if (!value) {
      action('')
      return
    }
    if (moneyRegex.test(value?.toString() ?? '')) {
      action(value.toString())
    }
  }
}
