import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { POSReturnStep, POSReturnSteps, StepComponentProps } from '@neo-commons/policies'
import {
  AddressTypes,
  ClientSelectors,
  PosSelectors,
  PosTypes,
  PreparePOSReturn,
} from '@neo-commons/store'
import { ClientUtils } from '@neo-commons/libraries'

import { AddressDto, AddressTypeDto, ClientDto } from '@afone/neo-core-client/dist/models'

import { AddressSelect, LazyLoadingContainer } from '@neo-web/componentsOld'

type POSReturnProps = StepComponentProps<POSReturnSteps[POSReturnStep.AddressCheck]>

export const AddressCheck: React.FC<POSReturnProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const preparedPOSReturn: PreparePOSReturn = useSelector(PosSelectors.prepareReturn)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const deliveryAddresses: AddressDto[] = ClientUtils.getClientAddresses(client, AddressTypeDto.DELIVERY)
  const currentAddress: AddressDto = preparedPOSReturn?.deliveryAddress
    ? preparedPOSReturn?.deliveryAddress
    : (deliveryAddresses.length ? deliveryAddresses[0] : undefined)

  const [selectedAddress, setSelectedAddress] = useState(currentAddress)

  const addNewAddress = () => {
    delete preparedPOSReturn.deliveryAddress
    nextStep(preparedPOSReturn)
  }

  const validAddress = () => {
    nextStep({
      ...preparedPOSReturn,
      deliveryAddress: selectedAddress,
    })
  }

  return (
    <LazyLoadingContainer events={[PosTypes.PREPARE_POS_RETURN_REQUEST, AddressTypes.LIST_ADDRESS_REQUEST]}>
      <AddressSelect
        title={t('neo-commons:pages:services:pos:return:selectAddress:title')}
        selectedAddress={selectedAddress}
        onSelectAddress={setSelectedAddress}
        addNewAddress={addNewAddress}
        validAddress={validAddress}
      />
    </LazyLoadingContainer>
  )
}
