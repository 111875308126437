import React, { FunctionComponent } from 'react'

import IBAN from 'iban'
import { Colors } from '@neo-commons/styles'
import { Formatters, Validators } from '@neo-commons/libraries'

import { Icon, IconTypes } from '..'
import { Field } from '../Field/Field'
import { Card, CardSkin } from '../Card/Card'
import { ThemeProvider } from '../ThemeProvider'

import styles from './styles'

export interface ChannelContactCardProps {
  label: string,
  value: string,
  onEditPress?: () => void,
  leftIcon?: any,
  editable?: boolean,
  selected?: boolean,
  isNoElse?: boolean,
  editableContactDetail?: boolean
}

type IconContact = {
  name: string
  type: IconTypes
}

export const ChannelContactCard: FunctionComponent<ChannelContactCardProps> =
  (props: ChannelContactCardProps, editableContactDetail) => {
    const { editable, isNoElse } = props

    const generateIcon = (): IconContact => {
      let result = { name: '', type: IconTypes.MATERIAL_COMMUNITY }

      switch (true) {
        case props.isNoElse && editable:
          result = { name: 'dark-logo', type: IconTypes.NEOFONT }
          break
        case IBAN.isValid(props.value):
          result = { name: 'bank', type: IconTypes.MATERIAL_COMMUNITY }
          break
        case Validators.isPhoneNumber(props.value).isValid:
          result = { name: 'phone', type: IconTypes.FEATHER }
          break
        case Validators.isEmail(props.value).isValid:
          result = { name: 'alternate-email', type: IconTypes.MATERIAL }
          break
      }

      return result
    }

    const formatValue = (valueToFormat: string): string => {
      let result = valueToFormat

      switch (true) {
        case IBAN.isValid(result):
          result = Formatters.formatIban(result, true)
          break
        case Validators.isPhoneNumber(result).isValid:
          result = Formatters.formatPhone(result)
          break
      }

      return result
    }

    return (
      <Card
        containerStyle={[
          !props.selected ? styles.card : styles.select, {
            marginBottom: ThemeProvider.get().ChannelContactCard.marginBottom,
          },
        ]}
        skin={CardSkin.TOUCHABLE}
        withShadow={!editable}
      >
        <Field
          value={formatValue(props.value) ?? ''}
          label={props.label}
          onEditPress={props.onEditPress}
          textColor={props.selected ? Colors.white : undefined}
          valueStyle={styles.valueStyle}
          leftIcon={
            <Icon
              name={generateIcon().name}
              type={generateIcon().type}
              testID={'channelIcon-' + generateIcon().name}
              size={24}
              color={(props.isNoElse && props.editable) ? Colors.black
                : props.selected ? Colors.white : Colors.secondary}
            />
          }
          rightIcon={
            (!editableContactDetail || !editable)
              ? !isNoElse && !editable ? <></>
                : (
                  <Icon
                    name='dark-logo'
                    color={props.selected ? Colors.white : Colors.black}
                    type={IconTypes.NEOFONT}
                    size={24}
                  />
                )
              : undefined
          }
        />
      </Card>
    )
  }
