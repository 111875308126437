
import React, { Dispatch, SetStateAction } from 'react'

import { Flags, Typography2 } from '@neo-commons/components'
import { CountryUtils } from '@neo-commons/libraries'

import { CountryDto } from '@afone/neo-core-client/dist/models'

import { DataItemType, RSSelect } from './Select'

import './SelectCountry.scss'

interface SelectCountryProps {
  placeholder: string,
  countries: CountryDto[],
  selectedCountry: CountryDto,
  setSelectedCountry: Dispatch<SetStateAction<CountryDto>>,
}

type CountryFormatted = DataItemType & { firstLetter: string }

export const SelectCountry: React.FC<SelectCountryProps> = ({
  countries, placeholder, selectedCountry, setSelectedCountry,
}: SelectCountryProps) => {
  CountryUtils.sortByName(countries)

  const countriesFiltered: CountryFormatted[] = countries.reduce(
    (cumulator: CountryFormatted[], country: CountryDto): CountryFormatted[] => {
      const firstLetter = country.name.slice(0, 1)
      const firstLetterAlreadyExist = cumulator.find(countryFormatted => countryFormatted.firstLetter === firstLetter)

      cumulator.push({
        label: country.name,
        value: country.isoCodeAlpha3,
        firstLetter: !firstLetterAlreadyExist ? firstLetter : '',
      } as CountryFormatted)
      return cumulator
    }, [])

  return (
    <RSSelect
      searchable
      data={countriesFiltered}
      placeholder={<Typography2 typeface='h5'>{placeholder}</Typography2>}
      appearance='subtle'
      value={selectedCountry?.isoCodeAlpha3}
      onChange={value => {
        setSelectedCountry(countries.find(country => country.isoCodeAlpha3 === value))
      }}
      renderMenuItem={(label, item) => {
        return (
          <div className='SelectCountry_item'>
            <p className='SelectCountry_item_firstletter'>{item.firstLetter}</p>
            <img src={String(Flags[item.value ?? 'Unknown'])} className='SelectCountry_item_flag' />
            <p className='SelectCountry_item_name'>{label}</p>
          </div>
        )
      }}
    />
  )
}
