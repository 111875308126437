import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { TunnelContent } from 'UI/Components/Layout/TunnelContent'
import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'
import { StateTypes } from 'Store'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'

import { RegisterStep, RegisterSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Icon, IconTypes, ReadOnlyInput, Stepper2, Typography2 } from '@neo-commons/components'
import { SignupProfileState } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

type CodeValidateProps = StepComponentProps<RegisterSteps[RegisterStep.CodeValidate]>

export const CodeValidate: React.FC<CodeValidateProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const profile: SignupProfileState = useSelector((state: StateTypes) => state?.signup?.profile)

  useEffect(() => {
    const phoneNumber = parsePhoneNumber(profile.phone, profile.countryCode as CountryCode)
    AnalyticsHandler.track(AnalyticsEvents.signUp.userSignUpStepCompleted,
      {
        step_name: RegisterStep.CodeValidate,
        email: profile?.email,
        phone: phoneNumber.formatInternational().replace(/ /g, ''),
      })
  }, [])

  return (
    <TunnelContent
      header={
        <View>
          <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
            {t('app:pages:register:step1:contact:signup')}
          </Typography2>
          <Stepper2 currentStep={1} />
          <Typography2 typeface='h5'>
            {t('app:pages:register:step3:codeValidate:titleMessage')}
          </Typography2>
          <Typography2 typeface='body1'>
            {t('app:pages:register:step3:codeValidate:subtitleMessage')}
          </Typography2>
        </View>
      }
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:pages:register:step3:codeValidate:next')}
          onPress={nextStep}
          leftIcon={<Icon name='questioncircleo' type={IconTypes.ANT_DESIGN} size={20} color={Colors.white} />}
        />
      }
    >
      <View style={{ alignItems: 'center', width: '100%' }}>
        <ReadOnlyInput
          label={t('app:pages:register:stepper:personalCode')}
          value={'•'.repeat(profile.password.length)}
          rightIcon={<View><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></View>}
        />
      </View>
    </TunnelContent>
  )
}
