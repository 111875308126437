
import { CreditCardData } from '@neo-commons/store'
import { CreditCardUtils } from '@neo-commons/libraries'

import { CardDto, CardStatusDto } from '@afone/neo-core-client/dist/models'

import { Routes } from '../../Routes/Routes'
import { AbstractPolicy } from '../AbstractPolicy'

export class ActivateCardPolicy extends AbstractPolicy {
  public static redirect (creditCardData: CreditCardData): string {
    let redirect = Routes.Services.index.path
    switch (true) {
      case this.canStep1(creditCardData):
        redirect = Routes.Services.activateCard.path
        break
      case this.canStep2(creditCardData):
        redirect = Routes.Services.activationSuccess.path
        break
    }

    return redirect
  }

  public static canStep1 (creditCardData: CreditCardData): boolean {
    return creditCardData && CreditCardUtils.canActivate(creditCardData)
  }

  public static canStep2 (creditCardData: CreditCardData): boolean {
    return creditCardData && creditCardData.status === CardStatusDto.ACTIVE
  }

  public static canActivate (card: CardDto): boolean {
    return CreditCardUtils.canActivate(card)
  }
}
