import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  switchContainer: {
    height: 20,
    width: 42,
    borderRadius: 30,
  },
  switchCircle: {
    height: 27,
    width: 27,
    borderRadius: 50,
    marginTop: -3,
    marginLeft: -3,
    elevation: 4,
  },
  container: {
    padding: 5,
    alignSelf: 'flex-start',
  },
})

export default styles
