import React from 'react'

import { Toggle, ToggleProps } from 'rsuite'
import './Switch.scss'

export const Switch: React.FC<ToggleProps> = (props: ToggleProps) => {
  return (
    <Toggle
      {...props}
    />
  )
}
