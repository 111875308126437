
import { map } from 'lodash'

import { BusinessSubscriptionPolicy } from '@neo-commons/policies'

export const BusinessRoutes = {
  index: {
    path: BusinessSubscriptionPolicy.basePath,
    name: 'businessSubscription',
    available: true,
  },
  ...map(
    BusinessSubscriptionPolicy.steps, ({ path, meta }) => ({
      path,
      name: meta?.screenTitle ?? '',
      available: true,
    })),
  affiliateDocumentsChoice: {
    path: BusinessSubscriptionPolicy.basePath + '/affiliate-documents-choice/:personUuid',
    name: 'app:pages:subscription:global:documentChoice:title',
    available: true,
  },
  verifyAffiliatesDocuments: {
    path: BusinessSubscriptionPolicy.basePath + '/verifyAffiliatesDocuments',
    name: 'app:pages:subscription:business:identifyAffiliatesPedagogy:titleWeb',
    available: true,
  },
}
