import { AnyAction, Reducer } from 'redux'
import i18next from 'i18next'
import { find, omit, uniq } from 'lodash'
import { NeobankApi } from '@neo-commons/services'
import {
  ClientDto,
  ClientTypeDto,
  KycTypeDto,
  OfferDto,
  OfferTypeDto,
  PersonAffiliationDto,
  SubscriptionCreateDto,
  SubscriptionDto,
  SubscriptionOfferDto,
  SubscriptionStatusDto,
} from '@afone/neo-core-client/dist/models'
import { createSelector } from 'reselect'
import { OfferUtils, SubscriptionUtils } from '@neo-commons/libraries'

import { Dispatch, resourceDefaultSelectors, ResourceState } from '../utils/resourceState'
import { initialResourceState, State } from '../utils'

import { OfferActions } from './offer'
import { UserActions } from './user'
import { ClientActions, ClientSelectors } from './client'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Subscription = SubscriptionDto & SubscriptionCreateDto
  & { offer: SubscriptionOfferDto & OfferDto, offerUuid: string }

/* %%%%%%%%%%%%%%%%%% *\
    Action Types.
\* %%%%%%%%%%%%%%%%%% */

const GET_SUBSCRIPTION_LIST_REQUEST = 'subscription/GET_SUBSCRIPTION_LIST_REQUEST'
const GET_SUBSCRIPTION_LIST_SUCCESS = 'subscription/GET_SUBSCRIPTION_LIST_SUCCESS'
const GET_SUBSCRIPTION_LIST_FAILURE = 'subscription/GET_SUBSCRIPTION_LIST_FAILURE'

const CREATE_SUBSCRIPTION_PREPARE = 'subscription/CREATE_SUBSCRIPTION_PREPARE'
const CREATE_SUBSCRIPTION_REQUEST = 'subscription/CREATE_SUBSCRIPTION_REQUEST'
const CREATE_SUBSCRIPTION_SUCCESS = 'subscription/CREATE_SUBSCRIPTION_SUCCESS'
const CREATE_SUBSCRIPTION_FAILURE = 'subscription/CREATE_SUBSCRIPTION_FAILURE'

const VALIDATE_AFFILIATION_REQUEST = 'subscription/VALIDATE_AFFILIATION_REQUEST'
const VALIDATE_AFFILIATION_SUCCESS = 'subscription/VALIDATE_AFFILIATION_SUCCESS'
const VALIDATE_AFFILIATION_FAILURE = 'subscription/VALIDATE_AFFILIATION_FAILURE'

const ATTACH_CURRENT_USER_TO_PERSON_REQUEST = 'subscription/ATTACH_CURRENT_USER_TO_PERSON_REQUEST'
const ATTACH_CURRENT_USER_TO_PERSON_SUCCESS = 'subscription/ATTACH_CURRENT_USER_TO_PERSON_SUCCESS'
const ATTACH_CURRENT_USER_TO_PERSON_FAILURE = 'subscription/ATTACH_CURRENT_USER_TO_PERSON_FAILURE'

const SUBMIT_SIRET_REQUEST = 'subscription/SUBMIT_SIRET_REQUEST'
const SUBMIT_SIRET_SUCCESS = 'subscription/SUBMIT_SIRET_SUCCESS'
const SUBMIT_SIRET_FAILURE = 'subscription/SUBMIT_SIRET_FAILURE'

const CHECK_SIGNATURE_REQUEST = 'subscription/CHECK_SIGNATURE_REQUEST'
const CHECK_SIGNATURE_SUCCESS = 'subscription/CHECK_SIGNATURE_SUCCESS'
const CHECK_SIGNATURE_FAILURE = 'subscription/CHECK_SIGNATURE_FAILURE'

const GET_SIGNATURE_REQUEST = 'subscription/GET_SIGNATURE_REQUEST'
const GET_SIGNATURE_SUCCESS = 'subscription/GET_SIGNATURE_SUCCESS'
const GET_SIGNATURE_FAILURE = 'subscription/GET_SIGNATURE_FAILURE'
const RESET_SIGNATURE_LINK = 'subscription/RESET_SIGNATURE_LINK'

const SEND_INVITATION_REQUEST = 'subscription/SEND_INVITATION_REQUEST'
const SEND_INVITATION_SUCCESS = 'subscription/SEND_INVITATION_SUCCESS'
const SEND_INVITATION_FAILURE = 'subscription/SEND_INVITATION_FAILURE'

const GET_PRODUCT_PRICE_REQUEST = 'subscription/GET_PRODUCT_PRICE_REQUEST'
const GET_PRODUCT_PRICE_SUCCESS = 'subscription/GET_PRODUCT_PRICE_SUCCESS'
const GET_PRODUCT_PRICE_FAILURE = 'subscription/GET_PRODUCT_PRICE_FAILURE'

const SET_CURRENT_PERSON_TO_VERIFY = 'SET_CURRENT_PERSON_TO_VERIFY'

const RESET = 'subscription/RESET'
const RESET_KYC_STATUS = 'subscription/RESET_KYC_STATUS'

const SOFT_CANCEL_SUBSCRIPTION = 'SOFT_CANCEL_SUBSCRIPTION'

export const SubscriptionTypes = {
  GET_SUBSCRIPTION_LIST_REQUEST,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_FAILURE,

  CREATE_SUBSCRIPTION_PREPARE,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,

  SUBMIT_SIRET_REQUEST,
  SUBMIT_SIRET_SUCCESS,
  SUBMIT_SIRET_FAILURE,

  ATTACH_CURRENT_USER_TO_PERSON_REQUEST,
  ATTACH_CURRENT_USER_TO_PERSON_SUCCESS,
  ATTACH_CURRENT_USER_TO_PERSON_FAILURE,

  VALIDATE_AFFILIATION_REQUEST,
  VALIDATE_AFFILIATION_SUCCESS,
  VALIDATE_AFFILIATION_FAILURE,

  GET_SIGNATURE_REQUEST,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_FAILURE,

  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_FAILURE,

  GET_PRODUCT_PRICE_REQUEST,
  GET_PRODUCT_PRICE_SUCCESS,
  GET_PRODUCT_PRICE_FAILURE,

  RESET_KYC_STATUS,
  RESET,

  SOFT_CANCEL_SUBSCRIPTION,
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */
const errorKeyTranslate = 'errors:unknownTechnicalIssue'

const list = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: GET_SUBSCRIPTION_LIST_REQUEST })
    try {
      await dispatch(UserActions.getUser())
      if (!getState().client.list.loadedOnce) {
        await dispatch(ClientActions.list())
      }

      const defaultClient = ClientSelectors.defaultOne(getState())
      if (!getState().offer.list.loadedOnce) { await dispatch(OfferActions.list()) }
      if (!defaultClient) {
        dispatch({
          type: GET_SUBSCRIPTION_LIST_SUCCESS,
          data: [],
          toProceedId: null,
        })
        return
      }
      const getSubscriptionsResponse = await NeobankApi.getInstance().subscriptionApi.getSubscriptions(
        50,
        undefined,
        defaultClient?.uuid
      )
      const subscriptions = getSubscriptionsResponse.data

      const subscriptionToProceed =
        getSubscriptionsResponse.status === 204
          ? null
          : subscriptions.find((subscription: SubscriptionDto) =>
            subscription.status === SubscriptionStatusDto.SUBSCRIBING &&
            subscription.offer?.type === OfferTypeDto.SOCLE
          )

      dispatch({
        type: GET_SUBSCRIPTION_LIST_SUCCESS,
        data: getSubscriptionsResponse.status === 204 ? [] : subscriptions,
        toProceedId: subscriptionToProceed?.uuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:internalTechnicalIssue')
      dispatch({ type: GET_SUBSCRIPTION_LIST_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const prepare = (data?: Partial<Subscription>) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CREATE_SUBSCRIPTION_PREPARE, data })
  }
}

const create = (payload: { offerUuid: string; discountCode?: string, clientType: ClientTypeDto }): Promise<SubscriptionDto> | any => {
  const { offerUuid, discountCode } = payload

  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: CREATE_SUBSCRIPTION_REQUEST })
    let defaultClient: ClientDto
    try {
      if (!getState().client?.list?.loadedOnce) {
        await dispatch(ClientActions.list())
      }
      defaultClient = ClientSelectors.defaultOne(getState())

      if (!getState().subscription.create) throw new Error()
      const { ibanLocalization } = getState().subscription.prepare! as Required<SubscriptionCreateDto>
      const getSubscriptionsResponse = await NeobankApi.getInstance().subscriptionApi.getSubscriptions(
        undefined,
        undefined,
        defaultClient?.uuid
      )

      const existingSubscriptions = getSubscriptionsResponse.data

      if (existingSubscriptions) {
        await dispatch({ type: GET_SUBSCRIPTION_LIST_SUCCESS, data: existingSubscriptions })

        const existingSubscriptionProcessable = find(
          existingSubscriptions,
          (subscription: SubscriptionDto) => subscription.offerUuid === offerUuid &&
            subscription.status === SubscriptionStatusDto.SUBSCRIBING
        )

        if (existingSubscriptionProcessable) {
          await dispatch({ type: CREATE_SUBSCRIPTION_SUCCESS, data: existingSubscriptionProcessable })
          return existingSubscriptionProcessable
        }
      }

      const createSubscriptionResponse = await NeobankApi.getInstance().subscriptionApi.createSubscription(
        defaultClient?.uuid ?? '',
        offerUuid,
        { ibanLocalization, discountCode }
      )

      const subscription = createSubscriptionResponse?.data
      await dispatch({ type: CREATE_SUBSCRIPTION_SUCCESS, data: subscription })
      return subscription
    } catch (error) {
      await dispatch({ type: CREATE_SUBSCRIPTION_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

const checkSignature = (payload: { subscriptionUuid: string, personUuid: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CHECK_SIGNATURE_REQUEST })
    try {
      await NeobankApi.getInstance().subscriptionApi.submitKycDocument(
        payload?.subscriptionUuid,
        payload?.personUuid,
        KycTypeDto.SIGNATURE_ADVANCED
      )
      dispatch({ type: CHECK_SIGNATURE_SUCCESS })
    } catch (error) {
      dispatch({ type: CHECK_SIGNATURE_FAILURE })
      throw error
    }
  }
}

const getSignatureLink = (payload: { subscriptionUuid: string, personUuid: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GET_SIGNATURE_REQUEST })
    try {
      const response = await NeobankApi.getInstance().subscriptionApi.generateSignatureLink(
        payload.subscriptionUuid,
        payload.personUuid
      )
      const data = response.data
      dispatch({ type: GET_SIGNATURE_SUCCESS, data })
    } catch (error) {
      dispatch({ type: GET_SIGNATURE_FAILURE })
      throw error
    }
  }
}

const getProductPrice = (payload: { subscriptionUuid: string, productCode: string }) => {
  return async (dispatch: Dispatch) => {
    if (!payload.subscriptionUuid || !payload.productCode) {
      return
    }
    dispatch({ type: GET_PRODUCT_PRICE_REQUEST })
    try {
      const response = await NeobankApi.getInstance().subscriptionApi.getProductPrice(
        payload.subscriptionUuid,
        payload.productCode
      )
      const data = response.data
      dispatch({ type: GET_PRODUCT_PRICE_SUCCESS, data })
    } catch (error) {
      dispatch({ type: GET_PRODUCT_PRICE_FAILURE })
      throw error
    }
  }
}

const resetSignatureLink = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET_SIGNATURE_LINK })
  }
}

const submitSiret = (payload: { subscriptionUuid: string, personUuid: string, siret: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SUBMIT_SIRET_REQUEST })
    try {
      await NeobankApi.getInstance().subscriptionApi.submitKycDocument(
        payload.subscriptionUuid,
        payload.personUuid,
        KycTypeDto.COMPANY_KBIS,
        { siret: payload.siret }
      )
      dispatch({ type: SUBMIT_SIRET_SUCCESS })
      await dispatch(SubscriptionActions.list())
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({ type: SUBMIT_SIRET_FAILURE, errorMessage })
      throw error
    }
  }
}

const remindInvitation = (subscription: SubscriptionDto, affiliate: PersonAffiliationDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SubscriptionTypes.SEND_INVITATION_REQUEST })
    try {
      await NeobankApi.getInstance().subscriptionApi.remindInvitation(
        subscription?.uuid,
        affiliate?.uuid!
      )
      dispatch({ type: SubscriptionTypes.SEND_INVITATION_SUCCESS })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: SubscriptionTypes.SEND_INVITATION_FAILURE, errorMessage })
    }
  }
}

const setCurrentPersonToVerify = (uuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SET_CURRENT_PERSON_TO_VERIFY, data: { uuid } })
  }
}

const reset = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET })
  }
}

const softCancel = (uuid: string) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SOFT_CANCEL_SUBSCRIPTION, data: { uuid } })
  }
}

export const SubscriptionActions = {
  list,
  prepare,
  create,
  submitSiret,
  setCurrentPersonToVerify,
  checkSignature,
  getSignatureLink,
  resetSignatureLink,
  remindInvitation,
  getProductPrice,
  reset,
  softCancel,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type SubscriptionState = Omit<ResourceState<Subscription>, 'update'> & {
  toProceedId: string | null, // uuid of an ongoing subscription customer has to finish
  currentPersonToVerifyUuid: string | null,
  signatureLink: string | undefined,
  personalisedProductPrice: { [key: string]: number };
}

const initialState: SubscriptionState = {
  ...omit(initialResourceState, 'update'),
  toProceedId: null,
  currentPersonToVerifyUuid: null,
  signatureLink: '',
  personalisedProductPrice: {},
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const subscriptionSelector: (state: State) => SubscriptionState = state => state?.subscription

const defaultSelectors = resourceDefaultSelectors(subscriptionSelector)

export const SubscriptionSelectors = {
  ...defaultSelectors,
  toProceed: createSelector(subscriptionSelector, resource => resource?.data?.[resource?.toProceedId!] ?? null),
  defaultOne: createSelector(subscriptionSelector, resource => resource?.data[Object.keys(resource?.data)[0]] ?? null),
  defaultOneSocleSubscribing: createSelector(
    defaultSelectors.list,
    (subscriptions) => {
      return subscriptions.length ? subscriptions
        .filter(subscription => OfferUtils.isSocle(subscription.offer))
        .filter(subscription => SubscriptionUtils.isSubscribing(subscription))[0] : undefined
    }
  ),
  defaultOneSocle: createSelector(
    defaultSelectors.list,
    (subscriptions) => {
      return subscriptions.length ? subscriptions
        .filter(subscription => OfferUtils.isSocle(subscription.offer))[0] : undefined
    }
  ),
  getToProceed: (offerCode: string) => createSelector(
    defaultSelectors.list,
    (subscriptions) => {
      return subscriptions.filter(
        (subscription) => {
          return subscription?.offerCode === offerCode && subscription.status === SubscriptionStatusDto.SUBSCRIBING
        }
      ).shift()
    }
  ),
  listSocle: createSelector(
    defaultSelectors.list,
    (subscriptions) => {
      return subscriptions
        .filter(subscription => OfferUtils.isSocle(subscription.offer))
    }
  ),
  listAggregated: createSelector(
    defaultSelectors.list,
    (subscriptions) => {
      return subscriptions
        .filter(subscription => OfferUtils.isAggregate(subscription.offer))
    }
  ),
  listCard: createSelector(
    defaultSelectors.list,
    (subscriptions) => {
      return subscriptions
        .filter(subscription => subscription.offer?.type === OfferTypeDto.CARD)
    }
  ),
  byId: (uuid: string) => createSelector(
    subscriptionSelector,
    resource => resource.data[uuid] ?? null
  ),
  getPrimarySubscription: createSelector(
    subscriptionSelector,
    resource => resource.data
  ),
  getProductPriceByCode: (code: string) => createSelector(
    subscriptionSelector,
    resource => resource.personalisedProductPrice[code] ?? null
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const subscription: Reducer = (state: SubscriptionState = initialState, action: AnyAction): SubscriptionState => {
  switch (action.type) {
    case GET_SUBSCRIPTION_LIST_REQUEST:
    case VALIDATE_AFFILIATION_REQUEST:
    case CREATE_SUBSCRIPTION_REQUEST:
    case ATTACH_CURRENT_USER_TO_PERSON_REQUEST:
    case SUBMIT_SIRET_REQUEST:
    case GET_SIGNATURE_REQUEST:
    case CHECK_SIGNATURE_REQUEST:
    case SEND_INVITATION_REQUEST:
    case GET_PRODUCT_PRICE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data?.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {}) ?? {},
        list: {
          ...state.list,
          ids: action.data?.map(item => item.uuid) ?? [],
          loadedOnce: true,
        },
        toProceedId: action.toProceedId,
      }
    case VALIDATE_AFFILIATION_FAILURE:
    case GET_SUBSCRIPTION_LIST_FAILURE:
    case CREATE_SUBSCRIPTION_FAILURE:
    case ATTACH_CURRENT_USER_TO_PERSON_FAILURE:
    case SUBMIT_SIRET_FAILURE:
    case GET_SIGNATURE_FAILURE:
    case CHECK_SIGNATURE_FAILURE:
    case SEND_INVITATION_FAILURE:
    case SUBMIT_SIRET_SUCCESS:
    case CHECK_SIGNATURE_SUCCESS:
    case SEND_INVITATION_SUCCESS:
    case GET_PRODUCT_PRICE_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case CREATE_SUBSCRIPTION_PREPARE:
      return {
        ...state,
        create: {
          ...state.create,
        },
        prepare: {
          ...state.prepare,
          ...action.data,

        },
      }
    // TODO S'occuper du success
    // case VALIDATE_AFFILIATION_SUCCESS:
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          ids: uniq([...state.list.ids, action.data.uuid]),
        },
        data: { ...state.data, [action.data.uuid]: action.data },
        toProceedId: action.data.uuid,
      }

    case GET_PRODUCT_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        personalisedProductPrice: { ...state.personalisedProductPrice, [action.data.productCode]: action.data.amount },
      }

    case ATTACH_CURRENT_USER_TO_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        currentPersonToVerifyUuid: action.data.uuid,
      }
    case RESET:
      return initialState

    case RESET_KYC_STATUS:
      return {
        ...state,
        data: { ...state.data, [action.data.uuid]: { ...action.data, kycsStatus: null } },
      }

    case SET_CURRENT_PERSON_TO_VERIFY:
      return {
        ...state,
        currentPersonToVerifyUuid: action.data.uuid,
      }
    case GET_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatureLink: action.data,
        loading: false,
      }
    case RESET_SIGNATURE_LINK:
      return {
        ...state,
        signatureLink: '',
        loading: false,
      }
    case SOFT_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        data: { ...state.data, [action.data.uuid]: { ...state.data[action.data.uuid], status: SubscriptionStatusDto.CANCELED } },
      }

    default:
      return state
  }
}
