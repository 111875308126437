import { RoutesInterface } from './Routes'

export class PaymentsRoutes {
  public static index: RoutesInterface = {
    path: '/payments',
    name: 'app:menu:submenu:payments:pay',
    available: true,
  }

  public static amount: RoutesInterface = {
    path: PaymentsRoutes.index.path + '/amount',
    name: 'app:pages:payments:transfer:pay:title',
    available: true,
  }

  public static bankTransfer: RoutesInterface = {
    path: PaymentsRoutes.index.path + '/transfer',
    name: 'app:pages:payments:transfer:home:title',
    available: true,
  }

  public static summary: RoutesInterface = {
    path: PaymentsRoutes.index.path + '/summary',
    name: 'app:pages:payments:transfer:summary:title',
    available: true,
  }

  public static account: RoutesInterface = {
    path: PaymentsRoutes.bankTransfer.path + '/account',
    name: 'app:pages:payments:transfer:source:title',
    available: true,
  }

  public static beneficiary: RoutesInterface = {
    path: PaymentsRoutes.bankTransfer.path + '/beneficiary',
    name: 'app:pages:payments:transfer:beneficiary:title',
    available: true,
  }

  public static result: RoutesInterface = {
    path: PaymentsRoutes.bankTransfer.path + '/result',
    name: 'app:pages:payments:transfer:home:title',
    available: true,
  }

  public static recurring: RoutesInterface = {
    path: PaymentsRoutes.bankTransfer.path + '/recurring',
    name: 'app:pages:payments:transfer:summary:reccuring',
    available: true,
  }

  public static scheduled: RoutesInterface = {
    path: PaymentsRoutes.index.path + '/scheduled',
    name: 'app:pages:payments:transfer:scheduled:title',
    available: true,
  }

  public static cashIn: RoutesInterface = {
    path: PaymentsRoutes.index.path + '/cashIn',
    name: 'cashIn',
    available: true,
  }

  public static noelsePay: RoutesInterface = {
    path: PaymentsRoutes.bankTransfer.path + '/noelse',
    name: 'app:pages:debitCard:services:payWithNoelsePay',
    available: false,
  }

  public static standardPay: RoutesInterface = {
    path: PaymentsRoutes.bankTransfer.path + '/standard',
    name: 'app:pages:payments:pay:standard:title',
    available: false,
  }

  public static securedPay: RoutesInterface = {
    path: PaymentsRoutes.bankTransfer.path + '/securedPay',
    name: 'app:pages:payments:pay:secured:title',
    available: false,
  }

  public static contact: RoutesInterface = {
    path: PaymentsRoutes.index.path + '/contact',
    name: 'app:pages:payments:transfer:beneficiary:title',
    available: true,
  }

  public static contactHome: RoutesInterface = {
    path: PaymentsRoutes.index.path + '/contacts',
    name: 'app:pages:payments:contacts:menu',
    available: true,
  }

  public static contactCreate: RoutesInterface = {
    path: PaymentsRoutes.contactHome.path + '/create',
    name: 'app:pages:payments:contact:create:title',
    available: true,
  }

  public static contactAction: RoutesInterface = {
    path: PaymentsRoutes.contactHome.path + '/action/new',
    name: 'app:pages:payments:contact:create:title',
    available: true,
  }

  public static contactUpdate: RoutesInterface = {
    path: PaymentsRoutes.contactHome.path + '/update/:id',
    name: 'app:pages:payments:contact:create:form:channel:edit',
    available: true,
  }

  public static target: RoutesInterface = {
    path: PaymentsRoutes.index.path + '/target',
    name: 'app:pages:payments:transfer:target:title',
    available: true,
  }

  public static instant: RoutesInterface = {
    path: PaymentsRoutes.index.path + '/instant',
    name: 'app:pages:payments:pay:transfer:instant:title',
    available: false,
  }

  public static nextTransfer: RoutesInterface = {
    path: PaymentsRoutes.bankTransfer.path + '/next',
    name: 'app:pages:payments:transfer:next:menu',
    available: true,
  }

  public static transferUpdate: RoutesInterface = {
    path: PaymentsRoutes.nextTransfer.path + '/edit/:id',
    name: 'app:pages:payments:transfer:summary:details',
    available: true,
  }
}
