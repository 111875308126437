import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Content, Header } from 'rsuite'
import { SelectLang } from 'UI/Components/Select/SelectLang'

import { Typography2 } from '@neo-commons/components'

import { FooterDefault } from '@neo-web/componentsOld'
import { useResponsive } from '@neo-web/hooks'

import logo from '../../Resources/Images/site/dark-logo.svg'
import maintenanceIcon from '../../Resources/Images/site/icon-maintenance.svg'
import { version } from '../../../../package.json'

import './Maintenance.scss'

export const Maintenance: React.FC = () => {
  const { t } = useTranslation()
  const isTabletOrMobile = useResponsive()
  return (
    <div className='show-container Maintenance'>
      <Container>
        <div className='Maintenance_selectLang'>
          <SelectLang />
        </div>
        <Header className='Maintenance_header'>
          <img
            src={logo}
            alt='Logo'
          />
          <br />
          <span className='Maintenance_header-version'>
            {version}
          </span>
        </Header>
        <Content>
          <main className='Maintenance_container classic-box'>
            <img
              src={maintenanceIcon}
              alt='icon-maintenance'
              height={120}
            />
            <Typography2 typeface='h3'>
              {t('app:pages:maintenance:somethingNew')}
            </Typography2>
            <Typography2 typeface='body1'>
              {t('app:pages:maintenance:backSoon')}
            </Typography2>
          </main>
        </Content>
        {!isTabletOrMobile &&
          <FooterDefault />}
      </Container>
    </div>
  )
}
