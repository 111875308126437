import { useEffect, useRef, useState } from 'react'

import { ValidatorFunction, ValidatorResponseType, Validators } from '@neo-commons/libraries'

/*
 * Handle input validation.
 *
 * @param value, value on which perform validation.
 * @param validators, array of validator fns to apply on validation.
 * @returns [
 *   validatorStatus, current status of validation.
 * ]
 */
export const useValidation =
  (value: string, isRequired: boolean, validators: ValidatorFunction[]): [ValidatorResponseType] => {
    const isMounted = useRef(false)
    const [validatorStatus, setValidatorStatus] = useState<ValidatorResponseType>({ isValid: false })

    useEffect(() => {
      isMounted.current = true
      return () => {
        isMounted.current = false
      }
    }, [])

    const validate = async (value: string) => {
      const validatorList = validators || []

      let validatorStatus: ValidatorResponseType = {
        isValid: true,
      }

      if (isRequired) {
        validatorList.unshift(Validators.isNotEmpty)
      }
      for (const validator of validatorList) {
        validatorStatus = await validator(value)
        if (!validatorStatus.isValid) {
          break
        }
      }

      if (!isMounted.current) return null
      return setValidatorStatus(validatorStatus)
    }

    useEffect(() => {
      validate(value ?? '')
    }, [value])

    return [validatorStatus]
  }
