import IBAN from 'iban'

/* eslint-disable tsdoc/syntax */
/**
   * Generic methods use in NoElse for format value.
   *
   */
export class Formatters {
  /**
   * Return all number of the string
   *
   * @param {string} value - String to convert
   * @returns {string} return the string with only number.
   * @example
   * const onlyNumbers = pickNumbers(cardNumber)
   *
   */
  public static pickNumbers = (value: string) => {
    if (!value) {
      return ''
    }
    const onlyNumbers = value.replace(/[^0-9]/g, '')

    if (onlyNumbers.length === 0) {
      return ''
    }
    return onlyNumbers
  }

  /**
   * Return float number of the string
   *
   * @param {string} value - String to convert
   * @returns {string} return the string with only float number.
   * @example
   * const onlyFloats = pickFloats(amount)
   *
   */
  public static pickFloats = (value: string) => {
    // eslint-disable-next-line no-useless-escape
    const moneyRegex = RegExp('^[0-9]+((\\.|,)[0-9]{0,2})?$')
    if (!value) {
      return ''
    }
    if (moneyRegex.test(value?.toString() ?? '')) {
      return value.toString()
    }

    const decimalOverCounter = value.split(',')[1].length - 2
    if (decimalOverCounter > 0) {
      return value.substring(0, value.length - decimalOverCounter)
    }
    return value
  }

  /**
   * Format the credit card number
   *
   * @param {string} cardNumber - String to convert
   * @returns {string} return the string of card number convert.
   * @example
   * <Input format={Formatters.formatCreditCardNumbers} />
   *
   */
  public static formatCreditCardNumbers = (cardNumber: string) => {
    const onlyNumbers = Formatters.pickNumbers(cardNumber)

    if (onlyNumbers.length === 0) {
      return ''
    }

    const splitted = onlyNumbers.match(/[0-9]{1,4}/g)
    return splitted?.join(' ') ?? cardNumber
  }

  /**
   * Format the credit card date
   *
   * @param {string} cardNumber - String to convert
   * @param {string} reverse - True if need reverse year and month.
   * @returns {string} return the string of card date convert.
   * @example
   * <Input format={Formatters.formatCreditCardDate} />
   *
   */
  public static formatCreditCardDate = (cardDate: string, reverse?: boolean) => {
    const onlyNumbers = Formatters.pickNumbers(cardDate)

    if (onlyNumbers.length === 0) {
      return ''
    }

    const splitted = onlyNumbers.match(/[0-9]{1,2}/g)
    const formattedDate = !reverse ? splitted?.join('/') : splitted?.reverse()?.join('/')
    return formattedDate ?? cardDate
  }

  /**
   * Format the date with "DD/MM/YYYY" format
   *
   * @param {string} date - String to convert
   * @returns {string} return the string of date convert.
   * @example
   * <Input format={Formatters.formatDate} />
   *
   */
  public static formatDate = (date: string): string => {
    const onlyNumbers = Formatters.pickNumbers(date)

    if (onlyNumbers.length === 0) {
      return ''
    }

    const dayMonth = onlyNumbers.match(/[0-9]{1,2}/g)?.slice(0, 2)
    const year = onlyNumbers.match(/[0-9]{1,4}/g)?.slice(1)
    const formattedDate = !year?.length ? dayMonth?.join('/') : dayMonth?.join('/') + '/' + year[0]
    return formattedDate ?? date
  }

  /**
   * Format the string into money format
   *
   * @param {string} amount - String to convert
   * @returns {string} return the string of money convert.
   * @example
   * <Input format={Formatters.formatMoney} />
   *
   */
  public static formatMoney = (amount: string): string => {
    /*
     * Force amount to be with dot and extract decimals
     */
    const withDotInsteadOfComma = amount.replace(',', '.')
    const hasDecimals = withDotInsteadOfComma.match(/\./)
    const decimalPrecision = hasDecimals ? withDotInsteadOfComma.split('.')[1].length : 0

    /*
     * Verify string match an valid number
     */
    const parsedNumber = Math.abs(parseFloat(withDotInsteadOfComma))
    if (Number.isNaN(parsedNumber)) { return '' }

    /*
     * If there is a decimal separator but no decimals
     * keep it
     */
    if (hasDecimals && decimalPrecision === 0) return withDotInsteadOfComma

    /*
     * Force decimals to not exceed 2 and disable the rounding
     * of the 3rd decimal (hide in the input field)
     */
    let amountFormatMoney = parsedNumber.toString().slice(0, (parsedNumber.toString().indexOf('.')) + 3)
    const formatValidated = withDotInsteadOfComma.match(/^\d*(\.\d{0,2})?$/)
    if (formatValidated) {
      amountFormatMoney = parsedNumber.toFixed(Math.min(decimalPrecision, 2))
    }
    return amountFormatMoney
  }

  /**
   * Format the string into phone format
   *
   * @param {string} phone - String to convert
   * @returns {string} return the string of phone convert.
   * @example
   * <Input format={Formatters.formatPhone} />
   *
   */
  public static formatPhone = (phone: string): string => {
    const onlyNumbers = Formatters.pickNumbers(phone)

    if (onlyNumbers.length === 0) {
      return ''
    }

    const splitted = onlyNumbers.match(/[0-9]{1,2}/g)
    return splitted?.join(' ') ?? phone
  }

  /**
   * Format the string into phone format
   *
   * @param {string} value - iban to format (e.g. FR76123423)
   * @param {boolean} collapseIban - collapse the iban (e.g FR76 ... 957)
   * @returns {string} return the formatted iban. (e.g. FR76 1234 23)
   * @example
   * <Input format={Formatters.formatIban} />
   *
   */
  public static formatIban = (value: string, collapseIban = false): string => {
    let iban = ''

    if (value) {
      iban = IBAN.printFormat(value)

      if (collapseIban) {
        iban = `${iban.slice(0, 9)} ... ${iban.slice(iban.length - 3)}`
      }
    }
    return iban
  }
}
