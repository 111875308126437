import React, { FunctionComponent, ReactNode } from 'react'
import { View } from 'react-native'

import { Colors } from '../../../styles'
import { Icon, IconTypes, Typography2 } from '..'

import styles from './styles'

export interface ProfileCardProps {
  title: string,
  subTitle?: string | ReactNode,
  children?: ReactNode,
  editable?: boolean
  onPress?: () => void
}

export const ProfileCard: FunctionComponent<ProfileCardProps> =
  ({
    title,
    subTitle,
    children,
    editable = false,
    onPress,
  }) => {
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <View>
            <Typography2 typeface='h5'>{title}</Typography2>
            <Typography2 typeface='body2'>{subTitle}</Typography2>
          </View>
          {editable &&
            (
              <div style={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer' }}>
                <Icon name='edit-3' type={IconTypes.FEATHER} size={20} color={Colors.primary} onPress={() => onPress && onPress()} />
              </div>
            )}
        </View>
        <View style={styles.content}>
          {children}
        </View>
      </View>
    )
  }
