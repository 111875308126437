import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  ScaChannelCard,
  DeviceMethod,
  Typography2,
} from '@neo-commons/components'

import { ScaAuthenticationErrorTypeDto } from '@afone/neo-core-client/dist/models'

import { StateTypes } from '../../../../../Store'
import { ScaActions } from '../../../../../Store/Sca'

import './ScaChannel.scss'

export const ScaChannel: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const scaState = useSelector((state: StateTypes) => state.sca?.data)
  const signInState = useSelector((state: StateTypes) => state.signIn)
  const hasNoDevice = scaState?.steps[scaState.prepare.scaStep]?.methods
    .filter((method) => method.type === ScaAuthenticationErrorTypeDto.WALLET).length === 0 ?? false

  const prepareScaDevice = async (selectedMethod: DeviceMethod) => {
    try {
      if (selectedMethod.isPreview) {
        await dispatch(ScaActions.validatePreviewMethod(selectedMethod))
      } else {
        await dispatch(ScaActions.requestDeviceMethod(selectedMethod.type))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const methods : DeviceMethod[] = scaState?.steps[scaState.prepare.scaStep]?.methods

  if (hasNoDevice) {
    methods.push({
      type: ScaAuthenticationErrorTypeDto.WALLET,
      description: t('app:pages:auth:scaChooseTypeModal:noDeviceDescription'),
      isPreview: true,
    })
  }

  return (
    <div className='ScaChannel_container'>
      <div className='ScaChannel_title'>
        <Typography2 typeface='h4'>
          {signInState?.currentDeviceUuid !== null
            ? t('app:pages:auth:scaChooseTypeModal:titleAction')
            : t('app:pages:auth:scaChooseTypeModal:title')}
        </Typography2>
      </div>

      <div className='ScaChannel_text'>
        <Typography2 typeface='body1'>
          {t('app:pages:auth:scaChooseTypeModal:content')}
        </Typography2>
      </div>

      <div className='ScaChannel_item'>
        <ScaChannelCard
          methods={methods}
          onItemPress={prepareScaDevice}
        />
      </div>
    </div>
  )
}
