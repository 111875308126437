import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { JustifyContent, PageContent } from '@neo-web/componentsOld'

interface PedagogyScreenProps {
  buttons: ReactElement,
}

export const Screen1: React.FC<PedagogyScreenProps> = (props) => {
  const { t } = useTranslation()

  return (
    <PageContent
      justifyContent={JustifyContent.BETWEEN}
      title={t('neo-commons:pages:services:pos:return:anomalyPedagogy:screen1:title')}
      titleInBody
      description={t('neo-commons:pages:services:pos:return:anomalyPedagogy:screen1:description')}
      footer={props.buttons}
    />
  )
}
