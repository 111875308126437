import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Redirect, Route, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { store } from 'Store'
import { Routes } from 'Routes/Routes'

import { transactionRefundPolicy, TransactionRefundStep } from '@neo-commons/policies/src/Transaction'
import { useWizardPolicy } from '@neo-commons/hooks'
import { PrepareRefund, TransactionActions, TransactionSelectors } from '@neo-commons/store'

import { TransactionDto } from '@afone/neo-core-client/dist/models'

import { PageDetails } from '@neo-web/componentsOld'

import {
  Amount,
  Summary,
  Success,
  Failure,
} from './index'

export const TransactionRefund: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const preparedRefund: PrepareRefund = useSelector(TransactionSelectors.prepareRefund)

  const { currentStep, steps: wizardSteps, goBack, context } = useWizardPolicy(store, transactionRefundPolicy, {
    [TransactionRefundStep.Amount]: Amount,
    [TransactionRefundStep.Summary]: Summary,
    [TransactionRefundStep.Success]: Success,
    [TransactionRefundStep.Failure]: Failure,
  }, {
    onDone: () => null,
  })

  const goBackToTransactionDetails = () => {
    if (preparedRefund?.transaction) {
      history.push(generatePath(
        Routes.Synthesis.transactionDetails.path,
        { id: (preparedRefund?.transaction as TransactionDto).id }
      ))
    } else {
      history.push(Routes.Synthesis.transactions.path)
    }
  }

  const previousStep = () => {
    if (context?.stack && context?.stack?.length > 0) goBack()
    else goBackToTransactionDetails()
  }

  const closeTunnel = () => {
    (async () => {
      await dispatch(TransactionActions.refundReset())
      goBackToTransactionDetails()
    })()
  }

  return (
    <PageDetails
      component={
        <>
          {currentStep?.path && (
            <Redirect to={{ pathname: currentStep?.path }} />
          )}
          {wizardSteps.map((value) =>
            <Route
              key={value.navigationKey}
              exact
              path={value.path}
              component={value.component}
            />
          )}
        </>
      }
      onBack={() => previousStep()}
      onClose={() => closeTunnel()}
      title={currentStep?.meta?.headerTitle
        ? t(currentStep.meta.headerTitle)
        : t('app:pages:synthesis:transaction:refund:title')}
    />
  )
}
