import React, { FunctionComponent, useMemo } from 'react'
import { ColorValue, Switch, SwitchProps, View, ViewStyle, Platform } from 'react-native'

import { Colors } from '@neo-commons/styles'

import { Card, CardSkin } from '../Card/Card'
import { Typography } from '../Typography/Typography'
import { useStylesheet } from '../utils'

import styles from './styles'

interface ToggleCardCustomization {
  cardSkin?: CardSkin,
  titleColor?: string,
  subtitleColor?: string,
  thumbColor?: string,
  trackColor?: string,
}

export interface ToggleCardProps extends SwitchProps {
  title?: string,
  enabledCustomization?: ToggleCardCustomization,
  disabledCustomization?: ToggleCardCustomization,
  subtitles?: string[],
  detail?: string,
  skin?: ToggleCardSkin,
  containerStyle?: ViewStyle,
  testID?: string,
}

export enum ToggleCardSkin {
  GOLD_BORDER = 'GOLD_BORDER',
  GRAY_BORDER = 'GRAY_BORDER',
  DEFAULT = 'DEFAULT',
}

interface ToggleCardSkinType {
  disabledCardSkin: CardSkin,
  enabledCardSkin: CardSkin,
  enabledthumbColor: ColorValue,
  disabledthumbColor: ColorValue,
  enabledTrack: ColorValue,
  disabledTrack: ColorValue,
}

const getToggleCardSkin = (
  skin?: ToggleCardSkin,
  disabled?: boolean,
  enabledCustomization?: ToggleCardCustomization,
  disabledCustomization?: ToggleCardCustomization
): ToggleCardSkinType => {
  if (disabled) {
    return {
      disabledCardSkin: disabledCustomization?.cardSkin ?? CardSkin.DISABLED,
      enabledCardSkin: enabledCustomization?.cardSkin ?? CardSkin.DISABLED,
      disabledthumbColor: disabledCustomization?.thumbColor ?? Colors.gray,
      enabledthumbColor: enabledCustomization?.thumbColor ?? Colors.gray,
      enabledTrack: enabledCustomization?.trackColor ?? Colors.darkGray,
      disabledTrack: disabledCustomization?.trackColor ?? Colors.darkGray,
    }
  }

  switch (skin) {
    case ToggleCardSkin.GOLD_BORDER:
      return {
        disabledCardSkin: disabledCustomization?.cardSkin ?? CardSkin.SECONDARY_BORDERED,
        enabledCardSkin: enabledCustomization?.cardSkin ?? CardSkin.PRIMARY,
        disabledthumbColor: disabledCustomization?.thumbColor ?? Colors.darkGray,
        enabledthumbColor: enabledCustomization?.thumbColor ?? Colors.white,
        disabledTrack: disabledCustomization?.trackColor ?? Colors.gray,
        enabledTrack: enabledCustomization?.trackColor ?? Colors.primaryGhost,
      }

    case ToggleCardSkin.GRAY_BORDER:
      return {
        disabledCardSkin: disabledCustomization?.cardSkin ?? CardSkin.PRIMARY_DARKER_BORDERED,
        enabledCardSkin: enabledCustomization?.cardSkin ?? CardSkin.PRIMARY,
        disabledthumbColor: disabledCustomization?.thumbColor ?? Colors.darkGray,
        enabledthumbColor: enabledCustomization?.thumbColor ?? Colors.white,
        disabledTrack: disabledCustomization?.trackColor ?? Colors.gray,
        enabledTrack: enabledCustomization?.trackColor ?? Colors.primaryGhost,
      }

    default:
      return {
        disabledCardSkin: disabledCustomization?.cardSkin ?? CardSkin.DEFAULT,
        enabledCardSkin: enabledCustomization?.cardSkin ?? CardSkin.DEFAULT,
        disabledthumbColor: disabledCustomization?.thumbColor ?? Platform.select({
          ios: Colors.white,
          default: Colors.darkGray,
        }),
        enabledthumbColor: enabledCustomization?.thumbColor ?? Platform.select({
          ios: Colors.white,
          default: Colors.primary,
        }),
        disabledTrack: disabledCustomization?.trackColor ?? Colors.gray,
        enabledTrack: enabledCustomization?.trackColor ?? Platform.select({
          ios: Colors.primary,
          default: Colors.primaryGhost,
        }),
      }
  }
}

export const ToggleCard: FunctionComponent<ToggleCardProps> = ({
  skin = ToggleCardSkin.DEFAULT,
  value,
  disabled,
  enabledCustomization,
  disabledCustomization,
  subtitles,
  detail,
  onValueChange,
  title,
  containerStyle,
  testID,
}) => {
  const skinToggleCard: ToggleCardSkinType = useMemo(() =>
    getToggleCardSkin(skin, disabled, enabledCustomization, disabledCustomization),
  [skin, disabled, enabledCustomization, disabledCustomization])
  const s = useStylesheet(styles,
    {
      skin,
      props: {
        disabled, enabledCustomization, disabledCustomization, value,
      },
    })

  return (
    <Card
      testID={testID}
      skin={value ? skinToggleCard.enabledCardSkin : skinToggleCard.disabledCardSkin}
      containerStyle={containerStyle}
      withShadow
      onPress={() => { !disabled && onValueChange && onValueChange(!value) }}
    >
      <View style={s.wrapContent}>
        <View style={s.leftChildArea}>
          <Typography
            typeface='input'
            style={[
              s.title,
            ]}
          >
            {title}
          </Typography>
          {subtitles?.map((subtitle, i) => (
            <Typography
              key={i}
              typeface='body1'
              style={[
                s.subtitle,
              ]}
            >
              {subtitle ?? ''}
            </Typography>
          ))}
        </View>
        <View style={s.switchArea} pointerEvents='none'>
          {detail &&
            <Typography
              typeface='bold'
              style={[
                s.title,
              ]}
            >
              {detail}
            </Typography>}
          <Switch
            value={value}
            disabled={disabled}
            onValueChange={onValueChange}
            thumbColor={value ? skinToggleCard.enabledthumbColor : skinToggleCard.disabledthumbColor}
            trackColor={{
              true: skinToggleCard.enabledTrack,
              false: skinToggleCard.disabledTrack,
            }}
            style={{ marginLeft: 15 }}
            /*
             * adjustements because of react-native-web syntax https://github.com/necolas/react-native-web/blob/aa8593ba9704d42f26c87303ac5a584856cbadaa/packages/docs/src/components/Switch/Switch.stories.mdx
             */
            // eslint-disable-next-line
	          // @ts-ignore
            activeThumbColor={skinToggleCard.enabledthumbColor}
          />
        </View>
      </View>
    </Card>
  )
}
