import { Dispatch, ThunkActionCreator } from '@neo-commons/store'
import { IconTypes } from '@neo-commons/components'
import axios from 'axios'
import { map, isFunction, reduce } from 'lodash'
import { assign, interpret, Interpreter, createMachine, send, StateMachine } from 'xstate'

export enum StepperStepStatus {
  FAIL='FAIL',
  VALID='VALID',
  CURRENT='CURRENT',
}

/*
 * Binds Statecharts Machine Promise invoke to an Thunk Action Creator
 * Property 'data' of invoked Statecharts event is pass as parameter of the Thunk action creator
 * For instance, send({ type: 'NEXT', data: { foo, bar }}) will invoke dispatch(actionCreator({ foo, bar }))
 */
export const invokeActionCreator =
  <Func extends ThunkActionCreator>(actionCreator: Func): ((...args: Parameters<Func>) => ReturnType<Dispatch>) => {
    const fn = {
      [actionCreator.name]: (
        context: any,
        event: { type: string, data: Parameters<typeof actionCreator> }
      ): ReturnType<Dispatch> => {
        return (context.store.dispatch as Dispatch)(actionCreator(event.data))
      },
    }
    return fn[actionCreator.name] // hack to preserve original action creator fn name in inspector
  }

/*
 * Type definitions
 */

export type WizardStepperStepKey = number | string

export interface WizardStepperStep {
  title: () => string,
  icon: { name: string, size: number, type: IconTypes }
}

export type WizardStepperSteps = { [key in WizardStepperStepKey]: WizardStepperStep }

export type WizardStepKey = number | string

export interface WizardStep {
  nextStep?: (() => WizardStepKey) | WizardStepKey | 'final',
  editableSteps?: WizardStepKey[],
  history?: {
    preventStack?: boolean,
    resetStack?: boolean,
  },
  machineId?: string,
  stateId?: string,
  meta?: any,
  path?: string,
  skipIf?: (context: any, event: any, meta: any) => boolean,
  skipTo?: WizardStepKey | 'final',
  canAccessStep?: (context: any, event: any, meta: any) => boolean,
  bypassIf?: (context: any, event: any, meta: any) => boolean,
  bypassTo?: WizardStepKey,
  fulfill?: ReturnType<typeof invokeActionCreator>,
  stepper?: {
    step: WizardStepperStep | 'final' | string,
    status?: StepperStepStatus,
  }
  onErrorRedirectStep?: (() => WizardStepKey) | WizardStepKey | 'final',
  additionalEvents?: any,
  childrenSteps?: WizardSteps,
  initialStep?: string,
}

export type WizardSteps = { [key in WizardStepKey]: WizardStep }

// TODO: define Interpreter typings
export type WizardPolicy = {
  steps: WizardSteps,
  stepperSteps?: WizardStepperSteps,
  machine: StateMachine<any, any, any>,
  service: Interpreter<any>,
  basePath: string,
  machineId: string,
  next: (data?: any) => null | WizardNextStepBinders,
  edit: (stepKey?: string) => void,
  goBack: () => void,
}

export type WizardNextStepBinders = {
  onDone: (fn: () => any) => WizardNextStepBinders,
  onError: (fn: () => any) => WizardNextStepBinders
}
type WizardNextStepFn<T extends Array<any>> = (...args: T) => WizardNextStepBinders
export interface StepComponentProps<T extends WizardStep> {
  nextStep: T['fulfill'] extends (...args: any) => ReturnType<Dispatch> ? WizardNextStepFn<Parameters<T['fulfill']>> : (...args: any) => any
  editStep?: (stepKey: string) => void
}

export const createStepTarget = (machineId, stateId, step) =>
  `#${machineId}.${
    isFunction(step)
      ? `${stateId}.${(step as () => number | string)()}`
      : step === 'final'
        ? 'success'
        : `${stateId}.${step}`
  }`

export const listAllSteps = (steps: WizardSteps) => {
  let list = { ...steps }
  map(steps, step => { list = { ...list, ...listAllSteps(step.childrenSteps as WizardSteps) } })
  return list
}

const findPossiblePreviousSteps = (currentStep: WizardStepKey, steps: WizardSteps): WizardStepKey[] => {
  let allPreviousSteps: WizardStepKey[] = []
  const allSteps = listAllSteps(steps)

  for (const stepKey in allSteps) {
    const step = allSteps[stepKey]
    if (step.nextStep === currentStep) {
      if (step?.history?.preventStack) {
        allPreviousSteps = allPreviousSteps.concat(findPossiblePreviousSteps(stepKey, allSteps))
      } else {
        allPreviousSteps.push(stepKey)

        if (step.bypassIf) {
          allPreviousSteps = allPreviousSteps.concat(findPossiblePreviousSteps(stepKey, steps))
        }
      }
    }
  }

  return allPreviousSteps
}

/*
 * Factory functions to build an Statecharts machine representing a Wizard
 * & various utility methods to interact with it
 */

const createWizardPolicyMachineState = ({
  steps,
  key,
  nextStep,
  editableSteps,
  machineId,
  stateId,
  meta = {},
  path,
  skipIf,
  bypassIf,
  skipTo,
  bypassTo,
  fulfill,
  stepper,
  onErrorRedirectStep,
  additionalEvents,
  initialStep,
  childrenSteps,
}: WizardStep & { steps: WizardSteps, key: WizardStepKey }) => {
  const possiblePreviousSteps = findPossiblePreviousSteps(key, steps)
  const nextStepTarget = createStepTarget(machineId, stateId, nextStep)
  const onErrorRedirectStepTarget = createStepTarget(machineId, stateId, onErrorRedirectStep)

  const possiblePreviousStepsTargets = possiblePreviousSteps.map((stepKey) => {
    return {
      target: createStepTarget(machineId, stateId, stepKey),
      actions: assign({
        fromHistory: true,
        stack: (context: any) => context.stack?.slice(0, context.stack.length - 1),
      }),
      cond: (context: any) => context.stack && stepKey === context.stack[context.stack.length - 1],
    }
  })

  const goBackStack = (context: any, event: any) => {
    return {
      ...context,
      lastEvent: event,
    }
  }

  const updateStack = (context: any, event: any) => {
    let newStack: WizardStepKey[]
    if (steps[key]?.history?.preventStack) {
      newStack = context.stack
    } else {
      newStack = context.stack ? context.stack.concat([key]) : [key]
    }

    return {
      fromHistory: false,
      stack: newStack,
      data: { ...(context.data ?? {}), ...event.data },
      lastEvent: event,
    }
  }

  const onErrorUpdateStack = (context: any, event: any) => {
    const indexInStack = context.stack.findIndex(step => step === onErrorRedirectStep)
    if (indexInStack > -1) {
      const newStack = context.stack.splice(0, indexInStack)
      return {
        fromHistory: true,
        stack: newStack,
        lastEvent: event,
      }
    }

    return updateStack(context, event)
  }

  const onEditUpdateStack = (context: any, event: any) => {
    const indexInStack = context.stack.findIndex(step => step === event.stepKey)
    const newStack = context.stack.splice(0, indexInStack)
    return {
      fromHistory: true,
      stack: newStack,
      lastEvent: event,
    }
  }

  const resetStackIfNeeded = (context: any) => {
    return {
      stack: steps[key]?.history?.resetStack ? [] : context?.stack,
    }
  }

  const shouldSkipStep = (context: any, event: any, meta: any) => {
    return !context?.fromHistory && skipIf && skipIf(context, event, meta)
  }

  const shouldBypassStep = (context: any, event: any, meta: any) => {
    return !context?.fromHistory && bypassIf && bypassIf(context, event, meta)
  }

  return {
    [key]: {
      meta: {
        ...meta,
        ...(path ? { path } : {}),
        stepper,
      },

      /*
       * Reset stack if needed
       */
      entry: assign(resetStackIfNeeded),

      /*
       * If no fulfill fn is provided, on NEXT it goes directly to the next step
       * otherwise it executes provided fulfill fn then redirect to next step on success
       */
      initial: 'main',
      states: {
        main: {
          initial: initialStep,
          /*
           * Automatically skip step and redirect to provided step if provided condition is true
           */
          always: [
            ...skipIf ? [{
              target: skipTo
                ? skipTo === 'final' ? 'success' : `#${machineId}.${stateId}.${skipTo}`
                : nextStepTarget,
              cond: shouldSkipStep,
              actions: assign(updateStack),
            }] : [],
            ...bypassIf ? [{
              target: bypassTo ? `#${machineId}.${stateId}.${bypassTo}` : nextStepTarget,
              cond: shouldBypassStep,
              actions: assign((context: any) => {
                return {
                  ...context,
                  lastEvent: {
                    type: 'BYPASS',
                  },
                }
              }),
            }] : [],
          ],
          on: {
            GO_BACK: {
              target: 'history',
              cond: (context: any) => context.stack && context.stack.length > 0,
              actions: assign(goBackStack),
            },
            ...(nextStep ? {
              NEXT: {
                target: fulfill ? 'loading' : nextStepTarget,
                actions: !fulfill ? assign(updateStack) : undefined,
              },
            } : {}),
            ...(editableSteps ? reduce(
              editableSteps,
              (acc, stepKey) => ({
                ...acc,
                [`EDIT_${stepKey}`]: {
                  target: createStepTarget(machineId, stateId, stepKey),
                  actions: assign(onEditUpdateStack),
                },
              }), {}) : {}),
            ...additionalEvents,
          },
          states: {
            ...reduce(
              childrenSteps,
              (acc, step, stepKey) => ({
                ...acc,
                ...createWizardPolicyMachineState({
                  steps,
                  machineId,
                  stateId: `${stateId}.${key}.main`,
                  key: stepKey,
                  ...step,
                  path: path?.slice(path.length - 1) === '/' ? path + step.path : path + '/' + step.path,
                }),
              }), {}),
          },
        },
        ...(fulfill ? {
          loading: {
            invoke: {
              id: fulfill.name,
              src: (context, event) => {
                return (fulfillCallback) => {
                  (async () => {
                    try {
                      await fulfill(context, event)
                      fulfillCallback('DONE')
                    } catch (e) {
                      if (e instanceof axios.Cancel || context?.store?.getState().sca?.cancelled) {
                        return fulfillCallback('CANCEL')
                      }
                      fulfillCallback('ERROR')
                    }
                  })()
                }
              },
            },
            on: {
              DONE: {
                target: 'success',
              },
              ERROR: {
                target: 'failure',
              },
              CANCEL: {
                target: 'main',
              },
            },
          },
          success: {
            entry: send({ type: 'REDIRECT_NEXT' }),
            on: {
              '*': {
                target: nextStepTarget,
                actions: assign(updateStack),
              },
            },
          },
          failure: {
            entry: send({ type: 'REDIRECT_ERROR' }),
            on: {
              '*': {
                ...(onErrorRedirectStep ? {
                  target: onErrorRedirectStepTarget,
                  actions: assign(onErrorUpdateStack),
                } : {
                  target: 'main',
                }),
              },
            },
          },
        } : {}),
        history: {
          always: possiblePreviousStepsTargets,
        },
      },
    },
  }
}

const createWizardPolicyMachineStates = (
  {
    machineId,
    stateId,
    steps,
    basePath,
  }: {
    machineId: string,
    stateId: string,
    steps: { [key: number]: WizardStep },
    basePath: string,
  }
) => ({
  ...reduce(
    steps,
    (acc, step, key) => ({
      ...acc,
      ...createWizardPolicyMachineState({
        steps,
        machineId,
        stateId,
        key,
        ...step,
        path: basePath?.slice(basePath.length - 1) === '/' ? basePath + step.path : basePath + '/' + step.path,
      }),
    }),
    {}
  ),
})

const createFullPath = (steps, basePath) => {
  Object.keys(steps).map(key => {
    steps[key].path = basePath?.slice(basePath.length - 1) === '/'
      ? basePath + steps[key].path : basePath + '/' + steps[key].path
    if (steps[key].childrenSteps) {
      createFullPath(steps[key].childrenSteps, steps[key].path)
    }
  })
}

export const createWizardPolicy = (
  {
    machineId,
    steps,
    initialStep,
    stepperSteps,
    basePath,
  }: {
    machineId: string,
    steps: WizardSteps,
    initialStep: WizardStepKey,
    stepperSteps?: WizardStepperSteps,
    basePath: string
  }
): WizardPolicy => {
  const machine = createMachine({
    id: machineId,
    initial: 'funnel',
    states: {
      funnel: {
        initial: initialStep,
        states: {
          ...createWizardPolicyMachineStates({
            machineId,
            stateId: 'funnel',
            steps,
            basePath,
          }),
        },
      },
      success: { type: ('final' as const) },
    },
  })
  const service = interpret(machine, { devTools: true })

  createFullPath(steps, basePath)

  const policy = {
    steps,
    stepperSteps,
    service,
    machine,
    basePath,
    machineId,
    goBack: () => {
      policy.service.send({ type: 'GO_BACK' })
    },
    edit: (stepKey: string) => {
      policy.service.send({ type: 'EDIT_' + stepKey, stepKey })
    },
    next: (data?: any) => {
      const currentStepKey = Object.keys(((policy?.service?.getSnapshot()?.value) as any)?.funnel)[0]
      const currentStep = steps[currentStepKey]

      const handlers = {
        onDone: () => null,
        onError: () => null,
      }
      const binders: WizardNextStepBinders = {
        onDone: (fn) => {
          handlers.onDone = fn
          return binders
        },
        onError: (fn) => {
          handlers.onError = fn
          return binders
        },
      }

      policy.service.send({ type: 'NEXT', data })

      const listener: Parameters<typeof policy.service.onTransition>[0] = (state) => {
        const isOnSuccessState = state.matches(`funnel.${currentStepKey}.success`)
        const isOnFailureState = state.matches(`funnel.${currentStepKey}.failure`)

        if (!isOnSuccessState && !isOnFailureState) {
          return
        }

        if (isOnSuccessState) {
          handlers.onDone()
        }
        if (isOnFailureState) {
          handlers.onError()
        }
        policy.service.off(listener)
      }
      if (currentStep.fulfill) {
        policy.service.onTransition(listener)
        return binders
      }

      return null
    },
  }

  return policy as unknown as WizardPolicy
}
