import React from 'react'

import { BusinessSubscriptionStep, BusinessSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'

type PosOfferPedagogyProps = StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.PosPedagogy]>

export const PosOfferPedagogy: React.FC<PosOfferPedagogyProps> = ({ nextStep }) => {
  console.log(nextStep)
  return (
    <div>PosOfferPedagogy</div>
  )
}
