import { BankAccount } from '@neo-commons/store'

import { AccountTypeDto } from '@afone/neo-core-client/dist/models'

import { PriceCardSkin } from '@neo-web/componentsOld'

export const useSkin = (
  bankAccount: BankAccount,
) : PriceCardSkin => {
  let skin

  switch (bankAccount?.type) {
    case AccountTypeDto.MAIN:
    case AccountTypeDto.AGGREGATED:
      skin = PriceCardSkin.DEFAULT
      break

    case AccountTypeDto.PROJECT:
    case AccountTypeDto.POS:
      skin = PriceCardSkin.SECONDARY
      break
  }
  return skin
}
