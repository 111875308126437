import { StyleSheet } from 'react-native'

import { Spacing, Colors, Commons } from '@neo-commons/styles'

import { createStylesheet } from '../utils'

import { CardSkin } from './Card'

const styles = createStylesheet(({ Skin, props }) => {
  return StyleSheet.create({
    touchableContainer: {
      backgroundColor: Colors.white,
      borderRadius: Spacing.item.radius,
      marginHorizontal: 0,
      marginBottom: 18,
      alignContent: 'center',
      borderWidth: 1,
      borderColor: Colors.transparent,
      overflow: 'hidden',
      padding: 10,
    },
    cardContainer: {
      borderWidth: 0,
      borderColor: Colors.white,

      ...Skin.select([
        CardSkin.PRIMARY_BORDERED,
      ], {
        borderColor: Colors.primary,
      }),
      ...Skin.select([
        CardSkin.PRIMARY_DARKER_BORDERED,
      ], {
        borderColor: Colors.darkGray,
      }),
      /*
      UNUSED TODO REMOVE ?
      too many Skin.select break tsc (out of memory)
      ...Skin.select([
        CardSkin.PRIMARY_GOLD_BORDERED,
      ], {
        borderColor: Colors.lightGold,
      }),
      ...Skin.select([
        CardSkin.SECONDARY_BORDERED,
      ], {
        borderColor: Colors.secondary,
      }),
      */
      ...Skin.select([
        CardSkin.SECONDARY,
      ], {
        backgroundColor: Colors.secondary,
      }),
      ...Skin.select([
        CardSkin.PRIMARY,
      ], {
        backgroundColor: Colors.primary,
      }),
      ...Skin.select([
        CardSkin.DISABLED,
      ], {
        backgroundColor: Colors.gray,
      }),
      ...Skin.select([
        CardSkin.DEFAULT,
        CardSkin.PRIMARY_BORDERED,
        // CardSkin.SECONDARY_BORDERED,
        // CardSkin.PRIMARY_GOLD_BORDERED,
        CardSkin.PRIMARY_DARKER_BORDERED,
        CardSkin.BACKGROUND_BORDERED,
      ], {
        borderWidth: 2.5,
      }),

      // Workaround because too many Skin.select break tsc (out of memory)
      ...(props.skin === CardSkin.BACKGROUND_BORDERED ? {
        borderColor: Colors.background,
      } : {}),
    },
    shadow: Commons.shadow,
  })
})

export default styles
