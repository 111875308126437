import i18next from 'i18next'
import { RibDto } from '@afone/neo-core-client/dist/models'

/**
   * Generic utils use in NoElse in rib.
   *
   */
export class RibUtils {
  /**
   * Return an RIB format in string with their translations.
   *
   * @param {RibDto} bankAccountRibInfos - send the Ribinfos
   * @returns {string} return the RIB format
   * @example
   * const message = await RibUtils.getRibMessage(ribInfos) ?? ''
   *
   */
  public static getRibMessage (bankAccountRibInfos: RibDto): string {
    const { holder, iban, bic } = bankAccountRibInfos
    return `${i18next.t('account:ibanSharingTitle')}
${holder
            ? `${i18next.t('commons:holder')} : ${holder}\n`
            : ''
        }
${iban
            ? `${i18next.t('commons:iban')} : ${iban}\n`
            : ''
        }
${bic
            ? `${i18next.t('commons:bic')} : ${bic}\n`
            : ''
        }
${i18next.t('account:ibanSharingSignature')}
    `
  }
}
