import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, useHistory } from 'react-router-dom'

import { store } from 'Store'
import { Routes } from 'Routes/Routes'

import { posReturnPolicy, POSReturnStep } from '@neo-commons/policies/src/POS/Return'
import { useWizardPolicy } from '@neo-commons/hooks'
import { BankAccountSelectors, PosActions, PosSelectors, PreparePOSReturn } from '@neo-commons/store'
import { BankAccountUtils } from '@neo-commons/libraries'

import { PageDetails } from '@neo-web/componentsOld'

import {
  Pedagogy,
  SelectPos,
  IdentifyAnomaly,
  AnomalyPedagogy,
  AddressCheck,
  AddressForm,
  Summary,
  Process,
  Success,
  Failure,
} from './index'

export const Return: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const preparedPOSReturn: PreparePOSReturn = useSelector(PosSelectors.prepareReturn)
  const selectedBankAccount = useSelector(BankAccountSelectors.selected)

  const { currentStep, steps: wizardSteps, goBack, context } = useWizardPolicy(store, posReturnPolicy, {
    [POSReturnStep.Pedagogy]: Pedagogy,
    [POSReturnStep.SelectPos]: SelectPos,
    [POSReturnStep.IdentifyAnomaly]: IdentifyAnomaly,
    [POSReturnStep.AnomalyPedagogy]: AnomalyPedagogy,
    [POSReturnStep.AddressCheck]: AddressCheck,
    [POSReturnStep.AddressForm]: AddressForm,
    [POSReturnStep.Summary]: Summary,
    [POSReturnStep.Process]: Process,
    [POSReturnStep.Success]: Success,
    [POSReturnStep.Failure]: Failure,
  }, {
    onDone: () => null,
  })

  const previousStep = () => {
    if (BankAccountUtils.isMain(selectedBankAccount)) {
      if (context?.stack && context?.stack?.length > 0) goBack()
      else history.push(Routes.Services.managePos.path)
    } else history.goBack()
  }

  const closeTunnel = () => {
    if (BankAccountUtils.isMain(selectedBankAccount)) {
      (async () => {
        try {
          await dispatch(PosActions.resetPrepareReturn())
          history.push(Routes.Services.managePos.path)
        } catch (error) {}
      })()
    } else history.goBack()
  }

  useEffect(() => {
    if (!preparedPOSReturn?.returnReason) {
      closeTunnel()
    }
  }, [])

  return (
    <PageDetails
      component={
        <>
          {currentStep?.path && (
            <Redirect
              to={{
                pathname: currentStep?.path,
              }}
            />
          )}
          {wizardSteps.map((value) =>
            <Route
              key={value.navigationKey}
              exact
              path={value.path}
              component={value.component}
            />
          )}
        </>
      }
      onBack={currentStep?.meta?.canGoBack ? previousStep : null}
      onClose={closeTunnel}
      title={currentStep?.meta?.headerTitle ? t(currentStep.meta.headerTitle) : t('neo-commons:pages:services:pos:return:summary:title')}
    />
  )
}
