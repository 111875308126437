import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'
import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'
import { PasswordKeypad } from 'UI/Components/Auth/PasswordKeypad'
import { AppConfig } from 'Config/AppConfig'

import { Colors } from '@neo-commons/styles'
import {
  DeviceRecoveryActions,
  SignInActions,
  SignInState,
  SignInTypes, SubscriptionSelectors,
} from '@neo-commons/store'
import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  Typography,
} from '@neo-commons/components'
import { usePasswordKeypad } from '@neo-commons/hooks'
import { SubscriptionUtils } from '@neo-commons/libraries'

import { KeypadFieldDto, OsTypeDto } from '@afone/neo-core-client/dist/models'

import { BrowserUtils } from '@neo-web/utils'

import './Code.scss'

interface CodeProps {
deviceUpdateNeeded: boolean;
}
export const Code: React.FC<CodeProps> = (props: CodeProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentHistory = useHistory()

  const signInState: SignInState = useSelector((state: StateTypes) => state?.signIn)
  const passwordKeypadData = usePasswordKeypad({
    phone: signInState.data?.phone,
    phoneCountryCode: signInState.data?.countryCode,
    field: KeypadFieldDto.PASSWORD,
    passwordSize: AppConfig.PASS_CODE_SIZE,
  })
  const browserInfo = BrowserUtils.getDeviceBrowserInformation()
  let deviceUpdateNeeded = props.deviceUpdateNeeded
  const mainSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)

  const deviceCreateOrUpdate = async () => {
    deviceUpdateNeeded = false

    const device: any = await dispatch(DeviceRecoveryActions.createOrUpdate({
      os: OsTypeDto.WEB,
      deviceName: browserInfo.name,
      userUuid: signInState.data.id,
      uniqueDeviceId: signInState?.uniqueDeviceId,
      browserInfo: browserInfo,
      noSuccessToast: true,
    }))

    if (signInState?.uniqueDeviceId !== device.uniqueDeviceId) {
      await dispatch(SignInActions.setCurrentDevice(
        { deviceUuid: device.uuid, uniqueDeviceId: device.uniqueDeviceId }
      ))
    }
  }

  useEffect(() => {
    if (SubscriptionUtils.isActive(mainSubscription)) {
      AnalyticsHandler.track(AnalyticsEvents.Account.bankAccountOpened)
    }
  }, [mainSubscription])

  const signIn = () => {
    (async () => {
      try {
        if (!signInState.currentDeviceUuid) {
          currentHistory.push(Routes.SignIn.index.path)
        }
        // If uniqueDeviceId is missing : we create a new device before login, so we can send the new id in the token
        if (!signInState?.uniqueDeviceId) await deviceCreateOrUpdate()

        await dispatch(SignInActions.signIn(passwordKeypadData.password.map(e => e.value)))
        AnalyticsHandler.track(AnalyticsEvents.general.userLogin)
        // If necessary : we update the device information after login to avoid SCA
        if (deviceUpdateNeeded) await deviceCreateOrUpdate()
        currentHistory.push(Routes.Origin.path)
      } catch (e) {
        passwordKeypadData.reset(e)
      }
    })()
  }

  const goToRecoverPassword = () => {
    currentHistory.push(Routes.RecoverPassword.index.path)
  }

  useEffect(() => {
    (async () => {
      try {
        if (!signInState.data.id && signInState.data.phone) {
          await dispatch(SignInActions.getUuidByPhone(signInState.data.phone))
        }
      } catch (e) {}
    })()
  }, [signInState.data.id])

  return (
    <div className='Code text-center' data-testid='Auth/Code'>
      <div className='Code_header'>
        <div className='Code_header-change-code'>
          <Button
            skin={ButtonSkin.DEFAULT_LIGHT}
            small
            title={
              <Icon
                name='change_user'
                type={IconTypes.NEOFONT}
                size={30} color={Colors.primary}
              />
            }
            onPress={() => {
              dispatch({ type: SignInTypes.USER_CHANGE })
              currentHistory.push(Routes.SignIn.index.path)
            }}
          />
        </div>
        <Typography typeface='bigTitle'>
          {t('app:pages:signIn:phone:title')}&nbsp;
          {signInState?.data?.user?.firstName && signInState.data.user.firstName}
        </Typography>
        <br />
        <Typography typeface='subtitle'>
          {t('app:pages:signIn:code:tapeCode')}
        </Typography>
      </div>
      <div className='Code_body'>
        <PasswordKeypad
          {...passwordKeypadData}
          withoutValidate
        />
      </div>
      <div className='Code_footer'>
        <Button
          title={t('app:pages:signIn:code:codeForget')}
          skin={ButtonSkin.LINK}
          onPress={() => {
            AnalyticsHandler.track(AnalyticsEvents.signIn.forgotPassword)
            goToRecoverPassword()
          }}
        />
        <br />
        <Button
          title={t('app:pages:signIn:code:connect')}
          skin={ButtonSkin.PRIMARY}
          disabled={!passwordKeypadData.isFilled}
          onPressOut={signIn}
        />
      </div>
    </div>
  )
}
