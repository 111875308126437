import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StepComponentProps } from '@neo-commons/policies'
import { TransactionRefundStep, TransactionRefundSteps } from '@neo-commons/policies/src/Transaction'
import { Button, ButtonSkin, EditItemCard, EditItemCardSkin } from '@neo-commons/components'
import { PrepareRefund, TransactionActions, TransactionSelectors } from '@neo-commons/store'
import { NumbersUtils } from '@neo-commons/libraries'

import { JustifyContent, PageContent } from '@neo-web/componentsOld'

type TransactionRefundSummaryProps = StepComponentProps<TransactionRefundSteps[TransactionRefundStep.Summary]>

export const Summary: React.FC<TransactionRefundSummaryProps> = ({ nextStep, editStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const preparedRefund: PrepareRefund = useSelector(TransactionSelectors.prepareRefund)

  const [buttonDisabled, setButtonDisabled] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        await dispatch(TransactionActions.refund({
          prepare: preparedRefund,
          xValidationOnly: 1,
        }))
        setButtonDisabled(false)
      } catch (error) {}
    })()
  }, [])

  return (
    <PageContent
      justifyContent={JustifyContent.BETWEEN}
      footer={(
        <Button
          title={t('app:global:confirm')}
          skin={ButtonSkin.PRIMARY}
          onPress={() => nextStep({
            prepare: preparedRefund,
          })}
          disabled={buttonDisabled}
        />
      )}
    >
      <EditItemCard
        label={t('app:pages:synthesis:transaction:refund:summary:cardTitle').toUpperCase()}
        skin={EditItemCardSkin.DELIVERY}
        payload={{
          title: NumbersUtils.displayPriceForHuman(preparedRefund?.amount),
          subtitles: [preparedRefund.transaction?.secondaryLabel],
        }}
        buttonLabel={t('app:global:edit')}
        onEditPress={() => editStep(TransactionRefundStep.Amount)}
      />
    </PageContent>
  )
}
