import { AnyAction, Reducer } from 'redux'
import i18next from 'i18next'
import { createSelector } from 'reselect'
import { NeobankApi } from '@neo-commons/services'
import {
  ContactChannelFormatDto,
  ContactCreateDto,
  ContactDto,
  ContactSortDto,
} from '@afone/neo-core-client/dist/models'
import { ContactUtils } from '@neo-commons/libraries'

import { Dispatch, ResourceState, ResourceStateFactory } from '../../utils/resourceState'
import { AlertType, initialResourceState, State } from '../../utils'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Contact = ContactDto

const {
  resourceActionTypes: ContactActionTypes,
  resourceReducer: ContactResourceReducer,
  resourceAction: ContactAction,
  resourceSelector: ContactResourceSelector,
} = ResourceStateFactory<Contact, 'contact'>(state => state.contact, 'contact')

const contactSelector = state => state.contact

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

export const ContactTypes = {
  ...ContactActionTypes,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

export const ContactSelectors = {
  ...ContactResourceSelector,
  defaultOne: createSelector(contactSelector, resource => resource.data[Object.keys(resource.data)[0]] ?? null),
  byUuid: (uuid: string) => createSelector(
    contactSelector,
    resource => resource.data[uuid] ?? null
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */
const errorKeyTranslate = 'errors:unknownTechnicalIssue'

const create = (contact: ContactCreateDto|any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ContactActionTypes.CREATE_CONTACT_REQUEST })

    try {
      contact = ContactUtils.formatContactForApi(contact)
      const contactCreateResponse = await NeobankApi.getInstance().contactApi.createContact(
        0,
        contact
      )

      dispatch({
        type: ContactActionTypes.CREATE_CONTACT_SUCCESS,
        data: contactCreateResponse.data,
        alertType: AlertType.SUCCESS,
        errorMessage: i18next.t('neo-commons:contact:create:success'),
      })

      return contactCreateResponse.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({
        type: ContactActionTypes.CREATE_CONTACT_FAILURE,
        errorMessage,
      })
      throw new Error(errorMessage)
    }
  }
}

const update = (contact: Contact) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ContactActionTypes.UPDATE_CONTACT_REQUEST })
    try {
      contact = ContactUtils.formatContactForApi(contact)
      const contactCreateResponse = await NeobankApi.getInstance().contactApi.updateContactByUuid(
        contact.uuid!,
        contact
      )

      dispatch({
        type: ContactActionTypes.UPDATE_CONTACT_SUCCESS,
        data: contactCreateResponse.data,
        alertType: AlertType.SUCCESS,
        errorMessage: i18next.t('neo-commons:contact:create:update'),
      })

      return contactCreateResponse.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({
        type: ContactActionTypes.UPDATE_CONTACT_FAILURE,
        errorMessage,
      })
      throw new Error(errorMessage)
    }
  }
}

const list = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: ContactActionTypes.LIST_CONTACT_REQUEST })
    try {
      const text = getState().contact.list.filter.text !== '' ? getState().contact.list.filter.text : undefined
      const search = !text ? [] : [
        { familyName: { contains: text } },
        { givenName: { contains: text } },
        {
          channels: {
            value: { startsWith: text },
            format: ContactChannelFormatDto.IBAN,
          },
        },
        {
          channels: {
            label: { startsWith: text },
            format: ContactChannelFormatDto.IBAN,
          },
        },
      ]
      const payload = await NeobankApi.getInstance().contactApi.searchContacts(
        getState().contact.list.perPage,
        getState().contact.list.page!,
        [ContactSortDto.FAMILY_NAME],
        undefined,
        search,
      )

      const data = payload.status === 204 ? [] : payload.data

      dispatch({
        type: ContactActionTypes.LIST_CONTACT_SUCCESS,
        data,
        paginationEnded: data.length < getState().contact.list.perPage,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({
        type: ContactActionTypes.LIST_CONTACT_FAILURE,
        errorMessage,
      })
      throw new Error(errorMessage)
    }
  }
}

const getContactByUuid = (uuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ContactActionTypes.BYID_CONTACT_REQUEST })

    try {
      const contactCreateResponse = await NeobankApi.getInstance().contactApi.getContactByUuid(
        uuid!
      )

      dispatch({
        type: ContactActionTypes.BYID_CONTACT_SUCCESS,
        data: contactCreateResponse.data,
      })

      return contactCreateResponse.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({
        type: ContactActionTypes.BYID_CONTACT_FAILURE,
        errorMessage,
      })
      throw new Error(errorMessage)
    }
  }
}

const remove = (contact: ContactDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ContactActionTypes.DELETE_CONTACT_REQUEST })

    try {
      const contactCreateResponse = await NeobankApi.getInstance().contactApi.deleteContactByUuid(
        contact.uuid!
      )

      dispatch({
        type: ContactActionTypes.DELETE_CONTACT_SUCCESS,
        data: contact,
        alertType: AlertType.SUCCESS,
        errorMessage: i18next.t('neo-commons:contact:delete:success'),
      })

      return contactCreateResponse.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({
        type: ContactActionTypes.DELETE_CONTACT_FAILURE,
        errorMessage,
      })
      throw new Error(errorMessage)
    }
  }
}

const setSelected = (selectedId: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (getState().contact.list!.selectedId === selectedId) return
    dispatch({ type: ContactActionTypes.SET_SELECTED, selectedId })
  }
}

export const ContactActions = {
  ...ContactAction,
  update,
  create,
  list,
  remove,
  getContactByUuid,
  setSelected,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type ContactState = ResourceState<Contact>

const initialState: ContactState = {
  ...initialResourceState,
  list: {
    ...initialResourceState.list,
    filter: {
      userUuid: undefined,
      iban: undefined,
      name: undefined,
    },
  },
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const contact: Reducer = (state = initialState, action: AnyAction): ContactState => {
  switch (action.type) {
    default:
      return {
        ...ContactResourceReducer(state, action, {
          identifier: 'uuid',
          isPaginate: true,
          initialState,
        }),
      }
  }
}
