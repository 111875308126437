import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import i18n from 'i18next'
import dayjs from 'dayjs'
import { resources } from 'I18n'
import { StateTypes } from 'Store'

import { NeobankApi } from '@neo-commons/services/src'
import { Flags, Typography2 } from '@neo-commons/components'
import { ConfigActions, SignupActions } from '@neo-commons/store'

import { RSSelect } from '@neo-web/componentsOld'
import './SelectLang.scss'

export const SelectLang: React.FC = () => {
  const { t } = useTranslation()
  const signupState = useSelector((state: StateTypes) => state.signup)
  const [languageSelected, setLanguageSelected] = useState(signupState?.profile?.locale ?? i18n.language)
  const dispatch = useDispatch()

  useEffect(() => {
    setLanguageSelected(i18n.language)
  }, [t])

  const changeLanguage = async (value) => {
    try {
      NeobankApi.getInstance().setLanguage(languageSelected)
      await i18n.changeLanguage(value)
      await dispatch(ConfigActions.getConfig())
      dayjs.locale(value.split('_')[0])
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <RSSelect
        className='SelectLang'
        cleanable={false}
        searchable={false}
        appearance='subtle'
        data={Object.entries(resources).map(value =>
          ({
            label: t(value[1].label),
            value: value[1].value,
            isoCodeAlpha2: value[1].isoCodeAlpha2,
            isoCodeAlpha3: value[1].isoCodeAlpha3,
          })
        )}
        value={languageSelected}
        placement='bottomEnd'
        onChange={async (value) => {
          setLanguageSelected(value)
          dispatch(SignupActions.prepare(value))
          await changeLanguage(value)
        }}
        renderValue={(value) => {
          const selected = Object.values(resources).filter((r) => r.value === value)[0]
          return (
            <div className='SelectLang_select-value'>
              <img
                src={
                  String(Flags[selected?.isoCodeAlpha3 ?? 'Unknown'])
                }
                className='SelectLang_select-value_flag'
                alt='flag'
              />
              <div className='SelectLang_select-value_name'>
                <Typography2 typeface='h5'>
                  {selected?.isoCodeAlpha2}
                </Typography2>
              </div>
            </div>
          )
        }}
        renderMenuItem={(label, item) => {
          return (
            <div className='SelectLang_select-item'>
              <img
                src={String(Flags[item?.isoCodeAlpha3 ?? 'Unknown'])}
                className='SelectLang_select-item_flag'
                alt='item-flag'
              />
              <p className='SelectLang_select-item_name'>{label}</p>
            </div>
          )
        }}
      />
    </>
  )
}
