import { RoutesInterface } from './Routes'

export class ErrorRoutes {
  public static index: RoutesInterface = {
    path: '/error',
    name: 'index',
    available: true,
  }

  public static 404: RoutesInterface = {
    path: ErrorRoutes.index.path + '/404',
    name: '404',
    available: true,
  }

  public static 500: RoutesInterface = {
    path: ErrorRoutes.index.path + '/500',
    name: '500',
    available: true,
  }
}
