import React, { FunctionComponent, useEffect, useState } from 'react'
import { View, Platform, Text, TouchableOpacity } from 'react-native'

import { Validators, Formatters, CheckTextType } from '@neo-commons/libraries'
import i18next from 'i18next'
import { Colors } from '@neo-commons/styles'

import { Input } from '..'
import { CheckboxInput } from '../CheckboxInput/CheckboxInput'
import { Icon, IconTypes } from '../Icon/Icon'

import styles from './style'

export interface DebitCardInputsProps{
    onDebitCardOwner: (value) => void,
    onDebitCardNumbers: (value) => void,
    onDebitCardDate: (value) => void,
    onDebitCardCvv: (value) => void,
    onSaveDebitCard: (value) => void,
    onCheckAllValid: (value) => void,
    onCvvInfoPress: () => void,
    values?: {
        owner: string,
        bin: string,
        expirationDate: string,
        cvv: string,
    }
    errors?: {
      owner?: boolean,
      numbers?: boolean,
      date?: boolean,
      cvv?: boolean,
    }
}

export const DebitCardInputs: FunctionComponent<DebitCardInputsProps> = (
  {
    onDebitCardOwner,
    onDebitCardNumbers,
    onDebitCardDate,
    onDebitCardCvv,
    onSaveDebitCard,
    onCheckAllValid,
    onCvvInfoPress,
    values,
    errors,
  }) => {
  const [owner, setOwner] = useState(values?.owner ?? '')
  const [numbers, setNumbers] = useState(values?.bin ?? '')
  const [expirationDate, setExpirationDate] = useState(values?.expirationDate ?? '')
  const [cvv, setCvv] = useState(values?.cvv ?? '')
  const [saveDebitCard, setSaveDebitCard] = useState(false)
  const [isOwnerCertified, setIsOwnerCertified] = useState(false)
  const keyBoardType = Platform.OS === 'web' ? 'default' : 'number-pad'

  useEffect(() => {
    if ((Validators.isName(owner) as CheckTextType).isValid &&
        (Validators.isCardNumber(numbers) as CheckTextType).isValid &&
        (Validators.isCardDate(expirationDate) as CheckTextType).isValid &&
        (Validators.isCvv(cvv) as CheckTextType).isValid &&
        isOwnerCertified) {
      onCheckAllValid(true)
    } else {
      onCheckAllValid(false)
    }
  }, [owner, numbers, expirationDate, cvv, isOwnerCertified])

  return (
    <View style={styles.container}>
      <Input
        accessibilityLabel='testOwner'
        isRequired
        autoFocus
        label={i18next.t('provisioning:owner')}
        validators={[Validators.isName]}
        blurOnSubmit
        hasClear
        value={owner}
        clearButtonMode='while-editing'
        onChangeText={(value) => {
          onDebitCardOwner(value)
          setOwner(value)
        }}
      />
      <Input
        accessibilityLabel='testNumbers'
        isRequired
        label={i18next.t('provisioning:cardNumbers')}
        keyboardType={keyBoardType}
        maxLength={19}
        hasClear
        blurOnSubmit
        value={numbers}
        validators={[Validators.isCardNumber]}
        clearButtonMode='while-editing'
        onChangeText={(value) => {
          onDebitCardNumbers(value)
          setNumbers(value)
        }}
        format={Formatters.formatCreditCardNumbers}
        inputHasError={errors?.numbers ?? false}
      />
      <View style={styles.horizontalInputs}>
        <Input
          accessibilityLabel='testExpirationDate'
          isRequired
          label={i18next.t('provisioning:MM/AA')}
          containerStyle={[styles.horizontalInputsContainer, styles.horizontalInputsContainerLeft]}
          keyboardType={keyBoardType}
          maxLength={5}
          blurOnSubmit
          value={expirationDate}
          validators={[Validators.isCardDate]}
          hasClear
          clearButtonMode='while-editing'
          onChangeText={(value) => {
            onDebitCardDate(value)
            setExpirationDate(value)
          }}
          format={Formatters.formatCreditCardDate}
        />
        <Input
          accessibilityLabel='testCvv'
          isRequired
          label={i18next.t('provisioning:CVV')}
          containerStyle={[styles.horizontalInputsContainer, styles.horizontalInputsContainerRight]}
          keyboardType={keyBoardType}
          maxLength={3}
          blurOnSubmit
          hasClear
          value={cvv}
          validators={[Validators.isCvv]}
          clearButtonMode='while-editing'
          onChangeText={(value) => {
            onDebitCardCvv(value)
            setCvv(value)
          }}
          format={Formatters.pickNumbers}
        />
        <TouchableOpacity onPress={onCvvInfoPress} style={styles.infoIcon}>
          <Icon name='info' type={IconTypes.FEATHER} size={20} color={Colors.darkGray} />
        </TouchableOpacity>
      </View>
      <Text style={styles.forbiddenCards}>
        {i18next.t('provisioning:forbiddenCards')}
      </Text>
      <CheckboxInput
        accessibilityLabel='testCheckboxCvv'
        containerStyle={styles.label}
        text={i18next.t('provisioning:saveCard')}
        onPress={(value) => {
          onSaveDebitCard(value)
          setSaveDebitCard(value)
        }}
        isSelected={saveDebitCard}
      />
      <CheckboxInput
        accessibilityLabel='testCertificate'
        containerStyle={styles.label}
        text={i18next.t('provisioning:certifyOwnership')}
        onPress={() => { setIsOwnerCertified(!isOwnerCertified) }}
        isSelected={isOwnerCertified}
      />
    </View>
  )
}
