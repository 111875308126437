import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import commonsFR from '../locales_commons/fr_FR.json'
import commonsDE from '../locales_commons/de_DE.json'
import commonsEN from '../locales_commons/en_US.json'
import commonsIT from '../locales_commons/it_IT.json'
import commonsES from '../locales_commons/es_ES.json'
import enumFR from '../locales_enum/fr_FR.json'
import enumDE from '../locales_enum/de_DE.json'
import enumEN from '../locales_enum/en_US.json'
import enumIT from '../locales_enum/it_IT.json'
import enumES from '../locales_enum/es_ES.json'

export const i18commons = i18n.createInstance()

i18commons
  .use(initReactI18next)
  .init({
    debug: false,
    resources: {
      fr_FR: { ...commonsFR, ...enumFR },
      de_DE: { ...commonsDE, ...enumDE },
      en_US: { ...commonsEN, ...enumEN },
      it_IT: { ...commonsIT, ...enumIT },
      es_ES: { ...commonsES, ...enumES },
    },
    fallbackLng: 'fr_FR',
  }, () => null)
