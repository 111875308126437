import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { POSReturnStep, POSReturnSteps, StepComponentProps } from '@neo-commons/policies'
import { PosActions } from '@neo-commons/store'

import { FailureContent } from '@neo-web/componentsOld'

type POSReturnProps = StepComponentProps<POSReturnSteps[POSReturnStep.Failure]>

export const Failure: React.FC<POSReturnProps> = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <FailureContent
      buttonBack={{
        action: () => {
          dispatch(PosActions.resetPrepareReturn())
          history.push(Routes.Synthesis.index.path)
        },
      }}
      buttonAction={{
        action: () => {
          dispatch(PosActions.resetPrepareReturn())
          history.push(Routes.Services.managePos.path)
        },
      }}
    />
  )
}
