import { Reducer, AnyAction } from 'redux'
import i18next from 'i18next'
import { omit, filter, uniq, find } from 'lodash'
import { NeobankApi } from '@neo-commons/services'
import { createSelector } from 'reselect'
import {
  ClientTypeDto,
  OfferDto,
  OfferTypeDto,
  SubscriptionOfferDto,
} from '@afone/neo-core-client/dist/models'

import { Dispatch, ResourceState, resourceDefaultSelectors } from '../utils/resourceState'
import { State, initialResourceState, AlertType } from '../utils'

import { ClientActions, ClientSelectors } from './client'

/* %%%%%%%%%%%%%%%%%% *\
    Action Types.
\* %%%%%%%%%%%%%%%%%% */

const GET_OFFER_LIST_REQUEST = 'offer/GET_OFFER_LIST_REQUEST'
const GET_OFFER_LIST_SUCCESS = 'offer/GET_OFFER_LIST_SUCCESS'
const GET_OFFER_LIST_FAILURE = 'offer/GET_OFFER_LIST_FAILURE'

const GET_DISCOUNT_CODE_FAILURE = 'offer/GET_DISCOUNT_CODE_FAILURE'
const DISCOUNT_CODE = 'DISCOUNT_CODE'

const IS_CORPORATE = 'IS_CORPORATE'

const RESET = 'offer/RESET'

export const OfferTypes = {
  GET_OFFER_LIST_REQUEST,
  GET_OFFER_LIST_SUCCESS,
  GET_OFFER_LIST_FAILURE,
  GET_DISCOUNT_CODE_FAILURE,
  DISCOUNT_CODE,
  IS_CORPORATE,
  RESET,
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */

const list = function (discountCode?: string, offerCode?: string, clientType?: ClientTypeDto) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: GET_OFFER_LIST_REQUEST })
    try {
      const offerResponse = await NeobankApi.getInstance().offerApi.getOffers(discountCode, offerCode, clientType)
      const data = offerResponse.status === 204 ? { ...getState().offer.data } : offerResponse.data
      dispatch({
        type: GET_OFFER_LIST_SUCCESS,
        data,
        alertType: AlertType.SUCCESS,
        successMessage: discountCode ? i18next.t('neo-commons:app:global:subscription:offers:discountCodeValidated') : undefined,
      })
      dispatch({
        type: OfferTypes.DISCOUNT_CODE,
        discountCode,
      })
    } catch (error) {
      const errorMessage = error?.message ?? i18next.t('errors:internalTechnicalIssue')
      if (discountCode) {
        dispatch({ type: GET_DISCOUNT_CODE_FAILURE })
      }
      dispatch({ type: GET_OFFER_LIST_FAILURE, errorMessage })
      throw (errorMessage)
    }
  }
}

const listForClientUuid = function (discountCode?: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: GET_OFFER_LIST_REQUEST })
    try {
      if (!getState().client.list.loadedOnce) { await dispatch(ClientActions.list()) }
      const defaultClient = ClientSelectors.defaultOne(getState())
      if (!defaultClient) {
        throw new Error()
      }
      const offerResponse =
        await NeobankApi.getInstance().offerApi.getOffersForClientUuid(defaultClient?.uuid ?? '', discountCode)
      const data = offerResponse.status === 204 ? [] : offerResponse.data

      dispatch({ type: GET_OFFER_LIST_SUCCESS, data })
    } catch (error) {
      const errorMessage = error?.message ?? i18next.t('errors:internalTechnicalIssue')
      dispatch({ type: GET_OFFER_LIST_FAILURE, errorMessage })
      if (discountCode) {
        throw error?.message ?? ''
      }
    }
  }
}

const isCorporate = (value : boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: OfferTypes.IS_CORPORATE,
      value,
    })
  }
}


const reset = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET })
  }
}

export const OfferActions = {
  list,
  listForClientUuid,
  reset,
  isCorporate,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type OfferState = Omit<ResourceState<OfferDto & SubscriptionOfferDto>, 'create' | 'update'> & {
  isCorporate?: boolean,
  discountCode?: string | null,
}

const initialState: OfferState = {
  ...omit(initialResourceState, 'create', 'update'),
  isCorporate: false,
  discountCode: null,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const sortOffers = (offer1: OfferDto, offer2: OfferDto) => (offer1?.authorized ? 0 : 1) - (offer2?.authorized ? 0 : 1)

const offerSelector = (state: State) => state.offer
const defaultSelectors = resourceDefaultSelectors(offerSelector)

export const OfferSelectors = {
  ...defaultSelectors,
  listByType: (offerType: OfferTypeDto | undefined) => createSelector(
    defaultSelectors.list,
    (offers: OfferDto[]) => filter(offers, offer => offer.type === offerType).sort(sortOffers)
  ),
  listByClientType: (clientType: ClientTypeDto, offerType: OfferTypeDto) => createSelector(
    defaultSelectors.list,
    (offers: OfferDto[]) =>
      filter(offers, offer => offer.clientType === clientType && offer.type === offerType).sort(sortOffers)
  ),
  selected: () => createSelector(
    defaultSelectors.list,
    (offers: OfferDto[]) => find(offers, offer => offer.clientType)
  ),
  getByUuid: (uuid: string) => createSelector(
    defaultSelectors.list,
    (offers: OfferDto[]) => find(offers, offer => offer.uuid === uuid)
  ),
  isCorporate: (state: State) => state.offer.isCorporate,
  discountCode: (state: State) => state.offer.discountCode,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const offer: Reducer = (state: OfferState = initialState, action: AnyAction): OfferState => {
  switch (action.type) {
    case GET_OFFER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_OFFER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...(action.data?.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {}) ?? {}),
        },
        list: {
          ...state.list,
          ids: uniq([...state.list.ids, ...(action.data?.map(item => item.uuid) ?? [])]),
          loadedOnce: true,
        },
      }
    case GET_OFFER_LIST_FAILURE:
    case GET_DISCOUNT_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        discountCode: undefined,
      }
    case IS_CORPORATE:
      return {
        ...state,
        isCorporate: action.value,
      }
    case DISCOUNT_CODE:
      return {
        ...state,
        discountCode: action.discountCode,
      }
    case RESET:
      return initialState
    default:
      return state
  }
}
