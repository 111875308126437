import fromEntries from 'object.fromentries'

export class UrlUtils {
  /**
   * Convert all params GET url to object.
   *
   * @param url - With params in full string.
   * @returns an object with any type.
   * @example
   * const url = useLocation().search
   * const query = UrlUtils.deserialize(url)
   *
   */
  static deserialize (url: string): any {
    const urlParams = new URLSearchParams(url)
    if (!Object.fromEntries) {
      fromEntries.shim()
    }
    return Object.fromEntries(urlParams)
  }

  /**
   * Open Dialer on web
   *
   * @param phoneNumber - With params in full string.
   * @returns an object with any type.
   */
  static openPhoneDialer (phoneNumber: string): Window {
    return window.open(`tel:${phoneNumber}`)
  }

  /**
   * Open Mail App on web
   *
   * @param mail - send to mail open mail dialog
   * @param subject - optional subject to open mail dialog
   * @returns an object with any type.
   */
  static openMailApp (mail: string, subject = ''): Window {
    return window.open(`mailto:${mail}?subject=${subject}`)
  }
}
