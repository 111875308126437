import { StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

export default StyleSheet.create({
  icon: {
    marginHorizontal: 5,
  },
  dotsContainer: {
    paddingStart: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'baseline',
  },
  error: {
    ...Typo.info,
    textAlign: 'center',
    color: Colors.error,
  },

  pinEmpty: {
    marginStart: 5,
    marginEnd: 5,
    padding: 6,
    height: 38,
    width: 38,
    borderRadius: 20,
    borderWidth: 1,
    position: 'relative',
    overflow: 'hidden',
  },

  pinFill: {
    marginStart: 5,
    marginEnd: 5,
    padding: 6,
    height: 38,
    width: 38,
    borderRadius: 20,
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: Colors.secondary,
  },

  pinBorder: {
    marginStart: 5,
    marginEnd: 5,
    padding: 6,
    height: 38,
    width: 38,
    borderRadius: 20,
    borderWidth: 1,
    position: 'relative',
    borderColor: Colors.black,
    overflow: 'hidden',
  },

  pinText: {
    textAlign: 'center',
    textAlignVertical: 'center',
    position: 'absolute',
    lineHeight: 36,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    fontWeight: 'bold',
    color: Colors.black,
    backgroundColor: Colors.secondary,
  },

  eyes: {
    width: 30,
    height: 30,
    marginStart: 10,
    marginTop: 4,
    paddingTop: 4,
  },

  wrapWiew: {
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
  },
})
