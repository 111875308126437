import React from 'react'
import { useTranslation } from 'react-i18next'

import { Field, FieldProps, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { AddressDto } from '@afone/neo-core-client/dist/models'

import './AddressField.scss'

export interface AddressFieldProps extends FieldProps {
  address: AddressDto,
}

export const AddressField: React.FC<AddressFieldProps> = ({ address, ...props }) => {
  const { t } = useTranslation()

  return (
    <Field {...props}>
      <div className='AddressField_container'>
        {!address &&
          <Typography2 style={{ color: Colors.darkGreyText }} typeface='input'>
            {t('app:global:field:empty')}
          </Typography2>}
        {address && (
          <>
            <Typography2 typeface='input'>{address?.line1}</Typography2>
            {address?.line2 && <Typography2 typeface='input'>{address?.line2}</Typography2>}
            <Typography2 typeface='input'>{`${address?.postalCode} ${address?.city}`}</Typography2>
            <Typography2 typeface='input'>{address?.country}</Typography2>
          </>
        )}
      </div>
    </Field>
  )
}
