import { map } from 'lodash'

import { transactionRefundPolicy } from '@neo-commons/policies/src/Transaction'

export const TransactionRefundRoutes = {
  index: {
    path: transactionRefundPolicy.basePath,
    name: 'app:pages:synthesis:transaction:refund:title',
    available: true,
  },
  ...map(transactionRefundPolicy.steps, ({ path, meta }) => ({
    path,
    name: meta && meta.headerTitle,
    available: true,
  })),
}
