import React, { FunctionComponent } from 'react'
import { Image, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { CreditCardUtils } from '@neo-commons/libraries'
import { useTextWithBoldWords } from '@neo-commons/hooks'
import { Colors } from '@neo-commons/styles'
import { CardDto } from '@afone/neo-core-client/dist/models'
import { isEmpty } from 'lodash'

import { Icon, IconTypes, Typography } from '../index'
import { useStylesheet } from '../utils'

import styles from './styles'

export enum PaymentCardType {
  BOTH = 'BOTH',
  DIGITAL = 'DIGITAL',
  PHYSICAL = 'PHYSICAL',
}

export interface CreditCardDisplayProps {
  selected?: boolean,
  onPress?: () => void,
  card: CardDto,
  displayInfo?: boolean,
  only?: PaymentCardType,
  showStatus?: boolean
}

export const PaymentCards: FunctionComponent<CreditCardDisplayProps> = ({
  selected,
  onPress,
  card,
  displayInfo,
  only,
  showStatus = false,
}) => {
  const { t } = useTranslation()
  const canActivate = CreditCardUtils.canActivate(card)
  const isTemporaryBlocked = CreditCardUtils.isTemporaryBlocked(card)
  const isOrderInProgress = CreditCardUtils.isOrderInProgress(card)
  switch (true) {
    case PaymentCardType.PHYSICAL === only:
      card = { ...card, plastic: true }
      break
    case PaymentCardType.DIGITAL === only:
      card = { ...card, plastic: false }
      break
  }
  const s = useStylesheet(styles,
    {
      skin: '',
      props: {
        selected,
        card,
      },
    },
  )

  const PaymentCardItem: FunctionComponent<{ second?: boolean }> = ({
    second,
  }) => {
    return (
      <View testID={second ? 'secondaryRecto' : 'primaryRecto'} style={second ? s.digital : s.cardImage}>
        <Image
          source={{ uri: second ? card?.productImage : card?.plasticInfo?.productImage }}
          style={{
            width: '100%',
            minHeight: '100%',
            flexGrow: 1,
          }}
        />
        {displayInfo &&
          <View style={second ? s.infoPaymentCardDigital : s.infoPaymentCardPhysical} testID='displayInfo'>
            <Typography typeface='tinyBoldLabel' style={s.font}>
              {second && t('neo-commons:paymentCard:digital')}
            </Typography>
            <Typography typeface='tinyBoldLabel' style={s.font}>
              {CreditCardUtils.formatFullName(card?.formatedEmbossedData?.firstLine ?? '')}
            </Typography>
            {!isEmpty(card?.formatedEmbossedData?.secondLine) && (
              <Typography typeface='tinyBoldLabel' style={s.font}>
                {CreditCardUtils.formatFullName(card?.formatedEmbossedData?.secondLine ?? '')}
              </Typography>
            )}
            <Typography typeface='tinyBoldLabel' style={s.font}>
              {CreditCardUtils.formatTruncatedPan(card?.truncatedPan ?? '')}
            </Typography>
          </View>}
      </View>
    )
  }

  return (
    <TouchableOpacity testID='creditCardDisplay' onPress={onPress} disabled={!onPress}>
      <View testID='viewLayout' style={[s.container, selected ? s.selected : undefined]}>
        {showStatus && (isOrderInProgress || canActivate || isTemporaryBlocked) && (
          <View style={[s.overlay, { width: isTemporaryBlocked ? '100%' : '75%' }]}>
            <Icon
              color={Colors.secondaryDarker}
              name={isTemporaryBlocked ? 'lock' : 'payment'}
              type={isTemporaryBlocked ? IconTypes.FEATHER : IconTypes.NEOFONT}
              size={40}
            />
            {useTextWithBoldWords({
              text: t(isTemporaryBlocked
                ? 'neo-commons:paymentCard:status:blocked'
                : canActivate ? 'neo-commons:paymentCard:status:inactive' : 'neo-commons:paymentCard:status:order'
              ),
              regularFont: 'smallTextLight',
              boldFont: 'boldMedium',
            })}
          </View>
        )}
        {card?.plastic && card?.plasticInfo?.productImage && (<PaymentCardItem />)}
        {card?.productImage && <PaymentCardItem second />}
      </View>
    </TouchableOpacity>
  )
}
