import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import {
  LinkActionTypes,
} from '@neo-commons/store'

import { loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    LinkActionTypes.BYID_LINK_REQUEST,
    LinkActionTypes.BYID_LINK_SUCCESS,
    LinkActionTypes.BYID_LINK_FAILURE,
  ])
}

export default [
  reactiveLoader$,
]
