import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { StepComponentProps, BusinessSubscriptionSteps, BusinessSubscriptionStep } from '@neo-commons/policies'
import { Icon, IconTypes, MenuItemCard, Stepper2, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { EnumUtils, SubscriptionUtils } from '@neo-commons/libraries'
import { DocumentUploadActions, SubscriptionSelectors } from '@neo-commons/store'

import { SubscriptionDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './DocumentsChoice.scss'

type DocumentsChoiceProps =
  StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.DocumentsChoice]>

export const DocumentsChoice: React.FC<DocumentsChoiceProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const kycDocumentsIncomplete = useMemo(() =>
    SubscriptionUtils.getKycAffiliateDocumentsIncomplete(
      subscription,
      subscription?.subscriber?.personUuid
    ),
  [subscription])

  useEffect(() => {
    if (!kycDocumentsIncomplete.length) {
      nextStep()
    }
  }, [kycDocumentsIncomplete])

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      header={
        <div className='TunnelContent_container'>
          <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
            {t('neo-commons:subscription:idDocument')}
          </Typography2>
          <Stepper2 currentStep={1} />
          <div style={{ paddingBottom: 40 }}>
            <Icon name='idcard-passport' type={IconTypes.NEOFONT} size={50} color={Colors.secondary} />
          </div>
          <div className='TunnelContent_container_text'>
            <Typography2 typeface='h4'>
              {t('app:pages:subscription:global:documentChoice:title')}
            </Typography2>
          </div>
          <div>
            <Typography2 typeface='body1'>
              {t('app:pages:subscription:global:documentChoice:description')}
            </Typography2>
          </div>
        </div>
      }
    >
      <div className='DocumentChoice_container'>
        {kycDocumentsIncomplete.map((document, index) => {
          return (
            <div className='DocumentChoice_container_item' key={index}>
              <MenuItemCard
                title={t(EnumUtils.getKycTypeDtoKey(document.type))}
                onPress={() => {
                  dispatch(DocumentUploadActions.prepare({
                    documentType: document.type,
                    name: t(EnumUtils.getKycTypeDtoKey(document.type)),
                  }, subscription?.subscriber?.personUuid))
                  history.push(Routes.DocumentUpload.index.path)
                }}
              />
            </div>
          )
        })}
      </div>
    </TunnelContent>
  )
}
