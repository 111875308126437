import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useHotkeys } from 'react-hotkeys-hook'

import { FlexboxGrid, Button as RSuiteButton, Whisper, Tooltip } from 'rsuite'
import { RoutesActions } from 'Store/Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Modal } from '@neo-web/componentsOld'

import { AppPolicy } from '../../../Policies'

interface ComingSoonModalProps {
  onClose: () => void
}

export const ComingSoonModal: React.FC<ComingSoonModalProps> = (props: ComingSoonModalProps) => {
  const [showModal, setShowModal] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()
  const [isShow, setIsShow] = useState(false)
  const [shortcutUse, setShortcutUse] = useState(false)
  const currentUrl = useLocation()
  useHotkeys('q+a', () => {
    setShortcutUse(true)
  })

  useEffect(() => {
    if (shortcutUse) {
      setIsShow(!isShow)
      setShortcutUse(false)
    }
  }, [shortcutUse])

  const { t } = useTranslation()

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false)
        props.onClose()
      }}
    >
      <FlexboxGrid justify='center' className='text-center'>
        <FlexboxGrid.Item colspan={24} className='pb-s'>
          <Icon name='truck' size={30} type={IconTypes.FEATHER} color={Colors.secondary} />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24} className='pb-s'>
          <Typography typeface='bigTitle'>
            {t('app:global:wait:title')}
          </Typography>
          <br />
          <Typography typeface='infoRegular'>
            {t('app:global:wait:message')}
          </Typography>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <Button skin={ButtonSkin.PRIMARY} title={t('app:global:okay')} onPress={() => props?.onClose()} />
        </FlexboxGrid.Item>
        {isShow && AppPolicy.isNotProduction() &&
          <FlexboxGrid.Item colspan={24}>
            <Whisper
              placement='top'
              trigger='hover'
              speaker={
                <Tooltip>
                  {t('app:global:link:tester')}
                </Tooltip>
              }
            >
              <RSuiteButton
                appearance='link'
                onClick={() => {
                  dispatch(RoutesActions.unlockRoute(history.location.pathname))
                  history.push(currentUrl.pathname)
                }}
              >
                {t('app:global:tester')}
              </RSuiteButton>
            </Whisper>
          </FlexboxGrid.Item>}
      </FlexboxGrid>
    </Modal>
  )
}
