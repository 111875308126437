
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { SelectPicker } from 'rsuite'

import { UserActions } from '@neo-commons/store'

import { MaritalStatusDto, UserDto } from '@afone/neo-core-client/dist/models'

import './MyInfoFields.scss'

export interface MyMaritalStatusContentProps {
  user: UserDto
  onCloseModal: () => void
}

export const MyMaritalStatusContent: React.FC<MyMaritalStatusContentProps> = ({
  user,
  onCloseModal,
}: MyMaritalStatusContentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [maritalStatus, setMaritalStatus] = useState<MaritalStatusDto>(user?.person?.maritalStatus)

  const updateClientMaritalStatus = (maritalStatus: MaritalStatusDto) => {
    (async () => {
      try {
        await dispatch(UserActions.updateUserPerson(user, {
          ...user?.person,
          maritalStatus: maritalStatus,
        }))
        onCloseModal()
      } catch (error) {}
    })()
  }

  return (
    <div className='fieldContainer'>
      <SelectPicker
        data={Object.entries(MaritalStatusDto).map(
          (value) =>
            ({
              value: value[1],
              label: t('app:global:enum:maritalStatus:' + value[0].replace(/(_)/g, '').toLowerCase()),
            }),
        )}
        onChange={value => {
          setMaritalStatus(value)
          updateClientMaritalStatus(value)
        }}
        appearance='subtle'
        defaultValue={maritalStatus}
        cleanable={false}
        searchable={false}
        style={{ color: 'black' }}
        className='selectPickerField'
      />
    </div>
  )
}
