import React from 'react'

import { Tunnel, Errors } from '@neo-web/componentsOld'

interface ErrorPageProps {
  code: number
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ code }) => {
  return (
    <Tunnel component={<Errors code={code} />} />

  )
}
