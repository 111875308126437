import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { View } from 'react-native'

import { TunnelContent } from 'UI/Components/Layout/TunnelContent'
import { StateTypes } from 'Store'
import { PasswordKeypad } from 'UI/Components/Auth/PasswordKeypad'
import { AppConfig } from 'Config/AppConfig'
import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'

import { Button, ButtonSkin, Stepper2, Typography2 } from '@neo-commons/components'
import { RegisterStep, RegisterSteps, StepComponentProps } from '@neo-commons/policies'
import { usePasswordKeypad } from '@neo-commons/hooks'

import { KeypadFieldDto } from '@afone/neo-core-client/dist/models'

type CodeProps = StepComponentProps<RegisterSteps[RegisterStep.Code]>
export const Code: React.FC<CodeProps> = ({ nextStep }: CodeProps) => {
  const { t } = useTranslation()

  const profile = useSelector((state: StateTypes) => state?.signup?.profile)
  const passwordKeypadData = usePasswordKeypad({
    phone: profile?.phone,
    phoneCountryCode: profile?.countryCode,
    field: KeypadFieldDto.PASSWORD,
    passwordSize: AppConfig.PASS_CODE_SIZE,
  })

  const onValidate = async () => {
    try {
      nextStep({
        password: passwordKeypadData.password.map(e => e.value),
        phone: passwordKeypadData.phone,
      }).onDone(() => {
        const phoneNumber = parsePhoneNumber(profile.phone, profile.countryCode as CountryCode)
        AnalyticsHandler.track(AnalyticsEvents.signUp.userSignUpStepCompleted,
          {
            step_name: RegisterStep.Code,
            email: profile?.email,
            phone: phoneNumber.formatInternational().replace(/ /g, ''),
          })
      })
    } catch (e) {
      passwordKeypadData.reset(e)
    }
  }

  return (
    <TunnelContent
      header={
        <View>
          <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
            {t('app:pages:register:step1:contact:signup')}
          </Typography2>
          <Stepper2 currentStep={1} />
          <Typography2 typeface='h5'>
            {t('app:pages:register:step3:code:titleMessage')}
          </Typography2>
          <Typography2 typeface='body1'>
            {t('app:pages:register:step3:code:subTitleMessage')}
          </Typography2>
        </View>
      }
      footer={
        <Button
          title={t('app:global:validate')} skin={ButtonSkin.PRIMARY}
          disabled={!passwordKeypadData.isFilled}
          onPressOut={onValidate}
        />
      }
    >
      <View style={{ width: '90%' }}>
        <PasswordKeypad
          {...passwordKeypadData}
          withoutValidate
        />
      </View>
    </TunnelContent>
  )
}
