import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { Header, Sidebar, Sidenav } from 'rsuite'
import { Routes } from 'Routes/Routes'

import { Icon, IconTypes } from '@neo-commons/components'

import { Route } from '@neo-web/componentsOld'

import logo from '../../Resources/Images/site/dark-logo.svg'

import { pageType } from './Logged'

import './PageHeader.scss'

interface PageHeaderProps {
  pages: pageType[]
  onPressBurger: () => void
  isTabletOrMobile: boolean
}

export const PageHeader: React.FC<PageHeaderProps> = (props: PageHeaderProps) => {
  const renderContent = () => useMemo(() => {
    return (
      <div className='PageHeader_content'>
        <Link className='navbar-brand logo nav-link' to={Routes.Synthesis.transactions.path}>
          <img src={logo} alt='Logo' />
        </Link>
        {props.pages.map((page, index) => {
          return (
            <Route
              path={page.path}
              key={index}
              exact={page.exact ?? true}
              render={() => {
                return (page.header && <>{page.header}</>)
              }}
            />
          )
        })}
      </div>
    )
  }, [])

  return props.isTabletOrMobile ? (
    <TransitionGroup>
      <CSSTransition classNames='fadeheader' timeout={250}>
        <Header className='PageHeader'>
          <div className='text-center'>
            <div className='menu-burger'>
              <Icon name='menu' size={40} type={IconTypes.FEATHER} onPress={props.onPressBurger} />
            </div>
            {renderContent()}
          </div>
        </Header>
      </CSSTransition>
    </TransitionGroup>
  ) : (
    <Sidebar className='PageHeader'>
      <Sidenav.Body className='text-center'>
        {renderContent()}
      </Sidenav.Body>
    </Sidebar>
  )
}
