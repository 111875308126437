import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Typography } from '@neo-commons/components'

import { Tunnel } from '@neo-web/componentsOld'

import './LegalNotice.scss'

export const LegalNotice: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Tunnel
      title={t('app:pages:publicLink:legalNotice:title')}
      onClose={() => history.goBack()}
      component={
        <div className='LegalNotice_container'>
          <Typography typeface='content'>
            {t('app:pages:publicLink:legalNotice:content')}
          </Typography>
        </div>
      }
    />
  )
}
