import { Platform, StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

import { createStylesheet } from '../utils'

import { BadgeSkin } from './Badge'

const styles = createStylesheet(({ Skin, props }) => {
  return StyleSheet.create({
    containerStyle: {
      display: 'flex',
      flexDirection: 'row',
    },
    touchableStyle: {
      borderColor: Colors.white,
      ...Typo.infoRegular,

      ...Skin.select([BadgeSkin.DEFAULT], {
        backgroundColor: Colors.gray,
      }),
      ...Skin.select([BadgeSkin.PRIMARY], {
        backgroundColor: Colors.primary,
      }),
      ...Skin.select([BadgeSkin.SECONDARY], {
        backgroundColor: Colors.secondary,
      }),
      ...Skin.select([BadgeSkin.WARNING], {
        backgroundColor: Colors.warning,
      }),
      ...(props.skin === BadgeSkin.SUCCESS ? {
        backgroundColor: Colors.success,
      } : {}),
      ...(props.skin === BadgeSkin.ERROR ? {
        backgroundColor: Colors.error,
      } : {}),
      ...(props.skin === BadgeSkin.SECONDARY ? {
        backgroundColor: Colors.secondary,
      } : {}),

      ...(props.iconValue ? {
        display: 'flex',
        justifyContent: 'center',
        width: 'auto',
        height: '100%',
        borderRadius: 1000,
        padding: 8,
      } : {
        justifyContent: 'center',
        alignItems: 'center',
        height: 20,
        fontSize: 15,
        borderRadius: 15,
        minWidth: 60,
        paddingHorizontal: 12,
      }),

    },
    textStyle: {
      ...Typo.infoRegular,

      ...Skin.select([BadgeSkin.DEFAULT], {
        ...Typo.boldMedium,
      }),
      ...Skin.select([BadgeSkin.PRIMARY], {
        color: Colors.white,
      }),
      ...Skin.select([BadgeSkin.SECONDARY], {
        color: Colors.white,
      }),
      ...Skin.select([BadgeSkin.WARNING], {
        color: Colors.white,
      }),
      ...(props.skin === BadgeSkin.SUCCESS ? {
        color: Colors.white,
      } : {}),
      ...(props.skin === BadgeSkin.ERROR ? {
        color: Colors.white,
      } : {}),

      ...(!props.iconValue && {
        fontSize: 12,
        marginTop: Platform.OS === 'android' ? -2 : 0,
      }),
    },
  })
})

export default styles
