import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import {
  MenuItemCard,
  Typography2,
} from '@neo-commons/components'
import { BankAccountActions, BankAccountSelectors, BankAccountTypes } from '@neo-commons/store'
import { BankAccountUtils } from '@neo-commons/libraries'
import { NeobankApi } from '@neo-commons/services'

import './ListAggregationBanks.scss'

import { AccountDto } from '@afone/neo-core-client'
import { AggregationConnectorDto } from '@afone/neo-core-client/models'

import { AggregationLogo, JustifyContent, LazyLoadingContainer, PageContent, PageDetails } from '@neo-web/componentsOld'

export const ListAggregationBanks: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const aggregatedAccounts = useSelector(BankAccountSelectors.list)
    .filter((account: AccountDto) => BankAccountUtils.isAggregated(account))

  const [aggregatedConnectors, setAggregatedConnectors] = useState<AggregationConnectorDto[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    NeobankApi.getInstance().aggregationApi.getAggregationConnectors().then(el => {
      const aggregatedConnectorsTemp = []
      aggregatedAccounts.forEach((account) => {
        const myBankConnector = el?.data?.find(bankConnector => bankConnector.connectorId === account.slug)
        if (aggregatedConnectorsTemp.find(connector => connector.connectorId === account.slug) === undefined &&
          myBankConnector) {
          aggregatedConnectorsTemp.push(myBankConnector)
        }
      })
      setAggregatedConnectors(aggregatedConnectorsTemp)
      setIsLoading(false)
    })
  }, [])

  return (
    <LazyLoadingContainer events={[BankAccountTypes.SET_SELECTED_CONNECTOR]} isShow={isLoading}>
      <PageDetails
        title={t('app:global:edit')}
        onBack={() => history.push(Routes.Services.aggregation.path)}
        component={(
          <PageContent
            justifyContent={JustifyContent.START}
          >
            <div className='ListAggregationBanks_container'>
              <Typography2 typeface='body1'>
                Gérez l’affichage de vos comptes agrégés sur la page d’accueil
              </Typography2>
              <div>
                {aggregatedConnectors.map((bank, index) =>
                  <MenuItemCard
                    key={index}
                    containerStyle={{ height: 75, marginBottom: 16, borderTopWidth: 0 }}
                    title={bank.name}
                    chevronPosition='center'
                    icon={<AggregationLogo bank={bank} size={48} />}
                    onPress={() => {
                      dispatch(BankAccountActions.setSelectedConnector(bank))
                      history.push(Routes.Services.aggregatedAccounts.path)
                    }}
                  />
                )}
              </div>
            </div>
          </PageContent>
        )}
      />
    </LazyLoadingContainer>
  )
}
