import { map } from 'lodash'

import { posReturnPolicy } from '@neo-commons/policies/src/POS/Return'

export const PosReturnRoutes = {
  index: {
    path: posReturnPolicy.basePath,
    name: 'neo-commons:pages:services:pos:return:title',
    available: true,
  },
  ...map(posReturnPolicy.steps, ({ path, meta }) => ({
    path,
    name: meta && meta.headerTitle,
    available: true,
  })),
}
