import { map } from 'lodash'

import { RecoverPasswordPolicy } from '@neo-commons/policies'

export const RecoverPasswordRoutes = {
  index: {
    path: RecoverPasswordPolicy.basePath,
    name: 'app:pages:register:step4:secretQuestion:titleMessage',
    available: true,
  },
  ...map(RecoverPasswordPolicy.steps, ({ path, meta }) => ({
    path,
    name: meta.screenTitle,
    available: true,
  })),
}

export const RecoverPasswordAlertRoutes = {
  secretQuestion: {
    path: '/alert-secret-question',
    name: 'app:pages:recoverPassword:alert:titleMessage',
    available: true,
  },
}
