import React, { FunctionComponent } from 'react'
import { Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'

import { SwitchInput } from '../SwitchInput/SwitchInput'
import { CheckboxInput } from '..'

import styles from './ styles'

export interface PreferenceChannelValueDto {
  value: boolean,
  onChangeValue?: (value: any) => void,
  type?: PreferenceChannelTypeEnum|string,
  title?: string,
}

export interface PreferencesCardProps {
  choices: PreferenceChannelValueDto[],
  title?: string,
  subTitle?: string,
  onSubtitleClick?: () => void,
  group?: boolean
  containerStyle?: ViewStyle,
  cardStyle?: ViewStyle,
  titleStyle?: ViewStyle,
}

export enum PreferenceChannelTypeEnum {
  SMS= 'SMS',
  EMAIL= 'EMAIL',
  PUSH= 'PUSH',
}

export const SwitchPreferenceCard: FunctionComponent<PreferencesCardProps> = (
  { title, subTitle, onSubtitleClick, group, choices, containerStyle, cardStyle, titleStyle }) => {
  const { t } = useTranslation()

  const renderView = () => {
    switch (true) {
      case group:

        return (
          <View style={[styles.groupContainer, containerStyle]}>
            {title && <Text style={[styles.groupTitle, titleStyle]}>{title}</Text>}
            {choices.map(item =>
              <View style={[styles.groupItemContainer, cardStyle]} key={item.title! + item.type}>
                <Text style={[styles.groupItemName]}>
                  {item.title ? item.title : t('neo-commons:profile:' + item.type)}
                </Text>
                <SwitchInput
                  value={item.value}
                  onChange={isOn => item.onChangeValue && item.onChangeValue(isOn)}
                />
              </View>
            )}
          </View>
        )
      case !group:

        return (
          <View style={styles.checkCardContainer}>
            <View>
              {title && <Text style={styles.checkTitle}>{title}</Text>}
              {subTitle && onSubtitleClick &&
                <TouchableOpacity onPress={onSubtitleClick}>
                  <Text style={styles.checkSubTitle}>{subTitle}</Text>
                </TouchableOpacity>}
            </View>
            <View style={styles.checkContainer}>
              {choices.map(item =>
                <View style={[styles.checkItemContainer]} key={item.title! + item.type}>
                  {choices.length > 1
                    ? (
                      <CheckboxInput
                        size={24}
                        value={item.value}
                        isSelected={item.value}
                        text=''
                        onPress={isOn => item.onChangeValue && item.onChangeValue(isOn)}
                      />
                    )
                    : (
                      <SwitchInput
                        value={item.value}
                        onChange={isOn => item.onChangeValue && item.onChangeValue(isOn)}
                      />
                    )}
                </View>
              )}
            </View>
          </View>
        )
      default:
        return <View />
    }
  }

  return (
    <View>
      {renderView()}
    </View>
  )
}
