/**
   * Convert a object in url and convert params to object.
   *
   */
export class UrlUtils {
  /**
   * Convert all params obj in GET url.
   *
   * @param {any} obj - Object with params.
   * @returns {string} Return the url in string.
   * @example
   * const url = UrlUtils.serialize(obj)
   *
   */
  static serialize (obj: any) {
    const str = []
    for (const p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]) as never)
      }
    }

    return str.join('&')
  }

  /**
   * Convert all params GET url to object.
   *
   * @param {string} url - With params in full string.
   * @returns {any} Return - an object with any type.
   * @example
   * const url = useLocation().search
   * const query = UrlUtils.deserialize(url)
   *
   */
  static deserialize (url: string): any {
    const question = url.indexOf('?')
    let hash = url.indexOf('#')
    if (hash === -1 && question === -1) return {}
    if (hash === -1) hash = url.length
    const query = question === -1 || hash === question + 1 ? url.substring(hash)
      : url.substring(question + 1, hash)
    const result = {}
    query.split('&').forEach(function (part) {
      if (!part) return
      part = part.split('+').join(' ') // replace every + with space, regexp-free version
      const eq = part.indexOf('=')
      let key = eq > -1 ? part.substr(0, eq) : part
      const val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : ''
      const from = key.indexOf('[')
      if (from === -1) result[decodeURIComponent(key)] = val
      else {
        const to = key.indexOf(']', from)
        const index = decodeURIComponent(key.substring(from + 1, to))
        key = decodeURIComponent(key.substring(0, from))
        if (!result[key]) result[key] = []
        if (!index) result[key].push(val)
        else result[key][index] = val
      }
    })
    return result
  }

  static getRootUrl (): string {
    return `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`
  }
}
