import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Colors from 'UI/Resources/Styles/sass/themes/Colors'
import { StateTypes } from 'Store'

import { Field, Typography2 } from '@neo-commons/components'

import { AddressTypeDto, CountryDto, UserDto } from '@afone/neo-core-client/dist/models'

import { PageDetails, ProgressBar } from '@neo-web/componentsOld'

import { AddressField } from '../../Components/AddressField/AddressField'
import { EditModal } from '../../Components/EditModal'
import {
  MyHeritageContent,
  MyMaritalStatusContent,
  MyTaxIdNumberContent,
  MyProfessionContent,
  MyAddressContent,
  MyAnnualIncomeContent,
  MyTaxResidenceContent,
} from '../MyInfoFields'

import './UserProfile.scss'

export const UserProfile: React.FC = () => {
  const { t } = useTranslation()
  const regexReplace = /(_)/g

  const user: UserDto = useSelector((state: StateTypes) => state.user.data)

  const countries: CountryDto[] = useSelector((state: StateTypes) =>
    state.config?.data?.currentConfig?.countries ?? [])

  const profileCompletionPercentage = user?.person?.completion ?? 0

  const [showEditModal, setShowEditModal] = useState(false)
  const [editModalTitle, setEditModalTitle] = useState('')
  const [editModalContent, setEditModalContent] = useState('')
  // for modal overflow
  const [editModalFooter, setEditModalFooter] = useState('')

  const openModalEdit = (title: string, content?, footer?) => {
    setEditModalTitle(title)
    setEditModalContent(content)
    setEditModalFooter(footer)
    setShowEditModal(true)
  }
  const displayCountryName = () =>
    countries.find(country => country.isoCodeAlpha2 === user?.person?.taxDomiciliationCountryCode)?.name ?? ''

  return (
    <PageDetails
      title='Mon profil'
      component={
        <div className='UserProfile'>
          <div className='UserProfile_container'>
            {user?.person && (
              <div className='UserProfile_container_progress'>
                <Typography2 typeface='overline' style={{ color: Colors.textColor }}>
                  {t('app:pages:myProfile:myInfo:profile:state')}
                </Typography2>
                <div className='UserProfile_container_progress_percentage'>
                  <ProgressBar percentage={profileCompletionPercentage} color={profileCompletionPercentage === 100 ? Colors.success : Colors.primary} />
                  <Typography2 typeface='h6' style={{ color: profileCompletionPercentage === 100 ? Colors.success : Colors.disable }}>
                    {t('app:pages:myProfile:myInfo:profile:full')}
                  </Typography2>
                </div>
              </div>
            )}
            <div className='UserProfile_container_title'>
              <Typography2 typeface='overline'>
                {t('app:pages:myProfile:myInfo:myInformation')}
              </Typography2>
            </div>
            <div className='UserProfile_container_section'>
              <div className='UserProfile_container_section_field'>
                <Field
                  label={t('app:global:user:firstname')}
                  value={user?.firstName}
                />
              </div>
              <div className='UserProfile_container_section_field'>
                <Field
                  label={t('app:global:user:lastname')}
                  value={user?.lastName}
                />
              </div>
              <div className='UserProfile_container_section_field'>
                <Field
                  label={t('app:global:user:phoneNumber')}
                  value={user?.phone?.nationalFormat}
                  onEditPress={() => { openModalEdit(t('app:global:userEdit:editPhoneNumber')) }}
                />
              </div>
              <div className='UserProfile_container_section_field'>
                <Field
                  label={t('app:global:user:email')}
                  value={user?.email}
                  onEditPress={() => { openModalEdit(t('app:global:userEdit:editEmail')) }}
                />
              </div>
              {user?.person && (
                <>
                  <div className='UserProfile_container_section_field'>
                    <Field
                      label={t('app:global:user:taxResidence')}
                      value={displayCountryName()}
                      onEditPress={() => {
                        openModalEdit(t('app:global:user:taxResidence'),
                          <MyTaxResidenceContent
                            user={user}
                            onCloseModal={() => { setShowEditModal(false) }}
                          />)
                      }}
                    />
                  </div>
                  <div className='UserProfile_container_section_field'>
                    <Field
                      label={t('app:global:user:input:taxIdentificationNumber')}
                      value={user?.person?.taxIdentificationNumber}
                      onEditPress={() => {
                        openModalEdit(t('app:global:user:taxIdentificationNumber'),
                          <MyTaxIdNumberContent
                            user={user}
                            onCloseModal={() => { setShowEditModal(false) }}
                          />)
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {user?.person && (
              <>
                <div className='UserProfile_container_title'>
                  <Typography2 typeface='overline'>
                    {t('app:pages:myProfile:myInfo:myAddress')}
                  </Typography2>
                </div>
                <div className='UserProfile_container_section'>
                  <div className='UserProfile_container_section_field'>
                    <AddressField
                      label={t('app:global:user:address')}
                      address={user?.person?.addresses[0]}
                      onEditPress={() => {
                        openModalEdit(t('app:global:user:address'),
                          <MyAddressContent
                            address={user?.person?.addresses?.find(add => add.type === AddressTypeDto.FISCAL)}
                            user={user}
                            onCloseModal={() => { setShowEditModal(false) }}
                          />)
                      }}
                    />
                  </div>
                </div>
                <div className='UserProfile_container_title'>
                  <Typography2 typeface='overline'>
                    {t('app:pages:myProfile:myInfo:myInfosAdd')}
                  </Typography2>
                </div>
                <div className='UserProfile_container_section'>
                  <div className='UserProfile_field'>
                    <Field
                      label={t('app:global:user:maritalStatus')}
                      value={
                        user?.person?.maritalStatus
                          ? t('app:global:enum:maritalStatus:' +
                        user?.person?.maritalStatus?.replace(regexReplace, '').toLowerCase())
                          : ''
                      }
                      onEditPress={() => {
                        openModalEdit(t('app:global:user:maritalStatus'),
                          <MyMaritalStatusContent
                            user={user}
                            onCloseModal={() => { setShowEditModal(false) }}
                          />)
                      }}
                    />
                  </div>
                  <div className='UserProfile_container_section_field'>
                    <Field
                      label={t('app:global:user:profession')}
                      value={
                        user?.person?.socioprofessionalCategory
                          ? t('app:global:enum:profession:' +
                        user?.person?.socioprofessionalCategory?.replace(regexReplace, '').toLowerCase())
                          : ''
                      }
                      onEditPress={() => {
                        openModalEdit(t('app:global:user:profession'),
                          <MyProfessionContent
                            user={user}
                            onCloseModal={() => { setShowEditModal(false) }}
                          />)
                      }}
                    />
                  </div>
                  <div className='UserProfile_container_section_field'>
                    <Field
                      label={t('app:global:user:annualIncome')}
                      value={
                        user?.person?.annualIncome
                          ? t('app:global:enum:annualIncome:' +
                        user?.person?.annualIncome?.replace(regexReplace, '').toLowerCase())
                          : ''
                      }
                      onEditPress={() => {
                        openModalEdit(t('app:global:user:annualIncome'),
                          <MyAnnualIncomeContent
                            user={user}
                            onCloseModal={() => { setShowEditModal(false) }}
                          />)
                      }}
                    />
                  </div>
                  <div className='UserProfile_container_section_field'>
                    <Field
                      label={t('app:global:user:heritage')}
                      value={
                        user?.person?.patrimony
                          ? t('app:global:enum:heritage:' +
                        user?.person?.patrimony?.replace(regexReplace, '').toLowerCase())
                          : ''
                      }
                      onEditPress={() => {
                        openModalEdit(t('app:global:user:heritage'),
                          <MyHeritageContent
                            user={user}
                            onCloseModal={() => { setShowEditModal(false) }}
                          />)
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <EditModal
            title={editModalTitle} show={showEditModal}
            onClose={() => {
              setShowEditModal(false)
            }}
            content={editModalContent}
            footer={editModalFooter}
          />
        </div>
      }
    />
  )
}
