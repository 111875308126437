import React, { createRef, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { gsap } from 'gsap'
import { Routes } from 'Routes/Routes'

import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import './NavbarMenu.scss'
import { useResponsive } from '@neo-web/hooks'

const menuItems = [
  {
    name: 'app:menu:synthesis',
    icon: <Icon type={IconTypes.NEOFONT} name='menu-hamburger' size={24} />,
    to: Routes.Synthesis.transactions.path,
  },
  {
    name: 'app:menu:payments',
    icon: <Icon type={IconTypes.NEOFONT} name='menu-wallet' size={24} />,
    to: Routes.Payments.index.path,
  },
  {
    name: 'app:menu:settings',
    icon: <Icon type={IconTypes.NEOFONT} name='menu-dashboard' size={24} />,
    to: Routes.Services.index.path,
  },
  {
    name: 'app:menu:myProfile',
    icon: <Icon type={IconTypes.NEOFONT} name='menu-profile' size={24} />,
    to: Routes.Profile.index.path,
  },
]

export const NavbarMenu: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const isTabletOrMobile = useResponsive()

  const $root = useRef()
  const $container = useRef()
  const $indicator1 = useRef()
  const $indicator2 = useRef()
  const $items: any = useRef(menuItems.map(createRef))

  const index: number = menuItems.findIndex(item => location.pathname.startsWith(item.to))
  const [active, setActive] = useState<number>(index !== -1 ? index : 0)
  const [menuPosition, setMenuPosition] = useState<number>(0)

  // We need this ref to access the current value of state 'active'
  // in callback function when animate() is called from the eventListener behind (resize)
  const $activeRef: any = useRef()
  $activeRef.current = active

  const animate = () => {
    const menu: any = $container?.current
    if (!isTabletOrMobile && menu) {
      const menuOffset = menu?.getBoundingClientRect()
      const activeItem = $items.current[$activeRef.current].current
      if (activeItem) {
        const {
          width,
          height,
          top,
          left,
        } = activeItem.getBoundingClientRect()

        const settings = {
          x: left - menuOffset.x,
          y: top - menuOffset.y,
          width: width,
          height: height,
          backgroundColor: Colors.primary,
          ease: 'elastic.out(.1, .7)',
          duration: 0.8,
        }

        gsap.to($indicator1.current, {
          ...settings,
        })

        gsap.to($indicator2.current, {
          ...settings,
          duration: 1,
        })
      }
    }
  }

  const positionMenu = () => {
    if ($container && $root) {
      const menu: any = $container.current
      const root: any = $root.current
      if (!isTabletOrMobile && menu && root) {
        // We position the menu on the right with a dynamic marginLeft to avoid problems with scrollbar offset
        const rootOffset = ($root as any).current.getBoundingClientRect()
        const menuOffset = ($container as any).current?.getBoundingClientRect()
        setMenuPosition(rootOffset.width - menuOffset?.width - 25)

        setTimeout(() => {
          animate()
        }, 100)
      }
    }
  }

  useLayoutEffect(() => {
    positionMenu()
    window.addEventListener('resize', positionMenu)
    return () => {
      window.removeEventListener('resize', positionMenu)
    }
  }, [])

  useLayoutEffect(() => {
    setTimeout(() => {
      animate()
    }, 100)
  }, [active])

  useLayoutEffect(() => {
    if (active !== 0 && location.pathname.startsWith(Routes.Synthesis.transactions.path)) {
      // Animate to Synthesis when logo is clicked
      setActive(0)
    }
    if (active !== 1 && location.pathname.startsWith(Routes.Payments.index.path)) {
      // Animate to Payment
      setActive(1)
    }
    if (active !== 2 && location.pathname.startsWith(Routes.Services.index.path)) {
      // Animate to Services
      setActive(2)
    }
    if (active !== 3 && location.pathname.startsWith(Routes.Profile.index.path)) {
      // Animate to Profile
      setActive(3)
    }
  }, [location])

  return (
    <div
      ref={$root}
      className='NavbarMenu'
    >
      <ul
        ref={$container}
        className='NavbarMenu_container'
        style={{ marginLeft: isTabletOrMobile ? 0 : (menuPosition > 20 ? menuPosition : 20) }}
      >
        {menuItems.map((item, index) => (
          <li
            key={index}
            ref={$items.current[index]}
            className={`NavbarMenu_item ${active === index ? 'active' : ''}`}
            onClick={() => {
              setActive(index)
              if (location.pathname !== item.to) {
                history.push(item.to)
              }
            }}
          >
            {item.icon} {t(item.name)}
          </li>
        ))}
        <div
          ref={$indicator1}
          className='NavbarMenu_item_indicator'
        />
        <div
          ref={$indicator2}
          className='NavbarMenu_item_indicator'
        />
      </ul>
    </div>
  )
}
