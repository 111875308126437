import { UserState as OidcUserState } from 'redux-oidc'

import { DocumentUploadState } from '../states/documentUpload'
import { BankAccountState } from '../states/bankAccount'
import { TransactionState } from '../states/transaction'
import { SignupState } from '../states/signup'
import { ProvisioningState } from '../states/provisioning/provisioning'
import { SecretQuestionState } from '../states/secretQuestion'
import { UserSecretQuestionState } from '../states/userSecretQuestion/userSecretQuestion'
import { UserState } from '../states/user'
import { OfferState } from '../states/offer'
import { SubscriptionState } from '../states/subscription'
import { ClientState } from '../states/client'
import { AddressState } from '../states/address'
import { InvoiceState } from '../states/invoice'
import { TransferState } from '../states/transfer'
import { ContactState } from '../states/contact/contact'
import { SignInState } from '../states/signin/signin'
import { RecoverPasscodeState } from '../states/recoverPasscode'
import { NotificationPreferencesState } from '../states/notificationPreferences'
import { CardState } from '../states/card'
import { DeviceState } from '../states/device'
import { ContactPreferencesState } from '../states/contactPreference'
import { ConfigState } from '../states/config/config'
import { DocumentState } from '../states/document'
import { ExternalCardState } from '../states/externalCard'
import { DeviceRecoveryState } from '../states/deviceRecovery'
import { ContractState } from '../states/contract'
import { StatementState } from '../states/statement'
import { LinkState } from '../states/link'
import { NotificationState } from '../states/notification'
import { PosState } from '../states/pos'
import { TelecollectionState } from '../states/telecollection'
import { AdvanceState } from '../states/advance'
import { OrdersState } from '../states/orders'

import { ResourceState } from './resourceState'

export interface State {
  bankAccount: BankAccountState,
  transaction: TransactionState,
  signup: SignupState,
  provisioning: ProvisioningState,
  secretQuestion: SecretQuestionState,
  userSecretQuestion: UserSecretQuestionState,
  user: UserState,
  offer: OfferState,
  subscription: SubscriptionState,
  client: ClientState,
  address: AddressState
  invoice: InvoiceState,
  transfer: TransferState,
  contact: ContactState,
  signIn: SignInState,
  recoverPasscode: RecoverPasscodeState
  notificationPreferenceApi: NotificationPreferencesState,
  contactPreferences: ContactPreferencesState,
  card: CardState,
  device: DeviceState,
  config: ConfigState,
  document: DocumentState,
  externalCard: ExternalCardState,
  deviceRecovery: DeviceRecoveryState,
  contract: ContractState,
  statement: StatementState,
  link: LinkState,
  notification: NotificationState,
  pos: PosState,
  telecollection: TelecollectionState
  documentUpload: DocumentUploadState
  oidc: OidcUserState
  advance: AdvanceState
  orders: OrdersState
}

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export const initialResourceState: ResourceState<any> = {
  loading: false,
  data: {},
  total: 0,
  list: {
    ids: [],
    selectedId: null,
    loadedOnce: false,
    sort: null,
    order: null,
    page: 0,
    perPage: 20,
    filter: null,
    paginationEnded: false,
    subLists: {},
    length: 0,
    shouldRefresh: true,
  },
  create: null,
  update: null,
  prepare: null,
  isDelete: false,
}

export const getInitialResourceState = (): ResourceState<any> => {
  return JSON.parse(JSON.stringify(initialResourceState))
}
