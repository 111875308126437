// @format
import { StyleSheet, ViewStyle, Platform } from 'react-native'

import { Typo, Colors } from '@neo-commons/styles'
import { CalendarTheme } from '@afone/react-native-toggle-calendar'

interface CalendarStyle {
  container: ViewStyle,
  pickerContainer: ViewStyle,
  calendarContainer: ViewStyle,
  yearPicker: ViewStyle,
  monthPicker: ViewStyle,
  withBorder: ViewStyle,
}

const styles = StyleSheet.create<CalendarStyle>({
  container: {
    backgroundColor: Colors.transparent,
    maxWidth: 400,
  },
  pickerContainer: {
    flexDirection: 'row',
    marginHorizontal: 0,
    marginBottom: 8,
  },
  calendarContainer: {
    margin: 0,
    padding: 0,
    borderWidth: 1,
    backgroundColor: Colors.white,
    borderColor: Colors.white,
    borderRadius: 15,
    paddingTop: 4,
    paddingBottom: 8,
  },
  monthPicker: {
    marginLeft: 4,
  },
  yearPicker: {
    marginRight: 4,
  },
  withBorder: {
    borderWidth: 1.5,
    borderColor: Colors.backgroundDarker,
  },
})

export const calendarStyles: CalendarTheme = {
  calendarBackground: Colors.transparent,
  textSectionTitleColor: Colors.secondary,
  textDayFontFamily: Typo.type.medium,
  'stylesheet.calendar.header': {
    header: {
      height: 0,
      width: 0,
      opacity: 0,
    },
  },
  'stylesheet.day.basic': {
    base: {
      width: 32,
      height: 32,
      alignItems: 'center',
      borderWidth: 1,
      borderColor: Colors.secondary,
      borderRadius: 15,
    },
    text: {
      marginTop: Platform.OS === 'android' ? 4 : 6,
      fontFamily: Typo.type.medium,
      fontSize: 15,
      color: Colors.black,
    },
    today: {
      backgroundColor: Colors.secondary,
    },
    todayText: {
      color: Colors.black,
    },
    selected: {
      backgroundColor: Colors.primary,
      borderColor: Colors.primary,
    },
    selectedText: {
      color: Colors.white,
    },
  },
}

export default styles
