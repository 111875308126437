import React from 'react'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'

import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'
import CustomIcon from 'UI/Components/CustomIcon'

import {
  IndividualSubscriptionSteps,
  IndividualSubscriptionStep,
  StepComponentProps,
} from '@neo-commons/policies'
import { Button, ButtonSkin, Typography2 } from '@neo-commons/components'
import { ConfigSelectors } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './KYCPedagogy.scss'

type KYCPedagogyProps = StepComponentProps<IndividualSubscriptionSteps[IndividualSubscriptionStep.KYCPedagogy]>

export const KYCPedagogy: React.FC<KYCPedagogyProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const currentConfig = useSelector(ConfigSelectors.getCurrentConfig)

  const fontBold = { fontFamily: 'Quicksand-Bold' }
  const fontMedium = { fontFamily: 'Quicksand-Medium' }

  const onPressnextStep = () => {
    AnalyticsHandler.track(AnalyticsEvents.KYC.kycFunnelStarted, {
      account_type: 'B2C',
    })
    nextStep()
  }

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.CENTER}
      header={
        <div style={{ textAlign: 'center' }}>
          <Typography2 typeface='h3'>
            {t('app:pages:subscription:global:offers:openAccount')}
          </Typography2>
          <br />
          <div style={{ marginTop: 16, marginBottom: 12 }}>
            <CustomIcon name='clock' size={68} color={Colors.primary} />
          </div>
          <br />
          <Typography2 typeface='h5' style={fontMedium}>
            {t('neo-commons:pages:kyc:individual:introduction')}
          </Typography2>
        </div>
      }
      description={
        <div className='KYCPedagogy_description'>
          <div className='KYCPedagogy_row'>
            <div className='KYCPedagogy_icon'>
              <div style={{ marginTop: -16 }}>
                <CustomIcon name='bank' size={50} color={Colors.primary} />
              </div>
            </div>
            <div className='KYCPedagogy_col'>
              <Typography2 typeface='h5' style={fontBold}>{t('neo-commons:pages:kyc:individual:fiscalDomiciliation')}</Typography2>
            </div>
          </div>
          <div className='KYCPedagogy_row'>
            <div className='KYCPedagogy_icon'>
              <CustomIcon name='userId' size={42} color={Colors.primary} />
            </div>
            <div className='KYCPedagogy_col'>
              <Typography2 typeface='h5' style={fontBold}>{t('neo-commons:pages:kyc:individual:idDocument')}</Typography2><br />
              <Typography2 typeface='h5' style={fontMedium}>{t('neo-commons:pages:kyc:individual:idDocumentText')}</Typography2>
            </div>
          </div>
          <div className='KYCPedagogy_row'>
            <div className='KYCPedagogy_icon'>
              <CustomIcon name='penIcon' size={48} color={Colors.primary} />
            </div>
            <div className='KYCPedagogy_col'>
              <Typography2 typeface='h5' style={fontBold}>{t('neo-commons:pages:kyc:individual:contractSignature')}</Typography2><br />
              <Typography2 typeface='h5' style={fontMedium}>
                <Trans
                  i18nKey='neo-commons:pages:kyc:individual:contractSignatureText'
                  components={[
                    <Typography2
                      typeface='h5'
                      key={0}
                      style={{ ...fontBold, textDecorationLine: 'underline' }}
                      onPress={() => {
                        window.open(currentConfig?.cgus?.individual, '_blank')
                      }}
                    />,
                    <Typography2
                      typeface='h5'
                      key={1}
                      style={{ ...fontBold, textDecorationLine: 'underline' }}
                      onPress={() => {
                        window.open(currentConfig?.cgus?.individual, '_blank')
                      }}
                    />,
                  ]}
                />
              </Typography2>
            </div>
          </div>
        </div>
      }
      footer={
        <div className='KYCPedagogy_footer'>
          <Typography2 typeface='h5' style={fontMedium}>
            <Trans
              i18nKey='neo-commons:pages:kyc:individual:documentValidation'
              components={[
                <Typography2 typeface='h5' key={0} style={fontBold} />,
              ]}
            />
          </Typography2>
          <Button
            overrideStyleButton={{ marginTop: 32 }}
            skin={ButtonSkin.PRIMARY}
            title={t('app:global:letsGo')}
            onPress={onPressnextStep}
          />
        </div>
      }
    />
  )
}
