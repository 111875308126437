import React from 'react'
import { Image } from 'react-native'

import { Typography, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import './OfferDescription.scss'

export type OfferDescriptionItem = {
  title: string,
  description?: string,
  text?: string,
  img?: Array<string>,
  url?: string,
}

interface OfferDescriptionProps {
  descriptions: OfferDescriptionItem[],
  hasImage?: boolean,
}

const DescriptionPictos = (props: any) => {
  return (
    <div className='OfferDescription_pictos'>
      {props.images && props.images.map((picto, index) => {
        return (
          <Image
            key={index}
            source={{ uri: picto }}
          />
        )
      })}
    </div>
  )
}

export const OfferDescription: React.FC<OfferDescriptionProps> = (props: OfferDescriptionProps) => {
  const descriptions = props.descriptions.filter((description) => !description.url)
  const links = props.descriptions.filter((description) => description.url)

  return (
    <div className={`OfferDescription scrollable ${props.hasImage ? 'hasImage' : ''}`}>
      <ul>
        {descriptions.map((value, index) =>
          <div key={index}>
            <li>
              <div className='OfferDescription_icon'>
                <Icon
                  color={Colors.greener}
                  name='check-circle'
                  size={20}
                  type={IconTypes.FEATHER}
                />
              </div>

              <div className='OfferDescription_text'>
                <Typography typeface='bold'>
                  {value.title}
                </Typography>
                <br />
                <Typography typeface='content'>
                  {value.description ? value.description : value.text}
                </Typography>
              </div>
            </li>
            {value.img && <DescriptionPictos images={value.img} />}
          </div>
        )}
        {links.map((link, index) => {
          return (
            <li key={index} className='OfferDescription_link'>
              <a href={link.url} target='_blank' rel='noreferrer'>
                <Typography typeface='smallText' style={{ textDecorationLine: 'underline' }}>
                  {link.title}
                </Typography>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
