import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  Typography,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { StateTypes } from '../../../../../Store'
import { ScaActions } from '../../../../../Store/Sca'

import './WalletVerify.scss'

export const WalletVerify: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const scaState = useSelector((state: StateTypes) => state.sca?.data)

  useEffect(() => {
    dispatch(ScaActions.processWallet({
      authenticationRequestUuid: scaState?.authenticationRequestUuid,
    }))
  }, [])

  return (
    <div className='WalletVerify_container'>
      <Icon
        color={Colors.secondary}
        name='key'
        size={40}
        type={IconTypes.FEATHER}
      />

      <div className='WalletVerify_title'>
        <Typography typeface='bold'>
          {t('app:pages:auth:scaDevice:walletVerify:title')}
        </Typography>
      </div>

      <div className='WalletVerify_content'>
        <Typography typeface='content'>
          {t('app:pages:auth:scaDevice:walletVerify:content')}
        </Typography>
      </div>

      <div className='WalletVerify_devices'>
        <ul>
          {scaState?.prepare?.deviceNames && scaState?.prepare?.deviceNames.map((value, index) => {
            return (
              <li key={index}>{value}</li>
            )
          })}
        </ul>
      </div>

      <div className='WalletVerify_button'>
        <Button
          title={t('app:pages:auth:scaDevice:walletVerify:notAccessBtn')}
          skin={ButtonSkin.LINK}
          onPress={() => {
            dispatch(ScaActions.cancelMethod())
          }}
        />
      </div>
    </div>
  )
}
