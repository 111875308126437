import React from 'react'

import { BankAccount } from '@neo-commons/store'
import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { BankAccountUtils } from '@neo-commons/libraries'

import { AccountTypeDto, ClientTypeDto } from '@afone/neo-core-client/dist/models'

import { AggregationLogo } from '@neo-web/componentsOld'

import NoelseProWhite from '../../Resources/Images/site/noelse_pro_white.png'
import NoelsePro from '../../Resources/Images/site/noelse_pro_black.png'

export const useIcon = (
  bankAccount: BankAccount,
  clientType: ClientTypeDto,
  isSelected?:boolean,
  isDisabled?:boolean,
  size?: number,
  isInverted?: boolean,
) : typeof Icon | HTMLImageElement => {
  let result
  switch (bankAccount?.type) {
    case AccountTypeDto.MAIN: {
      if (clientType === ClientTypeDto.CORPORATE) {
        result =
          <img
            style={size ? { height: size, width: size } : { height: 24, width: 24 }}
            src={isSelected ? NoelseProWhite : NoelsePro}
          />
      } else {
        result =
          <Icon
            name='dark-logo'
            color={isSelected ? Colors.white : (isDisabled ? Colors.darkGreyText : Colors.black)}
            type={IconTypes.NEOFONT}
            size={size || 24}
          />
      }
      break
    }
    case AccountTypeDto.AGGREGATED: {
      result =
        <AggregationLogo
          bank={{
            icon: bankAccount?.icon,
            color: bankAccount?.color,
            slug: bankAccount?.slug,
            name: bankAccount?.bank,
          }}
          size={size || 40}
        />
      break
    }
    case AccountTypeDto.PROJECT: {
      result =
        <Icon
          name={BankAccountUtils.getFrontIconString(bankAccount.icon)}
          color={isInverted ? Colors.secondary : Colors.white}
          type={IconTypes.MATERIAL}
          withBackground
          containerStyle={{
            backgroundColor: isInverted ? Colors.white : Colors.secondary,
            alignSelf: 'center',
            alignItems: 'center',
            paddingHorizontal: 4,
            paddingVertical: 4,
          }}
          size={size || 16}
        />
      break
    }
    case AccountTypeDto.POS: {
      result =
        <Icon
          name='tpe'
          color={isInverted ? Colors.secondary : Colors.white}
          withBackground
          type={IconTypes.NEOFONT}
          containerStyle={{
            backgroundColor: isInverted ? Colors.white : Colors.secondary,
            alignSelf: 'center',
            alignItems: 'center',
            paddingHorizontal: 4,
            paddingVertical: 4,
          }}
          size={size || 16}
        />
      break
    }
  }
  return result
}
