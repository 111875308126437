import { map } from 'lodash'

import { closeProjectPolicy } from '@neo-commons/policies'

export const CloseProjectRoutes = {
  index: {
    path: closeProjectPolicy.basePath,
    name: 'neo-commons:project:close',
    available: true,
  },
  ...map(closeProjectPolicy.steps, ({ path, meta }) => ({
    path,
    name: meta && meta.headerTitle,
    available: true,
  })),
}
