import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import './EditPinSuccess.scss'
interface EditPinSuccessProps {
    onGoToServicesClick?: () => void,
  }
export const EditPinSuccess: FunctionComponent<EditPinSuccessProps> = ({ onGoToServicesClick }) => {
  const { t } = useTranslation()
  return (
    <div className='EditPinSuccess'>
      <Icon
        name='check-circle'
        type={IconTypes.FEATHER}
        size={35}
        color={Colors.secondary}
      />
      <div className='EditPinSuccess_text_area'>
        <Typography typeface='bigTitle'>
          {t('app:pages:debitCard:services:cardInfos:pinCodeChanged')}
        </Typography>
        <Typography typeface='info' style={{ marginTop: 20, marginBottom: 20 }}>
          {t('app:pages:debitCard:services:cardInfos:editPinInstructionPart1')}
        </Typography>
        <Typography typeface='info' style={{ marginBottom: 20 }}>
          {t('app:pages:debitCard:services:cardInfos:editPinInstructionPart2')}
        </Typography>
        <Button
          title={t('app:pages:debitCard:services:cardInfos:returnToServices')}
          onPress={onGoToServicesClick} skin={ButtonSkin.OUTLINE_PRIMARY}
          containerStyle={{ width: 200 }}
        />
      </div>
    </div>
  )
}
