import { RecoverPasscodeActions } from '@neo-commons/store'
import { IconTypes } from '@neo-commons/components'
import i18next from 'i18next'

import { invokeActionCreator, createWizardPolicy } from '../../utils'

const machineId = 'resetPasscode'

/*
* Guards
*/
const isCodeValid = (context) : boolean => {
  return context?.store.getState().recoverPasscode?.resetCode?.isCodeValid
}

const isReset = (context) : boolean => {
  return context?.store.getState().recoverPasscode?.data?.isReset
}

/*
* Stepper
*/
export enum ResetPasscodeStepperStep {
  Code = 'Code',
  NewCode = 'NewCode',
  NewCodeConfirm = 'NewCodeConfirm',
}

const stepperSteps = {
  [ResetPasscodeStepperStep.Code]: {
    title: () => i18next.t('neo-commons:profile:codeReset:actual:breadcrumb'),
    icon: { name: 'code', size: 30, type: IconTypes.NEOFONT },
  },
  [ResetPasscodeStepperStep.NewCode]: {
    title: () => i18next.t('neo-commons:profile:codeReset:newCode:breadcrumb'),
    icon: { name: 'code', size: 30, type: IconTypes.NEOFONT },
  },
  [ResetPasscodeStepperStep.NewCodeConfirm]: {
    title: () => i18next.t('neo-commons:profile:codeReset:confirmCode:breadcrumb'),
    icon: { name: 'code', size: 30, type: IconTypes.NEOFONT },
  },
}

/*
* Steps
*/
export enum ResetPasscodeStep {
  Code = 'Code',
  NewCode = 'NewCode',
  NewCodeConfirm = 'NewCodeConfirm',
  Success = 'Success',
}

export const resetPasscodeSteps = {
  [ResetPasscodeStep.Code]: {
    path: '',
    nextStep: ResetPasscodeStep.NewCode,
    skipIf: isCodeValid,
    fulfill: invokeActionCreator(RecoverPasscodeActions.prepare),
    stepper: {
      step: stepperSteps[ResetPasscodeStep.Code],
    },
    meta: {
      screenTitle: 'app:pages:myProfile:codeReset:screen:title',
    },
  },
  [ResetPasscodeStep.NewCode]: {
    path: 'code/new',
    nextStep: ResetPasscodeStep.NewCodeConfirm,
    fulfill: invokeActionCreator(RecoverPasscodeActions.prepare),
    stepper: {
      step: stepperSteps[ResetPasscodeStep.NewCode],
    },
    meta: {
      screenTitle: 'app:pages:myProfile:codeReset:screen:title',
    },
  },
  [ResetPasscodeStep.NewCodeConfirm]: {
    path: 'code/confirm',
    nextStep: ResetPasscodeStep.Success,
    skipIf: isReset,
    fulfill: invokeActionCreator(RecoverPasscodeActions.changePassword),
    stepper: {
      step: stepperSteps[ResetPasscodeStep.NewCodeConfirm],
    },
    meta: {
      screenTitle: 'app:pages:myProfile:codeReset:screen:title',
    },
  },
  [ResetPasscodeStep.Success]: {
    path: 'result',
    nextStep: 'final',
    stepper: {
      step: 'final',
    },
    history: {
      preventStack: true,
      resetStack: true,
    },
    meta: {
      screenTitle: 'app:pages:myProfile:codeReset:screen:title',
    },
  },
}

export type ResetPasscodeSteps = typeof resetPasscodeSteps

/*
* Policy initialization
*/
export const ResetPasscodePolicy = createWizardPolicy({
  machineId: machineId,
  steps: resetPasscodeSteps,
  stepperSteps,
  initialStep: ResetPasscodeStep.Code,
  basePath: '/profile/preference/code',
})
