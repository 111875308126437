import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'

import dayjs from 'dayjs'
import i18next from 'i18next'
import { Routes } from 'Routes/Routes'

import {
  Button,
  ButtonSkin,
  MenuItemCard,
  ToggleCard,
  ToggleCardSkin,
  Typography,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { BankAccountActions, BankAccountSelectors, ContractSelectors, SubscriptionSelectors } from '@neo-commons/store'
import { SubscriptionUtils } from '@neo-commons/libraries'

import {
  AccountDto,
  AccountStatusDto,
  KycPageDto,
  OfferTypeDto,
  SubscriptionDto,
  SubscriptionStatusDto,
} from '@afone/neo-core-client/dist/models'

import { useProfileDocumentPage } from '@neo-web/hooks'
import { Modal } from '@neo-web/componentsOld'

import { StateTypes } from '../../../../../Store'
import { DateFormat, resources } from '../../../../../I18n'

import { DocumentType } from './InvoiceAndContractDetail'

import './Contracts.scss'

export const Contracts: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const dateFormat: DateFormat = resources[i18next.language].formats

  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const bankAccountSubscription =
    useSelector((state: StateTypes) => state.subscription.data[selectedBankAccount?.subscriptionUuid])
  const contracts: KycPageDto[] = useSelector(ContractSelectors.list)
  const filteredSubAccounts: AccountDto[] =
    useSelector(BankAccountSelectors.getSubAccountsByUuid(selectedBankAccount?.uuid))
      .filter(el => el.status !== AccountStatusDto.TERMINATION_IN_PROGRESS)
  const subscriptions: SubscriptionDto[] = useSelector(SubscriptionSelectors.list)

  // Sort subscription by type of offer
  const subscriptionsByType: Map<OfferTypeDto, SubscriptionDto[]> = new Map()
  subscriptions.filter(sub => sub.offer.type !== OfferTypeDto.SOCLE).forEach((subscription) => {
    if (subscriptionsByType.get(subscription?.offer?.type) === undefined) {
      subscriptionsByType.set(subscription?.offer?.type, [])
    }
    subscriptionsByType.get(subscription?.offer?.type).push(subscription)
  })

  const accountClosure = selectedBankAccount?.accountClosure
  const date = accountClosure?.closureDate || bankAccountSubscription?.activationDate
  const subtitle = t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:' + (accountClosure
    ? 'closedOn'
    : 'signedOn'
  ))

  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionDto>()
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  useProfileDocumentPage(DocumentType.CONTRACT)

  return (
    <div className='Contracts_container'>
      <div className='Contracts_title'>
        <Typography typeface='subtitle' style={{ textTransform: 'uppercase' }}>
          {t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:generalConditions')}
        </Typography>
      </div>

      {contracts.map((contract) => (
        <MenuItemCard
          key={contract.documentUuid}
          title={bankAccountSubscription?.offer?.name ?? 'offer'}
          subtitle={`${subtitle} ${dayjs(date).format(dateFormat.fullDate)}`}
          onPress={() => {
            history.push(generatePath(
              Routes.Profile.documentDetail.path,
              { documentUuid: contract.uuid, type: DocumentType.CONTRACT }
            ))
          }}
          chevronPosition='center'
          chevronColor={Colors.darkGreyText}
          subtitleStyle={{ color: accountClosure ? Colors.error : Colors.black }}
        />
      ))}

      {subscriptions.filter(sub => sub.offer.type !== OfferTypeDto.SOCLE).length > 0 &&
        <div className='Contracts_title'>
          <Typography typeface='subtitle' style={{ textTransform: 'uppercase' }}>
            {t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:services')}
          </Typography>
        </div>}
      {
        Array.from(subscriptionsByType).map(([type, subscriptions]) => {
          if (subscriptions.find(subscription => subscription.status === SubscriptionStatusDto.ACTIVE)) {
            // If there is at least one active subscription, only show the active ones
            subscriptions = subscriptions.filter(subscription => subscription.status !== SubscriptionStatusDto.CANCELED)
          } else {
            // If there is NO active subscription, only show the LAST canceled one
            subscriptions = [subscriptions.reduce((a, b) =>
              new Date(a.statusDate) > new Date(b.statusDate) ? a : b)]
          }
          return subscriptions.map((subscription) => {
            return (
              <div className='Contracts_subList' key={subscription.uuid}>
                <ToggleCard
                  skin={ToggleCardSkin.DEFAULT}
                  disabled={subscription?.status === SubscriptionStatusDto.CANCELED}
                  value={subscription?.status !== SubscriptionStatusDto.CANCELED}
                  title={t(SubscriptionUtils.getSubscriptionTitle(type))}
                  subtitles={[subscription?.status === SubscriptionStatusDto.CANCELED
                    ? (t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:cancelledOn') + ' ' +
                      dayjs(subscription?.statusDate).format(dateFormat.fullDate))
                    : (t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:subscribeOn') + ' ' +
                      dayjs(subscription?.activationDate).format(dateFormat.fullDate) + ' ' +
                      (type === OfferTypeDto.AGGREGATE
                        ? t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:byDoubleAuthentification')
                        : '')),
                  ]}
                  onValueChange={() => {
                    setSelectedSubscription(subscription)
                    setShowConfirmModal(true)
                  }}
                />
              </div>
            )
          })
        })
      }
      {
        selectedBankAccount?.accountClosure
          ? null : (
            <div className='Contracts_button'>
              <Button
                flat
                skin={ButtonSkin.OUTLINE_RED}
                title={t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:closeMyAccount')}
                onPress={() => {
                  filteredSubAccounts.length > 0
                    ? history.push(Routes.Profile.impossibleToCloseAccount.path)
                    : history.push(Routes.Profile.ClosingAccount.index.path)
                }}
              />
            </div>)
      }
      <Modal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        displayCross
      >
        <div className='AggregationService_modalDiv'>
          <Typography style={{ textAlign: 'center' }} typeface='h4'>
            {t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:serviceTerminationModal:title')}
          </Typography>
          <Typography style={{ textAlign: 'center', marginBottom: 16 }} typeface='subtitle'>
            {t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:serviceTerminationModal:subtitle',
              { serviceToTerminate: t(`app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:serviceTerminationModal:serviceToTerminate:${selectedSubscription?.offer?.type}`) })}
          </Typography>
          <Button
            title={t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:serviceTerminationModal:cancelButton')}
            containerStyle={{ width: '100%' }}
            onPress={() => setShowConfirmModal(false)}
          />
          <Button
            containerStyle={{ width: '100%' }}
            skin={ButtonSkin.OUTLINE_PRIMARY}
            title={t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:serviceTerminationModal:confirmButton')}
            onPress={async () => {
              if (selectedSubscription?.offer?.type === OfferTypeDto.AGGREGATE) {
                await dispatch(BankAccountActions.disconnectAggregation(selectedSubscription.uuid))
                setShowConfirmModal(false)
              } else if (selectedSubscription?.offer?.type === OfferTypeDto.CARD) {
                history.push(Routes.Services.opposition.path)
              } else if (selectedSubscription?.offer?.type === OfferTypeDto.POS) {
                history.push(Routes.Services.closePosAccount.path)
              } else if (selectedSubscription?.offer?.type === OfferTypeDto.PROJECT) {
                history.push(Routes.Services.ProjectsRoutes.closeProjectList.path)
              }
              setSelectedSubscription(undefined)
            }}
          />
        </div>
      </Modal>
    </div>
  )
}
