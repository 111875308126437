const COLORS: { [key: string]: string } = {
  background: '#F6F5F3',
  backgroundDarker: '#F6F5F3',
  backgroundSecondaryTint: '#EBEFF2',
  transparent: 'rgba(0,0,0,0)',
  secondary: '#E1D3A8',
  secondaryDarker: '#D3C59A',
  secondaryLighter: '#f0ead4',
  primary: '#89ADF7',
  primaryLighter: '#ACC7E3',
  primaryGhost: '#ACC7E390',
  focus: '#FAE9DC',
  success: '#4AAB91',
  infoModal: '#8BDDBD',
  info: '#44D3FA',
  warning: '#ebcb68',
  error: '#EB6868',
  lightBeige: '#F7D8AA',
  darkBeige: '#D5B78B',
  gold: '#7B6011',
  lightGold: '#B59740',
  bgGray: '#F6F5F2',
  ghostGray: '#EFEFEF4F',
  ghostGray2: '#FFFFFF39',
  ghostBlack: '#00000050',
  ghostGrayLevel2: '#DDD',
  lightGray: '#8F908F',
  gray: '#e7e7e7',
  gray2: '#979797',
  gray3: '#AAAAAA',
  gray4: '#8E8E8E',
  darkGray: '#d2d2d2',
  black: '#131313',
  trueBlack: '#262626',
  white: '#FFFFFF',
  green: '#1A9E3D',
  greener: '#22AF74',
  red: '#EB6868',
  brightRed: '#F42020',
  ghostBeige: '#f6f4e8',
  darkGreyText: '#8D8D8D',
  lightGrayShadow: '#190000',
  lightBlue: '#8aafd7',
  lightGrayBorder: '#d8d8d8',
  ghostWhite: 'rgba(255, 255, 255, .8)',
  backDrop: 'rgba(19, 19, 19, 0.2)',
  coolGrey: '#EBEFF2',
  disable: '#D2D2D2',
  // New offers
  primaryLight: '#dbe6fc',
  primaryDark: '#326be5',
  greenLight: '#ccfce8',
  greenDark: '#a1c1b4',
  // New menuItemCard animation
  blueAnimation: '#dae8ff',
}

export default COLORS
