import { isMobile, isDesktop } from 'react-device-detect'

import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import { Callback } from 'i18next'
import { config, ENV } from 'Config/EnvConfig'
import { Monitoring } from 'Config/Monitoring'
import { v4 as uuidv4 } from 'uuid'

export const AnalyticsEvents = {
  general: {
    userLogin: 'User Signed In',
    userConsentGiven: 'User Consent Given for Marketing',
  },
  signIn: {
    forgotPassword: 'Forgot Password Started',
    forgotPasswordSuccess: 'Forgot Password Completed',
  },
  signUp: {
    chooseIbanLocation: 'IBAN Country Selected',
    chooseCustomerType: 'Customer Type Selected',
    subscriptionOptionSelected: 'Subscription Option Selected',
    subscriptionOptionConfirmed: 'Subscription Option Confirmed',
    userCountrySelected: 'User Country Selected',
    userSignUpStarted: 'User Sign Up Started',
    userSignUpCompleted: 'User Sign Up Completed',
    userSignUpStepCompleted: 'User Sign Up Step Completed',
    companyLegalStructureSelected: 'Company Legal Structure Selected',
    companyLegalIDProvided: 'Company Legal ID Provided',
    companyMemberListViewed: 'Company Member List Viewed',
    companyMemberAdded: 'Company Member Added',
    allCompanyMembersAdded: 'All Company Members Added',
    companyMemberNotified: 'Company Member Notified',
    allUserInfoSubmitted: 'All User Info Submitted',
    promoCodeApplied: 'Promo Code Applied',
  },
  eligibility: {
    bankAggregationProcessStarted: 'Bank Aggregation Process Started',
    bankAggregated: 'Bank Aggregated',
  },
  KYC: {
    kycFunnelStarted: 'KYC Funnel Started',
    eSignatureStarted: 'E-Signature Started',
    documentValidationEmailClicked: 'Document Validation Email Clicked',
  },
  Account: {
    bankAccountOpened: 'Bank Account Opened',
    bankAccountClosureRequested: 'Bank Account Closure Requested',
    subAccountCreated: 'Sub-Account Created',
    subAccountDeleted: 'Sub-Account Deleted',
  },
  miscellaneous: {
    forgotPasswordStarted: 'Forgot Password Started',
    forgotPasswordCompleted: 'Forgot Password Completed',
  },
}

const deviceCategory = isMobile
  ? 'Mobile'
  : (isDesktop ? 'Desktop' : 'Tablet')

const deviceOptions = {
  noelse_platform: 'WebSite',
  device_category: deviceCategory,
}

export class AnalyticsHandler {
  public analytics: Analytics
  public segment: AnalyticsBrowser
  public identifyPersistedTraits: any

  private static instance: AnalyticsHandler

  private constructor () {
    this.analytics = new Analytics(
      { writeKey: config(ENV.SEGMENT_WRITE_KEY) },
    )
    this.segment = Monitoring.initSegment()
    this.identifyPersistedTraits = {}
  }

  static createInstance (): AnalyticsHandler {
    if (!AnalyticsHandler.instance) {
      AnalyticsHandler.instance = new AnalyticsHandler()
    }
    return AnalyticsHandler.instance
  }

  /**
 * Send identify of user to analytics services
   * @param userId - id of the user
   * @param traits - traits you know about the user, like their email, name, etc, as an object
 */
  static identify (userId: string|any, traits?: any|Callback): void {
    if (this.instance?.segment) {
      if (traits) {
        this.instance.identifyPersistedTraits = {
          ...this.instance.analytics.user().traits(),
          ...traits,
        }
        this.instance.segment.identify(
          userId,
          this.instance.identifyPersistedTraits,
        )
      } else if (userId) {
        this.instance.segment.identify(
          userId,
          {},
        )
      }
    }
  }

  /**
   * Send event to analytics services
   * @param name - name of event
   * @param properties - customs properties of the event, as an object
   */
  static track (name: string, properties?: any|Callback): void {
    if (this.instance?.segment) {
      this.instance.segment.track(
        name,
        {
          ...properties,
          ...deviceOptions,
          order_id: uuidv4(),
        },
        {
          traits: this.instance.analytics.user().traits(),
        }
      )
    }
  }

  /**
   * Send page of user to analytics services
   */
  static page (name: string): void {
    if (this.instance?.segment) {
      this.instance.segment.page(
        undefined,
        name,
        deviceOptions,
        {
          traits: this.instance.analytics.user().traits(),
        },
      )
    }
  }

  /**
   * Get the current anonymous id
   */
  static getAnonymousId (): string {
    if (this.instance?.analytics) {
      return this.instance.analytics.user().anonymousId()
    }
  }

  /**
   * Reset the analytics service
   */
  static reset (): void {
    if (this.instance && this.instance.segment) {
      this.instance.segment.reset()
    }
    if (this.instance && this.instance.identifyPersistedTraits) {
      this.instance.identifyPersistedTraits = {}
    }
  }
}
