import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { RecoverPasscodeTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    RecoverPasscodeTypes.TRIGGER_PHONE_VERIFY_REQUEST,
    RecoverPasscodeTypes.TRIGGER_PHONE_VERIFY_FAILURE,
    RecoverPasscodeTypes.TRIGGER_PHONE_VERIFY_SUCCESS,
    RecoverPasscodeTypes.RENEW_PASSCODE_FAILURE,
    RecoverPasscodeTypes.RENEW_PASSCODE_REQUEST,
    RecoverPasscodeTypes.RENEW_PASSCODE_SUCCESS,
    RecoverPasscodeTypes.CHANGE_PASSCODE_REQUEST,
    RecoverPasscodeTypes.CHANGE_PASSCODE_SUCCESS,
    RecoverPasscodeTypes.CHANGE_PASSCODE_FAILURE,
    RecoverPasscodeTypes.CHANGE_PASSCODE_CANCELLED,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    RecoverPasscodeTypes.RENEW_PASSCODE_FAILURE,
    RecoverPasscodeTypes.TRIGGER_PHONE_VERIFY_FAILURE,
    RecoverPasscodeTypes.CHANGE_PASSCODE_FAILURE,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
]
