import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { RecoverPasswordStep, RecoverPasswordSteps, StepComponentProps } from '@neo-commons/policies'

import { SuccessContent } from '@neo-web/componentsOld'

type ValidateProps = StepComponentProps<RecoverPasswordSteps[RecoverPasswordStep.Validate]>

export const Validate: React.FC<ValidateProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  useEffect(() => {
    AnalyticsHandler.track(AnalyticsEvents.miscellaneous.forgotPasswordCompleted)
  }, [])

  return (
    <SuccessContent
      title={t('app:pages:recoverPassword:step4:validate:titleMessage')}
      buttonAction={{
        text: t('app:pages:recoverPassword:step4:validate:connect'),
        action: nextStep,
      }}
    />
  )
}
