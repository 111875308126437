import React from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  Typography,
} from '@neo-commons/components'
import { CardActions, CardSelectors, CardTypes, CreditCardData } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { PageDetails, LazyLoadingContainer } from '@neo-web/componentsOld'

import { Routes } from '../../../../../../../Routes/Routes'

import './ActivateCardChoice.scss'

export const ActivateCardChoice: React.FC = () => {
  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const activateCard = async () => {
    try {
      await dispatch(CardActions.activate(selectedCard.uuid))
      history.push(Routes.Services.activationSuccess.path)
    } catch (e) {
      console.warn('failed to activate card')
    }
  }

  return (
    <>
      <PageDetails
        title={t('app:pages:services:debitCard:activation:activateACard')}
        component={
          <LazyLoadingContainer events={[CardTypes.ACTIVATE_CARD_REQUEST]}>
            <div className='ActivateCardChoice_container'>
              <Icon type={IconTypes.NEOFONT} name='active_card' size={64} color={Colors.secondaryDarker} />
              <Typography typeface='bigTitle'>
                {t('app:pages:services:debitCard:activation:activateYourCardOnlyWhenReceived')}
              </Typography>
              <Typography typeface='bigTitleLight'>
                {t('app:pages:services:debitCard:activation:noelseWillNotTakeCharge')}
              </Typography>
              <div className='ActivateCardChoice_action'>
                <Button
                  skin={ButtonSkin.OUTLINE_PRIMARY}
                  title={t('app:pages:services:debitCard:activation:iWaiting')}
                  onPress={() => {
                    history.push(Routes.Services.index.path)
                  }}
                />
                <Button
                  skin={ButtonSkin.PRIMARY}
                  title={t('app:pages:services:debitCard:activation:iActivateMyCard')}
                  onPress={async () => {
                    await activateCard()
                  }}
                />
              </div>
            </div>
          </LazyLoadingContainer>
        }
      />
    </>
  )
}
