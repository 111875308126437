import React, { FunctionComponent } from 'react'
import { Text, TextStyle } from 'react-native'

import style from './styles'

interface RichTypographyProps {
  styles: TextStyle[],
  texts: string[],
  accessibilityLabels?: string[],
  containerStyle?: TextStyle
}

export const RichTypography: FunctionComponent<RichTypographyProps> = ({
  texts, styles,
  accessibilityLabels,
  containerStyle,
}) => {
  return (
    <Text style={containerStyle}>
      {texts.map((text, index) =>
        <Text
          key={text} style={{ ...styles[index], ...style.text }}
          accessibilityLabel={accessibilityLabels && accessibilityLabels[index]}
        >
          {`${text} `}
        </Text>
      )}
    </Text>
  )
}
