import React, { useEffect, useMemo } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { CardSelectors, PrepareCardOrder } from '@neo-commons/store'

import { Routes } from '../../../../../Routes/Routes'
import { OrderPolicy } from '../../../../../Policies'

import {
  Address, ChoicesDebitCard, SelectCardOwner, Code,
  DeliveryMode, Physical, PhysicalPromo, Summary, Validate,
} from './index'

/**
 * Main file of Order Ambassador debit Card, he contain global route of tunnel order
 *
 * Key lokalise app:pages:services:debitCard:order:NomDuComposant:NomDeLaTrad
 * @returns JSX.Element
 */
export const Order: React.FC = () => {
  const stepsContent: { path: string, content: React.ReactNode }[] = [
    {
      // Step 0
      path: Routes.Services.selectCardOwner.path,
      content: <SelectCardOwner />,
    },
    {
      // Step 1
      path: Routes.Services.choicesDebitCard.path,
      content: <ChoicesDebitCard />,
    },
    {
      // Step 1 bis
      path: Routes.Services.physical.path,
      content: <Physical />,
    },
    {
      // Step 2
      path: Routes.Services.physicalPromo.path,
      content: <PhysicalPromo />,
    },
    {
      // Step 3
      path: Routes.Services.code.path,
      content: <Code />,
    },
    {
      // Step 4
      path: Routes.Services.deliveryMode.path,
      content: <DeliveryMode />,
    },
    {
      // Step 5
      path: Routes.Services.address.path,
      content: <Address />,
    },
    {
      // Step 6
      path: Routes.Services.summary.path,
      content: <Summary />,
    },
    {
      // Step 7
      path: Routes.Services.validate.path,
      content: <Validate />,
    },
  ]
  const renderRoutes = useMemo(() => stepsContent.map((value, index) => {
    return (
      <Route
        key={index} exact path={value.path} component={() =>
          <>
            {value.content}
          </>}
      />
    )
  }), [])

  const history = useHistory()
  const preparedCardOrder: PrepareCardOrder = useSelector(CardSelectors.preparedCardOrder)

  useEffect(() => {
    const redirect = OrderPolicy.orderRedirect()
    if (redirect !== history.location.pathname) {
      history.push(redirect)
    }
  }, [preparedCardOrder])

  return (
    <>
      {renderRoutes}
    </>
  )
}
