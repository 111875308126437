import React, { useMemo } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { store } from 'Store'
import { Routes } from 'Routes/Routes'

import { ClientActions, SubscriptionActions } from '@neo-commons/store'
import { BusinessSubscriptionPolicy, BusinessSubscriptionStep } from '@neo-commons/policies'
import { useWizardPolicy } from '@neo-commons/hooks'

import { Tunnel } from '@neo-web/componentsOld'

import { IdentifyYourself } from '../Business/IdentifyYourself/IdentifyYourself'

import { Offers } from './Offers/Offers'
import { ElectronicSignature } from './ElectronicSignature/ElectronicSignature'
import { Kbis } from './Kbis/Kbis'
import { IdentifyAffiliates } from './IdentifyAffiliates/IdentifyAffiliates'
import { LegalStatus } from './LegalStatus/LegalStatus'
import { Receipts } from './Receipts/Receipts'
import { TaxDomiciliation } from './TaxDomiciliation/TaxDomiciliation'
import { DocumentsChoice } from './DocumentsChoice/DocumentsChoice'
import { VerifyAffiliatesDocuments } from './VerifyAffiliatesDocument/VerifyAffiliatesDocuments'
import { DocumentsInvalid } from './DocumentsInvalid/DocumentsInvalid'
import { KYCPedagogy } from './KYCPedagogy/KYCPedagogy'
import { IdentifyAffiliatesPedagogy } from './IdentifyAffiliatesPedagogy/IdentifyAffiliatesPedagogy'
import {
  VerifyAffiliatesDocumentsPedagogy,
} from './VerifyAffiliatesDocumentsPedagogy/VerifyAffiliatesDocumentsPedagogy'
import {
  ElectronicSignatureDocumentsChoice,
} from './ElectronicSignatureDocumentsChoice/ElectronicSignatureDocumentsChoice'
import { SubscriptionSuccess } from './SubscriptionSuccess/SubscriptionSuccess'
import { AffiliateDocumentsChoice } from './AffiliateDocumentsChoice/AffiliateDocumentsChoice'
import { PosOfferPedagogy } from './PosOfferPedagogy/PosOfferPedagogy'
import { TaxDomiciliationFallback } from './TaxDomiciliationFallback/TaxDomiciliationFallback'

export const Business: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentStep, steps: wizardSteps } = useWizardPolicy(store, BusinessSubscriptionPolicy, {
    [BusinessSubscriptionStep.Offers]: Offers,
    [BusinessSubscriptionStep.KYCPedagogy]: KYCPedagogy,
    [BusinessSubscriptionStep.PosPedagogy]: PosOfferPedagogy,
    [BusinessSubscriptionStep.TaxDomiciliation]: TaxDomiciliation,
    [BusinessSubscriptionStep.LegalStatus]: LegalStatus,
    [BusinessSubscriptionStep.Kbis]: Kbis,
    [BusinessSubscriptionStep.Receipts]: Receipts,
    [BusinessSubscriptionStep.IdentifyAffiliatesPedagogy]: IdentifyAffiliatesPedagogy,
    [BusinessSubscriptionStep.IdentifyAffiliates]: IdentifyAffiliates,
    [BusinessSubscriptionStep.IdentifyYourself]: IdentifyYourself,
    [BusinessSubscriptionStep.DocumentsChoice]: DocumentsChoice,
    [BusinessSubscriptionStep.VerifyAffiliatesDocumentsPedagogy]: VerifyAffiliatesDocumentsPedagogy,
    [BusinessSubscriptionStep.VerifyAffiliatesDocuments]: VerifyAffiliatesDocuments,
    [BusinessSubscriptionStep.ElectronicSignature]: ElectronicSignature,
    [BusinessSubscriptionStep.SubscriptionSuccess]: SubscriptionSuccess,
  },
  {
    onDone: async () => {
      history.replace(Routes.Origin.path)
    },
  })

  const wizardStepsPages = useMemo(() => {
    return wizardSteps.map((value, index) => {
      return (
        <Route
          key={index}
          exact
          path={value.path}
          component={value.component}
        />
      )
    })
  }, [])

  const onBack = () => {
    if (currentStep.key === BusinessSubscriptionStep.Offers) {
      dispatch(SubscriptionActions.reset())
      dispatch(ClientActions.reset())
      history.push(Routes.Subscription.subscriptionChoice.path)
    } else {
      history.goBack()
    }
  }

  return (
    <Tunnel
      overrideStyle={{ backgroundColor: '#fff' }}
      onBack={currentStep?.meta?.canGoBack ? onBack : undefined}
      component={
        <>
          {currentStep?.path &&
            <Redirect
              to={{
                pathname: currentStep?.path,
              }}
            />}
          {wizardStepsPages}

          <Route
            path={Routes.Subscription.taxDomiciliationNotEligibleBusiness.path}
            component={TaxDomiciliationFallback}
            exact
          />
          <Route
            path={Routes.Subscription.Business.affiliateDocumentsChoice.path}
            component={AffiliateDocumentsChoice}
            exact
          />
          <Route
            path={Routes.Subscription.documentsInvalidBusiness.path}
            component={DocumentsInvalid}
            exact
          />
          <Route
            path={Routes.Subscription.electronicSignatureDocumentsChoiceBusiness.path}
            component={ElectronicSignatureDocumentsChoice}
            exact
          />
        </>
      }
    />
  )
}
