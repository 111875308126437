import { SubscriptionDto } from '@afone/neo-core-client/dist/models'
import { SubscriptionUtils } from '@neo-commons/libraries'
import { ClientActions, SubscriptionActions, SubscriptionSelectors } from '@neo-commons/store'

import { createWizardPolicy, invokeActionCreator } from '../../utils'
const machineId = 'individualSubscription'

/*
 * Steps
 */
export enum IndividualSubscriptionStep {
  Offers = 'Offers',
  KYCPedagogy = 'KYCPedagogy',
  EuropeanTaxResident = 'EuropeanTaxResident',
  NotEuropeanTaxResident = 'NotEuropeanTaxResident',
  TaxResidence = 'TaxResidence',
  USPerson = 'USPerson',
  DocumentsChoice = 'DocumentsChoice',
  Address = 'Address',
  ElectronicSignature = 'ElectronicSignature',
  SubscriptionSuccess = 'SubscriptionSuccess'
}

/*
 * Guards
 */
const hasSubscribingSocleSubscription = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  return subscription !== undefined
}
const hasAnAddress = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  return SubscriptionUtils.isAddressOk(subscription)
}

const hasSentAllKycDocuments = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  return !SubscriptionUtils.hasKycDocumentsIncomplete(subscription) ||
    SubscriptionUtils.hasKycDocumentsInvalid(subscription)
}

const hasASignature = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  return SubscriptionUtils.isSignatureOk(subscription)
}

const hasAllKycDocuments = (context) => {
  const subscription: SubscriptionDto = SubscriptionSelectors.defaultOneSocleSubscribing(context?.store.getState())
  return SubscriptionUtils.areKycDocumentsOk(subscription) || hasASignature(context) // for sse if signature ariad is ok
}

const hasSignatureOrSignatureLink = (context) => {
  return hasASignature(context) || !!context?.store.getState().subscription?.signatureLink
}

export const individualSubscriptionSteps = {
  [IndividualSubscriptionStep.Offers]: {
    path: 'offers',
    fulfill: invokeActionCreator(SubscriptionActions.prepare),
    bypassIf: hasSubscribingSocleSubscription,
    nextStep: IndividualSubscriptionStep.KYCPedagogy,
    meta: {
      screenTitle: 'app:pages:subscription:global:offers:title',
      canGoBack: true,
    },
  },
  [IndividualSubscriptionStep.KYCPedagogy]: {
    path: 'kycPedagogy',
    bypassIf: hasSubscribingSocleSubscription,
    nextStep: IndividualSubscriptionStep.EuropeanTaxResident,
    meta: {
      screenTitle: 'app:pages:subscription:individual:kycPedagogy:title',
      canGoBack: true,
    },
  },
  [IndividualSubscriptionStep.EuropeanTaxResident]: {
    path: 'europeanTaxResident',
    bypassIf: hasSubscribingSocleSubscription,
    nextStep: IndividualSubscriptionStep.TaxResidence,
    meta: {
      screenTitle: 'app:pages:subscription:individual:europeanTaxResident:title',
      canGoBack: true,
    },
  },
  [IndividualSubscriptionStep.TaxResidence]: {
    path: 'taxResidence',
    fulfill: invokeActionCreator(ClientActions.prepare),
    bypassIf: hasSubscribingSocleSubscription,
    nextStep: IndividualSubscriptionStep.USPerson,
    meta: {
      screenTitle: 'app:pages:subscription:individual:taxResidence:title',
      canGoBack: true,
    },
  },
  [IndividualSubscriptionStep.USPerson]: {
    path: 'usPerson',
    fulfill: invokeActionCreator(SubscriptionActions.create),
    bypassIf: hasSubscribingSocleSubscription,
    nextStep: IndividualSubscriptionStep.DocumentsChoice,
    meta: {
      screenTitle: 'app:pages:subscription:individual:usPerson:title',
      canGoBack: true,
    },
  },
  [IndividualSubscriptionStep.DocumentsChoice]: {
    path: 'documentsChoice',
    nextStep: IndividualSubscriptionStep.Address,
    bypassIf: hasSentAllKycDocuments,
    meta: {
      screenTitle: 'app:pages:subscription:individual:documentChoice:title',
      canGoBack: true,
    },
  },
  [IndividualSubscriptionStep.Address]: {
    path: 'address',
    bypassIf: hasAnAddress,
    nextStep: IndividualSubscriptionStep.ElectronicSignature,
    history: {
      preventStack: false,
    },
    meta: {
      screenTitle: 'app:pages:subscription:individual:documentChoice:title',
      canGoBack: true,
    },
  },
  [IndividualSubscriptionStep.ElectronicSignature]: {
    path: 'electronicSignature',
    fulfill: invokeActionCreator(SubscriptionActions.list),
    bypassIf: hasASignature,
    nextStep: IndividualSubscriptionStep.SubscriptionSuccess,
    history: {
      preventStack: true,
    },
    meta: {
      screenTitle: 'app:pages:subscription:global:electronicSignature:title',
      canGoBack: false,
    },
  },
  [IndividualSubscriptionStep.SubscriptionSuccess]: {
    path: 'subscriptionSuccess',
    // nextStep: 'final',
    history: {
      preventStack: true,
    },
    meta: {
      screenTitle: 'app:pages:subscription:global:subscriptionSuccess:title',
      canGoBack: false,
    },
  },
}

export type IndividualSubscriptionSteps = typeof individualSubscriptionSteps

/*
 * Policy initialization
 */
export const IndividualSubscriptionPolicy = createWizardPolicy({
  machineId: machineId,
  steps: individualSubscriptionSteps,
  initialStep: IndividualSubscriptionStep.Offers,
  basePath: '/subscription/individual',
})
