import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { RecoverPasscodeActions } from '@neo-commons/store'

import { CardMenuItem, CardMenuList, JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

import './Security.scss'

export const Security: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const menuCards: CardMenuItem[] = [
    {
      title: t('app:pages:myProfile:security:security:editCode'),
      content: undefined,
      disabled: false,
      onPress: () => history.push(Routes.Profile.ResetPasscode.index.path),
    },
    {
      title: t('app:pages:myProfile:security:security:editSecretQuestion'),
      content: undefined,
      onPress: () => history.push(Routes.Profile.SecretQuestion.index.path),
      disabled: false,
    },
  ]

  useEffect(() => {
    dispatch(RecoverPasscodeActions.reset())
  }, [dispatch])

  return (
    <PageDetails
      component={
        <PageContent justifyContent={JustifyContent.START}>
          <CardMenuList
            menus={menuCards}
          />
        </PageContent>
      }
      title={t('app:pages:myProfile:security:detailsSecurity:title')}
      onBack={() => history.push(Routes.Profile.preference.path)}
    />
  )
}
