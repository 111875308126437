import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { SuccessFailure, SuccessFailureProps } from './SuccessFailure'

export const FailureContent: React.FC<SuccessFailureProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const contactServiceClient = () =>
    <Typography typeface='subtitle'>
      {t('app:pages:credit:failure:message:failed')}
      &nbsp;
      <a
        className='SuccessFailure_description_textLink'
        onClick={() => history.push(Routes.PublicLink.clientService.path)}
      >
        {t('app:pages:credit:failure:messageEnd:failed')}
      </a>
    </Typography>

  return (
    <SuccessFailure
      {...props}
      icon={props?.icon
        ? props.icon
        : <Icon color={Colors.secondary} name='alert-circle' size={40} type={IconTypes.FEATHER} />}
      title={props?.title ? props.title : t('app:global:screen:failure:title')}
      description={
        <div className='SuccessFailure_description'>
          {props?.description ? (
            typeof props.description === 'string'
              ? <Typography typeface='subtitle'>{props.description}</Typography>
              : props.description
          ) : contactServiceClient()}
        </div>
      }
    />
  )
}
