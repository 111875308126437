import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Icon, IconTypes, MenuItemCard } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { EnumUtils, SubscriptionUtils } from '@neo-commons/libraries'
import { DocumentUploadActions, SubscriptionSelectors } from '@neo-commons/store'

import { SubscriptionDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './AffiliateDocumentsChoice.scss'

export const AffiliateDocumentsChoice: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const params: any = useParams()

  const [isRedirect, setIsRedirect] = useState(false)
  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const kycDocumentsIncomplete = useMemo(() =>
    SubscriptionUtils.getKycAffiliateDocumentsIncomplete(
      subscription,
      params.personUuid
    ),
  [subscription])

  useEffect(() => {
    if (!kycDocumentsIncomplete.length) {
      setIsRedirect(true)
    }
    setIsRedirect(false)
  }, [kycDocumentsIncomplete])

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      icon={<Icon name='idcard-passport' type={IconTypes.NEOFONT} size={50} color={Colors.secondary} />}
      subtitle={t('app:pages:subscription:global:documentChoice:title')}
      description={t('app:pages:subscription:global:documentChoice:description')}
    >
      <div className='DocumentChoice_container'>
        {!isRedirect
          ? kycDocumentsIncomplete.map((document, index) => {
            return (
              <div className='DocumentChoice_container_item' key={index}>
                <MenuItemCard
                  title={t(EnumUtils.getKycTypeDtoKey(document.type))}
                  onPress={() => {
                    dispatch(DocumentUploadActions.prepare({
                      documentType: document.type,
                      name: t(EnumUtils.getKycTypeDtoKey(document.type)),
                    }, params.personUuid))
                    history.push(Routes.DocumentUpload.index.path)
                  }}
                />
              </div>
            )
          })
          : <Redirect to={Routes.Subscription.Business.verifyAffiliatesDocuments.path} />}
      </div>
    </TunnelContent>
  )
}
