import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ClientSelectors } from '@neo-commons/store'
import { Field, Typography2 } from '@neo-commons/components'

import { ClientDto } from '@afone/neo-core-client/models'

import { PageDetails } from '@neo-web/componentsOld'

import { EditModal } from '../../Components/EditModal'

import './AffiliateProfile.scss'

export const AffiliateProfile: React.FC = () => {
  const { t } = useTranslation()
  const param: {id: string} = useParams()

  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const affiliate = client?.holder?.affiliates.find((affiliate) => affiliate?.uuid === param.id)

  const [showEditModal, setShowEditModal] = useState(false)
  const [editModalTitle, setEditModalTitle] = useState('')
  const [editModalContent, setEditModalContent] = useState('')
  // for modal overflow
  const [editModalFooter, setEditModalFooter] = useState('')

  const openModalEdit = (title: string, content?, footer?) => {
    setEditModalTitle(title)
    setEditModalContent(content)
    setEditModalFooter(footer)
    setShowEditModal(true)
  }

  return (
    <PageDetails
      title={affiliate?.person?.fullName}
      component={
        <div className='AffiliateProfile'>
          <div className='AffiliateProfile_container'>
            <div className='AffiliateProfile_container_title'>
              <Typography2 typeface='overline'>
                {t('app:pages:myProfile:myInfo:myInformation')}
              </Typography2>
            </div>
            <div className='AffiliateProfile_container_section'>
              <div className='AffiliateProfile_container_section_field'>
                <Field
                  label={t('app:global:user:firstname')}
                  value={affiliate?.person?.firstName}
                />
              </div>
              <div className='AffiliateProfile_container_section_field'>
                <Field
                  label={t('app:global:user:lastname')}
                  value={affiliate?.person?.lastName}
                />
              </div>
              <div className='AffiliateProfile_container_section_field'>
                <Field
                  label={t('app:global:user:phoneNumber')}
                  value={affiliate?.person?.phone?.nationalFormat}
                  onEditPress={() => { openModalEdit(t('app:global:userEdit:editPhoneNumber')) }}
                />
              </div>
              <div className='AffiliateProfile_container_section_field'>
                <Field
                  label={t('app:global:user:email')}
                  value={affiliate?.person?.email}
                  onEditPress={() => { openModalEdit(t('app:global:userEdit:editEmail')) }}
                />
              </div>
            </div>
          </div>
          <EditModal
            title={editModalTitle} show={showEditModal}
            onClose={() => {
              setShowEditModal(false)
            }}
            content={editModalContent}
            footer={editModalFooter}
          />
        </div>
      }
    />
  )
}
