import React from 'react'
import { Route } from 'react-router-dom'

import { Routes } from '../../../Routes/Routes'

import { ClientService } from './ClientService/ClientService'
import { OurPartners } from './OurPartners/OurPartners'
import { LegalNotice } from './LegalNotice/LegalNotice'
import { PrivacyPolicy } from './PrivacyPolicy'
import { Manifest } from './Manifest'

export const PublicLink: React.FC = () => {
  return (
    <>
      <Route path={Routes.PublicLink.clientService.path} component={ClientService} />
      <Route path={Routes.PublicLink.recoverPassword.path} component={ClientService} />
      <Route path={Routes.PublicLink.ourPartners.path} component={OurPartners} />
      <Route path={Routes.PublicLink.legalNotice.path} component={LegalNotice} />
      <Route path={Routes.PublicLink.privacyPolicy.path} component={PrivacyPolicy} />
      <Route path={Routes.PublicLink.manifest.path} component={Manifest} />
    </>
  )
}
