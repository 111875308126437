import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { AddressTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    AddressTypes.UPDATE_ADDRESS_REQUEST,
    AddressTypes.UPDATE_ADDRESS_SUCCESS,
    AddressTypes.UPDATE_ADDRESS_FAILURE,
    AddressTypes.CREATE_OR_REPLACE_USER_ADDRESS_REQUEST,
    AddressTypes.CREATE_OR_REPLACE_USER_ADDRESS_SUCCESS,
    AddressTypes.CREATE_OR_REPLACE_USER_ADDRESS_FAILURE,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    AddressTypes.UPDATE_ADDRESS_SUCCESS,
    AddressTypes.UPDATE_ADDRESS_FAILURE,
    AddressTypes.CREATE_OR_REPLACE_USER_ADDRESS_SUCCESS,
    AddressTypes.CREATE_OR_REPLACE_USER_ADDRESS_FAILURE,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
]
