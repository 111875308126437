
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { FlexboxGrid } from 'rsuite'

import {
  Button,
  Input,
  ButtonSkin,
} from '@neo-commons/components'
import { ClientActions, UserTypes } from '@neo-commons/store'

import { ClientDto } from '@afone/neo-core-client/dist/models'

import { LazyLoadingContainer } from '@neo-web/componentsOld'

export interface TaxIdentificationNumberFieldProps {
  client: ClientDto
  onCloseModal: () => void
}

export const TaxIdentificationNumberField: React.FC<TaxIdentificationNumberFieldProps> = ({
  client,
  onCloseModal,
}: TaxIdentificationNumberFieldProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [taxIdentificationNumber, setTaxIdentificationNumber] = useState<string>(client?.holder?.taxIdentificationNumber ?? '')

  const updateClientTaxIdentificationNumber = () => {
    (async () => {
      try {
        await dispatch(
          ClientActions.updateHolder(client, {
            ...client?.holder,
            taxIdentificationNumber: taxIdentificationNumber,
          })
        )
        onCloseModal()
      } catch (error) {}
    })()
  }

  return (
    <LazyLoadingContainer events={[UserTypes.UPDATE_PERSON_REQUEST]}>
      <FlexboxGrid.Item colspan={24}>
        <Input
          label={t('app:pages:myProfile:informations:companyProfile:otherInfos:taxIdentificationNumber')}
          value={taxIdentificationNumber}
          onChangeText={(value) => setTaxIdentificationNumber(value)}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24} className='EditModal_saveButton'>
        <Button
          skin={ButtonSkin.PRIMARY}
          disabled={!taxIdentificationNumber}
          title={t('app:global:save')}
          onPress={() => { updateClientTaxIdentificationNumber() }}
        />
      </FlexboxGrid.Item>
    </LazyLoadingContainer>
  )
}
