import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Footer } from 'rsuite'
import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { Button, ButtonSkin } from '@neo-commons/components'

import './FooterDefault.scss'

export const FooterDefault: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const configState = useSelector((state: StateTypes) => state.config)

  return (
    <Footer style={{ display: 'flex', justifyContent: 'center' }}>
      <div className='rs-footer_link'>
        <Button
          title={t('app:footer:default:customerService')}
          skin={ButtonSkin.LINK}
          onPress={() => {
            history.push(Routes.PublicLink.clientService.path)
          }}
        />
      </div>
      <div className='rs-footer_link'>
        <Button
          title={t('app:footer:default:FAQ')}
          skin={ButtonSkin.LINK}
          onPress={() => {
            window.open(configState?.data?.currentConfig?.faq, '_blank')
          }}
        />
      </div>
    </Footer>
  )
}
