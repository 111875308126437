import { Platform, StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

export const styles = StyleSheet.create({
  formContainer: {
    width: '100%',
  },
  input: {
    marginBottom: 0,
  },
  row: {
    backgroundColor: Colors.white,
    padding: 0,
    flexDirection: 'column',
    minHeight: 0,
  },
  rowData: {
    backgroundColor: Colors.white,
    height: 46,
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    borderColor: Colors.gray,
    borderRightWidth: 0.5,
    borderLeftWidth: 0.5,
  },
  powered: {
    display: 'none',
  },
  poweredContainer: {
    minHeight: 0,
    maxHeight: 0.5,
    borderColor: Colors.gray,
    borderRightWidth: 0.5,
    borderLeftWidth: 0.5,
    marginBottom: 10,
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    borderTopWidth: 0,
  },
  separator: {
    width: '100%',
    height: 0.8,
    backgroundColor: Colors.gray,
    alignSelf: 'center',
    display: 'none',
  },
  rowText: {
    fontFamily: Typo.type.regular,
    fontSize: Typo.size.medium,
    color: Colors.black,
  },
  rowUnderline: {
    width: '100%',
    height: 0.8,
    backgroundColor: Colors.gray,
  },
  zipCodeContainer: {
    flex: 0,
    width: '100%',
    minHeight: 50,
    justifyContent: 'space-between',
    paddingTop: 12,
    ...Platform.select({
      ios: {
        position: 'relative',
        zIndex: 1,
      },
    }),
  },
  errorMessage: {
    color: Colors.error,
    fontFamily: Typo.type.regular,
  },
  dropDownContainer: {
    ...Platform.select({
      web: {
        marginBottom: 0,
      },
    }),
  },
  inputCityContainer: {
    paddingTop: 0,
    ...Platform.select({
      web: {
        marginBottom: 0,
      },
    }),
  },
  addressInput: {
    marginTop: 10,
  },
  additionalAddress: {
    ...Platform.select({
      web: {
        marginTop: 10,
      },
    }),
    marginTop: 10,
  },
  listView: {
    position: 'absolute',
    zIndex: 9,
    top: 57,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  webListView: {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
})
