import React, { FunctionComponent } from 'react'
import { View, Text, Image } from 'react-native'
import { useTranslation } from 'react-i18next'

import { Formatters, NumbersUtils, BankAccountUtils, EnumUtils } from '@neo-commons/libraries'
import { Card, CardSkin } from '@neo-commons/components'
import { AccountDto } from '@afone/neo-core-client/dist/models'

import { BankAccountCardSkin } from '../BankAccountCard'
import { useStylesheet } from '../../utils'
import { Icon, Typography } from '../..'

import styles from './style'

interface BankAccountCardSmallProps {
    bankAccount: Partial<AccountDto>,
    icon?: Image | typeof Icon,
    isSelected?: boolean,
    withoutIcon?: boolean,
    skin?: BankAccountCardSkin,
    onPress?: () => void,
}

export const BankAccountCardSmall: FunctionComponent<BankAccountCardSmallProps> = (
  {
    bankAccount,
    icon,
    isSelected,
    withoutIcon = false,
    skin = BankAccountCardSkin.DEFAULT,
    onPress,
  }
) => {
  const { t } = useTranslation()
  const s = useStylesheet(styles,
    {
      skin,
      props: {
        isSelected,
      },
    })

  const getAmountTypography = ({ amount, typoStyle }) => {
    if (amount === undefined) {
      return
    }
    return (
      <Typography
        typeface='bold'
        style={typoStyle}
      >
        {NumbersUtils.displayPriceForHuman(amount)}
      </Typography>)
  }

  return (
    <Card
      skin={CardSkin.TOUCHABLE}
      onPress={onPress}
      withShadow
      testID='bankAccountCardSmall'
      containerStyle={[s.cardContainer, { marginBottom: 8 }]}
    >
      <View style={s.container}>
        <View style={s.subContainer}>
          <View style={s.leftArea} accessibilityLabel='pictogramme'>
            {!withoutIcon && icon}
          </View>
          <View style={s.centerArea}>
            <Typography typeface='info' style={s.title}>
              {BankAccountUtils.getName(bankAccount as AccountDto)}
            </Typography>

            <View style={s.priceCenteredContainerStyle}>
              {bankAccount?.balance !== undefined
                ? getAmountTypography({ amount: bankAccount?.balance, typoStyle: s.priceTitleCentered })
                : null}
            </View>
            {BankAccountUtils.isMain(bankAccount as AccountDto)
              ? (
                <Text accessibilityLabel='subtitle' style={s.subtitle}>
                  {Formatters.formatIban(bankAccount?.iban ?? '', true)}
                </Text>
              )
              : (
                <Text accessibilityLabel='subtitle' style={s.subtitle}>
                  {bankAccount?.type ? t(EnumUtils.getAccountTypeDtoKey(bankAccount?.type)) : ''}
                </Text>
              )}
          </View>
          <View style={s.priceContainerStyle}>
            {getAmountTypography({ amount: bankAccount?.balance, typoStyle: s.priceTitle })}
          </View>
        </View>
      </View>
    </Card>
  )
}
