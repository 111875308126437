import i18next from 'i18next'
import { Subject, Observable } from 'rxjs'
import { debounceTime, buffer } from 'rxjs/operators'
import axios from 'axios'
import { filter, map, max } from 'lodash'

interface Config {
  projectId: string,
  token: string,
  takeScreenshot: () => Promise<string>,
}

export class LokaliseScreenshotter {
  private onTranslateSubject = new Subject<string>()
  private onTranslateDebounced$: Observable<string>
  private onTranslate$: Observable<string[]>
  private lokaliseKeys: { keyId: number, key: string, screenshotted: boolean }[]

  private static instance: LokaliseScreenshotter
  protected projectId: string
  protected token: string
  protected takeScreenshot: () => Promise<string>

  private constructor (config: Config) {
    this.projectId = config.projectId
    this.token = config.token
    this.takeScreenshot = config.takeScreenshot
  }

  public static createInstance (config: Config) {
    if (!LokaliseScreenshotter.instance) {
      LokaliseScreenshotter.instance = new LokaliseScreenshotter(config)
    }
    return LokaliseScreenshotter.instance
  }

  public async initialize () {
    /*
     * Load keys from Lokalise API
     * to retrieve key IDs & unScreenshotted keys
     */
    await this.loadLokaliseKeys()
    /*
     * Configure rxjs observables
     * to group screenshot and upload calls for the same screen
     */
    this.onTranslateDebounced$ = this.onTranslateSubject.pipe(
      debounceTime(300)
    )
    this.onTranslate$ = this.onTranslateSubject.pipe(
      buffer(this.onTranslateDebounced$)
    )

    /*
     * Tweak react-i18next to emit event each time a string is translated
     */
    this.hookI18nTranslate()

    /*
     * Upload screenshot to Lokalise each time unScreenshotted keys are translated
     */
    this.onTranslate$.subscribe((keys: string[]) => this.uploadScreenshot(keys))
  }

  private hookI18nTranslate () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18next._t = i18next.t
    i18next.t = (key: string, params: any) => {
      this.onTranslateSubject.next(key)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return i18next._t(key, params)
    }
  }

  private async loadLokaliseKeys () {
    const response = await axios.get(
      `https://api.lokalise.com/api2/projects/${this.projectId}/keys`,
      { params: { include_screenshots: 1, limit: 5000 }, headers: { 'x-api-token': this.token } }
    )

    this.lokaliseKeys = response.data.keys.map((lokaliseKey: any) => {
      const screenshotsCreatedAt = map(lokaliseKey.screenshots, 'created_at_timestamp')
      const lastScreenshotCreatedAt = max(screenshotsCreatedAt)
      const screenshotted =
        lokaliseKey.screenshots.length >= 3 &&
        (lastScreenshotCreatedAt > lokaliseKey.translations_modified_at_timestamp)

      return { keyId: lokaliseKey.key_id, key: lokaliseKey.key_name.web.replace(/::/g, ':'), screenshotted }
    })
  }

  private async uploadScreenshot (keys: string[]) {
    const toScreenshotKeys = filter(
      this.lokaliseKeys,
      lokaliseKey => !lokaliseKey.screenshotted && keys.includes(lokaliseKey.key)
    )

    if (!toScreenshotKeys.length) return
    const screenshot = await this.takeScreenshot()

    await axios.post(
      `https://api.lokalise.com/api2/projects/${this.projectId}/screenshots`,
      {
        screenshots: [{
          data: `data:image/jpeg;base64,${screenshot}`,
          ocr: false,
          key_ids: toScreenshotKeys.map(toScreenshotKey => toScreenshotKey.keyId),
        }],
      },
      { headers: { 'x-api-token': this.token } }
    )

    this.lokaliseKeys = this.lokaliseKeys.map(lokaliseKey => {
      if (toScreenshotKeys.includes(lokaliseKey)) { return { ...lokaliseKey, screenshotted: true } }
      return lokaliseKey
    })
  }
}
