import { LocalizeEnumDto } from '@afone/neo-core-client/dist/models'
import { AnyAction, Reducer } from 'redux'
import { createSelector } from 'reselect'
import { NeobankApi } from '@neo-commons/services'

import { Dispatch } from '../'

/* %%%%%%%%%%%%%%%%%% *\
    Types.
\* %%%%%%%%%%%%%%%%%% */

export interface UtilitiesState {
  data: {
    enums: LocalizeEnumDto[],
  }
  ui: {
    isLoading: boolean
  },
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types
\* %%%%%%%%%%%%%%%%%% */

const LIST_ENUMS_REQUEST = 'utilities/LIST_ENUMS_REQUEST'
const LIST_ENUMS_SUCCESS = 'utilities/LIST_ENUMS_SUCCESS'
const LIST_ENUMS_FAILURE = 'utilities/LIST_ENUMS_FAILURE'

export const UtilitiesTypes = {
  LIST_ENUMS_REQUEST,
  LIST_ENUMS_SUCCESS,
  LIST_ENUMS_FAILURE,
}

/* %%%%%%%%%%%%%%%%%% *\
    Types.
\* %%%%%%%%%%%%%%%%%% */

const utilitiesSelector = state => state.utilities
export const UtilitiesSelectors = {
  enums: createSelector(
    utilitiesSelector,
    resource => resource.data.enums
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators
\* %%%%%%%%%%%%%%%%%% */

const listEnums = function () {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LIST_ENUMS_REQUEST })
    try {
      const response = await NeobankApi.getInstance().utilitiesApi.getEnums()
      dispatch({ type: LIST_ENUMS_SUCCESS, data: response.data })
    } catch (e) {
      dispatch({ type: LIST_ENUMS_FAILURE })
    }
  }
}

export const UtilitiesActions = {
  listEnums,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

const initialState: UtilitiesState = {
  data: {
    enums: [],
  },
  ui: {
    isLoading: false,
  },
}

export const utilities: Reducer = (
  state: UtilitiesState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case LIST_ENUMS_REQUEST:
      return {
        ...state,
        ui: {
          isLoading: true,
        },
      }
    case LIST_ENUMS_FAILURE:
      return {
        ...state,
        ui: {
          isLoading: true,
        },
      }
    case LIST_ENUMS_SUCCESS:
      return {
        data: {
          enums: action.data,
        },
        ui: {
          isLoading: false,
        },
      }
    default:
      return state
  }
}
