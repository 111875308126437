import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'
import CustomIcon from 'UI/Components/CustomIcon'
import { FlexboxGrid } from 'rsuite'

import { Button, MenuItemCard, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import {
  ClientSelectors,
  Dispatch,
  DocumentUploadActions,
  SignInActions,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { EnumUtils, SubscriptionUtils } from '@neo-commons/libraries'

import { PersonAffiliationDto, StepKycItemDto, RefusalReasonDto } from '@afone/neo-core-client'

import { Modal } from '@neo-web/componentsOld'

export interface KYCModalProps {
  show: boolean,
  onClose?: () => void,
}
type StepKycItemWithAffiliate = StepKycItemDto & { affiliate?: PersonAffiliationDto }

export const KYCModal: React.FC<KYCModalProps> = (
  props: KYCModalProps
) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()

  // KYC Modal informations
  const client = useSelector(ClientSelectors.defaultOne)
  const includesRefusalReason = (reason) => client?.refusalReasons?.includes(reason)
  const mainSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  // const kycDocuments = useMemo(() => SubscriptionUtils.getKycDocuments(mainSubscription), [mainSubscription])
  const affiliates = useMemo(() => client?.holder?.affiliates, [client])
  const kycDocumentsWithAffiliates: StepKycItemWithAffiliate[] = useMemo(() => {
    const affiliateDocuments = []
    affiliates && affiliates.forEach((affiliate) => {
      if (SubscriptionUtils.hasKycAffiliateDocumentsInvalid(mainSubscription, affiliate.person.uuid)) {
        const docs = SubscriptionUtils
          .getKycAffiliateDocumentsInvalid(mainSubscription, affiliate?.person?.uuid)
        affiliateDocuments.push(...docs.map((doc) => {
          return {
            ...doc,
            affiliate: affiliate,
          }
        }))
      }
    })
    return SubscriptionUtils.getKycDocumentsInvalid(mainSubscription).concat(affiliateDocuments)
  }, [mainSubscription, affiliates])
  // Signature Modal informations
  const kycPending = SubscriptionUtils.isKycStepAnalysing(mainSubscription)

  const needToSign = !SubscriptionUtils.isSignatureOk(mainSubscription)
  const documentsRefused = SubscriptionUtils.shouldReuploadKycDocumentsInvalid(mainSubscription) ||
    SubscriptionUtils.shouldReUploadKycAffiliatesDocumentsInvalid(mainSubscription)
  const prospectRefused = kycPending && SubscriptionUtils.isCanceled(mainSubscription)

  const getTitle = () => {
    let title = ''
    if (documentsRefused) {
      // documents refused to reupload
      title = t('neo-commons:prospect:documentsRefused:reuploadTitle')
    } else if (prospectRefused) {
      // prospect refused
      title = t('neo-commons:prospect:documentsRefused:refusalTitle')
    } else if (needToSign) {
      // signature not done
      title = t('neo-commons:prospect:signYourContract')
    }
    return title
  }
  const getDescription = () => {
    let description = ''
    if (documentsRefused) {
      // documents refused to reupload
      description = t('neo-commons:prospect:documentsRefused:reuploadDescription')
    } else if (prospectRefused) {
      // prospect refused
      if (includesRefusalReason(RefusalReasonDto.US_PERSON)) {
        description = t('neo-commons:prospect:documentsRefused:usPersonDescription')
      } else {
        description = t('neo-commons:prospect:documentsRefused:refusalDescription')
      }
    } else if (needToSign) {
      // signature not done
      description = t('neo-commons:prospect:signYourContractDescription')
    }
    return description
  }
  const getButtons = () => {
    let buttons = (<></>)
    if (documentsRefused) {
      // documents refused to reupload
      buttons = (
        <>
          {kycDocumentsWithAffiliates?.map((document, index) =>
            <MenuItemCard
              key={index}
              containerStyle={{ borderWidth: 1, borderColor: '#AAA' }}
              title={
                (document?.affiliate ? document?.affiliate?.person?.fullName + ' - ' : '') +
                '' +
                t(EnumUtils.getKycTypeDtoKey(document.type))
              }
              // subtitle={document?.reason ? t(EnumUtils.getReasonDtoKey(document.reason)) : undefined}
              onPress={async () => {
                if (document?.affiliate) {
                  await dispatch(DocumentUploadActions.prepare({
                    name: t(EnumUtils.getKycTypeDtoKey(document.type)),
                    documentType: document.type,
                  }, document?.affiliate?.person?.uuid))
                } else {
                  await dispatch(DocumentUploadActions.prepare({
                    name: t(EnumUtils.getKycTypeDtoKey(document.type)),
                    documentType: document.type,
                  }))
                }

                await dispatch(DocumentUploadActions.setIsReUpload(true))
                history.push(Routes.DocumentUpload.index.path, { reUpload: true })
                props.onClose && props.onClose()
              }}
            />
          )}
        </>
      )
    } else if (prospectRefused) {
      // prospect refused
      buttons = (
        <Button
          title={t('neo-commons:prospect:documentsRefused:refusalButton')}
          onPress={async () => {
            await dispatch(SignInActions.reset())
            await dispatch(SignInActions.signOut())
            history.push(Routes.SignIn.index.path)
            props.onClose && props.onClose()
          }}
        />
      )
    } else if (needToSign) {
      // signature not done
      buttons = (
        <Button
          title={t('neo-commons:prospect:signContract')}
          onPress={() => {
            history.push(Routes.Subscription.index.path)
            props.onClose && props.onClose()
          }}
          leftIcon={<CustomIcon name='pen' size={25} color={Colors.white} />}
        />
      )
    }
    return buttons
  }

  return (
    <Modal autoFocus displayCross={false} open={props.show}>
      <FlexboxGrid justify='center' className='text-center InformationModal'>
        <FlexboxGrid.Item colspan={24} className='pb-s'>
          <Typography typeface='bigTitle'>{getTitle()}</Typography>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={22} className='pt-xs pb-xl text-center'>
          <Typography typeface='content'>
            <Trans>{getDescription()}</Trans>
          </Typography>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24} style={{ zIndex: 2 }}>
          {getButtons()}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Modal>
  )
}
