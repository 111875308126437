import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import {
  BankAccountSelectors,
  BankAccountTypes,
  PosActions,
  PosSelectors,
  PosTypes,
} from '@neo-commons/store'
import { PosByAccount, POSUtils } from '@neo-commons/libraries'
import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { AccountDto, PosDto } from '@afone/neo-core-client/dist/models'

import {
  CardDetails,
  CardDetailsItem,
  JustifyContent,
  PageContent,
  PageDetails,
  ShimmerList,
  ShimmerListType,
  SuspenseLoading,
} from '@neo-web/componentsOld'

import { PosByAccounts } from '../../Components/PosByAccounts/PosByAccounts'

import { ReassignPosModal } from './ReassignPosModal'

export const ReassignPos: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()

  const newPosIds = location.state as string[]
  const pos: PosDto[] = useSelector(PosSelectors.listPos())
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts())
  const [openModal, setOpenModal] = useState(false)
  const [selectedPos, setSelectedPos] = useState<PosDto>()

  const posByAccounts: PosByAccount = POSUtils.groupPosByAccounts(bankAccounts, pos)

  useEffect(() => {
    if (!pos.length) {
      (async () => {
        try {
          await dispatch(PosActions.list(bankAccounts))
        } catch (error) {}
      })()
    }
  }, [])

  const listItems: CardDetailsItem[] = [{
    icon: <Icon name='alert-circle' type={IconTypes.FEATHER} size={20} color={Colors.secondary} />,
    value: t('app:pages:services:pos:managePos:reassign:info'),
  }]

  return (
    <PageDetails
      title={t('app:pages:services:pos:managePos:reassign:title')}
      onBack={() => newPosIds ? history.replace(Routes.Services.pos.path) : history.goBack()}
      component={(
        <PageContent justifyContent={JustifyContent.START}>
          <CardDetails
            title={t('app:pages:synthesis:telecollections:detail:info:title')}
            list={listItems}
            justifyBetween
          />
          <br />
          <SuspenseLoading
            events={[
              PosTypes.LIST_POS_REQUEST,
              PosTypes.UPDATE_POS_REQUEST,
              BankAccountTypes.LIST_BANK_ACCOUNT_REQUEST,
              BankAccountTypes.CREATE_SUB_ACCOUNT_REQUEST,
            ]}
            fallback={<ShimmerList type={ShimmerListType.POS} fading />}
          >
            <PosByAccounts
              posList={posByAccounts}
              newPosIds={newPosIds}
              onPress={(pos) => {
                setSelectedPos(pos)
                setOpenModal(true)
              }}
            />
          </SuspenseLoading>
          <ReassignPosModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            bankAccounts={bankAccounts}
            pos={pos}
            selectedPos={selectedPos}
          />
        </PageContent>
      )}
    />
  )
}
