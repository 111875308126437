import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { StepComponentProps } from '@neo-commons/policies'
import { POSOrderStep, POSOrderSteps } from '@neo-commons/policies/src/POS/Order'
import { OfferSelectors, PosActions } from '@neo-commons/store'
import { POSUtils } from '@neo-commons/libraries'

import { OfferDto, OfferTypeDto } from '@afone/neo-core-client/dist/models'

import { FailureContent } from '@neo-web/componentsOld'

type POSOrderModelChoiceProps = StepComponentProps<POSOrderSteps[POSOrderStep.Failure]>

export const Failure: React.FC<POSOrderModelChoiceProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const offers: OfferDto[] = useSelector(OfferSelectors.listByType(OfferTypeDto.POS))

  const posReset = async () => {
    await dispatch(PosActions.reset())
    POSUtils.reinitProductQuantity(offers)
  }

  return (
    <FailureContent
      title={t('app:pages:credit:failure:title:failed')}
      buttonBack={{
        action: () => {
          (async () => {
            await posReset()
            history.push(Routes.Synthesis.index.path)
          })()
        },
      }}
      buttonAction={{
        action: () => {
          (async () => {
            await posReset()
            history.push(Routes.Services.pos.path)
          })()
        },
      }}
    />
  )
}
