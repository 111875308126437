
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { FlexboxGrid } from 'rsuite'

import {
  Button,
  Input,
  ButtonSkin,
} from '@neo-commons/components'
import { UserActions, UserTypes } from '@neo-commons/store'

import { UserDto } from '@afone/neo-core-client/dist/models'

import { LazyLoadingContainer } from '@neo-web/componentsOld'

export interface MyTaxIdNumberContentProps {
  user: UserDto
  onCloseModal: () => void
}

export const MyTaxIdNumberContent: React.FC<MyTaxIdNumberContentProps> = ({
  user,
  onCloseModal,
}: MyTaxIdNumberContentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [taxIdNumber, setTaxIdNumber] = useState<string>(user?.person?.taxIdentificationNumber ?? '')

  const updateClientTaxIdNumber = () => {
    (async () => {
      try {
        await dispatch(
          UserActions.updateUserPerson(user, {
            ...user?.person,
            taxIdentificationNumber: taxIdNumber,
          })
        )
        onCloseModal()
      } catch (error) {}
    })()
  }

  return (
    <LazyLoadingContainer events={[UserTypes.UPDATE_PERSON_REQUEST]}>
      <FlexboxGrid.Item colspan={24}>
        <Input
          label={t('app:global:user:input:taxIdentificationNumber')}
          value={taxIdNumber}
          onChangeText={(value) => setTaxIdNumber(value)}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24} className='EditModal_saveButton'>
        <Button
          skin={ButtonSkin.PRIMARY}
          disabled={!taxIdNumber}
          title={t('app:global:save')}
          onPress={() => { updateClientTaxIdNumber() }}
        />
      </FlexboxGrid.Item>
    </LazyLoadingContainer>
  )
}
