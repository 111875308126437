import React from 'react'

import { Typography, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { OfferDescription, OfferDescriptionItem } from '@neo-web/componentsOld'

import './DebitCardOffer.scss'

interface DebitCardOfferProps {
  title: string,
  descriptions: OfferDescriptionItem[],
  price?: string,
  image?: React.ReactNode,
  footer?: React.ReactNode,
  authorized?: string
}

export const DebitCardOffer: React.FC<DebitCardOfferProps> = (props: DebitCardOfferProps) => {
  return (
    <div className={`DebitCardOffer ${props.footer ? 'hasFooter' : ''}`}>
      {props.authorized &&
        <div className='DebitCardOffer_unavailable'>
          <div>
            <Typography typeface='content'>
              {props.authorized}
            </Typography>
            <br />
            <Icon
              color={Colors.secondary}
              name='lock'
              size={30}
              type={IconTypes.FEATHER}
            />
          </div>
        </div>}
      <div className={`DebitCardOffer_header ${props.image ? 'hasImage' : ''}`}>
        <Typography typeface='bigTitle'>{props.title}</Typography>
        {props.price &&
          <Typography typeface='content'>{props.price}</Typography>}
        {props.image &&
          <div className='DebitCardOffer_header-image'>
            {props.image}
          </div>}
      </div>
      <OfferDescription
        descriptions={props.descriptions}
        hasImage={Boolean(props.image)}
      />
      {props.footer &&
        <div className='DebitCardOffer_footer'>{props.footer}</div>}
    </div>
  )
}
