import { AnyAction, Reducer } from 'redux'

import { Dispatch } from '@neo-commons/store'

export enum IntroStepName {
  POS_ACCOUNT = 'POS_ACCOUNT',
  TELECOLLECTIONS = 'TELECOLLECTIONS',
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types
\* %%%%%%%%%%%%%%%%%% */

/* -------------- Types -------------- */
const INTROSTEP_HIDE_POS_ACCOUNT = 'introStep/INTROSTEP_HIDE_POS_ACCOUNT'
const INTROSTEP_HIDE_TELECOLLECTIONS = 'introStep/INTROSTEP_HIDE_TELECOLLECTIONS'

export const IntroStepTypes = {
  INTROSTEP_HIDE_POS_ACCOUNT,
  INTROSTEP_HIDE_TELECOLLECTIONS,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators
\* %%%%%%%%%%%%%%%%%% */

const hideIntroStep = function (introStepName: IntroStepName) {
  let actionType
  switch (introStepName) {
    case IntroStepName.POS_ACCOUNT:
      // NO MORE USED
      break
    case IntroStepName.TELECOLLECTIONS:
      actionType = INTROSTEP_HIDE_TELECOLLECTIONS
      break
  }
  return async (dispatch: Dispatch) => {
    dispatch({ type: actionType })
  }
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export const IntroStepActions = {
  hideIntroStep,
}

export interface IntroStepState {
  telecollections: boolean;
}

const initialState: IntroStepState = {
  telecollections: true,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const introStep: Reducer = (
  state: IntroStepState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case INTROSTEP_HIDE_POS_ACCOUNT:
      // NO MORE USED
      break

    case INTROSTEP_HIDE_TELECOLLECTIONS:
      return {
        ...state,
        telecollections: false,
      }

    default:
      return state
  }
}
