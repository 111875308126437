import { AnyAction, Reducer } from 'redux'
import { NeobankApi } from '@neo-commons/services'
import { KycPageDto, KycPageTypeDto, KycTypeDto } from '@afone/neo-core-client/dist/models'
import i18next from 'i18next'

import { initialResourceState } from '../utils'
import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Contract = KycPageDto & {
  id: number
}

const {
  resourceActionTypes: ContractActionTypes,
  resourceReducer: ContractResourceReducer,
  resourceAction: ContractAction,
  resourceSelector: ContractResourceSelector,
} = ResourceStateFactory<Contract, 'contract'>(state => state.contract, 'contract')

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

export const ContractTypes = {
  ...ContractActionTypes,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

export const ContractSelectors = {
  ...ContractResourceSelector,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */
const errorKeyTranslate = 'errors:internalTechnicalIssue'

const getContractsByPersonUuid = (personUuid: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: ContractTypes.LIST_CONTRACT_REQUEST })

    try {
      const payload = await NeobankApi.getInstance().personApi.getKycPagesByPersonAndByKycType(
        personUuid,
        KycTypeDto.SIGNATURE_ADVANCED,
        KycPageTypeDto.EULA,
      )

      // TODO: No ID return by BO so generate one by ourself
      const dataWithId = payload.data.map(contract => {
        return {
          ...contract,
          id: contract.uuid,
        }
      })

      dispatch({ type: ContractTypes.LIST_CONTRACT_SUCCESS, data: dataWithId })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({ type: ContractTypes.LIST_CONTRACT_FAILURE, errorMessage })
    }
  }
}

export const ContractActions = {
  ...ContractAction,
  getContractsByPersonUuid,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type ContractState = ResourceState<Contract>

const initialState: ContractState = {
  ...initialResourceState,
  list: {
    ...initialResourceState.list,
    filter: {
      userUuid: undefined,
      iban: undefined,
      name: undefined,
    },
  },
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const contract: Reducer = (state = initialState, action: AnyAction): ContractState => {
  switch (action.type) {
    default:
      return {
        ...ContractResourceReducer(state, action, {
          identifier: 'id',
          isPaginate: true,
          initialState,
        }),
      }
  }
}
