import {
  AdvanceDto,
  AdvanceDtoStatusEnum,
  OfferDto,
  OfferTypeDto,
  OrderDto,
  OrderStatusDto,
  ProductDto,
  SubscriptionDto,
  SubscriptionOfferDto,
} from '@afone/neo-core-client/dist/models'
import _, { filter } from 'lodash'

import { DeliveryModeDto, SubscriptionUtils } from '.'

/**
 * Utils for offer management
 */

export enum OrderType {
  ASC = 'ASC',
  DESC = 'DESC',
}
export class OfferUtils {
  /**
   *
   * Get Payment Cards images from a list of products
   * @param products - Array of ProductDto
   * @returns Object that contains all different images
   */
  public static getPaymentCardsImagesFromProducts = (products: ProductDto[]): any | null => {
    if (!products || products?.length <= 0) return null
    const filtered = filter(products, product => (product.data as any)?.images !== undefined)
    const result = {}
    filtered.map((value) => _.merge(result, (value.data as any).images))

    return _.isEmpty(result) ? null : result
  }

  /**
   * Get Payment Cards images from a card offer
   * @param offer - OfferDto
   * @returns Digital and plastic images
   */
  public static getPaymentCardsImagesFromOffer = (offer: OfferDto): { digital: string, plastic: string } => {
    const products = offer.products
    const productsWithImages = filter(products, product => (product.data as any)?.images !== undefined)
    return productsWithImages.reduce((acc, product) => {
      return {
        ...acc,
        ...(product?.data as any)?.images,
      }
    }, {})
  }

  /**
   * Order deliveryModes by amount/price
   * @param deliveryModes - Array of DeliveryModeDto
   * @param orderType - order type that should be used
   * @returns DeliveryModeDto[] return deliveryModes ordered base on orderType
   **/
  public static sortDeliveryModeByPrice = (
    deliveryModes: DeliveryModeDto[],
    orderType: OrderType
  ): DeliveryModeDto[] => {
    if (!deliveryModes) {
      return deliveryModes
    }
    return _.sortBy(
      deliveryModes,
      [(a) => { return a.prices![0].amount }],
      [orderType],
    )
  }

  /**
   * Know if offer is a bundle
   * @param product - ProductDto
   * @returns boolean returns wether the offer is a bundle or not
  */
  public static isBundle = (product: ProductDto): boolean => {
    return (product.data as any)?.images.length > 1
  }

  /**
   * Check if offer is type SOCLE
   */
  public static isSocle (offer: OfferDto): boolean {
    return offer.type === OfferTypeDto.SOCLE
  }

  /**
   * Check if offer is type AGGREGATE
   */
  public static isAggregate (offer: OfferDto): boolean {
    return offer.type === OfferTypeDto.AGGREGATE
  }

  /**
   * Check if offer is SOCLE INDIVIDUAL
   */
  public static isSocleIndividual (offer: OfferDto): boolean {
    return this.isSocle(offer) && offer.offerCode === 'NEO-OFR-INDIV-01'
  }

  /**
   * Check if offer is Ambassador
   */
  public static isAmbassador (offerCode: string): boolean {
    return ['NEO-OFR-CB-AMBASSADOR-CONSUMER-01', 'NEO-OFR-CB-AMBASSADOR-BUSINESS-01'].includes(offerCode)
  }

  public static getOffersPos (offers: OfferDto[]): OfferDto {
    return offers.filter(x => x.offerCode === 'NEO-OFR-POS-BUSINESS-01')[0]
  }

  public static getProductPosReturn (offers: OfferDto[]): ProductDto | undefined {
    return this.getOffersPos(offers).products?.filter(x => x.productCode === 'NEO-PRD-POS-RETURN')[0]
  }

  /**
   * Check if offer type is acquisition (POS)
   * @param offer - client offer
   * * @returns true if offer type is acquisition, false otherwise
   */
  public static isOfferAcquisition (offer: OfferDto) : boolean {
    return offer?.offerCode === 'NEO-OFR-CORPO-02'
  }

  /**
   * Check if offer type is "associate" (collaborateur)
   * @param offer - client offer
   * @returns boolean
   */
  public static isOfferCollab (offer: OfferDto | SubscriptionOfferDto) : boolean {
    return offer?.offerCode === 'NEO-OFR-INDIV-COLLAB-01'
  }

  // ADVANCE ***********************************************************************************************************

  /**
   * Check if offer type is "advance" (any amount)
   * @param offer - client offer
   * @returns boolean
   */
  public static isOfferAdvance (offer: OfferDto): boolean {
    return !!offer && !!offer.data && offer.data.hasOwnProperty('advance')
  }

  /**
   * Check if offer type is "advance" AND advance is active
   * @param offer - client offer
   * @param advances - client current advances
   * @returns boolean
   */
  public static isAdvanceActivated (offer: OfferDto, advances: AdvanceDto[]): boolean {
    return !!(this.isOfferAdvance(offer) &&
      advances && advances.length > 0 &&
      advances.find((advance) => advance.status === AdvanceDtoStatusEnum.ACTIVE))
  }

  /**
   * Check if client is still prospect and advance is ordered
   * @param subscription - client main subscription
   * @param advanceOrder - client order for advance
   * @returns boolean
   */
  public static isAdvanceOrdered (subscription: SubscriptionDto, advanceOrder: OrderDto): boolean {
    return !SubscriptionUtils.isActive(subscription) && !!advanceOrder
  }

  /**
   * Check if client is eligible to advance but advance is not active
   * @param offer - client offer
   * @param eligibilityOrder - client order for advance eligibility
   * @param advances - client current advances
   * @param advanceOrder - client order for advance
   * @returns boolean
   */
  public static isAdvanceEligibleNotActive (
    offer: OfferDto,
    eligibilityOrder: OrderDto,
    advances: AdvanceDto[],
    advanceOrder: OrderDto
  ): boolean {
    return !!((this.isOfferAdvance(offer) || !offer) &&
      (eligibilityOrder && eligibilityOrder.analysis && eligibilityOrder.analysis.eligibility) &&
      (!OfferUtils.isAdvanceActivated(offer, advances) && advanceOrder?.status !== OrderStatusDto.PENDING))
  }

  /**
   * Get current active advance
   * @param advances - client current advances
   * @returns boolean
   */
  public static getActiveAdvance (advances?: AdvanceDto[]): AdvanceDto | undefined {
    return advances && advances.find((advance) => advance.status === AdvanceDtoStatusEnum.ACTIVE)
  }
}
