import React from 'react'
import { View, Text, ViewStyle, Platform } from 'react-native'

import styles from './styles'

type ReadOnlyInputProps = {
  label?: string,
  value: string,
  rightIcon?: React.ReactNode,
  leftIcon?: React.ReactNode,
  style?: ViewStyle,
}

export const ReadOnlyInput = ({
  label,
  value,
  rightIcon,
  leftIcon,
  style,
}: ReadOnlyInputProps) => {
  return (

    <View style={[styles.container, style]}>
      <View style={{ flexDirection: 'row' }}>
        {leftIcon}
        <View style={(Platform.OS === 'web' || !rightIcon) ? { minWidth: '70%' } : { minWidth: '70%', maxWidth: '95%' }}>
          {label && <Text style={styles.label}>{label}</Text>}
          <Text style={styles.value}>{value}</Text>
        </View>
      </View>
      {rightIcon}
    </View>

  )
}
