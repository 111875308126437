import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { StepComponentProps, BusinessSubscriptionSteps, BusinessSubscriptionStep } from '@neo-commons/policies'
import { Button, ButtonSkin, Icon, IconTypes, ProfileCard, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import {
  ClientActions,
  ClientSelectors,
  ConfigActions,
  SubscriptionActions,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { ClientUtils, EnumUtils, SubscriptionUtils } from '@neo-commons/libraries'

import {
  ClientDto,
  PersonAffiliationDto,
  PersonAffiliationDtoPerson,
  SubscriptionDto,
} from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import { RegisterAffiliatesModal } from '../../Components'

import './VerifyAffiliatesDocuments.scss'

type VerifyAffiliatesDocumentsProps =
  StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.VerifyAffiliatesDocuments]>

export const VerifyAffiliatesDocuments: React.FC<VerifyAffiliatesDocumentsProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [isShowModal, setShowModal] = useState<boolean>(false)
  const [affiliateSelected, setAffiliateSelected] = useState<PersonAffiliationDtoPerson>()
  const [disabledRemindInvite, setDisabledRemindInvite] = useState<boolean>(false)

  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const legalsForms = useSelector((state: StateTypes) => state.config?.legalsForms)

  const affiliationTypes = useMemo(() => {
    return legalsForms?.find((legalForm) => legalForm.name === client?.holder?.legalForm?.name)?.affiliationTypes
  }, [legalsForms, client])

  const affiliates = useMemo(() => client?.holder?.affiliates, [client])

  const getLegalForms = async () => {
    try {
      await dispatch(ConfigActions.listLegalFormsByCountry({
        countryCode: client?.holder?.taxDomiciliationCountryCode,
      }))
    } catch (err) {}
  }

  useEffect(() => {
    AnalyticsHandler.track(AnalyticsEvents.signUp.companyMemberListViewed)
    if (!legalsForms?.length) {
      getLegalForms()
    }
    setShowModal(!affiliates?.length)
  }, [])

  useEffect(() => {
    const allDocumentsSent = affiliates?.reduce((acc, affiliate) => {
      return acc &&
        !SubscriptionUtils.hasKycAffiliateDocumentsIncomplete(subscription, affiliate?.person?.uuid)
    }, true)
    if (allDocumentsSent) {
      nextStep()
    }
  }, [subscription, affiliates])

  const documentsVerified = (personUuid: string) => {
    return !SubscriptionUtils.getKycAffiliateDocumentsIncomplete(subscription, personUuid).length
  }

  const createOrUpdateAffiliate = async (affiliate: PersonAffiliationDto) => {
    try {
      if (affiliate.uuid) {
        await dispatch(ClientActions.updateAffiliate(client, affiliate))
      } else {
        await dispatch(ClientActions.createAffiliate(client, affiliate))
      }
      setShowModal(false)
      setAffiliateSelected(undefined)
    } catch (e) {}
  }

  const deleteAffiliate = async (affiliate: PersonAffiliationDto) => {
    try {
      await dispatch(ClientActions.deleteAffiliate(client, affiliate))
      setShowModal(false)
      setAffiliateSelected(undefined)
    } catch (e) {}
  }

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      scrollableBody
      header={
        <div className='VerifyAffiliatesDocuments_header'>
          <div className='VerifyAffiliatesDocuments_header_title'>
            <Typography2 typeface='h4'>
              {ClientUtils.isAssociation(client)
                ? t('app:pages:subscription:business:verifyAffiliates:titleAssociation')
                : t('app:pages:subscription:business:verifyAffiliates:titleCompany')}
            </Typography2>
          </div>
          <div className='VerifyAffiliatesDocuments_header_description'>
            <Icon name='info' type={IconTypes.FEATHER} size={25} color={Colors.secondary} />
            <Typography2 style={{ marginLeft: 24 }} typeface='body1'>
              {t('app:pages:subscription:business:verifyAffiliates:description')}
            </Typography2>
          </div>
        </div>
      }
    >
      <div className='VerifyAffiliatesDocuments_container'>
        {affiliates && affiliates.map((affiliate, index) => {
          return (
            <div
              key={index}
              className='VerifyAffiliatesDocuments_container_affiliate'
            >
              <ProfileCard
                title={affiliate?.person?.fullName}
                subTitle={t(EnumUtils.getAffiliationTypeDtoKey(affiliate?.types[0]))}
                editable={!documentsVerified(affiliate?.person?.uuid)}
                onPress={() => {
                  setAffiliateSelected(affiliate)
                  setShowModal(true)
                }}
              >
                {documentsVerified(affiliate?.person?.uuid)
                  ? (
                    <Button
                      title={t('app:pages:subscription:business:verifyAffiliates:documentReceived')}
                      skin={ButtonSkin.DEFAULT}
                      rightIcon={
                        <Icon type={IconTypes.MATERIAL} name='check-circle' color={Colors.primary} size={24} />
                      }
                      containerStyle={{ marginBottom: 16 }}
                    />
                  )
                  : (
                    <>
                      <Button
                        title={t('app:pages:subscription:business:verifyAffiliates:resendInvite')}
                        skin={ButtonSkin.DEFAULT}
                        containerStyle={{ marginBottom: 16 }}
                        onPress={async () => {
                          await dispatch(SubscriptionActions.remindInvitation(subscription, affiliate))
                          setDisabledRemindInvite(true)
                        }}
                        disabled={disabledRemindInvite}
                      />
                      <Button
                        title={t('app:pages:subscription:business:verifyAffiliates:verifyIdentityNow')}
                        skin={ButtonSkin.PRIMARY}
                        onPress={() => {
                          AnalyticsHandler.track(AnalyticsEvents.signUp.companyMemberNotified)
                          history.push(generatePath(
                            Routes.Subscription.Business.affiliateDocumentsChoice.path,
                            { personUuid: affiliate?.person?.uuid })
                          )
                        }}
                      />
                    </>
                  )}
              </ProfileCard>
            </div>
          )
        }
        )}
      </div>
      <RegisterAffiliatesModal
        affiliate={affiliateSelected}
        affiliationTypes={affiliationTypes}
        onSaveAffiliate={createOrUpdateAffiliate}
        onDeleteAffiliate={deleteAffiliate}
        show={isShowModal}
        onClose={() => setShowModal(false)}
      />
    </TunnelContent>
  )
}
