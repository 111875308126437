import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { FlexboxGrid } from 'rsuite'
import { UrlUtils } from 'Utils'
import { AppConfigTxt } from 'Config/AppConfig'

import {
  Button,
  ButtonSkin,
} from '@neo-commons/components'

import { Modal } from '@neo-web/componentsOld'

import { Routes } from '../../../../Routes/Routes'
import { StateTypes } from '../../../../Store'

import './ServiceClientModal.scss'

interface ServiceClientModalProps {
  show: boolean,
  onClose?: () => void
}

export const ServiceClientModal: React.FC<ServiceClientModalProps> = (
  props: ServiceClientModalProps
) => {
  const { t } = useTranslation()
  const userState = useSelector((state: StateTypes) => state.user.data)
  const [openModal, setOpenModal] = useState(false)
  const history = useHistory()

  useEffect(() => {
    setOpenModal(props.show)
  }, [props])

  return (
    <Modal onClose={props.onClose} autoFocus open={openModal}>
      <FlexboxGrid
        justify='space-between'
        align='middle'
        className='text-center'
      >
        <FlexboxGrid.Item className='ServiceClientModal_item' colspan={24}>
          <Button
            title={t('app:pages:myProfile:help:customerService:takeAppointment')}
            skin={ButtonSkin.OUTLINE_PRIMARY}
            onPressOut={() => {
              props.onClose()

              history.push(Routes.Profile.appointment.path)
            }}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item className='ServiceClientModal_item' colspan={24}>
          <Button
            title={t('app:pages:myProfile:help:customerService:call')}
            skin={ButtonSkin.OUTLINE_PRIMARY}
            onPressOut={() => {
              props.onClose()
              history.push(Routes.Profile.clientService.path)
            }}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item className='ServiceClientModal_item' colspan={24}>
          <Button
            title={t('app:pages:myProfile:help:customerService:sendEmail')}
            skin={ButtonSkin.PRIMARY}
            onPressOut={() => {
              props.onClose()
              UrlUtils.openMailApp(AppConfigTxt.MAIL_SERVICE_CLIENT,
                `${t('app:pages:myProfile:help:customerService:subject')} [${userState.uuid}]`)
            }}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Modal>
  )
}
