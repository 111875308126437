import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { StateTypes } from 'Store'
import {
  DocumentUploadStep,
  DocumentUploadSteps,
} from 'Policies'

import {
  StepComponentProps,
} from '@neo-commons/policies'
import {
  DocumentPage,
  DocumentUploadActions,
  DocumentUploadSelectors,
  DocumentUploadTypes,
} from '@neo-commons/store'
import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  MenuItemCard,
  Stepper2,
  Typography,
  Typography2,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { EnumUtils } from '@neo-commons/libraries'

import { KycTypeDto } from '@afone/neo-core-client/dist/models'
import './DocumentUploadSummary.scss'

import {
  DeleteDocumentPageModal,
  TunnelContent,
  TunnelJustifyContent,
} from '@neo-web/componentsOld'

import { DocumentPedagogy } from '../Components'

type DocumentUploadSummaryProps = StepComponentProps<DocumentUploadSteps[DocumentUploadStep.summary]>
export const DocumentUploadSummary: React.FC<DocumentUploadSummaryProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const SIZE_MAX = useSelector((state: StateTypes) => state?.config?.data?.currentConfig?.uploadSizeLimit)
  const pages: DocumentPage[] = useSelector(DocumentUploadSelectors.pages)
  const [selectedDocument, setSelectedDocument] = useState<DocumentPage>()
  const dispatch = useDispatch()
  const documentInputRef = useRef(null)
  const focusDocumentInput = () => {
    documentInputRef.current && documentInputRef.current.click()
  }
  const [pdfSelectedPagesNumber, setSelectedPdfPagesNumber] = useState()
  const documentUploadName = useSelector((state: StateTypes) => state?.documentUpload?.data?.document?.name)

  // requiredPageNumber is 2 for recto & verso (can change in the future)
  const requiredPageNumber = 2
  const documentNameException = [KycTypeDto.PASSPORT, KycTypeDto.ID, KycTypeDto.RESIDENCE_PERMIT].map(
    type => t(EnumUtils.getKycTypeDtoKey(type))
  )

  const [isShowModalDeleteDocumentPage, setIsShowModalDeleteDocumentPage] = useState(false)

  const displayBtnAddPage = (pages): boolean => {
    return !documentNameException.includes(documentUploadName) && pages.length > 0 && !pages.find((elt) => elt.data.size >= SIZE_MAX)
  }

  const shouldDisplayAddVersoBtn = (pages): boolean => {
    return documentNameException.includes(documentUploadName) &&
      pages?.length < requiredPageNumber &&
      !pages.find((elt) => elt.data.size >= SIZE_MAX) &&
      pages?.length > 0
  }

  const shouldActivateDropZone = (pages): boolean => {
    return ((pages?.length < requiredPageNumber &&
      documentNameException.includes(documentUploadName)) ||
        !(documentNameException.includes(documentUploadName)))
  }

  const onDrop = useCallback((acceptedFiles) => {
    if (shouldActivateDropZone(pages)) {
      dispatch(DocumentUploadActions.addDocumentPage(
        {
          previewUri: URL.createObjectURL(acceptedFiles[0]),
          data: acceptedFiles[0],
        },
        acceptedFiles[0]?.size,
        SIZE_MAX,
      ))
    }
  }, [pages])

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
    },
    disabled: false,
    maxFiles: 1,
    onDrop,
  })

  useEffect(() => {
    if (pages.length > 0) {
      setSelectedDocument(pages[pages.length - 1])
    }
  }, [pages])

  const getContent = () => {
    if (!pages.length) {
      return (
        <div className='EmptyDocumentContainer'>
          <div className='EmptyDocumentContainerContent'>
            <Icon
              name='download'
              size={64}
              type={IconTypes.FEATHER}
              color={Colors.darkGreyText}
            />
            <Typography2 typeface='infoMessage' style={{ color: Colors.darkGray }}>
              {t('app:pages:documentUpload:summary:dragDocument')}
            </Typography2>
          </div>
          <Button
            title={t('app:pages:documentUpload:summary:chooseFile')}
            skin={ButtonSkin.OUTLINE_PRIMARY}
            onPress={focusDocumentInput}
            overrideStyleButton={{ borderWidth: 3, zIndex: 999 }}
          />
        </div>
      )
    }

    if (!selectedDocument) {
      return
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
      setSelectedPdfPagesNumber(numPages)
    }

    return (
      <>
        <div className='DocumentUploadSummary_container_preview'>
          {(selectedDocument.data.type === 'application/pdf') ? (
            <div className='DocumentUploadSummary_container_preview_item'>
              <Document file={selectedDocument.previewUri} onLoadSuccess={onDocumentLoadSuccess}>
                {/* eslint-disable-next-line prefer-spread */}
                {Array.apply(null, Array(pdfSelectedPagesNumber ?? 1))
                  .map((x, i) => i + 1).map((number) => {
                    return <Page key={number} pageNumber={number} />
                  })}
              </Document>
            </div>
          )
            : (
              <Image
                source={{ uri: selectedDocument.previewUri }}
                style={{ width: '100%', resizeMode: 'contain', aspectRatio: 1 }}
              />)}
        </div>
        {(pages.length > 0 && selectedDocument) && (
          <div className='DocumentUploadSummary_container_smallPreviews'>
            {pages.map((document, index) => {
              return (
                <div key={index} style={{ position: 'relative' }}>
                  <div
                    className='DocumentUploadSummary_container_smallPreviews_closeButton'
                    onClick={() => {
                      setSelectedDocument(document)
                      setIsShowModalDeleteDocumentPage(true)
                    }}
                  >
                    <div style={{ backgroundColor: Colors.black, borderRadius: 50, paddingRight: 4, paddingLeft: 4 }}>
                      <Icon name='close' size={12} type={IconTypes.MATERIAL} color={Colors.white} />
                    </div>
                  </div>
                  <div
                    key={index}
                    className='DocumentUploadSummary_container_smallPreviews_item'
                    onClick={() => setSelectedDocument(document)}
                    style={{
                      borderColor: selectedDocument.generatedUuid === document.generatedUuid ? Colors.primary : Colors.gray,
                    }}
                  >
                    {(document.data.type === 'application/pdf') ? (
                      <div style={{ width: '100%', height: 45, overflow: 'hidden', borderRadius: 5 }}>
                        <Document file={document.previewUri}>
                          <Page pageNumber={1} scale={0.5} />
                        </Document>
                      </div>
                    ) : (
                      <Image
                        source={{ uri: document.previewUri }}
                        style={{ width: '100%', height: 36 }}
                        onError={async () => {
                          await dispatch({ type: DocumentUploadTypes.RESET })
                          history.back()
                        }}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }

  const handleChangeImage = e => {
    [...e.target.files].forEach((file) => {
      dispatch(DocumentUploadActions.addDocumentPage(
        {
          previewUri: URL.createObjectURL(file),
          data: file,
        },
        file?.size,
        SIZE_MAX,
      ))
    }
    )
    documentInputRef.current.value = ''
  }

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.CENTER}
      header={
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
            {documentUploadName}
          </Typography2>
          <Stepper2 currentStep={1} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 30 }}>
            <Icon
              name='alert-circle'
              iconStyle={{ marginRight: 8 }}
              size={20} type={IconTypes.FEATHER}
              color={Colors.secondary}
            />
            <Typography2 typeface='body3' style={{ textAlign: 'left', lineHeight: 16 }}>
              {t('app:pages:documentUpload:summary:pedagogy')}
            </Typography2>
          </div>
          {!!pages.find((elt) => elt.data.size >= SIZE_MAX) &&
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 12, backgroundColor: 'red' }}>
              <Icon
                name='alert-circle'
                iconStyle={{ marginRight: 8 }}
                size={24} type={IconTypes.FEATHER}
                color={Colors.red}
              />
              <Typography typeface='content' style={{ marginTop: 2, color: Colors.red }}>
                {t('app:pages:documentUpload:summary:infoSize')}
              </Typography>
            </div>}
        </div>
      }
      footer={
        <>
          {displayBtnAddPage(pages) &&
            <MenuItemCard
              title={t('app:pages:documentUpload:summary:addPage')}
              extraIcon={
                <Icon
                  name='pluscircleo'
                  size={18}
                  type={IconTypes.ANT_DESIGN}
                  color={Colors.primary}
                />
              }
              onPress={focusDocumentInput}
              chevronPosition='center'
            />}
          <Button
            title={t(shouldDisplayAddVersoBtn(pages) ? 'app:pages:documentUpload:summary:addVerso' : 'app:pages:documentUpload:summary:send')}
            disabled={pages.length === 0 || !!pages.find((elt) => elt.data.size >= SIZE_MAX)}
            onPress={shouldDisplayAddVersoBtn(pages) ? focusDocumentInput : nextStep}
          />
        </>
      }
    >
      <div className='DocumentUploadSummary_container'>
        <div className='DocumentUploadSummary_container_pedagogy'>
          <DocumentPedagogy type={documentUploadName} />
        </div>
        <div className='DocumentUploadSummary_container_dropZoneContainer'>
          <div className='DocumentUploadSummary_container_dropZoneContainer_dropZone' {...getRootProps()}>
            <input {...getInputProps()} />
            {getContent()}
          </div>
        </div>
        <input
          type='file'
          onChange={handleChangeImage}
          ref={documentInputRef}
          style={{ display: 'none' }}
          accept='image/png, image/jpeg, application/pdf'
        />
      </div>
      <DeleteDocumentPageModal
        title={t('app:pages:documentUpload:deleteDocumentPage:modal:title')}
        cancelText={t('app:pages:documentUpload:deleteDocumentPage:modal:cancel')}
        confirmText={t('app:pages:documentUpload:deleteDocumentPage:modal:validate')}
        show={isShowModalDeleteDocumentPage}
        onConfirm={() => {
          dispatch(DocumentUploadActions.removeDocumentPage(selectedDocument))
          setIsShowModalDeleteDocumentPage(false)
        }}
        onClose={() => setIsShowModalDeleteDocumentPage(false)}
      />
    </TunnelContent>)
}
