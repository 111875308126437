import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { SignInActions } from '@neo-commons/store'

import { TunnelContent } from '@neo-web/componentsOld'

export const TaxDomiciliationFallback: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <TunnelContent
      icon={<Icon name='alert-circle' type={IconTypes.FEATHER} size={40} color={Colors.secondary} />}
      subtitle={t('app:pages:subscription:business:notTaxDomiciliation:title')}
      description={t('app:pages:subscription:business:notTaxDomiciliation:description')}
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:backToHome')}
          onPress={async () => {
            await dispatch(SignInActions.signOut())
            history.push(Routes.SignIn.index.path)
          }}
        />
      }
    />
  )
}
