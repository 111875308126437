import React, { forwardRef, useCallback } from 'react'
import { Image, Pressable, View } from 'react-native'

import examples from 'libphonenumber-js/examples.mobile.json'
import { CountryCode, getExampleNumber } from 'libphonenumber-js'
import { InputRefHandle } from '@neo-commons/components'
import { PhoneNumberUtils, SyncValidatorFunction } from '@neo-commons/libraries'
import i18next from 'i18next'

import { Input2, Input2Props } from '../Input2/Input2'
import { Flags } from '../../assets/images'

import styles from './styles'

interface PhoneInput2Props extends Input2Props {
  isSubmitting?: boolean
  selectedCountry: {isoCodeAlpha2: string, isoCodeAlpha3: string}
  onPressPrefix: () => void
  testID?: string
  phoneRegex?: string
}

export const PhoneInput2Component = forwardRef<InputRefHandle, PhoneInput2Props>((props, phoneInputRef) => {
  const countryCode = props.selectedCountry.isoCodeAlpha2 as CountryCode
  const flag = Flags[props.selectedCountry.isoCodeAlpha3 ?? 'Unknown'] ?? Flags.Unknown

  const getPhoneValidator: SyncValidatorFunction = useCallback((text) => {
    const isValid = PhoneNumberUtils.isAllowForApp(text, countryCode)
    return {
      isValid: isValid,
      errorMessage: i18next.t('validation:badPhoneNumber'),
    }
  }, [props.selectedCountry.isoCodeAlpha2])

  return (
    <View style={styles.container}>
      <Pressable
        onPress={props.onPressPrefix}
        style={[styles.country,
          (!PhoneNumberUtils.isAllowForApp(props.value, countryCode) && props.isSubmitting) && styles.countryError]}
        disabled={!props.editable}
      >
        <Image style={styles.flags} source={flag} />
      </Pressable>
      <View style={styles.inputContainer}>
        <Input2
          {...props}
          numeric
          ref={phoneInputRef}
          testID={props.testID}
          label={i18next.t('neo-commons:register:phone')}
          hasBeenSubmitted={props.isSubmitting}
          onChange={props.onChange}
          onChangeText={props.onChangeText}
          validators={[getPhoneValidator]}
          placeholder={getExampleNumber(countryCode as CountryCode, examples)?.formatInternational()}
          format={{
            type: 'phone',
            additionalProps: {
              country: countryCode,
            },
          }}
          editable={props.editable}
        />
      </View>
    </View>
  )
})

export const PhoneInput2 = PhoneInput2Component
