import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { ClientUtils, DateUtils } from '@neo-commons/libraries'
import { BankAccountSelectors, ClientSelectors, State } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { ClosingAccountStep, ClosingAccountSteps, StepComponentProps } from '@neo-commons/policies'

import { CgusDto, ClientDto } from '@afone/neo-core-client/dist/models'

import { CardDetails, CardDetailsItem, JustifyContent, Modal, PageContent, PageDetails } from '@neo-web/componentsOld'

import './VerifyAccountToClose.scss'

type VerifyAccountToCloseProps = StepComponentProps<ClosingAccountSteps[ClosingAccountStep.VerifyAccountToClose]>

export const VerifyAccountToClose: React.FC<VerifyAccountToCloseProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const user = useSelector((state: StateTypes) => state.user.data)
  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const bankAccountSubscription = useSelector(
    (state: StateTypes) => state.subscription.data[selectedBankAccount?.subscriptionUuid])
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const urlCGU: CgusDto = useSelector((state: State) => state.config.data.currentConfig.cgus)

  const [showModal, setShowModal] = useState<boolean>(false)

  const accountToCloseData: CardDetailsItem[] = [
    {
      label: t('app:pages:myProfile:closingAccount:verifyAccountToClose:owner'),
      value: !user ? null : `${user?.person?.firstName ?? ''} ${user?.person?.lastName ?? ''}`,
    }, {
      label: t('app:pages:myProfile:closingAccount:verifyAccountToClose:iban'),
      value: selectedBankAccount?.iban ?? '',
    }, {
      label: t('app:pages:myProfile:closingAccount:verifyAccountToClose:activationDate'),
      value: DateUtils.Iso8601ToHumanDate(bankAccountSubscription?.activationDate),
    },
  ]

  const onNextClick = () => {
    nextStep()
  }

  return (
    <PageDetails
      title={t('app:pages:myProfile:closingAccount:title')}
      onBack={() => history.push(Routes.Profile.invoiceAndContractDetail.path)}
      component={
        <PageContent
          justifyContent={JustifyContent.BETWEEN}
          title={t('app:pages:myProfile:closingAccount:verifyAccountToClose:title')}
          footer={
            <Button
              title={t('app:pages:myProfile:closingAccount:verifyAccountToClose:button')}
              skin={ButtonSkin.PRIMARY}
              onPress={onNextClick}
            />
          }
        >
          <CardDetails list={accountToCloseData} />
          <Button
            title={t('app:pages:myProfile:closingAccount:verifyAccountToClose:informations')}
            skin={ButtonSkin.DEFAULT_LIGHT}
            rightIcon={<Icon name='info' type={IconTypes.FEATHER} size={20} color={Colors.primary} />}
            overrideTextStyleButton={{ justifyContent: 'space-between' }}
            containerStyle={{ marginTop: 20 }}
            onPress={() => setShowModal(true)}
          />
          <Modal
            open={showModal}
            title={t('app:pages:myProfile:closingAccount:verifyAccountToClose:modal:title')}
            onClose={() => setShowModal(false)}
            displayCross
            size='md'
          >
            <div className='VerifyAccountToClose_modal_content'>
              <Typography typeface='content'>
                {t('app:pages:myProfile:closingAccount:verifyAccountToClose:modal:content')}
              </Typography>
              <Button
                skin={ButtonSkin.LINK}
                title={t('app:pages:myProfile:closingAccount:verifyAccountToClose:modal:link')}
                onPress={() => {
                  // Attention la page est à changer si les CGU sont mis à jour pour arriver sur les conditions cloture
                  const newTab = window.open(
                    ClientUtils.isClientTypeIndividual(client?.type)
                      ? `${urlCGU?.individual}#page=14`
                      : `${urlCGU?.corporate}#page=17`,
                    '_blank'
                  )
                  newTab.focus()
                }}
              />
            </div>
          </Modal>
        </PageContent>
      }
    />
  )
}
