import React, { useEffect, useState } from 'react'
import { Switch, useHistory, useLocation, matchPath, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import i18n from 'i18next'
import { resources } from 'I18n'
import { Notification } from 'rsuite'
import { Routes } from 'Routes/Routes'
import { RootActions, StateTypes } from 'Store'

import { NeobankApi } from '@neo-commons/services'
import { DeviceRecoveryActions, SignInActions } from '@neo-commons/store'
import { Icon, IconTypes } from '@neo-commons/components'

import { useLanguage, useStateListener } from '@neo-web/hooks'
import { RoutesUtils } from '@neo-web/utils'
import {
  Logged,
  ExternalPage,
  AlertHandler,
  ComingSoonModal,
  PrivateRoute,
  Route,
  LockDeviceAlert,
  PageModal,
} from '@neo-web/componentsOld'

import { AnalyticsHandler } from '../../Services/Analytics'
import { PublicLinkRoutes } from '../../Routes/PublicLinkRoutes'
import { SignInPolicy } from '../../Policies/SignIn/SignInPolicy'
import { DocumentUpload } from '../Pages/DocumentUpload/DocumentUpload'
import { Register } from '../Pages/Auth/Register/Register'
import { Credit } from '../Pages/Credit/Credit'
import { Subscription } from '../Pages/Subscription/Subscription'
import { SignIn } from '../Pages/Auth/SignIn/SignIn'
import { Opposition } from '../Pages/Services/DebitCard/Opposition'
import { ErrorPage } from '../Pages/PublicLink/PageErrors'
import { ScaDevice } from '../Pages/Auth/ScaDevice/ScaDevice'
import { SuspendedAccount } from '../Pages/Auth/SuspendedAccount/SuspendedAccount'
import { PublicLink } from '../Pages/PublicLink/PublicLink'
import { RecoverPassword } from '../Pages/Auth/SignIn/RecoverPassword/RecoverPassword'
import { RecoverPasswordAlert } from '../Pages/Auth/SignIn/RecoverPassword/Alert/AlertRecoverPassword'
import { AgentClientRegister } from '../Pages/Auth/AgentClientRegister/AgentClientRegister'
import { AdvanceEligibility } from '../Pages/AdvanceEligibility/AdvanceEligibility'

import '../../UI/Resources/Styles/sass/main.scss'

export const RootContainer: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const signupState = useSelector((state: StateTypes) => state.signup)
  const signInState = useSelector((state: StateTypes) => state.signIn)
  const scaState = useSelector((state: StateTypes) => state.sca)
  const bankAccountState = useSelector((state: StateTypes) => state.bankAccount,
    (p, c) => p?.suspendedBankAccountAlert === c?.suspendedBankAccountAlert)
  const routeState = useSelector((state: StateTypes) => state.routes)

  const [background, setBackground] = useState(null)
  const [showModal, setShowModal] = useState(true)
  const [initModalData, setInitModalData] = useState(null)
  const [isShowComingSoonModal, setIsShowComingSoonModal] = useState(false)
  let local = signupState?.profile?.locale ?? i18n.language

  if ((new RegExp(Object.keys(resources).join('|'))).test(location.search)) {
    local = location.search.replace('?lang=', '')
  }
  useLanguage(local)
  useStateListener()

  const signOut = async () => {
    try {
      await dispatch(SignInActions.signOut())
    } catch (e) {}
  }

  const onExpireToken = async () => {
    await signOut()

    history.push(Routes.SignIn.code.path)
  }

  const onDeviceLock = async () => {
    try {
      await signOut()

      await dispatch(DeviceRecoveryActions.lockDevice())

      history.push(Routes.SignIn.index.path)

      Notification({
        type: 'error',
        header: <Icon name='alert-circle' type={IconTypes.FEATHER} size={25} />,
        children: <LockDeviceAlert callback={() => history.push(PublicLinkRoutes.clientService.path)} />,
      })
    } catch (e) {}
  }

  NeobankApi.getInstance().setOnExpireToken(onExpireToken)
  NeobankApi.getInstance().setOnDeviceLock(onDeviceLock)

  useEffect(() => {
    if (routeState.ui.loaded) {
      const unblock = history.block((newLocation) => {
        const isInAvailableRoute = routeState?.route.available.filter((value) => {
          const match = matchPath(newLocation.pathname, {
            path: value.path,
            strict: true,
            sensitive: true,
          })
          return match && match.isExact
        })

        if (isInAvailableRoute.length === 0) {
          setIsShowComingSoonModal(true)
          return false
        } else {
          setTimeout(() => AnalyticsHandler.page(newLocation.pathname), 50)
        }
      })
      return () => {
        unblock()
      }
    }
  }, [location, routeState?.route])

  // Handling of account credit in a Modal/Drawer
  useEffect(() => {
    if (!(location?.pathname.startsWith(Routes.Credit.index.path))) {
      setShowModal(true)
      setBackground(location)
    } else if (location.state) {
      setInitModalData(location.state)
    }
  }, [location])

  const onModalExited = () => {
    if (background?.pathname) {
      history.replace(background.pathname)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        await dispatch(RootActions.startup())
      } catch (e) {}
    })()
  }, [])

  return (
    <>
      <Switch location={background || location}>
        {/* PRIVATE ROUTES */}
        <PrivateRoute
          path={Routes.Credit.index.path}
          component={Credit}
        />
        <Route
          path={RoutesUtils.getAllRoutes(Routes.Subscription)}
          component={Subscription}
        />
        <PrivateRoute
          path={Routes.Opposition.index.path}
          component={Opposition}
        />
        <PrivateRoute
          path={[]
            .concat(Routes.Origin.path)
            .concat(RoutesUtils.getAllRoutes(Routes.Synthesis))
            .concat(RoutesUtils.getAllRoutes(Routes.Payments))
            .concat(RoutesUtils.getAllRoutes(Routes.Services))
            .concat(RoutesUtils.getAllRoutes(Routes.BankAccount))
            .concat(RoutesUtils.getAllRoutes(Routes.Profile))}
          exact
        >
          <Logged />
        </PrivateRoute>

        {/* PUBLIC ROUTES */}
        {/* !!!All ROUTES PUBLIC OBLIGATORY DECLARED AFTER PRIVATE ROUTE!!! */}
        <Route path={Routes.SvaRegisterRoutes.index.path} component={AgentClientRegister} />
        <Route
          exact
          path={RoutesUtils.getAllRoutes(Routes.Register)}
          component={Register}
        />
        <Route
          path={RoutesUtils.getAllRoutes(Routes.DocumentUpload)}
          component={DocumentUpload}
        />
        <Route
          path={RoutesUtils.getAllRoutes(Routes.SignIn)}
          component={SignIn}
        />
        <Route
          path={RoutesUtils.getAllRoutes(Routes.RecoverPassword)}
          component={RecoverPassword}
        />
        <Route
          path={RoutesUtils.getAllRoutes(Routes.RecoverPasswordAlert)}
          component={RecoverPasswordAlert}
        />
        <Route path={RoutesUtils.getAllRoutes(Routes.AdvanceEligibility)} component={AdvanceEligibility} />
        <Route path={RoutesUtils.getAllRoutes(Routes.External)} component={ExternalPage} />
        <Route path={Routes.PublicLink.index.path} component={PublicLink} />
        <Route path={Routes.Error.index.path}>
          <ErrorPage code={Number(location.pathname.split('/')?.slice(-1)[0])} />
        </Route>
        <Route path='*'>
          <Redirect to={Routes.Error[404].path} />
        </Route>
      </Switch>

      {background && SignInPolicy.isAuthenticated(signInState) && (
        <PrivateRoute path={Routes.Credit.index.path}>
          <PageModal
            show={showModal}
            onExited={() => onModalExited()}
          >
            <Credit
              initData={initModalData}
              onClose={() => setShowModal(false)}
            />
          </PageModal>
        </PrivateRoute>
      )}

      {(routeState.ui.loaded && isShowComingSoonModal) &&
        <ComingSoonModal onClose={() => setIsShowComingSoonModal(false)} />}

      {scaState.data?.prepare?.showModal && (
        <ScaDevice />
      )}

      {bankAccountState?.suspendedBankAccountAlert &&
        <SuspendedAccount />}

      <AlertHandler />
    </>
  )
}
