import React from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Modal } from '@neo-web/componentsOld'

export interface AccountSuspendedModalProps {
  onClose?: () => void
  open: boolean
  description: string
}

export const AccountSuspendedModal: React.FC<AccountSuspendedModalProps> = (
  { onClose, open, description }: AccountSuspendedModalProps
) => {
  const { t } = useTranslation()

  return (
    <Modal
      autoFocus open={open}
      displayCross onClose={onClose}
      title={t('neo-commons:accountSuspended:modal:title')}
      titleIcon={<Icon type={IconTypes.FEATHER} name='alert-circle' color={Colors.error} size={33} />}
    >
      <div className='CancelPosAccountClosureModal_container'>
        <Typography2 typeface='body2'>
          {description}
        </Typography2>
      </div>
    </Modal>
  )
}
