import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { BankAccountUtils } from '@neo-commons/libraries'
import { BankAccountActions, BankAccountSelectors, PrepareSubAccountOrder, TransactionTypes } from '@neo-commons/store'
import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { ProjectStep, ProjectSteps, StepComponentProps } from '@neo-commons/policies'
import { Colors } from '@neo-commons/styles'

import { AccountDto, AccountTypeDto } from '@afone/neo-core-client/dist/models'

import { PageContent, PageDetails, JustifyContent, Banner } from '@neo-web/componentsOld'

import './Success.scss'
import { bannerPhysic } from '../../../../../Resources/Images'

type SuccessProps = StepComponentProps<ProjectSteps[ProjectStep.Success]>

export const Success: React.FC<SuccessProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const bankAccountState = useSelector((state: StateTypes) => state?.bankAccount)
  const selectedBankAccount: AccountDto = useSelector(
    BankAccountSelectors.selected,
    (prev, next) => prev?.uuid === next?.uuid
  )

  const preparedSubAccountOrder: PrepareSubAccountOrder = useSelector(BankAccountSelectors.getPreparedSubAccountOrder)

  useEffect(() => {
    AnalyticsHandler.track(
      AnalyticsEvents.Account.subAccountCreated,
      { sub_account_id: preparedSubAccountOrder?.uuid, sub_account_name: preparedSubAccountOrder?.name }
    )
    const refreshProjects = async () => {
      if (!bankAccountState.loading) {
        await dispatch(BankAccountActions.list({
          bankAccountType: AccountTypeDto.PROJECT,
          parentUuid: BankAccountUtils.isSecondary(selectedBankAccount) ? selectedBankAccount.parentUuid : selectedBankAccount?.uuid,
          isPaginate: true,
        }))
      }
    }
    try {
      refreshProjects()
    } catch (error) {}
  }, [])

  return (
    <PageDetails
      component={
        <PageContent
          justifyContent={JustifyContent.CENTER}
          header={
            <Banner>
              <img
                className='SuccessContent_header-banner'
                src={bannerPhysic}
                alt='banner'
              />
            </Banner>
          }
          button={
            <div className='SuccessContent_button'>
              <Button
                skin={ButtonSkin.PRIMARY}
                containerStyle={{ width: '100%' }}
                title={t('app:pages:services:project:success:button')}
                onPress={async () => {
                  await dispatch({ type: TransactionTypes.RESET })
                  await dispatch(BankAccountActions.setSelected(preparedSubAccountOrder.uuid))
                  dispatch(BankAccountActions.resetPrepareSubAccount())
                  history.replace(Routes.Synthesis.transactions.path)
                }}
              />
            </div>
          }
        >
          <div className='SuccessContent'>

            <div className='SuccessContent_icon'>
              <Icon
                color={Colors.secondary}
                name='check-circle'
                size={39}
                type={IconTypes.FEATHER}
              />
            </div>
            <div className='SuccessContent_title'>
              <Typography typeface='input'>
                {t('app:pages:services:project:success:title')}
              </Typography>
            </div>

            <Typography typeface='contentLightGrey'>
              {t('app:pages:services:project:success:description')}
            </Typography>
          </div>
        </PageContent>
      }
      onClose={async () => {
        await dispatch(BankAccountActions.resetPrepareSubAccount())
        history.push(Routes.Services.ProjectsRoutes.index.path)
      }}
      onBack={false}
    />
  )
}
