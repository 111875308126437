import React from 'react'

import { Drawer, Modal as ModalRs, ModalProps as ModalPropsRs } from 'rsuite'

import { Colors } from '@neo-commons/styles'
import { Icon, IconTypes, Typography } from '@neo-commons/components'

import { useResponsive } from '@neo-web/hooks'

import './Modal.scss'

export interface ModalProps extends ModalPropsRs {
  icon?: React.ReactNode,
  title?: React.ReactNode,
  titleIcon?: React.ReactElement<typeof Icon>,
  children?: React.ReactNode,
  footer?: React.ReactNode,
  displayCross?: boolean,
  onClose?: () => void,
}

export const Modal: React.FC<ModalProps> = ({
  icon,
  title,
  children,
  titleIcon,
  footer,
  displayCross,
  onClose,
  ...props
}: ModalProps) => {
  const isTabletOrMobile = useResponsive()

  const crossComponent =
    <div className='Modal_close'>
      <Icon
        name='x'
        type={IconTypes.FEATHER}
        size={25}
        onPress={onClose}
        color={Colors.black}
      />
    </div>

  const iconComponent =
    <div className='Modal_icon'>
      {icon}
    </div>

  return (
    <>
      {isTabletOrMobile && (
        <Drawer placement='bottom' onClose={onClose} {...props} className={`Modal ${props.className}`}>
          {displayCross && crossComponent}
          {icon && iconComponent}
          {title && (
            <div className='Modal_title'>
              {titleIcon && <div className='Modal_title_icon'>{titleIcon}</div>}
              <Typography typeface='bigTitle'>{title}</Typography>
            </div>
          )}
          <div className='Modal_content'>{children}</div>
          {footer && <Drawer.Footer>{footer}</Drawer.Footer>}
        </Drawer>
      )}
      {!isTabletOrMobile && (
        <ModalRs {...props} onClose={onClose} className={`Modal ${props.className}`}>
          {displayCross && crossComponent}
          {icon && iconComponent}
          {title && (
            <div className='Modal_title'>
              {titleIcon && <div className='Modal_title_icon'>{titleIcon}</div>}
              <Typography typeface='bigTitle'>{title}</Typography>
            </div>
          )}
          <div className='Modal_content'>
            <ModalRs.Body>
              {children}
            </ModalRs.Body>
          </div>
          {footer && <ModalRs.Footer>{footer}</ModalRs.Footer>}
        </ModalRs>
      )}
    </>
  )
}
