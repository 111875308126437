import React, { FunctionComponent, memo, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { TransactionDto } from '@afone/neo-core-client/dist/models'
import { EnumUtils, TextUtils, Transaction, TransactionUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { Icon, IconTypes, PaymentCard } from '..'

import styles from './styles'

export interface TransactionCardProps {
  transaction: Transaction | undefined
  currency?: string
  clickable?: boolean
  onPress: () => void
}

export const TransactionCard: FunctionComponent<TransactionCardProps> = memo((props: TransactionCardProps) => {
  const { clickable = true, onPress, transaction, currency } = props

  const { t } = useTranslation()
  const iconName = useRef((transaction)
    ? (TransactionUtils.isAdvanceRefund(transaction)
      ? 'fee'
      : TransactionUtils.getIconFromTransactionFamily(transaction))
    : '').current

  const [iconColor, setIconColor] = useState(Colors.black)
  const [amountColor, setAmountColor] = useState(Colors.black)

  const icon = (
    <View>
      <Icon type={IconTypes.NEOFONT} name={iconName} size={30} color={iconColor} />
      {TransactionUtils.isTransactionDto(transaction) && transaction.checkedDate &&
        <View style={styles.checkedIcon}>
          <Icon type={IconTypes.MATERIAL} name='check-circle' size={14} color={Colors.primary} />
        </View>}
    </View>
  )

  const setColor = (transaction: TransactionDto) => {
    if (typeof transaction.price !== 'undefined' &&
      (typeof transaction.price.amount !== 'undefined' || typeof transaction.price.feesTotal !== 'undefined')) {
      const isFee = TransactionUtils.isFee(transaction) &&
        (!transaction.price?.amount || transaction.price?.amount === 0)
      const feePositive = isFee && (transaction.price?.feesTotal && transaction.price?.feesTotal > 0)
      const feeNegative = isFee && (transaction.price?.feesTotal && transaction.price?.feesTotal <= 0)

      switch (true) {
        case !transaction.completed:
          setIconColor(Colors.error)
          setAmountColor(Colors.darkGreyText)
          break
        case feePositive:
        case (transaction.price?.amount && transaction.price?.amount > 0):
          setIconColor(Colors.primary)
          setAmountColor(Colors.primary)
          break
        case feeNegative:
        case (transaction.price?.amount && transaction.price?.amount <= 0):
          setIconColor(Colors.secondary)
          break
      }
    }
  }

  const label = useMemo(() => {
    if (TransactionUtils.isTransactionDto(transaction) && transaction.primaryLabel) {
      return transaction.primaryLabel
    } else if (TransactionUtils.isTransactionDto(transaction) && TransactionUtils.isAdvanceRefund(transaction)) {
      return t('neo-commons:advance:reimbursement')
    } else if (TransactionUtils.isTransactionDto(transaction) && !!transaction.family) {
      return t(EnumUtils.getTransactionTypeFamilyDtoKey(transaction.family))
    } else {
      return t('account:defaultTransactionLabel')
    }
  }, [transaction])

  const info = useMemo(() => {
    if (TransactionUtils.isTransactionDto(transaction) && transaction.secondaryLabel) {
      return transaction.secondaryLabel
    } else if (TransactionUtils.isRecurringTransferDto(transaction) && transaction.privateLabel) {
      return transaction.privateLabel
    } else if (TransactionUtils.isTransactionDto(transaction) && TransactionUtils.isAdvanceRefund(transaction)) {
      return t('neo-commons:advance:title')
    } else {
      return ''
    }
  }, [transaction])

  useEffect(() => {
    if (transaction !== undefined) {
      setColor(transaction)
    }
  }, [transaction])

  return (
    <PaymentCard
      title={TextUtils.prettyShortText(label, 40)}
      info={info}
      annotation={(TransactionUtils.isTransactionDto(transaction) && transaction?.annotation)
        ? t('neo-commons:transaction:note', { value: transaction.annotation })
        : undefined}
      isOnus={TransactionUtils.isTransactionDto(transaction) ? transaction?.onus : false}
      currency={currency}
      price={transaction?.price}
      family={TransactionUtils.isTransactionDto(transaction) ? transaction.family : null}
      isComplete={TransactionUtils.isTransactionDto(transaction) && transaction.completed}
      isAdvanceTransaction={TransactionUtils.isTransactionDto(transaction) && TransactionUtils.isAdvanceTransaction(transaction)}
      icon={icon}
      amountColor={amountColor}
      clickable={clickable}
      feesLabel={
        TransactionUtils.isTransactionDto(transaction)
          ? transaction.feeLabel
          : t('neo-commons:transaction:fees')
      }
      onPress={() => clickable && onPress()}
    />
  )
})
