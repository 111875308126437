import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import { Badge, BadgeProps } from '../Badge/Badge'

import styles from './styles'

/**
 * BadgeList Properties
 */
export interface BadgeListProps{
    /**  List of badge informations */
    badges: BadgeProps[],
    onItemPress?: (value) => void,
}

export const BadgeList: FunctionComponent<BadgeListProps> = ({ badges, onItemPress }) => {
  return (
    <View style={styles.badgesContainer}>
      <View style={styles.badges} testID='badgeList'>
        {badges.map((badge, i) => (
          <View style={styles.badge} key={i}>
            {onItemPress
              ? <Badge {...badge} onPress={onItemPress} />
              : <Badge {...badge} />}
          </View>
        ))}
      </View>
    </View>
  )
}
