import React, { FunctionComponent, useState, useEffect } from 'react'
import { View, TouchableOpacity, Text, ViewStyle } from 'react-native'

import { Icon, IconTypes } from '../Icon/Icon'

import styles from './styles'

interface PickerItem {
  label: string,
  value: any
}

export interface PickerProps {
  items: Array<PickerItem>,
  defaultKey?: number,
  onChange: (value: any) => void,
  value: any,
  containerStyle?: ViewStyle[],
  backButtonDisabled?: boolean,
  nextButtonDisabled?: boolean,
}

export const Picker: FunctionComponent<PickerProps> = (
  { items, defaultKey, onChange, value, containerStyle, backButtonDisabled, nextButtonDisabled }
) => {
  const [key, setKey] = useState(defaultKey || 0)

  const itemsWithIndex = items.map((obj, index) => ({ ...obj, key: index }))

  useEffect(() => {
    if (value || value === 0) {
      const key = itemsWithIndex.findIndex(item => item.value === value)
      if (key !== -1) { setKey(key) }
    }
  }, [value, itemsWithIndex])

  const previous = () => {
    if (key - 1 < 0) {
      setKey(items.length - 1)
      onChange(items[items.length - 1].label)
    } else {
      setKey(key - 1)
      onChange(items[key - 1].label)
    }
  }

  const next = () => {
    if (key + 1 === items.length) {
      setKey(0)
      onChange(items[0].label)
    } else {
      setKey(key + 1)
      onChange(items[key + 1].label)
    }
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <TouchableOpacity
        onPress={() => previous()}
        style={styles.btnAction && (backButtonDisabled && styles.btnDisabled)}
        disabled={backButtonDisabled}
        hitSlop={{ top: 10, left: 10, bottom: 10, right: 10 }}
        accessibilityLabel='Previous'
      >
        <Icon type={IconTypes.FEATHER} name='chevron-left' size={22} />
      </TouchableOpacity>
      <Text accessibilityLabel={itemsWithIndex[key]?.label} style={styles.text}>{itemsWithIndex[key]?.label}</Text>
      <TouchableOpacity
        onPress={() => next()}
        disabled={nextButtonDisabled}
        style={styles.btnAction && (nextButtonDisabled && styles.btnDisabled)}
        hitSlop={{ top: 10, left: 10, bottom: 10, right: 10 }}
        accessibilityLabel='Next'
      >
        <Icon type={IconTypes.FEATHER} name='chevron-right' size={22} />
      </TouchableOpacity>
    </View>
  )
}
