import { version } from '@neo-commons/store/package.json'
import { Dispatch, Reducer, AnyAction } from 'redux'
import { NeobankApi } from '@neo-commons/services'
import i18next from 'i18next'
import { ConfigDto, LegalFormDto } from '@afone/neo-core-client/dist/models'
import countries from 'i18n-iso-countries'
import countriesFR from 'i18n-iso-countries/langs/fr.json'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'

import { ResourceStateFactory } from '../../utils/resourceState'
import { State } from '../../utils'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/it'
import 'dayjs/locale/es'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localeData from 'dayjs/plugin/localeData'
import updateLocale from 'dayjs/plugin/updateLocale'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import isoWeek from 'dayjs/plugin/isoWeek'
// @ts-ignore
import dayjsBusinessDays from 'dayjs-business-days'

countries.registerLocale(countriesFR)

dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(isoWeek)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(dayjsBusinessDays)
dayjs.locale(i18next.language)

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Config = ConfigDto

const {
  resourceSelector: ConfigResourceSelector,
} = ResourceStateFactory<Config, 'config'>((state: State) => state.config, 'config')

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */
const GET_CONFIG_REQUEST = 'config/GET_CONFIG_REQUEST'
const GET_CONFIG_SUCCESS = 'config/GET_CONFIG_SUCCESS'
const GET_CONFIG_FAILED = 'config/GET_CONFIG_FAILED'

const LIST_LEGAL_FORM_REQUEST = 'config/LIST_LEGAL_FORM_REQUEST'
const LIST_LEGAL_FORM_SUCCESS = 'config/LIST_LEGAL_FORM_SUCCESS'
const LIST_LEGAL_FORM_FAILURE = 'config/LIST_LEGAL_FORM_FAILURE'

export const ConfigStore = {
  GET_CONFIG_REQUEST,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILED,

  LIST_LEGAL_FORM_REQUEST,
  LIST_LEGAL_FORM_SUCCESS,
  LIST_LEGAL_FORM_FAILURE,
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */

function getConfig () {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GET_CONFIG_REQUEST })
    try {
      /*
       * TODO: handle cache when needUpdate property will be returned by API.
       */
      const payload = await NeobankApi.getInstance().configApi.getConfig(version.split('-')[0])
      const data = payload?.data
      dispatch({ type: GET_CONFIG_SUCCESS, config: data })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:internalTechnicalIssue')
      dispatch({ type: GET_CONFIG_FAILED, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

function listLegalFormsByCountry ({ countryCode }) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LIST_LEGAL_FORM_REQUEST })

    try {
      const getLegalFormsByCountryResponse = await NeobankApi.getInstance().configApi.getLegalFormsByCountry(countryCode)
      dispatch({ type: LIST_LEGAL_FORM_SUCCESS, data: getLegalFormsByCountryResponse.data })
      return getLegalFormsByCountryResponse.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: LIST_LEGAL_FORM_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

export const ConfigActions = {
  getConfig,
  listLegalFormsByCountry,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const configSelector = state => state.config

export const ConfigSelectors = {
  ...ConfigResourceSelector,
  getCurrentConfig: createSelector(
    configSelector,
    resource => resource.data.currentConfig
  ),
  legalsForms: createSelector(
    configSelector,
    resource => resource.legalsForms
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export interface ConfigState {
  ui: {
    isLoadingConfig: boolean,
    isFail: boolean,
    errorMessage: string,
    isForceUpdate: boolean,
  }
  data: ConfigDto,
  legalsForms: LegalFormDto[],
}

const initialState = {
  ui: {
    isLoadingConfig: false,
    isFail: false,
    errorMessage: '',
    isForceUpdate: false,
  },
  data: {},
  legalsForms: [],
}

export const config: Reducer = (state = initialState, action: AnyAction): ConfigState => {
  switch (action.type) {
    case LIST_LEGAL_FORM_REQUEST:
    case GET_CONFIG_REQUEST:
      return {
        ...state,
        ui: {
          isLoadingConfig: true,
        },
      }
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        ui: {
          isLoadingConfig: false,
        },
        data: action?.config,
      }
    case LIST_LEGAL_FORM_SUCCESS: {
      return {
        ...state,
        ui: {
          isLoadingConfig: false,
        },
        legalsForms: action?.data,
      }
    }
    case LIST_LEGAL_FORM_FAILURE:
    case GET_CONFIG_FAILED:
      return {
        ...state,
        ui: {
          isLoadingConfig: false,
          isFail: true,
          errorMessage: action?.errorMessage ?? '',
        },
      }

    default:
      return state
  }
}
