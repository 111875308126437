import { TextStyle } from 'react-native'

import s from './styles'

export const getStylesFromProps = (props: { value?: string, errorShown: boolean, isFocused: boolean, hasClear?: boolean, inputStyle: TextStyle }): { inputStyle: TextStyle[], labelStyle: TextStyle[] } => {
  const { value, errorShown, isFocused, hasClear } = props
  const inputStyle: TextStyle[] = [s.input]
  const labelStyle: TextStyle[] = [s.label]

  if (isFocused) {
    inputStyle.push(s.inputOnFocus)
    labelStyle.push(s.labelOnFocus)
  }

  if (value && !isFocused) {
    inputStyle.push(s.inputOnValue)
    labelStyle.push(s.labelOnValue)
  }

  if (errorShown) {
    inputStyle.push(s.inputOnError)
  }

  if (hasClear) {
    inputStyle.push({ paddingRight: 28 })
  }

  inputStyle.push(props.inputStyle)

  return { inputStyle, labelStyle }
}
