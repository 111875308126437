import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { SignInActions } from '@neo-commons/store'
import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Tunnel, TunnelContent } from '@neo-web/componentsOld'

export const SubscriptionError: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const path = location.state as any

  return (
    <Tunnel
      component={
        <TunnelContent
          icon={<Icon name='info' type={IconTypes.FEATHER} size={40} color={Colors.secondary} />}
          subtitle={t('app:pages:subscription:global:subscriptionError:title')}
          description={path?.errorMessage ? path?.errorMessage : t('app:pages:subscription:global:subscriptionError:description')}
          footer={
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:global:backToHome')}
              onPress={async () => {
                await dispatch(SignInActions.signOut())
                history.push(Routes.SignIn.index.path)
              }}
            />
          }
        />
      }
      footer
    />
  )
}
