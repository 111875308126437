import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

import { createStylesheet } from '../utils'

import { ToggleCardSkin, ToggleCardProps } from './ToggleCard'

const styles = createStylesheet(({ Skin, props }: { Skin, props: ToggleCardProps }) => {
  return StyleSheet.create({
    wrapContent: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: 5,
    },
    leftChildArea: {
      flex: 1,
      justifyContent: 'center',
    },
    switchArea: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 5,
    },

    title: {
      ...Skin.select([
        ToggleCardSkin.GOLD_BORDER,
      ], {
        color: props.value ? Colors.white : Colors.secondary,
      }),
      ...Skin.select([
        ToggleCardSkin.GRAY_BORDER,
      ], {
        color: props.value ? Colors.white : Colors.black,
      }),

      ...((props.value && props.enabledCustomization?.titleColor) ? {
        color: props.enabledCustomization?.titleColor,
      } : {}),

      ...((!props.value && props.disabledCustomization?.titleColor) ? {
        color: props.disabledCustomization?.titleColor,
      } : {}),

      ...((props.disabled && props.value) ? {
        color: props.enabledCustomization?.titleColor ?? Colors.black,
      } : {}),

      ...((props.disabled && !props.value) ? {
        color: props.disabledCustomization?.titleColor ?? Colors.black,
      } : {}),
    },

    subtitle: {
      ...Skin.select([
        ToggleCardSkin.GOLD_BORDER, ToggleCardSkin.GRAY_BORDER,
      ], {
        color: props.value ? Colors.white : Colors.black,
      }),

      ...((props.value && props.enabledCustomization?.subtitleColor) ? {
        color: props.enabledCustomization?.subtitleColor,
      } : {}),

      ...((!props.value && props.disabledCustomization?.subtitleColor) ? {
        color: props.disabledCustomization?.subtitleColor,
      } : {}),

      ...((props.disabled && props.value) ? {
        color: props.enabledCustomization?.subtitleColor ?? Colors.black,
      } : {}),

      ...((props.disabled && !props.value) ? {
        color: props.disabledCustomization?.subtitleColor ?? Colors.black,
      } : {}),
    },
  })
})

export default styles
