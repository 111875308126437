import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'

import { Container, Content, Header } from 'rsuite'
import { Routes } from 'Routes/Routes'

import { FooterDefault } from '@neo-web/componentsOld'
import { useResponsive } from '@neo-web/hooks'

import ConsentModal from '../../../Components/ConsentModal/ConsentModal'
import logo from '../../../Resources/Images/site/dark-logo.svg'
import { SelectLang } from '../../../Components/Select/SelectLang'

import { Login } from './Login/Login'
import { RecoverPassword } from './RecoverPassword/RecoverPassword'

import './SignIn.scss'

const pages: { path: string, header?: React.ReactNode, content: React.ReactNode }[] = [
  { path: Routes.SignIn.index.path, content: <Login /> },
  { path: Routes.RecoverPassword.index.path, content: <RecoverPassword /> },
]

export const SignIn: React.FC = () => {
  const [consentModal, setConsentModal] = React.useState(null)
  useEffect(() => {
    setTimeout(() => {
      setConsentModal(<ConsentModal />)
    }, 1000)
  }, [])
  const isTabletOrMobile = useResponsive()
  return (
    <div className='show-container SignIn'>
      <Container>
        {consentModal}
        <div className='SignIn_selectLang'>
          <SelectLang />
        </div>
        <Header className='SignIn_header'>
          <img
            src={logo}
            alt='Logo'
          />
        </Header>
        <Content>
          <main className='SignIn_container classic-box'>
            {pages.map((page, index) => {
              return (
                <Route path={page.path} key={index}>
                  {page.content && <div>{page.content}</div>}
                </Route>
              )
            })}
          </main>
        </Content>
        {!isTabletOrMobile &&
          <FooterDefault />}
      </Container>
    </div>
  )
}
