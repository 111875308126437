import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { SelectPicker, SelectPickerProps } from 'rsuite'

import './Select.scss'

type SelectProps = {
  renderMenuItem?: (label: string, item) => ReactNode // function which return a designed item
  renderValue?: (label: string, item, selectedElement) => ReactNode // function which return a designed value
  searchable?: boolean
} & SelectPickerProps<any>

export type DataItemType = {
  value: string // property value is the value of valueKey
  label: React.ReactNode // property value is the vaue of labelKey
  children?: Array<DataItemType> // property value is the value of childrenKey
  groupBy?: string
}

const defaultRenderMenuItem = (label) => {
  return (
    <div className='Select_item'>
      {label}
    </div>
  )
}

const defaultRenderValue = (label, item, selectedElement) => {
  return (
    <div className='Select_value'>
      {selectedElement}
    </div>
  )
}

export const RSSelect: React.FC<SelectProps> = ({
  renderMenuItem = defaultRenderMenuItem, renderValue, searchable = false, ...props
}: SelectProps) => {
  const { t } = useTranslation()

  return (
    <SelectPicker
      className='Select'
      {...props}
      searchable={searchable}
      renderMenuItem={renderMenuItem}
      renderMenuGroup={(label) => {
        return (
          <div className='Select_group'>
            {label}
          </div>
        )
      }}
      locale={{
        searchPlaceholder: t('app:global:search'),
      }}
      renderValue={renderValue ?? defaultRenderValue}
      block
    />
  )
}
