import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'

import { store } from 'Store'

import { useWizardPolicy } from '@neo-commons/hooks'
import { orderProjectPolicy, ProjectStep } from '@neo-commons/policies'

import { Name } from './Step1/Name'
import { Summary } from './Step2/Summary'
import { Success } from './Step3/Success'
export const Order: React.FC = () => {
  const location = useLocation()

  const { steps: wizardSteps, currentStep, load } = useWizardPolicy(
    store,
    orderProjectPolicy,
    {
      [ProjectStep.Name]: Name,
      [ProjectStep.Summary]: Summary,
      [ProjectStep.Success]: Success,
    }, {
      onDone: async () => {
        // TODO: onDone not working
        // history.replace(Routes.Synthesis.transactions.path)
      },
    }
  )

  return (
    load &&
      <>
        {currentStep?.path && location.pathname !== currentStep.path &&
          <Redirect
            to={{
              pathname: currentStep?.path,
            }}
          />}
        {currentStep?.path && wizardSteps.map((value) => {
          return (
            <Route
              key={value.navigationKey}
              exact
              path={value.path}
              component={value.component}
            />
          )
        })}
      </>
  )
}
