import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Route, Switch, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { BankAccountSelectors, SubscriptionSelectors } from '@neo-commons/store'
import { BankAccountUtils, SubscriptionUtils } from '@neo-commons/libraries'
import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { ContactDto } from '@afone/neo-core-client/dist/models'

import { Page, PageContent } from '@neo-web/componentsOld'

import { Routes } from '../../../Routes/Routes'

import { List } from './Pay/Contact/List'
import { Pay } from './Pay/Pay'
import { Transfers } from './Pay/Transfers/Transfers'
import { Action } from './Pay/Contact/Action'
import { Update } from './Pay/Transfers/Update'
import Banks from './Banks/Banks'

export const Payments: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const mainSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  const signatureOk = SubscriptionUtils.isSignatureOk(mainSubscription)

  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const isSelectedAccountSecondaryOrAggregated = BankAccountUtils.isSecondaryOrAggregated(selectedBankAccount)
  const isProspectAccount = BankAccountUtils.isProspectProject(selectedBankAccount)

  const [content, setContent] = useState(
    <div className='classic-box'>
      <PageContent
        title={t('app:pages:services:unavailable')}
      />
    </div>
  )

  useEffect(() => {
    (async () => {
      try {
        if (isSelectedAccountSecondaryOrAggregated || (isProspectAccount && !signatureOk)) {
          setContent(
            <div className='classic-box'>
              <PageContent
                icon={<Icon type={IconTypes.FEATHER} name='alert-circle' color={Colors.secondary} size={50} />}
                title={t('app:pages:payments:unavailable')}
              />
            </div>
          )
        } else {
          setContent(
            <Switch>
              <Route
                path={[Routes.Payments.index.path, Routes.Payments.contactHome.path]}
                exact
                component={
                  () =>
                    <Page pages={[{
                      path: Routes.Payments.index.path,
                      component: <Pay />,
                      name: t('app:pages:payments:pay:menu'),
                    }, {
                      path: Routes.Payments.cashIn.path,
                      component: <div>Todo : CashIn</div>,
                      name: t('app:pages:payments:bank:menu'),
                      isAvailable: false,
                      isHidden: true,
                    }, {
                      path: Routes.Payments.contactHome.path,
                      component: (
                        <List
                          createRouting={Routes.Payments.contactAction.path}
                          onSelectContact={(contactDto: ContactDto) => history.push(generatePath(
                            Routes.Payments.contactUpdate.path,
                            { id: contactDto.uuid }
                          ))}
                        />
                      ),
                      name: t('app:pages:payments:contacts:menu'),
                    }]}
                    />
                }
              />
              <Route
                exact
                path={[
                  Routes.Payments.bankTransfer.path, Routes.Payments.contact.path, Routes.Payments.account.path,
                  Routes.Payments.target.path, Routes.Payments.summary.path, Routes.Payments.amount.path,
                  Routes.Payments.nextTransfer.path, Routes.Payments.result.path, Routes.Payments.scheduled.path,
                  Routes.Payments.recurring.path, Routes.Payments.beneficiary.path,
                ]} component={Transfers}
              />
              <Route path={Routes.Payments.transferUpdate.path} component={Update} />
              <Route path={Routes.Payments.contactCreate.path} component={Transfers} />
              <Route
                path={[Routes.Payments.contactAction.path, Routes.Payments.contactUpdate.path]}
                component={Action}
              />
              <Route path={Routes.Payments.cashIn.path} component={Banks} />
            </Switch>
          )
        }
      } catch (error) {
      }
    })()
  }, [selectedBankAccount])

  return (
    <>
      {content}
    </>
  )
}
