import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'
import { SubscriptionPolicy } from 'Policies'

import {
  CardActions,
  CardSelectors,
  OrderUpdateType,
  PrepareCardOrder,
  SubscriptionActions,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { Button, PaymentCards, PaymentCardType, Typography } from '@neo-commons/components'
import { OfferUtils } from '@neo-commons/libraries'

import { CardDto, ClientTypeDto, ProductDto } from '@afone/neo-core-client/dist/models'

import { PageDetails, ButtonSkin, PageContent } from '@neo-web/componentsOld'

import './PhysicalPromo.scss'

export const PhysicalPromo: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const preparedCardOrder: PrepareCardOrder = useSelector(CardSelectors.preparedCardOrder)
  let cardSubscriptionInProgress = useSelector(SubscriptionSelectors.getToProceed(preparedCardOrder?.offer?.offerCode))
  const productImages = OfferUtils.getPaymentCardsImagesFromProducts(preparedCardOrder?.offer?.products)
  const cardProduct = (preparedCardOrder?.offer?.products as (ProductDto & {data: {productType?: string}})[])?.filter(product => product?.data?.productType === 'plasticCard')
  const amount = useSelector(SubscriptionSelectors.getProductPriceByCode(cardProduct?.[0]?.productCode))

  useEffect(() => {
    (async () => {
      if (!amount) {
        if (!cardSubscriptionInProgress?.uuid) {
          await dispatch(SubscriptionActions.prepare({ ibanLocalization: 'FR' }))
          cardSubscriptionInProgress = await dispatch(SubscriptionActions.create({
            offerUuid: preparedCardOrder?.offer.uuid,
            discountCode: preparedCardOrder?.promoCode,
            clientType: SubscriptionPolicy.isIndividual() ? ClientTypeDto.INDIVIDUAL : ClientTypeDto.CORPORATE,
          }))
        }
        dispatch(SubscriptionActions.getProductPrice({ subscriptionUuid: cardSubscriptionInProgress?.uuid, productCode: cardProduct?.[0]?.productCode }))
      }
    })()
  }, [])

  return (
    <>
      <PageDetails
        component={
          <PageContent>
            <div className='PhysicalPromo'>
              <div className='PhysicalPromo_content'>
                <div className='PhysicalPromo_content_image'>
                  {productImages && (
                    <PaymentCards
                      card={{
                        productImage: productImages?.digital,
                        plasticInfo: { productImage: productImages?.plastic },
                        plastic: productImages?.digital && productImages?.plastic,
                      } as CardDto}
                      only={PaymentCardType.PHYSICAL}
                    />
                  )}
                </div>
                <div className='PhysicalPromo_header'>
                  <Typography typeface='bigTitle'>
                    {t('app:pages:services:debitCard:order:physicalPromo:contentTitle')}
                  </Typography>
                </div>
                <Typography typeface='content'>
                  {t('app:pages:services:debitCard:order:physicalPromo:contentText')}
                </Typography>
                <div className='OfferCard_value'>
                  <Typography typeface='bigTitle'>
                    {amount + ' €'}
                  </Typography>
                </div>
              </div>
              <div className='PhysicalPromo_footer'>
                <Button
                  skin={ButtonSkin.OUTLINE_PRIMARY}
                  title={t('app:pages:services:debitCard:order:physicalPromo:getDigitalCard')}
                  onPress={async () => {
                    await dispatch(CardActions.prepare({
                      ...preparedCardOrder,
                      updateType: OrderUpdateType.NONE,
                      withPhysicalCard: false,
                    }))
                    history.push(Routes.Services.summary.path)
                  }}
                />
                <Button
                  skin={ButtonSkin.PRIMARY}
                  containerStyle={{ marginTop: 8 }}
                  title={t('app:pages:services:debitCard:order:physicalPromo:getPhysicalCard', { amount })}
                  onPress={async () => {
                    await dispatch(CardActions.prepare({
                      ...preparedCardOrder,
                      updateType: OrderUpdateType.NONE,
                      withPhysicalCard: true,
                      isBundle: true,
                    }))
                  }}
                />
              </div>
            </div>
          </PageContent>
        }
        onClose={() => history.push(Routes.Services.index.path)}
        onBack={async () => {
          await dispatch(CardActions.prepare({
            ...preparedCardOrder,
            updateType: OrderUpdateType.CHOICES_DEBIT_CARD,
          }))
        }}
        title={t('app:pages:services:debitCard:order:physicalPromo:title')}
      />
    </>
  )
}
