import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Button, ButtonSkin, Typography } from '@neo-commons/components'
import { SignInActions } from '@neo-commons/store'

import { Modal } from '@neo-web/componentsOld'

import './LogoutModal.scss'

interface LogoutModalProps {
  show: boolean
  onClose: any
}

export const LogoutModal: React.FC<LogoutModalProps> = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <Modal
      open={props.show}
      onClose={props.onClose}
    >
      <div className='LogoutModal_container'>
        <div className='LogoutModal_title'>
          <Typography typeface='bigTitle'>
            {t('app:global:disconnect:modal:title')}
          </Typography>
        </div>
        <div className='LogoutModal_action'>
          <Button
            skin={ButtonSkin.OUTLINE_PRIMARY}
            title={t('app:global:disconnect:modal:cancel')}
            onPress={props.onClose}
          />
          <Button
            skin={ButtonSkin.PRIMARY}
            title={t('app:global:disconnect:modal:validate')}
            onPress={() => {
              dispatch(SignInActions.signOut())
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
