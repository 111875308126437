import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

export default StyleSheet.create({
  container: {
    overflow: 'hidden',
    backgroundColor: Colors.white,
    borderRadius: 15,
    padding: 0,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    elevation: 3,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  containerBasic: {
    display: 'flex',
    flexDirection: 'row',
  },
  centerArea: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingVertical: 10,
  },
  subtitle: {
    marginTop: 5,
  },
  stateIconContainerStyle: {
    paddingVertical: 10,
    justifyContent: 'center',
  },
})
