import React, { CSSProperties } from 'react'

import { Button, Icon, Typography } from '@neo-commons/components'

import './PageContent.scss'

export enum JustifyContent {
  START = 'start',
  CENTER = 'center',
  BETWEEN = 'between',
}

export interface PageContentProps {
  justifyContent?: JustifyContent,
  fullWidth?: boolean,
  header?: React.ReactElement<Element>,
  icon?: React.ReactElement<typeof Icon>,
  title?: string | React.ReactElement<Element>,
  titleInBody?: boolean,
  description?: string | React.ReactElement<Element>,
  children?: React.ReactNode,
  button?: React.ReactElement<typeof Button>,
  footer?: React.ReactElement<Element>,
  footerAtBottom?: boolean,
  withBackground?: boolean,
  style?: CSSProperties,
}

export const PageContent: React.FC<PageContentProps> = (props: PageContentProps) => {
  const title = typeof props.title === 'string'
    ? <Typography typeface='bigTitle'>{props.title}</Typography>
    : props.title

  return (
    <div className={`PageContent ${props.withBackground ? 'withBackground' : ''}`}>
      {props.header &&
        <div className='PageContent_header'>
          {props.header}
        </div>}

      <div
        className={`PageContent_container PageContent_container_${props.justifyContent ?? JustifyContent.CENTER} ${props.fullWidth ? 'fullWidth' : ''}`}
        style={{ ...props?.style }}
      >

        {(props.icon || (props.title && !props.titleInBody)) &&
          <div className='PageContent_center'>
            {props.icon &&
              <div className='PageContent_iconContainer'>
                {props.icon}
              </div>}
            {(props.title && !props.titleInBody) &&
              <div className='PageContent_item'>{title}</div>}
          </div>}

        <div className='PageContent_body'>
          {(props.title && props.titleInBody) &&
            <div className='PageContent_item PageContent_center'>{title}</div>}

          {props.description &&
            <div className='PageContent_item'>
              {typeof props.description === 'string'
                ? (
                  <div className='PageContent_center'>
                    <Typography typeface='content'>{props.description}</Typography>
                  </div>
                ) : props.description}
            </div>}

          {props.children &&
            <div className='PageContent_item'>
              {props.children}
            </div>}
        </div>

        <div className={`PageContent_footer ${props?.footerAtBottom ? 'at_bottom' : ''}`}>
          {props.button &&
            <div className='PageContent_buttonContainer'>
              {props.button}
            </div>}

          {props.footer &&
            <div className='PageContent_item'>
              {props.footer}
            </div>}
        </div>

      </div>
    </div>
  )
}
