import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  step: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeCircle: {
    width: 16,
    height: 16,
    borderRadius: 15,
  },
  innerCircle: {
    width: 8,
    height: 8,
    borderRadius: 15,
    backgroundColor: Colors.white,
  },
  line: {
    width: 17,
    height: 2,
    backgroundColor: Colors.primary,
  },
})

export default styles
