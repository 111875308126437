import React, { FunctionComponent } from 'react'
import { View, Image, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'

import { NumbersUtils, BankAccountUtils } from '@neo-commons/libraries'
import { AccountDto, ClientTypeDto } from '@afone/neo-core-client/dist/models'

import { BankAccountCardSkin, BankAccountAction } from '../BankAccountCard'
import { Icon, Typography, Button, ButtonSkin } from '../..'
import { useStylesheet } from '../../utils'

import styles from './style'

interface BankAccountCardLargeProps {
    bankAccount: Partial<AccountDto>,
    clientType?: ClientTypeDto,
    icon?: Image | typeof Icon,
    isDisabled?: boolean,
    skin?: BankAccountCardSkin,
    onActionPress?: (action: BankAccountAction) => void
}

export const BankAccountCardLarge: FunctionComponent<BankAccountCardLargeProps> = (
  {
    bankAccount,
    clientType,
    icon,
    isDisabled = false,
    skin = BankAccountCardSkin.DEFAULT,
    onActionPress,
  }
) => {
  const { t } = useTranslation()

  if (BankAccountUtils.isSecondary(bankAccount as AccountDto)) {
    skin = BankAccountCardSkin.SECONDARY
  }

  const s = useStylesheet(styles,
    {
      skin,
      props: {
        isDisabled,
      },
    })

  const getButton = (wording: any, action: BankAccountAction) => {
    return (
      <Button
        containerStyle={s.button}
        skin={skin === BankAccountCardSkin.SECONDARY
          ? ButtonSkin.OUTLINE_LIGHT
          : (isDisabled ? ButtonSkin.OUTLINE_GRAY : ButtonSkin.OUTLINE_SECONDARY)}
        title={wording}
        onPress={() => (onActionPress && !isDisabled)
          ? onActionPress(action)
          : null}
      />
    )
  }
  return (
    <View testID='bankAccountCardLarge' style={s.container}>
      <View style={s.headerContainer}>
        <View style={s.leftArea} accessibilityLabel='icon'>
          {icon}
        </View>
      </View>
      <View style={s.bodyContainer}>
        {typeof bankAccount.balance === 'number' &&
          <Typography style={s.balance} typeface='bigTitle1'>
            {NumbersUtils.displayPriceForHuman(bankAccount.balance)}
          </Typography>}
        <Typography style={s.accountName} typeface='bold'>
          {BankAccountUtils.getName(bankAccount as AccountDto)}
        </Typography>
        <Typography style={s.accountSubtitle} typeface='subtitle'>
          {BankAccountUtils.isProject(bankAccount as AccountDto) ? bankAccount.type : clientType}
        </Typography>
      </View>
      <View style={s.footerContainer}>
        {BankAccountUtils.isMain(bankAccount as AccountDto) &&
          <>
            {getButton(t('neo-commons:account:credit'), BankAccountAction.CREDIT)}
            {getButton(t('neo-commons:account:myIban'), BankAccountAction.IBAN)}
          </>}
        {BankAccountUtils.isAggregated(bankAccount as AccountDto) &&
          <>
            {getButton(t('neo-commons:account:myIban'), BankAccountAction.IBAN)}
          </>}
        {BankAccountUtils.isProject(bankAccount as AccountDto) &&
          <>
            {getButton(t('neo-commons:account:transfer'), BankAccountAction.TRANSFER)}
          </>}
        {BankAccountUtils.isPos(bankAccount as AccountDto) &&
          <>
            {getButton(t('neo-commons:account:collect'), BankAccountAction.COLLECT)}
          </>}
      </View>
      {isDisabled &&
        <View style={{ ...StyleSheet.absoluteFillObject, borderRadius: 15, backgroundColor: 'transparent' }} />}

    </View>
  )
}
