import React from 'react'

import { Loader } from '@neo-web/componentsOld'
import './LazyLoadingContainer.scss'

interface LazyLoadingContainerProps {
  events?: string[],
  children?: any;
  className?: string;
  isShow?: boolean;
  isNotComponent?: boolean;
}

export const LazyLoadingContainer: React.FC<LazyLoadingContainerProps> = (props: LazyLoadingContainerProps) => {
  return (
    <div className={`LazyLoadingContainer_container ${props.className ?? ''}`}>
      {props.events.map((listen) =>
        <Loader key={listen} isNotComponent={props.isNotComponent} isShow={props.isShow} area={listen} />
      )}
      {props.children}
    </div>
  )
}
