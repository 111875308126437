import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { StepComponentProps } from '@neo-commons/policies'
import { POSOrderStep, POSOrderSteps } from '@neo-commons/policies/src/POS/Order'
import {
  BankAccountSelectors,
  OfferActions,
  OfferSelectors,
  OfferTypes,
  PosActions,
  PosSelectors,
  PosTypes,
} from '@neo-commons/store'
import { PosProductData, POSUtils, SubscriptionUtils } from '@neo-commons/libraries'
import { Button, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { AccountDto, ClientTypeDto, OfferDto, OfferTypeDto, PosDto } from '@afone/neo-core-client/dist/models'

import {
  JustifyContent,
  LazyLoadingContainer,
  PageContent,
  ShimmerList,
  ShimmerListType,
  SuspenseLoading,
} from '@neo-web/componentsOld'

import { PosModelCard } from './PosModelCard'

type POSOrderModelChoiceProps = StepComponentProps<POSOrderSteps[POSOrderStep.ModelChoice]>

export const PosSelection: React.FC<POSOrderModelChoiceProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const POS_MAX_QUANTITY = 4

  const offers: OfferDto[] = useSelector(OfferSelectors.listByType(OfferTypeDto.POS))
  const products = useMemo(() => {
    return offers.length ? offers[0].products.filter(product => {
      return SubscriptionUtils.isPosModel(product)
    }) : []
  }, [offers])
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts())
  const pos: PosDto[] = useSelector(PosSelectors.listPos())
  const posNumber = useMemo(() => pos.length, [pos])

  const [selectedQuantity, setSelectedQuantity] = useState({
    total: 0,
    models: {},
  })

  const setQuantity = (ope, product) => {
    const total = selectedQuantity.total
    const modelCode = product.productCode
    const modelQuantity = selectedQuantity.models[modelCode] ?? 0
    const operation = ope === 'add'
      ? (total + posNumber < POS_MAX_QUANTITY ? 1 : 0)
      : (modelQuantity > 0 ? -1 : 0)

    product.data = { ...product.data, quantity: modelQuantity + operation }

    setSelectedQuantity({
      total: total + operation,
      models: { ...selectedQuantity.models, [modelCode]: modelQuantity + operation },
    })
  }

  useEffect(() => {
    (async () => {
      try {
        await dispatch(PosActions.list(bankAccounts))
      } catch (error) {}

      if (!offers.length) {
        try {
          await dispatch(OfferActions.listForClientUuid())
          await dispatch(OfferActions.list(undefined, undefined, ClientTypeDto.CORPORATE))
        } catch (error) {}
      } else {
        const init = { total: 0, models: {} }
        products.forEach(product => {
          const proQuantity = POSUtils.getProductQuantity(product)
          if (proQuantity > 0) {
            init.total += proQuantity
            init.models = { ...init.models, [product.productCode]: proQuantity }
          }
        })
        setSelectedQuantity(init)
      }
    })()
  }, [])

  return (
    <PageContent
      justifyContent={JustifyContent.START}
      title={t('app:pages:services:pos:order:choicesPosTitle')}
      footer={(
        <>
          <div style={{ height: 25, textAlign: 'center' }}>
            {selectedQuantity.total + posNumber >= POS_MAX_QUANTITY &&
              <Typography typeface='smallTextWeightMedium' style={{ color: Colors.error }}>
                {t('app:pages:services:pos:order:posLimit', { number: POS_MAX_QUANTITY })}
              </Typography>}
          </div>
          <Button
            title={`${t('app:pages:services:pos:order:selectModel')} (${selectedQuantity.total})`}
            disabled={selectedQuantity.total === 0 || posNumber >= POS_MAX_QUANTITY}
            onPress={() => {
              nextStep({
                offerUuid: offers[0]?.uuid,
                posProducts: products.filter(pro =>
                  (pro.data as PosProductData)?.quantity && (pro.data as PosProductData)?.quantity > 0
                ),
              })
            }}
          />
        </>
      )}
    >
      <LazyLoadingContainer events={[PosTypes.SELECT_POS_REQUEST]}>
        <SuspenseLoading
          events={[
            OfferTypes.GET_OFFER_LIST_REQUEST,
            PosTypes.LIST_POS_REQUEST,
          ]}
          fallback={<ShimmerList type={ShimmerListType.POS_ORDER} />}
        >
          {POSUtils.sortProductsByPrice(products).map((product, index) => {
            const modelQuantity = selectedQuantity.models[product.productCode] ?? 0
            return (
              <PosModelCard
                key={index}
                product={product}
                quantity={modelQuantity}
                setQuantity={(ope, pro) => setQuantity(ope, pro)}
                maxQuantityReached={selectedQuantity.total + posNumber >= POS_MAX_QUANTITY}
              />
            )
          })}
        </SuspenseLoading>
      </LazyLoadingContainer>
    </PageContent>
  )
}
