import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { StateTypes } from 'Store'

import { Colors } from '@neo-commons/styles'
import { Icon, IconTypes, MenuItemCard } from '@neo-commons/components'

import { PageDetails } from '@neo-web/componentsOld'

import { Routes } from '../../../../../Routes/Routes'

import './BankAccountCreate.scss'

export const BankAccountCreate: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const userData = useSelector((state: StateTypes) => state.user.data)

  const isAggregateActive = userData?.features?.aggregator?.active

  return (
    <>
      <PageDetails
        title={t('app:global:addAccount')}
        onClose={() => history.push(Routes.Synthesis.index.path)}
        component={(
          <div className='BankAccountCreate'>

            <div className='BankAccountCreate_content'>
              <MenuItemCard
                testID='crazyNoelse-button'
                title={t('app:pages:synthesis:bankAccount:create:crazyNoelse:title')}
                subtitle={t('app:pages:synthesis:bankAccount:create:crazyNoelse:subTitle')}
                icon={
                  <Icon
                    name='dark-logo'
                    color={Colors.primary}
                    type={IconTypes.NEOFONT}
                    size={32}
                  />
                }
                chevronPosition='center'
                onPress={() => null}
                availableSoon
                disabled
              />
              <MenuItemCard
                testID='bindBankAccount-button'
                title={t('app:pages:synthesis:bankAccount:create:bindBankAccount:title')}
                subtitle={t('app:pages:synthesis:bankAccount:create:bindBankAccount:subTitle')}
                icon={
                  <Icon
                    name='account-balance'
                    color={Colors.primary}
                    type={IconTypes.MATERIAL}
                    size={32}
                  />
                }
                chevronPosition='center'
                onPress={() => history.push(Routes.Services.aggregationSearch.path)}
                disabled={!isAggregateActive}
                availableSoon={!isAggregateActive}
              />
            </div>

          </div>
        )}
      />
    </>
  )
}
