import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { PosActions, PosSelectors, PosTypes } from '@neo-commons/store'

import { PosDispatchDto, PosDispatchStatusDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, LazyLoadingContainer, PageContent, PageDetails } from '@neo-web/componentsOld'

import { DispatchListItem } from './DispatchListItem'

export const DispatchList: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const dispatches: PosDispatchDto[] = useSelector(PosSelectors.listDispatches) ?? []

  const inProgress = dispatches.filter(r => r.status === PosDispatchStatusDto.IN_PROGRESS)
  const dispatched = dispatches.filter(r => r.status === PosDispatchStatusDto.DISPATCHED)

  useEffect(() => {
    (async () => {
      if (!dispatches.length) {
        try {
          await dispatch(PosActions.listDispatches())
        } catch (error) {}
      }
    })()
  }, [])

  return (
    <PageDetails
      title={t('neo-commons:pages:services:pos:return:dispatch:list:pageTitle')}
      onBack={() => history.push(Routes.Services.pos.path)}
      component={
        <LazyLoadingContainer events={[PosTypes.LIST_DISPATCHES_REQUEST]}>
          <PageContent justifyContent={JustifyContent.START}>
            {!!inProgress.length &&
              <DispatchListItem
                title={PosDispatchStatusDto.IN_PROGRESS}
                dispatches={inProgress}
              />}
            {!!dispatched.length &&
              <DispatchListItem
                title={PosDispatchStatusDto.DISPATCHED}
                dispatches={dispatched}
              />}
          </PageContent>
        </LazyLoadingContainer>
      }
    />
  )
}
