import { ReactElement } from 'react'

import { AnyAction, Dispatch, Reducer } from 'redux'

import { AlertType, ConfigActions } from '@neo-commons/store'
import { NeobankApi } from '@neo-commons/services'

import { RoutesActions } from '../Routes/Routes'

export interface AlertProps {
  type: AlertType | null;
  title?: string | ReactElement | null;
  description?: string | null;
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types
\* %%%%%%%%%%%%%%%%%% */

/* -------------- Types -------------- */
const STARTUP_REQUEST = 'root/STARTUP_REQUEST'
const STARTUP_FAILED = 'root/STARTUP_FAILED'
const STARTUP_SUCCESS = 'root/STARTUP_SUCCESS'

const DISPLAY_ALERT = 'root/DISPLAY_ALERT'
const RESET_ALERT = 'root/RESET_ALERT'

const DISPLAY_GLOBAL_MODAL = 'root/DISPLAY_GLOBAL_MODAL'
const RESET_GLOBAL_MODAL = 'root/RESET_GLOBAL_MODAL'

const ROOT_UPDATE = 'root/ROOT_UPDATE'
const RESET_ROOT_UPDATE = 'root/RESET_ROOT_UPDATE'

export const RootTypes = {
  ROOT_UPDATE,
  RESET_ROOT_UPDATE,

  DISPLAY_ALERT,
  RESET_ALERT,

  DISPLAY_GLOBAL_MODAL,
  RESET_GLOBAL_MODAL,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators
\* %%%%%%%%%%%%%%%%%% */
const startup = function () {
  return async (dispatch, getState) => {
    // Get app config.
    try {
      dispatch({ type: STARTUP_REQUEST })
      NeobankApi.getInstance().setAuthToken(getState().signIn?.data?.token)
      await dispatch(ConfigActions.getConfig())
      await dispatch(RoutesActions.getRoutesAvailable())
      dispatch({ type: STARTUP_SUCCESS })
    } catch (e) {
      dispatch({ type: STARTUP_FAILED })
    }
  }
}

const alert = function (alert: AlertProps) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DISPLAY_ALERT, alert })
  }
}
const resetAlert = function () {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET_ALERT })
  }
}

const resetModalGlobal = function () {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET_GLOBAL_MODAL })
  }
}

export const RootActions = {
  startup,
  resetAlert,
  alert,
  resetModalGlobal,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export interface RootState {
  alert: AlertProps;
  globalModal: {
    showModal: boolean;
    mainIcon: string | null;
    title: string | null;
    subtitle: string | null;
    closable: boolean | null;
    onButtonPress: () => void | undefined;
    buttonText: string | null;
  };
  sca: {
    required: boolean;
    allowedMethods: any[];
    issuerData: any;
  };
  config: {
    refreshSold: boolean;
    refreshSubAccounts: boolean;
  }
}

const initialState: RootState = {
  alert: {
    type: null,
    title: '',
    description: null,
  },
  globalModal: {
    showModal: false,
    mainIcon: null,
    title: '',
    subtitle: '',
    closable: true,
    onButtonPress: () => {
      return undefined
    },
    buttonText: '',
  },
  sca: {
    required: false,
    allowedMethods: [],
    issuerData: null,
  },
  config: {
    refreshSold: false,
    refreshSubAccounts: false,
  },
}

export const root: Reducer = (
  state: RootState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case ROOT_UPDATE:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.config,
        },
      }
    case RESET_ROOT_UPDATE:
      return {
        ...state,
        config: {
          ...initialState.config,
        },
      }
    case DISPLAY_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          ...action.alert,
        },
      }
    case RESET_ALERT:
      return {
        ...state,
        alert: {
          ...initialState.alert,
        },
      }
    case DISPLAY_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: {
          ...state.globalModal,
          ...action.globalModal,
          showModal: true,
        },
      }
    case RESET_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: {
          ...initialState.globalModal,
        },
      }

    default:
      return state
  }
}
