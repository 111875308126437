import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'

import dayjs from 'dayjs'
import { Routes } from 'Routes/Routes'
import i18next from 'i18next'
import _ from 'lodash'
import { StateTypes } from 'Store'
import { PaginationList } from 'UI/Components/List/PaginationList'

import { MenuItemCard } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { BankAccountSelectors, PaginateItem, StatementActions, StatementSelectors, StatementTypes } from '@neo-commons/store'

import { AccountStatementDto } from '@afone/neo-core-client/dist/models'

import { useProfileDocumentPage } from '@neo-web/hooks'

import { resources, DateFormat } from '../../../../../I18n'

import { DocumentType } from './InvoiceAndContractDetail'

import './Statements.scss'

export interface GroupStatements extends PaginateItem<AccountStatementDto> {
  date: number,
}

export const Statements: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const dateFormat: DateFormat = resources[i18next.language].formats

  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const statementsList = useSelector(StatementSelectors.list)
  const statementsState = useSelector((state: StateTypes) => state?.statement)
  const paginationEnded = useSelector((state: StateTypes) => state.statement.list.paginationEnded)
  const currentPage = useSelector((state: StateTypes) => state.statement.list.page)

  const accountClosure = selectedBankAccount?.accountClosure

  useProfileDocumentPage(DocumentType.STATEMENT)

  const getMonthYearText = (date: string): string => {
    const monthYear = dayjs(date).format(dateFormat.monthAndYearVerbose)

    return t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:statementsByMonth',
      { monthYear: monthYear.charAt(0).toUpperCase() + monthYear.slice(1) })
  }

  const groupStatements = (statements: AccountStatementDto[], field = 'month',
    orders: 'desc'|'asc' = 'desc'): PaginateItem<AccountStatementDto>[] => {
    return _.orderBy(
      statements.reduce((previousValue: GroupStatements[], currentValue: AccountStatementDto) => {
        const year = dayjs(currentValue[field]).year().toString()

        const existing = previousValue.find(group => group.title === year)
        if (existing) {
          existing.data.push(currentValue)
          existing.data = _.orderBy(existing.data, field, 'desc')
        } else {
          previousValue.push({ title: year, date: dayjs(currentValue[field]).unix(), data: [currentValue] })
        }
        return previousValue
      }, [])
      , ['date'], [orders])
  }

  const getNext = () => {
    (async () => {
      if (!paginationEnded && !statementsState.loading) {
        const page = (currentPage ?? 0) + 1
        await dispatch(StatementActions.getStatementByAccountUuid(selectedBankAccount.uuid, page))
      }
    })()
  }

  return (
    <div className='Statements_container'>
      <PaginationList
        state={statementsState}
        stateList={statementsList}
        items={(statement) =>
          <MenuItemCard
            key={statement.documentUuid}
            title={getMonthYearText(statement.month)}
            onPress={() => {
              history.push(generatePath(
                Routes.Profile.documentDetail.path,
                { documentUuid: statement.documentUuid, type: DocumentType.STATEMENT }
              ))
            }}
            chevronPosition='center'
            chevronColor={Colors.darkGreyText}
            subtitleStyle={{ color: accountClosure ? Colors.error : Colors.black }}
          />}
        next={() => getNext()}
        eventLoading={StatementTypes.LIST_STATEMENT_REQUEST}
        groupData={groupStatements(statementsList)}
        refreshFunction={() => {
          dispatch({ type: StatementTypes.LIST_STATEMENT_REQUEST })
          dispatch(StatementActions.getStatementByAccountUuid(selectedBankAccount.uuid))
        }}
      />
    </div>
  )
}
