import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { CardStepper, CardStepperItem, JustifyContent, PageContent } from '@neo-web/componentsOld'

interface PedagogyScreenProps {
  buttons: ReactElement,
}

export const Screen7: React.FC<PedagogyScreenProps> = (props) => {
  const { t } = useTranslation()

  const listSteps: CardStepperItem[] = [
    {
      icon: <Icon name='calculator' type={IconTypes.NEOFONT} size={28} color={Colors.secondary} />,
      text: t('neo-commons:pages:services:pos:return:anomalyPedagogy:screen7:stepper1'),
    }, {
      icon: <Icon name='power-control' type={IconTypes.NEOFONT} size={28} color={Colors.secondary} />,
      text: t('neo-commons:pages:services:pos:return:anomalyPedagogy:screen7:stepper2'),
    }, {
      icon: <Icon name='pin' type={IconTypes.NEOFONT} size={28} color={Colors.secondary} />,
      text: t('neo-commons:pages:services:pos:return:anomalyPedagogy:screen7:stepper3'),
    },
  ]

  return (
    <PageContent
      justifyContent={JustifyContent.BETWEEN}
      title={t('neo-commons:pages:services:pos:return:anomalyPedagogy:title')}
      titleInBody
      description={t('neo-commons:pages:services:pos:return:anomalyPedagogy:screen7:description')}
      footer={props.buttons}
    >
      <CardStepper list={listSteps} />
    </PageContent>
  )
}
