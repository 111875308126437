import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { FlexboxGrid } from 'rsuite'

import { SignupActions } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  Typography,
} from '@neo-commons/components'

import { Modal } from '@neo-web/componentsOld'

interface RegisterRecoveryModalProps {
  show: boolean
  next: () => void
}

export const RegisterRecoveryModal: React.FC<RegisterRecoveryModalProps> = (
  props: RegisterRecoveryModalProps
) => {
  const { t } = useTranslation()

  const [openModal, setOpenModal] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    setOpenModal(props.show)
  }, [props])

  return (
    <div className='modal-container'>
      <Modal autoFocus open={openModal}>
        <FlexboxGrid
          justify='center'
          className='text-center'
        >
          <FlexboxGrid.Item>
            <Icon
              color={Colors.secondary}
              name='alert-circle'
              size={40}
              type={IconTypes.FEATHER}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={24} className='py-md'>
            <Typography typeface='bigTitle'>
              {t('app:pages:register:recovery:titleMessage')}
            </Typography>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={24} className='py-md'>
            <Button
              title={t('app:global:continue')}
              skin={ButtonSkin.PRIMARY}
              small
              onPressOut={() => {
                props.next()
                setOpenModal(false)
              }}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={24}>
            <Button
              title={t('app:pages:register:recovery:retry')}
              skin={ButtonSkin.LINK}
              onPressOut={async () => {
                dispatch(SignupActions.reset())
                // Refresh is delay to be sure sign up store is reset
                setTimeout(() => {
                  history.go(0)
                }, 50)
              }}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal>
    </div>
  )
}
