import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'
import { TransferPolicy } from 'Policies/TransferPolicy'

import {
  ContactSelectors,
  ContactTypes,
  TransferActions,
} from '@neo-commons/store'
import { Button, ButtonSkin, ChannelContactCard } from '@neo-commons/components'

import { ContactChannelDto, ContactDto } from '@afone/neo-core-client/dist/models'

import { LazyLoadingContainer } from '@neo-web/componentsOld'

import './Target.scss'

export const Target: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const transferState = useSelector((state: StateTypes) => state?.transfer)
  const onGoingTransfer = transferState.prepare
  const contactSelected: ContactDto = useSelector(ContactSelectors.selected)

  const selectChannel = async (iban: ContactChannelDto) => {
    try {
      dispatch(TransferActions.prepare({
        ...onGoingTransfer,
        contactChannelUuid: iban?.uuid,
        contactChannelValue: iban?.value,
        contactFamilyName: contactSelected.familyName,
        contactGivenName: contactSelected.givenName,
      }))
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <LazyLoadingContainer events={[
      ContactTypes.UPDATE_CONTACT_REQUEST,
      ContactTypes.CREATE_CONTACT_REQUEST,
      ContactTypes.BYID_CONTACT_REQUEST]}
    >
      <div
        className='Target_container'
      >

        <div className='Target_item'>
          {contactSelected && contactSelected.channels.map((channel: ContactChannelDto) =>
            <div key={channel.uuid} className='Target_card'>
              <ChannelContactCard
                label={channel.label}
                value={channel.value}
                isNoElse={channel.applicationUser}
                selected={channel.uuid === onGoingTransfer.contactChannelUuid}
                onEditPress={() => {
                  selectChannel(channel)
                }}
              />
            </div>
          )}
        </div>

        <div className='SelectTransferSourceAccount_action'>
          <Button
            title={t('app:pages:payments:transfer:account:cancel')}
            skin={ButtonSkin.OUTLINE_PRIMARY}
            onPressOut={() => {
              history.push(onGoingTransfer.isUpdating ? generatePath(
                Routes.Payments.transferUpdate.path,
                { id: onGoingTransfer.uuid }
              ) : Routes.Payments.index.path)
            }}
          />
          <Button
            title={t('app:pages:payments:transfer:account:validate')}
            skin={ButtonSkin.PRIMARY}
            disabled={!TransferPolicy.canStep3(transferState)}
            onPress={() => {
              TransferPolicy.canStep3(transferState) && history.push(
                TransferPolicy.canStep6(transferState) ? onGoingTransfer.isUpdating ? generatePath(
                  Routes.Payments.transferUpdate.path,
                  { id: onGoingTransfer.uuid }
                ) : Routes.Payments.summary.path : Routes.Payments.amount.path)
            }}
          />
        </div>
      </div>
    </LazyLoadingContainer>
  )
}
