import { Dispatch, Reducer, AnyAction } from 'redux'
import i18next from 'i18next'
import { NeobankApi } from '@neo-commons/services'
import { ContactPreferenceDto, ContactPreferenceChannelValueDto } from '@afone/neo-core-client/dist/models'

/* %%%%%%%%%%%%%%%%%% *\
    Action Types.
\* %%%%%%%%%%%%%%%%%% */

export const GET_CONTACT_PREFERENCES_REQUEST = 'contactPreferences/GET_CONTACT_PREFERENCES_REQUEST'
export const GET_CONTACT_PREFERENCES_SUCCESS = 'contactPreferences/GET_CONTACT_PREFERENCES_SUCCESS'
export const GET_CONTACT_PREFERENCES_FAILURE = 'contactPreferences/GET_CONTACT_PREFERENCES_FAILURE'

export const UPDATE_CONTACT_PREFERENCES_REQUEST = 'contactPreferences/UPDATE_CONTACT_PREFERENCES_REQUEST'
export const UPDATE_CONTACT_PREFERENCES_SUCCESS = 'contactPreferences/UPDATE_CONTACT_PREFERENCES_SUCCESS'
export const UPDATE_CONTACT_PREFERENCES_FAILURE = 'contactPreferences/UPDATE_CONTACT_PREFERENCES_FAILURE'

export const ContactPreferencesTypes = {
  GET_CONTACT_PREFERENCES_REQUEST,
  GET_CONTACT_PREFERENCES_SUCCESS,
  GET_CONTACT_PREFERENCES_FAILURE,

  UPDATE_CONTACT_PREFERENCES_REQUEST,
  UPDATE_CONTACT_PREFERENCES_SUCCESS,
  UPDATE_CONTACT_PREFERENCES_FAILURE,
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */

const getContactPreferences = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GET_CONTACT_PREFERENCES_REQUEST })
    try {
      const payload = await NeobankApi.getInstance().contactPreferenceApi.getContactPreferences()
      const contactPreferences = payload.data
      dispatch({ type: GET_CONTACT_PREFERENCES_SUCCESS, contactPreferences })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: GET_CONTACT_PREFERENCES_FAILURE, errorMessage })
    }
  }
}

const updateContactPreferences = (uuid: string, contactPreferenceChannelValue: ContactPreferenceChannelValueDto[]) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_CONTACT_PREFERENCES_REQUEST })
    try {
      await NeobankApi.getInstance().contactPreferenceApi
        .updateContactPreferenceByUuid(uuid, contactPreferenceChannelValue)
      dispatch({ type: UPDATE_CONTACT_PREFERENCES_SUCCESS, data: { uuid, contactPreferenceChannelValue } })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: UPDATE_CONTACT_PREFERENCES_FAILURE, errorMessage })
    }
  }
}

export const ContactPreferencesActions = {
  getContactPreferences,
  updateContactPreferences,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export interface ContactPreferencesState {
  ui: {
    isUpdating: boolean;
    isUpdated: boolean;
    isFetching: boolean;
    isFetched: boolean;
  },
  data: ContactPreferenceDto[]
}

const INITIAL_STATE: ContactPreferencesState = {
  ui: {
    isUpdating: false,
    isUpdated: false,
    isFetching: false,
    isFetched: false,
  },
  data: [],
}

export const contactPreferences: Reducer = (state: ContactPreferencesState = INITIAL_STATE, action: AnyAction) => {
  let newDataState: ContactPreferenceDto[] = []

  switch (action.type) {
    case GET_CONTACT_PREFERENCES_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isFetching: true,
        },
      }

    case GET_CONTACT_PREFERENCES_SUCCESS:

      return {
        ...state,
        ui: {
          ...state.ui,
          isFetched: true,
          isFetching: false,
        },
        data: action.contactPreferences,
      }

    case GET_CONTACT_PREFERENCES_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isFetching: false,
          errorMessage: action.errorMessage,
        },
      }

    case UPDATE_CONTACT_PREFERENCES_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdating: true,
        },
      }

    case UPDATE_CONTACT_PREFERENCES_SUCCESS:
      newDataState = state.data.map(item => {
        const newItemState = { ...item }
        if (item.uuid === action.data.uuid) {
          newItemState.channels = action.data.contactPreferenceChannelValue
        }
        return newItemState
      })

      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdated: true,
          isUpdating: false,
        },
        data: newDataState,
      }

    case UPDATE_CONTACT_PREFERENCES_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdating: false,
          errorMessage: action.errorMessage,
        },
      }
    default:
      return state
  }
}
