import React, { FunctionComponent, useMemo } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { Colors } from '@neo-commons/styles'

import { Typography } from '..'
import { Card, CardSkin } from '../Card/Card'
import { useStylesheet } from '../utils'

import styles from './styles'

interface SkinsState {
  default?: CardSkin,
  disabled?: CardSkin,
  selected?: CardSkin
}

interface OfferOptionCardProps {
  title: string,
  subtitle?: string | string[],
  onPress?: () => void,
  selected?: boolean,
  amountText?: string,
  testID?: string,
  disabled?: boolean,
  skins?: SkinsState,
  withShadow?: boolean
 }

const getSkin = (selected?: boolean, disabled?: boolean, skins?: SkinsState): CardSkin => {
  if (disabled) {
    return skins?.disabled ?? CardSkin.DISABLED
  }
  if (selected) {
    return skins?.selected ?? CardSkin.PRIMARY
  }
  return skins?.default ?? CardSkin.TOUCHABLE
}

export const OfferOptionCard: FunctionComponent<OfferOptionCardProps> = ({
  title,
  subtitle,
  disabled,
  onPress,
  selected,
  withShadow,
  amountText,
  testID,
  skins,
}) => {
  const skin = useMemo(() => getSkin(selected, disabled, skins), [selected, disabled, skins])
  const s = useStylesheet(styles,
    {
      skin,
      props: {
        selected, disabled,
      },
    })

  const getSubtitle = (subtitle) => {
    if (typeof subtitle === 'string') {
      return <Typography typeface='itemTitle' style={s.subtitle}>{subtitle}</Typography>
    }

    return subtitle.map((text, index) =>
      <Typography
        typeface='itemTitle'
        key={index}
        style={s.subtitle}
      >
        {text}
      </Typography>
    )
  }

  return (
    <TouchableOpacity
      testID={testID}
      onPress={() => {
        if (!disabled) {
          return onPress?.()
        }
      }}
      disabled={disabled}
    >
      <Card
        testID={`${testID}-card`}
        withShadow={withShadow}
        skin={skin}
        containerStyle={s.container}
      >
        <View style={s.textsArea}>
          <Typography
            typeface='smallText'
            style={{ color: selected ? Colors.white : Colors.black }}
          >
            {title}
          </Typography>
          {subtitle && getSubtitle(subtitle)}
        </View>
        {amountText && <Typography style={s.amountArea} typeface='bigTitle'>{amountText}</Typography>}
      </Card>
    </TouchableOpacity>
  )
}
