import { FlagsImages } from './flags'
import { CreditCardsImages } from './creditCards'
import { NoElseContactImage } from './contact'
import { CreditCardsSchemesImages } from './cbSchemes'
import { BankAccountLogoImages } from './bankAccountLogo'

export const Flags = FlagsImages
export const CreditCards = CreditCardsImages
export const NoElseContact = NoElseContactImage
export const CreditCardsSchemes = CreditCardsSchemesImages
export const BankAccountLogo = BankAccountLogoImages
