import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AppConfig } from 'Config/AppConfig'
import { StateTypes } from 'Store'
import { ProfileRoutes } from 'Routes/Profile'

import { ResetPasscodeStep, ResetPasscodeSteps, StepComponentProps } from '@neo-commons/policies'
import { RecoverPasscodeActions } from '@neo-commons/store'
import { usePasswordKeypad } from '@neo-commons/hooks'

import { KeypadFieldDto } from '@afone/neo-core-client/dist/models'

import { CodeConfirm } from '@neo-web/componentsOld'

import '../Step1/PreviousCode.scss'

type ConfirmPasswordProps = StepComponentProps<ResetPasscodeSteps[ResetPasscodeStep.NewCodeConfirm]>

export const PasswordReset: React.FC<ConfirmPasswordProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const recoverPasscode = useSelector((state: StateTypes) => state.recoverPasscode)
  const signInData = useSelector((state: StateTypes) => state?.signIn?.data)
  const passwordKeypadData = usePasswordKeypad({
    phone: signInData?.phone,
    phoneCountryCode: signInData?.countryCode,
    field: KeypadFieldDto.CONFIRM_PASSWORD,
    passwordSize: AppConfig.PASS_CODE_SIZE,
  })

  const handleNextStepError = () => {
    passwordKeypadData.reset()
    history.push(ProfileRoutes.preference.path)
  }

  const onValidate = async () => {
    try {
      await dispatch(RecoverPasscodeActions.prepare({
        ...recoverPasscode,
        confirmNewPassword: passwordKeypadData.password.map(e => e.value),
      }))

      await props.nextStep()
        .onError(handleNextStepError)
    } catch (error) {
      handleNextStepError()
    }
  }

  return (
    <div className='PreviousCode_container'>
      <div className='PreviousCode_item'>
        <CodeConfirm
          title={t('app:pages:myProfile:codeReset:confirmCode:title')}
          onValidate={onValidate}
          keypadData={passwordKeypadData}
        />
      </div>
    </div>
  )
}
