import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'
import Colors from 'UI/Resources/Styles/sass/themes/Colors'

import { EnumUtils } from '@neo-commons/libraries'
import { Field, MenuItemCard, Typography2 } from '@neo-commons/components'
import { ClientSelectors } from '@neo-commons/store'

import { ClientDto } from '@afone/neo-core-client/models'

import { PageDetails, ProgressBar } from '@neo-web/componentsOld'

import { EditModal } from '../../Components/EditModal'
import {
  RevenueField,
  RealActivityField,
  EmployeesNumberField,
  AverageBasketField,
  UrlField,
  TaxIdentificationNumberField,
} from '../CompanyField'

import './CompanyProfile.scss'

export const CompanyProfile: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const client: ClientDto = useSelector(ClientSelectors.selected)

  const profileCompletionPercentage = client?.holder?.completion ?? 0

  const [showEditModal, setShowEditModal] = useState(false)
  const [editModalTitle, setEditModalTitle] = useState('')
  const [editModalContent, setEditModalContent] = useState('')
  // for modal overflow
  const [editModalFooter, setEditModalFooter] = useState('')

  const openModalEdit = (title: string, content?, footer?) => {
    setEditModalTitle(title)
    setEditModalContent(content)
    setEditModalFooter(footer)
    setShowEditModal(true)
  }

  return (
    <PageDetails
      title='Informations Société'
      component={
        <div className='CompanyProfile'>
          <div className='CompanyProfile_container'>
            <div className='CompanyProfile_container_progress'>
              <Typography2 typeface='overline' style={{ color: Colors.textColor }}>
                {t('app:pages:myProfile:myInfo:profile:state')}
              </Typography2>
              <div className='CompanyProfile_container_progress_percentage'>
                <ProgressBar percentage={profileCompletionPercentage} color={profileCompletionPercentage === 100 ? Colors.success : Colors.primary} />
                <Typography2 typeface='h6' style={{ color: profileCompletionPercentage === 100 ? Colors.success : Colors.disable }}>
                  {t('app:pages:myProfile:myInfo:profile:full')}
                </Typography2>
              </div>
            </div>
            <div className='CompanyProfile_container_title'>
              <Typography2 typeface='overline'>
                {t('app:pages:myProfile:informations:companyProfile:identity:label')}
              </Typography2>
            </div>
            <div className='CompanyProfile_container_section'>
              <div className='CompanyProfile_container_section_field'>
                <Field
                  label={t('app:pages:myProfile:informations:companyProfile:identity:tradeName')}
                  value={client?.holder?.commercialName}
                />
              </div>
              <div className='CompanyProfile_container_section_field'>
                <Field
                  label={t('app:pages:myProfile:informations:companyProfile:identity:legalName')}
                  value={client?.holder?.legalName}
                />
              </div>
              <div className='CompanyProfile_container_section_field'>
                <Field
                  label={t('app:pages:myProfile:informations:companyProfile:identity:legalForm')}
                  value={client?.holder?.legalForm?.name}
                />
              </div>
            </div>
            <div className='CompanyProfile_container_title'>
              <Typography2 typeface='overline'>
                {t('app:pages:myProfile:informations:companyProfile:affiliates:label')}
              </Typography2>
            </div>
            <div className='CompanyProfile_container_section_menuItem'>
              {client?.holder?.affiliates.map((affiliate, index) => {
                return (
                  <MenuItemCard
                    key={index}
                    title={affiliate.person?.fullName}
                    subtitle={t(EnumUtils.getAffiliationTypeDtoKey(affiliate.types[0]))}
                    onPress={() => {
                      history.push(
                        generatePath(Routes.Profile.affiliateProfile.path, { id: affiliate.uuid })
                      )
                    }}
                  />
                )
              })}
            </div>
            <div className='CompanyProfile_container_title'>
              <Typography2 typeface='overline'>
                {t('app:pages:myProfile:informations:companyProfile:otherInfos:label')}
              </Typography2>
            </div>
            <div className='CompanyProfile_container_section'>
              <div className='MyInfo_field'>
                <Field
                  label={t('app:pages:myProfile:informations:companyProfile:otherInfos:revenue')}
                  value={
                    client?.holder?.revenue
                      ? t('app:global:enum:revenue:' +
                    client?.holder?.revenue?.replace(/(_)/g, '').toLowerCase())
                      : ''
                  }
                  onEditPress={() => {
                    openModalEdit(t('app:pages:myProfile:informations:companyProfile:otherInfos:revenue'),
                      <RevenueField
                        client={client}
                        onCloseModal={() => { setShowEditModal(false) }}
                      />)
                  }}
                />
              </div>
              <div className='CompanyProfile_container_section_field'>
                <Field
                  label={t('app:pages:myProfile:informations:companyProfile:otherInfos:realActivity')}
                  value={client?.holder?.realActivity}
                  onEditPress={() => {
                    openModalEdit(t('app:pages:myProfile:informations:companyProfile:otherInfos:realActivity'),
                      <RealActivityField
                        client={client}
                        onCloseModal={() => { setShowEditModal(false) }}
                      />)
                  }}
                />
              </div>
              <div className='CompanyProfile_container_section_field'>
                <Field
                  label={t('app:pages:myProfile:informations:companyProfile:otherInfos:employeesNumber')}
                  value={
                    client?.holder?.employeesNumber
                      ? t('app:global:enum:employeesNumber:' +
                    client?.holder?.employeesNumber?.replace(/(_)/g, '').toLowerCase())
                      : ''
                  }
                  onEditPress={() => {
                    openModalEdit(t('app:pages:myProfile:informations:companyProfile:otherInfos:employeesNumber'),
                      <EmployeesNumberField
                        client={client}
                        onCloseModal={() => { setShowEditModal(false) }}
                      />)
                  }}
                />
              </div>
              <div className='CompanyProfile_container_section_field'>
                <Field
                  label={t('app:pages:myProfile:informations:companyProfile:otherInfos:averageBasket')}
                  value={client?.holder?.averageBasket?.toString()}
                  onEditPress={() => {
                    openModalEdit(t('app:pages:myProfile:informations:companyProfile:otherInfos:averageBasket'),
                      <AverageBasketField
                        client={client}
                        onCloseModal={() => { setShowEditModal(false) }}
                      />)
                  }}
                />
              </div>
              <div className='CompanyProfile_container_section_field'>
                <Field
                  label={t('app:pages:myProfile:informations:companyProfile:otherInfos:taxIdentificationNumber')}
                  value={client?.holder?.taxIdentificationNumber}
                  onEditPress={() => {
                    openModalEdit(t('app:pages:myProfile:informations:companyProfile:otherInfos:taxIdentificationNumber'),
                      <TaxIdentificationNumberField
                        client={client}
                        onCloseModal={() => { setShowEditModal(false) }}
                      />)
                  }}
                />
              </div>
              <div className='CompanyProfile_container_section_field'>
                <Field
                  label={t('app:pages:myProfile:informations:companyProfile:otherInfos:url')}
                  value={client?.holder?.url}
                  onEditPress={() => {
                    openModalEdit(t('app:pages:myProfile:informations:companyProfile:otherInfos:url'),
                      <UrlField
                        client={client}
                        onCloseModal={() => { setShowEditModal(false) }}
                      />)
                  }}
                />

              </div>
            </div>
          </div>
          <EditModal
            title={editModalTitle} show={showEditModal}
            onClose={() => {
              setShowEditModal(false)
            }}
            content={editModalContent}
            footer={editModalFooter}
          />
        </div>
      }
    />
  )
}
