
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { FlexboxGrid, SelectPicker } from 'rsuite'

import {
  Button,
  ButtonSkin,
} from '@neo-commons/components'
import { ClientActions, ClientTypes } from '@neo-commons/store'

import { ClientDto, RevenueDto } from '@afone/neo-core-client/dist/models'

import { useResponsive } from '@neo-web/hooks'
import { LazyLoadingContainer } from '@neo-web/componentsOld'

export interface RevenueFieldProps {
  client: ClientDto
  onCloseModal: () => void
}

export const RevenueField: React.FC<RevenueFieldProps> = ({
  client,
  onCloseModal,
} : RevenueFieldProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTabletOrMobile = useResponsive()
  const [revenue, setRevenue] = useState<RevenueDto>(client?.holder?.revenue)

  const updateClientRevenue = () => {
    (async () => {
      try {
        await dispatch(ClientActions.updateHolder(client, {
          ...client?.holder,
          revenue: revenue,
        }))
        onCloseModal()
      } catch (error) {}
    })()
  }

  return (
    <LazyLoadingContainer events={[ClientTypes.UPDATE_CLIENT_HOLDER_REQUEST]}>
      <FlexboxGrid.Item colspan={24}>
        <SelectPicker
          data={Object.entries(RevenueDto).map(
            (value) =>
              ({
                value: value[1],
                label: t('app:global:enum:revenue:' + value[0].replace(/(_)/g, '').toLowerCase()),
              }),
          )}
          onChange={value => {
            setRevenue(value)
          }}
          appearance='subtle'
          defaultValue={revenue}
          cleanable={false}
          searchable={false}
          placement={isTabletOrMobile ? 'topStart' : 'bottomStart'}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24} className='EditModal_saveButton'>
        <Button
          skin={ButtonSkin.PRIMARY}
          disabled={!revenue}
          title={t('app:global:save')}
          onPress={() => { updateClientRevenue() }}
        />
      </FlexboxGrid.Item>
    </LazyLoadingContainer>
  )
}
