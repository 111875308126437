import React from 'react'
import ReactDOM from 'react-dom'

import i18n from 'i18next'
import { inspect } from '@xstate/inspect'

import { BankAccountActions, Dispatch } from '@neo-commons/store'
import { NeobankApi, ComnpayApi, ScaCancelled } from '@neo-commons/services'

import { ENV, config } from './Config/EnvConfig'
import { App } from './App'
import { Monitoring } from './Config/Monitoring'
import { store } from './Store'
import { AnalyticsHandler } from './Services/Analytics'
import { ScaActions } from './Store/Sca'
import './Config/ReactotronConfig'
import './I18n/i18n'

if (config(ENV.NODE_ENV) === 'development') {
  inspect({
    iframe: false, // open in new window
    url: 'https://stately.ai/viz?inspect',
  })
}

/*
 * Allow developers to change NeobankApi baseUrl from console
 */
// eslint-disable-next-line
// @ts-ignore
window.changeApiUrl = (url: string) => {
  sessionStorage.setItem('NEOBANK_API_URL', url)
  window.location.reload()
}
const NEOBANK_API_URL = sessionStorage.getItem('NEOBANK_API_URL') ?? config(ENV.API_URL)

NeobankApi.createInstance({
  basePath: NEOBANK_API_URL,
  debug: config(ENV.PROFILE) === 'DEBUG',
  locale: i18n.language,
  os: 'web',
  suspendedAccountHandler: () => new Promise(() => { (store.dispatch as Dispatch)(BankAccountActions.suspendedAccountAlert()) }),
  scaHandler: ({ authenticationRequestUuid, steps, eventCode }) => new Promise((resolve, reject) => {
    (store.dispatch as Dispatch)(ScaActions.process({ authenticationRequestUuid, steps, eventCode }))

    const unsubscribe = store.subscribe(() => {
      const succeed = store.getState().sca.succeed
      const cancelled = store.getState().sca.cancelled
      if (succeed === null && !cancelled) return

      unsubscribe()

      if (succeed) {
        resolve(null)
      } else {
        if (cancelled) {
          reject(new ScaCancelled(i18n.t('errors:scaRequired')))
        } else {
          reject(new Error())
        }
      }
    })
  }),
})

ComnpayApi.configure({
  apiUrl: config(ENV.COMNPAY_API_URL),
  serialNumber: config(ENV.COMNPAY_VAD_NUMBER),
  origin: config(ENV.COMNPAY_ORIGIN),
})

Monitoring.initSentry()
// BugBattle is deprecated and is crashing the app. To be replaced by gleap in the future
// Monitoring.initBugBattle()
AnalyticsHandler.createInstance()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
