import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { store } from 'Store'
import { Routes } from 'Routes/Routes'

import { posLostPolicy, POSLostStep } from '@neo-commons/policies/src/POS/Lost'
import { useWizardPolicy } from '@neo-commons/hooks'
import { PosActions } from '@neo-commons/store'

import { PageDetails } from '@neo-web/componentsOld'

import {
  SelectPos,
  Summary,
  Success,
  Failure,
} from './index'

export const Lost: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentStep, steps: wizardSteps, goBack, context } = useWizardPolicy(store, posLostPolicy, {
    [POSLostStep.SelectPos]: SelectPos,
    [POSLostStep.Summary]: Summary,
    [POSLostStep.Success]: Success,
    [POSLostStep.Failure]: Failure,
  }, {
    onDone: () => null,
  })

  const previousStep = () => {
    if (context?.stack && context?.stack?.length > 0) goBack()
    else history.push(Routes.Services.managePos.path)
  }

  const closeTunnel = () => {
    (async () => {
      try {
        await dispatch(PosActions.resetPrepareLost())
        history.push(Routes.Services.managePos.path)
      } catch (error) {}
    })()
  }

  return (
    <PageDetails
      component={
        <>
          {currentStep?.path && (
            <Redirect
              to={{
                pathname: currentStep?.path,
              }}
            />
          )}
          {wizardSteps.map((value) =>
            <Route
              key={value.navigationKey}
              exact
              path={value.path}
              component={value.component}
            />
          )}
        </>
      }
      onBack={currentStep?.meta?.canGoBack ? previousStep : null}
      onClose={closeTunnel}
      title={currentStep?.meta?.headerTitle ? t(currentStep.meta.headerTitle) : t('neo-commons:pages:services:pos:lost:title')}
    />
  )
}
