import React, { FunctionComponent } from 'react'
import { TouchableOpacity, ViewStyle } from 'react-native'

import { useStylesheet } from '../utils'

import styles from './styles'

export enum CardSkin {
  DEFAULT = 'DEFAULT',
  PRIMARY_BORDERED = 'PRIMARY_BORDERED',
  PRIMARY_DARKER_BORDERED = 'PRIMARY_DARKER_BORDERED',
  PRIMARY_GOLD_BORDERED = 'PRIMARY_GOLD_BORDERED',
  SECONDARY_BORDERED = 'SECONDARY_BORDERED',
  BACKGROUND_BORDERED = 'BACKGROUND_BORDERED',
  TOUCHABLE = 'TOUCHABLE',
  SECONDARY = 'SECONDARY',
  PRIMARY = 'PRIMARY',
  DISABLED = 'DISABLED',
}

export interface CardProps {
  skin: CardSkin
  onPress?: () => void
  containerStyle?: ViewStyle | ViewStyle[]
  withShadow?: boolean
  testID?: string
}

export const Card: FunctionComponent<CardProps> = (
  {
    children,
    skin,
    onPress,
    containerStyle,
    withShadow,
    testID,
  }) => {
  const s = useStylesheet(styles,
    {
      skin,
      props: {
        skin: skin,
      },
    })

  return (
    <TouchableOpacity
      style={[s.touchableContainer, s.cardContainer, withShadow ? s.shadow : undefined, containerStyle]}
      onPress={onPress}
      disabled={!onPress}
      testID={testID}
    >
      {children}
    </TouchableOpacity>
  )
}
