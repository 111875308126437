import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from '@neo-customer-front/applications/neo-web/src/Store'

import { POSLostStep, POSLostSteps, StepComponentProps } from '@neo-commons/policies'
import {
  Button,
  ButtonSkin,
  EditItemCard,
  EditItemCardSkin,
} from '@neo-commons/components'
import { PosActions, PosTypes, PreparePOSLost } from '@neo-commons/store'

import { JustifyContent, LazyLoadingContainer, PageContent } from '@neo-web/componentsOld'

import { CloseAccountModal } from '../../Return/Step7/CloseAccountModal'

type POSLostProps = StepComponentProps<POSLostSteps[POSLostStep.Summary]>

export const Summary: React.FC<POSLostProps> = ({ editStep, nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const preparedPOSLost: PreparePOSLost = useSelector((state: StateTypes) => state.pos.prepareLost)

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        await dispatch(PosActions.checkLostPos())
      } catch (error) {}
    })()
  }, [])

  const confirmLost = () => {
    if (preparedPOSLost?.response?.accountsToClose?.[0]) {
      setShowModal(true)
    } else {
      nextStep()
    }
  }

  return (
    <LazyLoadingContainer events={[PosTypes.PREPARE_POS_LOST_REQUEST, PosTypes.LOST_POS_REQUEST]}>
      <PageContent
        justifyContent={JustifyContent.BETWEEN}
        footer={
          <Button
            skin={ButtonSkin.PRIMARY}
            title={t('app:global:confirm')}
            onPress={() => confirmLost()}
            disabled={!preparedPOSLost?.response}
          />
        }
      >
        <CloseAccountModal
          open={showModal}
          onClose={() => setShowModal(false)}
          onValidate={nextStep}
        />
        <EditItemCard
          label={t('neo-commons:pages:services:pos:lost:summary:cardLost:label').toUpperCase()}
          skin={EditItemCardSkin.DELIVERY}
          payload={{
            title: `${preparedPOSLost?.pos?.length} ${preparedPOSLost?.pos?.length > 1
              ? t('neo-commons:pages:services:pos:return:summary:severalPos')
              : t('neo-commons:pages:services:pos:return:summary:singlePos')}`,
            subtitles: preparedPOSLost?.pos?.map(p => (p?.serialNumber
              ? `${t('app:pages:services:pos:detail:posSerialNumberShort')}: ${p.serialNumber} `
              : '') + `(${p?.name ?? p.label})`
            ),
          }}
          buttonLabel={t('app:global:edit')}
          onEditPress={() => {
            dispatch(PosActions.prepareLost({
              ...preparedPOSLost,
              response: undefined,
            }))
            editStep(POSLostStep.SelectPos)
          }}
        />
        <EditItemCard
          containerStyle={{ marginTop: 12, paddingBottom: 16 }}
          label={t('neo-commons:pages:services:pos:lost:summary:cardInfo:label').toUpperCase()}
          skin={EditItemCardSkin.DELIVERY}
          payload={{
            title: t('neo-commons:pages:services:pos:lost:summary:cardInfo:subtitle'),
            subtitles: [
              t('neo-commons:pages:services:pos:lost:summary:cardInfo:text'),
            ],
          }}
          hideButton
        />
      </PageContent>
    </LazyLoadingContainer>
  )
}
