import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { CustomerServiceCountryCardList, Typography2 } from '@neo-commons/components'

import { useResponsive } from '@neo-web/hooks'
import { Tunnel } from '@neo-web/componentsOld'

import './ClientService.scss'

export const ClientService: React.FC = () => {
  const isTabletOrMobile = useResponsive()
  const history = useHistory()
  const { t } = useTranslation()
  const url = useLocation().pathname

  return (
    <Tunnel
      onBack={() => history.goBack()}
      quitTunnelDirect
      component={(
        <div className={`ClientService ${!isTabletOrMobile ? 'classic-box' : ''}`}>
          <div className='ClientService_container'>
            {url.includes(Routes.PublicLink.clientService.path) &&
              <div className='ClientService_needHelp'>
                <Typography2 typeface='h4'>
                  {t('app:pages:publicLink:clientService:title')}
                </Typography2>
              </div>}
            {url.includes(Routes.PublicLink.recoverPassword.path) &&
              <div className='ClientService_recoverPassword'>
                <Typography2 typeface='h4'>
                  {t('app:pages:publicLink:clientService:recoverPassword:title')}
                </Typography2>
                <Typography2 typeface='body1'>
                  {t('app:pages:publicLink:clientService:recoverPassword:info')}
                </Typography2>
              </div>}
            <CustomerServiceCountryCardList
              itemContainerStyle={{ marginBottom: 5 }}
            />
          </div>
        </div>
      )}
    />
  )
}
