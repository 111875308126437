import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native-web'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import dayjs from 'dayjs'
import { now } from 'lodash'
import { TunnelContent } from 'UI/Components/Layout/TunnelContent'
import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'

import { Button, ButtonSkin, OTPInput, Stepper2, Timer, Typography2 } from '@neo-commons/components'
import { Dispatch, SignupActions, SignupProfileState } from '@neo-commons/store'
import { RegisterStep, RegisterSteps, StepComponentProps } from '@neo-commons/policies'

import { StateTypes } from '../../../../../Store'
import { AppConfig } from '../../../../../Config/AppConfig'

type SmsProps = StepComponentProps<RegisterSteps[RegisterStep.Sms]>

export const Sms: React.FC<SmsProps> = ({ nextStep }: SmsProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, setToken] = useState('')
  const [disabled, setDisabled] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()

  const signup = useSelector((state: StateTypes) => state.signup)
  const profile: SignupProfileState = useSelector((state: StateTypes) => state?.signup?.profile)
  const [codeFilled, setCodeFilled] = useState('')
  const { phone, countryCode } = signup.profile
  const { verifyPhoneLoading } = signup?.ui
  const [isValid, setIsValid] = useState(false)
  const ref = useRef()

  const [startDate, setStartDate] = useState(dayjs(now()).toString())
  const [endDate, setEndDate] =
    useState(dayjs(now()).add(dayjs.duration(5, 'minute')).toString())

  const handleReCaptchaVerify = async () => {
    const token = await executeRecaptcha('registerStep1_sms')
    setToken(token)
  }

  useEffect(() => {
    if (!executeRecaptcha) {
      return
    }

    (async () => await handleReCaptchaVerify())()
  }, [executeRecaptcha])

  // TODO : remove after refacto OTPinput
  useEffect(() => {
    setIsValid(
      !verifyPhoneLoading && codeFilled.length === AppConfig.OTP_INPUT_SIZE
    )
  }, [verifyPhoneLoading, codeFilled])

  return (
    <TunnelContent
      header={
        <View>
          <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
            {t('app:pages:register:step1:contact:signup')}
          </Typography2>
          <Stepper2 currentStep={0} />
          <Typography2 typeface='h5'>
            {t('app:pages:register:step1:sms:titleMessage')}
          </Typography2>
          <Typography2 typeface='body1'>
            {t('app:pages:register:step1:sms:titleMessage2', { phone: profile.phone })}
            <br />
            {t('app:pages:register:step1:sms:titleMessage3')}
            <Timer
              accessibilityLabel='otpInputTimer'
              startDate={startDate}
              endDate={endDate}
            />
          </Typography2>
        </View>
      }
      footer={
        <>
          <Button
            title={t('app:global:validate')} skin={ButtonSkin.PRIMARY}
            disabled={!isValid}
            onPress={async () => {
              nextStep({ code: codeFilled ?? '' }).onDone(() => {
                dispatch(SignupActions.triggerEmailVerify(profile?.email))
                const phoneNumber = parsePhoneNumber(profile.phone, profile.countryCode as CountryCode)
                AnalyticsHandler.track(AnalyticsEvents.signUp.userSignUpStepCompleted,
                  {
                    step_name: RegisterStep.Sms,
                    phone: phoneNumber.formatInternational().replace(/ /g, ''),
                  })
              })
            }}
          />
          <Button
            title={t('app:pages:register:step1:sms:codeNotReceived')}
            skin={ButtonSkin.LINK}
            disabled={disabled}
            onPress={async () => {
              setDisabled(true)
              setTimeout(() => {
                setDisabled(false)
              }, 5000)
              try {
                await handleReCaptchaVerify()
                await dispatch(SignupActions.triggerPhoneVerify({
                  phone,
                  phoneCountryCode: countryCode,
                  token,
                  email: profile.email,
                  lastName: profile.lastName,
                  firstName: profile.firstName,
                })).then(() => {
                  setStartDate(dayjs(now()).toString())
                  setEndDate(dayjs(now()).add(dayjs.duration(5, 'minute')).toString())
                })
              } catch (error) {
              }
            }}
          />
        </>
      }
    >
      <div>
        <OTPInput
          onChangeText={code => {
            setCodeFilled(code)
          }}
          autoFocus
          ref={ref}
          disabled={verifyPhoneLoading}
        />
      </div>
    </TunnelContent>
  )
}
