import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

import { createStylesheet } from '../../utils'
import { BankAccountCardSkin } from '../BankAccountCard'

const styles = createStylesheet(({ Skin, props }) => {
  return StyleSheet.create({
    container: {
      padding: 16,
      borderRadius: 15,
      marginBottom: 8,
      backgroundColor: 'white',
      borderColor: '#ddd',
      borderBottomWidth: 0,
      shadowColor: Colors.black,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
      elevation: 3,
      ...Skin.select([
        BankAccountCardSkin.SECONDARY,
      ], {
        backgroundColor: 'rgba(255,255,255,0.2)',
        borderColor: Colors.white,
        borderWidth: 1,
        borderBottomWidth: 1,
        shadowColor: 'transparent',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0,
        shadowRadius: 0,
        elevation: 0,
      }),

    },
    containerBlur: {
      flex: 1,
      ...StyleSheet.absoluteFillObject,
    },
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    bodyContainer: {
      alignItems: 'center',
      paddingBottom: 16,
    },
    accountName: {
      ...(props.isDisabled ? {
        color: Colors.darkGreyText,
      }
        : {
          color: Colors.black,
        }),
    },
    accountSubtitle: {
      ...(props.isDisabled ? {
        color: Colors.darkGreyText,
      }
        : {
          color: Colors.black,
        }),
    },
    balance: {
      ...(props.isDisabled ? {
        color: Colors.darkGreyText,
      }
        : {
          color: Colors.black,
        }),

      marginTop: -16,
      paddingBottom: 8,
    },
    footerContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    button: {
      paddingHorizontal: 4,
      flex: 1,
    },
  })
})

export default styles
