
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { Routes } from 'Routes/Routes'

import { BankAccountSelectors, Client, ClientSelectors, ContractActions, InvoiceActions, StatementActions } from '@neo-commons/store'

import { DocumentType } from '../Pages/MyProfile/InvoiceAndContract/InvoiceAndContractDetail/InvoiceAndContractDetail'

export const useProfileDocumentPage = (documentType: DocumentType): void => {
  const history = useHistory()
  const dispatch = useDispatch()
  const client: Client = useSelector(ClientSelectors.defaultOne)
  const selectedBankAccount = useSelector(BankAccountSelectors.selected)

  useEffect(() => {
    if (selectedBankAccount) {
      switch (documentType) {
        case DocumentType.CONTRACT:
          dispatch(ContractActions.getContractsByPersonUuid(client.holder.uuid))
          break
        case DocumentType.INVOICE:
          dispatch(InvoiceActions.getInvoiceDocuments())
          break
        case DocumentType.STATEMENT:
          dispatch(StatementActions.getStatementByAccountUuid(selectedBankAccount.uuid))
          break
      }
    } else {
      history.push(Routes.Profile.choicesAccount.path)
    }
  }, [])
}
