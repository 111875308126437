import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { DocumentUploadStep, DocumentUploadSteps } from 'Policies'

import { StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Icon, IconTypes, Stepper2, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { DocumentUploadSelectors } from '@neo-commons/store'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import { Routes } from '../../../../Routes/Routes'

type DocumentUploadFailureProps = StepComponentProps<DocumentUploadSteps[DocumentUploadStep.failure]>
export const DocumentUploadFailure: React.FC<DocumentUploadFailureProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const canRetrySend = useSelector(DocumentUploadSelectors.canRetrySend)
  const SIZE_MAX = useSelector((state: StateTypes) => state?.config?.data?.currentConfig?.uploadSizeLimit)
  const documentPages = useSelector((state: StateTypes) => state?.documentUpload?.data?.document?.pages)
  const hasSizeError = documentPages?.includes(documentPages.find((page) => page?.data?.size >= SIZE_MAX))

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.CENTER}
      header={
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <Typography2 typeface='h3'>
            {t('app:pages:subscription:individual:mainTitle:idDocument')}
          </Typography2>
          <Stepper2 currentStep={1} />
          <Icon
            name='alert-circle'
            iconStyle={{ marginRight: 8 }}
            size={40}
            type={IconTypes.FEATHER}
            color={Colors.brightRed}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Typography2 typeface='h4' style={{ }}>{t('app:pages:documentUpload:failure:title')}</Typography2>
            <Typography2 typeface='body1'>
              {t(hasSizeError
                ? 'app:pages:documentUpload:failure:sizeError'
                : 'app:pages:documentUpload:failure:description')}
            </Typography2>
          </div>
          <Typography2 typeface='body1' style={{ textAlign: 'center', marginTop: 32 }}>
            <Trans
              i18nKey='app:pages:documentUpload:failure:contactCustomerService'
              components={[
                <Typography2 typeface='body1' style={{ fontFamily: 'Quicksand-Bold' }} key={0} />,
              ]}
            />
          </Typography2>
        </div>
      }
      footer={
        canRetrySend &&
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              containerStyle={{ width: '50%', marginRight: 4 }}
              skin={ButtonSkin.OUTLINE_PRIMARY}
              title={t('app:global:add')}
              onPress={() => history.push(Routes.DocumentUpload.index.path)}
            />
            <Button
              containerStyle={{ width: '50%', marginLeft: 4 }}
              title={t('app:pages:documentUpload:failure:retryButton')}
              onPress={() => {
                nextStep()
              }}
            />
          </div>
      }
    />
  )
}
