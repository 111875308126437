import { store, StateTypes, Dispatch } from '../Store'

/**
 *  AbstractPolicy
 */
export abstract class AbstractPolicy {
  protected static get state (): StateTypes {
    return store.getState()
  }

  protected static get dispatch (): Dispatch {
    return store.dispatch
  }
}
