import React from 'react'

import './Banner.scss'

export interface BannerProps {
  children: React.ReactElement<Element>,
}

export const Banner: React.FC<BannerProps> = (props) => {
  return (
    <div className='Banner_container'>
      {props.children}
    </div>
  )
}
