import React from 'react'
import Shimmer from 'react-shimmer-effect'

import { ShimmerCard, ShimmerCardType } from '@neo-web/componentsOld'

import './ShimmerList.scss'

export enum ShimmerListType {
  TRANSACTION = 'TRANSACTION',
  PROJECT = 'PROJECT',
  POS_ORDER = 'POS_ORDER',
  POS = 'POS',
}

interface ShimmerListProps {
  type: ShimmerListType,
  fading?: boolean,
}

export const ShimmerList: React.FC<ShimmerListProps> = (props) => {
  let listContent

  switch (props.type) {
    case ShimmerListType.TRANSACTION:
      listContent = (
        <>
          <Shimmer>
            <div className='shimmer-list-title' />
          </Shimmer>
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <Shimmer>
            <div className='shimmer-list-title' />
          </Shimmer>
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
        </>
      )
      break

    case ShimmerListType.PROJECT:
      listContent = (
        <>
          <Shimmer>
            <div className='shimmer-list-title' />
          </Shimmer>
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
        </>
      )
      break

    case ShimmerListType.POS_ORDER:
      listContent = (
        <>
          <ShimmerCard type={ShimmerCardType.POS_ORDER} />
          <ShimmerCard type={ShimmerCardType.POS_ORDER} />
          <ShimmerCard type={ShimmerCardType.POS_ORDER} />
        </>
      )
      break

    case ShimmerListType.POS:
      listContent = (
        <>
          <Shimmer>
            <div className='shimmer-list-title' />
          </Shimmer>
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <Shimmer>
            <div className='shimmer-list-title' />
          </Shimmer>
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
          <Shimmer>
            <div className='shimmer-list-title' />
          </Shimmer>
          <ShimmerCard type={ShimmerCardType.TRANSACTION} />
        </>
      )
      break
  }

  return (
    <div className={`shimmer-list ${props.fading && 'fading-list'}`}>
      {listContent}
    </div>
  )
}
