import React from 'react'
import { TextStyle } from 'react-native'
import { useTranslation } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'

import { PreferenceChannelValueDto, Typography } from '@neo-commons/components'

import { Switch } from '@neo-web/componentsOld'

import './PreferenceCardList.scss'

export type PreferenceCardItem = {
  title?: string;
  choices: PreferenceChannelValueDto[],
  onChange?: (id, isCheck: boolean) => void,
  subtitleStyle?: TextStyle;
  disabled?: boolean;
}

export const PreferenceCardList: React.FC<PreferenceCardItem> = (
  { title, choices, onChange }) => {
  const { t } = useTranslation()

  return (
    <FlexboxGrid justify='space-between' align='middle' className='PreferenceCardList_container'>
      {title &&
        <FlexboxGrid.Item className='PreferenceCardList_title' colspan={24}>
          <Typography typeface='header'>
            {title}
          </Typography>
        </FlexboxGrid.Item>}
      <FlexboxGrid.Item colspan={24}>
        {choices.map(item =>
          <div className='PreferenceCardList_card classic-box box-shadow' key={item.type + title}>
            <Typography style={{ fontSize: 16 }} typeface='bigTitle'>
              {item.title ? item.title : t('neo-commons:profile:' + item.type)}
            </Typography>
            <Switch
              defaultChecked={item.value}
              onChange={isOn => onChange && onChange(item.type, isOn)}
            />
          </div>
        )}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}
