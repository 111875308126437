import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { CardMenuItem, CardMenuList, JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

export const About: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const menuCards: CardMenuItem[] = [
    {
      title: t('app:global:manifest'),
      content: undefined,
      onPress: () => history.push(Routes.PublicLink.manifest.path),
      disabled: false,
    },
    {
      title: t('app:global:legalNotice'),
      content: undefined,
      onPress: () => history.push(Routes.PublicLink.legalNotice.path),
      disabled: false,
    },
    {
      title: t('app:global:privacyPolicy'),
      content: undefined,
      onPress: () => history.push(Routes.PublicLink.privacyPolicy.path),
      disabled: false,
    },
  ]

  return (
    <PageDetails
      component={(
        <PageContent justifyContent={JustifyContent.START}>
          <CardMenuList
            menus={menuCards}
          />
        </PageContent>
      )}
      onBack={() => history.push(Routes.Profile.index.path)}
      title={t('app:pages:myProfile:menu:about')}
    />
  )
}
