import React, { FunctionComponent, useEffect, useState } from 'react'
import { Image, SectionList, SectionListData, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { CountryDto } from '@afone/neo-core-client/dist/models'
import { CountryUtils, SelectionMode, TextUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { Badge, BadgeSkin, SearchBar } from '..'
import { Flags } from '../../assets/images'

import styles from './styles'

interface CountryItemProps {
  selectionMode?: SelectionMode,
  index: number,
  country: CountryDto,
  onPress: (country: CountryDto) => void,
  hidePrefix?: boolean,
  disabled?: boolean,
}

const CountryItem: FunctionComponent<CountryItemProps> = ({ selectionMode, country, onPress, index, hidePrefix = false, disabled = false }) => {
  const { t } = useTranslation()
  const flag = Flags[country?.isoCodeAlpha3 ?? 'Unknown'] ?? Flags.Unknown

  return (
    <TouchableOpacity disabled={disabled} style={styles.itemPrefix} onPress={() => { onPress(country) }} key={index}>
      <Image style={styles.flags} source={flag} />
      {!hidePrefix && <Text style={styles.prefix}>{country?.prefixPhone ?? ''}</Text>}
      <Text style={[styles.countryName, { color: disabled ? Colors.gray : Colors.black }]}>{country?.name ?? ''}</Text>
      {(selectionMode === SelectionMode.PRO_TAX_DOMICILIATION &&
          country?.isoCodeAlpha3 !== 'FRA' &&
          country.isAuthorizeForCorporation) &&
            <div style={{ marginLeft: 'auto' }}>
              <Badge value={t('services:soonAvalaible')} skin={BadgeSkin.WARNING} />
            </div>}
    </TouchableOpacity>
  )
}

export interface CountryListProps {
  emptyText?: string,
  selectionMode?: SelectionMode
  countries: CountryDto[]
  onCountrySelectedAction: (country: CountryDto) => void
  hidePrefix?: boolean
}

export const CountryList: FunctionComponent<CountryListProps> = ({
  selectionMode,
  countries,
  onCountrySelectedAction,
  hidePrefix = false,
}) => {
  const selectCountry = (country: CountryDto) => {
    onCountrySelectedAction(country)
  }

  const [countriesFiltered, setCountriesFiltered] = useState(countries)
  const [groupCountriesFiltered, setGroupCountriesFiltered] = useState<SectionListData<CountryDto>[]>([])

  const filterItem = (country: CountryDto, text: string): boolean => {
    let nameFromJson = country?.name ?? ''
    if (selectionMode === SelectionMode.PHONE_PREFIX) {
      nameFromJson += ' ' + (country?.prefixPhone ?? '')
    }

    return TextUtils.matchWord(nameFromJson, text) !== null
  }

  useEffect(() => {
    setGroupCountriesFiltered(CountryUtils.groupCountriesByFirstLetter(countriesFiltered))
  }, [countriesFiltered])

  return (
    <View style={styles.container}>
      <SearchBar<CountryDto>
        items={countries}
        itemFilter={(country, text) => filterItem(country, text)}
        onItemsFiltered={filteredItems => setCountriesFiltered(filteredItems)}
      />
      <SectionList
        sections={groupCountriesFiltered}
        keyExtractor={(_, index) => index.toString()}
        disableVirtualization
        renderItem={({ item, index }) => (
          <CountryItem
            index={index}
            key={item.isoCodeAlpha3}
            selectionMode={selectionMode}
            country={item}
            onPress={selectedCountry => selectCountry(selectedCountry)}
            hidePrefix={hidePrefix}
            disabled={selectionMode === SelectionMode.PRO_TAX_DOMICILIATION &&
              (!item.isAuthorizeForCorporation || (item?.isoCodeAlpha3 !== 'FRA' && item.isAuthorizeForCorporation))}
          />
        )}
        renderSectionHeader={(props) => {
          return (
            <Text style={styles.letterGroup}>
              {props.section.title}
            </Text>
          )
        }}
        style={[styles.sectionsContainer]}
        stickySectionHeadersEnabled
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator
        bounces={false}
        initialNumToRender={1000}
        maxToRenderPerBatch={1000}
        updateCellsBatchingPeriod={10}
        removeClippedSubviews
      />
    </View>
  )
}
