import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'
import { TransferPolicy } from 'Policies/TransferPolicy'

import { BankAccountSelectors, ClientSelectors, TransferActions } from '@neo-commons/store'
import { Button, ButtonSkin } from '@neo-commons/components'
import { BankAccountUtils, EnumUtils, Formatters, NumbersUtils } from '@neo-commons/libraries'

import { AccountDto, ClientDto } from '@afone/neo-core-client/dist/models'

import { InformationModal, PriceCard, PriceCardSkin } from '@neo-web/componentsOld'
import { useIcon } from '@neo-web/hooks'

import './CreditIssuers.scss'

export const CreditIssuers: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)

  const selectedSourceBankAccount = useSelector(BankAccountSelectors.selected)
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts()).filter(el => !BankAccountUtils.isAggregated(el))
  const transferState = useSelector((state: StateTypes) => state?.transfer)
  const onGoingTransfer = transferState.prepare
  const preparedTransferAccountAmount = useSelector(BankAccountSelectors.getBankAccountBalance(onGoingTransfer?.accountUuid))
  const client: ClientDto = useSelector(ClientSelectors.byId(selectedSourceBankAccount))

  const selectItem = (bankAccount) => {
    (async () => {
      try {
        await dispatch(TransferActions.prepare({
          ...onGoingTransfer,
          accountUuid: bankAccount?.uuid,
        }))
      } catch (e) {}
    })()
  }

  useEffect(() => {
    if (!onGoingTransfer.accountUuid) {
      selectItem(selectedSourceBankAccount)
    }
  }, [selectItem, selectedSourceBankAccount, onGoingTransfer])

  return (
    <div className='SelectTransferSourceAccount_container'>
      {openModal &&
        <InformationModal
          show={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
          title={t('app:pages:payments:transfer:account:zeroBalanceModal:title')}
          descriptionAlign='center'
          description={t('app:pages:payments:transfer:account:zeroBalanceModal:description')}
        />}

      <div className='SelectTransferSourceAccount_item'>
        {bankAccounts.map(bankAccount =>
          <div
            key={bankAccount.iban}
            className={
            `SelectTransferSourceAccount_card ${BankAccountUtils.isSecondary(bankAccount) ? 'secondary' : ''}`
            }
          >
            <PriceCard
              isLarge={false}
              selected={bankAccount.uuid === onGoingTransfer.accountUuid}
              skin={BankAccountUtils.isSecondary(bankAccount) ? PriceCardSkin.SECONDARY : PriceCardSkin.DEFAULT}
              icon={useIcon(bankAccount, client?.type, bankAccount.uuid === onGoingTransfer.accountUuid)}
              price={NumbersUtils.displayPriceForHuman(bankAccount.balance)}
              title={BankAccountUtils.getName(bankAccount)}
              subTitle={
                BankAccountUtils.isSecondary(bankAccount)
                  ? t(EnumUtils.getAccountTypeDtoKey(bankAccount.type))
                  : Formatters.formatIban(bankAccount.iban ?? '', true)
              }
              onClick={() => { selectItem(bankAccount) }}
            />
          </div>
        )}
      </div>
      <div className='SelectTransferSourceAccount_action'>
        <Button
          title={t('app:pages:payments:transfer:account:cancel')}
          skin={ButtonSkin.OUTLINE_PRIMARY}
          onPress={() => {
            history.push(onGoingTransfer.isUpdating ? generatePath(
              Routes.Payments.transferUpdate.path,
              { id: onGoingTransfer.uuid }
            ) : Routes.Payments.index.path)
          }}
        />

        <Button
          title={t('app:pages:payments:transfer:account:validate')}
          skin={ButtonSkin.PRIMARY}
          onPress={() => {
            if (preparedTransferAccountAmount <= 0) {
              setOpenModal(true)
            } else {
              TransferPolicy.canStep2(transferState) && history.push(
                TransferPolicy.canStep6(transferState) ? onGoingTransfer.isUpdating
                  ? generatePath(
                    Routes.Payments.transferUpdate.path,
                    { id: onGoingTransfer.uuid }
                  ) : Routes.Payments.summary.path : Routes.Payments.beneficiary.path)
            }
          }}
        />
      </div>
    </div>
  )
}
