import React, { FunctionComponent, memo } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'

import { NumbersUtils } from '@neo-commons/libraries'
import {
  TransactionPriceDto,
  TransactionTypeFamilyDto,

} from '@afone/neo-core-client/dist/models'
import { Colors } from '@neo-commons/styles'

import { Icon, IconTypes, Typography } from '..'

import styles from './styles'

export interface PaymentCardProps{
  title?: string,
  icon: string | React.ReactElement<typeof Icon>,
  amountColor?: string,
  amount?: number,
  price?: TransactionPriceDto,
  family?: TransactionTypeFamilyDto | null,
  info?: string,
  subtitle?: string,
  currency?: string,
  annotation?: string,
  isOnus?: boolean,
  onPress?: () => void,
  isComplete?: boolean,
  clickable?: boolean,
  feesLabel?: string
  isAdvanceTransaction?: boolean
}
export const PaymentCard: FunctionComponent<PaymentCardProps> = memo((props: PaymentCardProps) => {
  const {
    title,
    icon,
    amountColor,
    clickable,
    price,
    currency,
    family,
    info,
    feesLabel,
    onPress,
    isComplete = true,
    isAdvanceTransaction,
  } = props

  const amountStyle = isComplete ? styles.amountText : styles.amountDeclinedText

  const onlyFees = () => {
    return (
      family && (family === TransactionTypeFamilyDto.FEES) &&
      (price?.feesTotal && price?.feesTotal !== 0)
    ) ?? false
  }

  const subtitle = () => {
    return (
      !!info && info.length > 0 ? info : (
        onlyFees() ? feesLabel : false
      )
    ) ?? false
  }

  if (title !== undefined) {
    return (
      <TouchableOpacity
        accessibilityLabel='paymentCard'
        style={styles.itemContainer} disabled={!clickable} onPress={() => onPress && onPress()}
      >
        <View style={styles.topContainer}>
          <View style={{ justifyContent: 'center' }}>
            {icon}
          </View>
          <View style={styles.labelContainer}>
            {title && (
              <Text numberOfLines={1} accessibilityLabel='TransfertLabel' style={styles.label}>
                {title}
                {props.isOnus &&
                  <Icon name='dark-logo' type={IconTypes.NEOFONT} size={12} iconStyle={styles.onusTransferIcon} />}
              </Text>
            )}
            {subtitle() && <Text style={styles.info} numberOfLines={1}>{subtitle()}</Text>}
            {props?.annotation && <Text style={styles.note} numberOfLines={1}>{props.annotation}</Text>}
          </View>
          {isAdvanceTransaction ? (
            <View style={styles.badge}>
              <Typography typeface='button1' accessibilityLabel='CreditAmount' style={{ color: Colors.white }}>
                {onlyFees()
                  ? NumbersUtils.displaySignedPriceForHuman(price?.feesTotal)
                  : NumbersUtils.displayPriceForHumanWithCurrency(price?.amount, currency)}
              </Typography>
            </View>
          ) : (
            <View style={styles.amountContainer}>
              <Typography typeface='button1' accessibilityLabel='CreditAmount' style={[amountStyle, { color: amountColor }]}>
                {onlyFees()
                  ? NumbersUtils.displaySignedPriceForHuman(price?.feesTotal)
                  : NumbersUtils.displayPriceForHumanWithCurrency(price?.amount, currency)}
              </Typography>
            </View>
          )}
        </View>
        {(!onlyFees() && price?.feesTotal !== undefined && price?.feesTotal !== 0) && (
          <View accessibilityLabel='DebitAmount' style={styles.bottomContainer}>
            <Typography typeface='content' style={styles.feesLabel}>
              {feesLabel}
            </Typography>
            <Text accessibilityLabel='DebitAmount' style={styles.feesStyle}>
              {NumbersUtils.displaySignedPriceForHuman(price?.feesTotal)}
            </Text>
          </View>
        )}
      </TouchableOpacity>
    )
  } else {
    return (
      <View style={styles.itemContainer}>
        <View>
          <Text style={styles.labelEmpty} />
          <Text style={styles.infoEmpty} />
        </View>
      </View>
    )
  }
})
