import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { DeviceTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    DeviceTypes.UPDATE_DEVICE_SUCCESS,
    DeviceTypes.UPDATE_DEVICE_FAILURE,

    DeviceTypes.LIST_DEVICE_REQUEST,
    DeviceTypes.LIST_DEVICE_SUCCESS,
    DeviceTypes.LIST_DEVICE_FAILURE,

    DeviceTypes.LIST_DEVICE_ACTIVITIES_REQUEST,
    DeviceTypes.LIST_DEVICE_ACTIVITIES_SUCCESS,
    DeviceTypes.LIST_DEVICE_ACTIVITIES_FAILURE,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    DeviceTypes.LIST_DEVICE_ACTIVITIES_FAILURE,

    DeviceTypes.LOCK_DEVICE_FAILURE,
    DeviceTypes.LOCK_DEVICE_SUCCESS,

    DeviceTypes.UNLOCK_DEVICE_SUCCESS,
    DeviceTypes.UNLOCK_DEVICE_FAILURE,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
]
