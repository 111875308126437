import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Nav } from 'rsuite'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { Button, ButtonSkin, Typography, Typography2 } from '@neo-commons/components'
import {
  Client,
  ClientActions,
  ClientSelectors,
  OfferActions,
  OfferSelectors,
  OrdersSelectors,
  SubscriptionActions,
} from '@neo-commons/store'
import { NeobankApi } from '@neo-commons/services'
import { OfferUtils } from '@neo-commons/libraries'

import { ClientTypeDto, OfferDto, OfferTypeDto, OrderTypeDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, Modal, PageContent, Tunnel } from '@neo-web/componentsOld'

import './SubscriptionChoice.scss'

import { Routes } from '../../../../Routes/Routes'
import { DiscountCodeModal, NewOfferMinCard, NewEligibilityCard } from '../Components'

export const SubscriptionChoice: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const offerDiscountCode = useSelector(OfferSelectors.discountCode)
  const [discountCode, setDiscountCode] = useState(offerDiscountCode)
  const [openModalDiscountCode, setOpenModalDiscountCode] = useState<boolean>(false)
  const [openModalEligibility, setOpenModalEligibility] = useState<boolean>(false)
  const [active, setActive] = useState(ClientTypeDto.INDIVIDUAL)

  const eligibilityOrder = useSelector(OrdersSelectors.byType(OrderTypeDto.ADVANCE_ELIGIBILITY))
  const individualOffers: OfferDto[] = useSelector(OfferSelectors
    .listByClientType(ClientTypeDto.INDIVIDUAL, OfferTypeDto.SOCLE))
    .filter((offer) => offer.authorized)
  const advanceOffer = individualOffers.find(offer => OfferUtils.isOfferAdvance(offer))

  const corporateOffers: OfferDto[] =
    useSelector(OfferSelectors.listByClientType(ClientTypeDto.CORPORATE, OfferTypeDto.SOCLE))
      .filter((offer) => offer.authorized)
  const client: Client = useSelector(ClientSelectors.defaultOne)

  const onApplyPromoCode = async (discountCode) => {
    try {
      await dispatch(OfferActions.list(discountCode))
      setDiscountCode(discountCode)
      setOpenModalDiscountCode(false)
      AnalyticsHandler.track(AnalyticsEvents.signUp.promoCodeApplied,
        {
          discount_code: discountCode,
        })
    } catch (err) {
      setOpenModalDiscountCode(false)
    }
  }

  if (NeobankApi.getInstance().isAgentClient()) {
    const offers: OfferDto[] = useSelector(OfferSelectors.listByClientType(ClientTypeDto.CORPORATE, OfferTypeDto.SOCLE))
    dispatch(ClientActions.prepare({ type: ClientTypeDto.CORPORATE }))
    const offer = offers.length === 1 ? offers.pop() : undefined
    dispatch(SubscriptionActions.prepare({
      offerUuid: offer ? offer.uuid : undefined,
      offer: offer,
      ibanLocalization: 'FR',
    }))
    history.push(Routes.Subscription.Business.index.path)
  }
  const onPressOffer = async (offer : OfferDto) => {
    if (OfferUtils.isOfferAdvance(offer) && !eligibilityOrder?.analysis.eligibility) {
      setOpenModalEligibility(true)
    } else {
      dispatch(SubscriptionActions.prepare({
        offerUuid: offer.uuid,
        discountCode: offer?.discountCodeUsable ? discountCode : undefined,
      }))
      AnalyticsHandler.track(AnalyticsEvents.signUp.subscriptionOptionSelected,
        {
          option_name: offer?.name,
          option_mrr: offer?.price.substring(0, offer?.price.indexOf('/')),
          option_code: offer?.offerCode,
          account_type: client?.type === ClientTypeDto.CORPORATE ? 'B2B' : 'B2C',
        })
      offer.clientType === ClientTypeDto.CORPORATE
        ? history.push(Routes.Subscription.Business.index.path)
        : history.push(Routes.Subscription.Individual.index.path)
    }
  }

  const onPressEligibility = async () => {
    if (eligibilityOrder && eligibilityOrder.analysis.eligibility) {
      dispatch(SubscriptionActions.prepare({
        offerUuid: advanceOffer.uuid,
      }))
      history.push(Routes.AdvanceEligibility.success.path)
    } else {
      history.push(Routes.AdvanceEligibility.index.path)
    }
  }

  const Navbar = ({ active, onSelect, ...props }) => {
    return (
      <Nav {...props} activeKey={active} onSelect={onSelect} className='SubscriptionChoice_navbar'>
        <Nav.Item
          className='SubscriptionChoice_navbar_button'
          eventKey={ClientTypeDto.INDIVIDUAL}
        >
          {t('neo-commons:account:part')}
        </Nav.Item>
        <Nav.Item
          className='SubscriptionChoice_navbar_button'
          eventKey={ClientTypeDto.CORPORATE}
        >
          {t('neo-commons:account:pro')}
        </Nav.Item>
      </Nav>
    )
  }

  const offersList = active === ClientTypeDto.INDIVIDUAL ? individualOffers : corporateOffers
  return (
    <Tunnel
      footer
      onBack={() => history.goBack()}
      component={
        <PageContent
          withBackground
          justifyContent={JustifyContent.START}
        >
          <div className='SubscriptionChoice_container'>
            <div style={{ textAlign: 'center' }}>
              <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
                {t('neo-commons:loan:title')}
              </Typography2>
            </div>
            <Navbar appearance='default' active={active} onSelect={setActive} />
            {active === ClientTypeDto.INDIVIDUAL && advanceOffer && (
              <NewEligibilityCard onPress={onPressEligibility} />
            )}
            {offersList.map((offer) => (
              <NewOfferMinCard
                key={offer.uuid}
                offer={offer}
                onPress={onPressOffer}
              />
            ))}
            {active === ClientTypeDto.INDIVIDUAL && (
              <div className='SubscriptionChoice_footer'>
                <Typography2 typeface='label'>
                  {t('neo-commons:loan:averageFees')}
                </Typography2>
              </div>
            )}
            {!discountCode && (
              <Button
                accessibilityLabel='SubscriptionPromoCode'
                title={t('app:pages:subscription:global:offers:discountCodeLink')}
                skin={ButtonSkin.PRIMARY_LINK}
                onPress={() => setOpenModalDiscountCode(true)}
                hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
              />
            )}
          </div>
          <DiscountCodeModal
            show={openModalDiscountCode}
            onClose={() => setOpenModalDiscountCode(false)}
            onValidate={(discountCode) => onApplyPromoCode(discountCode)}
          />
          <Modal
            open={openModalEligibility}
            size='sm'
          >
            <div className='SubscriptionChoiceModal_container'>
              <Typography typeface='h2'>
                {!eligibilityOrder
                  ? t('neo-commons:eligibility:modal:title')
                  : t('neo-commons:eligibility:modal:notEligibleTitle')}
              </Typography>
              <Typography typeface='body2'>
                {!eligibilityOrder
                  ? t('neo-commons:eligibility:modal:text')
                  : t('neo-commons:eligibility:modal:notEligibleText')}
              </Typography>
              <div className='SubscriptionChoiceModal_button'>
                {!eligibilityOrder
                  ? (
                    <>
                      <Button
                        skin={ButtonSkin.OUTLINE_PRIMARY}
                        title={t('neo-commons:eligibility:modal:cancel')}
                        onPress={() => {
                          setOpenModalEligibility(false)
                        }}
                      />
                      <Button
                        skin={ButtonSkin.PRIMARY}
                        containerStyle={{ marginTop: 8 }}
                        title={t('neo-commons:eligibility:modal:confirm')}
                        onPress={() => {
                          setOpenModalEligibility(false)
                          onPressEligibility()
                        }}
                      />
                    </>
                  ) : (
                    <Button
                      skin={ButtonSkin.PRIMARY}
                      title={t('neo-commons:eligibility:modal:notEligibleButton')}
                      onPress={() => {
                        setOpenModalEligibility(false)
                      }}
                    />
                  )}
              </div>
            </div>
          </Modal>
        </PageContent>
      }
    />
  )
}
