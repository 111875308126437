import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StateTypes } from 'Store'

import {
  Button,
  ButtonSkin,
  IconTypes,
  Icon,
  Card,
  PaymentCards,
  CardSkin,
  Typography2,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { CardSelectors, CreditCardData } from '@neo-commons/store'
import { OppositionSteps, OppositionStep, StepComponentProps } from '@neo-commons/policies'

import { TunnelContent } from '@neo-web/componentsOld'

import './ConfirmOpposition.scss'

type ConfirmProps = StepComponentProps<OppositionSteps[OppositionStep.Confirm]>

export const ConfirmOpposition: React.FC<ConfirmProps> = ({ nextStep }: ConfirmProps) => {
  const { t } = useTranslation()

  const isLoadingCard: boolean = useSelector((state: StateTypes) => state.card?.loading)
  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)

  return (
    <TunnelContent
      loading={isLoadingCard}
      subtitle={t('app:pages:services:debitCard:opposition:confirmOpposition:title')}
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:pages:services:debitCard:opposition:confirmOpposition:validate')}
          onPress={() => {
            nextStep(0)
          }}
        />
      }
    >
      <div className='ConfirmOpposition_container'>
        <div className='ConfirmOpposition_container_card'>
          <Card skin={CardSkin.DEFAULT} containerStyle={{ width: '100%' }}>
            <PaymentCards card={selectedCard} />
          </Card>
        </div>
        <div className='ConfirmOpposition_container_block'>
          <div className='ConfirmOpposition_container_block_header'>
            <Icon name='info' type={IconTypes.FEATHER} color={Colors.secondary} size={25} />
            <div className='ConfirmOpposition_container_block_header_title'>
              <Typography2 typeface='h4'>
                {t('app:pages:services:debitCard:opposition:confirmOpposition:disclaimer:title')}
              </Typography2>
            </div>
          </div>
          <div className='ConfirmOpposition_description'>
            <Typography2 typeface='body1'>
              {t('app:pages:services:debitCard:opposition:confirmOpposition:disclaimer:description')}
            </Typography2>
          </div>
        </div>
      </div>
    </TunnelContent>
  )
}
