import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CustomIcon from 'UI/Components/CustomIcon'
import { Routes } from 'Routes/Routes'
import { CreditRoutes } from 'Routes/CreditRoutes'
import { PaymentsRoutes } from 'Routes/PaymentsRoutes'
import { RootActions, StateTypes } from 'Store'
import { config, ENV } from 'Config/EnvConfig'
import dayjs from 'dayjs'

import {
  Button,
  ButtonSkin,
  Card,
  CardSkin,
  Icon,
  IconTypes,
  MenuItemCard,
  TransactionCard,
  Typography,
  Typography2,
} from '@neo-commons/components'
import {
  BankAccountUtils,
  CreditCardUtils,
  NumbersUtils,
  OfferUtils,
  SubscriptionUtils,
  TransactionUtils,
} from '@neo-commons/libraries'
import {
  AdvanceActions,
  AlertType,
  BankAccountActions,
  BankAccountSelectors,
  CardActions,
  CardSelectors,
  ClientSelectors,
  Dispatch,
  NotificationActions,
  NotificationSelectors,
  OfferSelectors,
  PrepareCardOrder,
  SubscriptionSelectors,
  TelecollectionActions,
  TelecollectionSelectors,
  TelecollectionTypes,
  TransactionActions,
  TransactionSelectors,
  TransactionTypes,
  OrdersActions,
  AdvanceSelectors,
  OrdersSelectors,
  OfferActions,
} from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { NeobankApi } from '@neo-commons/services'

import {
  AccountDto,
  AccountTypeDto,
  TransactionDto,
  AccountStatusDto,
  ClientDto,
  AdvanceDto,
  OrderTypeDto,
  ProductDto,
  OfferDto,
  OfferTypeDto,
} from '@afone/neo-core-client/dist/models'

import {
  CancelPosAccountClosureModal,
  InformationModal,
  JustifyContent,
  LinkedCard,
  NotificationsAlert,
  PageContent,
  PaginationList,
  ShimmerList,
  ShimmerListType,
  KYCModal,
} from '@neo-web/componentsOld'

import debitCard from '../../Resources/Images/site/debitCard.png'

import { TelecollectionIntroStep } from './Telecollection/TelecollectionIntroStep'
import { TransactionBanner } from './Components/TransactionBanner/TransactionBanner'
import './MyTransaction.scss'

export const MyTransactions: React.FC = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()

  const transactionsList = useSelector(TransactionSelectors.list)
  const transactionsState = useSelector((state: StateTypes) => state?.transaction)

  const mainSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  const kycPending = SubscriptionUtils.isKycStepAnalysing(mainSubscription)
  const kycInProgress = SubscriptionUtils.isKycStepInProgress(mainSubscription)

  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selectedMain,
    (prev, next) => (prev?.uuid === next?.uuid && prev?.status === next?.status))
  const isSelectedBankAccountProject = BankAccountUtils.isProject(selectedBankAccount)
  const isProspectMain = BankAccountUtils.isProspectMain(selectedBankAccount)
  const isProspectProject = BankAccountUtils.isProspectProject(selectedBankAccount)
  const isProspectAccount = BankAccountUtils.isProspectAccount(selectedBankAccount)
  const subscription = useSelector(SubscriptionSelectors.byId(selectedBankAccount?.subscriptionUuid))
  const currentPage = useSelector((state: StateTypes) => state.transaction.list.page)
  const paginationEnded = useSelector((state: StateTypes) => state.transaction.list.paginationEnded)
  const loadedOnce = useSelector((state: StateTypes) => state.transaction.list.loadedOnce)
  const notifications = useSelector(NotificationSelectors.list)
  const minimumFirstProvisioningAmount = subscription?.offer?.minCredit
  const minCreditReached = selectedBankAccount?.minCreditReached

  const isPosAccount = BankAccountUtils.isPos(selectedBankAccount)
  const isAggregatedAccount = BankAccountUtils.isAggregated(selectedBankAccount)
  const telecollectAmount = useSelector(TelecollectionSelectors.summaryAmount)

  const client: ClientDto = useSelector(ClientSelectors.defaultOne, (prev, current) => prev?.uuid === current?.uuid)
  const cardOwnerUuid: string|undefined = CreditCardUtils.getDefaultCardOwnerUuid(client)
  const preparedCardOrder: PrepareCardOrder = useSelector(CardSelectors.preparedCardOrder)
  const preOrderedCard = useSelector(OrdersSelectors.byType(OrderTypeDto.CARD))
  const socleOffer = useSelector(OfferSelectors.getByUuid(mainSubscription?.offerUuid ?? mainSubscription?.offer?.uuid))

  const cardOffers: OfferDto[] = useSelector(OfferSelectors.listByType(OfferTypeDto.CARD))
  const authorizedCardOffer = cardOffers?.find(offer => offer.authorized)
  const authorizedCardProducts = (authorizedCardOffer?.products as (ProductDto & {data: {productType?: string}})[])?.filter(product => product?.data?.productType === 'plasticCard')
  const hasFreeCard = !!authorizedCardProducts?.[0]?.prices.find(price => price.amount === 0)

  const eligibilityOrder = useSelector(OrdersSelectors.byType(OrderTypeDto.ADVANCE_ELIGIBILITY))
  const advanceOrder = useSelector(OrdersSelectors.byType(OrderTypeDto.ADVANCE))
  const advances: AdvanceDto[] = useSelector(AdvanceSelectors.list)
  const activeAdvance = OfferUtils.getActiveAdvance(advances)

  const isLoadingTransactions = useSelector((state: StateTypes) => state?.transaction?.loading)
  const isLoadingAdvances = useSelector(AdvanceSelectors.isLoading)
  const isLoading = isLoadingTransactions || isLoadingAdvances

  // eslint-disable-next-line dot-notation
  const advanceMax = socleOffer?.data['advance'] || 100
  const remainingBalance = advanceMax - activeAdvance?.currentBalance

  const showIntroStepTlc = useSelector((state: StateTypes) => state.introStep.telecollections)
  const [tlcIntroStepEnabled, setTlcIntroStepEnabled] = useState(false)
  const [openCancelPosModal, setOpenCancelPosModal] = useState(false)
  const [showAdvanceModal, setShowAdvanceModal] = useState(false)
  const [showActionModal, setShowActionModal] = useState(false)

  const getTransactions = (first?: boolean) => {
    (async () => {
      const page = first ? 0 : (currentPage ?? 0) + 1
      if (!transactionsState.loading && ((!paginationEnded && !first) || first)) {
        try {
          await dispatch(TransactionActions.getTransactionList(page))
        } catch (e) {}
      }
    })()
  }

  useEffect(() => {
    dispatch(OrdersActions.listOrders())
    dispatch(OfferActions.listForClientUuid())
    if (SubscriptionUtils.isActive(mainSubscription)) {
      dispatch(AdvanceActions.getAdvanceData())
    }
    if (SubscriptionUtils.shouldSign(mainSubscription, client?.type) ||
      SubscriptionUtils.shouldReUploadDocuments(mainSubscription) ||
      SubscriptionUtils.isProspectRefused(mainSubscription)) {
      setShowActionModal(true)
    }
  }, [])

  const orderAdvance = () => {
    if (client && client.accounts?.length) {
      dispatch(AdvanceActions.createAdvance())
        .then(() => {
          setShowAdvanceModal(true)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      dispatch(OrdersActions.createAdvanceOrder())
        .then(() => {
          dispatch(OrdersActions.listOrders())
          setShowAdvanceModal(true)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const renderNoTransactions = () => {
    let messageEmpty = t('app:pages:synthesis:myTransactionsContent:emptyAccount:message1')
    let buttonEmpty = t('app:pages:synthesis:myTransactionsContent:emptyAccount:button')
    switch (selectedBankAccount?.type) {
      case AccountTypeDto.MAIN:
      case AccountTypeDto.PROJECT:
        if (isSelectedBankAccountProject) {
          messageEmpty = t('app:pages:synthesis:myTransactionsContent:emptyAccount:projectMessage')
          buttonEmpty = t('app:pages:synthesis:myTransactionsContent:emptyAccount:projectButton')
        } else if (subscription && OfferUtils.isOfferAcquisition(subscription?.offer)) {
          messageEmpty = t('app:pages:synthesis:myTransactionsContent:emptyAccount:posMessage')
        }
        return (
          <PageContent
            justifyContent={JustifyContent.CENTER}
            icon={<Icon type={IconTypes.NEOFONT} name='credit' size={42} color={Colors.secondary} />}
            title={messageEmpty}
            description={BankAccountUtils.isMain(selectedBankAccount) && (minCreditReached
              ? t('app:pages:synthesis:myTransactionsContent:emptyAccount:descriptionMinCreditReached')
              : t('app:pages:synthesis:myTransactionsContent:emptyAccount:description',
                { amount: NumbersUtils.displayPriceForHuman(minimumFirstProvisioningAmount) }))}
            button={
              <Button
                title={buttonEmpty}
                flat
                containerStyle={{ width: '100%' }}
                onPress={async () => {
                  if (isSelectedBankAccountProject) {
                    await dispatch(BankAccountActions.setSelected(selectedBankAccount.parentUuid))
                    history.push(PaymentsRoutes.bankTransfer.path)
                  } else {
                    history.push(CreditRoutes.creditChoices.path)
                  }
                }}
              />
            }
          />
        )
      case AccountTypeDto.POS:
        return (
          <PageContent
            icon={<Icon type={IconTypes.NEOFONT} name='tpe' color={Colors.secondary} size={50} />}
            title={t('app:pages:synthesis:myTransactionsContent:emptyPosAccount:message')}
          />
        )
      case AccountTypeDto.AGGREGATED:
        return (
          <PageContent
            icon={<Icon type={IconTypes.FEATHER} name='clock' color={Colors.secondary} size={50} />}
            title={t('app:pages:synthesis:myTransactionsContent:emptyAggregatedAccount:message')}
          />
        )
    }
  }

  const renderTelecollectionsAmount = () => {
    return (
      <>
        <div className='MyTransaction_telecollection'>
          <LinkedCard
            arrow
            onPress={() => history.push(Routes.Synthesis.telecollections.path)}
          >
            <div className='MyTransaction_telecollection_card'>
              <div className='MyTransaction_telecollection_card_icon'>
                <Icon type={IconTypes.NEOFONT} name='tlc' color={Colors.ghostBlack} size={35} />
              </div>
              <div className='MyTransaction_telecollection_card_text'>
                <Typography typeface='bold'>
                  {t('app:pages:synthesis:telecollections:title')}
                </Typography>
                <Typography typeface='subtitle' style={{ color: Colors.darkGreyText }}>
                  {/* eslint-disable-next-line max-len */}
                  {t('app:pages:synthesis:telecollections:expected')} : {NumbersUtils.displaySignedPriceForHuman(telecollectAmount ?? 0)}
                </Typography>
              </div>
            </div>
          </LinkedCard>
        </div>
        <TelecollectionIntroStep enabled={tlcIntroStepEnabled} />
      </>
    )
  }

  const preOrderCard = () => {
    if (cardOwnerUuid) {
      history.push(Routes.Services.choicesDebitCard.path)
      dispatch(CardActions.prepare({
        ...preparedCardOrder,
        personUuid: cardOwnerUuid,
        preOrder: true,
      } as PrepareCardOrder))
    } else {
      // Enable select cardOwner if not found
      history.push(Routes.Services.selectCardOwner.path)
      dispatch(CardActions.prepare({ ...preparedCardOrder, preOrder: true } as PrepareCardOrder))
    }
  }

  const renderReconnectAccount = () => {
    return (
      <PageContent
        icon={<Icon type={IconTypes.FEATHER} name='alert-circle' color={Colors.secondary} size={50} />}
        title={t('app:pages:synthesis:disconnected:title')}
        description={t('app:pages:synthesis:disconnected:description')}
        button={
          <Button
            containerStyle={{ width: '100%' }}
            title={t('app:pages:synthesis:disconnected:reconnect')}
            onPress={() => {
              const redirectUrl = `${config(ENV.COMNPAY_ORIGIN)}services/aggregation/fallback`
              const connection = NeobankApi.getInstance().aggregationApi
                .reconnectAccountAggregatedByUuid(
                  selectedBankAccount.uuid,
                  { uri: redirectUrl })
              connection.then(res => { location.href = res?.data }).catch((e) => {
                dispatch(RootActions.alert({
                  title: e.message,
                  type: AlertType.ERROR,
                }))
              })
            }}
          />
        }
      />
    )
  }

  useEffect(() => {
    if (selectedBankAccount && !selectedBankAccount?.lastUpdate) {
      (async () => {
        try {
          getTransactions(true)
          await dispatch(NotificationActions.list())
          if (isPosAccount) {
            setTimeout(() => {
              setTlcIntroStepEnabled(showIntroStepTlc)
            }, 1000)
            await dispatch(TelecollectionActions.getSummaryAmount())
          }
        } catch (e) {}
      })()
    }
  }, [selectedBankAccount])

  return (
    <>
      <KYCModal // KYC not validated or signature needed
        show={showActionModal}
        onClose={() => setShowActionModal(false)}
      />
      <InformationModal // advance created modal
        show={showAdvanceModal}
        confetti
        showInfoIcon={false}
        onClose={() => setShowAdvanceModal(false)}
        title={t('neo-commons:prospect:inlineAdvanced', {
          advanceMax: NumbersUtils.displayPriceForHuman(advanceMax, undefined, false),
        })}
        descriptionAlign='center'
        description={t('neo-commons:prospect:restrictedUseOfLoan')}
        extraIcon={<img src={debitCard} alt='debitCard' style={{ width: 200, margin: 5 }} />}
        description2={t('neo-commons:prospect:reimbourseAdvance', {
          advanceMax: NumbersUtils.displayPriceForHuman(advanceMax, undefined, false),
        })}
        button={
          <Button
            title='Ok'
            onPress={() => setShowAdvanceModal(false)}
          />
        }
      />
      <CancelPosAccountClosureModal onClose={() => setOpenCancelPosModal(false)} open={openCancelPosModal} />
      {BankAccountUtils.isSecondary(selectedBankAccount) &&
        selectedBankAccount.status === AccountStatusDto.TERMINATION_IN_PROGRESS &&
          <TransactionBanner
            icon={
              <Icon
                name='alert-circle' type={IconTypes.FEATHER}
                size={20} color={Colors.error}
              />
            }
            button={
              <Button
                small skin={ButtonSkin.PRIMARY} title={t('app:global:cancel')}
                onPress={async () => {
                  if (BankAccountUtils.isProject(selectedBankAccount)) {
                    await dispatch(BankAccountActions.cancelAccountClosure(selectedBankAccount.uuid))
                    history.push(Routes.Services.ProjectsRoutes.cancelClosure.path)
                  } else {
                    setOpenCancelPosModal(true)
                  }
                }}
              />
            }
            title={t('app:pages:synthesis:transactionBanner:title',
              { date: dayjs().endOf('month').format('DD/MM/YYYY') })}
            subtitle={t('app:pages:synthesis:transactionBanner:subtitle')}
          />}
      {BankAccountUtils.isMain(selectedBankAccount) && selectedBankAccount.status === AccountStatusDto.SUSPENDED &&
        <TransactionBanner
          icon={
            <Icon
              name='alert-circle' type={IconTypes.FEATHER}
              size={20} color={Colors.error}
            />
          }
          title={t('neo-commons:accountSuspended:banner:title')}
          subtitle={
            i18n.exists(`neo-commons:accountSuspended:banner:subtitle:${selectedBankAccount?.suspension?.reason}`)
              ? t(`neo-commons:accountSuspended:banner:subtitle:${selectedBankAccount?.suspension?.reason}`)
              : t('neo-commons:accountSuspended:banner:subtitle:default')
          }
        />}
      <div className='ProspectEmptyList'>
        {!isLoading &&
          OfferUtils.isAdvanceEligibleNotActive(socleOffer, eligibilityOrder, advances, advanceOrder) &&
          (
            // condition: signature ok, éligible à l'avance et avance non activée
            <MenuItemCard
              title={t('neo-commons:prospect:needAdvance', {
                advanceMax: NumbersUtils.displayPriceForHuman(advanceMax, undefined, false),
              })}
              subtitle={t('neo-commons:prospect:creditAvailable')}
              containerStyle={{ width: '100%' }}
              titleStyle={{ marginBottom: -2 }}
              chevronPosition='center'
              extraIcon={
                <Button
                  flat
                  skin={ButtonSkin.OUTLINE_PRIMARY}
                  accessibilityLabel='ValidateButton'
                  title={t('neo-commons:prospect:advance', {
                    advanceMax: NumbersUtils.displayPriceForHuman(advanceMax, undefined, false),
                  })}
                  leftIcon={<CustomIcon name='coins-amount' size={25} color={Colors.primary} />}
                  onPress={() => orderAdvance()}
                />
              }
              icon={<CustomIcon name='logo' size={44} color={Colors.trueBlack} />}
            />
          )}
        {!isLoading &&
          (OfferUtils.isAdvanceActivated(socleOffer, advances) ||
            OfferUtils.isAdvanceOrdered(mainSubscription, advanceOrder)) &&
          (// condition : avance active ou précommandée
            <MenuItemCard
              title={null}
              onPress={() => history.push(Routes.Services.advance.path)}
              containerStyle={{ width: '100%' }}
              chevronPosition='center'
              extraIcon={
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                }}
                >
                  <div>
                    <Typography2
                      typeface='body2'
                      style={{
                        textTransform: 'uppercase',
                        marginBottom: 4,
                        color: Colors.darkGreyText,
                      }}
                    >
                      {t('neo-commons:prospect:used')}
                    </Typography2>
                    <div
                      style={{
                        width: '100%',
                        borderRadius: 24,
                        padding: '4px 12px',
                        backgroundColor: Colors.coolGrey,
                      }}
                    >
                      <Typography2
                        typeface='h5'
                        style={{ textTransform: 'uppercase', color: Colors.darkGreyText }}
                      >
                        {NumbersUtils.displayPriceForHuman(remainingBalance || 0)}
                      </Typography2>
                    </div>
                  </div>
                  <div style={{ marginLeft: 12 }}>
                    <Typography2
                      typeface='body2'
                      style={{ textTransform: 'uppercase', marginBottom: 4, color: Colors.primary }}
                    >
                      {t('neo-commons:prospect:available')}
                    </Typography2>
                    <div
                      style={{
                        width: '100%',
                        borderRadius: 24,
                        padding: '4px 12px',
                        backgroundColor: Colors.primary,
                      }}
                    >
                      <Typography2 typeface='h5' style={{ textTransform: 'uppercase', color: Colors.white }}>
                        {NumbersUtils.displayPriceForHuman(activeAdvance?.currentBalance || advanceMax)}
                      </Typography2>
                    </div>
                  </div>
                </div>
              }
              icon={
                <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                  <CustomIcon name='logo' size={44} color={Colors.trueBlack} />
                  <Typography2 typeface='h5' style={{ lineHeight: 20, marginLeft: 8 }}>
                    {t('neo-commons:prospect:advancedNoNewLine', {
                      advanceMax: NumbersUtils.displayPriceForHuman(advanceMax, undefined, false),
                    })}
                  </Typography2>
                </div>
              }
            />
          )}
        {mainSubscription &&
          SubscriptionUtils.isActive(mainSubscription) &&
          OfferUtils.isOfferAdvance(socleOffer) &&
          (!transactionsList || transactionsList?.length === 0) &&
          !isLoadingTransactions &&
            <MenuItemCard
              subtitle={t('neo-commons:client:creditCardMessage')}
              containerStyle={{ width: '100%', marginTop: 8 }}
              chevronPosition='center'
              extraIcon={
                <Button
                  flat
                  disabled={!OfferUtils.isAdvanceActivated(socleOffer, advances)}
                  accessibilityLabel='ValidateButton'
                  leftIcon={<CustomIcon name='card' size={25} color={Colors.white} />}
                  title={t('neo-commons:client:creditCardButton')}
                  onPress={() => history.push(Routes.Services.debitCardDetail.path)}
                />
              }
              icon={<img src={debitCard} alt='debitCard' style={{ width: 100, margin: 5 }} />}
            />}
        {(isProspectProject || isProspectMain) ? (
          kycPending ? (
            <MenuItemCard
              title={t('neo-commons:prospect:kycPendingTitle')}
              subtitle={t('neo-commons:prospect:kycPendingText')}
              chevronContainerStyle={{ display: 'none' }}
              containerStyle={{ width: '100%' }}
              icon={<CustomIcon name='clock' size={28} color={Colors.primary} />}
            />
          ) : (!eligibilityOrder || !eligibilityOrder.analysis.eligibility) && !kycInProgress && (
            <MenuItemCard
              title={t('neo-commons:prospect:openAnAccount')}
              subtitle={t('neo-commons:prospect:realTimeUpdatedBalance')}
              onPress={() => history.push(Routes.Subscription.index.path)}
              chevronContainerStyle={{ display: 'none' }}
              containerStyle={{ width: '100%' }}
              icon={<CustomIcon name='codeLock' size={28} color={Colors.primary} />}
            />)
        ) : null}
        {kycPending && !preOrderedCard && hasFreeCard &&
          <MenuItemCard
            title={
              socleOffer?.offerCode !== 'NEO-OFR-INDIV-01'
                ? t('app:pages:services:account:noelseCard') + ' ' + t('app:pages:services:account:freeF')
                : t('app:pages:services:account:noelseCard')
            }
            subtitle={t('neo-commons:prospect:creditCardMessage')}
            onPress={preOrderCard}
            containerStyle={{ width: '100%', marginTop: 8 }}
            chevronPosition='center'
            extraIcon={
              <Button
                flat
                accessibilityLabel='ValidateButton'
                title={t('neo-commons:global:order')}
                onPress={preOrderCard}
              />
            }
            icon={<img src={debitCard} alt='debitCard' style={{ width: 100, margin: 5, marginTop: 10 }} />}
          />}
      </div>
      <div className='MyTransaction' data-testid='Synthesis/MyTransactions'>
        {isProspectAccount && kycInProgress && (
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <MenuItemCard
              title={t('neo-commons:prospect:mainAccount')}
              subtitle={t('app:global:opening')}
              onPress={() => history.push(Routes.Subscription.index.path)}
              icon={<CustomIcon name='logo' size={44} color={Colors.trueBlack} />}
            />
            <Typography2 typeface='body3' style={{ textTransform: 'uppercase', textAlign: 'center' }}>
              {t('neo-commons:prospect:today')}
            </Typography2>
          </div>
        )}
        {isProspectAccount
          ? (
            !mainSubscription && eligibilityOrder && eligibilityOrder.analysis.eligibility ? (
              <MenuItemCard
                title={t('neo-commons:prospect:loan100eurosToExpenseWithCreditCard')}
                subtitle={t('neo-commons:prospect:restrictedUseOfLoan')}
                containerStyle={{ width: '100%', marginTop: 8 }}
                chevronPosition='center'
                extraIcon={
                  <Button
                    containerStyle={{ marginTop: 16 }}
                    flat
                    accessibilityLabel='ValidateButton'
                    leftIcon={<CustomIcon name='codeLock' size={28} color={Colors.white} />}
                    title={t('neo-commons:prospect:openAnAccount')}
                    onPress={() => history.push(Routes.Subscription.index.path)}
                  />
                }
                icon={<img src={debitCard} alt='debitCard' style={{ width: 140, margin: 5, marginTop: 10 }} />}
              />
            ) : (
              <>
                {[0, 1, 2].map((i : number) => {
                  return (
                    <Card key={i} skin={CardSkin.DEFAULT} withShadow containerStyle={{ padding: 16 }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                          <CustomIcon name='codeLock' size={28} color={Colors.disable} />
                        </div>
                        <div style={{ flex: 1, marginLeft: 16 }}>
                          <div
                            style={{
                              width: '70%',
                              height: 10,
                              marginBottom: 8,
                              borderRadius: 6,
                              backgroundColor: Colors.disable,
                            }}
                          />
                          <div style={{ width: '50%', height: 10, borderRadius: 6, backgroundColor: Colors.disable }} />
                        </div>
                      </div>
                    </Card>
                  )
                })}
              </>
            )) : (!loadedOnce &&
            !selectedBankAccount?.lastUpdate && (<ShimmerList type={ShimmerListType.TRANSACTION} fading />)
          )}

        {!loadedOnce && isAggregatedAccount && selectedBankAccount?.lastUpdate &&
        renderReconnectAccount()}
        {loadedOnce && isPosAccount && (telecollectAmount > 0 || (transactionsList && transactionsList.length > 0)) &&
        renderTelecollectionsAmount()}
        {loadedOnce && (!transactionsList || transactionsList.length === 0) &&
        renderNoTransactions()}

        {!selectedBankAccount?.lastUpdate &&
          <PaginationList
            state={transactionsState}
            stateList={transactionsList}
            items={(item) =>
              <div
                key={item.id}
                className='MyTransaction_List_item'
              >
                <TransactionCard
                  transaction={item}
                  currency={selectedBankAccount?.currencyCode}
                  onPress={() => {
                    history.push(generatePath(
                      Routes.Synthesis.transactionDetails.path,
                      { id: (item as TransactionDto).id }
                    ))
                  }}
                />
              </div>}
            eventLoading={loadedOnce && [
              TransactionTypes.LIST_TRANSACTION_REQUEST,
              TelecollectionTypes.LIST_SUMMARY_AMOUNTS_REQUEST,
            ]}
            groupData={TransactionUtils.groupTransactionsByDate(transactionsList)}
            emptyText=''
            next={() => getTransactions()}
            refreshFunction={() => {
              try {
                dispatch({ type: TransactionTypes.RESET })
                dispatch(TransactionActions.getTransactionList())
              } catch (e) {}
            }}
          />}

        {notifications.length > 0 && <NotificationsAlert />}
      </div>
    </>
  )
}
