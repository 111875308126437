import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

import { createStylesheet } from '../utils'

const styles = createStylesheet(() => {
  return StyleSheet.create({
    versoContainer: {
      borderWidth: 1,
      borderColor: Colors.gray,
      borderRadius: 10,
      backgroundColor: Colors.white,
      alignSelf: 'flex-start',
      width: '100%',
    },
    label: {
      color: Colors.darkGreyText,
      marginBottom: 10,
    },
    value: {
      marginBottom: 10,
    },
    panArea: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    versoDataArea: {
      padding: 15,
    },
    dateAndCvvArea: {
      flexDirection: 'row',
    },
    cvvArea: {
      marginLeft: 20,
      alignItems: 'center',
    },
    cvv: {
      backgroundColor: Colors.secondary,
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 6,
      paddingRight: 6,
      borderRadius: 10,
      alignSelf: 'flex-start',
    },
  })
})

export default styles
