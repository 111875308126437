import { AppPolicy } from 'Policies/AppPolicy'

import { RoutesInterface } from '../Routes/Routes'

export class RoutesUtils {
  /**
   * Get string of translate message from code
   *
   * @returns Return - all routes
   * @example
   * ```
   * const routes = RoutesUtils.getAllRoutes(Routes.Credit)
   * console.log(routes)
   * ```
   * Output : ['/credit', '/credit/amount', ...]
   * @example
   * ```
   * const routes = RoutesUtils.getAllRoutes(Routes, { isAvailable: true })
   * console.log(routes)
   * ```
   * Output : ['/credit', ...]
   *
   * @param routes - Object Routes
   * @param paramsRequest - Object of multiple parameters request for route
   */
  static getAllRoutes (
    routes: Record<string, any>,
    paramsRequest?: { isAvailable: boolean, isFullRouteObject: boolean }
  ): Array<string> {
    const result = []
    Object.values(
      Object.getOwnPropertyDescriptors(routes)
    ).filter((route) => route.enumerable)
      .filter((route: TypedPropertyDescriptor<RoutesInterface>) => {
        // if class continue to child
        // console.log(route.value)
        if (typeof route.value === 'function' || route.value?.path === undefined) {
          result.push(...this.getAllRoutes(Object.values(route.value), paramsRequest))
        } else {
          switch (true) {
            case !paramsRequest:
            case paramsRequest && route.value.available === paramsRequest.isAvailable:
            case AppPolicy.authoriseAllRoutes():
              result.push(paramsRequest?.isFullRouteObject ? route.value : route.value.path)
          }
        }
        return result
      })
    return result
  }
}
