import React from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { documentUploadPolicy, DocumentUploadStep } from 'Policies'

import { NeobankApi } from '@neo-commons/services'
import { useWizardPolicy } from '@neo-commons/hooks'
import { SignInActions, SubscriptionActions } from '@neo-commons/store'

import { Tunnel } from '@neo-web/componentsOld'

import { store } from '../../../Store'

import { DocumentUploadSummary } from './DocumentUploadSummary/DocumentUploadSummary'
import { DocumentUploadUploading } from './DocumentUploadUploading/DocumentUploadUploading'
import { DocumentUploadSuccess } from './DocumentUploadSuccess/DocumentUploadSuccess'
import { DocumentUploadFailure } from './DocumentUploadFailure/DocumentUploadFailure'

export const DocumentUpload: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentStep, steps: wizardSteps } = useWizardPolicy(store, documentUploadPolicy, {
    [DocumentUploadStep.summary]: DocumentUploadSummary,
    [DocumentUploadStep.uploading]: DocumentUploadUploading,
    [DocumentUploadStep.success]: DocumentUploadSuccess,
    [DocumentUploadStep.failure]: ({ nextStep }) => <DocumentUploadFailure nextStep={nextStep} />,
  }, {
    onDone: async () => {
      const token = await NeobankApi.getInstance().getToken()
      if (token.data?.authenticationType) {
        await dispatch(SubscriptionActions.list())
        history.goBack()
      } else {
        await dispatch(SignInActions.reset())
        history.push(Routes.SignIn.index.path)
      }
    },
  })

  return (
    <Tunnel
      overrideStyle={{ backgroundColor: '#fff' }}
      onBack={() => currentStep?.meta?.canGoBack ? history.goBack() : undefined}
      component={
        <>
          {currentStep?.path &&
            <Redirect
              to={{
                pathname: currentStep?.path,
              }}
            />}
          {wizardSteps.map((value) =>
            <Route
              key={value.navigationKey}
              exact
              path={value.path}
              component={value.component}
            />
          )}
        </>
      }
    />
  )
}
