// Generic
export const generic = {
  marginHorizontal: 20,
  marginVertical: 20,
  section: 25,
  baseMargin: 16,
  basePadding: 10,
  doubleBaseMargin: 20,
  smallMargin: 5,
  doubleSection: 50,
  horizontalLineHeight: 1,
}

// List Item
export const item = {
  radius: 15,
  listContainerHeight: 50,
}

// Header
export const header = {
  height: 150,
}

// Buttons
export const button = {
  borderWidth: 1,
  radius: 15,
  height: 40,
}

export const bottomButton = {
  marginBottom: 16,
}

export const margedBottomButton = {
  marginBottom: 16,
  marginHorizontal: 16,
}

// Icons
export const icon = {
  tiny: 15,
  small: 20,
  medium: 30,
  large: 45,
  XL: 50,
}

// Images
export const image = {
  small: 20,
  medium: 40,
  large: 60,
  logo: 200,
}

// Texts Paddings
export const text = {
  alignLeftPadding: 20,
  alignTopPadding: 15,
}

// iOS Specific
export const ios = {
  clickableOpacity: 0.7,
  clickableHeight: 5,
  unclickableRadius: 2,
  unclickableHeight: 2,
  unclickableOpacity: 0.2,
  shadowOffsetWidth: 0,
  shadowOffsetHeight: 5,
  shadowRadius: 5,
  keyboardVerticalOffset: 100,
}

// Android Specific
export const android = {
  clickableElevation: 5,
  unclickableElevation: 1,
}
