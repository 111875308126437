
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { SelectPicker } from 'rsuite'

import { UserActions } from '@neo-commons/store'

import { SocioprofessionalCategoryDto, UserDto } from '@afone/neo-core-client/dist/models'

import { useResponsive } from '@neo-web/hooks'

import './MyInfoFields.scss'

export interface MyProfessionContentProps {
  user: UserDto
  onCloseModal: () => void
}

export const MyProfessionContent: React.FC<MyProfessionContentProps> = ({
  user,
  onCloseModal,
} : MyProfessionContentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTabletOrMobile = useResponsive()
  const [profession, setProfession] = useState<SocioprofessionalCategoryDto>(user?.person?.socioprofessionalCategory)

  const updateClientProfession = (profession: SocioprofessionalCategoryDto) => {
    (async () => {
      try {
        await dispatch(UserActions.updateUserPerson(user, {
          ...user?.person,
          socioprofessionalCategory: profession,
        }))
        onCloseModal()
      } catch (error) {}
    })()
  }

  return (
    <div className='fieldContainer'>
      <SelectPicker
        data={Object.entries(SocioprofessionalCategoryDto).map(
          (value) =>
            ({
              value: value[1],
              label: t('app:global:enum:profession:' + value[0].replace(/(_)/g, '').toLowerCase()),
            }),
        )}
        onChange={value => {
          setProfession(value)
          updateClientProfession(value)
        }}
        appearance='subtle'
        defaultValue={profession}
        cleanable={false}
        searchable={false}
        placement={isTabletOrMobile ? 'topStart' : 'bottomStart'}
      />
    </div>
  )
}
