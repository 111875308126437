import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Modal } from '@neo-web/componentsOld'

export interface CloseSubAccountModalProps {
  onClose?: () => void
  show: boolean
  cancelSubscription: boolean
  price?: string
}

export const CloseSubAccountModal: React.FC<CloseSubAccountModalProps> = (
  { onClose, show, cancelSubscription, price }: CloseSubAccountModalProps
) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Modal
      autoFocus open={show} onClose={onClose}
      displayCross
      titleIcon={
        <Icon
          name='alert-circle'
          type={IconTypes.FEATHER}
          size={40}
          color={Colors.secondary}
        />
      }
      title={t(cancelSubscription
        ? 'app:pages:services:closeProject:cancel:modal:title'
        : 'app:pages:services:closeProject:close:modal:title', { price: price })}
    >
      <div className='CancelPosAccountClosureModal_container'>
        <Typography2 typeface='body2'>
          {t(cancelSubscription
            ? 'app:pages:services:closeProject:cancel:modal:description'
            : 'app:pages:services:closeProject:close:modal:description')}
        </Typography2>
        <Button
          title={t('app:pages:services:closeProject:close:modal:confirm')}
          skin={ButtonSkin.OUTLINE_PRIMARY}
          onPress={() => history.push(Routes.Services.CloseProjectRoutes.index.path)}
        />
        <Button
          title={t('app:pages:services:closeProject:close:modal:cancel')}
          skin={ButtonSkin.PRIMARY}
          onPress={onClose}
        />
      </div>
    </Modal>
  )
}
