import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { BankAccountActions, BankAccountSelectors, PosActions } from '@neo-commons/store'
import { BankAccountUtils, Validators } from '@neo-commons/libraries'
import { Button, ButtonSkin, Input, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { AccountDto, AccountTypeDto, PosDto, PosUpdateDto } from '@afone/neo-core-client/dist/models'

import { Modal, SelectionList } from '@neo-web/componentsOld'

interface ReassignPosModalProps {
  openModal: boolean,
  setOpenModal: (arg: boolean) => void,
  bankAccounts: AccountDto[]
  pos: PosDto[],
  selectedPos: PosDto,
}

export const ReassignPosModal: React.FC<ReassignPosModalProps> = (
  { openModal, setOpenModal, bankAccounts, pos, selectedPos }: ReassignPosModalProps
) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [posAccounts, setPosAccounts] = useState<AccountDto[]>()
  const [createPosAccount, setCreatePosAccount] = useState<boolean>(false)
  const [nbPosInAccount, setNbPosInAccount] = useState<number>()
  const [accountName, setAccountName] = useState<string>()

  const selectedBankAccount: AccountDto = useSelector(
    BankAccountSelectors.selected,
    (prev, next) => prev?.uuid === next?.uuid
  )

  useEffect(() => {
    if (openModal) {
      setNbPosInAccount(pos.filter(p => p?.accountUuid === selectedPos?.accountUuid).length)
      setPosAccounts(bankAccounts.filter(bankAccount => BankAccountUtils.isPos(bankAccount)))
    }
  }, [openModal])

  const resetModal = () => {
    setTimeout(() => {
      setAccountName('')
      setCreatePosAccount(false)
    }, 500)
  }

  const reassignPos = async (newAccountUuid) => {
    setOpenModal(false)
    if (newAccountUuid !== selectedPos?.accountUuid) {
      await dispatch(PosActions.updatePos({
        pos: selectedPos,
        update: {
          newAccountPosUuid: newAccountUuid,
        },
      }))
    }
  }

  const createNewAccount = async () => {
    try {
      setOpenModal(false)
      const newAccount: any = await dispatch(
        BankAccountActions.createSubAccount({
          mainAccountUuid: posAccounts[0]?.parentUuid,
          xValidationOnly: 0,
          subAccountCreationRequestDto: {
            accountType: AccountTypeDto.POS,
            name: accountName,
            workflowSubscriptionUuid: selectedPos?.workflowSubscriptionUuid,
          },
        })
      )
      if (newAccount?.uuid) {
        await dispatch(BankAccountActions.list({
          bankAccountType: AccountTypeDto.POS,
          parentUuid: BankAccountUtils.isSecondary(selectedBankAccount) ? selectedBankAccount.parentUuid : selectedBankAccount?.uuid,
          isPaginate: true,
        }))
        const update: PosUpdateDto = { newAccountPosUuid: newAccount.uuid }
        await dispatch(PosActions.updatePos({ pos: selectedPos, update }))
      }
      resetModal()
    } catch (error) {
      resetModal()
    }
  }

  const renderLastPosAlert = () =>
    <div style={{ textAlign: 'center', lineHeight: '18px' }}>
      <Typography
        typeface='smallTextWeightMedium'
        style={{ color: Colors.error }}
      >
        {t('app:pages:services:pos:managePos:moveToAccount:impossible')}
      </Typography>
    </div>

  return (
    <Modal
      open={openModal}
      displayCross
      title={createPosAccount
        ? t('app:pages:services:pos:managePos:newSubAccount:title')
        : t('app:pages:services:pos:managePos:moveToAccount:title')}
      onClose={() => {
        setOpenModal(false)
        resetModal()
      }}
    >
      {nbPosInAccount === 1 && renderLastPosAlert()}
      {createPosAccount &&
        <>
          <Input
            placeholder={t('app:pages:services:pos:managePos:newSubAccount:placeholder')}
            value={accountName}
            maxLength={50}
            isRequired
            validators={[Validators.isNotEmpty]}
            onChangeText={(text) => setAccountName(text)}
          />
          <div>
            <Button
              skin={ButtonSkin.PRIMARY}
              containerStyle={{ marginTop: 30 }}
              title={t('app:global:validate')}
              disabled={nbPosInAccount === 1 || accountName === ''}
              onPress={() => createNewAccount()}
            />
          </div>
        </>}
      {(!createPosAccount && posAccounts) &&
        <>
          <SelectionList
            list={posAccounts.map(bankAccount => ({
              id: bankAccount.uuid,
              label: bankAccount.name,
            }))}
            selectedId={selectedPos?.accountUuid}
            disabled={nbPosInAccount === 1}
            onValidate={(newAccountUuid) => reassignPos(newAccountUuid)}
            additionalAction={(
              <Button
                skin={ButtonSkin.OUTLINE_PRIMARY}
                containerStyle={{ marginTop: 8 }}
                title={t('app:pages:services:pos:managePos:newSubAccount:button')}
                disabled={nbPosInAccount === 1}
                onPress={() => setCreatePosAccount(true)}
              />
            )}
          />
        </>}
    </Modal>
  )
}
