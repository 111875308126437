
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { SignInActions } from '@neo-commons/store'

import { TunnelContent } from '@neo-web/componentsOld'

import './NotEuropeanTaxResident.scss'

export const NotEuropeanTaxResident: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <TunnelContent
      icon={<Icon name='alert-circle' type={IconTypes.FEATHER} size={40} color={Colors.secondary} />}
      subtitle={t('app:pages:subscription:individual:notEuropeanTaxResident:title')}
      description={t('app:pages:subscription:individual:notEuropeanTaxResident:description')}
      footer={
        <div className='NotEuropeanTaxResident_footer'>
          <div className='NotEuropeanTaxResident_footer_button'>
            <Button
              skin={ButtonSkin.OUTLINE_PRIMARY}
              title={t('app:pages:subscription:individual:notEuropeanTaxResident:buttonBackToHome')}
              onPress={async () => {
                await dispatch(SignInActions.signOut())
                history.push(Routes.SignIn.index.path)
              }}
            />
          </div>
          <div className='NotEuropeanTaxResident_footer_button'>
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:pages:subscription:individual:notEuropeanTaxResident:buttonKeepInformed')}
              onPress={() => history.push(Routes.Subscription.keepInformed.path)}
            />
          </div>
        </div>
      }
    />
  )
}
