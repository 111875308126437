import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AppConfigTxt } from 'Config/AppConfig'
import { StateTypes } from 'Store'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Modal } from '@neo-web/componentsOld'
import { UrlUtils } from '@neo-web/utils'

interface TelecollectionModalProps {
  open: boolean,
  onClose: () => void,
}

export const TelecollectionModal: React.FC<TelecollectionModalProps> = (props) => {
  const { t } = useTranslation()

  const userState = useSelector((state: StateTypes) => state.user.data)

  return (
    <Modal
      open={props.open}
      displayCross
      backdrop='static'
      onClose={props.onClose}
      title={t('neo-commons:pages:services:pos:lost:selectPos:modalTlc:title')}
      titleIcon={<Icon color={Colors.secondary} name='alert-circle' size={40} type={IconTypes.FEATHER} />}
      footer={
        <Button
          skin={ButtonSkin.OUTLINE_PRIMARY}
          containerStyle={{ marginTop: 12 }}
          title={t('neo-commons:pages:services:pos:lost:selectPos:modalTlc:button')}
          onPressOut={() => {
            props.onClose()
            UrlUtils.openMailApp(
              AppConfigTxt.MAIL_SERVICE_CLIENT,
              `${t('app:pages:myProfile:help:customerService:subject')} [${userState.uuid}]`,
            )
          }}
        />
      }
    >
      <Typography typeface='content'>
        {t('neo-commons:pages:services:pos:lost:selectPos:modalTlc:text')}
      </Typography>
    </Modal>
  )
}
