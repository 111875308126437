import React, { FunctionComponent } from 'react'
import { View, Text, Image, ViewStyle, ImageStyle, TextStyle } from 'react-native'

import { Flags } from '../../assets/images'
import { Card, CardSkin } from '../Card/Card'
import { Icon, IconTypes } from '..'

import styles from './styles'

export interface CustomerServiceCountryCardProps {
  countryCode: string
  title: string
  subtitle: string
  onPress?: () => void
  containerStyle?: ViewStyle
  rightStyle?: ViewStyle
  leftStyle?: ImageStyle
  labelContainerStyle?: ViewStyle
  titleStyle?: TextStyle
  subtitleStyle?: TextStyle
  phoneNumber?: string
}

export const CustomerServiceCountryCard: FunctionComponent<CustomerServiceCountryCardProps> = (props) => {
  const flagSource = Flags[props.countryCode ?? 'Unknown'] ?? Flags.Unknown

  return (
    <Card skin={CardSkin.DEFAULT} containerStyle={styles.itemContainer} onPress={props.onPress}>
      <View style={styles.iconContainer}>
        <Image
          style={[styles.leftIcon, props.leftStyle]}
          source={flagSource}
        />
      </View>
      <View style={[styles.labelContainer, props.labelContainerStyle]}>
        <Text style={[styles.title, props.titleStyle]}>
          {props.title}
        </Text>
        <Text style={[styles.subtitle, props.subtitleStyle]}>
          {props.subtitle}
        </Text>
      </View>
      <View style={styles.iconContainer}>
        <Icon
          name='phone-call'
          type={IconTypes.FEATHER}
          size={19}
          iconStyle={[styles.rightIcon, props.rightStyle]}
        />
      </View>
    </Card>
  )
}
