import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'
import { config, ENV } from 'Config/EnvConfig'

import { NeobankApi } from '@neo-commons/services'
import { Button, ButtonSkin, Card, CardSkin, CheckboxInput, Stepper2, Typography } from '@neo-commons/components'
import { ConfigSelectors } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { AggregationConnectorDto } from '@afone/neo-core-client'

import { JustifyContent, PageContent } from '@neo-web/componentsOld'

export const AdvanceEligibilityTerms: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [CGV, setCGV] = useState(false)

  const selectedConnector: AggregationConnectorDto =
    useSelector((state: StateTypes) => state.bankAccount.selectedConnector)
  const currentConfig = useSelector(ConfigSelectors.getCurrentConfig)

  const onPressNextStep = () => {
    const redirectUrl = `${config(ENV.COMNPAY_ORIGIN)}advanceEligibility/aggregation/fallback`
    const connection = NeobankApi.getInstance().aggregationApi
      .connectAggregation(0, { uri: redirectUrl, connectorId: selectedConnector?.connectorId })
    connection.then(res => {
      location.href = res?.data?.uri
    }).catch((e) => {
      if (e?.code === 'C2700') { // Vous avez dépassé la limite autorisée pour l'agrégation de comptes
        history.push(Routes.AdvanceEligibility.failure.path)
      } else {
        history.push(Routes.AdvanceEligibility.error.path, { errorMessage: e?.message })
      }
    })
  }
  const checkboxInputText =
    <Typography typeface='body1'>
      {t('neo-commons:eligibility:aggregation:activate:accept')}
      <a
        href={currentConfig?.cgusAggregate?.individual}
        target='_blank' className='ActivateAggregateAccounts_checkbox_text' rel='noreferrer'
      >
        {t('neo-commons:eligibility:aggregation:activate:cg')}
      </a>
      {t('neo-commons:eligibility:aggregation:activate:afterCg')}
    </Typography>
  return (
    <PageContent
      withBackground
      title={t('neo-commons:eligibility:terms:title')}
      justifyContent={JustifyContent.START}
    >
      <Stepper2 currentStep={1} />
      <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 24 }}>
        <Typography typeface='body1'>
          {t('neo-commons:eligibility:terms:text')}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', gap: 16, marginBottom: 24 }}>
          <img src={selectedConnector?.icon} alt={selectedConnector?.name} style={{ width: 240 }} />
        </div>
        {[0, 1].map((i : number) => {
          return (
            <Card key={i} skin={CardSkin.DEFAULT} withShadow containerStyle={{ padding: 16, marginBottom: 0 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ flex: 1, marginLeft: 16 }}>
                  <div
                    style={{
                      width: '70%',
                      height: 10,
                      marginBottom: 8,
                      borderRadius: 6,
                      backgroundColor: Colors.disable,
                    }}
                  />
                  <div
                    style={{
                      width: '30%',
                      height: 10,
                      marginBottom: 8,
                      borderRadius: 6,
                      backgroundColor: Colors.disable,
                    }}
                  />
                  <div
                    style={{ width: '50%', height: 10, borderRadius: 6, backgroundColor: Colors.disable }}
                  />
                </div>
              </div>
            </Card>
          )
        })}
        <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
          <div style={{ textAlign: 'left' }}>
            <CheckboxInput
              size={24}
              isSelected={CGV}
              text={checkboxInputText}
              onPress={(value) => setCGV(value)}
            />
          </div>
          <Button
            flat
            skin={ButtonSkin.PRIMARY}
            disabled={!CGV}
            title={t('app:global:accept')}
            onPress={() => onPressNextStep()}
          />
        </div>
      </div>
    </PageContent>
  )
}
