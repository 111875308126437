import { useSelector } from 'react-redux'

import { BankAccountSelectors, BankTransferTypeEnum, ContactState, Transfer, TransferState } from '@neo-commons/store'
import { BankAccountUtils } from '@neo-commons/libraries'

import { AccountDto, ContactDto } from '@afone/neo-core-client/dist/models'

import { Routes } from '../Routes/Routes'

import { AbstractPolicy } from './AbstractPolicy'

export class TransferPolicy extends AbstractPolicy {
  public static transferRedirect (transferState: TransferState): string {
    let redirect = Routes.Payments.bankTransfer.path

    switch (true) {
      case !TransferPolicy.canStep1(transferState):
        redirect = Routes.Payments.bankTransfer.path
        break
      case !TransferPolicy.canStep2(transferState):
        redirect = Routes.Payments.account.path
        break
      case !TransferPolicy.canStep3(transferState):
        redirect = Routes.Payments.contact.path
        break
      case TransferPolicy.canStep4(transferState):
        redirect = Routes.Payments.amount.path
        break
      case !TransferPolicy.canStep5(transferState):
        redirect = TransferPolicy.isRepeated(transferState)
          ? Routes.Payments.recurring.path : Routes.Payments.scheduled.path
        break
      case TransferPolicy.canStep6(transferState):
        redirect = Routes.Payments.summary.path
        break
    }

    return redirect
  }

  public static canStep1 (transferState: TransferState): boolean {
    const { prepare } = transferState

    return prepare.transferType != null
  }

  public static canStep2 (transferState: TransferState): boolean {
    const { prepare } = transferState

    return !!prepare.accountUuid
  }

  public static isSelectContactAccount (contactState: ContactState): boolean {
    const contactSelected = contactState.list.selectedId

    return !!contactSelected
  }

  public static canStep3 (transferState: TransferState): boolean {
    const { prepare } = transferState

    return prepare.contactChannelUuid != null
  }

  public static canStep4 (transferState: TransferState): boolean {
    const { prepare } = transferState

    return prepare.amount > 0
  }

  public static canStep6 (transferState: TransferState): boolean {
    let result
    const { prepare } = transferState

    result = this.canStep1(transferState) && this.canStep2(transferState) && this.canStep3(transferState) &&
        this.canStep4(transferState)

    if (prepare.transferType !== BankTransferTypeEnum.Punctual && result) {
      result = this.canStep5(transferState)
    }

    return result
  }

  public static canStep5 (transferState: TransferState): boolean {
    const { prepare } = transferState

    return prepare.startDate !== undefined ||
      (prepare.transferType === BankTransferTypeEnum.Punctual ||
        prepare.recurrence !== undefined)
  }

  public static isIssuerSecondary (transferState: TransferState): boolean {
    const { prepare } = transferState

    const preparedTransferBankAccount = useSelector(BankAccountSelectors.byId(prepare.accountUuid))

    return BankAccountUtils.isSecondary(preparedTransferBankAccount)
  }

  public static isRepeated (transferState: TransferState): boolean {
    const { prepare } = transferState

    return prepare.transferType === BankTransferTypeEnum.Repeated
  }

  public static isPunctual (transferState: TransferState): boolean {
    const { prepare } = transferState

    return prepare.transferType === BankTransferTypeEnum.Punctual
  }

  public static iStepOne (path: string): boolean {
    return path === Routes.Payments.bankTransfer.path
  }

  public static step2isComplete (isRecipientCorrect: boolean, name: string): boolean {
    return isRecipientCorrect && name !== ''
  }

  public static hasAmountToTransfer (bankAccount: AccountDto, amount: string): boolean {
    return amount === '' || bankAccount?.balance >= parseFloat(amount)
  }

  public static contactHasSelectChannel (contact: ContactDto, transfer: Transfer): boolean {
    return contact.uuid === transfer.contactUuid
  }
}
