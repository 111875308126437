import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Card, CardSkin, Typography2 } from '@neo-commons/components'
import { OfferSelectors, SubscriptionActions } from '@neo-commons/store'
import { OfferUtils } from '@neo-commons/libraries'

import { ClientTypeDto, OfferTypeDto } from '@afone/neo-core-client/dist/models'

import { Animation, Banner } from '@neo-web/componentsOld'

import twogirlinasofa from '../../../Resources/Images/site/two_girl_in_a_sofa.png'
import iconSuccess from '../../../Resources/Images/eligibility/icon_success.svg'

import '../AdvanceEligibility.scss'

export const AdvanceEligibilitySuccess: React.FC = () => {
  const [currentHeight, setCurrentHeight] = useState<number>(null)
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const offer = useSelector(OfferSelectors
    .listByClientType(ClientTypeDto.INDIVIDUAL, OfferTypeDto.SOCLE))
    .filter(offer => OfferUtils.isOfferAdvance(offer))

  useEffect(() => {
    const currentHeight = document.getElementsByClassName('Tunnel')[0]?.clientHeight
    setCurrentHeight(currentHeight)
  }, [])

  return (
    <div className='Eligibility_container'>
      <Card skin={CardSkin.DEFAULT} withShadow>
        <Animation zIndexOverride={1} />
        <Banner>
          <img src={twogirlinasofa} alt='modern times' style={{ width: '100%', borderRadius: 12 }} />
        </Banner>
        <div className='Eligibility_description'>
          {currentHeight >= 700 &&
            <div className='Eligibility_row'>
              <img src={iconSuccess} alt='modern times' style={{ marginTop: 24, width: 68, objectFit: 'cover' }} />
            </div>}
          <div className='Eligibility_row' style={{ marginTop: 24, marginBottom: 24 }}>
            <div className='Eligibility_col'>
              <Typography2 typeface='h2'>
                {t('neo-commons:eligibility:success:title')}
              </Typography2>
              <br /><br />
              <Typography2 typeface='h2' style={{ lineHeight: 36 }}>
                {t('neo-commons:eligibility:success:subtitle')}
              </Typography2>
              <br /><br />
              <Typography2 typeface='h5' style={{ fontFamily: 'Quicksand-Medium' }}>
                {t('neo-commons:eligibility:success:text')}
              </Typography2>
            </div>
          </div>
          <div className='Eligibility_button'>
            <Button
              containerStyle={{ width: '48%', zIndex: 2 }}
              skin={ButtonSkin.OUTLINE_PRIMARY}
              title={t('neo-commons:eligibility:success:later')}
              onPress={() => history.push(Routes.Synthesis.index.path)}
            />
            <Button
              flat
              containerStyle={{ width: '48%', zIndex: 2 }}
              skin={ButtonSkin.PRIMARY}
              title={t('neo-commons:eligibility:success:open')}
              onPress={() => {
                dispatch(SubscriptionActions.prepare({
                  offerUuid: offer[0].uuid,
                }))
                history.push(Routes.Subscription.Individual.index.path)
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}
