import React from 'react'
import { useTranslation } from 'react-i18next'

import { NumbersUtils } from '@neo-commons/libraries'

import { TransactionDto } from '@afone/neo-core-client/dist/models'

import { CardDetails, CardDetailsItem, CardDetailsSkin } from '@neo-web/componentsOld'

interface TransactionFeesProps {
  transaction: TransactionDto,
}

export const TransactionFees: React.FC<TransactionFeesProps> = (props: TransactionFeesProps) => {
  const { t } = useTranslation()

  const fees = props.transaction?.amounts?.fees

  const listFees: CardDetailsItem[] = [
    {
      label: 'Nøelse',
      value: NumbersUtils.displaySignedPriceForHuman(fees),
    },
  ]

  const totalFees: CardDetailsItem[] = [
    {
      label: t('app:pages:synthesis:transaction:feesDetail:total'),
      value: NumbersUtils.displaySignedPriceForHuman(fees),
    },
  ]

  return (
    <div style={{ marginTop: 32 }}>
      <CardDetails
        title={t('app:pages:synthesis:transaction:feesDetail:title')}
        list={listFees}
        justifyBetween
      />
      <CardDetails
        list={totalFees}
        justifyBetween
        skin={CardDetailsSkin.SECONDARY}
      />
    </div>
  )
}
