import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Card, CardSkin, Typography2 } from '@neo-commons/components'
import { AdvanceSelectors, Dispatch, OrdersActions, OrdersSelectors } from '@neo-commons/store'

import { OrderTypeDto } from '@afone/neo-core-client'

import { Banner } from '@neo-web/componentsOld'

import twogirlinasofa from '../../../Resources/Images/site/two_girl_in_a_sofa.png'
import logo from '../../../Resources/Images/eligibility/logo.svg'

import '../AdvanceEligibility.scss'

export const AdvanceEligibilityPending: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()
  const isAdvanceLoading = useSelector(AdvanceSelectors.isLoading)
  const advanceError = useSelector(AdvanceSelectors.error)
  const isOrdersLoading = useSelector(OrdersSelectors.isLoading)
  const eligibilityOrder = useSelector(OrdersSelectors.byType(OrderTypeDto.ADVANCE_ELIGIBILITY))
  const numberOfAdvanceEligibilityCheck = useSelector(AdvanceSelectors.numberOfAdvanceEligibilityCheck)

  useEffect(() => {
    advanceError && advanceError.message && history.push(Routes.AdvanceEligibility.error.path, { errorMessage: advanceError.message })
  }, [advanceError])

  useEffect(() => {
    if (!isAdvanceLoading && !isOrdersLoading) {
      dispatch(OrdersActions.listOrders())
    }
  }, [isAdvanceLoading])

  useEffect(() => {
    if (!isAdvanceLoading && !isOrdersLoading) {
      if (eligibilityOrder && eligibilityOrder.analysis.eligibility) {
        history.push(Routes.AdvanceEligibility.success.path)
      } else if (numberOfAdvanceEligibilityCheck > 1 && !eligibilityOrder?.analysis?.eligibility) {
        history.push(Routes.AdvanceEligibility.failure.path)
      } else {
        history.push(Routes.AdvanceEligibility.error.path)
      }
    }
  }, [eligibilityOrder])

  return (
    <div className='Eligibility_container'>
      <Card skin={CardSkin.DEFAULT} withShadow>
        <Banner>
          <img src={twogirlinasofa} alt='modern times' style={{ width: '100%', borderRadius: 12 }} />
        </Banner>
        <div className='Eligibility_description'>
          <div className='Eligibility_row'>
            <img src={logo} alt='noelse logo' style={{ width: 68, objectFit: 'cover' }} />
          </div>
          <div className='Eligibility_row'>
            <div className='Eligibility_col'>
              <Typography2 typeface='h3'>
                {t('neo-commons:eligibility:pending:title')}
              </Typography2>
              <br /><br />
              <Typography2 typeface='h5' style={{ fontFamily: 'Quicksand-Medium' }}>
                {t('neo-commons:eligibility:pending:text')}
              </Typography2>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}
