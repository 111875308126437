import env from '@beam-australia/react-env'

export enum ENV {
  NODE_ENV = 'NODE_ENV',
  MAPS = 'MAPS',
  BRIDGE = 'BRIDGE',
  ENV = 'ENV',
  API_URL = 'API_URL',
  SSE_URL = 'SSE_URL',
  SSE_TIME_TO_LIVE = 'SSE_TIME_TO_LIVE',
  API_SEPA_URL = 'API_SEPA_URL',
  COMNPAY_API_URL = 'COMNPAY_API_URL',
  COMNPAY_ORIGIN = 'COMNPAY_ORIGIN',
  COMNPAY_VAD_NUMBER = 'COMNPAY_VAD_NUMBER',
  SANDBOX_API_URL = 'SANDBOX_API_URL',
  THREEDS_TERM_URL = 'THREEDS_TERM_URL',
  REDUX_LOG = 'REDUX_LOG',
  TIME_ERROR_DISPLAY = 'TIME_ERROR_DISPLAY',
  DEFAULT_LANGUAGE_APP = 'DEFAULT_LANGUAGE_APP',
  PROFILE = 'PROFILE',
  TEST = 'TEST',
  DISABLED_MODAL_COMING_SOON = 'DISABLED_MODAL_COMING_SOON',
  RECAPTCHA_KEY = 'RECAPTCHA_KEY',
  SEGMENT_WRITE_KEY = 'SEGMENT_WRITE_KEY',
  POWENS_URL = 'POWENS_URL',
  // FUSIONAUTH
  FA_AUTHORITY = 'FA_AUTHORITY',
  FA_CLIENT_ID = 'FA_CLIENT_ID',
  FA_TENANT_ID = 'FA_TENANT_ID',
  AGENT_CLIENT_OFFER = 'AGENT_CLIENT_OFFER'
}

export const config = (key: ENV): string => {
  return env(key)
}

config(ENV.TEST)
  ? console.info(config(ENV.TEST))
  : console.info('Environment variables loaded from environment ❌')

// TODO : script pour vérifier que toutes les variables d'environnements sont bien set.
