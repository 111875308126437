import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'
import { TransferPolicy } from 'Policies/TransferPolicy'

import { BankAccountSelectors, BankTransferBenificiaryTypeEnum, ClientSelectors, TransferActions } from '@neo-commons/store'
import { Formatters, NumbersUtils, Validators } from '@neo-commons/libraries'
import { Button, ButtonSkin, Input, MoneyInput } from '@neo-commons/components'

import { ClientDto, PersonTypeDto } from '@afone/neo-core-client/dist/models'

import { AmountHiddenInput, ConfirmModal } from '@neo-web/componentsOld'

import './Amount.scss'

export const Amount: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const transferState = useSelector((state: StateTypes) => state?.transfer)
  const onGoingTransfer = transferState.prepare
  const preparedTransferBankAccount = useSelector(BankAccountSelectors.byId(onGoingTransfer?.accountUuid))
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)

  const defaultPrivateLabel = t('neo-commons:transfer:default:incoming', {
    recipientAccountName: onGoingTransfer.benificiaryType === BankTransferBenificiaryTypeEnum.Internal
      ? onGoingTransfer.recipientAccountName
      : `${onGoingTransfer.contactFamilyName || ''} ${onGoingTransfer.contactGivenName || ''}`,
  })

  const [privateLabel, setPrivateLabel] = useState(
    onGoingTransfer?.isUpdating
      ? defaultPrivateLabel
      : (onGoingTransfer.privateLabel || defaultPrivateLabel || '')
  )

  const [recipientLabel, setRecipientLabel] = useState(onGoingTransfer.label ||
    t('neo-commons:transfer:default:label', client.holder.type === PersonTypeDto.PHYSICAL
      ? { lastName: client.holder.lastName, firstName: client.holder.firstName }
      : { lastName: client.holder.legalName }
    ) || '')
  const [amount, setAmount] = useState(
    onGoingTransfer?.price?.amount >= 0 ? onGoingTransfer?.price?.amount.toString() : '')
  const param: { id: string } = useParams()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isValid, setIsValid] = useState(false)
  const [isRecipientLabelValid, setisRecipientLabelValid] = useState(false)

  useEffect(() => {
    if (amount) {
      setIsValid(NumbersUtils.amountFormatToNumber(amount) !== 0)
    }
  }, [amount])

  const goNext = async () => {
    await dispatch(
      TransferActions.prepare({
        ...onGoingTransfer,
        amount: NumbersUtils.amountFormatToNumber(amount),
        label: recipientLabel,
        privateLabel: privateLabel,
      }),
    )
    if (!TransferPolicy.hasAmountToTransfer(preparedTransferBankAccount, amount)) {
      setShowModal(true)
    } else {
      history.push(TransferPolicy.canStep6(transferState) || TransferPolicy.isPunctual(transferState)
        ? param.id != null ? generatePath(
          Routes.Payments.transferUpdate.path,
          { id: param.id },
        ) : Routes.Payments.summary.path : TransferPolicy.isRepeated(transferState)
          ? Routes.Payments.recurring.path : Routes.Payments.scheduled.path)
    }
  }

  const formIsValid: boolean = isRecipientLabelValid && (amount !== '' || parseFloat(amount) > 0)

  return (
    <div className='TransferAmount_container'>
      {showModal &&
        <ConfirmModal
          title={t(
            'app:pages:payments:transfer:money:charge:title'
          )}
          confirmText={t('app:pages:payments:transfer:money:charge:button')}
          description={t('app:pages:payments:transfer:money:charge:description')}
          show={showModal}
          onConfirm={() => history.push(Routes.Credit.creditChoices.path, { background: location })}
          onClose={() => (setShowModal(false))}
        />}

      <AmountHiddenInput value={amount}>
        <MoneyInput
          autoFocus
          placeholder='0'
          currency={t('app:global:currency')}
          errorStyle={{ minWidth: 280, marginLeft: 'calc(-140px + 50%)', justifyContent: 'center' }}
          fullErrorMode={(!isValid && amount !== '') ||
            !TransferPolicy.hasAmountToTransfer(preparedTransferBankAccount, amount)}
          validators={[
            Validators.isAmountOverZeroWithErrorMessage(
              t('app:pages:payments:transfer:money:charge:minimum')),
            Validators.isLowerThan(preparedTransferBankAccount?.balance, t('app:money:input:sold:lower')),
          ]}
          isValid={isValid}
          onChangeText={value => setAmount(Formatters.formatMoney(value))}
          value={amount}
        />
      </AmountHiddenInput>

      <div className='TransferAmount_item'>
        <div className='div-center text-left'>
          <Input
            label={t('app:pages:payments:transfer:label:sender')}
            value={privateLabel}
            onChangeText={(text) => setPrivateLabel(text)}
            maxLength={45}
          />
        </div>
      </div>

      <div className='TransferAmount_item'>
        <div className='div-center text-left'>
          <Input
            label={t('app:pages:payments:transfer:label:recipient')}
            value={recipientLabel}
            isRequired
            validators={[Validators.isNotEmpty]}
            onValidationChecked={value => setisRecipientLabelValid(value.isValid)}
            onChangeText={(text) => setRecipientLabel(text)}
            maxLength={45}
          />
        </div>
      </div>
      <div className='SelectTransferSourceAccount_action'>
        <Button
          title={t('app:pages:payments:transfer:amount:cancel')}
          skin={ButtonSkin.OUTLINE_PRIMARY}
          onPressOut={() => {
            history.push(onGoingTransfer.isUpdating ? generatePath(
              Routes.Payments.transferUpdate.path,
              { id: onGoingTransfer.uuid }
            ) : Routes.Payments.index.path)
          }}
        />

        <Button
          title={t('app:pages:payments:transfer:amount:validate')}
          skin={ButtonSkin.PRIMARY}
          disabled={!formIsValid}
          onPressOut={() => {
            parseFloat(amount) > 0 && goNext()
          }}
        />
      </div>
    </div>
  )
}
