import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { IndividualSubscriptionStep, IndividualSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Stepper2, Typography2 } from '@neo-commons/components'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './EuropeanTaxResident.scss'

type EuropeanTaxResidentProps =
  StepComponentProps<IndividualSubscriptionSteps[IndividualSubscriptionStep.EuropeanTaxResident]>

export const EuropeanTaxResident: React.FC<EuropeanTaxResidentProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.CENTER}
      header={
        <div className='EuropeanTaxResident_header'>
          <Typography2 typeface='h3'>
            {t('app:pages:subscription:individual:mainTitle:taxResidence')}
          </Typography2>
          <Stepper2 currentStep={0} />
          <Typography2 typeface='h4'>
            {t('app:pages:subscription:individual:europeanTaxResident:title')}
          </Typography2>
          <Typography2 typeface='body1'>
            {t('app:pages:subscription:individual:europeanTaxResident:description')}
          </Typography2>
        </div>
      }
      footer={
        <div>
          <div>
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:pages:subscription:individual:europeanTaxResident:residentButton')}
              onPress={nextStep}
            />
          </div>
          <div>
            <Button
              skin={ButtonSkin.TRANSPARENT_PRIMARY}
              title={t('app:pages:subscription:individual:europeanTaxResident:notResidentButton')}
              onPress={() => {
                history.push(Routes.Subscription.notEuropeanTaxResident.path)
              }}
            />
          </div>
        </div>
      }
    />
  )
}
