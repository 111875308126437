import { AbstractPolicy } from '../AbstractPolicy'
import { Routes } from '../../Routes/Routes'

export class InvoiceAndContractPolicy extends AbstractPolicy {
  public static invoiceAndContractRedirect (): string {
    let redirect = Routes.Profile.invoiceAndContractDetail.path
    if (this.mustChooseAccount()) {
      redirect = Routes.Profile.choicesAccount.path
    }
    return redirect
  }

  public static mustChooseAccount (): boolean {
    return this.state.bankAccount.list.selectedId === null
  }
}
