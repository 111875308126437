import { AnyAction, Reducer } from 'redux'
import { omit } from 'lodash'
import i18next from 'i18next'
import {
  DeviceActivitySortDto,
  DeviceDto,
  DeviceSortDto, EventTypeDeviceDto,
} from '@afone/neo-core-client/dist/models'
import { NeobankApi } from '@neo-commons/services'

import { Dispatch, resourceDefaultSelectors, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { AlertType, initialResourceState, State } from '../utils'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Device = DeviceDto

const {
  resourceActionTypes: DeviceActionTypes,
  resourceReducer: DeviceResourceReducer,
  resourceAction: DeviceAction,
} = ResourceStateFactory<Device, 'device'>((state: State) => state.device, 'device')

export enum DeviceConfig {
  PAGE_SIZE_DEVICE_LIST = 100,
  PAGE_SIZE_ACTIVITIES_LIST = 3,
  PAGE_NUMBER_ACTIVITIES_LIST = 0,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

const LIST_DEVICE_REQUEST = 'device/LIST_DEVICE_REQUEST'
const LIST_DEVICE_SUCCESS = 'device/LIST_DEVICE_SUCCESS'
const LIST_DEVICE_FAILURE = 'device/LIST_DEVICE_FAILURE'

const LIST_DEVICE_ACTIVITIES_REQUEST = 'device/LIST_DEVICE_ACTIVITIES_REQUEST'
const LIST_DEVICE_ACTIVITIES_SUCCESS = 'device/LIST_DEVICE_ACTIVITIES_SUCCESS'
const LIST_DEVICE_ACTIVITIES_FAILURE = 'device/LIST_DEVICE_ACTIVITIES_FAILURE'

const SET_SELECTED_DEVICE = 'device/SET_SELECTED_DEVICE'

const LOCK_DEVICE_REQUEST = 'device/LOCK_DEVICE_REQUEST'
const LOCK_DEVICE_SUCCESS = 'device/LOCK_DEVICE_SUCCESS'
const LOCK_DEVICE_FAILURE = 'device/LOCK_DEVICE_FAILURE'

const UNLOCK_DEVICE_REQUEST = 'device/UNLOCK_DEVICE_REQUEST'
const UNLOCK_DEVICE_SUCCESS = 'device/UNLOCK_DEVICE_SUCCESS'
const UNLOCK_DEVICE_FAILURE = 'device/UNLOCK_DEVICE_FAILURE'

const RESET = 'device/RESET'

export const DeviceTypes = {
  ...DeviceActionTypes,

  LIST_DEVICE_REQUEST,
  LIST_DEVICE_SUCCESS,
  LIST_DEVICE_FAILURE,

  LIST_DEVICE_ACTIVITIES_REQUEST,
  LIST_DEVICE_ACTIVITIES_SUCCESS,
  LIST_DEVICE_ACTIVITIES_FAILURE,

  SET_SELECTED_DEVICE,

  LOCK_DEVICE_REQUEST,
  LOCK_DEVICE_SUCCESS,
  LOCK_DEVICE_FAILURE,

  UNLOCK_DEVICE_REQUEST,
  UNLOCK_DEVICE_SUCCESS,
  UNLOCK_DEVICE_FAILURE,

  RESET,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

const list = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LIST_DEVICE_REQUEST })
    try {
      const getDevicesResponse = await NeobankApi.getInstance().deviceApi.getDevices(
        DeviceConfig.PAGE_SIZE_DEVICE_LIST,
        DeviceConfig.PAGE_NUMBER_ACTIVITIES_LIST,
        [DeviceSortDto.LAST_LOGIN_DATE],
        [DeviceSortDto.LAST_LOGIN_DATE]
      )
      const devices = getDevicesResponse.status === 204 ? [] : getDevicesResponse.data
      dispatch({ type: LIST_DEVICE_SUCCESS, data: devices, paginationEnded: true })
    } catch (error) {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: LIST_DEVICE_FAILURE, errorMessage })
    }
  }
}

const searchDevicesActivities = (deviceUuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LIST_DEVICE_ACTIVITIES_REQUEST })
    try {
      const getDevicesActivitiesResponse = await NeobankApi.getInstance().deviceApi.searchDevicesActivities(
        deviceUuid, DeviceConfig.PAGE_SIZE_ACTIVITIES_LIST,
        DeviceConfig.PAGE_NUMBER_ACTIVITIES_LIST,
        [DeviceActivitySortDto.CREATED_DATE],
        [DeviceActivitySortDto.CREATED_DATE],
        [{
          event: EventTypeDeviceDto.LOGIN,
        }]
      )
      const activities = getDevicesActivitiesResponse.data
      dispatch({
        type: LIST_DEVICE_ACTIVITIES_SUCCESS,
        data: activities,
        deviceUuid: deviceUuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: LIST_DEVICE_ACTIVITIES_FAILURE, errorMessage })
    }
  }
}

const lock = (uniqueDeviceId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOCK_DEVICE_REQUEST })
    try {
      const getDeviceLockReasonResponse =
        await NeobankApi.getInstance().deviceApi.lockDeviceByUdid(uniqueDeviceId)
      const device = getDeviceLockReasonResponse.data
      dispatch({
        type: LOCK_DEVICE_SUCCESS,
        alertType: AlertType.SUCCESS,
        errorMessage: i18next.t('neo-commons:app:global:device:lock:success'),
        data: device,
      })

      return device
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: LOCK_DEVICE_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const unlock = (uniqueDeviceId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UNLOCK_DEVICE_REQUEST })
    try {
      const getDeviceUnlockReasonResponse =
        await NeobankApi.getInstance().deviceApi.unlockDeviceByUdid(uniqueDeviceId)
      const device = getDeviceUnlockReasonResponse.data
      dispatch({
        type: UNLOCK_DEVICE_SUCCESS,
        alertType: AlertType.SUCCESS,
        errorMessage: i18next.t('neo-commons:app:global:device:unlock:success'),
        data: device,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: UNLOCK_DEVICE_FAILURE, errorMessage })
    }
  }
}

const setSelected = (selectedId: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (getState().device.list!.selectedId === selectedId) return
    dispatch({ type: SET_SELECTED_DEVICE, selectedId })
  }
}

export const DeviceActions = {
  ...DeviceAction,
  list,
  searchDevicesActivities,
  lock,
  unlock,
  setSelected,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type DeviceState = Omit<ResourceState<DeviceDto>, 'create' | 'update'> & {

}

const initialState: DeviceState = {
  ...omit(initialResourceState, 'create', 'update'),
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const DeviceSelector = state => state.device

export const DeviceSelectors = {
  ...resourceDefaultSelectors(DeviceSelector),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const device: Reducer = (state = initialState, action: AnyAction): DeviceState => {
  switch (action.type) {
    case LIST_DEVICE_REQUEST:
    case LIST_DEVICE_ACTIVITIES_REQUEST:
    case LOCK_DEVICE_REQUEST:
    case UNLOCK_DEVICE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOCK_DEVICE_SUCCESS:
    case UNLOCK_DEVICE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.uuid]: action.data,
        },
      }
    case LIST_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data?.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {}) ?? {},
        list: {
          ...state.list,
          ids: action.data?.map(item => item.uuid) ?? [],
          loadedOnce: true,
        },
      }

    case SET_SELECTED_DEVICE:
      return {
        ...state,
        list: {
          ...state.list,
          selectedId: action.selectedId,
        },
      }

    case LIST_DEVICE_ACTIVITIES_SUCCESS:
      const device = { ...state.data[action.deviceUuid] }
      device.activities = action.data

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.deviceUuid]: device,
        },
      }

    case LIST_DEVICE_FAILURE:
    case LIST_DEVICE_ACTIVITIES_FAILURE:
    case LOCK_DEVICE_FAILURE:
    case UNLOCK_DEVICE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      }

    default:
      return {
        ...DeviceResourceReducer(state, action, {
          identifier: 'uuid',
          isPaginate: false,
          initialState,
        }),
      }
  }
}
