import dayjs from 'dayjs'

import { SubscriptionDto } from '@afone/neo-core-client/dist/models'

/**
 * Generic methods use in NoElse
 *
 */
export class GlobalUtils {
  /**
   * Order two string in alphabetical order
   *
   * @param a - First string to order
   * @param b - Second string to order
   * @returns -1 if a is before b / 0 if a and b are equals / 1 if b is before a
   *
   */
  public static orderString = (a: string, b: string): number => {
    const nameA = a.toUpperCase()
    const nameB = b.toUpperCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }

  /**
   * TEMPORARY FUNCTION - Check if subscription was made after BigBangTheory release (02/08 + 1 day)
   * @param subscription - a subscription
   * @returns true if subscription is "up to date"
   */
  public static isSubscriptionAfterBigBang (subscription: SubscriptionDto): boolean {
    const DATE_MIN_POS = '2022-08-03'
    const DATE_SUBSCRIPTION = subscription?.activationDate ?? '2022-01-01'

    return dayjs(DATE_MIN_POS).isBefore(DATE_SUBSCRIPTION)
  }
}
