import React, { ReactElement } from 'react'

import { Button, Icon, Typography2 } from '@neo-commons/components'

import './TransactionBanner.scss'

interface TransactionBannerProps {
  icon: ReactElement<typeof Icon>;
  button?: ReactElement<typeof Button>;
  title: string;
  subtitle: string;
}

export const TransactionBanner: React.FC<TransactionBannerProps> = ({ icon, button, title, subtitle }: TransactionBannerProps) => {
  return (
    <div className='TransactionBanner_container'>
      <div className='TransactionBanner_alert-container'>
        {icon}
        <div className='TransactionBanner_alert-container_text'>
          <Typography2 typeface='h5'>{title}</Typography2>
          <Typography2 typeface='body3'>{subtitle}</Typography2>
        </div>
      </div>
      {button}
    </div>)
}
