import React from 'react'
import { useTranslation } from 'react-i18next'

import Colors from 'UI/Resources/Styles/sass/themes/Colors'

import { Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { EnumUtils } from '@neo-commons/libraries'

import { KycTypeDto } from '@afone/neo-core-client/dist/models'

// CNI images pedagogy
import CNI_DEFAULT_RECTO from './../../../../Resources/Images/documentPedagogy/CNI_DEFAULT_RECTO.svg'
import CNI_DEFAULT_VERSO from './../../../../Resources/Images/documentPedagogy/CNI_DEFAULT_VERSO.svg'
import CNI_BLUR_RECTO from './../../../../Resources/Images/documentPedagogy/CNI_BLUR_RECTO.png'
import CNI_BLUR_VERSO from './../../../../Resources/Images/documentPedagogy/CNI_BLUR_VERSO.png'
import CNI_REFLECT_RECTO from './../../../../Resources/Images/documentPedagogy/CNI_REFLECT_RECTO.svg'
import CNI_REFLECT_VERSO from './../../../../Resources/Images/documentPedagogy/CNI_REFLECT_VERSO.svg'
import CNI_UNFRAMED_RECTO from './../../../../Resources/Images/documentPedagogy/CNI_UNFRAMED_RECTO.svg'
import CNI_UNFRAMED_VERSO from './../../../../Resources/Images/documentPedagogy/CNI_UNFRAMED_VERSO.svg'
// PASSPORT images pedagogy
import PASSEPORT_FRAME_TOP from './../../../../Resources/Images/documentPedagogy/PASSEPORT_FRAME_TOP.svg'
import PASSEPORT_FRAME_BOTTOM from './../../../../Resources/Images/documentPedagogy/PASSEPORT_FRAME_BOTTOM.svg'
import PASSEPORT_BLUR from './../../../../Resources/Images/documentPedagogy/PASSEPORT_BLUR.png'
import PASSEPORT_REFLECT from './../../../../Resources/Images/documentPedagogy/PASSEPORT_REFLECT.svg'
import PASSEPORT_UNFRAMED from './../../../../Resources/Images/documentPedagogy/PASSEPORT_UNFRAMED.svg'
// TDS images pedagogy
import TDS_DEFAULT_RECTO from './../../../../Resources/Images/documentPedagogy/TDS_DEFAULT_RECTO.svg'
import TDS_DEFAULT_VERSO from './../../../../Resources/Images/documentPedagogy/TDS_DEFAULT_VERSO.svg'
import TDS_BLUR_RECTO from './../../../../Resources/Images/documentPedagogy/TDS_BLUR_RECTO.png'
import TDS_BLUR_VERSO from './../../../../Resources/Images/documentPedagogy/TDS_BLUR_VERSO.png'
import TDS_REFLECT_RECTO from './../../../../Resources/Images/documentPedagogy/TDS_REFLECT_RECTO.svg'
import TDS_REFLECT_VERSO from './../../../../Resources/Images/documentPedagogy/TDS_REFLECT_VERSO.svg'
import TDS_UNFRAMED_RECTO from './../../../../Resources/Images/documentPedagogy/TDS_UNFRAMED_RECTO.svg'
import TDS_UNFRAMED_VERSO from './../../../../Resources/Images/documentPedagogy/TDS_UNFRAMED_VERSO.svg'
// OTHERS DOC images pedagogy
import DOC_RECTO from './../../../../Resources/Images/documentPedagogy/DOC_RECTO.svg'
import DOC_VERSO from './../../../../Resources/Images/documentPedagogy/DOC_VERSO.svg'
import DOC_BLUR from './../../../../Resources/Images/documentPedagogy/DOC_BLUR.png'
import DOC_UNFRAMED from './../../../../Resources/Images/documentPedagogy/DOC_UNFRAMED.svg'
import DOC_STAINED from './../../../../Resources/Images/documentPedagogy/DOC_STAINED.svg'

import './DocumentPedagogy.scss'

interface DocumentPedagogyProps {
  type?: string,
}

export const DocumentPedagogy: React.FC<DocumentPedagogyProps> = (props: DocumentPedagogyProps) => {
  const { t } = useTranslation()
  const getImageDocumentPedagogy = () => {
    switch (props.type) {
      case t(EnumUtils.getKycTypeDtoKey(KycTypeDto.ID)):
        return {
          blur: [CNI_BLUR_RECTO, CNI_BLUR_VERSO],
          reflect: [CNI_REFLECT_RECTO, CNI_REFLECT_VERSO],
          unframed: [CNI_UNFRAMED_RECTO, CNI_UNFRAMED_VERSO],
          accepted: [CNI_DEFAULT_RECTO, CNI_DEFAULT_VERSO],
          height: {
            normal: [30, 20],
            unframed: [50, 40],
            accepted: [30, 20],
          },
        }
      case t(EnumUtils.getKycTypeDtoKey(KycTypeDto.PASSPORT)):
        return {
          blur: [PASSEPORT_BLUR],
          reflect: [PASSEPORT_REFLECT],
          unframed: [PASSEPORT_UNFRAMED],
          accepted: [PASSEPORT_FRAME_TOP, PASSEPORT_FRAME_BOTTOM],
          height: {
            normal: [53, 43],
            unframed: [63, 53],
            accepted: [38, 28],
          },
        }
      case t(EnumUtils.getKycTypeDtoKey(KycTypeDto.RESIDENCE_PERMIT)):
        return {
          blur: [TDS_BLUR_RECTO, TDS_BLUR_VERSO],
          reflect: [TDS_REFLECT_RECTO, TDS_REFLECT_VERSO],
          unframed: [TDS_UNFRAMED_RECTO, TDS_UNFRAMED_VERSO],
          accepted: [TDS_DEFAULT_RECTO, TDS_DEFAULT_VERSO],
          height: {
            normal: [30, 20],
            unframed: [50, 40],
            accepted: [30, 20],
          },
        }
      default:
        return {
          blur: [DOC_BLUR],
          reflect: [DOC_STAINED],
          unframed: [DOC_UNFRAMED],
          accepted: [DOC_RECTO, DOC_VERSO],
          height: {
            normal: [50, 40],
            unframed: [50, 40],
            accepted: [50, 40],
          },
        }
    }
  }

  return (
    <div
      className='DocumentPedagogy'
    >
      {/* -- BLUR -- */}
      <div className='DocumentPedagogy_row'>
        <Icon name='x-circle' type={IconTypes.FEATHER} size={40} color={Colors.error} />
        <div>
          <div className='DocumentPedagogy_row_container'>
            {getImageDocumentPedagogy()?.blur?.map((image, index) => (
              <img src={image} key={image + index} width='30%' style={{ marginRight: 16 }} />
            ))}
          </div><br />
          <Typography2 typeface='body3' style={{ color: Colors.error, fontSize: 14 }}>
            {t('app:pages:documentUpload:summary:blur')}
          </Typography2>
        </div>
      </div>
      {/* --- REFLECT or STAINED --- */}
      <div className='DocumentPedagogy_row'>
        <Icon name='x-circle' type={IconTypes.FEATHER} size={40} color={Colors.error} />
        <div>
          <div className='DocumentPedagogy_row_container'>
            {getImageDocumentPedagogy()?.reflect?.map((image, index) => (
              <img src={image} key={image + index} width='40%' style={{ marginRight: 16 }} />
            ))}
          </div><br />
          <Typography2 typeface='body3' style={{ color: Colors.error, fontSize: 14 }}>
            {t(props.type === t(EnumUtils.getKycTypeDtoKey(KycTypeDto.ID)) ||
            props.type === t(EnumUtils.getKycTypeDtoKey(KycTypeDto.PASSPORT)) ||
            props.type === t(EnumUtils.getKycTypeDtoKey(KycTypeDto.RESIDENCE_PERMIT))
              ? 'app:pages:documentUpload:summary:reflect' : 'app:pages:documentUpload:summary:stained'
            )}
          </Typography2>
        </div>
      </div>
      {/* --- UNFRAMED --- */}
      <div className='DocumentPedagogy_row'>
        <Icon name='x-circle' type={IconTypes.FEATHER} size={40} color={Colors.error} />
        <div>
          <div className='DocumentPedagogy_row_container'>
            {getImageDocumentPedagogy()?.unframed?.map((image, index) => (
              <img src={image} key={image + index} width='25%' style={{ marginRight: 16 }} />
            ))}
          </div><br />
          <Typography2 typeface='body3' style={{ color: Colors.error, fontSize: 14 }}>
            {t('app:pages:documentUpload:summary:unframed')}
          </Typography2>
        </div>
      </div>
      {/* --- ACCEPTED --- */}
      <div className='DocumentPedagogy_container'>
        <div className='DocumentPedagogy_accepted'>
          <Icon name='check-circle' type={IconTypes.FEATHER} size={32} color={Colors.success} />
          <div className='DocumentPedagogy_accepted_container'>
            {getImageDocumentPedagogy().accepted?.map((image, index) => (
              <div key={'container' + index}>
                <div key={'accepted' + index} className='DocumentPedagogy_accepted_container_imageText'>
                  {index === 0
                    ? (
                      <Typography2 key={'accepted' + index + 1} typeface='buttonSmall' style={{ width: 'max-content' }}>
                        {t(props.type === t(EnumUtils.getKycTypeDtoKey(KycTypeDto.PASSPORT)) ? 'app:pages:documentUpload:summary:passportFirstPage' : 'app:pages:documentUpload:summary:recto')}
                      </Typography2>
                    )
                    : (
                      <Typography2 key={'accepted' + index + 1} typeface='buttonSmall' style={{ width: 'max-content' }}>
                        {t(props.type === t(EnumUtils.getKycTypeDtoKey(KycTypeDto.PASSPORT)) ? 'app:pages:documentUpload:summary:passportSecondPage' : 'app:pages:documentUpload:summary:verso')}
                      </Typography2>
                    )}
                  <img key={'accepted' + index + 2} src={image} width='80%' style={{ marginTop: 8 }} />
                </div>
                {index === 0 && <Icon name='plus' type={IconTypes.FEATHER} size={32} color={Colors.textSecondary} />}
              </div>
            ))}
          </div>
          <Typography2 typeface='body3' style={{ color: Colors.success, fontSize: 14 }}>
            {t('app:pages:documentUpload:summary:accepted')}
          </Typography2>
        </div>
      </div>
    </div>
  )
}
