import { get } from 'lodash'

/*
 * Generic methods use in NoElse for safe data.
 */
export class SafeDataUtils {
  /* %%%%%%%%%%%%%%%%%% *\
      Defaults data.
  \* %%%%%%%%%%%%%%%%%% */

  /**
   * Return default date
   *
   * @returns {date} return the default date
   *
   */
  public static getDefaultDateIfIsEmpty (): Date {
    return new Date(1970, 11, 31)
  }

  /**
   * Return empty string
   *
   * @returns {string} return the empty string
   *
   */
  public static getDefaultStringIfIsEmpty (): string {
    return ''
  }

  /**
   * Return default number
   *
   * @returns {number} return the default number
   *
   */
  public static getDefaultNumberIfIsEmpty (): number {
    return -1
  }

  /**
   * Return empty array
   *
   * @returns {[]} return the empty array
   *
   */
  public static getDefaultArrayIfIsEmpty (): [] {
    return []
  }

  /**
   * Return empty object
   *
   * @returns {{}} return the empty object
   *
   */
  public static getDefaultObjectIfIsEmpty (): any {
    return {}
  }

  /**
   * Return a boolean if date is default or not a date
   *
   * @param {Date} date - get a date
   * @returns {boolean} Return a boolean if date is default or not a date
   *
   */
  public static dateIsDefaultDateWhenEmpty (date: Date): boolean {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return true
    }

    return this.getDefaultDateIfIsEmpty().getTime() === date.getTime()
  }

  /* %%%%%%%%%%%%%%%%%%%%%%% *\
      Secure basic types.
  \* %%%%%%%%%%%%%%%%%%%%%%% */

  /**
   * Test if the value is a number and set a default value if not
   *
   * @param {object} objectElement
   * @param {key} key - Key of number want to test
   * @param {number} defaultValueIfEmpty - default value of number
   * @returns {number} Return the number or default number
   *
   */
  public static getSecureNumber (objectElement: any | undefined, key: string, defaultValueIfEmpty: number = this.getDefaultNumberIfIsEmpty()): number {
    const val: number = get(objectElement, key, defaultValueIfEmpty)

    if (typeof (val) !== 'number' || isNaN(val)) {
      return defaultValueIfEmpty
    }

    return val
  }

  /**
   * Test if the value is a string and set a default value if not
   *
   * @param {object} objectElement
   * @param {key} key - Key of string want to test
   * @param {string} defaultValueIfEmpty - default value of string
   * @returns {string} Return the string or default string
   *
   */
  public static getSecureString (objectElement: any | undefined, key: string, defaultValueIfEmpty: string = this.getDefaultStringIfIsEmpty()): string {
    const val: string = get(objectElement, key, defaultValueIfEmpty)

    if (typeof (val) !== 'string') {
      return defaultValueIfEmpty.trim()
    }

    return val.trim()
  }

  /**
   * Test if the value is a date and set a default value if not
   *
   * @param {object} objectElement
   * @param {key} key - Key of date want to test
   * @param {date} defaultValueIfEmpty - default value of date
   * @returns {date} Return the date or default date
   *
   */
  public static getSecureDate (objectElement: any | undefined, key: string, defaultValueIfEmpty: Date = this.getDefaultDateIfIsEmpty()): Date {
    const val: Date = get(objectElement, key, defaultValueIfEmpty)
    if (!(val instanceof Date) || isNaN(val.getTime())) {
      return defaultValueIfEmpty
    }

    return val
  }

  /**
   * Test if the value is a boolean and set a default value if not
   *
   * @param {object} objectElement
   * @param {key} key - Key of boolean want to test
   * @param {boolean} defaultValueIfEmpty - default value of boolean
   * @returns {boolean} Return the boolean or default boolean
   *
   */
  public static getSecureBoolean (objectElement: any | undefined, key: string, defaultValueIfEmpty = false): boolean {
    const val: boolean = get(objectElement, key, defaultValueIfEmpty)

    if (typeof val !== 'boolean') {
      return defaultValueIfEmpty
    }

    return val
  }

  /**
   * Test if the value is a array and set a default value if not
   *
   * @param {object} objectElement
   * @param {key} key - Key of array want to test
   * @param {array} defaultValueIfEmpty - default value of array
   * @returns {array} Return the array or default array
   *
   */
  public static getSecureArray (objectElement: any | undefined, key: string, defaultValueIfEmpty: [] = this.getDefaultArrayIfIsEmpty()): [] {
    const val: [] = get(objectElement, key, defaultValueIfEmpty)
    if (!(val instanceof Array)) {
      return defaultValueIfEmpty
    }

    return val
  }

  /**
   * Test if the value is a object and set a default value if not
   *
   * @param {object} objectElement
   * @param {key} key - Key of object want to test
   * @param {object} defaultValueIfEmpty - default value of object
   * @returns {object} Return the object or default object
   *
   */
  public static getSecureObject (objectElement: any | undefined, key: string, defaultValueIfEmpty: any = this.getDefaultObjectIfIsEmpty()): any {
    const val: any = get(objectElement, key, defaultValueIfEmpty)
    if (!(val instanceof Object)) {
      return defaultValueIfEmpty
    }

    return val
  }
}
