import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import i18next from 'i18next'
import { resources, DateFormat } from 'I18n'
import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import { StepComponentProps } from '@neo-commons/policies'
import { POSOrderStep, POSOrderSteps } from '@neo-commons/policies/src/POS/Order'
import {
  Button,
  ButtonSkin,
  EditItemCard,
  EditItemCardSkin,
  Typography,
} from '@neo-commons/components'
import {
  ClientSelectors,
  OfferSelectors,
  PosActions,
  PosSelectors,
  PosTypes,
  PreparedProvisioning,
  PreparePOSOrder,
  ProvisioningSelectors,
} from '@neo-commons/store'
import {
  ProvisioningStatus,
  NumbersUtils,
  POSUtils,
  ProductType,
  SubscriptionUtils,
  ProcessType,
} from '@neo-commons/libraries'

import { ClientDto, CountryDto, OfferDto, OfferTypeDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, LazyLoadingContainer, PageContent } from '@neo-web/componentsOld'

import { SummaryProductsList } from './SummaryProductsList'

type POSOrderModelChoiceProps = StepComponentProps<POSOrderSteps[POSOrderStep.Summary]>

export const Summary: React.FC<POSOrderModelChoiceProps> = ({ nextStep, editStep }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const preparedPOSOrder: PreparePOSOrder = useSelector(PosSelectors.prepare)
  const preparedProvisioning: PreparedProvisioning = useSelector(ProvisioningSelectors.prepare)

  const offers: OfferDto[] = useSelector(OfferSelectors.listByType(OfferTypeDto.POS))
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const countries: CountryDto[] = useSelector(
    (state: StateTypes) => state.config?.data?.currentConfig?.countries ?? []
  )

  const dateFormat: DateFormat = resources[i18next.language].formats
  const deliveryDate = preparedPOSOrder?.deliveryProduct
    ? POSUtils.getDeliveryDate(preparedPOSOrder?.deliveryProduct).format(dateFormat.dayAndMonth) : '...'
  const deliveryAmount = preparedPOSOrder?.deliveryProduct?.prices
    ? NumbersUtils.displayPriceForHuman(preparedPOSOrder?.deliveryProduct?.prices[0].amount)
    : NumbersUtils.displayPriceForHuman(0)

  const addressTitle = preparedPOSOrder?.deliveryAddress || client
    ? POSUtils.getDeliveryLabelCard(preparedPOSOrder?.deliveryAddress, client) : ''
  const addressSubtitles = preparedPOSOrder?.deliveryAddress && countries
    ? POSUtils.getDisplayAddressSubtitles(preparedPOSOrder?.deliveryAddress, countries) : ['']
  const orderTotal = preparedPOSOrder?.deliveryProduct
    ? POSUtils.getTotalFees(preparedPOSOrder?.posProducts, preparedPOSOrder?.deliveryProduct) : 0

  const modifyOffer = () => {
    editStep(POSOrderStep.ModelChoice)
  }

  const modifyAddress = () => {
    editStep(POSOrderStep.AddressCheck)
  }

  useEffect(() => {
    const deliveryProductOffer = offers
      ? SubscriptionUtils.getProductsByType(offers[0], ProductType.DELIVERY_MODE)?.[0] : null
    if (deliveryProductOffer && !preparedPOSOrder?.deliveryProduct) {
      (async () => {
        try {
          await dispatch(PosActions.prepare({
            ...preparedPOSOrder,
            deliveryProduct: deliveryProductOffer,
          }))
        } catch (error) {}
      })()
    }
  }, [])

  useEffect(() => {
    const acceptedStatus = [ProvisioningStatus.PENDING, ProvisioningStatus.SUCCESS]
    if (location.pathname &&
      preparedProvisioning?.processType === ProcessType.PAYMENT &&
      acceptedStatus.indexOf(preparedProvisioning?.provisioningStatus) !== -1
    ) {
      nextStep()
    }
  }, [location])

  useEffect(() => {
    if (preparedPOSOrder?.deliveryProduct && preparedPOSOrder?.missingBalance === undefined) {
      (async () => {
        try {
          await dispatch(PosActions.getOrderFees())
        } catch (error) {}
      })()
    }
  }, [preparedPOSOrder?.deliveryProduct, preparedPOSOrder?.missingBalance])

  const isConfirmButtonEnable = useMemo(() => {
    return preparedPOSOrder?.deliveryProduct && preparedPOSOrder?.missingBalance !== undefined
  }, [preparedPOSOrder?.deliveryProduct, preparedPOSOrder?.missingBalance])

  const confirmOrder = () => {
    if (preparedPOSOrder?.missingBalance > 0) {
      history.push(Routes.Credit.debitCard.path, {
        background: location,
        amount: orderTotal,
        productCode: POSUtils.getPaymentProductCode(),
      })
    } else {
      nextStep()
    }
  }

  const getFooter = () => {
    return (
      <>
        <div className='PosOrder_footer_text-center'>
          <Typography typeface='subtitle'>
            Total : {NumbersUtils.displayPriceForHuman(orderTotal)}
          </Typography>
        </div>
        <Button
          title={t('app:global:confirm')}
          skin={ButtonSkin.PRIMARY}
          onPress={() => confirmOrder()}
          disabled={!isConfirmButtonEnable}
        />
      </>
    )
  }

  return (
    <LazyLoadingContainer events={[PosTypes.PREPARE_POS_REQUEST]}>
      <PageContent
        justifyContent={JustifyContent.BETWEEN}
        footer={getFooter()}
      >
        <div className='PosOrder_content'>
          {preparedPOSOrder?.posProducts && (
            <SummaryProductsList
              products={preparedPOSOrder?.posProducts}
              modifyOffer={modifyOffer}
            />
          )}
          <EditItemCard
            label={t('app:pages:services:pos:order:cardTitle:deliveryMode').toUpperCase()}
            skin={EditItemCardSkin.DELIVERY}
            payload={{
              title: preparedPOSOrder?.deliveryProduct?.name ?? '',
              subtitles: [
                preparedPOSOrder?.deliveryProduct?.description?.length ? preparedPOSOrder?.deliveryProduct?.description[0] : '',
                'Estimée le ' + deliveryDate,
              ],
              amount: deliveryAmount,
            }}
            hideButton
          />
          <EditItemCard
            label={t('app:pages:services:pos:order:cardTitle:deliveryAddress').toUpperCase()}
            skin={EditItemCardSkin.DELIVERY}
            payload={{
              title: addressTitle,
              subtitles: addressSubtitles,
            }}
            buttonLabel={t('app:global:edit')}
            onEditPress={modifyAddress}
          />
        </div>
      </PageContent>
    </LazyLoadingContainer>
  )
}
