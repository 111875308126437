import { merge } from 'lodash'

export interface ThemeProviderConfiguration {
  MenuItemCard: {
    marginBottom: number
  },
  ChannelContactCard: {
    marginBottom: number
  }
}

const DEFAULT_CONFIGURATION: ThemeProviderConfiguration = {
  MenuItemCard: {
    marginBottom: 8,
  },
  ChannelContactCard: {
    marginBottom: 8,
  },
}

export class ThemeProvider {
  static configuration: ThemeProviderConfiguration = DEFAULT_CONFIGURATION

  static configure (configuration: ThemeProviderConfiguration) {
    this.configuration = merge(DEFAULT_CONFIGURATION, configuration)
  }

  static get () {
    return this.configuration
  }
}
