import React, { FunctionComponent, useState, useEffect, useMemo } from 'react'
import { View } from 'react-native'

import { Calendar as RNTCalendar, LocaleConfig } from '@afone/react-native-toggle-calendar'
import dayjs from 'dayjs'
import { TextUtils, DateUtils } from '@neo-commons/libraries'
import _ from 'lodash'

import { Picker } from '../Picker/Picker'

import styles, { calendarStyles } from './styles'

export enum CalendarSkin {
  DEFAULT= 'DEFAULT',
  BORDERERED= 'BORDERERED',
}

export interface CalendarProps {
  minDate: string,
  onChange: (date: string) => void,
  defaultDate?: string,
  skin?: CalendarSkin,
}

const DATE_FORMAT = 'YYYY-MM-DD'
const DEFAULT_DAY = 1

const configureLocale = (locale: string) => {
  LocaleConfig.locales[locale] = {
    monthNames: dayjs.localeData().months(),
    monthNamesShort: dayjs.localeData().monthsShort(),
    dayNames: dayjs.localeData().weekdays(),
    dayNamesShort: dayjs.localeData().weekdaysShort().map(dayName => TextUtils.capitalize(dayName.replace('.', ''))),
  }

  LocaleConfig.defaultLocale = locale
}

export const Calendar: FunctionComponent<CalendarProps> = ({ minDate, onChange, defaultDate = dayjs().add(2, 'days').format('YYYY-MM-DD'), skin }) => {
  const [selectedDate, setSelectedDate] = useState(defaultDate)
  const [calendarDate, setCalendarDate] = useState(dayjs().add(1, 'days').format(DATE_FORMAT))
  const [month, setMonth] = useState(dayjs().format('MMMM'))
  const [year, setYear] = useState(dayjs().format('YYYY'))

  useMemo(() => configureLocale(dayjs.locale()), [])
  const [monthItems] = useState(dayjs.months().map(obj => ({ label: obj, value: +dayjs().month(DateUtils.getMonthNumber(month)).format('M') })))
  const [yearItems] = useState(_.times(10, index => {
    const year = dayjs().year() + index
    return { label: year.toString(), value: year.toString() }
  }))

  useEffect(() => {
    onChange(selectedDate)
  }, [onChange, selectedDate])

  const changeMonth = (month: string) => {
    const goingNextYear = (+dayjs(calendarDate).format('M') === 12 && +dayjs().month(DateUtils.getMonthNumber(month)).format('M') === 1)
    const goingPrevYear = (+dayjs(calendarDate).format('M') === 1 && +dayjs().month(DateUtils.getMonthNumber(month)).format('M') === 12)
    const newYear = goingNextYear ? (+year + 1) : goingPrevYear ? (+year - 1) : +year
    setCalendarDate(dayjs().year(newYear).month(DateUtils.getMonthNumber(month)).date(DEFAULT_DAY).format(DATE_FORMAT))
    setMonth(month)
    setYear(newYear.toString())
  }

  const changeYear = (year: string) => {
    setCalendarDate(dayjs().year(+year).month(DateUtils.getMonthNumber(month)).date(DEFAULT_DAY).format(DATE_FORMAT))
    setYear(year)
  }

  return (
    <View style={styles.container} accessibilityLabel='calendar'>
      <View style={styles.pickerContainer}>
        <Picker
          items={yearItems}
          defaultKey={0}
          onChange={changeYear}
          backButtonDisabled={year === yearItems[0].value}
          nextButtonDisabled={year === yearItems[yearItems.length - 1].value}
          value={year}
          containerStyle={[styles.yearPicker, skin === CalendarSkin.BORDERERED ? styles.withBorder : {}]}
        />
        <Picker
          items={monthItems}
          defaultKey={parseInt(dayjs().add(1, 'days').format('M')) - 1}
          onChange={changeMonth}
          value={month}
          containerStyle={[styles.monthPicker, skin === CalendarSkin.BORDERERED ? styles.withBorder : {}]}
        />
      </View>
      <RNTCalendar
        current={calendarDate}
        headerData={{}}
        minDate={minDate}
        hideArrows
        hideExtraDays
        firstDay={1}
        onDayPress={(day: { dateString: string }) => { setSelectedDate(day.dateString) }}
        style={[styles.calendarContainer, skin === CalendarSkin.BORDERERED ? styles.withBorder : {}]}
        theme={calendarStyles}
        markedDates={{
          [selectedDate]: {
            selected: true,
            disableTouchEvent: true,
          },
        }}
      />
    </View>
  )
}
