/* eslint-disable import/no-duplicates */
import TWN from './TWN.png'
import AFG from './AFG.png'
import ALB from './ALB.png'
import DZA from './DZA.png'
import ASM from './ASM.png'
import AND from './AND.png'
import AGO from './AGO.png'
import AIA from './AIA.png'
import ATG from './ATG.png'
import ARG from './ARG.png'
import ARM from './ARM.png'
import ABW from './ABW.png'
import AUS from './AUS.png'
import AUT from './AUT.png'
import AZE from './AZE.png'
import BHS from './BHS.png'
import BHR from './BHR.png'
import BGD from './BGD.png'
import BRB from './BRB.png'
import BLR from './BLR.png'
import BEL from './BEL.png'
import BLZ from './BLZ.png'
import BEN from './BEN.png'
import BMU from './BMU.png'
import BTN from './BTN.png'
import BOL from './BOL.png'
import BIH from './BIH.png'
import BWA from './BWA.png'
import BRA from './BRA.png'
import IOT from './IOT.png'
import VGB from './VGB.png'
import BRN from './BRN.png'
import BGR from './BGR.png'
import BFA from './BFA.png'
import BDI from './BDI.png'
import CPV from './CPV.png'
import KHM from './KHM.png'
import CMR from './CMR.png'
import CAN from './CAN.png'
import CYM from './CYM.png'
import CAF from './CAF.png'
import TCD from './TCD.png'
import CHL from './CHL.png'
import CHN from './CHN.png'
import HKG from './HKG.png'
import MAC from './MAC.png'
import CXR from './CXR.png'
import CCK from './CCK.png'
import COL from './COL.png'
import COM from './COM.png'
import COG from './COG.png'
import COK from './COK.png'
import CRI from './CRI.png'
import HRV from './HRV.png'
import CUB from './CUB.png'
import CUW from './CUW.png'
import CYP from './CYP.png'
import CZE from './CZE.png'
import CIV from './CIV.png'
import PRK from './PRK.png'
import COD from './COD.png'
import DNK from './DNK.png'
import DJI from './DJI.png'
import DMA from './DMA.png'
import DOM from './DOM.png'
import ECU from './ECU.png'
import EGY from './EGY.png'
import SLV from './SLV.png'
import GNQ from './GNQ.png'
import ERI from './ERI.png'
import EST from './EST.png'
import ETH from './ETH.png'
import FLK from './FLK.png'
import FRO from './FRO.png'
import FJI from './FJI.png'
import FIN from './FIN.png'
import FRA from './FRA.png'
import PYF from './PYF.png'
import GAB from './GAB.png'
import GMB from './GMB.png'
import GEO from './GEO.png'
import DEU from './DEU.png'
import GHA from './GHA.png'
import GIB from './GIB.png'
import GRC from './GRC.png'
import GRL from './GRL.png'
import GRD from './GRD.png'
import GUM from './GUM.png'
import GTM from './GTM.png'
import GGY from './GGY.png'
import GIN from './GIN.png'
import GNB from './GNB.png'
import GUY from './GUY.png'
import HTI from './HTI.png'
import VAT from './VAT.png'
import HND from './HND.png'
import HUN from './HUN.png'
import ISL from './ISL.png'
import IND from './IND.png'
import IDN from './IDN.png'
import IRN from './IRN.png'
import IRQ from './IRQ.png'
import IRL from './IRL.png'
import IMN from './IMN.png'
import ISR from './ISR.png'
import ITA from './ITA.png'
import JAM from './JAM.png'
import JPN from './JPN.png'
import JEY from './JEY.png'
import JOR from './JOR.png'
import KAZ from './KAZ.png'
import KEN from './KEN.png'
import KIR from './KIR.png'
import KWT from './KWT.png'
import KGZ from './KGZ.png'
import LAO from './LAO.png'
import LVA from './LVA.png'
import LBN from './LBN.png'
import LSO from './LSO.png'
import LBR from './LBR.png'
import LBY from './LBY.png'
import LIE from './LIE.png'
import LTU from './LTU.png'
import LUX from './LUX.png'
import MDG from './MDG.png'
import MWI from './MWI.png'
import MYS from './MYS.png'
import MDV from './MDV.png'
import MLI from './MLI.png'
import MLT from './MLT.png'
import MHL from './MHL.png'
import MTQ from './MTQ.png'
import MRT from './MRT.png'
import MUS from './MUS.png'
import MEX from './MEX.png'
import FSM from './FSM.png'
import MCO from './MCO.png'
import MNG from './MNG.png'
import MNE from './MNE.png'
import MSR from './MSR.png'
import MAR from './MAR.png'
import MOZ from './MOZ.png'
import MMR from './MMR.png'
import NAM from './NAM.png'
import NRU from './NRU.png'
import NPL from './NPL.png'
import NLD from './NLD.png'
import NZL from './NZL.png'
import NIC from './NIC.png'
import NER from './NER.png'
import NGA from './NGA.png'
import NIU from './NIU.png'
import NFK from './NFK.png'
import MNP from './MNP.png'
import NOR from './NOR.png'
import OMN from './OMN.png'
import PAK from './PAK.png'
import PLW from './PLW.png'
import PAN from './PAN.png'
import PNG from './PNG.png'
import PRY from './PRY.png'
import PER from './PER.png'
import PHL from './PHL.png'
import PCN from './PCN.png'
import POL from './POL.png'
import PRT from './PRT.png'
import PRI from './PRI.png'
import QAT from './QAT.png'
import KOR from './KOR.png'
import MDA from './MDA.png'
import ROU from './ROU.png'
import RUS from './RUS.png'
import RWA from './RWA.png'
import KNA from './KNA.png'
import LCA from './LCA.png'
import WSM from './WSM.png'
import SMR from './SMR.png'
import STP from './STP.png'
import SAU from './SAU.png'
import SEN from './SEN.png'
import SRB from './SRB.png'
import SYC from './SYC.png'
import SLE from './SLE.png'
import SGP from './SGP.png'
import SXM from './SXM.png'
import SVK from './SVK.png'
import SVN from './SVN.png'
import SLB from './SLB.png'
import SOM from './SOM.png'
import ZAF from './ZAF.png'
import SSD from './SSD.png'
import ESP from './ESP.png'
import LKA from './LKA.png'
import PSE from './PSE.png'
import SDN from './SDN.png'
import SUR from './SUR.png'
import SWZ from './SWZ.png'
import SWE from './SWE.png'
import CHE from './CHE.png'
import SYR from './SYR.png'
import TJK from './TJK.png'
import THA from './THA.png'
import MKD from './MKD.png'
import TLS from './TLS.png'
import TGO from './TGO.png'
import TKL from './TKL.png'
import TON from './TON.png'
import TTO from './TTO.png'
import TUN from './TUN.png'
import TUR from './TUR.png'
import TKM from './TKM.png'
import TCA from './TCA.png'
import TUV from './TUV.png'
import UGA from './UGA.png'
import UKR from './UKR.png'
import ARE from './ARE.png'
import GBR from './GBR.png'
import TZA from './TZA.png'
import VIR from './VIR.png'
import USA from './USA.png'
import URY from './URY.png'
import UZB from './UZB.png'
import VUT from './VUT.png'
import VEN from './VEN.png'
import VNM from './VNM.png'
import ESH from './ESH.png'
import YEM from './YEM.png'
import ZMB from './ZMB.png'
import ZWE from './ZWE.png'
import ALA from './ALA.png'
import GLP from './FRA.png'
import GUF from './FRA.png'
import BLM from './FRA.png'
import MYT from './FRA.png'
import NCL from './FRA.png'
import REU from './FRA.png'
import SPM from './FRA.png'
import ATF from './FRA.png'
import WLF from './FRA.png'
import MAF from './FRA.png'
import Unknown from './Unknown.png'

export const FlagsImages: { [key: string]: any } = {
  TWN,
  AFG,
  ALB,
  DZA,
  ASM,
  AND,
  AGO,
  AIA,
  ATG,
  ARG,
  ARM,
  ABW,
  AUS,
  AUT,
  AZE,
  BHS,
  BHR,
  BGD,
  BRB,
  BLR,
  BEL,
  BLZ,
  BEN,
  BMU,
  BTN,
  BOL,
  BIH,
  BWA,
  BRA,
  IOT,
  VGB,
  BRN,
  BGR,
  BFA,
  BDI,
  CPV,
  KHM,
  CMR,
  CAN,
  CYM,
  CAF,
  TCD,
  CHL,
  CHN,
  HKG,
  MAC,
  CXR,
  CCK,
  COL,
  COM,
  COG,
  COK,
  CRI,
  HRV,
  CUB,
  CUW,
  CYP,
  CZE,
  CIV,
  PRK,
  COD,
  DNK,
  DJI,
  DMA,
  DOM,
  ECU,
  EGY,
  SLV,
  GNQ,
  ERI,
  EST,
  ETH,
  FLK,
  FRO,
  FJI,
  FIN,
  FRA,
  PYF,
  GAB,
  GMB,
  GEO,
  DEU,
  GHA,
  GIB,
  GRC,
  GRL,
  GRD,
  GUM,
  GTM,
  GGY,
  GIN,
  GNB,
  GUY,
  HTI,
  VAT,
  HND,
  HUN,
  ISL,
  IND,
  IDN,
  IRN,
  IRQ,
  IRL,
  IMN,
  ISR,
  ITA,
  JAM,
  JPN,
  JEY,
  JOR,
  KAZ,
  KEN,
  KIR,
  KWT,
  KGZ,
  LAO,
  LVA,
  LBN,
  LSO,
  LBR,
  LBY,
  LIE,
  LTU,
  LUX,
  MDG,
  MWI,
  MYS,
  MDV,
  MLI,
  MLT,
  MHL,
  MTQ,
  MRT,
  MUS,
  MEX,
  FSM,
  MCO,
  MNG,
  MNE,
  MSR,
  MAR,
  MOZ,
  MMR,
  NAM,
  NRU,
  NPL,
  NLD,
  NZL,
  NIC,
  NER,
  NGA,
  NIU,
  NFK,
  MNP,
  NOR,
  OMN,
  PAK,
  PLW,
  PAN,
  PNG,
  PRY,
  PER,
  PHL,
  PCN,
  POL,
  PRT,
  PRI,
  QAT,
  KOR,
  MDA,
  ROU,
  RUS,
  RWA,
  KNA,
  LCA,
  WSM,
  SMR,
  STP,
  SAU,
  SEN,
  SRB,
  SYC,
  SLE,
  SGP,
  SXM,
  SVK,
  SVN,
  SLB,
  SOM,
  ZAF,
  SSD,
  ESP,
  LKA,
  PSE,
  SDN,
  SUR,
  SWZ,
  SWE,
  CHE,
  SYR,
  TJK,
  THA,
  MKD,
  TLS,
  TGO,
  TKL,
  TON,
  TTO,
  TUN,
  TUR,
  TKM,
  TCA,
  TUV,
  UGA,
  UKR,
  ARE,
  GBR,
  TZA,
  VIR,
  USA,
  URY,
  UZB,
  VUT,
  VEN,
  VNM,
  ESH,
  YEM,
  ZMB,
  ZWE,
  ALA,
  GLP,
  GUF,
  BLM,
  MYT,
  NCL,
  REU,
  SPM,
  ATF,
  WLF,
  MAF,
  Unknown,
}
