import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Steps } from 'intro.js-react'
import { IntroStepName, IntroStepActions } from 'Store/IntroStep'

import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { IntroStep, InitIntroSteps } from '@neo-web/componentsOld'

export interface TlcIntroStepsProps {
  enabled: boolean,
}

export const TelecollectionIntroStep: React.FC<TlcIntroStepsProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const stepTelecollections = {
    element: '.MyTransaction_telecollection',
    intro: (
      <IntroStep
        title={t('app:pages:synthesis:introStep:tlc:title')}
      >
        <p>{t('app:pages:synthesis:introStep:tlc:message1')}</p>
        <div style={{ marginTop: 15 }}>
          <Icon name='warning' type={IconTypes.ANT_DESIGN} color={Colors.white} size={18} />
          <p>{t('app:pages:synthesis:introStep:tlc:message2')}</p>
        </div>
      </IntroStep>
    ),
  }

  const [tlcIntroSteps, setTlcIntroSteps]: any = useState({
    ...InitIntroSteps,
    steps: [
      stepTelecollections,
    ],
    options: {
      ...InitIntroSteps.options,
      doneLabel: t('app:global:understood'),
    },
    onExit: () => {
      setTlcIntroSteps({ ...tlcIntroSteps, stepsEnabled: false })
      dispatch(IntroStepActions.hideIntroStep(IntroStepName.TELECOLLECTIONS))
    },
  })

  return (
    <Steps
      enabled={props.enabled}
      steps={tlcIntroSteps.steps}
      initialStep={tlcIntroSteps.initialStep}
      options={tlcIntroSteps.options}
      onExit={() => tlcIntroSteps.onExit()}
    />
  )
}
