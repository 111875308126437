import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { Colors } from '@neo-commons/styles'

import { Typography } from '../Typography/Typography'
import { Icon, IconTypes } from '../Icon/Icon'
import { useStylesheet } from '../utils'

import styles from './styles'

interface CreditCardDisplayProps {
  onPress?: () => void,
  data?: {
    owner: string,
    pan: {
      numbers: string,
      onCopyPanPress?: (pan?: string) => void,
      hideCopyButton?: boolean
    },
    expirationDate: string,
    cvv: string,
  },
  hideNumbers?: boolean,
}
const hiddenData = {
  pan: '**** **** **** ****',
  expirationDate: '**/**',
  cvv: '   ',
}

export const CreditCardDisplay: React.FunctionComponent<CreditCardDisplayProps> = (props: CreditCardDisplayProps) => {
  const { data, hideNumbers, onPress } = props
  const { t } = useTranslation()
  const s = useStylesheet(styles,
    {
      skin: '',
      props: {},
    },
  )
  return (
    <TouchableOpacity
      testID='creditCardDisplay'
      onPress={onPress}
      disabled={!onPress}
    >
      <View
        testID='viewLayout'
        style={s.versoContainer}
      >
        <View testID='verso' style={[s.versoDataArea]}>
          <Typography typeface='subtitle' style={s.label}>{t('neo-commons:card:owner')}</Typography>
          <Typography typeface='bold' style={s.value}>{data?.owner}</Typography>
          <Typography typeface='subtitle' style={s.label}>{t('neo-commons:card:numbers')}</Typography>
          <View style={s.panArea}>
            <Typography typeface='bold' style={s.value}>
              {hideNumbers ? hiddenData.pan : data?.pan?.numbers}
            </Typography>
            {!data?.pan?.hideCopyButton &&
              <Icon
                testID='copyPan'
                type={IconTypes.MATERIAL}
                name='content-copy'
                size={20}
                color={Colors.primary}
                onPress={() => data?.pan?.onCopyPanPress?.(data.pan.numbers)}
              />}
          </View>
          <View style={s.dateAndCvvArea}>
            <View>
              <Typography typeface='subtitle' style={s.label}>{t('neo-commons:card:expirationDate')}</Typography>
              <Typography typeface='bold' style={s.value}>
                {hideNumbers ? hiddenData.expirationDate : data?.expirationDate}
              </Typography>
            </View>
            <View style={s.cvvArea}>
              <Typography typeface='subtitle' style={s.label}>{t('neo-commons:card:cvv')}</Typography>
              <Typography typeface='bold' style={[s.value, s.cvv]}>
                {hideNumbers ? hiddenData.cvv : data?.cvv}
              </Typography>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}
