import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { ScaActions } from 'Store/Sca'
import { AppConfig } from 'Config/AppConfig'

import { PasswordKeypadData, usePasswordKeypad } from '@neo-commons/hooks'

import { KeypadFieldDto, ScaAuthenticationErrorTypeDto, ValidateAuthenticationRequestByPasswordDto } from '@afone/neo-core-client/dist/models'

import { CodeConfirm } from '@neo-web/componentsOld'

import './Code.scss'

export const PinCode: React.FC = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const [showError, setShowError] = useState(false)

  const scaState = useSelector((state: StateTypes) => state.sca.data)
  const signInState = useSelector((state: StateTypes) => state.signIn)
  const passwordKeypadData = usePasswordKeypad({
    phone: signInState.data?.phone,
    phoneCountryCode: signInState.data?.countryCode,
    field: KeypadFieldDto.PASSWORD,
    passwordSize: AppConfig.PASS_CODE_SIZE,
  })

  const onValidate = async (passwordKeypadData: PasswordKeypadData) => {
    try {
      const validateAuthenticationRequestByPassword: ValidateAuthenticationRequestByPasswordDto = {
        password: passwordKeypadData.password.map(e => e.value),
      }
      const hasNoDevice = scaState?.steps[scaState.prepare.scaStep + 1]?.methods
        .filter((method) => method.type === ScaAuthenticationErrorTypeDto.WALLET).length === 0 ?? false

      passwordKeypadData.reset()
      await dispatch(ScaActions.validateAuthenticationRequestByPassword(
        scaState?.authenticationRequestUuid,
        validateAuthenticationRequestByPassword,
        signInState.currentDeviceUuid !== null && !hasNoDevice
      ))

      if (signInState.currentDeviceUuid !== null && !hasNoDevice) {
        await dispatch(ScaActions.requestDeviceMethod(ScaAuthenticationErrorTypeDto.WALLET))
      }

      setShowError(false)
    } catch (error) {
      setShowError(true)
      passwordKeypadData.reset(error)
    }
  }

  return (
    <div className='Code_Container'>
      <CodeConfirm
        error={showError && t('errors:verifyPhoneError')}
        title={i18n.exists(`app:pages:debitCard:services:sca:${scaState.prepare.eventCode}`) ? t(`app:pages:debitCard:services:sca:${scaState.prepare.eventCode}`) : t('app:pages:debitCard:services:enterYourCodeToValidate')}
        onValidate={onValidate}
        keypadData={passwordKeypadData}
      />
    </div>
  )
}
