import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { FlexboxGrid } from 'rsuite'
import { Routes } from 'Routes/Routes'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { SignInActions, SignInState, UserSecretQuestionActions, UserSecretQuestionState } from '@neo-commons/store'
import { Button, ButtonSkin, Input, Typography } from '@neo-commons/components'
import { StepComponentProps, RecoverPasswordStep, RecoverPasswordSteps } from '@neo-commons/policies'
import { Validators } from '@neo-commons/libraries'

import { StateTypes } from '../../../../../../Store'
import { AppConfig } from '../../../../../../Config/AppConfig'

type SecretQuestionProps = StepComponentProps<RecoverPasswordSteps[RecoverPasswordStep.SecretQuestion]>

export const SecretQuestion: React.FC<SecretQuestionProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, setToken] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()
  const signInState: SignInState = useSelector((state: StateTypes) => state?.signIn)
  const userSecretQuestionState: UserSecretQuestionState = useSelector((state: StateTypes) => state.userSecretQuestion)
  const [secretQuestionAnswer, setSecretQuestionAnswer] = useState('')
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false)

  const handleReCaptchaVerify = async () => {
    const token = await executeRecaptcha('recoverPassword_step1_secretQuestion')
    setToken(token)
  }

  useEffect(() => {
    AnalyticsHandler.track(AnalyticsEvents.miscellaneous.forgotPasswordStarted)
  }, [])

  useEffect(() => {
    dispatch(UserSecretQuestionActions.getUserSecretQuestion(signInState.data?.id))
  }, [])

  useEffect(() => {
    if (!executeRecaptcha) {
      return
    }

    (async () => await handleReCaptchaVerify())()
  }, [executeRecaptcha])

  useEffect(() => {
    if (userSecretQuestionState.blocked) {
      history.replace(Routes.RecoverPasswordAlert.secretQuestion.path)
    }
  }, [userSecretQuestionState])

  const onSubmit = () => {
    (async () => {
      try {
        await nextStep({ secretQuestionAnswer: secretQuestionAnswer, userUuid: signInState.data.id })

        await dispatch(SignInActions.getOtpPhoneVerify(signInState.data.phone, token))
      } catch (error) {
        console.log(error)
      }
    })()
  }

  return (
    <FlexboxGrid justify='space-between' align='middle' className='text-center'>

      <FlexboxGrid.Item colspan={24}>
        <Typography typeface='bigTitle'>
          {t('app:pages:recoverPassword:step1:secretQuestion:titleMessage')}
        </Typography>
      </FlexboxGrid.Item>

      {userSecretQuestionState.data &&
        <>
          <FlexboxGrid.Item colspan={24} className='pt-md'>
            <Typography typeface='subtitle'>
              {userSecretQuestionState.data.question}
            </Typography>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={24} className='pt-md'>
            <Input
              isRequired
              label={t('app:pages:recoverPassword:step1:secretQuestion:answer')}
              hasClear
              blurOnSubmit
              value={secretQuestionAnswer}
              clearButtonMode='while-editing'
              onChangeText={(value: string) => setSecretQuestionAnswer(value)}
              validators={[Validators.minLength(AppConfig.MIN_LENGTH_SECRET_QUESTION), Validators.maxLength(AppConfig.MAX_LENGTH_SECRET_QUESTION)]}
              onValidationChecked={(status) => {
                setIsAnswerCorrect(status.isValid)
              }}
            />
          </FlexboxGrid.Item>
        </>}

      <FlexboxGrid.Item colspan={24} className='pt-md'>
        <Button
          title={t('app:pages:recoverPassword:step1:secretQuestion:notAnswer')} skin={ButtonSkin.LINK} onPress={() => {
            history.push(Routes.PublicLink.recoverPassword.path)
          }}
        />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24} className='pt-xl'>
        <Button
          title={t('app:global:validate')} skin={ButtonSkin.PRIMARY}
          disabled={!(secretQuestionAnswer) || !isAnswerCorrect}
          onPressOut={() => { onSubmit() }}
        />
      </FlexboxGrid.Item>

    </FlexboxGrid>
  )
}
