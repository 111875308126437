import React, { useState, forwardRef, RefObject, useEffect, useImperativeHandle, useRef } from 'react'
import { Platform, TextInput, Text, View, TextInputProps, ViewStyle } from 'react-native'
import { TextStyle } from 'react-native-web'

import { ValidatorFunction, ValidatorResponseType } from '@neo-commons/libraries'
import { useValidation } from '@neo-commons/hooks'

import { Icon, IconTypes } from '..'

import s from './styles'
import { getStylesFromProps } from './getStylesFromProps'

export type ErrorMode = 'blur' | 'change' | 'submit'

export interface InputRefHandle {
  getValue: () => string | undefined,
  getValidatorStatus: () => ValidatorResponseType
  focus: () => void,
  blur: () => void,
  clear: () => void,
  formKey?: string,
}

export interface InputProps extends TextInputProps {
  hasClear?: boolean,
  testID?: string,
  format?: (value: string | undefined) => string | undefined,
  key?: string,
  formKey?: string,
  focusStyle?: TextStyle,
  label?: string,
  errorMode?: ErrorMode,
  inputHasError?: boolean,
  validators?: ValidatorFunction[],
  isRequired?: boolean,
  onValidationChecked?: (status: ValidatorResponseType) => void,
  inputStyle?: TextStyle,
  containerStyle?: ViewStyle | ViewStyle[],
  labelStyle?: TextStyle,
  errorStyle?: TextStyle | ViewStyle,
  inputContainerStyle?: ViewStyle | ViewStyle[],
  rightIcon?: () => React.ReactElement,
  keepRightIcon?: boolean,
  rightIconContainerStyle?: ViewStyle,
  clearStyle?: TextStyle,
  rightTextStyle?: TextStyle,
  rightText?: string,
  maxLength?: number,
  fullErrorMode?: boolean,
  // Be careful the counter takes into account the max length prop if not we force the maxlength to 250 when using the counter
  withTextCounter?: boolean,
}

export const InputComponent = forwardRef<InputRefHandle, InputProps>((props, inputRef) => {
  const errorMode = props.errorMode ?? 'blur'
  const [isFocused, setIsFocused] = useState(false)
  const [clearButtonOpacity, setClearButtonOpacity] = useState(0)
  const [hasBeenFocused, setHasBeenFocused] = useState(false)
  const [hasBeenBlurred, setHasBeenBlurred] = useState(false)
  const [errorShown, setErrorShown] = useState(false)
  const [textCounter, setTextCounter] = useState(props?.value?.length ?? 0)
  const inputMaxLength = props.maxLength
    ? props.maxLength
    : props.withTextCounter
      ? 250
      : undefined
  const inputVal = props.format ? props.format(props.value) : props.value
  const textInputRef = useRef<TextInput>()

  const [validatorStatus] = useValidation(inputVal ?? '', props.isRequired ?? false, props.validators ?? [])

  useEffect(() => {
    if (
      inputVal &&
      inputVal.localeCompare(props.value ?? '') !== 0 &&
      props.onChangeText
    ) { props.onChangeText(inputVal) }
  })

  const inputHasError = () => {
    setErrorShown(
      props.inputHasError ||
      (((props.isRequired && inputVal === '') ||
        (inputVal !== '' && !validatorStatus.isValid)) && ((errorMode === 'change' && hasBeenFocused) ||
        (errorMode === 'blur' && hasBeenBlurred)))
    )
  }
  useEffect(() => {
    props.onValidationChecked && props.onValidationChecked(validatorStatus)
    inputHasError()
  }, [validatorStatus])
  useEffect(() => {
    inputHasError()
  }, [hasBeenBlurred, props.inputHasError])

  const clear = () => {
    // eslint-disable-next-line
    textInputRef.current?.clear()
    props.onChangeText && props.onChangeText('')
    setTextCounter(0)
    setHasBeenBlurred(false)
    inputHasError()
  }

  useImperativeHandle(inputRef, () => ({
    getValue: () => inputVal,
    getValidatorStatus: () => validatorStatus,
    clear,
    focus: () => textInputRef.current?.focus(),
    blur: () => textInputRef.current?.blur(),
    formKey: props.formKey,
  }))

  const { inputStyle, labelStyle } = getStylesFromProps({
    ...props,
    inputStyle: props.inputStyle as TextStyle,
    errorShown,
    isFocused,
  })
  const hasClearRightIcon = (clearButtonOpacity === 1) &&
    props.hasClear && props.editable && inputVal && !props.rightIcon
  const rightIcon = hasClearRightIcon ? () => (
    <Icon
      name='closecircle'
      type={IconTypes.ANT_DESIGN}
      size={18} iconStyle={!errorShown ? s.iconClear : s.iconClearWithError}
      onPress={() => clear()}
    />
  )
    : props.rightIcon
      ? props.rightIcon
      : undefined
  const computedRightTextStyle = Platform.OS === 'web' ? [s.input, inputStyle] : []

  return (
    <View style={[s.container, props.containerStyle]}>
      <Text
        numberOfLines={1}
        ellipsizeMode='tail'
        style={[labelStyle, props.labelStyle, (isFocused || inputVal) && props.focusStyle]}
      >
        {props.label}
      </Text>
      <View accessibilityLabel={props.label} style={[s.inputContainer, props.inputContainerStyle]}>
        <TextInput
          {...props}
          clearButtonMode='never'
          maxLength={inputMaxLength}
          ref={textInputRef as RefObject<TextInput>}
          key={props?.key}
          style={[inputStyle, props.inputStyle, props.withTextCounter && s.inputOnCounter]}
          onChangeText={(value) => {
            props.onChangeText && props.onChangeText(value)
            setTextCounter(value.length)
          }}
          onSubmitEditing={(e) => {
            setHasBeenBlurred(true)
            props.onSubmitEditing && props.onSubmitEditing(e)
          }}
          onFocus={(e) => {
            props.onFocus && props.onFocus(e)
            setIsFocused(true)
            setHasBeenFocused(true)
            inputHasError()
            setClearButtonOpacity(1)
          }}
          onBlur={(e) => {
            props.onBlur && props.onBlur(e)
            setIsFocused(false)
            setHasBeenBlurred(true)
            inputHasError()
            setTimeout(() => setClearButtonOpacity(0), 125)
          }}
          value={inputVal}
        />
        {props.rightText &&
          <Text style={[...computedRightTextStyle, s.rightText, props.rightTextStyle ?? {}]}>
            {props.rightText}
          </Text>}
        {props.withTextCounter &&
          <View style={[s.textCounter, props.rightIconContainerStyle]}>
            <Text>
              {`${textCounter}/${inputMaxLength}`}
            </Text>
          </View>}
        {rightIcon &&
          <View style={[s.icon, props.rightIconContainerStyle, { opacity: (props?.keepRightIcon ? 1 : clearButtonOpacity) }]}>
            {rightIcon()}
          </View>}
      </View>
      <Text
        style={[s.error, props.errorStyle]}
        testID={props.testID + 'Error'}
        accessibilityLabel={props.accessibilityLabel + 'Error'}
      >
        {(errorShown && validatorStatus.errorMessage !== undefined && validatorStatus.errorMessage !== '')
          ? validatorStatus.errorMessage : ''}
      </Text>
    </View>
  )
})

InputComponent.defaultProps = {
  hasClear: true,
  editable: true,
}

InputComponent.displayName = 'Input'
export const Input = InputComponent
