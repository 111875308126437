
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { SelectPicker } from 'rsuite'

import { UserActions } from '@neo-commons/store'

import { AnnualIncomeDto, UserDto } from '@afone/neo-core-client/dist/models'

import './MyInfoFields.scss'

export interface MyAnnualIncomeContentProps {
  user: UserDto
  onCloseModal: () => void
}

export const MyAnnualIncomeContent: React.FC<MyAnnualIncomeContentProps> = ({
  user,
  onCloseModal,
}: MyAnnualIncomeContentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [annualIncome, setAnnualIncome] = useState<AnnualIncomeDto>(user?.person?.annualIncome)

  const updateClientAnnualIncome = (annualIncome: AnnualIncomeDto) => {
    (async () => {
      try {
        await dispatch(UserActions.updateUserPerson(user, {
          ...user?.person,
          annualIncome: annualIncome,
        }))
        onCloseModal()
      } catch (error) {}
    })()
  }

  return (
    <div className='fieldContainer'>
      <SelectPicker
        data={Object.entries(AnnualIncomeDto).map(
          (value) =>
            ({
              value: value[1],
              label: t('app:global:enum:annualIncome:' + value[0].replace(/(_)/g, '').toLowerCase()),
            }),
        )}
        onChange={value => {
          setAnnualIncome(value)
          updateClientAnnualIncome(value)
        }}
        appearance='subtle'
        defaultValue={annualIncome}
        cleanable={false}
        searchable={false}
      />
    </div>
  )
}
