import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { FlexboxGrid } from 'rsuite'

import { Button, ButtonSkin, Typography, OTPInput } from '@neo-commons/components'
import { SignInActions, SignInState } from '@neo-commons/store'
import { RecoverPasswordStep, RecoverPasswordSteps, StepComponentProps } from '@neo-commons/policies'

import { StateTypes } from '../../../../../../Store'
import { AppConfig } from '../../../../../../Config/AppConfig'

type SmsProps = StepComponentProps<RecoverPasswordSteps[RecoverPasswordStep.Sms]>

export const Sms: React.FC<SmsProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, setToken] = useState('')
  const signInState: SignInState = useSelector((state: StateTypes) => state?.signIn)
  const dispatch = useDispatch()
  const [codeFilled, setCodeFilled] = useState('')
  const [isValid, setIsValid] = useState(false)
  const ref = useRef()

  const handleReCaptchaVerify = async () => {
    const token = await executeRecaptcha('recoverPassword_step2_sms')
    setToken(token)
  }

  useEffect(() => {
    if (!executeRecaptcha) {
      return
    }

    (async () => await handleReCaptchaVerify())()
  }, [executeRecaptcha])

  useEffect(() => {
    setIsValid(
      !signInState.ui.verifyPhoneLoading && codeFilled.length === AppConfig.OTP_INPUT_SIZE
    )
  }, [signInState.ui.verifyPhoneLoading, codeFilled])

  return (
    <FlexboxGrid justify='space-between' align='middle' className='text-center'>

      <FlexboxGrid.Item colspan={24} className='pb-xl'>
        <Typography typeface='bigTitle'>
          {t('app:pages:recoverPassword:step2:sms:titleMessage')}
        </Typography>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24}>
        <div className='div-center'>
          <OTPInput
            onChangeText={code => setCodeFilled(code)}
            autoFocus
            ref={ref}
            disabled={signInState.ui.verifyPhoneLoading}
          />
        </div>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24} className='py-xl'>
        <Button
          title={t('app:global:validate')} skin={ButtonSkin.PRIMARY}
          disabled={!isValid}
          onPress={async () => {
            try {
              await nextStep(codeFilled ?? '')
            } catch (error) {
            }
          }}
        />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24}>
        <Button
          title={t('app:pages:recoverPassword:step2:sms:codeNotReceived')}
          skin={ButtonSkin.LINK}
          onPress={async () => {
            try {
              await dispatch(SignInActions.getOtpPhoneVerify(signInState.data.phone ?? '', token))
            } catch (error) {
            }
          }}
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}
