import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonSkin, Typography } from '@neo-commons/components'
import './LockDeviceAlert.scss'

export const LockDeviceAlert: React.FC<{callback}> = (props) => {
  const { t } = useTranslation()

  return (
    <div>
      <Typography typeface='infoRegular'>{t('app:pages:signIn:code:device:text')}</Typography>
      <div className='LockDeviceAlert_containerService'>
        <Typography typeface='infoRegular'>{t('app:pages:signIn:code:device:clientService')}</Typography>
        <div className='LockDeviceAlert_clientService'>
          <div>
            {/* Todo : test this */}
            <Button
              title={t('app:pages:signIn:code:device:link')}
              skin={ButtonSkin.LINK}
              onPress={() => props.callback()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
