import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { BankAccountUtils, ClientUtils, NumbersUtils } from '@neo-commons/libraries'
import { Button, ButtonSkin, Icon, IconTypes, Stepper2, Typography } from '@neo-commons/components'
import {
  AdvanceActions,
  BankAccountActions,
  BankAccountSelectors,
  ClientSelectors,
  Dispatch,
  OrdersActions,
  OrdersSelectors,
} from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { AccountDto, ClientDto, OrderStatusDto, OrderTypeDto } from '@afone/neo-core-client/dist/models'
import { AggregationConnectorDto } from '@afone/neo-core-client'

import { useIcon, useResponsive } from '@neo-web/hooks'
import { JustifyContent, Loader, PageContent, PriceCard, PriceCardSkin } from '@neo-web/componentsOld'

import loader from '../../../../UI/Resources/Animations/Loader/lf20_a5zm6vuv.json'

export const AdvanceEligibilityAggregationFallback: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()
  const isTabletOrMobile = useResponsive()

  const search = useLocation().search
  const error = new URLSearchParams(search).get('error')
  const mainAccounts = useSelector(BankAccountSelectors.listMain)
  const aggregationOrder = useSelector(OrdersSelectors.byType(OrderTypeDto.ACCOUNT_AGGREGATED))
  const eligibilityOrder = useSelector(OrdersSelectors.byType(OrderTypeDto.ADVANCE_ELIGIBILITY))
  const aggregatedAccounts = mainAccounts.filter(account => account?.type === 'AGGREGATED' && !account?.disabled)
  const clients: Array<ClientDto> = useSelector(ClientSelectors.list)
  const selectedConnector: AggregationConnectorDto =
    useSelector((state: StateTypes) => state.bankAccount.selectedConnector)
  const isBankAccountsLoading = useSelector(BankAccountSelectors.isLoading)
  const [isLoading, setIsLoading] = useState(true)
  const [retry, setRetry] = useState(0)

  useEffect(() => {
    (async () => {
      if (!error) {
        await dispatch(OrdersActions.listOrders())
        if (aggregationOrder && aggregationOrder.status === OrderStatusDto.PENDING) {
          AnalyticsHandler.track(
            AnalyticsEvents.eligibility.bankAggregated,
            {
              account_type: 'B2C',
              bank_name: selectedConnector?.name,
            })
          await dispatch(BankAccountActions.reset())
          await dispatch(BankAccountActions.list())
          setIsLoading(false)
        } else if (retry > 3) {
          setIsLoading(false)
          history.push(Routes.AdvanceEligibility.error.path)
        } else {
          setTimeout(() => {
            setRetry(retry + 1)
          }, 5000)
        }
      } else history.push(Routes.AdvanceEligibility.error.path, { errorMessage: 'La session a été interrompue' })
    })()
  }, [retry])

  return (
    <PageContent
      withBackground
      title={error
        ? t('app:global:connecting')
        : t('neo-commons:eligibility:aggregation:success:title')}
      justifyContent={JustifyContent.START}
      style={isTabletOrMobile ? { marginBottom: 100 } : {}}
    >
      {(isLoading || isBankAccountsLoading) && !error &&
        <div className='TunnelContent_loading'>
          <div className='TunnelContent_loading_background' />
          <div className='TunnelContent_loading_loader'><Loader loop animation={loader} isShow /> </div>
        </div>}
      <Stepper2 currentStep={2} />
      <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 24 }}>
        {error ? (
          <>
            <Typography typeface='body1'>
              {t('app:global:patientlyWaiting')}
            </Typography>
            <br />
            <Icon name='alert-circle' type={IconTypes.FEATHER} size={32} color={Colors.secondary} />
            <Typography typeface='h3'>
              {t('app:pages:external:aggregation:cancelled:title')}
            </Typography>
            <Typography typeface='body1'>
              {t('app:pages:external:aggregation:cancelled:subtitle')}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
              <Button
                title={t('app:global:login')}
                skin={ButtonSkin.DEFAULT_LIGHT}
                onPress={() => history.push(Routes.AdvanceEligibility.index.path)}
              />
            </div>
          </>)
          : (
            <>
              <Typography typeface='body1'>
                {t('neo-commons:eligibility:aggregation:success:subtitle')}
              </Typography>
              {aggregatedAccounts?.map((bankAccount: AccountDto,) => {
                const client: ClientDto = ClientUtils.getClientFromBankAccount(bankAccount, clients)
                return (
                  <PriceCard
                    key={bankAccount.uuid}
                    skin={PriceCardSkin.DEFAULT}
                    icon={useIcon(bankAccount, client?.type)}
                    price={NumbersUtils
                      .displayPriceForHumanWithCurrency(bankAccount.balance, bankAccount?.currencyCode)}
                    title={BankAccountUtils.getName(bankAccount)}
                  />
                )
              })}
              <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, marginLeft: 10, marginRight: 10 }}>
                <Typography typeface='body1'>
                  {t('neo-commons:eligibility:aggregation:success:subbutton')}
                </Typography>
                <br /><br />
                <Button
                  title={t('neo-commons:eligibility:aggregation:success:button')}
                  skin={ButtonSkin.PRIMARY}
                  onPress={async () => {
                    if (eligibilityOrder) {
                      dispatch(AdvanceActions.setNumberOfAdvanceEligibilityCheck())
                    }
                    dispatch(AdvanceActions.checkEligibility())
                    history.push(Routes.AdvanceEligibility.pending.path)
                  }}
                />
              </div>
            </>
          )}
      </div>
    </PageContent>
  )
}
