import { AnyAction } from 'redux'
import { ofType, ActionsObservable } from 'redux-observable'
import { tap, ignoreElements } from 'rxjs/operators'
import { USER_EXPIRED } from 'redux-oidc'

import { agentClientUserManager } from '../../Config/AgentClientUserManager'
import { store } from '../../Store'

const userExpired$ = (actions$: ActionsObservable<AnyAction>) => actions$.pipe(
  ofType(USER_EXPIRED),
  tap(() => {
    if (store && store.getState().oidc?.user) {
      agentClientUserManager.signoutRedirect()
    }
  }),
  ignoreElements(),
)

export default [
  userExpired$,
]
