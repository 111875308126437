
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'

import {
  Typography,
  Card,
  PaymentCards,
  CardSkin,
} from '@neo-commons/components'
import { CardSelectors, CreditCardData } from '@neo-commons/store'

import { useResponsive } from '@neo-web/hooks'

import './CardOpposed.scss'
import { OrderNewCard } from '../Order/OrderNewCard'

export const CardOpposed: React.FC = () => {
  const { t } = useTranslation()
  const isMobileOrTablet = useResponsive()
  const selectedCard: CreditCardData = useSelector(CardSelectors.selected, (p, c) => p?.uuid === c?.uuid)
  const oppositionDate = dayjs(selectedCard?.oppositionInfo?.oppositionDate)

  const title =
    <div className='CardOpposed_container-summary-title'>
      <div>
        <Typography typeface='bigTitle'>
          {t('app:pages:services:debitCard:opposition:oppositionSummary:title')}
        </Typography>
      </div>
    </div>

  return (
    <>
      <div className='CardOpposed'>
        <div className='CardOpposed_container'>
          <div className='CardOpposed_container-summary'>
            {isMobileOrTablet && title}
            <div>
              <div className='CardOpposed_container-summary-card'>
                <Card
                  skin={CardSkin.DEFAULT}
                  containerStyle={{ padding: 20, marginBottom: 0, width: isMobileOrTablet ? '100%' : 350 }}
                >
                  <PaymentCards card={selectedCard} />
                </Card>
              </div>
            </div>
            <div className='CardOpposed_container-summary-info'>
              {!isMobileOrTablet && title}
              <Typography typeface='subtitle'>
                {t(
                  'app:pages:services:debitCard:opposition:oppositionSummary:oppositionDate',
                  { date: oppositionDate.format('DD/MM/YYYY') }
                )}
              </Typography>
              <br />
              <Typography typeface='subtitle'>
                {t(
                  'app:pages:services:debitCard:opposition:oppositionSummary:oppositionNumber',
                  { number: selectedCard?.oppositionInfo?.oppositionNumber }
                )}
              </Typography>
            </div>
          </div>
          <div className='CardOpposed_container-divider' />
          <div className='CardOpposed_container-orderNewCard'>
            <OrderNewCard />
          </div>
        </div>
      </div>
    </>
  )
}
