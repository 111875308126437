import { map } from 'lodash'

import { ClosingAccountPolicy } from '@neo-commons/policies'

export const ClosingAccountRoutes = {
  index: {
    path: ClosingAccountPolicy.basePath,
    name: 'app:pages:myProfile:closingAccount:recipientAccount:title',
    available: true,
  },
  ...map(ClosingAccountPolicy.steps, ({ path, meta }) => ({
    path,
    name: meta.screenTitle,
    available: true,
  })),
}
