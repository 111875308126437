
import { ReactNode } from 'react'

import { NeobankApi } from '@neo-commons/services/src'
import { KeypadFieldDto, KeypadNumberDto } from '@afone/neo-core-client/dist/models'

/**
 * Ask an array of KeypadNumber. It can be transformed into a matrix to be displayed
 * @param phone - user phone
 * @param field - keypad field type to generate a keypad
 * @param keypadContext - the context where you want your keypad
 * @returns a 1 dimension keypad
 */
const getKeypad = async (phone: string, field: KeypadFieldDto): Promise<KeypadNumberDto[]> => {
  const response = await NeobankApi.getInstance().publicKeypadApi.createKeypad({
    phone,
    field,
    numberOfImages: 10,
    fontSize: 40,
    imageHeight: 64,
    imageWidth: 64,
    fontFamily: 'Quicksand-Bold',
  })
  return response.data
}

const KEYPAD_WIDTH = 3
const KEYPAD_HEIGHT = 4
enum SpecialKeysType {
  BIOMETRICS = 'BIOMETRICS',
  DELETE = 'DELETE',
  VALIDATE = 'VALIDATE',
}
/**
 * Define placement of special keys
 */
const SPECIAL_KEYS: {x:number, y:number, type: SpecialKeysType}[] = [
  { x: 2, y: 3, type: SpecialKeysType.BIOMETRICS },
  { x: 0, y: 3, type: SpecialKeysType.DELETE },
  { x: 2, y: 3, type: SpecialKeysType.VALIDATE },
]
/**
 * Generate a matrix that can be passed to a component to display a keypad
 * @param rawKeypad - a keypad returned by getKeypad()
 * @param specialKeys - define wich component to display in the special key position
 * @returns a matrix that can display a keypad
 */
const generateKeypadMatrix = <T>
  (rawKeypad: T[], specialKeys: {type: SpecialKeysType, component: ReactNode}[]): (T|ReactNode)[][] => {
  if (rawKeypad.length === 0) {
    return [[]]
  }

  const matrix: (T|ReactNode)[][] = []
  for (let y = 0; y < KEYPAD_HEIGHT; y++) {
    const raw: (T|ReactNode)[] = []
    for (let x = 0; x < KEYPAD_WIDTH; x++) {
      const specialKey = SPECIAL_KEYS.find(coordinates => coordinates.x === x && coordinates.y === y)
      raw.push(specialKey
        ? specialKeys.find(key => key.type === specialKey.type)?.component
        : rawKeypad.pop())
    }
    matrix.push(raw)
  }

  return matrix
}

/**
 * Get uri to display the image returned by getKeypad()
 * @param image - a base64 encoded image
 * @returns uri to pass in a image src attribute
 */
const getImageUri = (image: string): string => `data:image/png;base64, ${image}`

export const KeypadUtils = {
  getKeypad,
  generateKeypadMatrix,
  SpecialKeysType,
  getImageUri,
}
