import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { CardActions, CardSelectors, CreditCardData } from '@neo-commons/store'
import { Button, ButtonSkin, Typography } from '@neo-commons/components'

import {
  CardLimitDto,
  CardLimitUpdateDto,
  UsageLimitCodeUpdateDto,
  UsageLimitCodeDto,
} from '@afone/neo-core-client/dist/models'

import { Modal } from '@neo-web/componentsOld'

import { LimitCard } from './LimitCard'

import './ModifyLimitsModal.scss'

export interface ModifyLimitsModalProps {
  show: boolean;
  onClose: () => void;
  isPayment: boolean;
  weeklyLimit: number;
  monthlyLimit: number;
  atmLimit: number;
}

export const ModifyLimitsModal: React.FC<ModifyLimitsModalProps> = ({
  show,
  onClose,
  isPayment,
  weeklyLimit,
  monthlyLimit,
  atmLimit,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)

  const [customWeeklyLimit, setCustomWeeklyLimit] = useState(weeklyLimit)
  const [customMonthlyLimit, setCustomMonthlyLimit] = useState(monthlyLimit)
  const [customAtmWeeklyLimit, setCustomAtmWeeklyLimit] = useState(atmLimit)

  const getLimitByLimitCode = (limitCode: UsageLimitCodeDto): CardLimitDto => {
    return selectedCard?.limits?.find(limit => limit.usageLimitCode === limitCode)
  }

  const updateLimits = async () => {
    try {
      const limits: CardLimitUpdateDto[] =
        [{ usageLimitCode: UsageLimitCodeUpdateDto.ALL_WEEKLY, amount: customWeeklyLimit },
          { usageLimitCode: UsageLimitCodeUpdateDto.ALL_MONTHLY, amount: customMonthlyLimit },
          { usageLimitCode: UsageLimitCodeUpdateDto.ATM_WEEKLY, amount: customAtmWeeklyLimit }]

      await dispatch(CardActions.updateLimits(selectedCard?.uuid, limits))
    } catch (e) {}
  }

  useEffect(() => {
    if (show) {
      setCustomWeeklyLimit(weeklyLimit)
      setCustomMonthlyLimit(monthlyLimit)
      setCustomAtmWeeklyLimit(atmLimit)
    }
  }, [show])

  return (
    <Modal
      title={isPayment
        ? t('app:pages:debitCard:services:limits:modifyPayment:title')
        : t('app:pages:debitCard:services:limits:modifyWithdrawal:title')}
      onClose={onClose}
      open={show}
    >
      <>
        <div className='ModifyLimitsModal_title'>
          <Typography typeface='content'>
            {t('app:pages:debitCard:services:limits:modify:weeklyLimits:title')}
          </Typography>
        </div>
        <LimitCard
          limitBar={{
            current: isPayment ? customWeeklyLimit : customAtmWeeklyLimit,
            max: getLimitByLimitCode(isPayment ? UsageLimitCodeDto.ALL_WEEKLY
              : UsageLimitCodeDto.ATM_WEEKLY)?.maxLimit,
            isSelector: true,
          }}
          onChange={(value) => {
            if (isPayment && value > customMonthlyLimit) {
              setCustomMonthlyLimit(value)
            }
            isPayment ? setCustomWeeklyLimit(value) : setCustomAtmWeeklyLimit(value)
          }}
        />
        {isPayment &&
          <>
            <div className='ModifyLimitsModal_title'>
              <Typography typeface='content'>
                {t('app:pages:debitCard:services:limits:modify:monthlyLimits:title')}
              </Typography>
            </div>
            <LimitCard
              limitBar={{
                current: customMonthlyLimit,
                max: getLimitByLimitCode(UsageLimitCodeDto.ALL_MONTHLY)?.maxLimit,
                isSelector: true,
              }}
              onChange={(value) => {
                if (isPayment && customWeeklyLimit > value) {
                  setCustomWeeklyLimit(value)
                }
                setCustomMonthlyLimit(value)
              }}
            />
          </>}
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:save')}
          onPress={async () => {
            onClose()
            await updateLimits()
          }}
        />
      </>
    </Modal>
  )
}
