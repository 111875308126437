import React from 'react'
import { useTranslation } from 'react-i18next'

import { POSReturnStep, POSReturnSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import {
  CardInfo,
  CardStepper,
  CardStepperItem,
  JustifyContent,
  PageContent,
} from '@neo-web/componentsOld'

type POSReturnProps = StepComponentProps<POSReturnSteps[POSReturnStep.Pedagogy]>

export const Pedagogy: React.FC<POSReturnProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const listSteps: CardStepperItem[] = [
    {
      icon: <Icon name='calculator' type={IconTypes.NEOFONT} size={28} color={Colors.secondary} />,
      text: t('neo-commons:pages:services:pos:return:pedagogy:stepper1'),
    }, {
      icon: <Icon name='money-coins' type={IconTypes.NEOFONT} size={28} color={Colors.secondary} />,
      text: t('neo-commons:pages:services:pos:return:pedagogy:stepper2'),
    }, {
      icon: <Icon name='printer' type={IconTypes.NEOFONT} size={28} color={Colors.secondary} />,
      text: t('neo-commons:pages:services:pos:return:pedagogy:stepper3'),
    }, {
      icon: <Icon name='shipping-box' type={IconTypes.NEOFONT} size={28} color={Colors.secondary} />,
      text: t('neo-commons:pages:services:pos:return:pedagogy:stepper4'),
    },
  ]

  return (
    <PageContent
      justifyContent={JustifyContent.BETWEEN}
      title={t('neo-commons:pages:services:pos:return:pedagogy:title')}
      titleInBody
      footer={(
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:continue')}
          onPress={() => nextStep()}
        />
      )}
    >
      <CardInfo
        icon={<Icon name='alert-circle' type={IconTypes.FEATHER} size={24} color={Colors.secondary} />}
        title={t('neo-commons:pages:services:pos:return:pedagogy:cardTitle')}
        text={t('neo-commons:pages:services:pos:return:pedagogy:cardText')}
      />
      <CardStepper
        list={listSteps}
        timeline
      />
    </PageContent>
  )
}
