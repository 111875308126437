import { useTranslation } from 'react-i18next'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { SignInActions } from '@neo-commons/store'
import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { TunnelContent } from '@neo-web/componentsOld'

import InstagramLogo from '../../../../Resources/Images/site/instagram.png'
import FacebookLogo from '../../../../Resources/Images/site/facebook.png'
import LinkedinLogo from '../../../../Resources/Images/site/linkedin.png'
import { AppConfigTxt } from '../../../../../Config/AppConfig'

import './KeepInformed.scss'

export const KeepInformed: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <TunnelContent
      icon={<Icon name='check-circle' type={IconTypes.FEATHER} size={40} color={Colors.secondary} />}
      subtitle={t('app:pages:subscription:individual:keepInform:title')}
      description={t('app:pages:subscription:individual:keepInform:description')}
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:backToHome')}
          onPress={async () => {
            await dispatch(SignInActions.signOut())
            history.push(Routes.SignIn.index.path)
          }}
        />
      }
    >
      <div className='KeepInformed_container'>
        <a onClick={() => window.open(AppConfigTxt.INSTAGRAM, '_blank')}>
          <img alt='InstagramLogo' src={InstagramLogo} className='KeepInformed_container_icon' />
        </a>
        <a onClick={() => window.open(AppConfigTxt.FACEBOOK, '_blank')}>
          <img alt='FacebookLogo' src={FacebookLogo} className='KeepInformed_container_icon' />
        </a>
        <a onClick={() => window.open(AppConfigTxt.LINKEDIN, '_blank')}>
          <img alt='LinkedinLogo' src={LinkedinLogo} className='KeepInformed_container_icon' />
        </a>
      </div>
    </TunnelContent>
  )
}
