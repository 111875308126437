import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'

import {
  Contact,
  ContactActions,
  ContactSelectors,
  Transfer, TransferActions,
  TransferSelectors, TransferTypes,
} from '@neo-commons/store'
import { TransactionUtils } from '@neo-commons/libraries'
import { RecurringTransferCard } from '@neo-commons/components'

import { RecurringTransferDto } from '@afone/neo-core-client/dist/models'

import { PaginationList } from '@neo-web/componentsOld'

import { Routes } from '../../../../../Routes/Routes'
import { StateTypes } from '../../../../../Store'

import './ComingUp.scss'

export const ComingUp: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const transferState = useSelector((state: StateTypes) => state?.transfer)

  const transfers: Transfer[] = useSelector(
    TransferSelectors.list, (oldList, newList) => oldList.length === newList.length)

  const contacts: Contact[] = useSelector(
    ContactSelectors.list, (oldList, newList) => oldList.length === newList.length)

  const loadContactByUuid = (transfer: RecurringTransferDto) => {
    if (transfer && transfer.contactUuid) {
      (async () => {
        try {
          await dispatch(ContactActions.getContactByUuid(transfer.contactUuid))
          await dispatch(ContactActions.setSelected(transfer.contactUuid))
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }

  const editTransfer = (transfer: RecurringTransferDto) => {
    (async () => {
      try {
        await dispatch(TransferActions.resetPrepare())
        await dispatch(TransferActions.setSelected(transfer.uuid))
        loadContactByUuid(transfer)
      } catch (error) {
        console.log(error)
      }
    })()

    history.push(generatePath(
      Routes.Payments.transferUpdate.path,
      { id: transfer.uuid }
    ))
  }

  const getTransfers = () => {
    (async () => {
      await dispatch(TransferActions.prepareFilter({
        ...transferState.list,
        page: ++transferState.list.page,
      }
      ))
      await dispatch(TransferActions.getRecurringTransfers())
    })()
  }

  useEffect(() => {
    if (transferState.prepare.isUpdating) {
      dispatch(TransferActions.resetPrepare())
    }
  }, [])

  return (
    <div className='ComingUp_container'>
      {(contacts && transfers) &&
        <PaginationList
          state={transferState}
          stateList={transfers}
          items={(item) =>
            <div key={item.uuid}>
              <RecurringTransferCard
                transfer={item}
                onPress={() => editTransfer(item)}
              />
            </div>}
          eventLoading={TransferTypes.LIST_TRANSFER_REQUEST}
          groupData={TransactionUtils.groupTransactionsByDate(transfers, 'nextDeadline', 'asc')}
          next={() => getTransfers()}
          refreshFunction={() => {
            dispatch(TransferActions.getRecurringTransfers())
          }}
        />}
    </div>
  )
}
