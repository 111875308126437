import React from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'

import { useWizardPolicy } from '@neo-commons/hooks'
import { RecoverPasswordPolicy, RecoverPasswordStep } from '@neo-commons/policies'

import { store } from '../../../../../Store'
import { Routes } from '../../../../../Routes/Routes'

import { Validate } from './Step4/Validate'
import { CodeConfirm } from './Step3/CodeConfirm'
import { Code } from './Step3/Code'
import { Sms } from './Step2/Sms'
import { SecretQuestion } from './Step1/SecretQuestion'

import './RecoverPassword.scss'

export const RecoverPassword: React.FC = () => {
  const currentHistory = useHistory()

  const { steps: wizardSteps, currentStep } = useWizardPolicy(store, RecoverPasswordPolicy, {
    [RecoverPasswordStep.SecretQuestion]: SecretQuestion,
    [RecoverPasswordStep.Sms]: Sms,
    [RecoverPasswordStep.Code]: Code,
    [RecoverPasswordStep.CodeConfirm]: CodeConfirm,
    [RecoverPasswordStep.Validate]: Validate,
  }, {
    onDone: async () => {
      currentHistory.push(Routes.SignIn.code.path)
    },
  })

  return (
    <>
      {currentStep?.path &&
        <Redirect
          to={{
            pathname: currentStep?.path,
          }}
        />}
      <div className='RecoverPassword'>
        {wizardSteps.map((value, index) =>
          <Route
            key={index}
            exact
            path={value.path}
            component={value.component}
          />
        )}
      </div>
    </>
  )
}
