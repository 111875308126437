import { RoutesInterface } from './Routes'

export class BankAccountRoutes {
  public static index: RoutesInterface = {
    path: '/bankAccount',
    name: 'app:pages:synthesis:bankAccount:info',
    available: true,
  }

  public static create: RoutesInterface = {
    path: BankAccountRoutes.index.path + '/create',
    name: 'app:global:addAccount',
    available: true,
  }

  public static detail: RoutesInterface = {
    path: BankAccountRoutes.index.path + '/detail/:id',
    name: 'app:pages:synthesis:bankAccount:info',
    available: true,
  }
}
