import { Platform, StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

export default StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: '100%',
    overflow: 'visible',
  },
  inputContainer: {
    borderBottomWidth: 0,
  },
  input: {
    ...Typo.input,
    borderBottomColor: Colors.black,
    minHeight: 32,
    maxHeight: 32,
    height: 32,
    color: Colors.black,
    borderBottomWidth: 1,
    paddingBottom: 0,
    paddingTop: 2,
    paddingLeft: 0,
    marginTop: 24,
    marginBottom: 6,
  },
  inputOnValue: {
  },
  inputOnFocus: {
    borderBottomColor: Colors.primary,
  },
  inputOnCounter: {
    paddingRight: 65,
  },
  inputOnError: {
    borderBottomColor: Colors.error,
  },
  label: {
    ...Typo.info,
    position: 'absolute',
    top: 29,
    fontSize: 16,
    color: Colors.black,
    ...Platform.select({
      web: {
        transitionProperty: 'all',
        transitionDuration: '0.15s',
        transitionTimingFunction: 'ease-in-out',
      },
    }),
  },
  labelOnValue: {
    top: 5,
    fontSize: 14,
    color: Colors.darkGreyText,
  },
  labelOnFocus: {
    top: 5,
    fontSize: 14,
    color: Colors.primary,
  },
  error: {
    marginLeft: 0,
    color: Colors.error,
    textAlign: 'left',
    bottom: 0,
    position: 'relative',
    marginTop: 0,
  },
  iconClear: {
    color: Colors.primary,
  },
  iconClearWithError: {
    color: Colors.error,
  },
  icon: {
    position: 'absolute',
    right: 0,
    paddingTop: 28,
  },
  textCounter: {
    position: 'absolute',
    right: 28,
    paddingTop: 28,
  },
  rightText: {
    flex: 1,
    display: 'flex',
    ...Platform.select({
      native: {
        flex: 0,
      },
    }),
  },
})
