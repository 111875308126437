import { PosActions } from '@neo-commons/store'
import { FailureReason, POSUtils } from '@neo-commons/libraries'

import { createWizardPolicy, invokeActionCreator } from '../../utils'

const machineId = 'posReturn'

/*
 * Guards
 */

const canSelectMultiplePos = (context) => {
  return !POSUtils.returnReasonFailure(context?.store.getState().pos.prepareReturn?.returnReason)
}

const isAddressFilled = (context) => {
  return !!context?.store.getState().pos.prepareReturn?.deliveryAddress
}

const selectedReasonHasNoPedagogyScreen = (context) => {
  return !Object.keys(FailureReason).find(r => r === context?.store.getState().pos.prepareReturn?.failureReason?.code)
}

const bypassPedagogyScreen = (context) => {
  return canSelectMultiplePos(context) || selectedReasonHasNoPedagogyScreen(context)
}

/*
 * Steps
 */

export enum POSReturnStep {
  Pedagogy = 'Pedagogy',
  SelectPos = 'SelectPos',
  IdentifyAnomaly = 'IdentifyAnomaly',
  AnomalyPedagogy = 'AnomalyPedagogy',
  AddressCheck = 'AddressCheck',
  AddressForm = 'AddressForm',
  Summary = 'Summary',
  Process = 'Process',
  Failure = 'Failure',
  Success = 'Success',
}

export const posReturnSteps = {
  [POSReturnStep.Pedagogy]: {
    path: 'pedagogy',
    nextStep: POSReturnStep.SelectPos,
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:return:title',
      canGoBack: true,
    },
  },
  [POSReturnStep.SelectPos]: {
    path: 'selectPos',
    nextStep: POSReturnStep.IdentifyAnomaly,
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:return:title',
      canGoBack: true,
    },
  },
  [POSReturnStep.IdentifyAnomaly]: {
    path: 'identifyAnomaly',
    nextStep: POSReturnStep.AnomalyPedagogy,
    bypassIf: canSelectMultiplePos,
    fulfill: invokeActionCreator(PosActions.prepareReturn),
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:return:title',
      canGoBack: true,
    },
  },
  [POSReturnStep.AnomalyPedagogy]: {
    path: 'anomalyPedagogy',
    nextStep: POSReturnStep.AddressCheck,
    bypassIf: bypassPedagogyScreen,
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:return:title',
      canGoBack: true,
    },
  },
  [POSReturnStep.AddressCheck]: {
    path: 'addressCheck',
    nextStep: POSReturnStep.AddressForm,
    skipIf: isAddressFilled,
    fulfill: invokeActionCreator(PosActions.prepareReturn),
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:return:title',
      canGoBack: true,
    },
  },
  [POSReturnStep.AddressForm]: {
    path: 'addressForm',
    nextStep: POSReturnStep.Summary,
    bypassIf: isAddressFilled,
    fulfill: invokeActionCreator(PosActions.prepareReturn),
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:return:title',
      canGoBack: true,
    },
  },
  [POSReturnStep.Summary]: {
    path: 'summary',
    nextStep: POSReturnStep.Process,
    editableSteps: [
      POSReturnStep.SelectPos,
    ],
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:return:summary:title',
      canGoBack: true,
    },
  },
  [POSReturnStep.Process]: {
    path: 'waiting',
    nextStep: POSReturnStep.Success,
    onErrorRedirectStep: POSReturnStep.Failure,
    fulfill: invokeActionCreator(PosActions.returnPos),
    history: {
      preventStack: true,
    },
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:return:title',
      canGoBack: false,
    },
  },
  [POSReturnStep.Success]: {
    path: 'success',
    nextStep: 'final',
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:return:title',
      canGoBack: false,
    },
  },
  [POSReturnStep.Failure]: {
    path: 'failure',
    nextStep: 'final',
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:return:title',
      canGoBack: false,
    },
  },
}

export type POSReturnSteps = typeof posReturnSteps

/*
 * Policy initialization
 */
export const posReturnPolicy = createWizardPolicy({
  machineId: machineId,
  steps: posReturnSteps,
  initialStep: POSReturnStep.Pedagogy,
  basePath: '/services/pos/return',
})
