import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SensitiveCardData } from '@neo-commons/libraries'
import { CardActions, CardSelectors, CreditCardData } from '@neo-commons/store'

import { CreditCardUtilsWeb } from '@neo-web/utils'

export const useDecryptData = (
  showSca: boolean,
  onEncryptDone?: (error?: string) => void,
  onDecryptedDone?: (error?: string) => void,
) => {
  const [sensitiveCardData, setSensitiveCardData] = useState<SensitiveCardData>(null)
  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)
  const dispatch = useDispatch()

  const getDecryptedCardData = () => {
    (async () => {
      try {
        const sensitiveData = await CreditCardUtilsWeb.decryptSensitiveData(selectedCard.encryptedData)
        setSensitiveCardData(sensitiveData)
        if (onDecryptedDone) onDecryptedDone()
      } catch (error) {
        if (onDecryptedDone) onDecryptedDone(error)
        console.error(error)
      }
    })()
  }

  const getEncryptedCardData = () => {
    (async () => {
      try {
        const keyPair = await CreditCardUtilsWeb.initRsaKeyPair()
        await dispatch(CardActions.getEncryptedData(selectedCard.uuid, keyPair?.public))
        if (onEncryptDone) onEncryptDone()
      } catch (error) {
        if (onEncryptDone) onEncryptDone('SCA has been cancelled')
        console.warn('SCA has been cancelled')
      }
    })()
  }

  useEffect(() => {
    if (showSca) getEncryptedCardData()
  }, [showSca])

  useEffect(() => {
    if (selectedCard?.encryptedData) getDecryptedCardData()
  }, [selectedCard?.encryptedData])

  return sensitiveCardData
}
