import {
  AccountDto,
  AddressDto,
  ClientDto,
  CountryDto,
  ErrorCodeDto,
  OfferDto,
  SubscriptionOrderStatusDto,
  PosDto,
  PosDispatchStatusDto,
  PosReturnReasonDto,
  PosReturnStatusDto,
  ProductDto,
  SubscriptionDto,
  TransactionTlcSpentDto,
  PosStatusDto,
} from '@afone/neo-core-client/dist/models'
import { PosSoftwareDto } from '@afone/neo-core-client/models/pos-software-dto'
import { PaginateItem } from '@neo-commons/store'
import { orderBy } from 'lodash'
import dayjs, {Dayjs} from 'dayjs'

import { BankAccountUtils, SubscriptionUtils } from '../../libraries'

export enum TransactionTlcType{
  WAITING = 'WAITING',
  COMPENSATED = 'COMPENSATED',
  NOT_COMPENSATED = 'NOT_COMPENSATED'
}

export enum PosOrderStatus{
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export enum PosSoftwareCode{
  CB_VISA_MC = 'DLOGCBEMV',
  SANS_CONTACT = 'DLOGCBSANSCONTACT',
}

export enum FailureReason {
  ALERTE_IRRUPTION = 'ALERTE_IRRUPTION',
  ERROR_CONTACTLESS = 'ERROR_CONTACTLESS',
  KEYBOARD_PROBLEM = 'KEYBOARD_PROBLEM',
  MUTE_CARD = 'MUTE_CARD',
  OUT_OF_REACH = 'OUT_OF_REACH',
  POS_OFF = 'POS_OFF',
  POS_RESTART = 'POS_RESTART',
  PRINT_ERROR = 'PRINT_ERROR',
  TLC_ERROR = 'TLC_ERROR',
  TORN_CARD = 'TORN_CARD',
}

export interface PosByAccount {
  [bankAccount: string]: PosDto[]
}

export interface PosProductData{
  productType?: string
  image?: string
  delay?: number
  systemProductCode?: string
  softwareCodes?: string[]
  quantity?: number
}

export interface PosProductDescriptionHeader{
  title?: string
  description?: string
}

export interface PosProductDescriptionBody{
  title?: string
  text?: string
  img?: string[]
  url?: string
}

export interface PosProductDescription{
  header: PosProductDescriptionHeader[]
  body: PosProductDescriptionBody[]
}

export interface GroupTelecollection extends PaginateItem<TransactionTlcSpentDto> {
  date: number,
}

export const PendingDispatchStatuses: PosDispatchStatusDto[] = [
  PosDispatchStatusDto.IN_PROGRESS,
  PosDispatchStatusDto.DISPATCHED,
]

export const SentReturnStatuses: PosReturnStatusDto[] = [
  PosReturnStatusDto.RECEIVED,
  PosReturnStatusDto.REFUNDABLE,
  PosReturnStatusDto.REFUNDED,
  PosReturnStatusDto.REFUND_REJECTED,
  PosReturnStatusDto.DONE,
]

export class POSUtils {
  /**
   * Return description's product
   * @param product - Product.
   * * @returns PosProductDescription of the product.
   */
  public static getPosProductDescription (product: ProductDto) : PosProductDescription {
    return product.description ? JSON.parse(product.description[0]) : {
      header: [],
      body: [],
    }
  }

  /**
   * Return delivery address label for cards
   * @param address - Address.
   * @param client - Product.
   * * @returns string of the product.
   */
  public static getDeliveryLabelCard (address: AddressDto, client: ClientDto) : string {
    return (client?.holder?.legalName ? client?.holder?.legalName : address.fullName) ?? ''
  }

  /**
   * Return address subtitles
   * @param address - Address.
   * @param countries - Product.
   * * @returns string[] of the product.
   */
  public static getDisplayAddressSubtitles (address: AddressDto, countries: CountryDto[]) : string[] {
    const country = countries.find(country => country.isoCodeAlpha2 === address.country || country.isoCodeAlpha2 === address.countryCode)

    return [
      address.line1 ?? '',
      address.postalCode + ', ' + address.city,
      country ? country.name : '',
    ]
  }

  /**
   * Return pos delivery days date.
   * @param posProduct POS Product.
   * @returns Business days date.
   */
  public static getDeliveryDate (posProduct: ProductDto): Dayjs {
    // @ts-ignore
    return dayjs(new Date()).businessDaysAdd((posProduct.data as PosProductData).delay!, 'days')
  }

  /**
   * Return pos delivery days date.
   * @param posProducts POS Product.
   * @param deliveryProduct POS Product.
   * @returns price price as number.
   */
  public static getTotalFees (posProducts: ProductDto[], { prices }: ProductDto): number | undefined {
    if (!posProducts) return
    let sum = 0
    posProducts.forEach(product => {
      const qty = POSUtils.getProductQuantity(product)
      return product.prices?.forEach(price => {
        sum += qty * (price.amount || 0)
      })
    })
    if (prices) {
      prices.forEach(price => {
        sum += price.amount || 0
      })
    }
    return sum
  }

  public static getPaymentProductCode ():string {
    return 'NEO-PRD-CB-PAYMENT'
  }

  public static getPosReturnProductCode ():string {
    return 'NEO-PRD-POS-RETURN'
  }

  public static groupTelecollectionByDate = (transactions: TransactionTlcSpentDto[]): PaginateItem<TransactionTlcSpentDto>[] => {
    return orderBy(
      transactions.reduce((previousValue: GroupTelecollection[], currentValue: TransactionTlcSpentDto) => {
        const date = dayjs(currentValue?.tlcDate).format('D MMMM')

        const existing = previousValue.find(group => group.title === date)
        if (existing) {
          existing.data.push(currentValue)
          existing.data = orderBy(existing.data, 'tlcDate', 'desc')
        } else {
          previousValue.push({ title: date, date: dayjs(currentValue?.tlcDate).unix(), data: [currentValue] })
        }
        return previousValue
      }, [])
      , ['date'], ['desc'])
  }

  public static getTransactionTlcType (transaction: TransactionTlcSpentDto): TransactionTlcType {
    return transaction.cleared ? TransactionTlcType.COMPENSATED : TransactionTlcType.NOT_COMPENSATED
  }

  public static getProductQuantity = (product: ProductDto) => {
    return (product.data as PosProductData)?.quantity ?? 0
  }

  public static sortProductsByPrice = (products: ProductDto[]) => {
    return products.sort((product1, product2) =>
      ((product1.prices?.[0]?.amount ?? 0) > (product2.prices?.[0]?.amount ?? 0)) ? 1 : -1
    )
  }

  public static groupPosByAccounts = (accounts: AccountDto[], pos: PosDto[]): PosByAccount => {
    return [...accounts].filter(BankAccountUtils.isPos).reduce((previousValue, currentValue) => {
      return {
        ...previousValue,
        [currentValue.uuid]: pos.filter(p => p?.accountUuid === currentValue.uuid),
      }
    }, {}) as PosByAccount
  }

  public static getContractNumber = (posSoftwares: PosSoftwareDto[], type: PosSoftwareCode): string | undefined => (
    posSoftwares.find(pos => pos?.systemCode === type)?.contractNumber
  )

  public static reinitProductQuantity = (offers: OfferDto[]) => {
    const products = offers.length ? offers[0]?.products?.filter(product => SubscriptionUtils.isPosModel(product)) : []
    products && products.map(pro => {
      pro.data = { ...pro.data, quantity: undefined }
    })
  }

  public static filterPosByStatus = (pos: PosDto[], subscriptions: SubscriptionDto[], posStatus: PosStatusDto): PosDto[] => {
    const subStatus: SubscriptionOrderStatusDto[] = [SubscriptionOrderStatusDto.DELIVERED, SubscriptionOrderStatusDto.ACTIVATED]
    return pos.filter(p => {
      const sub: any = subscriptions.find(sub => sub.uuid === p.workflowSubscriptionUuid)
      return p.status === posStatus && (sub && subStatus.includes(sub.orderStatus))
    })
  }

  public static returnReasonFailure = (returnReason: PosReturnReasonDto): boolean => {
    return returnReason === PosReturnReasonDto.FAILURE
  }

  public static isTlcError = (errorCode: ErrorCodeDto): boolean => {
    return errorCode === ErrorCodeDto.C5004
  }
}
