import React, { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { View } from 'react-native'

import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { StateTypes } from 'Store'
import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'

import {
  Button,
  ButtonSkin,
  CountryList,
  Input2,
  PhoneInput2,
  Stepper2,
  Typography2,
} from '@neo-commons/components'
import { SignupProfileState } from '@neo-commons/store'
import { RegisterStep, RegisterSteps, StepComponentProps } from '@neo-commons/policies'
import {
  CountryUtils,
  SelectionMode,
  ValidatorResponseType,
  Validators,
} from '@neo-commons/libraries'

import { CountryDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent } from '@neo-web/componentsOld'

import './Contact.scss'

type ContactProps = StepComponentProps<RegisterSteps[RegisterStep.Contact]>

export const Contact: React.FC<ContactProps> = (props: ContactProps) => {
  const { t } = useTranslation()
  const countries: CountryDto[] = useSelector(
    (state: StateTypes) => {
      return CountryUtils.filterByAuthorized(
        state.config?.data?.currentConfig?.countries ?? [],
        SelectionMode.PHONE_PREFIX
      )
    }
  )
  const profile: SignupProfileState = useSelector((state: StateTypes) => state?.signup?.profile)
  const triggerPhoneVerifyLoading: boolean = useSelector((state: StateTypes) => state?.signup?.ui?.triggerPhoneVerifyLoading ?? false)
  const [state, setState] = useState({
    firstName: profile?.firstName ?? '',
    lastName: profile?.lastName ?? '',
    phone: profile?.phone ?? '',
    email: profile?.email ?? '',
    selectedCountry: {
      isoCodeAlpha2: 'FR',
      isoCodeAlpha3: 'FRA',
    },
    isOnDropdown: false,
    isOpen: false,
    token: '',
    isSubmitting: false,
    isFirstNameCorrect: Validators.isNotEmpty(profile?.firstName).isValid,
    isLastNameCorrect: Validators.isNotEmpty(profile?.lastName).isValid,
    isEmailCorrect: Validators.isEmail(profile?.email).isValid || profile?.isOfflineRegistration,
    isPhoneCorrect: Validators.isPhoneNumber(profile?.phone).isValid || profile?.isOfflineRegistration,
  })

  const {
    firstName,
    lastName,
    phone,
    email,
    selectedCountry,
    isOpen,
    token,
    isSubmitting,
    isFirstNameCorrect,
    isLastNameCorrect,
    isEmailCorrect,
    isPhoneCorrect,
  } = state

  const { executeRecaptcha } = useGoogleReCaptcha()
  const isFormValid = isFirstNameCorrect && isLastNameCorrect && isPhoneCorrect && isEmailCorrect

  const triggerVerify = async () => {
    setState({ ...state, isSubmitting: true })
    if (isFormValid && !triggerPhoneVerifyLoading && !!selectedCountry) {
      const phoneNumber = parsePhoneNumber(phone, selectedCountry.isoCodeAlpha2 as CountryCode)
      AnalyticsHandler.track(AnalyticsEvents.signUp.userSignUpStepCompleted,
        {
          step_name: RegisterStep.Contact,
          phone: phoneNumber.formatInternational().replace(/ /g, ''),
          email: email,
          firstname: firstName,
          lastname: lastName,
        })
      props.nextStep({
        phone: phone.replace(/ /g, '') as string,
        phoneCountryCode: selectedCountry.isoCodeAlpha3.slice(0, 2) as CountryCode,
        token: token as string,
        lastName: lastName,
        firstName: firstName,
        email: email,
      })
    }
  }

  useEffect(() => {
    AnalyticsHandler.reset()
    AnalyticsHandler.track(AnalyticsEvents.signUp.userSignUpStarted)
  }, [])

  useEffect(() => {
    if (!executeRecaptcha) {
      return
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha('registerStep1_phone')
      setState({ ...state, token })
    }

    (async () => await handleReCaptchaVerify())()
  }, [executeRecaptcha])

  useEffect(() => {
    setState({
      ...state,
      selectedCountry: countries.find(country =>
        country.isoCodeAlpha2 === (profile?.phone && profile?.phone.startsWith('+')
          ? parsePhoneNumber(profile?.phone)?.country : profile?.countryCode)),
    })
  }, [profile])

  return (
    <TunnelContent
      header={
        <View>
          <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
            {t('app:pages:register:step1:contact:signup')}
          </Typography2>
          <Stepper2 currentStep={0} />
        </View>
      }
      footer={
        !isFormValid
          ? (
            <div style={{ textAlign: 'center' }}>
              <Typography2 typeface='body2'>{t('app:pages:register:step1:contact:footerText')}</Typography2>
            </div>)
          : (
            <div style={{ textAlign: 'center' }}>
              <Button
                title={t('app:global:confirm')} skin={ButtonSkin.PRIMARY}
                disabled={triggerPhoneVerifyLoading}
                onPressOut={() => triggerVerify()}
              />
              <div className='Phone_privacy'>
                <Typography2 typeface='body2'>
                  <Trans i18nKey='app:pages:register:step1:phone:privacy:text' />
                </Typography2>
                <Typography2
                  typeface='body2'
                >
                  <a className='privacy-link' target='_blank' href='/public/privacyPolicy'>
                    {t('app:pages:register:step1:phone:privacy:link')}
                  </a>
                </Typography2>
              </div>
            </div>)
      }
    >
      <div className='Phone_container'>
        <div>
          <Input2
            label={t('app:pages:register:stepper:firstName')}
            value={firstName}
            autoFocus
            onChange={(text) => setState({ ...state, firstName: text as any })}
            hasBeenSubmitted={isSubmitting}
            validators={[Validators.fieldNotEmpty]}
            onValidationChecked={(value: ValidatorResponseType) => {
              setState({ ...state, isFirstNameCorrect: value.isValid })
            }}
            editable={!profile?.isOfflineRegistration}
          />
          <Input2
            label={t('app:pages:register:stepper:lastName')}
            value={lastName}
            onChange={(text) => setState({ ...state, lastName: text as any })}
            hasBeenSubmitted={isSubmitting}
            validators={[Validators.fieldNotEmpty]}
            onValidationChecked={(value: ValidatorResponseType) => {
              setState({ ...state, isLastNameCorrect: value.isValid })
            }}
            editable={!profile?.isOfflineRegistration}
          />
          <div className='container-custom-dropdown'>
            <PhoneInput2
              label=''
              isSubmitting={isSubmitting}
              onPressPrefix={() => setState({ ...state, isOpen: !isOpen })}
              selectedCountry={selectedCountry}
              value={phone}
              validators={[Validators.isPhoneNumber]}
              onValidationChecked={(value: ValidatorResponseType) => {
                setState({ ...state, isPhoneCorrect: value.isValid })
              }}
              onChange={(value) => setState({ ...state, phone: value as any })}
              editable={!profile?.isOfflineRegistration}
            />
            {isOpen && (
              <div
                className='custom-dropdown'
                onMouseEnter={() => setState({ ...state, isOnDropdown: true })}
                onMouseLeave={() => setState({ ...state, isOnDropdown: false })}
              >
                <CountryList
                  countries={countries}
                  selectionMode={SelectionMode.PHONE_PREFIX}
                  onCountrySelectedAction={(country) => {
                    setState({
                      ...state,
                      selectedCountry: country,
                      isOpen: false,
                    })
                  }}
                />
              </div>
            )}
          </div>
          {!profile?.isOfflineRegistration &&
            <Input2
              editable
              label={t('app:pages:register:stepper:eMailAddress')}
              value={email}
              onChange={(text) => setState({ ...state, email: text as any })}
              hasBeenSubmitted={isSubmitting}
              validators={[Validators.isEmail]}
              onValidationChecked={(value: ValidatorResponseType) => {
                setState({ ...state, isEmailCorrect: value.isValid })
              }}
              format={{
                type: 'email',
                additionalProps: '',
              }}
            />}
        </div>
      </div>
    </TunnelContent>
  )
}
