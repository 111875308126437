
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'

import {
  Button,
  ButtonSkin,
  Card,
  CardSkin,
  ChannelContactCard,
  Icon,
  IconTypes,
  Typography,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { ContactChannelDto, ContactDto } from '@afone/neo-core-client/dist/models'

import { useResponsive } from '@neo-web/hooks'

import { AppConfig } from '../../../../../Config/AppConfig'

import { CreateCanalContent } from './CreateCanalContent'

interface ContactChannelListProps {
  channels: ContactChannelDto[],
  onPressOut: (title: string, element: ReactElement) => void,
  saveContact: (contact: ContactChannelDto, indexEdit?: number) => void
  removeContact: (index: number) => void,
  contact?: ContactDto,
}

export const ContactChannelList: React.FC<ContactChannelListProps> = (props: ContactChannelListProps) => {
  const isTabletOrMobile = useResponsive()
  const { t } = useTranslation()

  const addContactButton =
    <Button
      skin={ButtonSkin.OUTLINE_PRIMARY}
      flat
      title={t('app:pages:payments:contact:create:form:channel:add')}
      leftIcon={
        <Icon
          name='plus'
          type={IconTypes.FEATHER}
          size={20}
          color={Colors.primary}
        />
      }
      onPressOut={() => props.onPressOut(
        t('app:pages:payments:contact:create:form:channel:add'),
        <CreateCanalContent
          remove={props.removeContact}
          save={props.saveContact}
        />)}
    />

  return (
    <>
      <FlexboxGrid.Item colspan={24} className='MyInfo_title'>
        <Typography typeface='header'>
          {t('app:pages:payments:contact:create:form:channel:label')}
        </Typography>
      </FlexboxGrid.Item>
      {props.channels.length === 0 ? (
        <Card
          skin={isTabletOrMobile ? CardSkin.DEFAULT : CardSkin.PRIMARY_DARKER_BORDERED}
          withShadow
        >
          <FlexboxGrid.Item colspan={24} className='ContactCreate_block'>
            <div>
              <Typography typeface='infoRegular'>
                {t('app:pages:payments:contact:create:form:channel:empty')}
              </Typography>
            </div>
            {addContactButton}
          </FlexboxGrid.Item>
        </Card>
      ) : (
        <>
          {props.channels.map((channel: ContactChannelDto, index: number) =>
            <Card
              key={channel.value}
              skin={isTabletOrMobile ? CardSkin.DEFAULT : CardSkin.PRIMARY_DARKER_BORDERED}
              withShadow
            >
              <ChannelContactCard
                label={channel.label}
                value={channel.value}
                isNoElse={channel.applicationUser}
                editable
                onEditPress={() => {
                  props.onPressOut(t('app:pages:payments:contact:create:form:channel:edit'),
                    <CreateCanalContent
                      indexEdit={index}
                      channel={channel}
                      disableDelete={(props.channels.length === 1 && !!props.contact)}
                      remove={props.removeContact}
                      save={props.saveContact}
                    />)
                }}
              />
            </Card>
          )}
          {props.channels.length < AppConfig.MAX_CHANNEL_FOR_CONTACT && addContactButton}
        </>
      )}
    </>)
}
