import React, { FunctionComponent } from 'react'
import { TouchableOpacity, View, ViewStyle } from 'react-native'

import { Colors } from '@neo-commons/styles'

import { Icon, IconTypes, Typography } from '..'

import styles from './styles'

export enum SecretQuestionState {
  CORRECT = 'CORRECT',
  WRONG = 'WRONG',
  BLANK = 'BLANK',
}

interface SecretQuestionCardProps {
  title?: string,
  subtitle?: string,
  onPress: () => void,
  containerStyle?: ViewStyle,
  testID?: string,
  state?: SecretQuestionState,
}

export const SecretQuestionCard: FunctionComponent<SecretQuestionCardProps> = (props: SecretQuestionCardProps) => {
  const {
    testID, title, subtitle, onPress, containerStyle, state,
  } = props
  return (
    <TouchableOpacity
      testID={testID}
      onPress={() => {
        onPress && onPress()
      }}
      style={[styles.container, containerStyle]}
    >
      <View style={styles.containerBasic}>
        <View style={styles.centerArea}>
          {title && <Typography typeface='subtitle'>{title}</Typography>}
          {subtitle &&
            <Typography typeface='smallText' style={styles.subtitle}>
              {subtitle}
            </Typography>}
        </View>
        {state && state !== SecretQuestionState.BLANK &&
          <View style={styles.stateIconContainerStyle}>
            <Icon
              testID='secretQuestionStateIcon'
              color={state === SecretQuestionState.CORRECT ? Colors.primary : Colors.error}
              name={state === SecretQuestionState.CORRECT ? 'checkcircle' : 'closecircle'}
              type={IconTypes.ANT_DESIGN}
              size={20}
            />
          </View>}
      </View>
    </TouchableOpacity>
  )
}
