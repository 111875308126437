import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { BankAccountActions, NotificationActions, NotificationSelectors, TransactionActions } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { NotificationTypeDto } from '@afone/neo-core-client/dist/models'

import { Modal } from '@neo-web/componentsOld'

import './NotificationsAlert.scss'

export const NotificationsAlert: React.FC = () => {
  const history = useHistory()
  const notificationState = useSelector(NotificationSelectors.list)[0]
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const renderIcon = () => {
    let name = 'alert-circle'
    let type = IconTypes.FEATHER

    if (notificationState) {
      switch (notificationState.type) {
        case NotificationTypeDto.SUCCESS:
          name = 'check'
          type = IconTypes.FEATHER
          break
        case NotificationTypeDto.WARNING:
          name = 'warning'
          type = IconTypes.ANT_DESIGN
          break
        case NotificationTypeDto.INFO:
          name = 'info'
          type = IconTypes.FEATHER
          break
        case NotificationTypeDto.ERROR:
          name = 'alert-circle'
          type = IconTypes.FEATHER
          break
      }
    }
    return <Icon name={name} color={Colors.secondary} type={type} size={40} />
  }

  useEffect(() => {
    if (notificationState !== null) {
      setShowModal(true)
    }
  }, [])

  const hideModal = () => {
    if (notificationState !== null) {
      (async () => {
        setShowModal(false)
        try {
          await dispatch(NotificationActions.read(notificationState))
        } catch (e) {}
      })()
    }
  }

  const redirect = (action) => {
    (async () => {
      try {
        history.push(action.actionUrl)
        if (action?.data && action?.data?.account) {
          await dispatch(BankAccountActions.setSelected(action?.data?.account))
          await dispatch(TransactionActions.reset())
        }
      } catch (e) {}
    })()
  }

  return (
    <Modal autoFocus open={showModal} displayCross onClose={() => hideModal()}>
      <div className='NotificationsAlert_container'>
        {renderIcon()}
        {notificationState.title && (
          <div className='NotificationsAlert_title'>
            <Typography typeface='bigTitle'>{notificationState.title}</Typography>
          </div>
        )}
        {notificationState.message && (
          <div className='NotificationsAlert_content'>
            <Typography typeface='content'>
              {t(notificationState.message)}
            </Typography>
          </div>
        )}
        {notificationState.actions.length && notificationState.actions.map((action, index) => {
          return (
            <div className='NotificationsAlert_action' key={index}>
              <Button
                title={action.title}
                skin={action.type || ButtonSkin.OUTLINE_PRIMARY}
                onPress={() => {
                  hideModal()
                  action.actionUrl && redirect(action)
                }}
              />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
