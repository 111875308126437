import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { DocumentUploadActions, SubscriptionSelectors } from '@neo-commons/store'
import { EnumUtils, SubscriptionUtils } from '@neo-commons/libraries'
import { IndividualSubscriptionStep, IndividualSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import { MenuItemCard, Stepper2, Typography2 } from '@neo-commons/components'

import { SubscriptionDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './DocumentChoice.scss'

type DocumentChoiceProps =
  StepComponentProps<IndividualSubscriptionSteps[IndividualSubscriptionStep.DocumentsChoice]>

export const DocumentChoice: React.FC<DocumentChoiceProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const kycDocumentsIncomplete = SubscriptionUtils.getKycDocumentsIncomplete(subscription)

  useEffect(() => {
    if (!kycDocumentsIncomplete?.length) {
      nextStep()
    }
  }, [subscription])

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      header={
        <div className='DocumentChoice_header'>
          <Typography2 typeface='h3'>
            {t('app:pages:subscription:individual:mainTitle:idDocument')}
          </Typography2>
          <Stepper2 currentStep={1} />
        </div>
      }
      description={
        <div>
          <p style={{ fontFamily: 'Quicksand-bold' }}>{t('app:pages:subscription:global:documentChoice:title')}</p>
          <p>{t('app:pages:subscription:global:documentChoice:description')}</p>
        </div>
      }
    >
      <div className='DocumentChoice_container'>
        {kycDocumentsIncomplete.map((document, index) => {
          return (
            <div className='DocumentChoice_container_item' key={index}>
              <MenuItemCard
                title={t(EnumUtils.getKycTypeDtoKey(document.type))}
                onPress={() => {
                  dispatch(DocumentUploadActions.prepare({
                    name: t(EnumUtils.getKycTypeDtoKey(document.type)),
                    documentType: document.type,
                  }))
                  history.push(Routes.DocumentUpload.index.path)
                }}
              />
            </div>
          )
        })}
      </div>
    </TunnelContent>
  )
}
