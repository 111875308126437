import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, Redirect, useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'
import { StateTypes } from '@neo-customer-front/applications/neo-web/src/Store'

import {
  BankAccountSelectors,
  TelecollectionActions,
  TelecollectionSelectors,
  TelecollectionTypes,
} from '@neo-commons/store'
import { BankAccountUtils, DateUtils, NumbersUtils, POSUtils } from '@neo-commons/libraries'
import { Button, ButtonSkin, Icon, IconTypes, Typography, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import {
  CardDetails,
  JustifyContent,
  Modal,
  PageContent,
  PageDetails,
  PaginationList,
} from '@neo-web/componentsOld'

import { TelecollectionCard } from '../Components/TelecollectionCard/TelecollectionCard'

import './TelecollectionsList.scss'

export const TelecollectionsList: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const isPosAccount = BankAccountUtils.isPos(selectedBankAccount)

  const telecollectAmount = useSelector(TelecollectionSelectors.summaryAmount)
  const telecollectList = useSelector(TelecollectionSelectors.listByIdentifier(selectedBankAccount.uuid))
  const telecollectPendingList = useSelector(TelecollectionSelectors.pendingList)
  const telecollectState = useSelector((state: StateTypes) => state?.telecollection)
  const currentPage = useSelector((state: StateTypes) => state.telecollection.list.page)
  const paginationEnded = useSelector((state: StateTypes) => state.telecollection.list.paginationEnded)
  const [showModal, setShowModal] = useState(false)

  const endDate = DateUtils.getDateToFormat('YYYY-MM-DD')
  const startDate = DateUtils.getDateSubtract(1, 'year', endDate)

  const getTelecollectionList = (first?: boolean) => {
    (async () => {
      const page = first ? 0 : (currentPage ?? 0) + 1
      if (!paginationEnded) {
        try {
          dispatch(TelecollectionActions.getTelecollectionList(startDate, endDate, page))
        } catch (error) {}
      }
    })()
  }

  useEffect(() => {
    if (isPosAccount) {
      try {
        getTelecollectionList(true)
        dispatch(TelecollectionActions.getSummaryAmount())
        dispatch(TelecollectionActions.getPendingList())
      } catch (error) {}
    }
  }, [])

  return (
    <>
      {!isPosAccount ? (
        <Redirect
          to={{ pathname: Routes.Synthesis.index.path }}
        />
      ) : (
        <PageDetails
          title={t('app:pages:synthesis:telecollections:title')}
          component={(
            <PageContent
              justifyContent={JustifyContent.START}
            >
              <Modal
                open={showModal}
                displayCross
                title={t('app:pages:synthesis:telecollections:list:info:title')}
                onClose={() => setShowModal(false)}
              >
                <Typography typeface='content'>
                  {t('app:pages:synthesis:telecollections:list:info:modalContent')}
                </Typography>
              </Modal>
              <Button
                title={t('app:pages:synthesis:telecollections:list:info:title')}
                skin={ButtonSkin.DEFAULT_LIGHT}
                rightIcon={<Icon name='info' type={IconTypes.FEATHER} size={20} color={Colors.primary} />}
                overrideTextStyleButton={{ justifyContent: 'space-between' }}
                containerStyle={{ marginBottom: 20 }}
                onPress={() => setShowModal(true)}
              />
              <CardDetails
                title={t('app:pages:synthesis:telecollections:list:header:title')}
              >
                <div className='TelecollectionsList_header_amount'>
                  <Typography typeface='titleH4Secondary'>
                    {`${NumbersUtils.displaySignedPriceForHuman(telecollectAmount ?? 0)}`}
                  </Typography>
                </div>
                <div className='TelecollectionsList_header_alert'>
                  <div className='TelecollectionsList_header_alert_icon'>
                    <Icon name='alert-triangle' type={IconTypes.FEATHER} size={25} color={Colors.secondary} />
                  </div>
                  <Typography typeface='subtitle'>
                    {t('app:pages:synthesis:telecollections:list:header:message')}
                  </Typography>
                </div>
              </CardDetails>

              {telecollectPendingList && telecollectPendingList.length > 0 && (
                <div className='TelecollectionsList_pending'>
                  <div className='TelecollectionsList_pending_sectionTitle'>
                    <Typography2 typeface='overline'>
                      {t('app:pages:synthesis:telecollections:list:pending:title')}
                    </Typography2>
                  </div>
                  {telecollectPendingList.map((telecollectPending, i) => {
                    return (
                      <TelecollectionCard
                        key={i}
                        telecollect={telecollectPending}
                        pending
                        onPress={() => {
                          history.push(
                            generatePath(
                              Routes.Synthesis.telecollectionDetails.path,
                              { id: 'pending-' + telecollectPending.idTlc }
                            )
                          )
                        }}
                      />
                    )
                  })}
                </div>
              )}

              <PaginationList
                state={telecollectState}
                stateList={telecollectList}
                items={(item) =>
                  <TelecollectionCard
                    key={item.idTlc}
                    telecollect={item}
                    onPress={() => {
                      history.push(
                        generatePath(Routes.Synthesis.telecollectionDetails.path, { id: item.idTlc })
                      )
                    }}
                  />}
                eventLoading={[
                  TelecollectionTypes.LIST_TELECOLLECTION_REQUEST,
                  TelecollectionTypes.LIST_SUMMARY_AMOUNTS_REQUEST,
                ]}
                groupData={POSUtils.groupTelecollectionByDate(telecollectList)}
                next={() => getTelecollectionList()}
                refreshFunction={() => {
                  try {
                    dispatch({ type: TelecollectionTypes.RESET })
                    getTelecollectionList(true)
                  } catch (error) {}
                }}
              />
            </PageContent>
          )}
        />
      )}
    </>
  )
}
