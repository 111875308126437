import * as Sentry from '@sentry/react'
import BugBattle from 'bugbattle'
import { AnalyticsBrowser } from '@segment/analytics-next'

import { Colors } from '@neo-commons/styles'

import { UserDto } from '@afone/neo-core-client/dist/models'

import * as packageJson from '../../package.json'

import { ENV, config } from './EnvConfig'

export class Monitoring {
  static initSentry (): void {
    try {
      Sentry.init({
        dsn: 'https://0b2aa11cc92c47e4bfa9f63785dd9adf@o478106.ingest.sentry.io/5565809',
        autoSessionTracking: true,
        environment: config(ENV.ENV),
        release: `${packageJson.name}@${packageJson.version}`,
        normalizeDepth: 10,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        // See : https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
        ignoreErrors: [
          // Random plugins/extensions
          'top.GLOBALS',
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          'originalCreateNotification',
          'canvas.contentDocument',
          'MyApp_RemoveAllHighlights',
          'http://tt.epicplay.com',
          "Can't find variable: ZiteReader",
          'jigsaw is not defined',
          'ComboSearch is not defined',
          'http://loading.retry.widdit.com/',
          'atomicFindClose',
          // Facebook borked
          'fb_xd_fragment',
          // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
          // reduce this. (thanks @acdha)
          // See http://stackoverflow.com/questions/4113268
          'bmi_SafeAddOnload',
          'EBCallBackMessageReceived',
          // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          'conduitPage',
          // See https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications',
        ],
        // See : https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
        denyUrls: [
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Woopra flakiness
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
        integrations: [
          // Pas sur de l'activation du replay de cette manière, à tester
          // see : https://docs.sentry.io/platforms/javascript/guides/react/session-replay/
          Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
          }),
          Sentry.browserTracingIntegration(),
        ],
        beforeSend (event) {
          // Check if it is an exception, and if so, show the report dialog
          if (event.exception) {
            Sentry.showReportDialog({ eventId: event.event_id })
          }
          return event
        },
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
      })
    } catch (e) {
      console.warn('Impossible init sentry', e)
    }
  }

  static initBugBattle (): void {
    try {
      BugBattle.initialize('RPHuQPY3vNMuu8eybXgBPvInfexy2Eoo', BugBattle.FEEDBACK_BUTTON)
      BugBattle.setAppBuildNumber(config(ENV.ENV))
      BugBattle.setAppVersionCode(packageJson.version)
      BugBattle.setMainColor(Colors.secondary)
      BugBattle.enablePrivacyPolicy(true)
      BugBattle.enablePoweredByBugbattle(true)
      BugBattle.enableCrashDetector(true)
    } catch (e) {
      console.warn('Impossible init bug battle', e)
    }
  }

  static setUserSentry (user: UserDto): void {
    if (user) {
      Sentry.setUser({
        id: user.uuid,
        email: user.email,
        username: `${user?.person?.lastName} ${user?.person?.firstName}`,
      })
    } else {
      Sentry.configureScope(scope => scope.setUser(null))
    }
  }

  static initSegment (): AnalyticsBrowser|null {
    return AnalyticsBrowser.load(
      { writeKey: config(ENV.SEGMENT_WRITE_KEY) },
    )
  }
}
