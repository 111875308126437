import { StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

const styles = StyleSheet.create({
  ribContainer: {
    margin: 20,
    backgroundColor: Colors.white,
    borderRadius: 15,
    marginBottom: 30,
    padding: 16,
  },
  label: {
    ...Typo.itemTitle,
    fontSize: 14,
    marginBottom: 10,
  },
  info: {
    ...Typo.bigTitle,
    fontSize: 16,
  },
  ribInfoColumn: {
    marginRight: 15,
  },
  ribInfoBank: {
    ...Typo.bigTitle,
    fontSize: 14,
  },
  ribInfoGuichet: {
    ...Typo.bigTitle,
    fontSize: 16,
    textAlign: 'center',

  },
  ribInfoId: {
    ...Typo.bigTitle,
    fontSize: 16,
    textAlign: 'center',

  },
  ribInfoKey: {
    ...Typo.bigTitle,
    fontSize: 16,
    textAlign: 'center',

  },
  infoSubtitleBank: {
    ...Typo.itemTitle,
    fontSize: 14,
    marginBottom: 10,
  },
  infoSubtitleGuichet: {
    ...Typo.itemTitle,
    fontSize: 14,
    marginBottom: 10,
  },
  infoSubtitleId: {
    ...Typo.itemTitle,
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 10,
  },
  infoSubtitleKey: {
    ...Typo.itemTitle,
    fontSize: 14,
    marginBottom: 10,
  },
  horizontalRow: {
    flexDirection: 'row',
  },
  row: {
    marginBottom: 15,
  },
  buttonContainer: {
    paddingHorizontal: 20,
    paddingBottom: 10,
  },
  icon: {

  },
  ribInfoColumnRowContainer: {
    flexDirection: 'row',
  },
  iconContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    display: 'flex',
    marginRight: 0,
    paddingBottom: 6,
  },
  bottomInfo: {
    display: 'flex',
  },
  withIcon: {
    justifyContent: 'space-between',
  },
  bottomInfoIcon: {
    minWidth: '50%',
  },
})

export default styles
