import React, { FunctionComponent } from 'react'
import { View, Text, TextStyle } from 'react-native'

import { Colors } from '@neo-commons/styles'

import styles from './styles'

interface ProgressBarProps {
  /** Minimum value */
  min?: number,
  /** Maximum value */
  max: number,
  /** Value */
  value: number,
  /** Left label */
  leftLabel?: string,
  /** Right label */
  rightLabel?: string,
  /** Progress color */
  color?: string,
  /** Progress background color */
  backgroundColor?: string,
  /** Left Label style */
  leftLabelStyle?: TextStyle,
  /** Right Label style */
  rightLabelStyle?: TextStyle,
  /** Unit */
  unit?: string
}

export const ProgressBarComponent: FunctionComponent<ProgressBarProps> = ({
  min = 0,
  max,
  value,
  color,
  backgroundColor,
  leftLabel,
  leftLabelStyle,
  rightLabel,
  rightLabelStyle,
  unit,
}) => {
  const customColor = color ?? Colors.primary
  const customBackgroundColor = backgroundColor ?? Colors.background

  const clippedValue = Math.max(min, Math.min(value, max))
  const percent = (clippedValue - min) / (max - min)

  const customLeftLabel = leftLabel
  const customRightLabel = rightLabel

  return (

    <View style={styles.row}>
      <Text style={[styles.leftLabel, leftLabelStyle, { color: customColor }]}>
        {customLeftLabel}{unit}
      </Text>
      <View style={[styles.progressBarContainer]}>
        <View style={[styles.progressBarBackground, { backgroundColor: customBackgroundColor }]} />
        <View style={[styles.progressBarForeground, { width: `${percent * 100}%`, backgroundColor: customColor }]} />
      </View>
      <Text style={[styles.rightLabel, rightLabelStyle]}>
        {customRightLabel}{unit}
      </Text>
    </View>
  )
}

export const ProgressBar = ProgressBarComponent
