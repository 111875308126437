import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { POSReturnStep, POSReturnSteps, StepComponentProps } from '@neo-commons/policies'
import {
  PosActions,
  PosSelectors,
  PreparedProvisioning,
  PreparePOSReturn,
  ProvisioningSelectors,
} from '@neo-commons/store'
import { ProvisioningStatus } from '@neo-commons/libraries'

import { Loader } from '@neo-web/componentsOld'

type POSReturnProps = StepComponentProps<POSReturnSteps[POSReturnStep.Process]>

export const Process: React.FC<POSReturnProps> = ({ nextStep }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const preparedPOSReturn: PreparePOSReturn = useSelector(PosSelectors.prepareReturn)
  const preparedProvisioning: PreparedProvisioning = useSelector(ProvisioningSelectors.prepare)

  const [tryNumber, setTryNumber] = useState(0)
  let tryAgain

  const listen = async () => {
    if (tryNumber >= 8) {
      nextStep()
    }

    try {
      await dispatch(PosActions.getReturnFees())
      if (preparedPOSReturn.missingBalance === 0) {
        nextStep()
      } else {
        tryAgain = setTimeout(() => setTryNumber(tryNumber + 1), 5000)
      }
    } catch (error) {
      if (error.status === 402 && preparedProvisioning.provisioningStatus === ProvisioningStatus.SUCCESS) {
        tryAgain = setTimeout(() => setTryNumber(tryNumber + 1), 5000)
      } else {
        nextStep()
      }
    }
  }

  useEffect(() => {
    if (preparedPOSReturn.missingBalance === 0) {
      nextStep()
    } else {
      (async () => await listen())()
    }
  }, [tryNumber])

  useEffect(() => {
    return () => clearTimeout(tryAgain)
  }, [])

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
      <Loader isShow text={t('app:pages:services:pos:order:processing')} />
    </div>
  )
}
