import React, { useEffect, useState } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Tunnel } from 'UI/Components/Layout/Tunnel'
import { Routes } from 'Routes/Routes'
import { StateTypes, store } from 'Store'

import { Dispatch, SecretQuestionActions, SignupActions, SignupTypes, SubscriptionActions } from '@neo-commons/store'
import { registerPolicy, RegisterStep } from '@neo-commons/policies'
import { useWizardPolicy } from '@neo-commons/hooks'

import { Loader } from '@neo-web/componentsOld'

import { Contact } from './RegisterStep1/Contact'
import { Sms } from './RegisterStep1/Sms'
import { EmailVerify } from './RegisterStep2/EmailVerify'
import { Code } from './RegisterStep3/Code'
import { CodeConfirm } from './RegisterStep3/CodeConfirm'
import { CodeValidate } from './RegisterStep3/CodeValidate'
import { RegisterStep5 } from './RegisterStep5/RegisterStep5'
import { RegisterRecoveryModal } from './RegisterRecoveryModal'
import { SecretQuestion } from './RegisterStep4/SecretQuestion'

import './Register.scss'

export const Register: React.FC = () => {
  const dispatch = useDispatch<Dispatch>()
  const history = useHistory()
  const [keepActualState, setKeepActualState] = useState(false)

  const isRegisterLoading = useSelector((state: StateTypes) => state.signup?.ui.registerLoading)
  const isSubscriptionLoading = useSelector((state: StateTypes) => state.subscription?.loading)
  const [showRecoveryModal, setShowRecoveryModal] = useState(false)
  const isOfflineRegistration = useSelector((state: StateTypes) => state.signup?.profile?.isOfflineRegistration)

  const { currentStep, steps: wizardSteps, load, goBack } = useWizardPolicy(store, registerPolicy, {
    [RegisterStep.Contact]: ({ nextStep }) =>
      <Contact
        nextStep={(params) => {
          setKeepActualState(true)
          return nextStep(params)
        }}
      />,
    [RegisterStep.Sms]: Sms,
    [RegisterStep.EmailVerify]: EmailVerify,
    [RegisterStep.Code]: Code,
    [RegisterStep.CodeConfirm]: CodeConfirm,
    [RegisterStep.CodeValidate]: CodeValidate,
    [RegisterStep.SecretQuestion]: SecretQuestion,
    [RegisterStep.Complete]: RegisterStep5,
  }, {
    onDone: async () => {
      try {
        dispatch(SubscriptionActions.list()).then(() => {
          dispatch({ type: SignupTypes.SIGN_UP_COMPLETED })
          dispatch(SignupActions.reset())
          history.replace(Routes.Subscription.index.path)
        })
      } catch (error) {
        history.replace(Routes.Error['500'].path)
      }
    },
  })

  useEffect(() => {
    dispatch(SecretQuestionActions.getSecretQuestionList())
  }, [dispatch])

  useEffect(() => {
    const isFirstStep = wizardSteps[0].path === currentStep?.path
    if (!isFirstStep && load && !keepActualState) {
      setShowRecoveryModal(true)
    } else if (isFirstStep && !keepActualState && !isOfflineRegistration) {
      dispatch(SignupActions.reset())
    }
  }, [currentStep])

  const onBack = () => {
    if (currentStep.key === RegisterStep.Contact) {
      dispatch(SignupActions.reset())
      history.push(Routes.SignIn.index.path)
    } else {
      goBack()
    }
  }

  return (
    <Tunnel
      footer
      onBack={currentStep?.meta?.canGoBack ? onBack : undefined}
      component={(
        <>
          <Loader isShow={isRegisterLoading || isSubscriptionLoading} />
          <RegisterRecoveryModal
            next={() => {
              setShowRecoveryModal(false)
              setKeepActualState(true)
            }}
            show={showRecoveryModal}
          />
          {(!showRecoveryModal && currentStep?.path) &&
            <Redirect
              to={{
                pathname: currentStep?.path,
              }}
            />}
          {wizardSteps.map((value) =>
            <Route
              key={value.navigationKey}
              exact
              path={value.path}
              component={value.component}
            />
          )}
        </>
      )}
    />
  )
}
