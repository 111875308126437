import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from 'react-native'

import { EditItemCard, EditItemCardSkin } from '@neo-commons/components'
import { PosProductData, POSUtils } from '@neo-commons/libraries'

import { ProductDto } from '@afone/neo-core-client/dist/models'

interface PosProductSummaryProps {
  products: ProductDto[],
  modifyOffer: () => void,
}

export const SummaryProductsList: React.FC<PosProductSummaryProps> = (props: PosProductSummaryProps) => {
  const { t } = useTranslation()
  const { products, modifyOffer } = props

  return (
    <>
      {products.map((product, index) => {
        const lastIndex = products.length === index + 1
        const image = (product.data as PosProductData).image
        const deposit = POSUtils.getPosProductDescription(product).header?.[0]
        const commission = POSUtils.getPosProductDescription(product).header?.[1]

        return (
          <EditItemCard
            key={index}
            label={index === 0 && t('app:pages:services:pos:order:cardTitle:offer').toUpperCase()}
            skin={EditItemCardSkin.DELIVERY}
            payload={{
              title: `${POSUtils.getProductQuantity(product)} x ${product?.name}`,
              subtitles: [commission?.description],
              amount: `${deposit?.title} ${deposit?.description}`,
              image: image && (
                <Image
                  source={{ uri: image }}
                  style={{ height: 84, width: 84, marginHorizontal: 'auto', marginVertical: 10, borderRadius: 15 }}
                />
              ),
            }}
            buttonLabel={t('app:global:edit')}
            onEditPress={lastIndex && modifyOffer}
          />
        )
      })}
    </>
  )
}
