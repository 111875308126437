import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { StepComponentProps } from '@neo-commons/policies'
import { POSOrderStep, POSOrderSteps } from '@neo-commons/policies/src/POS/Order'
import { Button, ButtonSkin, Input } from '@neo-commons/components'
import {
  BankAccountActions,
  BankAccountSelectors,
  PosActions,
  PosSelectors,
  PreparePOSOrder,
} from '@neo-commons/store'
import { Validators } from '@neo-commons/libraries'

import { AccountDto, AccountTypeDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, PageContent } from '@neo-web/componentsOld'

type POSOrderRenameAccountProps = StepComponentProps<POSOrderSteps[POSOrderStep.RenameAccount]>

export const RenamePosAccount: React.FC<POSOrderRenameAccountProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const preparedPOSOrder: PreparePOSOrder = useSelector(PosSelectors.prepare)
  const createdAccount: AccountDto =
    useSelector(BankAccountSelectors.byId(preparedPOSOrder?.associatedBankAccount))

  const [bankAccountName, setBankAccountName] = useState(createdAccount?.name ?? '')
  const [isAccountNameValid, setIsAccountNameValid] = useState(false)

  return (
    <PageContent
      justifyContent={JustifyContent.BETWEEN}
      title={t('app:pages:services:pos:order:nameAccount:title')}
      description={t('app:pages:services:pos:order:nameAccount:message')}
      footer={(
        <Button
          title={t('app:global:confirm')}
          skin={ButtonSkin.PRIMARY}
          disabled={!isAccountNameValid}
          onPress={() =>
            (async () => {
              try {
                createdAccount.name = bankAccountName
                await dispatch(BankAccountActions.update(createdAccount.uuid, createdAccount, false))
                await dispatch(BankAccountActions.list({
                  bankAccountType: AccountTypeDto.POS,
                  parentUuid: createdAccount.parentUuid,
                }))
                const newPosIds = preparedPOSOrder?.newPosIds
                await dispatch(PosActions.reset())
                history.push(Routes.Services.reassignPos.path, newPosIds)
              } catch (e) {}
            })()}
        />
      )}
    >
      <Input
        placeholder=''
        value={bankAccountName}
        autoCorrect={false}
        autoFocus
        isRequired
        validators={[Validators.isNotEmpty, Validators.noEmojis]}
        onValidationChecked={value => setIsAccountNameValid(value.isValid)}
        onChangeText={(accountName: string) => {
          setBankAccountName(accountName)
        }}
        maxLength={50}
      />
    </PageContent>
  )
}
