import { map } from 'lodash'

import { posLostPolicy } from '@neo-commons/policies/src/POS/Lost'

export const PosLostRoutes = {
  index: {
    path: posLostPolicy.basePath,
    name: 'neo-commons:pages:services:pos:lost:title',
    available: true,
  },
  ...map(posLostPolicy.steps, ({ path, meta }) => ({
    path,
    name: meta && meta.headerTitle,
    available: true,
  })),
}
