import { AnyAction, Dispatch, Reducer } from 'redux'
import produce from 'immer'

import { RoutesUtils } from '../../Utils'
import { Routes } from '../../Routes/Routes'

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types
\* %%%%%%%%%%%%%%%%%% */

/* -------------- Types -------------- */
const GET_ROUTES_AVAILABLE_REQUEST = 'root/GET_ROUTES_AVAILABLE_REQUEST'
const GET_ROUTES_AVAILABLE_SUCCESS = 'root/GET_ROUTES_AVAILABLE_SUCCESS'
const GET_ROUTES_AVAILABLE_FAILED = 'root/GET_ROUTES_AVAILABLE_FAILED'

const ADD_ROUTES_AVAILABLE_REQUEST = 'root/ADD_ROUTES_AVAILABLE_REQUEST'
const ADD_ROUTES_AVAILABLE_SUCCESS = 'root/ADD_ROUTES_AVAILABLE_SUCCESS'
const ADD_ROUTES_AVAILABLE_FAILED = 'root/ADD_ROUTES_AVAILABLE_FAILED'

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators
\* %%%%%%%%%%%%%%%%%% */
const getRoutesAvailable = function () {
  return async (dispatch: Dispatch, getState: () => any) => {
    let routesAvailable = getState()?.route?.available ?? []
    dispatch({ type: GET_ROUTES_AVAILABLE_REQUEST })
    try {
      if (!routesAvailable.length) {
        routesAvailable = RoutesUtils.getAllRoutes(Routes, { isAvailable: true, isFullRouteObject: true })
      }

      dispatch({ type: GET_ROUTES_AVAILABLE_SUCCESS, routesAvailable })
    } catch (e) {
      dispatch({ type: GET_ROUTES_AVAILABLE_FAILED })
      throw new Error(e)
    }
  }
}

const unlockRoute = function (route: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ADD_ROUTES_AVAILABLE_REQUEST })
    try {
      dispatch({ type: ADD_ROUTES_AVAILABLE_SUCCESS, route })
    } catch (e) {
      dispatch({ type: ADD_ROUTES_AVAILABLE_FAILED })
      throw new Error(e)
    }
  }
}

export const RoutesActions = {
  getRoutesAvailable,
  unlockRoute,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export interface RoutesState {
  route: {
    available: any[],
  },
  ui: {
    loading: boolean;
    loaded: boolean;
  }
}

const initialState: RoutesState = {
  route: {
    available: [],
  },
  ui: {
    loading: false,
    loaded: false,
  },
}

export const routes: Reducer = (
  state: RoutesState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case GET_ROUTES_AVAILABLE_SUCCESS:
      return produce(state, newState => {
        newState.route.available = action.routesAvailable
        newState.ui.loading = false
        newState.ui.loaded = true
      })
    case ADD_ROUTES_AVAILABLE_SUCCESS:
      return produce(state, newState => {
        newState.route.available = [...state.route.available, action.route]
        newState.ui.loading = false
      })
    case GET_ROUTES_AVAILABLE_FAILED:
    case ADD_ROUTES_AVAILABLE_FAILED:
      return produce(state, newState => { newState.ui.loading = false })
    case GET_ROUTES_AVAILABLE_REQUEST:
    case ADD_ROUTES_AVAILABLE_REQUEST:
      return produce(state, newState => { newState.ui.loading = true })
    default:
      return state
  }
}
