
import { AnyAction, Reducer } from 'redux'
import i18next from 'i18next'

import { AlertType, Dispatch, State } from '@neo-commons/store'
import { NeobankApi } from '@neo-commons/services'
import { DeviceMethod } from '@neo-commons/components'

import { ScaAuthenticationErrorTypeDto, ValidateAuthenticationRequestByPasswordDto } from '@afone/neo-core-client/dist/models'

import { ENV, config } from '../../Config/EnvConfig'
import { StateTypes } from '../index'

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types
\* %%%%%%%%%%%%%%%%%% */

/* -------------- Types -------------- */
const SCA_REQUIRED = 'sca/SCA_REQUIRED'
const SCA_SUCCESS = 'sca/SCA_SUCCESS'
const SCA_FAILURE = 'sca/SCA_FAILURE'
const SCA_CANCELLED = 'sca/SCA_CANCELLED'

const VALIDATE_PASSWORD_REQUEST = 'sca/VALIDATE_PASSWORD_REQUEST'
const VALIDATE_PASSWORD_SUCCESS = 'sca/VALIDATE_PASSWORD_SUCCESS'
const VALIDATE_PASSWORD_FAILURE = 'sca/VALIDATE_PASSWORD_FAILURE'

const VALIDATE_OTP_REQUEST = 'sca/VALIDATE_OTP_REQUEST'
const VALIDATE_OTP_SUCCESS = 'sca/VALIDATE_OTP_SUCCESS'
const VALIDATE_OTP_FAILURE = 'sca/VALIDATE_OTP_FAILURE'

const DEVICE_METHOD_REQUEST = 'sca/DEVICE_METHOD_REQUEST'
const DEVICE_METHOD_SUCCESS = 'sca/DEVICE_METHOD_SUCCESS'
const DEVICE_METHOD_FAILURE = 'sca/DEVICE_METHOD_FAILURE'
const DEVICE_METHOD_CANCEL = 'sca/DEVICE_METHOD_CANCEL'

export const ScaTypes = {
  SCA_REQUIRED,
  SCA_SUCCESS,
  SCA_FAILURE,
  SCA_CANCELLED,

  VALIDATE_PASSWORD_REQUEST,
  VALIDATE_PASSWORD_SUCCESS,
  VALIDATE_PASSWORD_FAILURE,

  VALIDATE_OTP_REQUEST,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAILURE,

  DEVICE_METHOD_REQUEST,
  DEVICE_METHOD_SUCCESS,
  DEVICE_METHOD_FAILURE,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators
\* %%%%%%%%%%%%%%%%%% */

const errorKeyI18n = 'errors:internalTechnicalIssue'

const validateAuthenticationRequestByPassword = (
  authenticationRequestUuid,
  validateAuthenticationRequestByPassword: ValidateAuthenticationRequestByPasswordDto,
  disableRedirect
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: VALIDATE_PASSWORD_REQUEST })

    try {
      await NeobankApi.getInstance().authenticationRequestApi
        .validateAuthenticationRequestByPassword(
          authenticationRequestUuid,
          validateAuthenticationRequestByPassword
        )

      dispatch({ type: VALIDATE_PASSWORD_SUCCESS, disableRedirect })
    } catch (e) {
      const errorMessage = e.message ?? i18next.t(errorKeyI18n)
      dispatch({ type: VALIDATE_PASSWORD_FAILURE, errorMessage })
      throw e
    }
  }
}

const validateOtp = ({ otpUuid, code, method }) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: VALIDATE_OTP_REQUEST })

    try {
      switch (method) {
        case ScaAuthenticationErrorTypeDto.OTP_PHONE:
          await NeobankApi.getInstance().otpPhoneApi.updateOtpPhoneByUuid(otpUuid, { code })
          break

        case ScaAuthenticationErrorTypeDto.OTP_EMAIL:
          await NeobankApi.getInstance().otpEmailApi.updateOtpEmailByUuid(otpUuid, { code })
          break
      }

      dispatch({
        type: VALIDATE_OTP_SUCCESS,
      })
      dispatch({ type: SCA_SUCCESS })
    } catch (e) {
      const errorMessage = e.message ?? i18next.t(errorKeyI18n)
      dispatch({ type: VALIDATE_OTP_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const processWallet = ({ authenticationRequestUuid }) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const userUuid = getState().signIn.data.id

    const SSE = new EventSource(`${config(ENV.SSE_URL)}?id=${userUuid}.${authenticationRequestUuid}&ttl=3600`)
    SSE.onmessage = function () {
      dispatch({ type: SCA_SUCCESS })
    }
  }
}

const process = ({ authenticationRequestUuid, steps, eventCode }) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SCA_REQUIRED, authenticationRequestUuid, steps, eventCode })
  }
}

const requestDeviceMethod = (
  method,
  isResend = false,
) => {
  return async (dispatch: Dispatch, getState: () => StateTypes) => {
    const { sca } = getState()

    dispatch({ type: DEVICE_METHOD_REQUEST })

    const authenticationRequestUuid = sca.data.authenticationRequestUuid
    try {
      const getUpdateDeviceResponse =
        await NeobankApi.getInstance().authenticationRequestApi.sendAuthenticationRequest(
          authenticationRequestUuid, { type: method })

      dispatch({
        type: DEVICE_METHOD_SUCCESS,
        data: { ...getUpdateDeviceResponse.data, scaType: method },
        alertType: AlertType.SUCCESS,
        errorMessage: isResend ? i18next.t('neo-commons:otpCode:codeResend') : '',

      })
      return getUpdateDeviceResponse.data
    } catch (e) {
      const errorMessage = e.message ?? i18next.t(errorKeyI18n)
      dispatch({ type: DEVICE_METHOD_FAILURE, errorMessage })
      dispatch({ type: SCA_FAILURE })
      throw new Error(errorMessage)
    }
  }
}
const validatePreviewMethod = (method: DeviceMethod) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DEVICE_METHOD_SUCCESS,
      data: { scaType: method.type, isPreview: method.isPreview },
    })
  }
}

const cancelMethod = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DEVICE_METHOD_CANCEL,
    })
  }
}

const cancel = () => {
  // TODO: appeler cet action creator lorsque la popup du SCA est fermé sans
  // avoir réalise d'authentification
  return async (dispatch: Dispatch) => {
    dispatch({ type: SCA_CANCELLED })
  }
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export const ScaActions = {
  validateAuthenticationRequestByPassword,
  process,
  processWallet,
  validateOtp,
  requestDeviceMethod,
  validatePreviewMethod,
  cancelMethod,
  cancel,
}

export interface ScaState {
  required: boolean;
  succeed: boolean;
  cancelled: boolean;
  loading: boolean;
  data: {
    authenticationRequestUuid: string,
    steps: any[],
    prepare: {
      scaStep: number,
      pinCode: boolean,
      showModal: boolean,
      scaType: string,
      otpUuid: '',
      isPreview?: boolean,
      endDate: undefined,
      date: undefined,
      verified: boolean,
      deviceNames: any[]
      eventCode: string
    },
  } | null;
}

const initialState: ScaState = {
  succeed: null,
  loading: false,
  cancelled: null,
  required: false,
  data: null,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const sca: Reducer = (
  state: ScaState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case SCA_REQUIRED:
      return {
        ...state,
        required: true,
        cancelled: null,
        succeed: null,
        data: {
          authenticationRequestUuid: action.authenticationRequestUuid,
          steps: action.steps,
          prepare: {
            showModal: true,
            scaStep: 0,
            eventCode: action.eventCode,
          },
        },
      }

    case VALIDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          prepare: {
            showModal: true,
            pinCode: !action.disableRedirect,
            scaStep: ++state.data.prepare.scaStep,
          },
        },
      }

    case SCA_SUCCESS:
      return {
        ...state,
        succeed: true,
        cancelled: null,
        required: false,
        data: {
          ...state.data,
          prepare: {
            ...state.data.prepare,
            showModal: false,
            ...action.data,

          },
        },
      }

    case DEVICE_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case DEVICE_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          prepare: {
            ...state.data.prepare,
            showModal: true,
            pinCode: true,
            ...action.data,
          },
        },
      }

    case DEVICE_METHOD_FAILURE:
      return {
        ...initialState,
        loading: false,
      }

    case SCA_CANCELLED:
      return {
        ...state,
        succeed: false,
        cancelled: true,
        data: {
          prepare: {
            showModal: false,
          },
        },
      }

    case SCA_FAILURE:
      return {
        ...state,
        succeed: false,
        data: {
          prepare: {
            showModal: false,
          },
        },
      }

    case VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          prepare: {
            verified: true,
          },
        },
      }

    case VALIDATE_OTP_FAILURE:
    case DEVICE_METHOD_CANCEL:
      return {
        ...state,
        data: {
          ...state.data,
          prepare: {
            ...state.data.prepare,
            verified: false,
            scaStep: state.data.prepare.scaStep,
            showModal: true,
            scaType: undefined,
          },
        },
      }

    default:
      return state
  }
}
