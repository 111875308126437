import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import { BankAccountSelectors, PosActions, PosTypes } from '@neo-commons/store'
import { PosSoftwareCode, POSUtils, Validators } from '@neo-commons/libraries'
import { Button, ButtonSkin, Input } from '@neo-commons/components'

import { AccountDto, PosDto, PosUpdateDto } from '@afone/neo-core-client/dist/models'

import {
  CardDetails,
  CardDetailsItem,
  JustifyContent,
  LazyLoadingContainer,
  Modal,
  PageContent,
  PageDetails,
} from '@neo-web/componentsOld'

export const DetailPos: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const param: any = useParams()

  const selectedPos: PosDto = useSelector((state: StateTypes) => state?.pos?.data?.[param.id])
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts())
  const [posName, setPosName] = useState(selectedPos?.name)
  const [showEditModal, setShowEditModal] = useState(false)

  useEffect(() => {
    if (!selectedPos) {
      (async () => {
        try {
          await dispatch(PosActions.list(bankAccounts))
        } catch (error) {}
      })()
    }
  }, [])

  useEffect(() => {
    if (!posName) {
      setPosName(selectedPos?.name)
    }
  }, [selectedPos])

  const listItems: CardDetailsItem[] = [
    {
      label: t('app:pages:services:pos:detail:posName'),
      value: selectedPos?.name ?? t('app:global:field:empty'),
      action: () => setShowEditModal(true),
    }, {
      label: t('app:pages:services:pos:detail:posSerialNumber'),
      value: selectedPos?.serialNumber,
    }, {
      label: t('app:pages:services:pos:detail:posModel'),
      value: selectedPos?.model,
    }, {
      label: t('app:pages:services:pos:detail:numComCBMV'),
      value: selectedPos?.posSoftwares &&
        POSUtils.getContractNumber(selectedPos.posSoftwares, PosSoftwareCode.CB_VISA_MC),
    }, {
      label: t('app:pages:services:pos:detail:numComSC'),
      value: selectedPos?.posSoftwares &&
        POSUtils.getContractNumber(selectedPos.posSoftwares, PosSoftwareCode.SANS_CONTACT),
    }, {
      label: t('app:pages:services:pos:detail:bankCode'),
      value: '16338',
    },
  ]

  const updatePosName = () => {
    (async () => {
      try {
        const update: PosUpdateDto = { name: posName }
        await dispatch(PosActions.updatePos({ pos: selectedPos, update }))
        setShowEditModal(false)
      } catch (error) {}
    })()
  }

  return (
    <PageDetails
      title={t('app:pages:services:pos:detail:title')}
      onBack={() => history.goBack()}
      onClose={() => history.push(Routes.Services.pos.path)}
      component={
        <LazyLoadingContainer events={[PosTypes.LIST_POS_REQUEST]}>
          {selectedPos && (
            <PageContent justifyContent={JustifyContent.START}>
              <img src={selectedPos?.urlImage} style={{ maxWidth: '100%', borderRadius: 10 }} />
              <CardDetails list={listItems} />
            </PageContent>
          )}
          <Modal
            open={showEditModal}
            displayCross
            onClose={() => setShowEditModal(false)}
            title={t('app:pages:services:pos:detail:rename')}
          >
            <Input
              placeholder={t('app:pages:services:pos:detail:posName')}
              value={posName}
              maxLength={50}
              isRequired
              validators={[Validators.isNotEmpty]}
              onChangeText={(text) => setPosName(text)}
            />
            <div>
              <Button
                disabled={posName === ''}
                skin={ButtonSkin.PRIMARY}
                title={t('app:global:validate')}
                onPress={() => updatePosName()}
                containerStyle={{ marginTop: 30 }}
              />
            </div>
          </Modal>
        </LazyLoadingContainer>
      }
    />
  )
}
