export enum ProcessType{
  PAYMENT = 'PAYMENT',
  PROVISIONING = 'PROVISIONING'
}

export enum ProvisioningStatus{
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}
