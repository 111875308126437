import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import dayjs from 'dayjs'
import { Routes } from 'Routes/Routes'

import { Card, CardSkin, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { NumbersUtils, OfferUtils } from '@neo-commons/libraries'
import { AdvanceActions, AdvanceSelectors, Dispatch, OfferSelectors, OrdersActions } from '@neo-commons/store'

import { AdvanceDto, AdvanceDtoStatusEnum, ClientTypeDto, OfferTypeDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

import '../AdvanceService.scss'

export const AdvanceHistory: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()
  const { t } = useTranslation()

  const offers = useSelector(OfferSelectors
    .listByClientType(ClientTypeDto.INDIVIDUAL, OfferTypeDto.SOCLE))
    .filter(offer => offer.authorized)
  const advanceOffer = offers.find(offer => OfferUtils.isOfferAdvance(offer))

  // eslint-disable-next-line dot-notation
  const advanceMax = advanceOffer?.data['advance']
  const advances: AdvanceDto[] = useSelector(AdvanceSelectors.list)
  // list every month present in advances by createdDate
  const months: string[] = Array.from(new Set(advances.map(advance => dayjs(advance.createdDate).format('MMMM YYYY'))))

  useEffect(() => {
    if (!advances || advances.length === 0) {
      dispatch(OrdersActions.listOrders())
      dispatch(AdvanceActions.getAdvanceData())
    }
  }, [])

  return (
    <PageDetails
      onBack={() => history.goBack()}
      onClose={() => history.push(Routes.Services.advance.path)}
      title={t('neo-commons:advance:history:title')}
      component={
        <PageContent
          justifyContent={JustifyContent.START}
        >
          {months.map(month => {
            return (
              <div key={month}>
                <div style={{ textTransform: 'uppercase', marginBottom: 16 }}>
                  <Typography typeface='body2'>{month}</Typography>
                </div>
                {advances
                  .filter(advance => advance.status !== AdvanceDtoStatusEnum.ACTIVE)
                  .filter(advance => dayjs(advance.createdDate).format('MMMM YYYY') === month)
                  .map((advance, index) => {
                    const activationDate = advance && dayjs(advance.createdDate).format('DD/MM/YYYY [à] HH:mm')
                    const reimbursements = advance.reimbursements
                    return (
                      <Card key={'advance-' + index} skin={CardSkin.DEFAULT} withShadow>
                        <div className='AdvanceService_container'>
                          <div className='AdvanceService_flexColumn'>
                            <Typography typeface='h4'>{t('neo-commons:advance:history:request')}</Typography>
                            <Typography typeface='body2'>
                              {activationDate}
                            </Typography>
                          </div>
                          <div>
                            <Typography typeface='h4' style={{ color: Colors.primary }}>
                              {NumbersUtils.displayPriceForHuman(advanceMax || 100)}
                            </Typography>
                          </div>
                        </div>
                        {reimbursements && reimbursements.length > 0 &&
                          reimbursements.map(reimbursement => {
                            return (
                              <>
                                <div className='AdvanceService_containerText'>
                                  <Typography typeface='h4'>{t('neo-commons:advance:history:refund')}</Typography>
                                  <Typography typeface='h4' style={{ color: Colors.gray3 }}>
                                    {NumbersUtils.displayPriceForHuman(-reimbursement.amount ?? -reimbursement.amounts.amount)}
                                  </Typography>
                                </div>
                                <div key={reimbursement.id} className='AdvanceService_flexColumn'>
                                  <Typography typeface='body2'>
                                    {dayjs(reimbursement.transactionDate).format('DD/MM/YYYY')}, prélèvement réussi
                                  </Typography>
                                </div>
                              </>
                            )
                          })}
                      </Card>
                    )
                  })}
              </div>
            )
          })}
        </PageContent>
      }
    />
  )
}
