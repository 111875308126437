import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StateTypes } from '@neo-customer-front/applications/neo-web/src/Store'

import { POSReturnStep, POSReturnSteps, StepComponentProps } from '@neo-commons/policies'
import { ClientSelectors, ConfigSelectors, PosSelectors, PosTypes, PreparePOSReturn } from '@neo-commons/store'
import { ClientUtils, CountryUtils } from '@neo-commons/libraries'

import { AddressDto, AddressTypeDto, ClientDto, CountryDto } from '@afone/neo-core-client/dist/models'

import {
  AddressButtonWhisper,
  AddressDataType,
  JustifyContent,
  LazyLoadingContainer,
  PageContent,
} from '@neo-web/componentsOld'
import { UseAddressForm } from '@neo-web/hooks'

type POSReturnProps = StepComponentProps<POSReturnSteps[POSReturnStep.AddressForm]>

export const AddressForm: React.FC<POSReturnProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const preparedPOSReturn: PreparePOSReturn = useSelector(PosSelectors.prepareReturn)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const fiscalAddresses = ClientUtils.getClientAddresses(client, AddressTypeDto.FISCAL)
  const deliveryAddresses = ClientUtils.getClientAddresses(client, AddressTypeDto.DELIVERY)
  const userData = useSelector((state: StateTypes) => state.user.data)
  const allCountries: CountryDto[] = useSelector(ConfigSelectors.getCurrentConfig)?.countries ?? []
  const countries = CountryUtils.filterByAddressAuthorized(allCountries)

  const values = {
    countryData: null as CountryDto,
    zipCode: '',
    cityName: '',
    address: '',
    additionalAddress: '',
  }
  if (!deliveryAddresses.length) {
    const address: AddressDto = fiscalAddresses[0]
    values.countryData = CountryUtils.getCountryFromCode(allCountries, address?.countryCode) ?? null as CountryDto
    values.zipCode = address?.postalCode
    values.cityName = address?.city
    values.address = address?.line1
    values.additionalAddress = address?.line2
  }

  const [addressData, renderAddressForm] = UseAddressForm({
    countries,
    userData,
    values,
  })

  const checkFormIsValid = (): boolean => {
    const { address, zipCode, cityName, countryData } = addressData as AddressDataType
    return Boolean(address && zipCode && cityName && countryData)
  }

  const validAddress = (selectedAddress: AddressDataType) => {
    const { address, zipCode, cityName, countryData, additionalAddress } = selectedAddress
    nextStep({
      ...preparedPOSReturn,
      deliveryAddress: {
        ...preparedPOSReturn.deliveryAddress,
        line1: address,
        postalCode: zipCode,
        city: cityName,
        line2: additionalAddress,
        country: countryData.name,
        countryCode: countryData.isoCodeAlpha2,
      },
    })
  }

  return (
    <LazyLoadingContainer events={[PosTypes.PREPARE_POS_RETURN_REQUEST]}>
      <PageContent
        justifyContent={JustifyContent.BETWEEN}
        title={t('neo-commons:pages:services:pos:return:selectAddress:title')}
        footer={
          <AddressButtonWhisper
            originAddress={addressData}
            onSelect={validAddress}
            buttonDisabled={!checkFormIsValid()}
            buttonTitle={t('app:global:validate')}
          />
        }
      >
        {renderAddressForm}
      </PageContent>
    </LazyLoadingContainer>
  )
}
