import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Alert } from '@neo-web/componentsOld'

interface PosDepositAlertProps {
  onPress: () => void,
}

export const PosDepositAlert: React.FC<PosDepositAlertProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Alert
      icon={<Icon name='alert-circle' color={Colors.secondary} type={IconTypes.FEATHER} size={28} />}
      title={t('neo-commons:pages:services:pos:return:deposit:banner:title')}
      subTitle={t('neo-commons:pages:services:pos:return:deposit:banner:subtitle')}
      button={
        <Button
          small
          skin={ButtonSkin.PRIMARY}
          title={t('neo-commons:pages:services:pos:return:deposit:banner:button')}
          onPress={props.onPress}
        />
      }
    />
  )
}
