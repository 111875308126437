import React from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

import Lottie, { LottieProps } from 'lottie-react-web'

import { loader as loaderLottie } from '../../Resources/Animations'
import './Loader.scss'

interface LoaderProps {
  props?: LottieProps;
  area?: string;
  fadeIn?: boolean;
  loop?: boolean;
  isShow?: boolean;
  isNotComponent?: boolean;
  animation?: any;
  text?: string;
}

export const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { promiseInProgress } = usePromiseTracker({ area: props.area })

  return (
    <>
      {(promiseInProgress || props.isShow) &&
        <div className='Loader_container'>
          <Lottie
            {...props}
            options={{
              animationData: props.animation ?? loaderLottie,
              loop: props.loop ?? true,
            }}
          />
          {props.text && (
            <p>{props.text}</p>
          )}
        </div>}
    </>
  )
}
