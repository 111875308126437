import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { config, ENV } from 'Config/EnvConfig'
import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, EditItemCard, EditItemCardSkin, Typography } from '@neo-commons/components'
import {
  ExternalCardSelectors,
  PreparedProvisioning,
  ProvisioningActions,
  ProvisioningSelectors,
} from '@neo-commons/store'
import { CreditCardUtils, NumbersUtils, POSUtils, ProcessType, UrlUtils } from '@neo-commons/libraries'

import { CreditCardDto, TransactionDto, TransactionUpdateDto } from '@afone/neo-core-client/dist/models'

import { BrowserUtils } from '@neo-web/utils'
import { JustifyContent, Modal, PageContent } from '@neo-web/componentsOld'

import './Summary.scss'

interface SummaryProps {
  nextStep: () => void
  stepFailed: (message: string) => void
}

export const Summary: React.FC<SummaryProps> = (props: SummaryProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const preparedProvisioning: PreparedProvisioning = useSelector(ProvisioningSelectors.prepare)
  const externalCardSelected: CreditCardDto =
    useSelector(ExternalCardSelectors.byId(preparedProvisioning?.card?.saved?.uuid))
  const [fees, setFees] = useState(0)
  const [isCreditFree, setIsCreditFree] = useState(false)
  const [data3DS, setData3DS] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const outputFrame = useRef(null)

  useEffect(() => {
    if (preparedProvisioning?.card?.new?.tokenizedData?.tokenRef ||
      preparedProvisioning?.card?.saved?.uuid) {
      (async () => {
        try {
          const result = await dispatch(ProvisioningActions.getProvisioningFees()) as TransactionDto
          const isPosOrder = preparedProvisioning?.productCode === POSUtils.getPaymentProductCode()
          setFees(result?.price?.fees ?? 0)
          setIsCreditFree(result?.price.feesTotal === 0 && (isPosOrder || result?.price?.discount < 0))
        } catch (e) {}
      })()
    }
  }, [preparedProvisioning?.card?.new?.tokenizedData?.tokenRef, preparedProvisioning?.card?.saved?.uuid])

  const sendCredit = () => {
    (async () => {
      try {
        const callback3DSV2Url = config(ENV.THREEDS_TERM_URL)
        const browserInfo = BrowserUtils.getTransactionBrowserInformation()
        const result: any = await dispatch(ProvisioningActions.provisioningBankAccountWithCreditCard({
          xValidationOnly: 0,
          callback3DSV2Url,
          browserInfo,
        }))

        if (result?.actionUrl) {
          setData3DS(result)
          setOpenModal(true)
        } else {
          props.nextStep()
        }
      } catch (error) {
        props.stepFailed(error?.message ?? '')
      }
    })()
  }

  const onLoad = async () => {
    try {
      const url = outputFrame.current?.contentWindow?.location?.href

      if (!url.includes('start3ds') && url.includes('end3ds')) {
        try {
          const params = UrlUtils.deserialize(url)

          const transactionUpdateDto: TransactionUpdateDto = {
            md: params?.MD,
            pares: params?.PaRes,
            cres: params?.cres,
            saveCard: preparedProvisioning?.card?.new?.formData?.saveCard,
          }

          await dispatch(ProvisioningActions.validate3DSTransaction({
            bankAccountUuid: preparedProvisioning?.bankAccountUuid,
            transactionUuid: data3DS.transactionReference,
            transactionUpdateDto: transactionUpdateDto,
          }))

          setData3DS(null)
          setOpenModal(false)
          props.nextStep()
        } catch (e) {
          setData3DS(null)
          setOpenModal(false)
          await dispatch(ProvisioningActions.cancelTransaction())
          history.push(Routes.Synthesis.index.path)
        }
      }
    } catch (e) {}
  }

  return (
    <PageContent
      withBackground
      style={{ textAlign: 'center' }}
      justifyContent={JustifyContent.BETWEEN}
      title={t('app:pages:credit:step3:titleMessage')}
      footer={
        <>
          <div className='Summary_operationCost'>
            <Typography typeface='subtitleLight'>
              {t('app:pages:credit:step3:operationFeesTitle')}
            </Typography>
            {(fees > 0 || !isCreditFree) &&
              <Typography typeface='subtitleLight' style={isCreditFree && { textDecorationLine: 'line-through' }}>
                {NumbersUtils.displayPriceForHuman(fees)}
              </Typography>}
            {isCreditFree &&
              <Typography typeface='bold'>
                {t('neo-commons:account:freeFees')}
              </Typography>}
          </div>
          <Button
            title={t('app:global:confirm')} skin={ButtonSkin.PRIMARY}
            onPressOut={() => sendCredit()}
          />
        </>
      }
    >
      <div className='pb-s'>
        <EditItemCard
          skin={EditItemCardSkin.AMOUNT}
          payload={{
            amount: preparedProvisioning?.amount,
          }}
          hideButton={preparedProvisioning?.processType === ProcessType.PAYMENT}
          onEditPress={() => {
            history.push(Routes.Credit.amount.path)
          }}
        />
      </div>

      <div className='pb-s'>
        <EditItemCard
          skin={EditItemCardSkin.DEFAULT}
          payload={{
            title: t('app:pages:credit:step3:cardToDebit'),
            subtitles: [
              preparedProvisioning?.card?.new?.formData?.owner ?? externalCardSelected?.cardHolderName,
              t('app:global:card') + ' ' + (preparedProvisioning?.card?.new?.tokenizedData?.truncatedCardNumber ??
              CreditCardUtils.formatTruncatedPan(externalCardSelected?.truncatedPan ?? ''))],
          }}
          onEditPress={() => {
            history.push(Routes.Credit.chooseCard.path)
          }}
        />
      </div>

      <Modal
        open={openModal}
        size='full'
      >
        {data3DS && (
          <iframe
            title='3ds'
            className='Summary_3ds-frame'
            name='output-frame'
            id='output-frame'
            src={
              `${Routes.External.start3ds.path}?${UrlUtils.serialize(data3DS)}&TermUrl=${config(ENV.THREEDS_TERM_URL)}`
            }
            onLoad={onLoad}
            ref={outputFrame}
          />
        )}
      </Modal>
    </PageContent>
  )
}
