import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Routes } from '../../../../Routes/Routes'

import { BankAccountDetail } from './BankAccountDetail/BankAccountDetail'
import { BankAccountCreate } from './BankAccountCreate/BankAccountCreate'

export const BankAccount: React.FC = () => {
  return (
    <Switch>
      <Route exact path={Routes.BankAccount.create.path} component={BankAccountCreate} />
      <Route exact path={Routes.BankAccount.detail.path} component={BankAccountDetail} />
    </Switch>
  )
}
