import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentUploadStep, DocumentUploadSteps } from 'Policies'

import { StepComponentProps } from '@neo-commons/policies'

import { Loader, TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './DocumentUploadUploading.scss'

type DocumentUploadUploadingProps = StepComponentProps<DocumentUploadSteps[DocumentUploadStep.uploading]>
export const DocumentUploadUploading: React.FC<DocumentUploadUploadingProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  useEffect(() => {
    nextStep()
  }, [])

  return (
    <TunnelContent
      icon={
        <div className='icon'>
          <Loader loop animation={require('../../../Resources/Animations/Loader/lf20_a5zm6vuv.json')} isShow />
        </div>
      }
      subtitle={t('app:pages:documentUpload:uploading:subtitle')}
      justifyContent={TunnelJustifyContent.CENTER}
    />
  )
}
