import { CardActions } from '@neo-commons/store'

import { createWizardPolicy, invokeActionCreator } from '../../utils'

const machineId = 'opposition'

/*
 * Guards
 */

const isSelectedIdMissing = (context): boolean => {
  return context?.store.getState().card.list?.selectedId === undefined
}

const isWorkflowMissing = (context): boolean => {
  return context?.store.getState().card.preparedCardOpposition?.workflowCardOpposition === undefined
}

const isReasonMissing = (context): boolean => {
  return context?.store.getState().card.preparedCardOpposition?.reason === undefined
}

const isOppositionDateMissing = (context): boolean => {
  return context?.store.getState().card.preparedCardOpposition?.workflowCardOpposition?.oppositionDate === undefined
}

/*
 * Steps
 */

export enum OppositionStep {
  Intro = 'Intro',
  Reason = 'Reason',
  Confirm = 'Confirm',
  Summary = 'Summary',
}

export const oppositionSteps = {
  [OppositionStep.Intro]: {
    path: 'intro',
    nextStep: OppositionStep.Reason,
    skipIf: isSelectedIdMissing,
    skipTo: 'final',
    fulfill: invokeActionCreator(CardActions.prepareOpposition),
    meta: {
      screenTitle: 'app:pages:services:debitCard:opposition:intro:pageTitle',
    },
  },
  [OppositionStep.Reason]: {
    path: 'reason',
    nextStep: OppositionStep.Confirm,
    skipIf: isWorkflowMissing,
    skipTo: 'final',
    fulfill: invokeActionCreator(CardActions.prepareOpposition),
    meta: {
      screenTitle: 'app:pages:services:debitCard:opposition:intro:pageTitle',
    },
  },
  [OppositionStep.Confirm]: {
    path: 'confirm',
    nextStep: OppositionStep.Summary,
    skipIf: isReasonMissing,
    skipTo: 'final',
    fulfill: invokeActionCreator(CardActions.validateOpposition),
    meta: {
      screenTitle: 'app:pages:services:debitCard:opposition:intro:pageTitle',
    },
  },
  [OppositionStep.Summary]: {
    path: 'summary',
    nextStep: 'final',
    skipIf: isOppositionDateMissing,
    skipTo: 'final',
    fulfill: invokeActionCreator(CardActions.list),
    meta: {
      screenTitle: 'app:pages:services:debitCard:opposition:intro:pageTitle',
    },
  },
}

export type OppositionSteps = typeof oppositionSteps

/*
 * Policy initialization
 */
export const OppositionPolicy = createWizardPolicy({
  machineId: machineId,
  steps: oppositionSteps,
  initialStep: OppositionStep.Intro,
  basePath: '/services/debitCard/opposition',
})
