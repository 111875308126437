import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { Routes } from 'Routes/Routes'

import { POSReturnStep, POSReturnSteps, StepComponentProps } from '@neo-commons/policies'
import {
  Button,
  ButtonSkin,
  EditItemCard,
  EditItemCardSkin,
  Typography,
} from '@neo-commons/components'
import { OfferSelectors, PosActions, PosSelectors, PosTypes, PreparePOSReturn } from '@neo-commons/store'
import { NumbersUtils, OfferUtils, POSUtils } from '@neo-commons/libraries'

import { OfferDto, OfferTypeDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, LazyLoadingContainer, PageContent } from '@neo-web/componentsOld'

import { CloseAccountModal } from './CloseAccountModal'

type POSReturnProps = StepComponentProps<POSReturnSteps[POSReturnStep.Summary]>

export const Summary: React.FC<POSReturnProps> = ({ editStep, nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const preparedPOSReturn: PreparePOSReturn = useSelector(PosSelectors.prepareReturn)
  const paymentNeeded = preparedPOSReturn?.missingBalance > 0
  const nbPos = preparedPOSReturn?.pos?.length
  const offers: OfferDto[] = useSelector(OfferSelectors.listByType(OfferTypeDto.POS))

  const fees = useMemo(() => {
    return OfferUtils.getProductPosReturn(offers).prices[0].amount
  }, [offers])

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        await dispatch(PosActions.getReturnFees())
      } catch (_) {}
    })()
  }, [])

  const confirmOrder = () => {
    if (paymentNeeded) {
      history.push(Routes.Credit.creditChoices.path, {
        background: location,
        amount: fees,
        productCode: POSUtils.getPosReturnProductCode(),
      })
    } else {
      nextStep()
    }
  }

  const openModalOrConfirmOrder = () => {
    if (preparedPOSReturn?.response?.accountsToClose?.[0]) {
      setShowModal(true)
    } else {
      confirmOrder()
    }
  }

  return (
    <LazyLoadingContainer events={[PosTypes.PREPARE_POS_RETURN_REQUEST, PosTypes.RETURN_POS_REQUEST]}>
      <PageContent
        justifyContent={JustifyContent.BETWEEN}
        footer={
          <>
            <div className='PosOrder_footer_text-center'>
              <Typography typeface='subtitle'>
                {t('neo-commons:pages:services:pos:return:summary:returnFees')} {NumbersUtils.displayPriceForHuman(fees)}
              </Typography>
            </div>
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:global:confirm')}
              onPress={() => openModalOrConfirmOrder()}
              disabled={!preparedPOSReturn?.response && !paymentNeeded}
            />
          </>
        }
      >
        <CloseAccountModal
          open={showModal}
          onClose={() => setShowModal(false)}
          onValidate={() => confirmOrder()}
        />
        <EditItemCard
          label={t('neo-commons:pages:services:pos:return:summary:cardTitle').toUpperCase()}
          skin={EditItemCardSkin.DELIVERY}
          payload={{
            title: `${nbPos} ${nbPos > 1
              ? t('neo-commons:pages:services:pos:return:summary:severalPos')
              : t('neo-commons:pages:services:pos:return:summary:singlePos')}`,
            subtitles: preparedPOSReturn?.pos?.map(p => (p?.serialNumber
              ? `${t('app:pages:services:pos:detail:posSerialNumberShort')}: ${p.serialNumber} `
              : '') + `(${p?.name ?? p.label})`
            ),
          }}
          buttonLabel={t('app:global:edit')}
          onEditPress={() => {
            dispatch(PosActions.prepareReturn({
              ...preparedPOSReturn,
              response: undefined,
            }))
            editStep(POSReturnStep.SelectPos)
          }}
        />
        <EditItemCard
          containerStyle={{ marginTop: 12 }}
          label={t('neo-commons:pages:services:pos:return:summary:cardReason:title').toUpperCase()}
          skin={EditItemCardSkin.DELIVERY}
          payload={{
            title: POSUtils.returnReasonFailure(preparedPOSReturn?.returnReason)
              ? t('neo-commons:pages:services:pos:return:summary:cardReason:failure')
              : t('neo-commons:pages:services:pos:return:summary:cardReason:termination'),
          }}
          hideButton
        />
      </PageContent>
    </LazyLoadingContainer>
  )
}
