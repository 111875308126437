import { browserName, osName, getUA } from 'react-device-detect'

import { DeviceBrowserInfoDto } from '@neo-commons/services'

import { BrowserInfoDto } from '@afone/neo-core-client/dist/models'

export class BrowserUtils {
  /**
   * Browser information needed by deviceApi createOrUpdate
   * * @returns DeviceBrowserDto
   */
  public static getDeviceBrowserInformation (): DeviceBrowserInfoDto {
    return {
      name: `${browserName} - ${osName}`,
      browserUserAgent: getUA,
      browserLanguage: navigator.language,
      browserTZ: new Date().getTimezoneOffset(),
      browserJavaEnabled: navigator.javaEnabled(),
      browserJavascriptEnabled: true,
    }
  }

  /**
   * Browser information needed by transactionApi createCardTransaction
   * * @returns BrowserInfoDto
   */
  public static getTransactionBrowserInformation (): BrowserInfoDto {
    const challengeWindowSize = (width) => {
      if (width > 600) return '05'
      else if (width > 500) return '04'
      else if (width > 390) return '03'
      else if (width > 250) return '02'
      else return '01'
    }

    return {
      colorDepth: screen.colorDepth.toString(),
      screenHeight: screen.height,
      screenWidth: screen.width,
      challengeWindowSize: challengeWindowSize(screen.width),
    }
  }
}
