import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { AppConfigTxt } from 'Config/AppConfig'
import { Routes } from 'Routes/Routes'

import {
  BankAccountCard,
  BankAccountCardSkin,
} from '@neo-commons/components'
import {
  BankAccountActions,
  BankAccountSelectors,
  BankAccountTypes,
  CardTypes,
  ClientSelectors,
  OfferActions,
  OfferTypes,
  TransactionTypes,
} from '@neo-commons/store'

import {
  AccountDto,
  AccountTypeDto,
  ClientDto,
} from '@afone/neo-core-client/dist/models'

import {
  JustifyContent,
  PageContent,
  PaginationList,
  SuspenseLoading,
  ShimmerList,
  ShimmerListType,
  PageDetails,
} from '@neo-web/componentsOld'

import './Projects.scss'

export const ListProjects: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)

  const offerState = useSelector((state: StateTypes) => state.offer)

  const bankAccountState = useSelector((state: StateTypes) => state?.bankAccount)
  const bankAccountProjectsList: AccountDto[] = useSelector(
    BankAccountSelectors.getSubAccountByUuidAndType(selectedAccount.uuid, AccountTypeDto.PROJECT))
  const paginationEnded = useSelector((state: StateTypes) => state?.bankAccount.list?.subLists[AccountTypeDto.PROJECT]?.paginationEnded)
  const currentPage = useSelector((state: StateTypes) => state.bankAccount.list.subLists[AccountTypeDto.PROJECT].page)

  useEffect(() => {
    (async () => {
      try {
        if (!offerState.loading) {
          await dispatch(OfferActions.listForClientUuid())
        }
      } catch (error) {}
    })()
  }, [])

  const getBankAccounts = () => {
    (async () => {
      if (!paginationEnded && !bankAccountState.loading) {
        await dispatch(BankAccountActions.list({
          pageNumber: currentPage + 1,
          bankAccountType: AccountTypeDto.PROJECT,
          parentUuid: selectedAccount.uuid,
          isPaginate: true,
        }))
      }
    })()
  }
  useEffect(() => {
    (async () => {
      await dispatch(
        BankAccountActions.createSubAccount({
          mainAccountUuid: selectedAccount?.uuid,
          xValidationOnly: 1,
          subAccountCreationRequestDto: {
            accountType: AccountTypeDto.PROJECT,
            icon: AppConfigTxt.DEFAULT_PROJECT_ICON,
          },
        })
      )
    })()
  }, [])

  return (
    <>
      <PageDetails
        title={t('app:pages:services:project:listProjects:title')}
        onBack={() => history.push(Routes.Services.ProjectsRoutes.index.path)}
        component={(
          <PageContent
            justifyContent={JustifyContent.START}
          >
            <SuspenseLoading
              events={[BankAccountTypes.CREATE_SUB_ACCOUNT_REQUEST, OfferTypes.GET_OFFER_LIST_REQUEST]}
              fallback={(
                <div className='ListProjects_title'>
                  <ShimmerList type={ShimmerListType.PROJECT} fading />
                </div>
              )}
            >
              <div>
                {bankAccountProjectsList.length > 0 &&
                  <PaginationList
                    state={bankAccountState}
                    stateList={bankAccountProjectsList}
                    items={(item) =>
                      <div key={item?.uuid}>
                        <BankAccountCard
                          onPress={async () => {
                            dispatch({ type: TransactionTypes.RESET })
                            dispatch({ type: CardTypes.RESET })
                            await dispatch(BankAccountActions.setSelected(item.uuid))
                            history.push(Routes.Synthesis.index.path)
                          }}
                          bankAccount={item}
                          client={client}
                          skin={BankAccountCardSkin.DEFAULT}
                          isDisabled
                        />
                      </div>}
                    eventLoading={BankAccountTypes.LIST_BANK_ACCOUNT_REQUEST}
                    next={() => getBankAccounts()}
                    refreshFunction={() => {
                      (async () => {
                        try {
                          await dispatch(BankAccountActions.list({
                            bankAccountType: AccountTypeDto.PROJECT,
                            parentUuid: selectedAccount.uuid,
                            isPaginate: true,
                          }))
                        } catch (error) {}
                      })()
                    }}
                  />}
              </div>
            </SuspenseLoading>
          </PageContent>
        )}
      />
    </>
  )
}
