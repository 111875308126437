import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'
import IBAN from 'iban'

import { Button, ButtonSkin, Icon, IconTypes, ToggleCard, ToggleCardSkin, Typography2 } from '@neo-commons/components'
import {
  BankAccountActions,
  BankAccountSelectors,
  SubscriptionActions,
  State,
  TransactionActions,
  Dispatch,
} from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { BankAccountUtils, NumbersUtils } from '@neo-commons/libraries'

import { AggregationConnectorDto } from '@afone/neo-core-client'

import { AggregationLogo, JustifyContent, Modal, PageContent, PageDetails } from '@neo-web/componentsOld'

import { TypeRemoveAccount } from '../RemovedAggregatedAccount/RemovedAggregatedAccount'

import './ShowAggregatedAccounts.scss'

export const ShowAggregatedAccounts:React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()

  const selectedConnector: AggregationConnectorDto = useSelector((state: State) => state.bankAccount.selectedConnector)
  const bankAccountSelected = useSelector(BankAccountSelectors.selected)
  const bankName = (selectedConnector?.name || bankAccountSelected?.bank) ?? ''
  const bankAccounts = useSelector(BankAccountSelectors
    .getAccountsForBank((selectedConnector?.connectorId || bankAccountSelected?.slug) ?? '')
  ).filter(el => !el.lastUpdate)
  const bankLoading = useSelector((state: StateTypes) => state?.bankAccount?.loading)
  const mainAccounts = useSelector(BankAccountSelectors.listMain)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [currentAccount, setCurrentAccount] = useState(undefined)

  useEffect(() => {
    if (!selectedConnector && !BankAccountUtils.isAggregated(bankAccountSelected)) {
      history.push(Routes.Services.aggregatedBanks.path)
    }
  }, [])

  return (
    <>
      <PageDetails
        title={t('app:global:edit')}
        onBack={() => {
          if (BankAccountUtils.isMain(bankAccountSelected)) {
            history.push(Routes.Services.aggregatedBanks.path)
          } else {
            history.push(Routes.Services.aggregation.path)
          }
        }}
        component={(
          <PageContent
            justifyContent={JustifyContent.START}
            footer={(
              <Button
                skin={ButtonSkin.OUTLINE_RED}
                title={t('app:pages:synthesis:showWhichAccounts:delete')}
                onPress={() => setShowDeleteModal(true)}
              />
            )}
          >
            <div className='ShowAggregatedAccounts_container'>
              <div className='ShowAggregatedAccounts_center'>
                <AggregationLogo bank={selectedConnector || bankAccountSelected} size={60} />
              </div>
              <div>
                {bankAccounts.map((account, index) =>
                  <ToggleCard
                    key={index}
                    value={!account.disabled}
                    title={account.name}
                    subtitles={[
                      IBAN.printFormat(account.iban ?? ''),
                    ]}
                    detail={NumbersUtils.displayPriceForHumanWithCurrency(account.balance, account?.currencyCode)}
                    skin={ToggleCardSkin.DEFAULT}
                    onValueChange={async () => {
                      if (!bankLoading) {
                        if (bankAccounts.filter(el => el.disabled === false).length === 1 && !account.disabled) {
                          setCurrentAccount(account)
                          setShowDeactivateModal(true)
                        } else {
                          await dispatch(BankAccountActions.updateAggregatedAccount(account.uuid,
                            { disabled: !account.disabled }))
                          !account.disabled && !BankAccountUtils.isMain(bankAccountSelected) &&
                          bankAccountSelected.uuid === account.uuid &&
                          dispatch(BankAccountActions
                            .setSelected(mainAccounts.find(el =>
                              (BankAccountUtils.isAggregated(el) && el.uuid !== account.uuid && !el.disabled)).uuid))
                        }
                      }
                    }}
                  />

                )}
              </div>
            </div>
            <Modal
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              displayCross
            >
              <div className='ShowAggregatedAccounts_modal'>
                <Icon
                  name='alert-circle'
                  type={IconTypes.FEATHER}
                  size={40}
                  color={Colors.secondary}
                />
                <Typography2 typeface='h4'>{t(
                  'app:pages:synthesis:showWhichAccounts:modal:titleDelete'
                )}
                </Typography2>
                <Typography2 style={{ textAlign: 'center' }} typeface='body2'>
                  {t('app:pages:synthesis:showWhichAccounts:modal:descriptionDelete',
                    { bank: selectedConnector?.name || bankName })}
                </Typography2>
                <Button
                  containerStyle={{ width: '100%' }}
                  title={t('app:pages:synthesis:showWhichAccounts:modal:denyDelete')}
                  onPress={() => setShowDeleteModal(false)}
                />
                <Button
                  containerStyle={{ width: '100%' }}
                  skin={ButtonSkin.OUTLINE_PRIMARY}
                  title={t('app:pages:synthesis:showWhichAccounts:modal:confirmDelete')}
                  onPress={async () => {
                    for (const account of bankAccounts) {
                      await dispatch(BankAccountActions.deleteAggregatedAccount(account?.uuid))
                    }
                    await dispatch(TransactionActions.reset())
                    await dispatch(BankAccountActions.setSelected(mainAccounts[0].uuid))
                    await dispatch(BankAccountActions.list())
                    await dispatch(SubscriptionActions.list())
                    history.push(
                      Routes.Services.removedAggregatedAccount.path,
                      { type: TypeRemoveAccount.DELETE, bankName: selectedConnector?.name || bankName })
                  }}
                />
              </div>
            </Modal>
            <Modal
              open={showDeactivateModal}
              onClose={() => setShowDeactivateModal(false)}
              displayCross
            >
              <div className='ShowAggregatedAccounts_modal'>
                <Icon
                  name='alert-circle'
                  type={IconTypes.FEATHER}
                  size={40}
                  color={Colors.secondary}
                />
                <Typography2 typeface='h4'>{t(
                  'app:pages:synthesis:showWhichAccounts:modal:titleDeactivate'
                )}
                </Typography2>
                <Typography2 style={{ textAlign: 'center' }} typeface='body2'>
                  {t('app:pages:synthesis:showWhichAccounts:modal:descriptionDeactivate',
                    { bank: selectedConnector?.name || bankName })}
                </Typography2>
                <Button
                  title={t('app:pages:synthesis:showWhichAccounts:modal:denyDeactivate')}
                  containerStyle={{ width: '100%' }}
                  onPress={() => setShowDeactivateModal(false)}
                />
                <Button
                  containerStyle={{ width: '100%' }}
                  skin={ButtonSkin.OUTLINE_PRIMARY}
                  title={t('app:pages:synthesis:showWhichAccounts:modal:confirmDeactivate')}
                  onPress={async () => {
                    await dispatch(BankAccountActions.updateAggregatedAccount(currentAccount.uuid,
                      { disabled: !currentAccount.disabled }))
                    await dispatch(TransactionActions.reset())
                    await dispatch(BankAccountActions.setSelected(mainAccounts[0].uuid))
                    history.push(
                      Routes.Services.removedAggregatedAccount.path,
                      { type: TypeRemoveAccount.DEACTIVATE, bankName: selectedConnector?.name || bankName })
                  }}
                />
              </div>
            </Modal>
          </PageContent>
        )}
      />
    </>
  )
}
