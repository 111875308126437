import { Reducer, AnyAction } from 'redux'
import { omit } from 'lodash'
import { i18commons } from '@neo-commons/i18n'
import {
  LinkDto,
} from '@afone/neo-core-client/dist/models'
import { NeobankApi } from '@neo-commons/services'

import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { initialResourceState } from '../utils'

export type Link = LinkDto

const {
  resourceActionTypes: LinkActionType,
  resourceReducer: LinkResourceReducer,
  resourceAction: LinkAction,
  resourceSelector: LinkResourceSelector,
} = ResourceStateFactory<Link, 'link'>(state => state.link, 'link')

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export const LinkActionTypes = {
  ...LinkActionType,
}
/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

const getLink = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LinkActionTypes.BYID_LINK_REQUEST })
    try {
      const contactCreateResponse = await NeobankApi.getInstance().linkApi.getLink(
        id
      )

      dispatch({
        type: LinkActionTypes.BYID_LINK_SUCCESS,
      })

      return contactCreateResponse.data
    } catch (error) {
      const errorCode = error?.code;
      const errorMessage = errorCode === 'C4000' ? i18commons.t('neo-commons:errors:expiredLinkContactSupport') : (error?.message || i18commons.t('neo-commons:errors:unknownTechnicalIssue'))
      dispatch({
        type: LinkActionTypes.BYID_LINK_FAILURE,
        errorMessage,
      })
      if(errorCode === 'C4000'){
        throw {message: errorMessage, cause: errorCode}
      }
      throw new Error(errorMessage)
    }
  }
}

const successLink = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LinkActionTypes.BYID_LINK_SUCCESS,
    })
  }
}

export const LinkActions = {
  ...LinkAction,
  getLink,
  successLink,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

export const LinkSelectors = {
  ...LinkResourceSelector,

}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type LinkState = Omit<ResourceState<Link>, 'link'>

const initialState: LinkState = {
  ...omit(initialResourceState),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const link: Reducer = (state = initialState, action: AnyAction): LinkState => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case LinkActionType.BYID_LINK_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return {
        ...LinkResourceReducer(state, action, {
          identifier: 'type',
          isPaginate: false,
          initialState,
        }),
      }
  }
}
