import { map } from 'lodash'

import { orderProjectPolicy } from '@neo-commons/policies'

const project = 'app:pages:services:project:menu'

export const ProjectsRoutes = {
  index: {
    path: orderProjectPolicy.basePath,
    name: project,
    available: true,
  },
  listProjects: {
    path: orderProjectPolicy.basePath + '/list',
    name: project,
    available: true,
  },
  closeProjectList: {
    path: orderProjectPolicy.basePath + '/closeAccount/list',
    name: project,
    available: true,
  },
  cancelClosure: {
    path: orderProjectPolicy.basePath + '/cancel/closure',
    name: 'neo-commons:project:cancelClosure',
    available: true,
  },
  ...map((orderProjectPolicy.steps), ({ path, meta }) => ({
    path,
    name: meta.screenTitle,
    available: true,
  })),
}
