import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'

import { RootActions } from 'Store'
import { CreditPolicy } from 'Policies'
import { Routes } from 'Routes/Routes'

import { AlertType, BankAccountActions, BankAccountSelectors, BankAccountTypes, Dispatch } from '@neo-commons/store'
import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { BankAccountUtils } from '@neo-commons/libraries'

import { FileUtils } from '@neo-web/utils'
import { CardDetails, CardDetailsItem, JustifyContent, LazyLoadingContainer, PageContent } from '@neo-web/componentsOld'

import './Transfer.scss'

export const Transfer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()
  const location = useLocation()

  const domiciliation: any = (location?.state as any)?.domiciliation

  const bankAccountSelected = useSelector(BankAccountSelectors.selected)
  const checkBankAccountSelected = CreditPolicy.checkBankAccountSelected(bankAccountSelected)

  const copyToClipboard = (value: string) => {
    try {
      (async () => {
        await navigator?.clipboard?.writeText(value)
        await dispatch(RootActions.alert({
          title: t('app:global:copied'),
          type: AlertType.SUCCESS,
        }))
      })()
    } catch (error) {}
  }

  useEffect(() => {
    if (bankAccountSelected && bankAccountSelected.uuid && !bankAccountSelected.rib &&
      BankAccountUtils.isMain(bankAccountSelected)) {
      (async () => {
        try {
          await dispatch(BankAccountActions.ribInfos(bankAccountSelected.uuid))
        } catch (error) {}
      })()
    }
  }, [bankAccountSelected])

  const copyIcon = <Icon name='copy' color={Colors.primary} type={IconTypes.FEATHER} size={18} />
  const listItems: CardDetailsItem[] = [
    {
      label: t('neo-commons:account:iban'),
      value: bankAccountSelected.rib?.iban,
      action: () => copyToClipboard(bankAccountSelected.rib?.iban),
      actionIcon: copyIcon,
    }, {
      label: t('neo-commons:account:bic'),
      value: bankAccountSelected.rib?.bic,
      action: () => copyToClipboard(bankAccountSelected.rib?.bic),
      actionIcon: copyIcon,
    }, {
      label: t('neo-commons:account:titular'),
      value: bankAccountSelected.rib?.holder,
      action: () => copyToClipboard(bankAccountSelected.rib?.holder),
      actionIcon: copyIcon,
    },
  ]

  return (
    <PageContent
      withBackground
      justifyContent={JustifyContent.BETWEEN}
      title={domiciliation
        ? t('app:pages:credit:transfer:domiciliate:title')
        : t('app:pages:credit:transfer:title')}
    >
      {!checkBankAccountSelected &&
        <Redirect
          to={{
            pathname: Routes.Origin.path,
          }}
        />}
      <LazyLoadingContainer events={[BankAccountTypes.GET_BANK_ACCOUNT_RIB_INFOS_REQUEST]}>
        {bankAccountSelected && bankAccountSelected.rib && (
          <>
            <CardDetails>
              <div className='ibanStep'>
                <div className='ibanStep_icon'>
                  <Typography typeface='boldSecondary'>1.</Typography>
                </div>
                <div className='ibanStep_text'>
                  <Typography typeface='bold'>{t('app:pages:credit:transfer:step1:title')}</Typography>
                  <Typography typeface='content'>{domiciliation
                    ? t('app:pages:credit:transfer:domiciliate:step1:text')
                    : t('app:pages:credit:transfer:step1:text')}
                  </Typography>
                </div>
              </div>
              <div className='ibanStep'>
                <div className='ibanStep_icon'>
                  <Typography typeface='boldSecondary'>2.</Typography>
                </div>
                <div className='ibanStep_text'>
                  <Typography typeface='bold'>{domiciliation
                    ? t('app:pages:credit:transfer:domiciliate:step2:title')
                    : t('app:pages:credit:transfer:step2:title')}
                  </Typography>
                  <Typography typeface='content'>{domiciliation
                    ? t('app:pages:credit:transfer:domiciliate:step2:text')
                    : t('app:pages:credit:transfer:step2:text')}
                  </Typography>
                </div>
              </div>
              <div className='ibanStep'>
                <div className='ibanStep_icon'>
                  <Typography typeface='boldSecondary'>3.</Typography>
                </div>
                <div className='ibanStep_text'>
                  <Typography typeface='bold'>{t('app:pages:credit:transfer:step3:title')} 🎉</Typography>
                </div>
              </div>
            </CardDetails>
            <CardDetails list={listItems} />
            <Button
              title={t('app:global:saveIban')}
              skin={ButtonSkin.DEFAULT_LIGHT}
              containerStyle={{ marginTop: 12 }}
              rightIcon={<Icon name='download' type={IconTypes.FEATHER} size={20} color={Colors.primary} />}
              onPressOut={() => {
                (async () => {
                  dispatch(BankAccountActions.ribFile(bankAccountSelected.uuid)).then(() => {
                    FileUtils.openBase64NewTab(
                      bankAccountSelected.rib?.file.fileEncoded || '',
                      bankAccountSelected.rib?.file.name || '',
                      'pdf'
                    )
                  })
                })()
              }}
            />
          </>
        )}
      </LazyLoadingContainer>
    </PageContent>
  )
}
