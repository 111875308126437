import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { CheckTextType, ClientUtils, CountryUtils, Validators } from '@neo-commons/libraries'
import {
  CardActions,
  CardSelectors,
  ClientSelectors,
  ConfigSelectors,
  Country,
  OrderUpdateType,
  PrepareCardOrder,
} from '@neo-commons/store'
import { CheckboxInput, Input2, Typography } from '@neo-commons/components'

import { ClientDto, CountryDto, PersonAffiliationDtoPerson } from '@afone/neo-core-client/dist/models'

import { UseAddressForm } from '@neo-web/hooks'
import { AddressButtonWhisper, JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

interface AddressDataType{
  address: string|null,
  zipCode: string|null,
  cityName: string|null,
  additionalAddress: string|null,
  countryData: Country|null,
}

export const Address: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const preparedCardOrder: PrepareCardOrder = useSelector(CardSelectors.preparedCardOrder)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const userData = useSelector((state: StateTypes) => state.user.data)
  const person: PersonAffiliationDtoPerson =
    ClientUtils.getPersonAffiliateFromClient(client, preparedCardOrder?.personUuid)

  const [lastname, setLastname] = useState(
    preparedCardOrder?.deliveryData?.user?.lastname ??
    ClientUtils.isClientTypeIndividual(client?.type) ? client?.holder.lastName : person?.lastName)
  const [firstname, setFirstname] = useState(
    preparedCardOrder?.deliveryData?.user?.firstname ??
    ClientUtils.isClientTypeIndividual(client?.type) ? client?.holder.firstName : person?.firstName)
  const [isExactAddress, setIsExactAddress] = useState(false)

  const allCountries: CountryDto[] = useSelector(ConfigSelectors.getCurrentConfig)?.countries ?? []
  const countries = CountryUtils.filterByAddressAuthorized(allCountries)

  const getCountry = () => {
    return client?.holder?.addresses?.[0]?.country
      ? countries.find(country => country.name === client?.holder?.addresses?.[0]?.country)
      : null
  }

  const preparedCardOrderAddress = preparedCardOrder?.deliveryData?.address
  const clientFirstAddresses = client?.holder?.addresses?.[0]

  const city = preparedCardOrderAddress?.city ?? clientFirstAddresses?.city
  const postalCode = preparedCardOrderAddress?.zipCode ?? clientFirstAddresses?.postalCode
  const street = preparedCardOrderAddress?.street ?? clientFirstAddresses?.line1
  const streetLine2 = preparedCardOrderAddress?.streetLine2 ?? ''
  const country = preparedCardOrderAddress?.country ?? getCountry()

  const checkIsNotEmpty = (input: string): boolean => {
    return !!(Validators.isNotEmpty(input) as CheckTextType).isValid
  }
  const [addressData, renderAddressForm] = UseAddressForm({
    userData,
    countries,
    values: {
      countryData: country,
      zipCode: (country && postalCode) ? postalCode : '',
      address: (country && street) ? street : '',
      additionalAddress: streetLine2 ?? '',
      cityName: (country && city) ? city : '',
    },
  })
  const checkFormIsValid = (): boolean => {
    const { address, zipCode, cityName, countryData } = addressData as AddressDataType
    return Boolean(address && cityName &&
      countryData && zipCode &&
      checkIsNotEmpty(lastname) && checkIsNotEmpty(firstname) && isExactAddress)
  }

  const validate = async (addressSelected: AddressDataType) => {
    const { address, zipCode, cityName, countryData, additionalAddress } = addressSelected
    await dispatch(CardActions.prepare({
      ...preparedCardOrder,
      updateType: OrderUpdateType.NONE,
      deliveryData: {
        ...preparedCardOrder.deliveryData,
        user: {
          lastname: lastname ?? '',
          firstname: firstname ?? '',
        },
        address: {
          country: countryData,
          zipCode: zipCode,
          city: cityName,
          street: address,
          streetLine2: additionalAddress ?? '',
        },
      },
    }))
  }

  const validAddress = async (addressSelected: AddressDataType) => {
    await validate(addressSelected)
    history.push(Routes.Services.summary.path)
  }

  return (
    <PageDetails
      component={
        <PageContent
          justifyContent={JustifyContent.BETWEEN}
          footer={(
            <div>
              <CheckboxInput
                onPress={() => setIsExactAddress(!isExactAddress)}
                text={
                  <Typography typeface='body2' onPress={() => setIsExactAddress(!isExactAddress)}>
                    {t('app:pages:services:debitCard:order:address:isExactAddress')}
                  </Typography>
                }
                isSelected={isExactAddress}
              />
              <AddressButtonWhisper
                originAddress={addressData}
                onSelect={validAddress}
                buttonDisabled={!checkFormIsValid()}
                buttonTitle={t('app:global:validate')}
              />
            </div>
          )}
        >
          <Input2
            label={t('app:pages:services:debitCard:order:address:firstName')}
            value={firstname}
            onChangeText={(value) => { setFirstname(value) }}
            editable
            validators={[Validators.isNotEmpty]}
          />
          <Input2
            label={t('app:pages:services:debitCard:order:address:lastName')}
            value={lastname}
            onChangeText={(value) => { setLastname(value) }}
            editable
            validators={[Validators.isNotEmpty]}
          />
          <div style={{ marginTop: 20 }}>
            {renderAddressForm}
          </div>
        </PageContent>
      }
      onClose={() => history.push(Routes.Services.index.path)}
      onBack={async () => {
        await dispatch(CardActions.prepare({
          ...preparedCardOrder,
          updateType: OrderUpdateType.DELIVERY_MODE,
          deliveryData: null,
        }))
        history.push(Routes.Services.deliveryMode.path)
      }}
      title={t('app:pages:services:debitCard:order:address:title')}
    />
  )
}
