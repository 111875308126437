import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'
import { orderBy } from 'lodash'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { NeobankApi } from '@neo-commons/services'
import { TextUtils } from '@neo-commons/libraries'
import { BankAccountActions } from '@neo-commons/store'
import { Stepper2 } from '@neo-commons/components'

import { AggregationConnectorDto } from '@afone/neo-core-client/dist/models'

import { AggregationLogo, JustifyContent, PageContent, PaginationList } from '@neo-web/componentsOld'

import '../AdvanceEligibility.scss'

export const AdvanceEligibilitySearchBank: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const [banks, setBanks] = useState([])
  const [filteredBanks, setFilteredBanks] = useState([])

  const groupBankByFirstLetter = (banks: AggregationConnectorDto[]) => {
    return orderBy(
      banks?.reduce((cumulate, bank) => {
        const firstLetterContact = TextUtils.normalizeText((bank?.name?.[0] ?? '')).toUpperCase()
        const existing = cumulate.find(group => group.title === firstLetterContact)
        if (existing) {
          (existing.data as any[]).push(bank)
          existing.data = orderBy(existing.data, 'name', 'asc')
        } else {
          cumulate.push({ title: firstLetterContact, data: [bank] })
        }

        return cumulate
      }, [])
      , 'title', 'asc')
  }

  useEffect(() => {
    NeobankApi.getInstance().aggregationApi.getAggregationConnectors().then(el => {
      setBanks(el?.data)
    })
  }, [])

  useEffect(() => {
    setFilteredBanks(banks)
  }, [banks])

  useEffect(() => {
    AnalyticsHandler.track(
      AnalyticsEvents.eligibility.bankAggregationProcessStarted,
      { account_type: 'B2C' })
  }, [])

  const onPressNextStep = (bank: any) => {
    dispatch(BankAccountActions.setSelectedConnector(bank))
    history.push(Routes.AdvanceEligibility.terms.path)
  }

  return (
    <PageContent
      withBackground
      title={t('app:pages:services:aggregation:search:title')}
      justifyContent={JustifyContent.START}
    >
      <Stepper2 currentStep={0} />
      <div className='Eligibility_paginationList'>
        <PaginationList
          search
          onFilter={(value) =>
            setFilteredBanks(banks?.filter(el => el.name.toUpperCase().includes(value.toUpperCase())))}
          stateList={filteredBanks}
          emptyText={t('app:pages:services:aggregation:search:empty')}
          items={(bank) =>
            <div
              key={bank.connectorId}
              className='Eligibility_paginationListItem'
              onClick={() => onPressNextStep(bank)}
            >
              <AggregationLogo bank={bank} size={40} />
              <div className='ItemBank_name'>{bank.name}</div>
            </div>}
          groupData={groupBankByFirstLetter(filteredBanks)}
          next={() => null}
          state={{}}
          refreshFunction={() => null}
        />
      </div>
    </PageContent>
  )
}
