import React from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { CardDetails, CardDetailsItem } from '@neo-web/componentsOld'

interface TransactionInfoProps {
  info: string
}

export const TransactionInfo: React.FC<TransactionInfoProps> = (props: TransactionInfoProps) => {
  const { info } = props
  const { t } = useTranslation()

  const listItems: CardDetailsItem[] = [{
    icon: <Icon name='alert-circle' type={IconTypes.FEATHER} size={20} color={Colors.secondary} />,
    value: info,
  }]

  return (
    <div style={{ marginTop: 32 }}>
      <CardDetails
        title={t('app:pages:synthesis:transaction:detail:moreInfo')}
        list={listItems}
        justifyBetween
      />
    </div>
  )
}
