import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BankAccountSelectors, PosActions, PosSelectors, PosTypes, SubscriptionSelectors } from '@neo-commons/store'
import { PosByAccount, POSUtils } from '@neo-commons/libraries'

import { AccountDto, PosDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'

import {
  JustifyContent,
  PageContent,
  PageDetails,
  ShimmerList,
  ShimmerListType,
  SuspenseLoading,
} from '@neo-web/componentsOld'

import { PosByAccounts } from '../../Components/PosByAccounts/PosByAccounts'

export const ListDetailPos: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const pos: PosDto[] = useSelector(PosSelectors.listPos())
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts())
  const subscriptions: SubscriptionDto[] = useSelector(SubscriptionSelectors.list) ?? []

  const posByAccounts: PosByAccount = POSUtils.groupPosByAccounts(bankAccounts, pos)

  useEffect(() => {
    if (!pos.length) {
      (async () => {
        try {
          await dispatch(PosActions.list(bankAccounts))
        } catch (error) {}
      })()
    }
  }, [])

  return (
    <PageDetails
      title={t('app:pages:services:pos:detail:list:title')}
      onBack={() => history.goBack()}
      component={
        <PageContent justifyContent={JustifyContent.START}>
          <SuspenseLoading
            events={[PosTypes.LIST_POS_REQUEST]}
            fallback={<ShimmerList type={ShimmerListType.POS} fading />}
          >
            <PosByAccounts
              posList={posByAccounts}
              subscriptions={subscriptions}
            />
          </SuspenseLoading>
        </PageContent>
      }
    />
  )
}
