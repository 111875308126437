import React, { FunctionComponent } from 'react'
import { Text, TextStyle } from 'react-native'

import * as typo from '@neo-commons/styles/src/Typo2'

export type Typeface2 =
  'h1' |
  'h2' |
  'h3' |
  'h4' |
  'h5' |
  'h6' |
  'body1' |
  'body1Medium' |
  'body2' |
  'body3' |
  'overline' |
  'buttonLarge' |
  'buttonMedium' |
  'buttonSmall' |
  'label' |
  'infoMessage' |
  'input'

interface Typography2Props {
  typeface: Typeface2,
  style?: TextStyle[] | TextStyle,
  accessibilityLabel?: string,
  numberOfLines?: number
  onPress?: () => void
}

export const Typography2: FunctionComponent<Typography2Props> =
  ({
    typeface,
    children,
    style,
    accessibilityLabel,
    numberOfLines = 0,
    onPress,
  }) => {
    return (
      <Text
        style={[typo[typeface] ?? {}, style]}
        accessibilityLabel={accessibilityLabel}
        numberOfLines={numberOfLines}
        onPress={onPress ? () => onPress() : undefined}
      >
        {children}
      </Text>
    )
  }
