import { i18commons } from '@neo-commons/i18n'
import { BankAccountActions } from '@neo-commons/store'

import { createWizardPolicy, invokeActionCreator } from '../utils'

const machineId = 'orderProject'

const isFree = (context) => {
  return (context?.store.getState().bankAccount.preparedSubAccountOrder.cost?.amount === 0)
}

/*
 * Steps
 */

export enum ProjectStep {
  Name = 'Name',
  Summary = 'Summary',
  Success = 'Success',
}

export const projectSteps = {
  [ProjectStep.Name]: {
    path: 'name',
    nextStep: ProjectStep.Summary,
    meta: {
      screenTitle: 'neo-commons:project:name',
      headerTitle: ():string => i18commons.t('neo-commons:project:name'),
    },
  },
  [ProjectStep.Summary]: {
    path: 'summary',
    skipIf: isFree,
    editableSteps: [ProjectStep.Name],
    nextStep: ProjectStep.Success,
    fulfill: invokeActionCreator(BankAccountActions.createSubAccount),
    meta: {
      screenTitle: 'neo-commons:project:summary',
      headerTitle: ():string => i18commons.t('neo-commons:project:summary'),
    },
  },
  [ProjectStep.Success]: {
    path: 'success',
    nextStep: 'final',
    history: {
      preventStack: true,
    },
    meta: {
      screenTitle: 'neo-commons:project:summary',
      headerTitle: ():string => i18commons.t('neo-commons:project:summary'),
    },
  },
}

export type ProjectSteps = typeof projectSteps

/*
 * Policy initialization
 */
export const orderProjectPolicy = createWizardPolicy({
  machineId: machineId,
  steps: projectSteps,
  initialStep: ProjectStep.Name,
  basePath: '/services/projects',
})
