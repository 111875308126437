import { AnyAction, Reducer } from 'redux'
import i18next from 'i18next'
import { ComnpayApi, CreateTokenDto, NeobankApi, TokenizedExternalCard } from '@neo-commons/services'
import { CreditCardUtils, ProcessType, ProvisioningStatus } from '@neo-commons/libraries'
import { BrowserInfoDto, SchemeDto, TransactionUpdateDto, Transaction3DSecureDto } from '@afone/neo-core-client/dist/models'
import { createSelector } from 'reselect'

import { State } from '../../utils'
import { Dispatch } from '../../utils/resourceState'
import { BankAccountSelectors } from '../bankAccount'
import { TransactionActions } from '../transaction'

/* %%%%%%%%%%%%%%%%%% *\
    Action Types.
\* %%%%%%%%%%%%%%%%%% */

const CREDIT_CARD_PROVISIONING_REQUEST = 'provisioning/CREDIT_CARD_PROVISIONING_REQUEST'
const CREDIT_CARD_PROVISIONING_SUCCESS = 'provisioning/CREDIT_CARD_PROVISIONING_SUCCESS'
const CREDIT_CARD_PROVISIONING_FAILURE = 'provisioning/CREDIT_CARD_PROVISIONING_FAILURE'

const TOKENIZE_CARD_REQUEST = 'provisioning/TOKENIZE_CARD_REQUEST'
const TOKENIZE_CARD_SUCCESS = 'provisioning/TOKENIZE_CARD_SUCCESS'
const TOKENIZE_CARD_FAILURE = 'provisioning/TOKENIZE_CARD_FAILURE'

const VALIDATE_3DS_TRANSACTION_REQUEST = 'provisioning/VALIDATE_3DS_TRANSACTION_REQUEST'
const VALIDATE_3DS_TRANSACTION_SUCCESS = 'provisioning/VALIDATE_3DS_TRANSACTION_SUCCESS'
const VALIDATE_3DS_TRANSACTION_FAILURE = 'provisioning/VALIDATE_3DS_TRANSACTION_FAILURE'

const FIND_BIN_INFOS_REQUEST = 'provisioning/FIND_BIN_INFOS_REQUEST'
const FIND_BIN_INFOS_SUCCESS = 'provisioning/FIND_BIN_INFOS_SUCCESS'
const FIND_BIN_INFOS_FAILURE = 'provisioning/FIND_BIN_INFOS_FAILURE'

const PREPARE = 'provisioning/PREPARE'
const RESET = 'provisioning/RESET'

export const ProvisioningTypes = {
  CREDIT_CARD_PROVISIONING_REQUEST,
  CREDIT_CARD_PROVISIONING_SUCCESS,
  CREDIT_CARD_PROVISIONING_FAILURE,

  TOKENIZE_CARD_REQUEST,
  TOKENIZE_CARD_SUCCESS,
  TOKENIZE_CARD_FAILURE,

  VALIDATE_3DS_TRANSACTION_REQUEST,
  VALIDATE_3DS_TRANSACTION_SUCCESS,
  VALIDATE_3DS_TRANSACTION_FAILURE,

  FIND_BIN_INFOS_REQUEST,
  FIND_BIN_INFOS_SUCCESS,
  FIND_BIN_INFOS_FAILURE,

  PREPARE,
  RESET,
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */

const defaultErrorMessage = i18next.t('errors:unknownTechnicalIssue')

interface TransactionPayloadType {
  cvv?: string
  uuid?: string
  selectedScheme?: SchemeDto
  owner?: string
  saveCard?: boolean
  schemesChoices?: SchemeDto
  cardToken?: string
}

const getCardTransactionPayload = (preparedProvisioning: PreparedProvisioning): TransactionPayloadType => {
  if (preparedProvisioning?.card?.saved) {
    const savedCard = preparedProvisioning?.card?.saved
    return { cvv: savedCard?.cvv, uuid: savedCard?.uuid, selectedScheme: savedCard?.scheme }
  } else if (preparedProvisioning?.card?.new) {
    const { formData } = preparedProvisioning?.card?.new
    return {
      owner: formData.owner,
      saveCard: formData.saveCard,
      schemesChoices: formData.schemesChoices,
      cvv: formData.cvv,
      selectedScheme: formData.scheme,
      cardToken: preparedProvisioning?.card?.new?.tokenizedData?.tokenRef,
    }
  }
  return {}
}

const prepare = (prepare: PreparedProvisioning) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const preparedProvisioning = getState().provisioning?.prepare
    prepare.provisioningStatus = prepare.provisioningStatus
      ? prepare.provisioningStatus : preparedProvisioning?.provisioningStatus
        ? preparedProvisioning.provisioningStatus : ProvisioningStatus.PENDING
    dispatch({ type: PREPARE, prepare })
  }
}

const provisioningBankAccountWithCreditCard = (payload: {
  xValidationOnly?: number,
  callback3DSV2Url?: string,
  browserInfo?: BrowserInfoDto
} = { xValidationOnly: 0 }) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: CREDIT_CARD_PROVISIONING_REQUEST })

    const preparedProvisioning = getState().provisioning?.prepare
    const bankAccountUuid = preparedProvisioning?.bankAccountUuid ?? ''
    const amount = preparedProvisioning?.amount ?? 0
    const { xValidationOnly, callback3DSV2Url, browserInfo } = payload

    const {
      cvv,
      cardToken,
      owner,
      saveCard,
      schemesChoices,
      uuid,
      selectedScheme,
    } = getCardTransactionPayload(preparedProvisioning || {})

    try {
      const payload: any = await NeobankApi.getInstance().transactionApi.createCardTransaction(
        bankAccountUuid,
        {
          amount,
          token: cardToken,
          cardHolder: owner,
          saveCard,
          availableSchemes: schemesChoices,
          creditCardUuid: uuid,
          cvv,
          selectedScheme,
          productCode: preparedProvisioning?.productCode,
          callback3DSV2Url: callback3DSV2Url,
          browserInfo: browserInfo,
        },
        xValidationOnly
      )

      const transaction3DSecure = payload?.data?.transactionReference ? payload.data : undefined
      if (!transaction3DSecure && xValidationOnly === 0) {
        await dispatch(prepare({
          ...preparedProvisioning,
          provisioningStatus: ProvisioningStatus.SUCCESS,
        }))
      }
      dispatch({ type: CREDIT_CARD_PROVISIONING_SUCCESS, transaction3DSecure })
      return payload.data
    } catch (error) {
      const errorMessage = error.message ?? defaultErrorMessage
      dispatch({ type: CREDIT_CARD_PROVISIONING_FAILURE, errorMessage })
      await dispatch(prepare({
        ...preparedProvisioning,
        provisioningStatus: ProvisioningStatus.ERROR,
      }))
      throw new Error(errorMessage)
    }
  }
}

const getProvisioningFees = () => {
  return provisioningBankAccountWithCreditCard({ xValidationOnly: 1 })
}

const tokenizeCard = (formData: CreateTokenDto) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: TOKENIZE_CARD_REQUEST })

    try {
      const { cvv, expirationDate, owner, scheme, bin } = formData
      const trimedBin = bin.replace(/\s/g, '')
      const createTokenResponse = await ComnpayApi.createToken({ bin: trimedBin, expirationDate, cvv, scheme, owner })
      const cardToken = createTokenResponse.data.token

      if (!cardToken) { throw new Error(createTokenResponse.data.message) }
      const preparedProvisioning = getState().provisioning.prepare
      const newPreparedProvisioning: PreparedProvisioning = {
        ...preparedProvisioning,
        card: {
          new: {
            tokenizedData: {
              ...cardToken,
              truncatedCardNumber: CreditCardUtils.formatTruncatedPan(trimedBin),
            },
            formData,
          },
        },
      }
      dispatch({ type: TOKENIZE_CARD_SUCCESS, prepare: newPreparedProvisioning })
    } catch (error) {
      const errorMessage = error.message ?? defaultErrorMessage
      dispatch({ type: TOKENIZE_CARD_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const findBinInfos = (bin: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FIND_BIN_INFOS_REQUEST })
    try {
      const payload = await ComnpayApi.findBinInfos(bin.replace(/\s/g, ''))

      if (payload?.data?.nbBins === 0) {
        throw new Error(i18next.t('neo-commons:errors:invalidCardNumber'))
      }

      dispatch({ type: FIND_BIN_INFOS_SUCCESS })
      return payload.data
    } catch (error) {
      const errorMessage = error?.message ?? defaultErrorMessage
      dispatch({ type: FIND_BIN_INFOS_FAILURE, errorMessage })
      throw new Error(error.message)
    }
  }
}

const validate3DSTransaction = (action: {
  bankAccountUuid: string,
  transactionUuid: string,
  transactionUpdateDto: TransactionUpdateDto
}, waitForBalanceToBeUpdated = true) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: VALIDATE_3DS_TRANSACTION_REQUEST })
    const preparedProvisioning = getState().provisioning.prepare
    try {
      const response = await NeobankApi.getInstance().transactionApi.updateTransaction(
        action.bankAccountUuid,
        action.transactionUuid,
        action.transactionUpdateDto,
      )

      if (waitForBalanceToBeUpdated) {
        const currentBalance = BankAccountSelectors.getCurrentBalance(getState())
        await new Promise((resolve) => {
          let attempts = 0

          const intervalId = setInterval(async () => {
            await dispatch(TransactionActions.getTransactionList())
            const newBalance = BankAccountSelectors.getCurrentBalance(getState())

            if (newBalance !== currentBalance || attempts > 2) {
              clearInterval(intervalId)
              resolve(true)
            }

            attempts++
          }, 1500)
        })
      }

      dispatch({ type: VALIDATE_3DS_TRANSACTION_SUCCESS })
      await dispatch(prepare({
        ...preparedProvisioning,
        provisioningStatus: ProvisioningStatus.SUCCESS,
      }))
      return response?.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:internalTechnicalIssue')
      dispatch({ type: VALIDATE_3DS_TRANSACTION_FAILURE, errorMessage })
      await dispatch(prepare({
        ...preparedProvisioning,
        provisioningStatus: ProvisioningStatus.ERROR,
      }))
      throw new Error(error.message)
    }
  }
}

const cancelTransaction = () => async (dispatch: Dispatch) => {
  dispatch({ type: VALIDATE_3DS_TRANSACTION_FAILURE })
}

const reset = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET })
  }
}

export const ProvisioningActions = {
  validate3DSTransaction,
  getProvisioningFees,
  tokenizeCard,
  provisioningBankAccountWithCreditCard,
  cancelTransaction,
  prepare,
  findBinInfos,
  reset,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export interface PreparedProvisioning {
  processType?: ProcessType,
  provisioningStatus?: ProvisioningStatus,
  bankAccountUuid?: string,
  bankAccountLabel?: string,
  card?: {
    new?: NewCard,
    saved?: SavedCard,
  },
  amount?: number
  productCode?: string
}

export interface NewCard {
  formData: CreateTokenDto,
  tokenizedData: TokenizedExternalCard,
}

export interface SavedCard {
  uuid?: string,
  cvv?: string,
  scheme?: SchemeDto,
}

export type ProvisioningState = {
  ui: {
    loading: boolean,
  },
  prepare: PreparedProvisioning,
  transaction3DSecure?: Transaction3DSecureDto,
}

const initialState: ProvisioningState = {
  ui: {
    loading: false,
  },
  prepare: {},
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const cardSelector = (state: State) => state.provisioning

export const ProvisioningSelectors = {
  prepare: createSelector(
    cardSelector,
    resource => resource.prepare
  ),
  transaction3DSecure: createSelector(
    cardSelector,
    resource => resource.transaction3DSecure
  ),
}

export const provisioning: Reducer = (
  state: ProvisioningState = initialState,
  action: AnyAction
): ProvisioningState => {
  switch (action.type) {
    case FIND_BIN_INFOS_REQUEST:
    case VALIDATE_3DS_TRANSACTION_REQUEST:
    case TOKENIZE_CARD_REQUEST:
    case CREDIT_CARD_PROVISIONING_REQUEST:
      return {
        ...state,
        ui: {
          loading: true,
        },
      }

    case FIND_BIN_INFOS_SUCCESS:
    case FIND_BIN_INFOS_FAILURE:
    case CREDIT_CARD_PROVISIONING_FAILURE:
    case VALIDATE_3DS_TRANSACTION_FAILURE:
    case VALIDATE_3DS_TRANSACTION_SUCCESS:
    case TOKENIZE_CARD_FAILURE:
      return {
        ...state,
        ui: {
          loading: false,
        },
      }
    case CREDIT_CARD_PROVISIONING_SUCCESS:
      return {
        ...state,
        ui: {
          loading: false,
        },
        transaction3DSecure: action.transaction3DSecure,
      }
    case TOKENIZE_CARD_SUCCESS:
      return {
        ...state,
        ui: {
          loading: false,
        },
        prepare: action.prepare,
      }

    case PREPARE:
      return {
        ...state,
        prepare: action.prepare,
      }

    case RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
