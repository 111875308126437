import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { BankAccountActions, BankAccountSelectors } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { AccountDto } from '@afone/neo-core-client/dist/models'

import { Modal } from '@neo-web/componentsOld'

import './CancelPosAccountClosureModal.scss'

export interface CancelPosAccountClosureModalProps {
  onClose?: () => void
  open: boolean
}

export const CancelPosAccountClosureModal: React.FC<CancelPosAccountClosureModalProps> = (
  { onClose, open }: CancelPosAccountClosureModalProps
) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedMain: AccountDto = useSelector(BankAccountSelectors.selectedMain)

  return (
    <Modal
      autoFocus open={open}
      displayCross onClose={onClose}
      title={t('app:pages:services:cancelClosePosProject:modal:title')}
      titleIcon={<Icon type={IconTypes.NEOFONT} name='tpe' color={Colors.secondary} size={33} />}
    >
      <div className='CancelPosAccountClosureModal_container'>
        <Typography2 typeface='body2'>
          {t('app:pages:services:cancelClosePosProject:modal:description')}
        </Typography2>
        <Button
          title={t('app:pages:services:pos:orderPos:cardTitle')}
          skin={ButtonSkin.PRIMARY}
          onPress={async () => {
            await dispatch(BankAccountActions.setSelected(selectedMain.uuid))
            history.push(Routes.Services.PosOrderRoutes.index.path)
          }}
        />
      </div>
    </Modal>
  )
}
