import React from 'react'
import { View } from 'react-native'

import styles from './styles'

interface Stepper2Props {
  currentStep: number,
}

export const Stepper2 = ({ currentStep }: Stepper2Props) => {
  const steps = [0, 1, 2]
  return (
    <View style={styles.container}>
      {steps.map((step, index) => (
        <View style={styles.step} key={index}>
          <View
            style={[styles.circle, currentStep === step && styles.activeCircle]}
          >
            {currentStep === step && <View style={styles.innerCircle} />}
          </View>

          {index !== steps.length - 1 && <View style={styles.line} />}
        </View>
      ))}
    </View>
  )
}
