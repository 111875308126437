import { DocumentUploadActions } from '@neo-commons/store'

import { createWizardPolicy, invokeActionCreator } from '../utils'

const machineId = 'documentUpload'

/*
 * Guards
 */

/*
 * Actions
 */

/*
 * Steps
 */

export enum DocumentUploadStep {
  summary = 'summary',
  uploading = 'uploading',
  success = 'success',
  failure = 'failure',
}

export const documentUploadSteps = {
  [DocumentUploadStep.summary]: {
    path: 'summary',
    nextStep: DocumentUploadStep.uploading,
    meta: {
      canGoBack: true,
    },
  },
  [DocumentUploadStep.uploading]: {
    path: 'uploading',
    nextStep: DocumentUploadStep.success,
    fulfill: invokeActionCreator(DocumentUploadActions.uploadDocument),
    history: {
      resetStack: true,
      preventStack: true,
    },
    meta: {
      canGoBack: false,
    },
    onErrorRedirectStep: DocumentUploadStep.failure,
  },
  [DocumentUploadStep.success]: {
    path: 'success',
    nextStep: 'final',
    history: {
      resetStack: true,
      preventStack: true,
    },
    meta: {
      canGoBack: false,
    },
  },
  [DocumentUploadStep.failure]: {
    path: 'failure',
    nextStep: DocumentUploadStep.uploading,
    history: {
      resetStack: true,
      preventStack: true,
    },
    meta: {
      canGoBack: false,
    },
  },
}

export type DocumentUploadSteps = typeof documentUploadSteps

/*
 * Policy initialization
 */
export const documentUploadPolicy = createWizardPolicy({
  machineId: machineId,
  steps: documentUploadSteps,
  initialStep: DocumentUploadStep.summary,
  basePath: '/documentUpload',
})
