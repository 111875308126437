import React, { useCallback, useEffect, useRef } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'

const canvasStyles = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}

interface AnimationProps {
  zIndexOverride?: number
}

export const Animation: React.FC<AnimationProps> = (props: AnimationProps) => {
  const refAnimationInstance = useRef(null)

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance
  }, [])

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
    refAnimationInstance.current({
      ...opts,
      origin: { y: 0.3 },
      particleCount: Math.floor(800 * particleRatio),
    })
  }, [])

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    })

    makeShot(0.2, {
      spread: 100,
    })

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    })
  }, [makeShot])

  useEffect(() => {
    fire()
  }, [])

  return (
    <>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{ ...(canvasStyles as any), zIndex: props.zIndexOverride ?? -100 }}
      />
    </>
  )
}
