import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  BankAccountSelectors,
  PosSelectors,
  PosTypes,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { PosByAccount, POSUtils } from '@neo-commons/libraries'

import {
  AccountDto,
  PosDto,
  SubscriptionDto,
} from '@afone/neo-core-client/dist/models'

import {
  JustifyContent,
  PageContent,
  ShimmerList,
  ShimmerListType,
  SuspenseLoading,
} from '@neo-web/componentsOld'

import { PosByAccounts } from '../Components/PosByAccounts/PosByAccounts'
import { PosDeliveryAlert } from '../Components/PosAlerts/PosDeliveryAlert'

export const PosSubAccount: React.FC = () => {
  const { t } = useTranslation()

  const pos: PosDto[] = useSelector(PosSelectors.listPos())
  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const subscriptions: SubscriptionDto[] = useSelector(SubscriptionSelectors.list)

  const posByAccounts: PosByAccount = POSUtils.groupPosByAccounts([selectedBankAccount], pos)
  const subscriptionsUuids = posByAccounts?.[selectedBankAccount?.uuid]?.map((data) => data?.workflowSubscriptionUuid) ?? []
  const posSubscriptions: SubscriptionDto[] = subscriptions.filter(sub => subscriptionsUuids.includes(sub?.uuid)) ?? []

  return (
    <PageContent
      justifyContent={JustifyContent.START}
      header={posSubscriptions &&
        <>{posSubscriptions.map(sub => <PosDeliveryAlert key={sub?.uuid} subscription={sub} />)}</>}
    >
      <SuspenseLoading
        events={[PosTypes.LIST_POS_REQUEST]}
        fallback={<ShimmerList type={ShimmerListType.POS} fading />}
      >
        <PosByAccounts
          title={t('app:pages:services:pos:detail:list:title')}
          posList={posByAccounts}
          subscriptions={posSubscriptions}
        />
      </SuspenseLoading>
    </PageContent>
  )
}
