import { SafeDataUtils } from './SafeDataUtils'

/**
   * Generic utils use in NoElse in text.
   *
   */
export class TextUtils {
  /**
   * Normalize the text
   *
   * @param {string} text - text to normalize
   * @returns {string} return the normalize text
   *
   */
  public static normalizeText (text: string): string {
    if (typeof text !== 'string') {
      return ''
    }

    const splitedText = text.split(' ').map(word => this.normalizeWord(word))
    return splitedText.join(' ').trim().toLowerCase()
  }

  /**
   * Normalize a word
   *
   * @param {string} word - word to normalize
   * @returns {string} return the normalize word
   *
   */
  public static normalizeWord (word: string): string {
    return word.trim().toLowerCase().normalize('NFKD').replace(/[^\w]/g, '')
  }

  /**
   * Match if the word is in text
   *
   * @param {string} word - word to search
   * @param {string} text - text to analyze
   * @returns {RegExpMatchArray} return the group of match
   *
   */
  static matchWord = (word: string, text: string): RegExpMatchArray | null => {
    const textRegex = TextUtils.normalizeText(text).trim().split(' ').join('(.*) ') + '(.*)'
    return TextUtils.normalizeText(word).match('^' + textRegex + '| ' + textRegex)
  }

  /**
   *
   * @param {string} attributName - string to sort
   * @returns {number} return a number depends of sort
   *
   */
  static compareToSort (attributName: string): (element1: any, element2: any) => number {
    return (element1: any, element2: any) => {
      const element1Normalize = TextUtils.normalizeText(SafeDataUtils.getSecureString(element1, attributName))
      const element2Normalize = TextUtils.normalizeText(SafeDataUtils.getSecureString(element2, attributName))

      if (element1Normalize < element2Normalize) {
        return -1
      }

      if (element1Normalize > element2Normalize) {
        return 1
      }

      return 0
    }
  }

  /**
   * cut the text if too long,
   * like 'Hi everybody !' into 'Hi ev...'
   *
   * @param {string} text - string to test
   * @param {number} maxLength - maxLength, default 4
   * @param {boolean} fromStart - position of test
   * @param {string} overflow - patern for overflow
   * @returns {string} return the transform text
   *
   */
  public static prettyShortText (text: string, maxLength = 4, fromStart = false, overflow = '…'): string {
    if (!text) {
      return ''
    }

    const textSize = text.length
    let result = text

    if (textSize > maxLength) {
      if (fromStart) {
        result = overflow + text.substring(textSize - maxLength)
      } else {
        result = text.substring(0, maxLength) + overflow
      }
    }

    return result
  }

  /**
   * Render the phone text prettier for display,
   * like '0707070707' into '07 07 07 07 07'
   *
   * @param {string} phoneNumber - phone number to format
   * @returns {string} return the phone number format
   *
   */
  public static prettyPhoneNumber (phoneNumber: string): string {
    return phoneNumber.split('').map((char, i) => i % 2 ? char + ' ' : char).join('').trim()
  }

  /**
   * Render the firstname and lastname prettier for display,
   *
   * @param {string} lastname - lastname to format
   * @param {string} firstname - firstname to format
   * @returns {string} return the all name format
   *
   */
  public static prettyName = (lastname: string, firstname: string) => {
    const MAX_LENGTH = 24
    if (lastname && firstname) {
      const lastnameParts = lastname.split(' ') ?? ''

      let fullName = ''

      for (const lastnamePart of lastnameParts) {
        if (fullName.length + firstname.length + lastnamePart.length < MAX_LENGTH) {
          fullName += ` ${lastnamePart}`
        } else {
          break
        }
      }
      return firstname + fullName
    }
    return lastname
  }

  /**
   * Uppercase the first letter of a word,
   */
  public static capitalize = (value: string) => {
    return value[0].toUpperCase() + value.slice(1)
  }
}
