import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  Button,
  ButtonSkin,
  Typography,
} from '@neo-commons/components'

import { ScaActions } from '../../../../../Store/Sca'
import appStore from '../../../../Resources/Images/site/market/appstore@2x.webp'
import playStore from '../../../../Resources/Images/site/market/playstore@2x.webp'
import { AppConfigTxt } from '../../../../../Config/AppConfig'
import './WalletPreview.scss'

export const WalletPreview: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <div className='WalletVerify_container'>
      <div className='WalletVerify_content'>
        <Typography typeface='content'>
          {t('app:pages:auth:scaDevice:walletPreview:content')}
        </Typography>
      </div>

      <div className='WalletVerify_image'>
        <a target='_blank' href={AppConfigTxt.APPLE_STORE_SCA_LINK} rel='noreferrer'>
          <img
            src={appStore}
            alt='Appstore'
          />
        </a>
        <a target='_blank' href={AppConfigTxt.ANDROID_STORE_SCA_LINK} rel='noreferrer'>
          <img
            src={playStore}
            alt='Playstore'
          />
        </a>

      </div>

      <div className='WalletVerify_button'>
        <Button
          title={t('app:pages:auth:scaDevice:walletPreview:button')}
          skin={ButtonSkin.OUTLINE_PRIMARY}
          onPress={() => {
            dispatch(ScaActions.cancelMethod())
          }}
        />
      </div>
    </div>
  )
}
