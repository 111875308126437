import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { StepComponentProps } from '@neo-commons/policies'
import { TransactionRefundStep, TransactionRefundSteps } from '@neo-commons/policies/src/Transaction'
import { TransactionActions } from '@neo-commons/store'

import { SuccessContent } from '@neo-web/componentsOld'

type TransactionRefundSuccessProps = StepComponentProps<TransactionRefundSteps[TransactionRefundStep.Success]>

export const Success: React.FC<TransactionRefundSuccessProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <SuccessContent
      title={t('app:pages:synthesis:transaction:refund:success:title')}
      buttonAction={{
        text: t('app:global:goBackToSynthesis'),
        action: () => {
          (async () => {
            await dispatch(TransactionActions.refundReset())
            history.push(Routes.Synthesis.transactions.path)
          })()
        },
      }}
    />
  )
}
