import commonsFR from './locales/fr_FR.json'
import commonsDE from './locales/de_DE.json'
import commonsEN from './locales/en_US.json'
import commonsIT from './locales/it_IT.json'
import commonsES from './locales/es_ES.json'

type langApp = {
  value: string,
  isoCodeAlpha2: string,
  isoCodeAlpha3: string,
  label: string,
  translate: any,
  formats: DateFormat,
}

export type DateFormat = {
  dayAndMonth: string,
  monthAndFullYear: string,
  fullDate: string,
  fullVerboseDate: string,
  monthAndYearVerbose: string,
}

/* Theses formats are based on dayjs date formats -> https://day.js.org/docs/en/parse/string-format
** in order to use them, dayjs.locale() must be set to the correct country
*/
const basicDateFormat = {
  dayAndMonth: 'DD/MM',
  monthAndFullYear: 'MM/YYYY',
  fullDate: 'L',
  fullVerboseDate: 'LL',
  monthAndYearVerbose: 'MMMM YYYY',
}

export const resources = {
  fr_FR: {
    value: 'fr_FR',
    isoCodeAlpha2: 'FR',
    isoCodeAlpha3: 'FRA',
    label: 'app:global:lang:france',
    translate: commonsFR,
    formats: basicDateFormat,
  } as langApp,
  de_DE: {
    value: 'de_DE',
    isoCodeAlpha2: 'DE',
    isoCodeAlpha3: 'DEU',
    label: 'app:global:lang:germany',
    translate: commonsDE,
    formats: {
      ...basicDateFormat,
      dayAndMonth: 'DD.MM',
      monthAndFullYear: 'MM.YYYY',
    },
  } as langApp,
  en_US: {
    value: 'en_US',
    isoCodeAlpha2: 'UK',
    isoCodeAlpha3: 'GBR',
    label: 'app:global:lang:englishUK',
    translate: commonsEN,
    formats: basicDateFormat,
  } as langApp,
  it_IT: {
    value: 'it_IT',
    isoCodeAlpha2: 'IT',
    isoCodeAlpha3: 'ITA',
    label: 'app:global:lang:italy',
    translate: commonsIT,
    formats: basicDateFormat,
  } as langApp,
  es_ES: {
    value: 'es_ES',
    isoCodeAlpha2: 'ES',
    isoCodeAlpha3: 'ESP',
    label: 'app:global:lang:spain',
    translate: commonsES,
    formats: basicDateFormat,
  } as langApp,
}
