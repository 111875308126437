import React, { useEffect, useRef, useState } from 'react'
import './Carousel.scss'
import Slider, { Settings } from 'react-slick'

import { Responsive } from '../../../Config/Responsive'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export type ItemCarousel = {
  index: number;
  defaultActive?: boolean;
  component: React.ReactNode;
  onActive?: () => void;
}

export interface CarouselProps {
  items: ItemCarousel[],
  height?: number,
  shadow?: boolean,
  noActiveShadow?: boolean,
  sliderSettings?: Settings,
}

export const Carousel: React.FC<CarouselProps> = (props: CarouselProps) => {
  const [active, setActive] = useState(props.items.find(value => value.defaultActive === true)?.index ?? 0)
  const slider = useRef<Slider>()

  const oneSlide = {
    slidesToShow: 1,
    infinite: false,
    centerMode: true,
    centerPadding: '30px',
  }
  const sliderSettings: Settings = {
    className: '',
    dots: true,
    dotsClass: 'slick-dots',
    adaptiveHeight: false,
    infinite: false,
    variableWidth: false,
    accessibility: true,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 3,
    centerMode: false,
    responsive: [
      { breakpoint: Responsive.CAROUSEL_CONTAINER_MD, settings: { slidesToShow: 2 } },
      { breakpoint: Responsive.CAROUSEL_CONTAINER_SM, settings: oneSlide },
      { breakpoint: Responsive.WITH_TABLET, settings: { slidesToShow: 2 } },
      { breakpoint: Responsive.WITH_MOBILE, settings: oneSlide },
    ],
    arrows: false,
  }

  useEffect(() => {
    slider.current.slickGoTo(props.items.find(value => value.defaultActive === true)?.index)
  }, [props.items])

  return (
    <div className='Carousel'>
      <Slider
        ref={slider}
        {...Object.assign(sliderSettings, props?.sliderSettings)}
        beforeChange={(oldIndex, newIndex) => {
          if (oldIndex !== newIndex) {
            setActive(newIndex)
            const item = props.items.find(i => i.index === newIndex)
            item?.onActive && item.onActive()
          }
        }}
      >
        {props.items.map((value) => {
          return (
            <div key={value.index}>
              <div
                style={{ height: props.height ? props.height : 'auto', marginTop: 1 }}
                onMouseDown={() => {
                  setActive(value.index)
                  value.onActive && value.onActive()
                }}
                className={
                  `classic-box
                ${(active === value.index && !props.noActiveShadow) ? 'active-shadow' : ''}
                ${props.shadow ? 'box-shadow' : ''}`
                }
              >
                {value.component}
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
