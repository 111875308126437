import { BankAccountActions } from '@neo-commons/store'
import { i18commons } from '@neo-commons/i18n'

import { invokeActionCreator, createWizardPolicy, createStepTarget } from '../utils'

const machineId = 'closingAccount'

export enum FinalRedirectStep {
  CREDIT,
  SYNTHESIS
}

/*
 * Events
 */

enum ClosingAccountEvent {
  MODIFY = 'MODIFY',
}

/*
 * Guards
 */

const isRecipientAccountFilled = (context, event, meta) => {
  return !!context?.store.getState().bankAccount.recipientAccountData &&
    meta?.state?._event?.name !== ClosingAccountEvent.MODIFY
}

/*
 * Steps
 */

export enum ClosingAccountStep {
  VerifyAccountToClose = 'VerifyAccountToClose',
  RecipientAccount = 'RecipientAccount',
  ConfirmClosingAccount = 'ConfirmClosingAccount',
  ClosingAccountSuccess = 'ClosingAccountSuccess',
  ClosingAccountFailure = 'ClosingAccountFailure',
}

export const closingAccountSteps = {
  [ClosingAccountStep.VerifyAccountToClose]: {
    path: 'verifyClosingAccount',
    nextStep: ClosingAccountStep.RecipientAccount,
    meta: {
      screenTitle: 'app:pages:myProfile:closingAccount:verifyAccountToClose:title',
      headerTitle: ():string => i18commons.t('neo-commons:profile:closingBankAccount:accountToClose'),
    },
  },
  [ClosingAccountStep.RecipientAccount]: {
    path: 'recipientAccount',
    nextStep: ClosingAccountStep.ConfirmClosingAccount,
    skipIf: isRecipientAccountFilled,
    fulfill: invokeActionCreator(BankAccountActions.prepareRecipientAccount),
    meta: {
      screenTitle: 'app:pages:myProfile:closingAccount:recipientAccount:title',
      headerTitle: ():string => i18commons.t('neo-commons:profile:closingBankAccount:recipientAccount'),
    },
  },
  [ClosingAccountStep.ConfirmClosingAccount]: {
    path: 'confirmClosingAccount',
    nextStep: ClosingAccountStep.ClosingAccountSuccess,
    onErrorRedirectStep: ClosingAccountStep.ClosingAccountFailure,
    fulfill: invokeActionCreator(BankAccountActions.closeBankAccount),
    additionalEvents: {
      [ClosingAccountEvent.MODIFY]: createStepTarget(machineId, 'funnel', ClosingAccountStep.RecipientAccount),
    },
    editableSteps: [
      ClosingAccountStep.RecipientAccount,
    ],
    meta: {
      screenTitle: 'app:pages:myProfile:closingAccount:confirmClosingAccount:title',
      headerTitle: ():string => i18commons.t('neo-commons:profile:closingBankAccount:summary'),
    },
  },
  [ClosingAccountStep.ClosingAccountSuccess]: {
    path: 'successClosingAccount',
    nextStep: 'final',
    history: {
      resetStack: true,
    },
    meta: {
      screenTitle: 'app:pages:myProfile:closingAccount:successClosingAccount:title',
    },
  },
  [ClosingAccountStep.ClosingAccountFailure]: {
    path: 'failClosingAccount',
    nextStep: 'final',
    history: {
      resetStack: true,
    },
    meta: {
      screenTitle: 'app:pages:myProfile:closingAccount:failClosingAccount:title',
    },
  },
}

export type ClosingAccountSteps = typeof closingAccountSteps

/*
 * Policy initialization
 */
export const ClosingAccountPolicy = createWizardPolicy({
  machineId: machineId,
  steps: closingAccountSteps,
  initialStep: ClosingAccountStep.VerifyAccountToClose,
  basePath: '/profile/closingAccount',
})
