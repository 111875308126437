import { map } from 'lodash'

import { ResetPasscodePolicy } from '@neo-commons/policies'

export const ResetPasscodeRoutes = {
  index: {
    path: ResetPasscodePolicy.basePath,
    name: 'app:pages:myProfile:codeReset:screen:title',
    available: true,
  },
  ...map(ResetPasscodePolicy.steps, ({ path, meta }) => ({
    path,
    name: meta.screenTitle,
    available: true,
  })),
}
