import _ from 'lodash'

export interface ResultSelectEntities<E> {
  Eligible: BundleEntities<E>,
  Trash: BundleEntities<E>,
}

export interface BundleEntities<E> {
  ids: string[],
  entities: { [key: string]: E }
}

export type WhereFunction<E> = (candidate: E) => boolean

/**
   * Differents action in entities.
   *
   */
export class EntitiesUtils {
  /* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%% *\
    Operation over entities.
  \* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

  /**
   * Select entities in bundle entities
   *
   * @param {BundleEntities<E>} bundleEntities - list of entities with unique id.
   * @param {WhereFunction<E>} whereFunction - function return boolean
   * @returns {ResultSelectEntities<E>} Return list of entities.
   * @example
   * EntitiesUtils.selectEntities<BankAccountEntity>(accountsBundleEntities, cadidate => cadidate?.transferRoles?.canEmit ?? false).Eligible
   *
   */
  public static selectEntities<E> (bundleEntities: BundleEntities<E>, whereFunction: WhereFunction<E>): ResultSelectEntities<E> {
    return bundleEntities.ids.reduce((resultSelectEntities: ResultSelectEntities<E>, candidateId: string) => {
      const bundleEntity = bundleEntities?.entities?.[candidateId]
      if (whereFunction(bundleEntity)) {
        resultSelectEntities.Eligible.entities[candidateId] = bundleEntity
        resultSelectEntities.Eligible.ids.push(candidateId)
      } else {
        resultSelectEntities.Trash.entities[candidateId] = bundleEntity
        resultSelectEntities.Trash.ids.push(candidateId)
      }
      return resultSelectEntities
    }, {
      Eligible: {
        ids: [],
        entities: {},
      },
      Trash: {
        ids: [],
        entities: {},
      },
    })
  }

  /**
   * Transform entities into a list
   *
   * @param {BundleEntities<E>} bundleEntities - Entities
   * @returns {E[]} Return list with value of entities.
   * @example
   * EntitiesUtils.entitiesToList(AccountUtils.getAccountsCanEmit(accountsBundleEntities))
   *
   */
  public static entitiesToList<E> (bundleEntities: BundleEntities<E>): E[] {
    return Object.values(bundleEntities?.entities ?? [])
  }

  /**
   * Normalize entities
   *
   * @param {D[]} response
   * @param {string} id
   * @returns {BundleEntities<E>}
   * @example
   * EntitiesUtils.normalize<BankAccountDto, BankAccountEntity>(action?.bankAccounts ?? {}, 'uuid', DtoToEntityUtils.toBankAccountEntity)
   *
   */
  public static normalize<D, E> (response: D[], id = 'id', transformer: (item: D) => E): BundleEntities<E> {
    const result: BundleEntities<E> = {
      ids: [],
      entities: {},
    }

    response.map(item => {
      const itemId: string = _.get(item, id, this.getAltUniqueId()) || this.getAltUniqueId()
      result.ids.push(itemId)
      result.entities[itemId] = transformer(item)
    })

    return result
  }

  /**
   * Generate altID
   *
   * @param {boolean} withAlt - generate the id with altID
   * @returns {string} return a unique id
   * @example
   * const itemId: string = _.get(item, id, this.getAltUniqueId()) || this.getAltUniqueId()
   *
   */
  public static getAltUniqueId (withAlt = true): string {
    const id = Math.floor(Math.random() * Math.floor(300)) + new Date().getTime()
    if (withAlt) {
      return 'altID_' + id
    }

    return '' + id
  }
}
