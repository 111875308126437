import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { StateTypes } from 'Store'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { ClosingAccountStep, ClosingAccountSteps, StepComponentProps } from '@neo-commons/policies'
import { BankAccountActions } from '@neo-commons/store'

import { Banner, SuccessContent } from '@neo-web/componentsOld'

import { bannerPhysic } from '../../../../Resources/Images'

type SuccessClosingAccountProps = StepComponentProps<ClosingAccountSteps[ClosingAccountStep.ClosingAccountSuccess]>

export const SuccessClosingAccount: React.FC<SuccessClosingAccountProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const bankAccount = useSelector((state: StateTypes) => state.bankAccount)

  useEffect(() => {
    AnalyticsHandler.track(AnalyticsEvents.Account.bankAccountClosureRequested)
  }, [])

  const onNextClick = () => {
    (async () => {
      try {
        // TODO: list() is deprecated here, change when account remove action will be implemented (list main then sub accounts)
        await dispatch(BankAccountActions.list())
        nextStep({ finalRedirectStep: 'Test' })
      } catch (e) {
        console.log(e)
      }
    })()
  }

  return (
    <SuccessContent
      header={<Banner><img src={bannerPhysic} alt='banner' /></Banner>}
      title={t('app:pages:myProfile:closingAccount:successClosingAccount:title')}
      description={t('app:pages:myProfile:closingAccount:successClosingAccount:content',
        { amount: bankAccount?.closingBankAccountAmount ?? '' })}
      buttonAction={{
        text: t('app:pages:myProfile:closingAccount:successClosingAccount:backToProfile'),
        action: onNextClick,
      }}
    />
  )
}
