import React, { ReactNode } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

interface ShimmerSuspenseProps {
  events: Array<string>,
  fallback: ReactNode,
  children: ReactNode,
}

export const SuspenseLoading: React.FC<ShimmerSuspenseProps> = (props: ShimmerSuspenseProps) => {
  let eventIsLoading = 0
  props.events.forEach((event) => {
    if (usePromiseTracker({ area: event }).promiseInProgress) eventIsLoading++
  })

  return (
    <>
      {eventIsLoading > 0
        ? props.fallback
        : props.children}
    </>
  )
}
