import React, { useState } from 'react'

import { Country } from '@neo-commons/store'

import { CountryDto, UserDto } from '@afone/neo-core-client/dist/models'

import { AddressForm } from '@neo-web/componentsOld'

export type AddressDataType = {
  cityName: string,
  zipCode: string,
  address: string,
  additionalAddress: string,
  countryData: Country,
}

interface UseAddressFormType {
  userData: UserDto | null,
  countries: CountryDto[],
  values?: AddressDataType,
}

export const UseAddressForm = (props?: UseAddressFormType) => {
  const [addressData, setAddressData] = useState<AddressDataType | any >(props?.values ?? {})

  const renderAddressForm = (
    <AddressForm
      getFormData={data => setAddressData(data)}
      countries={props.countries}
      values={props?.values}
    />
  )
  return [addressData, renderAddressForm]
}
