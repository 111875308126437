import React, { forwardRef, FunctionComponent, Ref, useEffect, useState } from 'react'
import { View, TouchableOpacity, TextInput } from 'react-native'

import { times } from 'lodash'

import { Input, Timer } from '../'

import s from './styles'

export interface OTPInputProps {
  onChangeText?: (value: string) => void,
  disabled?: boolean,
  autoFocus?: boolean,
  endDate?: string,
  startDate?: string,
  title?: string,
  onTimerEnd?: () => void
  value?: string,
  ref?: Ref<TextInput>
}

export const OTPInputComponent : FunctionComponent<OTPInputProps> = forwardRef((props, ref: any) => {
  const [digitsInput, setDigits] = useState(times(6, () => ''))
  const [valueInput, setValue] = useState('')
  const [, setIsFocused] = useState(false)

  const pinCount = 6

  const characters = valueInput.split('').filter(character => !isNaN(parseInt(character)))

  const blur = () => {
    if (ref && ref.current) { ref.current.blur() }
  }

  const update = (value: string) => {
    const characters = value.split('').filter(character => !isNaN(parseInt(character)))
    const digits = [...characters.slice(0, pinCount), ...times(pinCount - characters.length, () => '')]

    setDigits(digits)
    setValue(value)

    const isFulfilled = characters.length >= pinCount
    if (isFulfilled) {
      blur()
      props.onChangeText && props.onChangeText(digits.join(''))
    }
  }

  const focus = () => {
    if (ref && ref.current) ref.current.blur()

    setTimeout(() => {
      if (ref && ref.current) ref.current.focus()
    }, 100)
  }

  const clearFrom = (index: number) => {
    const clearedCode = [...digitsInput.slice(0, index), ...times(pinCount - index, () => '')]

    setDigits(clearedCode)
    setValue(valueInput)

    focus()
  }

  useEffect(() => {
    if (ref && ref.current) {
      focus()
    }
  }, [ref])

  useEffect(() => {
    if (props.value) {
      update(props.value)
    }
  }, [props.value])

  const renderHiddenInput = () => {
    return (
      <View style={s.hidden}>
        <Input
          autoFocus={props.autoFocus}
          maxLength={6}
          onFocus={() => { setIsFocused(true) }}
          onBlur={() => { setIsFocused(false) }}
          hasClear={false}
          textContentType='oneTimeCode'
          caretHidden
          ref={ref}
          value={digitsInput.join('')}
          keyboardType='phone-pad'
          onChangeText={(value: string) => update(value)}
        />
      </View>
    )
  }

  return (
    <View style={s.otpContainer}>
      <View style={s.container}>
        {renderHiddenInput()}

        {digitsInput.map((value, index) => {
          return (
            <View style={s.inputWrap} key={index}>
              <TouchableOpacity accessibilityLabel='SMSCodeInput' onPress={() => { clearFrom(index) }}>
                <Input
                  hasClear={false}
                  editable={false}
                  inputStyle={[
                    s.input,
                    (characters.length > index) ? s.focused : {},
                    { height: 'auto', maxHeight: 50, width: 25 },
                  ]}
                  keyboardType='number-pad'
                  value={value}
                />
              </TouchableOpacity>
            </View>
          )
        })}
      </View>
      {props.startDate && props.endDate &&
        <Timer
          accessibilityLabel='otpInputTimer'
          textTimer={props.title}
          endDate={props.endDate}
          startDate={props.startDate}
          onTimerEnd={props.onTimerEnd}
        />}
    </View>
  )
})

export const OTPInput = OTPInputComponent
