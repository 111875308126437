import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { TransferTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$, refreshSold$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    TransferTypes.LOADING_FEES_FAILURE,
    TransferTypes.LOADING_FEES_SUCCESS,
    TransferTypes.LOADING_FEES_REQUEST,
    TransferTypes.CREATE_TRANSFER_CANCELLED,
    TransferTypes.CREATE_TRANSFER_FAILURE,
    TransferTypes.CREATE_TRANSFER_REQUEST,
    TransferTypes.CREATE_TRANSFER_SUCCESS,
    TransferTypes.LIST_TRANSFER_REQUEST,
    TransferTypes.LIST_TRANSFER_SUCCESS,
    TransferTypes.LIST_TRANSFER_FAILURE,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    TransferTypes.DELETE_TRANSFER_FAILURE,
    TransferTypes.LOADING_FEES_FAILURE,
    TransferTypes.CREATE_TRANSFER_FAILURE,
    TransferTypes.UPDATE_TRANSFER_FAILURE,
    TransferTypes.DELETE_TRANSFER_FAILURE,
  ])
}

const refreshBankAccountSold$ = (actions$: ActionsObservable<AnyAction>) => {
  return refreshSold$(actions$, [
    TransferTypes.CREATE_TRANSFER_SUCCESS,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
  refreshBankAccountSold$,
]
