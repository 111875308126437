import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

const styles = StyleSheet.create({
  container: {
    marginBottom: 16,
    borderColor: Colors.green,
    alignSelf: 'stretch',
  },
  inputContainer: {
    marginVertical: 1,
    borderWidth: 1,
    borderColor: Colors.gray2,
    borderRadius: 12,
    paddingHorizontal: 16,
    backgroundColor: Colors.white,
  },
  inputContainerFocused: {
    marginVertical: 0,
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  inputContainerError: {
    borderWidth: 1,
    borderColor: Colors.red,
  },
  label: {
    display: 'flex',
    position: 'absolute',
    left: 15,
    top: -14,
    backgroundColor: Colors.white,
    paddingHorizontal: 4,
    color: Colors.gray2,
  },
  labelError: {
    color: Colors.red,
  },
  textInput: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 16,
    color: Colors.black,
    height: 42,
    width: '100%',
  },
  textInputError: {
    color: Colors.red,
  },
  textError: {
    fontFamily: 'Quicksand-Medium',
    paddingLeft: 16,
    color: Colors.red,
  },
  icon: {
    position: 'absolute',
    right: 16,
    top: '50%',
    marginTop: -13,
  },
})

export default styles
