import React, { FunctionComponent } from 'react'
import { Text, TextStyle } from 'react-native'

import { Typo } from '@neo-commons/styles'

export type Typeface =
  'paragraphe'
  | 'header'
  | 'headerTab'
  | 'heading'
  | 'accountHeader'
  | 'accountAmount'
  | 'transactionAmount'
  | 'dateTransactionTitle'
  | 'contentLightGrey'
  | 'bigTitle'
  | 'bigTitle1'
  | 'bigTitleLight'
  | 'semiBold'
  | 'bold'
  | 'boldSecondary'
  | 'titleH4'
  | 'titleH4Secondary'
  | 'title'
  | 'tabLabel'
  | 'tinyBoldLabel'
  | 'subtitle'
  | 'subtitle2'
  | 'subtitleGrey'
  | 'subtitleLight'
  | 'smallText'
  | 'smallTextLight'
  | 'smallTextWeightMedium'
  | 'content'
  | 'crossedContent'
  | 'info'
  | 'infoRegular'
  | 'input'
  | 'button'
  | 'buttonLight'
  | 'pinText'
  | 'itemTitle'
  | 'itemSubtitle'
  | 'menuItemTitle'
  | 'selectedTitle'
  | 'tabTitle'
  | 'appName'
  | 'boldMedium'
  | 'offerPrice'
  | 'offerSmallText'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'overline'
  | 'button1'
  | 'button2'
  | 'label'
  | 'cardTitle'
  | 'cardSubtitle'
  | 'subListItem'

    interface TypographyProps {
      typeface: Typeface,
      style?: TextStyle[] | TextStyle,
      accessibilityLabel?: string,
      numberOfLines?: number
      onPress?: () => void
      ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip'
    }

    export const Typography: FunctionComponent<TypographyProps> =
      ({
        typeface,
        children,
        style,
        accessibilityLabel,
        numberOfLines = 0,
        onPress,
        ellipsizeMode = 'tail',
      }) => {
        return (
          <Text
            style={[Typo[typeface] ?? {}, style]}
            allowFontScaling={false}
            accessibilityLabel={accessibilityLabel}
            numberOfLines={numberOfLines}
            onPress={onPress ? () => onPress() : undefined}
            ellipsizeMode={ellipsizeMode}
          >
            {children}
          </Text>
        )
      }
