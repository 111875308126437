import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { View } from 'react-native'

import { StateTypes } from 'Store'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { TunnelContent } from 'UI/Components/Layout/TunnelContent'
import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'

import { SecretQuestionSelectors, SignupProfileState, SignInActions, SignupActions } from '@neo-commons/store'
import { Button, ButtonSkin, Stepper2, Input2, Typography2 } from '@neo-commons/components'
import { RegisterStep, RegisterSteps, StepComponentProps } from '@neo-commons/policies'
import { Validators } from '@neo-commons/libraries'

import { SecretQuestionDto, OsTypeDto } from '@afone/neo-core-client/dist/models'

import { DataItemType, RSSelect } from '@neo-web/componentsOld'

import { AppConfig } from '../../../../../Config/AppConfig'

import './SecretQuestion.scss'

type SecretQuestionProps = StepComponentProps<RegisterSteps[RegisterStep.SecretQuestion]>

export const SecretQuestion: React.FC<SecretQuestionProps> = ({ nextStep }: SecretQuestionProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const profile: SignupProfileState = useSelector((state: StateTypes) => state?.signup?.profile)
  const secretQuestionList = useSelector(SecretQuestionSelectors.list)

  const [secretQuestionAnswer, setSecretQuestionAnswer] =
    useState(profile?.secretQuestionResponse || '')
  const [secretQuestionUuid, setSecretQuestionUuid] =
    useState('')
  const [secretQuestion, setSecretQuestion] =
    useState(profile?.secretQuestion || '')
  const [isAnswerCorrect, setIsAnswerCorrect] =
    useState(false)

  const register = () => {
    if (secretQuestionUuid) {
      const phoneNumber = parsePhoneNumber(profile.phone, profile.countryCode as CountryCode)
      dispatch(SignInActions.prepare(phoneNumber.formatInternational(), profile.countryCode))
      dispatch(SignupActions.secretQuestion(secretQuestionAnswer, secretQuestion))

      nextStep({
        secretQuestionUuid: secretQuestionUuid,
        secretQuestionAnswer: secretQuestionAnswer,
        deviceOs: OsTypeDto.WEB,
        deviceName: navigator.userAgent,
      }).onDone(() =>
        AnalyticsHandler.track(AnalyticsEvents.signUp.userSignUpStepCompleted,
          {
            step_name: RegisterStep.SecretQuestion,
            email: profile?.email,
            phone: phoneNumber.formatInternational().replace(/ /g, ''),
          })
      )
    }
  }

  function convertToDataItem (secretQuestion: SecretQuestionDto): DataItemType {
    return {
      value: secretQuestion.uuid,
      label: secretQuestion.question,
    }
  }

  useEffect(() => {
    if (secretQuestionList.length > 0 && profile?.secretQuestion) {
      const selectedSecretQuestionUuid = secretQuestionList && secretQuestionList.map(convertToDataItem)
        .find((question: DataItemType) => question.label === profile?.secretQuestion).value
      setSecretQuestionUuid(selectedSecretQuestionUuid)
    }
  }, [secretQuestionList])

  return (
    <TunnelContent
      header={
        <View>
          <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
            {t('app:pages:register:step1:contact:signup')}
          </Typography2>
          <Stepper2 currentStep={1} />
          <Typography2 typeface='h5'>
            {t('app:pages:register:step4:secretQuestion:titleMessage')}
          </Typography2>
          <Typography2 typeface='body1'>
            {t('app:pages:register:step4:secretQuestion:subtitleMessage')}
          </Typography2>
        </View>
      }
      footer={secretQuestionUuid && (
        <Button
          title={t('app:global:validate')}
          skin={ButtonSkin.PRIMARY}
          disabled={
            !(Boolean(secretQuestionUuid) && Boolean(secretQuestionAnswer)) || !isAnswerCorrect
          }
          onPressOut={() => {
            register()
          }}
        />)}
    >
      <div className='SecretQuestion_container'>
        <div className='SecretQuestion_container_item'>
          <RSSelect
            value={secretQuestionUuid || null}
            data={secretQuestionList.map(convertToDataItem)}
            onChange={secretQuestionUuid => {
              setSecretQuestionUuid(secretQuestionUuid)
              const selectedSecretQuestion = secretQuestionList.map(convertToDataItem)
                .find((question: DataItemType) => question.value === secretQuestionUuid)?.label
              setSecretQuestion(selectedSecretQuestion)
            }}
            appearance='subtle'
            searchable={false}
            placeholder={
              <Typography2 typeface='label'>{t('app:pages:register:step4:secretQuestion:titleMessage')}</Typography2>
            }
          />
        </div>
        {secretQuestionUuid && (
          <div className='SecretQuestion_container_item'>
            <Input2
              editable
              label={t('app:pages:register:step4:secretQuestion:answer')}
              value={secretQuestionAnswer}
              onChangeText={(value: string) => setSecretQuestionAnswer(value)}
              validators={[Validators.minLength(AppConfig.MIN_LENGTH_SECRET_QUESTION) &&
                Validators.maxLength(AppConfig.MAX_LENGTH_SECRET_QUESTION)]}
              onValidationChecked={(status) => {
                setIsAnswerCorrect(status.isValid)
              }}
            />
          </div>)}
      </div>
    </TunnelContent>
  )
}
