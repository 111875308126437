import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Typography } from '@neo-commons/components'
import { ResetPasscodeStep, ResetPasscodeSteps, StepComponentProps } from '@neo-commons/policies'

import './Success.scss'

type SuccessProps = StepComponentProps<ResetPasscodeSteps[ResetPasscodeStep.Success]>

export const Success: React.FC<SuccessProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className='Success_container'>
      <div className='Success_item'>
        <Typography typeface='bigTitle'>
          {t('app:pages:payments:code:result:title')}
        </Typography>
      </div>

      <div className='Result_action'>
        <Button
          title={t('app:pages:payments:code:result:button')}
          skin={ButtonSkin.OUTLINE_PRIMARY}
          onPressOut={() => {
            history.push(Routes.Profile.preference.path)
          }}
        />
      </div>
    </div>
  )
}
