import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Notification, useToaster } from 'rsuite'
import { RootActions, StateTypes } from 'Store'
import { config, ENV } from 'Config/EnvConfig'
import Colors from 'UI/Resources/Styles/sass/themes/Colors'

import { AlertType } from '@neo-commons/store'
import { IconTypes, Icon, Typography2 } from '@neo-commons/components'

export const AlertHandler = (): React.ReactElement => {
  const toaster = useToaster()
  const dispatch = useDispatch()

  const alert = useSelector((state: StateTypes) => state.root.alert)

  // To stop double alert (Not pretty, to improve with DS)
  const [lastAlert, setLastAlert] = useState(undefined)

  // To initialize toaster without the first alert doesn't show
  useEffect(() => {
    (async () => {
      await toaster.push(<></>, {
        placement: 'topEnd',
      })
    })()
  }, [])

  useEffect(() => {
    if ((typeof alert?.type === 'string') && (alert !== lastAlert || !lastAlert)) {
      setLastAlert(alert)
      let style = {}
      let title = alert.title
      let description = alert.description ?? alert.title

      if (title) {
        switch (alert.type) {
          case AlertType.SUCCESS:
            title = <Icon name='check' type={IconTypes.FEATHER} size={25} color='white' />
            style = { backgroundColor: Colors.success }
            break
          case AlertType.WARNING:
            title = <Icon name='warning' type={IconTypes.ANT_DESIGN} size={25} />
            style = { backgroundColor: Colors.warning }
            break
          case AlertType.ERROR:
            title = <Icon name='alert-circle' type={IconTypes.FEATHER} size={25} color='white' />
            style = { backgroundColor: Colors.error }
            break
          default:
            if (title === description) description = ''
        }

        toaster.push(
          <Notification
            type={alert.type}
            duration={parseInt(config(ENV.TIME_ERROR_DISPLAY))}
            onClose={() => {
              dispatch(RootActions.resetAlert())
              setLastAlert(undefined)
            }}
            style={style}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
              {title}
              <Typography2
                typeface='body2'
                style={{ color: alert.type === AlertType.WARNING ? 'black' : 'white' }}
              >
                {description}
              </Typography2>
            </div>
          </Notification>,
          { placement: 'topEnd' }
        )
      }
    }
  }, [alert, toaster])

  return null
}
