import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BusinessSubscriptionStep, BusinessSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { ClientUtils } from '@neo-commons/libraries'
import { ClientSelectors } from '@neo-commons/store'

import { ClientDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

type VerifyAffiliatesDocumentsPedagogyProps =
  StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.VerifyAffiliatesDocumentsPedagogy]>

export const VerifyAffiliatesDocumentsPedagogy: React.FC<VerifyAffiliatesDocumentsPedagogyProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const client: ClientDto = useSelector(ClientSelectors.defaultOne)

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.CENTER}
      header={
        <div className='TunnelContent_container'>
          <div style={{ paddingBottom: 40 }}>
            <Icon name='organization' type={IconTypes.NEOFONT} size={50} color={Colors.secondary} />
          </div>
          <div className='TunnelContent_container_text'>
            <Typography2 typeface='h4'>
              {t('app:pages:subscription:business:verifyAffiliatesPedagogy:title',
                { typeBusiness: ClientUtils.isAssociation(client) ? 'association' : 'entreprise' })}
            </Typography2>
          </div>
          <div>
            <Typography2 typeface='body1'>
              {t('app:pages:subscription:business:verifyAffiliatesPedagogy:description')}
            </Typography2>
          </div>
        </div>
      }
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:pages:subscription:business:verifyAffiliatesPedagogy:button')}
          onPress={nextStep}
        />
      }
    />
  )
}
