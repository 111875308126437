import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from '@neo-customer-front/applications/neo-web/src/Store'

import { BusinessSubscriptionStep, BusinessSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Icon, IconTypes, Stepper2, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { SubscriptionUtils, ClientUtils } from '@neo-commons/libraries'
import { ClientActions, ClientSelectors, Dispatch, SubscriptionSelectors } from '@neo-commons/store'

import { AffiliationTypeDto, ClientDto, SubscriptionDto, UserDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

type IdentifyAffiliatesProps =
  StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.IdentifyAffiliatesPedagogy]>

export const IdentifyAffiliatesPedagogy: React.FC<IdentifyAffiliatesProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()

  const user: UserDto = useSelector((state: StateTypes) => state.user.data)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.CENTER}
      header={
        <div className='TunnelContent_container'>
          <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
            {t('neo-commons:subscription:business:identifyAffiliates:title')}
          </Typography2>
          <Stepper2 currentStep={1} />
          <div style={{ paddingBottom: 20 }}>
            <Icon name='organization' type={IconTypes.NEOFONT} size={50} color={Colors.secondary} />
          </div>
          <div className='TunnelContent_container_text'>
            <Typography2 typeface='h4'>
              {t('app:pages:subscription:business:identifyAffiliatesPedagogy:title',
                { typeBusiness: ClientUtils.isAssociation(client) ? 'association' : 'entreprise' })}
            </Typography2>
          </div>
          <div>
            <Typography2 typeface='body1'>
              {ClientUtils.isAssociation(client)
                ? t('app:pages:subscription:business:identifyAffiliatesPedagogy:descriptionAssociation')
                : SubscriptionUtils.withKbis(subscription)
                  ? t('app:pages:subscription:business:identifyAffiliatesPedagogy:descriptionKbis')
                  : t('app:pages:subscription:business:identifyAffiliatesPedagogy:descriptionOtherBusiness')}
            </Typography2>
          </div>
        </div>
      }
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:continue')}
          onPress={async () => {
            await dispatch(ClientActions.createAffiliate(client, {
              types: [AffiliationTypeDto.LEGAL_REPRESENTATIVE],
              person: {
                firstName: user?.firstName,
                lastName: user?.lastName,
                phone: {
                  internationalFormat: user?.phone?.internationalFormat,
                },
                email: user?.email,
              },
            }))
            nextStep()
          }}
        />
      }
    />
  )
}
