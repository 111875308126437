import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { PageContent, PageDetails, SuccessContent } from '@neo-web/componentsOld'

export enum TypeRemoveAccount {
  DELETE = 'DELETE',
  DEACTIVATE = 'DEACTIVATE',
}

export const RemovedAggregatedAccount:React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location: any = useLocation()

  const title = location?.state?.type === TypeRemoveAccount.DEACTIVATE
    ? t('app:pages:synthesis:removedAggregatedAccount:deactivateTitle')
    : t('app:pages:synthesis:removedAggregatedAccount:removeTitle')
  const subTitle = location?.state?.type === TypeRemoveAccount.DEACTIVATE
    ? t('app:pages:synthesis:removedAggregatedAccount:deactivateSubtitle', { bank: location?.state?.bankName })
    : t('app:pages:synthesis:removedAggregatedAccount:removeSubtitle', { bank: location?.state?.bankName })
  return (
    <PageDetails
      onClose={() => history.push(Routes.Services.aggregation.path)}
      onBack={null}
      component={
        <PageContent>
          <SuccessContent
            title={title}
            description={subTitle}
            buttonBack={{
              text: t('app:global:goBackToSynthesis'),
              action: () => history.push(Routes.Synthesis.index.path),
            }}
          />
        </PageContent>
      }
    />
  )
}
