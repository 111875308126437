import i18next from 'i18next'
import _ from 'lodash'

/**
   * Transform the result of API according to the response
   *
   */
export class ApiUtils {
  /**
   * Response the message if exists or send a unknown technical issue.
   *
   * @param {{ message: string }} response - Object with the message.
   * @returns {string} Return the message.
   * @example
   * reject(ApiUtils._getErrorMessage(responseJson))
   *
   */
  public static _getErrorMessage = (response: { message: string}) => {
    return _.get(response, 'message', false) ? response.message : i18next.t('errors:unknownTechnicalIssue')
  }

  /**
   * check and transform the reponse according to the case.
   *
   * @param {Promise<Response>} response - response of the API call.
   * @returns {Promise} Return the result of the promise with the response transform.
   *
   */
  public static responseTransformer = (response: Promise<Response>) => {
    return new Promise((resolve, reject) => {
      response
        .then((response: Response) => !_.isEmpty(response) ? response.json() : reject(new Error(i18next.t('errors:invalidResponse'))))
        .then((responseJson) => {
          if (_.get(responseJson, 'ok', false)) {
            resolve(responseJson)
          } else {
            reject(ApiUtils._getErrorMessage(responseJson))
          }
        })
        .catch((error) => {
          reject(ApiUtils._getErrorMessage(error))
        })
    })
  }
}

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
