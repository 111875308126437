import {config, ENV} from "./EnvConfig";

if (config(ENV.ENV) !== 'production' && config(ENV.PROFILE) === 'TRANSLATOR') {
// @ts-ignore
  window.LOKALISE_CONFIG = window.LOKALISE_CONFIG || {
    projectId: '257350105ec2b087da2275.08062668',
    locale: 'en',
    usePanelOffset: false,
  };

// Create stylesheet
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute(
    'src',
    `https://app.lokalise.com/live-js/script.min.js?${(new Date()).getTime()}`
  );
  script.setAttribute('async', `${!0}`);

// Inject scrypt
  document.head.appendChild(script);
}

