import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ButtonSkin,
  Input2,
  Typography,
} from '@neo-commons/components'

import { Modal } from '@neo-web/componentsOld'

import './DiscountCodeModal.scss'

interface DiscountCodeModalProps {
  onClose?: () => void
  show: boolean
  onValidate: (string) => void
}

export const DiscountCodeModal: React.FC<DiscountCodeModalProps> = ({
  onClose,
  show,
  onValidate,
}: DiscountCodeModalProps) => {
  const { t } = useTranslation()

  const [discountCodeValue, setDiscountCodeValue] = useState('')
  const [inputValidated, setInputValidated] = useState(false)

  return (
    <Modal autoFocus open={show} onClose={onClose} displayCross size='xs'>
      <div className='DiscountCodeModal_container'>
        <Typography typeface='h4'>
          {t('app:pages:subscription:global:offers:promoCodeModal:title')}
        </Typography>
        <div className='DiscountCodeModal_input'>
          <Input2
            editable
            label={t(
              'app:pages:subscription:global:offers:promoCodeModal:input'
            )}
            onChangeText={text => {
              setDiscountCodeValue(text)
            }}
            value={discountCodeValue}
            autoFocus
            onValidationChecked={status => {
              setInputValidated(status.isValid)
            }}
          />
        </div>
        <div className='DiscountCodeModal_button'>
          <Button
            disabled={!discountCodeValue}
            skin={ButtonSkin.PRIMARY}
            title={t('app:global:validate')}
            onPress={() => {
              if (inputValidated) {
                onValidate(discountCodeValue)
              }
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
