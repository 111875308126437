import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'
import { AppConfig } from 'Config/AppConfig'

import {
  BankAccountSelectors,
  BankTransferBenificiaryTypeEnum,
  BankTransferTypeEnum,
  ContactSelectors,
  TransferActions,
  TransferSelectors,
  TransferTypes,
} from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { DateUtils, TextUtils } from '@neo-commons/libraries'
import {
  Button,
  ButtonSkin,
  EditItemCard,
  EditItemCardSkin,
  OfferTypography,
} from '@neo-commons/components'

import { LazyLoadingContainer } from '@neo-web/componentsOld'

import './Summary.scss'
interface SummaryProps {
  isEdit?: boolean
}

const editItemCardStyle = { borderColor: Colors.backgroundDarker, padding: 12, borderWidth: 1.5 }

export const Summary: React.FC<SummaryProps> = ({ isEdit }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params: { id: string } = useParams()
  const transferState = useSelector((state: StateTypes) => state?.transfer)
  const onGoingTransfer = transferState.prepare
  const contactSelected = useSelector(ContactSelectors.selected)
  const preparedTransfer = useSelector(TransferSelectors.getPreparedTransfer)
  const beneficiaryBankAccount = useSelector(BankAccountSelectors.byId(preparedTransfer?.recipientAccountUuid))
  const preparedTransferIssuerBankAccount = useSelector(BankAccountSelectors.byId(preparedTransfer?.accountUuid))
  const [beneficiaryTitle, setBeneficiaryTitle] = useState('')

  const sendTransfer = async (isDelete = false) => {
    try {
      if (isDelete) {
        await dispatch(TransferActions.remove(onGoingTransfer))
      } else {
        if (onGoingTransfer.isUpdating) {
          await dispatch(TransferActions.update(onGoingTransfer))
        } else {
          await dispatch(TransferActions.create(onGoingTransfer))
        }
      }

      history.push(Routes.Payments.result.path)
    } catch (_) {}
  }

  useEffect(() => {
    (async () => {
      if (preparedTransfer.amount && (preparedTransfer.contactChannelUuid || preparedTransfer.recipientAccountUuid)) {
        if (preparedTransfer?.benificiaryType === BankTransferBenificiaryTypeEnum.Internal) {
          preparedTransfer.contactChannelUuid = undefined
        } else {
          preparedTransfer.recipientAccountUuid = undefined
        }
        try {
          if (preparedTransfer.transferType !== BankTransferTypeEnum.Punctual) {
            await dispatch(TransferActions.getRecurringTransferByUuid(onGoingTransfer.uuid))
          }
          if (!isEdit) {
            await dispatch(TransferActions.getTransferFees(preparedTransfer))
          }
        } catch (e) { }
      }
    })()
  }, [preparedTransfer.contactChannelUuid, preparedTransfer.recipientAccountUuid, preparedTransfer.amount])

  useEffect(() => {
    onGoingTransfer?.benificiaryType === BankTransferBenificiaryTypeEnum.Recipient
      ? setBeneficiaryTitle(`${contactSelected?.givenName} ${contactSelected?.familyName}` ?? '')
      : setBeneficiaryTitle(beneficiaryBankAccount?.name)
  }, [contactSelected, preparedTransfer, beneficiaryBankAccount])

  useEffect(() => {
    if (isEdit) {
      dispatch(TransferActions.resetPrepare())
    }
  }, [])

  return (
    <LazyLoadingContainer events={[TransferTypes.LOADING_FEES_REQUEST, TransferTypes.CREATE_TRANSFER_REQUEST]}>
      <div className='TransferSummary_container'>
        <div className='TransferSummary_item'>
          <EditItemCard
            skin={EditItemCardSkin.AMOUNT}
            payload={{
              amount: onGoingTransfer?.price?.amount ?? 0,
            }}
            onEditPress={() => history.push(onGoingTransfer.isUpdating ? generatePath(
              Routes.Payments.amount.path,
              { id: params.id }
            ) : Routes.Payments.amount.path)}
            containerStyle={editItemCardStyle}
          />
        </div>

        <div className='TransferSummary_item'>
          <EditItemCard
            skin={EditItemCardSkin.DEFAULT}
            payload={{
              title: TextUtils.prettyShortText(preparedTransferIssuerBankAccount?.name ?? '', AppConfig.MAX_CHARACTER_SUMMARY_TRANSFER),
              subtitles: [
                TextUtils.prettyShortText(
                  onGoingTransfer?.privateLabel ?? '', AppConfig.MAX_CHARACTER_SUMMARY_TRANSFER
                ),
              ],
            }}
            containerStyle={editItemCardStyle}
            onEditPress={() => history.push(isEdit ? generatePath(
              Routes.Payments.account.path,
              { id: params.id }
            ) : Routes.Payments.account.path)}
          />
        </div>
        <div className='TransferSummary_item'>
          <EditItemCard
            skin={EditItemCardSkin.DEFAULT}
            payload={{
              title: TextUtils.prettyShortText(beneficiaryTitle ?? '', AppConfig.MAX_CHARACTER_SUMMARY_TRANSFER),
              subtitles: [
                TextUtils.prettyShortText(onGoingTransfer?.label ?? '', AppConfig.MAX_CHARACTER_SUMMARY_TRANSFER),
              ],
            }}
            containerStyle={editItemCardStyle}
            onEditPress={() => history.push(Routes.Payments.beneficiary.path)}
          />
        </div>

        {onGoingTransfer.transferType === BankTransferTypeEnum.Scheduled &&
          <div className='TransferSummary_item TransferSummary_date'>
            <EditItemCard
              skin={EditItemCardSkin.DEFAULT}
              payload={{
                title: t('app:pages:payments:transfer:summary:scheduled'),
                subtitles: [
                  DateUtils.Iso8601ToHumanDate(onGoingTransfer.startDate),
                ],
              }}
              containerStyle={editItemCardStyle}
              onEditPress={() => history.push(isEdit ? generatePath(
                Routes.Payments.scheduled.path,
                { id: params.id }
              ) : Routes.Payments.scheduled.path)}
            />
          </div>}

        {onGoingTransfer.transferType === BankTransferTypeEnum.Repeated &&
          <div className='TransferSummary_item'>
            <EditItemCard
              skin={EditItemCardSkin.DEFAULT}
              payload={{
                title: t('app:pages:payments:transfer:summary:reccuring'),
                subtitles: [
                  t('app:pages:payments:transfer:summary:startTransfer',
                    { value: DateUtils.Iso8601ToHumanDate(onGoingTransfer.startDate) }),
                  onGoingTransfer.recurrenceName,
                ],
              }}
              containerStyle={editItemCardStyle}
              onEditPress={() => history.push(isEdit ? generatePath(
                Routes.Payments.recurring.path,
                { id: params.id }
              ) : Routes.Payments.recurring.path)}
            />
          </div>}

        <div className='TransferSummary_item TransferSummary_fees'>
          {onGoingTransfer?.price &&
            <OfferTypography
              isOffer={onGoingTransfer?.price?.feesTotal === 0}
              value={onGoingTransfer?.price?.fees ?? 0}
              label={t('app:pages:payments:transfer:label:fees')}
              offerLabel={t('app:pages:payments:transfer:label:offer')}
            />}
        </div>
        {(isEdit || onGoingTransfer.isUpdating) &&
          <div className='TransferSummary_action'>
            <Button
              title={t('app:pages:payments:transfer:summary:delete')}
              skin={ButtonSkin.OUTLINE_SECONDARY}
              onPress={async () => {
                await sendTransfer(true)
              }}
            />
          </div>}
        <div className='TransferSummary_action'>
          <Button
            title={t('app:pages:payments:transfer:summary:confirm')}
            skin={ButtonSkin.PRIMARY}
            onPress={async () => {
              await sendTransfer()
            }}
          />
        </div>
      </div>
    </LazyLoadingContainer>
  )
}
