import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import { Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import {
  BankAccountSelectors,
  BankTransferBenificiaryTypeEnum,
  ClientSelectors,
  TransferActions,
} from '@neo-commons/store'
import { BankAccountUtils, ClientUtils, EnumUtils, Formatters, NumbersUtils } from '@neo-commons/libraries'

import { AccountDto, ClientDto } from '@afone/neo-core-client/dist/models'

import { PriceCard, PriceCardSkin } from '@neo-web/componentsOld'
import { useIcon } from '@neo-web/hooks'

import './Beneficiary.scss'

export const Beneficiary: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const transferState = useSelector((state: StateTypes) => state?.transfer)
  const onGoingTransfer = transferState.prepare
  const preparedTransferBankAccount = useSelector(BankAccountSelectors.byId(onGoingTransfer?.accountUuid))
  const preparedTransferBankAccountParent: AccountDto = useSelector(BankAccountSelectors.parentFor(onGoingTransfer?.accountUuid))
  const filteredSubAccounts: AccountDto[] = useSelector(BankAccountSelectors.getSubAccountsByUuid(onGoingTransfer?.accountUuid)).filter(item => !BankAccountUtils.isPos(item))
  const filteredMainBankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listMain).filter(item => item.uuid !== onGoingTransfer?.accountUuid).filter(item => !BankAccountUtils.isAggregated(item))
  const clients: Array<ClientDto> = useSelector(ClientSelectors.list)

  const selectItem = useCallback((bankAccount: AccountDto) => {
    dispatch(TransferActions.prepare({
      ...onGoingTransfer,
      benificiaryType: BankTransferBenificiaryTypeEnum.Internal,
      recipientAccountUuid: bankAccount?.uuid,
      recipientAccountName: bankAccount?.name,
      contactChannelUuid: undefined,
      contactChannelValue: undefined,
      contactFamilyName: undefined,
      contactGivenName: undefined,
    }))
  }, [])

  const bankAccountsListContent = useMemo(() => {
    const filteredBankAccounts: AccountDto[] = filteredSubAccounts.concat(filteredMainBankAccounts)
    if (BankAccountUtils.isSecondary(preparedTransferBankAccount)) {
      const client: ClientDto = ClientUtils.getClientFromBankAccount(preparedTransferBankAccountParent, clients)
      return (
        <div className='Beneficiary_item'>
          <div className='Beneficiary_card'>
            <PriceCard
              isLarge={false}
              selected={preparedTransferBankAccountParent.uuid === onGoingTransfer.recipientAccountUuid}
              skin={PriceCardSkin.DEFAULT}
              icon={useIcon(preparedTransferBankAccountParent, client?.type, preparedTransferBankAccountParent.uuid === onGoingTransfer.recipientAccountUuid)}
              price={NumbersUtils.displayPriceForHuman(preparedTransferBankAccountParent.balance)}
              title={BankAccountUtils.getName(preparedTransferBankAccountParent)}
              subTitle={Formatters.formatIban(preparedTransferBankAccountParent.iban ?? '', true)}
              onClick={() => {
                selectItem(preparedTransferBankAccountParent)
                history.push(Routes.Payments.amount.path)
              }}
            />
          </div>
        </div>
      )
    } else if (filteredBankAccounts.length <= 0) {
      return (
        <div className='Beneficiary_empty'>
          <div className='Beneficiary_empty_item'>
            <Icon type={IconTypes.MATERIAL} name='account-balance' size={64} color={Colors.secondary} />
          </div>
          <div className='Beneficiary_empty_item'>
            <Typography typeface='bold'>
              {
                t('app:pages:payments:account:list:empty:title')
              }
            </Typography>
          </div>
          <div className='Beneficiary_empty_item'>
            <Typography typeface='content'>
              {
                t('app:pages:payments:account:list:empty:text')
              }
            </Typography>
          </div>
        </div>
      )
    } else {
      return (
        <div className='Beneficiary_item'>
          {filteredBankAccounts.map(account => {
            const client: ClientDto = ClientUtils.getClientFromBankAccount(account, clients)
            return (
              <div
                key={account.uuid}
                className={
                  `Beneficiary_card ${BankAccountUtils.isSecondary(account) ? 'secondary' : ''}`
                }
              >
                <PriceCard
                  isLarge={false}
                  selected={account.uuid === onGoingTransfer.recipientAccountUuid}
                  skin={BankAccountUtils.isSecondary(account) ? PriceCardSkin.SECONDARY : PriceCardSkin.DEFAULT}
                  icon={useIcon(account, client?.type, account.uuid === onGoingTransfer.recipientAccountUuid)}
                  price={NumbersUtils.displayPriceForHuman(account.balance)}
                  title={BankAccountUtils.getName(account)}
                  subTitle={
                    BankAccountUtils.isSecondary(account) ? t(EnumUtils.getAccountTypeDtoKey(account.type)) : Formatters.formatIban(account.iban ?? '', true)
                  }
                  onClick={() => {
                    selectItem(account)
                    history.push(Routes.Payments.amount.path)
                  }}
                />
              </div>
            )
          })}
        </div>
      )
    }
  }, [preparedTransferBankAccount])

  return (
    <div className='Beneficiary_container'>
      {bankAccountsListContent}
    </div>
  )
}
