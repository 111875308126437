import { AnyAction } from 'redux'
import { ActionsObservable, ofType } from 'redux-observable'
import { tap, ignoreElements } from 'rxjs/operators'
import i18next from 'i18next'

import { SignupTypes } from '@neo-commons/store'

import { ENV, config } from '../../Config/EnvConfig'
import { displayAlertGlobal$, loading$ } from '../Epics'
import { store, Dispatch } from '../../Store'

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    SignupTypes.CHECK_EMAIL_STATUS_FAILURE,
    SignupTypes.TRIGGER_PHONE_VERIFY_FAILURE,
    SignupTypes.TRIGGER_EMAIL_VERIFY_FAILURE,
    SignupTypes.VERIFY_PHONE_FAILURE,
    SignupTypes.VERIFY_EMAIL_FAILURE,
    SignupTypes.PASSWORD_CHECK_FAILURE,
    SignupTypes.CONFIRM_PASSWORD_CHECK_FAILURE,
    SignupTypes.REGISTER_FAILURE,
  ])
}

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    SignupTypes.REGISTER_REQUEST,
    SignupTypes.REGISTER_SUCCESS,
    SignupTypes.REGISTER_FAILURE,
    SignupTypes.PASSWORD_CHECK_REQUEST,
    SignupTypes.PASSWORD_CHECK_SUCCESS,
    SignupTypes.PASSWORD_CHECK_FAILURE,
    SignupTypes.CONFIRM_PASSWORD_CHECK_REQUEST,
    SignupTypes.CONFIRM_PASSWORD_CHECK_FAILURE,
    SignupTypes.CONFIRM_PASSWORD_CHECK_SUCCESS,
    SignupTypes.TRIGGER_PHONE_VERIFY_REQUEST,
    SignupTypes.TRIGGER_PHONE_VERIFY_FAILURE,
    SignupTypes.TRIGGER_PHONE_VERIFY_SUCCESS,
  ])
}

const processVerifyEmail$ = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    ofType(SignupTypes.VERIFY_EMAIL_WAITING),
    tap(async (action: AnyAction) => {
      const dispatch = store.dispatch as Dispatch

      const { otpEmailUuid } = action
      const SSE = new EventSource(`${config(ENV.SSE_URL)}?id=${otpEmailUuid}&ttl=${config(ENV.SSE_TIME_TO_LIVE)}`)

      SSE.onmessage = () => {
        dispatch({ type: SignupTypes.VERIFY_EMAIL_SUCCESS })
        SSE.close()
      }
      SSE.onerror = () => {
        dispatch({ type: SignupTypes.VERIFY_EMAIL_FAILURE, errorMessage: i18next.t('errors:internalTechnicalIssue') })
      }
    }
    ),
    ignoreElements()
  )

export default [
  displayDropdownAlert$,
  reactiveLoader$,
  processVerifyEmail$,
]
