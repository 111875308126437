import React from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { BankAccountSelectors } from '@neo-commons/store'
import { Badge, BadgeSkin, MenuItemCard, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { PosByAccount } from '@neo-commons/libraries'

import { AccountDto, SubscriptionOrderStatusDto, PosDto, PosStatusDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'

import { ImageWrapper } from '@neo-web/componentsOld'

import './PosByAccounts.scss'

interface PosByAccountsProps {
  title?: string,
  posList: PosByAccount,
  newPosIds?: string[],
  subscriptions?: SubscriptionDto[],
  selected?: PosDto[],
  onPress?: (pos: PosDto) => void,
}

export const PosByAccounts: React.FC<PosByAccountsProps> = (props: PosByAccountsProps) => {
  const history = useHistory()
  const { t } = useTranslation()

  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts())

  return (
    <>
      {Object.entries(props.posList).map(([accountUuid, posList]) => (
        <div className='PosByAccounts' key={accountUuid}>
          {!!posList.length &&
            <Typography2 typeface='overline'>
              {props?.title
                ? props.title
                : bankAccounts.find(account => account.uuid === accountUuid)?.name ?? ''}
            </Typography2>}
          {posList.map(pos => {
            let enabled = true
            if (props?.subscriptions) {
              const posStatus: PosStatusDto[] = [PosStatusDto.ACTIVE]
              const subStatus: SubscriptionOrderStatusDto[] = [SubscriptionOrderStatusDto.DELIVERED, SubscriptionOrderStatusDto.ACTIVATED]
              const sub: any = props?.subscriptions?.find(sub => sub.uuid === pos?.workflowSubscriptionUuid)
              enabled = posStatus.includes(pos.status) && sub && subStatus.includes(sub.orderStatus)
            }

            const newPos = props?.newPosIds && props.newPosIds.includes(pos.uuid)
            const selected = props?.selected && props.selected.find(selectedPos => selectedPos.uuid === pos.uuid)

            return (
              <div className='PosByAccounts_card' key={pos?.uuid}>
                <MenuItemCard
                  title={newPos
                    ? <Badge skin={BadgeSkin.PRIMARY} value={t('app:pages:services:pos:newPos')} />
                    : pos?.name ?? t('app:pages:services:pos:title')}
                  subtitle={pos?.serialNumber
                    ? `${t('app:pages:services:pos:detail:posSerialNumberShort')} : ${pos.serialNumber}`
                    : (pos?.label ?? pos?.systemProductCode)}
                  containerStyle={selected && { borderColor: Colors.primary }}
                  chevronContainerStyle={props?.onPress && { opacity: 0 }}
                  disabled={!enabled}
                  icon={(
                    <ImageWrapper
                      uri={pos?.urlImage}
                      width={50}
                      height={50}
                      wrapperStyle={{ borderRadius: 5 }}
                    />
                  )}
                  onPress={() => props?.onPress
                    ? props.onPress(pos)
                    : history.push(generatePath(Routes.Services.detailPos.path, { id: pos.uuid }))}
                />
              </div>
            )
          })}
        </div>
      ))}
    </>
  )
}
