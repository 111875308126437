import React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'

import { Button, ButtonSkin, Typography } from '@neo-commons/components'
import { PasswordKeypadData } from '@neo-commons/hooks'

import { PasswordKeypad } from './PasswordKeypad'
import './CodeConfirm.scss'

interface CodeConfirmProps {
  title: string,
  onValidate: (confirmPassword: PasswordKeypadData) => void,
  error?: string,
  keypadData: PasswordKeypadData
}

export const CodeConfirm: React.FC<CodeConfirmProps> = ({ title, onValidate, error, keypadData }
  : CodeConfirmProps) => {
  const { t } = useTranslation()

  return (
    <FlexboxGrid justify='space-between' align='middle' className='CodeConfirm text-center'>
      <FlexboxGrid.Item colspan={24} className='CodeConfirm_text'>
        <Typography typeface='bigTitle'>
          {title}
        </Typography>
      </FlexboxGrid.Item>

      {error &&
        <FlexboxGrid.Item colspan={24} className='CodeConfirm_error'>
          <Typography typeface='info'>
            {error}
          </Typography>
        </FlexboxGrid.Item>}

      <FlexboxGrid.Item colspan={24} className='CodeConfirm_item'>
        <div className='div-center'>
          <PasswordKeypad
            {...keypadData}
            withoutValidate
          />
        </div>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24}>
        <Button
          title={t('app:global:validate')} skin={ButtonSkin.PRIMARY}
          disabled={!keypadData.isFilled}
          onPressOut={() => onValidate(keypadData)}
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}
