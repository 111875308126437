import i18next from 'i18next'
import { IconTypes } from '@neo-commons/components'
import { SignupActions } from '@neo-commons/store'
import { NeobankApi } from '@neo-commons/services'

import { invokeActionCreator, createWizardPolicy, StepperStepStatus } from '../../utils'

export const machineId = 'register'

/*
 * Guards
 */

const isOfflineRegistration = context => context?.store.getState().signup.profile.isOfflineRegistration

const isAgentClientOrOffline = (context) => NeobankApi.getInstance().isAgentClient() || isOfflineRegistration(context)

const isPhoneFilled = (context) => {
  return !!context?.store.getState().signup.profile.phone && !isOfflineRegistration(context)
}
const isPhoneVerified = (context) => {
  return context?.store.getState().signup.profile.isPhoneVerified
}
const isEmailFilled = (context) => {
  return context?.store.getState().signup.profile.isEmailAvailable
}
const isContactFilled = (context) => {
  return isPhoneFilled(context) && isEmailFilled(context)
}
const isEmailVerified = (context) => {
  return context?.store.getState().signup.profile.isEmailVerified
}
const isPasswordChecked = (context) => {
  const profileStore = context?.store.getState().signup.profile
  return profileStore.isPasswordChecked && profileStore.confirmPassword
}
const isPasswordConfirmed = (context) => {
  const { password, confirmPassword } = context?.store.getState().signup.profile
  return !!password && !!confirmPassword
}
const isRegistered = (context) => {
  return context?.store.getState().signup.profile.isRegistered
}

/*
 * Stepper
 */

export enum RegisterStepperStep {
  Contact = 'Contact',
  Email = 'Email',
  PersonalCode = 'PersonalCode',
  SecretQuestion = 'SecretQuestion'
}

const stepperSteps = {
  [RegisterStepperStep.Contact]: {
    title: () => i18next.t('neo-commons:register:stepper:phoneNumber'),
    icon: { name: 'sms', size: 30, type: IconTypes.NEOFONT },
  },
  [RegisterStepperStep.Email]: {
    title: () => i18next.t('neo-commons:register:stepper:emailAddress'),
    icon: { name: 'e_mail', size: 30, type: IconTypes.NEOFONT },
  },
  [RegisterStepperStep.PersonalCode]: {
    title: () => i18next.t('neo-commons:register:stepper:personalCode'),
    icon: { name: 'code', size: 30, type: IconTypes.NEOFONT },
  },
  [RegisterStepperStep.SecretQuestion]: {
    title: () => i18next.t('neo-commons:register:stepper:secretQuestion'),
    icon: { name: 'security_question', size: 30, type: IconTypes.NEOFONT },
  },
}

/*
 * Steps
 */

export enum RegisterStep {
  Contact = 'Contact',
  Sms = 'Sms',
  EmailVerify = 'EmailVerify',
  Code = 'Code',
  CodeConfirm = 'CodeConfirm',
  CodeValidate = 'CodeValidate',
  SecretQuestion = 'SecretQuestion',
  Complete = 'Complete'
}

export const registerSteps = {
  [RegisterStep.Contact]: {
    path: 'contact',
    nextStep: RegisterStep.Sms,
    skipIf: isContactFilled,
    skipTo: RegisterStep.Sms,
    fulfill: invokeActionCreator(SignupActions.triggerPhoneVerify),
    stepper: {
      step: stepperSteps[RegisterStepperStep.Contact],
      status: StepperStepStatus.CURRENT,
    },
    meta: {
      screenTitle: 'signup:title',
      canGoBack: true,
    },
  },
  [RegisterStep.Sms]: {
    path: 'sms',
    nextStep: RegisterStep.EmailVerify,
    skipIf: isPhoneVerified,
    skipTo: RegisterStep.EmailVerify,
    fulfill: invokeActionCreator(SignupActions.verifyPhone),
    stepper: {
      step: stepperSteps[RegisterStepperStep.Contact],
      status: StepperStepStatus.CURRENT,
    },
    history: {
      preventStack: true,
    },
    meta: {
      screenTitle: 'signup:title',
      canGoBack: true,
    },
  },
  [RegisterStep.EmailVerify]: {
    path: 'email/verify',
    nextStep: RegisterStep.Code,
    skipIf: isEmailVerified,
    skipTo: RegisterStep.Code,
    stepper: {
      step: stepperSteps[RegisterStepperStep.Email],
    },
    history: {
      preventStack: true,
    },
    meta: {
      screenTitle: 'signup:title',
      canGoBack: false,
    },
  },
  [RegisterStep.Code]: {
    path: 'code',
    nextStep: RegisterStep.CodeConfirm,
    skipIf: isPasswordChecked,
    skipTo: RegisterStep.SecretQuestion,
    fulfill: invokeActionCreator(SignupActions.passwordCheck),
    stepper: {
      step: stepperSteps[RegisterStepperStep.PersonalCode],
    },
    meta: {
      screenTitle: 'signup:title',
      canGoBack: false,
    },
  },
  [RegisterStep.CodeConfirm]: {
    path: 'code/confirm',
    nextStep: RegisterStep.CodeValidate,
    skipIf: isPasswordConfirmed,
    skipTo: RegisterStep.SecretQuestion,
    fulfill: invokeActionCreator(SignupActions.confirmPassword),
    onErrorRedirectStep: RegisterStep.Code,
    stepper: {
      step: stepperSteps[RegisterStepperStep.PersonalCode],
    },
    history: {
      preventStack: true,
    },
    meta: {
      screenTitle: 'signup:title',
      canGoBack: true,
    },
  },
  [RegisterStep.CodeValidate]: {
    path: 'code/validate',
    nextStep: RegisterStep.SecretQuestion,
    stepper: {
      step: stepperSteps[RegisterStepperStep.PersonalCode],
    },
    history: {
      preventStack: true,
    },
    meta: {
      screenTitle: 'signup:title',
      canGoBack: true,
    },
  },
  [RegisterStep.SecretQuestion]: {
    path: 'secretQuestion',
    nextStep: RegisterStep.Complete,
    skipIf: isRegistered,
    fulfill: invokeActionCreator(SignupActions.register),
    stepper: {
      step: stepperSteps[RegisterStepperStep.SecretQuestion],
    },
    meta: {
      screenTitle: 'signup:title',
      canGoBack: true,
    },
    onErrorRedirectStep: RegisterStep.Code,
  },
  [RegisterStep.Complete]: {
    path: 'complete',
    nextStep: 'final',
    skipIf: isAgentClientOrOffline,
    history: {
      preventStack: true,
      resetStack: true,
    },
    stepper: {
      step: stepperSteps[RegisterStepperStep.SecretQuestion],
      canGoBack: true,
    },
    meta: {
      screenTitle: 'signup:success',
    },
  },
}

export type RegisterSteps = typeof registerSteps

/*
 * Policy initialization
 */
export const registerPolicy = createWizardPolicy({
  machineId: machineId,
  steps: registerSteps,
  stepperSteps,
  initialStep: RegisterStep.Contact,
  basePath: '/register',
})
