import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { AdvanceTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_REQUEST,
    AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_SUCCESS,
    AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_FAILURE,
    AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_ERROR,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    AdvanceTypes.CREATE_ADVANCE_ERROR,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
]
