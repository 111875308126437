import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

const styles = StyleSheet.create({
  container: {
    borderColor: Colors.background,
    borderWidth: 1,
    borderRadius: 15,
    minWidth: 320,
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  header: {
    borderBottomColor: Colors.background,
    borderBottomWidth: 1,
    paddingBottom: 8,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    paddingTop: 16,
    paddingBottom: 8,
  },
})

export default styles
