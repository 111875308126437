import React from 'react'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { POSUtils } from '@neo-commons/libraries'
import { Badge, BadgeSkin, Typography, Typography2 } from '@neo-commons/components'

import { PosReturnDetailDto, PosReturnStatusDto } from '@afone/neo-core-client/dist/models'

import { CardDetails, ImageWrapper } from '@neo-web/componentsOld'

import './ReturnedListItem.scss'

interface ReturnedListItemProps {
  title: string,
  returns: PosReturnDetailDto[],
}

export const ReturnedListItem: React.FC<ReturnedListItemProps> = (props: ReturnedListItemProps) => {
  const { t } = useTranslation()

  const getBadgeSkin = (status: PosReturnStatusDto) => {
    switch (status) {
      case PosReturnStatusDto.IN_PROGRESS:
      case PosReturnStatusDto.RECEIVED:
      case PosReturnStatusDto.REFUNDABLE:
        return BadgeSkin.WARNING
      case PosReturnStatusDto.REFUNDED:
      case PosReturnStatusDto.DONE:
        return BadgeSkin.SUCCESS
      case PosReturnStatusDto.REFUND_REJECTED:
        return BadgeSkin.ERROR
      default:
        return BadgeSkin.DEFAULT
    }
  }

  return (
    <div style={{ marginBottom: 12 }}>
      <Typography2 typeface='overline'>{props.title}</Typography2>
      {props.returns?.map(r =>
        <CardDetails key={r.uuid}>
          <div className='Return_card'>
            <ImageWrapper
              uri={r.pos?.urlImage}
              width={40}
              height={40}
              wrapperStyle={{ borderRadius: 5 }}
            />
            <div className='Return_card_content'>
              <Typography typeface='bold'>
                {r.pos?.name ?? r.pos.label}
              </Typography>
              {r.pos?.serialNumber &&
                <Typography typeface='smallTextLight'>
                  {`${t('app:pages:services:pos:detail:posSerialNumberShort')}: ${r.pos.serialNumber}`}
                </Typography>}
              <Typography typeface='smallTextLight'>
                {POSUtils.returnReasonFailure(r.reason)
                  ? t('neo-commons:pages:services:pos:return:dispatch:detail:reason:failure')
                  : t('neo-commons:pages:services:pos:return:dispatch:detail:reason:termination')}
              </Typography>
              <Badge
                skin={getBadgeSkin(r.status)}
                value={r.status !== PosReturnStatusDto.IN_PROGRESS ? r.status : (
                  `${t('neo-commons:pages:services:pos:return:list:pending:badge')} ` +
                  `${r?.createdDate && t('app:global:field:dateFormat', {
                    date: dayjs(r.createdDate).format('L'),
                    hours: dayjs(r.createdDate).format('HH:mm'),
                    interpolation: { escapeValue: false },
                  })}`
                )}
                overrideContainerStyle={{ marginTop: 8 }}
              />
            </div>
          </div>
        </CardDetails>
      )}
    </div>
  )
}
