import React, { useRef } from 'react'

import { DidomiSDK } from '@didomi/react'

const didomiConfig = {
  app: {
    name: 'noelse.com',
    apiKey: '413b2df9-639d-4362-812f-719ab07619fd',
    noticeId: 'FDinrCYC',
  },
  notice: {
    position: 'popup',
    logoAlignment: 'flex-start',
  },
  languages: {
    enabled: ['fr', 'en', 'es'],
    default: 'fr',
  },
}

export const ConsentModal: React.FC = () => {
  const didomiObjectRef = useRef(null)
  const onDidomiReady = (didomi: any) => {
    didomiObjectRef.current = didomi
  }

  const consentHasChanged = (cwtToken : string) => {
    const userStatus = didomiObjectRef.current.getUserStatus()
    const enabledVendors = userStatus.vendors.global.enabled
    const disabledVendors = userStatus.vendors.global.disabled
    console.log(
      'Didomi - disabledVendors : ', disabledVendors,
      'Didomi - enabledVendors : ', enabledVendors,
      'Didomi - cwtToken:', cwtToken
    )
  }
  return (
    <DidomiSDK
      noticeId='FDinrCYC'
      config={didomiConfig}
      onReady={onDidomiReady}
      onConsentChanged={consentHasChanged}
    />
  )
}

export default ConsentModal
