import React from 'react'
import Shimmer from 'react-shimmer-effect'

import './ShimmerCard.scss'

export enum ShimmerCardType {
  TRANSACTION = 'TRANSACTION',
  POS_ORDER = 'POS_ORDER',
}

interface ShimmerCardProps {
  type: ShimmerCardType,
}

export const ShimmerCard: React.FC<ShimmerCardProps> = (props) => {
  let cardContent

  switch (props.type) {
    case ShimmerCardType.TRANSACTION:
      cardContent = (
        <div className='shimmer-card transaction classic-box box-border box-shadow'>
          <Shimmer><div className='shimmer-circle' /></Shimmer>
          <div className='shimmer-text'>
            <Shimmer><div className='shimmer-text-big' /></Shimmer>
            <Shimmer><div className='shimmer-text-small' /></Shimmer>
          </div>
        </div>
      )
      break

    case ShimmerCardType.POS_ORDER:
      cardContent = (
        <div className='shimmer-card pos-order classic-box box-border'>
          <Shimmer><div className='shimmer-square' /></Shimmer>
          <div className='shimmer-text'>
            <Shimmer><div className='shimmer-text-big' /></Shimmer>
            <Shimmer><div className='shimmer-text-small' /></Shimmer>
            <Shimmer><div className='shimmer-text-big' /></Shimmer>
            <Shimmer><div className='shimmer-text-small' /></Shimmer>
          </div>
        </div>
      )
      break
  }

  return cardContent
}
