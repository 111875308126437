import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { BankAccountTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$, refreshSubAccounts$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    BankAccountTypes.CREATE_SUB_ACCOUNT_SUCCESS,
    BankAccountTypes.CREATE_SUB_ACCOUNT_FAILURE,
    BankAccountTypes.LIST_BANK_ACCOUNT_REQUEST,
    BankAccountTypes.LIST_BANK_ACCOUNT_SUCCESS,
    BankAccountTypes.LIST_BANK_ACCOUNT_FAILURE,
    BankAccountTypes.GET_ACCOUNT_CLOSURE_FAILURE,
    BankAccountTypes.GET_BANK_ACCOUNT_RIB_INFOS_REQUEST,
    BankAccountTypes.GET_BANK_ACCOUNT_RIB_INFOS_SUCCESS,
    BankAccountTypes.GET_BANK_ACCOUNT_RIB_INFOS_FAILURE,
    BankAccountTypes.GET_BANK_ACCOUNT_RIB_FILE_REQUEST,
    BankAccountTypes.GET_BANK_ACCOUNT_RIB_FILE_SUCCESS,
    BankAccountTypes.GET_BANK_ACCOUNT_RIB_FILE_FAILURE,
    BankAccountTypes.UPDATE_BANKACCOUNT_REQUEST,
    BankAccountTypes.UPDATE_BANKACCOUNT_SUCCESS,
    BankAccountTypes.UPDATE_BANKACCOUNT_FAILURE,
    BankAccountTypes.CREATE_SUB_ACCOUNT_REQUEST,
    BankAccountTypes.CREATE_SUB_ACCOUNT_SUCCESS,
    BankAccountTypes.CREATE_SUB_ACCOUNT_FAILURE,
    BankAccountTypes.CLOSE_BANK_ACCOUNT_REQUEST,
    BankAccountTypes.CLOSE_BANK_ACCOUNT_SUCCESS,
    BankAccountTypes.CLOSE_BANK_ACCOUNT_FAILURE,
    BankAccountTypes.UPDATE_AGGREGATED_ACCOUNT_REQUEST,
    BankAccountTypes.UPDATE_AGGREGATED_ACCOUNT_SUCCESS,
    BankAccountTypes.UPDATE_AGGREGATED_ACCOUNT_FAILURE,
    BankAccountTypes.DELETE_AGGREGATED_ACCOUNT_REQUEST,
    BankAccountTypes.DELETE_AGGREGATED_ACCOUNT_SUCCESS,
    BankAccountTypes.DELETE_AGGREGATED_ACCOUNT_FAILURE,
    BankAccountTypes.DELETE_AGGREGATED_CONNECTION_REQUEST,
    BankAccountTypes.DELETE_AGGREGATED_CONNECTION_SUCCESS,
    BankAccountTypes.DELETE_AGGREGATED_CONNECTION_FAILURE,
  ])
}

const refreshBankAccountSubAccounts$ = (actions$: ActionsObservable<AnyAction>) => {
  return refreshSubAccounts$(actions$, [
    BankAccountTypes.SET_SELECTED,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    BankAccountTypes.CREATE_SUB_ACCOUNT_SUCCESS,
    BankAccountTypes.CREATE_SUB_ACCOUNT_FAILURE,
    BankAccountTypes.GET_ACCOUNT_CLOSURE_FAILURE,
    BankAccountTypes.UPDATE_BANKACCOUNT_SUCCESS,
    BankAccountTypes.UPDATE_BANKACCOUNT_FAILURE,
    BankAccountTypes.CLOSE_BANK_ACCOUNT_FAILURE,
    BankAccountTypes.UPDATE_AGGREGATED_ACCOUNT_SUCCESS,
    BankAccountTypes.UPDATE_AGGREGATED_ACCOUNT_FAILURE,
    BankAccountTypes.DELETE_AGGREGATED_CONNECTION_SUCCESS,
    BankAccountTypes.DELETE_AGGREGATED_CONNECTION_FAILURE,
    BankAccountTypes.DELETE_AGGREGATED_ACCOUNT_FAILURE,
    BankAccountTypes.GET_BANK_ACCOUNT_RIB_FILE_SUCCESS,
    BankAccountTypes.GET_BANK_ACCOUNT_RIB_FILE_FAILURE,
  ])
}

export default [
  reactiveLoader$,
  refreshBankAccountSubAccounts$,
  displayDropdownAlert$,
]
