import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { BankAccountActions, BankAccountSelectors } from '@neo-commons/store'
import { NumbersUtils } from '@neo-commons/libraries'

import { AccountDto } from '@afone/neo-core-client/dist/models'

import { UseTextWithBoldWords } from '@neo-web/hooks'
import {
  JustifyContent,
  PageContent,
} from '@neo-web/componentsOld'

export const Success: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const accountClosureReview = useSelector((state: StateTypes) => state.bankAccount.accountClosureReview)
  const selectedAccount: AccountDto = useSelector((state: StateTypes) => state.bankAccount.selectedBankAccountToClose)
  const selectedMain: AccountDto = useSelector(BankAccountSelectors.selectedMain)
  const cancelSubscription = accountClosureReview?.willCancelSubscription

  return (
    <PageContent
      justifyContent={JustifyContent.CENTER}
      icon={<Icon name='alert-circle' type={IconTypes.FEATHER} size={37} color={Colors.secondary} />}
      footer={
        <Button
          skin={ButtonSkin.OUTLINE_PRIMARY}
          title={t('app:global:goBackToSynthesis')}
          onPress={async () => {
            !cancelSubscription && await dispatch(BankAccountActions.setSelected(selectedMain.uuid))
            await dispatch(BankAccountActions.reset())
            await dispatch(BankAccountActions.list())
            history.replace(Routes.Synthesis.index.path)
          }}
        />
      }
      title={cancelSubscription
        ? t('app:pages:services:closeProject:success:cancelTitle')
        : t('app:pages:services:closeProject:success:closeTitle')}
    >
      <div style={{ textAlign: 'center' }}>
        {UseTextWithBoldWords({
          text: cancelSubscription
            ? t('app:pages:services:closeProject:success:cancelDescription', { amount: accountClosureReview?.subscriptions[0].price })
            : selectedAccount?.balance > 0 ? t('app:pages:services:closeProject:success:closeDescription', { amount: NumbersUtils.displayPriceForHuman(selectedAccount?.balance) }) : '',
        }
        )}
      </div>
    </PageContent>
  )
}
