import { DeviceDto, DeviceStatusDto } from '@afone/neo-core-client/dist/models'
/**
 * Generic methods use in NoElse for transaction.
 *
 */
export class DeviceUtils {
  /**
   * Group the device
   *
   * @param devices - List of devices
   * @param paramsRequest - Object of multiple parameters request for device
   * @returns return the list device in group by paramsRequest
   *
   */
  public static groupDevices = (devices: DeviceDto[], paramsRequest?: {status: DeviceStatusDto}): DeviceDto[] => {
    return devices.filter((device) => {
      switch (true) {
        case !paramsRequest:
          return devices
        case paramsRequest && device.status === paramsRequest.status:
          return devices.filter(device => device.status === paramsRequest.status)
      }
    })
  }
}
