import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EnumUtils } from '@neo-commons/libraries'
import { Icon, IconTypes, MenuItemCard, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { ClientSelectors, DocumentUploadActions, SubscriptionSelectors } from '@neo-commons/store'

import { ClientDto, KycTypeDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import { Routes } from '../../../../../Routes/Routes'

import './ElectronicSignatureDocumentsChoice.scss'

export const ElectronicSignatureDocumentsChoice: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const documentsType = [KycTypeDto.ID, KycTypeDto.PASSPORT, KycTypeDto.RESIDENCE_PERMIT]

  const client: ClientDto = useSelector(ClientSelectors?.defaultOne)
  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors?.defaultOneSocleSubscribing)
  const affiliates = useMemo(() => client?.holder?.affiliates, [client])

  const affiliateSubscriber = useMemo(() => {
    return affiliates?.find((affiliate) => affiliate?.person?.uuid === subscription?.subscriber?.personUuid)
  }, [affiliates])

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      icon={<Icon name='idcard-passport' type={IconTypes.NEOFONT} size={50} color={Colors.secondary} />}
      subtitle={t('app:pages:subscription:global:electronicSignatureDocumentsChoice:title')}
    >
      <div className='ElectronicSignatureDocumentsChoice_container'>
        <div className='ElectronicSignatureDocumentsChoice_container_description'>
          <Typography2 typeface='body1'>
            {t('app:pages:subscription:global:electronicSignatureDocumentsChoice:description')}
          </Typography2>
        </div>
        {documentsType.map((type, index) =>
          <div className='ElectronicSignatureDocumentsChoice_container_item' key={index}>
            <MenuItemCard
              title={affiliateSubscriber.person?.fullName + ' - ' + t(EnumUtils.getKycTypeDtoKey(type))}
              onPress={async () => {
                await dispatch(DocumentUploadActions.prepare({
                  name: t(EnumUtils.getKycTypeDtoKey(type)),
                  documentType: KycTypeDto.SIGNATURE_ADVANCED,
                }, client?.holder?.uuid))
                history.push(Routes.DocumentUpload.index.path)
              }}
            />
          </div>
        )}
      </div>
    </TunnelContent>
  )
}
