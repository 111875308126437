import { AnyAction, Reducer } from 'redux'
import { NotificationDto } from '@afone/neo-core-client/dist/models'
import { omit } from 'lodash'
import { i18commons } from '@neo-commons/i18n'
import { NeobankApi } from '@neo-commons/services'

import { initialResourceState } from '../utils'
import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Notification = NotificationDto

const {
  resourceActionTypes: NotificationActionTypes,
  resourceReducer: NotificationResourceReducer,
  resourceAction: NotificationAction,
  resourceSelector: NotificationResourceSelector,
} = ResourceStateFactory<Notification, 'notification'>(state => state.notification, 'notification')

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

export const NotificationTypes = {
  ...NotificationActionTypes,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

export const NotificationSelectors = {
  ...NotificationResourceSelector,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */
const errorKeyTranslate = 'errors:internalTechnicalIssue'

const list = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: NotificationTypes.LIST_NOTIFICATION_REQUEST })

    try {
      const response = await NeobankApi.getInstance().notificationApi.getNotifications(
        1, 0, undefined, undefined, false)

      const data = response.status === 204 ? [] : response.data

      dispatch({ type: NotificationTypes.LIST_NOTIFICATION_SUCCESS, data })
    } catch (error) {
      const errorMessage = error.message ?? i18commons.t(errorKeyTranslate)
      dispatch({ type: NotificationTypes.LIST_NOTIFICATION_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const last = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: NotificationTypes.BYID_NOTIFICATION_REQUEST })

    try {
      const response = await NeobankApi.getInstance().notificationApi.getNotifications()

      const data = response.status === 204 ? [] : response.data

      dispatch({ type: NotificationTypes.BYID_NOTIFICATION_SUCCESS, data: data })
    } catch (error) {
      const errorMessage = error.message ?? i18commons.t(errorKeyTranslate)
      dispatch({
        type: NotificationTypes.BYID_NOTIFICATION_FAILURE,
        errorMessage,
      })
      throw new Error(errorMessage)
    }
  }
}

const read = (notification: Notification) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: NotificationTypes.UPDATE_NOTIFICATION_REQUEST })

    try {
      notification.read = true
      await NeobankApi.getInstance().notificationApi.updateNotificationByUuid(
        notification.uuid!, { read: notification.read }
      )

      dispatch({ type: NotificationTypes.UPDATE_NOTIFICATION_SUCCESS, data: notification })
    } catch (error) {
      const errorMessage = error.message ?? i18commons.t(errorKeyTranslate)
      dispatch({ type: NotificationTypes.UPDATE_NOTIFICATION_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

export const NotificationActions = {
  ...NotificationAction,
  list,
  last,
  read,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type NotificationState = Omit<ResourceState<Notification>, 'notification'>

const initialState: NotificationState = {
  ...omit(initialResourceState),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const notification: Reducer = (state = initialState, action: AnyAction): NotificationState => {
  switch (action.type) {
    default:
      return {
        ...NotificationResourceReducer(state, action, {
          identifier: 'uuid',
          isPaginate: false,
          initialState,
        }),
      }
  }
}
