import { AnyAction, Reducer } from 'redux'
import { omit, uniq } from 'lodash'
import produce from 'immer'
import { createSelector } from 'reselect'
import { NeobankApi } from '@neo-commons/services'
import {
  AdvanceDto,
  AdvanceFieldDto,
  ClientDto,
} from '@afone/neo-core-client'

import { AlertType, initialResourceState, State } from '../utils'
import { Dispatch, resourceDefaultSelectors, ResourceState } from '../utils/resourceState'

import { Client, ClientActions, ClientSelectors } from './client'

/* %%%%%%%%%%%%%%%%%% *\
    Action Types.
\* %%%%%%%%%%%%%%%%%% */

export const AdvanceTypes = {
  CHECK_ADVANCE_ELIGIBILITY_REQUEST: 'advance/CHECK_ADVANCE_ELIGIBILITY_REQUEST',
  CHECK_ADVANCE_ELIGIBILITY_SUCCESS: 'advance/CHECK_ADVANCE_ELIGIBILITY_SUCCESS',
  CHECK_ADVANCE_ELIGIBILITY_FAILURE: 'advance/CHECK_ADVANCE_ELIGIBILITY_FAILURE',
  CHECK_ADVANCE_ELIGIBILITY_ERROR: 'advance/CHECK_ADVANCE_ELIGIBILITY_ERROR',
  CREATE_ADVANCE_REQUEST: 'advance/CREATE_ADVANCE_REQUEST',
  CREATE_ADVANCE_SUCCESS: 'advance/CREATE_ADVANCE_SUCCESS',
  CREATE_ADVANCE_ERROR: 'advance/CREATE_ADVANCE_ERROR',
  GET_ADVANCES_REQUEST: 'advance/GET_ADVANCES_REQUEST',
  GET_ADVANCES_SUCCESS: 'advance/GET_ADVANCES_SUCCESS',
  GET_ADVANCES_ERROR: 'advance/GET_ADVANCES_ERROR',
  RESET: 'advance/RESET',
  SET_NUMBER_OF_ELIGIBILITY_CHECKS: 'advance/SET_NUMBER_OF_ELIGIBILITY_CHECKS',
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */

const checkEligibility = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_REQUEST })
    try {
      const response = await NeobankApi.getInstance().advanceApi.getAdvanceEligibility()
      const { eligibility } = response.data
      if (eligibility) {
        dispatch({ type: AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_SUCCESS })
      } else {
        dispatch({ type: AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_FAILURE })
      }
    } catch (error) {
      dispatch({ type: AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_ERROR, error })
    }
  }
}

const createAdvance = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const client: Client = ClientSelectors.defaultOne(getState())
    dispatch({ type: AdvanceTypes.CREATE_ADVANCE_REQUEST })
    try {
      const response = await NeobankApi.getInstance().advanceApi.createAdvance(client.uuid ?? '')
      dispatch({
        type: AdvanceTypes.CREATE_ADVANCE_SUCCESS,
        data: response.data,
      })
    } catch (error) {
      dispatch({
        type: AdvanceTypes.CREATE_ADVANCE_ERROR,
        error,
        alertType: AlertType.ERROR,
        errorMessage: error.message,
      })
      throw new Error(error.message)
    }
  }
}

const getAdvanceData = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      if (!getState().client?.list?.loadedOnce) {
        await dispatch(ClientActions.list())
      }
      const defaultClient: ClientDto = ClientSelectors.defaultOne(getState())
      if (!defaultClient) {
        throw new Error()
      }
      dispatch({ type: AdvanceTypes.GET_ADVANCES_REQUEST })
      const response =
        await NeobankApi.getInstance().advanceApi.getAdvances(
          defaultClient?.uuid ?? '',
          10,
          0,
          [AdvanceFieldDto.TRANSACTIONS, AdvanceFieldDto.REIMBURSEMENTS]
        )
      dispatch({
        type: AdvanceTypes.GET_ADVANCES_SUCCESS,
        data: response.status === 204 ? [] : response.data,
      })
    } catch (error) {
      dispatch({ type: AdvanceTypes.GET_ADVANCES_ERROR, error })
    }
  }
}

const setNumberOfAdvanceEligibilityCheck = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: AdvanceTypes.SET_NUMBER_OF_ELIGIBILITY_CHECKS })
  }
}

const reset = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: AdvanceTypes.RESET })
  }
}

export const AdvanceActions = {
  checkEligibility,
  createAdvance,
  getAdvanceData,
  setNumberOfAdvanceEligibilityCheck,
  reset,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type AdvanceState = Omit<ResourceState<AdvanceDto>, 'create' | 'update' | 'prepare'> & {
  error: {
    type: 'failure' | 'error' | ''
    message?: string
    errorCode?: string
  },
  numberOfAdvanceEligibilityCheck: number,
}

const initialState: AdvanceState = {
  ...omit(initialResourceState, 'create', 'update', 'prepare'),
  error: {
    errorCode: '',
    message: '',
    type: '',
  },
  numberOfAdvanceEligibilityCheck: 0,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const advanceSelector = (state: State) => state.advance
const defaultSelectors = resourceDefaultSelectors(advanceSelector)

export const AdvanceSelectors = {
  ...defaultSelectors,
  isLoading: createSelector(
    advanceSelector,
    resource => resource.loading
  ),
  error: createSelector(
    advanceSelector,
    resource => resource.error
  ),
  numberOfAdvanceEligibilityCheck: createSelector(
    advanceSelector,
    resource => resource.numberOfAdvanceEligibilityCheck
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const advance: Reducer = (state: AdvanceState = initialState, action: AnyAction) => {
  switch (action.type) {
    case AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_REQUEST:
    case AdvanceTypes.CREATE_ADVANCE_REQUEST:
    case AdvanceTypes.GET_ADVANCES_REQUEST:
      return produce(state, newState => { newState.loading = true })

    case AdvanceTypes.SET_NUMBER_OF_ELIGIBILITY_CHECKS:
      return produce(state, newState => {
        newState.numberOfAdvanceEligibilityCheck = 1
      })

    case AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        newState.numberOfAdvanceEligibilityCheck = state.numberOfAdvanceEligibilityCheck + 1
      })
    case AdvanceTypes.CREATE_ADVANCE_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        newState.list.ids = uniq([...state.list.ids, action.data.uuid])
        newState.data[action.data.uuid] = action.data
      })
    case AdvanceTypes.GET_ADVANCES_SUCCESS:
      if (!action.data) {
        return produce(state, newState => {
          newState.loading = false
          newState.list.loadedOnce = true
        })
      }

      return produce(state, newState => {
        newState.loading = false
        newState.list.loadedOnce = true
        /*
         * We redo a data by taking the list of what we received and overwriting the
         * data we had before for the same uuid This is necessary if we do not want to
         * lose the data of LIST_CARDS_LIMIT and if we want to overload the old data with new
         */
        newState.data = action.data?.reduce((acc, item) => ({
          ...acc,
          [item.uuid]: {
            ...state.data[item.uuid],
            ...item,
          },
        }), {}) ?? {}
        newState.list = {
          ...state.list,
          ids: uniq(action.data?.map(item => item.uuid) ?? []),
          loadedOnce: true,
          shouldRefresh: false,
        }
        if (action.data.length === 1 && newState.list.selectedId !== action.data[0].uuid) {
          newState.list.selectedId = action.data[0].uuid
        }
      })

    case AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_FAILURE:
      return produce(state, newState => {
        newState.loading = false
        newState.error = {
          ...state.error,
          message: action.rejectReason,
          type: 'failure',
        }
        newState.numberOfAdvanceEligibilityCheck = state.numberOfAdvanceEligibilityCheck + 1
      })

    case AdvanceTypes.CHECK_ADVANCE_ELIGIBILITY_ERROR:
      return produce(state, newState => {
        newState.loading = false
        newState.error = {
          ...state.error,
          errorCode: action.error.errorCode,
          message: action.error.message,
          type: 'error',
        }
        newState.numberOfAdvanceEligibilityCheck = state.numberOfAdvanceEligibilityCheck + 1
      })

    case AdvanceTypes.CREATE_ADVANCE_ERROR:
    case AdvanceTypes.GET_ADVANCES_ERROR:
      return produce(state, newState => {
        newState.loading = false
        newState.error = {
          ...state.error,
          errorCode: action.error.errorCode,
          message: action.error.message,
          type: 'error',
        }
      })

    case AdvanceTypes.RESET:
      return initialState
    default:
      return state
  }
}
