import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import {
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  Typography2,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { CardSelectors } from '@neo-commons/store'
import { OppositionStep, OppositionSteps, StepComponentProps } from '@neo-commons/policies'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './Intro.scss'

type IntroProps = StepComponentProps<OppositionSteps[OppositionStep.Intro]>

export const Intro: React.FC<IntroProps> = ({ nextStep }: IntroProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const preparedCardOpposition = useSelector(CardSelectors.preparedCardOpposition)
  const selectedCard = useSelector(CardSelectors.selected)

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      subtitle={t('app:pages:services:debitCard:opposition:intro:title')}
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:pages:services:debitCard:opposition:continue')}
          onPress={() =>
            nextStep({
              preparedCardOpposition: { ...preparedCardOpposition, card: selectedCard },
              forceCreateOrUpdate: true,
            })}
        />
      }
    >
      <div className='Intro_container'>
        <div className='Intro_container_block'>
          <div className='Intro_container_block_header'>
            <Icon name='info' type={IconTypes.FEATHER} color={Colors.secondary} size={25} />
            <div className='Intro_container_block_header_title'>
              <Typography2 typeface='h4'>
                {t('app:pages:services:debitCard:opposition:intro:whenToOppose:title')}
              </Typography2>
            </div>
          </div>
          <div className='Intro_container_block_description'>
            <Typography2 typeface='body1'>
              {t('app:pages:services:debitCard:opposition:intro:whenToOppose:description')}
            &nbsp;
              <a
                className='Intro_container_block_description_link'
                onClick={() =>
                  history.push(Routes.Services.index.path)}
              >
                {t('app:pages:services:debitCard:opposition:intro:whenToOppose:descriptionEnd')}
              </a>
            </Typography2>
          </div>
        </div>
        <div className='Intro_container_block'>
          <div className='Intro_container_block_header'>
            <Icon name='info' type={IconTypes.FEATHER} color={Colors.secondary} size={25} />
            <div className='Intro_container_block_header_title'>
              <Typography2 typeface='h4'>
                {t('app:pages:services:debitCard:opposition:intro:disclaimer:title')}
              </Typography2>
            </div>
          </div>
          <div className='Intro_container_block_description'>
            <Typography2 typeface='body1'>
              {t('app:pages:services:debitCard:opposition:intro:disclaimer:description')}
            </Typography2>
          </div>
        </div>
      </div>
    </TunnelContent>
  )
}
