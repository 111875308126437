import { StyleSheet } from 'react-native'

import { Typo, Commons } from '@neo-commons/styles'

export default StyleSheet.create({
  container: {
    borderRadius: 15,
    ...Commons.shadow,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
  },
  textsArea: {
    paddingLeft: 18,
    paddingRight: 1,
    flex: 1,
  },
  title: {
    fontSize: 16,
  },
  subtitle: {
    ...Typo.itemTitle,
    display: 'flex',
    marginTop: 4,
  },
  iconContainer: {
    justifyContent: 'center',
  },
  leftIcon: {
    width: 32,
    height: 32,
  },
})
