import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { PosTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    PosTypes.SELECT_POS_REQUEST,
    PosTypes.SELECT_POS_SUCCESS,
    PosTypes.SELECT_POS_FAILURE,
    PosTypes.PREPARE_POS_REQUEST,
    PosTypes.PREPARE_POS_SUCCESS,
    PosTypes.PREPARE_POS_FAILURE,
    PosTypes.ORDER_POS_REQUEST,
    PosTypes.ORDER_POS_SUCCESS,
    PosTypes.ORDER_POS_FAILURE,
    PosTypes.ACTIVATE_POS_REQUEST,
    PosTypes.ACTIVATE_POS_SUCCESS,
    PosTypes.ACTIVATE_POS_FAILURE,
    PosTypes.LIST_POS_REQUEST,
    PosTypes.LIST_POS_SUCCESS,
    PosTypes.LIST_POS_FAILURE,
    PosTypes.UPDATE_POS_REQUEST,
    PosTypes.UPDATE_POS_SUCCESS,
    PosTypes.UPDATE_POS_FAILURE,
    PosTypes.PREPARE_POS_RETURN_REQUEST,
    PosTypes.PREPARE_POS_RETURN_SUCCESS,
    PosTypes.PREPARE_POS_RETURN_FAILURE,
    PosTypes.LIST_POS_RETURN_REASONS_REQUEST,
    PosTypes.LIST_POS_RETURN_REASONS_SUCCESS,
    PosTypes.LIST_POS_RETURN_REASONS_FAILURE,
    PosTypes.RETURN_POS_REQUEST,
    PosTypes.RETURN_POS_SUCCESS,
    PosTypes.RETURN_POS_FAILURE,
    PosTypes.LIST_DISPATCHES_REQUEST,
    PosTypes.LIST_DISPATCHES_SUCCESS,
    PosTypes.LIST_DISPATCHES_FAILURE,
    PosTypes.CANCEL_DISPATCH_REQUEST,
    PosTypes.CANCEL_DISPATCH_SUCCESS,
    PosTypes.CANCEL_DISPATCH_FAILURE,
    PosTypes.LIST_RETURNS_REQUEST,
    PosTypes.LIST_RETURNS_SUCCESS,
    PosTypes.LIST_RETURNS_FAILURE,
    PosTypes.REFUND_DEPOSIT_REQUEST,
    PosTypes.REFUND_DEPOSIT_SUCCESS,
    PosTypes.REFUND_DEPOSIT_FAILURE,
    PosTypes.PREPARE_POS_LOST_REQUEST,
    PosTypes.PREPARE_POS_LOST_SUCCESS,
    PosTypes.PREPARE_POS_LOST_FAILURE,
    PosTypes.LOST_POS_REQUEST,
    PosTypes.LOST_POS_SUCCESS,
    PosTypes.LOST_POS_FAILURE,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    PosTypes.SELECT_POS_FAILURE,
    PosTypes.ORDER_POS_FAILURE,
    PosTypes.ACTIVATE_POS_FAILURE,
    PosTypes.LIST_POS_FAILURE,
    PosTypes.UPDATE_POS_SUCCESS,
    PosTypes.UPDATE_POS_FAILURE,
    PosTypes.PREPARE_POS_RETURN_FAILURE,
    PosTypes.LIST_POS_RETURN_REASONS_FAILURE,
    PosTypes.GET_TELECOLLECTIONS_FAILURE,
    PosTypes.RETURN_POS_FAILURE,
    PosTypes.GET_DISPATCH_LABEL_FAILURE,
    PosTypes.CANCEL_DISPATCH_SUCCESS,
    PosTypes.CANCEL_DISPATCH_FAILURE,
    PosTypes.REFUND_DEPOSIT_SUCCESS,
    PosTypes.REFUND_DEPOSIT_FAILURE,
    PosTypes.LOST_POS_FAILURE,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
]
