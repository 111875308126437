import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

export default StyleSheet.create({
  icon: {
    backgroundColor: Colors.secondary,
    alignSelf: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  selectedIcon: {
    backgroundColor: Colors.secondary,
    alignSelf: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginVertical: 14,
    marginHorizontal: 11,
  },
  availableIcon: {
    backgroundColor: Colors.background,
    alignSelf: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginVertical: 14,
    marginHorizontal: 11,
  },
})
