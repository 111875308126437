import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { AppConfig } from 'Config/AppConfig'

import { PasswordKeypadData, usePasswordKeypad } from '@neo-commons/hooks'
import { ResetPasscodeStep, ResetPasscodeSteps, StepComponentProps } from '@neo-commons/policies'

import { KeypadFieldDto } from '@afone/neo-core-client/dist/models'

import { CodeConfirm } from '@neo-web/componentsOld'

import './PreviousCode.scss'

type PreviousCodeProps = StepComponentProps<ResetPasscodeSteps[ResetPasscodeStep.Code]>

export const PreviousCode: React.FC<PreviousCodeProps> = (props:PreviousCodeProps) => {
  const { t } = useTranslation()

  const recoverPassword = useSelector((state: StateTypes) => state?.recoverPassCode)
  const signInData = useSelector((state: StateTypes) => state?.signIn?.data)
  const passwordKeypadData = usePasswordKeypad({
    phone: signInData?.phone,
    phoneCountryCode: signInData?.countryCode,
    field: KeypadFieldDto.CURRENT_PASSWORD,
    passwordSize: AppConfig.PASS_CODE_SIZE,
  })

  const [showError, setShowError] = useState(false)

  const onValidate = async (passwordKeypadData: PasswordKeypadData) => {
    try {
      setShowError(false)

      const code = passwordKeypadData.password.map(e => e.value)

      await props.nextStep({
        ...recoverPassword,
        currentPassword: code,
        isCodeValid: true,
      })
    } catch (error) {
      setShowError(true)
      passwordKeypadData.reset(error)
    }
  }

  return (
    <div className='PreviousCode_container'>
      <div className='PreviousCode_item'>
        <CodeConfirm
          error={showError && t('errors:verifyPhoneError')}
          title={t('app:pages:myProfile:codeReset:actual:title')}
          onValidate={onValidate}
          keypadData={passwordKeypadData}
        />
      </div>
    </div>
  )
}
