import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { View, TextInput, TextInputProps } from 'react-native'

import { PhoneNumberUtils, ValidatorFunction, ValidatorResponseType } from '@neo-commons/libraries'
import { IconTypes, Icon, Typography2 } from '@neo-commons/components'
import { AsYouType, CountryCode } from 'libphonenumber-js'
import { Colors } from '@neo-commons/styles'
import { useValidation } from '@neo-commons/hooks'

import styles from './styles'

export interface InputRef2Handle {
  getValue: () => string | undefined,
  getValidatorStatus: () => ValidatorResponseType,
  focus: () => void,
  blur: () => void,
  clear: () => void,
  formKey?: string,
}
export interface Input2Props extends TextInputProps {
  label: string,
  value: string,
  noBorder?: boolean,
  hasBeenSubmitted?: boolean,
  placeholder?: string,
  error?: string,
  hasIcon?: boolean,
  format?: {
    type: 'phone' | 'email'
    additionalProps?: any
  },
  numeric?: boolean,
  validators?: ValidatorFunction[],
  onValidationChecked?: (status: ValidatorResponseType) => void,
}

export const Input2Component = forwardRef<InputRef2Handle, Input2Props>((props) => {
  const [isFocused, setIsFocused] = useState(false)
  const [hasBlurred, setHasBlurred] = useState(false)

  const phoneFormatter = useCallback((value: string) =>
    PhoneNumberUtils.isAllowForApp(value || '', props.format?.additionalProps?.country as CountryCode)
      ? new AsYouType(props.format?.additionalProps?.country as CountryCode).input(value)
      : value, [props.format?.additionalProps?.country as CountryCode])
  const handleBlur = () => {
    setIsFocused(false)
    setHasBlurred(true)
  }

  const [errorShown, setErrorShown] = useState(false)
  const displayLabel = isFocused || props.value !== ''
  const displayFocusStyle = isFocused && !errorShown
  const displayedPlaceholder = props.placeholder || props.label
  const [validatorStatus] = useValidation(props.value ?? '', true, props.validators ?? [])

  const inputHasError = () => {
    setErrorShown(
      ((props.hasBeenSubmitted && !validatorStatus.isValid) ||
        (hasBlurred && !validatorStatus.isValid))
    )
  }

  useEffect(() => {
    props.onValidationChecked && props.onValidationChecked(validatorStatus)
    inputHasError()
  }, [validatorStatus])

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.inputContainer,
          displayFocusStyle && props.editable && styles.inputContainerFocused,
          !!errorShown && styles.inputContainerError,
          props.noBorder && { borderWidth: 0 },
          !props.editable && { opacity: 0.6, borderColor: Colors.gray2 },
        ]}
      >
        {displayLabel && (
          <Typography2 typeface='body3' style={[styles.label, !!errorShown ? styles.labelError : {}]}>
            {props.label}
          </Typography2>
        )}
        <TextInput
          autoFocus={props.autoFocus}
          keyboardType={props.numeric ? 'numeric' : props.format?.type === 'email' ? 'email-address' : 'default'}
          autoCapitalize={props.format?.type === 'email' ? 'none' : 'sentences'}
          onChangeText={(value) => {
            props.onChangeText && props.onChangeText(value)
            props.onChange && props.onChange(value as any)
          }}
          value={phoneFormatter(props.value)}
          placeholder={isFocused ? '' : displayedPlaceholder}
          style={[styles.textInput, !!errorShown && styles.textInputError]}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          placeholderTextColor={Colors.gray2}
          underlineColorAndroid='transparent'
          editable={props.editable}
        />
        {props.hasIcon && (
          <View style={styles.icon}>
            <Icon
              name='alert-circle'
              type={IconTypes.FEATHER}
              size={25}
              color={errorShown ? '#F42020' : 'black'}
            />
          </View>
        )}
      </View>
      {errorShown && validatorStatus.errorMessage !== undefined && validatorStatus.errorMessage !== '' &&
        <Typography2 typeface='body3' style={styles.textError}>{validatorStatus.errorMessage}</Typography2>}
    </View>
  )
})

Input2Component.displayName = 'Input'
export const Input2 = Input2Component
