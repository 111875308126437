import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { View } from 'react-native'

import { DocumentUploadStep, DocumentUploadSteps } from 'Policies'
import { StateTypes } from 'Store'
import CustomIcon from 'UI/Components/CustomIcon'

import { StepComponentProps } from '@neo-commons/policies'
import { Button, ReadOnlyInput, Stepper2, Typography2 } from '@neo-commons/components'
import { ClientUtils, SubscriptionUtils } from '@neo-commons/libraries'
import {
  ClientSelectors,
  Dispatch,
  DocumentUploadSelectors,
  SubscriptionActions,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { ClientDto } from '@afone/neo-core-client'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

type DocumentUploadSuccessProps = StepComponentProps<DocumentUploadSteps[DocumentUploadStep.success]>
export const DocumentUploadSuccess: React.FC<DocumentUploadSuccessProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()
  const isReUpload = useSelector(DocumentUploadSelectors.isReUpload)
  const documentUpload = useSelector((state: StateTypes) => state?.documentUpload)

  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const mainSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.CENTER}
      header={
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <Typography2 typeface='h3'>
            {t('app:pages:subscription:individual:mainTitle:idDocument')}
          </Typography2>
          <Stepper2 currentStep={1} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Typography2 typeface='h4' style={{ }}>{t('app:pages:documentUpload:success:title')}</Typography2>
            <Typography2 typeface='body1'>
              {t('app:pages:documentUpload:success:description')}
            </Typography2>
          </div>
          <ReadOnlyInput
            style={{ width: '100%' }}
            label={t('app:pages:subscription:individual:mainTitle:idDocument')}
            value={documentUpload?.data?.document?.name}
            rightIcon={<View><CustomIcon name='clock' size={26} color={Colors.black} /></View>}
          />
        </div>
      }
      footer={
        <Button
          title={SubscriptionUtils.isAddressOk(mainSubscription)
            ? t('app:pages:subscription:backtoHome')
            : ClientUtils.isClientTypeCorporate(client.type)
              ? t('app:pages:documentUpload:summary:confirmButton')
              : t('app:pages:documentUpload:success:confirmButton')}
          onPress={async () => {
            if (isReUpload) {
              await dispatch(SubscriptionActions.list())
              dispatch(DocumentUploadSelectors.isReUpload(false))
            }
            nextStep()
          }}
        />
      }
    />
  )
}
