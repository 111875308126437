export const SeaCguRoutes = {
  index: {
    path: '/subscription/sea-cgu',
    name: '',
    available: true,
  },
  seaCguPedagogy: {
    path: '/subscription/sea-cgu/pedagogy',
    name: 'app:pages:subscription:global:electronicSignature:title',
    available: true,
  },
  electronicSignaturePedagogy: {
    path: '/subscription/sea-cgu/electronic-signature-pedagogy',
    name: 'app:pages:subscription:global:electronicSignaturePedagogy:title',
    available: true,
  },
  electronicSignature: {
    path: '/subscription/sea-cgu/electronic-signature',
    name: 'app:pages:subscription:global:electronicSignature:title',
    available: true,
  },
  electronicSignatureFallback: {
    path: '/subscription/sea-cgu/electronic-signature-fallback',
    name: 'app:pages:subscription:global:electronicSignatureDocumentsChoice:title',
    available: true,
  },
}
