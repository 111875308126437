import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { BankAccountSelectors, Client, ClientSelectors } from '@neo-commons/store'
import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { AccountDto, AccountStatusDto, AccountTypeDto } from '@afone/neo-core-client/dist/models'

import { PageContent } from '@neo-web/componentsOld'

import { Offers } from './Components/Offers'
import { ProjectMenu } from './Components/ProjectMenu'

export const Projects: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const client: Client = useSelector(ClientSelectors.byId(selectedBankAccount?.clientUuid))

  const selectedBankAccountProjects = selectedBankAccount
    ? useSelector(BankAccountSelectors.getSubAccountByUuidAndType(selectedBankAccount.uuid, AccountTypeDto.PROJECT))
    : []

  return useMemo(() => {
    if ((selectedBankAccount?.balance > 0 && selectedBankAccount?.status !== AccountStatusDto.WAITING_KYC) && selectedBankAccountProjects.length === 0) {
      return <Offers />
    } else if (selectedBankAccountProjects.length > 0) {
      return <ProjectMenu />
    } else if (!client) {
      return (
        <div className='classic-box'>
          <PageContent
            icon={<Icon type={IconTypes.FEATHER} name='alert-circle' color={Colors.secondary} size={50} />}
            title={t('app:pages:services:unavailable')}
          />
        </div>)
    } else {
      return (
        <PageContent
          icon={<Icon type={IconTypes.NEOFONT} name='amount' color={Colors.secondary} size={55} />}
          title={t('app:pages:services:project:credit')}
          button={(
            <Button
              skin={ButtonSkin.PRIMARY}
              containerStyle={{ width: '100%' }}
              title={t('app:pages:credit:title')}
              onPress={() => {
                history.push(Routes.Credit.creditChoices.path, { background: location })
              }}
            />
          )}
        />
      )
    }
  }, [selectedBankAccountProjects])
}
