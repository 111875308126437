import { StyleSheet } from 'react-native'

import { Typo, Colors } from '@neo-commons/styles'

const styles = StyleSheet.create({
  cardContainer: {
    width: '100%',
    height: 75,
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    paddingHorizontal: 16,
  },
  deviceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  deviceName: {
    ...Typo.bigTitle,
    fontSize: 16,
    lineHeight: 21,
  },
  lastAuth: {
    ...Typo.content,
    color: Colors.black,
    fontSize: 12,
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textArea: {
    textAlign: 'left',
    flexGrow: 1,
    paddingLeft: 16,
  },
  badge: {
    marginLeft: 8,
  },
})

export default styles
