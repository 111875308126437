import React, { useEffect, useMemo, useState } from 'react'

import { useMachine } from '@xstate/react'
import { TextUtils } from '@neo-commons/libraries'
import { map, findIndex, isObject } from 'lodash'
import {
  WizardPolicy,
  WizardStepKey,
  WizardStep,
  StepComponentProps,
  StepperStepStatus,
  WizardSteps,
} from '@neo-commons/policies'
import { Store } from 'redux'

export type WizardComponentMapType = React.ComponentType<StepComponentProps<WizardStep>>
export type WizardComponentMapFn = (({ nextStep, editStep }) => React.ReactElement)
export type WizardComponentMap = WizardComponentMapType | WizardComponentMapFn

export const useWizardPolicy = (
  store: Store,
  wizardPolicy: WizardPolicy,
  wizardComponents: {
    [key in WizardStepKey]: WizardComponentMap
  },
  {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDone = (context = {}) => null, // context must be defined or redirections will crash the app
  }: {
    onDone: (context: any) => void
  }
): any => {
  const [state, , service] = useMachine(
    wizardPolicy.machine,
    {
      context: { store },
      devTools: true,
    }
  )

  const [load, setLoad] = useState(false)
  const [currentStep, setCurrentStep] = useState<any>(undefined)

  const listAllSteps = (steps: WizardSteps) => {
    let list = { ...steps }
    map(steps, step => { list = { ...list, ...listAllSteps(step.childrenSteps as WizardSteps) } })
    return list
  }

  useEffect(() => {
    wizardPolicy.service = service
    service.onDone(() => onDone(service.getSnapshot().context))
    setLoad(true)
    return () => {
      service.stop()
    }
  }, [])

  const steps = useMemo(() => map(
    listAllSteps(wizardPolicy.steps),
    (step, key) => {
      return {
        ...step,
        key,
        navigationKey: `${TextUtils.capitalize(wizardPolicy.machineId)}${key}`,
        component: (props: any) => {
          const Component = wizardComponents[key]

          return (<Component key={key} {...({ ...props, nextStep: wizardPolicy.next, editStep: wizardPolicy.edit })} />)
        },
      }
    }
  ), [])

  useEffect(() => {
    const currentStepMeta = state.meta[Object.keys(state.meta)[0]] ?? {}
    if (currentStep?.path !== currentStepMeta.path) {
      setCurrentStep({
        ...currentStepMeta,
        ...(steps.find(step => step.path === currentStepMeta.path) ?? {}),
      })
    }
  }, [state])

  const stepperToArray = map(wizardPolicy.stepperSteps, stepperStep => stepperStep)
  const stepperIndex = findIndex(stepperToArray, stepperStep =>
    isObject(stepperStep) && stepperStep?.title === currentStep?.stepper?.step.title)
  const stepper = wizardPolicy.stepperSteps
    ? map(stepperToArray, (stepperStep, index) => {
      const status = index === stepperIndex
        ? StepperStepStatus.CURRENT
        : (index < stepperIndex) || (currentStep?.stepper?.step === 'final')
          ? StepperStepStatus.VALID
          : null
      return { ...(isObject(stepperStep) ? stepperStep : {}), status }
    })
    : null

  return {
    currentStep,
    steps,
    stepper,
    load,
    context: state?.context,
    goBack: wizardPolicy.goBack,
  }
}
