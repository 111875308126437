import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'
import { AppConfig } from 'Config/AppConfig'

import { BankAccountUtils, OfferUtils } from '@neo-commons/libraries'
import {
  BankAccountActions,
  BankAccountSelectors,
  BankAccountTypes,
  ClientSelectors,
  OfferActions,
  OfferSelectors,
  PrepareSubAccountOrder, SubscriptionSelectors,
} from '@neo-commons/store'
import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { ProjectStep, ProjectSteps, StepComponentProps } from '@neo-commons/policies'

import {
  AccountDto,
  AccountTypeDto,
  ClientDto,
  ClientTypeDto,
  OfferDto,
  OfferTypeDto,
} from '@afone/neo-core-client/dist/models'

import { JustifyContent, LazyLoadingContainer, PageContent, PageDetails } from '@neo-web/componentsOld'
import { UseTextWithBoldWords } from '@neo-web/hooks'

import './Summary.scss'

type SummaryProps = StepComponentProps<ProjectSteps[ProjectStep.Summary]>

export const Summary: React.FC<SummaryProps> = ({ editStep, nextStep }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [offerProjectNumberPerSubscription, setOfferProjectNumberPerSubscription] = useState(AppConfig.PROJECT_NUMBER_PER_SUBSCRIPTION)
  const [remainingProjects, setRemainingProjects] = useState(AppConfig.REMAINING_PROJECT_NUMBER)
  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const client: ClientDto = useSelector(ClientSelectors.byId(selectedBankAccount?.clientUuid))
  const mainSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  const socleOffer = useSelector(OfferSelectors.getByUuid(mainSubscription?.offerUuid ?? mainSubscription?.offer?.uuid))

  const projectOffer: OfferDto[] = useSelector(OfferSelectors.listByClientType(
    client?.type === ClientTypeDto.INDIVIDUAL ? ClientTypeDto.INDIVIDUAL : ClientTypeDto.CORPORATE,
    OfferTypeDto.PROJECT)
  )
  const offerState = useSelector((state: StateTypes) => state.offer)
  const preparedSubAccountOrder: PrepareSubAccountOrder = useSelector(BankAccountSelectors.getPreparedSubAccountOrder)
  const [offerPrice, setOfferPrice] = useState(projectOffer[0]?.price)
  const isBankAccountLoading = useSelector(BankAccountSelectors.isLoading)

  useEffect(() => {
    (async () => {
      try {
        if (!offerState.loading) {
          await dispatch(OfferActions.listForClientUuid())
        }
      } catch (error) {}

      setOfferPrice((projectOffer[0]?.price))
      setOfferProjectNumberPerSubscription((projectOffer[0]?.data as any)?.maxAccountPerSubscription)
    })()
  }, [projectOffer[0]?.price, (projectOffer[0]?.data as any)?.maxAccountPerSubscription])

  useEffect(() => {
    setRemainingProjects(offerProjectNumberPerSubscription - 1)
  }, [offerProjectNumberPerSubscription])

  const offer = t('app:pages:services:project:summary:description:offer',
    { maxAccountPerSubscription: offerProjectNumberPerSubscription, amount: offerPrice })

  return (
    <LazyLoadingContainer events={[BankAccountTypes.CREATE_SUB_ACCOUNT_REQUEST]} isShow={isBankAccountLoading}>
      <PageDetails
        title={t('neo-commons:project:summary')}
        component={
          <div className='SummaryContent'>
            <PageContent
              justifyContent={JustifyContent.BETWEEN}
              footer={
                <>
                  {!OfferUtils.isOfferAdvance(socleOffer) && (
                    <div className='SummaryContent_bill'>
                      <Typography typeface='menuItemTitle'>
                        {t('app:pages:services:project:summary:billText', { amount: preparedSubAccountOrder?.cost?.amount })}
                      </Typography>
                    </div>
                  )}

                  <Button
                    skin={ButtonSkin.PRIMARY}
                    title={t('app:pages:services:project:summary:createProject')}
                    onPress={() => {
                      nextStep({
                        mainAccountUuid: selectedBankAccount?.uuid,
                        xValidationOnly: 0,
                        subAccountCreationRequestDto: {
                          accountType: AccountTypeDto.PROJECT,
                          name: preparedSubAccountOrder?.name,
                          icon: preparedSubAccountOrder?.icon,
                        },
                      })
                    }}
                  />
                </>
              }
            >

              <div className='SummaryContent_card'>
                <div className='SummaryContent_card-title'>
                  <Typography typeface='menuItemTitle'>
                    {t('app:pages:services:project:summary:firstTitle').toUpperCase()}
                  </Typography>
                </div>

                <div className='SummaryContent_card-icon'>
                  <Icon
                    name={BankAccountUtils.getFrontIconString(preparedSubAccountOrder?.icon)}
                    type={IconTypes.MATERIAL}
                    size={36}
                    color={Colors.white}
                    withBackground
                    containerStyle={{ backgroundColor: Colors.secondary }}
                  />
                </div>

                <div className='SummaryContent_card-projectName'>
                  <Typography typeface='input'>
                    {preparedSubAccountOrder?.name}
                  </Typography>
                </div>

                <div className='SummaryContent_card-button'>
                  <Button
                    skin={ButtonSkin.DEFAULT}
                    title={t('app:pages:services:project:summary:rename')}
                    small
                    textColor='black'
                    onPress={() => { editStep(ProjectStep.Name) }}
                  />
                </div>
              </div>
              {!OfferUtils.isOfferAdvance(socleOffer) && (
                <div className='SummaryContent_card'>
                  <Typography typeface='menuItemTitle'>
                    {t('app:pages:services:project:summary:secondTitle').toUpperCase()}
                  </Typography>
                  <div className='SummaryContent_card-description'>
                    <div className='SummaryContent_card-description-subTitle'>
                      <Typography typeface='bold'>
                        {t('app:pages:services:project:summary:subTitle', { remainingProjects: remainingProjects })}
                      </Typography>
                    </div>
                    <div className='SummaryContent_card-description-text1'>
                      {UseTextWithBoldWords({
                        text: t('app:pages:services:project:summary:description:text1', { offer: offer }),
                        regularFont: 'subtitle',
                        boldFont: 'boldMedium',
                      })}
                    </div>
                    <div className='SummaryContent_card-description-text2'>
                      <Typography typeface='subtitle'>
                        {t('app:pages:services:project:summary:description:text2')}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}

            </PageContent>
          </div>
        }
        onClose={() => {
          history.push(Routes.Services.ProjectsRoutes.index.path)
          dispatch(BankAccountActions.resetPrepareSubAccount())
        }}
        onBack={() => { editStep(ProjectStep.Name) }}
      />
    </LazyLoadingContainer>
  )
}
