import React, { FunctionComponent } from 'react'
import { View, Text, Image } from 'react-native'

import { Colors } from '@neo-commons/styles'
import { ContactDto } from '@afone/neo-core-client/dist/models'
import IBAN from 'iban'
import { Formatters } from '@neo-commons/libraries'

import { Typography, Icon } from '..'
import { Card, CardSkin } from '../Card/Card'
import { IconTypes } from '../Icon/Icon'

import styles from './styles'

enum ContactCardSkin {
  DEFAULT,
  NOELSE
}

interface ContactCardProps {
  contact: ContactDto,
  onPress: (contact: ContactDto) => void,
  selected?: boolean,
}

export const ContactCard: FunctionComponent<ContactCardProps> = ({ contact, onPress, selected }) => {
  const getIconFromSkin = (skin: ContactCardSkin): Image | typeof Icon => {
    let result

    switch (skin) {
      case ContactCardSkin.DEFAULT:
        result =
          <Icon
            name='user'
            testID='default'
            color={Colors.secondary}
            type={IconTypes.FEATHER}
            size={32}
          />
        break
      case ContactCardSkin.NOELSE:
        result =
          <Icon
            name='dark-logo'
            testID='noelse'
            color={Colors.black}
            type={IconTypes.NEOFONT}
            size={20}
          />
        break
    }

    return result
  }

  return (
    <Card skin={selected ? CardSkin.PRIMARY : CardSkin.TOUCHABLE} containerStyle={styles.container} onPress={() => onPress(contact)}>
      <View style={styles.iconContainer}>
        {getIconFromSkin(contact.applicationUser ? ContactCardSkin.NOELSE : ContactCardSkin.DEFAULT)}
      </View>
      <View style={styles.textsArea}>
        <Typography typeface='smallText' style={{ fontSize: 18, color: selected ? Colors.white : Colors.black }}>
          {contact.familyName} {contact.givenName}
        </Typography>
        {(contact.channels && contact.channels.length > 0) &&
          <Text accessibilityLabel={'IBANAccount-' + IBAN.printFormat(contact.channels[0].value!)} key={contact.channels[0].uuid} style={[styles.subtitle, { color: selected ? Colors.white : Colors.black }]}>
            {Formatters.formatIban(contact.channels[0].value!, true)}
          </Text>}
      </View>
    </Card>
  )
}
