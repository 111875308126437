import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { debounce } from 'lodash'
import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { BankAccountSelectors, TransactionActions, TransactionTypes } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { BankAccountUtils, TransactionUtils } from '@neo-commons/libraries'

import { AccountDto, TransactionDto, TransactionUpdateDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, LazyLoadingContainer, PageContent } from '@neo-web/componentsOld'

import { ServiceClientModal } from '../../Pages/MyProfile/HelpAndEmergency/ServiceClientModal'

import { AnnotationModal } from './AnnotationModal/AnnotationModal'
import { TransactionDetails } from './TransactionDetails'
import { TransactionInfo } from './TransactionInfo'
import { TransactionRefund } from './TransactionRefund'
import { TransactionConversion } from './TransactionConversion'
import { TransactionFees } from './TransactionFees'

interface TransactionProps {
  transaction?: TransactionDto
}

export const Transaction: React.FC<TransactionProps> = (props: TransactionProps) => {
  const { transaction } = props
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const param: any = useParams()

  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const isPosAccount = BankAccountUtils.isPos(selectedBankAccount)

  const [showModalClientService, setShowModalClientService] = useState(false)
  const [showAnnotationModal, setShowAnnotationModal] = useState(false)

  const updateTransaction = (transactionUpdate: TransactionUpdateDto) => {
    (async () => {
      try {
        setShowAnnotationModal(false)
        await dispatch(TransactionActions.updateTransaction(
          transaction.id, { finalize3ds: false, ...transactionUpdate }
        ))
      } catch (error) {}
    })()
  }

  const updateTransactionDebounce = React.useCallback(debounce(updateTransaction, 300), [])

  const titleButtonAnnotation = transaction?.annotation
    ? t('app:pages:synthesis:transaction:detail:note', { value: transaction.annotation })
    : t('app:pages:synthesis:transaction:detail:annotation')

  return (
    <LazyLoadingContainer
      events={[
        TransactionTypes.BYID_TRANSACTION_REQUEST,
      ]}
    >
      <ServiceClientModal
        show={showModalClientService} onClose={() => setShowModalClientService(false)}
      />
      <AnnotationModal
        show={showAnnotationModal}
        onClose={() => setShowAnnotationModal(false)}
        annotation={transaction?.annotation}
        sendAnnotation={(annotation) => updateTransactionDebounce({ annotation: annotation })}
      />
      <PageContent
        justifyContent={JustifyContent.BETWEEN}
        footer={
          !BankAccountUtils.isAggregated(selectedBankAccount) &&
            <Button
              title={t('app:pages:synthesis:transaction:detail:problem')}
              skin={ButtonSkin.DEFAULT}
              flat
              onPress={() => setShowModalClientService(true)}
              containerStyle={{ marginTop: 16 }}
            />
        }
      >
        {transaction &&
          <TransactionDetails
            transaction={transaction}
            onChecked={(value) => updateTransactionDebounce(
              { checked: value, annotation: transaction.annotation ?? '' }
            )}
            currency={selectedBankAccount?.currencyCode}
          />}

        {transaction?.transfered &&
          <Button
            title={titleButtonAnnotation}
            skin={ButtonSkin.DEFAULT_LIGHT}
            rightIcon={<Icon name='file-text' type={IconTypes.FEATHER} size={20} color={Colors.primary} />}
            overrideTextStyleButton={{ justifyContent: 'space-between' }}
            containerStyle={{ marginVertical: 16 }}
            onPress={() => setShowAnnotationModal(true)}
          />}

        {!BankAccountUtils.isAggregated(selectedBankAccount) &&
          <Button
            title={t('app:pages:synthesis:transaction:detail:downloadDocument')}
            skin={ButtonSkin.DEFAULT_LIGHT}
            rightIcon={<Icon name='download' type={IconTypes.FEATHER} size={20} color={Colors.primary} />}
            overrideTextStyleButton={{ justifyContent: 'space-between' }}
            containerStyle={{ marginVertical: 16 }}
            onPress={() => history.push(generatePath(
              Routes.Synthesis.transactionProof.path,
              { id: param.id }
            ))}
          />}

        {transaction?.amounts?.originalCurrencyAmount &&
          <TransactionConversion transaction={transaction} />}

        {transaction?.error?.detail &&
          <TransactionInfo info={transaction.error.detail} />}

        {(TransactionUtils.isPreAuthorisation(transaction) && !transaction?.cleared && transaction?.completed) &&
          <TransactionInfo info={t('app:pages:synthesis:transaction:detail:infoLockedPreautho')} />}

        {(!!transaction?.amounts?.fees &&
        !TransactionUtils.isFee(transaction)) &&
        !BankAccountUtils.isAggregated(selectedBankAccount) &&
          <TransactionFees transaction={transaction} />}

        {isPosAccount &&
          <TransactionRefund transaction={transaction} />}
      </PageContent>
    </LazyLoadingContainer>
  )
}
