import React, { FunctionComponent, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { RecurringTransferDto } from '@afone/neo-core-client/dist/models'
import { Formatters, TextUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { PaymentCard } from '../TransactionCard/PaymentCard'
import { Icon, IconTypes } from '../Icon/Icon'

import styles from './styles'

export interface RecurringTransferCardProps {
  transfer?: RecurringTransferDto
  clickable?: boolean
  onPress: (transfer: RecurringTransferDto) => void,
}

export const RecurringTransferCard: FunctionComponent<RecurringTransferCardProps> =
  memo((props: RecurringTransferCardProps) => {
    const { transfer, clickable = true, onPress } = props
    const { t } = useTranslation()

    const icon =
      <Icon
        type={transfer?.recipientAccountName ? IconTypes.FEATHER : IconTypes.NEOFONT}
        name={transfer?.recipientAccountName ? 'corner-up-left' : 'vir-out'} size={32} color={Colors.secondary}
      />

    return (
      <View style={styles.recurringCard}>
        <PaymentCard
          title={transfer?.recipientAccountName
            ? t('neo-commons:transfer:default:incoming', {
              recipientAccountName: transfer?.recipientAccountName!,
            })
            : TextUtils.prettyShortText(`${transfer?.contactGivenName} ${transfer?.contactFamilyName}`, 15)}
          info={transfer?.recipientAccountName
            ? TextUtils.prettyShortText(`${transfer?.privateLabel}`, 15)
            : Formatters.formatIban(transfer?.contactChannelValue ?? '', true)}
          amount={transfer?.price?.amount}
          icon={icon}
          clickable={clickable}
          price={transfer?.price}
          feesLabel={t('app:pages:payments:transfer:fees')}
          onPress={() => transfer && onPress(transfer)}
        />
      </View>
    )
  })
