import React, { useEffect } from 'react'

import { Loader } from '@neo-web/componentsOld'

import { agentClientUserManager } from '../../../../Config/AgentClientUserManager'

import './AgentClientRegister.scss'

export const RedirectLogin: React.FC = () => {
  useEffect(() => {
    agentClientUserManager.signinRedirect()
  }, [])

  return (
    <div className='container'>
      <Loader loop isShow />
    </div>
  )
}
