import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { SignoutCallbackComponent } from 'redux-oidc'
import { Routes } from 'Routes/Routes'

import { JustifyContent, PageContent } from '@neo-web/componentsOld'

import { agentClientUserManager } from '../../../../Config/AgentClientUserManager'

export const LogoutCallback:React.FunctionComponent = () => {
  const [stateCallBack, setStateCallBack] = useState(true)
  const history = useHistory()
  const { t } = useTranslation()

  const successCallback = (): void => {
    setStateCallBack(true)
  }

  const errorCallback = (error: Error): void => {
    setStateCallBack(false)
    throw error
  }

  useEffect(() => {
    if (stateCallBack) {
      history.push(Routes.SvaRegisterRoutes.index.path)
    } else {
      history.push(Routes.Error[401].path)
    }
  }, [stateCallBack])

  return (
    <SignoutCallbackComponent
      userManager={agentClientUserManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      <PageContent
        justifyContent={JustifyContent.BETWEEN}
        title={t('app:pages:agentClientRegister:logout:title')}
      />
    </SignoutCallbackComponent>
  )
}
