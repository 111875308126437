import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { Icon, IconTypes, MenuItemCard } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { ClientSelectors, DocumentUploadActions, SubscriptionSelectors } from '@neo-commons/store'
import { EnumUtils, SubscriptionUtils } from '@neo-commons/libraries'

import { ClientDto, PersonAffiliationDto, StepKycItemDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './DocumentsInvalid.scss'

type StepKycItemWithAffiliate = StepKycItemDto & { affiliate?: PersonAffiliationDto }

export const DocumentsInvalid: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const affiliates = useMemo(() => client?.holder?.affiliates, [client])

  const kycDocuments: StepKycItemWithAffiliate[] = useMemo(() => {
    const affiliateDocuments = []
    affiliates && affiliates.forEach((affiliate) => {
      if (SubscriptionUtils.hasKycAffiliateDocumentsIncomplete(subscription, affiliate.person.uuid)) {
        const docs = SubscriptionUtils
          .getKycAffiliateDocumentsIncompleteAndInvalid(subscription, affiliate?.person?.uuid)
        affiliateDocuments.push(...docs.map((doc) => {
          return {
            ...doc,
            affiliate: affiliate,
          }
        }))
      }
    })
    return SubscriptionUtils.getKycDocumentsIncompleteAndInvalid(subscription).concat(affiliateDocuments)
  }, [subscription, affiliates])

  useEffect(() => {
    if (!SubscriptionUtils.isKycStepInProgress(subscription) &&
        !SubscriptionUtils.hasKycAffiliateStepInProgress(subscription)) {
      history.goBack()
    }
  }, [subscription])

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      icon={<Icon name='info' type={IconTypes.FEATHER} size={40} color={Colors.secondary} />}
      subtitle={t('app:pages:subscription:global:reasonDocumentsProcessingFailure:title')}
      description={t('app:pages:subscription:global:reasonDocumentsProcessingFailure:description')}
    >
      <div className='DocumentsInvalid_container'>
        {kycDocuments?.map((document, index) =>
          <div className='DocumentsInvalid_container_item' key={index}>
            <MenuItemCard
              title={
                (document?.affiliate ? document?.affiliate?.person?.fullName + ' - ' : '') +
                '' +
                t(EnumUtils.getKycTypeDtoKey(document.type))
              }
              subtitle={document?.reason ? t(EnumUtils.getReasonDtoKey(document.reason)) : undefined}
              onPress={async () => {
                if (document?.affiliate) {
                  await dispatch(DocumentUploadActions.prepare({
                    name: t(EnumUtils.getKycTypeDtoKey(document.type)),
                    documentType: document.type,
                  }, document?.affiliate?.person?.uuid))
                } else {
                  await dispatch(DocumentUploadActions.prepare({
                    name: t(EnumUtils.getKycTypeDtoKey(document.type)),
                    documentType: document.type,
                  }))
                }
                history.push(Routes.DocumentUpload.index.path)
              }}
            />
          </div>
        )}
      </div>
    </TunnelContent>
  )
}
