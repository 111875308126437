import {
  AccountStatusDto, AccountTypeDto, AffiliationTypeDto, ClientTypeDto, ContactPreferenceTypeDto, EmployeesNumberDto, ImagePartDto,
  KycTypeDto, NotificationPreferenceTypeDto, ReasonDto, RevenueDto, TransactionTypeFamilyDto,
} from '@afone/neo-core-client'

type EnumsListObject = {
  AccountStatusDto: AccountStatusDto,
  AccountTypeDto: AccountTypeDto,
  AffiliationTypeDto: AffiliationTypeDto,
  ClientTypeDto: ClientTypeDto,
  ContactPreferenceTypeDto: ContactPreferenceTypeDto,
  ImagePartDto: ImagePartDto,
  KycTypeDto: KycTypeDto,
  NotificationPreferenceTypeDto: NotificationPreferenceTypeDto,
  TransactionTypeFamilyDto: TransactionTypeFamilyDto,
  RevenueDto: RevenueDto,
  EmployeesNumberDto: EmployeesNumberDto,
  ReasonDto: ReasonDto,
}
// It's used only to generate getKey functions. It's mandatory if we want good typing
const enumsListObject: EnumsListObject = {
  AccountStatusDto: AccountStatusDto.ACTIVE, // Take any enum
  AccountTypeDto: AccountTypeDto.AGGREGATED,
  AffiliationTypeDto: AffiliationTypeDto.CURATOR,
  ClientTypeDto: ClientTypeDto.CORPORATE,
  ContactPreferenceTypeDto: ContactPreferenceTypeDto.ADVISOR,
  ImagePartDto: ImagePartDto.OTHER,
  KycTypeDto: KycTypeDto.ADDRESS,
  NotificationPreferenceTypeDto: NotificationPreferenceTypeDto.BUDGET_COACHING,
  TransactionTypeFamilyDto: TransactionTypeFamilyDto.AC_CREDIT,
  RevenueDto: RevenueDto.LESS_10_K,
  EmployeesNumberDto: EmployeesNumberDto.LESS_10,
  ReasonDto: ReasonDto.CORRUPT,
}

type GetKeyFunctionType<T> = {
  [Property in keyof T as `get${string & Property}Key`]: (enumType: T[Property]) => string
} | Record<string, never> // It prevent TS to raise a typing error on the empty object of the .reduce() below

const generateGetKeyFunctions = (enumsListObject: EnumsListObject): GetKeyFunctionType<EnumsListObject> =>
  Object.keys(enumsListObject).reduce(
    (functions, dtoName) => ({
      ...functions,
      [`get${dtoName}Key`]: value => `${dtoName}:${value}`,
    }),
    {}
  )

export const EnumUtils = generateGetKeyFunctions(enumsListObject)
