import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { createSelector, Selector } from 'reselect'
import { uniq, unset, isNumber } from 'lodash'

import { initialResourceState, State } from './index'

type ResourceOperationType = 'list' | 'create' | 'update' | 'delete' | 'byId';
type ResourceOperationStatus = 'request' | 'success' | 'failure' | 'cancelled';

interface CommonKeyType {
  PREPARE?: string,
  RESET?: string,
  RESET_PREPARE?: string,
  RESET_SUBLIST?: string,
  PREPARE_FILTER?: string,
  SET_SELECTED?: string,
  RESET_CACHE?: string,
}

type OperationGetters<T, N extends string, S extends string> = {
  [K in keyof T as `${Uppercase<string & K>}_${Uppercase<N>}_${Uppercase<S>}`]?: string
}

type ResourceType<State> = CommonKeyType & OperationGetters<State, ResourceOperationType, ResourceOperationStatus>;
type SingleOperationActionTypes<K extends string, O extends string> =
  `${Uppercase<O>}_${Uppercase<K>}_${Uppercase<ResourceOperationStatus>}`

type SingleOperationActionTypeObject<K extends string> = Record<K, string> & CommonKeyType

function getResourceOperationType<K extends string> (key: string):
  SingleOperationActionTypeObject<SingleOperationActionTypes<K, ResourceOperationType>> {
  const resourceOperationType = {}
  const operations = ['list', 'create', 'update', 'delete', 'byId']

  operations.forEach((operation) => {
    resourceOperationType[`${operation.toUpperCase()}_${key.toUpperCase()}_REQUEST`] =
      `${key}/${operation.toUpperCase()}_${key.toUpperCase()}_REQUEST`
    resourceOperationType[`${operation.toUpperCase()}_${key.toUpperCase()}_SUCCESS`] =
      `${key}/${operation.toUpperCase()}_${key.toUpperCase()}_SUCCESS`
    resourceOperationType[`${operation.toUpperCase()}_${key.toUpperCase()}_FAILURE`] =
      `${key}/${operation.toUpperCase()}_${key.toUpperCase()}_FAILURE`
    resourceOperationType[`${operation.toUpperCase()}_${key.toUpperCase()}_CANCELLED`] =
      `${key}/${operation.toUpperCase()}_${key.toUpperCase()}_CANCELLED`
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return resourceOperationType
}

type StateGetters<T> = {
  [K in keyof T as `${string & K}`]: null
}
type StoreType = keyof StateGetters<State>;

export type StoreResourceType = ResourceType<State>

export interface PaginateItem<T> {
  data: T[],
  title: string,
}

export type Dispatch = ThunkDispatch<State, void, AnyAction>
export type ThunkActionCreator = (...args: any) => ThunkAction<Promise<any>, State, void, AnyAction>
export type List = {
  ids: string[];
  selectedId: string | null;
  loadedOnce: boolean;
  shouldRefresh: boolean;
  sort: string | null;
  order: string | null;
  page: number | null;
  perPage: number;
  filter: any;
  paginationEnded: boolean;
  subLists: { [key: string]: List };
  length: number;
}

export interface ResourceState<T> {
  loading: boolean;
  data: { [key: string]: T };
  total: number;
  list: List,
  create: Partial<T> | null,
  update: Partial<T> | null,
  prepare: Partial<T> | null,
  isDelete: boolean,
}

export interface Filter {
  perPage: number | null;
  page: number | null;
  filter: {
    text: string | null;
  }
}

const getResourceTypes = <K extends string> (key: string) => {
  return {
    ...getResourceOperationType<K>(key),
    ...{
      RESET: `${key}/RESET`,
      RESET_PREPARE: `${key}/RESET_PREPARE`,
      PREPARE: `${key}/PREPARE`,
      PREPARE_FILTER: `${key}/PREPARE_FILTER`,
      SET_SELECTED: `${key}/SET_SELECTED`,
      RESET_SUBLIST: `${key}/RESET_SUBLIST`,
      RESET_CACHE: `${key}/RESET_CACHE`,
    },
  }
}

/**
 * Reducer to update the data of one object of reducer.
 * @param existingData old data of object to merge
 * @param updated new object to merge
 * @param identifier key to identify object
 */
export const updateDataReducer = (existingData, updated, identifier = 'uuid') => {
  return {
    ...existingData,
    [updated[identifier]]: updated,
  }
}

/**
 * Reducer to update list object.
 * @param existingData old data of object to merge
 */
export const listReducer = (existingData, action,
  {
    identifier = 'uuid',
    isPaginate = false,
    listIdentifier = '',
  } = {}) => {
  let paginationObject = {}
  const subLists = existingData.subLists

  if (isPaginate) {
    paginationObject = {
      ...action.listData,
      loadedOnce: true,
      paginationEnded: action.paginationEnded,
      page: isNumber(action.page) ? action.page : existingData.page,
    }
  }

  if (listIdentifier) {
    if (!subLists[listIdentifier]) {
      subLists[listIdentifier] = JSON.parse(JSON.stringify(initialResourceState.list))
    }

    subLists[listIdentifier].ids = isPaginate
      ? uniq(subLists[listIdentifier].ids.concat(action.data?.map(item => item[identifier]) ?? []))
      : action.data?.map(item => item[identifier])

    if (isPaginate) {
      subLists[listIdentifier] = {
        ...subLists[listIdentifier],
        ids: uniq(subLists[listIdentifier].ids.concat(action.data?.map(item => item[identifier]) ?? [])),
        ...paginationObject,
      }
    } else {
      subLists[listIdentifier] = {
        ...subLists[listIdentifier],
        ids: action.data?.map(item => item[identifier]),
      }
    }
  }

  return {
    ...existingData,
    ids: isPaginate ? uniq(existingData.ids.concat(action.data?.map(item => item[identifier]) ?? []))
      : action.data?.map(item => item[identifier]) ?? [],
    subLists: subLists,
    loadedOnce: true,
    shouldRefresh: false,
    ...paginationObject,
  }
}

/**
 * Reducer to update the data in a list object.
 * @param data list object
 * @param identifier key to identify object
 */
export const listDataReducer = (data, oldState, identifier = 'uuid') => {
  return data ? data?.reduce((acc, item) => ({
    ...acc,
    [item[identifier]]: item,
  }), oldState.data) ?? {} : {}
}

/**
 * Loading reducer.
 * @param loading boolean
 * @param state current state
 */
export const loadingReducer = ({
  loading,
  state,
}) => {
  return {
    ...state,
    loading,
  }
}

/**
 * Prepare reducer.
 * @param oldState old state
 * @param newState new state
 */
export const prepareReducer = (oldState, newState) => {
  return {
    ...oldState,
    ...newState,
  }
}

export const reduceAndRemoveSubList = (state: any, listIdentifier: string) => {
  const dataList = state.data
  if (state.list.subLists[listIdentifier]) {
    state.list.subLists[listIdentifier].ids.forEach(id => {
      delete dataList[id]
    })
    delete state.list.subLists[listIdentifier]
  }

  return {
    ...state,
    data: dataList,
    list: state.list,
  }
}

const getResourceReducer = <K extends string> (key,
  actionTypes: SingleOperationActionTypeObject<SingleOperationActionTypes<K, ResourceOperationType>>) => (
    state, action: AnyAction, options: { identifier: string, isPaginate?: boolean, initialState }) => {
    let data = {}

    switch (true) {
      case action.type === actionTypes[`LIST_${key.toUpperCase()}_REQUEST`]:
      case action.type === actionTypes[`DELETE_${key.toUpperCase()}_REQUEST`]:
      case action.type === actionTypes[`UPDATE_${key.toUpperCase()}_REQUEST`]:
      case action.type === actionTypes[`CREATE_${key.toUpperCase()}_REQUEST`]:
      case action.type === actionTypes[`BYID_${key.toUpperCase()}_REQUEST`]:
        return loadingReducer({
          loading: true,
          state,
        })
      case action.type === actionTypes[`LIST_${key.toUpperCase()}_FAILURE`]:
      case action.type === actionTypes[`DELETE_${key.toUpperCase()}_FAILURE`]:
      case action.type === actionTypes[`UPDATE_${key.toUpperCase()}_FAILURE`]:
      case action.type === actionTypes[`CREATE_${key.toUpperCase()}_FAILURE`]:
      case action.type === actionTypes[`BYID_${key.toUpperCase()}_FAILURE`]:
        return loadingReducer({
          loading: false,
          state,
        })
      case action.type === actionTypes[`LIST_${key.toUpperCase()}_CANCELLED`]:
      case action.type === actionTypes[`DELETE_${key.toUpperCase()}_CANCELLED`]:
      case action.type === actionTypes[`UPDATE_${key.toUpperCase()}_CANCELLED`]:
      case action.type === actionTypes[`CREATE_${key.toUpperCase()}_CANCELLED`]:
      case action.type === actionTypes[`BYID_${key.toUpperCase()}_CANCELLED`]:
        return loadingReducer({
          loading: false,
          state,
        })
      case action.type === actionTypes[`LIST_${key.toUpperCase()}_SUCCESS`]:
        return {
          ...state,
          loading: false,
          data: listDataReducer(action.data, state, options.identifier),
          list: listReducer(state.list, action, {
            identifier: options.identifier,
            isPaginate: options.isPaginate,
            listIdentifier: action.listIdentifier ?? undefined,
          }),
        }
      case action.type === actionTypes[`CREATE_${key.toUpperCase()}_SUCCESS`]:
      case action.type === actionTypes[`UPDATE_${key.toUpperCase()}_SUCCESS`]:

        if (action.data) {
          data = {
            [action.data.uuid ?? action.data.id]: action.data,
          }
        }
        return {
          ...state,
          loading: false,
          data: {
            ...state.data,
            ...data,
          },
          list: {
            ...state.list,
            shouldRefresh: true,
          },
        }
      case action.type === actionTypes[`DELETE_${key.toUpperCase()}_SUCCESS`]:
        unset(state.data, [action.data[options.identifier]])

        return {
          ...state,
          loading: false,
          data: state.data,
          isDelete: true,
          list: {
            ...state.list,
            ids: state.list.ids.filter((item) => item !== action.data[options.identifier]),
            shouldRefresh: true,
          },
        }

      case action.type === actionTypes[`BYID_${key.toUpperCase()}_SUCCESS`]:
        return {
          ...state,
          data: {
            ...state.data,
            ...{ [action.data[options.identifier]]: action.data },
          },
          list: {
            ...state.list,
            ids: uniq([...state.list.ids, action.data[options.identifier]]),
          },
          loading: false,
        }

      case action.type === actionTypes.SET_SELECTED:
        return {
          ...state,
          list: {
            ...state.list,
            selectedId: action.selectedId,
          },
        }

      case action.type === actionTypes.PREPARE:
        return {
          ...state,
          prepare: {
            ...state.prepare,
            ...action.data,
          },
        }
      case action.type === actionTypes.PREPARE_FILTER:
        return {
          ...state,
          list: {
            ...state.list,
            sort: action.data.sort,
            filter: action.data.filter,
          },
        }
      case action.type === actionTypes.RESET_PREPARE:
        return {
          ...state,
          prepare: options.initialState.prepare,
        }

      case action.type === actionTypes.RESET_SUBLIST:
        return reduceAndRemoveSubList(state, action.listIdentifier)

      case action.type === actionTypes.RESET:
        return options.initialState

      default:
        return state
    }
  }

const getResourceActions = <T> (key) => {
  return {
    reset: () => {
      return async (dispatch: Dispatch) => {
        dispatch({ type: `${key}/RESET` })
      }
    },
    resetPrepare: () => {
      return async (dispatch: Dispatch) => {
        dispatch({ type: `${key}/RESET_PREPARE` })
      }
    },
    resetSubList: (listIdentifier: string) => {
      return async (dispatch: Dispatch) => {
        dispatch({ type: `${key}/RESET_SUBLIST`, listIdentifier })
      }
    },
    prepare: (data: Partial<T>) => {
      return async (dispatch: Dispatch) => {
        dispatch({
          type: `${key}/PREPARE`,
          data,
        })
      }
    },
    prepareFilter: (data: Partial<Filter>) => {
      return async (dispatch: Dispatch) => {
        dispatch({
          type: `${key}/PREPARE_FILTER`,
          data,
        })
      }
    },
    setSelected: (selectedId: string) => {
      return async (dispatch: Dispatch) => {
        dispatch({
          type: `${key}/SET_SELECTED`,
          selectedId,
        })
      }
    },
  }
}

export const resourceDefaultSelectors = (resourceSelector: Selector<State, any>) => ({
  list: createSelector(
    resourceSelector,
    resource => resource.list.ids.map((id: string) => resource.data[id])
  ),
  listByIdentifier: (identifier: string) => createSelector(
    resourceSelector,
    resource => identifier !== undefined &&
    resource.list.subLists && resource.list.subLists[identifier]
      ? resource.list.subLists[identifier].ids.map((id: string) => resource.data[id]) : []
  ),
  selected: createSelector(
    resourceSelector,
    resource => resource.data[resource.list.selectedId] ?? null
  ),
  byId: (id: string) => createSelector(
    resourceSelector,
    resource => resource.data[id] ?? null
  ),
})

export const ResourceStateFactory = <T, K extends StoreType> (state: Selector<State, any>, key: K) => {
  const resourceActionTypes = getResourceTypes<K>(key)
  const resourceReducer = getResourceReducer(key, resourceActionTypes)
  const resourceAction = getResourceActions<T>(key)
  const resourceSelector = resourceDefaultSelectors(state)

  return {
    resourceActionTypes,
    resourceReducer,
    resourceAction,
    resourceSelector,
  }
}
