import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import dayjs from 'dayjs'
import { Timeline } from 'rsuite'

import { Typography, Button, ButtonSkin } from '@neo-commons/components'
import { DeviceActions, DeviceSelectors, DeviceTypes } from '@neo-commons/store'

import { DeviceStatusDto } from '@afone/neo-core-client/dist/models'

import './DeviceActivity.scss'
import { LazyLoadingContainer } from '@neo-web/componentsOld'

interface DeviceActivityProps {
  deviceUuid: string
}

export const DeviceActivity: React.FC<DeviceActivityProps> = (
  props: DeviceActivityProps
) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const deviceSelected = useSelector(DeviceSelectors.selected)
  const [didMount, setDidMount] = useState(false)

  const lockUnlockDevice = async () => {
    try {
      if (deviceSelected.status === DeviceStatusDto.ACTIVE) {
        await dispatch(DeviceActions.lock(deviceSelected.uniqueDeviceId))
      } else {
        await dispatch(DeviceActions.unlock(deviceSelected.uniqueDeviceId))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        if (props.deviceUuid) {
          await dispatch(DeviceActions.searchDevicesActivities(props.deviceUuid))
          setDidMount(true)
          return () => setDidMount(false)
        }
      } catch (error) {
      }
    })()
  }, [dispatch, props.deviceUuid])

  return (
    <LazyLoadingContainer events={[DeviceTypes.SET_SELECTED_DEVICE, DeviceTypes.LIST_DEVICE_ACTIVITIES_REQUEST]}>
      {didMount &&
        <div className='DeviceActivity'>
          <div className='DeviceActivity_title'>
            <Typography typeface='info'>
              {deviceSelected && !deviceSelected.activities
                ? t('app:pages:myProfile:components:devicesList:activities:empty')
                : t('app:pages:myProfile:components:devicesList:activities:title')}
            </Typography>
          </div>

          {(deviceSelected && deviceSelected.activities) &&
            <Timeline className='DeviceActivity_timeline'>
              {deviceSelected.activities.map((activity, index) =>
                <Timeline.Item key={index} className='DeviceActivity_timeline_item'>
                  <Typography typeface='smallText'>
                    {dayjs(activity.createdDate).fromNow()}
                  </Typography>
                  <div>{activity.location}</div>
                </Timeline.Item>
              )}
            </Timeline>}

          <div className='DeviceActivity_button'>
            <Button
              title={deviceSelected && deviceSelected.status === DeviceStatusDto.ACTIVE
                ? t('app:pages:myProfile:components:devicesList:activities:lockDevice')
                : t('app:pages:myProfile:components:devicesList:activities:unlockDevice')}
              skin={ButtonSkin.PRIMARY}
              onPressOut={() => lockUnlockDevice()}
            />
          </div>
        </div>}
    </LazyLoadingContainer>
  )
}
