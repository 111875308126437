import React from 'react'
import { useTranslation } from 'react-i18next'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { ProvisioningActions } from '@neo-commons/store'

import { Tunnel } from '@neo-web/componentsOld'

import { CreditChoice } from './CreditChoice/CreditChoice'
import { Transfer } from './Transfer/Transfer'
import { DebitCard } from './DebitCard/DebitCard'

import './Credit.scss'

interface CreditProps {
  initData?: any,
  onClose?: () => void
}

export const Credit: React.FC<CreditProps> = (props: CreditProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const onClose = () => {
    dispatch(ProvisioningActions.reset())
    if (props?.onClose) {
      props.onClose()
    } else {
      history.push(Routes.Origin.path)
    }
  }

  return (
    <Tunnel
      title={t('app:pages:credit:title')}
      onClose={onClose}
      onBack={() => history.goBack()}
      component={
        <Switch>
          <Route
            exact
            path='/credit'
            render={() => {
              return (
                <Redirect to={Routes.Credit.creditChoices.path} />
              )
            }}
          />
          <Route path={Routes.Credit.creditChoices.path} component={CreditChoice} />
          <Route path={Routes.Credit.transfer.path} component={Transfer} />
          <Route path={Routes.Credit.debitCard.path}>
            <DebitCard
              initData={props?.initData}
              onClose={props?.onClose}
            />
          </Route>
        </Switch>
      }
    />
  )
}
