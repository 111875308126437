import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AppConfig } from 'Config/AppConfig'

import { Button, ButtonSkin, DebitCardInputs, Icon, IconTypes, RadioInput, Typography } from '@neo-commons/components'
import { PreparedProvisioning, ProvisioningActions, ProvisioningSelectors } from '@neo-commons/store'
import { ComnpaySchemeType, CreditCardUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { JustifyContent, Modal, PageContent } from '@neo-web/componentsOld'

import { getCbSchemeImage } from '../CbSchemeUtils'

import './AddNewCard.scss'

interface AddNewCardProps {
  nextStep: () => void,
}

export const AddNewCard: React.FC<AddNewCardProps> = (props: AddNewCardProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const preparedProvisioning: PreparedProvisioning = useSelector(ProvisioningSelectors.prepare)

  const [stateCard, setStateCard] = useState(preparedProvisioning?.card?.new?.formData)

  const [cbInputsValid, setCbInputsValid] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCvvModalOpen, setIsCvvModalOpen] = useState(false)
  const [cbSchemes, setCbSchemes] = useState<ComnpaySchemeType[]>([])
  const [selectedCbScheme, setSelectedCbScheme] = useState<ComnpaySchemeType>(ComnpaySchemeType.CB)

  const [owner, setOnDebitCardOwner] = useState(stateCard?.owner ?? '')
  const [bin, setOnDebitCardNumbers] = useState(stateCard?.bin ?? '')
  const [expirationDate, setOnDebitCardDate] = useState(stateCard?.expirationDate ?? '')
  const [cvv, setOnDebitCardCvv] = useState(stateCard?.cvv ?? '')
  const [saveCard, setOnDebitCardCard] = useState(stateCard?.saveCard ?? false)
  const [errors, setErrors] = useState({
    owner: false,
    numbers: false,
    date: false,
    cvv: false,
  })

  const dispatchCardInfo = async () => {
    try {
      const schemesChoices = cbSchemes.length > 1
        ? CreditCardUtils.convertComnpaySchemeTypeToNoelseFormat(
          cbSchemes.filter(element => element !== ComnpaySchemeType.CB)[0],
          true
        )
        : CreditCardUtils.convertComnpaySchemeTypeToNoelseFormat(cbSchemes[0])

      await dispatch(ProvisioningActions.tokenizeCard({
        ...stateCard,
        schemesChoices,
        scheme: CreditCardUtils.convertComnpaySchemeTypeToNoelseFormat(selectedCbScheme),
      }))
    } catch (e) {}
  }

  useEffect(() => {
    setStateCard({
      ...stateCard,
      owner,
      bin,
      expirationDate,
      cvv,
      saveCard,
    })
  }, [owner, bin, expirationDate, cvv, saveCard])

  const validateFormHandler = async () => {
    try {
      const result: any = await dispatch(ProvisioningActions.findBinInfos(stateCard.bin))

      if (result.nbBins >= AppConfig.MIN_CB_SCHEME_NUMBER_FOR_MODAL) {
        setCbSchemes(result.bin.map((elem) => elem.scheme))
        setIsModalOpen(true)
      }
      if (result.nbBins < AppConfig.MIN_CB_SCHEME_NUMBER_FOR_MODAL) {
        setCbSchemes(result.bin.map((elem) => elem.scheme))
        setSelectedCbScheme(result.bin[0].scheme)
        dispatchCardInfo()
        props.nextStep()
      }
    } catch (e) {
      setErrors({
        ...errors,
        numbers: true,
      })
    }
  }

  return (
    <PageContent
      withBackground
      style={{ textAlign: 'center' }}
      justifyContent={JustifyContent.BETWEEN}
      title={t('app:pages:credit:step2:cbInfos:titleMessage')}
      footer={
        <Button
          title={t('app:global:validate')}
          skin={ButtonSkin.PRIMARY}
          disabled={!cbInputsValid}
          onPressOut={() => validateFormHandler()}
        />
      }
    >
      <DebitCardInputs
        errors={errors}
        values={stateCard}
        onCheckAllValid={(value) => setCbInputsValid(value)}
        onDebitCardOwner={(value) => setOnDebitCardOwner(value)}
        onDebitCardNumbers={(value) => setOnDebitCardNumbers(value)}
        onDebitCardDate={(value) => setOnDebitCardDate(value)}
        onDebitCardCvv={(value) => setOnDebitCardCvv(value)}
        onSaveDebitCard={(value) => setOnDebitCardCard(value)}
        onCvvInfoPress={() => setIsCvvModalOpen(true)}
      />
      <Modal
        title={t('app:pages:credit:addNewCard:modal:title')}
        open={isModalOpen}
      >
        <div className='AddNewCard_modal'>
          {cbSchemes?.map((scheme, id) => {
            return (
              <div key={id} className='AddNewCard_modal-CbChoice'>
                <img alt='' src={getCbSchemeImage(CreditCardUtils.convertComnpaySchemeTypeToNoelseFormat(scheme))} />
                <RadioInput
                  value={scheme === selectedCbScheme}
                  onPress={() => {
                    setSelectedCbScheme(scheme)
                  }}
                />
              </div>
            )
          })}
        </div>
        <Button
          title={t('app:global:validate')}
          skin={ButtonSkin.PRIMARY}
          onPressOut={() => {
            dispatchCardInfo()
            setIsModalOpen(false)
            props.nextStep()
          }}
        />
      </Modal>
      <Modal
        icon={
          <div className='AddNewCard_modal-icon'>
            <Icon name='cvv' type={IconTypes.NEOFONT} size={50} color={Colors.secondary} />
          </div>
        }
        title={t('app:pages:credit:addNewCard:modal:cvv:title')}
        open={isCvvModalOpen}
        onClose={() => setIsCvvModalOpen(false)}
        displayCross
      >
        <div className='AddNewCard_modal-cvv'>
          <div className='AddNewCard_modal-text'>
            <Typography typeface='content'>
              {t('app:pages:credit:addNewCard:modal:cvv:subtitle')}
            </Typography>
          </div>
        </div>
      </Modal>
    </PageContent>
  )
}
