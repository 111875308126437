import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StepComponentProps } from '@neo-commons/policies'
import { TransactionRefundStep, TransactionRefundSteps } from '@neo-commons/policies/src/Transaction'
import { Button, ButtonSkin, MoneyInput } from '@neo-commons/components'
import { PrepareRefund, TransactionSelectors } from '@neo-commons/store'
import { Formatters, NumbersUtils, Validators } from '@neo-commons/libraries'

import { AmountHiddenInput, JustifyContent, PageContent } from '@neo-web/componentsOld'

type TransactionRefundAmountProps = StepComponentProps<TransactionRefundSteps[TransactionRefundStep.Amount]>

export const Amount: React.FC<TransactionRefundAmountProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const preparedRefund: PrepareRefund = useSelector(TransactionSelectors.prepareRefund)
  const refund = preparedRefund?.transaction?.detail?.refund

  const [value, setValue] = useState(refund?.availableAmount.toString())
  const [isValid, setIsValid] = useState(false)

  const validators = [
    Validators.isLowerThan(refund?.availableAmount,
      t('app:pages:synthesis:transaction:refund:amount:maximumRefundError', { amount: refund?.availableAmount })),
  ]

  useEffect(() => {
    if (value) {
      setIsValid(
        NumbersUtils.amountFormatToNumber(value) > 0 &&
        (NumbersUtils.amountFormatToNumber(value) <= refund?.availableAmount)
      )
    }
  }, [value, refund])

  return (
    <PageContent
      title={t('app:pages:synthesis:transaction:refund:amount:input')}
      justifyContent={JustifyContent.BETWEEN}
      footer={(
        <Button
          title={t('app:global:validate')}
          skin={ButtonSkin.PRIMARY}
          onPress={() => nextStep({
            ...preparedRefund,
            amount: parseFloat(value),
          })}
          disabled={!isValid}
        />
      )}
    >
      <AmountHiddenInput value={value}>
        <MoneyInput
          autoFocus
          value={value}
          errorStyle={{ minWidth: 280, marginLeft: 'calc(-140px + 50%)', justifyContent: 'center' }}
          currency={t('app:global:currency')}
          validators={validators}
          fullErrorMode={!isValid && value !== ''}
          onChangeText={text => setValue(Formatters.formatMoney(text))}
          placeholder={(refund?.availableAmount ?? 0).toString()}
          isValid={isValid}
        />
      </AmountHiddenInput>
    </PageContent>
  )
}
