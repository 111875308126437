import React, { useEffect, useState } from 'react'

import './AmountHiddenInput.scss'

interface AmountHiddenInputProps {
  value: string,
  isFirstCredit?: boolean,
}

export const AmountHiddenInput: React.FC<AmountHiddenInputProps> = (props) => {
  const [valueWidth, setValueWidth] = useState<number>(0)

  useEffect(() => {
    // Get width of hidden input to set the real input width and keep it centered
    const hiddenMoneyInputWidth = document.getElementById('Amount_money_hidden')?.clientWidth

    // Sometimes, hidden input change width by 1px at each render, which lead to infinite loop
    // That's why we verify new width got more than 1px of difference
    if (hiddenMoneyInputWidth > valueWidth + 1 || hiddenMoneyInputWidth < valueWidth - 1) {
      setValueWidth(hiddenMoneyInputWidth)
    }
  }, [props.value])

  return (
    <div className='AmountHiddenInput_container'>
      <div className='AmountHiddenInput_item'>
        <div className='AmountHiddenInput_money' style={{ width: `${valueWidth ? valueWidth + 5 : (props?.isFirstCredit ? 90 : 70)}px` }}>
          <div className='AmountHiddenInput_money_hidden' id='Amount_money_hidden'>
            {props.value}
          </div>
          {props.children}
        </div>
      </div>
    </div>
  )
}
