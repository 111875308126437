import React, { Component } from 'react'
import { TextStyle, View, ViewStyle } from 'react-native'

import i18next from 'i18next'
import { Formatters, Validators } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { Input, InputProps } from '..'

import s from './styles'

export interface MoneyInputProps extends InputProps {
  currency?: string,
  isValid?: boolean,
  hasClear?: boolean,
  currencyStyle?: TextStyle
  inputStyle?: TextStyle
  containerStyle?: ViewStyle
  inputContainerStyle?: ViewStyle,
  testID?: string,
  // display all content in red when input is errored
  fullErrorMode?: boolean,
  errorStyle?: ViewStyle | TextStyle,
}

const initialState = {
  isFocused: false,
  fullErrorMode: false,
}

type MoneyInputState = Readonly<typeof initialState>

class MoneyInputComponent extends Component<MoneyInputProps> {
  readonly state: MoneyInputState = initialState

  getStyles () {
    const inputStyle: TextStyle[] = [s.input]
    const inputContainerStyle: ViewStyle[] = [s.inputContainer, this.props.inputContainerStyle ?? {}]
    const containerStyle: ViewStyle[] = [s.container, this.props.containerStyle ?? {}]
    const currencyStyle: TextStyle[] = [s.currencyText, this.props.currencyStyle ?? {}]

    this.props.inputStyle && inputStyle.push(this.props.inputStyle)
    this.props.currencyStyle && currencyStyle.push(this.props.currencyStyle)

    return { inputStyle, currencyStyle, inputContainerStyle, containerStyle }
  }

  render () {
    const { inputStyle, currencyStyle, inputContainerStyle, containerStyle } = this.getStyles()

    return (
      <View style={{
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'center',
      }}
      >
        <Input
          {...this.props}
          hasClear={false}
          fullErrorMode={false}
          testID={this.props.testID}
          labelStyle={{ display: 'none' }}
          inputStyle={
            this.props.fullErrorMode
              ? [inputStyle, s.inputRed]
              : [inputStyle, this.props.inputStyle]
          }
          rightTextStyle={
            this.props.fullErrorMode
              ? [inputStyle, s.currencyTextRed]
              : [inputStyle, this.props.inputStyle, currencyStyle]
          }
          errorStyle={[s.inputErrorStyle, s.inputOnError, this.props.errorStyle]}
          validators={[Validators.isAmountFormat, ...(this.props.validators ?? [])]}
          placeholderTextColor={Colors.gray}
          inputContainerStyle={inputContainerStyle}
          containerStyle={containerStyle}
          keyboardType='decimal-pad'
          autoFocus={this.props.autoFocus}
          format={Formatters.formatMoney}
          maxLength={this.props.maxLength}
          rightText={this.props.currency ?? i18next.t('commons:currency')}
        />
      </View>
    )
  }
}

export const MoneyInput = MoneyInputComponent
