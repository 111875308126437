import React from 'react'
import { TouchableOpacityProps, TouchableOpacity, View, Text, ViewStyle } from 'react-native'

import { useStylesheet } from '../utils'
import { Icon } from '../Icon/Icon'

import styles from './styles'

export enum BadgeSkin {
  DEFAULT = 'DEFAULT',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface BadgeProps extends TouchableOpacityProps{
  value: string | React.ReactElement<typeof Icon>;
  skin: BadgeSkin
  onPress?: (value) => void,
  overrideContainerStyle?: ViewStyle
}

export const Badge: React.FC<BadgeProps> = ({
  value,
  skin,
  onPress,
  overrideContainerStyle,
}) => {
  const s = useStylesheet(styles,
    {
      skin,
      props: {
        iconValue: typeof value !== 'string',
        skin,
      },
    })

  return (
    <View style={s.containerStyle}>
      {onPress
        ? (
          <TouchableOpacity
            testID={'badge_' + value}
            style={[s.touchableStyle, overrideContainerStyle]}
            onPress={() => { onPress && onPress(value) }}
          >
            <Text style={[s.textStyle]}>{value}</Text>
          </TouchableOpacity>
        )
        : (
          <View
            testID={'badge_' + value}
            style={[s.touchableStyle, overrideContainerStyle]}
          >
            <Text style={[s.textStyle]}>{value}</Text>
          </View>
        )}
    </View>
  )
}
