import { Reducer, AnyAction } from 'redux'
import { CountryDto, PersonDto, UserDto, UserUpdateDto, ValidatePasswordDto } from '@afone/neo-core-client/dist/models'
import i18next from 'i18next'
import { NeobankApi } from '@neo-commons/services'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import produce from 'immer'

import { AlertType } from '../utils'
import { Dispatch } from '../utils/resourceState'

import { ClientActions } from './client'

/* --------------------------* Action Types *-------------------------- */
export const UserTypes = {
  RETRIEVE_USER_UUID_FROM_PHONE_REQUEST: 'login/RETRIEVE_USER_UUID_FROM_PHONE_REQUEST',
  RETRIEVE_USER_UUID_FROM_PHONE_SUCCESS: 'login/RETRIEVE_USER_UUID_FROM_PHONE_SUCCESS',
  RETRIEVE_USER_UUID_FROM_PHONE_FAILURE: 'login/RETRIEVE_USER_UUID_FROM_PHONE_FAILURE',
  SET_USER_UUID: 'login/SET_USER_UUID',
  GET_REQUEST: 'user/GET_REQUEST',
  GET_SUCCESS: 'user/GET_SUCCESS',
  GET_FAILURE: 'user/GET_FAILURE',
  UPDATE_REQUEST: 'user/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'user/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'user/UPDATE_FAILURE',
  UPDATE_PERSON_REQUEST: 'user/UPDATE_PERSON_REQUEST',
  UPDATE_PERSON_SUCCESS: 'user/UPDATE_PERSON_SUCCESS',
  UPDATE_PERSON_FAILURE: 'user/UPDATE_PERSON_FAILURE',
  CHECK_PASSCODE_SEQUENCE_REQUEST: 'user/CHECK_PASSCODE_SEQUENCE_REQUEST',
  CHECK_PASSCODE_SEQUENCE_SUCCESS: 'user/CHECK_PASSCODE_SEQUENCE_SUCCESS',
  CHECK_PASSCODE_SEQUENCE_FAILURE: 'user/CHECK_PASSCODE_SEQUENCE_FAILURE',
  UPDATE_SUPPORTED_FEATURES: 'user/UPDATE_SUPPORTED_FEATURES',
  RESET: 'user/RESET',
}

/* --------------------------* Actions *-------------------------- */
const getUser = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UserTypes.GET_REQUEST })
    try {
      const response = await NeobankApi.getInstance().userApi.getUser('me')
      const user = response.data
      dispatch({ type: UserTypes.GET_SUCCESS, user })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: UserTypes.GET_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const updateUser = (userParams: UserUpdateDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UserTypes.UPDATE_REQUEST })
    try {
      const requestParams = userParams
      const response = await NeobankApi.getInstance().userApi.updateUser('me', requestParams)
      const updatedUser = response.data

      dispatch({
        type: UserTypes.UPDATE_SUCCESS,
        alertType: AlertType.SUCCESS,
        updatedUser,
        errorMessage: i18next.t('neo-commons:app:global:profile:update:success'),
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: UserTypes.UPDATE_FAILURE, errorMessage })
    }
  }
}

const updateUserPerson = (user: UserDto, person: PersonDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UserTypes.UPDATE_PERSON_REQUEST })
    try {
      const updatedPersonResponse = await NeobankApi.getInstance().personApi.updatePersonByUuid(
        person.uuid!,
        person,
      )
      dispatch({
        type: UserTypes.UPDATE_PERSON_SUCCESS,
        alertType: AlertType.SUCCESS,
        data: {
          ...user,
          person: updatedPersonResponse.data,
        },
        successMessage: i18next.t('neo-commons:app:global:profile:update:success'),
      })
      dispatch(ClientActions.list()) // TODO : remove when holder, person and user is ok
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: UserTypes.UPDATE_PERSON_FAILURE, errorMessage })
    }
  }
}

const checkPasscodeSequence = (validatePassword: ValidatePasswordDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UserTypes.CHECK_PASSCODE_SEQUENCE_REQUEST })
    try {
      await NeobankApi.getInstance().userApi.validatePassword(validatePassword)
      dispatch({ type: UserTypes.CHECK_PASSCODE_SEQUENCE_SUCCESS })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: UserTypes.CHECK_PASSCODE_SEQUENCE_FAILURE, errorMessage })
      throw error
    }
  }
}

const getUserUuidFromPhone = (payload: { phone: string; selectedCountry: CountryDto }) => {
  const { phone, selectedCountry } = payload
  return async (dispatch: Dispatch) => {
    dispatch({ type: UserTypes.RETRIEVE_USER_UUID_FROM_PHONE_REQUEST })

    try {
      const phoneResponse = await NeobankApi.getInstance().phoneApi.getPhoneInformationByNumber(
        '+' + selectedCountry?.prefixPhone + (phone.charAt(0) === '0' ? phone.substr(1) : phone)
      )

      dispatch({ type: UserTypes.SET_USER_UUID, data: phoneResponse.data.userUuid })

      const parsedPhoneNumber = parsePhoneNumber(phone, (selectedCountry?.isoCodeAlpha2 as CountryCode))
      const calculatedPhone = {
        countryCode: selectedCountry.isoCodeAlpha2,
        nationalFormat: parsedPhoneNumber.formatNational(),
        ...parsedPhoneNumber,
      }
      dispatch({
        type: UserTypes.GET_SUCCESS,
        user: {
          uuid: phoneResponse.data.userUuid,
          login: phone.charAt(0) === '0' ? phone : `0${phone}`,
          phone: calculatedPhone,
        },
      })
      dispatch({ type: UserTypes.RETRIEVE_USER_UUID_FROM_PHONE_SUCCESS, phone: phone })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalError')
      dispatch({ type: UserTypes.RETRIEVE_USER_UUID_FROM_PHONE_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

export const UserActions = {
  getUser,
  updateUser,
  updateUserPerson,
  checkPasscodeSequence,
  getUserUuidFromPhone,
}

/* --------------------------*  Reducer *-------------------------- */
export interface SupportedFeatures {
  biometrics: boolean;
}
export interface UserState {
  ui: {
    isUpdating: boolean;
    isUpdated: boolean;
    isFetching: boolean;
    isFetched: boolean;
    isLoading: boolean;
  },
  supportedFeatures: SupportedFeatures,
  data: UserDto | null,
}

const initialeState: UserState = {
  ui: {
    isUpdating: false,
    isUpdated: false,
    isFetching: false,
    isFetched: false,
    isLoading: false,
  },
  supportedFeatures: {
    biometrics: false,
  },
  data: null,
}

export const user: Reducer = (state: UserState = initialeState, action: AnyAction) => {
  switch (action.type) {
    case UserTypes.CHECK_PASSCODE_SEQUENCE_REQUEST:
      return produce(state, newState => {
        newState.ui.isLoading = true
      })
    case UserTypes.CHECK_PASSCODE_SEQUENCE_SUCCESS:
    case UserTypes.CHECK_PASSCODE_SEQUENCE_FAILURE:
      return produce(state, newState => {
        newState.ui.isLoading = false
      })
    case UserTypes.GET_REQUEST:
      return produce(state, newState => {
        newState.ui.isFetching = true
      })
    case UserTypes.GET_SUCCESS:
      return produce(state, newState => {
        newState.ui.isFetched = true
        newState.ui.isFetching = false
        newState.data = action?.user
      })
    case UserTypes.SET_USER_UUID:
      return {
        ...state,
        data: {
          ...state.data,
          id: action.data.userUuid,
        },
      }
    case UserTypes.GET_FAILURE:
      return produce(state, newState => {
        newState.ui.isFetching = false
      })
    case UserTypes.UPDATE_PERSON_REQUEST:
    case UserTypes.UPDATE_REQUEST:
      return produce(state, newState => {
        newState.ui.isUpdating = true
      })
    case UserTypes.UPDATE_SUCCESS:
      return produce(state, newState => {
        newState.ui.isUpdated = true
        newState.ui.isUpdating = false
        newState.data = action.updatedUser
      })
    case UserTypes.UPDATE_PERSON_SUCCESS:
      return produce(state, newState => {
        newState.ui.isUpdated = true
        newState.ui.isUpdating = false
        newState.data = action.data
      })
    case UserTypes.UPDATE_PERSON_FAILURE:
    case UserTypes.UPDATE_FAILURE:
      return produce(state, newState => {
        newState.ui.isUpdated = false
        newState.ui.isUpdating = false
      })
    case UserTypes.UPDATE_SUPPORTED_FEATURES:
      return produce(state, newState => {
        newState.supportedFeatures = {
          ...state.supportedFeatures,
          ...action.supportedFeatures,
        }
      })
    case UserTypes.RESET:
      return initialeState
    default:
      return state
  }
}
