import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Image } from 'react-native'
import { useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { Button, Flags, Icon, IconTypes, ReadOnlyInput, Stepper2, Typography2 } from '@neo-commons/components'
import {
  AddressActions,
  ClientSelectors,
  ConfigSelectors,
  Dispatch,
  Subscription,
  SubscriptionActions,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { IndividualSubscriptionStep, IndividualSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import { AddressDataType, CountryUtils } from '@neo-commons/libraries'

import { AddressTypeDto, CountryDto, ErrorCodeDto, PersonTypeDto } from '@afone/neo-core-client/dist/models'

import { AddressButtonWhisper, TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'
import { UseAddressForm } from '@neo-web/hooks'

import './Address.scss'

type AddressProps =
  StepComponentProps<IndividualSubscriptionSteps[IndividualSubscriptionStep.Address]>

export const AddressScreen: React.FC<AddressProps> = ({ nextStep }: AddressProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()
  const isAddressLoading = useSelector((state: StateTypes) => state.address?.loading)
  const isSubscriptionLoading = useSelector((state: StateTypes) => state.subscription?.loading)
  const [waitingSignatureLink, setWaitingSignatureLink] = useState(false)

  const user = useSelector((state: StateTypes) => state.user.data)
  const client = useSelector(ClientSelectors.defaultOne)
  const subscription: Subscription = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)

  const [validated, setValidated] = useState(false)

  const userData = useSelector((state: StateTypes) => state.user.data)
  const allCountries: CountryDto[] = useSelector(ConfigSelectors.getCurrentConfig)?.countries ?? []
  const countries = CountryUtils.filterByAddressAuthorized(allCountries)
  const values = {
    countryData: null as CountryDto,
    zipCode: '',
    cityName: '',
    address: '',
    additionalAddress: '',
  }

  const [addressData, renderAddressForm] = UseAddressForm({
    countries,
    userData,
    values,
  })

  const isButtonDisabled =
    !addressData.address || !addressData.zipCode || !addressData.cityName

  const validAddress = (addressSelected: AddressDataType) => {
    const { address, zipCode, cityName, countryData, additionalAddress } = addressSelected
    dispatch(AddressActions.createOrUpdate({
      addressRequest: {
        fullName: user.person.type === PersonTypeDto.PHYSICAL
          ? user.person.firstName + ' ' + user.person.lastName
          : undefined,
        line1: address,
        line2: additionalAddress,
        postalCode: zipCode,
        city: cityName,
        country: countryData?.name,
        countryCode: countryData?.isoCodeAlpha2,
        type: AddressTypeDto.FISCAL,
      },
    })).then(() => {
      AnalyticsHandler.track(AnalyticsEvents.signUp.allUserInfoSubmitted)
      setValidated(true)
    })
  }

  return validated
    ? (
      <TunnelContent
        loading={isSubscriptionLoading || isAddressLoading}
        justifyContent={TunnelJustifyContent.CENTER}
        header={
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <Typography2 typeface='h3'>
              {t('app:pages:subscription:individual:mainTitle:idDocument')}
            </Typography2>
            <Stepper2 currentStep={1} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
              <Typography2 typeface='h4'>
                {t('app:pages:subscription:individual:address:addressAdded')}
              </Typography2>
              <Typography2 typeface='body1'>
                {t('app:pages:subscription:individual:address:lastStepSignature')}
              </Typography2>
            </div>
          </div>
        }
        footer={
          <Button
            title={t('app:pages:subscription:individual:address:button')}
            disabled={waitingSignatureLink}
            onPress={async () => {
              try {
                setWaitingSignatureLink(true)
                await dispatch(SubscriptionActions.getSignatureLink({
                  subscriptionUuid: subscription.uuid,
                  personUuid: client.holder?.uuid,
                }))
                setWaitingSignatureLink(false)
                nextStep()
              } catch (e) {
                try {
                  await dispatch(SubscriptionActions.list())
                  if (e.code === ErrorCodeDto.C0707) {
                    history.push(Routes.Subscription.electronicSignatureDocumentsChoice.path)
                  } else if (e.code === ErrorCodeDto.C0512) {
                    history.push(Routes.Subscription.subscriptionError.path, { errorMessage: e.message })
                  } else {
                    history.push(Routes.Subscription.subscriptionError.path)
                  }
                } catch (e) {}
              }
            }}
          />
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <ReadOnlyInput
            value={addressData?.countryData?.name}
            leftIcon={
              <div style={{ }}>
                <Image
                  style={{ width: 16, height: 16, marginTop: 3, marginRight: 16 }}
                  source={Flags[addressData.address?.countryData?.isoCodeAlpha3 ?? 'FRA'] ?? Flags.Unknown}
                />
              </div>
            }
            rightIcon={<div style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></div>}
          />
          <ReadOnlyInput
            label={t('neo-commons:address:line1')}
            value={addressData?.address}
            rightIcon={<div style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></div>}
          />
          {addressData?.additionalAddress &&
            <ReadOnlyInput
              label={t('neo-commons:address:line2')}
              value={addressData?.additionalAddress}
              rightIcon={
                <div style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></div>
              }
            />}
          <ReadOnlyInput
            label={t('neo-commons:address:zipCode')}
            value={addressData?.zipCode}
            rightIcon={<div style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></div>}
          />
          <ReadOnlyInput
            label={t('neo-commons:address:city')}
            value={addressData?.cityName}
            rightIcon={<div style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></div>}
          />
        </div>
      </TunnelContent>
    ) : (
      <TunnelContent
        loading={isSubscriptionLoading || isAddressLoading}
        justifyContent={TunnelJustifyContent.CENTER}
        header={
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <Typography2 typeface='h3'>
              {t('app:pages:subscription:individual:mainTitle:idDocument')}
            </Typography2>
            <Stepper2 currentStep={1} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
              <Typography2 typeface='h4'>
                {t('app:pages:subscription:individual:address:title')}
              </Typography2>
              <Typography2 typeface='body1'>
                {t('app:pages:subscription:individual:address:information')}
              </Typography2>
            </div>
          </div>
        }
        footer={
          <AddressButtonWhisper
            originAddress={addressData}
            onSelect={validAddress}
            buttonDisabled={isButtonDisabled}
          />
        }
      >
        <div className='Address_container'>
          {renderAddressForm}
        </div>
      </TunnelContent>
    )
}
