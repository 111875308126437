import React from 'react'
import { useTranslation } from 'react-i18next'

import { NumbersUtils } from '@neo-commons/libraries'

import { TransactionDto, TransactionTypeFamilyDto } from '@afone/neo-core-client/dist/models'

import { CardDetails, CardDetailsItem } from '@neo-web/componentsOld'

interface TransactionConversionProps {
  transaction: TransactionDto,
}

export const TransactionConversion: React.FC<TransactionConversionProps> = (props: TransactionConversionProps) => {
  const { transaction } = props
  const { t } = useTranslation()

  const originalCurrency = transaction?.amounts?.originalCurrencyAmount
  const currencyValue = originalCurrency?.conversionRate
    ? (1 / originalCurrency.conversionRate).toFixed(2).replace('.', ',') +
      ' ' + originalCurrency?.currencyAlphaCode
    : null

  const listItems: CardDetailsItem[] = [
    {
      label: t('app:pages:synthesis:transaction:detail:exchangeRate'),
      value: currencyValue && `1 EUR = ${currencyValue}`,
    }, {
      label: transaction?.family === TransactionTypeFamilyDto.IS_CASH
        ? t('app:pages:synthesis:transaction:detail:withdrawCurrencyAmount')
        : t('app:pages:synthesis:transaction:detail:paidCurrencyAmount'),
      value: NumbersUtils.displayPriceForHumanWithCurrency(
        (originalCurrency?.amount - (originalCurrency?.fee ?? 0)),
        originalCurrency?.currencyAlphaCode
      ),
    }, {
      label: t('app:pages:synthesis:transaction:detail:distributorFees'),
      value: originalCurrency?.fee && NumbersUtils.displayPriceForHumanWithCurrency(
        originalCurrency.fee,
        originalCurrency?.currencyAlphaCode
      ),
    }, {
      label: t('app:pages:synthesis:transaction:detail:euroAmount'),
      value: NumbersUtils.displayPriceForHuman(transaction?.amounts?.amount),
    },
  ]

  return (
    <div style={{ marginTop: 32 }}>
      <CardDetails
        title={t('app:pages:synthesis:transaction:detail:conversionDetail')}
        list={listItems}
        justifyBetween
      />
    </div>
  )
}
