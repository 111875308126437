import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ServicesRoutes } from 'Routes/Services'
import { Routes } from 'Routes/Routes'
import { AppConfig } from 'Config/AppConfig'
import { SubscriptionPolicy } from 'Policies'

import { ClientUtils, NumbersUtils, OfferUtils, SubscriptionUtils } from '@neo-commons/libraries'
import {
  CardActions,
  CardSelectors,
  CardTypes,
  ClientSelectors,
  OrdersActions,
  OrderUpdateType,
  PrepareCardOrder,
  State,
  SubscriptionActions,
  SubscriptionSelectors,
} from '@neo-commons/store'
import {
  Button,
  ButtonSkin,
  CheckboxInput,
  EditItemCard,
  EditItemCardSkin,
  PasscodeInput,
  PaymentCards,
  PaymentCardType,
  Typography,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { CardDto, CgusDto, ClientDto, ClientTypeDto, ProductDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, LazyLoadingContainer, PageContent, PageDetails } from '@neo-web/componentsOld'

import './Summary.scss'

export const Summary: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const preparedCardOrder: PrepareCardOrder = useSelector(CardSelectors.preparedCardOrder)
  const isOrderingPhysicalCardOnly: boolean = preparedCardOrder?.withPhysicalCard && !preparedCardOrder?.isBundle
  const urlCGU: CgusDto = useSelector((state: State) => state.config.data.currentConfig.cgus)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)

  // Todo replace this and translate message offer when offer type bo is update
  const isAmbassador = OfferUtils.isAmbassador(preparedCardOrder?.offer?.offerCode)
  const withPhysicalCard: boolean = preparedCardOrder?.withPhysicalCard || isAmbassador
  const mainSubscription = useSelector(SubscriptionSelectors.defaultOne)
  let subscription = useSelector(SubscriptionSelectors.getToProceed(preparedCardOrder?.offer?.offerCode))
  const productImages = OfferUtils.getPaymentCardsImagesFromProducts(preparedCardOrder?.offer?.products)
  const cardProduct = (preparedCardOrder?.offer?.products as (ProductDto & {data: {productType?: string}})[])?.filter(product => product?.data?.productType === 'plasticCard')
  const cardPrice = useSelector(SubscriptionSelectors.getProductPriceByCode(cardProduct?.[0]?.productCode))

  const [isCgvAccepted, setIsCgvAccepted] = useState(false)
  const [amount] = useState(preparedCardOrder?.deliveryData?.mode?.prices?.length
    ? preparedCardOrder?.deliveryData?.mode?.prices[0].amount : 0
  )

  const createCard = () => {
    (async () => {
      try {
        if (!SubscriptionUtils.isActive(mainSubscription)) {
          await dispatch(OrdersActions.createCardOrder())
        } else if (!preparedCardOrder?.cardUuid) {
          // Todo Remove ibanLocalization when bo update
          // TODO handle clientType corporate
          if (!subscription?.uuid) {
            await dispatch(SubscriptionActions.prepare({ ibanLocalization: 'FR' }))
            subscription = await dispatch(SubscriptionActions.create({
              offerUuid: preparedCardOrder?.offer.uuid,
              discountCode: preparedCardOrder?.promoCode,
              clientType: SubscriptionPolicy.isIndividual() ? ClientTypeDto.INDIVIDUAL : ClientTypeDto.CORPORATE,
            }))
          }
          await dispatch(CardActions.createCard(subscription.uuid))
        } else {
          await dispatch(CardActions.createPhysicalCard())
        }
        await dispatch(CardActions.prepare({
          ...preparedCardOrder,
          ui: {
            ...preparedCardOrder.ui,
            isCgvAccepted: true,
          },
        } as PrepareCardOrder))
        history.replace(Routes.Services.validate.path)
      } catch (e) {
        // If keypad has expired
        if (e.status === 408) {
          history.replace(ServicesRoutes.order.path)
        }
      }
    })()
  }

  const updateOrderUpdateType = async (type: OrderUpdateType) => {
    await dispatch(CardActions.prepare({
      ...preparedCardOrder,
      updateType: type,
    }))
  }

  return (
    <>
      <PageDetails
        component={
          <LazyLoadingContainer events={[CardTypes.CREATE_CARD_REQUEST]}>
            <PageContent
              justifyContent={JustifyContent.START}
              title={t(withPhysicalCard
                ? (isOrderingPhysicalCardOnly
                  ? 'app:pages:services:debitCard:order:summary:yourPhysicalOrder'
                  : 'app:pages:services:debitCard:order:summary:yourDigitalAndPhysicalOrder')
                : 'app:pages:services:debitCard:order:summary:yourDigitalOrder'
              )}
              footer={(
                <>
                  <div className='OrderSummary_container-items'>
                    <div className='OrderSummary_container-items-fees'>
                      <Typography typeface='smallTextLight'>
                        {preparedCardOrder?.deliveryData &&
                          <div>
                            {t('app:pages:services:debitCard:order:summary:deliveryFees')}&nbsp;
                            {amount === 0
                              ? t('app:pages:services:debitCard:order:deliveryMode:free')
                              : NumbersUtils.displayPriceForHuman(amount)}
                            <br />
                            {t('app:pages:services:debitCard:order:summary:pricesDebitCard')}&nbsp;
                            {NumbersUtils.displayPriceForHuman(cardPrice)}
                          </div>}
                        {!preparedCardOrder?.deliveryData &&
                          <div>
                            {t('app:pages:services:debitCard:order:summary:fees')}&nbsp;
                            {t('app:pages:services:debitCard:order:deliveryMode:free')}
                          </div>}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <CheckboxInput
                      onPress={(value) => setIsCgvAccepted(value)}
                      text={
                        <Typography typeface='body2'>
                          {t('app:pages:services:debitCard:order:summary:accept')}&nbsp;
                          <a
                            target='_blank' rel='noreferrer'
                            href={ClientUtils.isClientTypeIndividual(client?.type)
                              ? urlCGU?.individual
                              : urlCGU?.corporate}
                            style={{ color: Colors.trueBlack, fontWeight: 'bold', textDecorationLine: 'underline' }}
                          >
                            {t('app:pages:services:debitCard:order:summary:termsAndConditions')}
                          </a>
                        </Typography>
                      }
                      isSelected={isCgvAccepted}
                    />
                  </div>
                  <Button
                    disabled={!isCgvAccepted}
                    skin={ButtonSkin.PRIMARY}
                    title={t('app:global:confirm')}
                    onPress={() => createCard()}
                  />
                </>
              )}
            >
              <div className='OrderSummary_container-items'>
                <EditItemCard
                  label={t(withPhysicalCard
                    ? 'app:pages:services:debitCard:order:summary:digitalAndPhysic'
                    : 'app:pages:services:debitCard:order:summary:digital').toUpperCase()}
                  skin={withPhysicalCard ? EditItemCardSkin.CUSTOM : EditItemCardSkin.CUSTOMDIGITAL}
                  payload={{
                    content: productImages && (
                      <PaymentCards
                        card={{
                          productImage: productImages?.digital,
                          plasticInfo: { productImage: productImages?.plastic },
                          plastic: productImages?.digital && productImages?.plastic,
                        } as CardDto}
                        only={withPhysicalCard ? PaymentCardType.BOTH : PaymentCardType.DIGITAL}
                      />
                    ),
                  }}
                  onEditPress={async () => {
                    history.push(Routes.Services.choicesDebitCard.path)
                  }}
                />
              </div>
              {!withPhysicalCard ? (
                <div className='OrderSummary_container-items'>
                  <EditItemCard
                    label={t('app:pages:services:debitCard:order:summary:physicalCard').toUpperCase()}
                    skin={EditItemCardSkin.CUSTOMTEXTDIGITAL}
                    payload={{
                      content: (
                        <Typography typeface='content'>
                          {t('app:pages:services:debitCard:order:summary:physicalCardText')}
                        </Typography>
                      ),
                    }}
                    onEditPress={async () => {
                      await updateOrderUpdateType(OrderUpdateType.PHYSICAL_PROMO)
                      history.push(Routes.Services.physicalPromo.path)
                    }}
                  />
                </div>
              ) : (
                <>
                  {preparedCardOrder?.deliveryData &&
                    <div className='OrderSummary_container-items'>
                      <EditItemCard
                        label={t('app:pages:services:debitCard:order:address:title').toUpperCase()}
                        skin={EditItemCardSkin.CUSTOM}
                        payload={{
                          content: (
                            <div className='OrderSummary_address_container'>
                              <div>
                                <Typography typeface='bold'>
                                  {preparedCardOrder?.deliveryData?.user?.lastname}&nbsp;
                                  {preparedCardOrder?.deliveryData?.user?.firstname}
                                </Typography>
                              </div>
                              <div>
                                <Typography typeface='content' style={{ textAlign: 'left' }}>
                                  {preparedCardOrder?.deliveryData?.address?.street}&nbsp;
                                  <br />
                                  {preparedCardOrder?.deliveryData?.address?.zipCode}&nbsp;
                                  {preparedCardOrder?.deliveryData?.address?.city}&nbsp;
                                  {preparedCardOrder?.deliveryData?.address?.country?.name}
                                </Typography>
                              </div>
                            </div>
                          ),
                        }}
                        onEditPress={async () => {
                          await updateOrderUpdateType(OrderUpdateType.ADDRESS)
                          history.push(Routes.Services.address.path)
                        }}
                      />
                    </div>}
                  {preparedCardOrder?.keypadDataCardPin &&
                    <div className='OrderSummary_container-items'>
                      <EditItemCard
                        label={t('app:pages:services:debitCard:order:summary:pinCode').toUpperCase()}
                        skin={EditItemCardSkin.CUSTOM}
                        payload={{
                          content: (
                            <PasscodeInput
                              pinLimit={AppConfig.PASS_CODE_SIZE}
                              codePin={preparedCardOrder?.keypadDataCardPin}
                              isImage
                            />
                          )
                          ,
                        }}
                        onEditPress={async () => {
                          await updateOrderUpdateType(OrderUpdateType.PIN_CODE)
                          history.push(Routes.Services.code.path)
                        }}
                      />
                    </div>}
                  {preparedCardOrder?.deliveryData &&
                    <div className='OrderSummary_container-items'>
                      <EditItemCard
                        label={t('app:pages:services:debitCard:order:deliveryMode:deliveryMethod').toUpperCase()}
                        skin={EditItemCardSkin.CUSTOM}
                        payload={{
                          content: (
                            <div className='OrderSummary_delivery_container'>
                              <div>
                                <Typography typeface='bold'>
                                  {t(preparedCardOrder?.deliveryData?.mode?.description?.title)}
                                </Typography>
                                &nbsp;
                                <Typography typeface='bold'>
                                  {NumbersUtils.displayPriceForHuman(amount)}
                                </Typography>
                              </div>
                              <div>
                                <Typography typeface='content'>
                                  {t(preparedCardOrder?.deliveryData?.mode?.description?.description)}
                                  <br />
                                  {t(preparedCardOrder?.deliveryData?.mode?.description?.term)}
                                </Typography>
                              </div>
                            </div>
                          ),
                        }}
                        hideButton={!SubscriptionUtils.isActive(mainSubscription)}
                        onEditPress={async () => {
                          await updateOrderUpdateType(OrderUpdateType.DELIVERY_MODE)
                          history.push(Routes.Services.deliveryMode.path)
                        }}
                      />
                    </div>}
                </>
              )}
            </PageContent>
          </LazyLoadingContainer>
        }
        onClose={() => history.push(Routes.Services.index.path)}
        onBack={async () => {
          if (preparedCardOrder.withPhysicalCard) {
            await dispatch(CardActions.prepare({
              ...preparedCardOrder,
              updateType: OrderUpdateType.ADDRESS,
            }))
            history.push(Routes.Services.address.path)
          } else {
            await dispatch(CardActions.prepare({
              ...preparedCardOrder,
              updateType: OrderUpdateType.PHYSICAL_PROMO,
              isBundle: false,
            }))
            history.push(Routes.Services.physicalPromo.path)
          }
        }}
        title={t('app:pages:services:debitCard:order:summary:title')}
      />
    </>
  )
}
