import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin } from '@neo-commons/components'
import { BankAccountActions, BankAccountSelectors, BankAccountTypes, ClientSelectors } from '@neo-commons/store'
import { BankAccountUtils, ClientUtils, Formatters, NumbersUtils } from '@neo-commons/libraries'

import { AccountDto, ClientDto } from '@afone/neo-core-client/dist/models'

import { useIcon } from '@neo-web/hooks'
import {
  JustifyContent,
  LazyLoadingContainer,
  PageContent,
  PageDetails,
  PriceCard,
  PriceCardSkin,
} from '@neo-web/componentsOld'

import './ChoicesAccount.scss'

export const ChoicesAccount: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedBankAccountIndex, setSelectedBankAccountIndex] = useState(null)
  const bankAccounts = useSelector(BankAccountSelectors.listMain).filter(el => !BankAccountUtils.isAggregated(el))
  const clients: Array<ClientDto> = useSelector(ClientSelectors.list)

  useEffect(() => {
    // TODO: list() is deprecated here, change when needed (list main then sub accounts)
    dispatch(BankAccountActions.list())
  }, [])

  useEffect(() => {
    (async () => {
      const bankAccountsLength = bankAccounts?.length
      if (bankAccountsLength === 1) {
        await dispatch(BankAccountActions.setSelected(bankAccounts[0].uuid))
        history.push(Routes.Profile.invoiceAndContractDetail.path)
      }
    })()
  }, [bankAccounts])

  const listBankAccounts = (bankAccounts: Array<AccountDto>) => {
    return bankAccounts.map((bankAccount, i) => {
      const client: ClientDto = ClientUtils.getClientFromBankAccount(bankAccount, clients)
      return (
        <div key={i} className='ChoicesAccount_account'>
          <PriceCard
            key={i}
            isLarge={false}
            selected={selectedBankAccountIndex === i}
            skin={PriceCardSkin.DEFAULT}
            icon={useIcon(bankAccount, client?.type, selectedBankAccountIndex === i)}
            price={NumbersUtils.displayPriceForHuman(bankAccount.balance)}
            title={BankAccountUtils.getName(bankAccount)}
            subTitle={Formatters.formatIban(bankAccount.iban ?? '', true)}
            onClick={() => {
              if (selectedBankAccountIndex === i) {
                setSelectedBankAccountIndex(null)
                return
              }
              setSelectedBankAccountIndex(i)
            }}
          />
        </div>
      )
    })
  }

  return (
    <PageDetails
      title={t('app:pages:myProfile:contractAndInvoice:choiceAccount:title')}
      onBack={() => history.goBack()}
      onClose={() => history.push(Routes.Profile.index.path)}
      component={
        <LazyLoadingContainer events={[BankAccountTypes.LIST_BANK_ACCOUNT_REQUEST]}>
          <PageContent
            title={t('app:pages:myProfile:contractAndInvoice:choiceAccount:viewBankAccounts')}
            justifyContent={JustifyContent.BETWEEN}
            button={
              <Button
                skin={ButtonSkin.PRIMARY}
                containerStyle={{ width: '100%' }}
                title={t('app:pages:myProfile:contractAndInvoice:choiceAccount:validate')}
                onPress={() => {
                  const bankAccount = bankAccounts[selectedBankAccountIndex]
                  dispatch(BankAccountActions.setSelected(bankAccount.uuid))
                  history.push(Routes.Profile.invoiceAndContractDetail.path)
                }}
                disabled={selectedBankAccountIndex === null}
              />
            }
          >
            {listBankAccounts(bankAccounts)}
          </PageContent>
        </LazyLoadingContainer>
      }
    />
  )
}
