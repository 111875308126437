import { ProvisioningActions } from '@neo-commons/store'
import { i18commons } from '@neo-commons/i18n'
import { ProcessType } from '@neo-commons/libraries'

import { createWizardPolicy, invokeActionCreator } from '../utils'

const machineId = 'provisioning'

/*
* Guards
*/

const isPaymentProcess = (context) => {
  const prepare = context?.store.getState().provisioning.prepare
  return !!(prepare?.processType === ProcessType.PAYMENT && prepare?.amount)
}

const hasNoSavedCard = (context) => {
  return !context?.store.getState().externalCard.list.ids.length
}

const isSavedCardCvvFilled = (context) => {
  return !!context?.store.getState().provisioning.prepare?.card?.saved?.cvv
}

const isSavedCardSelected = (context) => {
  return !!context?.store.getState().provisioning.prepare.card?.saved?.uuid
}

const isNewCardFilled = (context) => {
  return !!context?.store.getState().provisioning.prepare.card?.new
}

const is3DSecureNotRequired = (context) => {
  return !context?.store.getState().provisioning.transaction3DSecure
}

/*
 * Steps
 */
export enum ProvisioningStep {
  Amount = 'Amount',
  PaymentCardChoice = 'PaymentCardChoice',
  PaymentCardCvv = 'PaymentCardCvv',
  PaymentCardForm = 'PaymentCardForm',
  Summary = 'Summary',
  PaymentVerify = 'PaymentVerify',
  Success = 'Success',
  Failure = 'Failure'
}

export const provisioningSteps = {
  [ProvisioningStep.Amount]: {
    path: 'amount',
    nextStep: ProvisioningStep.PaymentCardChoice,
    bypassIf: isPaymentProcess,
    fulfill: invokeActionCreator(ProvisioningActions.prepare),
    meta: {
      headerTitle: (): string => i18commons.t('neo-commons:provisioning:amount'),
    },
  },
  [ProvisioningStep.PaymentCardChoice]: {
    path: 'paymentCardChoice',
    nextStep: ProvisioningStep.PaymentCardForm,
    skipIf: isSavedCardSelected,
    bypassIf: hasNoSavedCard,
    fulfill: invokeActionCreator(ProvisioningActions.prepare),
    meta: {
      headerTitle: (): string => i18commons.t('neo-commons:provisioning:creditCardInfo'),
    },
  },
  [ProvisioningStep.PaymentCardForm]: {
    path: 'paymentCardForm',
    nextStep: ProvisioningStep.PaymentCardCvv,
    skipIf: isNewCardFilled,
    bypassIf: isSavedCardSelected,
    fulfill: invokeActionCreator(ProvisioningActions.tokenizeCard),
    meta: {
      headerTitle: (): string => i18commons.t('neo-commons:debitCard:wrong:deliveryPrice:button'),
    },
  },
  [ProvisioningStep.PaymentCardCvv]: {
    path: 'paymentCardCvv',
    nextStep: ProvisioningStep.Summary,
    skipIf: isSavedCardCvvFilled,
    bypassIf: isNewCardFilled,
    fulfill: invokeActionCreator(ProvisioningActions.prepare),
    meta: {
      headerTitle: (): string => i18commons.t('neo-commons:provisioning:creditCardInfo'),
    },
  },
  [ProvisioningStep.Summary]: {
    path: 'summary',
    nextStep: ProvisioningStep.PaymentVerify,
    editableSteps: [
      ProvisioningStep.Amount,
      ProvisioningStep.PaymentCardChoice,
      ProvisioningStep.PaymentCardForm,
    ],
    meta: {
      headerTitle: (): string => i18commons.t('neo-commons:provisioning:summary'),
    },
  },
  [ProvisioningStep.PaymentVerify]: {
    path: 'paymentVerify',
    nextStep: ProvisioningStep.Success,
    skipIf: is3DSecureNotRequired,
    onErrorRedirectStep: ProvisioningStep.Failure,
    fulfill: invokeActionCreator(ProvisioningActions.validate3DSTransaction),
  },
  [ProvisioningStep.Success]: {
    path: 'success',
    nextStep: 'final',
    bypassIf: isPaymentProcess,
    history: {
      resetStack: true,
    },
  },
  [ProvisioningStep.Failure]: {
    path: 'failure',
    nextStep: 'final',
    history: {
      resetStack: true,
    },
  },
}

export type ProvisioningSteps = typeof provisioningSteps

/*
 * Policy initialization
 */
export const provisioningPolicy = createWizardPolicy({
  machineId: machineId,
  steps: provisioningSteps,
  initialStep: ProvisioningStep.Amount,
  basePath: '/provisioning',
})
