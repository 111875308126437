import React from 'react'

import { Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import './ProgressBar.scss'

interface ProgressBarProps {
  percentage: number;
  color?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {
  return (
    <div className='ProgressBar'>
      <div className='ProgressBar_percentage' style={{ width: props.percentage + '%', backgroundColor: props.color ?? Colors.primary }}>
        <Typography2 typeface='buttonSmall' style={{ color: Colors.white }}>
          {props.percentage !== 0 ? props.percentage + '%' : undefined}
        </Typography2>
      </div>
    </div>
  )
}
