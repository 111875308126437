import { BankTransferTypeEnum, ContactActions, TransferActions } from '@neo-commons/store'

import { createWizardPolicy, invokeActionCreator } from '../utils'
const machineId = 'transfer'

/*
 * Guards
 */

const hasSelectType = (context): boolean => {
  return context?.store.getState().transfer.prepare.transferType != null
}

const hasSelectAccountUuid = (context): boolean => {
  return !!context?.store.getState().transfer.prepare.accountUuid
}

const hasSelectContactChannelUuid = (context): boolean => {
  return context?.store.getState().transfer.prepare.contactChannelUuid != null
}

const hasSetAmount = (context): boolean => {
  return context?.store.getState().transfer.prepare.amount > 0
}

const hasContactSelected = (context): boolean => {
  return !!context?.store.getState().contact.list.selectedId
}

const hasScheduled = (context): boolean => {
  const { prepare } = context?.store.getState().transfer

  return prepare.startDate ||
    prepare.transferType === BankTransferTypeEnum.Punctual ||
      prepare.recurrence
}

/*
 * Steps
 */

export enum TransferStep {
  TypeOf = 'TypeOf',
  SourceAccount = 'SourceAccount',
  TargetAccount = 'TargetAccount',
  SetAmount = 'SetAmount',
  ScheduledDate = 'ScheduledDate',
  Summary = 'Summary',
  ContactChannel = 'ContactChannel',
  Success = 'Success'
}

export const transferSteps = {
  [TransferStep.TypeOf]: {
    path: '',
    nextStep: TransferStep.SourceAccount,
    skipIf: hasSelectType,
    fulfill: invokeActionCreator(TransferActions.prepare),
    meta: {
      screenTitle: 'app:pages:payments:transfer:home:title',
    },
  },
  [TransferStep.SourceAccount]: {
    path: 'source',
    nextStep: TransferStep.TargetAccount,
    skipIf: hasSelectAccountUuid,
    fulfill: invokeActionCreator(TransferActions.prepare),

    meta: {
      screenTitle: 'app:pages:payments:transfer:source:title',
    },
  },

  [TransferStep.TargetAccount]: {
    path: 'target',
    nextStep: TransferStep.ContactChannel,
    skipIf: hasContactSelected,
    skipTo: TransferStep.ContactChannel,
    fulfill: invokeActionCreator(ContactActions.setSelected),
    meta: {
      screenTitle: 'app:pages:payments:transfer:contact:title',
    },
  },
  [TransferStep.ContactChannel]: {
    path: 'channel',
    nextStep: TransferStep.SetAmount,
    skipIf: hasSelectContactChannelUuid,
    skipTo: TransferStep.SetAmount,
    meta: {
      screenTitle: 'app:pages:payments:transfer:target:title',
    },
  },
  [TransferStep.SetAmount]: {
    path: 'amount',
    nextStep: TransferStep.ScheduledDate,
    skipIf: hasSetAmount,
    skipTo: TransferStep.ScheduledDate,
    fulfill: invokeActionCreator(TransferActions.prepare),
    meta: {
      screenTitle: 'app:pages:payments:transfer:pay:title',
    },
  },
  [TransferStep.ScheduledDate]: {
    path: 'scheduled',
    nextStep: TransferStep.Summary,
    skipIf: hasScheduled,
    skipTo: TransferStep.Summary,
    fulfill: invokeActionCreator(TransferActions.prepare),
    meta: {
      screenTitle: 'app:pages:payments:transfer:scheduled:title',
    },
  },
  [TransferStep.Summary]: {
    path: 'summary',
    nextStep: TransferStep.Success,
    fulfill: invokeActionCreator(TransferActions.create),
    meta: {
      screenTitle: 'app:pages:payments:transfer:summary:title',
    },
  },
  [TransferStep.Success]: {
    path: 'result',
    nextStep: 'final',

    meta: {
      screenTitle: 'app:pages:register:step3:codeConfirm:titleMessage',
    },
  },
}

export type TransferSteps = typeof transferSteps

/*
 * Policy initialization
 */
export const transferPolicy = createWizardPolicy({
  machineId: machineId,
  steps: transferSteps,
  initialStep: TransferStep.TypeOf,
  basePath: '/transfer',
})
