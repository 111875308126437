import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { orderBy } from 'lodash'
import { config, ENV } from 'Config/EnvConfig'
import { RootActions } from 'Store'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { NeobankApi } from '@neo-commons/services'
import { TextUtils } from '@neo-commons/libraries'
import { AlertType, BankAccountActions, SubscriptionSelectors } from '@neo-commons/store'
import { Stepper2 } from '@neo-commons/components'

import { SubscriptionDto, SubscriptionStatusDto, AggregationConnectorDto } from '@afone/neo-core-client/dist/models'

import {
  AggregationLogo,
  JustifyContent,
  Loader,
  PageContent,
  PageDetails,
  PaginationList,
} from '@neo-web/componentsOld'

import './AggregationSearch.scss'

export const AggregationSearch:React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const subscriptions: SubscriptionDto[] = useSelector(SubscriptionSelectors.listAggregated)
  const [banks, setBanks] = useState([])
  const [filteredBanks, setFilteredBanks] = useState([])
  const [loading, setLoading] = useState(false)

  const groupBankByFirstLetter = (banks: AggregationConnectorDto[]) => {
    return orderBy(
      banks?.reduce((cumulate, bank) => {
        const firstLetterContact = TextUtils.normalizeText((bank?.name?.[0] ?? '')).toUpperCase()
        const existing = cumulate.find(group => group.title === firstLetterContact)
        if (existing) {
          (existing.data as any[]).push(bank)
          existing.data = orderBy(existing.data, 'name', 'asc')
        } else {
          cumulate.push({ title: firstLetterContact, data: [bank] })
        }

        return cumulate
      }, [])
      , 'title', 'asc')
  }

  useEffect(() => {
    setLoading(true)
    NeobankApi.getInstance().aggregationApi.getAggregationConnectors().then(el => {
      setBanks(el?.data)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    setFilteredBanks(banks)
  }, [banks])

  useEffect(() => {
    AnalyticsHandler.track(
      AnalyticsEvents.eligibility.bankAggregationProcessStarted,
      { account_type: 'B2C' })
  }, [])

  const onPressNextStep = (bank: any) => {
    dispatch(BankAccountActions.setSelectedConnector(bank))
    if (subscriptions.filter(el => el.status !== SubscriptionStatusDto.CANCELED).length) {
      const redirectUrl = `${config(ENV.COMNPAY_ORIGIN)}services/aggregation/fallback`
      NeobankApi.getInstance().aggregationApi
        .connectAggregation(0, { uri: redirectUrl, connectorId: bank.connectorId })
        .then(res => { location.href = res?.data?.uri })
        .catch((e) => {
          dispatch(RootActions.alert({
            title: e.message,
            type: AlertType.ERROR,
          }))
          history.push(Routes.Services.aggregation.path)
        })
    } else {
      history.push(Routes.Services.activateAggregateAccounts.path)
    }
  }

  return (
    <PageDetails
      onBack={() => history.goBack()}
      onClose={() => history.push(Routes.Services.aggregation.path)}
      title={t('app:pages:services:aggregation:menu')}
      component={
        <PageContent
          title={t('app:pages:services:aggregation:search:title')}
          justifyContent={JustifyContent.START}
        >
          <Stepper2 currentStep={0} />
          {loading
            ? <Loader isShow loop text={t('neo-commons:account:loading')} />
            : (
              <div className='AggregationSearch_paginationList'>
                <PaginationList
                  search
                  onFilter={(value) =>
                    setFilteredBanks(banks?.filter(el => el.name.toUpperCase().includes(value.toUpperCase())))}
                  stateList={filteredBanks}
                  emptyText={t('app:pages:services:aggregation:search:empty')}
                  items={(bank) =>
                    <div
                      className='AggregationSearch_paginationList-item'
                      onClick={() => onPressNextStep(bank)}
                    >
                      <AggregationLogo bank={bank} size={40} />
                      <div className='ItemBank_name'>{bank.name}</div>
                    </div>}
                  groupData={groupBankByFirstLetter(filteredBanks)}
                  next={() => null}
                  state={{}}
                  refreshFunction={() => null}
                />
              </div>
            )}
        </PageContent>
      }
    />
  )
}
