import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ViewStyle } from 'react-native'

import { Routes } from 'Routes/Routes'
import { AppConfigTxt } from 'Config/AppConfig'

import { BankAccountUtils, Validators } from '@neo-commons/libraries/src'
import { BankAccountActions, BankAccountSelectors, BankAccountTypes, ConfigSelectors, PrepareSubAccountOrder } from '@neo-commons/store'
import { Button, ButtonSkin, Input, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors, Typo } from '@neo-commons/styles'
import { ProjectStep, ProjectSteps, StepComponentProps } from '@neo-commons/policies'

import { AccountDto, AccountTypeDto, SubAccountCreationRequestDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, LazyLoadingContainer, Modal, PageContent, PageDetails } from '@neo-web/componentsOld'

import './Name.scss'
import styles from './styles'

type NameProps = StepComponentProps<ProjectSteps[ProjectStep.Name]>

export const Name: React.FC<NameProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const [projectName, setProjectName] = useState('')
  const [isProjectNameValid, setIsProjectNameValid] = useState(false)
  const [projectIcon, setProjectIcon] = useState(AppConfigTxt.DEFAULT_PROJECT_ICON)

  const currentConfig = useSelector(ConfigSelectors.getCurrentConfig)
  const [showIconList, setShowIconList] = useState(false)

  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selected)

  const preparedSubAccountOrder: PrepareSubAccountOrder = useSelector(BankAccountSelectors.getPreparedSubAccountOrder)

  const isBankAccountLoading = useSelector(BankAccountSelectors.isLoading)

  let listIconStyle: ViewStyle
  let listIconColor: string

  const handlePressConfirm = async () => {
    const subAccountCreationRequestDto: SubAccountCreationRequestDto = {
      accountType: AccountTypeDto.PROJECT,
      name: projectName,
      icon: projectIcon,
    }

    const subAccountData = {
      mainAccountUuid: selectedBankAccount?.uuid,
      xValidationOnly: preparedSubAccountOrder.cost.amount === 0 ? 0 : 1,
      subAccountCreationRequestDto: subAccountCreationRequestDto,
    }

    try {
      await dispatch(BankAccountActions.createSubAccount(subAccountData))
      nextStep()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <LazyLoadingContainer events={[BankAccountTypes.CREATE_SUB_ACCOUNT_REQUEST]} isShow={isBankAccountLoading}>
      <PageDetails
        title={t('app:pages:services:project:name:title')}
        component={
          <PageContent
            justifyContent={JustifyContent.BETWEEN}
            title={
              <Typography typeface='input'>
                {t('app:pages:services:project:name:question')}
              </Typography>
            }
            footer={
              <Button
                skin={ButtonSkin.PRIMARY}
                title={t('app:pages:services:project:name:confirm')}
                disabled={!isProjectNameValid}
                onPress={handlePressConfirm}
              />
            }
          >
            {showIconList &&
              <Modal
                open={showIconList}
                displayCross
                onClose={() => {
                  setShowIconList(false)
                }}
                title={
                  <Typography typeface='input'>
                    {t('app:pages:services:project:name:modalTitle')}
                  </Typography>
                }
                size='sm'
              >

                <div className='ModalContent'>
                  {
                    currentConfig.icons.map((item, index) => {
                      if (item === projectIcon) {
                        listIconStyle = styles.selectedIcon
                        listIconColor = Colors.white
                      } else {
                        listIconStyle = styles.availableIcon
                        listIconColor = Colors.secondary
                      }
                      return (
                        <Icon
                          type={IconTypes.MATERIAL}
                          key={index}
                          name={BankAccountUtils.getFrontIconString(item)}
                          size={32}
                          color={listIconColor}
                          withBackground
                          containerStyle={listIconStyle}
                          onPress={() => {
                            setProjectIcon(item)
                            setShowIconList(false)
                          }}
                        />
                      )
                    })
                  }
                </div>
              </Modal>}

            <div className='NameContent'>
              <div className='NameContent_icon'>
                <Icon
                  name={BankAccountUtils.getFrontIconString(projectIcon)}
                  type={IconTypes.MATERIAL}
                  size={36}
                  color={Colors.white}
                  containerStyle={{ backgroundColor: Colors.secondary }}
                  editable={() => {
                    setShowIconList(true)
                  }}
                />
              </div>
              <Input
                placeholder={t('app:pages:services:project:name:inputDescription')}
                value={projectName}
                autoCorrect={false}
                autoFocus
                isRequired
                validators={[Validators.isNotEmpty, Validators.noEmojis]}
                onValidationChecked={value => setIsProjectNameValid(value.isValid)}
                onChangeText={(projectName: string) => {
                  setProjectName(projectName)
                }}
                maxLength={50}
                labelStyle={Typo.inputProject}
              />
            </div>
          </PageContent>
        }
        onClose={() => {
          history.push(Routes.Services.ProjectsRoutes.index.path)
          dispatch(BankAccountActions.resetPrepareSubAccount())
        }}
        onBack={() => {
          history.push(Routes.Services.ProjectsRoutes.index.path)
          dispatch(BankAccountActions.resetPrepareSubAccount())
        }}
      />
    </LazyLoadingContainer>
  )
}
