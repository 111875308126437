import { combineReducers, Store, AnyAction } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { reducer as oidcReducer, UserState as OidcUserState } from 'redux-oidc'
import { ThunkDispatch } from 'redux-thunk'

import {
  signup,
  State as CommonsState,
  SignupState,
  config,
  secretQuestion,
  ConfigState,
  UserState,
  user,
  SecretQuestionState,
  subscription,
  offer,
  client,
  SubscriptionState,
  OfferState,
  ClientState,
  bankAccount,
  BankAccountState,
  transaction,
  address,
  userSecretQuestion,
  transfer,
  SignInState,
  SignIn,
  notificationPreferences,
  NotificationPreferencesState,
  RecoverPasscodeState,
  recoverPasscode,
  ContactPreferencesState,
  contactPreferences,
  AddressState,
  contact,
  ContactState,
  card,
  CardState,
  device,
  DeviceState,
  pos,
  PosState,
  telecollection,
  TelecollectionState,
  provisioning,
  ProvisioningState,
  invoice,
  externalCard,
  deviceRecovery,
  DeviceRecoveryState,
  link,
  LinkState,
  contract,
  ContractState,
  document,
  DocumentState,
  notification,
  NotificationState,
  statement,
  StatementState,
  documentUpload,
  DocumentUploadState,
  AdvanceState,
  advance,
  orders,
} from '@neo-commons/store'

import createStore from './CreateStore'
import { root, RootState } from './Root'
import { introStep, IntroStepState } from './IntroStep'
import { RoutesState, routes } from './Routes/Routes'
import { ScaState, sca } from './Sca'

export * from './Root'

export const reducers = combineReducers({
  /* from @neo-commons/store */
  signup: persistReducer({ key: 'signup', storage: storage, whitelist: ['profile'] }, signup),
  config: persistReducer({ key: 'config', storage: storage }, config),
  signIn: persistReducer({ key: 'signIn', storage: storage }, SignIn),
  client: persistReducer({ key: 'client', storage: storage, blacklist: ['list'] }, client),
  subscription: persistReducer({ key: 'subscription', storage: storage }, subscription),
  user: persistReducer({ key: 'user', storage: storage }, user),
  card: persistReducer({ key: 'card', storage: storage, whitelist: ['data', 'list'] }, card),
  device: device,
  pos: pos,
  telecollection: telecollection,
  provisioning: provisioning,
  secretQuestion: secretQuestion,
  offer: persistReducer({ key: 'offer', storage: storage }, offer),
  userSecretQuestion: userSecretQuestion,
  bankAccount: persistReducer(
    { key: 'bankAccount', storage: storage, whitelist: ['list', 'data', 'preparedSubAccountOrder', 'selectedConnector'] }, bankAccount
  ),
  transaction: persistReducer(
    { key: 'transaction', storage: storage, whitelist: ['prepareRefund'] }, transaction
  ),
  address: address,
  transfer: transfer,
  recoverPasscode,
  notificationPreferences: notificationPreferences,
  contactPreferences: contactPreferences,
  advance: persistReducer({ key: 'advance', storage: storage }, advance),
  orders: persistReducer({ key: 'orders', storage: storage }, orders),
  contact: contact,
  invoice: invoice,
  externalCard: externalCard,
  deviceRecovery: deviceRecovery,
  link: link,
  contract: contract,
  document: document,
  notification: notification,
  statement: statement,
  documentUpload: persistReducer({ key: 'documentUpload', storage: storage }, documentUpload),
  introStep: persistReducer({ key: 'introStep', storage: storage }, introStep),

  /* from neo-web */
  root: root,
  routes: routes,
  sca: sca,
  oidc: oidcReducer,
})

/* from @neo-commons/store */
export type Dispatch = ThunkDispatch<StateTypes, unknown, AnyAction>

export const { store, persistor } = createStore(reducers) as {
  store: Store<StateTypes>,
  persistor: any,
}

export default { store, persistor }

export interface StateTypes extends CommonsState {
  bankAccounts: BankAccountState
  user: UserState
  signIn: SignInState
  signup: SignupState
  pos: PosState
  telecollection: TelecollectionState
  provisioning: ProvisioningState
  config: ConfigState
  secretQuestion: SecretQuestionState
  root: RootState
  subscription: SubscriptionState
  offer: OfferState
  client: ClientState
  recoverPassCode: RecoverPasscodeState
  routes: RoutesState,
  notificationPreferences: NotificationPreferencesState
  contactPreferences: ContactPreferencesState
  address: AddressState,
  contact: ContactState,
  card: CardState,
  sca: ScaState,
  device: DeviceState
  deviceRecovery: DeviceRecoveryState,
  link: LinkState,
  contract: ContractState,
  document: DocumentState,
  notification: NotificationState,
  statement: StatementState,
  documentUpload: DocumentUploadState,
  introStep: IntroStepState,
  oidc: OidcUserState,
  advance: AdvanceState,
}
