import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Icon, IconTypes, MenuItemCard, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import {
  ExternalCardSelectors,
  PreparedProvisioning,
  ProvisioningActions,
  ProvisioningSelectors,
} from '@neo-commons/store'
import { CreditCardUtils, Formatters } from '@neo-commons/libraries'

import { CreditCardDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, PageContent } from '@neo-web/componentsOld'

import { getCbSchemeImage } from '../CbSchemeUtils'

import './ChooseCard.scss'

interface ChooseCardProps {
  nextStep: () => void,
  addNewCard: () => void,
}

export const ChooseCard: React.FC<ChooseCardProps> = (props: ChooseCardProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const externalCards: CreditCardDto[] = useSelector(ExternalCardSelectors.list)
  const prepare: PreparedProvisioning = useSelector(ProvisioningSelectors.prepare)

  return (
    <PageContent
      withBackground
      style={{ textAlign: 'center' }}
      justifyContent={JustifyContent.BETWEEN}
      title={t('app:pages:credit:chooseDebitCard:title')}
    >
      <div className='ChooseCard_section'>
        {
          externalCards?.map((card, id) => {
            return (
              <div key={id} className='ChooseCard_card'>
                <MenuItemCard
                  title={CreditCardUtils.formatTruncatedPan(card.truncatedPan)}
                  subtitle={card.cardHolderName + '\n' + Formatters.formatCreditCardDate(card.expirationDate)}
                  chevronPosition='center'
                  icon={
                    <div className='ChooseCard_cbScheme'>
                      <img src={getCbSchemeImage(card.lastUsageScheme)} />
                    </div>
                  }
                  onPress={() => {
                    dispatch(ProvisioningActions.prepare({
                      ...prepare,
                      card: {
                        saved: {
                          uuid: card.uuid,
                        },
                      },
                    }))
                    props.nextStep()
                  }}
                />
              </div>
            )
          })
        }
      </div>

      <div className='ChooseCard_divider' />

      <div className='ChooseCard_section'>
        <MenuItemCard
          title={t('app:pages:credit:chooseDebitCard:useAnotherCard')}
          chevronPosition='center'
          onPress={() => props.addNewCard()}
        />
      </div>

      <div className='ChooseCard_info'>
        <div className='ChooseCard_info-icon'>
          <Icon name='info' type={IconTypes.FEATHER} color={Colors.secondary} size={25} />
        </div>
        <Typography typeface='subtitle'>
          {t('app:pages:credit:chooseDebitCard:info')}
        </Typography>
      </div>
    </PageContent>
  )
}
