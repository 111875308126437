import React, { ReactElement } from 'react'

import { Button, Icon, Typography } from '@neo-commons/components'

import { CardDetails } from '@neo-web/componentsOld'

import './CardButton.scss'

interface CardButtonProps {
  icon: ReactElement<typeof Icon>
  title: string,
  subtitle?: string,
  button: ReactElement<typeof Button>
  cardTitle?: string,
}

export const CardButton: React.FC<CardButtonProps> = (props) => {
  return (
    <CardDetails title={props?.cardTitle}>
      <div className='CardButton'>
        <div className='CardButton_content'>
          <div className='CardButton_content_icon'>
            {props.icon}
          </div>
          <div className='CardButton_content_text'>
            <Typography typeface='bold'>
              {props.title}
            </Typography>
            {props?.subtitle &&
              <Typography typeface='content'>
                {props.subtitle}
              </Typography>}
          </div>
        </div>
        {props.button}
      </div>
    </CardDetails>
  )
}
