import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'
import { StateTypes } from '@neo-customer-front/applications/neo-web/src/Store'

import { BusinessSubscriptionStep, BusinessSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Input, Typography2 } from '@neo-commons/components'
import { ValidatorFunction, ValidatorResponseType } from '@neo-commons/libraries'
import { ClientSelectors, SubscriptionActions, SubscriptionSelectors, Dispatch } from '@neo-commons/store'

import { ClientDto, ErrorCodeDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent } from '@neo-web/componentsOld'

import './Kbis.scss'

type KbisProps = StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.Kbis]>

export const Kbis: React.FC<KbisProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()

  const [siret, setSiret] = useState<string>('')
  const [isSiretCorrect, setIsSiretCorrect] = useState(false)

  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const isSubscriptionLoading = useSelector((state: StateTypes) => state.subscription?.loading)

  const siretValidator: ValidatorFunction = (text: string) => {
    const regex = /^(\d{14})$/
    if (!text || !regex.test(text.trim())) {
      return {
        isValid: false,
        errorMessage: t('app:global:errors:invalidSiret'),
      }
    }
    return { isValid: true }
  }

  return (
    <TunnelContent
      loading={isSubscriptionLoading}
      header={<Typography2 typeface='h4'>{t('app:pages:subscription:business:kbis:title')}</Typography2>}
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:continue')}
          disabled={!isSiretCorrect}
          onPress={async () => {
            try {
              await dispatch(SubscriptionActions.submitSiret({
                subscriptionUuid: subscription?.uuid,
                personUuid: client?.holder?.uuid,
                siret: siret,
              }))
              nextStep()
              AnalyticsHandler.track(AnalyticsEvents.signUp.companyLegalIDProvided)
            } catch (error) {
              // If we got this specific error, we continue the process manually because it means that INPI is down
              if (error.code === ErrorCodeDto.C0708) {
                nextStep()
                AnalyticsHandler.track(AnalyticsEvents.signUp.companyLegalIDProvided)
              }
            }
          }}
        />
      }
    >
      <div className='Kbis_container'>
        <Input
          label={t('app:pages:subscription:business:kbis:placeholder')}
          onValidationChecked={(value: ValidatorResponseType) => { setIsSiretCorrect(value.isValid) }}
          value={siret}
          onChangeText={(value) => setSiret(value)}
          validators={[siretValidator]}
        />
      </div>
    </TunnelContent>
  )
}
