import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { IconTypes, Icon, Button, ButtonSkin } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { BankAccountActions } from '@neo-commons/store'

import { JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

export const CancelClosure: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <PageDetails
      onClose={() => history.push(Routes.Synthesis.index.path)}
      onBack={null}
      component={
        <PageContent
          justifyContent={JustifyContent.CENTER}
          footer={
            <Button
              skin={ButtonSkin.OUTLINE_PRIMARY}
              title={t('app:global:goBackToSynthesis')}
              onPress={async () => {
                await dispatch(BankAccountActions.reset())
                await dispatch(BankAccountActions.list())
                history.replace(Routes.Synthesis.index.path)
              }}
            />
          }
          icon={<Icon color={Colors.secondary} name='check-circle' size={40} type={IconTypes.FEATHER} />}
          title={t('app:pages:services:closeProject:cancel:title')}
          description={t('app:pages:services:closeProject:cancel:description')}
        />
      }
    />
  )
}
