import React from 'react'

import { Button as RSuiteButton, ButtonProps as RSuiteButtonProps } from 'rsuite'

import { Icon } from '@neo-commons/components'
import { Colors, Typo } from '@neo-commons/styles'

export enum ButtonSkin {
  PRIMARY = 'PRIMARY',
  DEFAULT = 'DEFAULT',
  DEFAULT_LIGHT = 'DEFAULT_LIGHT',
  SECONDARY = 'SECONDARY',
  OUTLINE_PRIMARY = 'OUTLINE_PRIMARY',
  OUTLINE_SECONDARY = 'OUTLINE_SECONDARY',
  LINK = 'LINK'
}
export interface ButtonProps extends RSuiteButtonProps {
  title: any,
  /** Button style */
  skin?: ButtonSkin,
  /** Button right icon. Use Icons */
  rightIcon?: React.ReactElement<typeof Icon>
  /** Button left icon. Use Icons */
  leftIcon?: React.ReactElement<typeof Icon>
  /** Is button text wrapping ? */
  small?: boolean
  /** Is Button flat design ? */
  flat?: boolean
  /** Text color */
  textColor?: string
  onPress?: () => void
}

type ButtonStyle = {
  appearance: any;
  style: any;
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const getButtonStyle = (buttonSkin: ButtonSkin) => {
    const result: ButtonStyle = { appearance: 'default', style: { ...Typo.button } }

    switch (buttonSkin) {
      case ButtonSkin.OUTLINE_PRIMARY:
        result.appearance = 'ghost'
        result.style = { color: props.textColor, borderColor: props.textColor, ...result.style }
        break
      case ButtonSkin.LINK:
        result.appearance = 'link'
        result.style = { color: Colors.black, ...result.style, ...Typo.button, textDecorationLine: 'underline' }
        break
      default:
        break
    }

    return result
  }

  const buttonStyle = getButtonStyle(props.skin)

  return (
    <RSuiteButton
      appearance={buttonStyle.appearance}
      style={buttonStyle.style}
      block
      onClick={props.onPress}
    >
      {props.title}
    </RSuiteButton>
  )
}
