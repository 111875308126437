import React from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { SubscriptionOrderStatusDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'

import { Alert } from '@neo-web/componentsOld'

interface PosDeliveryAlertProps {
  subscription: SubscriptionDto,
}

export const PosDeliveryAlert: React.FC<PosDeliveryAlertProps> = (props) => {
  const history = useHistory()
  const { t } = useTranslation()

  let alertTitle, alertSubtitle, alertButton

  switch (props.subscription?.orderStatus) {
    case SubscriptionOrderStatusDto.ORDERED:
      alertTitle = t('app:pages:services:pos:posTracking:alert:step1')
      alertSubtitle = t('app:pages:services:pos:posTracking:alert:description1')
      break

    case SubscriptionOrderStatusDto.DISPATCHED:
      alertTitle = t('app:pages:services:pos:posTracking:alert:step2')
      alertSubtitle = t('app:pages:services:pos:posTracking:alert:description2')
      alertButton = props.subscription?.orderTrackingUrl && (
        <a href={props.subscription.orderTrackingUrl} target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
          <Button small skin={ButtonSkin.PRIMARY} title={t('app:pages:services:pos:posTracking:alert:button')} />
        </a>
      )
      break

    case SubscriptionOrderStatusDto.DELIVERED:
      alertTitle = t('app:pages:services:pos:posTracking:alert:step3')
      alertSubtitle = t('app:pages:services:pos:posTracking:activate:message')
      alertButton = (
        <Button
          small
          skin={ButtonSkin.PRIMARY}
          title={t('app:pages:services:pos:posTracking:activate:button')}
          onPress={() => history.push(Routes.Services.activatePos.path)}
        />
      )
      break
  }

  return !alertTitle ? <></> : (
    <Alert
      icon={<Icon name='letter_delivery' color={Colors.secondary} type={IconTypes.NEOFONT} size={15} />}
      title={alertTitle}
      subTitle={alertSubtitle}
      button={alertButton}
    />
  )
}
