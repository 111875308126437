import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import i18n from 'i18next'
import dayjs from 'dayjs'

import { NeobankApi } from '@neo-commons/services'
import { i18commons } from '@neo-commons/i18n'

export const useLanguage = (locale: string) => {
  const dispatch = useDispatch()
  const userState = useSelector((state: StateTypes) => state.user.data)
  const signupState = useSelector((state: StateTypes) => state.signup)

  useEffect(() => {
    (async () => {
      let defaultLocale
      switch (true) {
        case signupState?.profile?.locale !== null :
          defaultLocale = signupState.profile.locale
          break
        case (userState?.locale && locale !== userState?.locale):
          defaultLocale = userState.locale
          break
        default:
          defaultLocale = locale
      }
      NeobankApi.getInstance().setLanguage(defaultLocale)
      await i18n.changeLanguage(defaultLocale)
      await i18commons.changeLanguage(defaultLocale)
      dayjs.locale(locale.split('_')[0])
    })()
  }, [dispatch, userState, locale])
}
