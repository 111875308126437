import { RoutesInterface } from '../Routes'

export class SynthesisRoutes {
  public static index: RoutesInterface = {
    path: '/synthesis',
    name: 'app:pages:synthesis:transactions',
    available: true,
  }

  public static transactions: RoutesInterface = {
    path: SynthesisRoutes.index.path + '/transactions',
    name: 'app:pages:synthesis:transactions',
    available: true,
  }

  public static transactionDetails: RoutesInterface = {
    path: SynthesisRoutes.transactions.path + '/:id',
    name: 'app:pages:synthesis:transaction:detail:title',
    available: true,
  }

  public static transactionProof: RoutesInterface = {
    path: SynthesisRoutes.transactionDetails.path + '/proof',
    name: 'app:pages:synthesis:transaction:detail:title',
    available: true,
  }

  public static telecollections: RoutesInterface = {
    path: SynthesisRoutes.transactions.path + '/telecollections',
    name: 'app:pages:synthesis:telecollections:title',
    available: true,
  }

  public static telecollectionDetails: RoutesInterface = {
    path: SynthesisRoutes.telecollections.path + '/:id',
    name: 'app:pages:synthesis:telecollections:detail:title',
    available: true,
  }
}
