import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { DocumentUploadTypes } from '@neo-commons/store'

import { displayAlertGlobal$ } from '../Epics'

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    DocumentUploadTypes.UPLOAD_DOCUMENT_SUCCESS,
    DocumentUploadTypes.UPLOAD_DOCUMENT_FAILURE,
    DocumentUploadTypes.ADD_DOCUMENT_PAGE_MAX_SIZE_FAILURE,
    DocumentUploadTypes.ADD_DOCUMENT_PAGE_SUCCESS,
    DocumentUploadTypes.REMOVE_DOCUMENT_PAGE,
  ])
}

export default [
  displayDropdownAlert$,
]
