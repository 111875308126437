/**
   * Generic methods use in NoElse for route.
   *
   */
export class RouteUtils {
  /**
   * Return the current Routes
   *
   * @param {any} navigationState - Send the navigation status
   * @returns {any} return the current route
   *
   */
  public static getCurrentRoute = (navigationState) => {
    if (!navigationState) {
      return null
    }
    const route = navigationState.routes[navigationState.index]
    // dive into nested navigators
    if (route.routes) {
      return RouteUtils.getCurrentRoute(route)
    }
    return route
  }
}
