import React, { useEffect } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { store } from 'Store'
import { Routes } from 'Routes/Routes'

import { ClosingAccountPolicy, ClosingAccountStep, FinalRedirectStep } from '@neo-commons/policies'
import { BankAccountSelectors } from '@neo-commons/store'
import { useWizardPolicy } from '@neo-commons/hooks'

import { ConfirmClosingAccount } from './ConfirmClosingAccount/ConfirmClosingAccount'
import { RecipientAccount } from './RecipientAccount/RecipientAccount'
import { SuccessClosingAccount } from './SuccessClosingAccount/SuccessClosingAccount'
import { VerifyAccountToClose } from './VerifyAccountToClose/VerifyAccountToClose'
import './ClosingAccount.scss'
import { FailClosingAccount } from './FailClosingAccount/FailClosingAccount'

export const ClosingAccount: React.FC = () => {
  const history = useHistory()
  const selectedBankAccount = useSelector(BankAccountSelectors.selected)

  const redirectToFinalStep = (finalRedirectStep: FinalRedirectStep) => {
    let finalRoute = Routes.Profile.index.path

    switch (finalRedirectStep) {
      case FinalRedirectStep.CREDIT:
        finalRoute = Routes.Credit.index.path
        break
      case FinalRedirectStep.SYNTHESIS:
        finalRoute = Routes.Synthesis.index.path
        break
    }
    history.replace(finalRoute)
  }

  const { currentStep, steps: wizardSteps } = useWizardPolicy(store, ClosingAccountPolicy, {
    [ClosingAccountStep.RecipientAccount]: RecipientAccount,
    [ClosingAccountStep.VerifyAccountToClose]: VerifyAccountToClose,
    [ClosingAccountStep.ConfirmClosingAccount]: ConfirmClosingAccount,
    [ClosingAccountStep.ClosingAccountSuccess]: SuccessClosingAccount,
    [ClosingAccountStep.ClosingAccountFailure]: FailClosingAccount,
  }, {
    onDone: async (context) => {
      const { finalRedirectStep } = context.data
      redirectToFinalStep(finalRedirectStep)
    },
  })

  useEffect(() => {
    if (!selectedBankAccount) {
      history.push(Routes.Profile.choicesAccount.path)
    }
  }, [])

  return (
    <>
      {currentStep?.path &&
        <Redirect
          to={{
            pathname: currentStep?.path,
          }}
        />}
      {wizardSteps.map((value) => {
        return (
          <Route
            key={value.navigationKey}
            exact
            path={value.path}
            component={value.component}
          />
        )
      })}
    </>
  )
}
