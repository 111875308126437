import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, ButtonSkin, Card, CardSkin, CheckboxInput, Typography } from '@neo-commons/components'
import {
  AddressTypes,
  ClientSelectors,
  ConfigSelectors,
  PosTypes,
} from '@neo-commons/store'
import { ClientUtils, CountryUtils, POSUtils } from '@neo-commons/libraries'

import { AddressDto, AddressTypeDto, ClientDto, CountryDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, LazyLoadingContainer, PageContent } from '@neo-web/componentsOld'

import './AddressSelect.scss'

interface AddressCheckProps {
  title: string,
  selectedAddress: AddressDto,
  onSelectAddress: (arg) => void,
  addNewAddress: () => void,
  validAddress: () => void,
  showCheckbox?: boolean,
}

export const AddressSelect: React.FC<AddressCheckProps> = (props) => {
  const { t } = useTranslation()

  const countries: CountryDto[] = useSelector(ConfigSelectors.getCurrentConfig)?.countries ?? []
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const deliveryAddresses: AddressDto[] = ClientUtils.getClientAddresses(client, AddressTypeDto.DELIVERY)

  const [isExactAddress, setIsExactAddress] = useState(!props?.showCheckbox)

  const renderFooter = () => {
    return (
      <>
        {props.showCheckbox && (
          <div className='AddressSelect_footer_checkbox'>
            <CheckboxInput
              onPress={(value) => setIsExactAddress(value)}
              text={
                <Typography typeface='boldMedium'>
                  {t('app:pages:services:pos:order:isExactAddress')}
                </Typography>
              }
              containerStyle={{ justifyContent: 'center' }}
              isSelected={isExactAddress}
            />
          </div>
        )}
        <div className='AddressSelect_footer_buttons'>
          <Button
            title={t('app:pages:services:pos:order:addressNok')}
            skin={ButtonSkin.OUTLINE_PRIMARY}
            onPress={() => props.addNewAddress()}
          />
          <Button
            title={t('app:pages:services:pos:order:addressOk')}
            skin={ButtonSkin.PRIMARY}
            disabled={!isExactAddress}
            onPress={() => props.validAddress()}
          />
        </div>
      </>
    )
  }

  const formatAddress = (address) => {
    const subtitles = POSUtils.getDisplayAddressSubtitles(address, CountryUtils.filterByAuthorized(countries))
    return subtitles?.map((subtitle, index) =>
      <Typography key={index} typeface='content'>
        {subtitle}
      </Typography>
    )
  }

  return (
    <LazyLoadingContainer events={[PosTypes.PREPARE_POS_RETURN_REQUEST, AddressTypes.LIST_ADDRESS_REQUEST]}>
      <PageContent
        justifyContent={JustifyContent.BETWEEN}
        title={props.title}
        footer={renderFooter()}
      >
        {deliveryAddresses.map((address, i) => {
          const selected = address.uuid === props.selectedAddress.uuid
          return (
            <Card
              key={i}
              skin={selected ? CardSkin.PRIMARY_BORDERED : CardSkin.BACKGROUND_BORDERED}
              containerStyle={{ paddingVertical: 12, paddingHorizontal: 16 }}
              onPress={() => props.onSelectAddress(address)}
            >
              <Typography typeface='bold' style={{ marginBottom: 8 }}>
                {POSUtils.getDeliveryLabelCard(address, client)}
              </Typography>
              {formatAddress(address)}
            </Card>
          )
        })}
      </PageContent>
    </LazyLoadingContainer>
  )
}
