import React from 'react'

import './ProgressStep.scss'

interface ProgressStepProps {
  currentValue: number;
  maxValue: number;
  minValue: number;
}

export const ProgressStep: React.FC<ProgressStepProps> = (props: ProgressStepProps) => {
  const percentageCurrentStep = ((props.currentValue - props.minValue) / (props.maxValue - props.minValue)) * 100
  const percentageOneStep = (1 / (props.maxValue - props.minValue)) * 100

  return (
    <div className='ProgressStep'>
      <div className='ProgressStep_currentStep' style={{ width: percentageCurrentStep + '%' }}>
        <div className='ProgressStep_currentStep_bar' />
      </div>
      <div className='ProgressStep_nextStep' style={{ width: percentageOneStep + '%' }} />
    </div>
  )
}
