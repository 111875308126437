import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { BankAccountActions, BankAccountSelectors } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { AccountDto } from '@afone/neo-core-client/dist/models'

import { Modal } from '@neo-web/componentsOld'

export const SuspendedAccount: React.FC = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const history = useHistory()

  const [showModal, setShowModal] = useState(true)

  const selectedMain: AccountDto = useSelector(BankAccountSelectors.selectedMain)

  return (
    <div className='modal-container'>
      <Modal
        autoFocus
        open={showModal}
        title={t('neo-commons:accountSuspended:popupWarning:title')}
        titleIcon={<Icon type={IconTypes.FEATHER} name='alert-circle' color={Colors.error} size={33} />}
        onClose={() => {
          dispatch(BankAccountActions.resetSuspendedAccountAlert())
          setShowModal(false)
          history.push(Routes.Synthesis.index.path)
        }}
        displayCross
      >
        <div style={{ textAlign: 'center' }}>
          <Typography2 typeface='body2'>
            {i18n.exists(`neo-commons:accountSuspended:popupWarning:${selectedMain?.suspension?.reason}`)
              ? t(`neo-commons:accountSuspended:popupWarning:${selectedMain?.suspension?.reason}`)
              : t('neo-commons:accountSuspended:popupWarning:default')}
          </Typography2>
        </div>
      </Modal>
    </div>
  )
}
