import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Nav } from 'rsuite'
import { StateTypes } from 'Store'
import i18next from 'i18next'

import { ButtonSkin, Input, Typography, Button } from '@neo-commons/components'
import { Formatters, Validators } from '@neo-commons/libraries'
import { StepComponentProps, ClosingAccountSteps, ClosingAccountStep } from '@neo-commons/policies'
import { BankAccountActions, BankAccountSelectors, BankAccountTypes } from '@neo-commons/store'

import { ClosingAccountLayout } from '../ClosingAccountLayout/ClosingAccountLayout'

import './RecipientAccount.scss'

type RecipientAccountProps = StepComponentProps<ClosingAccountSteps[ClosingAccountStep.RecipientAccount]>

export const RecipientAccount: React.FC<RecipientAccountProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const recipientAccountState = useSelector((state: StateTypes) => state.bankAccount.recipientAccountData)
  const selectedBankAccount = useSelector(BankAccountSelectors.selected)

  const [activeKey, setActiveKey] = useState('newAccount')
  const [recipientAccount, setRecipientAccount] = useState({
    owner: recipientAccountState?.recipientName ?? '',
    iban: recipientAccountState?.recipientIban ?? '',
    bic: recipientAccountState?.recipientBic ?? '',
  })
  const [isIbanValid, setIsIbanValid] = useState(true)
  const [isBicValid, setIsBicValid] = useState(true)

  const onNextClick = async () => {
    try {
      await dispatch(BankAccountActions.closeBankAccount({
        uuid: selectedBankAccount?.uuid ?? '',
        accountClosureDetails: {
          recipientName: recipientAccount.owner,
          recipientIban: recipientAccount.iban?.replaceAll(' ', ''),
          recipientBic: recipientAccount.bic,
        },
        simulation: true,
      }))
      await nextStep({
        recipientName: recipientAccount.owner,
        recipientIban: recipientAccount.iban?.replaceAll(' ', ''),
        recipientBic: recipientAccount.bic,
      })
    } catch (error) {
      const keyErrorTranslate = 'errors:internalTechnicalIssue'
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({ type: BankAccountTypes.CLOSE_BANK_ACCOUNT_FAILURE, errorMessage })
      throw error
    }
  }

  return (
    <ClosingAccountLayout>
      <div className='RecipientAccount_container'>
        <div className='RecipientAccount_title'>
          <Typography typeface='heading'>
            {t('app:pages:myProfile:closingAccount:recipientAccount:title')}
          </Typography>
        </div>
        <div className='RecipientAccount_description'>
          <Typography typeface='content'>
            {t('app:pages:myProfile:closingAccount:recipientAccount:description')}
          </Typography>
        </div>
        <div className='RecipientAccount_tab'>
          <Nav activeKey={activeKey} onSelect={(key) => setActiveKey(key)} appearance='subtle'>
            <Nav.Item eventKey='newAccount'>
              {t('app:pages:myProfile:closingAccount:recipientAccount:tab:newAccount')}
            </Nav.Item>
            <Nav.Item eventKey='contact' disabled>
              {t('app:pages:myProfile:closingAccount:recipientAccount:tab:contacts')}
            </Nav.Item>
          </Nav>
        </div>
        <div className='RecipientAccount_tab-content'>
          {activeKey === 'newAccount' &&
            <>
              <Input
                label={t('app:pages:myProfile:closingAccount:recipientAccount:newAccount:owner')}
                value={recipientAccount.owner}
                onChangeText={(value) => setRecipientAccount({ ...recipientAccount, owner: value })}
              />
              <Input
                label={t('app:pages:myProfile:closingAccount:recipientAccount:newAccount:iban')}
                value={recipientAccount.iban}
                onChangeText={(value) => setRecipientAccount({ ...recipientAccount, iban: value })}
                format={Formatters.formatIban}
                validators={[Validators.isCorrectIban]}
                onValidationChecked={(value) => setIsIbanValid(value.isValid)}
              />
              <Input
                label={t('app:pages:myProfile:closingAccount:recipientAccount:newAccount:bic')}
                value={recipientAccount.bic}
                onChangeText={(value) => setRecipientAccount({ ...recipientAccount, bic: value })}
                validators={[Validators.isCorrectBic]}
                onValidationChecked={(value) => setIsBicValid(value.isValid)}
              />
            </>}
        </div>
        <div className='RecipientAccount_button'>
          <Button
            title={t('app:pages:myProfile:closingAccount:recipientAccount:button')}
            skin={ButtonSkin.PRIMARY}
            disabled={
              !(isIbanValid && isBicValid && recipientAccount.owner && recipientAccount.iban && recipientAccount.bic)
            }
            onPress={onNextClick}
          />
        </div>
      </div>
    </ClosingAccountLayout>
  )
}
