import { Reducer, AnyAction } from 'redux'
import i18next from 'i18next'
import { NeobankApi } from '@neo-commons/services'
import { SecretQuestionDto } from '@afone/neo-core-client/dist/models'

import { Dispatch, ResourceState, resourceDefaultSelectors } from '../utils/resourceState'
import { initialResourceState } from '../utils'

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

const GET_SECRET_QUESTION_LIST_REQUEST = 'secretQuestion/GET_SECRET_QUESTION_LIST_REQUEST'
const GET_SECRET_QUESTION_LIST_SUCCESS = 'secretQuestion/GET_SECRET_QUESTION_LIST_SUCCESS'
const GET_SECRET_QUESTION_LIST_FAILURE = 'secretQuestion/GET_SECRET_QUESTION_LIST_FAILURE'

export const SecretQuestionTypes = {
  GET_SECRET_QUESTION_LIST_REQUEST,
  GET_SECRET_QUESTION_LIST_SUCCESS,
  GET_SECRET_QUESTION_LIST_FAILURE,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

function getSecretQuestionList (page = 1) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GET_SECRET_QUESTION_LIST_REQUEST })

    try {
      const getSecretQuestionsListResponse = await NeobankApi.getInstance().secretQuestionApi.getSecretQuestions()

      const data = getSecretQuestionsListResponse.status === 204 ? null : getSecretQuestionsListResponse.data

      dispatch({ type: GET_SECRET_QUESTION_LIST_SUCCESS, data, page })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: GET_SECRET_QUESTION_LIST_FAILURE, errorMessage })
    }
  }
}

export const SecretQuestionActions = {
  getSecretQuestionList,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type SecretQuestionState = ResourceState<SecretQuestionDto>

const initialState: SecretQuestionState = {
  ...initialResourceState,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const secretQuestionSelector = state => state.secretQuestion

export const SecretQuestionSelectors = {
  ...resourceDefaultSelectors(secretQuestionSelector),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const secretQuestion: Reducer = (state = initialState, action: AnyAction): SecretQuestionState => {
  switch (action.type) {
    case GET_SECRET_QUESTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_SECRET_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data?.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {}) ?? {},
        list: {
          ...state.list,
          ids: action.data?.map(item => item.uuid) ?? [],
          loadedOnce: true,
        },
      }
    case GET_SECRET_QUESTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
