import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    display: 'flex',
  },
  horizontalInputs: {
    flexDirection: 'row',
  },
  horizontalInputsContainer: {
    width: '50%',
  },
  horizontalInputsContainerLeft: {
    paddingRight: 5,
  },
  horizontalInputsContainerRight: {
    paddingLeft: 5,
  },
  icon: {
    position: 'absolute',
    top: 6,
    right: 0,
    flexDirection: 'row',
  },
  forbiddenCards: {
    marginVertical: 50,
  },
  label: {
    textAlign: 'left',
    width: '100%',
  },
  infoIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
