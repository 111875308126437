import React from 'react'
import { useTranslation } from 'react-i18next'

import { UrlUtils } from 'Utils'

import { CustomerServiceCountryCardList } from '@neo-commons/components'

import { PageContent, PageDetails } from '@neo-web/componentsOld'

export const ClientService = () => {
  const { t } = useTranslation()

  return (
    <PageDetails
      title={t('app:pages:myProfile:helpAndEmergency:clientService:title')}
      component={
        <PageContent>
          <CustomerServiceCountryCardList
            callMethod={(phoneNumber: string) => {
              UrlUtils.openPhoneDialer(phoneNumber)
            }}
          />
        </PageContent>
      }
    />
  )
}
