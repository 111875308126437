import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'

import { orderBy } from 'lodash'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'
import { PaginationList } from 'UI/Components/List/PaginationList'

import { NumbersUtils } from '@neo-commons/libraries'
import { InvoiceActions, InvoiceSelectors, InvoiceTypes, PaginateItem } from '@neo-commons/store'
import { MenuItemCard } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { InvoiceDocumentDto, InvoiceDocumentTypeDto } from '@afone/neo-core-client/dist/models'

import { useProfileDocumentPage } from '@neo-web/hooks'

import { resources, DateFormat } from '../../../../../I18n'

import { DocumentType } from './InvoiceAndContractDetail'

import './Invoices.scss'

export interface GroupStatements extends PaginateItem<InvoiceDocumentDto> {
  date: number,
}

export const Invoices: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const dateFormat: DateFormat = resources[i18next.language].formats

  const invoicesList = useSelector(InvoiceSelectors.list)
  const invoicesState = useSelector((state: StateTypes) => state?.invoice)
  const paginationEnded = useSelector((state: StateTypes) => state.invoice.list.paginationEnded)
  const currentPage = useSelector((state: StateTypes) => state.invoice.list.page)

  useProfileDocumentPage(DocumentType.INVOICE)

  const getMonthYearText = (date: string, type: string): string => {
    if (type === InvoiceDocumentTypeDto.ANNUAL_REPORT) {
      return t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:invoicesByYear',
        { year: dayjs(date).format('YYYY') })
    }

    const monthYear = dayjs(date).format(dateFormat.monthAndYearVerbose)
    return t('app:pages:myProfile:contractAndInvoice:invoiceAndContractDetail:invoicesByMonth',
      { monthYear: monthYear.charAt(0).toUpperCase() + monthYear.slice(1) })
  }

  const groupInvoices = (statements: InvoiceDocumentDto[], field = 'documentDate',
    orders: 'desc'|'asc' = 'desc'): PaginateItem<InvoiceDocumentDto>[] => {
    return orderBy(
      statements.reduce((previousValue: GroupStatements[], currentValue: InvoiceDocumentDto) => {
        const date = dayjs(currentValue[field]).format('YYYY')

        const existing = previousValue.find(group => group.title === date)

        if (existing) {
          existing.data.push(currentValue)
          existing.data = orderBy(existing.data, field, 'desc')
        } else {
          previousValue.push(
            { title: date, date: dayjs(currentValue[field]).unix(), data: [currentValue] }
          )
        }
        return previousValue
      }, [])
      , ['date'], [orders])
  }

  const getNext = () => {
    (async () => {
      if (!paginationEnded && !invoicesState.loading) {
        const page = (currentPage ?? 0) + 1
        await dispatch(InvoiceActions.getInvoiceDocuments(page))
      }
    })()
  }

  return (
    <div className='Invoices_container'>
      <PaginationList
        state={invoicesState}
        stateList={invoicesList}
        items={(invoice) =>
          <MenuItemCard
            key={invoice.documentUuid}
            title={getMonthYearText(invoice.documentDate, invoice.type)}
            subtitle={NumbersUtils.displayPriceForHuman(invoice.amount)}
            onPress={() => {
              history.push(generatePath(
                Routes.Profile.documentDetail.path,
                { documentUuid: invoice.documentUuid, type: DocumentType.INVOICE, documentDate: invoice.documentDate }
              ))
            }}
            chevronPosition='center'
            chevronColor={Colors.darkGreyText}
            subtitleStyle={{ color: Colors.black }}
          />}
        next={() => getNext()}
        eventLoading={InvoiceTypes.LIST_INVOICE_REQUEST}
        groupData={groupInvoices(invoicesList)}
        refreshFunction={() => {
          dispatch({ type: InvoiceTypes.LIST_INVOICE_REQUEST })
          dispatch(InvoiceActions.getInvoiceDocuments())
        }}
      />
    </div>
  )
}
