import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ScaAuthenticationErrorTypeDto } from '@afone/neo-core-client/dist/models'

import { Modal } from '@neo-web/componentsOld'

import { StateTypes } from '../../../../Store'
import { ScaActions } from '../../../../Store/Sca'

import { Code } from './Code'
import { ScaChannel } from './ScaChannel/ScaChannel'
import { WalletVerify } from './WalletVerify/WalletVerify'
import { PinCode } from './PinCode'
import { WalletPreview } from './WalletPreview/WalletPreview'
import './ScaDevice.scss'

export const ScaDevice: React.FC = () => {
  const dispatch = useDispatch()
  const scaState = useSelector((state: StateTypes) => state.sca)
  const [renderView, setRenderView] = useState(undefined)
  const [showModal, setShowModal] = useState(true)
  const [title, setSetTitle] = useState(undefined)
  const { t } = useTranslation()

  useEffect(() => {
    if (scaState?.data?.steps && scaState?.data?.steps[scaState?.data.prepare.scaStep]) {
      setSetTitle(undefined)

      switch (true) {
        case (scaState.data?.steps[0]?.methods[0].type ===
          ScaAuthenticationErrorTypeDto.PIN && !scaState.data.prepare.pinCode):
          setRenderView(<PinCode />)
          break
        case (Boolean(scaState?.data?.prepare?.scaType) &&
          scaState?.data?.prepare?.scaType === ScaAuthenticationErrorTypeDto.WALLET):

          if (scaState?.data?.prepare?.isPreview) {
            setRenderView(<WalletPreview />)
            setSetTitle(t('app:pages:auth:scaDevice:walletPreview:title'))
          } else {
            setRenderView(<WalletVerify />)
          }
          break
        case Boolean(scaState?.data?.prepare?.scaType):
          setRenderView(<Code />)
          break
        default:
          setRenderView(<ScaChannel />)
          break
      }
    }
  }, [scaState?.data])

  return (
    <div className='modal-container'>
      <Modal
        autoFocus
        open={showModal}
        backdrop='static'
        title={title}
        onClose={() => {
          dispatch(ScaActions.cancel())
          setShowModal(false)
        }}
        displayCross
      >
        <div className='ScaDevice'>
          {renderView}
        </div>
      </Modal>
    </div>
  )
}
