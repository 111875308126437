import { StyleSheet, TextStyle, ViewStyle } from 'react-native'

import { Typo, Colors, Commons } from '@neo-commons/styles'

interface TransactionCardStyle {
    itemContainer: ViewStyle
    label: TextStyle
    labelEmpty: TextStyle
    info: TextStyle
    note: TextStyle
    infoEmpty: TextStyle
    labelContainer: ViewStyle
    onusTransferIcon: ViewStyle
    amountContainer: ViewStyle
    amountText: TextStyle
    amountDeclinedText: TextStyle
    topContainer: ViewStyle
    bottomContainer: ViewStyle
    feesLabel: TextStyle
    feesStyle: TextStyle
    checkedIcon: ViewStyle
    badge: ViewStyle
}

const styles = StyleSheet.create<TransactionCardStyle>({
  itemContainer: {
    borderRadius: 15,
    ...Commons.shadow,
    paddingHorizontal: 10,
    paddingVertical: 8,
    backgroundColor: Colors.white,
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: 2,
    marginTop: 2,
  },
  topContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 7,
    minHeight: 48,
  },
  label: {
    ...Typo.selectedTitle,
    fontSize: 14,
    color: Colors.black,
    textAlign: 'left',
  },
  labelEmpty: {
    width: 50,
    height: 12,
    marginBottom: 3,
    backgroundColor: 'grey',
    opacity: 0.1,
  },
  info: {
    ...Typo.itemSubtitle,
    fontSize: 13,
    paddingTop: 3,
    color: Colors.black,
    textAlign: 'left',
  },
  note: {
    ...Typo.itemSubtitle,
    fontSize: 13,
    paddingTop: 3,
    color: Colors.lightGray,
    textAlign: 'left',
  },
  infoEmpty: {
    width: 150,
    opacity: 0.05,
    height: 12,
    backgroundColor: 'grey',
  },
  labelContainer: {
    display: 'flex',
    flex: 1,
    marginLeft: 16,
    marginRight: 8,
    justifyContent: 'center',
  },
  onusTransferIcon: {
    position: 'relative',
    left: 8,
    top: 1,
  },
  amountContainer: {
    maxWidth: '30%',
    height: '100%',
    paddingTop: 2,
  },
  amountText: {
    ...Typo.transactionAmount,
    fontSize: 16,
  },
  amountDeclinedText: {
    ...Typo.transactionAmount,
    fontSize: 16,
    color: Colors.error,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  bottomContainer: {
    borderStyle: 'solid',
    borderTopWidth: 1.5,
    borderTopColor: Colors.bgGray,
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingHorizontal: 7,
    paddingTop: 4,
    marginTop: 5,
    minHeight: 40,
  },
  feesLabel: {
    fontSize: 14,
  },
  feesStyle: {
    ...Typo.transactionAmount,
    fontSize: 16,
  },
  checkedIcon: {
    position: 'absolute',
    right: -4,
    bottom: -3,
    backgroundColor: Colors.white,
    padding: 0.5,
    borderRadius: 8,
  },
  badge: {
    borderRadius: 24,
    paddingHorizontal: 12,
    paddingVertical: 4,
    paddingBottom: 4,
    backgroundColor: Colors.primary,
  },
})

export default styles
