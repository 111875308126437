import { Dispatch, Reducer, AnyAction } from 'redux'
import i18next from 'i18next'
import {
  NotificationPreferenceDto,
  NotificationPreferenceUpdateDto,
} from '@afone/neo-core-client/dist/models'
import { NeobankApi } from '@neo-commons/services'

/* %%%%%%%%%%%%%%%%%% *\
    Action Types.
\* %%%%%%%%%%%%%%%%%% */

export const GET_NOTIFICATION_PREFERENCES_REQUEST = 'notificationPreferences/GET_NOTIFICATION_PREFERENCES_REQUEST'
export const GET_NOTIFICATION_PREFERENCES_SUCCESS = 'notificationPreferences/GET_NOTIFICATION_PREFERENCES_SUCCESS'
export const GET_NOTIFICATION_PREFERENCES_FAILURE = 'notificationPreferences/GET_NOTIFICATION_PREFERENCES_FAILURE'

export const UPDATE_NOTIFICATION_PREFERENCES_REQUEST = 'notificationPreferences/UPDATE_NOTIFICATION_PREFERENCES_REQUEST'
export const UPDATE_NOTIFICATION_PREFERENCES_SUCCESS = 'notificationPreferences/UPDATE_NOTIFICATION_PREFERENCES_SUCCESS'
export const UPDATE_NOTIFICATION_PREFERENCES_FAILURE = 'notificationPreferences/UPDATE_NOTIFICATION_PREFERENCES_FAILURE'

export const NotificationPreferencesTypes = {
  GET_NOTIFICATION_PREFERENCES_REQUEST,
  GET_NOTIFICATION_PREFERENCES_SUCCESS,
  GET_NOTIFICATION_PREFERENCES_FAILURE,

  UPDATE_NOTIFICATION_PREFERENCES_REQUEST,
  UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
  UPDATE_NOTIFICATION_PREFERENCES_FAILURE,
}

const keyErrorTranslate = 'errors:unknownTechnicalIssue'

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */

const getNotificationPreferences = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GET_NOTIFICATION_PREFERENCES_REQUEST })
    try {
      const payload = await NeobankApi.getInstance().notificationPreferenceApi.getNotificationPreferences()
      const notificationPreferences = payload.data
      dispatch({ type: GET_NOTIFICATION_PREFERENCES_SUCCESS, notificationPreferences })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({ type: GET_NOTIFICATION_PREFERENCES_FAILURE, errorMessage })
    }
  }
}

const updateNotificationPreferences = (
  uuid: string, notificationPreferenceChannelValue: NotificationPreferenceUpdateDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_NOTIFICATION_PREFERENCES_REQUEST })
    try {
      await NeobankApi.getInstance().notificationPreferenceApi.updateNotificationPreferenceByUuid(
        uuid, notificationPreferenceChannelValue)
      dispatch({ type: UPDATE_NOTIFICATION_PREFERENCES_SUCCESS, data: { uuid, notificationPreferenceChannelValue } })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({ type: UPDATE_NOTIFICATION_PREFERENCES_FAILURE, errorMessage })
    }
  }
}

export const NotificationPreferencesActions = {
  getNotificationPreferences,
  updateNotificationPreferences,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export interface NotificationPreferencesState {
  ui: {
    isUpdating: boolean;
    isUpdated: boolean;
    isFetching: boolean;
    isFetched: boolean;
  },
  data: NotificationPreferenceDto[]
}

const INITIAL_STATE: NotificationPreferencesState = {
  ui: {
    isUpdating: false,
    isUpdated: false,
    isFetching: false,
    isFetched: false,
  },
  data: [],
}

export const notificationPreferences: Reducer = (
  state: NotificationPreferencesState = INITIAL_STATE, action: AnyAction) => {
  let newDataState

  switch (action.type) {
    case GET_NOTIFICATION_PREFERENCES_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isFetching: true,
        },
      }

    case GET_NOTIFICATION_PREFERENCES_SUCCESS:

      return {
        ...state,
        ui: {
          ...state.ui,
          isFetched: true,
          isFetching: false,
        },
        data: action.notificationPreferences,
      }

    case GET_NOTIFICATION_PREFERENCES_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isFetching: false,
          errorMessage: action.errorMessage,
        },
      }

    case UPDATE_NOTIFICATION_PREFERENCES_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdating: true,
        },
      }

    case UPDATE_NOTIFICATION_PREFERENCES_SUCCESS:
      newDataState = state.data.map(item => {
        const newItemState = { ...item }
        if (item.uuid === action.data.uuid) {
          newItemState.channels = action.data.notificationPreferenceChannelValue
        }
        return newItemState
      })

      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdated: true,
          isUpdating: false,
        },
        data: newDataState,
      }

    case UPDATE_NOTIFICATION_PREFERENCES_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdating: false,
          errorMessage: action.errorMessage,
        },
      }
    default:
      return state
  }
}
