import React from 'react'

interface ImageWrapperProps {
  uri: string,
  width?: number,
  height?: number,
  backgroundSize?: string,
  wrapperStyle?: any,
}

export const ImageWrapper: React.FC<ImageWrapperProps> = (props) => {
  return (
    <div
      className='ImageWrapper'
      style={{
        ...props?.wrapperStyle,
        width: props?.width ? props.width : '100%',
        height: props?.height ? props.height : '100%',
        backgroundImage: `url(${props.uri})`,
        backgroundSize: props?.backgroundSize ? props.backgroundSize : 'cover',
        backgroundPosition: 'center',
      }}
    />
  )
}
