import { AnyAction, Reducer } from 'redux'
import { createSelector } from 'reselect'
import i18next from 'i18next'
import {
  AddressDto,
  AddressTypeDto,
  PosCreateDto,
  ClientTypeDto,
  PosDto,
  ProductDto,
  ClientDto,
  AccountDto,
  PosSearchDto,
  PosUpdateDto,
  PosFailureReasonDto,
  PosLostDeclarationCreateDto,
  PosReturnReasonDto,
  PosReturnDto,
  PosDispatchDto,
  PosReturnDetailDto,
  PosListDto,
} from '@afone/neo-core-client/dist/models'
import { NeobankApi } from '@neo-commons/services'
import { PosProductData } from '@neo-commons/libraries'

import { Dispatch, resourceDefaultSelectors, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { AlertType, initialResourceState, State } from '../utils'

import { BankAccountSelectors } from './bankAccount'
import { ClientActions, ClientSelectors } from './client'
import { SubscriptionActions } from './subscription'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type posData = PosDto

const {
  resourceActionTypes: posActionTypes,
  resourceReducer: posResourceReducer,
  resourceAction: posAction,
} = ResourceStateFactory<posData, 'pos'>(state => state.pos, 'pos')

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

export const PosTypes = {
  ...posActionTypes,
  ACTIVATE_POS_REQUEST: 'pos/ACTIVATE_POS_REQUEST',
  ACTIVATE_POS_SUCCESS: 'pos/ACTIVATE_POS_SUCCESS',
  ACTIVATE_POS_FAILURE: 'pos/ACTIVATE_POS_FAILURE',

  UPDATE_POS_REQUEST: 'pos/UPDATE_POS_REQUEST',
  UPDATE_POS_SUCCESS: 'pos/UPDATE_POS_SUCCESS',
  UPDATE_POS_FAILURE: 'pos/UPDATE_POS_FAILURE',

  SELECT_POS_REQUEST: 'pos/SELECT_POS_REQUEST',
  SELECT_POS_SUCCESS: 'pos/SELECT_POS_SUCCESS',
  SELECT_POS_FAILURE: 'pos/SELECT_POS_FAILURE',

  PREPARE_POS_REQUEST: 'pos/PREPARE_POS_REQUEST',
  PREPARE_POS_SUCCESS: 'pos/PREPARE_POS_SUCCESS',
  PREPARE_POS_FAILURE: 'pos/PREPARE_POS_FAILURE',

  ORDER_POS_REQUEST: 'pos/ORDER_POS_REQUEST',
  ORDER_POS_SUCCESS: 'pos/ORDER_POS_SUCCESS',
  ORDER_POS_FAILURE: 'pos/ORDER_POS_FAILURE',

  PREPARE_POS_RETURN_REQUEST: 'pos/PREPARE_POS_RETURN_REQUEST',
  PREPARE_POS_RETURN_SUCCESS: 'pos/PREPARE_POS_RETURN_SUCCESS',
  PREPARE_POS_RETURN_FAILURE: 'pos/PREPARE_POS_RETURN_FAILURE',
  PREPARE_POS_RETURN_RESET: 'pos/PREPARE_POS_RETURN_RESET',

  LIST_POS_RETURN_REASONS_REQUEST: 'pos/LIST_POS_RETURN_REASONS_REQUEST',
  LIST_POS_RETURN_REASONS_SUCCESS: 'pos/LIST_POS_RETURN_REASONS_SUCCESS',
  LIST_POS_RETURN_REASONS_FAILURE: 'pos/LIST_POS_RETURN_REASONS_FAILURE',

  GET_TELECOLLECTIONS_REQUEST: 'pos/GET_TELECOLLECTIONS_REQUEST',
  GET_TELECOLLECTIONS_SUCCESS: 'pos/GET_TELECOLLECTIONS_SUCCESS',
  GET_TELECOLLECTIONS_FAILURE: 'pos/GET_TELECOLLECTIONS_FAILURE',

  RETURN_POS_REQUEST: 'pos/RETURN_POS_REQUEST',
  RETURN_POS_SUCCESS: 'pos/RETURN_POS_SUCCESS',
  RETURN_POS_FAILURE: 'pos/RETURN_POS_FAILURE',

  LIST_DISPATCHES_REQUEST: 'pos/LIST_DISPATCHES_REQUEST',
  LIST_DISPATCHES_SUCCESS: 'pos/LIST_DISPATCHES_SUCCESS',
  LIST_DISPATCHES_FAILURE: 'pos/LIST_DISPATCHES_FAILURE',

  GET_DISPATCH_LABEL_REQUEST: 'pos/GET_DISPATCH_LABEL_REQUEST',
  GET_DISPATCH_LABEL_SUCCESS: 'pos/GET_DISPATCH_LABEL_SUCCESS',
  GET_DISPATCH_LABEL_FAILURE: 'pos/GET_DISPATCH_LABEL_FAILURE',

  CANCEL_DISPATCH_REQUEST: 'pos/CANCEL_DISPATCH_REQUEST',
  CANCEL_DISPATCH_SUCCESS: 'pos/CANCEL_DISPATCH_SUCCESS',
  CANCEL_DISPATCH_FAILURE: 'pos/CANCEL_DISPATCH_FAILURE',

  LIST_RETURNS_REQUEST: 'pos/LIST_RETURNS_REQUEST',
  LIST_RETURNS_SUCCESS: 'pos/LIST_RETURNS_SUCCESS',
  LIST_RETURNS_FAILURE: 'pos/LIST_RETURNS_FAILURE',

  REFUND_DEPOSIT_REQUEST: 'pos/REFUND_DEPOSIT_REQUEST',
  REFUND_DEPOSIT_SUCCESS: 'pos/REFUND_DEPOSIT_SUCCESS',
  REFUND_DEPOSIT_FAILURE: 'pos/REFUND_DEPOSIT_FAILURE',

  PREPARE_POS_LOST_REQUEST: 'pos/PREPARE_POS_LOST_REQUEST',
  PREPARE_POS_LOST_SUCCESS: 'pos/PREPARE_POS_LOST_SUCCESS',
  PREPARE_POS_LOST_FAILURE: 'pos/PREPARE_POS_LOST_FAILURE',
  PREPARE_POS_LOST_RESET: 'pos/PREPARE_POS_LOST_RESET',

  LOST_POS_REQUEST: 'pos/LOST_POS_REQUEST',
  LOST_POS_SUCCESS: 'pos/LOST_POS_SUCCESS',
  LOST_POS_FAILURE: 'pos/LOST_POS_FAILURE',
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */
const posSelector = state => state.pos
const defaultSelectors = resourceDefaultSelectors(posSelector)

export const PosSelectors = {
  ...defaultSelectors,
  prepare: createSelector(
    posSelector,
    resource => resource.prepare),
  prepareReturn: createSelector(
    posSelector,
    resource => resource.prepareReturn),
  listPos: (bankAccountUuid?: string) => createSelector(posSelector, (resource) => (
    (resource.list?.ids?.map((id: string) => resource.data[id]).filter((item: any) => item) ?? []).filter(e => {
      if (bankAccountUuid) {
        return bankAccountUuid === e.accountUuid
      }
      return true
    })
  )),
  listFailureReasons: createSelector(
    posSelector,
    resource => resource.posReturnReasons
  ),
  listReturns: createSelector(
    posSelector,
    resource => resource.returns
  ),
  listDispatches: createSelector(
    posSelector,
    resource => resource.dispatches
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

const list = (accounts?: AccountDto[]) =>
  async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: PosTypes.LIST_POS_REQUEST })

    const bankAccounts: AccountDto[] = accounts ?? BankAccountSelectors.list(getState())
    const posSearchRequest: PosSearchDto[] = bankAccounts.map(bankAccount => ({
      accountUuid: bankAccount.uuid,
    }))
    try {
      const response = await NeobankApi.getInstance().posApi.getPos(
        undefined, 0, undefined, undefined, posSearchRequest
      )

      const data = response?.status === 204 ? [] : response.data
      const paginationEnded = response?.status === 204

      dispatch({
        type: PosTypes.LIST_POS_SUCCESS,
        data,
        paginationEnded,
      })
    } catch (_) {}
  }

const activatePos = (subscriptionUuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PosTypes.ACTIVATE_POS_REQUEST })

    try {
      await NeobankApi.getInstance().subscriptionApi.activatePos(subscriptionUuid)
      await dispatch(SubscriptionActions.list())

      dispatch({ type: PosTypes.ACTIVATE_POS_SUCCESS })
    } catch (error) {
      dispatch({ type: PosTypes.ACTIVATE_POS_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

const updatePos = (payload:{
  pos: PosDto,
  update: PosUpdateDto,
}) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PosTypes.UPDATE_POS_REQUEST })

    const { pos, update } = payload

    try {
      await NeobankApi.getInstance().posApi.updatePos(pos?.accountUuid ?? '', pos?.uuid ?? '', update)
      dispatch({
        type: PosTypes.UPDATE_POS_SUCCESS,
        pos,
        newName: update.name,
        newAccountPosUuid: update.newAccountPosUuid,
        alertType: AlertType.SUCCESS,
        successMessage: i18next.t('neo-commons:app:global:profile:update:success'),
      })
    } catch (error) {
      dispatch({ type: PosTypes.UPDATE_POS_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

/** ==========================================================================
 * POS ORDER / COMMANDE DE TPE
========================================================================== **/
const prepare = (prepareOrder: PreparePOSOrder) => {
  return async (dispatch: Dispatch, getState: () => State) : Promise<void> => {
    dispatch({ type: PosTypes.PREPARE_POS_REQUEST })

    const preparedPosOrder = getState().pos.prepare
    try {
      if (prepareOrder.deliveryAddress && !prepareOrder.deliveryAddress?.uuid) {
        if (!getState().client?.list?.loadedOnce) {
          await dispatch(ClientActions.list())
        }
        const client = ClientSelectors.defaultOne(getState())
        prepareOrder.deliveryAddress = await dispatch(ClientActions.createAddress({
          ...prepareOrder.deliveryAddress,
          type: AddressTypeDto.DELIVERY,
          fullName: client?.holder?.legalName ??
            ((getState().user.data?.person?.firstName ?? '') + (getState().user.data?.person?.lastName ?? '')),
        }))
      }

      if (preparedPosOrder?.workflowSubscriptionUuid && prepareOrder?.deliveryAddress?.uuid) {
        await NeobankApi.getInstance().subscriptionApi
          .updateSubscriptionByUuid(preparedPosOrder.workflowSubscriptionUuid, {
            addressUuid: prepareOrder?.deliveryAddress?.uuid,
          })
      }
      dispatch({
        type: PosTypes.PREPARE,
        prepareOrder,
      })

      dispatch({ type: PosTypes.PREPARE_POS_SUCCESS })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: PosTypes.PREPARE_POS_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const initiateWorkflowSubscription =
  ({
    offerUuid,
    posProducts,
  }: {
    offerUuid: string,
    posProducts: ProductDto[],
  }) => {
    return async (dispatch: Dispatch, getState: () => State) : Promise<void> => {
      const preparedPosOrder = getState().pos.prepare

      if (preparedPosOrder?.workflowSubscriptionUuid) {
        return await dispatch(prepare({
          ...preparedPosOrder,
          posProducts: posProducts,
        }))
      }

      let defaultClient: ClientDto
      try {
        if (!getState().client?.list?.loadedOnce) {
          await dispatch(ClientActions.list())
        }
        defaultClient = ClientSelectors.defaultOne(getState())
      } catch (error) {
        throw new Error(error.message)
      }

      if (!preparedPosOrder?.workflowSubscriptionUuid && defaultClient?.uuid) {
        try {
          await dispatch(SubscriptionActions.prepare({ ibanLocalization: 'FR' }))
          const createdSubscription = await dispatch(SubscriptionActions.create({
            offerUuid: offerUuid,
            clientType: ClientTypeDto.CORPORATE,
          }))
          await dispatch(prepare({
            ...preparedPosOrder,
            workflowSubscriptionUuid: createdSubscription?.uuid ?? '',
            posProducts: posProducts,
          }))
        } catch (error) {
          throw new Error(error.message)
        }
      }
    }
  }

const selectPos =
  ({
    offerUuid,
    posProducts,
  }: {
    offerUuid: string,
    posProducts: ProductDto[],
  }) => {
    return async (dispatch: Dispatch, getState: () => State) => {
      dispatch({ type: PosTypes.SELECT_POS_REQUEST })

      const preparedPosOrder = getState().pos.prepare ?? {}

      try {
        if (!preparedPosOrder.workflowSubscriptionUuid) {
          await dispatch(initiateWorkflowSubscription({ offerUuid, posProducts }))
          dispatch({ type: PosTypes.PREPARE_POS_SUCCESS })
        } else {
          await dispatch(prepare({
            ...preparedPosOrder,
            posProducts: posProducts,
            missingBalance: undefined,
          }))
        }
        dispatch({ type: PosTypes.SELECT_POS_SUCCESS })
      } catch (error) {
        dispatch({ type: PosTypes.SELECT_POS_FAILURE })
      }
    }
  }

const orderPos = (xValidationOnly = 0) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: PosTypes.ORDER_POS_REQUEST })

    const preparedPosOrder = getState().pos.prepare ?? {}
    const selectedBankAccount = getState().bankAccount.data[getState().bankAccount.list.selectedId as string]

    try {
      const payload: any = await NeobankApi.getInstance().subscriptionApi.createPos(
        preparedPosOrder.workflowSubscriptionUuid ?? '',
        {
          accountMainUuid: selectedBankAccount.uuid,
          deliveryProductCode: preparedPosOrder.deliveryProduct?.productCode ?? '',
          pos: preparedPosOrder.posProducts!.map(product => {
            return {
              productCode: product.productCode,
              systemProductCode: (product.data as PosProductData).systemProductCode,
              softwareCodes: (product.data as PosProductData)?.softwareCodes ?? [],
              quantity: (product.data as PosProductData)?.quantity ?? 1,
            } as PosCreateDto
          }),
        },
        xValidationOnly
      )

      const associatedBankAccount = xValidationOnly === 0 && payload.data?.[0].accountUuid ? {
        associatedBankAccount: payload.data?.[0].accountUuid,
        newPosIds: (payload.data as PosDto[]).map(pos => pos?.uuid) as string[],
      } : {}
      const missingBalance = payload.status === 204 ? { missingBalance: 0 } : {}

      await dispatch(prepare({
        ...preparedPosOrder,
        ...associatedBankAccount,
        ...missingBalance,
      }))
      dispatch({ type: PosTypes.ORDER_POS_SUCCESS })

      return payload.data
    } catch (error) {
      await dispatch(prepare({
        ...preparedPosOrder,
        missingBalance: error.data?.amount ?? 0,
      }))
      dispatch({
        type: PosTypes.ORDER_POS_FAILURE,
        errorMessage: error.status !== 402 && error.message,
      })
      throw error
    }
  }
}

const getOrderFees = () => {
  return orderPos(1)
}

/** ==========================================================================
 * POS RETURN / RETOUR DE TPE (RENVOI/PANNE)
 ========================================================================== **/
const prepareReturn = (prepareReturn: PreparePOSReturn) => {
  return async (dispatch: Dispatch, getState: () => State) : Promise<void> => {
    dispatch({ type: PosTypes.PREPARE_POS_RETURN_REQUEST })

    try {
      if (prepareReturn.failureReason && !prepareReturn.failureReason?.uuid) {
        const createdFailure = await NeobankApi.getInstance().posApi.createPosFailure(
          prepareReturn?.pos?.[0]?.uuid ?? '',
          { failureReasonCode: prepareReturn?.failureReason?.code }
        )
        prepareReturn.failureReason.uuid = createdFailure?.data?.uuid
      }
      if (prepareReturn.deliveryAddress && !prepareReturn.deliveryAddress?.uuid) {
        if (!getState().client?.list?.loadedOnce) {
          await dispatch(ClientActions.list())
        }
        const client = ClientSelectors.defaultOne(getState())
        prepareReturn.deliveryAddress = await dispatch(ClientActions.createAddress({
          ...prepareReturn.deliveryAddress,
          type: AddressTypeDto.DELIVERY,
          fullName: client?.holder?.legalName ??
            ((getState().user.data?.person?.firstName ?? '') + (getState().user.data?.person?.lastName ?? '')),
        }))
      }

      dispatch({
        type: PosTypes.PREPARE_POS_RETURN_SUCCESS,
        prepareReturn,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: PosTypes.PREPARE_POS_RETURN_FAILURE, errorMessage })
    }
  }
}

const resetPrepareReturn = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PosTypes.PREPARE_POS_RETURN_RESET })
  }
}

const listFailureReasons = () =>
  async (dispatch: Dispatch) => {
    dispatch({ type: PosTypes.LIST_POS_RETURN_REASONS_REQUEST })

    try {
      const response = await NeobankApi.getInstance().posApi.getPosFailures()

      const data = response?.status === 204 ? [] : response.data

      dispatch({
        type: PosTypes.LIST_POS_RETURN_REASONS_SUCCESS,
        data,
      })
    } catch (_) {}
  }

const getTelecollections = (pos: PosDto[], lostPos?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: PosTypes.GET_TELECOLLECTIONS_REQUEST })

    try {
      const posList: PosListDto = { posList: pos.map(p => p.uuid as string) }
      const response = await NeobankApi.getInstance().posApi.getTlcs(posList)

      const data = response?.status === 204 ? [] : response.data

      dispatch({
        type: PosTypes.GET_TELECOLLECTIONS_SUCCESS,
        hasTlc: !!data.length,
        lostPos,
      })
    } catch (error) {
      dispatch({
        type: PosTypes.GET_TELECOLLECTIONS_FAILURE,
        errorMessage: error,
      })
    }
  }

const returnPos = (xValidationOnly = 0) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: PosTypes.RETURN_POS_REQUEST })

    const preparedPosReturn = getState().pos.prepareReturn ?? {}

    try {
      const response = await NeobankApi.getInstance().posApi.returnPos(
        xValidationOnly, {
          posList: preparedPosReturn?.pos!.map(p => {
            return {
              posUuid: p.uuid,
              posFailureUuid: preparedPosReturn?.failureReason?.uuid,
            } as PosReturnDto
          }),
          reason: preparedPosReturn?.returnReason,
          adressUuid: preparedPosReturn?.deliveryAddress?.uuid,
        },
      )

      await dispatch(prepareReturn({
        ...preparedPosReturn,
        missingBalance: 0,
      }))
      dispatch({
        type: PosTypes.RETURN_POS_SUCCESS,
        data: response?.data,
      })

      return response?.data
    } catch (error) {
      if (error.status === 402) {
        await dispatch(prepareReturn({
          ...preparedPosReturn,
          missingBalance: error.data?.amount ?? 0,
        }))
        dispatch({ type: PosTypes.RETURN_POS_FAILURE })
      } else {
        dispatch({
          type: PosTypes.RETURN_POS_FAILURE,
          errorMessage: error.message,
        })
      }
      throw error
    }
  }
}

const getReturnFees = () => {
  return returnPos(1)
}

/** ==========================================================================
 * POS MANAGE DISPATCHES / SUIVI DES RENVOIS
 ========================================================================== **/
const listDispatches = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: PosTypes.LIST_DISPATCHES_REQUEST })

    const selectedBankAccount = getState().bankAccount.data[getState().bankAccount.list.selectedId as string]

    try {
      const response = await NeobankApi.getInstance().posApi.getPosDispatchs(selectedBankAccount?.uuid)
      const data = response?.status === 204 ? [] : response.data

      dispatch({
        type: PosTypes.LIST_DISPATCHES_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: PosTypes.LIST_DISPATCHES_FAILURE })
    }
  }
}

const getDispatchLabel = (uuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PosTypes.GET_DISPATCH_LABEL_REQUEST })

    try {
      const response = await NeobankApi.getInstance().posApi.getPosReturnDispatchLabelByUuid(uuid)
      dispatch({ type: PosTypes.GET_DISPATCH_LABEL_SUCCESS })

      return response?.data ?? {}
    } catch (error) {
      dispatch({
        type: PosTypes.GET_DISPATCH_LABEL_FAILURE,
        errorMessage: error.message,
      })
      throw error
    }
  }
}

const cancelDispatch = (uuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PosTypes.CANCEL_DISPATCH_REQUEST })

    try {
      await NeobankApi.getInstance().posApi.cancelPosReturnDispatch(uuid)

      dispatch({
        type: PosTypes.CANCEL_DISPATCH_SUCCESS,
        alertType: AlertType.SUCCESS,
        successMessage: i18next.t('neo-commons:pages:services:pos:return:dispatch:cancel:successAlert'),
      })
    } catch (error) {
      dispatch({
        type: PosTypes.CANCEL_DISPATCH_FAILURE,
        errorMessage: error.message,
      })
    }
  }
}

/** ==========================================================================
 * POS MANAGES RETURNS & DEPOSIT / TPE RETOURNÉS & CAUTIONS
 ========================================================================== **/
const listReturns = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: PosTypes.LIST_RETURNS_REQUEST })

    const selectedBankAccount = getState().bankAccount.data[getState().bankAccount.list.selectedId as string]

    try {
      const response = await NeobankApi.getInstance().posApi.getPosReturns(selectedBankAccount?.uuid)
      const data = response?.status === 204 ? [] : response.data

      dispatch({
        type: PosTypes.LIST_RETURNS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: PosTypes.LIST_RETURNS_FAILURE })
    }
  }
}

const refundDeposit = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: PosTypes.REFUND_DEPOSIT_REQUEST })

    const selectedBankAccount = getState().bankAccount.data[getState().bankAccount.list.selectedId as string]

    try {
      await NeobankApi.getInstance().posApi.refundPosDeposit(selectedBankAccount?.uuid)

      dispatch({
        type: PosTypes.REFUND_DEPOSIT_SUCCESS,
        alertType: AlertType.SUCCESS,
        successMessage: i18next.t('neo-commons:pages:services:pos:return:deposit:successAlert'),
      })
    } catch (error) {
      dispatch({
        type: PosTypes.REFUND_DEPOSIT_FAILURE,
        errorMessage: error.message,
      })
    }
  }
}

/** ==========================================================================
 * POS LOST / TPE PERDUS
 ========================================================================== **/
const prepareLost = (prepareLost: PreparePOSLost) => {
  return async (dispatch: Dispatch) : Promise<void> => {
    dispatch({ type: PosTypes.PREPARE_POS_LOST_REQUEST })

    try {
      dispatch({
        type: PosTypes.PREPARE_POS_LOST_SUCCESS,
        prepareLost,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('neo-commons:errors:unknownTechnicalIssue')
      dispatch({ type: PosTypes.PREPARE_POS_LOST_FAILURE, errorMessage })
    }
  }
}

const lostPos = (xValidationOnly = 0) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: PosTypes.LOST_POS_REQUEST })

    const preparedPosLost = getState().pos.prepareLost ?? {}
    const lostPosUuids = { posUuids: preparedPosLost?.pos?.map(p => p.uuid) ?? [] } as PosLostDeclarationCreateDto
    const selectedBankAccount = getState().bankAccount.data[getState().bankAccount.list.selectedId as string]

    try {
      const response = await NeobankApi.getInstance().posApi.declarePosLost(
        selectedBankAccount?.uuid,
        xValidationOnly,
        lostPosUuids,
      )

      await dispatch(prepareLost({
        ...preparedPosLost,
        response: response.data,
      }))

      dispatch({ type: PosTypes.LOST_POS_SUCCESS })
    } catch (error) {
      dispatch({
        type: PosTypes.LOST_POS_FAILURE,
        errorMessage: error.message,
      })
      throw error
    }
  }
}

const checkLostPos = () => {
  return lostPos(1)
}

const resetPrepareLost = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PosTypes.PREPARE_POS_LOST_RESET })
  }
}

export const PosActions = {
  ...posAction,
  list,
  activatePos,
  updatePos,
  prepare,
  selectPos,
  orderPos,
  getOrderFees,
  prepareReturn,
  resetPrepareReturn,
  listFailureReasons,
  getTelecollections,
  returnPos,
  getReturnFees,
  listDispatches,
  getDispatchLabel,
  cancelDispatch,
  listReturns,
  refundDeposit,
  prepareLost,
  lostPos,
  checkLostPos,
  resetPrepareLost,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export interface PreparePOSOrder {
  workflowSubscriptionUuid?: string
  posProducts?: ProductDto[]
  deliveryAddress?: AddressDto
  deliveryProduct?: ProductDto
  missingBalance?: number
  associatedBankAccount?: string
  newPosIds?: string[]
}

export interface PreparePOSReturn {
  pos?: PosDto[]
  deliveryAddress?: AddressDto
  returnReason?: PosReturnReasonDto
  failureReason?: {
    code?: string
    uuid?: string
  }
  missingBalance?: number
  hasTlc?: boolean
  response?: any
}

export interface PreparePOSLost {
  pos?: PosDto[]
  hasTlc?: boolean
  response?: any
}

export type PosState = Omit<ResourceState<posData>, 'create' | 'update' | 'prepare'>
  & { prepare?: PreparePOSOrder}
  & { prepareReturn?: PreparePOSReturn }
  & { posReturnReasons?: PosFailureReasonDto[] }
  & { dispatches?: PosDispatchDto[] }
  & { returns?: PosReturnDetailDto[] }
  & { prepareLost?: PreparePOSLost }

const initialState: PosState = {
  ...initialResourceState,
  prepare: undefined,
  prepareReturn: undefined,
  posReturnReasons: undefined,
  dispatches: undefined,
  returns: undefined,
  prepareLost: undefined,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const pos: Reducer = (state = initialState, action: AnyAction): PosState => {
  switch (action.type) {
    case PosTypes.SELECT_POS_REQUEST:
    case PosTypes.PREPARE_POS_REQUEST:
    case PosTypes.ORDER_POS_REQUEST:
    case PosTypes.ACTIVATE_POS_REQUEST:
    case PosTypes.UPDATE_POS_REQUEST:
    case PosTypes.PREPARE_POS_RETURN_REQUEST:
    case PosTypes.LIST_POS_RETURN_REASONS_REQUEST:
    case PosTypes.GET_TELECOLLECTIONS_REQUEST:
    case PosTypes.RETURN_POS_REQUEST:
    case PosTypes.LIST_DISPATCHES_REQUEST:
    case PosTypes.GET_DISPATCH_LABEL_REQUEST:
    case PosTypes.CANCEL_DISPATCH_REQUEST:
    case PosTypes.LIST_RETURNS_REQUEST:
    case PosTypes.REFUND_DEPOSIT_REQUEST:
    case PosTypes.PREPARE_POS_LOST_REQUEST:
    case PosTypes.LOST_POS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case PosTypes.SELECT_POS_SUCCESS:
    case PosTypes.SELECT_POS_FAILURE:
    case PosTypes.PREPARE_POS_SUCCESS:
    case PosTypes.PREPARE_POS_FAILURE:
    case PosTypes.ORDER_POS_SUCCESS:
    case PosTypes.ORDER_POS_FAILURE:
    case PosTypes.ACTIVATE_POS_SUCCESS:
    case PosTypes.ACTIVATE_POS_FAILURE:
    case PosTypes.UPDATE_POS_FAILURE:
    case PosTypes.PREPARE_POS_RETURN_FAILURE:
    case PosTypes.LIST_POS_RETURN_REASONS_FAILURE:
    case PosTypes.GET_TELECOLLECTIONS_FAILURE:
    case PosTypes.RETURN_POS_FAILURE:
    case PosTypes.LIST_DISPATCHES_FAILURE:
    case PosTypes.GET_DISPATCH_LABEL_SUCCESS:
    case PosTypes.GET_DISPATCH_LABEL_FAILURE:
    case PosTypes.CANCEL_DISPATCH_SUCCESS:
    case PosTypes.CANCEL_DISPATCH_FAILURE:
    case PosTypes.LIST_RETURNS_FAILURE:
    case PosTypes.REFUND_DEPOSIT_SUCCESS:
    case PosTypes.REFUND_DEPOSIT_FAILURE:
    case PosTypes.PREPARE_POS_LOST_FAILURE:
    case PosTypes.LOST_POS_SUCCESS:
    case PosTypes.LOST_POS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case PosTypes.PREPARE:
      return {
        ...state,
        prepare: action.prepareOrder,
      }
    case PosTypes.UPDATE_POS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.pos.uuid]: {
            ...state.data[action.pos.uuid],
            ...(action.newAccountPosUuid ? { accountUuid: action.newAccountPosUuid } : {}),
            ...(action.newName ? { name: action.newName } : {}),
          },
        },
      }
    case PosTypes.PREPARE_POS_RETURN_SUCCESS:
      return {
        ...state,
        loading: false,
        prepareReturn: {
          ...action.prepareReturn,
        },
      }
    case PosTypes.PREPARE_POS_RETURN_RESET:
      return {
        ...state,
        prepareReturn: {
          ...initialState.prepareReturn,
        },
      }
    case PosTypes.GET_TELECOLLECTIONS_SUCCESS:
      return action.lostPos ? {
        ...state,
        loading: false,
        prepareLost: {
          ...state.prepareLost,
          hasTlc: action.hasTlc,
        },
      } : {
        ...state,
        loading: false,
        prepareReturn: {
          ...state.prepareReturn,
          hasTlc: action.hasTlc,
        },
      }
    case PosTypes.RETURN_POS_SUCCESS:
      return {
        ...state,
        loading: false,
        prepareReturn: {
          ...state.prepareReturn,
          response: action.data,
        },
      }
    case PosTypes.LIST_POS_RETURN_REASONS_SUCCESS:
      return {
        ...state,
        loading: false,
        posReturnReasons: action.data,
      }
    case PosTypes.LIST_RETURNS_SUCCESS:
      return {
        ...state,
        loading: false,
        returns: action.data,
      }
    case PosTypes.LIST_DISPATCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatches: action.data,
      }
    case PosTypes.PREPARE_POS_LOST_SUCCESS:
      return {
        ...state,
        loading: false,
        prepareLost: {
          ...action.prepareLost,
        },
      }
    case PosTypes.PREPARE_POS_LOST_RESET:
      return {
        ...state,
        prepareLost: {
          ...initialState.prepareLost,
        },
      }
    default:
      return {
        ...posResourceReducer(state, action, {
          identifier: 'uuid',
          isPaginate: false,
          initialState,
        }),
      }
  }
}
