import { ViewStyle, TextStyle, Platform } from 'react-native'

import * as Spacing from './Spacing'
import Colors from './Colors'
import * as Typo from './Typo'

// This file is for a reusable grouping of Theme items.
// Similar to an XML fragment layout in Android
export const mainContainer: ViewStyle = {
  flex: 1,
  backgroundColor: Colors.transparent,
}

export const border: ViewStyle = {
  borderWidth: 1,
  borderColor: Colors.coolGrey,
}

export const centeredContent: ViewStyle = {
  alignContent: 'center',
}

export const divider: ViewStyle = {
  backgroundColor: Colors.black,
  marginHorizontal: 30,
  marginBottom: 20,
}

export const basicCursor: ViewStyle = {
  // cursor is not available in ViewStyle in current react-native version
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cursor: 'auto',
}

export const shadow: ViewStyle = {
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.20,
  shadowRadius: 1.41,
  elevation: 2,
  shadowColor: Colors.black,
  ...Platform.select({
    ios: {
      overflow: 'visible',
    },
    web: {
      shadowRadius: 3,
    },
  }),
}

export const tab: ViewStyle = {
  flexDirection: 'row',
  flexWrap: 'wrap',
  paddingHorizontal: 16,
  alignContent: 'space-between',
  justifyContent: 'space-between',
}

export const tabContainer: ViewStyle = {
  flexDirection: 'row',
  marginTop: 24,
  marginHorizontal: 0,
}

export const dashboardIcons: TextStyle = {
  color: Colors.primaryLighter,
  textAlignVertical: 'center',
  textAlign: 'left',
  paddingVertical: 10,
  paddingLeft: 10,
}

export const dashboardBottomHeader: ViewStyle = {
  backgroundColor: Colors.secondaryDarker,
  flexDirection: 'row',
  paddingHorizontal: 8,
}

export const dashboardBottomHeaderTitle: TextStyle = {
  ...Typo.selectedTitle,
  color: Colors.ghostBlack,
  fontSize: 16,
  lineHeight: 20,
  textAlign: 'center',
  marginLeft: 15,
  paddingTop: 10,
  paddingBottom: 16,
  paddingHorizontal: 0,
  alignSelf: 'flex-start',
  marginBottom: 0,
}

export const dashboardSelectedBottomHeaderTitle: TextStyle = {
  ...Typo.selectedTitle,
  color: Colors.black,
  fontSize: 16,
  lineHeight: 20,
  textAlign: 'center',
  marginLeft: 15,
  paddingTop: 10,
  paddingBottom: 16,
  paddingHorizontal: 0,
  alignSelf: 'flex-start',
  marginBottom: 0,
}

export const dashboardBottomHeaderTitleUnderliner: ViewStyle = {
  backgroundColor: Colors.secondary,
  paddingBottom: 3,
  top: 1,
  borderRadius: 15,
  paddingHorizontal: 0,
  left: 8,
}

export const cardLoaderStyle: ViewStyle = {
  minHeight: 180,
  alignContent: 'center',
  paddingVertical: 45,
}

export const cardContainer: ViewStyle = {
  overflow: 'hidden',
  margin: 0,
  padding: 12,
  backgroundColor: Colors.white,
  borderRadius: 10,
  shadowColor: Colors.lightGray,
  shadowOpacity: 1, // IOS
  shadowRadius: 1, // IOS
  elevation: 4, // ANDROID
}

export const gutter: ViewStyle = {
  marginLeft: 16,
  marginRight: 16,
}

export const marginBelowTitle: ViewStyle = {
  marginTop: 22,
}

export const marginBelowHeader: ViewStyle = {
  marginTop: 22,
}

export const carouselContainer: ViewStyle = {
}
export const centeredWrapper: ViewStyle = {
  justifyContent: 'center', alignSelf: 'center',
}

export const positiv: TextStyle = {
  color: Colors.primary,
}

export const negativ: TextStyle = {
  color: Colors.secondary,
}

export const neutral: TextStyle = {
}

export const link: TextStyle = {
  color: Colors.lightGold,
  textDecorationLine: 'underline',
}

export const alertContainer: ViewStyle = {
  backgroundColor: '#FFF',
  borderWidth: 1.5,
  borderColor: Colors.primary,
  // opacity: 0.9,
  width: '90%',
  top: 0,
  borderRadius: 4,
  alignSelf: 'center',
}

export const iconContainer: ViewStyle = {
  backgroundColor: 'transparent',
}

export const container: ViewStyle = {
  flex: 1,
  paddingTop: Spacing.generic.baseMargin,
  backgroundColor: Colors.transparent,
}

export const groupContainer: ViewStyle = {
  margin: Spacing.generic.smallMargin,
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
}

export const section: ViewStyle = {
  margin: Spacing.generic.section,
  padding: Spacing.generic.baseMargin,
}

export const sectionHeaderTitle: TextStyle = {
  padding: 5,
  backgroundColor: 'transparent',
}

export const sectionTitle: ViewStyle = {
  marginVertical: 15,
}

export const title: TextStyle = {
  marginVertical: 15,
  width: 290,
  textAlign: 'center',
  marginHorizontal: 'auto',
  alignSelf: 'center',
}

export const bottomButtonList: ViewStyle = {
  flex: 1,
  justifyContent: 'flex-end',
  paddingHorizontal: 10,
  paddingBottom: 10,
  paddingTop: 16,
}

export const buttonListTitle: TextStyle = {
  color: Colors.black,
  fontWeight: 'bold',
}

export const buttonListSubtitle: TextStyle = {
  ...Typo.info,
  textAlign: 'center',
}
export const buttonListRightText: TextStyle = {
  ...Typo.content,
  textAlign: 'center',
  justifyContent: 'space-around',
  bottom: 2,
}

export const flexContainer: ViewStyle = {
  flex: 1,
}

export const button: ViewStyle = {
  borderRadius: Spacing.button.radius,
  shadowColor: Colors.lightGray,
  shadowOffset: {
    width: Spacing.ios.shadowOffsetWidth,
    height: Spacing.ios.clickableHeight,
  },
  shadowRadius: Spacing.ios.shadowRadius,
  shadowOpacity: Spacing.ios.clickableOpacity,
  elevation: Spacing.android.clickableElevation,
}

export const touchableContainer: ViewStyle = {
  ...button,
  margin: 5,
  borderRadius: Spacing.item.radius,
  shadowRadius: Spacing.item.radius,
  shadowOpacity: Spacing.ios.clickableOpacity,
  elevation: Spacing.android.clickableElevation,
}

export const notTouchableContainer: ViewStyle = {
  ...button,
  margin: 10,
  shadowOffset: {
    width: Spacing.ios.shadowOffsetWidth,
    height: Spacing.ios.unclickableHeight,
  },
  shadowRadius: Spacing.ios.unclickableRadius,
  shadowOpacity: Spacing.ios.unclickableOpacity,
  elevation: Spacing.android.unclickableElevation,
}

export const fullRrow: ViewStyle = {
  flex: 1,
  alignSelf: 'center',
}

export const row: ViewStyle = {
  flexDirection: 'row',
}
