import React, { ReactElement, ReactNode } from 'react'

import { Panel } from 'rsuite'

import { Button, Icon } from '@neo-commons/components'

import { CardDetails } from '@neo-web/componentsOld'
import { UseTextWithBoldWords } from '@neo-web/hooks'

import './CardStepper.scss'

export enum JustifyIcon {
  START = 'icon-start',
  CENTER = 'icon-center',
}

export interface CardStepperItem {
  icon: ReactElement<typeof Icon>,
  text: string,
  button?: ReactElement<typeof Button>
  collapsibleContent?: ReactNode,
}

interface CardStepperProps {
  list?: CardStepperItem[],
  timeline?: boolean,
  justifyIcon?: JustifyIcon,
  cardTitle?: string,
  noContainer?: boolean,
}

export const CardStepper: React.FC<CardStepperProps> = (props) => {
  const cardContent = (
    <div className={`CardStepper ${props.timeline ? 'timeline' : ''}`}>
      {props?.list?.map((item, index) => {
        const content = (
          <>
            <div className='CardStepper_item_icon'>
              {item.icon}
            </div>
            <div className='CardStepper_item_text'>
              {UseTextWithBoldWords({ text: item.text })}
            </div>
          </>
        )
        return (
          <div
            key={index}
            className={`CardStepper_item ${props?.justifyIcon ?? JustifyIcon.CENTER}`}
          >
            {item?.collapsibleContent
              ? <Panel header={content} collapsible>{item?.collapsibleContent}</Panel>
              : content}
            {item?.button && <div className='CardStepper_item_button'>{item.button}</div>}
          </div>
        )
      })}
    </div>
  )

  return (
    props?.noContainer
      ? cardContent
      : <CardDetails title={props?.cardTitle}>{cardContent}</CardDetails>
  )
}
