import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { find } from 'lodash'
import { StateTypes } from '@neo-customer-front/applications/neo-web/src/Store'
import { Routes } from 'Routes/Routes'

import { BankAccountSelectors, DocumentActions } from '@neo-commons/store'

import { AccountDto } from '@afone/neo-core-client/dist/models'

import { DocumentViewer } from '@neo-web/componentsOld'

export const TransactionProof: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const param: any = useParams()

  const selectedBankAccount: AccountDto =
    useSelector(BankAccountSelectors.selected, (prev, next) => prev.uuid === next.uuid)
  const documents = useSelector((state: StateTypes) => state.document.data)
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>(selectedBankAccount?.uuid)

  const transactionProof = useMemo(() => {
    return find(documents, document => document.uuid === param.id)
  }, [documents])

  useEffect(() => {
    (async () => {
      try {
        if (!selectedBankAccountId || selectedBankAccountId === selectedBankAccount?.uuid) {
          await dispatch(DocumentActions.getTransactionProofById(param.id))
          setSelectedBankAccountId(selectedBankAccount?.uuid)
        } else {
          history.push(Routes.Synthesis.transactions.path)
        }
      } catch (e) {}
    })()
  }, [selectedBankAccount, param.id])

  return (
    <DocumentViewer
      title={t('app:pages:synthesis:transaction:detail:yourProof')}
      base64={transactionProof?.fileEncoded}
      filename={transactionProof?.name}
    />
  )
}
