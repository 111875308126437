import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonSkin, Icon, IconTypes, Input } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Modal, ModalProps } from '@neo-web/componentsOld'

import './PromoCodeModal.scss'
import { AppConfig } from '../../../Config/AppConfig'

interface PromoCodeModalProps extends ModalProps {
    inputLabel: string
    show: boolean
    onValidate: (string) => void
    onClose: () => void
}
export const PromoCodeModal: React.FC<PromoCodeModalProps> = (props: PromoCodeModalProps) => {
  const { t } = useTranslation()
  const [promoCodeValue, setPromoCodeValue] = useState('')
  return (
    <Modal
      className='PromoCodeModal'
      title={
        <div className='PromoCodeModal_title'>
          <Icon
            name='loyalty'
            type={IconTypes.MATERIAL}
            size={35}
            color={Colors.secondary}
          /><br />
          {props.title}
        </div>
      }
      autoFocus
      open={props.show}
      onClose={() => {
        props.onClose()
        setPromoCodeValue('')
      }}
      size='xs'
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:validate')}
          disabled={
            promoCodeValue.length < 1
          }
          onPress={() => {
            props.onValidate(promoCodeValue)
          }}
        />
      }
    >
      <Input
        editable
        hasClear
        label={props.inputLabel}
        onChangeText={text => {
          setPromoCodeValue(text)
        }}
        value={promoCodeValue}
        onSubmitEditing={() => AppConfig.PASS_CODE_SIZE === promoCodeValue.length && props.onValidate(promoCodeValue)}
        isRequired
        autoFocus
      />
    </Modal>
  )
}
