import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'
import { Routes } from 'Routes/Routes'

import { BusinessSubscriptionStep, BusinessSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import { ClientSelectors, Dispatch, Subscription, SubscriptionActions, SubscriptionSelectors } from '@neo-commons/store'

import { ErrorCodeDto } from '@afone/neo-core-client/dist/models'

import './ElectronicSignature.scss'
import { Loader } from '@neo-web/componentsOld'

type ElectronicSignatureProps =
  StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.ElectronicSignature]>

export const ElectronicSignature: React.FC<ElectronicSignatureProps> = () => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()
  const signatureLink: string = useSelector((state: StateTypes) => state.subscription?.signatureLink)

  const client = useSelector(ClientSelectors.defaultOne)
  const subscription: Subscription = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)

  const getSignatureLink = async () => {
    try {
      await dispatch(SubscriptionActions.getSignatureLink({
        subscriptionUuid: subscription.uuid,
        personUuid: client.holder?.uuid,
      }))
    } catch (e) {
      try {
        await dispatch(SubscriptionActions.list())
        if (e.code === ErrorCodeDto.C0707) {
          history.push(Routes.Subscription.electronicSignatureDocumentsChoice.path)
        } else if (e.code === ErrorCodeDto.C0512) {
          history.push(Routes.Subscription.subscriptionError.path, { errorMessage: e.message })
        } else {
          history.push(Routes.Subscription.subscriptionError.path)
        }
      } catch (e) {}
    }
  }

  useEffect(() => {
    if (signatureLink) {
      window.sessionStorage.setItem('source', 'web')
      window.location.href = signatureLink
      AnalyticsHandler.track(
        AnalyticsEvents.KYC.eSignatureStarted,
        { account_type: 'B2B', contract_slug: 'account_cgu' }
      )
    } else {
      getSignatureLink()
    }
  }, [signatureLink])

  return (
    <div className='ElectronicSignature_container'>
      <Loader isShow loop />
    </div>
  )
}
