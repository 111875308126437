import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { displayAlertGlobal$, loading$ } from '../Epics'

import { ScaTypes } from './index'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    ScaTypes.DEVICE_METHOD_REQUEST,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    ScaTypes.DEVICE_METHOD_FAILURE,
    ScaTypes.VALIDATE_OTP_FAILURE,
    ScaTypes.VALIDATE_OTP_SUCCESS,
    ScaTypes.DEVICE_METHOD_SUCCESS,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
]
