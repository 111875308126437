
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { SelectPicker } from 'rsuite'

import { UserActions } from '@neo-commons/store'

import { PatrimonyDto, UserDto } from '@afone/neo-core-client/dist/models'

import { useResponsive } from '@neo-web/hooks'

import './MyInfoFields.scss'

export interface MyHeritageContentProps {
  user: UserDto
  onCloseModal: () => void
}

export const MyHeritageContent: React.FC<MyHeritageContentProps> = ({
  user,
  onCloseModal,
}: MyHeritageContentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTabletOrMobile = useResponsive()

  const [heritage, setHeritage] = useState<PatrimonyDto>(user?.person?.patrimony)

  const updateClientHeritage = (heritage: PatrimonyDto) => {
    (async () => {
      try {
        await dispatch(UserActions.updateUserPerson(user, {
          ...user?.person,
          patrimony: heritage,
        }))
        onCloseModal()
      } catch (error) {}
    })()
  }

  return (
    <div className='fieldContainer'>
      <SelectPicker
        data={Object.entries(PatrimonyDto).map(
          (value) =>
            ({
              value: value[1],
              label: t('app:global:enum:heritage:' + value[0].replace(/(_)/g, '').toLowerCase()),
            }),
        )}
        onChange={value => {
          setHeritage(value)
          updateClientHeritage(value)
        }}
        appearance='subtle'
        defaultValue={heritage}
        cleanable={false}
        searchable={false}
        placement={isTabletOrMobile ? 'topStart' : 'bottomStart'}
      />
    </div>
  )
}
