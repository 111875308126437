import { BankAccountRoutes } from './BankAccountRoutes'
import { RegisterRoutes } from './RegisterRoutes'
import { SignInRoutes, RecoverPasswordRoutes, RecoverPasswordAlertRoutes } from './SignIn'
import { ExternalRoutes } from './ExternalRoutes'
import { CreditRoutes } from './CreditRoutes'
import { SynthesisRoutes, TransactionRefundRoutes } from './Synthesis'
import { PublicLinkRoutes } from './PublicLinkRoutes'
import { BusinessRoutes, IndividualRoutes, SeaCguRoutes, SubscriptionRoutes } from './Subscription'
import { PaymentsRoutes } from './PaymentsRoutes'
import { ProfileRoutes, ClosingAccountRoutes, SecretQuestionRoutes, ResetPasscodeRoutes } from './Profile'
import {
  ServicesRoutes,
  PosOrderRoutes,
  ProjectsRoutes,
  CloseProjectRoutes,
  PosReturnRoutes,
  PosLostRoutes,
} from './Services'
import { ErrorRoutes } from './ErrorRoutes'
import { OppositionRoutes } from './OppositionRoutes'
import { DocumentUploadRoutes } from './DocumentUploadRoutes'
import { SvaRegisterRoutes } from './SvaRegisterRoutes'
import { AdvanceEligibilityRoutes } from './AdvanceEligibilityRoutes'

export type RoutesInterface = {
  path: string;
  name: string;
  slug?: string;
  available: boolean;
}
export const Routes = {
  External: ExternalRoutes,
  PublicLink: PublicLinkRoutes,
  SvaRegisterRoutes: SvaRegisterRoutes,
  Register: RegisterRoutes,
  DocumentUpload: DocumentUploadRoutes,
  SignIn: SignInRoutes,
  RecoverPassword: RecoverPasswordRoutes,
  RecoverPasswordAlert: RecoverPasswordAlertRoutes,
  Credit: CreditRoutes,
  Synthesis: {
    ...SynthesisRoutes,
    TransactionRefund: TransactionRefundRoutes,
  },
  Payments: PaymentsRoutes,
  BankAccount: BankAccountRoutes,
  AdvanceEligibility: AdvanceEligibilityRoutes,
  Subscription: {
    ...SubscriptionRoutes,
    Individual: IndividualRoutes,
    Business: BusinessRoutes,
    SeaCgu: SeaCguRoutes,
  },
  Opposition: OppositionRoutes,
  Services: {
    ...ServicesRoutes,
    PosOrderRoutes: PosOrderRoutes,
    PosReturnRoutes: PosReturnRoutes,
    PosLostRoutes: PosLostRoutes,
    ProjectsRoutes: ProjectsRoutes,
    CloseProjectRoutes: CloseProjectRoutes,
  },
  Profile: {
    ...ProfileRoutes,
    ClosingAccount: ClosingAccountRoutes,
    SecretQuestion: SecretQuestionRoutes,
    ResetPasscode: ResetPasscodeRoutes,
  },
  Maintenance: {
    path: '/maintenance',
    name: 'app:pages:maintenance:title',
    available: true,
  },
  Origin: {
    path: '/',
    name: 'origin',
    available: true,
  },
  Error: ErrorRoutes,
}
