import React, { ComponentProps, FunctionComponent } from 'react'
import { View, TextStyle, ViewStyle, ImageStyle } from 'react-native'
import { useTranslation } from 'react-i18next'

import { CustomerServiceCountryCard } from '../CustomerServiceCountryCard/CustomerServiceCountryCard'

export interface CustomerServiceCountryCardListProps {
  countries?: ComponentProps<typeof CustomerServiceCountryCard>[]
  containerStyle?: ViewStyle
  itemContainerStyle?: ViewStyle
  itemRightStyle?: ViewStyle
  itemLeftStyle?: ImageStyle
  itemLabelContainerStyle?: ViewStyle
  itemTitleStyle?: TextStyle
  itemSubtitleStyle?: TextStyle
  callMethod?: (phoneNumber: string) => void
}

export const CustomerServiceCountryCardList: FunctionComponent<CustomerServiceCountryCardListProps> = (props) => {
  const { t } = useTranslation()
  const defaultOnPressCard = (phoneNumber: string | undefined) => {
    if (!phoneNumber || !props.callMethod) {
      return
    }
    props.callMethod(phoneNumber)
  }

  const defaultCountries: CustomerServiceCountryCardListProps['countries'] = [
    {
      countryCode: 'FRA',
      title: t('neo-commons:welcome:customerService:fr'),
      subtitle: t('neo-commons:welcome:customerService:frPhone'),
      phoneNumber: '+33172680970',
    },
    {
      countryCode: 'ESP',
      title: t('neo-commons:welcome:customerService:es'),
      subtitle: t('neo-commons:welcome:customerService:esPhone'),
      phoneNumber: '+34911980020',
    },
    {
      countryCode: 'DEU',
      title: t('neo-commons:welcome:customerService:de'),
      subtitle: t('neo-commons:welcome:customerService:dePhone'),
      phoneNumber: '+493030806950',
    },
    {
      countryCode: 'ITA',
      title: t('neo-commons:welcome:customerService:it'),
      subtitle: t('neo-commons:welcome:customerService:itPhone'),
      phoneNumber: '+390240708480',
    },
    {
      countryCode: 'GBR',
      title: t('neo-commons:welcome:customerService:uk'),
      subtitle: t('neo-commons:welcome:customerService:ukPhone'),
      phoneNumber: '+44172680970',
    },
  ]

  const countries: CustomerServiceCountryCardListProps['countries'] = props.countries ?? defaultCountries

  return (
    <View style={props.containerStyle}>
      {countries && countries.map((country) =>
        <CustomerServiceCountryCard
          key={country.countryCode}
          countryCode={country.countryCode}
          title={country.title}
          subtitle={country.subtitle}
          onPress={country.onPress ?? (() => { defaultOnPressCard(country.phoneNumber) })}
          containerStyle={props.itemContainerStyle}
          rightStyle={props.itemRightStyle}
          leftStyle={props.itemLeftStyle}
          labelContainerStyle={props.itemLabelContainerStyle}
          titleStyle={props.itemTitleStyle}
          subtitleStyle={props.itemSubtitleStyle}
        />)}

    </View>
  )
}
