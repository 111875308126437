import { CountryCode, NumberType, parsePhoneNumber } from 'libphonenumber-js/max'
import { AsYouType, isValidNumberForRegion } from 'libphonenumber-js'

const acceptedPhoneNumberTypes: NumberType[] = ['MOBILE', 'FIXED_LINE_OR_MOBILE']

export class PhoneNumberUtils {
  /**
   * Verify if a phone number is allowed for app base on country code.
   *
   * @param {string} phoneNumber - Phone number to verify.
   * @param {CountryCode} countryPrefix - Country code of phone number.
   * @returns {boolean} Return if phone number is allowed for app.
   * @example
   * PhoneNumberUtils.isAllowForApp('0611111111', 'FR')
   *
   */
    public static isAllowForApp = (phoneNumber: string, countryPrefix: CountryCode): boolean => {
      if (countryPrefix && phoneNumber && isValidNumberForRegion(phoneNumber, countryPrefix)) {
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber, countryPrefix)
        return (parsedPhoneNumber?.isValid() && acceptedPhoneNumberTypes.includes(parsedPhoneNumber?.getType()))
      }
      return false
    }

    /**
     * Format a phone number based on its country code
     * @param {string} phoneNumber - Phone number to format
     * @param {CountryCode} countryPrefix - Country code of phone number
     * @returns {string} Return formated phone number
     */
    public static phoneFormatter = (phoneNumber: string, countryPrefix: CountryCode): string =>
      new AsYouType(countryPrefix).input(phoneNumber ?? '')
}
