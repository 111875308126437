import React, { ReactElement, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'

import { debounce } from 'lodash'

import { Icon, IconTypes, SearchBar, Typography, Typography2 } from '@neo-commons/components'
import { PaginateItem } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { useResponsive } from '@neo-web/hooks'
import { LazyLoadingContainer } from '@neo-web/componentsOld'

import { AppConfig } from '../../../Config/AppConfig'

import './PaginationList.scss'

interface PaginationListProps<ItemType> {
  next: () => void,
  state: any,
  stateList: ItemType[],
  refreshFunction: () => void,
  items: (childrenItem) => void,
  groupData?: PaginateItem<any>[],
  eventLoading?: string | Array<string>,
  search?: boolean,
  onFilter?: (text: string) => void,
  navBar?: ReactElement, // have to keep navbar because used in /src/UI/Pages/Payments/Pay/Contact/List.tsx
  emptyText?: string
}

export const PaginationList = <ItemType extends any>(props: PaginationListProps<ItemType>) => {
  const { t } = useTranslation()
  const isTabletOrMobile = useResponsive()

  const [value, setValue] = useState(props.search ? props.state?.list?.filter?.text : '')
  const debouncedFunction = React.useCallback(debounce(
    props.onFilter ? props.onFilter : () => null, AppConfig.SEARCH_DEBOUNCE), [props.onFilter])

  const eventLoading = typeof props.eventLoading === 'string' ? [props.eventLoading] : props.eventLoading
  const paginationEnded = props.state?.list?.paginationEnded
  const length = props.stateList.length

  return (
    <div className='PaginationList'>
      <div className='PaginationList_header'>
        {props.search &&
          <div className='PaginationList_header_search'>
            <SearchBar
              items={props.stateList}
              value={value}
              filter={(text) => {
                setValue(text)
                debouncedFunction(text)
              }}
            />
          </div>}
        {/* have to keep navbar because used in src/UI/Pages/Payments/Pay/Contact/List.tsx */}
        {props.navBar}
      </div>

      <div className='PaginationList_container'>
        <InfiniteScroll
          dataLength={length}
          next={!paginationEnded && props.next}
          hasMore
          className='PaginationList_container'
          loader={(!paginationEnded && props.eventLoading) &&
            <LazyLoadingContainer events={eventLoading} />}
          endMessage={
            <div className='PaginationList_item'>
              <Typography typeface='content'>
                {t('app:global:paginate:list:end')}
              </Typography>
            </div>
          }
          refreshFunction={props.refreshFunction}
          pullDownToRefresh={isTabletOrMobile}
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <div className='PaginationList_item'>
              <Icon name='refresh' type={IconTypes.MATERIAL} size={22} color={Colors.ghostBlack} />
            </div>
          }
          releaseToRefreshContent={
            <div className='PaginationList_item'>
              <Icon name='refresh' type={IconTypes.MATERIAL} size={22} color={Colors.black} />
            </div>
          }
        >
          <>
            {length < 1 &&
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                {props?.emptyText ?? t('app:components:paginationList:noResults')}
              </div>}

            {props.groupData && props.groupData.map((item, index) => (
              <div key={index}>
                <div className='PaginationList_groupLetter'>
                  <Typography2 typeface='overline'>
                    {item.title}
                  </Typography2>
                </div>
                {item.data.map((childrenItem) => {
                  return (
                    props.items(childrenItem)
                  )
                })}
              </div>
            ))}

            {!props.groupData && props.stateList.map((item) => {
              return (
                props.items(item)
              )
            })}
          </>
        </InfiniteScroll>
      </div>
    </div>
  )
}
