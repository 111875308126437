import { map } from 'lodash'

import { registerPolicy } from '@neo-commons/policies'

export const RegisterRoutes = {
  index: {
    path: registerPolicy.basePath,
    name: 'app:pages:register:step1:phone.titleMessage',
    available: true,
  },
  ...map(registerPolicy.steps, ({ path, meta }) => ({
    path,
    name: meta.screenTitle,
    available: true,
  })),
}
