import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { RootActions, StateTypes } from 'Store'
import { config, ENV } from 'Config/EnvConfig'

import { ClientUtils, NumbersUtils, OfferUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'
import {
  AlertType,
  ClientSelectors,
  ConfigSelectors,
  OfferActions,
  OfferSelectors,
  State,
  Subscription,
  SubscriptionSelectors,
} from '@neo-commons/store'
import {
  Button,
  ButtonSkin,
  Card,
  CardSkin,
  CheckboxInput,
  Icon,
  IconTypes,
  Stepper2,
  Typography,
} from '@neo-commons/components'
import { NeobankApi } from '@neo-commons/services'

import {
  AggregationConnectorDto,
  AggregationSubscriptionDto,
  ClientDto,
} from '@afone/neo-core-client/dist/models'

import { AggregationLogo, JustifyContent, Loader, PageContent, PageDetails } from '@neo-web/componentsOld'

import './ActivateAggregateAccounts.scss'

export const ActivateAggregateAccounts:React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const selectedConnector: AggregationConnectorDto = useSelector((state: State) => state.bankAccount.selectedConnector)
  const dispatch = useDispatch()

  const [CGV, setCGV] = useState(false)
  const [mockSubscription, setMockSubscription] = useState<AggregationSubscriptionDto>()

  const offerState = useSelector((state: StateTypes) => state.offer)
  const currentConfig = useSelector(ConfigSelectors.getCurrentConfig)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const mainSubscription: Subscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  const socleOffer = useSelector(OfferSelectors.getByUuid(mainSubscription?.offerUuid ?? mainSubscription?.offer?.uuid))
  const redirectUrl = `${config(ENV.COMNPAY_ORIGIN)}services/aggregation/fallback`

  useEffect(() => {
    NeobankApi.getInstance().aggregationApi
      .connectAggregation(1, { uri: redirectUrl })
      .then(res => setMockSubscription(res?.data?.subscription))
      .catch((e) => {
        dispatch(RootActions.alert({
          title: e.message,
          type: AlertType.ERROR,
        }))
        history.push(Routes.Services.aggregation.path)
      })
    if (!offerState.loading) {
      dispatch(OfferActions.listForClientUuid())
    }
  }, [])

  const checkboxInputText =
    <Typography typeface='body1'>
      {`${t('app:pages:services:aggregation:activate:accept')} `}
      <a
        href={ClientUtils.isClientTypeIndividual(client?.type)
          ? currentConfig?.cgusAggregate?.individual
          : currentConfig?.cgusAggregate?.corporate}
        target='_blank' className='ActivateAggregateAccounts_checkbox_text' rel='noreferrer'
      >
        {t('app:pages:services:aggregation:activate:CGU')}
      </a>
    </Typography>

  return (
    <PageDetails
      onBack={() => history.goBack()}
      onClose={() => history.push(Routes.Services.aggregation.path)}
      title={t('app:pages:services:aggregation:menu')}
      component={
        <PageContent
          title={t('app:pages:services:aggregation:activate:title')}
          justifyContent={JustifyContent.START}
          footer={(
            <div className='ActivateAggregateAccounts_checkbox'>
              {!OfferUtils.isOfferAdvance(socleOffer) && (
                <CheckboxInput
                  size={24}
                  isSelected={CGV}
                  text={checkboxInputText}
                  onPress={(value) => setCGV(value)}
                />)}
              <Button
                skin={ButtonSkin.PRIMARY}
                title={t('app:global:confirm')}
                disabled={!OfferUtils.isOfferAdvance(socleOffer) ? (!CGV || !mockSubscription) : false}
                onPress={() => {
                  NeobankApi.getInstance().aggregationApi
                    .connectAggregation(0,
                      { uri: redirectUrl, connectorId: selectedConnector?.connectorId })
                    .then(res => { location.href = res?.data?.uri })
                    .catch((e) => {
                      dispatch(RootActions.alert({ title: e.message, type: AlertType.ERROR }))
                      history.push(Routes.Services.aggregation.path)
                    })
                }}
              />

            </div>
          )}
        >
          <Stepper2 currentStep={1} />
          <Loader isShow={!mockSubscription} />
          {!OfferUtils.isOfferAdvance(socleOffer) && (
            <>
              <Card
                skin={CardSkin.BACKGROUND_BORDERED}
              >
                <Typography style={{ marginBottom: '10px', textAlign: 'center' }} typeface='overline'>
                  {t('app:global:products')}
                </Typography>
                <div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
                  <div style={{ height: 55, width: 55, backgroundColor: '#F5EDDB', borderRadius: 5, position: 'relative' }}>
                    <Icon
                      iconStyle={{
                        margin: 0,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                      type={IconTypes.NEOFONT}
                      name='Icon-service'
                      color={Colors.secondary}
                      size={40}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 8, flex: 1 }}>
                    <Typography typeface='h5'>{t('app:pages:services:aggregation:activate:externalAccount')}</Typography>
                    <div
                      className='ActivateAggregateAccounts_card_split_children'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <Typography typeface='body1'>
                        {t('app:pages:services:aggregation:activate:priceOfService')}
                      </Typography>
                      <Typography typeface='h6'>
                        {mockSubscription?.price || NumbersUtils.displayPriceForHuman(0)}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Card>
              <Card skin={CardSkin.BACKGROUND_BORDERED}>
                <Typography
                  style={{ textAlign: 'justify', marginVertical: 8, marginHorizontal: 6, lineHeight: 18 }}
                  typeface='body1'
                >
                  {t('app:pages:services:aggregation:activate:information')}
                </Typography>
              </Card>
            </>
          )}
          <Card skin={CardSkin.BACKGROUND_BORDERED}>
            <Typography
              style={{ textAlign: 'center', marginBottom: '18px', marginTop: '6px', textTransform: 'uppercase' }}
              typeface='overline'
            >
              {t('app:pages:services:aggregation:aggregatedAccount')}
            </Typography>
            <div className='ActivateAggregateAccounts_card_split_children'>
              <div className='ActivateAggregateAccounts_card_split_children_item'>
                <AggregationLogo bank={selectedConnector} size={48} />
              </div>
              {Array.from({ length: 4 }, (_, i) => (
                <div key={i} className='ActivateAggregateAccounts_card_split_children_item_bordered'>
                  <Icon
                    name='bank'
                    color={Colors.darkGray}
                    type={IconTypes.MATERIAL_COMMUNITY}
                    size={24}
                  />
                </div>
              ))}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 18 }}>
              <Typography typeface='body1'>
                {t('app:pages:services:aggregation:aggregeableBanksAndAccounts')}
              </Typography>
              <Typography typeface='body1' style={{ fontWeight: 'bold' }}>
                {t('app:global:unlimited')}
              </Typography>
            </div>
          </Card>
        </PageContent>
      }
    />
  )
}
