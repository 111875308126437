import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { FlexboxGrid } from 'rsuite'
import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { NotificationPreferencesActions, NotificationPreferencesTypes } from '@neo-commons/store'
import { Typography } from '@neo-commons/components'

import { PageDetails, LazyLoadingContainer } from '@neo-web/componentsOld'

import { PreferenceCardList } from '../PreferenceCard/PreferenceCardList'

import './PreferenceNotification.scss'

export const PreferenceNotification: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const notificationPreferences = useSelector((state: StateTypes) => state?.notificationPreferences?.data ?? [])

  useEffect(() => {
    dispatch(NotificationPreferencesActions.getNotificationPreferences())
  }, [])

  return (
    <PageDetails
      component={
        <FlexboxGrid className='PreferenceNotification_container' justify='center' align='middle'>
          <FlexboxGrid.Item className='PreferenceNotification_item PreferenceNotification_subtext' colspan={24}>
            <Typography typeface='bold'>
              {t('app:pages:myProfile:preferenceAndSecurity:notification:general')}
            </Typography>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item className='PreferenceNotification_item' colspan={24}>
            <LazyLoadingContainer
              className='PreferenceNotification_item_container'
              events={[NotificationPreferencesTypes.GET_NOTIFICATION_PREFERENCES_REQUEST]}
            >
              {notificationPreferences.map(item =>
                <PreferenceCardList
                  key={item.uuid + item.title}
                  title={item.title}
                  choices={item.channels}
                />

              )}
            </LazyLoadingContainer>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      }
      onBack={() => history.push(Routes.Profile.preference.path)}
      title={t('app:pages:myProfile:preferenceAndSecurity:alert:title')}
    />
  )
}
