import { BankAccountActions } from '@neo-commons/store'

import { createWizardPolicy, invokeActionCreator } from '../utils'

const machineId = 'closeProject'

/*
 * Steps
 */

export enum CloseProjectStep {
  Transfer = 'Transfer',
  Summary = 'Summary',
  Success = 'Success',
}

export const closeProjectSteps = {
  [CloseProjectStep.Transfer]: {
    path: 'transfer',
    nextStep: CloseProjectStep.Summary,
    meta: {
      headerTitle: 'neo-commons:project:close',
      canGoBack: true,
    },
  },
  [CloseProjectStep.Summary]: {
    path: 'summary',
    nextStep: CloseProjectStep.Success,
    fulfill: invokeActionCreator(BankAccountActions.closeBankAccount),
    meta: {
      headerTitle: 'neo-commons:project:summary',
      canGoBack: true,
    },
  },
  [CloseProjectStep.Success]: {
    path: 'success',
    nextStep: 'final',
    meta: {
      canGoBack: false,
    },
  },
}

export type CloseProjectSteps = typeof closeProjectSteps

/*
 * Policy initialization
 */
export const closeProjectPolicy = createWizardPolicy({
  machineId: machineId,
  steps: closeProjectSteps,
  initialStep: CloseProjectStep.Transfer,
  basePath: '/services/projects/close',
})
