import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Card, CardSkin, Typography2 } from '@neo-commons/components'

import { Banner } from '@neo-web/componentsOld'

import twogirlinasofa from '../../../Resources/Images/site/two_girl_in_a_sofa.png'

import '../AdvanceEligibility.scss'

export const AdvanceEligibilityError: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className='Eligibility_container'>
      <Card skin={CardSkin.DEFAULT} withShadow>
        <Banner>
          <img src={twogirlinasofa} alt='modern times' style={{ width: '100%', borderRadius: 12 }} />
        </Banner>
        <div className='Eligibility_description'>
          <div className='Eligibility_row'>
            <p style={{ fontSize: 56 }}>😕</p>
          </div>
          <div className='Eligibility_row'>
            <div className='Eligibility_col'>
              <Typography2 typeface='h3'>
                {t('neo-commons:eligibility:error:title')}
              </Typography2>
              <br /><br />
              <Typography2 typeface='h5' style={{ fontFamily: 'Quicksand-Medium' }}>
                {t('neo-commons:eligibility:error:genericMessage')}
              </Typography2>
            </div>
          </div>
          <div className='Eligibility_button'>
            <Button
              containerStyle={{ width: '48%' }}
              skin={ButtonSkin.OUTLINE_PRIMARY}
              title={t('neo-commons:eligibility:error:retry')}
              onPress={() => history.push(Routes.AdvanceEligibility.index.path)}
            />
            <Button
              flat
              containerStyle={{ width: '48%' }}
              skin={ButtonSkin.PRIMARY}
              title={t('neo-commons:eligibility:success:open')}
              onPress={() => history.push(Routes.Subscription.subscriptionChoice.path)}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}
