import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BankAccountSelectors, OfferActions, OfferSelectors, PosActions, PosSelectors } from '@neo-commons/store'
import { BankAccountUtils } from '@neo-commons/libraries'

import { AccountDto, OfferDto, OfferTypeDto, PosDto } from '@afone/neo-core-client/dist/models'

import { PosMainAccount } from './PosMainAccount'
import { PosSubAccount } from './PosSubAccount'

export const Pos: React.FC = () => {
  const dispatch = useDispatch()

  const pos: PosDto[] = useSelector(PosSelectors.listPos())
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts())
  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const isPosAccount = BankAccountUtils.isPos(selectedBankAccount)
  const offers: OfferDto[] = useSelector(OfferSelectors.listByType(OfferTypeDto.POS))

  useEffect(() => {
    (async () => {
      try {
        if (!pos.length) {
          await dispatch(PosActions.list(bankAccounts))
        }
        if (!offers.length) {
          await dispatch(OfferActions.listForClientUuid())
        }
      } catch (error) {}
    })()
  }, [])

  return isPosAccount ? <PosSubAccount /> : <PosMainAccount />
}
