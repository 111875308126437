import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { StepComponentProps } from '@neo-commons/policies'
import { TransactionRefundStep, TransactionRefundSteps } from '@neo-commons/policies/src/Transaction'
import { PrepareRefund, TransactionActions, TransactionSelectors } from '@neo-commons/store'

import { TransactionDto } from '@afone/neo-core-client/dist/models'

import { FailureContent } from '@neo-web/componentsOld'

type TransactionRefundFailureProps = StepComponentProps<TransactionRefundSteps[TransactionRefundStep.Failure]>

export const Failure: React.FC<TransactionRefundFailureProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const preparedRefund: PrepareRefund = useSelector(TransactionSelectors.prepareRefund)

  const goBackToTransactionDetails = () => {
    if (preparedRefund?.transaction) {
      history.push(generatePath(
        Routes.Synthesis.transactionDetails.path,
        { id: (preparedRefund?.transaction as TransactionDto).id }
      ))
    } else {
      history.push(Routes.Synthesis.transactions.path)
    }
  }

  return (
    <FailureContent
      title={t('app:global:failure')}
      description=''
      buttonBack={{
        action: () => {
          (async () => {
            await dispatch(TransactionActions.refundReset())
            history.push(Routes.Synthesis.transactions.path)
          })()
        },
      }}
      buttonAction={{ action: () => goBackToTransactionDetails() }}
    />
  )
}
