import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { RootActions } from 'Store'

import {
  Button,
  ButtonSkin,
  PaymentCards,
  Typography,
  Icon,
  IconTypes, CreditCardDisplay,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { AlertType, CardSelectors, CardTypes, CreditCardData } from '@neo-commons/store'

import { useDecryptData, useResponsive } from '@neo-web/hooks'
import { LazyLoadingContainer } from '@neo-web/componentsOld'

import './ShowDebitCardInfo.scss'

interface ShowDebitCardInfoProps {
  card: CreditCardData
}

export const ShowDebitCardInfo: React.FC<ShowDebitCardInfoProps> = (props: ShowDebitCardInfoProps) => {
  const { card } = props
  const { t } = useTranslation()
  const [scaHasBeenCalledOnce, setScaHasBeenCalledOnce] = useState(false)
  const [showSca, setShowSca] = useState(false)
  const [hideNumbers, setHideNumbers] = useState(true)
  const isMobileOrTablet = useResponsive()

  const onEncryptDone = (error?: string) => {
    if (error) {
      setShowSca(false)
      return
    }
    setHideNumbers(!hideNumbers)
    setScaHasBeenCalledOnce(true)
  }
  const sensitiveCardData = useDecryptData(showSca, onEncryptDone)
  const cardState = useSelector(CardSelectors.selected)
  const dispatch = useDispatch()

  const copyToClipboard = (value: string) => {
    try {
      (async () => {
        if (!value || hideNumbers) return

        await navigator?.clipboard?.writeText(value)
        await dispatch(RootActions.alert({
          title: t('app:global:copied'),
          type: AlertType.SUCCESS,
        }))
      })()
    } catch (error) {}
  }

  const handleHideNumber = () => {
    if (!card?.encryptedData || !scaHasBeenCalledOnce) {
      setShowSca(true)
    } else {
      setHideNumbers(!hideNumbers)
    }
  }

  return (
    <LazyLoadingContainer events={[CardTypes.GET_ENCRYPTED_CARD_DATA_REQUEST]}>
      <div>
        <div className='ShowDebitCardInfo_container'>
          <Typography typeface='info'>
            {(t('app:pages:debitCard:services:cardInfos:card') + ' ' +
              (card.plastic ? '' : t('app:pages:services:debitCard:digital') + ' ') +
              card.offerName).toLocaleUpperCase()}
          </Typography>
          <div className='ShowDebitCardInfo_cards'>
            <div className='ShowDebitCardInfo_cards-infos'>
              <CreditCardDisplay
                hideNumbers={hideNumbers}
                data={{
                  owner: cardState?.formatedEmbossedData?.firstLine,
                  pan: {
                    numbers: sensitiveCardData?.pan,
                    onCopyPanPress: (pan) => copyToClipboard(pan),
                    hideCopyButton: hideNumbers,
                  },
                  expirationDate: sensitiveCardData?.expirationDate,
                  cvv: sensitiveCardData?.cvv,
                }}
              />
              <div className='ShowDebitCardInfo_cards-infos-button'>
                <Button
                  title={hideNumbers
                    ? t('app:pages:debitCard:services:showNumbers')
                    : t('app:pages:debitCard:services:hideNumbers')}
                  skin={ButtonSkin.DEFAULT_LIGHT}
                  onPress={handleHideNumber}
                  rightIcon={
                    <Icon
                      type={IconTypes.FEATHER}
                      name={hideNumbers ? 'eye' : 'eye-off'}
                      size={20}
                      color={Colors.primary}
                    />
                  }
                />
              </div>
            </div>
            {!isMobileOrTablet &&
              <div className='ShowDebitCardInfo_cards-image'>
                <PaymentCards
                  card={card}
                />
              </div>}
          </div>
        </div>
      </div>
    </LazyLoadingContainer>
  )
}
