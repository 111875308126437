import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { StateTypes } from 'Store'

import { StepComponentProps } from '@neo-commons/policies'
import { POSOrderStep, POSOrderSteps } from '@neo-commons/policies/src/POS/Order'
import { ClientSelectors, ConfigSelectors, PosSelectors, PosTypes, PreparePOSOrder } from '@neo-commons/store'
import { CheckboxInput, Typography } from '@neo-commons/components'
import { ClientUtils, CountryUtils } from '@neo-commons/libraries'

import { AddressDto, AddressTypeDto, ClientDto, CountryDto } from '@afone/neo-core-client/dist/models'

import { UseAddressForm } from '@neo-web/hooks'
import { LazyLoadingContainer, PageContent, JustifyContent, AddressButtonWhisper, AddressDataType } from '@neo-web/componentsOld'

import { AddressFormTitle } from './AddressFormTitle'

type POSOrderModelChoiceProps = StepComponentProps<POSOrderSteps[POSOrderStep.AddressForm]>

export const AddressForm: React.FC<POSOrderModelChoiceProps> = ({ nextStep }) => {
  const { t } = useTranslation()

  const preparedPOSOrder: PreparePOSOrder = useSelector(PosSelectors.prepare)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const fiscalAddresses = ClientUtils.getClientAddresses(client, AddressTypeDto.FISCAL)
  const deliveryAddresses = ClientUtils.getClientAddresses(client, AddressTypeDto.DELIVERY)
  const userData = useSelector((state: StateTypes) => state.user.data)
  const allCountries: CountryDto[] = useSelector(ConfigSelectors.getCurrentConfig)?.countries ?? []
  const countries = CountryUtils.filterByAddressAuthorized(allCountries)

  const values = {
    countryData: null as CountryDto,
    zipCode: '',
    cityName: '',
    address: '',
    additionalAddress: '',
  }

  if (!deliveryAddresses?.length) {
    const address: AddressDto = fiscalAddresses[0]
    values.countryData = CountryUtils.getCountryFromCode(allCountries, address?.countryCode) ?? null as CountryDto
    values.zipCode = address?.postalCode
    values.cityName = address?.city
    values.address = address?.line1
    values.additionalAddress = address?.line2
  }

  const [addressData, renderAddressForm] = UseAddressForm({
    countries,
    userData,
    values,
  })

  const [isExactAddress, setIsExactAddress] = useState(false)

  const checkFormIsValid = (): boolean => {
    const { address, zipCode, cityName, countryData } = addressData as AddressDataType
    return Boolean(address && zipCode && cityName && countryData && isExactAddress)
  }

  const validAddress = (addressSelected: AddressDataType) => {
    const { address, zipCode, cityName, countryData, additionalAddress } = addressSelected
    nextStep({
      ...preparedPOSOrder,
      deliveryAddress: {
        ...preparedPOSOrder.deliveryAddress,
        line1: address,
        postalCode: zipCode,
        city: cityName,
        line2: additionalAddress,
        country: countryData.name,
        countryCode: countryData.isoCodeAlpha2,
      },
    })
  }

  const getFooter = () => {
    return (
      <>
        <div className='PosOrder_footer_checkbox'>
          <CheckboxInput
            onPress={(value) => setIsExactAddress(value)}
            text={
              <Typography typeface='boldMedium'>
                {t('app:pages:services:pos:order:isExactAddress')}
              </Typography>
            }
            containerStyle={{ justifyContent: 'center' }}
            isSelected={isExactAddress}
          />
        </div>
        <AddressButtonWhisper
          originAddress={addressData}
          onSelect={validAddress}
          buttonDisabled={!checkFormIsValid()}
          buttonTitle={t('app:global:validate')}
        />
      </>
    )
  }

  return (
    <LazyLoadingContainer events={[PosTypes.PREPARE_POS_REQUEST]}>
      <PageContent
        justifyContent={JustifyContent.BETWEEN}
        title={<AddressFormTitle />}
        footer={getFooter()}
      >
        {renderAddressForm}
      </PageContent>
    </LazyLoadingContainer>
  )
}
