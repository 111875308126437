import { StyleSheet } from 'react-native'

import { Typo, Colors } from '@neo-commons/styles'

const styles = StyleSheet.create({
  listSection: {
    ...Typo.bigTitle,
    backgroundColor: Colors.ghostGray,
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 5,
    color: Colors.black,
    fontSize: 13,
  },
  sectionsContainer: {
    backgroundColor: Colors.white,
    paddingTop: 8,
  },
  screen: {
    backgroundColor: Colors.background,
    flex: 1,
  },

  letterGroup: {
    ...Typo.bigTitle,
    fontSize: 14,
    width: 20,
    marginTop: 2,
    textAlignVertical: 'center',
    position: 'absolute',
    color: Colors.black,
    backgroundColor: Colors.white,
  },
  searchContainer: {
    paddingHorizontal: 20,
    marginTop: 0,
    backgroundColor: Colors.white,
  },
  searchInputContainer: {
    borderRadius: 15,
  },

  itemPrefix: {
    display: 'flex',
    flexDirection: 'row',
    textAlignVertical: 'center',
    paddingVertical: 5,
    margin: 0,
    alignItems: 'center',
    paddingStart: 28,
    paddingEnd: 0,
  },
  flags: {
    width: 16,
    height: 16,
    marginTop: 4,
  },
  prefix: {
    ...Typo.smallText,
    fontSize: 15,
    textAlignVertical: 'center',
    textAlign: 'left',
    color: Colors.black,
    margin: 0,
    padding: 0,
    minWidth: 70,
    marginHorizontal: 10,
  },
  countryName: {
    ...Typo.smallText,
    textAlign: 'left',
    fontSize: 15,
    textAlignVertical: 'center',
    color: Colors.black,
    margin: 0,
    marginLeft: 10,
    padding: 0,
  },
  container: {
  },
})

export default styles
