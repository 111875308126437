import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Client, ClientSelectors, SignInActions } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { RefusalReasonDto } from '@afone/neo-core-client'

import { Tunnel, TunnelContent } from '@neo-web/componentsOld'

export const SubscriptionFailure: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const client: Client = useSelector(ClientSelectors.defaultOne)

  const includesRefusalReason = (reason) => client?.refusalReasons?.includes(reason)

  const getTranslation = () : string => {
    if (includesRefusalReason(RefusalReasonDto.US_PERSON)) {
      return 'app:pages:subscription:global:usPersonDocumentsProcessingFailure'
    } else {
      return 'app:pages:subscription:global:subscriptionFailure'
    }
  }

  return (
    <Tunnel
      component={
        <TunnelContent
          icon={<Icon name='info' type={IconTypes.FEATHER} size={40} color={Colors.secondary} />}
          subtitle={t(getTranslation() + ':title')}
          description={t(getTranslation() + ':description')}
          footer={
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:global:backToHome')}
              onPress={async () => {
                await dispatch(SignInActions.signOut())
                history.push(Routes.SignIn.index.path)
              }}
            />
          }
        />
      }
      footer
    />
  )
}
