import { TextStyle } from 'react-native'

import Colors from './Colors'

export const type = {
  regular: 'Quicksand-Regular',
  bold: 'Quicksand-Bold',
  semiBold: 'Quicksand-SemiBold',
  medium: 'Quicksand-Medium',
  light: 'Quicksand-Light',
}

export const size = {
  h1: 44,
  h2: 26,
  h3: 24,
  h4: 18,
  h5: 16,
  h6: 14,
  input: 16,
  body1: 16,
  body2: 14,
  body3: 12,
  overline: 14,
  buttonlarge: 16,
  buttonmedium: 14,
  buttonsmall: 12,
  infoMessage: 16,
  label: 14,
}

export const h1: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h1,
  lineHeight: 56,
  color: Colors.black,
}

export const h2: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h2,
  lineHeight: 32,
  color: Colors.black,
}

export const h3: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h3,
  lineHeight: 32,
  color: Colors.black,
}

export const h4: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h4,
  lineHeight: 32,
  color: Colors.black,
}

export const h5: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h5,
  lineHeight: 24,
  color: Colors.black,
}

export const h6: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h6,
  lineHeight: 24,
  color: Colors.black,
}

export const body1: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.body1,
  lineHeight: 24,
  color: Colors.black,
}
export const body1Medium: TextStyle = {
  fontFamily: type.medium,
  fontSize: size.body1,
  lineHeight: 24,
  color: Colors.trueBlack,
}

export const body2: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.body2,
  lineHeight: 18,
  color: Colors.black,
}

export const body3: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.body3,
  lineHeight: 24,
  color: Colors.black,
}

export const overline: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.overline,
  lineHeight: 18,
  textTransform: 'uppercase',
}

export const buttonLarge: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.buttonlarge,
  lineHeight: 20,
}

export const buttonMedium: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.buttonmedium,
  lineHeight: 18,
}

export const buttonSmall: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.buttonsmall,
  lineHeight: 16,
}

export const label: TextStyle = {
  color: Colors.darkGreyText,
  fontFamily: type.bold,
  fontSize: size.label,
  lineHeight: 16,
}

export const infoMessage: TextStyle = {
  color: Colors.darkGreyText,
  fontFamily: type.bold,
  fontSize: size.infoMessage,
  lineHeight: 24,
}

export const input: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.input,
  lineHeight: 24,
}
