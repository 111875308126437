import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { StateTypes } from '@neo-customer-front/applications/neo-web/src/Store'
import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'

import { IndividualSubscriptionStep, IndividualSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import { OfferSelectors, OrdersSelectors } from '@neo-commons/store'
import { OfferUtils } from '@neo-commons/libraries'

import { ClientTypeDto, OfferDto, OfferTypeDto, OrderTypeDto } from '@afone/neo-core-client'

import { Loader } from '@neo-web/componentsOld'

import { NewOffersContainer } from '../../Components'

type OffersProps = StepComponentProps<IndividualSubscriptionSteps[IndividualSubscriptionStep.Offers]>

export const Offers: React.FC<OffersProps> = ({ nextStep }) => {
  const [defaultIndex, setDefaultIndex] = useState(null)
  const eligibilityOrder = useSelector(OrdersSelectors.byType(OrderTypeDto.ADVANCE_ELIGIBILITY))
  const isAdvanceEligible = eligibilityOrder && eligibilityOrder.analysis.eligibility
  const userState = useSelector((state: StateTypes) => state.user.data)
  const subscription = useSelector((state: StateTypes) => state.subscription)
  const offers: OfferDto[] = useSelector(OfferSelectors.listByClientType(ClientTypeDto.INDIVIDUAL, OfferTypeDto.SOCLE))
    .filter((offer) => offer.authorized)
    .filter(offer => !OfferUtils.isOfferAdvance(offer) || isAdvanceEligible)

  useEffect(() => {
    AnalyticsHandler.identify(
      userState ? userState.uuid : null,
      {
        is_subscribed: false,
        is_account_creator: false,
      }
    )
  }, [])

  useEffect(() => {
    if (subscription?.prepare?.offerUuid) {
      const index = offers.findIndex((offer) => offer.uuid === subscription?.prepare.offerUuid)
      setDefaultIndex(index)
    }
  }, [subscription?.prepare?.offerUuid, offers])

  if (defaultIndex !== null) {
    return (
      <NewOffersContainer
        clientType={ClientTypeDto.INDIVIDUAL}
        offers={offers}
        initialSlide={defaultIndex}
        onOfferSelect={(offer, discountCode) => {
          AnalyticsHandler.track(AnalyticsEvents.signUp.subscriptionOptionConfirmed,
            {
              option_name: offer?.name,
              option_mrr: offer?.price.substring(0, offer?.price.indexOf('/')),
              option_code: offer?.offerCode,
              advance_eligible: isAdvanceEligible,
              account_type: 'B2C',
            })
          nextStep({
            offerUuid: offer?.uuid,
            offer: offer,
            discountCode: offer?.discountCodeUsable ? discountCode : undefined,
            ibanLocalization: 'FR',
          })
        }}
      />
    )
  } else {
    return (
      <Loader isShow loop />
    )
  }
}
