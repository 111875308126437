import { Platform, StyleSheet, TextStyle } from 'react-native'

import { Typo, Colors, Spacing, Commons } from '@neo-commons/styles'

import { createStylesheet } from '../utils'

import { ButtonSkin } from './Button'

const styles = createStylesheet(({ Skin, props }) => {
  const textStyle = {
    textAlign: 'center',
    alignSelf: 'center',
    color: props.textColor ?? Colors.black,

    ...Skin.select([ButtonSkin.PRIMARY], {
      color: Colors.white,
    }),
    ...Skin.select([ButtonSkin.OUTLINE_PRIMARY, ButtonSkin.PRIMARY_LINK, ButtonSkin.TRANSPARENT_PRIMARY], {
      color: Colors.primary,
    }),
    ...Skin.select([ButtonSkin.OUTLINE_GRAY], {
      color: Colors.darkGreyText,
    }),
    ...Skin.select([ButtonSkin.LINK, ButtonSkin.PRIMARY_LINK], {
      textDecorationLine: 'underline',
      fontSize: 14,
    }),
    ...(props.skin === ButtonSkin.OUTLINE_RED ? {
      color: Colors.red,
    } : {}),

    ...(props.small ? {
      fontSize: 12,
    } : {}),

    ...(props.disabled
      ? (
        props.skin === ButtonSkin.OUTLINE_RED ||
        props.skin === ButtonSkin.OUTLINE_PRIMARY ||
        props.skin === ButtonSkin.OUTLINE_GRAY
      )
        ? { color: Colors.gray3 }
        : { color: Colors.white }
      : {}),
  } as TextStyle

  return StyleSheet.create({
    container: {
      borderRadius: Spacing.button.radius,
      marginHorizontal: 24,
      marginVertical: Spacing.generic.baseMargin,
      ...Platform.select({
        android: {
          overflow: 'hidden',
        },
      }),
    },
    button: {
      ...Commons.button,
      paddingVertical: 10,
      paddingHorizontal: 20,
      shadowColor: 'transparent',
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowRadius: 0,
      shadowOpacity: 0,
      elevation: 0,
      backgroundColor: Colors.background,
      borderColor: Colors.transparent,
      borderWidth: Spacing.button.borderWidth,
      minHeight: 45,
      justifyContent: 'center',

      ...Skin.select([ButtonSkin.DEFAULT_LIGHT], {
        backgroundColor: Colors.white,
        borderColor: Colors.background,
        borderRadius: Spacing.button.radius,
        borderWidth: Platform.OS === 'web' ? 2.5 : 0,
        elevation: 1,
      }),
      ...Skin.select([ButtonSkin.PRIMARY, ButtonSkin.OUTLINE_PRIMARY], {
        borderColor: Colors.primary,
      }),
      ...Skin.select([ButtonSkin.PRIMARY], {
        backgroundColor: Colors.primary,
      }),
      ...Skin.select([ButtonSkin.SECONDARY, ButtonSkin.OUTLINE_SECONDARY], {
        borderColor: Colors.secondary,
      }),
      ...Skin.select([ButtonSkin.SECONDARY], {
        backgroundColor: Colors.secondary,
      }),
      ...Skin.select([
        ButtonSkin.OUTLINE_LIGHT,
        ButtonSkin.OUTLINE_PRIMARY,
        ButtonSkin.OUTLINE_SECONDARY,
        ButtonSkin.OUTLINE_RED,
        ButtonSkin.OUTLINE_GRAY,
        ButtonSkin.PRIMARY_LINK,
        ButtonSkin.LINK,
        ButtonSkin.TRANSPARENT,
        ButtonSkin.TRANSPARENT_PRIMARY,
      ], {
        backgroundColor: Colors.transparent,
        shadowOpacity: 0,
        elevation: 0,
      }),
      // Plante (yarn tsc) en ajoutant un Skin.select
      ...(props.skin === ButtonSkin.OUTLINE_GRAY ? {
        borderColor: Colors.darkGreyText,
      } : {}),
      ...(props.skin === ButtonSkin.OUTLINE_LIGHT ? {
        borderColor: Colors.white,
      } : {}),
      ...(props.skin === ButtonSkin.OUTLINE_RED ? {
        backgroundColor: Colors.transparent,
        borderColor: Colors.red,
      } : {}),
      ...((
        props.skin === ButtonSkin.PRIMARY_LINK ||
        props.skin === ButtonSkin.LINK
      ) ? {
          borderColor: Colors.transparent,
        } : {}),

      ...(props.small ? {
        alignSelf: 'center',
        paddingHorizontal: 14,
        borderRadius: Spacing.button.radius,
        minHeight: 36,
        maxHeight: Platform.OS === 'web' ? 'initial' : 40,
      } : {}),

      ...(props.disabled
        ? (
          props.skin === ButtonSkin.OUTLINE_RED ||
          props.skin === ButtonSkin.OUTLINE_PRIMARY ||
          props.skin === ButtonSkin.OUTLINE_GRAY
        )
          ? {
            borderColor: Colors.gray3,
          }
          : {
            backgroundColor: Colors.darkGray,
            borderColor: Colors.darkGray,
            shadowOpacity: 0,
          }
        : {}),

      ...(props.subtitle ? {
        height: 48,
      } : {}),

      ...props.overrideStyleButton,
    },
    title: {
      ...Typo.button,
      ...textStyle,
      ...(props.textColor ? {
        color: props.textColor,
      } : {}),
      overflow: 'visible',
    },
    subtitle: {
      ...Typo.tabTitle,
      ...textStyle,
    },
    leftIconContainer: {
      ...(props.small ? {
        alignSelf: 'center',
        marginRight: 4,
        position: 'relative',
        right: 2,
      } : {
        marginRight: 15,
        justifyContent: 'center',
      }),
    },
    rightIconContainer: {
      ...(props.small ? {
        alignSelf: 'center',
        marginLeft: 4,
        position: 'relative',
        left: 2,
      } : {
        marginLeft: 15,
        justifyContent: 'center',
      }),
    },
    textContainer: {
      justifyContent: 'center',
      alignSelf: 'center',

      ...((!props.small) && {
        width: '100%',
      }),

      ...((props.leftIcon || props.rightIcon) ? {
        ...Commons.row,
        flex: 1,
        display: 'flex',
      } : {}),

      ...props.overrideTextStyleButton,
      ...props.additionalButtonStyle,
    },
  })
})

export default styles
