import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { config, ENV } from 'Config/EnvConfig'

import { Button, ButtonSkin, CountryList, Field, Input, PhoneInput, Typography2 } from '@neo-commons/components'
import {
  CountryUtils,
  EnumUtils,
  PhoneNumberUtils,
  SelectionMode,
  SyncValidatorFunction,
  ValidatorResponseType,
  Validators,
} from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { AffiliationTypeDto, CountryDto, PersonAffiliationDto } from '@afone/neo-core-client/dist/models'

import { Modal } from '@neo-web/componentsOld'

import './RegisterAffiliatesModal.scss'

export interface RegisterAffiliatesModalProps {
  affiliate?: PersonAffiliationDto
  affiliationTypes: AffiliationTypeDto[],
  onSaveAffiliate: (affiliate: PersonAffiliationDto) => void,
  onDeleteAffiliate?: (affiliate: PersonAffiliationDto) => void,
  show: boolean
  onClose?: () => void
}

export const RegisterAffiliatesModal: React.FC<RegisterAffiliatesModalProps> = ({
  affiliate,
  affiliationTypes,
  onSaveAffiliate,
  onDeleteAffiliate,
  show,
  onClose,
}) => {
  const { t } = useTranslation()

  const [affiliationTypesSelected, setAffiliationTypesSelected] =
    useState<Array<AffiliationTypeDto>>(affiliate?.types ?? [])

  const [firstName, setFirstName] = useState<string>(affiliate?.person?.firstName)
  const [lastName, setLastName] = useState<string>(affiliate?.person?.lastName)
  const [phone, setPhone] = useState(affiliate?.person?.phone?.nationalFormat)
  const [email, setEmail] = useState<string>(affiliate?.person?.email)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOnDropdown, setIsOnDropdown] = useState<boolean>(false)
  const [prefix, setPrefix] = useState<CountryCode>(null)
  const [selectedCountry, setSelectedCountry] = useState<CountryDto>(undefined)
  const [isPhoneCorrect, setIsPhoneCorrect] = useState<boolean>(false)
  const [isEmailCorrect, setIsEmailCorrect] = useState(false)

  const profile = useSelector((state: StateTypes) => state?.signup?.profile)

  const countries: CountryDto[] = useSelector(
    (state: StateTypes) => {
      return CountryUtils.filterByAuthorized(
        state.config?.data?.currentConfig?.countries ?? [],
        SelectionMode.PHONE_PREFIX
      )
    }
  )

  const isSelected = (affiliationType: AffiliationTypeDto) => {
    return affiliationTypesSelected.includes(affiliationType)
  }

  const phoneValidator: SyncValidatorFunction = () => ({
    isValid: isPhoneCorrect,
    errorMessage: t('validation:badPhoneNumber'),
  })

  const isInputsValid = affiliationTypesSelected.length !== 0 &&
    firstName !== '' &&
    lastName !== '' &&
    phone !== '' &&
    email !== '' &&
    isPhoneCorrect &&
    isEmailCorrect

  useEffect(() => {
    if (show) {
      setAffiliationTypesSelected(affiliate?.types ?? [])
      setFirstName(affiliate?.person?.firstName ?? '')
      setLastName(affiliate?.person?.lastName ?? '')
      setPhone(affiliate?.person?.phone?.nationalFormat ?? '')
      setEmail(affiliate?.person?.email ?? '')
    }
  }, [affiliate, show])

  useEffect(() => {
    const pageClick = () => {
      if (!isOnDropdown) {
        setIsOpen(false)
      }
    }
    // watch click on this page and bind function to close dropdown
    window.addEventListener('mousedown', pageClick, false)
    return () => {
      // and unwatch event
      window.removeEventListener('mousedown', pageClick)
    }
  }, [isOnDropdown])

  useEffect(() => { phone && setIsPhoneCorrect(PhoneNumberUtils.isAllowForApp(phone, prefix)) }, [phone, prefix])

  useEffect(() => {
    setPrefix(selectedCountry?.isoCodeAlpha2 as CountryCode)
  }, [selectedCountry])

  useEffect(() => {
    setSelectedCountry(countries.find((country) =>
      country.isoCodeAlpha2 === profile?.countryCode ?? config(ENV.DEFAULT_LANGUAGE_APP)
    ))
  }, [profile])

  return (
    <Modal
      displayCross
      open={show}
      title={
        !affiliate
          ? t('app:pages:subscription:business:identifyAffiliates:modal:title:add')
          : t('app:pages:subscription:business:identifyAffiliates:modal:title:modify')
      }
      size='sm'
      backdrop='static'
      onClose={onClose}
    >
      <div className='RegisterAffiliatesModal_container'>
        {affiliate?.canUpdate || !affiliate
          ? (
            <>
              <Typography2 typeface='overline'>
                {t('app:pages:subscription:business:identifyAffiliates:modal:memberStatus:title')}
              </Typography2>
              <div className='RegisterAffiliatesModal_container_affiliationType'>
                {!!affiliationTypes && affiliationTypes?.map((affiliationType, index) => {
                  return (
                    <div
                      key={index}
                      className={
                    `RegisterAffiliatesModal_container_affiliationType_item
                    ${isSelected(affiliationType) ? 'selected' : ''}`
                      }
                      onClick={() => {
                        if (!affiliationTypesSelected.includes(affiliationType)) {
                          setAffiliationTypesSelected([affiliationType])
                        } else {
                          setAffiliationTypesSelected([])
                        }
                      }}
                    >
                      <Typography2
                        style={{ color: isSelected(affiliationType) ? Colors.white : Colors.black }}
                        typeface='buttonLarge'
                      >
                        {t(EnumUtils.getAffiliationTypeDtoKey(affiliationType))}
                      </Typography2>
                    </div>
                  )
                })}

              </div>

              <Typography2 typeface='overline'>
                {t('app:pages:subscription:business:identifyAffiliates:modal:coordonnee')}
              </Typography2>

              <Input
                label={`${t('app:pages:subscription:business:identifyAffiliates:firstName')}*`}
                value={firstName ?? ''}
                isRequired
                onChangeText={(firstname) => setFirstName(firstname)}
              />
              <Input
                label={`${t('app:pages:subscription:business:identifyAffiliates:lastName')}*`}
                value={lastName ?? ''}
                isRequired
                onChangeText={(lastName) => setLastName(lastName)}
              />
            </>
          )
          : (
            <>
              <Field
                label={t('app:pages:subscription:business:identifyAffiliates:modal:memberStatus:title')}
                value={t(EnumUtils.getAffiliationTypeDtoKey(affiliate?.types[0]))}
              />
              <Field
                label={t('app:pages:subscription:business:identifyAffiliates:firstName')}
                value={affiliate?.person?.firstName}
              />
              <Field
                label={t('app:pages:subscription:business:identifyAffiliates:lastName')}
                value={affiliate?.person?.lastName}
              />
            </>)}
        <div className='RegisterAffiliatesModal_container_coordonnee'>
          <div className='RegisterAffiliatesModal_container_phone'>
            <PhoneInput
              isRequired
              phoneLabel={`${t('app:pages:subscription:business:identifyAffiliates:phoneNumber')}*`}
              onChangeText={(text) => setPhone(text)}
              onPressPrefix={() => {
                setIsOpen(!isOpen)
              }}
              prefix={prefix}
              value={phone ?? ''}
              validators={[phoneValidator]}
              phoneRegex={selectedCountry?.regexPhoneValidation}
              containerStyle={{ maxWidth: '100%' }}
            />
            {isOpen && (
              <div
                className='RegisterAffiliatesModal_container_phone_dropdown'
                onMouseEnter={() => setIsOnDropdown(true)}
                onMouseLeave={() => setIsOnDropdown(false)}
              >
                {countries?.length && (
                  <CountryList
                    countries={countries}
                    selectionMode={SelectionMode.PHONE_PREFIX}
                    onCountrySelectedAction={(country) => {
                      setSelectedCountry(country)
                      setTimeout(() => {
                        setIsOpen(false)
                      }, 200)
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <Input
            label={`${t('app:pages:subscription:business:identifyAffiliates:email')}*`}
            value={email ?? ''}
            onChangeText={(email) => setEmail(email)}
            isRequired
            onValidationChecked={(value: ValidatorResponseType) => { setIsEmailCorrect(value.isValid) }}
            validators={[Validators.isEmail]}
          />
        </div>

        <div className='RegisterAffiliatesModal_container_button'>
          {affiliate && affiliate?.canUpdate &&
            <Button
              skin={ButtonSkin.OUTLINE_RED}
              title={t('app:pages:subscription:business:identifyAffiliates:modal:button:removeAffiliate')}
              containerStyle={{ marginBottom: 8 }}
              onPress={() => onDeleteAffiliate(affiliate)}
            />}
          <Button
            skin={ButtonSkin.PRIMARY}
            title={
              !affiliate
                ? t('app:pages:subscription:business:identifyAffiliates:modal:button:saveAffiliate')
                : t('app:pages:subscription:business:identifyAffiliates:modal:button:save')
            }
            disabled={!isInputsValid}
            onPress={() => onSaveAffiliate({
              ...affiliate,
              types: affiliationTypesSelected,
              person: {
                firstName: firstName,
                lastName: lastName,
                phone: {
                  ...affiliate?.person?.phone,
                  internationalFormat: parsePhoneNumber(phone, prefix).formatInternational().replace(/ /g, ''),
                },
                email: email,
              },
            }
            )}
          />
        </div>
      </div>
    </Modal>
  )
}
