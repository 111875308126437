import React from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { store } from 'Store'
import { Routes } from 'Routes/Routes'

import { closeProjectPolicy, CloseProjectStep } from '@neo-commons/policies'
import { useWizardPolicy } from '@neo-commons/hooks'

import { PageDetails } from '@neo-web/componentsOld'

import { Transfer } from './Step1/Transfer'
import { Summary } from './Step2/Summary'
import { Success } from './Step3/Success'

export const Close: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const { currentStep, steps: wizardSteps, goBack, context } = useWizardPolicy(store, closeProjectPolicy, {
    [CloseProjectStep.Transfer]: Transfer,
    [CloseProjectStep.Summary]: Summary,
    [CloseProjectStep.Success]: Success,
  }, {
    onDone: () => null,
  })

  const previousStep = () => {
    if (context?.stack && context?.stack?.length > 0) goBack()
    else history.goBack()
  }

  return (
    <PageDetails
      component={
        <>
          {currentStep?.path && (
            <Redirect
              to={{
                pathname: currentStep?.path,
              }}
            />
          )}
          {wizardSteps.map((value) =>
            <Route
              key={value.navigationKey}
              exact
              path={value.path}
              component={value.component}
            />
          )}
        </>
      }
      onBack={currentStep?.meta?.canGoBack ? previousStep : null}
      onClose={currentStep?.meta?.canGoBack ? () => history.push(Routes.Services.ProjectsRoutes.index.path) : null}
      title={currentStep?.meta?.canGoBack && t(currentStep?.meta?.headerTitle)}
    />
  )
}
