import React from 'react'
import { useHistory } from 'react-router-dom'

import { CallbackComponent } from 'redux-oidc'
import { Routes } from 'Routes/Routes'

import { Loader } from '@neo-web/componentsOld'

import { agentClientUserManager } from '../../../../Config/AgentClientUserManager'

import './AgentClientRegister.scss'

export const LoginCallback:React.FunctionComponent = () => {
  const history = useHistory()

  const successCallback = async () => {
    history.push(Routes.Register.index.path)
  }

  const errorCallback = (error: Error) => {
    history.push(Routes.SvaRegisterRoutes.unauthorized.path)
    throw error
  }

  return (
    <CallbackComponent
      userManager={agentClientUserManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      <div className='container'>
        <div className='subContainer'>
          <div className='containerLoader'>
            <Loader isShow loop />
          </div>
        </div>
      </div>
    </CallbackComponent>
  )
}
