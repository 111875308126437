import React from 'react'
import { Trans } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Modal, InformationModalProps } from '@neo-web/componentsOld'
import './ConfirmModal.scss'

interface ConfirmModalProps extends InformationModalProps {
  onConfirm: () => void
  cancelText?: string
  confirmText: string
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (
  props: ConfirmModalProps
) => {
  return (
    <Modal autoFocus open={props.show} onClose={props.onClose}>
      <FlexboxGrid justify='center' className='text-center InformationModal'>
        <FlexboxGrid.Item colspan={24} className='text-right'>
          <Icon
            name='x'
            type={IconTypes.FEATHER}
            size={25}
            onPress={props.onClose}
            color={Colors.black}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24} className='pb-s'>
          <Icon
            name='alert-circle'
            type={IconTypes.FEATHER}
            size={40}
            color={Colors.secondary}
          />
        </FlexboxGrid.Item>
        {props.title && (
          <FlexboxGrid.Item colspan={24} className='pb-s'>
            <Typography typeface='bigTitle'>{props.title}</Typography>
          </FlexboxGrid.Item>
        )}
        {props.description && (
          <FlexboxGrid.Item
            colspan={22}
          >
            <Typography typeface='content'>
              <Trans>{props.description}</Trans>
            </Typography>
          </FlexboxGrid.Item>
        )}
        <FlexboxGrid.Item style={{ display: 'flex', justifyContent: 'space-between' }} className='ConfirmModal_action' colspan={24}>
          {props.cancelText &&
            <Button
              title={props.cancelText}
              skin={ButtonSkin.OUTLINE_PRIMARY}
              onPress={props.onClose}
            />}
          <Button
            title={props.confirmText}
            skin={ButtonSkin.PRIMARY}
            onPress={props.onConfirm}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Modal>
  )
}
