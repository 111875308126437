import React from 'react'

import { AppConfig } from 'Config/AppConfig'

import { Icon, IconTypes, PasscodeInput, RandomNumericKeyboard } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { KeypadUtils } from '@neo-commons/libraries'
import { PasswordKeypadData } from '@neo-commons/hooks'
import './PasswordKeypad.scss'

interface PasswordKeypadProps extends PasswordKeypadData {
  withoutValidate?: boolean,
}
export const PasswordKeypad: React.FC<PasswordKeypadProps> = ({ withoutValidate, ...passwordKeypadData }) => {
  const createKeypadKey = value => `keypad-${value}`
  return (
    <div className='PasswordKeypad text-center' data-testid='passwordKeypad'>
      <div className='PasswordKeypad_body'>
        <PasscodeInput
          pinLimit={AppConfig.PASS_CODE_SIZE}
          codePin={passwordKeypadData}
          isImage
        />
        <br />

        <div className='PasswordKeypad_keyboard'>

          <RandomNumericKeyboard
            matrix={KeypadUtils.generateKeypadMatrix(
              passwordKeypadData.rawKeypad.map(
                el =>
                  <img
                    key={createKeypadKey(el.value)}
                    data-value={el.value}
                    src={KeypadUtils.getImageUri(el.image)}
                    alt={el.value}
                  />
              ),
              [{
                type: KeypadUtils.SpecialKeysType.VALIDATE,
                component: withoutValidate ? (
                  <Icon
                    testID='keyboard-validate'
                    size={25}
                    type={IconTypes.FEATHER}
                    name='arrow-right-circle'
                    color={!passwordKeypadData.isFilled ? Colors.ghostBlack : Colors.black}
                  />) : <></>,
              }, {
                type: KeypadUtils.SpecialKeysType.DELETE,
                component: withoutValidate ? (
                  <Icon
                    testID='keyboard-backSpace'
                    size={25}
                    type={IconTypes.FEATHER}
                    name='delete'
                    color={!passwordKeypadData.canDeleteKey ? Colors.ghostBlack : Colors.black}
                  />) : <></>,
              }]
            )}
            onKeyPress={img => {
              const foundKey = passwordKeypadData.rawKeypad.find(pad => createKeypadKey(pad.value) === img.key)
              !passwordKeypadData.isFilled && passwordKeypadData.addKey(foundKey)
            }}
            onDeleteCharPress={() => passwordKeypadData.deleteKey()}
            disableDeleteChar={!passwordKeypadData.canDeleteKey}
            withoutValidate={withoutValidate}
          />
        </div>

      </div>
    </div>
  )
}
