import { StyleSheet } from 'react-native'

import { Colors, Commons, Typo } from '@neo-commons/styles'

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 16,
    padding: 0,
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  shadow: Commons.shadow,
  containerBasic: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  basicCursor: Commons.basicCursor,
  containerDisabled: {
    backgroundColor: Colors.gray,
  },
  containerAvailableSoon: {
    backgroundColor: Colors.white,
  },
  leftArea: {
    justifyContent: 'center',
    marginRight: 14,
  },
  centerArea: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingVertical: 16,
  },
  title: {
    ...Typo.input,
    display: 'flex',
  },
  subtitle: {
    display: 'flex',
  },
  titleNumber: {
    fontSize: 14,
    borderColor: Colors.primary,
    color: Colors.primary,
    display: 'flex',
    borderRadius: 50,
    width: 24,
    lineHeight: 22,
    height: 24,
    marginLeft: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chevronContainerStyle: {
    paddingVertical: 11,
  },
  rowTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
})
