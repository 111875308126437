import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import { SubscriptionUtils } from '@neo-commons/libraries'
import { ClientSelectors, SubscriptionActions, SubscriptionSelectors } from '@neo-commons/store'
import { Button, ButtonSkin, Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { ClientDto, ClientTypeDto, ErrorCodeDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent } from '@neo-web/componentsOld'

import './SeaCguPedagogy.scss'

export const SeaCguPedagogy: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const isSubscriptionLoading = useSelector((state: StateTypes) => state.subscription?.loading)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const linkSignature: string = useSelector((state: StateTypes) => state.subscription?.signatureLink)
  const personUuid = SubscriptionUtils.getKycStep(subscription)?.personUuid ?? ''
  const CGUlink: string = useSelector((state: StateTypes) => state?.config?.data?.currentConfig?.cgu)

  return (
    <TunnelContent
      loading={isSubscriptionLoading}
      icon={<Icon name='info' type={IconTypes.FEATHER} size={40} color={Colors.secondary} />}
      subtitle={t('app:pages:subscription:seaCGU:seaCguPedagogy:title')}
      description={
        <>
          <Typography2 typeface='body1'>
            {t('app:pages:subscription:seaCGU:seaCguPedagogy:description:sentenceOne')}
          </Typography2>
          <a
            className='SeaCguPedagogy_link'
            href={CGUlink}
            target='_blank' rel='noreferrer'
          >
            {t('app:pages:subscription:seaCGU:seaCguPedagogy:description:link')}
          </a>
          <br /><br />
          <Typography2 typeface='body1'>
            {t('app:pages:subscription:seaCGU:seaCguPedagogy:description:sentenceTwo')}
          </Typography2>
        </>
      }
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:continue')}
          onPress={async () => {
            try {
              if (!linkSignature) {
                await dispatch(SubscriptionActions.getSignatureLink({
                  subscriptionUuid: subscription.uuid,
                  personUuid: client?.type === ClientTypeDto.CORPORATE ? client?.holder?.uuid : personUuid,
                }))
              }
              history.push(Routes.Subscription.SeaCgu.electronicSignature.path)
            } catch (e) {
              try {
                await dispatch(SubscriptionActions.list())
                if (e.code === ErrorCodeDto.C0707) {
                  history.push(Routes.Subscription.SeaCgu.electronicSignatureFallback.path)
                } else if (e.code === ErrorCodeDto.C0512) {
                  history.push(Routes.Subscription.subscriptionError.path, { errorMessage: e.message })
                } else {
                  history.push(Routes.Subscription.subscriptionError.path)
                }
              } catch (e) {}
            }
          }}
        />
      }
    />
  )
}
