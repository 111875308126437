import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonSkin, Button, Typography, IconTypes, Icon } from '@neo-commons/components'

import './SelectionList.scss'

interface SelectionListItem {
  id: string,
  label: string,
}

interface SelectionListProps {
  list: SelectionListItem[],
  selectedId?: string,
  onSelect?: (id: string) => void,
  onValidate?: (id: string) => void,
  disabled?: boolean,
  additionalAction?: ReactElement<typeof Button>,
}

export const SelectionList: React.FC<SelectionListProps> = (props: SelectionListProps) => {
  const { t } = useTranslation()

  const [selectedId, setSelectedId] = useState<string>(props?.selectedId)

  return (
    <>
      <div className={`SelectionList ${props?.disabled && 'disabled'}`}>
        {props.list.map((item) =>
          <div
            key={item.id}
            className='SelectionList_item'
            onClick={() => {
              if (!props?.disabled) {
                setSelectedId(item.id)
                if (props?.onSelect) {
                  props.onSelect(item.id)
                }
              }
            }}
          >
            <Typography typeface={item.id === selectedId ? 'semiBold' : 'content'}>
              {item.label}
            </Typography>
            {item.id === selectedId &&
              <Icon name='check' type={IconTypes.FEATHER} size={22} />}
          </div>
        )}
      </div>
      {props?.additionalAction}
      {props?.onValidate &&
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:validate')}
          disabled={props?.disabled || !selectedId}
          onPress={() => props.onValidate(selectedId)}
          containerStyle={{ marginTop: 8 }}
        />}
    </>
  )
}
