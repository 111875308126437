import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  country: {
    marginRight: 8,
    paddingHorizontal: 16,
    paddingTop: 10,
    paddingBottom: 8,
    borderWidth: 1,
    borderColor: '#AAAAAA',
    borderRadius: 12,
  },
  inputContainer: {
    flex: 1,
  },
  countryError: {
    borderColor: '#F42020',
  },
  
  countryText: {
    height: 24,
    fontSize: 16,
    color: '#AAAAAA',
  },
  countryFilled: {
    color: '#141414',
  },
  flags: {
    width: 20,
    height: 20,
    margin: 2,
  },
})
