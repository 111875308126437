import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { UserTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    UserTypes.GET_FAILURE,
    UserTypes.UPDATE_FAILURE,
    UserTypes.UPDATE_SUCCESS,
    UserTypes.CHECK_PASSCODE_SEQUENCE_FAILURE,
  ])
}

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    UserTypes.CHECK_PASSCODE_SEQUENCE_REQUEST,
    UserTypes.CHECK_PASSCODE_SEQUENCE_SUCCESS,
    UserTypes.CHECK_PASSCODE_SEQUENCE_FAILURE,
  ])
}

export default [
  displayDropdownAlert$,
  reactiveLoader$,
]
