import { Reducer, AnyAction } from 'redux'
import i18next from 'i18next'
import { omit, map } from 'lodash'
import { NeobankApi } from '@neo-commons/services'
import {
  AddressCreateDto,
  AddressCreateOrReplaceDto,
  AddressDto,
  AddressTypeDto,
  AddressUpdateDto,
  ClientDto,
} from '@afone/neo-core-client/dist/models'
import { createSelector } from 'reselect'

import { Dispatch, resourceDefaultSelectors, ResourceState } from '../utils/resourceState'
import { State, initialResourceState, AlertType } from '../utils'

import { ClientActions, ClientSelectors } from './client'
import { UserActions } from './user'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Address = AddressDto

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

const CREATE_ADDRESS_PREPARE = 'address/CREATE_ADDRESS_PREPARE'
const CREATE_ADDRESS_REQUEST = 'address/CREATE_ADDRESS_REQUEST'
const CREATE_ADDRESS_SUCCESS = 'address/CREATE_ADDRESS_SUCCESS'
const CREATE_ADDRESS_FAILURE = 'address/CREATE_ADDRESS_FAILURE'

const GET_ADDRESS_REQUEST = 'address/GET_ADDRESS_REQUEST'
const GET_ADDRESS_SUCCESS = 'address/GET_ADDRESS_SUCCESS'
const GET_ADDRESS_FAILURE = 'address/GET_ADDRESS_FAILURE'

const LIST_ADDRESS_REQUEST = 'address/LIST_ADDRESS_REQUEST'
const LIST_ADDRESS_SUCCESS = 'address/LIST_ADDRESS_SUCCESS'
const LIST_ADDRESS_FAILURE = 'address/LIST_ADDRESS_FAILURE'

const UPDATE_ADDRESS_PREPARE = 'address/UPDATE_ADDRESS_PREPARE'
const UPDATE_ADDRESS_REQUEST = 'address/UPDATE_ADDRESS_REQUEST'
const UPDATE_ADDRESS_SUCCESS = 'address/UPDATE_ADDRESS_SUCCESS'
const UPDATE_ADDRESS_FAILURE = 'address/UPDATE_ADDRESS_FAILURE'

const CREATE_OR_REPLACE_USER_ADDRESS_REQUEST = 'address/CREATE_OR_REPLACE_USER_ADDRESS_REQUEST'
const CREATE_OR_REPLACE_USER_ADDRESS_SUCCESS = 'address/CREATE_OR_REPLACE_USER_ADDRESS_SUCCESS'
const CREATE_OR_REPLACE_USER_ADDRESS_FAILURE = 'address/CREATE_OR_REPLACE_USER_ADDRESS_FAILURE'

const DELETE_ADDRESS_REQUEST = 'address/DELETE_ADDRESS_REQUEST'
const DELETE_ADDRESS_SUCCESS = 'address/DELETE_ADDRESS_SUCCESS'
const DELETE_ADDRESS_FAILURE = 'address/DELETE_ADDRESS_FAILURE'

const RESET = 'address/RESET'

export const AddressTypes = {
  CREATE_ADDRESS_REQUEST,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_FAILURE,

  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILURE,

  LIST_ADDRESS_REQUEST,
  LIST_ADDRESS_SUCCESS,
  LIST_ADDRESS_FAILURE,

  UPDATE_ADDRESS_PREPARE,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILURE,

  CREATE_OR_REPLACE_USER_ADDRESS_REQUEST,
  CREATE_OR_REPLACE_USER_ADDRESS_SUCCESS,
  CREATE_OR_REPLACE_USER_ADDRESS_FAILURE,

  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILURE,

  RESET,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const addressSelector = state => state.address

export const AddressSelectors = {
  ...resourceDefaultSelectors(addressSelector),
  defaultOne: createSelector(addressSelector, resource => resource.data[Object.keys(resource.data)[0]] ?? null),
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */
const errorKeyTranslate = 'errors:unknownTechnicalIssue'

const getAddressByUuid = (addressUuid: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: GET_ADDRESS_REQUEST })

    try {
      if (!getState().client?.list?.loadedOnce) {
        await dispatch(ClientActions.list())
      }
      const defaultClient: ClientDto = ClientSelectors.defaultOne(getState())
      if (!defaultClient) {
        throw new Error()
      }

      const getAddressResponse = await NeobankApi.getInstance().addressApi.getAddressByUuid(defaultClient.uuid as string, addressUuid)
      const data = getAddressResponse.data ?? {}

      dispatch({ type: GET_ADDRESS_SUCCESS, infos: data, addressUuid })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({ type: GET_ADDRESS_FAILURE, errorMessage })
    }
  }
}

const prepareCreate = (data: Partial<Address>) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CREATE_ADDRESS_PREPARE, data })
  }
}

const createAddress = (clientUuid?: string, addressRequest?: AddressCreateDto, checkAddress?: boolean) => {
  return async (dispatch: Dispatch, getState: () => State): Promise<AddressDto> => {
    dispatch({ type: CREATE_ADDRESS_REQUEST })

    const xValidationOnly: number = checkAddress ? 1 : 0

    try {
      const createRequest = {
        ...(getState().address.create as Required<AddressState['create']>),
        ...addressRequest,
      } as AddressCreateDto
      const createUuid = clientUuid ?? ClientSelectors.defaultOne(getState()).uuid
      const creationResponse = await NeobankApi.getInstance().addressApi.createAddress(
        createUuid!,
        xValidationOnly,
        createRequest)
      dispatch({ type: CREATE_ADDRESS_SUCCESS, data: creationResponse.data, checkAddress })
      return creationResponse.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({ type: CREATE_ADDRESS_FAILURE, errorMessage })
      throw error
    }
  }
}

const prepareUpdate = (data: Partial<Address>) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_ADDRESS_PREPARE, data })
  }
}

const updateAddress = (addressUuid?: string, addressRequest?: AddressUpdateDto) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: UPDATE_ADDRESS_REQUEST })

    try {
      if (!getState().client?.list?.loadedOnce) {
        await dispatch(ClientActions.list())
      }
      const defaultClient: ClientDto = ClientSelectors.defaultOne(getState())
      if (!defaultClient) {
        throw new Error()
      }
      const updateRequest = { ...(getState().address.update as Required<AddressState['update']>), ...addressRequest }
      const updateUuid = addressUuid ?? AddressSelectors.defaultOne(getState()).uuid
      const updateRequestWithoutUuid = omit(updateRequest, 'uuid')
      const updateAddressByUuidResponse = await NeobankApi.getInstance().addressApi.updateAddress(
        defaultClient.uuid as string,
        updateUuid,
        updateRequestWithoutUuid
      )

      dispatch({
        type: UPDATE_ADDRESS_SUCCESS,
        data: updateAddressByUuidResponse.data,
        alertType: AlertType.SUCCESS,
        successMessage: i18next.t('neo-commons:app:global:profile:update:success'),
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({ type: UPDATE_ADDRESS_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const createOrUpdate = (payload?: { addressUuid?: string, addressRequest?: Partial<Address> }) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const client = ClientSelectors.defaultOne(getState())
    if (client?.holder?.addresses?.length) {
      await dispatch(updateAddress(payload?.addressUuid ?? client?.holder?.addresses[0].uuid, payload?.addressRequest))
    } else {
      const adressCreateRequest: AddressCreateDto = {
        type: payload?.addressRequest?.type,
        fullName: payload?.addressRequest?.fullName ?? '',
        city: payload?.addressRequest?.city ?? '',
        line1: payload?.addressRequest?.line1 ?? '',
        postalCode: payload?.addressRequest?.postalCode ?? '',
        countryCode: payload?.addressRequest?.countryCode ?? 'FR',
        line2: payload?.addressRequest?.line2,
      }
      await dispatch(createAddress(client.uuid, adressCreateRequest))
    }
    await dispatch(ClientActions.list())
  }
}

const deleteAddress = addressUuid => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: UPDATE_ADDRESS_REQUEST })
    try {
      if (!getState().client?.list?.loadedOnce) {
        await dispatch(ClientActions.list())
      }
      const defaultClient: ClientDto = ClientSelectors.defaultOne(getState())
      if (!defaultClient) {
        throw new Error()
      }

      await NeobankApi.getInstance().addressApi.deleteAddress(defaultClient.uuid as string, addressUuid)

      dispatch({ type: UPDATE_ADDRESS_SUCCESS })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({ type: UPDATE_ADDRESS_FAILURE, errorMessage })
    }
  }
}

const createOrReplaceUserAddress = (address: AddressCreateOrReplaceDto) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: CREATE_OR_REPLACE_USER_ADDRESS_REQUEST })
    try {
      const user = getState()?.user?.data
      const userAddress = user?.person?.addresses?.find(elt => elt?.type === AddressTypeDto.FISCAL)

      // If the address exists, we replace its data with the uuid otherwise we create it
      const addressCreateOrReplaceRequest: AddressCreateOrReplaceDto = {
        uuid: address?.uuid ?? userAddress?.uuid,
        type: address?.type,
        fullName: address?.fullName ?? user?.person?.firstName + ' ' + user?.person?.lastName,
        city: address?.city ?? '',
        line1: address?.line1 ?? '',
        postalCode: address?.postalCode ?? '',
        countryCode: address?.countryCode ?? 'FR',
        line2: address?.line2,
      }

      const createOrReplaceUserAddressResponse = await NeobankApi.getInstance().addressApi.createOrReplaceUserAddress(undefined, addressCreateOrReplaceRequest)

      dispatch({
        type: CREATE_OR_REPLACE_USER_ADDRESS_SUCCESS,
        data: createOrReplaceUserAddressResponse.data,
        alertType: AlertType.SUCCESS,
        successMessage: i18next.t('neo-commons:app:global:profile:update:success'),
      })

      await dispatch(UserActions.getUser())
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
      dispatch({ type: CREATE_OR_REPLACE_USER_ADDRESS_FAILURE, errorMessage })
    }
  }
}

export const AddressActions = {
  getAddressByUuid,
  prepareCreate,
  createAddress,
  prepareUpdate,
  updateAddress,
  deleteAddress,
  createOrUpdate,
  createOrReplaceUserAddress,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type AddressState = ResourceState<Address>

const initialState: AddressState = {
  ...initialResourceState,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const address: Reducer = (state = initialState, action: AnyAction): AddressState => {
  switch (action.type) {
    case LIST_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data?.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {}) ?? {},
        list: {
          ...state.list,
          ids: action.data?.map(item => item.uuid) ?? [],
          loadedOnce: true,
        },
      }
    case GET_ADDRESS_SUCCESS: {
      const address = action.data

      return {
        ...state,
        data: {
          ...state.data,
          ...{ [action.data.uuid]: address },
        },
        loading: false,
      }
    }

    case CREATE_ADDRESS_PREPARE:
      return {
        ...state,
        create: {
          ...state.create,
          ...action.data,
        },
      }
    case UPDATE_ADDRESS_PREPARE:
      return {
        ...state,
        update: {
          ...state.create,
          ...action.data,
        },
      }

    case GET_ADDRESS_REQUEST:
    case DELETE_ADDRESS_REQUEST:
    case UPDATE_ADDRESS_REQUEST:
    case CREATE_ADDRESS_REQUEST:
    case CREATE_OR_REPLACE_USER_ADDRESS_REQUEST:
    case LIST_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_ADDRESS_SUCCESS:
    case UPDATE_ADDRESS_SUCCESS:
    case CREATE_OR_REPLACE_USER_ADDRESS_SUCCESS:
      if (action?.checkOnly) {
        return {
          ...state,
          loading: false,
        }
      }

      return {
        ...state,
        loading: false,
        data: map(state.data, (value, key) => {
          if (key === action.data.uuid) {
            return action.data
          } else {
            return value
          }
        }),
      }
    case GET_ADDRESS_FAILURE:
    case LIST_ADDRESS_FAILURE:
    case UPDATE_ADDRESS_FAILURE:
    case DELETE_ADDRESS_SUCCESS:
    case DELETE_ADDRESS_FAILURE:
    case CREATE_ADDRESS_FAILURE:
    case CREATE_OR_REPLACE_USER_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case RESET:
      return initialState

    default:
      return state
  }
}
