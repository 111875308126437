import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import {
  BankAccountCard,
  BankAccountCardSkin,
  Typography2,
} from '@neo-commons/components'
import {
  BankAccountActions,
  BankAccountSelectors,
  BankAccountTypes,
  ClientSelectors,
  OfferActions,
  OfferTypes,

} from '@neo-commons/store'

import {
  AccountDto,
  AccountTypeDto,
  ClientDto,
} from '@afone/neo-core-client/dist/models'

import {
  JustifyContent,
  PageContent,
  PaginationList,
  SuspenseLoading,
  ShimmerList,
  ShimmerListType,
  PageDetails,
  CloseSubAccountModal,
} from '@neo-web/componentsOld'

export const CloseProjectList: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [closeModal, setCloseModal] = useState(false)

  const selectedAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)

  const offerState = useSelector((state: StateTypes) => state.offer)

  const bankAccountState = useSelector((state: StateTypes) => state?.bankAccount)
  const bankAccountProjectsList: AccountDto[] = useSelector(
    BankAccountSelectors.getSubAccountByUuidAndType(selectedAccount.uuid, AccountTypeDto.PROJECT))
  const paginationEnded = useSelector((state: StateTypes) => state?.bankAccount.list?.subLists[AccountTypeDto.PROJECT]?.paginationEnded)
  const currentPage = useSelector((state: StateTypes) => state.bankAccount.list.subLists[AccountTypeDto.PROJECT].page)
  const accountClosureReview = useSelector((state: StateTypes) => state.bankAccount.accountClosureReview)

  useEffect(() => {
    (async () => {
      try {
        if (!offerState.loading) {
          await dispatch(OfferActions.listForClientUuid())
        }
      } catch (error) {}
    })()
  }, [])

  const getBankAccounts = () => {
    (async () => {
      if (!paginationEnded && !bankAccountState.loading) {
        await dispatch(BankAccountActions.list({
          pageNumber: currentPage + 1,
          bankAccountType: AccountTypeDto.PROJECT,
          parentUuid: selectedAccount.uuid,
          isPaginate: true,
        }))
      }
    })()
  }

  return (
    <>
      <CloseSubAccountModal
        show={closeModal}
        onClose={() => {
          setCloseModal(false)
        }}
        cancelSubscription={accountClosureReview?.willCancelSubscription}
        price={accountClosureReview?.subscriptions[0]?.price}
      />
      <PageDetails
        title={t('app:pages:services:project:closeProject:title')}
        onClose={() => history.push(Routes.Services.ProjectsRoutes.index.path)}
        onBack={() => history.push(Routes.Services.ProjectsRoutes.index.path)}
        component={(
          <PageContent
            justifyContent={JustifyContent.START}
          >
            <div className='CloseProjectList_subtitle'>
              <Typography2 typeface='h4'>{t('app:pages:services:project:closeProject:subtitle')}</Typography2>
            </div>
            <SuspenseLoading
              events={[BankAccountTypes.CREATE_SUB_ACCOUNT_REQUEST, OfferTypes.GET_OFFER_LIST_REQUEST]}
              fallback={(
                <div className='CloseProjectList_title'>
                  <ShimmerList type={ShimmerListType.PROJECT} fading />
                </div>
              )}
            >
              <div>
                {bankAccountProjectsList.length > 0 &&
                  <PaginationList
                    state={bankAccountState}
                    stateList={bankAccountProjectsList}
                    items={(item) =>
                      <div key={item?.uuid}>
                        <BankAccountCard
                          onPress={async () => {
                            await dispatch(BankAccountActions.getAccountClosureReview(item?.uuid))
                            dispatch(BankAccountActions.setSelectedBankAccountToClose(item))
                            setCloseModal(true)
                          }}
                          bankAccount={item}
                          client={client}
                          skin={BankAccountCardSkin.SECONDARY}
                          isDisabled
                        />
                      </div>}
                    eventLoading={BankAccountTypes.LIST_BANK_ACCOUNT_REQUEST}
                    next={() => getBankAccounts()}
                    refreshFunction={() => {
                      (async () => {
                        try {
                          await dispatch(BankAccountActions.list({
                            bankAccountType: AccountTypeDto.PROJECT,
                            parentUuid: selectedAccount.uuid,
                            isPaginate: true,
                          }))
                        } catch (error) {}
                      })()
                    }}
                  />}
              </div>
            </SuspenseLoading>
          </PageContent>
        )}
      />
    </>
  )
}
