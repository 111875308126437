import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { ContactPreferencesTypes, NotificationPreferencesTypes } from '@neo-commons/store'

import { loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    ContactPreferencesTypes.GET_CONTACT_PREFERENCES_REQUEST,
    ContactPreferencesTypes.GET_CONTACT_PREFERENCES_SUCCESS,
    ContactPreferencesTypes.GET_CONTACT_PREFERENCES_FAILURE,
    NotificationPreferencesTypes.GET_NOTIFICATION_PREFERENCES_REQUEST,
    NotificationPreferencesTypes.GET_NOTIFICATION_PREFERENCES_SUCCESS,
    NotificationPreferencesTypes.GET_NOTIFICATION_PREFERENCES_FAILURE,
  ])
}

export default [
  reactiveLoader$,
]
