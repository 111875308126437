import { SectionListData } from 'react-native'

import { CountryDto } from '@afone/neo-core-client/dist/models'
import { orderBy } from 'lodash'

import { TextUtils } from './TextUtils'

export interface GroupedCountries {
  [key: string]: CountryDto[]
}

export enum SelectionMode {
  PHONE_PREFIX = 'PhonePrefix',
  LIVE_COUNTRY = 'LiveCountry',
  LANGUAGE_CHOICE = 'LanguageChoice',
  PRO_TAX_DOMICILIATION = 'ProTaxDomiciliation',
}

const excludedCountries = {
  [SelectionMode.PHONE_PREFIX]: ['USA'],
}

/**
 * Differents treatment for countries.
 */
export class CountryUtils {
  /**
   * Group the countries in SectionListData by the first letter.
   *
   * @param countries - list of country.
   * @returns list of country sort by first letter.
   * @example
   * const groupCountriesFiltered = CountryUtils.groupCountriesByFirstLetter(countriesFiltered)
   *
   */
  public static groupCountriesByFirstLetter = (countries: CountryDto[]) => {
    CountryUtils.sortByName(countries)

    return orderBy(
      countries.reduce((cumulate: SectionListData<CountryDto>[], country: CountryDto): SectionListData<CountryDto>[] => {
        const firstLetterCountry = TextUtils.normalizeText((country?.name?.[0] ?? '')).toUpperCase()

        const existing = cumulate.find(group => group.title === firstLetterCountry)
        if (existing) {
          (existing.data as CountryDto[]).push(country)
          existing.data = orderBy(existing.data, 'name', 'asc')
        } else {
          cumulate.push({ title: firstLetterCountry, data: [country] })
        }

        return cumulate
      }, [])
      , 'title', 'asc')
  }

  /**
   * Filter a country list and return list with authorized countries only.
   *
   * @param countries - list of country.
   * @param selectionMode - section mode
   * @returns list of country authorized.
   * @example
   * const countriesFiltered = CountryUtils.filterByAuthorized(countries)
   */
  public static filterByAuthorized = (countries: CountryDto[], selectionMode?: SelectionMode) => {
    return countries
      .filter(country => country.isAuthorized)
      .filter(country => {
        if (selectionMode === SelectionMode.PHONE_PREFIX) {
          return !excludedCountries[selectionMode].includes(country.isoCodeAlpha3)
        }

        return true
      })
  }

  /**
   * Filter a country list and return list with address authorized countries only.
   *
   * @param countries - list of country.
   * @returns list of country authorized for address.
   * @example
   * const countriesFiltered = CountryUtils.filterByAddressAuthorized(countries)
   */
  public static filterByAddressAuthorized = (countries: CountryDto[]) => {
    return countries
      .filter(country => country.isAddressAuthorized)
  }

  /**
   * Return the fact of country is authorized or not.
   *
   * @param country - country.
   * @param selectionMode - section mode
   * @returns boolean of authorized status.
   * @example
   * CountryUtils.isAuthorizedCountry(country)
   */
  public static isAuthorizedCountry = (country: CountryDto, selectionMode?: SelectionMode) => {
    if (selectionMode === SelectionMode.PHONE_PREFIX && excludedCountries[selectionMode].includes(country.isoCodeAlpha3)) {
      return false
    }
    return country.isAddressAuthorized === true
  }

  /**
   * Sort a list of country alphabetically.
   *
   * @param countries - list of country.
   * @returns the list of countries sort.
   * @example
   * CountryUtils.sortByName(countries)
   *
   */
  public static sortByName = (countries: &CountryDto[]) => {
    countries.sort(TextUtils.compareToSort('name'))
  }

  /**
   * Returns the wanted country.
   *
   * @param countries - list of country.
   * @param countryCode - country code.
   * @returns the country object corresponding to the given country code.
   * @example
   * CountryUtils.getCountryFromCode(countries, 'FR')
   *
   */
  public static getCountryFromCode = (countries: CountryDto[], countryCode: string): CountryDto | undefined => {
    return countries.find(c => c.isoCodeAlpha2 === countryCode)
  }
}
