import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import IdCheckIo from 'idcheckio-sdk'

import { UrlUtils } from '@neo-commons/libraries'

const idCheck = new IdCheckIo({
  mode: 'IFRAME',
})

export const KYCRetrieveDocument = () => {
  const idCheckRef = useRef(null)

  const url = useLocation().search
  const query = UrlUtils.deserialize(url)

  const idCheckIoUrl = query?.idcheckiourl ?? ''

  const customPostMessage = (data) => {
    // eslint-disable-next-line
    // @ts-ignore
    if (window?.ReactNativeWebView) {
    // eslint-disable-next-line
    // @ts-ignore
      window.ReactNativeWebView.postMessage(data)
    }
  }

  idCheck.on('DOCUMENT_CAPTURED', () => { customPostMessage('DOCUMENT_CAPTURED') })
  idCheck.on('NAVIGATION_WELCOME', () => { customPostMessage('NAVIGATION_WELCOME') })
  idCheck.on('NAVIGATION_DOCUMENTS_LIST', () => { customPostMessage('NAVIGATION_DOCUMENTS_LIST') })
  idCheck.on('NAVIGATION_LEGAL_MENTIONS', () => { customPostMessage('NAVIGATION_LEGAL_MENTIONS') })
  idCheck.on('NAVIGATION_CAPTURE', () => { customPostMessage('NAVIGATION_CAPTURE') })
  idCheck.on('NAVIGATION_LIVENESS', () => { customPostMessage('NAVIGATION_LIVENESS') })
  idCheck.on('NAVIGATION_CAPTURE_HELP', () => { customPostMessage('NAVIGATION_CAPTURE_HELP') })
  idCheck.on('NAVIGATION_ANALYSIS', () => { customPostMessage('NAVIGATION_ANALYSIS') })
  idCheck.on('NAVIGATION_WAITING_ERROR', () => { customPostMessage('NAVIGATION_WAITING_ERROR') })
  idCheck.on('NAVIGATION_WAITING_END', () => { customPostMessage('NAVIGATION_WAITING_END') })
  idCheck.on('NAVIGATION_END', () => { customPostMessage('NAVIGATION_END') })
  idCheck.on('ENDED', () => { customPostMessage('ENDED') })
  idCheck.on('ERROR', () => { customPostMessage('ERROR') })
  idCheck.on('ACTIVITY', () => { customPostMessage('ACTIVITY') })

  useEffect(() => {
    if (idCheckIoUrl) {
      idCheck.init({
        url: idCheckIoUrl,
        element: idCheckRef.current, // Alternative: "#idCheck-component",
      })
    }
  }, [idCheckIoUrl])

  return (
    <div
      id='idCheck-component'
      ref={idCheckRef}
      style={{ height: '100vh' }}
    />
  )
}
