import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import {
  BankAccountSelectors,
  ClientSelectors,
  OfferSelectors,
  OrdersSelectors,
  Subscription,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { BankAccountUtils, ClientUtils, OfferUtils } from '@neo-commons/libraries'
import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { ClientDto, OfferTypeDto, OrderTypeDto } from '@afone/neo-core-client/dist/models'

import { Page, PageContent } from '@neo-web/componentsOld'
import { RoutesUtils } from '@neo-web/utils'

import { DebitCard } from './DebitCard/DebitCard'
import { Order } from './DebitCard/Order'
import { Order as OrderProject } from './Projects/Order/Order'
import { Close as CloseProject } from './Projects/Close/Close'
import { ShowPin } from './DebitCard/Settings/Pin/ShowPin'
import { ActivateCard } from './DebitCard/Settings/ActivateCard/ActivateCard'
import { Details } from './DebitCard/Settings/Details/Details'
import { Limits } from './DebitCard/Settings/Limits/Limits'
import { Projects } from './Projects/Projects'
import { Pos } from './Pos/Pos'
import { Order as OrderPos } from './Pos/Order'
import { ActivatePos } from './Pos/Activate/ActivatePos'
import { ListDetailPos } from './Pos/Detail/ListDetailPos'
import { DetailPos } from './Pos/Detail/DetailPos'
import { ManagePos } from './Pos/Manage/ManagePos'
import { ReassignPos } from './Pos/Manage/ReassignPos'
import { AdvanceService } from './Advance/AdvanceService'
import { AdvanceHistory } from './Advance/AdvanceHistory/AdvanceHistory'
import { AggregationService } from './Aggregation/AggregationService'
import { ActivateAggregateAccounts } from './Aggregation/ActivateAggregateAccounts/ActivateAggregateAccounts'
import { AggregationFallback } from './Aggregation/AggregationWebView/AggregationFallback'
import { ListAggregationBanks } from './Aggregation/ListAggregationBanks/ListAggregationBanks'
import { ShowAggregatedAccounts } from './Aggregation/ShowAggregatedAccounts/ShowAggregatedAccounts'
import { RemovedAggregatedAccount } from './Aggregation/RemovedAggregatedAccount/RemovedAggregatedAccount'
import { AggregationSearch } from './Aggregation/AggregationSearch/AggregationSearch'
import { AggregationKnowMore } from './Aggregation/AggregationKnowMore/AggregationKnowMore'
import { ListProjects } from './Projects/Components/ListProjects'
import { CloseProjectList } from './Projects/Components/CloseProjectList'
import { ReturnedList } from './Pos/Manage/ReturnedList'
import { Return as ReturnPos } from './Pos/Return'
import { DispatchList } from './Pos/Dispatches/DispatchList'
import { DispatchDetail } from './Pos/Dispatches/DispatchDetail'
import { Lost as LostPos } from './Pos/Lost'
import { CancelClosure } from './Projects/Close/CancelClosure'
import { ClosePosAccount } from './Pos/ClosePosAccount'

export const Services: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const selectedBankAccount = useSelector(BankAccountSelectors.selected, (p, c) => p?.uuid === c?.uuid)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne, (p, c) => p?.uuid === c?.uuid)
  const subscription: Subscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  const socleOffer = useSelector(
    OfferSelectors.listByClientType(client?.type, OfferTypeDto.SOCLE),
    (p, c) => JSON.stringify(p) === JSON.stringify(c))
    .filter(offer => offer.uuid === subscription?.offerUuid)[0]
  const userData = useSelector((state: StateTypes) => state.user.data)
  const advanceEligibilityOrder = useSelector(OrdersSelectors.byType(OrderTypeDto.ADVANCE_ELIGIBILITY))

  const isAccountSecondary = BankAccountUtils.isSecondary(selectedBankAccount)
  const isAccountPos = BankAccountUtils.isPos(selectedBankAccount)
  const isAccountAggregated = BankAccountUtils.isAggregated(selectedBankAccount)
  const isAccountProspect = BankAccountUtils.isProspectAccount(selectedBankAccount)
  const isAccountProspectProject = BankAccountUtils.isProspectProject(selectedBankAccount)

  const serviceBlock =
    <div className='classic-box'>
      <PageContent
        icon={<Icon type={IconTypes.FEATHER} name='alert-circle' color={Colors.secondary} size={50} />}
        title={t('app:pages:services:unavailable')}
      />
    </div>

  const secondaryAccount = useMemo(() =>
    <>
      <Switch>
        <Route path={Routes.Services.ProjectsRoutes.cancelClosure.path} component={CancelClosure} />
        <Route path={RoutesUtils.getAllRoutes(Routes.Services.CloseProjectRoutes)} component={CloseProject} />
        <Route path={Routes.Services.order.path} component={Order} />
        <Route
          path={[Routes.Services.index.path]}
          component={
            () => serviceBlock
          }
        />
      </Switch>
    </>, [])

  const secondaryAccountPos = useMemo(() =>
    <>
      <Switch>
        <Route
          path={[Routes.Services.index.path, Routes.Services.pos.path]}
          exact
          component={
            () =>
              <Page
                pages={[
                  {
                    path: Routes.Services.pos.path,
                    component: <Pos />,
                    name: t('app:pages:services:pos:menu'),
                  },
                ]}
              />
          }
        />
        <Route path={Routes.Services.activatePos.path} component={ActivatePos} />
        <Route path={Routes.Services.detailPos.path} component={DetailPos} />
        <Route path={RoutesUtils.getAllRoutes(Routes.Services.PosReturnRoutes)} component={ReturnPos} />
        <Route path={Routes.Services.closePosAccount.path} component={ClosePosAccount} exact />
      </Switch>
      {location.pathname !== Routes.Services.closePosAccount.path &&
        <Redirect to={{ pathname: Routes.Services.pos.path }} />}
    </>, [location.pathname])

  const aggregatedAccount = useMemo(() =>
    <>
      <Switch>
        <Route
          path={[
            Routes.Services.aggregation.path,
            Routes.Services.advance.path,
          ]}
          exact
          component={
            () =>
              <Page
                pages={[
                  {
                    path: Routes.Services.aggregation.path,
                    component: <AggregationService />,
                    name: t('app:pages:services:aggregation:menu'),
                    pathIsNotExact: true,
                    isHidden: !userData?.features?.aggregator?.active,
                  },
                  {
                    path: Routes.Services.advance.path,
                    component: <AdvanceService />,
                    name: t('app:pages:services:advance:menu'),
                    pathIsNotExact: true,
                    isHidden: (!OfferUtils.isOfferAdvance(socleOffer) && (!!socleOffer || !advanceEligibilityOrder?.analysis.eligibility)),
                  },
                ]}
              />
          }
        />
        <Route
          exact
          path={[
            Routes.Services.ProjectsRoutes.index.path,
            Routes.Services.index.path,
          ]}
        >
          <Redirect to={Routes.Services.aggregation.path} />
        </Route>
        <Route path={Routes.Services.order.path} component={Order} />
        <Route exact path={Routes.Services.aggregatedAccounts.path} component={ShowAggregatedAccounts} />
        <Route exact path={Routes.Services.aggregatedBanks.path} component={ListAggregationBanks} />
        <Route exact path={Routes.Services.removedAggregatedAccount.path} component={RemovedAggregatedAccount} />
        <Route exact path={Routes.Services.activateAggregateAccounts.path} component={ActivateAggregateAccounts} />
        <Route exact path={Routes.Services.aggregatedFallback.path} component={AggregationFallback} />
        <Route exact path={Routes.Services.aggregationSearch.path} component={AggregationSearch} />
        <Route exact path={Routes.Services.aggregationKnowMore.path} component={AggregationKnowMore} />
        <Route exact path={Routes.Services.advanceHistory.path} component={AdvanceHistory} />
      </Switch>
    </>, []
  )

  const primaryAccount = useMemo(() =>
    <Switch>
      <Route
        path={[
          Routes.Services.index.path,
          Routes.Services.ProjectsRoutes.index.path,
          Routes.Services.pos.path,
          Routes.Services.aggregation.path,
          Routes.Services.advance.path,
        ]}
        exact
        component={
          () =>
            <Page
              pages={[
                {
                  path: Routes.Services.index.path,
                  component: <DebitCard />,
                  name: t('app:pages:services:card:menu'),
                },
                {
                  path: Routes.Services.ProjectsRoutes.index.path,
                  component: <Projects />,
                  name: t('app:pages:services:project:menu'),
                  pathIsNotExact: true,
                },
                {
                  path: Routes.Services.pos.path,
                  component: <Pos />,
                  name: t('app:pages:services:pos:menu'),
                  isHidden: !(ClientUtils.isClientTypeCorporate(client?.type) &&
                    (socleOffer?.data as any)?.subType === OfferTypeDto.POS),
                },
                {
                  path: Routes.Services.aggregation.path,
                  component: <AggregationService />,
                  name: t('app:pages:services:aggregation:menu'),
                  pathIsNotExact: true,
                  isHidden: !userData?.features?.aggregator?.active,
                },
                {
                  path: Routes.Services.advance.path,
                  component: <AdvanceService />,
                  name: t('app:pages:services:advance:menu'),
                  pathIsNotExact: true,
                  isHidden: (!OfferUtils.isOfferAdvance(socleOffer) && (!!socleOffer || !advanceEligibilityOrder?.analysis.eligibility)),
                },
                {
                  path: Routes.Services.account.path,
                  component: <div>Todo : Compte</div>,
                  name: t('app:pages:services:account:menu'),
                  isHidden: true,
                },
                {
                  path: Routes.Services.insurance.path,
                  component: <div>Todo : Assurance</div>,
                  name: t('app:pages:services:insurance:menu'),
                  isAvailable: false,
                  isHidden: true,
                },
                {
                  path: Routes.Services.cashback.path,
                  component: <div>Todo : Cashback</div>,
                  name: t('app:pages:services:cashback:menu'),
                  isAvailable: false,
                  isHidden: true,
                },
              ]}
            />
        }
      />
      <Route path={Routes.Services.order.path} component={Order} />
      <Route path={Routes.Services.ProjectsRoutes.listProjects.path} component={ListProjects} exact />
      <Route path={Routes.Services.ProjectsRoutes.closeProjectList.path} component={CloseProjectList} exact />
      <Route path={RoutesUtils.getAllRoutes(Routes.Services.CloseProjectRoutes)} component={CloseProject} />
      <Route path={Routes.Services.ProjectsRoutes.index.path} component={OrderProject} />
      <Route path={Routes.Services.debitCardDetail.path} component={Details} />
      <Route path={Routes.Services.activateCard.path} component={ActivateCard} />
      <Route path={Routes.Services.debitCardPin.path} component={ShowPin} />
      <Route path={Routes.Services.limits.path} component={Limits} />
      <Route path={Routes.Services.activatePos.path} component={ActivatePos} />
      <Route path={Routes.Services.detailPosList.path} component={ListDetailPos} exact />
      <Route path={Routes.Services.detailPos.path} component={DetailPos} />
      <Route path={Routes.Services.managePos.path} component={ManagePos} exact />
      <Route path={Routes.Services.closePosAccount.path} component={ClosePosAccount} exact />
      <Route path={Routes.Services.reassignPos.path} component={ReassignPos} />
      <Route path={Routes.Services.returnedList.path} component={ReturnedList} />
      <Route path={Routes.Services.dispatchList.path} component={DispatchList} exact />
      <Route path={Routes.Services.dispatchDetail.path} component={DispatchDetail} />
      <Route exact path={Routes.Services.aggregatedAccounts.path} component={ShowAggregatedAccounts} />
      <Route exact path={Routes.Services.aggregatedBanks.path} component={ListAggregationBanks} />
      <Route exact path={Routes.Services.activateAggregateAccounts.path} component={ActivateAggregateAccounts} />
      <Route exact path={Routes.Services.aggregatedFallback.path} component={AggregationFallback} />
      <Route exact path={Routes.Services.aggregationSearch.path} component={AggregationSearch} />
      <Route exact path={Routes.Services.aggregationKnowMore.path} component={AggregationKnowMore} />
      <Route exact path={Routes.Services.removedAggregatedAccount.path} component={RemovedAggregatedAccount} />
      <Route exact path={Routes.Services.advanceHistory.path} component={AdvanceHistory} />
      <Route path={RoutesUtils.getAllRoutes(Routes.Services.PosOrderRoutes)} component={OrderPos} />
      <Route path={RoutesUtils.getAllRoutes(Routes.Services.PosReturnRoutes)} component={ReturnPos} />
      <Route path={RoutesUtils.getAllRoutes(Routes.Services.PosLostRoutes)} component={LostPos} />
    </Switch>, [])

  return (
    <>
      {((isAccountSecondary && !isAccountPos) || isAccountProspectProject) &&
        secondaryAccount}
      {isAccountPos && !isAccountProspect &&
        secondaryAccountPos}
      {isAccountAggregated &&
        aggregatedAccount}
      {!isAccountSecondary && !isAccountAggregated && !isAccountProspectProject &&
        primaryAccount}
    </>
  )
}
