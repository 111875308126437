export class FileUtils {
  /**
   * Convert base 64 file to Blob
   *
   * @param base64Data - string of file base 64
   * @returns Return - an object new Blob see https://developer.mozilla.org/fr/docs/Web/API/Blob.
   * @example
   * const blob = this.base64toBlob(base64String)
   *
   */
  static base64toBlob (base64Data: string): Blob {
    const sliceSize = 1024
    const byteCharacters = atob(base64Data)
    const bytesLength = byteCharacters.length
    const slicesCount = Math.ceil(bytesLength / sliceSize)
    const byteArrays = new Array(slicesCount)

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize
      const end = Math.min(begin + sliceSize, bytesLength)

      const bytes = new Array(end - begin)
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0)
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes)
    }

    return new Blob(byteArrays, { type: 'application/pdf' })
  }

  /**
   * Open new tab in browser to open file base 64
   *
   * @param base64 - string of file base 64
   * @param name - name of file
   * @param extension - extension of file
   * @returns Return - an object new Blob see https://developer.mozilla.org/fr/docs/Web/API/Blob.
   * @example
   * FileUtils.openBase64NewTab(
   *    bankAccountSelected.rib.file.fileEncoded,
   *    bankAccountSelected.rib.file.name,
   *    'pdf'
   *  )
   *
   */
  static openBase64NewTab (base64: string, name: string, extension: string): void {
    const blob = this.base64toBlob(base64)
    name = name.replace(/\.[^/.]+$/, '')
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, `${name}.${extension}`)
    } else {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = URL.createObjectURL(blob)
      a.download = `${name}.${extension}`
      a.click()
      URL.revokeObjectURL(a.href)
      a.remove()
    }
  }
}
