import React, { useEffect, useState } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'

import { InvoiceAndContractPolicy } from 'Policies'

import { Routes } from '../../../../Routes/Routes'

import { ChoicesAccount } from './ChoicesAccount/ChoicesAccount'
import { InvoiceAndContractDetail } from './InvoiceAndContractDetail/InvoiceAndContractDetail'

export const InvoiceAndContract: React.FC = () => {
  const history = useHistory()

  const [isRedirect, setIsRedirect] = useState<boolean>(false)

  const invoiceAndContractRedirect = InvoiceAndContractPolicy.invoiceAndContractRedirect()

  useEffect(() => {
    if (history.action === 'POP') {
      setIsRedirect(true)
    } else {
      setIsRedirect(false)
    }
  }, [history.action])

  const stepsContent: { path: string, content: React.ReactNode }[] = [
    {
      path: Routes.Profile.choicesAccount.path,
      content: <ChoicesAccount />,
    },
    {
      path: Routes.Profile.invoiceAndContractDetail.path,
      content: <InvoiceAndContractDetail />,
    },
  ]
  return (
    <>
      {isRedirect &&
        <Redirect
          to={{
            pathname: invoiceAndContractRedirect,
          }}
        />}
      {stepsContent.map((value, index) => {
        return (
          <Route key={index} exact path={value.path}>
            {value.content}
          </Route>
        )
      })}
    </>
  )
}
