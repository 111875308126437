import { RoutesInterface } from './Routes'

export class PublicLinkRoutes {
  public static index: RoutesInterface = {
    path: '/public',
    name: 'app:pages:analytics:publicLink:index',
    available: true,
  };

  public static ourPartners: RoutesInterface = {
    path: PublicLinkRoutes.index.path + '/our-partners',
    name: 'app:pages:publicLink:partners:ourPartners',
    available: true,
  };

  public static legalNotice: RoutesInterface = {
    path: PublicLinkRoutes.index.path + '/legalNotice',
    name: 'app:global:legalNotice',
    available: true,
  };

  public static manifest: RoutesInterface = {
    path: PublicLinkRoutes.index.path + '/manifest',
    name: 'app:global:manifest',
    available: true,
  };

  public static clientService: RoutesInterface = {
    path: PublicLinkRoutes.index.path + '/clientService',
    name: 'app:pages:profile:help:home:clientservice',
    available: true,
  };

  public static recoverPassword: RoutesInterface = {
    path: PublicLinkRoutes.index.path + '/recover-password',
    name: 'app:pages:profile:help:home:recoverPassword',
    available: true,
  };

  public static privacyPolicy: RoutesInterface = {
    path: PublicLinkRoutes.index.path + '/privacyPolicy',
    name: 'app:global:privacyPolicy',
    available: true,
  };

  public static changelog: RoutesInterface = {
    path: PublicLinkRoutes.index.path + '/changelog',
    name: 'app:global:changeLog',
    available: true,
  };
}
