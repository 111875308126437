import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Avatar } from 'rsuite'
import { StateTypes } from 'Store'

import { ClientSelectors, Subscription, SubscriptionSelectors } from '@neo-commons/store'
import { Badge, BadgeSkin, Button, ButtonSkin, Typography } from '@neo-commons/components'
import { OfferUtils, TextUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { LogoutModal } from '@neo-web/componentsOld'

import { version } from '../../../../../package.json'

import './UserProfileCard.scss'

export const UserProfileCard: React.FC = () => {
  const { t } = useTranslation()
  const user = useSelector((state: StateTypes) => state.user.data)
  const clientState = useSelector(ClientSelectors.defaultOne)
  const [isShowModalLogout, setIsShowModalLogout] = useState(false)
  const subscription: Subscription = useSelector(SubscriptionSelectors.listSocle)
  const isCollab = subscription?.[0]?.offer ? OfferUtils.isOfferCollab(subscription?.[0]?.offer) : false

  return (
    <>
      <LogoutModal
        show={isShowModalLogout}
        onClose={() => {
          setIsShowModalLogout(false)
        }}
      />

      <div className='UserProfileCard'>
        <div className='UserProfileCard_container classic-box box-shadow' style={{ textTransform: 'uppercase' }}>
          <Avatar circle>
            {user?.person?.firstName ? user?.person?.firstName.charAt(0) : user.firstName.charAt(0)}
            {user?.person?.lastName ? user?.person?.lastName.charAt(0) : user.firstName.charAt(0)}
          </Avatar>
          {isCollab && (
            <div className='UserProfileCard_container_badge'>
              <Badge value={`${t('app:pages:myProfile:badge:collab')} 🤘`} skin={BadgeSkin.PRIMARY} />
            </div>
          )}
          <div className='UserProfileCard_container_name'>
            <Typography typeface='heading' style={{ textTransform: 'capitalize' }}>
              {TextUtils.prettyName(user?.person?.lastName ?? user.lastName, user?.person?.firstName ?? user.firstName) ?? ''}
            </Typography>
          </div>
          {clientState?.code && t('app:pages:myProfile:components:userProfileCard:idClient')}
          <Typography typeface='subtitle'>
            {clientState?.code}
          </Typography>
        </div>
        <Button
          skin={ButtonSkin.OUTLINE_PRIMARY}
          overrideStyleButton={{ borderColor: Colors.white }}
          textColor={Colors.white}
          title={t('app:global:logout')}
          onPress={() => {
            setIsShowModalLogout(true)
          }}
        />
      </div>

      <div className='VersionApp'>
        {version}
      </div>
    </>
  )
}
