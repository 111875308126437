import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AppConfig } from 'Config/AppConfig'
import { Routes } from 'Routes/Routes'
import { ServicesRoutes } from 'Routes/Services'
import { StateTypes } from 'Store'

import { CardUpdateType } from '@neo-commons/libraries'
import { CardActions, CardSelectors, CardTypes, CreditCardData } from '@neo-commons/store'
import { usePasswordKeypad } from '@neo-commons/hooks'

import { KeypadFieldDto } from '@afone/neo-core-client/dist/models'

import { useDecryptData } from '@neo-web/hooks'
import { CodeConfirm, LazyLoadingContainer, PageDetails } from '@neo-web/componentsOld'

import { DisplayCodePin } from '../../DisplayCodePin'

import { EditPinSuccess } from './EditPinSuccess'

import './ShowPin.scss'

export const ShowPin: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)
  const signInData = useSelector((state: StateTypes) => state?.signIn?.data)
  const pinData = usePasswordKeypad({
    phone: signInData?.phone,
    phoneCountryCode: signInData?.countryCode,
    field: KeypadFieldDto.PIN,
    passwordSize: AppConfig.PIN_CODE_SIZE,
  })
  const confirmPinData = usePasswordKeypad({
    phone: signInData?.phone,
    phoneCountryCode: signInData?.countryCode,
    field: KeypadFieldDto.NEW_PIN,
    passwordSize: AppConfig.PIN_CODE_SIZE,
  })

  const [isEditMode, setIsEditMode] = useState(false)
  const [showEditPinSuccess, setShowEditPinSuccess] = useState(false)
  const [pinErrorMessage, setPinErrorMessage] = useState()
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const onDecryptDone = (error?: string) => {
    if (error) {
      history.push(Routes.Services.index.path)
    }
  }
  const sensitiveCardData = useDecryptData(false, undefined, onDecryptDone)

  const updatedDebitCardPin = async (newPin: string[]) => {
    setPinErrorMessage(undefined)
    try {
      await dispatch(CardActions.update(
        selectedCard.uuid,
        { pinUpdate: { newPin } },
        CardUpdateType.PIN)
      )
      setShowEditPinSuccess(true)
    } catch (error) {
      /* no error.message means that the SCA has been cancelled, which is not supposed to clear pinData */
      if (error?.message) {
        pinData.reset()
        confirmPinData.reset()
        setShowConfirmPassword(false)
      }
    }
  }

  const handleOnBack = () => {
    setPinErrorMessage(undefined)
    switch (true) {
      case isEditMode && pinData.password.length !== 0:
        pinData.reset()
        confirmPinData.reset()
        break
      case isEditMode && pinData.password.length === 0:
        setIsEditMode(false)
        break
      default:
        history.push(ServicesRoutes.index.path)
        break
    }
  }

  return (
    <>
      <PageDetails
        title={t('app:pages:debitCard:services:cardInfos:viewModifyMyPinCode')}
        onBack={handleOnBack}
        component={
          <LazyLoadingContainer events={[CardTypes.UPDATE_CARD_REQUEST]}>
            {sensitiveCardData &&
              <div className='ShowPin_container'>
                {!showEditPinSuccess && (
                  <>
                    {!isEditMode &&
                      <DisplayCodePin
                        onEditPinClick={() => setIsEditMode(true)}
                        pin={sensitiveCardData.pin}
                        cardStatus={selectedCard.plasticInfo.plasticStatus}
                      />}
                    {isEditMode && !showConfirmPassword &&
                      <CodeConfirm
                        title={t('app:pages:debitCard:services:cardInfos:pleaseEnterYourNewPinCode')}
                        onValidate={() => setShowConfirmPassword(true)}
                        error={pinErrorMessage && t(pinErrorMessage)}
                        keypadData={pinData}
                      />}
                    {isEditMode && showConfirmPassword &&
                      <CodeConfirm
                        title={t('app:pages:debitCard:services:cardInfos:pleaseConfirmYourNewPinCode')}
                        onValidate={(passwordKeypadData) => updatedDebitCardPin(passwordKeypadData.password.map(e => e.value))}
                        error={pinErrorMessage && t(pinErrorMessage)}
                        keypadData={confirmPinData}
                      />}
                  </>
                )}
                {showEditPinSuccess &&
                  <EditPinSuccess onGoToServicesClick={() => history.push(ServicesRoutes.index.path)} />}
              </div>}
          </LazyLoadingContainer>
        }
      />
    </>
  )
}
