import { TextStyle } from 'react-native'

import Colors from './Colors'

export const type = {
  regular: 'Quicksand-Regular',
  bold: 'Quicksand-Bold',
  semiBold: 'Quicksand-SemiBold',
  medium: 'Quicksand-Medium',
  light: 'Quicksand-Light',
}

export const size = {
  h1: 38,
  h2: 34,
  h3: 30,
  h4: 26,
  h5: 20,
  h6: 19,
  input: 18,
  regular: 16,
  medium: 14,
  small: 12,
  tiny: 8.5,
}

export const paragraphe: TextStyle = {
  fontFamily: type.light,
  fontSize: 18,
  letterSpacing: 1,
}

export const header: TextStyle = {
  fontFamily: type.medium,
  fontSize: size.regular,
  letterSpacing: 1,
}
export const headerTab: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h6,
  letterSpacing: 1,
}

export const heading: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h5,
  lineHeight: 32,
  color: Colors.black,
}

export const accountHeader: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.input,
  textTransform: 'capitalize',
  color: Colors.black,
}

export const accountAmount: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h5,
  color: Colors.primary,
}

export const transactionAmount: TextStyle = {
  fontFamily: type.semiBold,
  fontSize: size.h5,
  color: Colors.black,
}

export const dateTransactionTitle: TextStyle = {
  fontFamily: type.light,
  fontSize: size.medium,
  fontWeight: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.14,
  color: Colors.black,
  textTransform: 'uppercase',
}

export const contentLightGrey: TextStyle = {
  fontFamily: type.light,
  fontSize: size.regular,
  color: Colors.black,
}

export const bigTitle: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h5,
  color: Colors.black,
  lineHeight: 32,
}

export const bigTitle1: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h3,
  color: Colors.black,
}

export const bigTitleLight: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.h5,
  color: Colors.black,
  lineHeight: 32,
}

export const semiBold: TextStyle = {
  fontFamily: type.semiBold,
  fontSize: size.regular,
  color: Colors.black,
}

export const bold: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.regular,
  color: Colors.black,
}

export const boldSecondary: TextStyle = {
  ...bold,
  color: Colors.secondaryDarker,
}

export const titleH4: TextStyle = {
  ...bigTitle,
  fontSize: size.h4,
}

export const titleH4Secondary: TextStyle = {
  ...bigTitle,
  fontSize: size.h4,
  color: Colors.secondaryDarker,
}

export const title: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.h5,
  color: Colors.black,
}

export const tabLabel: TextStyle = {
  fontFamily: type.semiBold,
  fontSize: size.tiny,
}

export const tinyBoldLabel: TextStyle = {
  fontFamily: type.semiBold,
  fontSize: size.tiny,
}

export const subtitle: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.medium,
  color: Colors.black,
}

export const subtitle2 = {
  fontFamily: type.regular,
  fontSize: size.input,
  color: Colors.black,
}

export const subtitleGrey: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.medium,
  color: Colors.darkGreyText,
  lineHeight: 22,
}

export const subtitleLight: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.regular,
}
export const smallText: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.medium,
  color: Colors.black,
}

export const smallTextLight: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.small,
}

export const smallTextWeightMedium: TextStyle = {
  fontFamily: type.medium,
  fontSize: size.small,
}

export const content: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.regular,
}

export const crossedContent: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.medium,
  color: Colors.lightGray,
  textDecorationLine: 'line-through',
}

export const info: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.regular,
  color: Colors.black,
  lineHeight: 24,
}

export const infoRegular: TextStyle = {
  fontFamily: type.medium,
  fontSize: size.regular,
  color: Colors.black,
}

export const input: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.regular,
  color: Colors.black,
  lineHeight: 24,
}

export const inputProject: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.regular,
  color: Colors.darkGreyText,
  fontWeight: 'bold',
  fontStyle: 'normal',
}

export const button: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.medium,
  letterSpacing: 0.5,
}

export const buttonLight: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.medium,
  lineHeight: 16,
  letterSpacing: 1,
}

export const pinText: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h2,
  lineHeight: 35,
  letterSpacing: 24,
  color: Colors.secondaryDarker,
  paddingLeft: 12,
}

export const itemTitle: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.medium,
}

export const itemSubtitle: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.small,
}

export const menuItemTitle: TextStyle = {
  fontFamily: type.light,
  fontSize: size.medium,
}

export const selectedTitle: TextStyle = {
  fontFamily: type.bold,
  fontSize: size.h5,
}

export const tabTitle: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.small,
}

export const appName: TextStyle = {
  fontSize: size.h4,
}

export const boldMedium: TextStyle = {
  fontSize: size.medium,
  fontFamily: type.bold,
}

export const offerPrice: TextStyle = {
  fontSize: 50,
  fontFamily: type.semiBold,
  color: Colors.primaryDark,
  lineHeight: 50,
}

export const offerSmallText: TextStyle = {
  fontFamily: type.regular,
  fontSize: size.small,
  lineHeight: 14,
}

/* WAS SPECIFIC NEO MOBILE STYLES */

export const h1: TextStyle = {
  fontFamily: type.bold,
  fontSize: 40,
  lineHeight: 56,
  color: Colors.black,
}

export const h2: TextStyle = {
  fontFamily: type.bold,
  fontSize: 24,
  lineHeight: 32,
  color: Colors.black,
}

export const h3: TextStyle = {
  fontFamily: type.bold,
  fontSize: 20,
  lineHeight: 32,
  color: Colors.black,
}

export const h4: TextStyle = {
  fontFamily: type.bold,
  fontSize: 16,
  lineHeight: 24,
  color: Colors.black,
}

export const h5: TextStyle = {
  fontFamily: type.bold,
  fontSize: 14,
  lineHeight: 24,
  color: Colors.black,
}

export const h6: TextStyle = {
  fontFamily: type.bold,
  fontSize: 12,
  lineHeight: 16,
  color: Colors.black,
}

export const body1: TextStyle = {
  fontFamily: type.regular,
  fontSize: 14,
  lineHeight: 24,
  color: Colors.black,
}

export const body2: TextStyle = {
  fontFamily: type.regular,
  fontSize: 14,
  lineHeight: 18,
  color: Colors.black,
}

export const body3: TextStyle = {
  fontFamily: type.regular,
  fontSize: 10,
  lineHeight: 12,
  color: Colors.black,
}

export const overline: TextStyle = {
  fontFamily: type.regular,
  fontSize: 12,
  lineHeight: 16,
  color: Colors.black,
}

export const button1: TextStyle = {
  fontFamily: type.bold,
  fontSize: 14,
  lineHeight: 16,
  color: Colors.black,
}

export const button2: TextStyle = {
  fontFamily: type.bold,
  fontSize: 12,
  lineHeight: 16,
  color: Colors.black,
}

export const label: TextStyle = {
  fontFamily: type.regular,
  fontSize: 12,
  lineHeight: 16,
  color: Colors.black,
}

export const cardTitle: TextStyle = {
  fontFamily: type.bold,
  fontSize: 14,
  lineHeight: 16,
  color: Colors.black,
}

export const cardSubtitle: TextStyle = {
  fontFamily: type.bold,
  fontSize: 12,
  lineHeight: 16,
  color: Colors.black,
}

export const subListItem: TextStyle = {
  fontFamily: type.regular,
  fontSize: 14,
  color: Colors.darkGrey,
}
