import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory, useParams } from 'react-router-dom'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import {
  BankAccountSelectors,
  BankTransferBenificiaryTypeEnum,
  BankTransferTypeEnum,
  ContactActions,
  TransferActions,
  TransferSelectors,
} from '@neo-commons/store'
import { TransactionUtils } from '@neo-commons/libraries'

import { PageDetails, PageDetailsProps } from '@neo-web/componentsOld'

import { Summary } from './Summary'

export const Update: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const param: { id: string } = useParams()
  const history = useHistory()

  const transfer = useSelector(TransferSelectors.byId(param.id))

  const selectedBankAccount = useSelector(BankAccountSelectors.selected, (oldBankAccount, newBankAccount) =>
    oldBankAccount.uuid === newBankAccount.uuid
  )

  const transferState = useSelector((state: StateTypes) => state?.transfer, (old, newv) =>
    old.prepare.uuid === newv.prepare.uuid && old.prepare.isUpdating === newv.prepare.isUpdating
  )

  const onGoingTransfer = transferState.prepare

  useEffect(() => {
    (async () => {
      await dispatch(ContactActions.list())

      if (param.id && (!onGoingTransfer.isUpdating || onGoingTransfer.uuid !== param.id)) {
        await dispatch(TransferActions.getRecurringTransferByUuid(param.id))
      }
    })()
  }, [dispatch, selectedBankAccount, onGoingTransfer])

  useEffect(() => {
    if (transfer && (!onGoingTransfer.isUpdating || onGoingTransfer.uuid !== param.id)) {
      dispatch(TransferActions.prepare({
        ...transfer,
        contactChannelUuid: transfer.contactChannelUuid ? transfer.contactChannelUuid : null,
        recipientAccountUuid: transfer.recipientAccountUuid ? transfer.recipientAccountUuid : null,
        benificiaryType: transfer.contactChannelUuid ? BankTransferBenificiaryTypeEnum.Recipient : BankTransferBenificiaryTypeEnum.Internal,
        transferType: transfer.endDate ? BankTransferTypeEnum.Scheduled : BankTransferTypeEnum.Repeated,
        isUpdating: true,
        recurrence: transfer.endDate && TransactionUtils.getRecurrenceIhm(transfer.frequency, transfer.period),
        recurrenceName: transfer.endDate !== '' && t(TransactionUtils.getLabelName(
          TransactionUtils.getRecurrenceIhm(transfer.frequency, transfer.period))),
      }))
      transfer.benificiaryType === BankTransferBenificiaryTypeEnum.Recipient
        ? dispatch(TransferSelectors.getContactChannel(transfer.contactChannelUuid!)) : BankAccountSelectors.byId(transfer.recipientAccountUuid)
    }
  }, [dispatch, transfer])

  const stepsContent: { path: string, content: PageDetailsProps }[] = [
    {
      path: Routes.Payments.transferUpdate.path,
      content: {
        title: t('app:pages:payments:transfer:summary:details'),
        onBack: () => history.push(Routes.Payments.nextTransfer.path),
        component: <Summary isEdit />,
      },
    },
  ]

  useEffect(() => {
    try {
      (async () => {
        await dispatch(ContactActions.list())
      })()
    } catch (error) {
      console.log(error)
    }
  }, [selectedBankAccount])

  return (
    <>
      {(transfer && transfer.uuid != null) &&
        stepsContent
          .map((value, index) => {
            return (
              <Route key={value.path} exact path={value.path}>
                <PageDetails
                  key={index}
                  {...value.content}
                />
              </Route>
            )
          })}
    </>
  )
}
