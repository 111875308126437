import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { OfferTypes, SubscriptionTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    SubscriptionTypes.CREATE_SUBSCRIPTION_FAILURE,
    SubscriptionTypes.ATTACH_CURRENT_USER_TO_PERSON_FAILURE,
    SubscriptionTypes.GET_SUBSCRIPTION_LIST_FAILURE,
    SubscriptionTypes.VALIDATE_AFFILIATION_FAILURE,
  ])
}

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    SubscriptionTypes.CREATE_SUBSCRIPTION_REQUEST,
    SubscriptionTypes.CREATE_SUBSCRIPTION_SUCCESS,
    SubscriptionTypes.CREATE_SUBSCRIPTION_FAILURE,
    SubscriptionTypes.GET_SUBSCRIPTION_LIST_REQUEST,
    SubscriptionTypes.GET_SUBSCRIPTION_LIST_FAILURE,
    SubscriptionTypes.GET_SUBSCRIPTION_LIST_SUCCESS,
    SubscriptionTypes.ATTACH_CURRENT_USER_TO_PERSON_REQUEST,
    SubscriptionTypes.ATTACH_CURRENT_USER_TO_PERSON_SUCCESS,
    SubscriptionTypes.ATTACH_CURRENT_USER_TO_PERSON_FAILURE,
    SubscriptionTypes.VALIDATE_AFFILIATION_REQUEST,
    SubscriptionTypes.VALIDATE_AFFILIATION_SUCCESS,
    SubscriptionTypes.VALIDATE_AFFILIATION_FAILURE,
    OfferTypes.GET_OFFER_LIST_REQUEST,
    OfferTypes.GET_OFFER_LIST_FAILURE,
    OfferTypes.GET_OFFER_LIST_SUCCESS,
  ])
}

export default [
  displayDropdownAlert$,
  reactiveLoader$,
]
