import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { DeviceActions, DeviceSelectors } from '@neo-commons/store'

import { CardMenuItem, CardMenuList, JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

export const PreferenceAndSecurity = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const dispatch = useDispatch()
  const deviceList = useSelector(DeviceSelectors.list)

  const menuCards: CardMenuItem[] = [
    {
      title: t('app:pages:myProfile:preferenceAndSecurity:alert:title'),
      content: t('app:pages:myProfile:preferenceAndSecurity:alert:subtitle'),
      disabled: false,
      onPress: () => history.push(Routes.Profile.notification.path),
      hidden: true,
    },
    {
      title: t('app:pages:myProfile:preferenceAndSecurity:security:title'),
      content: t('app:pages:myProfile:preferenceAndSecurity:security:subtitle'),
      onPress: () => history.push(Routes.Profile.security.path),
      disabled: false,
    },
    {
      title: t('app:pages:myProfile:preferenceAndSecurity:devices:title'),
      content: t('app:pages:myProfile:preferenceAndSecurity:devices:subtitle'),
      onPress: () => history.push(Routes.Profile.deviceAndBrowser.path),
      titleNumber: deviceList.length,
    },
    {
      title: t('app:pages:myProfile:preferenceAndSecurity:contact:title'),
      content: undefined,
      onPress: () => history.push(Routes.Profile.contact.path),
      disabled: false,
      hidden: true,
    },
    {
      title: t('app:pages:myProfile:preferenceAndSecurity:language:title'),
      content: undefined,
      onPress: () => history.push(Routes.Profile.language.path),
      disabled: false,
    },
  ]

  useEffect(() => {
    (async () => {
      try {
        await dispatch(DeviceActions.list())
      } catch (error) {
      }
    })()
  }, [dispatch])

  return (
    <PageDetails
      component={
        <PageContent justifyContent={JustifyContent.START}>
          <CardMenuList
            menus={menuCards}
          />
        </PageContent>
      }
      onBack={() => history.push(Routes.Profile.index.path)}
      title={t('app:pages:myProfile:preferenceAndSecurity:home:preference:title')}
    />
  )
}
