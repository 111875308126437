import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, JustifyContent, PageContent } from '@neo-web/componentsOld'

import { agentClientUserManager } from '../../../../Config/AgentClientUserManager'

export const Unauthorized: React.FunctionComponent = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const logout = (): void => {
    agentClientUserManager.signoutRedirect()
    history.push(Routes.SvaRegisterRoutes.index.path)
  }

  return (
    <PageContent
      justifyContent={JustifyContent.BETWEEN}
      title={t('app:pages:agentClientRegister:unauthorized:title')}
      button={
        <Button
          title={t('app:pages:agentClientRegister:unauthorized:back')}
          skin={ButtonSkin.DEFAULT}
          onPress={logout}
        />
      }
    />
  )
}
