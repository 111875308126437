import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Card, CardSkin, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import womanAtGrocery from '../../../Resources/Images/eligibility/woman_at_grocery_store.png'
import mastercard from '../../../Resources/Images/eligibility/mastercard.png'
import dsp2 from '../../../Resources/Images/eligibility/DSP2.png'
import threeDSecure from '../../../Resources/Images/eligibility/three_D_Secure.png'
import pciDss from '../../../Resources/Images/eligibility/pci_dss.png'

import '../AdvanceEligibility.scss'

export const AdvanceEligibilityPedagogy: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className='Eligibility_container'>
      <Typography2 typeface='h3' style={{ marginVertical: 16 }}>
        {t('neo-commons:eligibility:title')}
      </Typography2>
      <Card skin={CardSkin.DEFAULT} withShadow>
        <img src={womanAtGrocery} alt='modern times' style={{ width: '100%', borderRadius: 12 }} />
        <div className='Eligibility_description'>
          <div className='Eligibility_row'>
            <div className='Eligibility_col'>
              <Typography2 typeface='h5' style={{ fontFamily: 'Quicksand-Medium' }}>
                <Trans
                  i18nKey='neo-commons:eligibility:text'
                  components={[
                    <Typography2
                      typeface='h5'
                      key={0}
                      style={{ fontFamily: 'Quicksand-Bold', color: Colors.primary }}
                    />,
                    <Typography2
                      typeface='h5'
                      key={1}
                      style={{ fontFamily: 'Quicksand-Bold', color: Colors.primary }}
                    />,
                  ]}
                />
              </Typography2>
            </div>
          </div>
          <div className='EligibilityPedagogy_row'>
            <img src={mastercard} alt='mastercard' style={{ width: 100 }} />
          </div>
          <div className='Eligibility_spaceBetween'>
            <img src={threeDSecure} alt='3D secure' style={{ width: 32 }} />
            <img src={pciDss} alt='PCI DSS' style={{ width: 32 }} />
            <img src={dsp2} alt='DSP 2' style={{ width: 32 }} />
          </div>
          <div className='Eligibility_row'>
            <div className='Eligibility_buttonLarge'>
              <Button
                overrideStyleButton={{ marginTop: 32 }}
                skin={ButtonSkin.PRIMARY}
                title={t('neo-commons:eligibility:connect')}
                onPress={() => history.push(Routes.AdvanceEligibility.searchBank.path)}
              />
            </div>
          </div>
        </div>

      </Card>
    </div>
  )
}
