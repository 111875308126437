import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { StateTypes } from 'Store'
import { AppConfig } from 'Config/AppConfig'
import { Routes } from 'Routes/Routes'

import {
  CardActions,
  CardSelectors,
  CreditCardData,
  Dispatch,
  OrderUpdateType,
  PrepareCardOrder,
  UserActions,
  UserTypes,
} from '@neo-commons/store'
import { PasswordKeypadData, usePasswordKeypad } from '@neo-commons/hooks'

import { KeypadFieldDto, ValidatePasswordDto } from '@afone/neo-core-client/dist/models'

import { PageDetails, CodeConfirm, LazyLoadingContainer, PageContent } from '@neo-web/componentsOld'

export const Code: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()
  const { t } = useTranslation()

  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)
  const preparedCardOrder: PrepareCardOrder = useSelector(CardSelectors.preparedCardOrder, (p, c) => p?.cardUuid === c?.cardUuid)

  const signInData = useSelector((state: StateTypes) => state?.signIn.data)
  const passwordKeypadData = usePasswordKeypad({
    phone: signInData?.phone,
    phoneCountryCode: signInData?.countryCode,
    field: KeypadFieldDto.PIN,
    passwordSize: AppConfig.PASS_CODE_SIZE,
  })

  const checkCode = (passwordKeypadData: PasswordKeypadData) => {
    (async () => {
      try {
        const validatePassword: ValidatePasswordDto = {
          password: passwordKeypadData.password.map(e => e.value),
          phone: passwordKeypadData.phone,
          field: passwordKeypadData.field,
        }
        const prepare = {
          ...preparedCardOrder,
          updateType: OrderUpdateType.NONE,
          keypadDataCardPin: passwordKeypadData,
          ui: {
            ...preparedCardOrder.ui,
            isCodeConfirm: false,
          },
        }
        await dispatch(UserActions.checkPasscodeSequence(validatePassword))
        await dispatch(CardActions.prepare({ ...prepare }))
        passwordKeypadData.reset()
      } catch (error) {
        passwordKeypadData.reset(error)
      }
    })()
  }

  return (
    <PageDetails
      component={
        <LazyLoadingContainer events={[UserTypes.CHECK_PASSCODE_SEQUENCE_REQUEST]} className='OrderContainer'>
          <PageContent>
            <CodeConfirm
              title={t('app:pages:services:debitCard:order:code:description')}
              onValidate={checkCode}
              keypadData={passwordKeypadData}
            />
          </PageContent>
        </LazyLoadingContainer>
      }
      onClose={() => history.push(Routes.Services.index.path)}
      onBack={async () => {
        if (preparedCardOrder.isBundle) {
          await dispatch(CardActions.prepare({
            ...preparedCardOrder,
            updateType: OrderUpdateType.CHOICES_DEBIT_CARD,
            isBundle: false,
          }))
          history.push(Routes.Services.choicesDebitCard.path)
        } else if (selectedCard) {
          history.push(Routes.Services.physical.path)
        } else {
          await dispatch(CardActions.prepare({
            ...preparedCardOrder,
            updateType: OrderUpdateType.PHYSICAL_PROMO,
            isBundle: false,
          }))
          history.push(Routes.Services.physicalPromo.path)
        }
      }}
      title={t('app:pages:services:debitCard:order:code:title')}
    />
  )
}
