
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'

import { UserActions } from '@neo-commons/store'
import { CountryUtils } from '@neo-commons/libraries'

import { CountryDto, UserDto } from '@afone/neo-core-client/dist/models'

import { SelectCountry } from '@neo-web/componentsOld'

import './MyInfoFields.scss'

export interface MyTaxResidenceContentProps {
  user: UserDto
  onCloseModal: () => void
}

export const MyTaxResidenceContent: React.FC<MyTaxResidenceContentProps> = ({
  user,
  onCloseModal,
}: MyTaxResidenceContentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const countries: CountryDto[] = useSelector((state: StateTypes) =>
    state.config?.data?.currentConfig?.countries ?? [])
  const authorizedCountries = CountryUtils.filterByAuthorized(countries)

  const [taxResidence, setTaxResidence] =
    useState<CountryDto>(countries.find(country => country.isoCodeAlpha2 === user?.person?.taxDomiciliationCountryCode))

  const updateClientTaxResidence = (taxResidence: CountryDto) => {
    (async () => {
      try {
        await dispatch(UserActions.updateUserPerson(user, {
          ...user.person,
          taxDomiciliationCountryCode: taxResidence.isoCodeAlpha2,
        }))
        onCloseModal()
      } catch (error) {}
    })()
  }

  return (
    <div className='fieldContainer'>
      <SelectCountry
        countries={authorizedCountries}
        placeholder={t('app:pages:subscription:individual:taxResidence:title')}
        selectedCountry={taxResidence}
        setSelectedCountry={(value) => {
          setTaxResidence(value)
          updateClientTaxResidence(value as CountryDto)
        }}
      />
    </div>
  )
}
