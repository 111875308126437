import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { DeviceRecoveryTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_REQUEST,
    DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_SUCCESS,
    DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_FAILURE,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_SUCCESS,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
]
