import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'
import CustomIcon from 'UI/Components/CustomIcon'

import { Button, ButtonSkin, Card, CardSkin, Typography2 } from '@neo-commons/components'
import {
  BankAccountSelectors,
  CardActions,
  CardSelectors,
  CreditCardData,
  SubscriptionSelectors,
  TransferActions,
} from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { CreditCardUtils, SubscriptionUtils } from '@neo-commons/libraries'

import { AccountDto, CardStatusDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, PageContent } from '@neo-web/componentsOld'

import debitCard from '../../../Resources/Images/site/debit-card.png'
import './Pay.scss'

export const Pay: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cards: Required<CreditCardData[]> = useSelector(CardSelectors.list)
  const cardSelected: Required<CreditCardData> = useSelector(CardSelectors.selected)
  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const cardState = useSelector((state: StateTypes) => state.card)
  const transfer = useSelector((state: StateTypes) => state.transfer)
  const isCardBlocked = useMemo(() => cards?.length === 1 && CreditCardUtils.isBlocked(cardSelected), [cardSelected])

  const mainSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  const disabled =
    (SubscriptionUtils.isKycStepAnalysing(mainSubscription) || !SubscriptionUtils.isActive(mainSubscription))
  const noCard = cardState?.list?.loadedOnce && cardState?.list?.ids.length === 0
  const cardDisabled = disabled || noCard || isCardBlocked

  useEffect(() => {
    // If the user has only one card we select it by default
    if (!cardSelected && cards.length === 1) {
      dispatch(CardActions.setSelected(cards[0]!.uuid!))
    }
  }, [cards, cardSelected])

  useEffect(() => {
    (async () => {
      if ((!cardState.loading && !cardState?.list?.loadedOnce)) {
        try {
          await dispatch(CardActions.list())
        } catch (e) {}
      }
    })()
  }, [selectedBankAccount, dispatch, cardState])

  useEffect(() => {
    try {
      if (transfer.isDelete) {
        dispatch(TransferActions.reset())
      }
      if (transfer.list.shouldRefresh || !transfer.list.loadedOnce) {
        dispatch(TransferActions.getRecurringTransfers())
      }
    } catch (e) {}
  }, [])

  const goToDetailRoute = () => {
    (async () => {
      try {
        let url = Routes.Services.debitCard.path

        if (cardSelected?.status !== CardStatusDto.ACTIVE &&
          cardSelected?.status !== CardStatusDto.ACTIVE_NO_RENEWAL &&
          cardSelected?.status !== CardStatusDto.DO_NOT_HONOUR) {
          throw Error('Card is not active.')
        }
        url = generatePath(Routes.Services.debitCardDetail.path, { id: cardSelected.uuid })
        history.push(url)
      } catch (error) {}
    })()
  }

  return (
    <PageContent justifyContent={JustifyContent.START}>
      <Card withShadow skin={CardSkin.DEFAULT} containerStyle={{ padding: 12 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <img
            alt='debitCard'
            src={debitCard}
            style={{
              width: 200,
              marginLeft: -8,
              backgroundSize: 'contain',
              mixBlendMode: cardDisabled ? 'luminosity' : 'normal',
            }}
          />
        </div>
        <Button
          flat
          skin={cardDisabled ? ButtonSkin.OUTLINE_GRAY : ButtonSkin.PRIMARY}
          title={t('neo-commons:paymentCard:online:button')}
          leftIcon={cardDisabled ? <CustomIcon name='codeLock' size={28} color={Colors.disable} /> : null}
          onPress={cardDisabled ? null : () => goToDetailRoute()}
          containerStyle={{ marginTop: 16 }}
        />
      </Card>
      <Card withShadow skin={CardSkin.DEFAULT} containerStyle={{ padding: 12 }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          {disabled
            ? <CustomIcon name='changeArrows' size={48} color={Colors.disable} />
            : <CustomIcon name='changeArrows' size={48} color={Colors.primary} />}
          <Typography2
            typeface='h5'
            style={{
              marginTop: 16,
              marginBottom: 16,
              color: disabled ? Colors.darkGreyText : Colors.primary,
            }}
          >
            {t('neo-commons:paymentCard:transfer:content')}
          </Typography2>
        </div>
        <Button
          flat
          skin={disabled ? ButtonSkin.OUTLINE_GRAY : ButtonSkin.PRIMARY}
          title={t('neo-commons:paymentCard:transfer:button')}
          leftIcon={disabled ? <CustomIcon name='codeLock' size={28} color={Colors.disable} /> : null}
          onPress={disabled ? null : () => history.push(Routes.Payments.bankTransfer.path)}
          containerStyle={{ marginTop: 16 }}
        />
      </Card>
    </PageContent>
  )
}
