import React, { FunctionComponent, useState, useEffect } from 'react'
import { View, TouchableOpacity, Easing, Animated, ViewStyle, Platform } from 'react-native'

import { Colors } from '@neo-commons/styles'

import styles from './styles'

/**
 * SwitchInput Properties
 */
export interface NOSwitchInputProps {
  /** Switch boolean value */
  value?: boolean,
  /** Action dispatched on value changes */
  onChange?: (isOn: boolean) => void,
  /** Disabled status */
  disable?: boolean,
  /** Container Style */
  containerStyle?: ViewStyle
}

export const SwitchInput: FunctionComponent<NOSwitchInputProps> = ({ value, onChange, disable, containerStyle }) => {
  const [switchAnimate] = useState(new Animated.Value(!value ? 0 : 20))
  const [prevValue, setPrevValue] = useState<boolean|undefined>(undefined)
  const animation = Animated.timing(switchAnimate, {
    useNativeDriver: Platform.OS !== 'web',
    toValue: !value ? 0 : 20,
    duration: 100,
    easing: Easing.inOut(Easing.sin),
  })

  useEffect(() => {
    if (prevValue !== value) animation.start()
    setPrevValue(value)
  }, [value])

  const lineStateStyle = disable ? { backgroundColor: Colors.darkGray }
    : (value ? { backgroundColor: Colors.primaryGhost } : { backgroundColor: Colors.ghostGrayLevel2 })
  const circleStateStyle = value ? { backgroundColor: Colors.primary } : { backgroundColor: Colors.bgGray }

  return (
    <TouchableOpacity
      onPressIn={() => !disable && onChange && onChange(!value)}
      style={[styles.container, containerStyle]}
      accessibilityLabel='switchInput'
    >
      <View style={[styles.switchContainer, lineStateStyle]}>
        <Animated.View style={[
          styles.switchCircle, circleStateStyle, { transform: [{ translateX: switchAnimate }] }]}
        />
      </View>
    </TouchableOpacity>
  )
}
