import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'
import { TransferPolicy } from 'Policies'

import { BankAccountActions } from '@neo-commons/store'
import { NumbersUtils } from '@neo-commons/libraries'

import { Banner, SuccessContent } from '@neo-web/componentsOld'

import { creditSuccessBanner } from '../../../../Resources/Images'

interface StepValidateProps {
  amount: number
}

export const CreditSuccess: React.FC<StepValidateProps> = (props: StepValidateProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const transfer = useSelector((state: StateTypes) => state?.transfer, (oldTransfer) =>
    oldTransfer.create !== null,
  )

  return (
    <SuccessContent
      header={<Banner><img src={creditSuccessBanner} alt='banner' /></Banner>}
      title={t('app:pages:credit:validate:titleMessage',
        { amount: NumbersUtils.displayPriceForHuman(props.amount) },
      )}
      buttonAction={{
        text: TransferPolicy.canStep3(transfer)
          ? t('app:pages:credit:validate:backToTransfer')
          : t('app:global:backToSynthesis'),
        action: async () => {
          await dispatch(BankAccountActions.list())
          TransferPolicy.canStep3(transfer)
            ? history.push(Routes.Payments.amount.path)
            : history.push(Routes.Synthesis.index.path)
        },
      }}
    />
  )
}
