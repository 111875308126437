import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Page } from '@neo-web/componentsOld'
import { RoutesUtils } from '@neo-web/utils'

import { MyTransactions } from './MyTransactions'
import { DetailTransaction } from './Transaction/DetailTransaction'
import { TransactionProof } from './Transaction/TransactionProof'
import { TransactionRefund } from './Transaction/Refund/Refund'
import { TelecollectionsList } from './Telecollection/TelecollectionsList'
import { DetailTelecollection } from './Telecollection/DetailTelecollection'

export const Synthesis: React.FC = () => {
  const { t } = useTranslation()

  const renderMyTransactions = useMemo(() =>
    <Page pages={[
      {
        path: Routes.Synthesis.transactions.path,
        component: <MyTransactions />,
        name: t('app:pages:synthesis:transactions'),
      },
    ]}
    />, [])

  return (
    <Switch>
      <Route
        path={[Routes.Synthesis.transactions.path]}
        exact
        component={() => renderMyTransactions}
      />
      <Route exact path={Routes.Synthesis.telecollections.path} component={TelecollectionsList} />
      <Route exact path={Routes.Synthesis.transactionDetails.path} component={DetailTransaction} />
      <Route exact path={Routes.Synthesis.transactionProof.path} component={TransactionProof} />
      <Route exact path={Routes.Synthesis.telecollectionDetails.path} component={DetailTelecollection} />
      <Route
        path={RoutesUtils.getAllRoutes(Routes.Synthesis.TransactionRefund)}
        component={TransactionRefund}
      />
    </Switch>
  )
}
