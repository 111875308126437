import React, { FunctionComponent, useLayoutEffect, useState } from 'react'
import { View, Text, Dimensions } from 'react-native'

import { Colors } from '@neo-commons/styles'

import { Icon, IconProps, IconTypes, Typography } from '..'

import styles from './styles'

export enum Status {
  FAIL='FAIL',
  VALID='VALID',
  CURRENT='CURRENT',
}

export interface Step {
  title?: () => string;
  description?: string;
  icon?: IconProps;
  current?: boolean;
  status?: Status;
}

/**
 * Step Properties
 */
export interface StepperProps {
  /**  List of step title */
  steps: Step[]
}

export const Stepper: FunctionComponent<StepperProps> = ({ steps }) => {
  const [, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    function updateSize () {
      setSize([Dimensions.get('window').width, Dimensions.get('window').height])
    }
    Dimensions.addEventListener('change', updateSize)
    updateSize()
    return () => Dimensions.removeEventListener('change', updateSize)
  }, [])

  return (
    <View style={styles.steps}>
      {steps.map((step, i) => {
        const currentHappened = step.status === Status.CURRENT
        return (
          <View key={i} style={styles.stepItem} testID={`stepper${i}`}>
            <View style={styles.stepIconContent}>
              <Text style={[styles.stepIcon,
                step.status === Status.VALID || currentHappened
                  ? { color: Colors.primary }
                  : step.status === Status.FAIL
                    ? { color: Colors.error } : { color: Colors.secondary },
              ]}
              >
                {step.status === Status.VALID
                  ? <Icon name='check' type={IconTypes.NEOFONT} size={30} />
                  : step.status === Status.FAIL
                    ? <Icon name='x-octagon' type={IconTypes.FEATHER} size={30} />
                    : step.icon ? <Icon {...step.icon} /> : i}
              </Text>
            </View>
            {steps.length - 1 !== i &&
              <View style={styles.stepDividerContainer}>
                <View style={[styles.stepDivider,
                  step.status === Status.VALID && !currentHappened
                    ? { borderColor: Colors.primary }
                    : step.status === Status.FAIL && !currentHappened
                      ? { borderColor: Colors.error } : {}]}
                />
              </View>}
            {Dimensions.get('window').width > 970 &&
              <View style={styles.stepContent}>
                {step.title &&
                  <Text style={styles.stepTitle}>
                    <Typography typeface='subtitle' style={styles.stepTitleTypography}>
                      {step.title()}
                    </Typography>
                  </Text>}
                {step.description &&
                  <Text style={styles.stepDescription}>
                    <Typography typeface='smallText'>
                      {step.description}
                    </Typography>
                  </Text>}
              </View>}
          </View>
        )
      })}
    </View>
  )
}
