import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'
import dayjs from 'dayjs'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { PosActions, PosSelectors } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { POSUtils } from '@neo-commons/libraries'

import {
  PosDispatchDto,
  PosDispatchStatusDto,
  PosDto,
  PosReturnDetailDto,
} from '@afone/neo-core-client/dist/models'

import { CardDetails, CardDetailsItem, JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'
import { FileUtils } from '@neo-web/utils'

export const DispatchDetail: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const param: any = useParams()

  const dispatches: PosDispatchDto[] = useSelector(PosSelectors.listDispatches)
  const dispatched: any = dispatches?.find(d => d.uuid === param?.id)
  const returned: PosReturnDetailDto = dispatched?.posReturnList?.[0]
  const returnedPos: PosDto[] = dispatched?.posReturnList.map(r => r.pos)

  useEffect(() => {
    if (!dispatched) {
      history.push(Routes.Services.dispatchList.path)
    }
  }, [])

  const listItems: CardDetailsItem[] = [
    {
      label: t('neo-commons:pages:services:pos:return:dispatch:detail:label:posReturned'),
      value: returnedPos &&
        <>
          {returnedPos.map(p =>
            <Typography key={p.uuid} typeface='smallText'>
              {(p?.serialNumber
                ? `${t('app:pages:services:pos:detail:posSerialNumberShort')}: ${p.serialNumber} `
                : '') + `(${p?.name ?? p.label})`}
            </Typography>
          )}
        </>,
    }, {
      label: t('neo-commons:pages:services:pos:return:dispatch:detail:label:reason'),
      value: returned && !POSUtils.returnReasonFailure(returned?.reason)
        ? t('neo-commons:pages:services:pos:return:dispatch:detail:reason:termination')
        : t('neo-commons:pages:services:pos:return:dispatch:detail:reason:failure'),
    }, {
      label: t('neo-commons:pages:services:pos:return:dispatch:detail:label:date'),
      value: dispatched?.createdDate && t('app:global:field:dateFormat', {
        date: dayjs(dispatched.createdDate).format('L'),
        hours: dayjs(dispatched.createdDate).format('HH:mm'),
        interpolation: { escapeValue: false },
      }),
    },
  ]

  const downloadCoupon = () => {
    (async () => {
      try {
        const res: any = await dispatch(PosActions.getDispatchLabel(dispatched?.uuid))
        if (res?.file?.fileEncoded) {
          FileUtils.openBase64NewTab(
            res.file.fileEncoded,
            res.file?.name ?? 'Return',
            'pdf'
          )
        }
      } catch (_) {}
    })()
  }

  const cancelDispatch = () => {
    (async () => {
      try {
        await dispatch(PosActions.cancelDispatch(dispatched?.uuid))
        await dispatch(PosActions.listDispatches())
        history.push(Routes.Services.pos.path)
      } catch (_) {}
    })()
  }

  return (
    <PageDetails
      title={t('neo-commons:pages:services:pos:return:dispatch:detail:pageTitle')}
      onBack={() => history.push(Routes.Services.dispatchList.path)}
      component={
        <PageContent
          justifyContent={JustifyContent.START}
          footer={
            <>
              <Button
                skin={ButtonSkin.DEFAULT}
                title={t('neo-commons:pages:services:pos:return:success:buttonCoupon')}
                rightIcon={<Icon name='download' type={IconTypes.FEATHER} size={20} color={Colors.primary} />}
                overrideTextStyleButton={{ justifyContent: 'space-between' }}
                onPress={() => downloadCoupon()}
              />
              {dispatched?.status === PosDispatchStatusDto.IN_PROGRESS &&
                <Button
                  skin={ButtonSkin.OUTLINE_RED}
                  title={t('neo-commons:pages:services:pos:return:dispatch:detail:cancelButton')}
                  containerStyle={{ marginTop: 12 }}
                  onPress={() => cancelDispatch()}
                />}
            </>
          }
        >
          <CardDetails
            title={t('neo-commons:pages:services:pos:return:dispatch:detail:cardTitle')}
            list={listItems}
          />
        </PageContent>
      }
    />
  )
}
