import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'
import { TransferPolicy } from 'Policies/TransferPolicy'
import dayjs from 'dayjs'

import { BankTransferTypeEnum, TransferActions } from '@neo-commons/store'
import { RecurringTransferValues, TransactionUtils } from '@neo-commons/libraries'
import { Button, ButtonSkin, Calendar, CalendarSkin, Typography, Typography2 } from '@neo-commons/components'

import { RSSelect } from '@neo-web/componentsOld'

import './Scheduled.scss'

export const Scheduled: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const transferState = useSelector((state: StateTypes) => state?.transfer)
  const onGoingTransfer = transferState.prepare
  const [selectedDate, setSelectedDate] = useState(onGoingTransfer?.startDate)
  const [periodChoice, setPeriodChoice] = useState<RecurringTransferValues>(onGoingTransfer?.recurrence)

  const goNext = async () => {
    await dispatch(TransferActions.prepare({
      ...onGoingTransfer,
      startDate: selectedDate,
      endDate: onGoingTransfer.transferType === BankTransferTypeEnum.Scheduled ? selectedDate : '',
      recurrenceName: onGoingTransfer.transferType === BankTransferTypeEnum.Repeated
        ? t(TransactionUtils.getLabelName(periodChoice))
        : '',
      recurrence: onGoingTransfer.transferType === BankTransferTypeEnum.Repeated ? periodChoice : undefined,
    }))

    history.push(Routes.Payments.summary.path)
  }

  return (
    <div className='Scheduled_container'>
      <div className='Scheduled_item Scheduled_text'>
        {TransferPolicy.isRepeated(transferState) &&
          <Typography2 typeface='overline'>
            {t('app:pages:payments:transfer:reccuring:first:transfer')}
          </Typography2>}
      </div>
      <div className='Scheduled_item'>
        <Calendar
          defaultDate={onGoingTransfer.startDate || dayjs().add(1, 'days').format('YYYY-MM-DD')}
          minDate={dayjs().add(1, 'days').format('YYYY-MM-DD')}
          skin={CalendarSkin.BORDERERED}
          onChange={date => setSelectedDate(date)}
        />
      </div>

      <div className='Scheduled_item Scheduled_content'>
        <Typography typeface='subtitle'>
          {t('app:pages:payments:transfer:scheduled:information')}
        </Typography>
      </div>
      {TransferPolicy.isRepeated(transferState) &&
        <>
          <div className='Scheduled_item Scheduled_text'>
            <Typography2 typeface='overline'>
              {t('app:pages:payments:transfer:reccuring:repeat')}
            </Typography2>
          </div>
          <div className='Scheduled_item'>
            <RSSelect
              placement='auto'
              value={periodChoice}
              data={[
                {
                  label: t(TransactionUtils.getLabelName(RecurringTransferValues.EVERY_7_DAYS)),
                  value: RecurringTransferValues.EVERY_7_DAYS,
                },
                {
                  label: t(TransactionUtils.getLabelName(RecurringTransferValues.EVERY_14_DAYS)),
                  value: RecurringTransferValues.EVERY_14_DAYS,
                },
                {
                  label: t(TransactionUtils.getLabelName(RecurringTransferValues.EVERY_MONTHS)),
                  value: RecurringTransferValues.EVERY_MONTHS,
                },
              ]}
              onChange={(periodChoice) => {
                setPeriodChoice(periodChoice)
              }}
              appearance='subtle'
              searchable={false}
              placeholder={t('app:pages:payments:transfer:reccuring:recurrence')}
            />
          </div>
        </>}
      <div className='Scheduled_action'>
        <Button
          title={t('app:pages:payments:transfer:scheduled:cancel')}
          skin={ButtonSkin.OUTLINE_PRIMARY}
          onPressOut={() => {
            history.push(onGoingTransfer.isUpdating ? generatePath(
              Routes.Payments.transferUpdate.path,
              { id: onGoingTransfer.uuid }
            ) : Routes.Payments.index.path)
          }}
        />

        <Button
          title={t('app:pages:payments:transfer:scheduled:validate')}
          skin={ButtonSkin.PRIMARY}
          disabled={selectedDate === undefined ||
            TransferPolicy.isRepeated(transferState) ? !periodChoice : false}
          onPressOut={() => {
            (!TransferPolicy.isRepeated(transferState) || periodChoice) && goNext()
          }}
        />
      </div>
    </div>
  )
}
