import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { Typography2 } from '@neo-commons/components'

import {
  CardDetails,
  CardDetailsItem,
  CardMenuItem,
  CardMenuList,
  JustifyContent,
  PageContent,
  PageDetails,
} from '@neo-web/componentsOld'

import { ServiceClientModal } from './ServiceClientModal'

export const HelpAndEmergency = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const configState = useSelector((state: StateTypes) => state.config)

  const menuCards: CardMenuItem[] = [
    {
      title: t('app:pages:profile:help:home:faq'),
      content: undefined,
      onPress: () => window.open(configState?.data?.currentConfig?.faq, '_blank'),
      disabled: false,
    },
    {
      title: t('app:pages:profile:help:home:clientservice'),
      content: undefined,
      onPress: () => {
        setIsOpenModal(true)
      },
      disabled: false,
    },
  ]

  const menuCardsEmergency: CardMenuItem[] = [
    {
      title: t('app:pages:profile:help:home:emergency:title'),
      content: t('app:pages:profile:help:home:emergency:subtitle'),
      disabled: false,
      onPress: () => history.push(Routes.Opposition.index.path),
    },
  ]

  const listItems: CardDetailsItem[] = [{
    value: <Typography2 typeface='body2' style={{ textAlign: 'center', padding: 15 }}>{t('app:pages:profile:help:home:no:ticket')}</Typography2>,
  }]

  return (
    <>
      <PageDetails
        component={
          <PageContent justifyContent={JustifyContent.START}>
            <CardDetails
              title={t('app:pages:profile:help:home:fraud:ticket')}
              list={listItems}
              justifyBetween
            />
            <CardMenuList
              menus={menuCards}
            />
            <CardMenuList
              title={t('app:pages:profile:help:home:fraud:menu')}
              menus={menuCardsEmergency}
            />
          </PageContent>
        }
        onBack={() => history.push(Routes.Profile.index.path)}
        title={t('app:pages:profile:help:home:page:title')}
      />
      {isOpenModal &&
        <ServiceClientModal
          show={isOpenModal}
          onClose={() => setIsOpenModal(false)}
        />}
    </>
  )
}
