import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'

import { Responsive } from 'Config/Responsive'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { OfferActions, OfferSelectors, OfferTypes } from '@neo-commons/store'
import { Typography2 } from '@neo-commons/components'

import { ClientTypeDto, OfferDto } from '@afone/neo-core-client/dist/models'

import { LazyLoadingContainer } from '@neo-web/componentsOld'

import { DiscountCodeModal, NewOfferCard } from '../../Components'

import './NewOffersContainer.scss'

interface NewOfferCardContainerProps {
  clientType: ClientTypeDto
  offers: OfferDto[]
  initialSlide?: number
  onOfferSelect: (offer, discount) => void
}

export const NewOffersContainer: React.FC<NewOfferCardContainerProps> = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { initialSlide } = props
  const offerDiscountCode = useSelector(OfferSelectors.discountCode)
  const [discountCode, setDiscountCode] = useState(offerDiscountCode)
  const [openModalDiscountCode, setOpenModalDiscountCode] = useState<boolean>(false)

  const onApplyPromoCode = async (discountCode) => {
    try {
      await dispatch(OfferActions.list(discountCode, undefined, props.clientType))
      setDiscountCode(discountCode)
      setOpenModalDiscountCode(false)
      AnalyticsHandler.track(AnalyticsEvents.signUp.promoCodeApplied,
        {
          discount_code: discountCode,
        })
    } catch (err) {
      setOpenModalDiscountCode(false)
    }
  }

  const settings = {
    className: 'center',
    dots: true,
    arrows: false,
    centerMode: true,
    infinite: false,
    centerPadding: '10px',
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlide,
    speed: 500,
    customPaging: () => (
      <div className='Offers_dots' />
    ),
    responsive: [
      { breakpoint: Responsive.WITH_MOBILE, settings: { slidesToShow: 1 } },
    ],
  }

  return (
    <LazyLoadingContainer events={[OfferTypes.GET_OFFER_LIST_REQUEST]}>
      <div style={{ textAlign: 'center' }}>
        <Typography2 typeface='h3'>
          {t('app:pages:subscription:global:offers:openAccount')}
        </Typography2>
      </div>
      <div className='Offers_container'>
        <Slider {...settings}>
          {props.offers?.map((offer, index) => {
            return (
              <NewOfferCard
                key={index}
                offer={offer}
                onOfferSelect={(offer) => props.onOfferSelect(offer, discountCode)}
                openDiscountCodeModal={() => setOpenModalDiscountCode(true)}
              />
            )
          })}
        </Slider>
        <DiscountCodeModal
          show={openModalDiscountCode}
          onClose={() => setOpenModalDiscountCode(false)}
          onValidate={(discountCode) => onApplyPromoCode(discountCode)}
        />
      </div>
    </LazyLoadingContainer>
  )
}
