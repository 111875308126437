import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { StepComponentProps } from '@neo-commons/policies'
import { POSOrderStep, POSOrderSteps } from '@neo-commons/policies/src/POS/Order'
import {
  BankAccountActions,
  OfferSelectors,
  PosActions,
  PosSelectors,
  PreparePOSOrder,
  SubscriptionActions,
} from '@neo-commons/store'
import { PosProductData, POSUtils } from '@neo-commons/libraries'

import { OfferDto } from '@afone/neo-core-client/models'
import { OfferTypeDto, PosDto } from '@afone/neo-core-client/dist/models'

import { Banner, SuccessContent } from '@neo-web/componentsOld'

import { bannerPhysic } from '../../../../../Resources/Images'

type POSOrderModelChoiceProps = StepComponentProps<POSOrderSteps[POSOrderStep.Success]>

export const Success: React.FC<POSOrderModelChoiceProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const preparedPOSOrder: PreparePOSOrder = useSelector(PosSelectors.prepare)
  const pos: PosDto[] = useSelector(PosSelectors.listPos())
  const multiplePos = preparedPOSOrder?.posProducts?.length > 1 ||
    (preparedPOSOrder?.posProducts?.[0]?.data as PosProductData)?.quantity > 1

  const offers: OfferDto[] = useSelector(OfferSelectors.listByType(OfferTypeDto.POS))

  const updateData = async () => {
    await dispatch(BankAccountActions.list())
    await dispatch(SubscriptionActions.list())
    POSUtils.reinitProductQuantity(offers)
  }

  return (
    <SuccessContent
      header={<Banner><img src={bannerPhysic} alt='banner' /></Banner>}
      title={multiplePos
        ? t('app:pages:services:pos:order:success:multi:title')
        : t('app:pages:services:pos:order:success:title')}
      description={multiplePos
        ? t('app:pages:services:pos:order:success:multi:description')
        : t('app:pages:services:pos:order:success:description')}
      buttonAction={{
        text: multiplePos
          ? t('app:pages:services:pos:order:managePos')
          : t('app:pages:services:pos:order:accessSubaccount'),
        action: () =>
          (async () => {
            try {
              if (pos.length === 0) {
                await updateData()
                nextStep()
              } else {
                const newPosIds = preparedPOSOrder?.newPosIds
                await updateData()
                await dispatch(PosActions.reset())
                history.push(Routes.Services.reassignPos.path, newPosIds)
              }
            } catch (_) {}
          })(),
      }}
    />
  )
}
