import { AnyAction, Reducer } from 'redux'
import { NeobankApi } from '@neo-commons/services'
import { AccountStatementDto } from '@afone/neo-core-client/dist/models'
import i18next from 'i18next'

import { initialResourceState, State } from '../utils'
import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Statement = AccountStatementDto

const {
  resourceActionTypes: StatementActionTypes,
  resourceReducer: StatementResourceReducer,
  resourceAction: StatementAction,
  resourceSelector: StatementResourceSelector,
} = ResourceStateFactory<Statement, 'statement'>(state => state.statement, 'statement')

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

export const StatementTypes = {
  ...StatementActionTypes,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

export const StatementSelectors = {
  ...StatementResourceSelector,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */
const errorKeyTranslate = 'errors:internalTechnicalIssue'

const getStatementByAccountUuid = (accountUuid: string, page = 0) => {
  const perPage = 20
  return async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    if (!getState().statement.loading) {
      dispatch({ type: StatementTypes.LIST_STATEMENT_REQUEST })
      try {
        const payload = await NeobankApi.getInstance().bankAccountApi.getAccountStatementByAccountUuid(
          accountUuid,
          perPage,
          page
        )
        const noResult = payload.status === 204
        const paginationEnded = noResult || payload.status === 200
        const data = noResult ? [] : payload.data.map(statement => ({
          ...statement,
          id: statement.documentUuid,
        }))
        dispatch({
          type: StatementTypes.LIST_STATEMENT_SUCCESS,
          data,
          page,
          perPage,
          paginationEnded,
        })
      } catch (error) {
        const errorMessage = error.message ?? i18next.t(errorKeyTranslate)
        dispatch({ type: StatementTypes.LIST_STATEMENT_FAILURE, errorMessage })
      }
    }
  }
}

export const StatementActions = {
  ...StatementAction,
  getStatementByAccountUuid,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type StatementState = ResourceState<Statement>

const initialState: StatementState = {
  ...initialResourceState,
  list: {
    ...initialResourceState.list,
    filter: {
      userUuid: undefined,
      iban: undefined,
      name: undefined,
    },
    paginationEnded: false,
  },
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const statement: Reducer = (state = initialState, action: AnyAction): StatementState => {
  switch (action.type) {
    default:
      return {
        ...StatementResourceReducer(state, action, {
          identifier: 'id',
          isPaginate: true,
          initialState,
        }),
      }
  }
}
