import { StyleSheet } from 'react-native'

import { Colors, Commons, Typo } from '@neo-commons/styles'

const styles = StyleSheet.create({
  // Group
  groupContainer: {
    ...Commons.cardContainer,
    ...Commons.gutter,
    ...Commons.marginBelowTitle,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  groupTitle: {
    ...Typo.bigTitle,
    fontSize: 16,
    marginBottom: 8,
    paddingBottom: 12,
    borderBottomColor: Colors.ghostGrayLevel2,
    borderBottomWidth: 1,
  },
  groupItemName: {
    ...Typo.bigTitle,
    fontSize: 16,
  },
  groupItemContainer: {
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  // Check / radio
  checkCardContainer: {
    ...Commons.cardContainer,
    ...Commons.gutter,
    ...Commons.marginBelowTitle,
    padding: 17,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkContainer: {
    flexDirection: 'row',
  },
  checkTitle: {
    ...Typo.bold,
    fontSize: 16,
    marginBottom: 4,
  },
  checkSubTitle: {
    ...Typo.tabLabel,
    fontSize: 16,
    color: Colors.primary,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: Colors.primary,
  },
  checkItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export default styles
