import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import {
  CardSelectors,
  PrepareCardOrder,
  CardActions,
  Subscription,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { OfferUtils, SubscriptionUtils } from '@neo-commons/libraries'

import { Banner, PageDetails, SuccessContent } from '@neo-web/componentsOld'

import { bannerAmbassador, bannerPhysic, creditSuccessBanner } from '../../../../../Resources/Images'

type configPage = {
    title: string
    paragraph1: string,
    paragraph2?: string,
    banner: string,
}

export const Validate: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const preparedCardOrder: PrepareCardOrder = useSelector(CardSelectors.preparedCardOrder)
  const mainSubscription: Subscription = useSelector(SubscriptionSelectors.defaultOne)

  // Todo replace this and translate message offer when offer type bo is update
  const isAmbassador = OfferUtils.isAmbassador(preparedCardOrder?.offer?.offerCode)

  let configPage: configPage

  switch (true) {
    case isAmbassador:
      configPage = {
        title: 'app:pages:services:debitCard:order:validate:youAreAmbassador',
        paragraph1: 'app:pages:services:debitCard:order:validate:activateYourCard',
        paragraph2: 'app:pages:services:debitCard:order:validate:digitalIsAvailable',
        banner: bannerAmbassador,
      }
      break
    case !SubscriptionUtils.isActive(mainSubscription):
      configPage = {
        title: 'app:pages:services:debitCard:order:validate:deliveryPlanned',
        paragraph1: 'app:pages:services:debitCard:order:validate:deliveryPlannedMessage',
        banner: creditSuccessBanner,
      }
      break
    case preparedCardOrder?.withPhysicalCard && !preparedCardOrder?.isBundle:
      configPage = {
        title: 'app:pages:services:debitCard:order:validate:yourPhysicIsOrder',
        paragraph1: 'app:pages:services:debitCard:order:validate:activateYourCard',
        banner: bannerPhysic,
      }
      break
    case !preparedCardOrder?.withPhysicalCard:
      configPage = {
        title: 'app:pages:services:debitCard:order:validate:digitalIsCreated',
        paragraph1: 'app:pages:services:debitCard:order:validate:usePhoneToPay',
        banner: bannerPhysic,
      }
      break
    case preparedCardOrder?.isBundle && !isAmbassador:
      configPage = {
        title: 'app:pages:services:debitCard:order:validate:digitalAndPhysicalOrdered',
        paragraph1: 'app:pages:services:debitCard:order:validate:whenPhysicalReceived',
        paragraph2: 'app:pages:services:debitCard:order:validate:digitalIsAvailable',
        banner: bannerPhysic,
      }
      break
  }

  const getButtonTitle = () => {
    let title = ''

    switch (true) {
      case isAmbassador:
        title = 'app:pages:services:debitCard:order:validate:seeAmbassadorCard'
        break
      case !SubscriptionUtils.isActive(mainSubscription):
        title = 'neo-commons:global:iunderstand'
        break
      case !preparedCardOrder?.withPhysicalCard || preparedCardOrder?.isBundle:
        title = 'app:pages:services:debitCard:order:validate:seeDigitalCard'
        break
      case preparedCardOrder?.withPhysicalCard:
        title = 'app:pages:services:debitCard:order:validate:goToServices'
        break
    }

    return t(title)
  }

  return (
    <PageDetails
      onBack={false}
      onClose={() => history.push(Routes.Services.index.path)}
      component={
        <SuccessContent
          header={<Banner><img src={configPage.banner} alt='banner' /></Banner>}
          title={t(configPage.title)}
          description={t(configPage.paragraph1) + (configPage?.paragraph2 ? `\n\n${t(configPage.paragraph2)}` : '')}
          buttonAction={{
            text: getButtonTitle(),
            action: () =>
              (async () => {
                await dispatch(CardActions.list())
                history.push(Routes.Services.index.path)
              })(),
          }}
        />
      }
    />
  )
}
