import { useMemo } from 'react'
import { StyleSheet, ViewStyle, TextStyle, ImageStyle } from 'react-native'

import { reduce, isString } from 'lodash'

type TStyle = ViewStyle | TextStyle | ImageStyle

const SkinUtilsFactory = (skin: string) => ({
  select: (skins: string | string[], styles: TStyle) => (
    ((skins.length && skins.includes(skin)) || skins === skin) ? styles : {}
  ),
})
const StyleNameUtilsFactory = () => ({
  select: (names: string | string[], styles: TStyle) => (
    // Removing TS2464 with any type
    isString(names)
      ? { [names as any]: styles }
      : reduce(names, (acc, name) => {
        acc[name] = styles
        return acc
      }, {})
  ),
})

export const createStylesheet = (
  skinnedStylesheet:
    ({ Skin, StyleName, props }: {
        Skin: ReturnType<typeof SkinUtilsFactory>,
        StyleName: ReturnType<typeof StyleNameUtilsFactory>,
        props: any
    }) => ReturnType<typeof StyleSheet.create>
) => {
  return ({ skin, props }: { skin: string, props: any }) => {
    const SkinUtils = SkinUtilsFactory(skin)
    const StyleNameUtils = StyleNameUtilsFactory()
    return skinnedStylesheet({ Skin: SkinUtils, StyleName: StyleNameUtils, props })
  }
}

export const useStylesheet = (
  stylesheet: ReturnType<typeof createStylesheet>,
  params: { skin: string, props: any }
) => useMemo(() => stylesheet(params), [params.skin, params.props])
