import i18next from 'i18next'
import IBAN from 'iban'

export interface ValidatorResponseType {
  isValid: boolean,
  errorMessage?: string
}

export type SyncValidatorFunction = (text: string) => ValidatorResponseType
export type AsyncValidatorFunction = (text: string) => Promise<ValidatorResponseType>
export type ValidatorFunction = SyncValidatorFunction | AsyncValidatorFunction
export type CheckTextType = {
  isValid: boolean,
  errorMessage?: string,
}

i18next.loadNamespaces('validation')

/**
   * Generic methods for validate some value and input.
   *
   */
export class Validators {
  /**
   * Check if the field is not empty
   *
   * @param {string} field - String to test
   * @returns {CheckTextType} return if is true, or false with error message
   *
   */
  public static fieldNotEmpty = (field: string): CheckTextType => {
    if (field === '') {
      return {
        isValid: false,
        errorMessage: i18next.t('validation:required'),
      }
    }

    return { isValid: true }
  }

  /**
   * Check if the text correspond to the regex
   *
   * @param {string} text - String to test
   * @param {RegExp} textFormat - Regex use for test
   * @param {string} errorMessage - Error message to display if false
   * @returns {CheckTextType} return if is true, or false with error message
   * @example
   * Validators._checkText(text, /^[0-9]{3}$/, i18next.t('validation:bad_cvv'))
   *
   */
  public static _checkText = (text: string, textFormat: RegExp, errorMessage: string): CheckTextType => {
    if (!text || !textFormat.test(text.trim())) {
      return {
        isValid: false,
        errorMessage,
      }
    }

    return { isValid: true }
  }

  /**
   * Check if the IBAN is valid
   *
   * @param {string} text - Iban to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isCorrectIban: SyncValidatorFunction = text => {
    const badIbanFormat = 'validation:bad_iban_format'
    if (text && !IBAN.isValid(text) && text.length > 15) {
      return {
        isValid: false,
        errorMessage: i18next.t(badIbanFormat),

      }
    }

    if (!IBAN.isValid(text)) {
      return {
        isValid: false,
        errorMessage: i18next.t(badIbanFormat),
      }
    }

    return { isValid: true }
  }

  /**
   * Check if the email is valid
   *
   * @param {string} text - email to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isEmail: SyncValidatorFunction = text => {
    const re = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return Validators._checkText(text, re, i18next.t('validation:invalidEmail'))
  }

  /**
   * Check if the Bic is valid
   *
   * @param {string} text - bic to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isCorrectBic: ValidatorFunction = text => {
    const re = /^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/
    return Validators._checkText(text, re, i18next.t('validation:invalidBic'))
  }

  /**
   * Check if the name is valid
   *
   * @param {string} text - name to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isName: SyncValidatorFunction = text => {
    const re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/
    return Validators._checkText(text, re, i18next.t('validation:bad_name'))
  }

  /**
   * Check if the cvv is valid
   *
   * @param {string} text - cvv to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isCvv: SyncValidatorFunction = text => {
    const re = /^[0-9]{3}$/
    return Validators._checkText(text, re, i18next.t('validation:bad_cvv'))
  }

  /**
   * Check if the cardDate is valid
   *
   * @param {string} text - cardDate to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isCardDate: SyncValidatorFunction = text => {
    const re = /^((0[1-9])|(1[0-2]))[/][2-9][0-9]$/
    const splitedDate = text.split('/')
    const month = +splitedDate[0]
    const year = splitedDate[1]
    const today = new Date()
    let isFutur = false

    if ((month >= today.getMonth() && year === today.getFullYear().toString().substr(-2)) ||
      year > today.getFullYear().toString().substr(-2)) {
      isFutur = true
    }
    if (!re.test(text) || !isFutur) {
      return {
        isValid: false,
        errorMessage: i18next.t('validation:bad_credit_card_date'),
      }
    }
    return { isValid: true }
  }

  /**
   * Check if the card number is valid
   *
   * @param {string} text - card number to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isCardNumber: SyncValidatorFunction = text => {
    const re = /^([0-9]{4}\s){3}([0-9]{4})$/
    return Validators._checkText(text, re, i18next.t('validation:bad_credit_card_numbers'))
  }

  /**
   * Check if the text is not empty
   *
   * @param {string} text - text to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isNotEmpty: SyncValidatorFunction = text => {
    const re = /.+/
    return Validators._checkText(text, re, i18next.t('validation:required'))
  }

  /**
   * Check if the secret question answer is correct
   *
   * @param {string} text - text to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static minLength: (minLength: number) => SyncValidatorFunction = (minLength) => (text) => {
    if (text && text.length <= minLength) {
      return {
        isValid: false,
        errorMessage: i18next.t('validation:minLength', { minLength }),
      }
    }
    return { isValid: true }
  }

  /**
   * Test max length of input is valid
   *
   * @param {string} text - text to test
   * @returns {any} return if is true, or false with error message
   *
   */
   public static maxLength: (maxLength: number) => SyncValidatorFunction = (maxLength) => (text) => {
     if (text && text.length > maxLength) {
       return {
         isValid: false,
         errorMessage: i18next.t('validation:maxLength', { maxLength: maxLength }),
       }
     }
     return { isValid: true }
   }

  /**
   * Check if the phone number is valid
   *
   * @param {string} text - phone number to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isPhoneNumber: SyncValidatorFunction = text => {
    const re = /^[0-9]{10}$/
    return Validators._checkText(text, re, i18next.t('validation:badPhoneNumber'))
  }

  /**
   * Check if the text is valid with specific regex and error message
   *
   * @param {string} text - text to test
   * @param {RegExp} textFormat - Regex use for test
   * @param {string} errorMessage - Error message to display if false
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isCustomCheck = (regex: RegExp, errorMessage: string): ValidatorFunction =>
    (text: string) => Validators._checkText(text, regex, errorMessage)

  /**
   * Check if the amount is valid
   *
   * @param {string} text - amount to test
   * @returns {any} if check pass returns true, or false with error message
   *
   */
  public static isAmountFormat: SyncValidatorFunction = text => {
    const re = /^[0-9]+((,|\.)?[0-9]{1,2})?$/
    return Validators._checkText(text, re, i18next.t('validation:bad_price_format'))
  }

  /**
   * Check if the IBAN is valid
   *
   * @param {string} text - IBAN to test
   * @returns {any} return if is true, or false with error message
   *
   */
  public static isIbanFormat: SyncValidatorFunction = text => {
    const re = /^[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?[\d]{0,2}$/
    return Validators._checkText(text, re, i18next.t('validation:bad_iban_format'))
  }

  /**
   * Check if the amount is zero
   *
   * @param {string} text - amount to test
   * @returns {ValidatorResponseType} return if is true, or false with error message
   *
   */
  public static isAmountOverZero: SyncValidatorFunction = (text: string): ValidatorResponseType => {
    return Validators._isAmountOverZero(text, i18next.t('validation:amount_must_be_over_zero'))
  }

  /**
   * Check if the amount is zero
   *
   * @param {string} text - amount to test
   * @param {string} errorMessage - error message if amount is zero
   * @returns {ValidatorResponseType} return if is true, or false with error message
   *
   */
  public static isAmountOverZeroWithErrorMessage (errorMessage: string): SyncValidatorFunction {
    return (text: string): ValidatorResponseType => {
      return Validators._isAmountOverZero(text, errorMessage)
    }
  }

  /**
   * Check if the text cast as number is greater than amount
   *
   * @param {number} amount - amount to compare with text
   * @param {string} errorMessage - add error message in ValidatorResponseType if amount >= text
   * @returns {ValidatorResponseType} - true if text is greater than amount
   */
  public static isGreaterThan (amount: number, errorMessage: string): SyncValidatorFunction {
    return (text: string): ValidatorResponseType => {
      const validatorResponse: ValidatorResponseType = {
        isValid: (+text !== null && (+text.replace(',', '.')) >= amount),
      }
      if (!validatorResponse.isValid) {
        validatorResponse.errorMessage = errorMessage
      }

      return validatorResponse
    }
  }

  public static isLowerThan (amount: number, errorMessage: string): SyncValidatorFunction {
    return (text: string): ValidatorResponseType => {
      const validatorResponse: ValidatorResponseType = {
        isValid: (+text !== null && (+text.replace(',', '.')) <= amount),
      }

      if (!validatorResponse.isValid) {
        validatorResponse.errorMessage = errorMessage
      }
      return validatorResponse
    }
  }

  private static _isAmountOverZero = (text: string, errorMessage: string): ValidatorResponseType => {
    const re = /^[0]+((,|\.)?[0]{1,2})?$/

    // Init with status amount format.
    const status = Validators.isAmountFormat(text)

    if (!status.isValid) {
      return status
    }

    if (re.test(text)) {
      return {
        isValid: false,
        errorMessage: errorMessage,
      }
    }

    return { isValid: true }
  }

    /**
   * Check if the text contains emojis
   *
   * @param {string} text - text to test
   * @returns {boolean} checks if contains emoji
   *
   */
    public static noEmojis: SyncValidatorFunction = text => {
      const re = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g

      if (re.test(text)) {
        return {
          isValid: false,
          errorMessage: i18next.t('validation:containsEmojis'),
        }
      }

      return { isValid: true }
    }
}
