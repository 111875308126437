import { Reducer, AnyAction } from 'redux'
import i18next from 'i18next'
import {
  AccountClosureRequestDto, AccountClosureReviewDto,
  AccountDto, AccountSortDto, AccountUpdateDto, SubAccountCostDto, SubAccountCreationRequestDto, SubscriptionDto,
  AccountTypeDto,
  UpdateAggregationAccountDto,
  AggregationConnectorDto,
} from '@afone/neo-core-client/dist/models'
import { NeobankApi } from '@neo-commons/services'
import { createSelector } from 'reselect'
import { BankAccountUtils } from '@neo-commons/libraries'
import { uniq, unset } from 'lodash'

import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { State, initialResourceState, AlertType } from '../utils'

import { SubscriptionActions } from './subscription'

const keyErrorTranslate = 'errors:internalTechnicalIssue'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type BankAccount = AccountDto

const {
  resourceActionTypes: BankAccountActionTypes,
  resourceReducer: BankAccountResourceReducer,
  resourceAction: BankAccountAction,
  resourceSelector: BankAccountResourceSelector,
} = ResourceStateFactory<BankAccount, 'bankAccount'>((state: State) => state.bankAccount, 'bankAccount')

const CREATE_BANKACCOUNT_SUCCESS = 'bankAccount/CREATE_BANKACCOUNT_SUCCESS'

const LIST_BANK_ACCOUNT_REQUEST = 'bankAccount/LIST_BANK_ACCOUNT_REQUEST'
const LIST_BANK_ACCOUNT_SUCCESS = 'bankAccount/LIST_BANK_ACCOUNT_SUCCESS'
const LIST_BANK_ACCOUNT_FAILURE = 'bankAccount/LIST_BANK_ACCOUNT_FAILURE'

const GET_BANK_ACCOUNT_RIB_FILE_REQUEST = 'bankAccount/GET_BANK_ACCOUNT_RIB_FILE_REQUEST'
const GET_BANK_ACCOUNT_RIB_FILE_SUCCESS = 'bankAccount/GET_BANK_ACCOUNT_RIB_FILE_SUCCESS'
const GET_BANK_ACCOUNT_RIB_FILE_FAILURE = 'bankAccount/GET_BANK_ACCOUNT_RIB_FILE_FAILURE'

const GET_BANK_ACCOUNT_RIB_INFOS_REQUEST = 'bankAccount/GET_BANK_ACCOUNT_RIB_INFOS_REQUEST'
const GET_BANK_ACCOUNT_RIB_INFOS_SUCCESS = 'bankAccount/GET_BANK_ACCOUNT_RIB_INFOS_SUCCESS'
const GET_BANK_ACCOUNT_RIB_INFOS_FAILURE = 'bankAccount/GET_BANK_ACCOUNT_RIB_INFOS_FAILURE'

const SET_RECIPIENT_ACCOUNT = 'bankAccount/SET_RECIPIENT_ACCOUNT'
const SET_SELECTED_BANK_ACCOUNT_TO_CLOSE = 'bankAccount/SET_SELECTED_BANK_ACCOUNT_TO_CLOSE'
const GET_BANK_ACCOUNT_SUBSCRIPTION_REQUEST = 'bankAccount/GET_BANK_ACCOUNT_SUBSCRIPTION_REQUEST'
const GET_BANK_ACCOUNT_SUBSCRIPTION_SUCCESS = 'bankAccount/GET_BANK_ACCOUNT_SUBSCRIPTION_SUCCESS'
const GET_BANK_ACCOUNT_SUBSCRIPTION_FAILURE = 'bankAccount/GET_BANK_ACCOUNT_SUBSCRIPTION_FAILURE'

const GET_ACCOUNT_CLOSURE_REQUEST = 'bankAccount/GET_ACCOUNT_CLOSURE_REQUEST'
const GET_ACCOUNT_CLOSURE_SUCCESS = 'bankAccount/GET_ACCOUNT_CLOSURE_SUCCESS'
const GET_ACCOUNT_CLOSURE_FAILURE = 'bankAccount/GET_ACCOUNT_CLOSURE_FAILURE'

const CREATE_SUB_ACCOUNT_REQUEST = 'bankAccount/CREATE_SUB_ACCOUNT_REQUEST'
const CREATE_SUB_ACCOUNT_SUCCESS = 'bankAccount/CREATE_SUB_ACCOUNT_SUCCESS'
const CREATE_SUB_ACCOUNT_FAILURE = 'bankAccount/CREATE_SUB_ACCOUNT_FAILURE'

const GET_AGGREGATION_REQUEST = 'bankAccount/GET_AGGREGATION_REQUEST'
const GET_AGGREGATION_SUCCESS = 'bankAccount/GET_AGGREGATION_SUCCESS'
const GET_AGGREGATION_FAILURE = 'bankAccount/GET_AGGREGATION_FAILURE'

const UPDATE_AGGREGATED_ACCOUNT_REQUEST = 'bankAccount/UPDATE_AGGREGATED_ACCOUNT_REQUEST'
const UPDATE_AGGREGATED_ACCOUNT_SUCCESS = 'bankAccount/UPDATE_AGGREGATED_ACCOUNT_SUCCESS'
const UPDATE_AGGREGATED_ACCOUNT_FAILURE = 'bankAccount/UPDATE_AGGREGATED_ACCOUNT_FAILURE'

const DELETE_AGGREGATED_ACCOUNT_REQUEST = 'bankAccount/DELETE_AGGREGATED_ACCOUNT_REQUEST'
const DELETE_AGGREGATED_ACCOUNT_SUCCESS = 'bankAccount/DELETE_AGGREGATED_ACCOUNT_SUCCESS'
const DELETE_AGGREGATED_ACCOUNT_FAILURE = 'bankAccount/DELETE_AGGREGATED_ACCOUNT_FAILURE'

const DELETE_AGGREGATED_CONNECTION_REQUEST = 'bank/DELETE_AGGREGATED_CONNECTION_REQUEST'
const DELETE_AGGREGATED_CONNECTION_SUCCESS = 'bank/DELETE_AGGREGATED_CONNECTION_SUCCESS'
const DELETE_AGGREGATED_CONNECTION_FAILURE = 'bank/DELETE_AGGREGATED_CONNECTION_FAILURE'

const DELETE_PROSPECTACCOUNT_SUCCESS = 'bankAccount/DELETE_PROSPECTACCOUNT_SUCCESS'
const DELETE_PROSPECTACCOUNT_FAILURE = 'bankAccount/DELETE_PROSPECTACCOUNT_FAILURE'

const PREPARE_SUB_ACCOUNT = 'bankAccount/PREPARE_SUB_ACCOUNT'
const RESET_PREPARE_SUB_ACCOUNT = 'bankAccount/RESET_PREPARE_SUB_ACCOUNT'

const CLOSE_BANK_ACCOUNT_REQUEST = 'bankAccount/CLOSE_BANK_ACCOUNT_REQUEST'
const CLOSE_BANK_ACCOUNT_SUCCESS = 'bankAccount/CLOSE_BANK_ACCOUNT_SUCCESS'
const CLOSE_BANK_ACCOUNT_FAILURE = 'bankAccount/CLOSE_BANK_ACCOUNT_FAILURE'
const SET_CLOSING_BANK_ACCOUNT_AMOUNT = 'bankAccount/SET_CLOSING_BANK_ACCOUNT_AMOUNT'
const GET_CLOSING_BANK_ACCOUNT_AMOUNT_FAILURE = 'bankAccount/GET_CLOSING_BANK_ACCOUNT_AMOUNT_FAILURE'

const CANCEL_BANK_ACCOUNT_CLOSURE_REQUEST = 'bankAccount/CANCEL_BANK_ACCOUNT_CLOSURE_REQUEST'
const CANCEL_BANK_ACCOUNT_CLOSURE_SUCCESS = 'bankAccount/CANCEL_BANK_ACCOUNT_CLOSURE_SUCCESS'
const CANCEL_BANK_ACCOUNT_CLOSURE_FAILURE = 'bankAccount/CANCEL_BANK_ACCOUNT_CLOSURE_FAILURE'

const SUSPENDED_ACCOUNT_ALERT = 'SUSPENDED_ACCOUNT_ALERT'
const RESET_SUSPENDED_ACCOUNT_ALERT = 'RESET_SUSPENDED_ACCOUNT_ALERT'

const SET_SELECTED_CONNECTOR = 'SET_SELECTED_CONNECTOR'

export const BankAccountTypes = {
  ...BankAccountActionTypes,

  CREATE_BANKACCOUNT_SUCCESS,

  LIST_BANK_ACCOUNT_REQUEST,
  LIST_BANK_ACCOUNT_SUCCESS,
  LIST_BANK_ACCOUNT_FAILURE,

  GET_BANK_ACCOUNT_RIB_FILE_REQUEST,
  GET_BANK_ACCOUNT_RIB_FILE_SUCCESS,
  GET_BANK_ACCOUNT_RIB_FILE_FAILURE,

  GET_BANK_ACCOUNT_RIB_INFOS_REQUEST,
  GET_BANK_ACCOUNT_RIB_INFOS_SUCCESS,
  GET_BANK_ACCOUNT_RIB_INFOS_FAILURE,

  SET_RECIPIENT_ACCOUNT,
  SET_SELECTED_BANK_ACCOUNT_TO_CLOSE,

  GET_BANK_ACCOUNT_SUBSCRIPTION_REQUEST,
  GET_BANK_ACCOUNT_SUBSCRIPTION_SUCCESS,
  GET_BANK_ACCOUNT_SUBSCRIPTION_FAILURE,

  GET_ACCOUNT_CLOSURE_REQUEST,
  GET_ACCOUNT_CLOSURE_SUCCESS,
  GET_ACCOUNT_CLOSURE_FAILURE,

  CREATE_SUB_ACCOUNT_REQUEST,
  CREATE_SUB_ACCOUNT_SUCCESS,
  CREATE_SUB_ACCOUNT_FAILURE,

  GET_AGGREGATION_REQUEST,
  GET_AGGREGATION_SUCCESS,
  GET_AGGREGATION_FAILURE,

  UPDATE_AGGREGATED_ACCOUNT_REQUEST,
  UPDATE_AGGREGATED_ACCOUNT_SUCCESS,
  UPDATE_AGGREGATED_ACCOUNT_FAILURE,

  DELETE_AGGREGATED_ACCOUNT_REQUEST,
  DELETE_AGGREGATED_ACCOUNT_SUCCESS,
  DELETE_AGGREGATED_ACCOUNT_FAILURE,

  DELETE_AGGREGATED_CONNECTION_REQUEST,
  DELETE_AGGREGATED_CONNECTION_SUCCESS,
  DELETE_AGGREGATED_CONNECTION_FAILURE,

  DELETE_PROSPECTACCOUNT_SUCCESS,
  DELETE_PROSPECTACCOUNT_FAILURE,

  PREPARE_SUB_ACCOUNT,
  RESET_PREPARE_SUB_ACCOUNT,

  CLOSE_BANK_ACCOUNT_REQUEST,
  CLOSE_BANK_ACCOUNT_SUCCESS,
  CLOSE_BANK_ACCOUNT_FAILURE,

  SET_CLOSING_BANK_ACCOUNT_AMOUNT,
  GET_CLOSING_BANK_ACCOUNT_AMOUNT_FAILURE,

  CANCEL_BANK_ACCOUNT_CLOSURE_REQUEST,
  CANCEL_BANK_ACCOUNT_CLOSURE_SUCCESS,
  CANCEL_BANK_ACCOUNT_CLOSURE_FAILURE,

  SUSPENDED_ACCOUNT_ALERT,
  RESET_SUSPENDED_ACCOUNT_ALERT,

  SET_SELECTED_CONNECTOR,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

const setSelectedBankAccountToClose = (data: AccountDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_BANK_ACCOUNT_TO_CLOSE,
      data,
    })
  }
}

const prepareSubAccount = (preparedSubAccountOrder?: PrepareSubAccountOrder) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PREPARE_SUB_ACCOUNT, preparedSubAccountOrder })
  }
}

const resetPrepareSubAccount = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET_PREPARE_SUB_ACCOUNT })
  }
}

const prepareRecipientAccount = (data: AccountClosureRequestDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_RECIPIENT_ACCOUNT,
      data,
    })
  }
}

const getBankAccountSubscriptions = (uuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GET_BANK_ACCOUNT_SUBSCRIPTION_REQUEST })
    try {
      const subscriptionsResponse = await NeobankApi.getInstance().subscriptionApi.getSubscriptionByUuid(uuid)
      const { data } = subscriptionsResponse
      dispatch({
        type: GET_BANK_ACCOUNT_SUBSCRIPTION_SUCCESS,
        data: data,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: GET_BANK_ACCOUNT_SUBSCRIPTION_FAILURE,
        errorMessage,
      })
    }
  }
}

const getAccountClosureReview = (uuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GET_ACCOUNT_CLOSURE_REQUEST })
    try {
      const accountClosureReview = await NeobankApi.getInstance().bankAccountApi.getAccountClosureReview(uuid)
      const { data } = accountClosureReview

      dispatch({
        type: GET_ACCOUNT_CLOSURE_SUCCESS,
        data,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: GET_ACCOUNT_CLOSURE_FAILURE,
        errorMessage,
      })
    }
  }
}

const create = (subscriptionUuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: BankAccountTypes.CREATE_BANKACCOUNT_REQUEST })

    try {
      const createBankAccountResponse = await NeobankApi.getInstance().bankAccountApi.createAccount(subscriptionUuid)
      const bankAccount = createBankAccountResponse.data
      dispatch({
        type: BankAccountTypes.CREATE_BANKACCOUNT_SUCCESS,
        data: bankAccount,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: BankAccountTypes.CREATE_BANKACCOUNT_FAILURE,
        errorMessage,
      })
    }
  }
}

const createProspectAccount = (
  account: {
    uuid: string,
    name: string,
    type: AccountTypeDto,
  }) => {
  return async (dispatch: Dispatch) => {
    try {
      const bankAccount = {
        uuid: account.uuid,
        name: account.name,
        balance: 0,
        currencyCode: 'EUR',
        type: account.type,
        createdDate: new Date().toISOString().split('T')[0],
      }
      dispatch({
        type: BankAccountTypes.CREATE_BANKACCOUNT_SUCCESS,
        data: bankAccount,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: BankAccountTypes.CREATE_BANKACCOUNT_FAILURE,
        errorMessage,
      })
    }
  }
}

const deleteProspectAccount = (uuid: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: BankAccountTypes.DELETE_PROSPECTACCOUNT_SUCCESS,
        data: uuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: BankAccountTypes.DELETE_PROSPECTACCOUNT_FAILURE,
        errorMessage,
      })
    }
  }
}

const closeBankAccount = (
  payload: {
    uuid: string,
    accountClosureDetails: AccountClosureRequestDto,
    simulation?: boolean
  }) => {
  return async (dispatch: Dispatch) => {
    if (!payload.simulation) {
      dispatch({ type: CLOSE_BANK_ACCOUNT_REQUEST })
    }

    try {
      const closeAccountResponse = await NeobankApi.getInstance().bankAccountApi.closeAccount(
        payload.uuid,
        payload.accountClosureDetails,
        payload.simulation ? 1 : 0,
      )
      const {
        data,
        headers,
      } = closeAccountResponse
      const amount = headers['x-amount']

      if (payload.simulation && amount) {
        dispatch({
          type: SET_CLOSING_BANK_ACCOUNT_AMOUNT,
          data: { amount: parseFloat(amount).toFixed(2) },
        })
      } else {
        dispatch({
          type: CLOSE_BANK_ACCOUNT_SUCCESS,
          data,
        })
      }
    } catch (error) {
      const amount = error.headers && error.headers['x-amount']

      if (payload.simulation) {
        if (amount) {
          dispatch({
            type: SET_CLOSING_BANK_ACCOUNT_AMOUNT,
            data: { amount: parseFloat(amount).toFixed(2) },
          })
        } else {
          const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
          dispatch({ type: GET_CLOSING_BANK_ACCOUNT_AMOUNT_FAILURE, errorMessage })
          throw error
        }
      } else if (amount) {
        throw error
      } else {
        const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
        dispatch({ type: CLOSE_BANK_ACCOUNT_FAILURE, errorMessage })
        throw error
      }
    }
  }
}

const cancelAccountClosure = (uuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: BankAccountTypes.CANCEL_BANK_ACCOUNT_CLOSURE_REQUEST })

    try {
      NeobankApi.getInstance().bankAccountApi.deleteAccountClosure(uuid)
      dispatch({
        type: BankAccountTypes.CANCEL_BANK_ACCOUNT_CLOSURE_SUCCESS,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: BankAccountTypes.CANCEL_BANK_ACCOUNT_CLOSURE_FAILURE,
        errorMessage,
      })
    }
  }
}

const createSubAccount = (
  payload: {
    mainAccountUuid: string,
    xValidationOnly: number,
    subAccountCreationRequestDto: SubAccountCreationRequestDto
  }
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: BankAccountTypes.CREATE_SUB_ACCOUNT_REQUEST })

    try {
      const createSubBankAccountResponse = await NeobankApi.getInstance().bankAccountApi.createSubAccount(
        payload.mainAccountUuid,
        payload.xValidationOnly,
        payload.subAccountCreationRequestDto
      )

      if (!payload.xValidationOnly) {
        dispatch({
          type: BankAccountTypes.CREATE_SUB_ACCOUNT_SUCCESS,
          data: {
            ...createSubBankAccountResponse.data,
            parentUuid: payload.mainAccountUuid,
            type: payload.subAccountCreationRequestDto.accountType,
          },
        })
      } else {
        dispatch({
          type: BankAccountTypes.CREATE_SUB_ACCOUNT_SUCCESS,
        })
      }

      await dispatch(prepareSubAccount({
        ...createSubBankAccountResponse.data,
      }))

      return createSubBankAccountResponse.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: BankAccountTypes.CREATE_SUB_ACCOUNT_FAILURE,
        errorMessage: errorMessage,
      })
      throw error
    }
  }
}

const getSubAccountCost = (
  mainAccountUuid: string,
  subAccountCreationRequestDto: SubAccountCreationRequestDto
) => {
  return createSubAccount({ mainAccountUuid, xValidationOnly: 1, subAccountCreationRequestDto })
}

const update = (accountUuid: string, account: AccountUpdateDto, showMessage = true) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: BankAccountTypes.UPDATE_BANKACCOUNT_REQUEST })

    try {
      const response = await NeobankApi.getInstance().bankAccountApi.updateAccountByUuid(accountUuid, account)
      const bankAccount = response.data
      dispatch({
        type: BankAccountTypes.UPDATE_BANKACCOUNT_SUCCESS,
        data: bankAccount,
        alertType: AlertType.SUCCESS,
        errorMessage: showMessage && i18next.t('neo-commons:account:successRenameAccount'),
        successMessage: showMessage && i18next.t('neo-commons:account:successRenameAccount'),
      })
    } catch (error) {
      dispatch({
        type: BankAccountTypes.UPDATE_BANKACCOUNT_FAILURE,
        errorMessage: i18next.t(keyErrorTranslate),
      })
    }
  }
}

const getById = (id:string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: BankAccountTypes.BYID_BANKACCOUNT_REQUEST })

    try {
      const getAccountResponse =
        await NeobankApi.getInstance().bankAccountApi.getAccountByUuid(id)

      dispatch({
        type: BankAccountTypes.BYID_BANKACCOUNT_SUCCESS,
        data: getAccountResponse.data,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({
        type: BankAccountTypes.BYID_BANKACCOUNT_FAILURE,
        errorMessage,
      })
    }
  }
}

const list = (payload?:{
  pageNumber?: number,
  pageSize?: number,
  parentUuid?: string,
  bankAccountType?: AccountTypeDto | null,
  isPaginate?: boolean
}) => {
  const pageSize = payload?.pageSize ? payload.pageSize : 20
  const pageNumber = payload?.pageNumber ? payload.pageNumber : 0
  return async (dispatch: Dispatch, getState: () => State) => {
    if (payload?.parentUuid && (payload.parentUuid === 'prospect-account' ||
      payload.parentUuid === 'prospect-project' ||
      payload.parentUuid === 'total-balance')) {
      return
    }
    if (!getState().bankAccount.loading) {
      dispatch({ type: BankAccountTypes.LIST_BANK_ACCOUNT_REQUEST })
      try {
        const response = await NeobankApi.getInstance().bankAccountApi.getAccounts(
          pageSize,
          pageNumber,
          undefined,
          [
            AccountSortDto.TYPE_PRIORITY,
            AccountSortDto.ACCOUNT_PRIORITY,
            AccountSortDto.SUBACCOUNT_PRIORITY,
            AccountSortDto.CREATED_DATE,
          ],
          [],
          payload?.parentUuid ? payload.parentUuid : undefined,
          payload?.bankAccountType ? payload.bankAccountType : undefined,
        )

        const data = response.status === 204 ? [] : response.data
        const dataElementsNumber = parseInt(response.headers['content-range']?.split('/')[1])
        data.filter((el) => !(el.type === AccountTypeDto.AGGREGATED && el.disabled))
        dispatch({
          type: BankAccountTypes.LIST_BANK_ACCOUNT_SUCCESS,
          data,
          isPaginate: !!(payload?.isPaginate),
          page: pageNumber,
          perPage: pageSize,
          paginationEnded: dataElementsNumber < ((pageNumber + 1) * pageSize),
          bankAccountType: payload?.bankAccountType ? payload.bankAccountType : undefined,
          parentUuid: payload?.parentUuid ? payload.parentUuid : undefined,
        })
      } catch (error) {
        const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
        dispatch({
          type: BankAccountTypes.LIST_BANK_ACCOUNT_FAILURE,
          errorMessage,
        })
      }
    }
  }
}

const ribFile = (bankAccountUuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GET_BANK_ACCOUNT_RIB_FILE_REQUEST })

    try {
      const payload = await NeobankApi.getInstance().bankAccountApi.getAccountRibFile(bankAccountUuid)
      const file = payload.data ?? {}

      dispatch({
        type: GET_BANK_ACCOUNT_RIB_FILE_SUCCESS,
        file,
        bankAccountUuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: GET_BANK_ACCOUNT_RIB_FILE_FAILURE,
        errorMessage,
      })
    }
  }
}

const ribInfos = (bankAccountUuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GET_BANK_ACCOUNT_RIB_INFOS_REQUEST })

    try {
      const payload = await NeobankApi.getInstance().bankAccountApi.getAccountRib(bankAccountUuid)
      const data = payload.data ?? {}

      dispatch({
        type: GET_BANK_ACCOUNT_RIB_INFOS_SUCCESS,
        infos: data,
        bankAccountUuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: GET_BANK_ACCOUNT_RIB_INFOS_FAILURE,
        errorMessage,
      })
    }
  }
}

const suspendedAccountAlert = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: BankAccountTypes.SUSPENDED_ACCOUNT_ALERT,
    })
  }
}

const resetSuspendedAccountAlert = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: BankAccountTypes.RESET_SUSPENDED_ACCOUNT_ALERT,
    })
  }
}

const setSelected = (selectedId: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (getState().bankAccount.list!.selectedId === selectedId) return
    dispatch({
      type: BankAccountTypes.SET_SELECTED,
      selectedId,
    })
  }
}

const setSelectedConnector = (connector: AggregationConnectorDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: BankAccountTypes.SET_SELECTED_CONNECTOR,
      data: connector,
    })
  }
}

const getAggregation = (payload:{
  reconnect: boolean,
  redirectUri: string,
  bankAccountUuid: string,
  connectorId: string,
  xValidationOnly: number
}) => {
  return async (dispatch: Dispatch) => {
    let aggregationResponse
    dispatch({ type: GET_AGGREGATION_REQUEST })

    try {
      payload.reconnect
        ? aggregationResponse = await NeobankApi.getInstance().aggregationApi.reconnectAccountAggregatedByUuid(
          payload.bankAccountUuid,
          { uri: payload.redirectUri }
        )
        : aggregationResponse = await NeobankApi.getInstance().aggregationApi
          .connectAggregation(payload.xValidationOnly,
            { uri: payload.redirectUri, connectorId: payload.connectorId })

      dispatch({
        type: GET_AGGREGATION_SUCCESS,
      })
      return aggregationResponse
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: GET_AGGREGATION_FAILURE,
        errorMessage,
      })
      throw error
    }
  }
}

const deleteAggregatedAccount = (uuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DELETE_AGGREGATED_ACCOUNT_REQUEST })

    try {
      await NeobankApi.getInstance().aggregationApi.deleteAccountAggregatedByUuid(uuid)

      dispatch({
        type: DELETE_AGGREGATED_ACCOUNT_SUCCESS,
        data: uuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: DELETE_AGGREGATED_ACCOUNT_FAILURE,
        errorMessage,
      })
    }
  }
}

const updateAggregatedAccount = (accountUuid: string, updateAggregationAccountDto: UpdateAggregationAccountDto) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_AGGREGATED_ACCOUNT_REQUEST })

    try {
      const payload = await NeobankApi.getInstance().aggregationApi
        .updateAccountAggregatedByUuid(accountUuid, updateAggregationAccountDto)
      dispatch({
        type: UPDATE_AGGREGATED_ACCOUNT_SUCCESS,
        data: payload.data,
        accountUuid,
        alertType: AlertType.SUCCESS,
        successMessage: updateAggregationAccountDto.disabled
          ? i18next.t('neo-commons:aggregation:disableAccount')
          : i18next.t('neo-commons:aggregation:activateAccount'),
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: UPDATE_AGGREGATED_ACCOUNT_FAILURE,
        errorMessage,
      })
    }
  }
}

const disconnectAggregation = (uuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DELETE_AGGREGATED_CONNECTION_REQUEST })

    try {
      await NeobankApi.getInstance().aggregationApi.disconnectAggregation()
      await dispatch(SubscriptionActions.softCancel(uuid))
      dispatch({
        type: DELETE_AGGREGATED_CONNECTION_SUCCESS,
        alertType: AlertType.SUCCESS,
        successMessage: i18next.t('neo-commons:aggregation:disableAggregation'),
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(keyErrorTranslate)
      dispatch({
        type: DELETE_AGGREGATED_CONNECTION_FAILURE,
        errorMessage,
      })
    }
  }
}

export const BankAccountActions = {
  ...BankAccountAction,
  create,
  createProspectAccount,
  createSubAccount,
  getSubAccountCost,
  update,
  list,
  ribFile,
  ribInfos,
  setSelected,
  prepareSubAccount,
  resetPrepareSubAccount,
  prepareRecipientAccount,
  setSelectedBankAccountToClose,
  getBankAccountSubscriptions,
  getAccountClosureReview,
  getAggregation,
  deleteAggregatedAccount,
  updateAggregatedAccount,
  cancelAccountClosure,
  closeBankAccount,
  disconnectAggregation,
  getById,
  resetSuspendedAccountAlert,
  suspendedAccountAlert,
  setSelectedConnector,
  deleteProspectAccount,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type BankAccountState = ResourceState<AccountDto> & {
  recipientAccountData: AccountClosureRequestDto | null,
  selectedBankAccountToClose: AccountDto | null,
  selectedConnector: AggregationConnectorDto | null,
  bankAccountSubscription: SubscriptionDto | null,
  accountClosureReview: AccountClosureReviewDto | null,
  closingBankAccountAmount: string | null,
  preparedSubAccountOrder: PrepareSubAccountOrder | null,
  suspendedBankAccountAlert: boolean,
}

export interface PrepareSubAccountOrder {
  uuid?: string,
  name?: string,
  icon?: string,
  type?: AccountTypeDto,
  cost?: SubAccountCostDto,
}

const initialState: BankAccountState = {
  ...initialResourceState,
  recipientAccountData: null,
  selectedBankAccountToClose: null,
  selectedConnector: null,
  bankAccountSubscription: null,
  accountClosureReview: null,
  closingBankAccountAmount: null,
  preparedSubAccountOrder: null,
  suspendedBankAccountAlert: false,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const bankAccountSelector = state => state.bankAccount

export const BankAccountSelectors = {
  ...BankAccountResourceSelector,
  listMain: createSelector(
    bankAccountSelector,
    resource => resource.list.ids.map(
      (id: string) => {
        if (!resource.data[id]?.parentUuid) {
          return resource.data[id]
        }
      },
    ).filter((item: any) => {
      return item
    }),
  ),
  parentFor: (bankAccountUuid: string) => createSelector(
    bankAccountSelector,
    resource => {
      const bankAccountSecondary = resource.data[bankAccountUuid]
      return bankAccountSecondary ? resource.data[bankAccountSecondary.parentUuid] : {}
    },
  ),
  // remind setSelected before call thisCLOSE_BANK_ACCOUNT_REQUEST selector
  selectedMain: createSelector(
    bankAccountSelector,
    resource => {
      const bankAccount = resource.data[resource.list.selectedId]
      return bankAccount?.parentUuid ? resource.data[bankAccount.parentUuid] : bankAccount
    },
  ),
  listAccounts: () => createSelector(
    bankAccountSelector,
    resource => {
      const mainAccounts: AccountDto[] = resource.list.ids.map((id: string) => resource.data[id]) ?? []
      const accountsSortedByUuid: AccountDto[] = []
      const subAccountTypes = Object.keys(resource.list?.subLists)
      mainAccounts.forEach(mainAccount => {
        accountsSortedByUuid.push(mainAccount)
        subAccountTypes.forEach(type => {
          if (resource.list?.subLists[type]?.ids) {
            return resource.list.subLists[type]?.ids?.map((id: string) => {
              if (resource.data[id]?.parentUuid === mainAccount.uuid) {
                accountsSortedByUuid.push(resource.data[id])
              }
            })
          }
        })
      })
      return uniq(accountsSortedByUuid)
    }
  ),
  listAccountsNavbar: () => createSelector(
    bankAccountSelector,
    resource => {
      const mainAccounts: AccountDto[] = resource.list.ids.map((id: string) => resource.data[id])
      let accountsSortedByUuid: AccountDto[] = []
      const subAccountTypes = Object.keys(resource.list?.subLists)
      mainAccounts.forEach(mainAccount => {
        accountsSortedByUuid.push(mainAccount)
        if (mainAccount?.uuid === resource.list.selectedId || BankAccountUtils
          .isSecondary(resource.data[resource.list.selectedId])) {
          subAccountTypes.forEach(type => {
            if (resource.list.subLists[type].ids) {
              const selectedMainId = resource.data[resource.list.selectedId].parentUuid
                ? resource.data[resource.list.selectedId].parentUuid
                : resource.list.selectedId
              accountsSortedByUuid = uniq(accountsSortedByUuid
                .concat(resource.list.subLists[type]?.ids?.map((id: string) => {
                  if (resource.data[id]?.parentUuid === selectedMainId) {
                    return resource.data[id]
                  }
                })))
            }
          })
        }
      })
      return accountsSortedByUuid
    }
  ),
  listSecondaryFor: (bankAccountUuid: string) => createSelector(
    bankAccountSelector,
    resource => resource.list.ids?.map(
      (id: string) => {
        if (resource.data[id]?.parentUuid === bankAccountUuid) {
          return resource.data[id]
        }
      },
    ).filter((item: any) => {
      return item
    }),
  ),
  // TODO: remove this it's deprecated, use bankAccountActions.list with AccountTypeDto
  listProjectsFor: (bankAccountUuid: string) => createSelector(
    bankAccountSelector,
    resource => resource.list.ids?.map(
      (id: string) => {
        if ((resource.data[id]?.parentUuid === bankAccountUuid) && (BankAccountUtils.isProject(resource.data[id]))) {
          return resource.data[id]
        }
      },
    ).filter((item: any) => {
      return item
    }),
  ),
  getSubAccountByType: (type: AccountTypeDto) => createSelector(
    bankAccountSelector,
    resource => {
      if (type in resource.list.subLists && !!resource.list.subLists[type].ids) {
        return resource.list.subLists[type]?.ids?.map((id: string) => {
          return resource.data[id]
        })
      } else {
        return []
      }
    }
  ),
  getSubAccountByUuidAndType: (uuid: string, type: AccountTypeDto) => createSelector(
    bankAccountSelector,
    resource => {
      let subAccounts: AccountDto[] = []
      if (type in resource.list.subLists && !!resource.list.subLists[type].ids && !resource.data[uuid]?.parentUuid) {
        subAccounts = uniq(subAccounts.concat(resource.list.subLists[type]?.ids?.map((id: string) => {
          if (resource.data[id]?.parentUuid === uuid) {
            return resource.data[id]
          }
        }))).filter(item => item !== undefined)
      }
      return subAccounts
    }
  ),
  getSubAccountsByUuid: (uuid: string) => createSelector(
    bankAccountSelector,
    resource => {
      const parentUuid = resource.data[uuid]?.parentUuid
        ? resource.data[uuid].parentUuid
        : uuid
      let subAccounts: AccountDto[] = []
      const subAccountTypes = Object.keys(resource.list?.subLists)
      subAccountTypes.forEach(type => {
        if (resource.list.subLists[type].ids) {
          subAccounts = uniq(subAccounts.concat(resource.list.subLists[type]?.ids?.map((id: string) => {
            if (resource.data[id]?.parentUuid === parentUuid) {
              return resource.data[id]
            }
          }))).filter(item => item !== undefined)
        }
      })
      return subAccounts
    }
  ),
  getSubAccounts: () => createSelector(
    bankAccountSelector,
    resource => {
      const subAccounts: AccountDto[] = []
      const subAccountTypes = Object.keys(resource.list?.subLists)
      subAccountTypes.forEach(type => {
        if (resource.list.subLists[type].ids) {
          return resource.list.subLists[type]?.ids?.map((id: string) => {
            subAccounts.push(resource.data[id])
          })
        }
      })
      return uniq(subAccounts)
    }
  ),
  getAccountsForBank: (connectorId) => createSelector(
    bankAccountSelector,
    resource => {
      const connectionAccs: AccountDto[] = []
      const connectionAccIds = Object.keys(resource.data).filter(key => resource.data[key].slug === connectorId)
      connectionAccIds.forEach(id => {
        connectionAccs.push(resource.data[id])
      })
      return uniq(connectionAccs)
    }
  ),
  getBankAccountBalance: (bankAccountUuid: string) => createSelector(
    bankAccountSelector,
    resource => resource.data[bankAccountUuid]?.balance
  ),
  getCurrentBalance: createSelector(
    bankAccountSelector,
    resource => resource.data[resource.list.selectedId]?.balance,
  ),
  getPreparedSubAccountOrder: createSelector(
    bankAccountSelector,
    resource => resource.preparedSubAccountOrder
  ),
  isLoading: createSelector(
    bankAccountSelector,
    resource => resource.loading
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const bankAccount: Reducer = (state = initialState, action: AnyAction): BankAccountState => {
  const getObjKeys = (obj, prop, value) => {
    return Object.keys(obj).filter(key => obj[key][prop] === value)
  }
  switch (action.type) {
    case GET_BANK_ACCOUNT_RIB_FILE_REQUEST:
    case GET_BANK_ACCOUNT_RIB_INFOS_REQUEST:
    case GET_BANK_ACCOUNT_SUBSCRIPTION_REQUEST:
    case LIST_BANK_ACCOUNT_REQUEST:
    case GET_ACCOUNT_CLOSURE_REQUEST:
    case CLOSE_BANK_ACCOUNT_REQUEST:
    case CREATE_SUB_ACCOUNT_REQUEST:
    case GET_AGGREGATION_REQUEST:
    case DELETE_AGGREGATED_ACCOUNT_REQUEST:
    case UPDATE_AGGREGATED_ACCOUNT_REQUEST:
    case DELETE_AGGREGATED_CONNECTION_REQUEST:
    case CANCEL_BANK_ACCOUNT_CLOSURE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_BANK_ACCOUNT_RIB_FILE_FAILURE:
    case CREATE_SUB_ACCOUNT_FAILURE:
    case LIST_BANK_ACCOUNT_FAILURE:
    case GET_BANK_ACCOUNT_RIB_INFOS_FAILURE:
    case GET_BANK_ACCOUNT_SUBSCRIPTION_FAILURE:
    case GET_ACCOUNT_CLOSURE_FAILURE:
    case CLOSE_BANK_ACCOUNT_FAILURE:
    case CLOSE_BANK_ACCOUNT_SUCCESS:
    case GET_AGGREGATION_SUCCESS:
    case GET_AGGREGATION_FAILURE:
    case DELETE_AGGREGATED_ACCOUNT_FAILURE:
    case UPDATE_AGGREGATED_ACCOUNT_FAILURE:
    case DELETE_AGGREGATED_CONNECTION_FAILURE:
    case CANCEL_BANK_ACCOUNT_CLOSURE_FAILURE:
    case CANCEL_BANK_ACCOUNT_CLOSURE_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case GET_BANK_ACCOUNT_RIB_FILE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.bankAccountUuid]: {
            ...state.data[action.bankAccountUuid],
            rib: {
              ...state.data[action.bankAccountUuid].rib,
              ...{ file: action.file },
            },
          },
        },
        loading: false,
      }

    case GET_BANK_ACCOUNT_RIB_INFOS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.bankAccountUuid]: {
            ...state.data[action.bankAccountUuid],
            rib: {
              ...state.data[action.bankAccountUuid].rib,
              ...action.infos,
            },
          },
        },
        loading: false,
      }
    }

    case LIST_BANK_ACCOUNT_SUCCESS:
      const isMainOrFull = (
        action.bankAccountType === AccountTypeDto.MAIN ||
        action.bankAccountType === AccountTypeDto.AGGREGATED ||
        action.bankAccountType === null ||
        action.bankAccountType === undefined
      )
      const selectedId = state.list?.selectedId
        ? state.list?.selectedId : action.data?.length >= 1
          ? action.data[0].uuid : null
      const bankAccountTypeList = state.list?.subLists?.[action.bankAccountType] ?? []
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.data?.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {}) ?? {},
        },
        list: {
          ...state.list,
          ids: isMainOrFull ? uniq(state.list?.ids.concat(action.data?.map(item => item.uuid))) : state.list?.ids,
          selectedId: selectedId,
          loadedOnce: true,
          subLists: !isMainOrFull ? {
            ...state.list.subLists,
            [action.bankAccountType]: {
              ids: bankAccountTypeList.ids
                ? uniq(bankAccountTypeList.ids.concat(action.data?.map(item => item.uuid)))
                : action.data?.map(item => item.uuid),
              selectedId: selectedId,
              page: action.page,
              perPage: action.perPage,
              paginationEnded: action.paginationEnded,
              loadedOnce: true,
            },
          } : {
            ...state.list.subLists,
          },
        },
      }

    case CREATE_BANKACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, [action.data.uuid]: action.data },
        list: {
          ...state.list,
          ids: [...state.list.ids, action.data.uuid],
          selectedId: action.data.uuid,
          loadedOnce: true,
        },
      }

    case CREATE_SUB_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...(action.data ? {
          data: { ...state.data, [action.data?.uuid]: action.data },
          list: {
            ...state.list,
            ids: [...state.list.ids, action.data.uuid],
            selectedId: action.data?.parentUuid,
            loadedOnce: true,
          },
        } : {}),
      }

    case DELETE_AGGREGATED_ACCOUNT_SUCCESS:

      unset(state, `data[${action.data}]`)

      return {
        ...state,
        loading: false,
        data: state.data,
        list: {
          ...state.list,
          ids: state.list.ids.filter((item) => item !== action.data),
          shouldRefresh: true,
        },
      }

    case DELETE_PROSPECTACCOUNT_SUCCESS:
      unset(state, `data[${action.data}]`)

      return {
        ...state,
        loading: false,
        data: state.data,
        list: {
          ...state.list,
          ids: state.list.ids.filter((item) => item !== action.data),
          shouldRefresh: true,
        },
      }

    case DELETE_AGGREGATED_CONNECTION_SUCCESS:

      const aggregatedIds = getObjKeys(state.data, 'type', AccountTypeDto.AGGREGATED)

      aggregatedIds.forEach(el => unset(state, `data[${el}]`))

      return {
        ...state,
        loading: false,
        data: state.data,
        list: {
          ...state.list,
          ids: state.list.ids.filter((item) => !aggregatedIds.includes(item)),
          shouldRefresh: true,
        },
      }

    case UPDATE_AGGREGATED_ACCOUNT_SUCCESS:
      const data = {
        [action.accountUuid]: { ...state.data[action.accountUuid], ...action.data, type: AccountTypeDto.AGGREGATED },
      }
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...data,
        },
        list: {
          ...state.list,
          shouldRefresh: true,
        },
      }

    case SET_RECIPIENT_ACCOUNT:
      return {
        ...state,
        recipientAccountData: action.data,
      }

    case PREPARE_SUB_ACCOUNT:
      return {
        ...state,
        preparedSubAccountOrder: {
          ...action.preparedSubAccountOrder,
        },
      }

    case RESET_PREPARE_SUB_ACCOUNT:
      return {
        ...state,
        preparedSubAccountOrder: null,
      }

    case SET_SELECTED_BANK_ACCOUNT_TO_CLOSE:
      return {
        ...state,
        selectedBankAccountToClose: action.data,
      }

    case SET_SELECTED_CONNECTOR:
      return {
        ...state,
        selectedConnector: action.data,
      }

    case GET_BANK_ACCOUNT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        bankAccountSubscription: action.data,
      }

    case GET_ACCOUNT_CLOSURE_SUCCESS:
      return {
        ...state,
        loading: false,
        accountClosureReview: action.data,
      }

    case SUSPENDED_ACCOUNT_ALERT:
      return {
        ...state,
        suspendedBankAccountAlert: true,
      }

    case RESET_SUSPENDED_ACCOUNT_ALERT:
      return {
        ...state,
        suspendedBankAccountAlert: false,
      }

    case SET_CLOSING_BANK_ACCOUNT_AMOUNT:
      return {
        ...state,
        closingBankAccountAmount: action.data.amount,
      }

    default:
      return {
        ...BankAccountResourceReducer(state, action, {
          identifier: 'uuid',
          isPaginate: true,
          initialState,
        }),
      }
  }
}
