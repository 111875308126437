import { TextUtils } from './TextUtils'

/**
   * Differents methods for file.
   *
   */
export class FileUtils {
  /**
   * Return a prettier path name for file
   *
   * @param {string} pathFile - path of the file
   * @returns {string} return the prettier URL.
   * @example
   * <Text style={styles.imgName}>{FileUtils.getPrettyFromPathName(file?.name)}</Text>
   *
   */
  static getPrettyFromPathName (pathFile: string): string {
    return TextUtils.prettyShortText(pathFile, 20, true)
  }

  /**
   * Convert octets to MO
   *
   * @param {number} octets - Octets to convert
   * @returns {number} MO
   * @example
   *   FileUtils.convertOctetsToMO(optionsEligibleFiles?.maxWeight ?? 0)
   *
   */
  static convertOctetsToMO (octets: number): number {
    return octets / 1000000
  }
}
