
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'
import { ExternalRoutes } from 'Routes/ExternalRoutes'

import { NeobankApi } from '@neo-commons/services'
import { DecodedJwt, EnumUtils, JwtUtils } from '@neo-commons/libraries'
import { DocumentUploadActions } from '@neo-commons/store'
import { Icon, IconTypes, MenuItemCard } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { KycTypeDto } from '@afone/neo-core-client/dist/models'

import { Tunnel, TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './DocumentsChoiceExternal.scss'

export const DocumentChoiceExternal: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const documentsType = [KycTypeDto.ID, KycTypeDto.PASSPORT, KycTypeDto.RESIDENCE_PERMIT]

  const [tokenData, setTokenData] = useState<DecodedJwt>()

  const authAffialiteToken = async () => {
    const token = await NeobankApi.getInstance().getToken()
    setTokenData(token.data)
    JwtUtils.isExpired(token.data?.exp) && history.push(ExternalRoutes.linkExpired.path)
  }

  useEffect(() => {
    authAffialiteToken()
  }, [])

  return (
    <Tunnel
      component={
        <TunnelContent
          justifyContent={TunnelJustifyContent.START}
          icon={<Icon name='idcard-passport' type={IconTypes.NEOFONT} size={50} color={Colors.secondary} />}
          subtitle={t('app:pages:subscription:global:documentChoice:title')}
          description={t('app:pages:subscription:global:documentChoice:description')}
        >
          <div className='DocumentsChoiceExternal_container'>
            {documentsType.map((type, index) => {
              return (
                <div className='DocumentsChoiceExternal_container_item' key={index}>
                  <MenuItemCard
                    title={t(EnumUtils.getKycTypeDtoKey(type))}
                    onPress={() => {
                      dispatch(DocumentUploadActions.prepare({
                        name: t(EnumUtils.getKycTypeDtoKey(type)),
                        documentType: type,
                      }, tokenData?.personUuid, tokenData?.subscriptionUuid))
                      history.push(Routes.DocumentUpload.index.path)
                    }}
                  />
                </div>
              )
            })}
          </div>
        </TunnelContent>
      }
      footer
    />
  )
}
