import React from 'react'
import { TouchableOpacity, TouchableOpacityProps, ViewStyle, View } from 'react-native'
import { createIconSetFromIcoMoon } from 'react-native-vector-icons'

import ZocialIcon from 'react-native-vector-icons/Zocial'
import OcticonIcon from 'react-native-vector-icons/Octicons'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import Ionicon from 'react-native-vector-icons/Ionicons'
import FoundationIcon from 'react-native-vector-icons/Foundation'
import EvilIcon from 'react-native-vector-icons/EvilIcons'
import EntypoIcon from 'react-native-vector-icons/Entypo'
import FAIcon from 'react-native-vector-icons/FontAwesome'
import FA5Icon from 'react-native-vector-icons/FontAwesome5'
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons'
import FeatherIcon from 'react-native-vector-icons/Feather'
import AntIcon from 'react-native-vector-icons/AntDesign'
import Fontisto from 'react-native-vector-icons/Fontisto'
import { Colors } from '@neo-commons/styles'

import styles from './styles'
import neofont from './../../assets/fonts/neofont/selection.json'

export const NeoIcon = createIconSetFromIcoMoon(
  neofont,
  'neofont',
  'neofont.ttf'
)

export enum IconTypes {
  ZOCIAL = 'zocial',
  OCTICON = 'octicon',
  MATERIAL = 'material',
  MATERIAL_COMMUNITY = 'material-community',
  IONIC = 'ionicon',
  FOUNDATION = 'foundation',
  EVIL = 'evilicon',
  ENTYPO = 'entypo',
  FONT_AWESOME = 'font-awesome',
  FONT_AWESOME_5 = 'font-awesome-5',
  SIMPLE_LINE = 'simple-line-icon',
  FEATHER = 'feather',
  ANT_DESIGN = 'antdesign',
  FONTISTO = 'fontisto',
  NEOFONT = 'neofont'
}
/**
 * Icon properties
 */
export interface IconProps extends TouchableOpacityProps {
  /** Name */
  name: string,
  /** Font */
  type?: IconTypes,
  /** Size */
  size?: number,
  /** Color */
  color?: string,
  /** Style */
  iconStyle?: any,
  /** Container styles */
  containerStyle?: ViewStyle,
  /** Action press */
  onPress?: () => void
  /** Test ID */
  testID?: string
  /** Show cicle background */
  withBackground?: boolean | string
  /** square icon */
  square?:boolean
  /** square icon */
  editable?: () => void
  ref?: React.MutableRefObject<any>
}

export const Icon: React.FC<IconProps> = ({
  type,
  containerStyle,
  iconStyle,
  onPress,
  size,
  name,
  color,
  testID,
  disabled,
  withBackground,
  square,
  editable,
}) => {
  const getIconType = (type) => {
    switch (type) {
      case IconTypes.ZOCIAL:
        return ZocialIcon
      case IconTypes.OCTICON:
        return OcticonIcon
      case IconTypes.MATERIAL:
        return MaterialIcon
      case IconTypes.MATERIAL_COMMUNITY:
        return MaterialCommunityIcon
      case IconTypes.IONIC:
        return Ionicon
      case IconTypes.FOUNDATION:
        return FoundationIcon
      case IconTypes.EVIL:
        return EvilIcon
      case IconTypes.ENTYPO:
        return EntypoIcon
      case IconTypes.FONT_AWESOME:
        return FAIcon
      case IconTypes.FONT_AWESOME_5:
        return FA5Icon
      case IconTypes.SIMPLE_LINE:
        return SimpleLineIcons
      case IconTypes.FEATHER:
        return FeatherIcon
      case IconTypes.ANT_DESIGN:
        return AntIcon
      case IconTypes.FONTISTO:
        return Fontisto
      case IconTypes.NEOFONT:
        return NeoIcon
      default:
        return FeatherIcon
    }
  }
  const IconComponent = getIconType(type)

  const getIconComponent = () => {
    const icon =
      <IconComponent
        testID={testID}
        style={[styles.icon, iconStyle]}
        size={size}
        name={name}
        color={color}
      />

    const editIcon =
      <FeatherIcon
        testID={testID}
        style={[styles.editIcon]}
        name='edit-2'
        size={13}
        color={Colors.ghostBlack}
      />

    if (onPress) {
      return (
        <TouchableOpacity
          style={[typeof withBackground === 'boolean' ? styles.roundedBackground : {}, containerStyle]}
          onPress={() => {
            if (!disabled) {
              onPress()
            }
          }}
          disabled={disabled}
          // eslint-disable-next-line
          // @ts-ignore
          focusable={false}
        >
          {icon}
        </TouchableOpacity>
      )
    }

    if (editable) {
      return (
        <>
          <View style={[styles.roundedBackground, containerStyle]}>
            {icon}
          </View>
          <TouchableOpacity
            style={[styles.editIconContainer]}
            onPress={() => {
              editable()
            }}
            disabled={disabled}
          >
            {editIcon}
          </TouchableOpacity>
        </>
      )
    }

    if (withBackground) {
      return (
        <View style={[styles.roundedBackground, containerStyle]}>
          {icon}
        </View>
      )
    }

    if (square) {
      return (
        <View style={[styles.iconContainer, { width: size, height: size }]}>
          {icon}
        </View>
      )
    }

    return icon
  }
  return getIconComponent()
}
