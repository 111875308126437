import React from 'react'
import { Route } from 'react-router-dom'

import { Routes } from '../../../../../../Routes/Routes'

import { AlertSecretQuestion } from './AlertSecretQuestion'

export const RecoverPasswordAlert: React.FC = () => (
  <>
    <Route path={Routes.RecoverPasswordAlert.secretQuestion.path} component={AlertSecretQuestion} />
  </>
)
