import React from 'react'
import { TextStyle } from 'react-native'

import { Typeface, Typography } from '@neo-commons/components'

interface useTextWithBoldWordsType {
  text: string,
  separator?: string,
  regularFont?: Typeface,
  boldFont?: Typeface,
  style?: TextStyle | TextStyle[],
}

export const useTextWithBoldWords = (props: useTextWithBoldWordsType) => {
  return props.text.split(props?.separator ?? '**').map((words, i) => words && (
    <Typography key={i} typeface={(i % 2 === 0) ? (props?.regularFont ?? 'content') : (props?.boldFont ?? 'bold')} style={props?.style}>
      {words}
    </Typography>
  ))
}
