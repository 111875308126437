import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { ClientActions, ClientSelectors, ConfigActions, SubscriptionActions } from '@neo-commons/store'
import { StepComponentProps, BusinessSubscriptionSteps, BusinessSubscriptionStep } from '@neo-commons/policies'
import { Button, ButtonSkin, Field, ProfileCard, Typography2, Icon, IconTypes, Stepper2 } from '@neo-commons/components'
import { ClientUtils, EnumUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { ClientDto, PersonAffiliationDto, PersonAffiliationDtoPerson } from '@afone/neo-core-client/dist/models'

import { TunnelContent } from '@neo-web/componentsOld'

import { RegisterAffiliatesModal } from '../../Components'

import './IdentifyAffiliates.scss'

type IdentifyAffiliatesProps =
  StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.IdentifyAffiliates]>

export const IdentifyAffiliates: React.FC<IdentifyAffiliatesProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isShowModal, setShowModal] = useState<boolean>(false)
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [affiliateSelected, setAffiliateSelected] = useState<PersonAffiliationDtoPerson>(undefined)

  const isClientLoading: boolean = useSelector((state: StateTypes) => state.client?.loading)
  const isSubscriptionLoading: boolean = useSelector((state: StateTypes) => state.subscription?.loading)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const legalsForms = useSelector((state: StateTypes) => state.config?.legalsForms)

  const affiliationTypes = useMemo(() => {
    return legalsForms?.find((legalForm) => legalForm.name === client?.holder?.legalForm?.name)?.affiliationTypes
  }, [legalsForms, client])

  const affiliates = useMemo(() => client?.holder?.affiliates, [client])

  const getLegalForms = async () => {
    try {
      await dispatch(ConfigActions.listLegalFormsByCountry({
        countryCode: client?.holder?.taxDomiciliationCountryCode,
      }))
    } catch (err) {}
  }

  const onPressNextStep = async () => {
    if (affiliates?.length === 1) {
      try {
        await dispatch(ClientActions.attachMe({ affiliateUuid: affiliates[0]?.uuid }))
        await dispatch(SubscriptionActions.list())
        nextStep()
        AnalyticsHandler.track(AnalyticsEvents.signUp.allCompanyMembersAdded, { number_company_members: affiliates?.length })
      } catch (e) {}
    } else {
      nextStep()
      AnalyticsHandler.track(AnalyticsEvents.signUp.allCompanyMembersAdded, { number_company_members: affiliates?.length })
    }
  }

  const isInfoComplete = (person: PersonAffiliationDtoPerson) => {
    return person?.fullName && person?.email && person?.phone
  }

  const createOrUpdateAffiliate = async (affiliate: PersonAffiliationDto) => {
    try {
      if (affiliate.uuid) {
        await dispatch(ClientActions.updateAffiliate(client, affiliate))
      } else {
        await dispatch(ClientActions.createAffiliate(client, affiliate))
        AnalyticsHandler.track(AnalyticsEvents.signUp.companyMemberAdded)
      }
      setAffiliateSelected(undefined)
      setShowModal(false)
    } catch (e) {}
  }

  const deleteAffiliate = async (affiliate: PersonAffiliationDto) => {
    try {
      await dispatch(ClientActions.deleteAffiliate(client, affiliate))
      setAffiliateSelected(undefined)
      setShowModal(false)
    } catch (e) {}
  }

  useEffect(() => {
    if (!legalsForms?.length) {
      getLegalForms()
    }
    setShowModal(!affiliates?.length)
    setShowMessage(affiliates?.length > 0)
  }, [])

  return (
    <TunnelContent
      loading={isClientLoading || isSubscriptionLoading}
      scrollableBody
      header={
        <div className='IdentifyAffiliates_header'>
          <Typography2 typeface='h3'>
            {t('neo-commons:subscription:business:identifyAffiliates:title')}
          </Typography2>
          <Stepper2 currentStep={1} />
          <Typography2 typeface='h4'>
            {t('app:pages:subscription:business:identifyAffiliates:title',
              { typeBusiness: ClientUtils.isAssociation(client) ? 'association' : 'entreprise' })}
          </Typography2>
        </div>
      }
      footer={
        <div className='IdentifyAffiliates_footer'>
          <div className='IdentifyAffiliates_footer_button'>
            <Button
              skin={ButtonSkin.OUTLINE_PRIMARY}
              title={t('app:pages:subscription:business:identifyAffiliates:addAffiliate')}
              onPress={() => {
                setAffiliateSelected(undefined)
                setShowModal(true)
              }}
            />
          </div>
          <div className='IdentifyAffiliates_footer_button'>
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:global:next')}
              onPress={() => onPressNextStep()}
              disabled={!(affiliates?.length
                ? (affiliates?.every((affiliate) =>
                  affiliate?.person?.email?.length > 0 && affiliate?.person?.phone?.nationalFormat?.length > 0))
                : false)}
            />
          </div>
        </div>
      }
    >
      <div className='IdentifyAffiliates'>
        {showMessage &&
          <div className='IdentifyAffiliates_message'>
            <div className='IdentifyAffiliates_message_icon'>
              <Icon type={IconTypes.FEATHER} name='info' color={Colors.secondary} size={25} />
            </div>
            <Typography2 typeface='infoMessage' style={{ marginLeft: 30 }}>
              {t('app:pages:subscription:business:identifyAffiliates:infoMessage')}
            </Typography2>
          </div>}
        <div className='IdentifyAffiliates_container'>
          {affiliates && affiliates.map((affiliate, index) => {
            return (
              <div className='IdentifyAffiliates_container_affiliate' key={index}>
                <ProfileCard
                  title={affiliate?.person?.fullName}
                  subTitle={
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 4 }}>
                      <Typography2 key={index} style={{ marginRight: 4 }} typeface='body2'>
                        {t(EnumUtils.getAffiliationTypeDtoKey(affiliate?.types[0]))}
                        {/* TMP : On affiche uniquement le premier type d'affiliation de l'affilié */}
                      </Typography2>
                      {isInfoComplete(affiliate?.person) && <Icon type={IconTypes.MATERIAL} name='check-circle' color={Colors.primary} size={15} />}
                    </div>
                  }
                  editable
                  onPress={() => {
                    setAffiliateSelected(affiliate)
                    setShowModal(true)
                  }}
                >
                  <Field
                    label={t('app:pages:subscription:business:identifyAffiliates:phoneNumber')}
                    value={affiliate?.person?.phone?.nationalFormat}
                  />
                  <Field
                    label={t('app:pages:subscription:business:identifyAffiliates:email')}
                    value={affiliate?.person?.email}
                  />
                </ProfileCard>
              </div>
            )
          })}
          <RegisterAffiliatesModal
            key={affiliateSelected?.uuid}
            affiliate={affiliateSelected}
            affiliationTypes={affiliationTypes}
            onSaveAffiliate={createOrUpdateAffiliate}
            onDeleteAffiliate={deleteAffiliate}
            show={isShowModal}
            onClose={() => {
              setShowModal(false)
              setAffiliateSelected(undefined)
            }}
          />
        </div>
      </div>
    </TunnelContent>
  )
}
