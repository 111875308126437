import React from 'react'
import { useTranslation } from 'react-i18next'

import { FileUtils } from 'Utils/FileUtils'

import { DocumentTypes } from '@neo-commons/store'
import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { LazyLoadingContainer, PageDetails, PDFViewer } from '@neo-web/componentsOld'

import './DocumentViewer.scss'

interface DocumentViewerProps {
  title: string;
  subtitle?: string;
  base64?: string;
  filename?: string;
}

export const DocumentViewer: React.FC<DocumentViewerProps> = (props: DocumentViewerProps) => {
  const { t } = useTranslation()

  const url = props.base64 ? `data:application/pdf;base64,${props.base64}` : null

  const exportFile = () => {
    try {
      FileUtils.openBase64NewTab(
        props.base64,
        props.filename,
        'pdf'
      )
    } catch (err) {}
  }

  return (
    <PageDetails
      title={props.title}
      subtitle={props.subtitle ?? ''}
      component={
        <LazyLoadingContainer events={[DocumentTypes.BYID_DOCUMENT_REQUEST]}>
          {url && (
            <div className='DocumentViewer_container classic-box'>
              <div className='DocumentViewer_button'>
                <Button
                  title={t('app:global:download')}
                  skin={ButtonSkin.DEFAULT}
                  leftIcon={<Icon name='download' type={IconTypes.FEATHER} size={20} color={Colors.primary} />}
                  onPressOut={exportFile}
                />
              </div>
              <PDFViewer file={url} />
            </div>
          )}
        </LazyLoadingContainer>
      }
    />
  )
}
