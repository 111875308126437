import React from 'react'
import { Image, Text, TextStyle, TouchableOpacity, View } from 'react-native'

import i18next from 'i18next'
import { Colors } from '@neo-commons/styles'

import { Icon, IconTypes } from '..'

import styles from './styles'

export interface FieldProps {
  label: string,
  value?: string,
  onEditPress?: () => void,
  leftIcon?: React.ReactElement<typeof Icon>,
  rightIcon?: React.ReactElement<typeof Icon|Image>,
  valueStyle?: TextStyle,
  textColor?: string|undefined
}

export const Field: React.FC<FieldProps> = ({ children, ...props }) => {
  const rightIcon = props.rightIcon ??
    <Icon
      name='edit-3'
      type={IconTypes.FEATHER}
      size={18}
      iconStyle={styles.icon}
    />

  const content = (
    <>
      {!children ? (
        <Text
          accessibilityLabel={props.label}
          style={[
            styles.input,
            { color: props.textColor ?? (props.value ? Colors.black : Colors.darkGreyText) },
            props.valueStyle]}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {!props.value ? i18next.t('neo-commons:field:empty') : props.value}
        </Text>
      ) : children}
    </>
  )

  return (
    <View style={styles.container}>
      {props.leftIcon &&
        <View style={styles.leftIconContainer}>
          {props.leftIcon}
        </View>}
      <View style={styles.mainContainer}>
        <Text style={[styles.label]}>
          {props.label}
        </Text>
        <View pointerEvents={props.onEditPress ? 'auto' : 'none'}>
          {props.onEditPress ? (
            <TouchableOpacity
              style={styles.inputContainer}
              onPress={() => {
                if (props.onEditPress) props.onEditPress()
              }}
              testID='field-edit'
            >
              {content}
            </TouchableOpacity>
          ) : content}
        </View>
        {(props.onEditPress || props.rightIcon) &&
          <View accessibilityLabel={props.label + ' Edit'} style={styles.iconContainer}>
            {props.onEditPress ? (
              <TouchableOpacity
                style={styles.inputContainer}
                onPress={() => {
                  if (props.onEditPress) props.onEditPress()
                }}
                testID='field-button'
              >
                {rightIcon}
              </TouchableOpacity>
            ) : rightIcon}
          </View>}
      </View>
    </View>
  )
}
