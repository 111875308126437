import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootTypes, StateTypes } from 'Store'

import { BankAccountUtils } from '@neo-commons/libraries'
import { BankAccountActions, BankAccountSelectors } from '@neo-commons/store'

import { AccountDto, AccountTypeDto } from '@afone/neo-core-client/dist/models'

export const useStateListener = () => {
  const dispatch = useDispatch()

  const config = useSelector((state: StateTypes) => state.root.config)
  const selectedBankAccount: AccountDto = useSelector(
    BankAccountSelectors.selected,
    (prev, next) => prev?.uuid === next?.uuid
  )

  useEffect(() => {
    const refreshSold = async () => {
      if (config.refreshSold) {
        await dispatch(BankAccountActions.list({
          bankAccountType: AccountTypeDto.MAIN,
        }))
        await dispatch(BankAccountActions.list({
          bankAccountType: AccountTypeDto.AGGREGATED,
        }))

        if (config.refreshSold || (config.refreshSubAccounts && !BankAccountUtils.isSecondary(selectedBankAccount))) {
          await dispatch(BankAccountActions.list({
            bankAccountType: AccountTypeDto.PROJECT,
            parentUuid: BankAccountUtils.isSecondary(selectedBankAccount) ? selectedBankAccount.parentUuid : selectedBankAccount?.uuid,
            // TODO: Uncomment this line if you want to implement the pagination on the navbar for PROJECTS
            // isPaginate: true,
          }))

          await dispatch(BankAccountActions.list({
            bankAccountType: AccountTypeDto.POS,
            parentUuid: BankAccountUtils.isSecondary(selectedBankAccount) ? selectedBankAccount.parentUuid : selectedBankAccount?.uuid,
            // TODO: Uncomment this line if you want to implement the pagination on the navbar for POS
            // isPaginate: true,
          }))

          dispatch({ type: RootTypes.RESET_ROOT_UPDATE })
        }
      }
    }

    try {
      refreshSold()
    } catch (error) {}
  }, [config, dispatch])
}
