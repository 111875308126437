import { Reducer, AnyAction } from 'redux'
import i18next from 'i18next'
import { NeobankApi } from '@neo-commons/services'
import { InvoiceDto } from '@afone/neo-core-client/dist/models'

import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { initialResourceState, State } from '../utils'

import { ClientSelectors } from './client'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Invoice = InvoiceDto

const {
  resourceActionTypes: InvoiceActionTypes,
  resourceReducer: InvoiceResourceReducer,
  resourceAction: InvoiceAction,
  resourceSelector: InvoiceResourceSelector,
} = ResourceStateFactory<Invoice, 'invoice'>(state => state.invoice, 'invoice')

export const InvoiceTypes = {
  ...InvoiceActionTypes,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

export const InvoiceSelectors = {
  ...InvoiceResourceSelector,
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */

// Retrieve invoice pages for logged client (e.g. annual invoices are not retrieved from getInvoice())
const getInvoiceDocuments = (page = 0) => {
  const perPage = 20
  return async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch({ type: InvoiceTypes.LIST_INVOICE_REQUEST })

    try {
      const defaultClientUuid = ClientSelectors.defaultOne(getState()).uuid
      const invoiceDocumentsResponse = await NeobankApi.getInstance().subscriptionApi.getInvoiceDocuments(
        defaultClientUuid,
        perPage,
        page
      )
      const noResult = invoiceDocumentsResponse.status === 204
      const paginationEnded = noResult || invoiceDocumentsResponse.status === 200
      const data = noResult ? [] : invoiceDocumentsResponse?.data

      dispatch({
        type: InvoiceTypes.LIST_INVOICE_SUCCESS,
        data,
        page,
        perPage,
        paginationEnded,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:internalTechnicalIssue')
      dispatch({ type: InvoiceTypes.LIST_INVOICE_FAILURE, errorMessage })
    }
  }
}

const getInvoices = (page = 0) => {
  const perPage = 20
  return async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch({ type: InvoiceTypes.LIST_INVOICE_REQUEST })

    try {
      const defaultClientUuid = ClientSelectors.defaultOne(getState()).uuid
      const payload = await NeobankApi.getInstance().subscriptionApi.getInvoices(
        defaultClientUuid,
        perPage,
        page
      )
      const noResult = payload.status === 204
      const paginationEnded = noResult || payload.status === 200
      const data = noResult ? [] : payload?.data

      dispatch({
        type: InvoiceTypes.LIST_INVOICE_SUCCESS,
        data,
        page,
        perPage,
        paginationEnded,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:internalTechnicalIssue')
      dispatch({ type: InvoiceTypes.LIST_INVOICE_FAILURE, errorMessage })
    }
  }
}

export const InvoiceActions = {
  ...InvoiceAction,
  getInvoiceDocuments,
  getInvoices,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type InvoiceState = ResourceState<Invoice>

const initialState: InvoiceState = {
  ...initialResourceState,
  list: {
    ...initialResourceState.list,
  },
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const invoice: Reducer = (state = initialState, action: AnyAction): InvoiceState => {
  switch (action.type) {
    default:
      return {
        ...InvoiceResourceReducer(state, action, {
          identifier: 'uuid',
          isPaginate: true,
          initialState,
        }),
      }
  }
}
