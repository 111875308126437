
import React from 'react'

import { Slider as RSuiteSlider, SliderProps as RSuiteSliderProps } from 'rsuite'
import { AppConfig } from 'Config/AppConfig'

import { Typography } from '@neo-commons/components'

import './Slider.scss'

export interface SliderProps extends RSuiteSliderProps {
  valueDisplayer?: () => string;
  leftLabel?: string;
  rightLabel?: string;
  error?: boolean;
}

export const Slider: React.FC<SliderProps> = (props: SliderProps) => {
  return (
    <div className='Slider'>
      <Typography typeface='appName'>
        <div className={props.error ? 'Slider_currentValue-error' : 'Slider_currentValue'}>
          {props.valueDisplayer ? props.valueDisplayer() : props.value}
        </div>
      </Typography>
      <div className='Slider_barContainer'>
        <RSuiteSlider
          tooltip={false}
          step={AppConfig.LIMIT_GRADUATION_FOR_SLIDER}
          min={props.min ?? 0}
          max={props.max}
          value={props.value}
          defaultValue={props.value}
          progress
          onChange={(value, e) => {
            props.onChange(value, e)
          }}
        />
      </div>
      <div className='Slider_labels'>
        {props.leftLabel &&
          <Typography typeface='content'>
            {props.leftLabel}
          </Typography>}
        {props.rightLabel &&
          <Typography typeface='content'>
            {props.rightLabel}
          </Typography>}
      </div>
    </div>
  )
}
