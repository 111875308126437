import {
  AddressComponentsTypeDto,
  AddressDetailDto,
  AddressDto,
  AutocompleteModeDto,
  AutocompletePredictionDto,
  CountryDto,
} from '@afone/neo-core-client/dist/models'
import { NeobankApi } from '@neo-commons/services'
import { Country } from '@neo-commons/store'

export interface ResDetails {
  resAddressLongName: string,
  resZipCode: AddressDetailDto | undefined,
  resCity: AddressDetailDto | undefined,
}

export interface AddressDataType {
  address: string,
  zipCode: string | undefined,
  cityName: string | undefined,
  additionalAddress: string,
  countryData: Country|CountryDto,
}

export class AddressUtils {
  static reg: string | RegExp = /[\s,]/g
  /**
   * Autocomplete address with start of address
   *
   * @param toComplete - the start of the address
   * @param autocompleteMode - type of predication is wanted (address, city, ...)
   * @param countryCode - the country code for the address
   * @returns A list of predicated addresses
   *
   */
  public static autoCompleteAddress = async (toComplete: string, autocompleteMode: AutocompleteModeDto, countryCode: string): Promise<AutocompletePredictionDto[]> => {
    const res = await NeobankApi.getInstance().addressApi.autocomplete(
      toComplete,
      autocompleteMode,
      countryCode
    )

    return res?.data
  }

  public static formatAddress = (address: AddressDto): string => {
    return address.country + ', ' +
      address.postalCode + ', ' +
      address.city + ', ' +
      address.line1
  }

  /**
   * Get detailed address providing a placeId
   *
   * @param placeId - placeId provided during autocomplete call
   * @returns resAddressLongName as string, resZipCode as AddressDetailDto, resCity as AddressDetailDto
   *
   */
  public static callDetailsByPlaceId = async (placeId: string): Promise<ResDetails> => {
    const fullAddress = await NeobankApi.getInstance().addressApi.getDetailsByPlaceId(placeId)

    const resAddress = fullAddress.data.find(el => el.types.includes(AddressComponentsTypeDto.STREET_NUMBER))
    const resZipCode = fullAddress.data.find(el => el.types.includes(AddressComponentsTypeDto.POSTAL_CODE))
    const resCity = fullAddress.data.find(el => el.types.includes(AddressComponentsTypeDto.LOCALITY))
    const resRoute = fullAddress.data.find(el => el.types.includes(AddressComponentsTypeDto.ROUTE))

    const resAddressLongName = (
      resAddress?.longName
        ? resAddress?.longName + ' '
        : ''
    ) + resRoute?.longName

    return {
      resAddressLongName,
      resZipCode,
      resCity,
    }
  }

  /**
   * Retrieve full address data
   *
   * @param placeId - placeId provided during autocomplete call
   * @param country - country to set in full address
   * @param additionalAddress - additionnal address to set in full address
   * @returns An AddressDataType
   */
  public static callFullAddressData = async (placeId: string, country: CountryDto, additionalAddress: string): Promise<AddressDataType> => {
    const { resAddressLongName, resZipCode, resCity } = await AddressUtils.callDetailsByPlaceId(placeId)

    return {
      countryData: country ?? undefined,
      address: resAddressLongName,
      zipCode: resZipCode?.longName,
      cityName: resCity?.longName,
      additionalAddress: additionalAddress ?? '',
    }
  }

  /**
   * Compare to address data type field by field
   *
   * @param currentAddressData -
   * @param suggestedAddressData -
   * @returns true of false depending addresses matching
   */
  public static isAddressValid = (currentAddressData: AddressDataType, suggestedAddressData: AddressDataType): boolean => {
    return (
      currentAddressData && suggestedAddressData ? (
        currentAddressData?.countryData?.name.toUpperCase() === suggestedAddressData?.countryData?.name.toUpperCase() &&
        currentAddressData?.address.toUpperCase() === suggestedAddressData?.address.toUpperCase() &&
        currentAddressData?.zipCode?.toUpperCase() === suggestedAddressData?.zipCode?.toUpperCase() &&
        currentAddressData?.cityName?.toUpperCase() === suggestedAddressData?.cityName?.toUpperCase()
      ) : false
    )
  }

  /**
   * Return if the specified address is present in the Autocomplete suggestions
   *
   * @param autocompleteAddresses - Array of autocomplete suggested addresses
   * @param addressDescriptionToFind - The full address to search for in string format
   * @returns true of false depending addresses matching
   */
  public static doesAutoCompleteContainFullAddress = (autocompleteAddresses: AutocompletePredictionDto[], addressDescriptionToFind: string): boolean => {
    return autocompleteAddresses.find(
      autocompleteAddress =>
        autocompleteAddress?.description?.replaceAll(AddressUtils.reg, '').toUpperCase() ===
        addressDescriptionToFind?.replaceAll(AddressUtils.reg, '').toUpperCase()
    ) !== undefined
  }

  /**
   * Return the first address suggested that match zipCode and city of the specified address
   *
   * @param autocompleteAddresses - Array of autocomplete suggested addresses
   * @param originAddress - The address to match with
   * @returns AutocompletePredictionDto (address suggested) or undefined depending addresses matching
   */
  public static searchAutoCompleteWithZipCodeAndCity = (autocompleteAddresses: AutocompletePredictionDto[], originAddress: AddressDataType): AutocompletePredictionDto | undefined => {
    return autocompleteAddresses.find(
      autocompleteAddress => (autocompleteAddress?.structuredFormatting?.mainText?.replaceAll(AddressUtils.reg, '').toUpperCase() !==
        originAddress?.address.replaceAll(AddressUtils.reg, '').toUpperCase()) &&
        (autocompleteAddress?.structuredFormatting?.secondaryText?.replaceAll(',', '').toUpperCase().split(' ')[0] ===
        originAddress?.zipCode?.replaceAll(AddressUtils.reg, '').toUpperCase()) &&
        (autocompleteAddress?.structuredFormatting?.secondaryText?.replaceAll(',', '').toUpperCase().split(' ')[1] ===
        originAddress?.cityName?.replaceAll(AddressUtils.reg, '').toUpperCase())
    )
  }

  /**
   * Return the first address suggested that match address description and city of the specified address
   *
   * @param autocompleteAddresses - Array of autocomplete suggested addresses
   * @param originAddress - The address to match with
   * @returns AutocompletePredictionDto (address suggested) or undefined depending addresses matching
   */
  public static searchAutoCompleteWithAddressAndCity = (autocompleteAddresses: AutocompletePredictionDto[], originAddress: AddressDataType): AutocompletePredictionDto | undefined => {
    return autocompleteAddresses.find(
      autocompleteAddress => (autocompleteAddress?.structuredFormatting?.mainText?.replaceAll(AddressUtils.reg, '').toUpperCase() ===
        originAddress?.address.replaceAll(AddressUtils.reg, '').toUpperCase()) &&
        (autocompleteAddress?.structuredFormatting?.secondaryText?.replaceAll(',', '').toUpperCase().split(' ')[0] ===
        originAddress?.cityName?.replaceAll(AddressUtils.reg, '').toUpperCase())
    )
  }

  /**
   * Return the first address suggested that match address description and zipCode of the specified address
   *
   * @param autocompleteAddresses - Array of autocomplete suggested addresses
   * @param originAddress - The address to match with
   * @returns AutocompletePredictionDto (address suggested) or undefined depending addresses matching
   */
  public static searchAutoCompleteWithAddressAndZipCode = (autocompleteAddresses: AutocompletePredictionDto[], originAddress: AddressDataType): AutocompletePredictionDto | undefined => {
    return autocompleteAddresses.find(
      autocompleteAddress => (autocompleteAddress?.structuredFormatting?.mainText?.replaceAll(AddressUtils.reg, '').toUpperCase() ===
        originAddress?.address.replaceAll(AddressUtils.reg, '').toUpperCase()) &&
        (autocompleteAddress?.structuredFormatting?.secondaryText?.replaceAll(',', '').toUpperCase().split(' ')[0] ===
        originAddress?.zipCode?.replaceAll(AddressUtils.reg, '').toUpperCase()) &&
        (autocompleteAddress?.structuredFormatting?.secondaryText?.replaceAll(',', '').toUpperCase().split(' ')[1] !==
        originAddress?.cityName?.replaceAll(AddressUtils.reg, '').toUpperCase())
    )
  }

  /**
   * Return the first address suggested that match address description of the specified address
   *
   * @param autocompleteAddresses - Array of autocomplete suggested addresses
   * @param originAddress - The address to match with
   * @returns AutocompletePredictionDto (address suggested) or undefined depending addresses matching
   */
  public static searchAutoCompleteWithAddressDescription = (autocompleteAddresses: AutocompletePredictionDto[], originAddress: AddressDataType): AutocompletePredictionDto | undefined => {
    return autocompleteAddresses.find(
      autocompleteAddress => (autocompleteAddress?.structuredFormatting?.mainText?.replaceAll(AddressUtils.reg, '').toUpperCase() ===
        originAddress?.address.replaceAll(AddressUtils.reg, '').toUpperCase()) &&
        (autocompleteAddress?.structuredFormatting?.secondaryText?.replaceAll(',', '').toUpperCase().split(' ')[0] !==
        originAddress?.zipCode?.replaceAll(AddressUtils.reg, '').toUpperCase()) &&
        (autocompleteAddress?.structuredFormatting?.secondaryText?.replaceAll(',', '').toUpperCase().split(' ')[1] !==
        originAddress?.cityName?.replaceAll(AddressUtils.reg, '').toUpperCase())
    )
  }
}
