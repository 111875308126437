import { ClientUtils, PosProductData } from '@neo-commons/libraries'
import {
  ClientTypeDto,
  KycStatusDto,
  KycTypeDto,
  OfferDto,
  OfferTypeDto,
  PersonAffiliationDto,
  ProductDto,
  StepDto,
  StepKycItemDto,
  StepStatusDto,
  StepTypeDto,
  SubscriptionDto,
  SubscriptionStatusDto,
} from '@afone/neo-core-client/dist/models'

export enum ProductType {
  POS_MODEL = 'posModel',
  DELIVERY_MODE = 'deliveryMode',
}

/**
 * Different treatments for list.
 */
export class SubscriptionUtils {
  /**
   * Return products with specific type
   * @param offer - POS products.
   * @param productType - Product type.
   * * @returns deliveryProducts with specific product type.
   */
  public static getProductsByType (offer: OfferDto, productType: ProductType) : ProductDto[] {
    return offer?.products!.filter(
      product => (product.data! as {productType: string}).productType === productType)
  }

  /**
   * Check if product is a POS Model
   * @param product
   * * @returns true if product type is POS_MODEL
   */
  public static isPosModel (product: ProductDto) : boolean {
    return (product.data as PosProductData)?.productType === ProductType.POS_MODEL
  }

  /**
   * Check if subscription is status SUBSCRIBING
   * @param subscription
   * @returns boolean
   */
  public static isSubscribing (subscription: SubscriptionDto): boolean {
    return subscription?.status === SubscriptionStatusDto.SUBSCRIBING
  }

  /**
   * Check if subscription is status CANCELED
   * @param subscription
   * @returns boolean
   */
  public static isCanceled (subscription: SubscriptionDto): boolean {
    return subscription?.status === SubscriptionStatusDto.CANCELED
  }

  /**
   * Check if subscription is status ACTIVE
   * @param subscription
   * @returns boolean
   */
  public static isActive (subscription: SubscriptionDto): boolean {
    return subscription?.status === SubscriptionStatusDto.ACTIVE
  }

  /**
   * Return kyc step
   * @param subscription
   * @returns StepDto | undefined
   */
  public static getKycStep (subscription: SubscriptionDto): StepDto | undefined {
    return subscription?.steps?.find((elt) => elt.type === StepTypeDto.KYC)
  }

  /**
   * Return all kyc affiliates documents
   * @param subscription
   * @returns StepDto[] | undefined
   */
  public static getKycAffiliatesSteps (subscription: SubscriptionDto): StepDto[] | undefined {
    return subscription?.steps?.filter((elt) => elt.type === StepTypeDto.KYC_AFFILIATE)
  }

  /**
   * Return kyc affiliate step of a person
   * @param subscription
   * @param personUuid affiliate personUuid
   * @returns StepDto | undefined
   */
  public static getKycAffiliateStep (subscription: SubscriptionDto, personUuid: string): StepDto | undefined {
    return subscription?.steps?.find(
      (elt) => elt.type === StepTypeDto.KYC_AFFILIATE && elt.personUuid === personUuid)
  }

  /**
   * Check if kyc step is status OK
   * @param subscription
   * @returns boolean
   */
  public static isKycStepOk (subscription: SubscriptionDto): boolean {
    return this.getKycStep(subscription)?.status === StepStatusDto.OK
  }

  /**
   * Check if kyc step is status IN_PROGRESS
   * @param subscription
   * @returns boolean
   */
  public static isKycStepInProgress (subscription: SubscriptionDto): boolean {
    return this.getKycStep(subscription)?.status === StepStatusDto.IN_PROGRESS
  }

  /**
   * Check if kyc step is status ANALYSING
   * @param subscription
   * @returns boolean
   */
  public static isKycStepAnalysing (subscription: SubscriptionDto): boolean {
    return this.getKycStep(subscription)?.status === StepStatusDto.ANALYSING
  }

  /**
   * Check if at least one kyc affiliates step is status IN_PROGRESS
   * @param subscription
   * @returns boolean
   */
  public static hasKycAffiliateStepInProgress (subscription: SubscriptionDto): boolean {
    return this.getKycAffiliatesSteps(subscription)?.reduce((acc, step) => acc && step.status === StepStatusDto.IN_PROGRESS, true) || false
  }

  /**
   * Return kyc step documents steps
   * (all subscription.steps(KYC).kycSteps except SIGNATURE_ADVANCED)
   * @param subscription
   * @returns StepKycItemDto[] | undefined
   */
  public static getKycDocuments (subscription: SubscriptionDto): StepKycItemDto[] | undefined {
    return this.getKycStep(subscription)?.kycSteps?.filter(
      (elt) => elt.type !== KycTypeDto.SIGNATURE_ADVANCED
    )
  }

  /**
   * Return kyc affiliate documents steps of a person
   * (all subscription.steps(KYC_AFFFILIATE).kycSteps)
   * @param subscription
   * @param personUuid affiliate personUuid
   * @returns StepKycItemDto[] | undefined
   */
  public static getKycAffiliateDocuments (subscription: SubscriptionDto, personUuid: string): StepKycItemDto[] | undefined {
    return this.getKycAffiliateStep(subscription, personUuid)?.kycSteps
  }

  /**
   * Return kyc documents incomplete
   * (all subscription.steps(KYC).kycSteps except SIGNATURE_ADVANCED with status INCOMPLETE)
   * @param subscription
   * @returns StepKycItemDto[] | undefined
   */
  public static getKycDocumentsIncomplete (subscription: SubscriptionDto): StepKycItemDto[] | undefined {
    return this.getKycDocuments(subscription)?.filter((elt) => elt.status === KycStatusDto.INCOMPLETE) || []
  }

  /**
   * Return kyc documents invalid
   * (all subscription.steps(KYC).kycSteps except SIGNATURE_ADVANCED with a REASON)
   * @param subscription
   * @returns StepKycItemDto[] | undefined
   */
  public static getKycDocumentsInvalid (subscription: SubscriptionDto): StepKycItemDto[] | undefined {
    return this.getKycDocuments(subscription)?.filter((elt) => elt.reason) || []
  }

  /**
   * Return kyc documents incomplete and invalid
   * (all subscription.steps(KYC).kycSteps except SIGNATURE_ADVANCED with a REASON or status INCOMPLETE)
   * @param subscription
   * @returns StepKycItemDto[] | undefined
   */
  public static getKycDocumentsIncompleteAndInvalid (subscription: SubscriptionDto): StepKycItemDto[] | undefined {
    return this.getKycDocuments(subscription)?.filter((elt) => elt.status === KycStatusDto.INCOMPLETE || elt.reason) || []
  }

  /**
   * Return kyc documents incomplete of a person
   * (person's subscription.steps(KYC).kycSteps except SIGNATURE_ADVANCED with status INCOMPLETE)
   * @param subscription
   * @param personUuid affiliate personUuid
   * @returns StepKycItemDto[] | undefined
   */
  public static getKycAffiliateDocumentsIncomplete (subscription: SubscriptionDto, personUuid: string): StepKycItemDto[] | undefined {
    return this.getKycAffiliateDocuments(subscription, personUuid)?.filter((elt) => elt.status === KycStatusDto.INCOMPLETE) || []
  }

  /**
   * Return kyc documents invalid of a person
   * (person's subscription.steps(KYC).kycSteps except SIGNATURE_ADVANCED with a REASON)
   * @param subscription
   * @param personUuid affiliate personUuid
   * @returns StepKycItemDto[] | undefined
   */
  public static getKycAffiliateDocumentsInvalid (subscription: SubscriptionDto, personUuid: string): StepKycItemDto[] | undefined {
    return this.getKycAffiliateDocuments(subscription, personUuid)?.filter((elt) => elt.reason) || []
  }

  /**
   * Return kyc documents incomplete and invalid of a person
   * (person's subscription.steps(KYC).kycSteps except SIGNATURE_ADVANCED with a REASON or status INCOMPLETE)
   * @param subscription
   * @param personUuid affiliate personUuid
   * @returns StepKycItemDto[] | undefined
   */
  public static getKycAffiliateDocumentsIncompleteAndInvalid (subscription: SubscriptionDto, personUuid: string): StepKycItemDto[] | undefined {
    return this.getKycAffiliateDocuments(subscription, personUuid)?.filter((elt) => elt.status === KycStatusDto.INCOMPLETE || elt.reason) || []
  }

  /**
   * Check if at least one kyc document is invalid
   * @param stepKyc
   * @returns boolean
   */
  public static containsReason (stepKyc: StepKycItemDto[] | undefined): boolean {
    return stepKyc?.filter((elt) => elt.reason !== undefined).length !== 0
  }

  /**
   * Check if kyc documents are status OK
   * @param subscription
   * @returns boolean
   */
  public static areKycDocumentsOk (subscription: SubscriptionDto): boolean {
    return this.isKycStepInProgress(subscription) &&
      (this.getKycDocuments(subscription)?.reduce(
        (prev, curr) => prev && curr?.status === KycStatusDto.OK, true) ?? false)
  }

  /**
   * Check if kyc affiliates documents are status OK
   * @param subscription
   * @param personUuid affiliate personUuid
   * @returns boolean
   */
  public static areKycAffiliateDocumentsOk (subscription: SubscriptionDto, personUuid: string): boolean {
    return this.getKycAffiliateStep(subscription, personUuid)?.kycSteps?.reduce(
      (prev, curr) => prev && curr?.status === KycStatusDto.OK, true) ?? false
  }

  /**
   * Check if all kyc documents and kyc affiliates documents are status OK
   * @param subscription
   * @param affiliates
   * @returns boolean
   */
  public static areAllKycAffiliateDocumentsOk (subscription: SubscriptionDto, affiliates?: PersonAffiliationDto[]): boolean {
    return this.areKycDocumentsOk(subscription) && (affiliates?.reduce((acc, affiliate) => acc &&
      SubscriptionUtils.areKycAffiliateDocumentsOk(subscription, affiliate?.person?.uuid!), true) ?? false)
  }

  /**
   * Check if an incomplete document exists
   * @param subscription
   * @returns boolean
   */
  public static hasKycDocumentsIncomplete (subscription: SubscriptionDto): boolean {
    return this.getKycDocumentsIncomplete(subscription)?.length !== 0
  }

  /**
   * Check if user should reupload invalid documents
   * @param subscription
   * @returns boolean
   */
  public static shouldReuploadKycDocumentsInvalid (subscription: SubscriptionDto): boolean {
    return this.isKycStepInProgress(subscription) && this.containsReason(this.getKycDocuments(subscription))
  }

  /**
   * Check if affiliate should reUpload invalid documents
   * @param subscription
   * @returns boolean
   */
  public static shouldReUploadKycAffiliatesDocumentsInvalid (subscription: SubscriptionDto): boolean {
    return this.isKycStepInProgress(subscription) &&
      this.getKycAffiliatesSteps(subscription)?.filter((step) => step?.kycSteps?.find((kycStep) => kycStep?.reason !== undefined)).length !== 0
  }

  /**
   * Check if subscription contains invalid documents
   * @param subscription
   * @returns boolean
   */
  public static hasKycDocumentsInvalid (subscription: SubscriptionDto): boolean {
    return this.containsReason(this.getKycDocuments(subscription))
  }

  /**
   * Check if an affiliate incomplete document exists
   * @param subscription
   * @param personUuid affiliate personUuid
   * @returns boolean
   */
  public static hasKycAffiliateDocumentsIncomplete (subscription: SubscriptionDto, personUuid: string): boolean {
    return this.getKycAffiliateDocumentsIncomplete(subscription, personUuid)?.length !== 0
  }

  /**
   * Check if kyc affiliates step contains invalid documents
   * @param subscription
   * @param personUuid affiliate personUuid
   * @returns boolean
   */
  public static hasKycAffiliateDocumentsInvalid (subscription: SubscriptionDto, personUuid: string): boolean {
    return this.containsReason(this.getKycAffiliateDocuments(subscription, personUuid))
  }

  /**
   * Return address step
   * @param subscription
   * @returns boolean
   */
  public static getAddressStep (subscription: SubscriptionDto): StepDto | undefined {
    return subscription?.steps?.find((elt) => elt.type === StepTypeDto.ADDRESS_ENTRY)
  }

  /**
   * Check if address step is status OK
   * @param subscription
   * @returns
   */
  public static isAddressOk (subscription: SubscriptionDto): boolean {
    return this.getAddressStep(subscription)?.status === StepStatusDto.OK
  }

  /**
   * Return signature kyc step
   * @param subscription
   * @returns Signature step
   */
  public static getSignatureKycStep (subscription: SubscriptionDto): StepKycItemDto | undefined {
    return this.getKycStep(subscription)?.kycSteps?.find(elt => elt.type === KycTypeDto.SIGNATURE_ADVANCED)
  }

  /**
   * Return signature kyc step
   * @param subscription
   * @returns Signature step
   */
  public static getSignatureKycSteps (subscription: SubscriptionDto): StepKycItemDto[] | undefined {
    return this.getKycStep(subscription)?.kycSteps?.filter(elt => elt.type === KycTypeDto.SIGNATURE_ADVANCED)
  }

  /**
   * Check if signature is invalid,
   * e.g. the documents are not valid for Ariad
   * @param subscription
   * @returns boolean
   */
  public static isSignatureInvalid (subscription: SubscriptionDto): boolean {
    return this.getKycStep(subscription)?.kycSteps
      ?.filter(elt => elt.type === KycTypeDto.SIGNATURE_ADVANCED)
      .filter(it => it?.status === KycStatusDto.INCOMPLETE && !!it?.reason).length !== 0
  }

  /**
   * Check if signature step is status OK (only first signature step found is checked : getSignatureKycStep does find())
   * @param subscription
   * @returns boolean
   */
  public static isSignatureOk (subscription: SubscriptionDto): boolean {
    return this.getSignatureKycStep(subscription)?.status === KycStatusDto.OK
  }

  /**
   * Check if signature step is status OK
   * @param subscription
   * @returns boolean
   */
  public static hasSignatureOk (subscription: SubscriptionDto): boolean {
    return !!this.getSignatureKycSteps(subscription)?.find(elt => elt.status === KycStatusDto.OK)
  }

  /**
   * Check if signature is needed
   * signature not ok AND (documents steps ok OU analysing)
   * @param subscription
   * @param clientType
   * @returns boolean
   */
  public static shouldSign (subscription: SubscriptionDto, clientType: ClientTypeDto): boolean {
    return !this.isSignatureOk(subscription) &&
      ((SubscriptionUtils.areKycDocumentsOk(subscription) ||
        (ClientUtils.isClientTypeCorporate(clientType) &&
          !SubscriptionUtils.hasKycAffiliateStepInProgress(subscription))) || SubscriptionUtils.isKycStepAnalysing(subscription))
  }

  /**
   * Check if documents reUpload is needed
   * @param subscription
   * @returns boolean
   */
  public static shouldReUploadDocuments (subscription: SubscriptionDto): boolean {
    return SubscriptionUtils.shouldReuploadKycDocumentsInvalid(subscription) ||
      SubscriptionUtils.shouldReUploadKycAffiliatesDocumentsInvalid(subscription)
  }

  /**
   * Check if prospect was refused during documents validation
   * @param subscription
   * @returns boolean
   */
  public static isProspectRefused (subscription: SubscriptionDto): boolean {
    return SubscriptionUtils.isKycStepAnalysing(subscription) && SubscriptionUtils.isCanceled(subscription)
  }

  /**
   * Check if kyc step has kbis step
   * @param subscription
   * @returns boolean
   */
  public static withKbis (subscription: SubscriptionDto): boolean {
    return !!this.getKycStep(subscription)?.kycSteps?.find(elt => elt.type === KycTypeDto.COMPANY_KBIS)
  }

  /**
   * Check if kyc step has kbis step complete
   * @param subscription
   * @returns boolean
   */
  public static isKbisFilled (subscription: SubscriptionDto): boolean {
    return this.getKycStep(subscription)?.kycSteps?.find(
      elt => elt.type === KycTypeDto.COMPANY_KBIS)?.status !== KycStatusDto.INCOMPLETE
  }

  /**
   * Return affiliate step
   * @param subscription
   * @returns boolean
   */
  public static getAffiliateEntryStep (subscription: SubscriptionDto): StepDto | undefined {
    return subscription?.steps?.find((elt) => elt.type === StepTypeDto.AFFILIATE_ENTRY)
  }

  /**
   * Check if Affiliate entry step is Ok
   * @param subscription
   * @returns boolean
   */
  public static isAffiliateEntryOk (subscription: SubscriptionDto): boolean {
    return this.getAffiliateEntryStep(subscription)?.status === StepStatusDto.OK
  }

  public static isUpdateClientSeaCgu (subscription: SubscriptionDto): boolean {
    return this.isSubscribing(subscription) &&
      this.isKycStepInProgress(subscription) &&
      this.getSignatureKycStep(subscription)?.status === KycStatusDto.OBSOLETE
  }

  public static getSubscriptionTitle (offerType: OfferTypeDto): string {
    switch (offerType) {
      case OfferTypeDto.AGGREGATE:
        return 'neo-commons:pages:profile:offerType:aggregate'
      case OfferTypeDto.POS:
        return 'neo-commons:pages:profile:offerType:pos'
      case OfferTypeDto.PROJECT:
        return 'neo-commons:pages:profile:offerType:project'
      case OfferTypeDto.CARD:
      default:
        return 'neo-commons:pages:profile:offerType:card'
    }
  }
}
