import { Platform } from 'react-native'
import EnvConfig from 'react-native-config'
import { initializeSslPinning } from 'react-native-ssl-public-key-pinning'

import {
  UserApi,
  AccountApi,
  Configuration as ConfNeobankApi,
  SubscriptionApi,
  AuthenticationApi,
  TransactionApi,
  OtpPhoneApi,
  OtpEmailApi,
  ConfigApi,
  PhoneApi,
  SecretQuestionApi,
  ClientApi,
  AddressApi,
  OfferApi,
  CardApi,
  NotificationPreferenceApi,
  ContactPreferenceApi,
  ContactApi,
  DeviceApi,
  RecurringTransfersApi,
  PersonApi,
  AuthenticationRequestApi,
  DocumentApi,
  CreditCardApi,
  LinkApi,
  NotificationsApi,
  UtilitiesApi,
  PosApi,
  AggregationApi,
  PublicKeypadApi,
  ErrorCodeDto,
  AdvanceApi,
  OrderApi,
} from '@afone/neo-core-client'
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import i18next from 'i18next'
import { DecodedJwt, JwtUtils } from '@neo-commons/libraries'
import { LOCK_DEVICE_CODE } from '@neo-commons/store'

type ScaHandlerType = {
    token,
    authenticationRequestUuid
}

interface Config {
  basePath: string,
  debug: boolean,
  locale?: string,
  os?: string,
  scaHandler: ({ authenticationRequestUuid, steps, eventCode }) => Promise<ScaHandlerType | void>,
  suspendedAccountHandler: () => Promise<void>,
  transactionId?: string,
  appVersion?: string,
}

export interface DeviceBrowserInfoDto {
  name?: string,
  browserUserAgent?: string,
  browserLanguage?: string,
  browserTZ?: number,
  browserJavaEnabled?: boolean,
  browserJavascriptEnabled?: boolean,
}

export class NeobankApi {
  private static instance: NeobankApi

  private baseConf: ConfNeobankApi

  private tokenData: { token: string | null, data: DecodedJwt } = {
    token: null,
    data: {},
  }

  private onExpireToken: () => void
  private onDeviceLock: () => void

  private languageData: { language?: string } = {}
  private sslPinningInitialized: boolean = false

  private axiosInstance: AxiosInstance

  public userApi: UserApi
  public clientApi: ClientApi
  public authenticationApi: AuthenticationApi
  public authenticationRequestApi: AuthenticationRequestApi
  public bankAccountApi: AccountApi
  public contactApi: ContactApi
  public subscriptionApi: SubscriptionApi
  public transactionApi: TransactionApi
  public otpPhoneApi: OtpPhoneApi
  public otpEmailApi: OtpEmailApi
  public phoneApi: PhoneApi
  public configApi: ConfigApi
  public secretQuestionApi: SecretQuestionApi
  public addressApi: AddressApi
  public offerApi: OfferApi
  public notificationPreferenceApi: NotificationPreferenceApi
  public contactPreferenceApi: ContactPreferenceApi
  public cardApi: CardApi
  public deviceApi: DeviceApi
  public recurringTransfersApi: RecurringTransfersApi
  public personApi: PersonApi
  public documentApi: DocumentApi
  public externalCardApi: CreditCardApi
  public linkApi: LinkApi
  public notificationApi: NotificationsApi
  public utilitiesApi: UtilitiesApi
  public posApi: PosApi
  public publicKeypadApi: PublicKeypadApi
  public aggregationApi: AggregationApi
  public advanceApi: AdvanceApi
  public orderApi: OrderApi

  private config: Config

  private constructor (config: Config) {
    this.config = config
    this.baseConf = new ConfNeobankApi({
      basePath: config.basePath,
    })

    if (Platform.OS !== 'web' && !__DEV__ && EnvConfig.ENV !== 'RECETTE') {
      initializeSslPinning({
        [config?.basePath]: {
          includeSubdomains: true,
          publicKeyHashes: [
            'pWOeDccpJxdJ40zUgSrJFOjgudmuyD86BGfi4XNhHSg=', // noelse.com
            '1rBLhqI7aeR5hgXxT/DR8fobI1LaSIvyu3PsYRRbT0M=', // noelse.com, expires on 2024-07-07
          ],
        },
      }).then(() => {
        console.log('ssl pinning initialized')
        this.sslPinningInitialized = true
      }).catch(() => {
        console.log('ssl pinning initialization failed')
        this.sslPinningInitialized = false
      })
    }

    this.axiosInstance = axios.create({
      timeout: 30000, // 30 seconds
      headers: {
        Token: this.tokenData?.token ?? '',
        'x-os': config?.os ?? '',
        'Accept-Language': config?.locale ?? '',
        'X-Transaction-ID': config?.transactionId ?? '',
        'X-App-Version': config?.appVersion ?? '',
      },
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || (config.debug && status === 501)
      },
    })

    /** CONFIGURE & INSTANTIATE HTTP CLIENT  */
    this.axiosInstance.interceptors.request.use(
      request => this.requestHandler(request)
    )

    this.axiosInstance.interceptors.response.use(response => {
      return this.responseHandler(response)
    }, error => {
      if (error && error.response && error.response.config && error.response.status === 423 && this.config.scaHandler) {
        const authenticationRequestUuid = error.response.headers['x-authentication-request-uuid']
        const steps = error.response.data.steps ?? null
        const eventCode = error.response.data.event ?? null

        return this.config.scaHandler({ authenticationRequestUuid, steps, eventCode })
          .then((token) => {
            if (token) error.config.headers.Authorization = `Bearer ${this.tokenData?.token}`
            error.config.headers['x-authentication-request-uuid'] = authenticationRequestUuid
            return this.axiosInstance.request(error?.response?.config)
          })
      }

      if (error && error.response && error.response.status === 403 &&
        error.response.data.errorCode === ErrorCodeDto.C2107 && this.config.suspendedAccountHandler) {
        return this.config.suspendedAccountHandler()
      }

      if (error.request?.status === 401 && this.onExpireToken) {
        this.onExpireToken()
      }

      if (error.request?.status === 400 && error.response.data.errorCode === LOCK_DEVICE_CODE && this.onDeviceLock) {
        this.onDeviceLock()
      }

      throw this.formatError(error)
    })

    /** NEOBANK CORE */
    this.userApi = new UserApi(this.baseConf, '', this.axiosInstance)
    this.clientApi = new ClientApi(this.baseConf, '', this.axiosInstance)
    this.authenticationApi = new AuthenticationApi(this.baseConf, '', this.axiosInstance)
    this.authenticationRequestApi = new AuthenticationRequestApi(this.baseConf, '', this.axiosInstance)
    this.bankAccountApi = new AccountApi(this.baseConf, '', this.axiosInstance)
    this.subscriptionApi = new SubscriptionApi(this.baseConf, '', this.axiosInstance)
    this.transactionApi = new TransactionApi(this.baseConf, '', this.axiosInstance)
    this.otpEmailApi = new OtpEmailApi(this.baseConf, '', this.axiosInstance)
    this.otpPhoneApi = new OtpPhoneApi(this.baseConf, '', this.axiosInstance)
    this.contactApi = new ContactApi(this.baseConf, '', this.axiosInstance)
    this.phoneApi = new PhoneApi(this.baseConf, '', this.axiosInstance)
    this.configApi = new ConfigApi(this.baseConf, '', this.axiosInstance)
    this.secretQuestionApi = new SecretQuestionApi(this.baseConf, '', this.axiosInstance)
    this.addressApi = new AddressApi(this.baseConf, '', this.axiosInstance)
    this.offerApi = new OfferApi(this.baseConf, '', this.axiosInstance)
    this.notificationPreferenceApi = new NotificationPreferenceApi(this.baseConf, '', this.axiosInstance)
    this.contactPreferenceApi = new ContactPreferenceApi(this.baseConf, '', this.axiosInstance)
    this.cardApi = new CardApi(this.baseConf, '', this.axiosInstance)
    this.deviceApi = new DeviceApi(this.baseConf, '', this.axiosInstance)
    this.recurringTransfersApi = new RecurringTransfersApi(this.baseConf, '', this.axiosInstance)
    this.personApi = new PersonApi(this.baseConf, '', this.axiosInstance)
    this.documentApi = new DocumentApi(this.baseConf, '', this.axiosInstance)
    this.externalCardApi = new CreditCardApi(this.baseConf, '', this.axiosInstance)
    this.linkApi = new LinkApi(this.baseConf, '', this.axiosInstance)
    this.notificationApi = new NotificationsApi(this.baseConf, '', this.axiosInstance)
    this.utilitiesApi = new UtilitiesApi(this.baseConf, '', this.axiosInstance)
    this.posApi = new PosApi(this.baseConf, '', this.axiosInstance)
    this.publicKeypadApi = new PublicKeypadApi(this.baseConf, '', this.axiosInstance)
    this.aggregationApi = new AggregationApi(this.baseConf, '', this.axiosInstance)
    this.advanceApi = new AdvanceApi(this.baseConf, '', this.axiosInstance)
    this.orderApi = new OrderApi(this.baseConf, '', this.axiosInstance)
  }

  static getInstance () {
    if (!NeobankApi.instance) {
      throw new Error('No NeobankApi instance created')
    }
    return NeobankApi.instance
  }

  static createInstance (config: Config) {
    if (!NeobankApi.instance) {
      NeobankApi.instance = new NeobankApi(config)
    }
    return NeobankApi.instance
  }

  public requestHandler = (request: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
    if (!request) {
      return Promise.reject<AxiosRequestConfig>(new Error('Bad request.'))
    }
    if (!request.headers) {
      return Promise.reject<AxiosRequestConfig>(new Error('Request headers are undefined.'))
    }

    if (this.languageData.language) {
      request.headers['Accept-Language'] = this.languageData.language
    }

    if (Platform.OS === 'web') {
      request.headers.Authorization = (this.tokenData?.token && this.tokenData.data?.exp &&
      !JwtUtils.isExpired(this.tokenData.data?.exp) ? `Bearer ${this.tokenData?.token}` : '')
      return request
    } else if (this.tokenData.token && this.sslPinningInitialized) {
      return this.checkSslPinning().then(() => {
        if (request.headers) {
          request.headers.Authorization = 'Bearer ' + (this.tokenData?.token ?? '')
        }
        return request
      }).catch(error => {
        throw error
      })
    } else {
      return request
    }
  }

  public responseHandler = (response: AxiosResponse) => {
    return response
  }

  public formatError = (error: AxiosError) => {
    const status: number = error.request?.status
    const message: string = (error.response as any)?.data?.message ?? this._getDefaultErrorMessageFromStatus(status)
    const headers: Record<string, any> = error.response?.headers ?? {}

    return {
      data: error.response?.data,
      message: message,
      status: status,
      code: (error.response as any)?.data?.errorCode,
      headers,
    }
  }

  private _getDefaultErrorMessageFromStatus = (status: number) => {
    if (status >= 500) {
      return i18next.t('errors:unknownTechnicalIssue')
    } else if (status >= 400 && status < 500) {
      return i18next.t('errors:queryError')
    } else if (status === 0) {
      return i18next.t('errors:networkError')
    } else {
      return i18next.t('errors:unknownTechnicalIssue')
    }
  }

  private async checkSslPinning () {
    if (__DEV__ || EnvConfig.ENV === 'RECETTE') {
      return
    }
    try {
      if (this.config.basePath) {
        await axios.get(this.config.basePath + '/public/config')
      }
    } catch (error) {
      throw new Error('SSL pinning failed')
    }
  }

  public setOnDeviceLock = (onDeviceLock: () => void) => {
    this.onDeviceLock = onDeviceLock
  }

  public setDataToken = (token: string) => {
    this.tokenData = {
      token: token,
      data: JwtUtils.parseToken(token),
    }
  }

  public setOnExpireToken = (onExpireToken: () => void) => {
    this.onExpireToken = onExpireToken
  }

  public getToken () {
    return this.tokenData
  }

  public setAuthToken (token: string | undefined) {
    this.tokenData = {
      token: token === undefined ? null : token,
      data: token === undefined ? {} : JwtUtils.parseToken(token),
    }

    if (this.tokenData.data?.exp && this.onExpireToken) {
      const isAlreadyExpired = JwtUtils.isExpired(this.tokenData.data?.exp)
      if (isAlreadyExpired) {
        this.tokenData = {
          token: null,
          data: {},
        }
        this.onExpireToken()
      } else {
        const expiresIn = JwtUtils.expiresIn(this.tokenData.data?.exp)
        setTimeout(this.onExpireToken, Math.min(expiresIn, 2147483647))
      }
    }
    NeobankApi.getInstance().baseConf.accessToken = token
  }

  public isAgentClient (): boolean {
    return !!this.getToken().data.data?.agentClient
  }

  public setLanguage (language?: string) {
    this.languageData = { language: language ?? undefined }
  }
}
