import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { IndividualSubscriptionStep, IndividualSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Stepper2, Typography2 } from '@neo-commons/components'
import { ClientActions } from '@neo-commons/store'

import { ClientTypeDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './USPerson.scss'

type USPersonProps =
  StepComponentProps<IndividualSubscriptionSteps[IndividualSubscriptionStep.USPerson]>

export const USPerson: React.FC<USPersonProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const subscriptionPrepare = useSelector((state: StateTypes) => state.subscription?.prepare)
  const isSubscriptionLoading = useSelector((state: StateTypes) => state.subscription?.loading)
  const loiFatcaUrl = useSelector((state: StateTypes) => state.config.data.currentConfig?.fatcaUrl)

  const onNotUSPersonPress = async () => {
    try {
      await dispatch(ClientActions.create({ taxUsPayer: false, type: ClientTypeDto.INDIVIDUAL }))
      nextStep({
        offerUuid: subscriptionPrepare?.offerUuid,
        clientType: ClientTypeDto.INDIVIDUAL,
        discountCode: subscriptionPrepare?.discountCode,
      })
    } catch (error) {}
  }

  return (
    <TunnelContent
      loading={isSubscriptionLoading}
      justifyContent={TunnelJustifyContent.CENTER}
      header={
        <div className='USPerson'>
          <Typography2 typeface='h3'>
            {t('app:pages:subscription:individual:mainTitle:taxResidence')}
          </Typography2>
          <Stepper2 currentStep={0} />
          <Typography2 typeface='h4'>
            {t('app:pages:subscription:individual:usPerson:title')}
          </Typography2>
          <div className='USPerson_container'>
            <div className='USPerson_container_subtitle'>
              <Typography2 typeface='h5' style={{ fontFamily: 'Quicksand-Medium' }}>
                {t('app:pages:subscription:individual:usPerson:subtitle')}
              </Typography2>
            </div>
            <div className='USPerson_container_description_list'>
              <Typography2 typeface='body1'>
                {t('app:pages:subscription:individual:usPerson:description:list')}
              </Typography2>
            </div>
            <div className='USPerson_container_description_info'>
              <div className='USPerson_container_description_info_text'>
                <Typography2 typeface='body1'>
                  {t('app:pages:subscription:individual:usPerson:description:info:text')}
                </Typography2>
                <a
                  className='USPerson_container_description_info_text_link'
                  href={loiFatcaUrl}
                  target='_blank' rel='noreferrer'
                >
                  {t('app:pages:subscription:individual:usPerson:description:info:link')}
                </a>
              </div>
            </div>
          </div>
        </div>
      }
      footer={
        <div>
          <div>
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:pages:subscription:individual:usPerson:notUsPersonButton')}
              onPress={onNotUSPersonPress}
            />
          </div>
          <div>
            <Button
              skin={ButtonSkin.TRANSPARENT_PRIMARY}
              title={t('app:pages:subscription:individual:usPerson:usPersonButton')}
              onPress={() => {
                history.push(Routes.Subscription.usPersonFallback.path)
              }}
            />
          </div>
        </div>
      }
    />
  )
}
