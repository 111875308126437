import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { AppConfigTxt } from 'Config/AppConfig'

import { Button, ButtonSkin, Typography } from '@neo-commons/components'
import { ClientUtils } from '@neo-commons/libraries'
import { ProjectStep, projectSteps } from '@neo-commons/policies'
import {
  BankAccountActions,
  BankAccountSelectors,
  BankAccountTypes,
  ClientSelectors,
  OfferActions,
  OfferSelectors,
  PrepareSubAccountOrder,
} from '@neo-commons/store'

import {
  AccountDto,
  AccountTypeDto,
  ClientDto,
  OfferDto,
  OfferTypeDto,
  SubAccountCreationRequestDto,
} from '@afone/neo-core-client/dist/models'

import { JustifyContent, LazyLoadingContainer, PageContent } from '@neo-web/componentsOld'

export const Offers: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const client: ClientDto = useSelector(ClientSelectors.byId(selectedBankAccount?.clientUuid))
  const projectOffer: OfferDto[] = useSelector(OfferSelectors.listByClientType(
    client?.type,
    OfferTypeDto.PROJECT)
  )
  const preparedSubAccountOrder: PrepareSubAccountOrder = useSelector(BankAccountSelectors.getPreparedSubAccountOrder)

  const subAccountCreationRequestDto: SubAccountCreationRequestDto = {
    accountType: AccountTypeDto.PROJECT,
    icon: AppConfigTxt.DEFAULT_PROJECT_ICON,
  }
  const isCorporate = ClientUtils.isClientTypeCorporate(client?.type)

  useEffect(() => {
    (async () => {
      try {
        await dispatch(
          BankAccountActions.createSubAccount({
            mainAccountUuid: selectedBankAccount?.uuid,
            xValidationOnly: 1,
            subAccountCreationRequestDto: subAccountCreationRequestDto,
          })
        )
        if (!projectOffer.length) await dispatch(OfferActions.listForClientUuid())
      } catch (error) {}
    })()
  }, [])

  return (
    <LazyLoadingContainer events={[
      BankAccountTypes.LIST_BANK_ACCOUNT_REQUEST,
      BankAccountTypes.CREATE_SUB_ACCOUNT_REQUEST]}
    >
      <PageContent
        justifyContent={JustifyContent.CENTER}
        title={t('app:pages:services:project:offers:escort')}
        description={
          <div style={{ textAlign: 'center' }}>
            <Typography typeface='content'>
              {
                isCorporate
                  ? t('app:pages:services:project:offers:pro:description')
                  : t('app:pages:services:project:offers:part:description')
              }
              <br /><br />
              {
                isCorporate
                  ? t(preparedSubAccountOrder?.cost?.amount > 0
                    ? 'app:pages:services:project:offers:pro:priceOld'
                    : 'app:pages:services:project:offers:pro:price', {
                    description: projectOffer[0]?.price,
                    maxAccountPerSubscription: (projectOffer[0]?.data as any)?.maxAccountPerSubscription,
                  })
                  : t(preparedSubAccountOrder?.cost?.amount > 0
                    ? 'app:pages:services:project:offers:part:priceOld'
                    : 'app:pages:services:project:offers:part:price', {
                    description: projectOffer[0]?.price,
                    maxAccountPerSubscription: (projectOffer[0]?.data as any)?.maxAccountPerSubscription,
                  })
              }

            </Typography>
          </div>
        }
        button={
          <Button
            skin={ButtonSkin.PRIMARY}
            title={
              t(preparedSubAccountOrder?.cost?.amount > 0
                ? 'app:pages:services:project:offers:activate'
                : 'app:pages:services:project:offers:enjoyMyFreeProjects')
            }
            containerStyle={{ width: '100%' }}
            onPress={() => history.push(projectSteps[ProjectStep.Name].path)}
          />
        }
      />
    </LazyLoadingContainer>
  )
}
