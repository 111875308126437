import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Popover, Radio, RadioGroup, Whisper, WhisperInstance } from 'rsuite'
import { ValueType } from 'rsuite/esm/Radio'

import { AddressDataType, AddressUtils } from '@neo-commons/libraries'
import { IconTypes, Icon } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { AutocompleteModeDto, CountryDto, AutocompletePredictionDto } from '@afone/neo-core-client/dist/models'

import './AddressButtonWhisper.scss'

export enum AddressChoices {
  ORIGINAL = 'original',
  NEW = 'new',
}

export interface AddressButtonWhisperProps {
  originAddress: AddressDataType,
  onSelect: (address: AddressDataType) => void,
  buttonDisabled?: boolean,
  buttonTitle?: string,
}

export const AddressButtonWhisper: React.FC<AddressButtonWhisperProps> = ({
  originAddress, onSelect, buttonDisabled, buttonTitle,
}) => {
  const { t } = useTranslation()
  const triggerRef = useRef<WhisperInstance>()
  const popoverRef = useRef<HTMLDivElement>()
  const open = () => triggerRef.current.open()
  const close = () => triggerRef.current.close()
  const [suggestedAddress, setSuggestedAddress] = useState<AddressDataType>(null)
  const [noSuggestedAddressFound, setNoSuggestedAddressFound] = useState<boolean>(false)
  const [addressChoice, setAddressChoice] = useState<ValueType>(AddressChoices.ORIGINAL)
  const addressToCheck = originAddress.address + ', ' + originAddress?.zipCode + ', ' + originAddress.cityName + ', ' + originAddress.countryData?.name

  const addressChoiceComponent =
    <RadioGroup value={addressChoice} onChange={setAddressChoice}>
      <Radio value={AddressChoices.ORIGINAL}>
        {t('neo-commons:address:original')} : {addressToCheck}
      </Radio>
      <Radio value={AddressChoices.NEW} disabled={!suggestedAddress}>
        {t('neo-commons:address:suggested')} : {suggestedAddress && (
          (suggestedAddress?.address?.length ? suggestedAddress?.address + ', ' : '') +
          (suggestedAddress?.zipCode?.length ? suggestedAddress?.zipCode + ', ' : '') +
          (suggestedAddress?.cityName?.length ? suggestedAddress?.cityName + ', ' : '') +
          (suggestedAddress?.countryData?.name?.length ? suggestedAddress?.countryData?.name : '')
        )}
      </Radio>
    </RadioGroup>

  const addressNotFindComponent =
    <div className='WhisperNoAddress'>
      <Icon name='info' color={Colors.secondary} type={IconTypes.FEATHER} size={25} />
      <div
        className='WhisperNoAddress_text'
        dangerouslySetInnerHTML={{ __html: t('neo-commons:address:unknown', { originAddress: addressToCheck, addressSave: t('neo-commons:address:save') }) }}
      />
    </div>

  const addressWhisperSpeaker =
    <Popover>
      <div ref={popoverRef}>
        {suggestedAddress
          ? addressChoiceComponent
          : addressNotFindComponent}
      </div>
    </Popover>

  const suggestedAddressWhisperNeeded = async () => {
    try {
      let suggestedAddress: AddressDataType
      // ADDRESS call
      const autocompleteAddresses: AutocompletePredictionDto[] = await AddressUtils.autoCompleteAddress(
        addressToCheck,
        AutocompleteModeDto.ADDRESS,
        originAddress?.countryData?.isoCodeAlpha2,
      )

      if (autocompleteAddresses?.length > 0) {
        // If origin address is same as suggested address : no need to show suggestion
        if (AddressUtils.doesAutoCompleteContainFullAddress(autocompleteAddresses, addressToCheck)) {
          return {
            isSuggestedNeeded: false,
            isSuggestedFound: true,
            suggestedAddress: originAddress,
          }
        }

        // If city and zipCode are same but not the address description, we suggest this address description change
        const autocompleteCityZipCode = AddressUtils.searchAutoCompleteWithZipCodeAndCity(autocompleteAddresses, originAddress)
        if (autocompleteCityZipCode) {
          suggestedAddress = await AddressUtils.callFullAddressData(autocompleteCityZipCode.placeId, originAddress.countryData as CountryDto, originAddress.additionalAddress)
          return {
            isSuggestedNeeded: true,
            isSuggestedFound: true,
            suggestedAddress: suggestedAddress,
          }
        }

        // If city and address description are same but not zipCode, we suggest this zipCode change
        const autocompleteCityAddress = AddressUtils.searchAutoCompleteWithAddressAndCity(autocompleteAddresses, originAddress)
        if (autocompleteCityAddress) {
          suggestedAddress = await AddressUtils.callFullAddressData(autocompleteCityAddress.placeId, originAddress.countryData as CountryDto, originAddress.additionalAddress)
          return {
            isSuggestedNeeded: true,
            isSuggestedFound: true,
            suggestedAddress: suggestedAddress,
          }
        }

        // If zipCode and address description are same but not the city, we suggest this city change
        const autocompleteZipCodeAddress = AddressUtils.searchAutoCompleteWithAddressAndZipCode(autocompleteAddresses, originAddress)
        if (autocompleteZipCodeAddress) {
          suggestedAddress = await AddressUtils.callFullAddressData(autocompleteZipCodeAddress.placeId, originAddress.countryData as CountryDto, originAddress.additionalAddress)
          return {
            isSuggestedNeeded: true,
            isSuggestedFound: true,
            suggestedAddress: suggestedAddress,
          }
        }

        // If zipCode and city are not same but the address description is correct, we suggest to change city and zipCode
        const autocompleteAddress = AddressUtils.searchAutoCompleteWithAddressDescription(autocompleteAddresses, originAddress)
        if (autocompleteAddress) {
          suggestedAddress = await AddressUtils.callFullAddressData(autocompleteAddress.placeId, originAddress.countryData as CountryDto, originAddress.additionalAddress)
          return {
            isSuggestedNeeded: true,
            isSuggestedFound: true,
            suggestedAddress: suggestedAddress,
          }
        }

        // If still no match but suggested address found, we suggest the first one
        suggestedAddress = await AddressUtils.callFullAddressData(autocompleteAddresses[0].placeId, originAddress.countryData as CountryDto, originAddress.additionalAddress)
        return {
          isSuggestedNeeded: true,
          isSuggestedFound: true,
          suggestedAddress: suggestedAddress,
        }
      } else {
        return {
          isSuggestedNeeded: true,
          isSuggestedFound: false,
          suggestedAddress: undefined,
        }
      }
    } catch (e) {
      return {
        isSuggestedNeeded: false,
        isSuggestedFound: false,
        suggestedAddress: undefined,
      }
    }
  }

  const checkAddress = async () => {
    await setNoSuggestedAddressFound(false)
    const { isSuggestedNeeded, isSuggestedFound, suggestedAddress } = await suggestedAddressWhisperNeeded()
    if (isSuggestedNeeded) {
      if (isSuggestedFound) {
        await setSuggestedAddress(suggestedAddress)
      } else {
        await setNoSuggestedAddressFound(true)
      }
      open()
    } else {
      onSelect(suggestedAddress || originAddress)
    }
  }

  const updateAddress = async () => {
    onSelect(addressChoice === AddressChoices.NEW ? suggestedAddress : originAddress)
  }

  useEffect(() => {
    function handleClickOutside (event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        close()
      }
    }
    if (suggestedAddress || noSuggestedAddressFound) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [suggestedAddress, popoverRef, noSuggestedAddressFound])

  return (
    <Whisper
      ref={triggerRef}
      className='AddressButtonWhisper'
      trigger='none'
      placement='top'
      onClose={() => {
        setSuggestedAddress(null)
        setNoSuggestedAddressFound(false)
        setAddressChoice(AddressChoices.ORIGINAL)
      }}
      speaker={addressWhisperSpeaker}
    >
      <Button
        disabled={buttonDisabled}
        onClick={suggestedAddress || noSuggestedAddressFound ? updateAddress : checkAddress}
        className='AddressButtonWhisper_button'
      >
        {suggestedAddress || noSuggestedAddressFound ? t('neo-commons:address:save') : buttonTitle ?? t('app:global:validate')}
      </Button>
    </Whisper>
  )
}
