import React, { FunctionComponent, ReactElement } from 'react'
import { TextStyle, Pressable, View, ViewStyle } from 'react-native'

import i18next from 'i18next'
import { Colors } from '@neo-commons/styles'
import { isString } from 'lodash'

import { Typography } from '../Typography/Typography'
import { Typography2 } from '../Typography/Typography2'
import { Icon, IconTypes } from '../Icon/Icon'
import { Badge, BadgeSkin } from '../Badge/Badge'

import styles from './styles'

interface MenuItemCardProps {
  highlightTitle?: boolean
  title?: string | React.ReactElement,
  subtitle?: string,
  titleNumber?: number,
  icon?: React.ReactElement | string,
  iconType?: IconTypes,
  extra?: React.ReactElement,
  onPress?: () => void,
  titleStyle?: TextStyle,
  subtitleStyle?: TextStyle,
  containerStyle?: ViewStyle,
  chevronContainerStyle?: ViewStyle,
  chevronPosition?: 'flex-start' | 'center' | 'flex-end',
  testID?: string,
  disabled?: boolean,
  chevronColor?: string,
  extraIcon?: React.ReactElement,
  availableSoon?: boolean
}

export const MenuItemCard: FunctionComponent<MenuItemCardProps> = (props: MenuItemCardProps) => {
  const {
    highlightTitle = false,
    testID,
    title,
    subtitle,
    titleNumber,
    extra,
    chevronColor,
    onPress,
    disabled,
    icon,
    iconType,
    containerStyle,
    titleStyle,
    subtitleStyle,
    chevronContainerStyle,
    chevronPosition,
    extraIcon,
    availableSoon,
  } = props

  const DisabledIcon: FunctionComponent = () => {
    return React.cloneElement(icon as ReactElement, { color: Colors.darkGray }, null)
  }
  return (
    <Pressable
      testID={testID ?? 'menuItemCard'}
      disabled={props.disabled}
      onPress={() => {
        if (!disabled && onPress) {
          onPress()
        }
      }}
      style={({ pressed }) => [
        styles.container,
        !availableSoon ? styles.shadow : {},
        {
          marginBottom: 8,
          backgroundColor: pressed && !!onPress ? Colors.blueAnimation : Colors.white,
        },
        containerStyle,
        !onPress ? styles.basicCursor : {},
      ]}
    >
      <View style={styles.containerBasic}>
        {icon &&
          <View style={styles.leftArea} accessibilityLabel='icon'>
            {typeof icon === 'string'
              ? (<Icon type={iconType} name={icon} size={24} color={Colors.darkGray} />)
              : disabled ? <DisabledIcon /> : icon}
          </View>}
        <View style={styles.centerArea} accessibilityLabel={isString(title) ? title : ''}>
          <View style={styles.rowTitle}>
            {title
              ? highlightTitle ? (
                <View style={{
                  borderRadius: 12,
                  paddingHorizontal: 8,
                  height: 24,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: Colors.primary,
                  marginLeft: -3,
                }}
                >
                  <Typography typeface='button2' style={{ color: Colors.white, marginTop: -4 }}>{title}</Typography>
                </View>
              ) : (
                <Typography2
                  typeface='input'
                  style={[
                    titleStyle || {}, styles.title,
                    (availableSoon || disabled) ? { color: Colors.darkGray } : {},
                  ]}
                >
                  {title}
                </Typography2>
              ) : undefined}
            {titleNumber &&
              <View>
                <Typography typeface='body2' style={styles.titleNumber}>{titleNumber}</Typography>
              </View>}
          </View>
          {subtitle &&
            <Typography
              typeface='body2'
              accessibilityLabel='InformationSubtitle'
              style={[styles.subtitle, subtitleStyle || {},
                (availableSoon || disabled) ? { color: Colors.darkGray } : {}]}
            >
              {subtitle}
            </Typography>}
        </View>
        {!availableSoon &&
          <View
            style={[{ justifyContent: chevronPosition ?? 'flex-start' }, styles.chevronContainerStyle,
              chevronContainerStyle]}
          >
            {extraIcon || (
              <Icon
                color={chevronColor || (disabled ? Colors.darkGray : '#8d8d8d')}
                name='chevron-right'
                type={IconTypes.ENTYPO}
                size={24}
              />
            )}
          </View>}
        {availableSoon &&
          <Badge
            skin={BadgeSkin.WARNING}
            value={i18next.t('services:soonAvalaible')}
          />}
      </View>
      {extra &&
        <View accessibilityLabel='extra'>
          {extra}
        </View>}
    </Pressable>
  )
}
