import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { Routes } from 'Routes/Routes'

import { Step, Stepper } from '@neo-commons/components'
import { RecoverPasscodeTypes, SignInTypes, SignupTypes } from '@neo-commons/store'

import { LazyLoadingContainer, PageDetails } from '@neo-web/componentsOld'

import './ResetCodeLayout.scss'

interface ResetCodeLayoutProps {
  children: ReactNode,
  stepper: any,
  goBack: () => void
}

export const ResetCodeLayout: React.FC<ResetCodeLayoutProps> = ({ children, stepper, goBack }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <PageDetails
      component={
        <LazyLoadingContainer
          events={[SignInTypes.CONFIRM_CODE_REQUEST,
            SignupTypes.PASSWORD_CHECK_REQUEST,
            RecoverPasscodeTypes.CHANGE_PASSCODE_REQUEST]}
        >
          <div className='ResetCodeLayout'>
            <div className='ResetCodeLayout_container'>
              <div className='ResetCodeLayout_stepper'>
                <Stepper steps={stepper as unknown as Step[]} />
              </div>
              {children}
            </div>
          </div>
        </LazyLoadingContainer>
      }
      onBack={() => goBack()}
      onClose={() => history.push(Routes.Profile.security.path)}
      title={t('app:pages:myProfile:codeReset:screen:title')}
    />
  )
}
