import React, { useEffect } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { store } from 'Store'
import { Routes } from 'Routes/Routes'

import { SecretQuestionActions, UserSecretQuestionActions } from '@neo-commons/store'
import { useWizardPolicy } from '@neo-commons/hooks'
import { SecretQuestionPolicy, SecretQuestionStep } from '@neo-commons/policies'

import { EditSecretQuestion } from './Step1/EditSecretQuestion'
import { SecretQuestionSuccess } from './Step2/SecretQuestionSuccess'

export const SecretQuestion: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentStep, steps: wizardSteps } = useWizardPolicy(store, SecretQuestionPolicy, {
    [SecretQuestionStep.SecretQuestion]: EditSecretQuestion,
    [SecretQuestionStep.Success]: SecretQuestionSuccess,
  }, {
    onDone: async () => {
      history.replace(Routes.Profile.security.path)
    },
  })

  useEffect(() => {
    dispatch(SecretQuestionActions.getSecretQuestionList())
    dispatch(UserSecretQuestionActions.reset())
  }, [])

  return (
    <>
      {currentStep?.path &&
        <Redirect
          to={{
            pathname: currentStep?.path,
          }}
        />}
      {wizardSteps.map((value) => {
        return (
          <Route
            key={value.navigationKey}
            exact
            path={value.path}
            component={value.component}
          />
        )
      })}
    </>
  )
}
