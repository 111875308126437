import { DeviceActions } from '@neo-commons/store'

import { LinkTypeDto } from '@afone/neo-core-client/dist/models'

import { AbstractPolicy } from '../AbstractPolicy'
import { store } from '../../Store'

export type ActionObject = {
  title: string,
  subTitle: string,
  buttonText: string,
  data?: string
}

export class MailActionPolicy extends AbstractPolicy {
  public static async processMailAction ({
    type,
    confirm,
    data,
    expiryMessage = undefined,
  }) {
    let redirect

    if (expiryMessage) {
      return {
        title: 'app:pages:mail:expiry:title',
        subTitle: expiryMessage,
        buttonText: 'app:pages:mail:expiry:buttonText',
      }
    }

    if (type === LinkTypeDto.DEVICE_ACCESS_CONFIRMATION) {
      redirect = this.processBlockDevices(confirm, data)
    }

    return redirect
  }

  public static async processBlockDevices (confirm: boolean, data) {
    return confirm ? this.processBlockDevicesYes(data) : this.processBlockDevicesNo()
  }

  public static async processBlockDevicesYes (data) {
    const deviceInfo = await store.dispatch((DeviceActions.lock(data.deviceUuid) as any))

    return {
      title: 'app:pages:mail:blockDevices:sayYes:title',
      subTitle: 'app:pages:mail:blockDevices:sayYes:subTitle',
      buttonText: 'app:pages:mail:blockDevices:sayYes:buttonText',
      data: deviceInfo.name,
    }
  }

  public static async processBlockDevicesNo () {
    return {
      title: 'app:pages:mail:blockDevices:sayNo:title',
      subTitle: 'app:pages:mail:blockDevices:sayNo:subTitle',
      buttonText: 'app:pages:mail:blockDevices:sayNo:buttonText',
    }
  }
}
