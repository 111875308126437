import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { config, ENV } from 'Config/EnvConfig'
import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'
import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'

import { SubscriptionUtils } from '@neo-commons/libraries'
import { Client, ClientSelectors, SubscriptionSelectors } from '@neo-commons/store'

import { ClientTypeDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'

import './ElectronicSignature.scss'

export const ElectronicSignature: React.FC = () => {
  const signatureFrame = useRef(null)
  const history = useHistory()

  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOne)
  const signatureLink: string = useSelector((state: StateTypes) => state.subscription?.signatureLink)
  const client: Client = useSelector(ClientSelectors.defaultOne)

  useEffect(() => {
    AnalyticsHandler.track(
      AnalyticsEvents.KYC.eSignatureStarted,
      {
        account_type: client?.type === ClientTypeDto.CORPORATE ? 'B2B' : 'B2C',
        contract_slug: 'change_account_cgu',
      }
    )
    if (subscription?.uuid) {
      const stepKycUuid = SubscriptionUtils.getKycStep(subscription)?.uuid
      const sse = new EventSource(
        `${config(ENV.SSE_URL)}?id=${stepKycUuid}&ttl=${config(ENV.SSE_TIME_TO_LIVE)}`
      )
      sse.onmessage = () => {
        history.replace(Routes.Origin.path)
        sse.close()
      }
      sse.onerror = () => {
        sse.close()
      }
      return () => {
        sse.close()
      }
    }
  }, [])

  return (
    <div className='ElectronicSignature_container'>
      {signatureLink && (
        <iframe
          ref={signatureFrame}
          id='output-frame'
          title='Electronic Signature'
          src={signatureLink}
          width='100%'
          height='100%'
          style={{ border: 'none' }}
        />
      )}
    </div>
  )
}
