import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Sidenav } from 'rsuite'
import { Routes } from 'Routes/Routes'

import { Icon, IconTypes } from '@neo-commons/components'

import { NavbarMenu } from '@neo-web/componentsOld'

import logo from '../../Resources/Images/site/dark-logo.svg'

import './SidenavMenu.scss'

interface SidenavMenuProps {
  onClose: () => void
  isOpen: boolean
}

export const SidenavMenu: React.FC<SidenavMenuProps> = ({
  onClose,
  isOpen,
}: SidenavMenuProps) => {
  const [wasOpened, setWasOpened] = useState(false)

  useEffect(() => {
    if (isOpen && !wasOpened) {
      setWasOpened(true)
    }
  }, [isOpen, wasOpened])

  return (
    <>
      <Sidenav>
        <div
          className={`SidenavMenu_close-icon-container ${
            isOpen ? 'SidenavMenu_close-icon-container_open' : ''
          }`}
        >
          <span className='SidenavMenu_close-icon'>
            <Icon
              name='x'
              size={50}
              type={IconTypes.FEATHER}
              onPress={onClose}
            />
          </span>
        </div>
        <Sidenav.Header className='content-center'>
          <div>
            <Link
              className='navbar-brand logo nav-link'
              to={Routes.Synthesis.transactions.path}
            >
              <img src={logo} alt='Logo' />
            </Link>
          </div>
        </Sidenav.Header>
        <Sidenav.Body>
          <NavbarMenu />
        </Sidenav.Body>
      </Sidenav>
    </>
  )
}
