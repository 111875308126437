import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import { RecurringTransferDto, RecurringTransferPeriodDto, TransactionDto, TransactionTypeFamilyDto } from '@afone/neo-core-client/dist/models'
import { PaginateItem } from '@neo-commons/store'

export type Transaction = RecurringTransferDto | TransactionDto

export interface GroupTransaction extends PaginateItem<Transaction> {
  date: number,
}

export enum RecurringTransferValues {
  EVERY_MONTHS = 'EVERY_MONTHS',
  EVERY_7_DAYS = 'EVERY_7_DAYS',
  EVERY_14_DAYS = 'EVERY_14_DAYS',
}

export enum TransactionType {
  PRE_AUTHO = 'PA01',
}

/**
   * Generic methods use in NoElse for transaction.
   *
   */
export class TransactionUtils {
  /**
   * Group the transaction by date
   *
   * @param {Transaction[]} transactions - List of transaction
   * @param field
   * @param orders
   * @returns {GroupTransaction[]} return the list transaction in group by date
   *
   */
  public static groupTransactionsByDate = (transactions: Transaction[], field = 'transactionDate',
    orders: 'desc'|'asc' = 'desc'): PaginateItem<Transaction>[] => {
    return orderBy(
      transactions.reduce((previousValue: GroupTransaction[], currentValue: Transaction) => {
        if (!currentValue) {
          return previousValue
        }
        const date = dayjs(currentValue[field]).format('D MMMM')

        const existing = previousValue.find(group => group.title === date)
        if (existing) {
          existing.data.push(currentValue)
          existing.data = orderBy(existing.data, field, 'desc')
        } else {
          previousValue.push({ title: date, date: dayjs(currentValue[field]).unix(), data: [currentValue] })
        }
        return previousValue
      }, [])
      , ['date'], [orders])
  }

  /**
   * Convert recurring value of apps to period and frequency
   *
   * @param {RecurringTransferValues} recurringValue - Recurrence value on App
   * @returns {[number, RecurringTransferPeriodDto]} return the frequency and period
   *
   */
  public static getRecurrence = (recurringValue: RecurringTransferValues): [number, RecurringTransferPeriodDto] => {
    let frequency = 1
    let period

    switch (recurringValue) {
      case RecurringTransferValues.EVERY_MONTHS:
        period = RecurringTransferPeriodDto.MONTH
        break

      case RecurringTransferValues.EVERY_7_DAYS:
        frequency = 7
        period = RecurringTransferPeriodDto.DAY
        break

      case RecurringTransferValues.EVERY_14_DAYS:
        frequency = 14
        period = RecurringTransferPeriodDto.DAY
        break

      default:
        frequency = 0
        period = RecurringTransferPeriodDto.MONTH
        break
    }

    return [frequency, period]
  }

  /**
   * Convert  period and frequency to recurring value of apps .
   *
   * @param {number} frequencyTransfer - Frequency of transfer
   * @param {RecurringTransferPeriodDto} periodTransfer - the period of transfer
   *
   */
  public static getRecurrenceIhm = (frequencyTransfer: number, periodTransfer: RecurringTransferPeriodDto):
    RecurringTransferValues => {
    for (const item in RecurringTransferValues) {
      const [frequency, period] = TransactionUtils.getRecurrence(RecurringTransferValues[item])

      if (frequencyTransfer === frequency && period === periodTransfer) {
        return RecurringTransferValues[item]
      }
    }

    return RecurringTransferValues.EVERY_7_DAYS
  }

  /**
   * Convert recurring value to app text
   *
   * @param {RecurringTransferValues} recurring - Recurrence value on App
   *
   */
  public static getLabelName = (recurring: RecurringTransferValues) => {
    let result = ''

    /*
     * We add a comment for each key with t() to trigger i18next-scanner detection
     */
    switch (recurring) {
      case RecurringTransferValues.EVERY_7_DAYS:
        result = 'app:pages:payments:transfer:reccuring:everysevenday' // i18next.t('app:pages:payments:transfer:reccuring:everysevenday')
        break
      case RecurringTransferValues.EVERY_14_DAYS:
        result = 'app:pages:payments:transfer:reccuring:everytwoweeks' // i18next.t('app:pages:payments:transfer:reccuring:everytwoweeks')
        break
      case RecurringTransferValues.EVERY_MONTHS:
        result = 'app:pages:payments:transfer:reccuring:everymonth' // i18next.t('app:pages:payments:transfer:reccuring:everymonth')
        break
    }

    return result
  }

  /**
   * TransactionDto type guard
   */
  public static isTransactionDto (transaction: Transaction | undefined): transaction is TransactionDto {
    return (transaction as TransactionDto)?.completed !== undefined
  }

  /**
   * RecurringTransferDto type guard
   */
  public static isRecurringTransferDto (transaction: Transaction | undefined): transaction is RecurringTransferDto {
    return (transaction as RecurringTransferDto) !== undefined
  }

  /**
   * Return true if the transaction is a Pre-authorisation
   */
  public static isPreAuthorisation (transaction: TransactionDto): boolean {
    return transaction?.type === TransactionType.PRE_AUTHO
  }

  /**
   * Return true if the transaction family is FEES
   */
  public static isFee (transaction: TransactionDto): boolean {
    return transaction?.family === TransactionTypeFamilyDto.FEES
  }

  public static getIconFromTransactionFamily = (data: TransactionDto) => {
    switch (data.family) {
      case TransactionTypeFamilyDto.AC_PURCHASE:
      case TransactionTypeFamilyDto.AC_CREDIT_CGBK:
      case TransactionTypeFamilyDto.AC_PURCHASE_REPRES:
        return 'tlc-ok'
      case TransactionTypeFamilyDto.AC_PURCHASE_CGBK:
      case TransactionTypeFamilyDto.AC_CREDIT:
      case TransactionTypeFamilyDto.AC_CREDIT_REPRES:
        return 'tlc-remboursement'
      case TransactionTypeFamilyDto.FEES:
        return 'fee'
      case TransactionTypeFamilyDto.IS_CASH:
        return 'cash-out'
      case TransactionTypeFamilyDto.OTHER:
      case TransactionTypeFamilyDto.IS_PURCHASE:
        return 'card'
      case TransactionTypeFamilyDto.SDDI:
        return 'prlv-in'
      case TransactionTypeFamilyDto.SCTI:
      case TransactionTypeFamilyDto.SICTI:
        return 'vir-in'
      case TransactionTypeFamilyDto.SCTO:
      case TransactionTypeFamilyDto.SICTO:
        return 'vir-out'
      case TransactionTypeFamilyDto.AC_FUND:
        return 'credit'
      case TransactionTypeFamilyDto.SDDO:
        return 'prlv-out'
      default:
        return 'card'
    }
  }

  public static isAdvanceTransaction (transaction: TransactionDto): boolean {
    return transaction?.advanceAmount !== undefined
  }

  public static isAdvanceRefund (transaction: TransactionDto): boolean {
    // AMF002 correspond aux remboursements de l'avance
    return transaction?.type === 'AFM002' && (transaction?.price?.amount as number) < 0
  }
}
