import React from 'react'
import { useTranslation } from 'react-i18next'

import { TunnelContent } from '@neo-web/componentsOld'

import './Errors.scss'

interface ErrorsProps {
  code: number
}

export const Errors: React.FC<ErrorsProps> = (props: ErrorsProps) => {
  const { t } = useTranslation()
  const keyLokalise = `errors:codes:${props.code}`

  return (
    <TunnelContent
      title={(props.code.toString())}
      subtitle={t(keyLokalise)}
    />
  )
}
