import React from 'react'
import { useRouteMatch } from 'react-router'
import { Link, NavLinkProps } from 'react-router-dom'

import { Nav } from 'rsuite'

import { Icon } from '@neo-commons/components'

interface NavItemCustomProps {
  to: string;
  icon?: React.ReactElement<typeof Icon>;
  label: string;
  active?: boolean;
  disabled?: boolean;
}

export const NavItemCustom: React.FC<NavItemCustomProps> = (
  props: NavItemCustomProps
) => {
  const match = useRouteMatch({
    path: props.to,
    exact: props.active,
  })

  const { disabled } = props

  const handleClick = e => {
    if (disabled) {
      e.preventDefault()
    }
  }

  const NavLink: React.FC<NavLinkProps> = (props: NavLinkProps) => (
    <Nav.Item
      active={!!match}
      disabled={disabled}
      as={Link}
      {...props as any}
    />
  )

  return (
    <NavLink
      to={props.to}
      onClick={handleClick}
      className='NavItemCustom_container'
    >
      {props.icon}
      <span className='NavItemCustom_label'>{props.label}</span>
    </NavLink>
  )
}
