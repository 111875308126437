import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'

import i18next from 'i18next'
import dayjs from 'dayjs'
import { resources, DateFormat } from 'I18n'
import { Routes } from 'Routes/Routes'

import {
  BankAccountSelectors,
  TelecollectionActions,
  TelecollectionSelectors,
} from '@neo-commons/store'
import { Icon, IconTypes } from '@neo-commons/components'
import { BankAccountUtils, NumbersUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import {
  CardDetails,
  CardDetailsItem,
  JustifyContent,
  PageContent,
  PageDetails,
} from '@neo-web/componentsOld'

import { DetailTelecollectionInfo } from './DetailTelecollectionInfo'

export const DetailTelecollection: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const param: any = useParams()

  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const isPosAccount = BankAccountUtils.isPos(selectedBankAccount)

  const pending = param.id.startsWith('pending')
  const paramId = !pending ? param.id : param.id.slice(param.id.indexOf('-') + 1)
  const telecollect = !pending ? useSelector(TelecollectionSelectors.byId(paramId))
    : useSelector(TelecollectionSelectors.pendingListById(paramId))

  useEffect(() => {
    if (!telecollect) {
      try {
        if (!pending) {
          dispatch(TelecollectionActions.getTelecollectionById(paramId))
        } else {
          dispatch(TelecollectionActions.getPendingList())
        }
      } catch (error) {}
    }
  }, [])

  const renderIcon = () => {
    let iconName = 'clock'
    if (!pending) {
      iconName = telecollect?.cleared ? 'check-circle' : 'arrow-right-circle'
    }

    return <Icon type={IconTypes.NEOFONT} name={iconName} color={Colors.black} size={35} />
  }

  const renderTlcDate = () => {
    const dateFormat: DateFormat = resources[i18next.language].formats
    return dayjs(telecollect?.tlcDate).format(dateFormat.fullDate)
  }

  const renderLine = (sum: number, number: number, desc: string, forceShow?: boolean) => {
    return (number || forceShow) && `${NumbersUtils.displayPriceForHuman(sum ?? 0)} (${number} ${desc})`
  }

  const listItems: CardDetailsItem[] = [
    {
      value: `${NumbersUtils.displaySignedPriceForHuman(telecollect?.tlcNetAmount ?? 0)}`,
    }, {
      label: t('app:pages:synthesis:telecollections:detail:label:posName'),
      value: telecollect?.posSerialNumber,
    }, {
      label: t('app:pages:synthesis:telecollections:detail:label:tlcNumber'),
      value: !pending && telecollect?.tlcNumber,
    }, {
      label: t('app:pages:synthesis:telecollections:detail:label:tlcDate'),
      value: !pending && renderTlcDate(),
    }, {
      label: t('app:pages:synthesis:telecollections:detail:label:debits'),
      value: !pending && renderLine(
        telecollect?.debitSum,
        telecollect?.debitNumber,
        t('app:pages:synthesis:telecollections:detail:label:debits').toLowerCase(),
        true,
      ),
    }, {
      label: t('app:pages:synthesis:telecollections:detail:label:failures'),
      value: !pending && renderLine(
        telecollect?.tnaSum,
        telecollect?.tnaNumber,
        t('app:pages:synthesis:telecollections:detail:label:transactions').toLowerCase(),
      ),
    }, {
      label: t('app:pages:synthesis:telecollections:detail:label:credits'),
      value: !pending && renderLine(
        telecollect?.creditSum,
        telecollect?.creditNumber,
        t('app:pages:synthesis:telecollections:detail:label:credits').toLowerCase(),
      ),
    }, {
      label: t('app:pages:synthesis:telecollections:detail:label:cancels'),
      value: !pending && renderLine(
        telecollect?.cancelSum,
        telecollect?.cancelNumber,
        t('app:pages:synthesis:telecollections:detail:label:cancels').toLowerCase(),
      ),
    },
  ]

  return (
    <>
      {!isPosAccount ? (
        <Redirect
          to={{ pathname: Routes.Synthesis.index.path }}
        />
      ) : telecollect &&
        <PageDetails
          title={!pending ? t('app:pages:synthesis:telecollections:detail:status')
            : t('app:pages:synthesis:telecollections:detail:pending:status')}
          headerIcon={renderIcon()}
          component={(
            <PageContent
              justifyContent={JustifyContent.START}
            >
              <DetailTelecollectionInfo
                pending={pending}
                cleared={telecollect?.cleared}
              />
              <CardDetails
                title={!pending
                  ? t('app:pages:synthesis:telecollections:detail:title')
                  : t('app:pages:synthesis:telecollections:detail:pending:title')}
                list={listItems}
              />
            </PageContent>
          )}
        />}
    </>
  )
}
