import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { Routes } from 'Routes/Routes'
import i18next from 'i18next'
import dayjs, { Dayjs } from 'dayjs'

import { CardActions, CardSelectors, CreditCardData } from '@neo-commons/store'
import { Button, ButtonSkin, Typography } from '@neo-commons/components'
import { CreditCardUtils } from '@neo-commons/libraries'

import { UsageLimitCodeDto } from '@afone/neo-core-client/dist/models'

import { PageDetails } from '@neo-web/componentsOld'

import { resources, DateFormat } from '../../../../../../I18n'

import { LimitCard } from './LimitCard'
import { ModifyLimitsModal } from './ModifyLimitsModal'

import './Limits.scss'

export enum LimitType {
  PAYMENT = 'payment',
  WITHDRAWAL = 'withdrawal',
}

interface LimitParams {
  id: string;
  type: LimitType;
}

export const Limits: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { id: selectedCardUuid, type } = useParams<LimitParams>()
  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)

  const isPayment = type === LimitType.PAYMENT
  const [showModal, setShowModal] = useState(false)

  const [weeklyLimit, setWeeklyLimit] = useState(0)
  const [atmLimit, setAtmLimit] = useState(0)
  const [monthlyLimit, setMonthlyLimit] = useState(0)
  const [weeklyUsage, setWeeklyUsage] = useState(0)
  const [monthlyUsage, setMonthlyUsage] = useState(0)

  const dateFormat: DateFormat = resources[i18next.language].formats

  const getCurrent = (limitCode: UsageLimitCodeDto): number => {
    return CreditCardUtils.getCardLimit(selectedCard, limitCode)?.currentUsage?.amount
  }
  const getMax = (limitCode: UsageLimitCodeDto): number => {
    return CreditCardUtils.getCardLimit(selectedCard, limitCode)?.maxUsage?.amount
  }

  const getDateByLimitCode = (limitCode: UsageLimitCodeDto, firstDay?: boolean): Dayjs => {
    const date = dayjs()

    switch (limitCode) {
      case UsageLimitCodeDto.ALL_WEEKLY:
      case UsageLimitCodeDto.ATM_WEEKLY:
        return date.startOf('isoWeek') // get previous monday
      case UsageLimitCodeDto.ALL_MONTHLY:
        return firstDay ? date.startOf('month') : date.endOf('month') // get first/last day of current month
    }
  }

  useEffect(() => {
    (async () => {
      if (selectedCardUuid) {
        await dispatch(CardActions.setSelected(selectedCardUuid))
      }
      if (selectedCard) {
        await dispatch(CardActions.getLimits(selectedCardUuid))
      }
    })()
  }, [selectedCardUuid])

  useEffect(() => {
    setWeeklyUsage(getCurrent(isPayment ? UsageLimitCodeDto.ALL_WEEKLY : UsageLimitCodeDto.ATM_WEEKLY))
    setMonthlyUsage(getCurrent(UsageLimitCodeDto.ALL_MONTHLY))
    setAtmLimit(getMax(UsageLimitCodeDto.ATM_WEEKLY))
    setWeeklyLimit(getMax(UsageLimitCodeDto.ALL_WEEKLY))
    setMonthlyLimit(getMax(UsageLimitCodeDto.ALL_MONTHLY))
  }, [selectedCard?.limits])

  return (
    <PageDetails
      title={isPayment
        ? t('app:pages:debitCard:services:limits:modify:payment:title')
        : t('app:pages:debitCard:services:limits:modify:withdrawal:title')}
      onBack={() => history.push(Routes.Services.index.path)}
      component={
        <>
          <div className='Limits_container'>
            <div className='Limits_title'>
              <Typography typeface='content'>
                {t('app:pages:debitCard:services:limits:modify:weeklyLimits:title')}
              </Typography>
            </div>
            <LimitCard
              title={t('app:pages:debitCard:services:limits:modify:weeklyLimits:actualLimit')}
              price={getMax(isPayment ? UsageLimitCodeDto.ALL_WEEKLY : UsageLimitCodeDto.ATM_WEEKLY)}
            />
            <LimitCard
              title={t(isPayment
                ? 'app:pages:debitCard:services:limits:modify:weeklyLimits:paymentsSinceDate'
                : 'app:pages:debitCard:services:limits:modify:weeklyLimits:paymentsSinceDateATM',
              {
                date: getDateByLimitCode(isPayment ? UsageLimitCodeDto.ALL_WEEKLY
                  : UsageLimitCodeDto.ATM_WEEKLY).format(dateFormat.dayAndMonth),
              })}
              limitBar={{
                current: weeklyUsage,
                max: getMax(isPayment ? UsageLimitCodeDto.ALL_WEEKLY : UsageLimitCodeDto.ATM_WEEKLY),
              }}
            />

            {isPayment &&
              <>
                <div className='Limits_title'>
                  <Typography typeface='content'>
                    {t('app:pages:debitCard:services:limits:modify:monthlyLimits:title')}
                  </Typography>
                </div>
                <LimitCard
                  title={t('app:pages:debitCard:services:limits:modify:monthlyLimits:actualLimit')}
                  price={monthlyLimit}
                  bottomText={t(
                    'app:pages:debitCard:services:limits:modify:monthlyLimits:fromTo',
                    {
                      firstDay: getDateByLimitCode(UsageLimitCodeDto.ALL_MONTHLY, true).format(dateFormat.dayAndMonth),
                      lastDay: getDateByLimitCode(UsageLimitCodeDto.ALL_MONTHLY).format(dateFormat.dayAndMonth),
                    }
                  )}
                />
                <LimitCard
                  title={t('app:pages:debitCard:services:limits:modify:monthlyLimits:paymentsSinceDate',
                    { date: getDateByLimitCode(UsageLimitCodeDto.ALL_MONTHLY, true).format(dateFormat.dayAndMonth) })}
                  limitBar={{
                    current: monthlyUsage,
                    max: getMax(UsageLimitCodeDto.ALL_MONTHLY),
                  }}
                />
              </>}

            <div className='Limits_button'>
              <Button
                skin={ButtonSkin.PRIMARY}
                title={isPayment
                  ? t('app:pages:debitCard:services:limits:modifyPayment:title')
                  : t('app:pages:debitCard:services:limits:modifyWithdrawal:title')}
                onPress={() => setShowModal(true)}
              />
            </div>
          </div>

          <ModifyLimitsModal
            show={showModal}
            onClose={() => setShowModal(false)}
            isPayment={isPayment}
            weeklyLimit={weeklyLimit}
            monthlyLimit={monthlyLimit}
            atmLimit={atmLimit}
          />
        </>
      }
    />
  )
}
