import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { ActivateCardPolicy } from 'Policies/DebitCard/ActivateCardPolicy'

import { CardSelectors, CreditCardData } from '@neo-commons/store'

import { Routes } from '../../../../../../Routes/Routes'

import { ActivateCardChoice, ActivationSuccess } from '.'

/**
 * Main file of ActivateCard, it contains global route of tunnel for card activation
 *
 * Key lokalise app:pages:services:debitCard:activation:NomDuComposant:NomDeLaTrad
 * @returns JSX.Element
 */
export const ActivateCard: React.FC = () => {
  const stepsContent: { path: string; content: React.ReactNode }[] = [
    {
      // Step 1
      path: Routes.Services.activateCard.path,
      content: <ActivateCardChoice />,
    },
    {
      // Step 2
      path: Routes.Services.activationSuccess.path,
      content: <ActivationSuccess />,
    },
  ]

  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)

  return (
    <>
      <Redirect
        to={{
          pathname: ActivateCardPolicy.redirect(selectedCard),
        }}
      />
      {stepsContent.map((value, index) => {
        return (
          <Route
            key={index}
            exact
            path={value.path}
            component={() => <>{value.content}</>}
          />
        )
      })}
    </>
  )
}
