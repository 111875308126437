import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PosActions, PosSelectors, PosTypes } from '@neo-commons/store'
import { SentReturnStatuses } from '@neo-commons/libraries'

import { PosReturnDetailDto, PosReturnStatusDto } from '@afone/neo-core-client/dist/models'

import {
  JustifyContent,
  LazyLoadingContainer,
  PageContent,
  PageDetails,
} from '@neo-web/componentsOld'

import { PosDepositAlert } from './PosDepositAlert'
import { ReturnedListItem } from './ReturnedListItem'

export const ReturnedList: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const returns: PosReturnDetailDto[] = useSelector(PosSelectors.listReturns) ?? []

  const pendingReturns: PosReturnDetailDto[] = returns.filter(r => r.status === PosReturnStatusDto.IN_PROGRESS)
  const sentReturns: PosReturnDetailDto[] = returns.filter(r => SentReturnStatuses.includes(r.status))
  const refundable = !!returns.find(r => r.status === PosReturnStatusDto.REFUNDABLE)

  useEffect(() => {
    (async () => {
      if (!returns.length) {
        try {
          await dispatch(PosActions.listReturns())
        } catch (error) {}
      }
    })()
  }, [])

  const refundDeposit = () => {
    (async () => {
      try {
        await dispatch(PosActions.refundDeposit())
        await dispatch(PosActions.listReturns())
      } catch (error) {}
    })()
  }

  return (
    <PageDetails
      title={t('neo-commons:pages:services:pos:return:list:title')}
      component={(
        <LazyLoadingContainer events={[PosTypes.LIST_RETURNS_REQUEST, PosTypes.REFUND_DEPOSIT_REQUEST]}>
          <PageContent
            justifyContent={JustifyContent.START}
            header={refundable && <PosDepositAlert onPress={refundDeposit} />}
          >
            {!!pendingReturns.length &&
              <ReturnedListItem
                title={t('neo-commons:pages:services:pos:return:list:pending:title')}
                returns={pendingReturns}
              />}
            {!!sentReturns.length &&
              <ReturnedListItem
                title={t('neo-commons:pages:services:pos:return:list:sent:title')}
                returns={sentReturns}
              />}
          </PageContent>
        </LazyLoadingContainer>
      )}
    />
  )
}
