import { AnyAction, Reducer } from 'redux'
import { findKey, omit } from 'lodash'
import i18next from 'i18next'
import { NeobankApi } from '@neo-commons/services'
import { TransactionTlcPendingDto, TransactionTlcSpentDto } from '@afone/neo-core-client/dist/models'
import { createSelector } from 'reselect'

import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { getInitialResourceState, initialResourceState, State } from '../utils'

const {
  resourceActionTypes: TelecollectionActionTypes,
  resourceReducer: TelecollectionResourceReducer,
  resourceAction: TelecollectionAction,
  resourceSelector: TelecollectionResourceSelector,
} = ResourceStateFactory<TransactionTlcSpentDto, 'telecollection'>(state => state.telecollection, 'telecollection')

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

const LIST_SUMMARY_AMOUNTS_REQUEST = 'telecollection/LIST_SUMMARY_AMOUNTS_REQUEST'
const LIST_SUMMARY_AMOUNTS_SUCCESS = 'telecollection/LIST_SUMMARY_AMOUNTS_SUCCESS'
const LIST_SUMMARY_AMOUNTS_FAILURE = 'telecollection/LIST_SUMMARY_AMOUNTS_FAILURE'
const LIST_PENDING_TLC_REQUEST = 'telecollection/LIST_PENDING_TLC_REQUEST'
const LIST_PENDING_TLC_SUCCESS = 'telecollection/LIST_PENDING_TLC_SUCCESS'
const LIST_PENDING_TLC_FAILURE = 'telecollection/LIST_PENDING_TLC_FAILURE'

export const TelecollectionTypes = {
  ...TelecollectionActionTypes,
  LIST_SUMMARY_AMOUNTS_REQUEST,
  LIST_SUMMARY_AMOUNTS_SUCCESS,
  LIST_SUMMARY_AMOUNTS_FAILURE,
  LIST_PENDING_TLC_REQUEST,
  LIST_PENDING_TLC_SUCCESS,
  LIST_PENDING_TLC_FAILURE,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

function getTelecollectionList (startDate: string, endDate: string, page = 0) {
  const perPage = 20

  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: TelecollectionTypes.LIST_TELECOLLECTION_REQUEST })
    const bankAccountUuid = getState().bankAccount.list!.selectedId
    try {
      if (!bankAccountUuid) throw new Error()

      const getAccountTelecollectionResponse =
        await NeobankApi.getInstance().transactionApi.getSpentAmounts(bankAccountUuid, startDate, endDate, perPage, page)

      let paginationEnded, data
      if (getAccountTelecollectionResponse.status === 204) {
        data = []
        paginationEnded = true
      } else {
        data = getAccountTelecollectionResponse.data
        paginationEnded = false
      }

      if (bankAccountUuid !== getState().bankAccount.list!.selectedId) {
        return
      }

      dispatch({
        type: TelecollectionTypes.LIST_TELECOLLECTION_SUCCESS,
        data: data,
        page,
        perPage,
        paginationEnded,
        listIdentifier: bankAccountUuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      if (bankAccountUuid !== getState().bankAccount.list!.selectedId) {
        return
      }
      dispatch({
        type: TelecollectionTypes.LIST_TELECOLLECTION_FAILURE,
        errorMessage,
      })
    }
  }
}

function getTelecollectionById (id) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: TelecollectionTypes.BYID_TELECOLLECTION_REQUEST })

    try {
      const bankAccountUuid = getState().bankAccount.list!.selectedId
      if (!bankAccountUuid) throw new Error()

      const getTelecollectionInfoReponse =
        await NeobankApi.getInstance().transactionApi.getTlcInfoById(bankAccountUuid, id)

      dispatch({
        type: TelecollectionTypes.BYID_TELECOLLECTION_SUCCESS,
        data: getTelecollectionInfoReponse.data,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({
        type: TelecollectionTypes.BYID_TELECOLLECTION_FAILURE,
        errorMessage,
      })
    }
  }
}

function getSummaryAmount () {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: LIST_SUMMARY_AMOUNTS_REQUEST })

    const bankAccountUuid = getState().bankAccount.list!.selectedId
    try {
      if (!bankAccountUuid) throw new Error()

      const getSummaryAmountResponse =
        await NeobankApi.getInstance().transactionApi.getUnclearedAmountsSummary(bankAccountUuid)

      if (bankAccountUuid !== getState().bankAccount.list!.selectedId) {
        return
      }

      dispatch({
        type: LIST_SUMMARY_AMOUNTS_SUCCESS,
        summaryAmount: getSummaryAmountResponse.data?.summaryAmount,
        bankAccountUuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      if (bankAccountUuid !== getState().bankAccount.list!.selectedId) {
        return
      }
      dispatch({
        type: LIST_SUMMARY_AMOUNTS_FAILURE,
        errorMessage,
      })
    }
  }
}

function getPendingList () {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: LIST_PENDING_TLC_REQUEST })

    const bankAccountUuid = getState().bankAccount.list!.selectedId
    try {
      if (!bankAccountUuid) throw new Error()

      const getPendingAmountsResponse =
        await NeobankApi.getInstance().transactionApi.getPendingAmounts(bankAccountUuid)

      if (bankAccountUuid !== getState().bankAccount.list!.selectedId) {
        return
      }

      dispatch({
        type: LIST_PENDING_TLC_SUCCESS,
        pendingList: getPendingAmountsResponse.status === 204 ? [] : getPendingAmountsResponse.data,
        bankAccountUuid,
      })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      if (bankAccountUuid !== getState().bankAccount.list!.selectedId) {
        return
      }
      dispatch({
        type: LIST_PENDING_TLC_FAILURE,
        errorMessage,
      })
    }
  }
}

export const TelecollectionActions = {
  ...TelecollectionAction,
  getTelecollectionList,
  getTelecollectionById,
  getSummaryAmount,
  getPendingList,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

type BaseTelecollectionState = Omit<ResourceState<TransactionTlcSpentDto>, 'create' | 'update'>
type TelecollectionStateList = BaseTelecollectionState['list']
export type TelecollectionState = Omit<ResourceState<TransactionTlcSpentDto>, 'create' | 'update' | 'prepare'>
  & {
  list: TelecollectionStateList,
  summaryAmount: {[key: string]: number},
  pendingList: {[key: string]: TransactionTlcPendingDto[]},
}

const initialState: TelecollectionState = {
  ...omit(initialResourceState, 'create', 'update', 'prepare'),
  list: {
    ...getInitialResourceState().list,
  },
  summaryAmount: {},
  pendingList: {},
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const telecollectionSelector = state => ({
  state: state.telecollection,
  identifier: state.bankAccount.list?.selectedId,
})
export const TelecollectionSelectors = {
  ...TelecollectionResourceSelector,
  summaryAmount: createSelector(
    telecollectionSelector,
    resource => resource?.state?.summaryAmount?.[resource.identifier]
  ),
  pendingList: createSelector(
    telecollectionSelector,
    resource => resource?.state?.pendingList?.[resource.identifier] ?? []
  ),
  pendingListById: (id: string) => createSelector(
    telecollectionSelector,
    resource => {
      const idKey: string | undefined = findKey(resource?.state?.pendingList?.[resource.identifier], function (o) {
        return o.idTlc === id
      })
      return idKey ? (resource?.state?.pendingList?.[resource.identifier][idKey] ?? []) : null
    },
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const telecollection: Reducer = (state = initialState, action: AnyAction): TelecollectionState => {
  switch (action.type) {
    case LIST_SUMMARY_AMOUNTS_REQUEST:
    case LIST_PENDING_TLC_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LIST_SUMMARY_AMOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        summaryAmount: { ...state.summaryAmount, [action.bankAccountUuid]: action.summaryAmount },
      }

    case LIST_PENDING_TLC_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingList: { ...state.pendingList, [action.bankAccountUuid]: action.pendingList },
      }

    case LIST_SUMMARY_AMOUNTS_FAILURE:
    case LIST_PENDING_TLC_FAILURE:
      return {
        ...state,
        loading: false,
      }

    default:
      return {
        ...TelecollectionResourceReducer(state, action, {
          identifier: 'idTlc',
          isPaginate: true,
          initialState,
        }),
      }
  }
}
