import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import i18next from 'i18next'
import dayjs from 'dayjs'
import { resources, DateFormat } from 'I18n'

import {
  BankAccountSelectors,
  Client,
  ClientSelectors,
  ContractActions,
  ContractSelectors,
  DocumentActions,
  DocumentSelectors,
  InvoiceActions,
  StatementActions,
  StatementSelectors,
} from '@neo-commons/store'

import { AccountStatementDto } from '@afone/neo-core-client/dist/models'

import { DocumentViewer } from '@neo-web/componentsOld'

import { DocumentType } from '../InvoiceAndContractDetail/InvoiceAndContractDetail'

export const DocumentDetail: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params: { documentUuid: string, type: string, documentDate?: string } = useParams()
  const dateFormat: DateFormat = resources[i18next.language].formats

  const client: Client = useSelector(ClientSelectors.defaultOne)

  const file = useSelector(DocumentSelectors.byId(params?.documentUuid))
  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const contract = useSelector(ContractSelectors.byId(params?.documentUuid))
  const statement: AccountStatementDto = useSelector(StatementSelectors.byId(params?.documentUuid))

  const accountNumber = selectedBankAccount.rib?.account ? '_compteN°' + selectedBankAccount.rib?.account : ''

  useEffect(() => {
    try {
      if (!file) {
        if (params.type === DocumentType.CONTRACT) {
          dispatch(DocumentActions.getContractsByPersonAndDocumentUuid(client.holder.uuid, params?.documentUuid))
        } else {
          dispatch(DocumentActions.getDocumentByUuid(params?.documentUuid))
        }
      }
      if (params.type === DocumentType.CONTRACT && !contract) {
        dispatch(ContractActions.getContractsByPersonUuid(selectedBankAccount.uuid))
      }
      if (params.type === DocumentType.STATEMENT && !statement) {
        dispatch(StatementActions.getStatementByAccountUuid(selectedBankAccount.uuid))
      }
      if (params.type === DocumentType.INVOICE) {
        dispatch(InvoiceActions.getInvoiceDocuments())
      }
    } catch (e) {}
  }, [params, file])

  const getTitle = (): string => {
    let title = ''

    switch (params.type) {
      case DocumentType.CONTRACT:
        title = 'app:pages:myProfile:contractAndInvoice:contractDetail:title'
        break
      case DocumentType.STATEMENT:
        title = 'app:pages:myProfile:contractAndInvoice:documentDetail:statementTitle'
        break
      case DocumentType.INVOICE:
        title = 'app:pages:myProfile:contractAndInvoice:documentDetail:invoiceTitle'
        break
    }
    return t(title)
  }

  const getSubtitle = (): string => {
    switch (params.type) {
      case DocumentType.CONTRACT:
        return contract ? t('app:pages:myProfile:contractAndInvoice:contractDetail:subtitle',
          {
            contractName: contract?.name,
            subscriptionDate: dayjs(contract?.startDate).format(dateFormat.fullDate),
          }) : null
      case DocumentType.STATEMENT:
        return t(dayjs(statement?.month).format(dateFormat.monthAndYearVerbose).charAt(0).toUpperCase() +
          dayjs(statement?.month).format(dateFormat.monthAndYearVerbose).slice(1))
      case DocumentType.INVOICE:
        return t(dayjs(params?.documentDate).format(dateFormat.monthAndYearVerbose).charAt(0).toUpperCase() +
          dayjs(params?.documentDate).format(dateFormat.monthAndYearVerbose).slice(1))
    }
  }

  const getFileName = (): string => {
    switch (params.type) {
      case DocumentType.CONTRACT:
        return getTitle() + '_' + accountNumber
      case DocumentType.STATEMENT:
        return getTitle() + '_' + getSubtitle() + accountNumber
      case DocumentType.INVOICE:
        return getTitle() + '_' + getSubtitle() + accountNumber
    }
  }

  return (
    <DocumentViewer
      title={getTitle()}
      subtitle={getSubtitle()}
      base64={file?.base64}
      filename={getFileName()}
    />
  )
}
