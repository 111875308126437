import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { IconTypes, Icon, Button, ButtonSkin, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { BankAccountSelectors } from '@neo-commons/store'

import { AccountDto, AccountStatusDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

import './ImpossibleToCloseAccount.scss'

export const ImpossibleToCloseAccount: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const selectedMain: AccountDto = useSelector(BankAccountSelectors.selectedMain)
  const subAccounts: AccountDto[] = useSelector(BankAccountSelectors.getSubAccountsByUuid(selectedMain?.uuid))
    .filter(el => el.status !== AccountStatusDto.TERMINATION_IN_PROGRESS)

  return (
    <PageDetails
      onBack={null}
      component={
        <PageContent
          justifyContent={JustifyContent.CENTER}
          footer={
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:pages:myProfile:closingAccount:impossible:button')}
              onPress={() => history.push(Routes.Profile.invoiceAndContractDetail.path)}
            />
          }
          icon={<Icon color={Colors.secondary} name='alert-circle' size={40} type={IconTypes.FEATHER} />}
          title={t('app:pages:myProfile:closingAccount:impossible:title')}
          description={t('app:pages:myProfile:closingAccount:impossible:description')}
        >
          <div className='ImpossibleToCloseAccount_list'>
            {subAccounts.map((el, index) =>
              <Typography2 typeface='h6' key={index}>{el.name}</Typography2>
            )}
          </div>
        </PageContent>
      }
    />
  )
}
