import React from 'react'

import { Icon, Typography2 } from '@neo-commons/components'

import { Loader } from '@neo-web/componentsOld'

import loader from '../../../UI/Resources/Animations/Loader/lf20_a5zm6vuv.json'

import './TunnelContent.scss'

export enum TunnelJustifyContent {
  START = 'start',
  CENTER = 'center',
  AROUND = 'around',
  END = 'end',
}
interface TunnelContentProps {
  loading?: boolean,
  justifyContent?: TunnelJustifyContent,
  header?: React.ReactElement<Element>,
  icon?: React.ReactElement<typeof Icon>,
  title?: string | React.ReactElement<typeof Typography2>,
  subtitle?: string | React.ReactElement<typeof Typography2>,
  description?: string | React.ReactElement<typeof Typography2>,
  children?: React.ReactNode,
  // scrollableBody makes the body container scrollable
  // use it when you have a very long body like a country list,
  // and you want to keep your footer on the screen without scrolling
  scrollableBody?: boolean,
  footer?: React.ReactElement<Element>,
}

export const TunnelContent: React.FC<TunnelContentProps> = (props: TunnelContentProps) => {
  /** HOW TO DISPLAY CONTENT :
   * - center only body (icon, title, subtitle, ...) :
   *    use justifyContent.CENTER and you can use children or others props like icon, title, subtitle
   * - no center with footer at the bottom :
   *    use header only (no children, no props icon, title, subtitle) and justifyContent.CENTER
   * - no center with no footer at the bottom : use justifyContent.START
   **/
  const styleJustifyContent = (justifyContent: TunnelJustifyContent) => {
    switch (justifyContent) {
      case TunnelJustifyContent.START:
        return 'start'
      case TunnelJustifyContent.CENTER:
        return 'center'
      case TunnelJustifyContent.AROUND:
        return 'around'
      case TunnelJustifyContent.END:
        return 'end'
      default:
        return 'center'
    }
  }
  return (
    <div
      className={`TunnelContent ${styleJustifyContent(props.justifyContent as TunnelJustifyContent)}`}
      style={props.scrollableBody ? { justifyContent: 'unset' } : {}}
    >
      {/* -- Loader if active -- */}
      {props.loading &&
        <div className='TunnelContent_loading'>
          <div className='TunnelContent_loading_background' />
          <div className='TunnelContent_loading_loader'><Loader loop animation={loader} isShow /> </div>
        </div>}
      {/* --- Header --- */}
      <div className='TunnelContent_header'>
        {props.header}
      </div>
      {/* --- Container --- */}
      <div
        className={`TunnelContent_container ${styleJustifyContent(props.justifyContent as TunnelJustifyContent)}`}
        style={props.scrollableBody && { minHeight: '20vh', overflowY: 'auto' }}
      >
        {props.icon &&
          <div className='TunnelContent_container_icon'>
            <div>{props.icon}</div>
          </div>}
        {props.title &&
          <div className='TunnelContent_container_text'>
            {typeof props.title === 'string' ? <Typography2 typeface='h3'>{props.title}</Typography2> : props.title}
          </div>}
        {props.subtitle &&
          <div className='TunnelContent_container_text'>
            <Typography2 typeface='h4'>
              {props.subtitle}
            </Typography2>
          </div>}
        {props.description &&
          <div className='TunnelContent_container_text'>
            <Typography2 typeface='body1'>
              {props.description}
            </Typography2>
          </div>}
        {props.children &&
          <div className='TunnelContent_container_item'>
            {props.children}
          </div>}
      </div>
      {/* --- Footer --- */}
      <div
        className='TunnelContent_footer'
        style={props.scrollableBody
          ? { marginTop: 'auto' }
          : {}}
      >
        {props.footer}
      </div>
    </div>
  )
}
