import React from 'react'

import { StepsProps } from 'intro.js-react'
import 'intro.js/introjs.css'

import './IntroStep.scss'

export const InitIntroSteps: StepsProps = {
  initialStep: 0,
  steps: [],
  onExit: () => null,
  options: {
    showBullets: false,
    hidePrev: true,
    skipLabel: '✕',
    exitOnOverlayClick: false,
    tooltipClass: 'introStep',
    positionPrecedence: ['bottom-middle-aligned', 'top-middle-aligned', 'auto'],
  },
}

export interface IntroStepProps {
  title?: string,
  children?: React.ReactNode,
}

export const IntroStep: React.FC<IntroStepProps> = (props) => {
  return (
    <div className='IntroStep'>
      <p className='IntroStep_title'>{props.title}</p>
      <div className='IntroStep_content'>
        {props.children}
      </div>
    </div>
  )
}
