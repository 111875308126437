import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin } from '@neo-commons/components'
import { TransactionActions } from '@neo-commons/store'
import { NumbersUtils } from '@neo-commons/libraries'

import { TransactionDto } from '@afone/neo-core-client/dist/models'

import { CardDetails, CardDetailsItem } from '@neo-web/componentsOld'

interface TransactionRefundProps {
  transaction: TransactionDto,
}

export const TransactionRefund: React.FC<TransactionRefundProps> = (props: TransactionRefundProps) => {
  const { transaction } = props
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const refund = transaction.detail?.refund

  const listItems: CardDetailsItem[] = [{
    label: refund?.availableAmount > 0
      ? t('app:pages:synthesis:transaction:refund:info:partial')
      : t('app:pages:synthesis:transaction:refund:info:total'),
    value: `- ${NumbersUtils.displayPriceForHuman(refund?.creditedAmount)}`,
  }]

  return (
    <div style={{ marginTop: 32 }}>
      {refund?.creditedAmount > 0 && (
        <CardDetails
          title={t('app:pages:synthesis:transaction:detail:refund')}
          list={listItems}
          justifyBetween
        />
      )}
      <Button
        skin={ButtonSkin.OUTLINE_RED}
        containerStyle={{ marginTop: 16 }}
        title={t('app:pages:synthesis:transaction:refund:button')}
        disabled={!refund?.granted}
        onPress={() => {
          (async () => {
            try {
              await dispatch(TransactionActions.refundPrepare({ transaction }))
              history.push(Routes.Synthesis.TransactionRefund.index.path)
            } catch (error) {}
          })()
        }}
      />
    </div>
  )
}
