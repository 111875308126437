import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import {
  SignInTypes,
} from '@neo-commons/store'

import { loading$, displayAlertGlobal$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    SignInTypes.SIGN_IN_REQUEST,
    SignInTypes.SIGN_IN_SUCCESS,
    SignInTypes.SIGN_IN_FAILURE,
    SignInTypes.CONFIRM_CODE_REQUEST,
    SignInTypes.CONFIRM_CODE_FAILURE,
    SignInTypes.CONFIRM_CODE_SUCCESS,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    SignInTypes.GET_OTP_PHONE_FAILURE,
    SignInTypes.VERIFY_PHONE_FAILURE,
    SignInTypes.RECOVER_PASSWORD_CHECK_FAILURE,
    SignInTypes.RECOVER_PASSWORD_FAILURE,
    SignInTypes.SIGN_IN_FAILURE,
    SignInTypes.CONFIRM_PASSWORD_CHECK_FAILURE,
    SignInTypes.CONFIRM_CODE_MAX_FAILURE,
  ])
}

export default [
  reactiveLoader$,
  displayDropdownAlert$,
]
