import JSEncrypt from 'jsencrypt'
import EncryptorWorker from 'Workers/Encryptor'

import { CreditCardUtils, SensitiveCardData } from '@neo-commons/libraries'

const PUBLIC_KEY = 'public_key'
const PRIVATE_KEY = 'private_key'

export class CreditCardUtilsWeb extends CreditCardUtils {
  public static generateRsaKeyPairPromise: Promise<{ public: string, private: string }>

  static encryptor = new JSEncrypt({})

  /**
   * To decrypt data of encrypted debit card infos.
   * @param encryptedData - Encrypted data.
   */
  public static async decryptSensitiveData (encryptedData: string): Promise<SensitiveCardData> {
    const keyPair = await this.initRsaKeyPair()
    this.encryptor.setPrivateKey(keyPair.private)
    const decryptedCardData = await this.encryptor.decrypt(encryptedData)
    const sensitiveData = this.decryptedToObject(decryptedCardData)
    if (!sensitiveData) {
      throw Error('Sensitive data is empty.')
    }
    return sensitiveData
  }

  /**
   * To generate a RSA key pair.
   */
  public static initRsaKeyPair (): Promise<{ public: string, private: string } | undefined> {
    const encryptorWorker = new EncryptorWorker()
    return new Promise((resolve, reject) => {
      const storagePublicKey = localStorage.getItem(PUBLIC_KEY)
      const storagePrivateKey = localStorage.getItem(PRIVATE_KEY)

      if (storagePublicKey && storagePrivateKey) {
        resolve({ public: storagePublicKey, private: storagePrivateKey })
      }

      if (!this.generateRsaKeyPairPromise) {
        this.generateRsaKeyPairPromise = encryptorWorker.generateRsaKeyPair(this.RSA_KEY_SIZE)
      }

      try {
        this.generateRsaKeyPairPromise.then(keyPair => {
          localStorage.setItem(PUBLIC_KEY, keyPair.public)
          localStorage.setItem(PRIVATE_KEY, keyPair.private)
          resolve(keyPair)
        }).catch(error => reject(error))
      } catch (error) {
        reject(error)
      }
    })
  }
}
