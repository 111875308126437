import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import {
  Button,
  ButtonSkin, Icon, IconTypes,
  Typography,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { PageDetails } from '@neo-web/componentsOld'

import { Routes } from '../../../../../../../Routes/Routes'
import { bannerPhysic } from '../../../../../../Resources/Images'

import './ActivationSuccess.scss'

export const ActivationSuccess: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <>
      <PageDetails
        component={
          <div className='ActivationSuccess_container'>
            <div className='ActivationSuccess_header'>
              <img
                className='ActivationSuccess_header-banner'
                src={bannerPhysic}
                alt='banner'
              />
            </div>
            <Icon
              name='check-circle'
              type={IconTypes.FEATHER}
              size={40}
              color={Colors.secondary}
            />
            <br />
            <Typography typeface='bigTitle'>
              {t('app:pages:services:debitCard:activation:bravo')}
            </Typography>
            <Typography typeface='bigTitle'>
              {t('app:pages:services:debitCard:activation:success:title')}
            </Typography>
            <div className='ActivationSuccess_text'>
              <Typography typeface='paragraphe'>
                {t('app:pages:services:debitCard:activation:success:message')}
              </Typography>
            </div>
            <br />
            <Button
              skin={ButtonSkin.OUTLINE_PRIMARY}
              title={t('app:pages:services:debitCard:activation:returnToServices')}
              onPress={() => {
                history.push(Routes.Services.index.path)
              }}
            />
          </div>
        }
      />
    </>
  )
}
