import React from 'react'
import { useTranslation } from 'react-i18next'

import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'
import CustomIcon from 'UI/Components/CustomIcon'

import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import './NewEligibilityCard.scss'

interface NewEligibilityCardProps {
  onPress: () => void
}

export const NewEligibilityCard: React.FC<NewEligibilityCardProps> = (props: NewEligibilityCardProps) => {
  const { onPress } = props
  const { t } = useTranslation()
  return (
    <div
      className='NewOfferMinCard' key='newLoanCard' onClick={() => {
        AnalyticsHandler.track(AnalyticsEvents.signUp.subscriptionOptionSelected,
          {
            offer_name: 'avance_100',
          })
        onPress()
      }}
    >
      <div className='NewOfferMinCard_row' style={{ marginBottom: 6 }}>
        <div className='NewOfferMinCard_row'>
          <div style={{ marginRight: 8 }}>
            <CustomIcon name='coins-amount' size={25} color={Colors.secondaryDarker} />
          </div>
          <h4 className='NewOfferMinCard_text'>{t('neo-commons:loan:need100€')}</h4>
        </div>
        <button
          className='NewOfferMinCard_button'
        >
          <span className='NewOfferMinCard_price'>{t('neo-commons:loan:free')}</span>
          <Icon
            name='chevron-right'
            color='#000'
            type={IconTypes.FEATHER}
            size={18}
          />
        </button>
      </div>
      <p className='NewOfferMinCard_text'>{t('neo-commons:loan:need100€Description')}</p>
    </div>
  )
}
