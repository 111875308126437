import { RoutesInterface } from './Routes'

export class ExternalRoutes {
  public static index: RoutesInterface = {
    path: '/external',
    name: 'index',
    available: true,
  }

  public static start3ds: RoutesInterface = {
    path: ExternalRoutes.index.path + '/start3ds',
    name: 'start3ds',
    available: true,
  }

  public static end3ds: RoutesInterface = {
    path: ExternalRoutes.index.path + '/end3ds',
    name: 'end3ds',
    available: true,
  }

  public static KYCRetrieveDocument: RoutesInterface = {
    path: ExternalRoutes.index.path + '/kyc_retrieve_document',
    name: 'KYCRetrieveDocument',
    available: true,
  }

  public static confirmEmail: RoutesInterface = {
    // path defined by bo, don't touch it
    path: ExternalRoutes.index.path + '/auth/verify_email',
    name: 'app:pages:confirmEmail:title',
    available: true,
  }

  public static linkHandler: RoutesInterface = {
    path: '/_:id',
    name: 'link',
    available: true,
  };

  public static linkExpired: RoutesInterface = {
    path: ExternalRoutes.index.path + '/linkExpired',
    name: 'app:pages:subscription:business:linkExpired:title',
    available: true,
  };

  public static documentsUploadAffiliate: RoutesInterface = {
    // path defined by bo, don't touch it
    path: ExternalRoutes.index.path + '/documentsUploadAffiliate',
    name: 'app:pages:subscription:global:documentChoice:title',
    available: true,
  };

  public static signature: RoutesInterface = {
    path: ExternalRoutes.index.path + '/signature',
    name: 'app:pages:subscription:global:documentChoice:title',
    available: true,
  }
}
