import React from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { PosDispatchDto, PosDispatchStatusDto } from '@afone/neo-core-client/dist/models'

import { CardButton } from '@neo-web/componentsOld'

interface ReturnListItemProps {
  title: PosDispatchStatusDto,
  dispatches: PosDispatchDto[],
}

export const DispatchListItem: React.FC<ReturnListItemProps> = (props: ReturnListItemProps) => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <div style={{ marginBottom: 12 }}>
      <Typography2 typeface='overline'>{props.title}</Typography2>
      {props.dispatches.map(dispatch =>
        <CardButton
          key={dispatch.uuid}
          icon={<Icon name='box' color={Colors.secondary} type={IconTypes.FEATHER} size={32} />}
          title={t('neo-commons:pages:services:pos:return:dispatch:list:card:title')}
          subtitle={t('neo-commons:pages:services:pos:return:dispatch:list:card:subtitle')}
          button={
            <Button
              small
              skin={ButtonSkin.PRIMARY}
              title={t('neo-commons:pages:services:pos:return:dispatch:list:card:button')}
              onPress={() => history.push(
                generatePath(Routes.Services.dispatchDetail.path, { id: dispatch.uuid })
              )}
            />
          }
        />
      )}
    </div>
  )
}
