import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { Routes } from 'Routes/Routes'

import { ClosingAccountStep } from '@neo-commons/policies'

import { PageDetails } from '@neo-web/componentsOld'

import './ClosingAccountLayout.scss'

interface ClosingAccountLayoutProps {
  children: ReactNode
  step?: ClosingAccountStep
}

export const ClosingAccountLayout: React.FC<ClosingAccountLayoutProps> = ({ children, step }) => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <PageDetails
      component={
        <div className='ClosingAccountLayout_container'>
          {children}
        </div>
      }
      onBack={() => history.push(Routes.Profile.invoiceAndContractDetail.path)}
      title={
        step === ClosingAccountStep.ConfirmClosingAccount
          ? t('app:pages:myProfile:closingAccount:summary')
          : t('app:pages:myProfile:closingAccount:title')
      }
    />
  )
}
