import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { BankAccountActions, BankAccountSelectors, OfferActions, SubscriptionSelectors } from '@neo-commons/store'
import {
  ToggleCard,
  ToggleCardSkin,
  Typography,
  Button,
  ButtonSkin,
  Icon,
  IconTypes,
  MenuItemCard,
  Card,
  CardSkin,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { BankAccountUtils } from '@neo-commons/libraries'

import { AccountDto, SubscriptionDto, SubscriptionStatusDto } from '@afone/neo-core-client'

import { AggregationLogo, JustifyContent, Modal, PageContent } from '@neo-web/componentsOld'

import './AggregationService.scss'

import BankSample from '../../../Resources/Images/site/bankSample.png'

export const AggregationService: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const mainAccounts = useSelector(BankAccountSelectors.listMain)
  const subscriptions: SubscriptionDto[] = useSelector(SubscriptionSelectors.listAggregated)
    .filter((el: SubscriptionDto) => el.status !== SubscriptionStatusDto.CANCELED)
  const aggregatedAccounts = mainAccounts
    .filter(account => BankAccountUtils.isAggregated(account) && !account?.disabled)
  const renderBankAccountsLength = aggregatedAccounts.length > 0 && ` (${aggregatedAccounts.length})`
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  useEffect(() => {
    dispatch(OfferActions.listForClientUuid())
  }, [])

  return (
    <PageContent
      justifyContent={JustifyContent.START}
    >
      {(subscriptions.length || aggregatedAccounts.length)
        // client souscrit à l'agrégation OU client possède déjà des comptes agrégés
        ? (
          <div>
            <Card skin={CardSkin.BACKGROUND_BORDERED}>
              <Typography
                style={{ textAlign: 'center', marginBottom: '18px', marginTop: '6px', textTransform: 'uppercase' }}
                typeface='overline'
              >
                {[t('app:pages:services:aggregation:aggregatedAccount'), renderBankAccountsLength]}
              </Typography>
              <div className='AggregationService_card_split_children'>
                {aggregatedAccounts.map((bankAccount) => (
                  <div key={bankAccount.uuid} className='AggregationService_card_split_item' style={{ marginLeft: 3 }}>
                    <AggregationLogo
                      bank={{
                        icon: bankAccount?.icon,
                        color: bankAccount?.color,
                        slug: bankAccount?.slug,
                        name: bankAccount?.bank,
                      }}
                      size={48}
                    />
                  </div>
                ))}
                {Array.from({ length: 5 - aggregatedAccounts.length }, (_, i) => (
                  <div key={i} className='AggregationService_card_split_children_item'>
                    <Icon
                      name='bank'
                      color={Colors.darkGray}
                      type={IconTypes.MATERIAL_COMMUNITY}
                      size={24}
                    />
                  </div>
                ))}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 18 }}>
                <Typography typeface='body1'>
                  {t('app:pages:services:aggregation:aggregeableBanksAndAccounts')}
                </Typography>
                <Typography typeface='body1' style={{ fontWeight: 'bold' }}>{t('app:global:unlimited')}</Typography>
              </div>
            </Card>
            {aggregatedAccounts.length > 0 &&
              <MenuItemCard
                title={t('app:pages:services:aggregation:edit:title')}
                chevronPosition='center'
                onPress={() => {
                  if (BankAccountUtils.isMain(selectedAccount)) {
                    history.push(Routes.Services.aggregatedBanks.path)
                  } else {
                    history.push(Routes.Services.aggregatedAccounts.path)
                  }
                }}
              />}
            <MenuItemCard
              title={t('app:pages:services:aggregation:new:button')}
              chevronPosition='center'
              onPress={() => history.push(Routes.Services.aggregationSearch.path)}
            />
            {subscriptions.length > 0 && (
              <>
                <br />
                <ToggleCard
                  skin={ToggleCardSkin.DEFAULT}
                  value
                  title={t('app:pages:services:aggregation:card:title')}
                  subtitles={[t('app:pages:services:aggregation:card:subtitle')]}
                  onValueChange={() => setShowConfirmModal(true)}
                />
              </>)}
          </div>)
        : (
          <div className='AggregationService_subscribeDiv'>
            <Typography typeface='h3'>{t('app:pages:services:aggregation:text:title')}</Typography>
            <Button
              title={t('app:global:knowMore')}
              skin={ButtonSkin.PRIMARY_LINK}
              onPress={() => history.push(Routes.Services.aggregationKnowMore.path)}
            />
            <img src={BankSample} alt='Bank Sample Picture' />
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:global:add')}
              onPress={() => history.push(Routes.Services.aggregationSearch.path)}
            />
          </div>)}
      <Modal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        displayCross
      >
        <div className='AggregationService_modalDiv'>
          <Icon
            name='alert-circle'
            type={IconTypes.FEATHER}
            size={40}
            color={Colors.secondary}
          />
          <Typography style={{ textAlign: 'center' }} typeface='h4'>{t(
            'app:pages:services:aggregation:deactivate:modal:title'
          )}
          </Typography>
          <Button
            title={t('app:pages:services:aggregation:deactivate:modal:cancelButton')} containerStyle={{ width: '100%' }}
            onPress={() => setShowConfirmModal(false)}
          />
          <Button
            containerStyle={{ width: '100%' }}
            skin={ButtonSkin.OUTLINE_PRIMARY} title={t('app:pages:services:aggregation:deactivate:modal:confirmButton')}
            onPress={async () => {
              await dispatch(BankAccountActions.disconnectAggregation(subscriptions[0].uuid))
              await dispatch(BankAccountActions.setSelected(mainAccounts[0].uuid))
              history.push(Routes.Synthesis.index.path)
            }}
          />
        </div>
      </Modal>
    </PageContent>
  )
}
