import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { RoutesUtils } from '@neo-web/utils'
import { Page } from '@neo-web/componentsOld'

import { Routes } from '../../../Routes/Routes'

import { Profile } from './Profile'
import { PreferenceNotification } from './PreferenceAndSecurity/PreferenceNotification/PreferenceNotification'
import { PreferenceContact } from './PreferenceAndSecurity/PreferenceContact/PreferenceContact'
import { Informations } from './Informations/Informations'
import { HelpAndEmergency } from './HelpAndEmergency/HelpAndEmergency'
import { ClientService } from './HelpAndEmergency/ClientService'
import { SecretQuestion } from './PreferenceAndSecurity/Security/SecretQuestion/SecretQuestion'
import { DeviceAndBrowser } from './PreferenceAndSecurity/DeviceAndBrowser/DeviceAndBrowser'
import { About } from './About/About'
import { Language } from './PreferenceAndSecurity/Language/Language'
import { InvoiceAndContract } from './InvoiceAndContract/InvoiceAndContract'
import { ClosingAccount } from './ClosingAccount/ClosingAccount'
import { ResetCode } from './PreferenceAndSecurity/Security/ResetCode/ResetCode'
import { PreferenceAndSecurity } from './PreferenceAndSecurity/PreferenceAndSecurity'
import { Security } from './PreferenceAndSecurity/Security/Security'
import { DocumentDetail } from './InvoiceAndContract/InvoiceAndContractDetail/DocumentDetail'
import { InvoiceAndContractDetail } from './InvoiceAndContract/InvoiceAndContractDetail/InvoiceAndContractDetail'
import { CompanyProfile } from './Informations/CompanyProfile/CompanyProfile'
import { UserProfile } from './Informations/UserProfile/UserProfile'
import { AffiliateProfile } from './Informations/AffiliateProfile/AffiliateProfile'
import { ImpossibleToCloseAccount } from './ClosingAccount/ImpossibleToCloseAccount'

export const MyProfile: React.FC = () => {
  return (
    <Switch>
      <Route
        path={[Routes.Profile.index.path]}
        exact
        component={
          () =>
            <Page pages={[
              {
                path: Routes.Profile.index.path,
                component: <Profile />,
              },
            ]}
            />
        }
      />
      <Route exact path={Routes.Profile.notification.path} component={PreferenceNotification} />
      <Route exact path={Routes.Profile.contact.path} component={PreferenceContact} />
      <Route exact path={Routes.Profile.helpAndEmergency.path} component={HelpAndEmergency} />
      <Route exact path={Routes.Profile.clientService.path} component={ClientService} />
      <Route exact path={Routes.Profile.information.path} component={Informations} />
      <Route exact path={Routes.Profile.userProfile.path} component={UserProfile} />
      <Route exact path={Routes.Profile.companyProfile.path} component={CompanyProfile} />
      <Route exact path={Routes.Profile.affiliateProfile.path} component={AffiliateProfile} />
      <Route exact path={Routes.Profile.security.path} component={Security} />
      <Route
        path={RoutesUtils.getAllRoutes(Routes.Profile.SecretQuestion)}
        component={SecretQuestion}
      />
      <Route
        path={Routes.Profile.ResetPasscode.index.path} component={ResetCode}
      />

      <Route exact path={Routes.Profile.deviceAndBrowser.path} component={DeviceAndBrowser} />
      <Route exact path={Routes.Profile.about.path} component={About} />
      <Route exact path={Routes.Profile.language.path} component={Language} />
      <Route
        exact
        path={[Routes.Profile.invoiceAndContract.path, Routes.Profile.choicesAccount.path,
          Routes.Profile.invoiceAndContractDetail.path]} component={InvoiceAndContract}
      />
      <Route
        exact
        path={[Routes.Profile.statementsDetail.path, Routes.Profile.invoices.path]} component={InvoiceAndContractDetail}
      />
      <Route exact path={Routes.Profile.documentDetail.path} component={DocumentDetail} />
      <Route path={Routes.Profile.ClosingAccount.index.path} component={ClosingAccount} />
      <Route path={Routes.Profile.impossibleToCloseAccount.path} component={ImpossibleToCloseAccount} />
      <Route exact path={Routes.Profile.preference.path} component={PreferenceAndSecurity} />

    </Switch>
  )
}
