import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { ProvisioningTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$, refreshSold$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    ProvisioningTypes.CREDIT_CARD_PROVISIONING_REQUEST,
    ProvisioningTypes.CREDIT_CARD_PROVISIONING_FAILURE,
    ProvisioningTypes.CREDIT_CARD_PROVISIONING_SUCCESS,
  ])
}

const refreshBankAccountSold$ = (actions$: ActionsObservable<AnyAction>) => {
  return refreshSold$(actions$, [
    ProvisioningTypes.CREDIT_CARD_PROVISIONING_SUCCESS,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    ProvisioningTypes.CREDIT_CARD_PROVISIONING_FAILURE,
    ProvisioningTypes.VALIDATE_3DS_TRANSACTION_FAILURE,
    ProvisioningTypes.FIND_BIN_INFOS_FAILURE,
  ])
}

export default [
  reactiveLoader$,
  refreshBankAccountSold$,
  displayDropdownAlert$,
]
