import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'
import { config, ENV } from 'Config/EnvConfig'

import { Button, ButtonSkin, CountryList, PhoneInput, Typography2 } from '@neo-commons/components'
import { DeviceRecoveryActions, Dispatch, SignInActions } from '@neo-commons/store'
import { CountryUtils, SelectionMode } from '@neo-commons/libraries'

import { CountryDto, OsTypeDto } from '@afone/neo-core-client/dist/models'

import { BrowserUtils } from '@neo-web/utils'

import './Phone.scss'

interface PhoneProps {
  setDeviceUpdateNeeded: (arg: boolean) => void
}

export const Phone: React.FC<PhoneProps> = (props: PhoneProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()
  const history = useHistory()

  const countries: CountryDto[] = useSelector(
    (state: StateTypes) => {
      return CountryUtils.filterByAuthorized(
        state.config?.data?.currentConfig?.countries ?? [],
        SelectionMode.PHONE_PREFIX
      )
    }
  )
  const profile = useSelector((state: StateTypes) => state?.signup?.profile)
  const signInState = useSelector((state: StateTypes) => state?.signIn)

  const [phoneValue, setPhoneValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isOnDropdown, setIsOnDropdown] = useState(false)
  const [isPhoneCorrect, setIsPhoneCorrect] = useState(false)
  const [prefix, setPrefix] = useState<CountryCode>(null)
  const [selectedCountry, setSelectedCountry] = useState(undefined)

  const prepareSignIn = async () => {
    if (isPhoneCorrect) {
      const phoneNumber = parsePhoneNumber(phoneValue, prefix)

      try {
        const uniqueDeviceId = signInState?.uniqueDeviceId
        const browserInfo = BrowserUtils.getDeviceBrowserInformation()
        const userUuid = await dispatch(SignInActions.getUuidByPhone(phoneNumber.formatInternational().replace(/ /g, '')))
        const device = await dispatch(DeviceRecoveryActions.createOrUpdate({
          os: OsTypeDto.WEB,
          deviceName: browserInfo.name,
          userUuid,
          uniqueDeviceId,
          browserInfo: browserInfo,
        }))

        await dispatch(SignInActions.setCurrentDevice(
          { deviceUuid: device.uuid, uniqueDeviceId: device.uniqueDeviceId }
        ))
        dispatch(SignInActions.prepare(phoneNumber.formatInternational(), prefix))
        if (uniqueDeviceId === device.uniqueDeviceId) props.setDeviceUpdateNeeded(false)
        history.push(Routes.SignIn.code.path)
      } catch (e) {
        setPhoneValue('')
        console.log(e)
      }
    }
  }

  useEffect(() => {
    const pageClick = () => {
      if (!isOnDropdown) {
        setIsOpen(false)
      }
    }
    // watch click on this page and bind function to close dropdown
    window.addEventListener('mousedown', pageClick, false)
    return () => {
      // and unwatch event
      window.removeEventListener('mousedown', pageClick)
    }
  }, [isOnDropdown])

  useEffect(() => {
    setPrefix(selectedCountry?.isoCodeAlpha2 as CountryCode)
    setPhoneValue('')
  }, [selectedCountry])

  useEffect(() => {
    setSelectedCountry(countries.find((country) =>
      country.isoCodeAlpha2 === profile?.countryCode ?? config(ENV.DEFAULT_LANGUAGE_APP)
    ))
  }, [profile])

  return (
    <div className='Phone_container text-center' data-testid='Auth/Phone'>
      <div className='Phone_container-header'>
        <Typography2 typeface='h4'>
          {t('app:pages:signIn:phone:title')}
        </Typography2>
        <br />
        <Typography2 typeface='body1'>
          {t('app:pages:signIn:phone:subtitle')}
        </Typography2>
      </div>
      <div className='Phone_container-body'>
        <div className='container-custom-dropdown'>
          <PhoneInput
            isRequired
            onSubmitEditing={() => {
              prepareSignIn()
            }}
            phoneLabel={t('app:pages:register:stepper:phoneNumber')}
            onChangeText={(text) => setPhoneValue(text)}
            onPressPrefix={() => {
              setIsOpen(!isOpen)
            }}
            prefix={prefix}
            value={phoneValue}
            onValidationChecked={(status) => {
              setIsPhoneCorrect(status.isValid)
            }}
            phoneRegex={selectedCountry?.regexPhoneValidation}
          />
          {isOpen && (
            <div
              className='custom-dropdown'
              onMouseEnter={() => setIsOnDropdown(true)}
              onMouseLeave={() => setIsOnDropdown(false)}
            >
              {countries.length && (
                <CountryList
                  countries={countries}
                  selectionMode={SelectionMode.PHONE_PREFIX}
                  onCountrySelectedAction={(country) => {
                    setSelectedCountry(country)
                    setTimeout(() => {
                      setIsOpen(false)
                    }, 200)
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className='Phone_container-footer'>
        <Button
          title={t('app:pages:signIn:phone:connect')}
          skin={ButtonSkin.PRIMARY}
          disabled={!isPhoneCorrect}
          onPress={() => {
            prepareSignIn()
          }}
        />
        <br />
        <Button
          title={t('app:pages:signIn:phone:createProfile')}
          skin={ButtonSkin.LINK}
          onPress={() => {
            history.push(Routes.Register.index.path)
          }}
        />
      </div>
    </div>
  )
}
