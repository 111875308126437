import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { POSReturnStep, POSReturnSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin } from '@neo-commons/components'
import { PosActions, PosSelectors, PosTypes, PreparePOSReturn } from '@neo-commons/store'

import { PosFailureReasonDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, LazyLoadingContainer, PageContent, SelectionList } from '@neo-web/componentsOld'

type POSReturnProps = StepComponentProps<POSReturnSteps[POSReturnStep.IdentifyAnomaly]>

export const IdentifyAnomaly: React.FC<POSReturnProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const preparedPOSReturn: PreparePOSReturn = useSelector(PosSelectors.prepareReturn)
  const failureReasons: PosFailureReasonDto[] = useSelector(PosSelectors.listFailureReasons) ?? []

  const [selectedFailure, setSelectedFailure] = useState<string>(preparedPOSReturn?.failureReason?.code)

  useEffect(() => {
    dispatch(PosActions.listFailureReasons())
  }, [])

  return (
    <LazyLoadingContainer events={[PosTypes.LIST_POS_RETURN_REASONS_REQUEST]}>
      <PageContent
        justifyContent={JustifyContent.BETWEEN}
        title={t('neo-commons:pages:services:pos:return:identifyAnomaly:title')}
        titleInBody
        footer={
          <Button
            skin={ButtonSkin.PRIMARY}
            title={t('app:global:confirm')}
            disabled={!selectedFailure}
            onPress={() => nextStep({
              ...preparedPOSReturn,
              failureReason: { code: selectedFailure },
            })}
          />
        }
      >
        <SelectionList
          list={failureReasons.map(reason => ({
            id: reason.failureReasonCode,
            label: reason.label,
          }))}
          selectedId={preparedPOSReturn?.failureReason?.code}
          onSelect={(id) => setSelectedFailure(id)}
        />
      </PageContent>
    </LazyLoadingContainer>
  )
}
