import { RoutesInterface } from '../Routes'

const debitCard = 'app:pages:debitCard:services:cardInfos:card'
const aggregation = 'app:pages:services:aggregation:title'
export class ServicesRoutes {
  public static index: RoutesInterface = {
    path: '/services',
    name: debitCard,
    available: true,
  }

  public static account: RoutesInterface = {
    path: ServicesRoutes.index.path + '/account',
    name: 'app:pages:analytics:service:account',
    available: true,
  }

  public static debitCard: RoutesInterface = {
    path: ServicesRoutes.index.path + '/debitCard',
    name: debitCard,
    available: true,
  }

  public static pos: RoutesInterface = {
    path: ServicesRoutes.index.path + '/pos',
    name: 'app:pages:services:pos:title',
    available: true,
  }

  public static activatePos: RoutesInterface = {
    path: ServicesRoutes.pos.path + '/activate',
    name: 'app:pages:services:pos:activate:title',
    available: true,
  }

  public static detailPosList: RoutesInterface = {
    path: ServicesRoutes.pos.path + '/detail',
    name: 'app:pages:services:pos:detail:list:title',
    available: true,
  }

  public static detailPos: RoutesInterface = {
    path: ServicesRoutes.detailPosList.path + '/:id',
    name: 'app:pages:services:pos:detail:title',
    available: true,
  }

  public static managePos: RoutesInterface = {
    path: ServicesRoutes.pos.path + '/manage',
    name: 'app:pages:services:pos:managePos:title',
    available: true,
  }

  public static closePosAccount: RoutesInterface = {
    path: ServicesRoutes.pos.path + '/close/subaccount',
    name: 'app:pages:services:pos:managePos:title',
    available: true,
  }

  public static reassignPos: RoutesInterface = {
    path: ServicesRoutes.managePos.path + '/reassign',
    name: 'app:pages:services:pos:managePos:reassign:title',
    available: true,
  }

  public static returnedList: RoutesInterface = {
    path: ServicesRoutes.managePos.path + '/returned',
    name: 'neo-commons:pages:services:pos:return:list:title',
    available: true,
  }

  public static dispatchList: RoutesInterface = {
    path: ServicesRoutes.pos.path + '/dispatches',
    name: 'neo-commons:pages:services:pos:return:dispatch:list:pageTitle',
    available: true,
  }

  public static dispatchDetail: RoutesInterface = {
    path: ServicesRoutes.dispatchList.path + '/:id',
    name: 'neo-commons:pages:services:pos:return:dispatch:detail:pageTitle',
    available: true,
  }

  public static activateCard: RoutesInterface = {
    path: ServicesRoutes.index.path + '/debitCard/activate',
    name: debitCard,
    available: true,
  }

  public static activationSuccess: RoutesInterface = {
    path: ServicesRoutes.activateCard.path + '/success',
    name: debitCard,
    available: true,
  }

  public static debitCardDetail: RoutesInterface = {
    path: ServicesRoutes.debitCard.path + '/details/:id',
    name: 'app:pages:debitCard:services:cardInfos:myCardDetails',
    available: true,
  }

  public static debitCardPin: RoutesInterface = {
    path: ServicesRoutes.debitCard.path + '/pin/:id',
    name: 'app:pages:debitCard:services:cardInfos:viewModifyMyPinCode',
    available: true,
  }

  public static cardInfos: RoutesInterface = {
    path: ServicesRoutes.debitCard.path + '/cardInfos',
    name: 'app:pages:analytics:service:cardInfos',
    available: true,
  }

  public static order: RoutesInterface = {
    path: ServicesRoutes.debitCard.path + '/order',
    name: 'app:pages:analytics:service:orderCard',
    available: true,
  }

  // Step 0
  public static selectCardOwner: RoutesInterface = {
    path: ServicesRoutes.order.path + '/selectCardOwner',
    name: 'app:pages:services:debitCard:order:selectCardOwner:title',
    available: true,
  }

  // Step 1
  public static choicesDebitCard: RoutesInterface = {
    path: ServicesRoutes.order.path + '/choices',
    name: 'app:pages:debitCard:choicesDebitCard:title',
    available: true,
  }

  // Step 1 bis
  public static physical: RoutesInterface = {
    path: ServicesRoutes.order.path + '/physical',
    name: 'app:pages:analytics:service:orderCard',
    available: true,
  }

  // Step 2
  public static physicalPromo: RoutesInterface = {
    path: ServicesRoutes.order.path + '/physicalPromo',
    name: 'app:pages:services:debitCard:order:physicalPromo:title',
    available: true,
  }

  // Step 3
  public static code: RoutesInterface = {
    path: ServicesRoutes.order.path + '/code',
    name: 'app:pages:services:debitCard:order:code:title',
    available: true,
  }

  // Step 4
  public static deliveryMode: RoutesInterface = {
    path: ServicesRoutes.order.path + '/deliveryMode',
    name: 'app:pages:services:debitCard:order:deliveryMode:deliveryMethod',
    available: true,
  }

  // Step 5
  public static address: RoutesInterface = {
    path: ServicesRoutes.order.path + '/address',
    name: 'app:pages:services:debitCard:order:address:title',
    available: true,
  }

  // Step 6
  public static summary: RoutesInterface = {
    path: ServicesRoutes.order.path + '/summary',
    name: 'app:pages:services:debitCard:order:summary:title',
    available: true,
  }

  // Step 7
  public static validate: RoutesInterface = {
    path: ServicesRoutes.order.path + '/validate',
    name: 'app:global:bravo',
    available: true,
  }

  public static insurance: RoutesInterface = {
    path: ServicesRoutes.index.path + '/insurance',
    name: 'app:pages:services:debitCard:order:insurance:title',
    available: true,
  }

  public static cashback: RoutesInterface = {
    path: ServicesRoutes.index.path + '/cashback',
    name: 'app:pages:analytics:service:cashback',
    available: true,
  }

  public static opposition: RoutesInterface = {
    path: ServicesRoutes.debitCard.path + '/opposition',
    name: 'app:pages:services:debitCard:opposition:intro:title',
    available: true,
  }

  public static limits: RoutesInterface = {
    path: ServicesRoutes.debitCard.path + '/:type/:id',
    name: 'app:pages:debitCard:services:modifyLimits:title',
    available: true,
  }

  public static aggregation: RoutesInterface = {
    path: ServicesRoutes.index.path + '/aggregation',
    name: 'app:pages:services:aggregation:menu',
    available: true,
  }

  public static aggregatedFallback: RoutesInterface = {
    path: ServicesRoutes.index.path + '/aggregation/fallback',
    name: aggregation,
    available: true,
  }

  public static aggregatedBanks: RoutesInterface = {
    path: ServicesRoutes.index.path + '/aggregatedBanks',
    name: 'app:pages:services:myAggregatedBanks',
    available: true,
  }

  public static aggregatedAccounts: RoutesInterface = {
    path: ServicesRoutes.index.path + '/aggregatedAccounts',
    name: 'app:pages:services:myAggregatedAccounts',
    available: true,
  }

  public static removedAggregatedAccount: RoutesInterface = {
    path: ServicesRoutes.index.path + '/removedAggregatedAccount',
    name: 'app:pages:services:myAggregatedAccounts',
    available: true,
  }

  public static activateAggregateAccounts: RoutesInterface = {
    path: ServicesRoutes.index.path + '/activateAggregateAccounts',
    name: aggregation,
    available: true,
  }

  public static aggregationSearch: RoutesInterface = {
    path: ServicesRoutes.index.path + '/aggregationSearch',
    name: 'app:pages:services:aggregation:search:name',
    available: true,
  }

  public static aggregationKnowMore: RoutesInterface = {
    path: ServicesRoutes.index.path + '/aggregationKnowMore',
    name: 'app:pages:services:aggregation:information:title',
    available: true,
  }

  public static advance: RoutesInterface = {
    path: ServicesRoutes.index.path + '/advance',
    name: 'app:pages:services:advance:menu',
    available: true,
  }

  public static advanceHistory: RoutesInterface = {
    path: ServicesRoutes.index.path + '/advanceHistory',
    name: 'app:pages:services:advance:history:menu',
    available: true,
  }
}
