import { useMediaQuery } from 'react-responsive'

import { Responsive } from '../../Config/Responsive'

/**
 * This function return if current device is a tablet or a mobile
 * @returns boolean isTabletOrMobile
 */
export const useResponsive: () => boolean = () => {
  return useMediaQuery({
    query: `(max-width: ${Responsive.WITH_TABLET}px)`,
  })
}
