import { useCallback, useEffect, useState } from 'react'

import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { KeypadUtils } from '@neo-commons/libraries'
import { ErrorCodeDto, KeypadFieldDto, KeypadNumberDto } from '@afone/neo-core-client'

export type PasswordKeypadProps = {
  phone: string,
  phoneCountryCode?: string,
  field: KeypadFieldDto,
  passwordSize: number,
}
export type PasswordKeypadData = {
  rawKeypad: KeypadNumberDto[],
  field: KeypadFieldDto,
  phone: string,
  password: KeypadNumberDto[],
  addKey: (key: KeypadNumberDto) => void,
  deleteKey: () => void,
  reset: (error?: any) => void,
  isFilled: boolean,
  canDeleteKey: boolean,
}
/**
 * A hook that provide data and functions to handle a secured keypad
 * @param passwordKeypadProps - an object needed to configure the keypad
 * @returns PasswordKeypadData
 */
export const usePasswordKeypad = ({ phone, phoneCountryCode, field, passwordSize }: PasswordKeypadProps): PasswordKeypadData => {
  const [password, setPassword] = useState<KeypadNumberDto[]>([])
  const [rawKeypad, setRawKeypad] = useState<KeypadNumberDto[]>([])

  if (phone && phoneCountryCode) {
    const parsed = parsePhoneNumber(phone, phoneCountryCode as CountryCode)
    phone = parsed?.number as string
  }

  const getKeypad = async () => {
    try {
      setRawKeypad(await KeypadUtils.getKeypad(phone, field))
    } catch {}
  }

  useEffect(() => {
    if (phone && field) {
      getKeypad()
    }
  }, [])

  const addKey = useCallback(key => {
    if (password.length < passwordSize) {
      setPassword([...password, key])
    }
  }, [password])

  const deleteKey = useCallback(() => setPassword(password.slice(0, -1)), [password])

  /**
   * This is called in case of error. It may be in case of invalid password or keypad expiration
   */
  const reset = (error?: any) => {
    setPassword([])
    if (error && error?.code === ErrorCodeDto.C6001) {
      getKeypad()
    }
  }

  /**
   * Helpers
   */
  const isFilled = password.length === passwordSize
  const canDeleteKey = password.length !== 0

  return {
    rawKeypad,
    phone,
    field,
    password,
    addKey,
    deleteKey,
    reset,
    isFilled,
    canDeleteKey,
  }
}
