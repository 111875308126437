import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import dayjs from 'dayjs'
import CustomIcon from 'UI/Components/CustomIcon'
import { Routes } from 'Routes/Routes'

import {
  AdvanceActions,
  AdvanceSelectors,
  ClientSelectors,
  Dispatch,
  OfferSelectors,
  OrdersActions,
  OrdersSelectors,
  SubscriptionSelectors,
} from '@neo-commons/store'
import {
  Typography,
  MenuItemCard,
  Card,
  CardSkin,
  Button,
  ButtonSkin,
} from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { NumbersUtils, OfferUtils } from '@neo-commons/libraries'

import {
  AdvanceDto,
  AdvanceDtoStatusEnum,
  ClientDto,
  ClientTypeDto,
  OfferTypeDto,
  OrderStatusDto,
  OrderTypeDto,
} from '@afone/neo-core-client/dist/models'

import { InformationModal, JustifyContent, PageContent } from '@neo-web/componentsOld'

import './AdvanceService.scss'

import debitCard from '../../../Resources/Images/site/debitCard.png'

export const AdvanceService: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()

  const offers = useSelector(OfferSelectors
    .listByClientType(ClientTypeDto.INDIVIDUAL, OfferTypeDto.SOCLE))
    .filter(offer => offer.authorized)
  const advanceOffer = offers.find(offer => OfferUtils.isOfferAdvance(offer))

  const mainSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const socleOffer = useSelector(OfferSelectors.getByUuid(mainSubscription?.offerUuid ?? mainSubscription?.offer?.uuid))

  const advanceOrder = useSelector(OrdersSelectors.byType(OrderTypeDto.ADVANCE))
  // eslint-disable-next-line dot-notation
  const advanceMax = advanceOffer?.data['advance']
  const advanceData: AdvanceDto[] = useSelector(AdvanceSelectors.list)
  const activeAdvance =
    advanceData.find((balance) => balance.status === AdvanceDtoStatusEnum.ACTIVE)
  const activationDate = activeAdvance
    ? dayjs(activeAdvance.createdDate).format('DD/MM/YYYY [à] HH:mm')
    : advanceOrder
      ? dayjs(advanceOrder.createdDate).format('DD/MM/YYYY [à] HH:mm')
      : null
  const refundDate = activeAdvance && dayjs(activeAdvance.createdDate).add(30, 'days').format('DD/MM/YYYY')
  const remainingDaysBeforeReimbursement = dayjs(refundDate, 'DD/MM/YYYY').diff(dayjs(), 'days')
  const remainingBalance = (advanceMax || 100) - activeAdvance?.currentBalance

  const [showAdvanceModal, setShowAdvanceModal] = useState(false)

  useEffect(() => {
    if (!advanceData || advanceData.length === 0) {
      dispatch(OrdersActions.listOrders())
      dispatch(AdvanceActions.getAdvanceData())
    }
  }, [])
  const orderAdvance = () => {
    if (client && client.accounts?.length) {
      dispatch(AdvanceActions.createAdvance())
        .then(() => {
          setShowAdvanceModal(true)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      dispatch(OrdersActions.createAdvanceOrder())
        .then(() => {
          dispatch(OrdersActions.listOrders())
          setShowAdvanceModal(true)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
  if (socleOffer && !advanceOrder && (!advanceData || advanceData.length === 0)) {
    return (
      <PageContent
        justifyContent={JustifyContent.START}
      >
        <div>
          <InformationModal // advance created modal
            show={showAdvanceModal}
            confetti
            showInfoIcon={false}
            onClose={() => setShowAdvanceModal(false)}
            title={t('neo-commons:prospect:inlineAdvanced', { advanceMax: NumbersUtils.displayPriceForHuman(advanceMax || 100, undefined, false) })}
            descriptionAlign='center'
            description={t('neo-commons:prospect:restrictedUseOfLoan')}
            extraIcon={<img src={debitCard} alt='debitCard' style={{ width: 200, margin: 5 }} />}
            description2={t('neo-commons:prospect:reimbourseAdvance', { advanceMax: NumbersUtils.displayPriceForHuman(advanceMax || 100, undefined, false) })}
            button={
              <Button
                title='Ok'
                onPress={() => setShowAdvanceModal(false)}
              />
            }
          />
          <MenuItemCard
            title={t('neo-commons:prospect:needAdvance', { advanceMax: NumbersUtils.displayPriceForHuman(advanceMax || 100, undefined, false) })}
            subtitle={t('neo-commons:prospect:creditAvailable')}
            onPress={() => orderAdvance()}
            containerStyle={{ width: '100%' }}
            titleStyle={{ marginBottom: -2 }}
            chevronPosition='center'
            extraIcon={
              <Button
                flat
                skin={ButtonSkin.OUTLINE_PRIMARY}
                accessibilityLabel='ValidateButton'
                title={t('neo-commons:prospect:advance', { advanceMax: NumbersUtils.displayPriceForHuman(advanceMax || 100, undefined, false) })}
                leftIcon={<CustomIcon name='coins-amount' size={25} color={Colors.primary} />}
                onPress={() => orderAdvance()}
              />
            }
            icon={<CustomIcon name='logo' size={44} color={Colors.trueBlack} />}
          />
        </div>
      </PageContent>
    )
  }

  return (
    <PageContent
      justifyContent={JustifyContent.START}
    >
      <div>
        <InformationModal // advance created modal
          show={showAdvanceModal}
          confetti
          showInfoIcon={false}
          onClose={() => setShowAdvanceModal(false)}
          title={t('neo-commons:prospect:inlineAdvanced', { advanceMax: NumbersUtils.displayPriceForHuman(advanceMax || 100, undefined, false) })}
          descriptionAlign='center'
          description={t('neo-commons:prospect:restrictedUseOfLoan')}
          extraIcon={<img src={debitCard} alt='debitCard' style={{ width: 200, margin: 5 }} />}
          description2={t('neo-commons:prospect:reimbourseAdvance', { advanceMax: NumbersUtils.displayPriceForHuman(advanceMax || 100, undefined, false) })}
          button={
            <Button
              title='Ok'
              onPress={() => setShowAdvanceModal(false)}
            />
          }
        />
        {!activeAdvance && advanceOrder?.status !== OrderStatusDto.PENDING && (
          <MenuItemCard
            title={t('neo-commons:prospect:needAdvance', { advanceMax: NumbersUtils.displayPriceForHuman(advanceMax || 100, undefined, false) })}
            subtitle={t('neo-commons:prospect:creditAvailable')}
            onPress={() => orderAdvance()}
            containerStyle={{ width: '100%' }}
            titleStyle={{ marginBottom: -2 }}
            chevronPosition='center'
            extraIcon={
              <Button
                flat
                skin={ButtonSkin.OUTLINE_PRIMARY}
                accessibilityLabel='ValidateButton'
                title={t('neo-commons:prospect:advance', { advanceMax: NumbersUtils.displayPriceForHuman(advanceMax || 100, undefined, false) })}
                leftIcon={<CustomIcon name='coins-amount' size={25} color={Colors.primary} />}
                onPress={() => orderAdvance()}
              />
            }
            icon={<CustomIcon name='logo' size={44} color={Colors.trueBlack} />}
          />
        )}
        {(activeAdvance || advanceOrder?.status === OrderStatusDto.PENDING) && (
          <Card skin={CardSkin.DEFAULT} withShadow>
            <div className='AdvanceService_container'>
              <div className='AdvanceService_flexColumn'>
                <Typography typeface='h4'>{t('neo-commons:advance:requestDate')}</Typography>
                {activationDate
                  ? <Typography typeface='body2'>{activationDate}</Typography>
                  : <Typography typeface='body2'>?</Typography>}
              </div>
              <div>
                <Typography typeface='h4' style={{ color: Colors.primary }}>
                  {NumbersUtils.displayPriceForHuman(advanceMax || 100)}
                </Typography>
              </div>
            </div>
            <div className='AdvanceService_containerText'>
              <div className='AdvanceService_flexColumn'>
                <Typography typeface='h4'>{refundDate ? t('neo-commons:advance:refundDate') : t('neo-commons:advance:refund')}</Typography>
                {
                  refundDate &&
                    <Typography typeface='body2'>{refundDate} dans {remainingDaysBeforeReimbursement} jours</Typography>
                }
              </div>
              <div>
                <Typography typeface='h4' style={{ color: Colors.gray3 }}>
                  {NumbersUtils.displayPriceForHuman(remainingBalance || 0)}
                </Typography>
              </div>
            </div>
            <Typography typeface='body2'>{t('neo-commons:advance:text')}</Typography>
          </Card>
        )}
        {advanceData && advanceData.filter(advance => advance.status !== AdvanceDtoStatusEnum.ACTIVE).length > 0 &&
          <MenuItemCard
            title={t('neo-commons:advance:history:title')}
            onPress={() => {
              history.push(Routes.Services.advanceHistory.path)
            }}
            chevronContainerStyle={{ display: 'none' }}
            containerStyle={{ width: '100%' }}
          />}
      </div>
    </PageContent>
  )
}
