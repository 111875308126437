import React from 'react'
import { Trans } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'

import { Button, ButtonSkin, Typography2 } from '@neo-commons/components'

import { Modal, InformationModalProps } from '@neo-web/componentsOld'
import './DeleteDocumentPageModal.scss'

interface DeleteDocumentPageModalProps extends InformationModalProps {
  onConfirm: () => void
  cancelText: string
  confirmText: string
}

export const DeleteDocumentPageModal: React.FC<DeleteDocumentPageModalProps> = (
  props: DeleteDocumentPageModalProps
) => {
  return (
    <Modal autoFocus open={props.show} onClose={props.onClose} displayCross>
      <FlexboxGrid justify='center' className='text-center DeleteDocumentPageModal'>
        {props.title && (
          <FlexboxGrid.Item colspan={24} className='pt-s'>
            <Typography2 typeface='h4'>{props.title}</Typography2>
          </FlexboxGrid.Item>
        )}
        <FlexboxGrid.Item colspan={24} className='pb-s'>
          <hr />
        </FlexboxGrid.Item>
        {props.description && (
          <FlexboxGrid.Item
            colspan={22}
          >
            <Typography2 typeface='body1'>
              <Trans>{props.description}</Trans>
            </Typography2>
          </FlexboxGrid.Item>
        )}
        <FlexboxGrid.Item style={{ justifyContent: 'space-between' }} className='DeleteDocumentPageModal_buttons' colspan={24}>
          <Button
            title={props.cancelText}
            skin={ButtonSkin.OUTLINE_PRIMARY}
            onPress={props.onClose}
          />
          <Button
            title={props.confirmText}
            skin={ButtonSkin.PRIMARY}
            onPress={props.onConfirm}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Modal>
  )
}
