import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, RadioInput, Typography2 } from '@neo-commons/components'
import { CardActions, CardSelectors, ClientSelectors, PrepareCardOrder } from '@neo-commons/store'

import { ClientDto, PersonAffiliationDto } from '@afone/neo-core-client'

import { JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'
import { CreditCardUtilsWeb } from '@neo-web/utils'

import './SelectCardOwner.scss'

export const SelectCardOwner: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const preparedCardOrder: PrepareCardOrder = useSelector(CardSelectors.preparedCardOrder)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)

  const legalRepresentatives = client?.holder?.affiliates
    ? CreditCardUtilsWeb.getAffiliatesThatCanOrderCard(client?.holder?.affiliates)
    : []
  const [affiliateSelected, setAffiliateSelected] = useState<PersonAffiliationDto>(legalRepresentatives[0])

  return (
    <PageDetails
      title={t('app:pages:analytics:service:orderCard')}
      component={
        <PageContent
          justifyContent={JustifyContent.BETWEEN}
          title={t('app:pages:services:debitCard:order:selectCardOwner:title')}
          footer={(
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:global:continue')}
              disabled={!affiliateSelected}
              onPress={async () => {
                await dispatch(CardActions.prepare({
                  ...preparedCardOrder,
                  personUuid: affiliateSelected.person.uuid,
                }))
                history.push(Routes.Services.choicesDebitCard.path)
              }}
            />
          )}
        >
          {legalRepresentatives.length === 0
            ? (
              <Typography2 typeface='body1'>
                {t('app:pages:services:debitCard:order:selectCardOwner:noAffiliatesFound')}
              </Typography2>
            )
            : (legalRepresentatives.map((affiliate, index) =>
              <RadioInput
                key={index}
                text={affiliate?.person?.fullName}
                value={affiliate?.uuid === affiliateSelected?.uuid}
                onPress={() => setAffiliateSelected(affiliate)}
              />))}
        </PageContent>
      }
      onClose={() => history.push(Routes.Services.index.path)}
      onBack={() => history.push(Routes.Services.index.path)}
    />
  )
}
