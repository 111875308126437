import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@neo-commons/components'

import './AddressFormTitle.scss'

export const AddressFormTitle: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Typography typeface='bigTitle'>
        {t('app:pages:services:pos:order:enterAddress')}
      </Typography>
      <div className='AddressFormTitle_mandatory'>
        <Typography typeface='smallTextLight'>
          {t('app:global:mandatoryFields')}
        </Typography>
      </div>
    </>
  )
}
