import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { BankAccountSelectors, TransactionActions, TransactionSelectors } from '@neo-commons/store'
import { EnumUtils, TransactionUtils } from '@neo-commons/libraries'

import { AccountDto } from '@afone/neo-core-client/dist/models'

import { PageDetails, Transaction } from '@neo-web/componentsOld'

export const DetailTransaction: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const param: any = useParams()
  const history = useHistory()

  const selectedBankAccount: AccountDto =
    useSelector(BankAccountSelectors.selected, (prev, next) => prev?.uuid === next?.uuid)
  const transaction = useSelector(TransactionSelectors.byId(param.id))
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>(selectedBankAccount?.uuid)

  const label = useMemo(() => {
    if (TransactionUtils.isTransactionDto(transaction) && transaction.primaryLabel) {
      return transaction.primaryLabel
    } else if (TransactionUtils.isTransactionDto(transaction) && TransactionUtils.isAdvanceRefund(transaction)) {
      return t('neo-commons:advance:reimbursement')
    } else if (TransactionUtils.isTransactionDto(transaction) && !!transaction.family) {
      return t(EnumUtils.getTransactionTypeFamilyDtoKey(transaction.family))
    } else {
      return t('account:defaultTransactionLabel')
    }
  }, [transaction])

  useEffect(() => {
    (async () => {
      try {
        if (!selectedBankAccountId || selectedBankAccountId === selectedBankAccount?.uuid) {
          await dispatch(TransactionActions.getTransactionById(param.id))
          setSelectedBankAccountId(selectedBankAccount?.uuid)
        } else {
          history.push(Routes.Synthesis.transactions.path)
        }
      } catch (e) {}
    })()
  }, [selectedBankAccount])

  return (
    <PageDetails
      title={label}
      headerIcon={transaction && (TransactionUtils.isAdvanceRefund(transaction)
        ? 'fee'
        : TransactionUtils.getIconFromTransactionFamily(transaction))}
      component={transaction && <Transaction transaction={transaction} />}
      onBack={() => history.push(Routes.Synthesis.index.path)}
    />
  )
}
