import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'

import { Button, ButtonSkin, OTPInput, Typography } from '@neo-commons/components'

import { AppConfig } from '../../../Config/AppConfig'
import './CodeConfirm.scss'

interface OtpConfirmProps {
  title?: string,
  onValidate: (code: string) => void,
  showReSendCode?: boolean,
  onResendCode?: () => void,
  startDate?: string,
  endDate?: string
}

export const CheckOtp: React.FC<OtpConfirmProps> = (props: OtpConfirmProps) => {
  const { t } = useTranslation()
  const [code, setCode] = useState('')
  const { title, onValidate, showReSendCode, onResendCode } = props
  const [isValid, setIsValid] = useState(false)
  const ref = useRef()

  useEffect(() => {
    setIsValid(
      code.length === AppConfig.OTP_INPUT_SIZE
    )
  }, [code])

  return (
    <FlexboxGrid justify='space-between' align='middle' className='CodeConfirm text-center'>
      <FlexboxGrid.Item colspan={24} className='CodeConfirm_text'>
        <Typography typeface='bigTitle'>
          {title}
        </Typography>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item className='CodeConfirm_otp' colspan={24}>
        <OTPInput
          onChangeText={c => setCode(c)}
          autoFocus
          ref={ref}
          startDate={props.startDate}
          endDate={props.endDate}
          title={props.title}
        />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item colspan={24}>
        <Button
          title={t('app:global:validate')} skin={ButtonSkin.PRIMARY}
          disabled={!isValid}
          onPress={() => {
            onValidate(code)
          }}
        />
      </FlexboxGrid.Item>

      {showReSendCode &&
        <FlexboxGrid.Item colspan={24} className='CodeConfirm_resendCodeBtn'>
          <Button
            title={t('app:pages:recoverPassword:step2:sms:codeNotReceived')}
            skin={ButtonSkin.LINK}
            onPress={() => {
              onResendCode()
            }}
          />
        </FlexboxGrid.Item>}

    </FlexboxGrid>
  )
}
