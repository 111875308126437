import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ButtonSkin, Input,
} from '@neo-commons/components'

import { Modal } from '@neo-web/componentsOld'

import './AnnotationModal.scss'

interface AnnotationModalProps {
  show: boolean,
  onClose: () => void,
  annotation: string,
  sendAnnotation: (string) => void
}

export const AnnotationModal: React.FC<AnnotationModalProps> = (
  props: AnnotationModalProps
) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const [annotation, setAnnotation] = useState(props.annotation ?? '')

  useEffect(() => {
    setOpenModal(props.show)
  }, [props])

  const titleButtonAnnotation = props.annotation
    ? t('app:pages:synthesis:transaction:detail:note', { value: props.annotation })
    : t('app:pages:synthesis:transaction:detail:annotation')

  return (
    <Modal
      title={titleButtonAnnotation}
      onClose={props.onClose} displayCross autoFocus open={openModal}
    >
      <div className='AnnotationModal_container'>
        <Input
          label={t('app:pages:synthesis:transaction:detail:modal:input')}
          value={annotation}
          onChangeText={(text) => setAnnotation(text)}
          withTextCounter
          maxLength={30}
        />
      </div>

      <div className='AnnotationModal_action'>
        <Button
          title={t('app:pages:synthesis:transaction:detail:modal:button')}
          skin={ButtonSkin.PRIMARY}
          onPress={() => props.sendAnnotation(annotation)}
        />
      </div>
    </Modal>
  )
}
