import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { ClientSelectors, Client, ClientActions } from '@neo-commons/store'
import { Icon, IconTypes, MenuItemCard, Typography2 } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { ClientUtils } from '@neo-commons/libraries'

import { UserDto } from '@afone/neo-core-client/dist/models'

import { PageDetails } from '@neo-web/componentsOld'

import './Informations.scss'

export const Informations: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const clients: Client[] = useSelector(ClientSelectors.list)
  const companies = useMemo(() => clients.filter(
    (client) => ClientUtils.isClientTypeCorporate(client?.type)), [clients])

  const user: UserDto = useSelector((state: StateTypes) => state.user.data)

  const userProfileCompletion = user?.person?.completion ?? 0

  useEffect(() => {
    try {
      dispatch(ClientActions.list())
    } catch (e) {}
  }, [])

  return (
    <PageDetails
      title={t('app:pages:myProfile:myInfo:title')}
      component={
        <div className='Informations'>
          <div className='Informations_container'>
            <div className='Informations_container_menu'>
              <Typography2 typeface='overline'>
                {t('app:pages:myProfile:informations:labelProfile')}
              </Typography2>
              <MenuItemCard
                containerStyle={{ marginTop: 16 }}
                icon={<Icon name='user-circle' type={IconTypes.NEOFONT} size={20} color={Colors.primary} />}
                title={t('app:pages:myProfile:informations:menuProfile')}
                subtitle={userProfileCompletion !== 100 && user?.person && t('app:pages:myProfile:menu:myInformationSubtitle', { completion: userProfileCompletion })}
                subtitleStyle={{ color: Colors.primary }}
                onPress={() => history.push(Routes.Profile.userProfile.path)}
              />
            </div>
            {companies.length > 0 &&
              <div className='Informations_container_menu'>
                <Typography2 typeface='overline'>
                  {t('app:pages:myProfile:informations:labelCompany')}
                </Typography2>
                {companies.map((company) =>
                  <MenuItemCard
                    key={company.uuid}
                    containerStyle={{ marginTop: 16 }}
                    icon={
                      <Icon
                        name='shopping-store-building'
                        type={IconTypes.NEOFONT}
                        size={20}
                        color={Colors.primary}
                      />
                    }
                    title={company?.holder?.legalName}
                    onPress={async () => {
                      await dispatch(ClientActions.setSelected(company.uuid))
                      history.push(Routes.Profile.companyProfile.path)
                    }}
                  />
                )}
              </div>}
          </div>
        </div>
      }
    />
  )
}
