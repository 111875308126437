import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import dayjs from 'dayjs'
import { RootActions, StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'
import IBAN from 'iban'

import {
  AlertType,
  BankAccount,
  BankAccountActions,
  BankAccountSelectors, BankAccountTypes,
  ClientSelectors,
  Dispatch,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { Button, ButtonSkin, Field, Icon, IconTypes } from '@neo-commons/components'
import { BankAccountUtils, ClientUtils, EnumUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { ClientDto, AccountStatusDto } from '@afone/neo-core-client/dist/models'

import { useIcon } from '@neo-web/hooks'
import { JustifyContent, PageContent, PageDetails, CloseSubAccountModal } from '@neo-web/componentsOld'
import { FileUtils } from '@neo-web/utils'

import './BankAccountDetail.scss'

export const BankAccountDetail: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()
  const history = useHistory()
  const param: any = useParams()

  const [closeModal, setCloseModal] = useState(false)

  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const bankAccount: BankAccount = useSelector(BankAccountSelectors.byId(param.id))
  const subscription = useSelector(SubscriptionSelectors.byId(selectedBankAccount?.subscriptionUuid))
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const accountClosureReview = useSelector((state: StateTypes) => state.bankAccount.accountClosureReview)
  const isCorporate = ClientUtils.isClientTypeCorporate(client?.type)
  const isSubAccount = BankAccountUtils.isSecondary(selectedBankAccount)
  const mainAccountName = useSelector((state: StateTypes) => state?.bankAccount).data[isSubAccount
    ? selectedBankAccount?.parentUuid
    : selectedBankAccount.uuid]?.name

  useEffect(() => {
    if (!selectedBankAccount) {
      (async () => {
        try {
          await dispatch(BankAccountActions.getById(param.id))
        } catch (e) {
          console.warn(e)
        }
      })()
    }
  }, [selectedBankAccount, param.id, dispatch])

  useEffect(() => {
    if (BankAccountUtils.isSecondary(selectedBankAccount)) {
      (async () => {
        await dispatch(BankAccountActions.getAccountClosureReview(selectedBankAccount?.uuid))
      })()
    } else if (BankAccountUtils.isMain(selectedBankAccount)) {
      (async () => {
        await dispatch(BankAccountActions.ribInfos(selectedBankAccount.uuid))
        await dispatch(BankAccountActions.ribFile(selectedBankAccount.uuid)).catch(() => {
          dispatch({
            type: BankAccountTypes.GET_BANK_ACCOUNT_RIB_FILE_FAILURE,
            errorMessage: t('errors:downloadError'),
          })
        })
      })()
    }
  }, [selectedBankAccount.uuid])

  const copyToClipboard = (value: string) => {
    try {
      (async () => {
        await navigator?.clipboard?.writeText(value)
        await dispatch(RootActions.alert({
          title: t('app:global:copied'),
          type: AlertType.SUCCESS,
        }))
      })()
    } catch (error) {}
  }

  const ibanDetails = (
    <div className='BankAccountDetail classic-box box-shadow'>
      <div>
        {BankAccountUtils.isMain(selectedBankAccount) &&
          <div className='BankAccountDetail_flex_row'>
            <div className='BankAccountDetail_content-field'>
              <Field
                label={t('app:global:bankDetails:bank')}
                value={selectedBankAccount?.rib?.bankCode}
              />
            </div>
            <div className='BankAccountDetail_content-field'>
              <Field
                label={t('app:global:bankDetails:counters')}
                value={selectedBankAccount?.rib?.locationCode}
              />
            </div>
            <div className='BankAccountDetail_content-field'>
              <Field
                label={t('app:global:bankDetails:accountNumber')}
                value={selectedBankAccount?.rib?.account}
              />
            </div>
            <div className='BankAccountDetail_content-field'>
              <Field
                label={t('app:global:bankDetails:key')}
                value={selectedBankAccount?.rib?.key}
              />
            </div>
          </div>}
        <div className='BankAccountDetail_content-field'>
          <Field
            label={t('app:global:bankDetails:iban')}
            value={selectedBankAccount?.rib?.iban ||
              (selectedBankAccount?.iban && IBAN.printFormat(selectedBankAccount?.iban))}
            rightIcon={
              <Icon
                name='content-copy'
                type={IconTypes.MATERIAL}
                size={24}
                color={Colors.primary}
              />
            }
            onEditPress={() => copyToClipboard(IBAN.printFormat(selectedBankAccount?.iban))}
          />
        </div>
        <div className='BankAccountDetail_content-field'>
          <Field
            label={t('app:global:bankDetails:bankSwiftCode')}
            value={selectedBankAccount?.bic}
            rightIcon={
              <Icon
                name='content-copy'
                type={IconTypes.MATERIAL}
                size={24}
                color={Colors.primary}
              />
            }
            onEditPress={() => copyToClipboard(selectedBankAccount?.bic)}
          />
        </div>
        {selectedBankAccount?.rib?.holder &&
          <div className='BankAccountDetail_content-field'>
            <Field
              label={t('app:global:bankDetails:holder')}
              value={selectedBankAccount?.rib?.holder}
              rightIcon={
                <Icon
                  name='content-copy'
                  type={IconTypes.MATERIAL}
                  size={24}
                  color={Colors.primary}
                />
              }
              onEditPress={() => copyToClipboard(selectedBankAccount?.rib?.holder)}
            />
          </div>}
      </div>
    </div>
  )

  const aggregatedAccountDetails = (
    <div className='BankAccountDetail classic-box box-shadow'>
      <div>
        <div className='BankAccountDetail_content-field'>
          <Field
            label={t('app:global:field:accountName')}
            value={selectedBankAccount?.name}
          />
        </div>
        <div className='BankAccountDetail_content-field'>
          <Field
            label={t('app:global:field:addDate')}
            value={dayjs(selectedBankAccount?.createdDate).format('L')}
          />
        </div>
        {selectedBankAccount?.updatedDate &&
          <div className='BankAccountDetail_content-field'>
            <Field
              label={t('app:global:field:updatedDate')}
              value={(t('app:global:field:dateFormat', {
                date: dayjs(selectedBankAccount?.updatedDate)
                  .format('dddd')
                  .charAt(0).toUpperCase()
                  .concat(dayjs(selectedBankAccount?.updatedDate).format('dddd Do MMMM').substr(1)),
                hours: dayjs(selectedBankAccount?.updatedDate).format('HH:mm'),
                interpolation: { escapeValue: false },
              }))}
            />
          </div>}
      </div>
    </div>
  )

  return (
    <>
      <CloseSubAccountModal
        show={closeModal}
        onClose={() => {
          setCloseModal(false)
        }}
        cancelSubscription={accountClosureReview?.willCancelSubscription}
        price={accountClosureReview?.subscriptions[0]?.price}
      />
      {bankAccount &&
        <PageDetails
          title={isSubAccount ? t('app:pages:synthesis:aboutSubAccount') : t('app:pages:synthesis:aboutAccount')}
          onBack={() => history.push(Routes.Synthesis.index.path)}
          component={(
            <PageContent
              footer={
                ((!BankAccountUtils.isMainOrAggregated(selectedBankAccount)) &&
                  (((BankAccountUtils.isProject(selectedBankAccount) &&
                  selectedBankAccount?.status !== AccountStatusDto.TERMINATION_IN_PROGRESS)) ||
                  BankAccountUtils.isPos(selectedBankAccount)))
                  ? (
                    <Button
                      skin={ButtonSkin.OUTLINE_RED}
                      title={t('app:pages:bankAccountDetail:closeSubAccount')}
                      onPress={async () => {
                        if (BankAccountUtils.isProject(selectedBankAccount)) {
                          dispatch(BankAccountActions.setSelectedBankAccountToClose(selectedBankAccount))
                          setCloseModal(true)
                        } else {
                          history.push(Routes.Services.closePosAccount.path)
                        }
                      }}
                    />)
                  : BankAccountUtils.isMain(selectedBankAccount)
                    ? (
                      <Button
                        skin={ButtonSkin.PRIMARY}
                        title={t('app:global:savePdf')}
                        rightIcon={<Icon name='download' type={IconTypes.FEATHER} size={20} color={Colors.white} />}
                        onPress={async () => {
                          try {
                            FileUtils.openBase64NewTab(
                              selectedBankAccount.rib?.file.fileEncoded || '',
                              selectedBankAccount.rib?.file.name || '',
                              'pdf'
                            )
                          } catch (error) {}
                        }}
                      />)
                    : (<></>)
              }
              justifyContent={JustifyContent.BETWEEN}
            >
              <div className='BankAccountDetail_center'>
                {useIcon(selectedBankAccount, client.type, false, false, 50, true)}
              </div>
              {!!selectedBankAccount?.iban && ibanDetails}
              {BankAccountUtils.isAggregated(selectedBankAccount)
                ? aggregatedAccountDetails
                : (
                  <div className='BankAccountDetail classic-box box-shadow'>
                    <div>
                      {isSubAccount
                        ? (
                          <>
                            <div className='BankAccountDetail_content-field'>
                              <Field
                                label={t('app:global:field:subAccountName')}
                                value={selectedBankAccount?.name}
                              />
                            </div>
                            <div className='BankAccountDetail_content-field'>
                              <Field
                                label={t('app:global:field:creationDate')}
                                value={selectedBankAccount?.createdDate && t('app:global:field:dateFormat', {
                                  date: dayjs(selectedBankAccount?.createdDate).format('L'),
                                  hours: dayjs(selectedBankAccount?.createdDate).format('HH:mm'),
                                  interpolation: { escapeValue: false },
                                })}
                              />
                            </div>
                            <div className='BankAccountDetail_content-field'>
                              <Field
                                label={t('app:global:field:parentName')}
                                value={mainAccountName}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='BankAccountDetail_content-field'>
                              <Field
                                label={t('app:global:field:accountName')}
                                value={selectedBankAccount?.name}
                              />
                            </div>
                            {isCorporate
                              ? (
                                <div className='BankAccountDetail_content-field'>
                                  <Field
                                    label={t('app:global:field:companyName')}
                                    value={(client as ClientDto)?.holder.legalName}
                                  />
                                </div>
                              )
                              : (
                                <div className='BankAccountDetail_content-field'>
                                  <Field
                                    label={t('app:global:field:subscriber')}
                                    value={subscription?.subscriber.firstName + ' ' + subscription?.subscriber.lastName}
                                  />
                                </div>
                              )}
                            <div className='BankAccountDetail_content-field'>
                              <Field
                                label={t('app:global:field:subscriptionDate')}
                                value={subscription?.createdDate && t('app:global:field:subscriptionDateFormat', {
                                  date: dayjs(subscription?.createdDate).format('L'),
                                  hours: dayjs(subscription?.createdDate).format('HH:mm'),
                                  interpolation: { escapeValue: false },
                                })}
                              />
                            </div>
                            <div className='BankAccountDetail_content-field'>
                              <Field
                                label={t('app:global:field:accountType')}
                                value={t(EnumUtils.getClientTypeDtoKey(client?.type))}
                              />
                            </div>
                            <div className='BankAccountDetail_content-field'>
                              <Field
                                label={t('app:global:field:offerName')}
                                value={subscription?.offer.name}
                              />
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                )}
            </PageContent>
          )}
        />}
    </>
  )
}
