import { map } from 'lodash'

import { documentUploadPolicy } from '@neo-commons/policies'

export const DocumentUploadRoutes = {
  index: {
    path: documentUploadPolicy.basePath,
    name: 'app:pages:register:step1:phone.titleMessage',
    available: true,
  },
  ...map(documentUploadPolicy.steps, ({ path, meta }) => ({
    path,
    name: meta?.screenTitle ?? '',
    available: true,
  })),
}
