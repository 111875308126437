import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { POSReturnStep, POSReturnSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { PosActions, PosSelectors, PreparePOSReturn } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { CardDetails, CardStepper, CardStepperItem, JustifyContent, PageContent } from '@neo-web/componentsOld'
import { FileUtils } from '@neo-web/utils'

type POSReturnProps = StepComponentProps<POSReturnSteps[POSReturnStep.Success]>

export const Success: React.FC<POSReturnProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const preparedPOSReturn: PreparePOSReturn = useSelector(PosSelectors.prepareReturn)
  const response = preparedPOSReturn?.response

  const posMaterialToReturn = () =>
    response?.equipmentReturn?.map(model =>
      <div key={model.posCode}>
        <Typography typeface='content'>
          {t('neo-commons:pages:services:pos:return:success:stepper2detail', { name: model.posName })}
        </Typography>
        <ul>
          {model?.posEquipment?.map(equipment =>
            <li key={equipment}><Typography typeface='content'>{equipment}</Typography></li>
          )}
        </ul>
      </div>
    )

  const listSteps: CardStepperItem[] = [
    {
      icon: <Icon name='printer' type={IconTypes.NEOFONT} size={28} color={Colors.secondary} />,
      text: t('neo-commons:pages:services:pos:return:success:stepper1'),
    }, {
      icon: <Icon name='shipping-box' type={IconTypes.NEOFONT} size={28} color={Colors.secondary} />,
      text: t('neo-commons:pages:services:pos:return:success:stepper2'),
      button: response?.trackingUrl && (
        <Button
          skin={ButtonSkin.DEFAULT}
          small
          rightIcon={<Icon name='map-pin' type={IconTypes.FEATHER} size={20} color={Colors.primary} />}
          overrideStyleButton={{ height: 20 }}
          title={t('neo-commons:pages:services:pos:return:success:stepper2button')}
          onPress={() => window.open(response?.trackingUrl)}
        />
      ),
      collapsibleContent: posMaterialToReturn(),
    }, {
      icon: <Icon name='arrow-right' type={IconTypes.NEOFONT} size={28} color={Colors.secondary} />,
      text: t('neo-commons:pages:services:pos:return:success:stepper3'),
    },
  ]

  const downloadCoupon = () => {
    (async () => {
      try {
        const res: any = await dispatch(PosActions.getDispatchLabel(response?.uuid))
        if (res?.file?.fileEncoded) {
          FileUtils.openBase64NewTab(
            res.file.fileEncoded,
            res.file?.name ?? 'Return',
            'pdf'
          )
        }
      } catch (_) {}
    })()
  }

  const onBack = () => {
    (async () => {
      await dispatch(PosActions.reset())
      history.push(
        Routes.Services.pos.path,
        { returnReason: preparedPOSReturn.returnReason }
      )
    })()
  }

  return (
    <PageContent
      title={t('neo-commons:pages:services:pos:return:success:title')}
      titleInBody
      justifyContent={JustifyContent.BETWEEN}
      footer={(
        <>
          <Button
            skin={ButtonSkin.DEFAULT}
            title={t('neo-commons:pages:services:pos:return:success:buttonCoupon')}
            rightIcon={<Icon name='download' type={IconTypes.FEATHER} size={20} color={Colors.primary} />}
            overrideTextStyleButton={{ justifyContent: 'space-between' }}
            containerStyle={{ marginVertical: 16 }}
            onPress={downloadCoupon}
          />
          <Button
            skin={ButtonSkin.PRIMARY}
            title={t('neo-commons:pages:services:pos:return:success:buttonBack')}
            onPress={onBack}
          />
        </>
      )}
    >
      <CardDetails>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '15px 0' }}>
          <Typography typeface='content'>{t('neo-commons:pages:services:pos:return:success:coupon')}</Typography>
          <Typography typeface='bold'>&nbsp;{response?.trackingCode}</Typography>
        </div>
        <CardStepper
          noContainer
          list={listSteps}
          timeline
        />
      </CardDetails>
    </PageContent>
  )
}
