import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import { Icon, IconTypes, Button, ButtonSkin } from '@neo-commons/components'
import { ClientSelectors, Dispatch, SubscriptionActions, SubscriptionSelectors } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { ClientUtils, SubscriptionUtils } from '@neo-commons/libraries'

import { ErrorCodeDto } from '@afone/neo-core-client/dist/models'
import { ClientDto, SubscriptionDto } from '@afone/neo-core-client'

import { Tunnel, TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import { SuccessFailureProps } from '../../../Components/SuccessFailure/SuccessFailure'

export const HandleSignatureCallback: React.FC<SuccessFailureProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<Dispatch>()
  const location: any = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const status = searchParams.get('status')
  const isSubscriptionLoading = useSelector((state: StateTypes) => state.subscription?.loading)
  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocleSubscribing)
  const personUuid = SubscriptionUtils.getKycStep(subscription)?.personUuid ?? ''
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const source = window.sessionStorage.getItem('source')

  let failSuccess: string | null = null
  let failSuccessLink: string | null = null
  if (status === 'FAILED') {
    failSuccess = 'electronicSignatureUserFailure'
    failSuccessLink = 'noelse://signature/failed'
  } else if (status === 'FINISHED') {
    failSuccess = 'electronicSignatureSuccess'
    failSuccessLink = 'noelse://signature/finished'
  }

  const regenerateLink = async () => {
    try {
      await dispatch(SubscriptionActions.getSignatureLink({
        subscriptionUuid: subscription.uuid,
        personUuid,
      }))
    } catch (e) {
      try {
        await dispatch(SubscriptionActions.list())
        if (e.code === ErrorCodeDto.C0707) {
          history.push(Routes.Subscription.electronicSignatureDocumentsChoice.path)
        } else if (e.code === ErrorCodeDto.C0512) {
          history.push(Routes.Subscription.subscriptionError.path, { errorMessage: e.message })
        } else {
          history.push(Routes.Subscription.subscriptionError.path)
        }
      } catch (e) {}
    }
    if (ClientUtils.isClientTypeIndividual(client?.type)) {
      history.push('/subscription/individual/electronicSignature')
    } else {
      history.push('/subscription/business/electronicSignature')
    }
  }
  const nextStep = async () => {
    if (ClientUtils.isClientTypeIndividual(client?.type)) {
      history.push('/subscription/individual/subscriptionSuccess')
    } else {
      history.push('/subscription/business/subscriptionSuccess')
    }
  }

  useEffect(() => {
    const refreshSubs = async () => {
      await dispatch(SubscriptionActions?.list())
    }
    if (source && source === 'web') {
      refreshSubs().then(() => {
        if (status === 'FINISHED') {
          nextStep()
        }
      })
    } else {
      window.location.href = failSuccessLink
    }
  }, [status])

  return (
    <Tunnel
      component={
        <TunnelContent
          loading={isSubscriptionLoading}
          justifyContent={TunnelJustifyContent.CENTER}
          icon={<Icon color={Colors.secondary} name='alert-circle' size={40} type={IconTypes.FEATHER} />}
          subtitle={t(`app:pages:subscription:global:${failSuccess}:title`)}
          description={t(`app:pages:subscription:global:${failSuccess}:description`)}
        >
          <div className='SuccessFailure_description'>
            {source && source === 'web'
              ? (status === 'FAILED'
                ? <Button title={t('app:global:retry')} skin={ButtonSkin.PRIMARY} onPress={regenerateLink} />
                : <Button title={t('app:global:continue')} skin={ButtonSkin.PRIMARY} onPress={nextStep} />
              )
              : <a href={failSuccessLink}><Button title={t('app:global:continue')} skin={ButtonSkin.PRIMARY} /></a>}
          </div>
        </TunnelContent>
      }
      footer
    />
  )
}
