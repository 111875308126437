// @format
import { StyleSheet, TextStyle, ViewStyle } from 'react-native'

import { Typo, Colors } from '@neo-commons/styles'

interface ReccuringTransferCardStyle {
  dateGroup: ViewStyle,
  label: TextStyle
  labelEmpty: TextStyle
  info: TextStyle
  infoEmpty: TextStyle
  labelContainer: ViewStyle
  amountContainer: ViewStyle
  amountText: TextStyle
  recurringCard: ViewStyle
  amountDeclinedText: TextStyle
}

const styles = StyleSheet.create<ReccuringTransferCardStyle>({
  dateGroup: {
    ...Typo.bigTitle,
    fontSize: 16,
    paddingLeft: 16,
    textAlignVertical: 'center',
    color: Colors.black,
    paddingTop: 8,
    paddingBottom: 8,
  },
  label: {
    ...Typo.selectedTitle,
    fontSize: 14,
    color: Colors.black,
    textAlign: 'left',
  },
  labelEmpty: {
    width: 50,
    height: 12,
    marginBottom: 3,
    backgroundColor: 'grey',
    opacity: 0.1,
  },
  info: {
    ...Typo.itemSubtitle,
    fontSize: 13,
    paddingTop: 5,
    color: Colors.black,
    textAlign: 'left',
  },
  infoEmpty: {
    width: 150,
    opacity: 0.05,
    height: 12,
    backgroundColor: 'grey',
  },
  labelContainer: {
    flex: 2,
    marginLeft: 3,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  amountContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  amountText: {
    ...Typo.transactionAmount,
    fontSize: 16,
  },
  amountDeclinedText: {
    ...Typo.transactionAmount,
    fontSize: 16,
    color: Colors.error,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  recurringCard: {
    paddingVertical: 8,
  },
})

export default styles
