import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { ConfigStore } from '@neo-commons/store'

import { loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    ConfigStore.GET_CONFIG_REQUEST,
    ConfigStore.GET_CONFIG_SUCCESS,
    ConfigStore.GET_CONFIG_FAILED,
  ])
}

export default [
  reactiveLoader$,
]
