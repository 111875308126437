import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StateTypes } from 'Store'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { Modal } from '@neo-web/componentsOld'

interface TelecollectionModalProps {
  open: boolean,
  onClose: () => void,
  nextStep: () => void,
}

export const TelecollectionModal: React.FC<TelecollectionModalProps> = (props) => {
  const { t } = useTranslation()

  const configState = useSelector((state: StateTypes) => state.config)
  const faqUrl = configState?.data?.currentConfig?.faq ?? t('app:pages:services:pos:managePos:assistance:link')

  return (
    <Modal
      open={props.open}
      displayCross
      backdrop='static'
      onClose={props.onClose}
      title={t('neo-commons:pages:services:pos:return:selectPos:modalTlc:title')}
      titleIcon={<Icon color={Colors.secondary} name='alert-circle' size={40} type={IconTypes.FEATHER} />}
      footer={
        <>
          <Button
            skin={ButtonSkin.OUTLINE_PRIMARY}
            containerStyle={{ marginTop: 12 }}
            title={t('neo-commons:pages:services:pos:return:selectPos:modalTlc:button1')}
            onPress={() => window.open(faqUrl, '_blank')}
          />
          <Button
            skin={ButtonSkin.PRIMARY}
            containerStyle={{ marginTop: 12 }}
            title={t('neo-commons:pages:services:pos:return:selectPos:modalTlc:button2')}
            onPress={() => props.nextStep()}
          />
        </>
      }
    >
      <Typography typeface='content'>
        {t('neo-commons:pages:services:pos:return:selectPos:modalTlc:text')}
      </Typography>
    </Modal>
  )
}
