import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexboxGrid } from 'rsuite'

import {
  Button,
  ButtonSkin,
  Input,
} from '@neo-commons/components'

export interface LabelContactContentProps {
  familyName: string,
  givenName: string,
  save: (familyName: string, givenName: string) => void,
}

export const LabelContactContent: React.FC<LabelContactContentProps> = (props: LabelContactContentProps) => {
  const { t } = useTranslation()

  const [familyName, setFamilyName] = useState(props.familyName)
  const [givenName, setGivenName] = useState(props.givenName)

  return (
    <>
      <FlexboxGrid.Item colspan={24}>
        <Input
          label={t('app:pages:payments:contact:create:form:name:firstname')}
          value={givenName}
          onChangeText={(value) => setGivenName(value)}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24}>
        <Input
          label={t('app:pages:payments:contact:create:form:name:name')}
          value={familyName}
          onChangeText={(value) => setFamilyName(value)}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24} className='EditModal_saveButton'>
        <Button
          skin={ButtonSkin.PRIMARY}
          disabled={!familyName}
          title={t('app:global:save')}
          onPress={() => { (familyName) && props.save(familyName, givenName) }}
        />
      </FlexboxGrid.Item>
    </>
  )
}
