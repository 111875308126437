import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { FlexboxGrid } from 'rsuite'
import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { ContactPreferencesActions, ContactPreferencesTypes } from '@neo-commons/store'
import { Typography } from '@neo-commons/components'

import { PageDetails, LazyLoadingContainer } from '@neo-web/componentsOld'

import { PreferenceCardList } from '../PreferenceCard/PreferenceCardList'

import './PreferenceContact.scss'

export const PreferenceContact = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const contactPreferencesState = useSelector((state: StateTypes) => state?.contactPreferences?.data ?? [])

  useEffect(() => {
    dispatch(ContactPreferencesActions.getContactPreferences())
  }, [])

  return (
    <PageDetails
      component={
        <FlexboxGrid className='PreferenceContact_container' justify='center' align='middle'>
          <Typography typeface='bold'>
            {t('app:pages:myProfile:preferenceAndSecurity:contact:subtitle')}
          </Typography>
          <FlexboxGrid.Item className='PreferenceContact_item' colspan={24}>
            <LazyLoadingContainer
              className='PreferenceContact_item_container'
              events={[ContactPreferencesTypes.GET_CONTACT_PREFERENCES_REQUEST]}
            >
              {contactPreferencesState.map(item =>
                <PreferenceCardList
                  key={item.uuid}
                  title={item.title}
                  choices={item.channels}
                />
              )}
            </LazyLoadingContainer>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      }
      onBack={() => history.push(Routes.Profile.preference.path)}
      title={t('app:pages:myProfile:preferenceAndSecurity:contact:title')}
    />
  )
}
