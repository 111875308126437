import { RoutesInterface } from './Routes'

export class SvaRegisterRoutes {
  public static index: RoutesInterface = {
    path: '/register/sva',
    name: 'app:pages:agentClientRegister:title',
    available: true,
  }

  public static redirectLogin: RoutesInterface = {
    path: SvaRegisterRoutes.index.path + '/redirectLogin',
    name: 'app:pages:agentClientRegister:title',
    available: true,
  }

  public static login: RoutesInterface = {
    path: SvaRegisterRoutes.index.path + '/login',
    name: 'app:pages:agentClientRegister:login:title',
    available: true,
  }

  public static logout: RoutesInterface = {
    path: SvaRegisterRoutes.index.path + '/logout',
    name: 'app:pages:agentClientRegister:logout:title',
    available: true,
  }

  public static unauthorized: RoutesInterface = {
    path: SvaRegisterRoutes.index.path + '/unauthorized',
    name: 'app:pages:agentClientRegister:unauthorized:title',
    available: true,
  }
}
