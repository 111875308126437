import {
  CardDto,
  CardLimitDto,
  CardUpdateDto,
  UsageLimitCodeDto,
  WalletProviderDto,
  OfferDto,
  WalletCreateDto,
  CardCreateDto,
  ErrorCodeDto,
  WorkflowCardOppositionDto,
  SecretQuestionDto,
  OppositionReasonDto,
  CardLimitUpdateDto,
  CardStatusDto,
} from '@afone/neo-core-client/dist/models'
import { NeobankApi } from '@neo-commons/services'
import { omit, mapValues, uniq, find } from 'lodash'
import { i18commons } from '@neo-commons/i18n'
import { AnyAction, Reducer } from 'redux'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import { CreditCardUtils, CardUpdateType, DeliveryModeDto, BankAccountUtils } from '@neo-commons/libraries'
import { PasswordKeypadData } from '@neo-commons/hooks'
import produce from 'immer'

import { State, initialResourceState, AlertType, getInitialResourceState } from '../utils'
import {
  Dispatch,
  ResourceState,
  resourceDefaultSelectors,
  reduceAndRemoveSubList,
} from '../utils/resourceState'

import { SubscriptionActions } from './subscription'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type CreditCardData = CardDto & {
  limits: CardLimitDto[],
  encryptedData?: string
}

export enum CardLimitStatus {
  ALL_WEEKLY = 'ALL_WEEKLY',
  ALL_MONTHLY = 'ALL_MONTHLY',
  ATM_WEEKLY = 'ATM_WEEKLY',
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Types.
\* %%%%%%%%%%%%%%%%%% */

export const CardTypes = {
  LIST_CARD_REQUEST: 'card/LIST_CARD_REQUEST',
  LIST_CARD_SUCCESS: 'card/LIST_CARD_SUCCESS',
  LIST_CARD_FAILURE: 'card/LIST_CARD_FAILURE',
  CREATE_CARD_REQUEST: 'card/CREATE_CARD_REQUEST',
  CREATE_CARD_SUCCESS: 'card/CREATE_CARD_SUCCESS',
  CREATE_CARD_FAILURE: 'card/CREATE_CARD_FAILURE',
  SET_SELECTED_CARD: 'card/SET_SELECTED_CARD',
  UPDATE_CARD_REQUEST: 'card/UPDATE_CARD_REQUEST',
  UPDATE_CARD_SUCCESS: 'card/UPDATE_CARD_SUCCESS',
  UPDATE_CARD_FAILURE: 'card/UPDATE_CARD_FAILURE',
  TOGGLE_CARD_WALLET_REQUEST: 'card/TOGGLE_CARD_WALLET_REQUEST',
  TOGGLE_CARD_WALLET_SUCCESS: 'card/TOGGLE_CARD_WALLET_SUCCESS',
  TOGGLE_CARD_WALLET_FAILURE: 'card/TOGGLE_CARD_WALLET_FAILURE',
  LIST_CARD_LIMITS_REQUEST: 'card/LIST_CARD_LIMITS_REQUEST',
  LIST_CARD_LIMITS_SUCCESS: 'card/LIST_CARD_LIMITS_SUCCESS',
  LIST_CARD_LIMITS_FAILURE: 'card/LIST_CARD_LIMITS_FAILURE',
  UPDATE_CARD_LIMITS_REQUEST: 'card/UPDATE_CARD_LIMITS_REQUEST',
  UPDATE_CARD_LIMITS_SUCCESS: 'card/UPDATE_CARD_LIMITS_SUCCESS',
  UPDATE_CARD_LIMITS_FAILURE: 'card/UPDATE_CARD_LIMITS_FAILURE',
  GET_CARD_DETAILS_REQUEST: 'card/GET_CARD_DETAILS_REQUEST',
  GET_CARD_DETAILS_SUCCESS: 'card/GET_CARD_DETAILS_SUCCESS',
  GET_CARD_DETAILS_FAILURE: 'card/GET_CARD_DETAILS_FAILURE',
  GET_ENCRYPTED_CARD_DATA_REQUEST: 'card/GET_ENCRYPTED_CARD_DATA_REQUEST',
  GET_ENCRYPTED_CARD_DATA_SUCCESS: 'card/GET_ENCRYPTED_CARD_DATA_SUCCESS',
  GET_ENCRYPTED_CARD_DATA_FAILURE: 'card/GET_ENCRYPTED_CARD_DATA_FAILURE',
  ACTIVATE_CARD_REQUEST: 'card/ACTIVATE_CARD_REQUEST',
  ACTIVATE_CARD_SUCCESS: 'card/ACTIVATE_CARD_SUCCESS',
  ACTIVATE_CARD_FAILURE: 'card/ACTIVATE_CARD_FAILURE',
  CHECK_SECRET_QUESTION_ANSWER_REQUEST: 'card/CHECK_SECRET_QUESTION_ANSWER_REQUEST',
  CHECK_SECRET_QUESTION_ANSWER_SUCCESS: 'card/CHECK_SECRET_QUESTION_ANSWER_SUCCESS',
  CHECK_SECRET_QUESTION_ANSWER_FAILURE: 'card/CHECK_SECRET_QUESTION_ANSWER_FAILURE',
  CREATE_OPPOSITION_WORKFLOW_REQUEST: 'card/CREATE_OPPOSITION_WORKFLOW_REQUEST',
  CREATE_OPPOSITION_WORKFLOW_SUCCESS: 'card/CREATE_OPPOSITION_WORKFLOW_SUCCESS',
  CREATE_OPPOSITION_WORKFLOW_FAILURE: 'card/CREATE_OPPOSITION_WORKFLOW_FAILURE',
  VALIDATE_CARD_OPPOSITION_REQUEST: 'card/VALIDATE_CARD_OPPOSITION_REQUEST',
  VALIDATE_CARD_OPPOSITION_SUCCESS: 'card/VALIDATE_CARD_OPPOSITION_SUCCESS',
  VALIDATE_CARD_OPPOSITION_FAILURE: 'card/VALIDATE_CARD_OPPOSITION_FAILURE',
  PREPARE: 'card/PREPARE',
  PREPARE_CARD_OPPOSITION: 'card/PREPARE_CARD_OPPOSITION',
  RESET_SUBLIST: 'card/RESET_SUBLIST',
  RESET: 'card/RESET',
}

/* %%%%%%%%%%%%%%%%%% *\
    Action Creators.
\* %%%%%%%%%%%%%%%%%% */

const createCard = function (workflowSubscriptionUuid: string, wallet?: WalletCreateDto, xValidationOnly?: number) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: CardTypes.CREATE_CARD_REQUEST })
    const accountUuid = getState().bankAccount.list!.selectedId
    const preparedCardOrder = getState().card.preparedCardOrder
    if (!accountUuid || !preparedCardOrder) throw new Error()

    let deliveryAddress
    if (preparedCardOrder?.withPhysicalCard) {
      deliveryAddress = {
        city: preparedCardOrder.deliveryData?.address?.city ?? '',
        countryCodeIso3: preparedCardOrder.deliveryData?.address?.country.isoCodeAlpha3 ?? '',
        postalCode: preparedCardOrder.deliveryData?.address?.zipCode ?? '',
        addressLine1: preparedCardOrder.deliveryData?.address?.street ?? '',
        addressLine2: preparedCardOrder.deliveryData?.address?.streetLine2 ?? '',
        lastName: preparedCardOrder.deliveryData?.user?.lastname ?? '',
        firstName: preparedCardOrder.deliveryData?.user?.firstname ?? '',
      }
    }
    const cardCreateDto: CardCreateDto = {
      plasticCardOrder: preparedCardOrder?.withPhysicalCard,
      personUuid: preparedCardOrder.personUuid,
      deliveryAddress,
      pin: preparedCardOrder?.keypadDataCardPin?.password.map(e => e.value),
      wallet,
      workflowSubscriptionUuid,
      deliveryCode: preparedCardOrder.deliveryData?.mode?.data.deliveryCode,
    }

    try {
      const payload = await NeobankApi.getInstance().cardApi.createCard(accountUuid, cardCreateDto, xValidationOnly)
      dispatch({ type: CardTypes.CREATE_CARD_SUCCESS, data: payload?.data })
    } catch (error) {
      dispatch({ type: CardTypes.CREATE_CARD_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

const createPhysicalCard = (xValidationOnly?: number) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: CardTypes.CREATE_CARD_REQUEST })
    const accountUuid = getState().bankAccount.list!.selectedId ?? ''
    const preparedCardOrder = getState().card.preparedCardOrder
    const deliveryAddress = {
      city: preparedCardOrder?.deliveryData?.address?.city ?? '',
      countryCodeIso3: preparedCardOrder?.deliveryData?.address?.country.isoCodeAlpha3 ?? '',
      postalCode: preparedCardOrder?.deliveryData?.address?.zipCode ?? '',
      addressLine1: preparedCardOrder?.deliveryData?.address?.street ?? '',
      lastName: preparedCardOrder?.deliveryData?.user?.lastname ?? '',
      firstName: preparedCardOrder?.deliveryData?.user?.firstname ?? '',
    }

    try {
      const payload = await NeobankApi.getInstance().cardApi.createPlasticCard(preparedCardOrder?.cardUuid ?? '', xValidationOnly, {
        accountUuid,
        personUuid: preparedCardOrder?.personUuid,
        deliveryAddress,
        pin: preparedCardOrder?.keypadDataCardPin?.password.map(e => e.value) as string[],
        deliveryCode: preparedCardOrder?.deliveryData?.mode?.data?.deliveryCode ?? '',
      })
      dispatch({ type: CardTypes.CREATE_CARD_SUCCESS, data: payload?.data })
    } catch (error) {
      dispatch({ type: CardTypes.CREATE_CARD_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

const list = function (walletId?: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      // We need to get the main account to list the cards
      const accountUuid = getState().bankAccount.list.ids
        .find(id => BankAccountUtils.isMain(getState().bankAccount.data[id]))
      if (!accountUuid) throw new Error()
      if (accountUuid && (accountUuid === 'prospect-account' ||
        accountUuid === 'prospect-project' ||
        accountUuid === 'total-balance')) {
        return
      }

      dispatch({ type: CardTypes.LIST_CARD_REQUEST })
      const cardResponse = await NeobankApi.getInstance()
        .cardApi.getCardsByAccount(
          accountUuid, undefined, undefined, undefined, undefined, walletId,
          [CardStatusDto.DESACTIVATED, CardStatusDto.ACTIVE, CardStatusDto.ACTIVE_NO_RENEWAL, CardStatusDto.DO_NOT_HONOUR]
        )
      const data = cardResponse.status === 204 ? [] : cardResponse?.data

      // If one card or less is received, we return
      if (data.length <= 1) {
        dispatch({ type: CardTypes.LIST_CARD_SUCCESS, data, paginationEnded: true, listIdentifier: accountUuid })
        return
      }

      // If we have active cards, we return them all
      const activeCards = data.filter((card) => card.status !== CardStatusDto.DESACTIVATED)
      if (activeCards.length > 0) {
        dispatch({
          type: CardTypes.LIST_CARD_SUCCESS,
          data: activeCards,
          paginationEnded: true,
          listIdentifier: accountUuid,
        })
      // Else we return the most recent desactivated (opposed)
      } else {
        const mostRecent = data.reduce((previous, current) => {
          return dayjs(previous.oppositionInfo?.oppositionDate)
            .isAfter(dayjs(current.oppositionInfo?.oppositionDate))
            ? previous : current
        })
        dispatch({ type: CardTypes.LIST_CARD_SUCCESS, data: [mostRecent], paginationEnded: true, listIdentifier: accountUuid })
      }
    } catch (error) {
      dispatch({ type: CardTypes.LIST_CARD_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

const update = (cardUuid: string, cardUpdateDto: CardUpdateDto, cardUpdateType: CardUpdateType) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CardTypes.UPDATE_CARD_REQUEST })
    try {
      const cardResponse = await NeobankApi.getInstance()
        .cardApi.updateCardByUuid(cardUuid, cardUpdateDto)
      const data = cardResponse?.data

      dispatch({
        type: CardTypes.UPDATE_CARD_SUCCESS,
        data,
        alertType: AlertType.SUCCESS,
        successMessage: CreditCardUtils.getUpdateSuccessMessage(data, cardUpdateType),
      })
    } catch (error) {
      dispatch({ type: CardTypes.UPDATE_CARD_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

const setSelected = (selectedId: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (getState().card.list!.selectedId === selectedId) return
    dispatch({ type: CardTypes.SET_SELECTED_CARD, selectedId })
  }
}

const toggleWallet = (cardUuid: string, walletId: string, tokenize: boolean) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: CardTypes.TOGGLE_CARD_WALLET_REQUEST })
    try {
      const accountUuid = getState().bankAccount.list!.selectedId
      if (!accountUuid) throw new Error()

      if (tokenize) {
        await NeobankApi.getInstance().cardApi.tokenizeCard(cardUuid,
          { provider: WalletProviderDto.ANTELOP, walletId: walletId, accountUuid: accountUuid })
      } else {
        await NeobankApi.getInstance().cardApi.deleteCardWallet(cardUuid, { walletId: walletId })
      }

      dispatch({
        type: CardTypes.TOGGLE_CARD_WALLET_SUCCESS,
        cardUuid,
        tokenize,
        successMessage: tokenize
          ? i18commons.t('neo-commons:card:cardAddedToNoelsePay') : i18commons.t('neo-commons:card:cardRemovedFromNoelsePay'),
      })
    } catch (error) {
      dispatch({ type: CardTypes.TOGGLE_CARD_WALLET_FAILURE, errorMessage: error.message })
    }
  }
}

const getLimits = (cardUuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CardTypes.LIST_CARD_LIMITS_REQUEST })
    try {
      const cardResponse = await NeobankApi.getInstance()
        .cardApi.getCardLimits(cardUuid, [UsageLimitCodeDto.ALL_WEEKLY, UsageLimitCodeDto.ALL_MONTHLY, UsageLimitCodeDto.ATM_WEEKLY])
      const data = cardResponse?.data

      dispatch({ type: CardTypes.LIST_CARD_LIMITS_SUCCESS, cardUuid, data })
    } catch (error) {
      dispatch({ type: CardTypes.LIST_CARD_LIMITS_FAILURE, errorMessage: error.message })
    }
  }
}

const updateLimits = (cardUuid: string, limits: CardLimitUpdateDto[]) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CardTypes.UPDATE_CARD_LIMITS_REQUEST })
    try {
      const cardResponse = await NeobankApi.getInstance()
        .cardApi.updateCardLimits(cardUuid, limits)
      const data = cardResponse?.data

      dispatch({
        type: CardTypes.UPDATE_CARD_LIMITS_SUCCESS,
        cardUuid,
        data,
        alertType: AlertType.SUCCESS,
        errorMessage: i18commons.t('neo-commons:card:limits:success'),
      })
    } catch (error) {
      dispatch({ type: CardTypes.UPDATE_CARD_LIMITS_FAILURE, alertType: AlertType.ERROR, errorMessage: error.message })
      throw error
    }
  }
}

const getCardDetails = (cardUuid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CardTypes.GET_CARD_DETAILS_REQUEST })
    try {
      const cardResponse = await NeobankApi.getInstance()
        .cardApi.getCardDetails(cardUuid)
      const data = cardResponse?.data
      dispatch({ type: CardTypes.GET_CARD_DETAILS_SUCCESS, data })
    } catch (error) {
      dispatch({ type: CardTypes.GET_CARD_DETAILS_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

const activate = (cardUuid: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: CardTypes.ACTIVATE_CARD_REQUEST })
    try {
      const accountUuid = getState().bankAccount.list!.selectedId
      if (!accountUuid) throw new Error()

      const cardResponse = await NeobankApi.getInstance().cardApi.activateCard(cardUuid, {
        accountUuid: accountUuid,
      })
      const data = cardResponse?.data

      dispatch({ type: CardTypes.ACTIVATE_CARD_SUCCESS, data })
    } catch (error) {
      dispatch({ type: CardTypes.ACTIVATE_CARD_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

const prepare = (preparedCardOrder?: PrepareCardOrder) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CardTypes.PREPARE, preparedCardOrder })
  }
}

const getEncryptedData = (cardUuid: string, publicKey: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CardTypes.GET_ENCRYPTED_CARD_DATA_REQUEST })
    try {
      const payload = await NeobankApi.getInstance().cardApi.getCardEncrypted(cardUuid, { key: publicKey })
      const cardEncrypted = payload.data.cardEncrypted
      dispatch({ type: CardTypes.GET_ENCRYPTED_CARD_DATA_SUCCESS, data: cardEncrypted, cardUuid })
      return cardEncrypted
    } catch (error) {
      dispatch({ type: CardTypes.GET_ENCRYPTED_CARD_DATA_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

const prepareOpposition = (payload: {
  preparedCardOpposition?: PrepareCardOpposition,
  forceCreateOrUpdate?: boolean,
}) => {
  return async (dispatch: Dispatch) => {
    if (payload.forceCreateOrUpdate) {
      dispatch({ type: CardTypes.CREATE_OPPOSITION_WORKFLOW_REQUEST })
      try {
        const cardResponse =
          await NeobankApi.getInstance()
            .cardApi.createOrUpdateCardOpposition(payload.preparedCardOpposition?.card?.uuid!)
        const cardOpposition = {
          ...payload.preparedCardOpposition,
          workflowCardOpposition: cardResponse.data,
          secretQuestions: cardResponse.data.secretQuestions,
        }

        dispatch({ type: CardTypes.CREATE_OPPOSITION_WORKFLOW_SUCCESS })
        dispatch({ type: CardTypes.PREPARE_CARD_OPPOSITION, preparedCardOpposition: cardOpposition })
        await dispatch(SubscriptionActions.list())
        return
      } catch (error) {
        dispatch({
          type: CardTypes.CREATE_OPPOSITION_WORKFLOW_FAILURE,
          alertType: AlertType.WARNING,
          errorMessage: error.message,
        })
        throw error
      }
    }

    dispatch({ type: CardTypes.PREPARE_CARD_OPPOSITION, preparedCardOpposition: payload.preparedCardOpposition })
  }
}

const reset = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CardTypes.RESET })
  }
}

const checkSecretQuestionAnswer = (questionUuid: string, answer: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: CardTypes.CHECK_SECRET_QUESTION_ANSWER_REQUEST })
    try {
      await NeobankApi.getInstance().secretQuestionApi.checkSecretQuestionAnswer(questionUuid, {
        answer: answer,
      })

      dispatch({ type: CardTypes.CHECK_SECRET_QUESTION_ANSWER_SUCCESS, questionUuid, answer })
    } catch (errorAnswer) {
      if (errorAnswer.code === ErrorCodeDto.C1401 || errorAnswer.code === ErrorCodeDto.C1402) {
        try {
          await NeobankApi.getInstance().cardApi
            .addWrongAttempt(
              getState().card.preparedCardOpposition?.card?.uuid!,
              getState().card.preparedCardOpposition?.workflowCardOpposition?.uuid!
            )
        } catch (errorWrongAttempts) {
          dispatch({ type: CardTypes.CHECK_SECRET_QUESTION_ANSWER_FAILURE, alertType: AlertType.WARNING, questionUuid, answer, errorCode: errorAnswer.code, message: errorAnswer.message })
          throw errorWrongAttempts
        }
      }

      const nbTry = getState().card.preparedCardOpposition?.workflowCardOpposition?.nbTry ?? 0
      const warningMessage = i18commons.t('neo-commons:card:secretQuestionsNbTriesRemaining',
        { nbTriesRemaining: 3 - (nbTry + 1) })
      dispatch({ type: CardTypes.CHECK_SECRET_QUESTION_ANSWER_FAILURE, alertType: AlertType.WARNING, questionUuid, answer, errorCode: errorAnswer.code, warningMessage, message: warningMessage })
      throw errorAnswer
    }
  }
}

const validateOpposition = (xValidationOnly?: number) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: CardTypes.VALIDATE_CARD_OPPOSITION_REQUEST })
    try {
      const accountUuid = getState().bankAccount.list!.selectedId
      if (!accountUuid) throw new Error()

      const preparedCardOpposition = getState().card.preparedCardOpposition

      const cardResponse = await NeobankApi.getInstance().cardApi.validateCardOpposition(
        preparedCardOpposition?.card?.uuid!,
        preparedCardOpposition?.workflowCardOpposition?.uuid!,
        xValidationOnly,
        {
          accountUuid: accountUuid,
          reason: preparedCardOpposition?.reason!,
          secretQuestions: preparedCardOpposition!.secretQuestions!.map((secretQuestion) => {
            return {
              secretQuestionUuid: secretQuestion.uuid,
              answer: secretQuestion.answer!,
            }
          }),
        }
      )

      dispatch({ type: CardTypes.VALIDATE_CARD_OPPOSITION_SUCCESS, workflowCardOpposition: cardResponse.data })
    } catch (error) {
      dispatch({ type: CardTypes.VALIDATE_CARD_OPPOSITION_FAILURE, errorMessage: error.message })
      throw error
    }
  }
}

const resetSubList = (listIdentifier: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CardTypes.RESET_SUBLIST, listIdentifier })
  }
}

export const CardActions = {
  createCard,
  createPhysicalCard,
  list,
  update,
  toggleWallet,
  setSelected,
  getLimits,
  updateLimits,
  getCardDetails,
  activate,
  prepare,
  getEncryptedData,
  prepareOpposition,
  checkSecretQuestionAnswer,
  validateOpposition,
  reset,
  resetSubList,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export interface Country {
  isoCodeAlpha3: string,
  isoCodeAlpha2: string,
  name: string
}

export enum OrderUpdateType {
  NONE='NONE',
  CHOICES_DEBIT_CARD='CHOICE_DEBIT_CARD',
  PHYSICAL_PROMO='PHYSICAL_PROMO',
  PIN_CODE='PIN_CODE',
  ADDRESS='ADDRESS',
  DELIVERY_MODE='DELIVERY_MODE',
}
export interface PrepareCardOrder {
  ui: {
    isCodeConfirm: boolean,
    isCgvAccepted: boolean
  }
  offer: OfferDto,
  withPhysicalCard?: boolean,
  cardUuid?: string
  personUuid: string
  isBundle?: boolean,
  keypadDataCardPin?: PasswordKeypadData,
  promoCode?: string,
  deliveryData?: {
    user?: {
      lastname: string,
      firstname: string
    },
    address?: {
      country: Country,
      zipCode: string,
      city: string,
      street: string,
      streetLine2?: string,
    },
    mode?: DeliveryModeDto
  },
  updateType: OrderUpdateType,
}
export interface PrepareSecretQuestion {
  answer?: string
  correct?: boolean,
}
export interface PrepareCardOpposition {
  workflowCardOpposition?: WorkflowCardOppositionDto,
  card?: CardDto,
  reason?: OppositionReasonDto,
  secretQuestions?: (PrepareSecretQuestion & SecretQuestionDto)[],
}

export type CardState = Omit<ResourceState<CreditCardData>, 'create' | 'update'> & { preparedCardOrder?: PrepareCardOrder, preparedCardOpposition?: PrepareCardOpposition }

const initialState: CardState = {
  ...omit(initialResourceState, 'create', 'update'),
  preparedCardOrder: undefined,
  preparedCardOpposition: undefined,
  list: {
    ...getInitialResourceState().list,
    paginationEnded: false,
  },
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const cardSelector = (state: State) => state.card
const defaultSelectors = resourceDefaultSelectors(cardSelector)

export const CardSelectors = {
  ...defaultSelectors,
  preparedCardOrder: createSelector(
    cardSelector,
    resource => resource.preparedCardOrder
  ),
  getUsageLimitByCode: (cardUuid: string, usageLimitCode: UsageLimitCodeDto) => createSelector(
    defaultSelectors.byId(cardUuid),
    (cards: CreditCardData) => find(cards?.limits, limit => limit.usageLimitCode === usageLimitCode)
  ),
  preparedCardOpposition: createSelector(
    cardSelector,
    resource => resource.preparedCardOpposition
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const card: Reducer = (state: CardState = initialState, action: AnyAction): CardState => {
  switch (action.type) {
    case CardTypes.GET_CARD_DETAILS_REQUEST:
    case CardTypes.UPDATE_CARD_REQUEST:
    case CardTypes.UPDATE_CARD_LIMITS_REQUEST:
    case CardTypes.TOGGLE_CARD_WALLET_REQUEST:
    case CardTypes.LIST_CARD_REQUEST:
    case CardTypes.ACTIVATE_CARD_REQUEST:
    case CardTypes.CREATE_CARD_REQUEST:
    case CardTypes.CREATE_OPPOSITION_WORKFLOW_REQUEST:
    case CardTypes.VALIDATE_CARD_OPPOSITION_REQUEST:
    case CardTypes.CHECK_SECRET_QUESTION_ANSWER_REQUEST:
    case CardTypes.GET_ENCRYPTED_CARD_DATA_REQUEST:
      return produce(state, newState => { newState.loading = true })
    case CardTypes.LIST_CARD_SUCCESS:
      if (!action.data) {
        return produce(state, newState => {
          newState.loading = false
          newState.list.loadedOnce = true
        })
      }

      return produce(state, newState => {
        newState.loading = false
        /*
         * We redo a data by taking the list of what we received and overwriting the
         * data we had before for the same uuid This is necessary if we do not want to
         * lose the data of LIST_CARDS_LIMIT and if we want to overload the old data with new
         */
        newState.data = action.data?.reduce((acc, item) => ({
          ...acc,
          [item.uuid]: {
            ...state.data[item.uuid],
            ...item,
          },
        }), {}) ?? {}
        newState.list = {
          ...state.list,
          ids: uniq(action.data?.map(item => item.uuid) ?? []),
          loadedOnce: true,
          shouldRefresh: false,
        }
        if (action.data.length === 1 && newState.list.selectedId !== action.data[0].uuid) {
          newState.list.selectedId = action.data[0].uuid
        }
      })
    case CardTypes.GET_CARD_DETAILS_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        newState.data[action.data.uuid] = action.data
        newState.list.ids = uniq([...(state?.list?.ids ?? []), action.data.uuid])
        newState.list.loadedOnce = true
      })
    case CardTypes.GET_CARD_DETAILS_FAILURE:
    case CardTypes.UPDATE_CARD_FAILURE:
    case CardTypes.UPDATE_CARD_LIMITS_FAILURE:
    case CardTypes.TOGGLE_CARD_WALLET_FAILURE:
    case CardTypes.ACTIVATE_CARD_FAILURE:
    case CardTypes.VALIDATE_CARD_OPPOSITION_FAILURE:
    case CardTypes.CREATE_CARD_FAILURE:
    case CardTypes.CREATE_OPPOSITION_WORKFLOW_FAILURE:
    case CardTypes.CREATE_OPPOSITION_WORKFLOW_SUCCESS:
    case CardTypes.GET_ENCRYPTED_CARD_DATA_FAILURE:
      return produce(state, newState => { newState.loading = false })
    case CardTypes.LIST_CARD_LIMITS_FAILURE:
    case CardTypes.LIST_CARD_FAILURE:
      return produce(state, newState => {
        newState.loading = false
        newState.list.loadedOnce = true
      })
    case CardTypes.CHECK_SECRET_QUESTION_ANSWER_FAILURE:
      if (action.errorCode !== ErrorCodeDto.C1401 && action.errorCode !== ErrorCodeDto.C1402) {
        return produce(state, newState => { newState.loading = false })
      }
      return produce(state, newState => {
        newState.loading = false
        if (newState.preparedCardOpposition) {
          newState.preparedCardOpposition.secretQuestions =
            state.preparedCardOpposition?.secretQuestions?.map((secretQuestion) =>
              (secretQuestion.uuid === action.questionUuid)
                ? { ...secretQuestion, answer: action.answer, correct: false }
                : secretQuestion
            )

          if (newState.preparedCardOpposition.workflowCardOpposition) {
            newState.preparedCardOpposition.workflowCardOpposition.nbTry =
            (state.preparedCardOpposition?.workflowCardOpposition?.nbTry ?? 0) + 1
          }
        }
      })
    case CardTypes.CHECK_SECRET_QUESTION_ANSWER_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        if (newState.preparedCardOpposition) {
          newState.preparedCardOpposition.secretQuestions =
            state.preparedCardOpposition?.secretQuestions?.map((secretQuestion) =>
              (secretQuestion.uuid === action.questionUuid)
                ? { ...secretQuestion, answer: action.answer, correct: true }
                : secretQuestion
            )
        }
      })
    case CardTypes.ACTIVATE_CARD_SUCCESS:
    case CardTypes.UPDATE_CARD_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        // Même problèmatique que pour le LIST_CARD_SUCCESS,
        // on a besoin de garder les datas de LIST_CARD_LIMITS mais on veut surcharger le reste
        newState.data[action.data.uuid] = {
          ...state.data[action.data.uuid],
          ...action.data,
          tokenized: state.data[action.data.uuid].tokenized,
        }
      })
    case CardTypes.SET_SELECTED_CARD:
      return produce(state, newState => { newState.list.selectedId = action.selectedId })
    case CardTypes.UPDATE_CARD_LIMITS_SUCCESS:
    case CardTypes.LIST_CARD_LIMITS_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        newState.data = mapValues(state.data, (value, key) => (key === action.cardUuid)
          ? { ...value, limits: action.data }
          : value
        )
      })
    case CardTypes.CREATE_CARD_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        newState.list.ids = uniq([...state.list.ids, action.data.uuid])
        newState.data[action.data.uuid] = action.data
        newState.list.selectedId = action.data.uuid
      })
    case CardTypes.PREPARE:
      return produce(state, newState => { newState.preparedCardOrder = action.preparedCardOrder })
    case CardTypes.PREPARE_CARD_OPPOSITION:
      return produce(state, newState => { newState.preparedCardOpposition = action.preparedCardOpposition })
    case CardTypes.TOGGLE_CARD_WALLET_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        newState.data = mapValues(state.data, (value, key) => (key === action.cardUuid)
          ? { ...value, tokenized: action.tokenize }
          : value
        )
      })
    case CardTypes.GET_ENCRYPTED_CARD_DATA_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        newState.data[action.cardUuid].encryptedData = action.data
      })
    case CardTypes.VALIDATE_CARD_OPPOSITION_SUCCESS:
      return produce(state, newState => {
        newState.loading = false
        if (newState.preparedCardOpposition) {
          newState.preparedCardOpposition.workflowCardOpposition = action.workflowCardOpposition
        }
      })
    case CardTypes.RESET_SUBLIST:
      return reduceAndRemoveSubList(state, action.listIdentifier)
    case CardTypes.RESET:
      return initialState

    default:
      return state
  }
}
