import React from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { CardDetails, CardDetailsItem } from '@neo-web/componentsOld'

interface DetailTelecollectionInfoProps {
  pending: boolean,
  cleared: boolean,
}

export const DetailTelecollectionInfo: React.FC<DetailTelecollectionInfoProps> = (props) => {
  const { t } = useTranslation()

  const listItems: CardDetailsItem[] = [{
    icon: <Icon name='alert-triangle' type={IconTypes.FEATHER} size={20} color={Colors.secondary} />,
    value: props.pending
      ? t('app:pages:synthesis:telecollections:detail:info:pending')
      : (!props.cleared ? t('app:pages:synthesis:telecollections:detail:info:uncleared') : ''),
    typeface: 'subtitle',
  }]

  return (props.pending || !props.cleared) && (
    <CardDetails
      title={t('app:pages:synthesis:telecollections:detail:info:title')}
      list={listItems}
      justifyBetween
    />
  )
}
