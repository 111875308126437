import { AnyAction, Reducer } from 'redux'
import i18next from 'i18next'
import { merge, isString } from 'lodash'
import { OtpPhoneTypeDto } from '@afone/neo-core-client/dist/models'
import { NeobankApi, ScaCancelled } from '@neo-commons/services'

import { Dispatch } from '../utils/resourceState'
import { State } from '../utils'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type ResetCode = {
  isCodeValid: boolean;
  confirmNewPassword: string[];
  newPassword: string[];
  currentPassword: string[];
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */
const VERIFY_SECRET_QUESTION_REQUEST = 'recoverPasscode/VERIFY_SECRET_QUESTION_REQUEST'
const VERIFY_SECRET_QUESTION_SUCCESS = 'recoverPasscode/VERIFY_SECRET_QUESTION_SUCCESS'
const VERIFY_SECRET_QUESTION_FAILURE = 'recoverPasscode/VERIFY_SECRET_QUESTION_FAILURE'

const TRIGGER_PHONE_VERIFY_REQUEST = 'recoverPasscode/TRIGGER_PHONE_VERIFY_REQUEST'
const TRIGGER_PHONE_VERIFY_SUCCESS = 'recoverPasscode/TRIGGER_PHONE_VERIFY_SUCCESS'
const TRIGGER_PHONE_VERIFY_FAILURE = 'recoverPasscode/TRIGGER_PHONE_VERIFY_FAILURE'

const VERIFY_PHONE_REQUEST = 'recoverPasscode/VERIFY_PHONE_REQUEST'
const VERIFY_PHONE_SUCCESS = 'recoverPasscode/VERIFY_PHONE_SUCCESS'
const VERIFY_PHONE_FAILURE = 'recoverPasscode/VERIFY_PHONE_FAILURE'

const CHANGE_PASSCODE_REQUEST = 'recoverPasscode/CHANGE_PASSCODE_REQUEST'
const CHANGE_PASSCODE_SUCCESS = 'recoverPasscode/CHANGE_PASSCODE_SUCCESS'
const CHANGE_PASSCODE_FAILURE = 'recoverPasscode/CHANGE_PASSCODE_FAILURE'
const CHANGE_PASSCODE_CANCELLED = 'recoverPasscode/CHANGE_PASSCODE_CANCELLED'

const RENEW_PASSCODE_REQUEST = 'recoverPasscode/RENEW_PASSCODE_REQUEST'
const RENEW_PASSCODE_SUCCESS = 'recoverPasscode/RENEW_PASSCODE_SUCCESS'
const RENEW_PASSCODE_FAILURE = 'recoverPasscode/RENEW_PASSCODE_FAILURE'

const RESET = 'recoverPasscode/RESET'
const PREPARE = 'recoverPasscode/PREPARE'

export const RecoverPasscodeTypes = {
  VERIFY_SECRET_QUESTION_REQUEST,
  VERIFY_SECRET_QUESTION_SUCCESS,
  VERIFY_SECRET_QUESTION_FAILURE,

  TRIGGER_PHONE_VERIFY_REQUEST,
  TRIGGER_PHONE_VERIFY_SUCCESS,
  TRIGGER_PHONE_VERIFY_FAILURE,

  VERIFY_PHONE_REQUEST,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_FAILURE,

  RENEW_PASSCODE_REQUEST,
  RENEW_PASSCODE_SUCCESS,
  RENEW_PASSCODE_FAILURE,

  CHANGE_PASSCODE_REQUEST,
  CHANGE_PASSCODE_SUCCESS,
  CHANGE_PASSCODE_FAILURE,
  CHANGE_PASSCODE_CANCELLED,

  PREPARE,
  RESET,
}

const verifyPhoneError = 'errors:verifyPhoneError'
const renewPasswordError = 'errors:renewPasscodeError'

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators
\* %%%%%%%%%%%%%%%%%% */
const prepare = (data: Partial<ResetCode>) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PREPARE, data })
  }
}

const reset = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET })
  }
}

const triggerPhoneVerify = (token: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: TRIGGER_PHONE_VERIFY_REQUEST })

    if (!isString(getState().user.data?.phone?.nationalFormat)) {
      dispatch({ type: TRIGGER_PHONE_VERIFY_FAILURE, errorMessage: i18next.t('errors:triggerPhoneVerifyError') })
      throw new Error(i18next.t('errors:triggerPhoneVerifyError'))
    }

    try {
      const response = await NeobankApi.getInstance().otpPhoneApi.createOtpPhone(
        {
          phoneCountryCode: getState().user.data?.phone?.countryCode,
          phone: getState().user.data?.phone?.nationalFormat!,
          type: OtpPhoneTypeDto.CHANGE_PASSWORD,
          token,
        })
      dispatch({ type: TRIGGER_PHONE_VERIFY_SUCCESS, otpPhoneUuid: response.data.uuid })
    } catch (error) {
      const errorMessage = error.message
      dispatch({ type: TRIGGER_PHONE_VERIFY_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const verifyPhone = function (action: { code: string }) {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { code } = action
    dispatch({ type: VERIFY_PHONE_REQUEST })

    if (!code) {
      dispatch({ type: VERIFY_PHONE_FAILURE, errorMessage: i18next.t('') })
      throw new Error(i18next.t(verifyPhoneError))
    }

    try {
      await NeobankApi.getInstance().otpPhoneApi.updateOtpPhoneByUuid(
        getState().recoverPasscode.data.otpPhoneUuid!, { code })
      dispatch({ type: VERIFY_PHONE_SUCCESS })
    } catch (error) {
      dispatch({ type: VERIFY_PHONE_FAILURE, errorMessage: i18next.t(verifyPhoneError) })
      throw new Error(i18next.t(verifyPhoneError))
    }
  }
}

const renewPasscode = function () {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { currentPassword, newPassword, confirmNewPassword } = getState().recoverPasscode.resetCode
    dispatch({ type: RENEW_PASSCODE_REQUEST })

    if (!newPassword) {
      dispatch({ type: RENEW_PASSCODE_FAILURE, errorMessage: i18next.t(renewPasswordError) })
      throw new Error(i18next.t(renewPasswordError))
    }

    try {
      await NeobankApi.getInstance().userApi.changePassword({
        currentPassword,
        password: newPassword,
        confirmPassword: confirmNewPassword,
      })

      dispatch({ type: RENEW_PASSCODE_SUCCESS })
    } catch (error) {
      const errorMessage = error.message
      dispatch({ type: RENEW_PASSCODE_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const changePassword = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { newPassword, confirmNewPassword, currentPassword } = getState().recoverPasscode.resetCode
    dispatch({ type: CHANGE_PASSCODE_REQUEST })

    if (!newPassword) {
      dispatch({ type: CHANGE_PASSCODE_FAILURE, errorMessage: i18next.t(renewPasswordError) })
      throw new Error(i18next.t(renewPasswordError))
    }

    try {
      const changePasswordResponse = await NeobankApi.getInstance().userApi.changePassword(
        {
          password: newPassword,
          confirmPassword: confirmNewPassword,
          currentPassword,
        },
        1
      )

      if (changePasswordResponse.status === 200) {
        await NeobankApi.getInstance().userApi.changePassword(
          {
            password: newPassword,
            confirmPassword: confirmNewPassword,
            currentPassword,
          },
          0
        )
      }

      dispatch({ type: CHANGE_PASSCODE_SUCCESS })
    } catch (error) {
      const errorMessage = error.message
      if (error instanceof ScaCancelled) {
        dispatch({ type: CHANGE_PASSCODE_CANCELLED })
      } else {
        dispatch({ type: CHANGE_PASSCODE_FAILURE, errorMessage })
      }
      throw new Error(errorMessage)
    }
  }
}

export const RecoverPasscodeActions = {
  triggerPhoneVerify,
  verifyPhone,
  renewPasscode,
  prepare,
  reset,
  changePassword,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export interface RecoverPasscodeState {
  data: {
    otpPhoneUuid: string | null
    isPhoneVerified: false
    isReset: false,
    secretQuestionAnswer: string | null
  },
  resetCode: {
    currentPassword: string[],
    isCodeValid: boolean,
    newPassword: string[],
    confirmNewPassword: string[],
  },
  ui: {
    loading: boolean
  }
}

const initialState: RecoverPasscodeState = {
  data: {
    otpPhoneUuid: null,
    isPhoneVerified: false,
    isReset: false,
    secretQuestionAnswer: null,
  },
  resetCode: {
    isCodeValid: false,
    currentPassword: [],
    newPassword: [],
    confirmNewPassword: [],
  },
  ui: {
    loading: false,
  },
}

export const recoverPasscode: Reducer = (state: RecoverPasscodeState = initialState, action: AnyAction) => {
  switch (action.type) {
    case PREPARE:
      return {
        ...state,
        resetCode: {
          ...state.resetCode,
          ...action.data,
        },
      }
    case VERIFY_SECRET_QUESTION_SUCCESS:
      return merge({}, state, {
        data: {
          secretQuestionAnswer: action.secretQuestionAnswer,
        },
        ui: {
          loading: false,
        },
      })
    case VERIFY_SECRET_QUESTION_FAILURE:
      return merge({}, state, {
        ui: {
          loading: false,
        },
      })
    case VERIFY_SECRET_QUESTION_REQUEST:
    case TRIGGER_PHONE_VERIFY_REQUEST:
    case VERIFY_PHONE_REQUEST:
    case RENEW_PASSCODE_REQUEST:
    case TRIGGER_PHONE_VERIFY_FAILURE:
    case RENEW_PASSCODE_FAILURE:
    case CHANGE_PASSCODE_REQUEST:
      return merge({}, state, {
        ui: {
          loading: true,
        },
      })
    case TRIGGER_PHONE_VERIFY_SUCCESS:
      return merge({}, state, {
        data: {
          otpPhoneUuid: action.otpPhoneUuid,
        },
        ui: {
          loading: false,
        },
      })
    case VERIFY_PHONE_SUCCESS:
      return merge({}, state, {
        data: {
          isPhoneVerified: true,
        },
        ui: {
          loading: false,
        },
      })
    case RENEW_PASSCODE_SUCCESS:
    case CHANGE_PASSCODE_SUCCESS:
      return merge({}, state, {
        data: {
          isReset: true,
        },
        ui: {
          loading: false,
        },
      })
    case VERIFY_PHONE_FAILURE:
    case CHANGE_PASSCODE_FAILURE:
      return merge({}, state, {
        data: {
          isPhoneVerified: false,
        },
        ui: {
          loading: false,
        },
      })
    case RESET:
      return { ...initialState }

    default:
      return state
  }
}
