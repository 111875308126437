import FontAwesomePolice from 'react-native-vector-icons/Fonts/FontAwesome.ttf'
import AntDesignPolice from 'react-native-vector-icons/Fonts/AntDesign.ttf'
import MaterialIconsPolice from 'react-native-vector-icons/Fonts/MaterialIcons.ttf'
import MaterialCommunityIconsPolice from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import FeatherPolice from 'react-native-vector-icons/Fonts/Feather.ttf'
import EntypoPolice from 'react-native-vector-icons/Fonts/Entypo.ttf'

// Generate required css
const iconFontStyles = `
  @font-face {
    src: url(${FontAwesomePolice});
    font-family: FontAwesome;
  }

  @font-face {
    src: url(${AntDesignPolice});
    font-family: AntDesign;
  }

  @font-face {
    src: url(${MaterialIconsPolice});
    font-family: MaterialIcons;
  }

  @font-face {
    src: url(${FeatherPolice});
    font-family: Feather;
  }

  @font-face {
    src: url(${EntypoPolice});
    font-family: Entypo;
  }

  @font-face {
    src: url(${MaterialCommunityIconsPolice});
    font-family: MaterialCommunityIcons;
  }
`
// Create stylesheet
const style = document.createElement('style')
style.setAttribute('type', 'text/css')
style.appendChild(document.createTextNode(iconFontStyles))

// Inject stylesheet
document.head.appendChild(style)
