import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'

import { CreditPolicy } from 'Policies/CreditPolicy'
import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import { BankAccountSelectors, ProvisioningState, ProvisioningTypes } from '@neo-commons/store'

import { LazyLoadingContainer } from '@neo-web/componentsOld'

import { Amount } from './Step1/Amount'
import { ChooseCard } from './Step2/ChooseCard'
import { AddNewCard } from './Step2/AddNewCard'
import { CvvCheck } from './Step3/CvvCheck'
import { Summary } from './Step4/Summary'
import { CreditSuccess } from './Step5/CreditSuccess'
import { CreditFailure } from './Step5/CreditFailure'

import './DebitCard.scss'

interface DebitCardProps {
  initData?: any
  onClose?: () => void
}

export const DebitCard: React.FC<DebitCardProps> = (props: DebitCardProps) => {
  const history = useHistory()

  const creditCardProvisioning: ProvisioningState = useSelector((state: StateTypes) => state.provisioning)
  const bankAccountSelected = useSelector(BankAccountSelectors.selected)
  const creditRedirect = CreditPolicy.creditRedirect(creditCardProvisioning, bankAccountSelected)

  const [amount, setAmount] = useState(0)
  const [message, setMessage] = useState('')

  const stepsContent: { path: string, content: React.ReactNode }[] = [
    {
      path: Routes.Credit.amount.path,
      content: (
        <Amount
          initData={props?.initData}
          nextStep={() => {
            history.push(CreditPolicy.canStep3(creditCardProvisioning) ? Routes.Credit.summary.path
              : CreditPolicy.hasExternalCards() ? Routes.Credit.chooseCard.path : Routes.Credit.addNewCard.path)
          }}
        />
      ),
    },
    {
      path: Routes.Credit.chooseCard.path,
      content: (
        <ChooseCard
          addNewCard={() => history.push(Routes.Credit.addNewCard.path)}
          nextStep={() => history.push(Routes.Credit.cvv.path)}
        />
      ),
    },
    {
      path: Routes.Credit.cvv.path,
      content: (
        <CvvCheck
          nextStep={() => history.push(Routes.Credit.summary.path)}
        />
      ),
    },
    {
      path: Routes.Credit.summary.path,
      content: (
        <Summary
          nextStep={() => {
            setAmount(creditCardProvisioning.prepare.amount)
            props?.initData?.productCode ? props.onClose() : history.push(Routes.Credit.validate.path)
          }}
          stepFailed={(message: string) => {
            setMessage(message)
            history.push(Routes.Credit.failure.path)
          }}
        />
      ),
    },
    {
      path: Routes.Credit.validate.path,
      content: (
        <CreditSuccess amount={amount} />
      ),
    },
    {
      path: Routes.Credit.failure.path,
      content: (
        <CreditFailure message={message} />
      ),
    },
    {
      path: Routes.Credit.addNewCard.path,
      content: (
        <AddNewCard
          nextStep={() => history.push(Routes.Credit.summary.path)}
        />
      ),
    },
  ]

  return (
    <LazyLoadingContainer
      events={[
        ProvisioningTypes.CREDIT_CARD_PROVISIONING_REQUEST,
        ProvisioningTypes.VALIDATE_3DS_TRANSACTION_REQUEST,
      ]}
    >
      <Redirect
        to={{
          pathname: creditRedirect,
        }}
      />
      <div className='DebitCard_container'>
        {stepsContent.map((value, index) => {
          return (
            <Route key={index} exact path={value.path}>
              {value.content}
            </Route>
          )
        })}
      </div>
    </LazyLoadingContainer>
  )
}
