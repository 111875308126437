import { isEmpty } from 'lodash'
import { decode } from 'base-64'

export type DecodedJwt = {
  applicationId?: string
  aud?: string
  authenticationType?: string
  deviceUuid?: string
  exp?: number
  iat?: number
  iss?: string
  jti?: string
  // eslint-disable-next-line camelcase
  preferred_username?: string
  roles?: []
  sub?: string
  personUuid?: string
  subscriptionUuid?: string
  data?: {
    agentClient?: string
  }
}

export class JwtUtils {
  /**
   * Get data of jwt token
   *
   * @returns Return token parsed
   * @example
   * console.log(token)
   * //Input : 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Im1CTEYtSlZpZ1VL......IkpXVCIsImtpZCI'
   * const tokenData = JwtUtils.parseToken(token)
   * console.log(tokenData)
   * //Output : `{"sub": "1234567890","name": "John Doe","iat": 1516239022}`
   *
   * @param token - jwt to be parsed
   */
  public static parseToken = (token: string): DecodedJwt => {
    try {
      if (isEmpty(token)) return {}
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(decode(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))

      return JSON.parse(jsonPayload)
    } catch (e) {
      console.error('Parser error', e)
      return {}
    }
  };

  /**
   * Return timestamp of token remaining
   * @param expireDate - timestamp of date end token
   * @returns time remaining of token expire in milliseconde
   */
  public static expiresIn = (expireDate: number): number => {
    return (expireDate - Math.floor(Date.now() / 1000)) * 1000
  }

  /**
   * Return if token is expired
   * @param expireDate - timestamp of date end token
   * @returns true or false if token is outdated
   */
  public static isExpired = (expireDate: number): boolean => {
    return Math.floor(Date.now() / 1000) >= expireDate
  }
}
