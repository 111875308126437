import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { Button, CardSkin, OfferOptionCard } from '@neo-commons/components'
import {
  BankAccountSelectors,
  CardActions,
  CardSelectors,
  OrderUpdateType,
  PrepareCardOrder,
  Subscription,
  SubscriptionSelectors,
} from '@neo-commons/store'
import {
  CreditCardUtils,
  DeliveryModeDto,
  NumbersUtils,
  OfferUtils,
  OrderType,
  SubscriptionUtils,
} from '@neo-commons/libraries'

import { ProductDto } from '@afone/neo-core-client/dist/models'

import { ConfirmModal, JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

export const DeliveryMode: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()

  const mainSubscription: Subscription = useSelector(SubscriptionSelectors.defaultOne)
  const preparedCardOrder: PrepareCardOrder = useSelector(CardSelectors.preparedCardOrder)
  const deliveryModes = CreditCardUtils.getDeliveryModesFromProducts(preparedCardOrder?.offer?.products)
  const orderedDeliveryModes = OfferUtils.sortDeliveryModeByPrice(deliveryModes ?? undefined, OrderType.ASC)
  const [deliveryMode, setDeliveryMode] = useState<DeliveryModeDto | undefined>(orderedDeliveryModes?.[0])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const selectedBankAccount = useSelector(BankAccountSelectors.selected)

  const cardProduct = (preparedCardOrder?.offer?.products as (ProductDto & {data: {productType?: string}})[])?.filter(product => product?.data?.productType === 'plasticCard')
  const physicCardPrice = useSelector(SubscriptionSelectors.getProductPriceByCode(cardProduct?.[0]?.productCode))

  const handlerValidateButtonClick = async () => {
    await dispatch(CardActions.prepare({
      ...preparedCardOrder,
      updateType: OrderUpdateType.NONE,
      deliveryData: {
        ...preparedCardOrder?.deliveryData,
        mode: {
          data: deliveryMode!.data,
          description: deliveryMode!.description,
          prices: deliveryMode!.prices,
        },
      },
    } as PrepareCardOrder))

    history.push(Routes.Services.address.path)
  }

  const updateDeliveryModel = (deliveryMethod: DeliveryModeDto) => {
    if (selectedBankAccount.balance > (deliveryMethod.prices[0].amount + physicCardPrice) ||
      !SubscriptionUtils.isActive(mainSubscription)) {
      setDeliveryMode(deliveryMethod)
    } else {
      setIsModalOpen(true)
    }
  }

  return (
    <PageDetails
      component={
        <PageContent
          justifyContent={JustifyContent.BETWEEN}
          footer={(
            <Button
              title={t('app:global:validate')}
              onPress={handlerValidateButtonClick}
              disabled={!deliveryMode}
            />
          )}
        >
          {orderedDeliveryModes?.map((delivery) =>
            <OfferOptionCard
              key={delivery.data.deliveryCode}
              subtitle={t(delivery?.description?.description) + '\n' + t(delivery?.description?.term)}
              title={t(delivery.name)}
              selected={delivery.data.deliveryCode === deliveryMode?.data.deliveryCode}
              amountText={delivery.prices[0].amount === 0
                ? t('app:pages:services:debitCard:order:deliveryMode:free')
                : NumbersUtils.displayPriceForHuman(delivery.prices[0].amount)}
              skins={{
                default: CardSkin.DEFAULT,
              }}
              onPress={() => {
                updateDeliveryModel(delivery)
              }}
              disabled={(!delivery.data.authorized) ||
                (!SubscriptionUtils.isActive(mainSubscription) && delivery.prices[0].amount !== 0)}
              withShadow
            />)}

          <ConfirmModal
            onConfirm={() => history.push(Routes.Credit.creditChoices.path, { background: location })}
            onClose={() => setIsModalOpen(false)}
            confirmText={t('neo-commons:debitCard:wrong:deliveryPrice:button')}
            title={t('neo-commons:debitCard:wrong:deliveryPrice:title')}
            description={t(
              'neo-commons:debitCard:wrong:deliveryPrice:content'
            )}
            descriptionAlign='center'
            show={isModalOpen}
          />
        </PageContent>
      }
      onClose={() => history.push(Routes.Services.index.path)}
      onBack={async () => {
        if (!SubscriptionUtils.isActive(mainSubscription)) {
          history.push(Routes.Services.choicesDebitCard.path)
        } else {
          await dispatch(CardActions.prepare({
            ...preparedCardOrder,
            updateType: OrderUpdateType.PIN_CODE,
            keypadDataCardPin: undefined,
          }))
          history.push(Routes.Services.code.path)
        }
      }}
      title={t('app:pages:services:debitCard:order:deliveryMode:deliveryMethod')}
    />
  )
}
