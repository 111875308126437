import React from 'react'
import { Provider } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { PersistGate } from 'redux-persist/integration/react'
import { config, ENV } from 'Config/EnvConfig'
import { Routes } from 'Routes/Routes'
import { Maintenance } from 'UI/Pages/Maintenance/Maintenance'

import { CreditCardUtilsWeb } from '@neo-web/utils'
import { Loader } from '@neo-web/componentsOld'

import { store, persistor } from './Store'
import { RootContainer } from './UI/RootContainer/RootContainer'
import history from './Services/HistoryService'
import i18n from './I18n/i18n'

import './Config/Lokalise'
import './UI/Resources/Fonts/Fonts'
import 'rsuite/dist/rsuite.min.css'

export const App: React.FC = () => {
  CreditCardUtilsWeb.initRsaKeyPair()

  return (
    <div className='App'>
      <Provider store={store}>
        <GoogleReCaptchaProvider
          useRecaptchaNet
          language={i18n.language}
          reCaptchaKey={config(ENV.RECAPTCHA_KEY)}
          scriptProps={{ async: true, defer: true, appendTo: 'body' }}
        >
          <I18nextProvider i18n={i18n}>
            <PersistGate loading={<Loader isShow />} persistor={persistor}>
              <Router history={history}>
                <Switch>
                  <Route path={Routes.Maintenance.path} component={Maintenance} />
                  <Route path='*' component={RootContainer} />
                </Switch>
              </Router>
            </PersistGate>
          </I18nextProvider>
        </GoogleReCaptchaProvider>
      </Provider>
    </div>
  )
}

export default App
