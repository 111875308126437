import {
  AffiliationTypeDto,
  CardDto,
  CardLimitDto,
  CardStatusDto,
  ClientDto,
  OfferDto,
  PersonAffiliationDto,
  PersonTypeDto,
  PlasticStatusDto,
  ProductDto,
  SchemeDto,
  UsageLimitCodeDto,
} from '@afone/neo-core-client/dist/models'
import { i18commons } from '@neo-commons/i18n'
import { CreditCardData } from '@neo-commons/store'
import dayjs, { Dayjs } from 'dayjs'

import { NumbersUtils } from './NumbersUtils'
import { Formatters } from './Formatters'

export interface DeliveryModeData {
  authorized?: boolean,
  sla: string,
  deliveryCode: string,
  productType: string,
}
export interface DeliveryModeDescription {
  title: string,
  description: string,
  term: string
}
export interface DeliveryModeDto extends Omit<ProductDto, 'data' | 'description'> {
  data: DeliveryModeData,
  description: DeliveryModeDescription,
}

export enum ComnpaySchemeType {
  CB = 'CB',
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD'
}

export interface BinData {
  bin: number,
  countryCode: string,
  scheme: ComnpaySchemeType
  pro?: boolean,
  countryLabel: string
}

export enum CardUpdateType {
  BLOCK,
  CONTACTLESS,
  PIN,
}

export enum CardType {
  CLASSIC_DIGITAL = 'CLASSIC_DIGITAL',
  CLASSIC_PHYSICAL = 'CLASSIC_PHYSICAL',

  PREMIUM_DIGITAL = 'PREMIUM_DIGITAL',
  PREMIUM_PHYSICAL = 'PREMIUM_PHYSICAL',

  AMBASSADOR_DIGITAL = 'AMBASSADOR_DIGITAL',
  AMBASSADOR_PHYSICAL = 'AMBASSADOR_PHYSICAL',

  BUSINESS_DIGITAL = 'BUSINESS_DIGITAL',
  BUSINESS_PHYSICAL = 'BUSINESS_PHYSICAL',

  WORLD_BUSINESS_DIGITAL = 'WORLD_BUSINESS_DIGITAL',
  WORLD_BUSINESS_PHYSICAL = 'WORLD_BUSINESS_PHYSICAL',
}

export interface SchemeInfos {
  schemesChoices: SchemeDto,
  secondaryScheme: SchemeDto,
  isMultipleChoice?: boolean
}

export interface SensitiveCardData {
  uuid: string,
  status: 'ACTIVE' | 'DO_NOT_HONOUR' | 'DESACTIVATED',
  ebamAccountId: string,
  mptsCardId: string,
  expirationDate: string,
  cvv: string,
  pan: string,
  pin: string,
  plasticStatus: 'ACTIVATED' | 'ORDERED' | 'DEACTIVATED',
  formatedEmbossedData: {
    firstLine: string,
    secondLigne?: string
  }
}

export interface CardOfferDescription {
  title?: string,
  text?: string,
  reasonForNotAuthorized?: string
}

export interface ProvisioningFees {
  individualAmountFees?: number,
  proPercentageFees?: number
}

/**
 * Card image.
 */
export interface CardOfferImages {
  physical: CardType,
  digital: CardType
}

/**
 * Utils for card management
 */
export class CreditCardUtils {
  // Size of RSA Key.
  static RSA_KEY_SIZE = 4096

  /**
   * Return the correct success message according to the update type
   * @param card - Updated card.
   * @param cardUpdateType - Update type.
   */
  public static getUpdateSuccessMessage = (card: CardDto, cardUpdateType: CardUpdateType) => {
    switch (cardUpdateType) {
      case CardUpdateType.BLOCK:
        return card.status === CardStatusDto.DO_NOT_HONOUR ? i18commons.t('neo-commons:card:yourCardIsTemporarilyBlocked')
          : i18commons.t('neo-commons:card:yourCardIsUnblocked')
      case CardUpdateType.CONTACTLESS:
        return card.contactLess ? i18commons.t('neo-commons:card:contactlessPaymentActivated')
          : i18commons.t('neo-commons:card:contactlessPaymentDeactivated')
      default:
        return ''
    }
  }

  /**
   * Return delivery modes products.
   * @param products Products list.
   * @returns Delivery modes list.
   */
  public static getDeliveryModesFromProducts = (products: ProductDto[]): DeliveryModeDto[] | undefined => {
    if (!products) return
    const deliveryProducts = products.filter(product => (product as {data: DeliveryModeData})?.data?.productType === 'deliveryMode')
    return deliveryProducts.map(deliveryProduct => {
      return {
        ...deliveryProduct,
        data: deliveryProduct.data,
        description: typeof deliveryProduct?.description?.[0] === 'string'
          ? JSON.parse(deliveryProduct?.description?.[0] ?? '{}') : deliveryProduct?.description?.[0],
      }
    }) as DeliveryModeDto[]
  }

  /**
   * Return monthly price.
   * @param products Products list.
   * @returns Monthly price.
   */
  public static getMonthlyPrice = (products: ProductDto[]): number => {
    const monthlyPrice = products?.filter(product => ['bundle', 'digitalCard'].includes((product?.data as DeliveryModeData).productType))
    return monthlyPrice?.[0]?.prices?.[0]?.amount ?? 0
  }

  /**
   * Convert sensitive card data string to object.
   * @param decryptedData - The decrypted card data.
   */
  public static decryptedToObject (decryptedData?: string | boolean): SensitiveCardData | undefined {
    if (!decryptedData) {
      return undefined
    }
    try {
      const jsonResponse: SensitiveCardData = JSON.parse(decryptedData as string)
      return {
        ...jsonResponse,
        pan: Formatters.formatCreditCardNumbers(jsonResponse.pan),
        expirationDate: Formatters.formatCreditCardDate(jsonResponse.expirationDate, true),
      }
    } catch (error) {
      return undefined
    }
  }

  /**
   * Convert description string to object.
   * @param description - Description text.
   */
  public static getDescriptionObject = (description: string): CardOfferDescription | undefined => {
    try {
      return JSON.parse(description)
    } catch (e) {
      return undefined
    }
  }

  /**
   * Checks if the card has been ordered and since more than 72hrs
   * @param card - card selected
   * @returns wether a card can be activated
   */
  public static canActivate (card: CardDto) {
    return this.checkOrderDateAndPlasticStatus(card) && !this.isTemporaryBlocked(card)
  }

  /**
   * Checks if the paymentCards is temporary blocked
   * @param card - card selected
   * @returns boolean of card it is blocked
   */
  public static isTemporaryBlocked (card: CardDto): boolean {
    return card?.status === CardStatusDto.DO_NOT_HONOUR
  }

  /**
   * Checks if the card has been ordered and since less than 72hrs
   * @param card - card selected
   * @returns wether a card was ordered since less than 72hrs
   */
  public static isOrderInProgress (card: CardDto) {
    return this.checkOrderDateAndPlasticStatus(card, { lessThanThreeDays: true })
  }

  /**
   * Checks if the card has been ordered and since less or more than 72hrs
   * @param card - card selected
   * @param payload - optional param, lessThanThreeDays attribute switches the condition return
   * @returns wether a card can be activated or not after a specific delay
   */
  private static checkOrderDateAndPlasticStatus (card: CardDto, payload?: { lessThanThreeDays: boolean }) {
    const plasticOrderDate = dayjs(card?.plasticInfo?.plasticOrderDate)
    const today = dayjs()
    const diffHours = today.diff(plasticOrderDate, 'hours')
    return (payload?.lessThanThreeDays ? diffHours < 72 : diffHours >= 72) &&
    card?.plasticInfo?.plasticStatus === PlasticStatusDto.ORDERED
  }

  /**
   * Uppercases each word first letter in the parameter string
   * @param fullName - firstline of the embossed data, representing the full name of the card owner
   * @returns full name with the first letter of each word capitalized
   */
  public static formatFullName (fullName: string) {
    const splitStr = fullName.toLowerCase().split(' ')
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
  }

  /**
   * @param truncatedPan - truncated pan of the card
   * @returns formatted truncated pan : **** 1234
   */
  public static formatTruncatedPan (truncatedPan: string) {
    return '**** ' + truncatedPan.substr(truncatedPan.length - 4, truncatedPan.length)
  }

  /**
   * To convert Comnpay scheme type to neo-core-client scheme type.
   * @param scheme Comnpay scheme type.
   * @param multiChoice True to return with mutiple choice.
   * @returns The neo-core-client scheme type.
   */
  public static convertComnpaySchemeTypeToNoelseFormat (scheme: ComnpaySchemeType, multiChoice?: boolean): SchemeDto {
    switch (scheme) {
      case ComnpaySchemeType.MASTERCARD:
        return multiChoice ? SchemeDto.CB_MC : SchemeDto.MC
      case ComnpaySchemeType.VISA:
        return multiChoice ? SchemeDto.CB_VISA : SchemeDto.VISA
      default:
        return SchemeDto.CB
    }
  }

  /**
   * To get the provisioning fees from offer socle.
   * @param socleOffer The user socle offer.
   * @returns The provisioning fees.
   */
  public static getProvisioningFeesFromSocleOffer (socleOffer: OfferDto): ProvisioningFees {
    const products = socleOffer?.products
    const individualPrices = products?.filter(product => product.productCode === 'NEO-PRD-CB-IN')?.[0]?.prices
    const individualFees = individualPrices?.filter(price => price.priceCode === 'NEO-PRI-CB-IN')?.[0]

    const proPrices = products?.filter(product => product.productCode === 'NEO-PRD-CB-IN-PRO')?.[0]?.prices
    const proFees = proPrices?.filter(price => price.priceCode === 'NEO-PRI-CB-IN-PRO')?.[0]

    return { individualAmountFees: individualFees?.amount, proPercentageFees: (proFees?.percentage ?? 0) * 100 }
  }

  /**
   * To retourne name by scheme type.
   * @param type Scheme type.
   * @returns Name of scheme type.
   */
  public static getSchemeNameByType (type: SchemeDto): string {
    switch (type) {
      case SchemeDto.VISA:
        return 'Visa'
      case SchemeDto.MC:
        return 'Mastercard'
      default:
        return 'CB'
    }
  }

  /**
   * Return true if is a multiple scheme type.
   * @param type Scheme type.
   * @returns True if is a multiple scheme type.
   */
  public static isMultipleSchemeChoice (scheme: SchemeDto): boolean {
    return scheme in [SchemeDto.CB_MC, SchemeDto.CB_VISA]
  }

  /**
   * To get scheme infos.
   * @param scheme The scheme.
   * @returns The scheme infos.
   */
  public static extractSchemeInfosToNeoCoreScheme (scheme: SchemeDto): SchemeInfos {
    switch (scheme) {
      case SchemeDto.CB_VISA:
        return {
          isMultipleChoice: true,
          secondaryScheme: SchemeDto.VISA,
          schemesChoices: scheme,
        }
      case SchemeDto.CB_MC:
        return {
          isMultipleChoice: true,
          secondaryScheme: SchemeDto.MC,
          schemesChoices: scheme,
        }
      case SchemeDto.VISA:
      case SchemeDto.CB:
      case SchemeDto.MC:
        return {
          secondaryScheme: scheme,
          schemesChoices: scheme,
        }
    }
  }

  /**
   * To convert comnpay bins to neo core scheme type.
   * @param bins Comnpay bins.
   * @returns The extracted scheme data from bin.
   */
  public static convertBinsDataToNeoCoreScheme (bins: BinData[]): SchemeDto {
    if (bins.length > 1) {
      const secondaryScheme = bins.filter(bin => bin.scheme !== ComnpaySchemeType.CB)[0].scheme
      if (secondaryScheme === ComnpaySchemeType.MASTERCARD) {
        return SchemeDto.CB_MC
      }
      return SchemeDto.CB_VISA
    } else {
      const scheme = bins?.[0]?.scheme
      if (scheme === ComnpaySchemeType.MASTERCARD) {
        return SchemeDto.MC
      }
      return SchemeDto.VISA
    }
  }

  /**
   * Return delivery days by delivery code.
   * @param code Delivery code.
   * @returns Number of days for delivery.
   */
  public static getDeliveryDaysByDeliveryCode (code?: string): number {
    switch (code) {
      case 'GMM':
        return 10
      case 'GMR':
        return 6
      case 'DHL':
        return 3
    }
    return 0
  }

  /**
   * Return delivery business days date.
   * @param card Card data.
   * @returns Business days date.
   */
  public static getDeliveryDate (card: CardDto): Dayjs {
    const deliveryDaysCount = this.getDeliveryDaysByDeliveryCode(card?.plasticInfo?.deliveryCode)
    const plasticOrderDate = dayjs(card?.plasticInfo?.plasticOrderDate)
    // @ts-ignore
    return plasticOrderDate.businessDaysAdd(deliveryDaysCount)
  }

  /**
   * Return true if the credit card is blocked.
   * @param card Card data.
   * @returns True if the credit card is blocked.
   */
  public static isBlocked (card: Required<CardDto>): boolean {
    return ![CardStatusDto.ACTIVE, CardStatusDto.ACTIVE_NO_RENEWAL].includes(card?.status as any)
  }

  /**
   * Affiliation type that can order a card
   */
  public static getAffiliatesThatCanOrderCard (affiliates: PersonAffiliationDto[]): PersonAffiliationDto[] {
    return affiliates.filter(
      // Check if at least one affiliates is one of any of these types
      affiliate => [
        AffiliationTypeDto.ULTIMATE_BENEFICIAL_OWNER,
        AffiliationTypeDto.LEGAL_REPRESENTATIVE,
        AffiliationTypeDto.TREASURER,
        AffiliationTypeDto.PRESIDENT,
        AffiliationTypeDto.SECRETARY,
      ].some(type => affiliate.types?.includes(type))
    ) || []
  }

  /**
   * Returns a Person.uuid if only 1 potential card owner is found
   * @param client
   * @returns a Person.uuid
   */
  public static getDefaultCardOwnerUuid (client: ClientDto): string|undefined {
    let cardOwnerUuid: string|undefined
    // Un part' ou un pro qui est seul représentant ne peut avoir de carte qu'à son nom donc on sait qui est l'owner
    if (client?.holder?.type === PersonTypeDto.PHYSICAL) {
      cardOwnerUuid = client?.holder?.uuid
    } else {
      const legalRepresentatives = client?.holder?.affiliates
        ? this.getAffiliatesThatCanOrderCard(client?.holder?.affiliates)
        : []
      cardOwnerUuid = (legalRepresentatives.length === 1 ? legalRepresentatives[0].person?.uuid : undefined)
    }
    return cardOwnerUuid
  }

  /**
   * Returns the object of all card limits for the given card and limit code
   * @param selectedCard
   * @param limitCode
   * @returns a CardLimitDto
   */
  public static getCardLimit (selectedCard: CreditCardData, limitCode: UsageLimitCodeDto): CardLimitDto | undefined {
    return selectedCard?.limits?.find(limit => limit.usageLimitCode === limitCode)
  }

  /**
   * Returns the formatted amount of max usage limit for the given card
   * @param selectedCard
   * @param limitCode
   * @returns a string
   */
  public static getMaxUsage (selectedCard: CreditCardData, limitCode: UsageLimitCodeDto): string {
    const limit = this.getCardLimit(selectedCard, limitCode)?.maxUsage?.amount
    return NumbersUtils.displayPriceForHuman(limit, undefined, false)
  }
}
