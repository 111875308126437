import i18next from 'i18next'
import { AccountDto, AccountTypeDto } from '@afone/neo-core-client/dist/models'

import { EnumUtils } from './EnumUtils'

const BankAccountIconNames = {
  audiotrack: 'audiotrack',
  balance: 'account-balance',
  beach: 'beach-access',
  birthday: 'cake',
  brush: 'brush',
  building: 'business',
  business: 'business-center',
  camera: 'camera-alt',
  car: 'directions-car',
  cloud: 'cloud',
  device: 'important-devices',
  dollar: 'attach-money',
  draft: 'drafts',
  earth: 'public',
  eat: 'local-dining',
  euro: 'euro',
  fitness: 'fitness-center',
  heart: 'favorite',
  hospital: 'local-hospital',
  lightbulb: 'lightbulb',
  lock: 'lock',
  market: 'local-grocery-store',
  persons: 'people',
  plane: 'flight',
  shipping: 'local-shipping',
  shop: 'shopping-bag',
  snow: 'ac-unit',
  sofa: 'weekend',
  star: 'star',
  sun: 'wb-sunny',
  van: 'airport-shuttle',
}

/**
 * some methods for bankAccount
 *
 */
export class BankAccountUtils {
  /**
   * Check if main bankAccount
   *
   * @param accountDto - bankAccount
   * @returns boolean - true if main account
   *
   */
  public static isMain (accountDto: AccountDto): boolean {
    return Boolean(accountDto?.type === AccountTypeDto.MAIN)
  }

  public static isProspectMain (accountDto: AccountDto): boolean {
    return Boolean(accountDto?.uuid === 'prospect-account')
  }

  public static isProspectProject (accountDto: AccountDto): boolean {
    return Boolean(accountDto?.uuid === 'prospect-project')
  }

  public static isProspectAccount (accountDto: AccountDto): boolean {
    return Boolean(
      this.isProspectMain(accountDto) ||
      this.isProspectProject(accountDto)
    )
  }

  /**
   * Check if secondary bankAccount
   *
   * @param accountDto - bankAccount
   * @returns boolean - true if secondary account
   *
   */
  public static isSecondary (accountDto: AccountDto): boolean {
    return Boolean(accountDto?.parentUuid)
  }

  /**
   * Check if project bankAccount
   *
   * @param accountDto - bankAccount
   * @returns boolean - true if project account
   *
   */
  public static isProject (accountDto: AccountDto): boolean {
    return Boolean(accountDto?.type === AccountTypeDto.PROJECT)
  }

  /**
   * Check if Aggregated bankAccount
   *
   * @param accountDto - bankAccount
   * @returns boolean - true if Aggregated
   *
   */
  public static isAggregated (accountDto: AccountDto): boolean {
    return Boolean(accountDto?.type === AccountTypeDto.AGGREGATED)
  }

  /**
   * Check if Pos bankAccount
   *
   * @param accountDto - bankAccount
   * @returns boolean - true if POS account
   *
   */
  public static isPos (accountDto: AccountDto): boolean {
    return Boolean(accountDto?.type === AccountTypeDto.POS)
  }

  /**
   * Check if Main or aggregated bankAccount
   *
   * @param accountDto - bankAccount
   * @returns boolean - true if main or aggregated account
   *
   */
  public static isMainOrAggregated (accountDto: AccountDto): boolean {
    return Boolean(accountDto?.type === AccountTypeDto.MAIN || accountDto?.type === AccountTypeDto.AGGREGATED)
  }

  /**
   * Check if Secondary or aggregated bankAccount
   *
   * @param accountDto - bankAccount
   * @returns boolean - true if secondary or aggregated account
   *
   */
  public static isSecondaryOrAggregated (accountDto: AccountDto): boolean {
    return Boolean(accountDto?.parentUuid || accountDto?.type === AccountTypeDto.AGGREGATED)
  }

  /**
   * get BankAccount name
   *
   * @param accountDto - bankAccount
   * @returns string - bank account name
   *
   */
  public static getName (accountDto: AccountDto): string | undefined {
    return accountDto?.name ?? i18next.t(EnumUtils.getAccountTypeDtoKey(accountDto?.type))
  }

  /**
   * get Front icon string from Back icon string
   *
   * @param backIconName - icon name from backend
   * @returns  string - Front icon string
   */
  public static getFrontIconString (backIconName: string): string {
    return BankAccountIconNames[backIconName] ?? 'euro'
  }

  /**
   * truncate iban to keep the 8 first and 3 last digits
   *
   * @param iban - iban
   * @returns  string - truncated iban (ex: FR00 0000 ... 000)
   */
  public static truncateIban (iban: string): string {
    return iban.slice(0, 9).concat(' ... ').concat(iban.slice(iban.length - 3, iban.length))
  }
}
