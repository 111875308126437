import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { PosActions, PosSelectors, PosTypes } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { PendingDispatchStatuses } from '@neo-commons/libraries'

import { PosDispatchDto, PosDto, PosReturnReasonDto } from '@afone/neo-core-client/dist/models'

import {
  CardMenuItem,
  CardMenuList,
  JustifyContent,
  LazyLoadingContainer,
  Modal,
  PageContent,
} from '@neo-web/componentsOld'

import { PosReturnAlert } from '../Components/PosAlerts/PosReturnAlert'

export const PosMainAccount: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const pos: PosDto[] = useSelector(PosSelectors.listPos())
  const posState = useSelector((state: StateTypes) => state?.pos)
  const dispatches: PosDispatchDto[] = useSelector(PosSelectors.listDispatches) ?? []

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        const locationState: any = location?.state
        if (locationState?.returnReason && locationState?.returnReason === PosReturnReasonDto.FAILURE) {
          setShowModal(true)
        }
        if (!dispatches.length) {
          await dispatch(PosActions.listDispatches())
        }
      } catch (error) {}
    })()
  }, [])

  const orderPosModal = () =>
    <Modal
      open={showModal}
      displayCross
      onClose={() => setShowModal(false)}
      title={t('neo-commons:pages:services:pos:return:dispatch:modalOrder:title')}
      titleIcon={<Icon color={Colors.secondary} name='calculator' size={40} type={IconTypes.NEOFONT} />}
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          containerStyle={{ marginTop: 12 }}
          title={t('neo-commons:pages:services:pos:return:dispatch:modalOrder:button')}
          onPress={() => history.push(Routes.Services.PosOrderRoutes.index.path)}
        />
      }
    >
      <Typography typeface='content'>
        {t('neo-commons:pages:services:pos:return:dispatch:modalOrder:text')}
      </Typography>
    </Modal>

  const mainAccountContent = () => {
    if (pos.length) {
      const pendingDispatch = dispatches.find(d => PendingDispatchStatuses.includes(d.status))

      const menuCards: CardMenuItem[] = [
        {
          icon: <Icon name='plus-circle' color={Colors.primary} size={24} type={IconTypes.FEATHER} />,
          title: t('app:pages:services:pos:orderPos:cardTitle'),
          onPress: () => history.push(Routes.Services.PosOrderRoutes.index.path),
          separation: true,
        }, {
          icon: <Icon name='tpe' color={Colors.primary} size={24} type={IconTypes.NEOFONT} />,
          title: t('app:pages:services:pos:showDetail:cardTitle'),
          onPress: () => history.push(Routes.Services.detailPosList.path),
        }, {
          icon: <Icon name='settings' color={Colors.primary} size={24} type={IconTypes.FEATHER} />,
          title: t('app:pages:services:pos:managePos:cardTitle'),
          onPress: () => history.push(Routes.Services.managePos.path),
        }, {
          title: t('app:pages:services:project:listProjects:closeProject'),
          onPress: () => history.push(Routes.Services.closePosAccount.path),
        },
      ]

      return (
        <PageContent
          justifyContent={JustifyContent.START}
          header={!!pendingDispatch && <PosReturnAlert dispatches={dispatches} />}
        >
          {orderPosModal()}
          <CardMenuList menus={menuCards} />
        </PageContent>
      )
    } else if (!posState.loading) {
      return (
        <PageContent
          icon={<Icon type={IconTypes.NEOFONT} name='tpe' color={Colors.secondary} size={50} />}
          title={t('app:pages:services:pos:noPos:title')}
          button={(
            <Button
              skin={ButtonSkin.PRIMARY}
              containerStyle={{ width: '100%' }}
              title={t('app:pages:services:pos:noPos:button')}
              onPress={() => history.push(Routes.Services.PosOrderRoutes.index.path)}
            />
          )}
        >
          <div className='text-center'>
            <Typography typeface='content'>
              {t('app:pages:services:pos:noPos:text1')}
            </Typography><br />
            <Typography typeface='content'>
              {t('app:pages:services:pos:noPos:text2')}
            </Typography>
          </div>
        </PageContent>
      )
    }
  }

  return (
    <LazyLoadingContainer events={[PosTypes.LIST_POS_REQUEST, PosTypes.LIST_DISPATCHES_REQUEST]}>
      {mainAccountContent()}
    </LazyLoadingContainer>
  )
}
