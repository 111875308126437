import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'

import { Drawer } from 'rsuite'
import { PaymentsRoutes } from 'Routes/PaymentsRoutes'
import { StateTypes } from 'Store'
import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { BankAccountUtils, ClientUtils, SubscriptionUtils } from '@neo-commons/libraries'
import {
  BankAccountActions,
  BankAccountSelectors,
  BankTransferBenificiaryTypeEnum,
  BankTransferTypeEnum,
  CardActions,
  ClientSelectors,
  Dispatch,
  SubscriptionSelectors,
  TransferActions,
} from '@neo-commons/store'

import { AccountDto, AccountTypeDto, ClientDto, PersonTypeDto } from '@afone/neo-core-client/dist/models'

import { useResponsive } from '@neo-web/hooks'
import { BankAccountPreview } from '@neo-web/componentsOld'

import './BankAccountsList.scss'

export const BankAccountsList: React.FC = () => {
  const dispatch: Dispatch = useDispatch<Dispatch>()
  const history = useHistory()
  const { t } = useTranslation()

  const isTabletOrMobile = useResponsive()
  const bankAccounts: AccountDto[] =
    useSelector(BankAccountSelectors.listAccountsNavbar()).filter(account => !account?.disabled)
  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selected)
  const listSecondaryAccounts: AccountDto[] =
    useSelector(BankAccountSelectors.getSubAccountsByUuid(selectedBankAccount?.uuid))
  const listMainAccounts: AccountDto[] =
    useSelector(BankAccountSelectors.listMain).filter(account => !account?.disabled)
  const selectedMain: AccountDto = useSelector(BankAccountSelectors.selectedMain)
  const bankAccountState = useSelector((state: StateTypes) => state?.bankAccount)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne, (p, c) => p?.uuid === c?.uuid)
  const numberOfMainAccountNotAggregated = listMainAccounts.filter(account => BankAccountUtils.isMain(account))?.length

  const socleSubscription = useSelector(SubscriptionSelectors.defaultOneSocle)

  const transferState = useSelector((state: StateTypes) => state?.transfer)
  const onGoingTransfer = transferState.prepare

  const [
    showAccountListInResponsive,
    setShowAccountListInResponsive,
  ] = useState(false)

  const showAccountList = (show: boolean) => {
    setShowAccountListInResponsive(show)
    if (show) {
      setTimeout(() => {
        // Le chainage optionnel est utilisé pour s'assurer qu'on ne génère pas d'erreur.
        // eslint considère donc cette ligne comme une expression non utilisée
        // eslint-disable-next-line no-unused-expressions
        document
          .querySelector('.rs-drawer-wrapper')
          ?.classList?.add('BankAccountsList_drawer')
      }, 10)
    }
  }

  const displayBankAccounts = () => {
    return (
      <BankAccountPreview
        selectedMain={selectedMain}
        selectedBankAccount={selectedBankAccount}
        listSecondaryAccounts={listSecondaryAccounts}
        listMainAccounts={listMainAccounts}
        listBankAccounts={bankAccounts}
        onIbanClick={() => {
          history.push(generatePath(Routes.BankAccount.detail.path, { id: selectedBankAccount.uuid }))
        }}
        onTransferClick={async () => {
          if (BankAccountUtils.isProject(selectedBankAccount)) {
            await dispatch(BankAccountActions.setSelected(selectedBankAccount.parentUuid))
            history.push(PaymentsRoutes.bankTransfer.path)
          }
        }}
        onCollectClick={async () => {
          if (BankAccountUtils.isPos(selectedBankAccount)) {
            const privateLabel = t('neo-commons:transfer:default:incoming',
              { recipientAccountName: selectedMain.name })
            const recipientLabel = t('neo-commons:transfer:default:label',
              client.holder.type === PersonTypeDto.PHYSICAL
                ? { lastName: client.holder.lastName, firstName: client.holder.firstName }
                : { lastName: client.holder.legalName })

            await dispatch(
              TransferActions.prepare({
                ...onGoingTransfer,
                accountUuid: selectedBankAccount?.uuid,
                transferType: BankTransferTypeEnum.Punctual,
                benificiaryType: BankTransferBenificiaryTypeEnum.Internal,
                recipientAccountUuid: selectedMain.uuid,
                recipientAccountName: selectedMain.name,
                amount: selectedBankAccount?.balance,
                label: recipientLabel,
                privateLabel: privateLabel,
              }),
            )
            await dispatch(BankAccountActions.setSelected(selectedBankAccount.parentUuid))
            history.push(PaymentsRoutes.summary.path)
          }
        }}
      />
    )
  }

  useEffect(() => {
    const prospectAccountName = 'prospect-account'
    if (!socleSubscription ||
      (SubscriptionUtils.isSubscribing(socleSubscription) &&
        (SubscriptionUtils.isKycStepInProgress(socleSubscription) ||
          SubscriptionUtils.isKycStepAnalysing(socleSubscription)))
    ) {
      if (!bankAccountState.list.ids.find((id) => id === prospectAccountName)) {
        const prospectAccounts = [
          { uuid: prospectAccountName, name: 'Compte principal', type: AccountTypeDto.MAIN },
          { uuid: 'prospect-project', name: 'Compte projet', type: AccountTypeDto.PROJECT },
        ]
        prospectAccounts.forEach((account) => {
          dispatch(BankAccountActions.createProspectAccount(account))
        })
      }
      dispatch(BankAccountActions.setSelected(prospectAccountName))
    } else if (SubscriptionUtils.isSignatureOk(socleSubscription) && SubscriptionUtils.isKycStepOk(socleSubscription) &&
      bankAccountState.list.ids.find((id) => id === prospectAccountName)) {
      // remove prospect account and get accounts
      dispatch(BankAccountActions.reset()).then(() => {
        dispatch(BankAccountActions.list())
      })
    }
  }, [])

  useEffect(() => {
    if (SubscriptionUtils.isSubscribing(socleSubscription) && SubscriptionUtils.isKycStepOk(socleSubscription) &&
      (!bankAccounts || bankAccounts?.length === 0)) {
      ClientUtils.isClientTypeCorporate(client?.type)
        ? history.push(Routes.Subscription.Business.index.path)
        : history.push(Routes.Subscription.Individual.index.path)
    }
  }, [])

  useEffect(() => {
    (async () => {
      if (!selectedBankAccount && bankAccounts.length) {
        try {
          await dispatch(BankAccountActions.setSelected(bankAccounts[0].uuid))
        } catch (error) {}
      }
    })()
  }, [bankAccounts, dispatch, selectedBankAccount])

  useEffect(() => {
    (async () => {
      if (!bankAccountState.loading) {
        if (!listMainAccounts.length) {
          try {
            await dispatch(BankAccountActions.list({
              pageSize: 100,
              bankAccountType: AccountTypeDto.MAIN,
            })).then(() => dispatch(CardActions.list()))
          } catch (error) {}
        } else if (!listSecondaryAccounts.length) {
          await dispatch(BankAccountActions.list({
            bankAccountType: AccountTypeDto.AGGREGATED,
          }))
          for (const mainAccount of listMainAccounts.filter(el => el.type === AccountTypeDto.MAIN)) {
            try {
              await dispatch(BankAccountActions.list({
                bankAccountType: AccountTypeDto.PROJECT,
                parentUuid: mainAccount.uuid,
              }))
              if (ClientUtils.isClientTypeCorporate(client?.type)) {
                await dispatch(BankAccountActions.list({
                  bankAccountType: AccountTypeDto.POS,
                  parentUuid: mainAccount.uuid,
                }))
              }
            } catch (error) {}
          }
        }
      }
    })()
  }, [numberOfMainAccountNotAggregated])

  return (
    bankAccounts && bankAccounts.length > 0 && (
      <>
        {isTabletOrMobile
          ? (
            <>
              <BankAccountPreview
                listBankAccounts={bankAccounts}
                selectedBankAccount={selectedBankAccount}
                onIbanClick={() => {
                  history.push(generatePath(Routes.BankAccount.detail.path, { id: selectedBankAccount.uuid }))
                }}
                displayTop
              />
              <Button
                skin={ButtonSkin.LINK}
                title={t('app:pages:synthesis:bankAccounts:changeAccount')}
                onPress={() => showAccountList(true)}
              />
              <Drawer size='full' open={showAccountListInResponsive} placement='bottom'>
                <div className='BankAccountsList_drawer-content text-center'>
                  <div className='BankAccountsList_close-icon'>
                    <Icon name='x' size={40} type={IconTypes.FEATHER} onPress={() => showAccountList(false)} />
                  </div>
                  <div className='BankAccountsList_title'>
                    <Typography typeface='bigTitle'>
                      {t('app:pages:synthesis:bankAccounts:selectAccount')}
                    </Typography>
                  </div>
                  {displayBankAccounts()}
                </div>
              </Drawer>
            </>
          )
          : (
            displayBankAccounts()
          )}
      </>
    )
  )
}
