import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import CustomIcon from 'UI/Components/CustomIcon'

import {
  Button,
  Icon,
  IconTypes,
  Typography2,
  Stepper2,
  ReadOnlyInput,
} from '@neo-commons/components'
import { BusinessSubscriptionStep, BusinessSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import {
  BankAccountActions,
  BankAccountSelectors,
  Dispatch,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { BankAccountUtils, SubscriptionUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { AccountDto, SubscriptionDto } from '@afone/neo-core-client/models'

import { TunnelContent } from '@neo-web/componentsOld'

import './SubscriptionSuccess.scss'

type SubscriptionSuccessProps =
  StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.SubscriptionSuccess]>

export const SubscriptionSuccess: React.FC<SubscriptionSuccessProps> = ({ nextStep }: SubscriptionSuccessProps) => {
  const [isBankAccountCreated, setIsBankAccountCreated] = useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()

  const bankAccountSelected = useSelector(BankAccountSelectors.selected)
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.list)
  const filteredMainBankAccounts = bankAccounts?.filter((bankAccount: AccountDto) => !bankAccount?.disabled && BankAccountUtils.isMain(bankAccount) && !BankAccountUtils.isProspectAccount(bankAccount))
  const subscription: SubscriptionDto = useSelector(SubscriptionSelectors.defaultOneSocle)
  const subscriptionLoading = useSelector((state: StateTypes) => state?.subscription?.loading)
  const isBankAccountLoading = useSelector((state: StateTypes) => state.bankAccount?.loading)

  const validateSubscription = async () => {
    nextStep()
  }

  const createBankAccount = async () => {
    try {
      await dispatch(BankAccountActions.create(subscription.uuid))
      setIsBankAccountCreated(true)
    } catch (error) {}
  }

  useEffect(() => {
    if (!isBankAccountCreated &&
      SubscriptionUtils.isKycStepOk(subscription) &&
      !SubscriptionUtils.hasKycAffiliateStepInProgress(subscription) &&
      (!filteredMainBankAccounts || filteredMainBankAccounts?.length === 0)) {
      createBankAccount()
    }
  }, [])

  return (
    <TunnelContent
      loading={subscriptionLoading || isBankAccountLoading}
      header={
        <div>
          <Typography2 typeface='h3'>
            {t('neo-commons:app:global:subscription:subscriptionSuccess:title')}
          </Typography2>
          <div style={{ marginTop: 16 }}>
            <Stepper2 currentStep={2} />
          </div>
        </div>
      }
      footer={
        <Button
          title={t('app:pages:subscription:backtoHome')}
          containerStyle={{ marginBottom: 16, marginHorizontal: 16 }}
          onPress={() => { validateSubscription() }}
          disabled={isBankAccountLoading && !bankAccountSelected?.iban}
        />
      }
    >
      <div style={{ alignItems: 'center', width: '100%' }}>
        <ReadOnlyInput
          label={t('app:pages:subscription:areYouEuropeanTaxResident')}
          value={t('app:pages:subscription:yes').toUpperCase()}
          rightIcon={<div style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></div>}
        />
        <ReadOnlyInput
          label={t('app:pages:subscription:USPersonQuestionTitle')}
          value={t('app:pages:subscription:no').toUpperCase()}
          rightIcon={<div style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></div>}
        />
        <ReadOnlyInput
          label={t('app:pages:subscription:idDocument')}
          value={'' + SubscriptionUtils.getKycDocuments(subscription).map((kycDocument) => {
            return t(`KycTypeDto:${kycDocument.type}`)
          })}
          rightIcon={
            SubscriptionUtils.isKycStepOk(subscription) && !SubscriptionUtils.hasKycAffiliateStepInProgress(subscription)
              ? <div style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></div>
              : <div style={{ }}><CustomIcon name='clock' size={28} color={Colors.black} /></div>
          }
        />
        <ReadOnlyInput
          label={t('app:pages:subscription:contract')}
          value={t('app:pages:subscription:signed')}
          rightIcon={<Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' />}
        />
        <div style={{ marginTop: 16, display: 'flex', justifyContent: 'center' }}>
          {SubscriptionUtils.isKycStepOk(subscription) && !SubscriptionUtils.hasKycAffiliateStepInProgress(subscription)
            ? null : (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                <div style={{ marginTop: 32 }}>
                  <CustomIcon name='clock' size={28} color={Colors.primary} />
                </div>
                <Typography2 typeface='h4' style={{ marginTop: 32 }}>
                  {t('app:pages:subscription:kycPending.title')}
                </Typography2>
                <Typography2 typeface='body1' style={{ marginTop: 8 }}>
                  {t('app:pages:subscription:kycPending.text')}
                </Typography2>
              </div>)}
        </div>
      </div>
    </TunnelContent>
  )
}
