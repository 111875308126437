import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { ContactTypes } from '@neo-commons/store'

import { loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    ContactTypes.UPDATE_CONTACT_REQUEST,
    ContactTypes.UPDATE_CONTACT_SUCCESS,
    ContactTypes.UPDATE_CONTACT_FAILURE,
    ContactTypes.CREATE_CONTACT_REQUEST,
    ContactTypes.CREATE_CONTACT_SUCCESS,
    ContactTypes.CREATE_CONTACT_FAILURE,
    ContactTypes.BYID_CONTACT_FAILURE,
    ContactTypes.BYID_CONTACT_REQUEST,
    ContactTypes.BYID_CONTACT_SUCCESS,
    ContactTypes.DELETE_CONTACT_FAILURE,
    ContactTypes.DELETE_CONTACT_REQUEST,
    ContactTypes.DELETE_CONTACT_SUCCESS,
    ContactTypes.LIST_CONTACT_FAILURE,
    ContactTypes.LIST_CONTACT_REQUEST,
    ContactTypes.LIST_CONTACT_SUCCESS,
  ])
}

export default [
  reactiveLoader$,
]
