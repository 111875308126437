import { Reducer, AnyAction } from 'redux'
import i18next from 'i18next'
import { NeobankApi } from '@neo-commons/services'
import {
  SecretQuestionUserAnswerDto,
  SecretQuestionDto,
  SecretQuestionUserUpdateDto,
} from '@afone/neo-core-client/dist/models'

import { Dispatch, resourceDefaultSelectors } from '../../utils/resourceState'
import { State } from '../../utils'

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

const UPDATE_SECRET_QUESTION_REQUEST = 'userSecretQuestion/UPDATE_SECRET_QUESTION_REQUEST'
const UPDATE_SECRET_QUESTION_SUCCESS = 'userSecretQuestion/UPDATE_SECRET_QUESTION_SUCCESS'
const UPDATE_SECRET_QUESTION_FAILURE = 'userSecretQuestion/UPDATE_SECRET_QUESTION_FAILURE'

const CHECK_SECRET_QUESTION_REQUEST = 'userSecretQuestion/CHECK_SECRET_QUESTION_REQUEST'
const CHECK_SECRET_QUESTION_SUCCESS = 'userSecretQuestion/CHECK_SECRET_QUESTION_SUCCESS'
const CHECK_SECRET_QUESTION_FAILURE = 'userSecretQuestion/CHECK_SECRET_QUESTION_FAILURE'

const GET_USER_SECRET_QUESTION_REQUEST = 'userSecretQuestion/BYID_USER_SECRET_QUESTION_REQUEST'
const GET_USER_SECRET_QUESTION_SUCCESS = 'userSecretQuestion/BYID_USER_SECRET_QUESTION_SUCCESS'
const GET_USER_SECRET_QUESTION_FAILURE = 'userSecretQuestion/BYID_USER_SECRET_QUESTION_FAILURE'

const BLOCKED = 'userSecretQuestion/BLOCKED'
const RESET = 'userSecretQuestion/RESET'

export const UserSecretQuestionTypes = {
  UPDATE_SECRET_QUESTION_REQUEST,
  UPDATE_SECRET_QUESTION_SUCCESS,
  UPDATE_SECRET_QUESTION_FAILURE,

  CHECK_SECRET_QUESTION_REQUEST,
  CHECK_SECRET_QUESTION_SUCCESS,
  CHECK_SECRET_QUESTION_FAILURE,

  GET_USER_SECRET_QUESTION_REQUEST,
  GET_USER_SECRET_QUESTION_SUCCESS,
  GET_USER_SECRET_QUESTION_FAILURE,

  BLOCKED,
  RESET,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

const getUserSecretQuestion = function (userUuid?: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: GET_USER_SECRET_QUESTION_REQUEST })

    try {
      if (!userUuid) {
        userUuid = getState()?.user?.data?.uuid ?? ''
      }
      const getUserSecretQuestionResponse =
          await NeobankApi?.getInstance()?.secretQuestionApi?.getSecretQuestionByUserUuid(userUuid)
      const data = getUserSecretQuestionResponse?.data
      dispatch({ type: GET_USER_SECRET_QUESTION_SUCCESS, data })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({ type: GET_USER_SECRET_QUESTION_FAILURE, errorMessage })
    }
  }
}

const checkSecretQuestion = function (action: { secretQuestionAnswer: string, userUuid?: string }) {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { secretQuestionAnswer } = action
    if (!action.userUuid) {
      action.userUuid = getState()?.user?.data?.uuid ?? ''
    }
    const data: SecretQuestionUserAnswerDto = {
      answer: secretQuestionAnswer,
    }

    dispatch({ type: CHECK_SECRET_QUESTION_REQUEST })

    try {
      const getSecretQuestionsResponse =
        await NeobankApi?.getInstance()?.secretQuestionApi?.checkSecretQuestion(action.userUuid, data)
      dispatch({ type: CHECK_SECRET_QUESTION_SUCCESS, data: getSecretQuestionsResponse, answer: secretQuestionAnswer })
    } catch (error) {
      const status = error.status
      const errorMessage = error.message
      if (status === '429' || status === 429) {
        dispatch({ type: BLOCKED, errorMessage })
      } else {
        dispatch({ type: CHECK_SECRET_QUESTION_FAILURE, errorMessage })
      }
      throw new Error(errorMessage)
    }
  }
}
const updateSecretQuestion = function (action: { secretQuestionUuid: string; secretQuestionAnswer: string }) {
  return async (dispatch: Dispatch) => {
    const { secretQuestionAnswer, secretQuestionUuid } = action

    const data: SecretQuestionUserUpdateDto = {
      answer: (secretQuestionAnswer.replace(/\s+/g, ' ')).trim(),
      secretQuestionUuid: secretQuestionUuid,
    }

    dispatch({ type: UPDATE_SECRET_QUESTION_REQUEST })
    try {
      await NeobankApi?.getInstance()?.secretQuestionApi?.updateSecretQuestionUser(data)
      dispatch({
        type: UPDATE_SECRET_QUESTION_SUCCESS,
        successMessage: i18next.t('profile:secretQuestionSuccessfullyUpdated'),
        answer: data.answer,
      })
    } catch (error) {
      const errorMessage = error.message ?? 'unknownTechnicalIssue'
      dispatch({ type: UPDATE_SECRET_QUESTION_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const reset = function () {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET })
  }
}

export const UserSecretQuestionActions = {
  getUserSecretQuestion,
  updateSecretQuestion,
  checkSecretQuestion,
  reset,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export interface UserSecretQuestionState {
  loading: boolean
  data: SecretQuestionDto | null,
  answer: string| null
  blocked: boolean
}

const initialState: UserSecretQuestionState = {
  loading: false,
  data: null,
  answer: null,
  blocked: false,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const userSecretQuestionSelector = state => state.userSecretQuestion

export const UserSecretQuestionSelectors = {
  ...resourceDefaultSelectors(userSecretQuestionSelector),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const userSecretQuestion: Reducer = (state: UserSecretQuestionState = initialState, action: AnyAction): UserSecretQuestionState => {
  switch (action.type) {
    case CHECK_SECRET_QUESTION_REQUEST:
    case UPDATE_SECRET_QUESTION_REQUEST:
    case GET_USER_SECRET_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CHECK_SECRET_QUESTION_FAILURE:
    case UPDATE_SECRET_QUESTION_FAILURE:
    case GET_USER_SECRET_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        blocked: false
      }
    case BLOCKED:
      return {
        ...state,
        loading: false,
        blocked: true
      }
    case CHECK_SECRET_QUESTION_SUCCESS:
    case UPDATE_SECRET_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        answer: action.answer,
      }
    case GET_USER_SECRET_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
