import React, { useCallback, useEffect } from 'react'
import { Image, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { TunnelContent } from 'UI/Components/Layout/TunnelContent'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'
import { AsYouType, CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import CustomIcon from 'UI/Components/CustomIcon'

import { Button, ButtonSkin, Icon, IconTypes, ReadOnlyInput, Stepper2, Typography2 } from '@neo-commons/components'
import { Dispatch, SignupActions, SignupProfileState } from '@neo-commons/store'
import { RegisterStep, RegisterSteps, StepComponentProps } from '@neo-commons/policies'
import { FlagsImages } from '@neo-commons/components/assets/images/flags'
import { CountryUtils, PhoneNumberUtils, SelectionMode } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { CountryDto } from '@afone/neo-core-client/dist/models'

import { StateTypes } from '../../../../../Store'

type EmailVerifyProps = StepComponentProps<RegisterSteps[RegisterStep.EmailVerify]>

export const EmailVerify: React.FC<EmailVerifyProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()

  const verifyEmailLoading = useSelector((state: StateTypes) => state?.signup?.ui.verifyEmailLoading)
  const profile: SignupProfileState = useSelector((state: StateTypes) => state?.signup?.profile)
  const countries: CountryDto[] = useSelector(
    (state: StateTypes) => {
      return CountryUtils.filterByAuthorized(
        state.config?.data?.currentConfig?.countries ?? [],
        SelectionMode.PHONE_PREFIX
      )
    }
  )

  useEffect(() => {
    if (!profile?.isEmailVerified && !!profile?.otpEmailUuid) {
      dispatch(SignupActions.checkEmailStatus(profile?.otpEmailUuid))
    }
  }, [profile.isEmailVerified, profile.otpEmailUuid])

  useEffect(() => {
    if (profile.isEmailVerified) {
      const phoneNumber = parsePhoneNumber(profile.phone, profile.countryCode as CountryCode)
      AnalyticsHandler.track(AnalyticsEvents.signUp.userSignUpStepCompleted,
        {
          step_name: RegisterStep.EmailVerify,
          email: profile?.email,
          phone: phoneNumber.formatInternational().replace(/ /g, ''),
        })
    }
  }, [profile.isEmailVerified])

  const phoneFormatter = useCallback((value: string) =>
    PhoneNumberUtils.isAllowForApp(value || '', profile.countryCode as CountryCode)
      ? new AsYouType(profile.countryCode as CountryCode).input(value)
      : value, [profile.countryCode as CountryCode])

  const triggerEmailVerify = async (skipValidation = false) => {
    if (!skipValidation && !profile) return false
    try {
      await dispatch(SignupActions.triggerEmailVerify(profile.email))
    } catch (e) {}
  }

  const openEmailApp = (emailAddress: string) => {
    window.location.href = `mailto:${encodeURIComponent(emailAddress)}`
  }

  const flagCode = countries.find(country =>
    country.isoCodeAlpha2 === (profile?.phone && profile?.phone.startsWith('+')
      ? parsePhoneNumber(profile?.phone)?.country : profile?.countryCode)).isoCodeAlpha3
  const flag = FlagsImages[flagCode ?? 'Unknown'] ?? FlagsImages.Unknown

  return (
    <TunnelContent
      header={
        <View>
          <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
            {t('app:pages:register:step1:contact:signup')}
          </Typography2>
          <Stepper2 currentStep={1} />
          <Typography2 typeface='h5'>
            {t(`app:pages:register:step2:${!profile.isEmailVerified
              ? 'emailVerify' : 'emailValidate'}:titleMessage`)}
          </Typography2>
          <Typography2 typeface='body1'>
            {t(`app:pages:register:step2:${!profile.isEmailVerified
              ? 'emailVerify' : 'emailValidate'}:subTitleMessage`)}
          </Typography2>
        </View>
      }
      loading={verifyEmailLoading}
      footer={
        !profile?.isEmailVerified
          ? (
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
              <Button
                title={t('app:pages:register:step2:emailVerify:emailNotReceived')}
                skin={ButtonSkin.OUTLINE_PRIMARY}
                onPress={() => {
                  triggerEmailVerify(true)
                }}
                containerStyle={{ width: '40%', marginRight: 8 }}
              />
              <Button
                title={t('app:pages:register:step2:emailVerify:openEmail')}
                skin={ButtonSkin.PRIMARY}
                onPress={() => {
                  openEmailApp(profile.email)
                }}
                containerStyle={{ width: '40%', marginLeft: 8 }}
              />
            </View>
          ) : (
            <Button
              title={t('app:pages:register:step2:emailValidate:next')}
              skin={ButtonSkin.PRIMARY}
              onPress={() => {
                nextStep()
              }}
              leftIcon={<CustomIcon name='codeLock' size={28} color={Colors.white} />}
            />)
      }
    >
      <View style={{ alignItems: 'center', width: '100%' }}>
        <ReadOnlyInput
          label={t('app:pages:register:stepper:phoneNumber')}
          value={phoneFormatter(profile.phone)}
          leftIcon={<Image style={{ width: 22, height: 16, marginRight: 40 }} source={flag} />}
          rightIcon={<View><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></View>}
        />
        <ReadOnlyInput
          label={t('app:pages:register:stepper:eMailAddress')}
          value={profile.email}
          rightIcon={
            <View>{profile?.isEmailVerified
              ? <Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' />
              : <CustomIcon name='fastMail' size={24} color={Colors.black} />}
            </View>
          }
        />
      </View>
    </TunnelContent>
  )
}
