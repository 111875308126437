import React, { FunctionComponent, ReactElement, useMemo, useRef } from 'react'
import { TextStyle, View, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'

import { NumbersUtils } from '@neo-commons/libraries'

import { OfferTypography, Typography } from '..'
import { Button, ButtonSkin } from '../Button/Button'
import { PasscodeInput } from '../PasscodeInput/PasscodeInput'

import styles from './style'

export enum EditItemCardSkin {
  DEFAULT = 'DEFAULT',
  AMOUNT = 'AMOUNT',
  CUSTOM = 'CUSTOM',
  CUSTOMDIGITAL = 'CUSTOMDIGITAL',
  CUSTOMTEXTDIGITAL = 'CUSTOMTEXTDIGITAL',
  PIN = 'PIN',
  DELIVERY = 'DELIVERY',
  DEFAULT_CENTERED = 'DEFAULT_CENTERED'
}

interface SubtitlesProps {
  subtitles?: string[],
  shouldCenter?: boolean,
}
const Subtitles: FunctionComponent<SubtitlesProps> = ({ subtitles, shouldCenter }) => {
  const computedStyle: TextStyle = shouldCenter ? { textAlign: 'center' } : {}
  return (
    <View style={{ marginTop: 8 }}>
      {subtitles?.map((subtitle, index) =>
        <Typography key={index} typeface='content' style={[styles.subTitle, computedStyle]}>
          {subtitle}
        </Typography>)}
    </View>
  )
}

interface PinPayloadProps {
  pinLimit: number,
  pinValue?: string,
}

interface BasicPayloadProps {
  title?: string,
  subtitles?: string[],
  image?: string | ReactElement
}

interface DeliveryPayloadProps extends BasicPayloadProps {
  amount?: string,
}

interface AmountPayloadProps {
  amount?: number,
  currency?: string,
  fees?: number,
  feesTotal?: number
}

interface CustomPayloadProps {
  content: ReactElement
}

export interface EditItemCardProps {
    skin: EditItemCardSkin,
    onEditPress?: () => void,
    containerStyle?: ViewStyle,
    label?: string,
    buttonLabel? : string,
    payload: PinPayloadProps | DeliveryPayloadProps | AmountPayloadProps | CustomPayloadProps | BasicPayloadProps,
    hideButton?: boolean
}

const getDefaultLayoutStyle = (skin: EditItemCardSkin): ViewStyle[] => {
  if (skin === EditItemCardSkin.DEFAULT) {
    return [{ flexDirection: 'row' }, { justifyContent: 'center' }]
  }

  return [{}, {}]
}

const getImageIfNeeded = (payload: BasicPayloadProps) : undefined | ReactElement => {
  if (!payload.image) {
    return
  }

  if (typeof payload.image === 'string') {
    return
  }

  return payload.image
}

export const EditItemCard: FunctionComponent<EditItemCardProps> =
  ({
    payload,
    skin,
    label,
    onEditPress,
    containerStyle,
    buttonLabel,
    hideButton,
  }) => {
    const [wrapperStyle, buttonAreaStyle] = useMemo(() => getDefaultLayoutStyle(skin), [skin])
    const { t } = useTranslation()

    const _buttonLabel = useRef(buttonLabel ?? t('neo-commons:global:edit') ?? '').current

    const getCustomStyle = () => {
      switch (skin) {
        case EditItemCardSkin.CUSTOMDIGITAL:
          return styles.customDigital
        case EditItemCardSkin.CUSTOMTEXTDIGITAL:
          return styles.customTextDigital
        case EditItemCardSkin.CUSTOM:
        default:
          return styles.custom
      }
    }

    return (
      <View style={[styles.container, wrapperStyle, containerStyle]}>
        {label && <Typography typeface='subtitleLight' style={[styles.title]}>{label}</Typography>}

        {skin === EditItemCardSkin.DEFAULT &&
          <View accessibilityLabel='ItemCardDefault' style={styles.defaultTextArea}>
            <Typography typeface='bold'>{(payload as BasicPayloadProps)?.title ?? ''}</Typography>
            <Subtitles subtitles={(payload as BasicPayloadProps)?.subtitles} />
          </View>}

        {(skin === EditItemCardSkin.CUSTOM ||
          skin === EditItemCardSkin.CUSTOMDIGITAL ||
          skin === EditItemCardSkin.CUSTOMTEXTDIGITAL) &&
            <View style={getCustomStyle()}>
              {(payload as CustomPayloadProps)?.content}
            </View>}

        {skin === EditItemCardSkin.DEFAULT_CENTERED &&
          <View style={styles.centerItems}>
            {(payload as BasicPayloadProps)?.title &&
              <Typography typeface='subtitleLight' style={styles.title}>
                {(payload as BasicPayloadProps)?.title ?? ''}
              </Typography>}
            <Subtitles subtitles={(payload as BasicPayloadProps)?.subtitles} shouldCenter />
          </View>}

        {skin === EditItemCardSkin.DELIVERY &&
          <View style={styles.deliveryContent}>
            <View style={{ flex: 1 }}>
              <Typography typeface='bold'>{(payload as DeliveryPayloadProps)?.title ?? ''}</Typography>
              <Subtitles subtitles={(payload as DeliveryPayloadProps)?.subtitles} />
            </View>
            <View style={styles.deliveryAmount}>
              <Typography typeface='bold'>{(payload as DeliveryPayloadProps)?.amount}</Typography>
            </View>
          </View>}

        {skin === EditItemCardSkin.PIN &&
          <View style={styles.centerItems}>
            <PasscodeInput
              pinLimit={(payload as PinPayloadProps)?.pinLimit ?? 4}
              codePin={(payload as PinPayloadProps)?.pinValue ?? ''}
            />
          </View>}

        {skin === EditItemCardSkin.AMOUNT &&
          <View style={styles.centerItems}>
            <Typography typeface='infoRegular' style={styles.amount}>
              {NumbersUtils.displayPriceForHuman((payload as AmountPayloadProps)?.amount ?? 0)}
            </Typography>
            {(payload as AmountPayloadProps)?.fees &&
              <View style={styles.fees}>
                <OfferTypography
                  isOffer={(payload as AmountPayloadProps)?.feesTotal === 0}
                  value={(payload as AmountPayloadProps)?.fees!}
                  label={t('neo-commons:account:fees')}
                  offerLabel={t('neo-commons:account:freeFees')}
                />
              </View>}
          </View>}

        {getImageIfNeeded(payload as BasicPayloadProps)}

        {(onEditPress && !hideButton) &&
          <View style={
            [styles.buttonArea,
              skin === EditItemCardSkin.DEFAULT ? { marginVertical: 0 } : {},
              buttonAreaStyle]
          }
          >
            <Button
              onPress={onEditPress}
              flat
              skin={ButtonSkin.DEFAULT}
              title={_buttonLabel}
            />
          </View>}
      </View>
    )
  }
