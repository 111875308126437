import { UserSecretQuestionActions } from '@neo-commons/store'

import { invokeActionCreator, createWizardPolicy } from '../../utils'

const machineId = 'secretQuestion'

/*
 * Steps
 */
export enum SecretQuestionStep {
  SecretQuestion = 'SecretQuestion',
  Success = 'Success'
}

export const secretQuestionSteps = {
  [SecretQuestionStep.SecretQuestion]: {
    path: '',
    nextStep: SecretQuestionStep.Success,
    fulfill: invokeActionCreator(UserSecretQuestionActions.updateSecretQuestion),
    meta: {
      screenTitle: 'app:pages:myProfile:security:secretQuestion:editSecretQuestion',
    },
  },
  [SecretQuestionStep.Success]: {
    path: 'success',
    nextStep: 'final',
    history: {
      preventStack: true,
      resetStack: true,
    },
    meta: {
      screenTitle: 'app:pages:myProfile:security:secretQuestion:summary',
    },
  },
}

export type SecretQuestionSteps = typeof secretQuestionSteps

/*
 * Policy initialization
 */
export const SecretQuestionPolicy = createWizardPolicy({
  machineId: machineId,
  steps: secretQuestionSteps,
  initialStep: SecretQuestionStep.SecretQuestion,
  basePath: '/profile/preference/security/secretQuestion',
})
