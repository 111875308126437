import { ErrorCodeDto, KycTypeDto } from '@afone/neo-core-client/dist/models'
import { AnyAction, Reducer } from 'redux'
import { createSelector } from 'reselect'
import i18next from 'i18next'
import { DocumentUploadStatus, EntitiesUtils, SubscriptionUtils } from '@neo-commons/libraries'
import { NeobankApi } from '@neo-commons/services'

import { Dispatch, SubscriptionActions, SubscriptionSelectors } from '../'
import { AlertType, State } from '../utils'

/* %%%%%%%%%%%%%%%%%% *\
    Types.
\* %%%%%%%%%%%%%%%%%% */

export interface DocumentPage {
  generatedUuid?: string
  uri?: string,
  width?: number,
  height?: number,
  previewUri?: string,
  data?: any
  status?: DocumentUploadStatus
}

interface Document {
  documentType: KycTypeDto,
  name: string,
  pages?: DocumentPage[]
}

export interface DocumentUploadState {
  data: {
    personUuid?: string,
    subscriptionUuid?: string,
    document?: Document
    status?: DocumentUploadStatus
    tryNumber: number // Number of times user tried to send his documents
  },
  ui: {
    isUploading: boolean,
    isReUpload: boolean,
    hasError: boolean,
  }
  ariadDocumentFailure: boolean,
  failureReason: string,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types
\* %%%%%%%%%%%%%%%%%% */

const PREPARE = 'documentUpload/PREPARE'
const ADD_DOCUMENT_PAGE_SUCCESS = 'documentUpload/ADD_DOCUMENT_PAGE_SUCCESS'
const ADD_DOCUMENT_PAGE_MAX_SIZE_FAILURE = 'documentUpload/ADD_DOCUMENT_PAGE_MAX_SIZE_FAILURE'
const ADD_DOCUMENT_PAGE_FAILURE = 'documentUpload/ADD_DOCUMENT_PAGE_FAILURE'
const REMOVE_DOCUMENT_PAGE = 'documentUpload/REMOVE_DOCUMENT_PAGE'
const UPLOAD_DOCUMENT_REQUEST = 'documentUpload/UPLOAD_DOCUMENT_REQUEST'
const UPLOAD_DOCUMENT_SUCCESS = 'documentUpload/UPLOAD_DOCUMENT_SUCCESS'
const UPLOAD_DOCUMENT_FAILURE = 'documentUpload/UPLOAD_DOCUMENT_FAILURE'
const UPLOAD_DOCUMENT_PAGE_REQUEST = 'documentUpload/UPLOAD_DOCUMENT_PAGE_REQUEST'
const UPLOAD_DOCUMENT_PAGE_SUCCESS = 'documentUpload/UPLOAD_DOCUMENT_PAGE_SUCCESS'
const UPLOAD_DOCUMENT_PAGE_FAILURE = 'documentUpload/UPLOAD_DOCUMENT_PAGE_FAILURE'
const SET_IS_RE_UPLOAD = 'documentUpload/SET_IS_RE_UPLOAD'
const RESET = 'documentUpload/RESET'

export const DocumentUploadTypes = {
  PREPARE,
  ADD_DOCUMENT_PAGE_SUCCESS,
  ADD_DOCUMENT_PAGE_MAX_SIZE_FAILURE,
  ADD_DOCUMENT_PAGE_FAILURE,
  REMOVE_DOCUMENT_PAGE,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  UPLOAD_DOCUMENT_PAGE_REQUEST,
  UPLOAD_DOCUMENT_PAGE_SUCCESS,
  UPLOAD_DOCUMENT_PAGE_FAILURE,
  SET_IS_RE_UPLOAD,
  RESET,
}

/* %%%%%%%%%%%%%%%%%% *\
    Types.
\* %%%%%%%%%%%%%%%%%% */

const documentUploadSelector = state => state.documentUpload
export const DocumentUploadSelectors = {
  pages: createSelector(
    documentUploadSelector,
    resource => resource.data.document?.pages || []),
  documentType: createSelector(
    documentUploadSelector,
    resource => resource.data.document?.documentType
  ),
  status: createSelector(
    documentUploadSelector,
    resource => resource.data.status
  ),
  canRetrySend: createSelector(
    documentUploadSelector,
    resource => resource.data.tryNumber < 5
  ),
  isReUpload: createSelector(
    documentUploadSelector,
    resource => resource.ui.reUpload
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators
\* %%%%%%%%%%%%%%%%%% */

const uploadDocumentPage = ({ subscriptionUuid, personUuid, documentType, page }: {
  subscriptionUuid: string,
  personUuid: string,
  documentType: KycTypeDto,
  page: DocumentPage
}) => {
  return async (dispatch: Dispatch): Promise<DocumentPage> => {
    dispatch({ type: UPLOAD_DOCUMENT_PAGE_REQUEST })
    return NeobankApi.getInstance().subscriptionApi.createStepPageForPersonUuid(
      subscriptionUuid,
      personUuid,
      documentType,
      page.data
    )
      .then(() => {
        dispatch({ type: UPLOAD_DOCUMENT_PAGE_SUCCESS, documentPage: page })
        return page
      })
      .catch(() => {
        dispatch({ type: UPLOAD_DOCUMENT_PAGE_FAILURE, documentPage: page })
        throw new Error()
      })
  }
}

const uploadDocument = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: UPLOAD_DOCUMENT_REQUEST })
    try {
      const subscription = SubscriptionSelectors.defaultOneSocleSubscribing(getState())
      const personUuid = (getState().documentUpload.data.personUuid ??
        SubscriptionUtils.getKycStep(subscription)?.personUuid)!
      const type = getState().documentUpload.data.document?.documentType!
      const pages = getState().documentUpload.data.document?.pages!
      const subscriptionUuid = getState().documentUpload.data.subscriptionUuid ?? subscription.uuid

      for (const page of pages) {
        if (page.status !== DocumentUploadStatus.SUCCESS) {
          await dispatch(
            uploadDocumentPage({
              subscriptionUuid,
              personUuid,
              documentType: type,
              page,
            })
          )
        }
      }

      if (type !== KycTypeDto.SIGNATURE_ADVANCED) {
        await NeobankApi.getInstance().subscriptionApi.submitKycDocument(subscription.uuid, personUuid, type, {})
      } else {
        await dispatch(SubscriptionActions.getSignatureLink({
          personUuid: personUuid,
          subscriptionUuid: subscription.uuid,
        }))
      }

      dispatch({ type: UPLOAD_DOCUMENT_SUCCESS })
    } catch (error) {
      const errorMessage = error.message
      const errorCode = error.code
      dispatch({ type: UPLOAD_DOCUMENT_FAILURE, errorMessage, errorCode })
      throw new Error(errorMessage)
    }
  }
}

const setIsReUpload = function (reUpload: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SET_IS_RE_UPLOAD, reUpload })
  }
}

const prepare = function (document: Document, personUuid?: string, subscriptionUuid?: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PREPARE, document, personUuid, subscriptionUuid })
  }
}

const addDocumentPage = function (documentPage: DocumentPage, size: number, maxAcceptedSize: number) {
  return async (dispatch: Dispatch) => {
    try {
      if (size >= maxAcceptedSize) {
        const oneKiloOctets = 1024
        const oneMegaOctets = 1024 * 1024
        const maxAcceptedSizeConvertedString = maxAcceptedSize < oneKiloOctets
          ? maxAcceptedSize.toFixed(2) + ' octets.'
          : maxAcceptedSize < oneMegaOctets
            ? (maxAcceptedSize / oneKiloOctets).toFixed(2) + ' Ko.'
            : (maxAcceptedSize / oneMegaOctets).toFixed(2) + ' Mo.'
        dispatch({
          type: ADD_DOCUMENT_PAGE_MAX_SIZE_FAILURE,
          alertType: AlertType.ERROR,
          errorMessage: i18next.t('app:pages:subscription:documentAddMaxLimitError', { size: maxAcceptedSizeConvertedString }),
        })
      } else {
        dispatch({
          type: ADD_DOCUMENT_PAGE_SUCCESS,
          alertType: AlertType.SUCCESS,
          successMessage: i18next.t('app:pages:subscription:documentAdded'),
          documentPage: {
            ...documentPage,
            generatedUuid: EntitiesUtils.getAltUniqueId(),
            status: DocumentUploadStatus.PENDING,
          },
        })
      }
    } catch (error) {
      const errorMessage = error.message
      dispatch({
        type: ADD_DOCUMENT_PAGE_FAILURE,
        errorMessage: i18next.t('app:pages:subscription:addDocumentError'),
      })
      throw new Error(errorMessage)
    }
  }
}

const removeDocumentPage = function (documentPage: DocumentPage) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REMOVE_DOCUMENT_PAGE,
      documentPage,
      alertType: AlertType.SUCCESS,
      successMessage: i18next.t('app:pages:subscription:documentRemoved'),
    })
  }
}

export const DocumentUploadActions = {
  uploadDocument,
  prepare,
  addDocumentPage,
  removeDocumentPage,
  setIsReUpload,
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

const initialState: DocumentUploadState = {
  data: {
    personUuid: undefined,
    document: undefined,
    subscriptionUuid: undefined,
    tryNumber: 0,
    status: DocumentUploadStatus.PENDING,
  },
  ui: {
    isUploading: false,
    isReUpload: false,
    hasError: false,
  },
  ariadDocumentFailure: false,
  failureReason: '',
}

export const documentUpload: Reducer = (
  state: DocumentUploadState = initialState,
  action: AnyAction
) => {
  let pageIndex, pages
  switch (action.type) {
    case SET_IS_RE_UPLOAD:
      return {
        ...state,
        ui: {
          ...state.ui,
          isReUpload: action.reUpload,
        },
      }
    case UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          tryNumber: state.data.tryNumber + 1,
        },
      }
    case PREPARE:
      return {
        ...initialState,
        data: {
          ...initialState.data,
          personUuid: action.personUuid,
          document: action.document,
          status: DocumentUploadStatus.PENDING,
          subscriptionUuid: action.subscriptionUuid,
        },
      }
    case ADD_DOCUMENT_PAGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          document: {
            ...state.data.document,
            pages: (state.data.document?.pages || []).concat(action.documentPage),
          },
        },
      }
    case REMOVE_DOCUMENT_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          document: {
            ...state.data.document,
            pages: (state.data.document?.pages || []).filter(
              (page) => page.generatedUuid !== action.documentPage.generatedUuid),
          },
        },
      }
    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          status: DocumentUploadStatus.SUCCESS,
        },
      }
    case UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          status: DocumentUploadStatus.ERROR,
        },
        ariadDocumentFailure: action.errorCode === ErrorCodeDto.C0707,
        failureReason: action.errorCode,
      }
    case UPLOAD_DOCUMENT_PAGE_SUCCESS:
      pageIndex = state.data.document?.pages?.findIndex(page => page.generatedUuid === action.documentPage.generatedUuid) ?? -1
      pages = state.data.document?.pages ?? []
      if (pageIndex > -1 && pages.length) {
        pages[pageIndex] = {
          ...pages[pageIndex],
          status: DocumentUploadStatus.SUCCESS,
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          document: {
            ...state.data.document,
            pages: pages,
          },
        },
      }
    case UPLOAD_DOCUMENT_PAGE_FAILURE:
      pageIndex = state.data.document?.pages?.findIndex(page => page.generatedUuid === action.documentPage.generatedUuid) ?? -1
      pages = state.data.document?.pages ?? []
      if (pageIndex > -1 && pages.length) {
        pages[pageIndex] = {
          ...pages[pageIndex],
          status: DocumentUploadStatus.ERROR,
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          document: {
            ...state.data.document,
            pages: pages,
          },
        },
      }
    case RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
