import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonSkin } from '@neo-commons/components'

import { PageContent, PageContentProps } from '@neo-web/componentsOld'

import './SuccessFailure.scss'

export interface ButtonActionProps {
  text?: string
  action?: () => void
}

export interface SuccessFailureProps extends PageContentProps {
  buttonBack?: ButtonActionProps
  buttonAction?: ButtonActionProps
}

export const SuccessFailure: React.FC<SuccessFailureProps> = (props) => {
  const { t } = useTranslation()

  return (
    <PageContent
      {...props}
      withBackground
      footer={
        <div style={!props?.description ? { marginTop: 12 } : {}}>
          {props?.buttonBack?.action &&
            <Button
              containerStyle={!props?.description ? { marginTop: 12 } : {}}
              title={props?.buttonBack?.text ?? t('app:global:goBackToSynthesis')}
              skin={ButtonSkin.OUTLINE_PRIMARY}
              onPressOut={() => props?.buttonBack?.action ? props.buttonBack?.action() : () => null}
            />}
          {props?.buttonAction?.text &&
            <Button
              containerStyle={props?.buttonBack ? { marginTop: 12 } : {}}
              title={props.buttonAction?.text}
              skin={ButtonSkin.PRIMARY}
              onPressOut={() => props?.buttonAction?.action ? props.buttonAction?.action() : () => null}
            />}
        </div>
      }
    />
  )
}
