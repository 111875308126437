import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { SubscriptionPolicy } from 'Policies'

import { SubscriptionSelectors } from '@neo-commons/store'

import { Business } from './Business/Business'
import { Individual } from './Individual/Individual'
import { SubscriptionChoice } from './SubscriptionChoice/SubscriptionChoice'
import { SubscriptionError } from './SubscriptionError/SubscriptionError'
import { SeaCgu } from './SeaCgu/SeaCgu'
import { SubscriptionFailure } from './SubscriptionFailure/SubscriptionFailure'

export const Subscription: React.FC = () => {
  /**
   * xState machine side effect...
   * Without this selector right here, Individual & Business machines break after SubscriptionActions.create()
   */
  useSelector(SubscriptionSelectors.defaultOne)

  return (
    <div data-testid='Subscription'>
      <Redirect to={SubscriptionPolicy.apply()} />
      <Route path={Routes.Subscription.subscriptionChoice.path} component={SubscriptionChoice} />
      <Route path={Routes.Subscription.Individual.index.path} component={Individual} />
      <Route path={Routes.Subscription.Business.index.path} component={Business} />
      <Route path={Routes.Subscription.subscriptionFailure.path} component={SubscriptionFailure} />
      <Route path={Routes.Subscription.subscriptionError.path} component={SubscriptionError} />
      <Route path={Routes.Subscription.SeaCgu.index.path} component={SeaCgu} />
    </div>
  )
}
