import { Reducer, AnyAction } from 'redux'
import i18next from 'i18next'
import _, { isString } from 'lodash'
import { NeobankApi } from '@neo-commons/services'
import {
  UserCreateDto, OtpPhoneTypeDto, OsTypeDto
  , ValidatePasswordDto,
  ErrorCodeDto,
} from '@afone/neo-core-client/dist/models'
import produce from 'immer'

import { Dispatch } from '../utils/resourceState'
import { State } from '../utils'

import { SignInActions } from './signin/signin'

const DEFAULT_LOCALE = 'FR'

const WAITING = 'WAITING'
const VALIDATED = 'VALIDATED'

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

export const SignupTypes = {
  SIGN_UP_PREPARE: 'signup/SIGN_UP_PREPARE',

  UPDATE_PROFILE: 'signup/UPDATE_PROFILE',

  START_COMPLETING_OFFLINE_REGISTRATION: 'signup/START_COMPLETING_OFFLINE_REGISTRATION',

  TRIGGER_PHONE_VERIFY_REQUEST: 'signup/TRIGGER_PHONE_VERIFY_REQUEST',
  TRIGGER_PHONE_VERIFY_SUCCESS: 'signup/TRIGGER_PHONE_VERIFY_SUCCESS',
  TRIGGER_PHONE_VERIFY_FAILURE: 'signup/TRIGGER_PHONE_VERIFY_FAILURE',

  VERIFY_PHONE_REQUEST: 'signup/VERIFY_PHONE_REQUEST',
  VERIFY_PHONE_SUCCESS: 'signup/VERIFY_PHONE_SUCCESS',
  VERIFY_PHONE_FAILURE: 'signup/VERIFY_PHONE_FAILURE',

  TRIGGER_EMAIL_VERIFY_REQUEST: 'signup/TRIGGER_EMAIL_VERIFY_REQUEST',
  TRIGGER_EMAIL_VERIFY_SUCCESS: 'signup/TRIGGER_EMAIL_VERIFY_SUCCESS',
  TRIGGER_EMAIL_VERIFY_FAILURE: 'signup/TRIGGER_EMAIL_VERIFY_FAILURE',

  VERIFY_EMAIL_REQUEST: 'signup/VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_WAITING: 'signup/VERIFY_EMAIL_WAITING',
  VERIFY_EMAIL_SUCCESS: 'signup/VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE: 'signup/VERIFY_EMAIL_FAILURE',

  CHECK_EMAIL_STATUS_REQUEST: 'signup/CHECK_EMAIL_STATUS_REQUEST',
  CHECK_EMAIL_STATUS_WAITING: 'signup/CHECK_EMAIL_STATUS_WAITING',
  CHECK_EMAIL_STATUS_SUCCESS: 'signup/CHECK_EMAIL_STATUS_SUCCESS',
  CHECK_EMAIL_STATUS_FAILURE: 'signup/CHECK_EMAIL_STATUS_FAILURE',
  CHECK_EMAIL_STATUS_FAILURE_C0020: 'signup/CHECK_EMAIL_STATUS_FAILURE_C0020',

  BLOCK: 'signup/BLOCK',

  REGISTER_REQUEST: 'signup/REGISTER_REQUEST',
  REGISTER_SUCCESS: 'signup/REGISTER_SUCCESS',
  REGISTER_FAILURE: 'signup/REGISTER_FAILURE',

  PASSWORD_CHECK_REQUEST: 'signup/PASSWORD_CHECK_REQUEST',
  PASSWORD_CHECK_SUCCESS: 'signup/PASSWORD_CHECK_SUCCESS',
  PASSWORD_CHECK_FAILURE: 'signup/PASSWORD_CHECK_FAILURE',

  CONFIRM_PASSWORD_CHECK_REQUEST: 'signup/CONFIRM_PASSWORD_CHECK_REQUEST',
  CONFIRM_PASSWORD_CHECK_SUCCESS: 'signup/CONFIRM_PASSWORD_CHECK_SUCCESS',
  CONFIRM_PASSWORD_CHECK_FAILURE: 'signup/CONFIRM_PASSWORD_CHECK_FAILURE',

  SAVE_SECRET_QUESTION: 'signup/SAVE_SECRET_QUESTION',

  SIGN_UP_COMPLETED: 'signup/SIGN_UP_COMPLETED',

  RESET: 'signup/RESET',
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators
\* %%%%%%%%%%%%%%%%%% */

const prepare = function (locale: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SignupTypes.SIGN_UP_PREPARE, locale })
  }
}

const block = function () {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SignupTypes.BLOCK })
  }
}

export type OfflineRegistrationData = {
  firstName: string,
  lastName: string,
  phone: string,
  otpEmailUuid: string,
  token: string
}
const startCompletingOfflineRegistration = function (offlineRegistrationData: OfflineRegistrationData) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SignupTypes.START_COMPLETING_OFFLINE_REGISTRATION, ...offlineRegistrationData })
  }
}

const triggerPhoneVerify =
  function ({ phone, phoneCountryCode, firstName, lastName, email, token }: {
    phone: string,
    phoneCountryCode: string,
    token: string,
    lastName: string,
    firstName: string,
    email: string,
  }) {
    return async (dispatch: Dispatch) => {
      dispatch({ type: SignupTypes.TRIGGER_PHONE_VERIFY_REQUEST })

      if (!isString(phone)) {
        dispatch({
          type: SignupTypes.TRIGGER_PHONE_VERIFY_FAILURE,
          errorMessage: i18next.t('errors:triggerPhoneVerifyError'),
        })
        throw new Error(i18next.t('errors:triggerPhoneVerifyError'))
      }

      try {
        const response = await NeobankApi.getInstance().otpPhoneApi.createOtpPhone({
          phoneCountryCode,
          phone,
          token,
          type: OtpPhoneTypeDto.SUBSCRIPTION,
        })
        dispatch({
          type: SignupTypes.TRIGGER_PHONE_VERIFY_SUCCESS,
          profile: {
            phone,
            countryCode: phoneCountryCode,
            otpPhoneUuid: response.data.uuid,
            lastName,
            firstName,
            email,
          },
        })
      } catch (error) {
        const errorMessage = error.message
        const errorCode = error.code
        dispatch({ type: SignupTypes.TRIGGER_PHONE_VERIFY_FAILURE, errorMessage, errorCode, phone })
        throw new Error(errorMessage)
      }
    }
  }
const verifyPhone = function (action: { code: string }) {
  const verifyPhoneErrorTransKey = 'errors:verifyPhoneError'
  return async (dispatch: Dispatch, getState: () => State) => {
    const { code } = action
    dispatch({ type: SignupTypes.VERIFY_PHONE_REQUEST })

    if (!code) {
      dispatch({ type: SignupTypes.VERIFY_PHONE_FAILURE, errorMessage: i18next.t(verifyPhoneErrorTransKey) })
      throw new Error(i18next.t(verifyPhoneErrorTransKey))
    }

    try {
      await NeobankApi.getInstance().otpPhoneApi.updateOtpPhoneByUuid(getState().signup.profile.otpPhoneUuid!, { code })
      dispatch({ type: SignupTypes.VERIFY_PHONE_SUCCESS })
    } catch (error) {
      dispatch({ type: SignupTypes.VERIFY_PHONE_FAILURE, errorMessage: i18next.t(verifyPhoneErrorTransKey) })
      throw new Error(i18next.t(verifyPhoneErrorTransKey))
    }
  }
}

const triggerEmailVerify = function (email: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    // Don't do anything if user already verified this email
    if (email === getState().signup.profile.email && getState().signup.profile.isEmailVerified) {
      return
    }

    dispatch({ type: SignupTypes.TRIGGER_EMAIL_VERIFY_REQUEST })

    if (!isString(email)) {
      dispatch({
        type: SignupTypes.TRIGGER_EMAIL_VERIFY_FAILURE,
        errorMessage: i18next.t('errors:unknownTechnicalError'),
      })
      return
    }

    try {
      const { otpPhoneUuid } = getState().signup.profile
      if (!otpPhoneUuid) {
        throw new Error('otpPhoneUuid is not defined')
      }
      const createOptEmailResponse =
        await NeobankApi.getInstance().otpEmailApi.createOtpEmail({
          email: email.trim(),
          otpPhoneUuid: otpPhoneUuid!,
        })
      const { uuid } = createOptEmailResponse.data
      dispatch({ type: SignupTypes.TRIGGER_EMAIL_VERIFY_SUCCESS, profile: { email, otpEmailUuid: uuid } })
    } catch (error) {
      const errorMessage = error.message
      dispatch({ type: SignupTypes.TRIGGER_EMAIL_VERIFY_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const verifyEmail = function (action: { otpEmailUuid: string, code: string }) {
  return async (dispatch: Dispatch) => {
    const { otpEmailUuid, code } = action
    dispatch({ type: SignupTypes.VERIFY_EMAIL_REQUEST })

    if (!code || !otpEmailUuid) {
      dispatch({ type: SignupTypes.VERIFY_EMAIL_FAILURE, errorMessage: i18next.t('errors:verifyEmailError') })
      throw new Error(i18next.t('errors:verifyEmailError'))
    }
    try {
      await NeobankApi.getInstance().otpEmailApi.updateOtpEmailByUuid(otpEmailUuid, { code })
      dispatch({ type: SignupTypes.VERIFY_EMAIL_SUCCESS })
    } catch (error) {
      const errorMessage = error.message
      dispatch({ type: SignupTypes.VERIFY_EMAIL_FAILURE, errorMessage })
      throw new Error(error.message)
    }
  }
}

const checkEmailStatus = function (otpEmailUuid: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SignupTypes.CHECK_EMAIL_STATUS_REQUEST })
    if (!otpEmailUuid) {
      dispatch({
        type: SignupTypes.CHECK_EMAIL_STATUS_FAILURE,
        errorMessage: i18next.t('errors:verifyEmailError'),
      })
      throw new Error(i18next.t('errors:verifyEmailError'))
    }

    try {
      const response = await NeobankApi.getInstance().otpEmailApi.getOtpEmailStatusByUuid(otpEmailUuid)
      switch (response.data) {
        case WAITING: {
          dispatch({ type: SignupTypes.VERIFY_EMAIL_WAITING, otpEmailUuid })
          break
        }
        case VALIDATED: {
          dispatch({ type: SignupTypes.VERIFY_EMAIL_SUCCESS })
          break
        }
      }
    } catch (error) {
      const errorMessage = error.message
      if (error.code === ErrorCodeDto.C0020) {
        // We need a specific action type because we don't want to display this specific error
        dispatch({ type: SignupTypes.CHECK_EMAIL_STATUS_FAILURE_C0020, errorMessage })
        throw new Error(error.message)
      }
      dispatch({ type: SignupTypes.CHECK_EMAIL_STATUS_FAILURE, errorMessage })
      throw new Error(error.message)
    }
  }
}

const updateProfile = function (profile: SignupProfileState) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SignupTypes.UPDATE_PROFILE, profile })
  }
}

const passwordCheck = function (validatePassword: ValidatePasswordDto) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SignupTypes.PASSWORD_CHECK_REQUEST })

    if (!validatePassword) {
      dispatch({ type: SignupTypes.PASSWORD_CHECK_FAILURE, errorMessage: i18next.t('errors:registerError') })
      throw new Error(i18next.t('errors:registerError'))
    } else {
      try {
        await NeobankApi.getInstance().userApi.validatePassword(validatePassword)
        dispatch({ type: SignupTypes.PASSWORD_CHECK_SUCCESS, password: validatePassword.password })
      } catch (error) {
        const errorMessage = error.message
        dispatch({ type: SignupTypes.PASSWORD_CHECK_FAILURE, errorMessage })
        throw error
      }
    }
  }
}

const confirmPassword = function (confirmPassword: string[]) {
  return async (dispatch: Dispatch) => {
    // This does nothing anymore but store the confirmPassword because we can't compare the new keypads
    try {
      dispatch({
        type: SignupTypes.CONFIRM_PASSWORD_CHECK_SUCCESS,
        confirmPassword,
      })
    } catch (error) {
      const errorMessage = error.message
      dispatch({ type: SignupTypes.CONFIRM_PASSWORD_CHECK_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const secretQuestion = function (secretQuestionResponse: string, secretQuestion: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: SignupTypes.SAVE_SECRET_QUESTION,
        secretQuestion,
        secretQuestionResponse,
      })
    } catch (error) {
      const errorMessage = error.message
      dispatch({ type: SignupTypes.CONFIRM_PASSWORD_CHECK_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const register = function (action: {
  secretQuestionUuid: string,
  secretQuestionAnswer: string,
  deviceOs: OsTypeDto,
  deviceName: string,
  uniqueDeviceId?: string,
  deviceToken?: string,
}) {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { secretQuestionUuid, secretQuestionAnswer, deviceOs, deviceName, uniqueDeviceId, deviceToken } = action
    const {
      phone,
      otpPhoneUuid,
      otpEmailUuid,
      password,
      confirmPassword,
      firstName,
      lastName,
    } = getState().signup.profile
    const accessToken = getState().oidc?.user?.access_token

    dispatch({ type: SignupTypes.REGISTER_REQUEST })

    try {
      const userCreateRequest: UserCreateDto = {
        lastName: lastName!,
        firstName: firstName!,
        password: password!,
        confirmPassword: confirmPassword!,
        otpPhoneUuid: otpPhoneUuid!,
        otpEmailUuid: otpEmailUuid!,
        secretQuestionUuid: secretQuestionUuid,
        secretQuestionAnswer: (secretQuestionAnswer?.replace(/\s+/g, ' ')).trim() ?? '',
        deviceOs,
        deviceName,
        uniqueDeviceId,
        deviceToken,
      }

      accessToken && NeobankApi.getInstance().setAuthToken(accessToken)
      const createUserResponse = await NeobankApi.getInstance().userApi.createUser(userCreateRequest)
      const userUuid = createUserResponse.data.uuid
      const {
        activationCode,
        walletId,
        deviceUuid,
        token,
      } = createUserResponse.data
      const uniqueDeviceIdByData = createUserResponse.data.uniqueDeviceId

      dispatch({
        type: SignupTypes.REGISTER_SUCCESS,
        passcode: password,
        userUuid,
        phone,
        walletId,
        activationCode,
        deviceUuid,
      })
      if (deviceUuid) {
        await dispatch(SignInActions.setCurrentDevice({ deviceUuid, uniqueDeviceId: uniqueDeviceIdByData }))
      }
      if (token) {
        NeobankApi.getInstance().setAuthToken(token)
      }
    } catch (error) {
      const errorMessage = error.message
      dispatch({ type: SignupTypes.REGISTER_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const reset = function () {
  return (dispatch: Dispatch) => {
    dispatch({ type: SignupTypes.RESET })
  }
}

export const SignupActions = {
  prepare,
  updateProfile,
  startCompletingOfflineRegistration,
  triggerPhoneVerify,
  verifyPhone,
  triggerEmailVerify,
  verifyEmail,
  checkEmailStatus,
  passwordCheck,
  confirmPassword,
  secretQuestion,
  register,
  reset,
  block,
}

/* -------------- Export for Sagas -------------- */

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export interface SignupProfileState {
  firstName: string | null,
  lastName: string | null,
  countryCode: string | null,
  email: string | null,
  isEmailAvailable: boolean,
  isEmailVerified: boolean,
  isOfflineRegistration: boolean,
  phone: string | null,
  isPhoneVerified: boolean,
  isPasswordChecked: boolean,
  isRegistered: boolean,
  isRegisterChecked: boolean,
  otpPhoneUuid: string | null,
  otpEmailUuid: string | null,
  secretQuestion: string | null,
  secretQuestionResponse: string | null
  password: string[] | null,
  confirmPassword: string[] | null
  locale: string | null
}
export interface SignupState {
  profile: SignupProfileState,
  ui: {
    errorMessage: string | null,
    triggerPhoneVerifyLoading: boolean,
    verifyPhoneLoading: boolean,
    isPhoneVerifyPending: boolean,
    triggerEmailVerifyLoading: boolean,
    verifyEmailLoading: boolean,
    isEmailVerifyPending: boolean,
    registerLoading: boolean,
    passwordCheckLoading: boolean,
    isBlocked: boolean,
  }
}

const initialState: SignupState = {
  profile: {
    countryCode: DEFAULT_LOCALE,
    lastName: null,
    firstName: null,
    email: null,
    isEmailAvailable: false,
    isPasswordChecked: false,
    isEmailVerified: false,
    isOfflineRegistration: false,
    phone: null,
    isPhoneVerified: false,
    isRegistered: false,
    isRegisterChecked: false,
    otpPhoneUuid: null,
    otpEmailUuid: null,
    secretQuestion: null,
    secretQuestionResponse: null,
    password: null,
    confirmPassword: null,
    locale: null,
  },
  ui: {
    errorMessage: null,
    triggerPhoneVerifyLoading: false,
    verifyPhoneLoading: false,
    isPhoneVerifyPending: false,
    triggerEmailVerifyLoading: false,
    verifyEmailLoading: false,
    isEmailVerifyPending: false,
    registerLoading: false,
    passwordCheckLoading: false,
    isBlocked: false,
  },
}

export const signup: Reducer = (state: SignupState = initialState, action: AnyAction) => {
  const errorMessage = action?.errorMessage
  let updatedState

  switch (action.type) {
    case SignupTypes.SIGN_UP_PREPARE:
      return {
        ...initialState,
        profile: {
          ...state.profile,
          locale: action.locale,
        },
      }

    case SignupTypes.UPDATE_PROFILE:
      updatedState = _.merge({}, state, {
        profile: action.profile,
        ui: {
          errorMessage: null,
        },
      })

      if (action.profile && action.profile.phone) {
        return _.merge({}, updatedState, {
          profile: {
            isPhoneVerified: false,
          },
          ui: {
            isPhoneVerifyPending: false,
          },
        })
      }
      if (action.profile && action.profile.email) {
        return _.merge({}, updatedState, {
          profile: {
            isEmailAvailable: false,
            isEmailVerified: false,
          },
          ui: {
            isEmailVerifyPending: false,
          },
        })
      }

      return updatedState

    case SignupTypes.START_COMPLETING_OFFLINE_REGISTRATION:
      NeobankApi.getInstance().setAuthToken(action.token)
      return produce(state, newState => {
        newState.profile.isOfflineRegistration = true
        newState.profile.firstName = action.firstName
        newState.profile.lastName = action.lastName
        newState.profile.phone = action.phone
        newState.profile.otpEmailUuid = action.otpEmailUuid
        newState.profile.isEmailAvailable = true
        newState.profile.isEmailVerified = true
      })

    case SignupTypes.TRIGGER_PHONE_VERIFY_REQUEST:
      return _.merge({}, state, {
        profile: {
          isPhoneVerified: false,
        },
        ui: {
          errorMessage: null,
          triggerPhoneVerifyLoading: true,
        },
      })
    case SignupTypes.TRIGGER_PHONE_VERIFY_SUCCESS:
      return _.merge({}, state, {
        profile: {
          phone: action?.profile?.phone ?? '',
          countryCode: action?.profile?.countryCode ?? '',
          otpPhoneUuid: action?.profile?.otpPhoneUuid,
          email: action?.profile?.email,
          firstName: action?.profile?.firstName,
          lastName: action?.profile?.lastName,
        },
        ui: {
          isPhoneVerifyPending: true,
          triggerPhoneVerifyLoading: false,
        },
      })
    case SignupTypes.TRIGGER_PHONE_VERIFY_FAILURE:
      return _.merge({}, state, {
        ui: {
          isPhoneVerifyPending: false,
          errorMessage: errorMessage,
          triggerPhoneVerifyLoading: false,
        },
      })

    case SignupTypes.VERIFY_PHONE_REQUEST:
      return _.merge({}, state, {
        ui: {
          errorMessage: null,
          verifyPhoneLoading: true,
        },
      })
    case SignupTypes.VERIFY_PHONE_SUCCESS:
      return _.merge({}, state, {
        profile: {
          isPhoneVerified: true,
        },
        ui: {
          verifyPhoneLoading: false,
        },
      })
    case SignupTypes.VERIFY_PHONE_FAILURE:
      return _.merge({}, state, {
        profile: {
          isPhoneVerified: false,
        },
        ui: {
          errorMessage: errorMessage,
          verifyPhoneLoading: false,
        },
      })

    case SignupTypes.TRIGGER_EMAIL_VERIFY_REQUEST:
      return _.merge({}, state, {
        profile: {
          isEmailVerified: false,
          password: null,
          confirmPassword: null,
        },
        ui: {
          errorMessage: null,
          triggerEmailVerifyLoading: true,
        },
      })
    case SignupTypes.TRIGGER_EMAIL_VERIFY_SUCCESS:
      return _.merge({}, state, {
        profile: {
          isEmailAvailable: true,
          email: action.profile?.email,
          otpEmailUuid: action.profile?.otpEmailUuid,
        },
        ui: {
          isEmailVerifyPending: true,
          triggerEmailVerifyLoading: false,
        },
      })
    case SignupTypes.TRIGGER_EMAIL_VERIFY_FAILURE:
      return _.merge({}, state, {
        profile: {
          isEmailAvailable: false,
          email: null,
          otpEmailUuid: null,
        },
        ui: {
          isEmailVerifyPending: false,
          errorMessage: errorMessage,
          triggerEmailVerifyLoading: false,
        },
      })

    case SignupTypes.VERIFY_EMAIL_REQUEST:
    case SignupTypes.CHECK_EMAIL_STATUS_REQUEST:
      return _.merge({}, state, {
        ui: {
          errorMessage: null,
          verifyEmailLoading: true,
        },
      })
    case SignupTypes.VERIFY_EMAIL_WAITING:
    case SignupTypes.CHECK_EMAIL_STATUS_WAITING:
      return _.merge({}, state, {
        profile: {
          otpEmailUuid: action.profile?.otpEmailUuid,
        },
        ui: {
          errorMessage: null,
          verifyEmailLoading: false,
        },
      })
    case SignupTypes.CHECK_EMAIL_STATUS_SUCCESS:
    case SignupTypes.VERIFY_EMAIL_SUCCESS:
      return _.merge({}, state, {
        profile: {
          isEmailVerified: true,
        },
        ui: {
          verifyEmailLoading: false,
        },
      })
    case SignupTypes.CHECK_EMAIL_STATUS_FAILURE:
    case SignupTypes.CHECK_EMAIL_STATUS_FAILURE_C0020:
    case SignupTypes.VERIFY_EMAIL_FAILURE:
      return _.merge({}, state, {
        profile: {
          isEmailVerified: false,
        },
        ui: {
          errorMessage: errorMessage.message,
          verifyEmailLoading: false,
        },
      })

    case SignupTypes.CONFIRM_PASSWORD_CHECK_REQUEST:
    case SignupTypes.PASSWORD_CHECK_REQUEST:
      return _.merge({}, state, {
        ui: {
          errorMessage: null,
          passwordCheckLoading: true,
        },
      })
    case SignupTypes.PASSWORD_CHECK_SUCCESS:
      return _.merge({}, state, {
        profile: {
          isPasswordChecked: true,
          password: action?.password,
          confirmPassword: null,
        },
        ui: {
          passwordCheckLoading: false,
        },
      })
    case SignupTypes.PASSWORD_CHECK_FAILURE:
    case SignupTypes.CONFIRM_PASSWORD_CHECK_FAILURE:
      return _.merge({}, state, {
        profile: {
          isPasswordChecked: false,
        },
        ui: {
          errorMessage: errorMessage.message,
          passwordCheckLoading: false,
        },
      })
    case SignupTypes.CONFIRM_PASSWORD_CHECK_SUCCESS:
      return _.merge({}, state, {
        profile: {
          isPasswordChecked: true,
          confirmPassword: action?.confirmPassword,
        },
        ui: {
          passwordCheckLoading: false,
        },
      })
    case SignupTypes.SAVE_SECRET_QUESTION:
      return _.merge({}, state, {
        profile: {
          secretQuestion: action?.secretQuestion,
          secretQuestionResponse: action?.secretQuestionResponse,
        },
      })

    case SignupTypes.REGISTER_REQUEST:
      return _.merge({}, state, {
        ui: {
          errorMessage: null,
          registerLoading: true,
        },
      })
    case SignupTypes.REGISTER_SUCCESS:
      return _.merge({}, state, {
        profile: {
          isRegistered: true,
        },
        ui: {
          registerLoading: false,
        },
      })
    case SignupTypes.REGISTER_FAILURE:
      return _.merge({}, state, {
        profile: {
          isRegistered: false,
        },
        ui: {
          errorMessage: errorMessage.message,
          registerLoading: false,
        },
      })

    case SignupTypes.BLOCK:
      return _.merge({}, state, {
        ...initialState,
        ui: {
          ...initialState.ui,
          isBlocked: true,
        },
      })

    case SignupTypes.RESET:
      return { ...initialState }

    default:
      return state
  }
}
