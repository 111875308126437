import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Image, Pressable, StyleSheet, View } from 'react-native'

import { StateTypes } from 'Store'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { IndividualSubscriptionStep, IndividualSubscriptionSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Icon, IconTypes, Typography2, Flags, Stepper2, CountryList } from '@neo-commons/components'
import { CountryUtils, SelectionMode } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { CountryDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './TaxResidence.scss'

const styles = StyleSheet.create({
  label: {
    display: 'flex',
    position: 'relative',
    width: 'fit-content',
    left: 15,
    top: 28,
    backgroundColor: Colors.white,
    paddingHorizontal: 4,
    color: Colors.gray2,
    marginTop: -24,
  },
  country: {
    marginRight: 8,
    paddingHorizontal: 16,
    paddingTop: 10,
    paddingBottom: 8,
    borderWidth: 1,
    borderColor: '#AAAAAA',
    borderRadius: 12,
  },
})

type TaxResidenceProps =
  StepComponentProps<IndividualSubscriptionSteps[IndividualSubscriptionStep.TaxResidence]>

export const TaxResidence: React.FC<TaxResidenceProps> = ({ nextStep }: TaxResidenceProps) => {
  const { t } = useTranslation()

  const countries: CountryDto[] = useSelector(
    (state: StateTypes) => state.config?.data?.currentConfig?.countries ?? []
  )
  const authorizedCountries = CountryUtils.filterByAuthorized(countries)
  CountryUtils.sortByName(authorizedCountries)

  const userState = useSelector((state: StateTypes) => state.user.data)
  const [selectedCountry, setSelectedCountry] = useState<CountryDto>()
  const [isOpen, setIsOpen] = useState(false)
  const flag = Flags[selectedCountry?.isoCodeAlpha3 ?? 'Unknown'] ?? Flags.Unknown

  const onNextStep = () => {
    try {
      if (selectedCountry) {
        nextStep({ taxDomiciliationCountryCode: selectedCountry.isoCodeAlpha2 }).onDone(() => {
          AnalyticsHandler.track(
            AnalyticsEvents.signUp.userCountrySelected,
            { residence_country_code: selectedCountry.isoCodeAlpha2 }
          )
          AnalyticsHandler.identify(
            userState ? userState.uuid : null,
            { first_account_created_residence_country_code: selectedCountry.isoCodeAlpha2 }
          )
        })
      }
    } catch (error) {}
  }

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      scrollableBody
      header={
        <div className='TaxResidence_header'>
          <Typography2 typeface='h3'>
            {t('app:pages:subscription:individual:mainTitle:taxResidence')}
          </Typography2>
          <Stepper2 currentStep={0} />
          <div className='TaxResidence_header_title'>
            <Typography2 typeface='h4'>
              {t('app:pages:subscription:individual:taxResidence:title')}
            </Typography2>
          </div>
        </div>
      }
      footer={
        selectedCountry && (
          <Button
            skin={ButtonSkin.PRIMARY}
            flat
            title={t('app:global:validate')}
            onPress={onNextStep}
            disabled={!selectedCountry}
          />)
      }
    >
      <div className='TaxResidence_container'>
        <div className='TaxResidence_container-custom-dropdown'>
          <Pressable
            onPress={() => setIsOpen(!isOpen)}
            style={styles.country}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {
                selectedCountry &&
                  <Image
                    style={{ width: 20, height: 20, marginTop: 2, marginRight: 8 }}
                    source={flag}
                  />
              }
              <Typography2
                typeface='body1'
                style={selectedCountry ? {} : { color: Colors.lightGray }}
              >
                {selectedCountry ? selectedCountry.name : t('neo-commons:global:select')}
              </Typography2>
              <View
                style={{ marginLeft: 'auto' }}
              >
                <Icon
                  color={Colors.gray2}
                  name='chevron-down'
                  type={IconTypes.ENTYPO}
                  size={24}
                />
              </View>
            </div>
          </Pressable>
          {isOpen &&
            <div
              className='custom-dropdown'
            >
              <CountryList
                countries={countries}
                selectionMode={SelectionMode.LIVE_COUNTRY}
                hidePrefix
                onCountrySelectedAction={(country) => {
                  setIsOpen(false)
                  setSelectedCountry(country)
                }}
              />
            </div>}
        </div>
      </div>
    </TunnelContent>
  )
}
