import React, { useCallback, useEffect } from 'react'
import { View, Image } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { StateTypes } from 'Store'
import { TunnelContent } from 'UI/Components/Layout/TunnelContent'
import { AnalyticsHandler, AnalyticsEvents } from 'Services/Analytics'
import { AppConfig } from 'Config/AppConfig'
import { AsYouType, CountryCode, parsePhoneNumber } from 'libphonenumber-js'

import {
  DeviceRecoveryActions,
  Dispatch,
  SignInActions,
  SignInState,
  SignupProfileState,
  SignupState,
} from '@neo-commons/store'
import { usePasswordKeypad } from '@neo-commons/hooks'
import { RegisterStep, RegisterSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin, Icon, IconTypes, ReadOnlyInput, Stepper2, Typography2 } from '@neo-commons/components'
import { CountryUtils, PhoneNumberUtils, SelectionMode } from '@neo-commons/libraries'
import { FlagsImages } from '@neo-commons/components/assets/images/flags'

import { CountryDto, KeypadFieldDto, OsTypeDto } from '@afone/neo-core-client/dist/models'

import { BrowserUtils } from '@neo-web/utils'
import { Animation } from '@neo-web/componentsOld'

import { Routes } from '../../../../../Routes/Routes'

type RegisterStep5Props = StepComponentProps<RegisterSteps[RegisterStep.Complete]>

export const RegisterStep5: React.FC<RegisterStep5Props> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<Dispatch>()
  const history = useHistory()

  const profile: SignupProfileState = useSelector((state: StateTypes) => state?.signup?.profile)
  const countries: CountryDto[] = useSelector(
    (state: StateTypes) => {
      return CountryUtils.filterByAuthorized(
        state.config?.data?.currentConfig?.countries ?? [],
        SelectionMode.PHONE_PREFIX
      )
    }
  )

  useEffect(() => {
    AnalyticsHandler.track(
      AnalyticsEvents.signUp.userSignUpCompleted,
      {
        step_name: RegisterStep.Complete,
        email: profile?.email,
        phone: profile && parsePhoneNumber(profile.phone, profile.countryCode as CountryCode)
          .formatInternational().replace(/ /g, ''),
      }
    )
  }, [])

  const phoneFormatter = useCallback((value: string) =>
    PhoneNumberUtils.isAllowForApp(value || '', profile.countryCode as CountryCode)
      ? new AsYouType(profile.countryCode as CountryCode).input(value)
      : value, [profile.countryCode as CountryCode])

  const flagCode = countries.find(country =>
    country.isoCodeAlpha2 === (profile?.phone && profile?.phone.startsWith('+')
      ? parsePhoneNumber(profile?.phone)?.country : profile?.countryCode)).isoCodeAlpha3
  const flag = FlagsImages[flagCode ?? 'Unknown'] ?? FlagsImages.Unknown

  const signInState: SignInState = useSelector((state: StateTypes) => state?.signIn)
  const signUpState: SignupState = useSelector((state: StateTypes) => state?.signup)
  const passwordKeypadData = usePasswordKeypad({
    phone: signUpState.profile?.phone,
    phoneCountryCode: signUpState.profile?.countryCode,
    field: KeypadFieldDto.PASSWORD,
    passwordSize: AppConfig.PASS_CODE_SIZE,
  })
  const browserInfo = BrowserUtils.getDeviceBrowserInformation()

  const deviceCreateOrUpdate = async () => {
    const device: any = await dispatch(DeviceRecoveryActions.createOrUpdate({
      os: OsTypeDto.WEB,
      deviceName: browserInfo.name,
      userUuid: signInState.data.id,
      uniqueDeviceId: signInState?.uniqueDeviceId,
      browserInfo: browserInfo,
      noSuccessToast: true,
    }))
    if (signInState?.uniqueDeviceId !== device.uniqueDeviceId) {
      await dispatch(SignInActions.setCurrentDevice(
        { deviceUuid: device.uuid, uniqueDeviceId: device.uniqueDeviceId }
      ))
    }
  }

  const signIn = async () => {
    try {
      await deviceCreateOrUpdate()
      await dispatch(SignInActions.signIn(signUpState?.profile?.password))
    } catch (e) {
      passwordKeypadData.reset(e)
    }
  }

  return (
    <TunnelContent
      header={
        <View>
          <Animation />
          <Typography2 typeface='h3' style={{ marginBottom: 16 }}>
            {t('app:pages:register:stepper:title')}
          </Typography2>
          <Stepper2 currentStep={2} />
        </View>
      }
      footer={
        <>
          <Button
            skin={ButtonSkin.PRIMARY}
            title={t('app:pages:subscription:global:offers:chooseOffer')}
            onPress={async () => {
              await signIn()
              nextStep()
            }}
          />
          <Button
            style={{ borderColor: 'white' }}
            skin={ButtonSkin.TRANSPARENT_PRIMARY}
            title={t('app:global:later')}
            onPress={async () => {
              await signIn()
              history.push(Routes.Synthesis.index.path)
              AnalyticsHandler.track(
                AnalyticsEvents.signUp.userSignUpCompleted,
                {
                  email: profile?.email,
                  phone: profile && parsePhoneNumber(profile.phone, profile.countryCode as CountryCode)
                    .formatInternational().replace(/ /g, ''),
                }
              )
            }}
          />
        </>
      }
    >
      <View style={{ alignItems: 'center', width: '100%' }}>
        <ReadOnlyInput
          label={t('app:pages:register:stepper:firstName')}
          value={profile?.firstName}
          rightIcon={<View style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></View>}
        />
        <ReadOnlyInput
          label={t('app:pages:register:stepper:lastName')}
          value={profile?.lastName}
          rightIcon={<View style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></View>}
        />
        <ReadOnlyInput
          label={t('app:pages:register:stepper:phoneNumber')}
          value={phoneFormatter(profile?.phone)}
          leftIcon={<Image style={{ width: 22, height: 16, marginRight: 40 }} source={flag} />}
          rightIcon={<View style={{ }}><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></View>}
        />
        <ReadOnlyInput
          label={t('app:pages:register:stepper:eMailAddress')}
          value={profile?.email}
          rightIcon={<View><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></View>}
        />
        <ReadOnlyInput
          label={t('app:pages:register:stepper:personalCode')}
          value={'•'.repeat(profile?.password?.length)}
          rightIcon={<View><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></View>}
        />
        <ReadOnlyInput
          label={t('app:pages:register:stepper:secretQuestion')}
          value={profile?.secretQuestion}
          rightIcon={<View><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></View>}
        />
        <ReadOnlyInput
          label={t('app:pages:register:stepper:response')}
          value={profile?.secretQuestionResponse}
          rightIcon={<View><Icon name='check' type={IconTypes.FEATHER} size={25} color='#22AF74' /></View>}
        />
      </View>
    </TunnelContent>
  )
}
