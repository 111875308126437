
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { Tunnel } from '@neo-web/componentsOld'

import { SeaCguPedagogy } from './SeaCguPedagogy/SeaCguPedagogy'
import { ElectronicSignature } from './ElectronicSignature/ElectronicSignature'
import { ElectronicSignatureFallback } from './ElectronicSignatureFallback/ElectronicSignatureFallback'
export const SeaCgu: React.FC = () => {
  return (
    <Tunnel
      footer
      onBack={undefined}
      component={
        <>
          <Redirect to={Routes.Subscription.SeaCgu.seaCguPedagogy.path} />
          <Route
            path={Routes.Subscription.SeaCgu.seaCguPedagogy.path}
            component={SeaCguPedagogy}
          />
          <Route
            path={Routes.Subscription.SeaCgu.electronicSignature.path}
            component={ElectronicSignature}
          />
          <Route
            path={Routes.Subscription.SeaCgu.electronicSignatureFallback.path}
            component={ElectronicSignatureFallback}
          />
        </>
      }
    />
  )
}
