import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'

import { PosByAccount, POSUtils } from '@neo-commons/libraries'
import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { POSReturnStep, POSReturnSteps, StepComponentProps } from '@neo-commons/policies'
import {
  BankAccountSelectors,
  PosActions,
  PosSelectors,
  PosTypes,
  PreparePOSReturn,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { AccountDto, PosDto, PosStatusDto, SubscriptionDto } from '@afone/neo-core-client/dist/models'

import {
  JustifyContent,
  PageContent,
  ShimmerList,
  ShimmerListType,
  SuspenseLoading,
} from '@neo-web/componentsOld'

import { PosByAccounts } from '../../../Components/PosByAccounts/PosByAccounts'

import { TelecollectionModal } from './TelecollectionModal'

import './SelectPos.scss'

type POSReturnProps = StepComponentProps<POSReturnSteps[POSReturnStep.SelectPos]>

export const SelectPos: React.FC<POSReturnProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const preparedPOSReturn: PreparePOSReturn = useSelector(PosSelectors.prepareReturn)
  const pos: PosDto[] = useSelector(PosSelectors.listPos())
  const loading = useSelector((state: StateTypes) => state.pos?.loading)
  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listAccounts())
  const subscriptions: SubscriptionDto[] = useSelector(SubscriptionSelectors.list)

  const posList = POSUtils.filterPosByStatus(pos, subscriptions, PosStatusDto.ACTIVE)
  const posByAccounts: PosByAccount = POSUtils.groupPosByAccounts(bankAccounts, posList)

  const [showModal, setShowModal] = useState(false)
  const [selectedPos, setSelectedPos] = useState<PosDto[]>(preparedPOSReturn?.pos ?? [])

  useEffect(() => {
    if (!pos.length) {
      dispatch(PosActions.list(bankAccounts))
    }
  }, [])

  const onConfirm = async () => {
    await dispatch(PosActions.prepareReturn({
      ...preparedPOSReturn,
      pos: selectedPos,
    }))
    await dispatch(PosActions.getTelecollections(selectedPos))
    if (preparedPOSReturn?.hasTlc) {
      setShowModal(true)
    } else {
      nextStep()
    }
  }

  return (
    <PageContent
      justifyContent={JustifyContent.BETWEEN}
      title={t('neo-commons:pages:services:pos:return:selectPos:title')}
      titleInBody={loading || !!posList.length}
      footer={(
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:pages:services:pos:order:selectModel')}
          disabled={!posList.length || !selectedPos.length}
          onPress={onConfirm}
        />
      )}
    >
      <SuspenseLoading
        events={[PosTypes.LIST_POS_REQUEST]}
        fallback={<ShimmerList type={ShimmerListType.POS} fading />}
      >
        <TelecollectionModal
          open={showModal}
          onClose={() => setShowModal(false)}
          nextStep={() => nextStep()}
        />
        {!posList.length ? (
          <div className='SelectPos_noPos'>
            <Icon name='alert-circle' type={IconTypes.FEATHER} size={40} color={Colors.secondary} />
            <Typography typeface='subtitle'>
              {t('neo-commons:pages:services:pos:return:selectPos:noPos')}
            </Typography>
          </div>
        ) : (
          <PosByAccounts
            posList={posByAccounts}
            selected={selectedPos}
            onPress={(pos) => {
              const newSelection = selectedPos.indexOf(pos) === -1
                ? (POSUtils.returnReasonFailure(preparedPOSReturn?.returnReason) ? [pos] : [...selectedPos, pos])
                : selectedPos.filter(selected => selected.uuid !== pos.uuid)
              setSelectedPos(newSelection)
            }}
          />
        )}
      </SuspenseLoading>
    </PageContent>
  )
}
