import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Settings } from 'react-slick'

import { CardActions, CardSelectors, CardTypes, CreditCardData } from '@neo-commons/store'

import { CardDto } from '@afone/neo-core-client/dist/models'

import { Carousel, ItemCarousel, LazyLoadingContainer } from '@neo-web/componentsOld'

import { SensitiveInfo } from '../../DebitCard/Settings/Settings'
import { ServicesRoutes } from '../../../../../Routes/Services/ServicesRoutes'
import { ActivateCardPolicy } from '../../../../../Policies/DebitCard/ActivateCardPolicy'

import { CarouselItem } from './CarouselItem'

import './SettingsCarousel.scss'

interface SettingsCarouselProps {
  cards: CardDto[],
  onDisplayNumber: (CARD: SensitiveInfo.CARD, uuid: string) => void
}

export const SettingsCarousel: React.FC<SettingsCarouselProps> = (props) => {
  const { cards, onDisplayNumber } = props
  const dispatch = useDispatch()

  const [sliderSettings] = useState<Settings>({ slidesToShow: 2 })
  const [itemsCarousel, setItemsCarousel] = useState<ItemCarousel[]>([])
  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)
  const history = useHistory()

  const selectDebitCard = (uuidCard?: string) => {
    dispatch(CardActions.setSelected(!uuidCard ? cards[0].uuid : uuidCard))
  }

  useEffect(() => {
    const carouselItems: ItemCarousel[] = []

    if (cards && cards.length > 0) {
      selectDebitCard(selectedCard?.uuid)
    }
    if (selectedCard) {
      cards.map((card, index) => {
        carouselItems.push({
          index: index,
          component: (
            <CarouselItem
              card={card}
              isSelected={card.uuid === selectedCard.uuid}
              onDisplayNumber={() => ActivateCardPolicy.canActivate(card)
                ? history.push(ServicesRoutes.activateCard.path)
                : onDisplayNumber(SensitiveInfo.CARD, card.uuid)}
            />
          ),
          defaultActive: card.uuid === selectedCard.uuid,
          onActive: () => selectDebitCard(card.uuid),
        })
      })
      setItemsCarousel(carouselItems)
    }
  }, [dispatch, selectedCard])

  return (
    <LazyLoadingContainer events={[CardTypes.ACTIVATE_CARD_REQUEST]} className='SettingsCarousel'>
      {itemsCarousel.length > 0 &&
        <Carousel items={itemsCarousel} sliderSettings={sliderSettings} noActiveShadow />}
    </LazyLoadingContainer>
  )
}
