import { StyleSheet } from 'react-native'

import { Typo } from '@neo-commons/styles'

const styles = StyleSheet.create({
  TextTimer: {
    ...Typo.content,
    fontSize: 14,

  },
  TimerCount: {
    ...Typo.smallText,
    fontSize: 14,
  },
})

export default styles
