import { AnyAction, Reducer } from 'redux'
import { omit } from 'lodash'
import i18next from 'i18next'
import { DeviceDto, DeviceUpdateOrCreateDto, OsTypeDto } from '@afone/neo-core-client/dist/models'
import { DeviceBrowserInfoDto, NeobankApi } from '@neo-commons/services'

import { Dispatch, resourceDefaultSelectors, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { AlertType, initialResourceState, State } from '../utils'

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export enum ScaType {
  WALLET = 'WALLET',
  OTPEMAIL = 'OTP_EMAIL',
  OTPPHONE = 'OTP_PHONE',
  PIN = 'PIN'
}

export const LOCK_DEVICE_CODE = 'C0203'

export type DeviceRecovery = {
  prepare: {
    otpPhoneUuid?: string,
    secretQuestionAnswer?: string,
    secretQuestionUuid?: string,
    isOtpVerified?: boolean,
    type?: ScaType,
  }
}

const {
  resourceActionTypes: DeviceRecoveryActionTypes,
  resourceReducer: DeviceRecoveryResourceReducer,
  resourceAction: DeviceRecoveryAction,
} = ResourceStateFactory<DeviceRecovery, 'deviceRecovery'>((state: State) => state.deviceRecovery, 'deviceRecovery')

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

const RECOVER_DEVICE_FAILURE = 'device/RECOVER_DEVICE_FAILURE'
const RECOVER_DEVICE_SUCCESS = 'device/RECOVER_DEVICE_SUCCESS'
const RECOVER_DEVICE_REQUEST = 'device/RECOVER_DEVICE_REQUEST'

const TRIGGER_OTP_VERIFY_FAILURE = 'device/TRIGGER_OTP_VERIFY_FAILURE'
const TRIGGER_OTP_VERIFY_SUCCESS = 'device/TRIGGER_OTP_VERIFY_SUCCESS'
const TRIGGER_OTP_VERIFY_REQUEST = 'device/TRIGGER_OTP_VERIFY_REQUEST'

const VERIFY_OTP_CODE_FAILURE = 'device/VERIFY_OTP_CODE_FAILURE'
const VERIFY_OTP_CODE_SUCCESS = 'device/VERIFY_OTP_CODE_SUCCESS'
const VERIFY_OTP_CODE_REQUEST = 'device/VERIFY_OTP_CODE_REQUEST'

const LOCK_DEVICE_SUCCESS = 'deviceRecovery/LOCK_DEVICE_SUCCESS'

export const DeviceRecoveryTypes = {
  ...DeviceRecoveryActionTypes,

  TRIGGER_OTP_VERIFY_REQUEST,
  TRIGGER_OTP_VERIFY_SUCCESS,
  TRIGGER_OTP_VERIFY_FAILURE,

  LOCK_DEVICE_SUCCESS,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

const createOrUpdate = (
  payload: {
    deviceName: string,
    os: OsTypeDto,
    deviceToken?: string,
    uniqueDeviceId?: string,
    userUuid?: string,
    browserInfo?: DeviceBrowserInfoDto,
    noSuccessToast?: boolean,
  }) => {
  return async (dispatch: Dispatch) => {
    const { deviceName, deviceToken, uniqueDeviceId, os, userUuid, browserInfo, noSuccessToast } = payload
    dispatch({ type: noSuccessToast ? RECOVER_DEVICE_REQUEST : DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_REQUEST })

    try {
      const params: DeviceUpdateOrCreateDto = {
        token: deviceToken!,
        name: deviceName,
        os: os,
        uniqueDeviceId,
        userUuid,
        ...browserInfo,
      }

      const getUpdateDeviceResponse =
        await NeobankApi.getInstance().deviceApi.createOrUpdate(params)
      dispatch({
        type: noSuccessToast ? RECOVER_DEVICE_SUCCESS : DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_SUCCESS,
        data: getUpdateDeviceResponse.data,
        deviceUuid: getUpdateDeviceResponse.data.uuid,
        alertType: AlertType.SUCCESS,
        errorMessage: i18next.t('neo-commons:scaChannel:success'),
      })
      return getUpdateDeviceResponse.data
    } catch (error) {
      const errorMessage = error.message ?? i18next.t('errors:unknownTechnicalIssue')
      dispatch({
        type: DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_FAILURE,
        errorMessage,
        isLock: error.code === LOCK_DEVICE_CODE,
      })
      throw error
    }
  }
}

const lockDevice = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DeviceRecoveryTypes.LOCK_DEVICE_SUCCESS })
  }
}

export const DeviceRecoveryActions = {
  ...DeviceRecoveryAction,
  createOrUpdate,
  lockDevice,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type DeviceRecoveryState = Omit<ResourceState<DeviceRecovery>, 'create' | 'update' | 'data'> & {
  prepare: {
    otpPhoneUuid?: string,
    secretQuestionAnswer?: string,
    secretQuestionUuid?: string,
    isOtpVerified?: boolean,
    type?: ScaType
  },
  data: {
    device?: DeviceDto,
  }
  isLock: boolean
}

const initialState: DeviceRecoveryState = {
  ...omit(initialResourceState, 'create', 'update'),
  prepare: {
    otpPhoneUuid: '',
    secretQuestionAnswer: '',
    secretQuestionUuid: '',
    isOtpVerified: false,
    type: undefined,
  },
  data: {
    device: undefined,
  },
  isLock: false,
}

/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const DeviceRecoverySelector = state => state.deviceRecovery

export const DeviceRecoverSelectors = {
  ...resourceDefaultSelectors(DeviceRecoverySelector),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const deviceRecovery: Reducer = (state = initialState, action: AnyAction): DeviceRecoveryState => {
  switch (action.type) {
    case DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_REQUEST:
    case RECOVER_DEVICE_REQUEST:
    case VERIFY_OTP_CODE_REQUEST:
    case TRIGGER_OTP_VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case TRIGGER_OTP_VERIFY_SUCCESS:
      return {
        ...state,
        prepare: {
          ...state.prepare,
          ...action.data,
        },
      }

    case VERIFY_OTP_CODE_SUCCESS:
      return {
        ...state,
        prepare: {
          ...state.prepare,
          isOtpVerified: true,
        },
      }

    case DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_SUCCESS:
    case RECOVER_DEVICE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, device: { ...action.data } },
        loading: false,
      }

    case DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
        isLock: action.isLock,
      }

    case DeviceRecoveryTypes.LOCK_DEVICE_SUCCESS:
      return {
        ...state,
        isLock: true,
      }

    case RECOVER_DEVICE_FAILURE:
    case VERIFY_OTP_CODE_FAILURE:
    case TRIGGER_OTP_VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      }

    default:
      return {
        ...DeviceRecoveryResourceReducer(state, action, {
          identifier: 'uuid',
          isPaginate: false,
          initialState,
        }),
      }
  }
}
