import React, { FunctionComponent } from 'react'
import { Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { Colors } from '@neo-commons/styles'
import { ScaAuthenticationErrorTypeDto } from '@afone/neo-core-client/dist/models'

import { Badge, BadgeSkin, Card, CardSkin, Icon } from '..'
import { IconTypes } from '../Icon/Icon'

import styles from './styles'

export type DeviceMethod = {
  type: ScaAuthenticationErrorTypeDto,
  description: string,
  isPreview?: boolean
}

export interface ScaChannelCardProps {
  methods: DeviceMethod[],
  onItemPress?: (method: DeviceMethod) => void,
  selectedMethod?: DeviceMethod,
}

export const ScaChannelCard: FunctionComponent<ScaChannelCardProps> = (
  { methods, onItemPress, selectedMethod }) => {
  const { t } = useTranslation()

  const generateIcon = (type: ScaAuthenticationErrorTypeDto) => {
    let name = ''
    let iconType: IconTypes = IconTypes.MATERIAL_COMMUNITY

    switch (type) {
      case ScaAuthenticationErrorTypeDto.OTP_PHONE:
        name = 'message-processing-outline'
        iconType = IconTypes.MATERIAL_COMMUNITY
        break
      case ScaAuthenticationErrorTypeDto.WALLET:
        name = 'smartphone'
        iconType = IconTypes.FEATHER
        break
      case ScaAuthenticationErrorTypeDto.OTP_EMAIL:
        name = 'mail'
        iconType = IconTypes.FEATHER

        break
    }

    return { name, iconType }
  }

  return (
    <View>
      {methods.map((item, index) =>
        <Card
          skin={selectedMethod && selectedMethod.type === item.type ? CardSkin.PRIMARY : CardSkin.TOUCHABLE}
          onPress={() => {
            onItemPress && onItemPress(item)
          }}
          withShadow
          key={index}
          containerStyle={styles.cardContainer}
        >
          <View style={styles.deviceContainer}>
            <Icon
              name={generateIcon(item.type).name}
              type={generateIcon(item.type).iconType!}
              size={24}
              color={selectedMethod && selectedMethod.type === item.type ? Colors.white : Colors.primary}
            />
            <View style={styles.textArea}>
              <Text
                accessibilityLabel={t('neo-commons:scaChannel:' + item.type)} style={[styles.deviceName,
                  selectedMethod && selectedMethod.type === item.type && { color: Colors.white }]}
              >
                {t('neo-commons:scaChannel:' + item.type)}
              </Text>
              <View style={styles.descriptionContainer}>
                <Text style={[styles.lastAuth,
                  selectedMethod && selectedMethod.type === item.type && { color: Colors.white }]}
                >
                  {item.description}
                </Text>
                {(item.type === ScaAuthenticationErrorTypeDto.WALLET) &&
                  <Badge
                    skin={BadgeSkin.WARNING}
                    overrideContainerStyle={styles.badge}
                    value={t('neo-commons:scaChannel:advise')}
                  />}
              </View>
            </View>
          </View>

        </Card>
      )}
    </View>
  )
}
