import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { SignupActions } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'
import { Icon, IconTypes } from '@neo-commons/components'

import { UrlUtils } from '@neo-web/utils'
import { Tunnel, TunnelContent } from '@neo-web/componentsOld'

import './Mail.scss'

export const ConfirmEmail: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { code, otpEmailUuid } = UrlUtils.deserialize(useLocation().search)
  const [message, setMessage] = useState<string>(t('app:pages:external:mail:saveSuccess'))

  const [icon, setIcon] = useState(
    <Icon
      color={Colors.secondary}
      name='check-circle'
      size={40}
      type={IconTypes.FEATHER}
    />
  )

  useEffect(() => {
    AnalyticsHandler.track(AnalyticsEvents.KYC.documentValidationEmailClicked)
  }, [])

  useEffect(() => {
    if (code && otpEmailUuid) {
      (async () => {
        try {
          await dispatch(SignupActions.verifyEmail({ otpEmailUuid, code }))
        } catch (e) {
          setIcon(
            <Icon
              color={Colors.secondary}
              name='alert-circle-outline'
              size={40}
              type={IconTypes.MATERIAL_COMMUNITY}
            />
          )
          setMessage(e.message)
        }
      })()
    }
  }, [code, otpEmailUuid])

  return (
    <Tunnel
      component={
        <TunnelContent
          icon={icon}
          subtitle={message}
          description={t('app:pages:external:mail:mailConfirmedInfo')}
        />
      }
    />
  )
}
