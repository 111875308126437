import { PosActions } from '@neo-commons/store'

import { createWizardPolicy, invokeActionCreator } from '../../utils'

const machineId = 'posLost'

/*
 * Steps
 */

export enum POSLostStep {
  SelectPos = 'SelectPos',
  Summary = 'Summary',
  Failure = 'Failure',
  Success = 'Success',
}

export const posLostSteps = {
  [POSLostStep.SelectPos]: {
    path: 'selectPos',
    nextStep: POSLostStep.Summary,
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:lost:title',
      canGoBack: true,
    },
  },
  [POSLostStep.Summary]: {
    path: 'summary',
    nextStep: POSLostStep.Success,
    fulfill: invokeActionCreator(PosActions.lostPos),
    onErrorRedirectStep: POSLostStep.Failure,
    editableSteps: [
      POSLostStep.SelectPos,
    ],
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:lost:summary:title',
      canGoBack: true,
    },
  },
  [POSLostStep.Success]: {
    path: 'success',
    nextStep: 'final',
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:lost:title',
      canGoBack: false,
    },
  },
  [POSLostStep.Failure]: {
    path: 'failure',
    nextStep: 'final',
    meta: {
      headerTitle: 'neo-commons:pages:services:pos:lost:title',
      canGoBack: false,
    },
  },
}

export type POSLostSteps = typeof posLostSteps

/*
 * Policy initialization
 */
export const posLostPolicy = createWizardPolicy({
  machineId: machineId,
  steps: posLostSteps,
  initialStep: POSLostStep.SelectPos,
  basePath: '/services/pos/lost',
})
