import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { AppConfig } from 'Config/AppConfig'
import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import {
  BankAccountSelectors,
  CardActions,
  CardSelectors,
  CardTypes,
  Client,
  ClientSelectors,
  CreditCardData,
  OfferSelectors,
  PrepareCardOrder,
  SubscriptionActions,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { CreditCardUtils } from '@neo-commons/libraries'

import {
  AccountDto,
  AccountStatusDto,
  ClientDto,
  ClientTypeDto,
  OfferDto,
  OfferTypeDto,
  ProductDto,
  SubscriptionStatusDto,
} from '@afone/neo-core-client/dist/models'

import { LazyLoadingContainer, PageContent } from '@neo-web/componentsOld'

import { Settings } from './Settings/Settings'
import { ListCardFailure } from './Settings/ListCardFailure'

export const DebitCard: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  // shallowEqual https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  const selectedBankAccount: AccountDto = useSelector(BankAccountSelectors.selected, (prev, current) => prev?.uuid === current?.uuid)
  const selectedBankAccountClient: Client = useSelector(ClientSelectors.byId(selectedBankAccount?.clientUuid))
  const cardState = useSelector((state: StateTypes) => state.card)
  const balanceTooSmall = (selectedBankAccount?.balance < AppConfig.MIN_CREDIT_AMOUNT || selectedBankAccount?.status === AccountStatusDto.WAITING_KYC)

  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)
  const offers: OfferDto[] = useSelector(OfferSelectors.listByType(OfferTypeDto.CARD))
  const selectedCardOffer = offers?.find(offer => offer.offerCode === selectedCard?.offerCode)
  const cardProduct = (selectedCardOffer?.products as (ProductDto & {data: {productType?: string}})[])?.filter(product => product?.data?.productType === 'plasticCard')
  const cardSubscription = useSelector(SubscriptionSelectors.listCard)
    .find(subscription => subscription?.offerCode === selectedCard?.offerCode && (subscription?.status === SubscriptionStatusDto.ACTIVE || subscription?.status === SubscriptionStatusDto.SUBSCRIBING))
  const client: ClientDto = useSelector(ClientSelectors.defaultOne, (prev, current) => prev?.uuid === current?.uuid)
  const cardOwnerUuid: string|undefined = CreditCardUtils.getDefaultCardOwnerUuid(client)

  const [hasError, setHasError] = useState(false)
  const [selectedBankAccountId, setSelectedBankAccountId] = useState('')

  if (!selectedBankAccountClient) {
    return (
      <div className='classic-box'>
        <PageContent
          icon={<Icon type={IconTypes.FEATHER} name='alert-circle' color={Colors.secondary} size={50} />}
          title={t('app:pages:services:unavailable')}
        />
      </div>)
  }

  const initContent = (
    <PageContent
      icon={<Icon type={IconTypes.NEOFONT} name='payment' color={Colors.secondary} size={55} />}
      title={t('app:pages:debitCard:noCard:messages:default')}
      description={t(balanceTooSmall
        ? 'app:pages:debitCard:noCard:messages:balanceTooSmall'
        : (client.type === ClientTypeDto.INDIVIDUAL
          ? 'app:pages:debitCard:noCard:messages:activateIncludedCard'
          : 'app:pages:debitCard:noCard:messages:activateCardBusiness')
      )}
      button={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t(balanceTooSmall ? 'app:pages:debitCard:noCard:funds:add' : 'app:pages:debitCard:noCard:card:add')}
          onPress={() => {
            if (balanceTooSmall) {
              history.push(Routes.Credit.creditChoices.path, { background: location })
            } else {
              if (cardOwnerUuid) {
                history.push(Routes.Services.choicesDebitCard.path)
                dispatch(CardActions.prepare({ personUuid: cardOwnerUuid } as PrepareCardOrder))
              } else {
                // Enable select cardOwner if not found
                history.push(Routes.Services.selectCardOwner.path)
                dispatch(CardActions.prepare({} as PrepareCardOrder))
              }
            }
          }}
        />
      }
    />
  )

  useEffect(() => {
    dispatch(SubscriptionActions.getProductPrice({ subscriptionUuid: cardSubscription?.uuid, productCode: cardProduct?.[0]?.productCode }))
  }, [cardSubscription])

  useEffect(() => {
    (async () => {
      try {
        const bankAccountChanged = selectedBankAccountId !== selectedBankAccount?.uuid
        if (!cardState.loading && (!cardState?.list?.loadedOnce || bankAccountChanged)) {
          if (bankAccountChanged) setSelectedBankAccountId(selectedBankAccount?.uuid)
          await dispatch(CardActions.list())
        }
        if (hasError) {
          setHasError(false)
        }
      } catch (error) {
        setHasError(true)
      }
    })()
  }, [selectedBankAccount, dispatch, cardState])

  return (
    <LazyLoadingContainer events={[CardTypes.LIST_CARD_REQUEST]}>
      {hasError ? <ListCardFailure />
        : cardState?.list?.ids.length > 0
          ? <Settings />
          : initContent}
    </LazyLoadingContainer>
  )
}
