import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconTypes, Icon, Button, ButtonSkin } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import './ListCardFailure.scss'

export const ListCardFailure: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className='ListCardFailure_container'>
      <Icon type={IconTypes.FEATHER} name='x-circle' color={Colors.error} size={55} />
      <p>
        {t('errors:internalTechnicalIssue')}
      </p>
      <div className='ListCardFailure_button'>
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:global:tryAgain')}
          onPress={() => window.location.reload()}
        />
      </div>
    </div>
  )
}
