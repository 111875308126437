import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'
import { AnalyticsEvents, AnalyticsHandler } from 'Services/Analytics'

import { ClientActions, ClientSelectors, ConfigActions } from '@neo-commons/store'
import { StepComponentProps, BusinessSubscriptionSteps, BusinessSubscriptionStep } from '@neo-commons/policies'
import { Button, ButtonSkin, MenuItemCard, Stepper2, Typography2 } from '@neo-commons/components'

import { ClientDto, ClientTypeDto, LegalFormDto } from '@afone/neo-core-client/dist/models'

import { Modal, TunnelContent } from '@neo-web/componentsOld'

import { agentClientUserManager } from '../../../../../Config/AgentClientUserManager'
import './LegalStatus.scss'

type LegalStatusProps = StepComponentProps<BusinessSubscriptionSteps[BusinessSubscriptionStep.LegalStatus]>

export const LegalStatus: React.FC<LegalStatusProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [showLegalStatusModal, setShowLegalStatusModal] = useState<boolean>(false)

  const [localLoading, setLocalLoading] = useState(false)
  const subscriptionPrepare = useSelector((state: StateTypes) => state.subscription?.prepare)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const userState = useSelector((state: StateTypes) => state.user.data)
  const countryCodeClient: string =
    useSelector((state: StateTypes) => state.client.prepare?.taxDomiciliationCountryCode)
  const legalsForms: LegalFormDto[] = useSelector((state: StateTypes) => state.config.legalsForms)
  const [legalFormSelected, setLegalFormSelected] = useState<LegalFormDto>(undefined)

  const onCreateClientAndSubscription = async (legalForm: LegalFormDto) => {
    setLocalLoading(true)
    try {
      if (!client) {
        await dispatch(ClientActions.create({ type: ClientTypeDto.CORPORATE, legalFormName: legalForm.name }))
      }
      AnalyticsHandler.identify(
        userState ? userState.uuid : null,
        { first_account_created_legal_structure: legalForm.label }
      )
      await agentClientUserManager.removeUser()
      nextStep({
        clientType: ClientTypeDto.CORPORATE,
        offerUuid: subscriptionPrepare?.offerUuid,
        discountCode: subscriptionPrepare?.discountCode,
      }).onDone(() => AnalyticsHandler.track(
        AnalyticsEvents.signUp.companyLegalStructureSelected,
        { legal_structure_type: legalForm?.name, legal_structure_country_code: legalForm?.countryCode }
      )).onError(() => {
        setLocalLoading(false)
      })
    } catch (e) {
      setLocalLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        await dispatch(ConfigActions.listLegalFormsByCountry({ countryCode: countryCodeClient }))
      } catch (e) {}
    })()
  }, [])

  return (
    <TunnelContent
      loading={localLoading}
      header={
        <div className='LegalStatus_header'>
          <Typography2 typeface='h3'>
            {t('neo-commons:subscription:business:legalStatus:title')}
          </Typography2>
          <Stepper2 currentStep={0} />
          <div className='LegalStatus_header_title'>
            <Typography2 typeface='h4'>{t('neo-commons:subscription:business:legalStatus:titleMessage')}</Typography2>
          </div>
        </div>
      }
    >
      <div className='LegalStatus_container'>
        {legalsForms && legalsForms.map((legalForm, index) =>
          <MenuItemCard
            key={index}
            title={legalForm.label}
            onPress={() => {
              setLegalFormSelected(legalForm)
              setShowLegalStatusModal(true)
            }}
          />
        )}
      </div>
      <Modal
        autoFocus
        open={showLegalStatusModal}
        onClose={() => setShowLegalStatusModal(false)}
        displayCross
      >
        <div className='LegalStatus_modal'>
          <div className='LegalStatus_modal_title'>
            <Typography2 typeface='h4'>{t('app:pages:subscription:business:legalStatus:modal:title')}</Typography2>
          </div>
          <div>
            <Typography2 typeface='body1'>
              {t('app:pages:subscription:business:legalStatus:modal:description')}
            </Typography2>
          </div>
          <div className='LegalStatus_modal_containerButton'>
            <Button
              skin={ButtonSkin.OUTLINE_PRIMARY}
              containerStyle={{ marginTop: 16 }}
              title={t('app:pages:subscription:business:legalStatus:modal:btnChangeChoice')}
              onPress={() => {
                setLegalFormSelected(undefined)
                setShowLegalStatusModal(false)
              }}
            />
            <Button
              skin={ButtonSkin.PRIMARY}
              containerStyle={{ marginTop: 8 }}
              title={t('app:pages:subscription:business:legalStatus:modal:btnContinue')}
              onPress={() => {
                onCreateClientAndSubscription(legalFormSelected)
                setShowLegalStatusModal(false)
              }}
            />
          </div>
        </div>
      </Modal>
    </TunnelContent>
  )
}
