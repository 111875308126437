import { AnyAction, Reducer } from 'redux'
import {
  ContactChannelDto,
  ContactDto,
  RecurringTransferCreateDto,
  RecurringTransferDto,
  RecurringTransferSearchDto,
  RecurringTransferSortDto,
  RecurringTransferStatusDto,
  RecurringTransferUpdateDto,
  TransactionDiscriminatorDto,
} from '@afone/neo-core-client/dist/models'
import { find } from 'lodash'
import { ScaCancelled, NeobankApi } from '@neo-commons/services'
import i18next from 'i18next'
import { DateUtils, RecurringTransferValues, TransactionUtils } from '@neo-commons/libraries'
import { createSelector } from 'reselect'

import { Dispatch, ResourceState, ResourceStateFactory } from '../utils/resourceState'
import { initialResourceState, State } from '../utils'

import { BankAccountSelectors } from './bankAccount'
import { ClientSelectors } from './client'

const {
  resourceActionTypes: TransferActionTypes,
  resourceReducer: TransferResourceReducer,
  resourceAction: TransferAction,
  resourceSelector: TransferResourceSelector,
} = ResourceStateFactory<Transfer, 'transfer'>((state: State) => state.transfer, 'transfer')

const transferSelector = state => state.transfer

export enum BankTransferBenificiaryTypeEnum {
  Internal = 'internal',
  Recipient = 'contact',
}

export enum BankTransferTypeEnum {
  Punctual = 'punctual',
  Scheduled = 'scheduled',
  Repeated = 'repeated',
}

// TODO : À revoir avec le back
export enum TransferTypeEnum {
  SCT = 'sepa',
  Internal = 'internal',
  Recurring = 'recurring',
}

/* %%%%%%%%%%%%%%%%%% *\
    Resource Type.
\* %%%%%%%%%%%%%%%%%% */

export type Transfer = Partial<RecurringTransferCreateDto>
  & Partial<RecurringTransferDto> & Partial<RecurringTransferUpdateDto> & {
    transferType?: BankTransferTypeEnum,
    fees?: number,
    recurrence?: RecurringTransferValues,
    recurrenceName: string,
    benificiaryType?: BankTransferBenificiaryTypeEnum,
    isUpdating: boolean,
}

export type OptionTransfer = {
  validationOnly: number;
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Types.
\* %%%%%%%%%%%%%%%%%% */

const LOADING_FEES_REQUEST = 'transfer/LOADING_FEES_REQUEST'
const LOADING_FEES_SUCCESS = 'transfer/LOADING_FEES_SUCCESS'
const LOADING_FEES_FAILURE = 'transfer/LOADING_FEES_FAILURE'

const unknownTechnicalIssue = 'errors:unknownTechnicalIssue'

export const TransferTypes = {
  ...TransferActionTypes,
  LOADING_FEES_REQUEST,
  LOADING_FEES_SUCCESS,
  LOADING_FEES_FAILURE,
}

/* %%%%%%%%%%%%%%%%%% *\
    Actions Creators.
\* %%%%%%%%%%%%%%%%%% */

const sendTransfer = async (transfer: Transfer, options: OptionTransfer, user) => {
  const label = transfer.label ? transfer.label : i18next.t('neo-commons:transfer:default:label',
    { firstName: user.firstName, lastName: user.lastName })
  const baseTransfer = {
    label,
    privateLabel: transfer.privateLabel,
    price: {
      amount: transfer.amount!,
    },
  }

  if (transfer.transferType !== BankTransferTypeEnum.Punctual) {
    const [frequency, period] = TransactionUtils
      .getRecurrence(transfer.recurrence ?? RecurringTransferValues.EVERY_MONTHS)

    const createdTransfer: RecurringTransferCreateDto = {
      ...baseTransfer,
      discriminator: TransactionDiscriminatorDto.RECURRING_TRANSFER_CREATE_DTO,
      contactChannelUuid: transfer.contactChannelUuid!,
      startDate: DateUtils.toDateTime(transfer.startDate!),
      frequency,
      period,
    }

    if (transfer.contactChannelUuid) {
      createdTransfer.contactChannelUuid = transfer.contactChannelUuid!
    } else {
      createdTransfer.recipientAccountUuid = transfer.recipientAccountUuid!
    }

    if (transfer.endDate && transfer.endDate !== '') {
      createdTransfer.endDate = DateUtils.toDateTime(transfer.endDate)
    }

    return NeobankApi.getInstance().transactionApi.createRecurringTransfer(
      transfer.accountUuid!,
      createdTransfer,
      options.validationOnly
    )
  } else {
    if (!transfer.contactChannelUuid) {
      return NeobankApi.getInstance().transactionApi.createInternalTransfer(
        transfer.accountUuid!, {
          ...baseTransfer,
          discriminator: TransactionDiscriminatorDto.TRANSACTION_CREATE_INTERNAL_TRANSFER_DTO,
          recipientAccountUuid: transfer.recipientAccountUuid!,
        }, options.validationOnly)
    } else {
      return NeobankApi.getInstance().transactionApi.createSepaCreditTransfer(
        transfer.accountUuid!, {
          ...baseTransfer,
          discriminator: TransactionDiscriminatorDto.TRANSACTION_CREATE_SEPA_CREDIT_TRANSFER_DTO,
          contactChannelUuid: transfer.contactChannelUuid!,
        }, options.validationOnly)
    }
  }
}

const create = (transfer: Partial<Transfer>) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: TransferTypes.CREATE_TRANSFER_REQUEST })

    transfer = getState().transfer.prepare!

    try {
      if (!transfer) throw new Error()
      if (transfer.contactChannelUuid || transfer.recipientAccountUuid) {
        await sendTransfer(transfer as Transfer, { validationOnly: 0 },
          ClientSelectors.defaultOne(getState()))

        dispatch({ type: TransferTypes.CREATE_TRANSFER_SUCCESS })
      }
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(unknownTechnicalIssue)
      if (error instanceof ScaCancelled) {
        dispatch({ type: TransferTypes.CREATE_TRANSFER_CANCELLED })
      } else {
        dispatch({ type: TransferTypes.CREATE_TRANSFER_FAILURE, errorMessage })
      }
      throw new Error(errorMessage)
    }
  }
}

const getRecurringTransfers = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (!getState().transfer.loading) {
      dispatch({ type: TransferTypes.LIST_TRANSFER_REQUEST })
      try {
        const transfersResponse = await NeobankApi.getInstance().transactionApi.getRecurringTransfers(
          getState().transfer.list.perPage, getState().transfer.list.page!, [RecurringTransferSortDto.NEXT_DEADLINE],
          undefined,
          { status: RecurringTransferStatusDto.ACTIVE } as RecurringTransferSearchDto,
        )

        const data = transfersResponse.status === 204 ? [] : transfersResponse.data

        dispatch({
          type: TransferTypes.LIST_TRANSFER_SUCCESS,
          data,
          paginationEnded:
            data.length < getState().transfer.list.perPage,
          list: {
            shouldRefresh: false,
          },
        })
      } catch (error) {
        const errorMessage = error.message ?? i18next.t(unknownTechnicalIssue)
        dispatch({ type: TransferTypes.LIST_TRANSFER_FAILURE, errorMessage })
      }
    }
  }
}

const getRecurringTransferByUuid = (uuid: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: TransferTypes.BYID_TRANSFER_REQUEST })
    try {
      const bankAccountUuid = getState().bankAccount.list!.selectedId
      if (!bankAccountUuid) throw new Error()

      const transfersResponse = await NeobankApi.getInstance().recurringTransfersApi.getRecurringTransferByUuid(uuid)

      dispatch({ type: TransferTypes.BYID_TRANSFER_SUCCESS, data: transfersResponse.data })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(unknownTechnicalIssue)
      dispatch({ type: TransferTypes.BYID_TRANSFER_FAILURE, errorMessage })
    }
  }
}

const update = (transfer: Partial<Transfer>) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: TransferTypes.UPDATE_TRANSFER_REQUEST })
    try {
      const [frequency, period] = TransactionUtils.getRecurrence(
        transfer.recurrence ?? RecurringTransferValues.EVERY_MONTHS)

      transfer.frequency = frequency
      transfer.period = period

      const payload = await NeobankApi.getInstance().recurringTransfersApi.updateRecurringTransfer(
        transfer?.uuid!, transfer)

      dispatch({ type: TransferTypes.UPDATE_TRANSFER_SUCCESS, data: payload.data })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(unknownTechnicalIssue)
      dispatch({ type: TransferTypes.UPDATE_TRANSFER_FAILURE, errorMessage })
      throw new Error(error)
    }
  }
}

const remove = (transfer: Partial<Transfer>) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: TransferTypes.DELETE_TRANSFER_REQUEST })

    try {
      const payload = await NeobankApi.getInstance().recurringTransfersApi.deleteRecurringTransfer(transfer?.uuid!)

      dispatch({ type: TransferTypes.RESET_PREPARE })
      dispatch({ type: TransferTypes.DELETE_TRANSFER_SUCCESS, data: payload.data })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(unknownTechnicalIssue)
      dispatch({ type: TransferTypes.DELETE_TRANSFER_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const getTransferFees = (transfer: Partial<Transfer>) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: LOADING_FEES_REQUEST })

    try {
      const payload = await sendTransfer(transfer as Transfer, { validationOnly: 1 },
        ClientSelectors.defaultOne(getState()))

      dispatch({ type: LOADING_FEES_SUCCESS, data: payload.data })
    } catch (error) {
      const errorMessage = error.message ?? i18next.t(unknownTechnicalIssue)
      dispatch({ type: LOADING_FEES_FAILURE, errorMessage })
      throw new Error(errorMessage)
    }
  }
}

const setSelected = (selectedId: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (getState().transfer.list!.selectedId === selectedId) return
    dispatch({ type: TransferTypes.SET_SELECTED, selectedId })
  }
}

export const TransferActions = {
  ...TransferAction,
  create,
  getRecurringTransfers,
  update,
  getTransferFees,
  remove,
  getRecurringTransferByUuid,
  setSelected,
}

/* %%%%%%%%%%%%%%%%%% *\
    State.
\* %%%%%%%%%%%%%%%%%% */

export type TransferState = ResourceState<Transfer> & {
  loadingFees: boolean,
}

const initialState: TransferState = {
  ...initialResourceState,
  loadingFees: false,
  isDelete: false,
  prepare: {
    accountUuid: undefined,
    fees: 0,
    isUpdating: false,
  },
}
/* %%%%%%%%%%%%%%%%%% *\
    Selectors.
\* %%%%%%%%%%%%%%%%%% */

const contactSelector = (state: State) => state.contact
const bankAccountSelector = (state: State) => state.bankAccount

export const TransferSelectors = {
  ...TransferResourceSelector,
  issuerLabel: createSelector(
    transferSelector,
    BankAccountSelectors.list,
    (preparedTransfer, bankAccountList) => {
      const relatedBankAccount = find(
        bankAccountList,
        bankAccount => bankAccount.uuid === preparedTransfer.prepare?.accountUuid
      )

      return relatedBankAccount ?? ''
    }),
  getContactChannel: (channelUuid: string) => createSelector(
    transferSelector,
    contactSelector,
    bankAccountSelector,
    (transferState, contactState):
    { contact: ContactDto, channel: ContactChannelDto } | null => {
      if (contactState?.list.selectedId) {
        const contact = contactState.data[contactState.list.selectedId]
        let channel

        if (contact && channelUuid) {
          channel = contact.channels?.filter(channel => channel.uuid === channelUuid)[0]
        }
        return { contact, channel }
      }
      return null
    }),
  getPreparedTransfer: createSelector(
    transferSelector,
    resource => resource.prepare
  ),
  getTransferTypeByUuid: (uuid: string) => createSelector(
    transferSelector,
    resource => {
      return resource.data[uuid]?.endDate
        ? BankTransferTypeEnum.Scheduled
        : (resource.data[uuid]?.period
          ? BankTransferTypeEnum.Repeated
          : BankTransferTypeEnum.Punctual)
    }
  ),
}

/* %%%%%%%%%%%%%%%%%% *\
    Reducer.
\* %%%%%%%%%%%%%%%%%% */

export const transfer: Reducer = (state = initialState, action: AnyAction): TransferState => {
  switch (action.type) {
    case TransferTypes.LOADING_FEES_REQUEST:
      return {
        ...state,
        loadingFees: true,
      }
    case TransferTypes.LOADING_FEES_FAILURE:
      return {
        ...state,
        loadingFees: false,
      }
    case TransferTypes.LOADING_FEES_SUCCESS:
      return {
        ...state,
        loadingFees: false,
        prepare: {
          ...state.prepare,
          price: action.data.price,
        },
      }

    default:
      return {
        ...TransferResourceReducer(state, action, {
          identifier: 'uuid',
          isPaginate: true,
          initialState,
        }),
      }
  }
}
