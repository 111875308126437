import React, { ReactNode } from 'react'

import { Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import './LinkedCard.scss'

interface LinkedCardProps {
  onPress: () => void;
  arrow?: boolean;
  className?: string;
  children?: ReactNode;
}

export const LinkedCard: React.FC<LinkedCardProps> = (props) => {
  const onClickAction = (e) => {
    e.preventDefault()
    props.onPress()
  }

  return (
    <a
      className={`LinkedCard classic-box box-shadow box-border ${props.className ?? ''}`}
      onClick={(e) => onClickAction(e)}
    >
      {props.children}
      {props.arrow && (
        <div className='LinkedCard_arrow'>
          <Icon color={Colors.darkGreyText} name='chevron-right' type={IconTypes.ENTYPO} size={24} />
        </div>
      )}
    </a>
  )
}
