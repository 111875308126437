import { ActionsObservable } from 'redux-observable'
import { AnyAction } from 'redux'

import { OfferTypes } from '@neo-commons/store'

import { displayAlertGlobal$, loading$ } from '../Epics'

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    OfferTypes.GET_OFFER_LIST_FAILURE,
    OfferTypes.GET_OFFER_LIST_SUCCESS,
  ])
}

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    OfferTypes.GET_OFFER_LIST_SUCCESS,
    OfferTypes.GET_OFFER_LIST_REQUEST,
    OfferTypes.GET_OFFER_LIST_FAILURE,
  ])
}

export default [
  displayDropdownAlert$,
  reactiveLoader$,
]
