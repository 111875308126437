import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'Routes/Routes'

import { POSReturnStep, POSReturnSteps, StepComponentProps } from '@neo-commons/policies'
import { Button, ButtonSkin } from '@neo-commons/components'
import { PosSelectors, PreparePOSReturn } from '@neo-commons/store'
import { FailureReason } from '@neo-commons/libraries'

import {
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  Screen5,
  Screen6,
  Screen7,
} from './index'

type POSReturnProps = StepComponentProps<POSReturnSteps[POSReturnStep.AnomalyPedagogy]>

export const AnomalyPedagogy: React.FC<POSReturnProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const preparedPOSReturn: PreparePOSReturn = useSelector(PosSelectors.prepareReturn)

  const btnContinue = () =>
    <Button
      skin={ButtonSkin.PRIMARY}
      title={t('neo-commons:pages:services:pos:return:anomalyPedagogy:buttonContinue')}
      onPress={() => nextStep()}
    />

  const btnContinueOrQuit = () =>
    <>
      <Button
        skin={ButtonSkin.OUTLINE_PRIMARY}
        title={t('neo-commons:pages:services:pos:return:anomalyPedagogy:buttonContinue')}
        onPress={() => nextStep()}
      />
      <Button
        skin={ButtonSkin.PRIMARY}
        title={t('neo-commons:pages:services:pos:return:anomalyPedagogy:buttonCancel')}
        containerStyle={{ marginTop: 8 }}
        onPress={() => history.push(Routes.Services.managePos.path)}
      />
    </>

  const renderPedagogyScreen = () => {
    switch (preparedPOSReturn?.failureReason?.code) {
      case FailureReason.ALERTE_IRRUPTION:
        return <Screen1 buttons={btnContinue()} />
      case FailureReason.POS_OFF:
      case FailureReason.POS_RESTART:
        return <Screen2 buttons={btnContinueOrQuit()} />
      case FailureReason.MUTE_CARD:
      case FailureReason.TORN_CARD:
        return <Screen3 buttons={btnContinueOrQuit()} />
      case FailureReason.ERROR_CONTACTLESS:
        return <Screen4 buttons={btnContinueOrQuit()} />
      case FailureReason.PRINT_ERROR:
        return <Screen5 buttons={btnContinueOrQuit()} />
      case FailureReason.OUT_OF_REACH:
      case FailureReason.TLC_ERROR:
        return <Screen6 buttons={btnContinueOrQuit()} />
      case FailureReason.KEYBOARD_PROBLEM:
        return <Screen7 buttons={btnContinueOrQuit()} />
      default:
        return <></>
    }
  }

  return renderPedagogyScreen()
}
