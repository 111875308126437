import { StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

const styles = StyleSheet.create({
  searchContainer: {
    backgroundColor: Colors.transparent,
    borderWidth: 1,
    borderColor: Colors.gray,
    borderRadius: 16,
    paddingHorizontal: 12,
    paddingVertical: 2,
    flex: 1,
  },
  searchInput: {
    flex: 1,
    maxHeight: 40,
    backgroundColor: Colors.transparent,
    paddingLeft: 2,
    maxWidth: '100%',
  },
  input: {
    ...Typo.input,
    backgroundColor: Colors.transparent,
    paddingLeft: 7,
    marginLeft: 0,
    marginTop: 3,
    borderBottomWidth: 0,
  },
})

export default styles
