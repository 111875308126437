import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { StateTypes } from 'Store'

import { Button, ButtonSkin, Card, CardSkin, Typography, Typography2 } from '@neo-commons/components'
import { Formatters } from '@neo-commons/libraries'
import {
  ClosingAccountPolicy,
  ClosingAccountStep,
  ClosingAccountSteps,
  StepComponentProps,
} from '@neo-commons/policies'
import { BankAccountActions, BankAccountSelectors } from '@neo-commons/store'

import { ClosingAccountLayout } from '../ClosingAccountLayout/ClosingAccountLayout'
import './ConfirmClosingAccount.scss'

type ConfirmClosingAccountProps = StepComponentProps<ClosingAccountSteps[ClosingAccountStep.ConfirmClosingAccount]>

export const ConfirmClosingAccount: React.FC<ConfirmClosingAccountProps> = ({ nextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useSelector((state: StateTypes) => state.user.data)
  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const recipientAccount = useSelector((state: StateTypes) => state.bankAccount.recipientAccountData)
  const bankAccount = useSelector((state: StateTypes) => state.bankAccount)
  const accountClosureReview = useSelector((state: StateTypes) => state.bankAccount.accountClosureReview)

  useEffect(() => {
    dispatch(BankAccountActions.getAccountClosureReview(selectedBankAccount?.uuid))
  }, [])

  const onModifyClick = async () => {
    ClosingAccountPolicy.service.send('MODIFY')
  }

  const onNextClick = async () => {
    try {
      nextStep({
        uuid: selectedBankAccount?.uuid ?? '',
        accountClosureDetails: bankAccount.recipientAccountData,
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <ClosingAccountLayout step={ClosingAccountStep.ConfirmClosingAccount}>
      <div className='ConfirmClosingAccount_container'>
        <div className='ConfirmClosingAccount_card-container'>
          <Card skin={CardSkin.BACKGROUND_BORDERED}>
            <Typography2 typeface='overline' style={{ textAlign: 'center', marginBottom: '1rem' }}>
              {t('app:pages:myProfile:closingAccount:confirmClosingAccount:accountToClose')}
            </Typography2>
            <div className='ConfirmClosingAccount_card-content'>
              <Typography typeface='bold'>
                {`${user?.person?.firstName ?? ''} ${user?.person?.lastName ?? ''}`}
              </Typography>
              <Typography typeface='subtitle'>
                {Formatters.formatIban(selectedBankAccount?.iban ?? '')}
              </Typography>
            </div>
          </Card>
        </div>
        <div className='ConfirmClosingAccount_card-container'>
          <Card skin={CardSkin.BACKGROUND_BORDERED}>
            <Typography2 typeface='overline' style={{ textAlign: 'center', marginBottom: '1rem' }}>
              {t('app:pages:myProfile:closingAccount:confirmClosingAccount:recipientAccount')}
            </Typography2>
            <div className='ConfirmClosingAccount_card-content-line'>
              <div className='ConfirmClosingAccount_card-content'>
                <Typography typeface='bold'>
                  {recipientAccount?.recipientName}
                </Typography>
                <Typography typeface='subtitle'>
                  {Formatters.formatIban(recipientAccount?.recipientIban ?? '')}
                </Typography>
                <Typography typeface='subtitle'>
                  {recipientAccount?.recipientBic}
                </Typography>
              </div>
              <Button
                skin={ButtonSkin.DEFAULT}
                title={t('app:pages:myProfile:closingAccount:confirmClosingAccount:modify')}
                flat
                small
                onPress={onModifyClick}
              />
            </div>
          </Card>
        </div>
        <div className='ConfirmClosingAccount_card-container'>
          <Card skin={CardSkin.BACKGROUND_BORDERED}>
            <Typography2 typeface='overline' style={{ textAlign: 'center', marginBottom: '1rem' }}>
              {t('app:pages:myProfile:closingAccount:confirmClosingAccount:terminatedServices')}
            </Typography2>
            {
              accountClosureReview?.subscriptions?.map((service, index) => (
                <div key={index} className='ConfirmClosingAccount_services'>
                  <Typography2 typeface='buttonMedium'>{service?.name}</Typography2>
                  <Typography2 typeface='body2'>{service?.price}</Typography2>
                </div>
              ))
            }
          </Card>
        </div>
        <div className='ConfirmClosingAccount_amount'>
          <Typography typeface='subtitle'>
            {t('app:pages:myProfile:closingAccount:confirmClosingAccount:amount',
              { amount: bankAccount?.closingBankAccountAmount ?? '' })}
          </Typography>
        </div>
        <div className='ConfirmClosingAccount_button'>
          <Button
            title={t('app:pages:myProfile:closingAccount:confirmClosingAccount:button')}
            skin={ButtonSkin.PRIMARY}
            onPress={onNextClick}
          />
        </div>
      </div>
    </ClosingAccountLayout>
  )
}
