import React, { useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { SignInPolicy } from 'Policies'
import { StateTypes } from 'Store'

import { ConfigStore, SignInState, SignInTypes, DeviceRecoveryTypes } from '@neo-commons/store'

import { LazyLoadingContainer } from '@neo-web/componentsOld'

import { Code } from './Step2/Code'
import { Phone } from './Step1/Phone'

import './Login.scss'

export const Login: React.FC = () => {
  const signInState: SignInState = useSelector((state: StateTypes) => state?.signIn)
  const [deviceUpdateNeeded, setDeviceUpdateNeeded] = useState(true)
  const stepsContent: { path: string, content: React.ReactNode }[] = [
    {
      path: Routes.SignIn.index.path,
      content: <Phone setDeviceUpdateNeeded={(arg: boolean) => setDeviceUpdateNeeded(arg)} />,
    },
    {
      path: Routes.SignIn.code.path,
      content: <Code deviceUpdateNeeded={deviceUpdateNeeded} />,
    },
  ]

  return (
    <div className='Login'>
      <Redirect
        to={{
          pathname: SignInPolicy.signInRedirect(signInState),
        }}
      />
      <LazyLoadingContainer events={[
        SignInTypes.SIGN_IN_REQUEST,
        ConfigStore.GET_CONFIG_REQUEST,
        DeviceRecoveryTypes.UPDATE_DEVICERECOVERY_REQUEST,
      ]}
      >
        {stepsContent.map((value, index) => {
          return (
            <Route key={index} exact path={value.path}>
              {value.content}
            </Route>
          )
        })}
      </LazyLoadingContainer>
    </div>
  )
}
