import React from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'

import { PosDispatchDto } from '@afone/neo-core-client/dist/models'

import { Alert } from '@neo-web/componentsOld'

interface PosReturnAlertProps {
  dispatches: PosDispatchDto[],
}

export const PosReturnAlert: React.FC<PosReturnAlertProps> = (props) => {
  const history = useHistory()
  const { t } = useTranslation()

  return !props.dispatches?.[0] ? <></> : (
    <Alert
      icon={<Icon name='box' color={Colors.secondary} type={IconTypes.FEATHER} size={28} />}
      title={t('neo-commons:pages:services:pos:return:dispatch:banner:title')}
      subTitle={t('neo-commons:pages:services:pos:return:dispatch:banner:subtitle')}
      button={
        <Button
          small
          skin={ButtonSkin.PRIMARY}
          title={t('neo-commons:pages:services:pos:return:dispatch:banner:button')}
          onPress={() => {
            if (props.dispatches.length > 1) {
              history.push(Routes.Services.dispatchList.path)
            } else if (props.dispatches?.[0]?.uuid) {
              history.push(
                generatePath(Routes.Services.dispatchDetail.path, { id: props.dispatches[0].uuid })
              )
            }
          }}
        />
      }
    />
  )
}
