import { RoutesInterface } from '../Routes'

export class SignInRoutes {
  public static index: RoutesInterface = {
    path: '/signIn',
    name: 'app:pages:signIn:title',
    available: true,
  }

  public static code: RoutesInterface = {
    path: SignInRoutes.index.path + '/code',
    name: 'app:pages:signIn:title',
    available: true,
  }
}
