import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { FlexboxGrid } from 'rsuite'

import { CheckOtp } from '@neo-web/componentsOld'

import { StateTypes } from '../../../../Store'
import { ScaActions } from '../../../../Store/Sca'
import './Code.scss'

export const Code: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const scaState = useSelector((state: StateTypes) => state.sca.data)

  const onValidate = async (code: string) => {
    try {
      await dispatch(ScaActions.validateOtp({
        otpUuid: scaState.prepare.otpUuid,
        code: code,
        method: scaState.prepare.scaType,
      }))
    } catch (e) {
      console.log(e)
    }
  }

  const resendCode = async () => {
    try {
      await dispatch(ScaActions.requestDeviceMethod(scaState.prepare.scaType, true))
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <FlexboxGrid className='Code_container'>
      <CheckOtp
        title={t('app:pages:auth:scaDevice:code:title')}
        onValidate={(code) => onValidate(code)}
        showReSendCode
        onResendCode={() => resendCode()}
        endDate={scaState.prepare.endDate}
        startDate={scaState.prepare.date}
      />
    </FlexboxGrid>
  )
}
