import { StyleSheet, TextStyle, ViewStyle, ImageStyle } from 'react-native'

import { Typo, Colors } from '@neo-commons/styles'

interface CustomerServiceCountryCardStyle {
  itemContainer: ViewStyle
  labelContainer: ViewStyle
  title: TextStyle
  subtitle: TextStyle
  iconContainer: ViewStyle
  leftIcon: ImageStyle
  rightIcon: ViewStyle
}

const styles = StyleSheet.create<CustomerServiceCountryCardStyle>({
  itemContainer: {
    borderRadius: 15,
    marginVertical: 0,
    marginHorizontal: 0,
    borderStyle: 'solid',
    borderWidth: 1.5,
    borderColor: Colors.bgGray,
    shadowColor: Colors.black,
    shadowOpacity: 0.1,
    display: 'flex',
    shadowRadius: 3,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    paddingHorizontal: 16,
    paddingVertical: 19,
    backgroundColor: Colors.white,
    flex: 0,
    flexDirection: 'row',
    width: '100%',
  },
  labelContainer: {
    flex: 2,
    marginHorizontal: 16,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  title: {
    ...Typo.selectedTitle,
    fontSize: 14,
    color: Colors.black,
    textAlign: 'left',
    display: 'flex',
  },
  subtitle: {
    color: Colors.darkGreyText,
    ...Typo.itemTitle,
    display: 'flex',
    marginTop: 4,
    fontSize: 11,
  },
  iconContainer: {
    justifyContent: 'center',
  },
  leftIcon: {
    width: 24,
    height: 24,
  },
  rightIcon: {
    color: Colors.primary,
  },
})

export default styles
