import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'

import { Button, PaymentCards, PaymentCardType } from '@neo-commons/components'
import {
  CardActions,
  CardSelectors,
  CreditCardData,
  OrderUpdateType,
  PrepareCardOrder,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { NumbersUtils } from '@neo-commons/libraries'

import { ProductDto } from '@afone/neo-core-client/dist/models'

import { ButtonSkin, PageContent, PageDetails } from '@neo-web/componentsOld'

import { JustifyContent } from '../../../../../Components/Layout/Page/PageContent'

import './Physical.scss'

export const Physical: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectedCard: CreditCardData = useSelector(CardSelectors.selected)
  const preparedCardOrder: PrepareCardOrder = useSelector(CardSelectors.preparedCardOrder)

  const cardProduct = (preparedCardOrder?.offer?.products as (ProductDto & {data: {productType?: string}})[])?.filter(product => product?.data?.productType === 'plasticCard')
  const physicCardPrice = useSelector(SubscriptionSelectors.getProductPriceByCode(cardProduct?.[0]?.productCode))

  return (
    <>
      <PageDetails
        component={
          <PageContent
            title={t('app:pages:services:debitCard:order:physical:subtitle')}
            description={t('app:pages:services:debitCard:order:physical:subsubtitle', {
              price: NumbersUtils.displayPriceForHuman(physicCardPrice, undefined, false),
            })}
            footer={
              <Button
                skin={ButtonSkin.PRIMARY}
                containerStyle={{ display: 'flex', alignItems: 'center' }}
                overrideStyleButton={{ width: '440px' }}
                title={t('app:global:next')}
                onPress={async () => {
                  await dispatch(CardActions.prepare({
                    ...preparedCardOrder,
                    updateType: OrderUpdateType.PIN_CODE,
                  }))
                  history.push(Routes.Services.code.path)
                }}
              />
            }
            footerAtBottom
            fullWidth
            justifyContent={JustifyContent.START}
            style={{ padding: '20px 0' }}
          >
            <div className='Physical'>
              <div className='Physical_content'>
                <div className='Physical_debit-card'>
                  <PaymentCards
                    card={selectedCard}
                    only={PaymentCardType.PHYSICAL}
                  />
                </div>
              </div>
            </div>
          </PageContent>
        }
        onClose={() => history.push(Routes.Services.index.path)}
        onBack={() => history.push(Routes.Services.index.path)}
        title={t('app:pages:services:debitCard:order:physical:title')}
      />
    </>
  )
}
