import { config, ENV } from '../Config/EnvConfig'

export class AppPolicy {
  public static isNotProduction (): boolean {
    return config(ENV.ENV) !== 'production' && config(ENV.ENV) !== 'recette'
  }

  public static isProduction (): boolean {
    return config(ENV.ENV) === 'production'
  }

  public static isDevelopment (): boolean {
    return config(ENV.ENV) === 'development'
  }

  public static authoriseAllRoutes (): boolean {
    return config(ENV.DISABLED_MODAL_COMING_SOON).toString() === 'true'
  }
}
