import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ConfigActions } from '@neo-commons/store'

import { Tunnel, PDFViewer } from '@neo-web/componentsOld'

import { Routes } from '../../../Routes/Routes'
import { StateTypes } from '../../../Store'

export const Manifest: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const configState = useSelector((state: StateTypes) => state.config)
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        await dispatch(
          ConfigActions.getConfig()
        )
        setIsLoad(true)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  return (
    <Tunnel
      scrollable
      title={t('app:global:manifest')}
      onClose={() => history.push(Routes.Profile.about.path)}
      component={isLoad && <PDFViewer file={configState.data?.currentConfig?.manifest} />}
    />
  )
}
