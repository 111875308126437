import { CreditCardsSchemes } from '@neo-commons/components'

import { SchemeDto } from '@afone/neo-core-client/dist/models'

export const getCbSchemeImage = (scheme: string) => {
  let src = ''

  switch (scheme?.toString()) {
    case SchemeDto.CB:
      src = CreditCardsSchemes.Cb
      break
    case SchemeDto.MC:
      src = CreditCardsSchemes.Mastercard
      break
    case SchemeDto.VISA:
      src = CreditCardsSchemes.Visa
      break
  }
  return src
}
