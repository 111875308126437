import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from 'Routes/Routes'
import { StateTypes } from 'Store'

import {
  CardActions,
  CardSelectors,
  OfferActions,
  OfferSelectors,
  OfferTypes,
  OrderUpdateType,
  PrepareCardOrder,
  Subscription,
  SubscriptionSelectors,
} from '@neo-commons/store'
import { Button, ButtonSkin, PaymentCards, PaymentCardType, Typography } from '@neo-commons/components'
import { CreditCardUtils, OfferUtils, SubscriptionUtils } from '@neo-commons/libraries'

import { CardDto, OfferDto, OfferTypeDto } from '@afone/neo-core-client/dist/models'

import {
  DebitCardOffer,
  ItemCarousel,
  LazyLoadingContainer,
  OfferDescriptionItem,
  PageContent,
  PageDetails,
  PromoCodeModal,
} from '@neo-web/componentsOld'

import { OfferCarousel } from '../../../Components/OfferCarousel/OfferCarousel'

export const ChoicesDebitCard: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [didMount, setDidMount] = useState(false)

  const mainSubscription: Subscription = useSelector(SubscriptionSelectors.defaultOne)
  const offers = useSelector(OfferSelectors.listByType(OfferTypeDto.CARD))
  const offerState = useSelector((state: StateTypes) => state.offer)
  const [itemsCarousel, setItemsCarousel] = useState<ItemCarousel[]>([])
  const [promoCode, setPromoCode] = useState(undefined)
  const [showPromoCodeModal, setShowPromoCodeModal] = useState(false)
  const preparedCardOrder = useSelector(CardSelectors.preparedCardOrder)

  const { t } = useTranslation()

  const getDescription = (description): OfferDescriptionItem[] => {
    const array: OfferDescriptionItem[] = []
    if (description) {
      description.map(value => {
        if (CreditCardUtils.getDescriptionObject(value).title) {
          array.push({
            title: CreditCardUtils.getDescriptionObject(value).title,
            description: CreditCardUtils.getDescriptionObject(value).text,
          })
        }
      })
    }
    return array
  }

  const getReasonForNotAuthorized = (description): string => {
    let result = ''
    if (description) {
      description.map(value => {
        if (value) {
          result = CreditCardUtils.getDescriptionObject(value)?.reasonForNotAuthorized ?? ''
        }
      })
    }
    return result
  }

  const fillCarousel = (offers: OfferDto[]) => {
    const carouselItems = []
    offers.map((offer: OfferDto) => {
      const reasonForNotAuthorized = getReasonForNotAuthorized(offer.description)
      // Todo replace this and translate message offer when offer type bo is update
      const isAmbassador = OfferUtils.isAmbassador(offer.offerCode)
      const productImages = OfferUtils.getPaymentCardsImagesFromProducts(offer.products)

      if (productImages) {
        carouselItems.push({
          index: offer.uuid,
          component: (
            <DebitCardOffer
              title={offer.name}
              price={offer.price}
              image={(
                <PaymentCards
                  card={{
                    productImage: productImages?.digital,
                    plasticInfo: { productImage: productImages?.plastic },
                    plastic: productImages?.digital && productImages?.plastic,
                  } as CardDto}
                  only={isAmbassador ? PaymentCardType.BOTH : PaymentCardType.DIGITAL}
                />
              )}
              descriptions={getDescription(offer.description)}
              footer={
                !offer.authorized && reasonForNotAuthorized ? (
                  <Button
                    title={t('app:pages:choicesDebitCard:promoCode:toggleModal')}
                    skin={ButtonSkin.OUTLINE_PRIMARY}
                    onPress={() => { setShowPromoCodeModal(true) }}
                  />
                ) : (
                  <Button
                    title={t(isAmbassador
                      ? 'app:pages:debitCard:choicesDebitCard:selectAmbassadorCard'
                      : (!SubscriptionUtils.isActive(mainSubscription)
                        ? 'app:pages:debitCard:choicesDebitCard:chooseThisCard'
                        : 'app:pages:debitCard:choicesDebitCard:selectDigitalCard')
                    )}
                    skin={ButtonSkin.PRIMARY}
                    onPress={() => {
                      dispatch(CardActions.prepare({
                        ...preparedCardOrder,
                        updateType: OrderUpdateType.NONE,
                        offer: offer,
                        promoCode,
                        withPhysicalCard: true,
                        isBundle: isAmbassador,
                      } as PrepareCardOrder))
                      if (isAmbassador) {
                        history.push(Routes.Services.code.path)
                      } else if (!SubscriptionUtils.isActive(mainSubscription)) {
                        dispatch(CardActions.prepare({
                          ...preparedCardOrder,
                          updateType: OrderUpdateType.DELIVERY_MODE,
                          offer: offer,
                          promoCode,
                          withPhysicalCard: true,
                          isBundle: true,
                        }))
                      } else {
                        history.push(Routes.Services.physicalPromo.path)
                      }
                    }}
                  />)
              }
              authorized={!offer.authorized && reasonForNotAuthorized}
            />
          ),
        })
      }
    })
    setItemsCarousel(carouselItems)
    setDidMount(true)
  }

  const getOffers = () => {
    (async () => {
      try {
        if (!offerState.loading) {
          // If promoCode offers come back later,
          // you should uncomment the reset but take care because it will create a bug on the TPE tab
          // await dispatch(OfferActions.reset())
          await dispatch(OfferActions.listForClientUuid(promoCode))
        }
      } catch (error) {
        try {
          await dispatch(OfferActions.list())
        } catch (_) {}
        setPromoCode('')
      }
    })()
  }

  const promoCodeModal = () => {
    return (
      <PromoCodeModal
        show={showPromoCodeModal}
        title={
          <Typography typeface='bigTitle'>
            {t('app:pages:debitCard:choicesDebitCard:promoModal:title')}
          </Typography>
        }
        inputLabel={t('app:pages:debitCard:choicesDebitCard:promoModal:input')}
        onValidate={(value) => {
          setPromoCode(value)
          setShowPromoCodeModal(false)
        }}
        onClose={() => {
          setShowPromoCodeModal(false)
          setPromoCode('')
        }}
      />
    )
  }

  useEffect(() => {
    setDidMount(true)
    fillCarousel(offers)
    return () => setDidMount(false)
  }, [offerState])

  useEffect(() => {
    getOffers()
  }, [promoCode])

  return (
    <>
      <PageDetails
        component={
          <LazyLoadingContainer events={[OfferTypes.GET_OFFER_LIST_REQUEST]}>
            <PageContent fullWidth>
              {!offerState.loading && didMount && itemsCarousel.length > 0 && (
                <OfferCarousel
                  itemsCarousel={itemsCarousel}
                  promoModal={promoCodeModal()}
                />
              )}
            </PageContent>
          </LazyLoadingContainer>
        }
        onBack={() => history.push(Routes.Services.index.path)}
        onClose={() => history.push(Routes.Services.index.path)}
        title={t('app:pages:debitCard:choicesDebitCard:title')}
      />
    </>
  )
}
