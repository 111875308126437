import React from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, IconTypes, Typography } from '@neo-commons/components'
import { NumbersUtils } from '@neo-commons/libraries'
import { Colors } from '@neo-commons/styles'

import { TransactionTlcSpentDto } from '@afone/neo-core-client/dist/models'

import { LinkedCard } from '@neo-web/componentsOld'

import './TelecollectionCard.scss'

interface TelecollectionCardProps {
  telecollect: TransactionTlcSpentDto;
  onPress: () => void;
  pending?: boolean;
}

export const TelecollectionCard: React.FC<TelecollectionCardProps> = (props) => {
  const { t } = useTranslation()

  let iconName = 'clock'
  let iconColor = Colors.ghostBlack
  let subtitle = t('app:pages:synthesis:telecollections:list:card:status:pending')
  let amountColor = Colors.ghostBlack

  if (!props?.pending) {
    const cleared = props.telecollect?.cleared
    iconName = cleared ? 'check-circle' : 'arrow-right-circle'
    iconColor = cleared ? Colors.primary : Colors.secondary
    subtitle = cleared
      ? t('app:pages:synthesis:telecollections:list:card:status:cleared')
      : t('app:pages:synthesis:telecollections:list:card:status:uncleared')
    amountColor = cleared ? Colors.primary : Colors.black
  }

  return (
    <LinkedCard
      className='TelecollectionCard box-thin'
      onPress={props.onPress}
    >
      <div className='TelecollectionCard_icon'>
        <Icon type={IconTypes.NEOFONT} name={iconName} color={iconColor} size={32} />
      </div>
      <div className='TelecollectionCard_content'>
        <div className='TelecollectionCard_content_title'>
          <Typography typeface='bold'>
            {t('app:pages:synthesis:telecollections:list:card:pos') + ' ' + props.telecollect.posSerialNumber}
          </Typography>
          <Typography typeface='smallText' style={{ color: amountColor }}>
            {NumbersUtils.displaySignedPriceForHuman(props.telecollect.tlcNetAmount ?? 0)}
          </Typography>
        </div>
        <Typography typeface='subtitle'>
          {subtitle}
        </Typography>
      </div>
    </LinkedCard>
  )
}
