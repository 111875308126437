import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { tap, ignoreElements, withLatestFrom } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { AnalyticsHandler } from 'Services/Analytics'
import { StateTypes } from 'Store'

import { NeobankApi } from '@neo-commons/services'
import { ClientTypes, SignInTypes, SignupTypes } from '@neo-commons/store'

import { ClientTypeDto } from '@afone/neo-core-client/dist/models'

import { displayAlertGlobal$, loading$ } from '../Epics'

const reactiveLoader$ = (actions$: ActionsObservable<AnyAction>) => {
  return loading$(actions$, [
    ClientTypes.UPDATE_CLIENT_REQUEST,
    ClientTypes.UPDATE_CLIENT_SUCCESS,
    ClientTypes.UPDATE_CLIENT_FAILURE,
  ])
}

const displayDropdownAlert$ = (actions$: ActionsObservable<AnyAction>) => {
  return displayAlertGlobal$(actions$, [
    ClientTypes.UPDATE_CLIENT_SUCCESS,
    ClientTypes.UPDATE_CLIENT_FAILURE,
    ClientTypes.LIST_CLIENT_FAILURE,
  ])
}

const analyticsIdentifyCall = (state: StateTypes) => {
  const user = state?.user
  const client = state?.client

  AnalyticsHandler.identify(
    user?.data?.uuid,
    {
      user_id: user?.data?.uuid,
      email: user?.data?.email,
      phone: user?.data?.phone?.internationalFormat,
      birthday: user?.data?.person?.birthDate,
      address: user?.data?.person?.addresses.length > 0
        ? {
          street: user?.data?.person?.addresses[0]?.line1,
          city: user?.data?.person?.addresses[0]?.city,
          postalCode: user?.data?.person?.addresses[0]?.postalCode,
          country: user?.data?.person?.addresses[0]?.country,
        } : undefined,
      name: user?.data?.person?.firstName
        ? `${user?.data?.person?.firstName} ${user?.data?.person?.lastName}`
        : `${user?.data?.firstName} ${user?.data?.lastName}`,
      first_name: user?.data?.person?.firstName ?? user?.data?.firstName,
      last_name: user?.data?.person?.lastName ?? user?.data?.lastName,
      created_at: user?.data?.createdDate,
      count_b2b_accounts: Object.values(client?.data)
        .filter(client => client?.accounts.length > 0 && client?.type === ClientTypeDto.CORPORATE).length,
      count_b2c_accounts: Object.values(client?.data)
        .filter(client => client?.accounts.length > 0 && client?.type === ClientTypeDto.INDIVIDUAL).length,
      first_account_created_at: Object.values(client?.data)[0]?.accounts[0]?.createdDate,
      first_account_created_type: Object.values(client?.data)[0]?.type === ClientTypeDto.CORPORATE
        ? 'B2B' : (Object.values(client?.data)[0]?.type === ClientTypeDto.INDIVIDUAL
          ? 'B2C' : undefined),
      first_account_created_iban_country_code: Object.values(client?.data)[0]?.accounts[0]?.iban?.slice(0, 2),
      first_account_created_residence_country_code: user?.data?.person?.taxDomiciliationCountryCode,
      first_account_created_legal_structure: Object.values(client?.data)[0]?.holder?.legalForm?.label,
      highest_b2c_account_plan: null,
      highest_b2B_account_plan: null,
      preferred_language: user?.data?.locale?.slice(0, 2).toUpperCase(),
      account_id: Object.values(client?.data)[0]?.accounts[0]?.uuid,
      agent_client: NeobankApi.getInstance().getToken().data.data?.agentClient,
      user_risk_level: user?.data?.person?.riskLevel,
    }
  )
}

const createOrListClientSuccess$ =
  (actions$: ActionsObservable<AnyAction>, state$: StateObservable<StateTypes>) => actions$.pipe(
    ofType(
      ClientTypes.CREATE_CLIENT_SUCCESS,
      ClientTypes.LIST_CLIENT_SUCCESS
    ),
    withLatestFrom(state$),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tap(([_, state]: [_: any, state: StateTypes]) => {
      analyticsIdentifyCall(state)
    }),
    ignoreElements(),
  )

const SignInOrSignUpClientSuccess$ =
  (actions$: ActionsObservable<AnyAction>, state$: StateObservable<StateTypes>) => actions$.pipe(
    ofType(
      SignInTypes.SIGN_IN_SUCCESS,
      SignupTypes.SIGN_UP_COMPLETED
    ),
    withLatestFrom(state$),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tap(([_, state]: [_: any, state: StateTypes]) => {
      analyticsIdentifyCall(state)
    }),
    ignoreElements(),
  )

export default [
  reactiveLoader$,
  displayDropdownAlert$,
  createOrListClientSuccess$,
  SignInOrSignUpClientSuccess$,
]
