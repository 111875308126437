import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { Button, ButtonSkin, Icon, IconTypes } from '@neo-commons/components'
import { SignInActions, Subscription, SubscriptionSelectors } from '@neo-commons/store'
import { Colors } from '@neo-commons/styles'

import { Tunnel, TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

export const LinkExpired: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const subsciption: Subscription = useSelector(SubscriptionSelectors.defaultOne)

  return (
    <Tunnel
      component={
        <TunnelContent
          justifyContent={TunnelJustifyContent.CENTER}
          icon={<Icon name='info' type={IconTypes.FEATHER} size={40} color={Colors.secondary} />}
          subtitle={t('app:pages:subscription:business:linkExpired:title')}
          description={t(
            'app:pages:subscription:business:linkExpired:description',
            { firstName: subsciption?.subscriber?.firstName, lastName: subsciption?.subscriber?.lastName }
          )}
          footer={
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:global:backToHome')}
              onPress={async () => {
                await dispatch(SignInActions.signOut())
                history.push(Routes.SignIn.index.path)
              }}
            />
          }
        />
      }
      footer
    />
  )
}
