import React from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button, ButtonSkin, Icon, IconTypes, Typography } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { BankAccountSelectors, PosActions, PosTypes, SubscriptionSelectors } from '@neo-commons/store'

import { JustifyContent, LazyLoadingContainer, PageContent, PageDetails } from '@neo-web/componentsOld'

import './ActivatePos.scss'

export const ActivatePos: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedBankAccount = useSelector(BankAccountSelectors.selected)
  const subscription = useSelector(SubscriptionSelectors.byId(selectedBankAccount.subscriptionUuid))

  const activationSteps = [
    {
      title: t('app:pages:services:pos:activate:steps:step1:title'),
      message: t('app:pages:services:pos:activate:steps:step1:message'),
    },
    {
      title: t('app:pages:services:pos:activate:steps:step2:title'),
      message: t('app:pages:services:pos:activate:steps:step2:message'),
    },
    {
      title: t('app:pages:services:pos:activate:steps:step3:title'),
      message: t('app:pages:services:pos:activate:steps:step3:message'),
    },
  ]

  const renderLi = (title, description) => {
    return (
      <li>
        <p>
          <Typography typeface='bold'>
            {title}
          </Typography>
        </p>
        <Typography typeface='content'>
          {description}
        </Typography>
      </li>
    )
  }

  const activatePos = () => {
    (async () => {
      try {
        await dispatch(PosActions.activatePos(subscription?.uuid))
        history.goBack()
      } catch (error) {}
    })()
  }

  return (
    <PageDetails
      title={t('app:pages:services:pos:activate:title')}
      onBack={() => history.goBack()}
      component={
        <LazyLoadingContainer events={[PosTypes.ACTIVATE_POS_REQUEST]}>
          <PageContent
            title={t('app:pages:services:pos:activate:steps:title')}
            justifyContent={JustifyContent.BETWEEN}
            footer={(
              <Button
                skin={ButtonSkin.PRIMARY}
                title={t('app:global:understood')}
                onPress={() => activatePos()}
              />
            )}
          >
            <div className='ActivatePos_prerequisites classic-box box-border'>
              <ul>
                <div className='ActivatePos_prerequisites_icon'>
                  <Icon name='alert-circle' type={IconTypes.FEATHER} color={Colors.secondary} size={25} />
                </div>
                {renderLi(
                  t('app:pages:services:pos:activate:steps:prerequisites:title'),
                  t('app:pages:services:pos:activate:steps:prerequisites:message')
                )}
              </ul>
            </div>
            <div className='classic-box box-border'>
              <ol className='ActivatePos_list'>
                {activationSteps.map(step => {
                  return renderLi(step.title, step.message)
                })}
              </ol>
            </div>
          </PageContent>
        </LazyLoadingContainer>
      }
    />
  )
}
