import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Tunnel, PDFViewer } from '@neo-web/componentsOld'

import { StateTypes } from '../../../Store'

export const PrivacyPolicy = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const configState = useSelector((state: StateTypes) => state.config)

  return (
    <Tunnel
      scrollable
      title={t('app:global:privacyPolicy')}
      onClose={() => history.goBack()}
      component={<PDFViewer file={configState.data?.currentConfig?.privacyPolicy} />}
    />
  )
}
