import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AppConfig } from 'Config/AppConfig'

import { Button, ButtonSkin, Card, CardSkin, Input, Typography } from '@neo-commons/components'
import {
  ExternalCardSelectors,
  PreparedProvisioning,
  ProvisioningActions,
  ProvisioningSelectors,
} from '@neo-commons/store'
import { CreditCardUtils } from '@neo-commons/libraries'

import { CreditCardDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, PageContent } from '@neo-web/componentsOld'

import { getCbSchemeImage } from '../CbSchemeUtils'

import './CvvCheck.scss'

interface CvvCheckProps {
  nextStep: () => void
}

export const CvvCheck: React.FC<CvvCheckProps> = (props: CvvCheckProps) => {
  const { t } = useTranslation()
  const [cvv, setCvv] = useState('')
  const dispatch = useDispatch()
  const prepare: PreparedProvisioning = useSelector(ProvisioningSelectors.prepare)
  const externalCardSelected: CreditCardDto =
    useSelector(ExternalCardSelectors.byId(prepare?.card?.saved?.uuid))

  const onValidate = () => {
    dispatch(ProvisioningActions.prepare({
      ...prepare,
      card: {
        saved: {
          ...prepare.card.saved,
          cvv,
        },
      },
    }))
    props.nextStep()
  }

  return (
    <PageContent
      withBackground
      style={{ textAlign: 'center' }}
      justifyContent={JustifyContent.BETWEEN}
      title={t('app:pages:credit:cvvCheck:title')}
      footer={
        <Button
          skin={ButtonSkin.PRIMARY}
          title={t('app:pages:credit:cvvCheck:validate')}
          disabled={cvv.length !== AppConfig.CVV_LENGTH}
          onPress={() => onValidate()}
        />
      }
    >
      <div className='CvvCheck_card'>
        <Card skin={CardSkin.PRIMARY_DARKER_BORDERED}>
          <div className='CvvCheck_cardInside'>
            <div className='CvvCheck_cbScheme'>
              <img src={getCbSchemeImage(externalCardSelected?.lastUsageScheme)} />
            </div>
            <div className='CvvCheck_texts'>
              <Typography typeface='bold'>
                {CreditCardUtils.formatTruncatedPan(externalCardSelected?.truncatedPan ?? '')}
              </Typography>
              <Typography typeface='subtitle'>
                {externalCardSelected?.cardHolderName}
              </Typography>
              <Typography typeface='subtitle'>
                {externalCardSelected?.expirationDate}
              </Typography>
            </div>
          </div>
        </Card>
      </div>
      <div className='CvvCheck_input'>
        <Input
          label={t('app:pages:credit:cvvCheck:cvv')}
          onChangeText={(text) => setCvv(text)}
          value={cvv}
          keyboardType='numeric'
          maxLength={AppConfig.CVV_LENGTH}
        />
      </div>
    </PageContent>
  )
}
