import { map } from 'lodash'

import { SecretQuestionPolicy } from '@neo-commons/policies'

export const SecretQuestionRoutes = {
  index: {
    path: SecretQuestionPolicy.basePath,
    name: 'app:pages:myProfile:security:secretQuestion:editSecretQuestion',
    available: true,
  },
  ...map(SecretQuestionPolicy.steps, ({ path, meta }) => ({
    path,
    name: meta.screenTitle,
    available: true,
  })),
}
