import { SignInState } from '@neo-commons/store'
import { NeobankApi } from '@neo-commons/services'

import { Routes } from '../../Routes/Routes'
import { AbstractPolicy } from '../AbstractPolicy'

export class SignInPolicy extends AbstractPolicy {
  public static signInRedirect (signIn: SignInState): string {
    let redirect
    switch (true) {
      case SignInPolicy.isAuthenticated(signIn):
        redirect = Routes.Origin.path
        break
      case SignInPolicy.canTypeCode(signIn) && !SignInPolicy.isAuthenticated(signIn):
        redirect = Routes.SignIn.code.path
        break
      default:
        redirect = Routes.SignIn.index.path
    }
    return redirect
  }

  public static canTypeCode (signIn: SignInState): boolean {
    let result = false
    const phone = (signIn?.data?.phone && signIn?.currentDeviceUuid) || this.state.deviceRecovery.isLock
    result = Boolean(phone)

    return result
  }

  public static isAuthenticated (signIn: SignInState): boolean {
    let result = false
    const token = signIn?.data?.token
    result = Boolean(token) && Boolean(NeobankApi.getInstance().getToken())
    return result
  }
}
