export enum AppConfig {
  PASS_CODE_SIZE = 4,
  PIN_CODE_SIZE = 4,
  PROMO_CODE_SIZE = 4,
  MIN_LENGTH_SECRET_QUESTION = 1,
  MAX_LENGTH_SECRET_QUESTION = 50,
  MAX_LENGTH_TRANSFERS_AMOUNT = 8,
  MAX_LENGTH_CREDIT_AMOUNT = 3,
  MIN_CREDIT_AMOUNT = 10,
  MAX_CREDIT_AMOUNT = 250,
  OTP_INPUT_SIZE = 6,
  MAX_PIN_ERROR = 5,
  MAX_KYC_CALL = 15,
  MAX_CHARACTER_SUMMARY_TRANSFER = 30,
  MAX_CHANNEL_FOR_CONTACT = 10,
  DEFAULT_PAGE_NUMBER = 0,
  MAX_OTP_ERROR = 5,
  SEARCH_DEBOUNCE = 1000,
  MIN_CB_SCHEME_NUMBER_FOR_MODAL = 2,
  CVV_LENGTH = 3,
  ALERT_TIMEOUT = 1000,
  LIMIT_GRADUATION_FOR_SLIDER = 100,
  DEFAULT_ACCOUNT_DISPLAY = 2,
  PROJECT_NUMBER_PER_SUBSCRIPTION = 3,
  REMAINING_PROJECT_NUMBER = 2,
}

export enum AppConfigTxt{
  NOELSE='Nøelse',
  MAIL_SERVICE_CLIENT='support@noelse.com',
  INSTAGRAM='https://www.instagram.com/Noelseofficial/',
  FACEBOOK='https://www.facebook.com/Noelseofficial/',
  LINKEDIN='https://www.linkedin.com/company/noelse/',
  ANDROID_STORE_SCA_LINK='https://play.google.com/store/apps/details?id=com.afone.noelse&utm_source=sendinblue&utm_campaign=Interne_Relance_-_Votre_carte_gratuite_maintenant_!_&utm_medium=email',
  APPLE_STORE_SCA_LINK='https://apps.apple.com/fr/app/noelse/id1537543974?utm_source=sendinblue&utm_campaign=Interne_Relance_-_Votre_carte_gratuite_maintenant_!_&utm_medium=email',
  DEFAULT_PROJECT_ICON = 'euro',
}
