import {
  AccountDto,
  AddressDto,
  ClientDto,
  ClientTypeDto,
  PersonAffiliationDto,
} from '@afone/neo-core-client/dist/models'
import { AddressTypeDto } from '@afone/neo-core-client/models/address-type-dto'

export class ClientUtils {
  // /**
  //  * Check if ClientType INDIVIDUAL
  //  *
  //  * @param {AccountDto} accountDto bankAccount type
  //  * @returns {boolean} return true if INDIVIDUAL
  //  */
  public static isClientTypeIndividual (clientType: ClientTypeDto): boolean {
    return Boolean(clientType === ClientTypeDto.INDIVIDUAL)
  }

  // /**
  //  * Check if ClientType CORPORATE
  //  *
  //  * @param {ClientDto} client type
  //  * @returns {boolean} return true if CORPORATE
  //  */
  public static isClientTypeCorporate (clientType: ClientTypeDto): boolean {
    return Boolean(clientType === ClientTypeDto.CORPORATE)
  }

  // /**
  //  * Get client addresses by type
  //  *
  //  * @param {ClientDto} client
  //  * @param {AddressTypeDto} address type
  //  * @returns {Array<AddressDto>} return an Array of AddressDto
  //  */
  public static getClientAddresses (client: ClientDto, type?: AddressTypeDto): AddressDto[] {
    const addresses = type ? client?.holder?.addresses?.filter(a => a.type === type) : client?.holder?.addresses
    return addresses ?? []
  }

  // /**
  //  * Get client from bank account
  //  *
  //  * @param {AccountDto} bankaccount
  //  * @param {Array<ClientDto>} list of clients to search into
  //  * @returns {ClientDto} the client corresponding with the given bankaccount clientUuid
  //  */
  public static getClientFromBankAccount (bankAccount: AccountDto, clients: ClientDto[]): ClientDto | undefined {
    return clients.find((c) => c.uuid === bankAccount.clientUuid)
  }

  // /**
  //  * Get person from personUuid in client
  //  *
  //  * @param {ClientDto} client
  //  * @param {string} personUuid
  //  * @returns {PersonAffiliationDto} the person corresponding with the given personUuid
  //  */
  public static getPersonAffiliateFromClient (client: ClientDto, personUuid: string): PersonAffiliationDto | undefined {
    const affiliate = client.holder?.affiliates &&
      client.holder?.affiliates.find((c) => c?.person?.uuid === personUuid)

    return affiliate && affiliate?.person
  }

  // /**
  //  * Check if is Association
  //  *
  //  * @param {ClientDto} client type
  //  * @returns {boolean} return true if CORPORATE
  //  */
  static isAssociation = (client: ClientDto): boolean => {
    return client?.holder?.legalForm?.name === 'ASSOCIATION'
  }
}
