import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { merge } from 'lodash'

import { i18commons } from '@neo-commons/i18n'

import { resources } from './index'

const resourcesTrad = []

Object.entries(resources).map(value => {
  resourcesTrad[value[0]] = value[1].translate
  return resourcesTrad
})

i18n.use(LanguageDetector).use(initReactI18next).init({

  debug: false,
  resources: merge(i18commons.options.resources, resourcesTrad),
  // use when language navigator not in resources
  fallbackLng: 'fr_FR',
  react: {
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
  },
  load: 'currentOnly',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  detection: {
    // order and from where user language should be detected
    order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' },
  },
}, () => {
  // for match with BO and Lokalise
  i18n.language =
    i18n?.language?.replace('-', '_').slice(0, 3) + i18n?.language?.replace('-', '_').slice(3).toUpperCase()
})

i18n.on('languageChanged', (lng) => {
  const lngs = Object.keys(resources).filter(value =>
    value === lng
  )
  if (lngs.length === 0) {
    i18n.language = i18n.options.fallbackLng[0]
  }
})

export default i18n
