import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import {
  BankAccountActions,
  BankAccountSelectors,
  ClientSelectors,
  OfferSelectors,
  ProvisioningActions,
} from '@neo-commons/store'
import { MenuItemCard, Typography } from '@neo-commons/components'
import { ClientUtils, NumbersUtils } from '@neo-commons/libraries'

import { AccountDto, ClientDto, OfferDto, OfferTypeDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, PageContent } from '@neo-web/componentsOld'

import './CreditChoice.scss'

export const CreditChoice: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [individualAmountFees, setIndividualAmountFees] = useState(undefined)
  const [proPercentageFees, setProPercentageFee] = useState(undefined)

  const bankAccounts: AccountDto[] = useSelector(BankAccountSelectors.listMain)
  const client: ClientDto = useSelector(ClientSelectors.defaultOne)
  const socleOffers: OfferDto[] = useSelector(OfferSelectors.listByClientType(client?.type, OfferTypeDto.SOCLE))

  useEffect(() => {
    if (socleOffers) {
      const products = socleOffers[0]?.products
      const individualPrices = products?.filter(product => product.productCode === 'NEO-PRD-CB-IN')?.[0]?.prices
      const individualFees = individualPrices?.filter(price => price.priceCode === 'NEO-PRI-CB-IN')?.[0]

      const proPrices = products?.filter(product => product.productCode === 'NEO-PRD-CB-IN-PRO')?.[0]?.prices
      const proFees = proPrices?.filter(price => price.priceCode === 'NEO-PRI-CB-IN-PRO')?.[0]

      setIndividualAmountFees(individualFees?.amount)
      setProPercentageFee(((proFees?.percentage ?? 0) * 100))
    }
  }, [socleOffers])

  useEffect(() => {
    (async () => {
      if (!bankAccounts.length) {
        try {
          await dispatch(BankAccountActions.list())
        } catch (error) {}
      }
    })()
  }, [bankAccounts])

  useEffect(() => {
    dispatch(ProvisioningActions.prepare({}))
  }, [])

  useEffect(() => {
    dispatch(ProvisioningActions.cancelTransaction())
  }, [dispatch])

  return (
    <PageContent
      withBackground
      title={t('app:pages:credit:step0:titleMessage')}
      justifyContent={JustifyContent.BETWEEN}
    >
      <div className='CreditChoice_container'>
        <div className='CreditChoice_section'>
          <Typography typeface='subtitle'>
            {t('app:pages:credit:step0:creditAutomaticallyYourAccount')}
          </Typography>
          <div className='CreditChoice_card'>
            <MenuItemCard
              title={t('app:pages:credit:step0:domiciliateMyIncome')}
              subtitle={t('app:pages:credit:step0:free')}
              chevronPosition='center'
              onPress={() => {
                history.push(Routes.Credit.transfer.path, { domiciliation: true })
              }}
            />
          </div>
        </div>
        <div className='CreditChoice_section'>
          <Typography typeface='subtitle'>
            {t('app:pages:credit:step0:creditYourAccountByYourNeeds')}
          </Typography>
          <div className='CreditChoice_card'>
            <MenuItemCard
              title={t('app:global:creditByTransfer')}
              subtitle={t('app:pages:credit:step0:free')}
              chevronPosition='center'
              onPress={() => {
                history.push(Routes.Credit.transfer.path)
              }}
            />
            <MenuItemCard
              title={t('app:global:creditByCard')}
              subtitle={ClientUtils.isClientTypeCorporate(client?.type)
                ? t('app:pages:credit:step0:costPro',
                  {
                    pourcentPro: proPercentageFees,
                  })
                : t('app:pages:credit:step0:cost',
                  {
                    amountPart: NumbersUtils.displayPriceForHuman(individualAmountFees),
                    pourcentPro: proPercentageFees,
                  })}
              chevronPosition='center'
              onPress={() => {
                history.push(Routes.Credit.debitCard.path)
              }}
            />
          </div>
        </div>
      </div>
    </PageContent>
  )
}
