import React, { FunctionComponent } from 'react'
import { Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'

import { Colors } from '@neo-commons/styles'

import { Icon } from '..'
import { IconTypes } from '../Icon/Icon'

import styles from './styles'

/**
 * CheckboxInput Properties
 */
export interface CheckboxInputProps {
  /** Button title */
  text?: string | React.ReactNode,
  /** Boolean status */
  value?: any,
  /** Is CheckboxInput selected ? */
  isSelected?: boolean,
  /** Global container style */
  containerStyle?: ViewStyle,
  /** On Checkbox Input press action */
  onPress: (value: any) => void,
  /** Is radio button disabled ?  */
  disabled?: boolean,
  /** Size of radio default is 20 */
  size?: number,
  /** Label style */
  labelStyle?: TextStyle
  /** accessibilityLabel style */
  accessibilityLabel?: string
}

export const CheckboxInput: FunctionComponent<CheckboxInputProps> = (props) => {
  const { isSelected, onPress, text, disabled, containerStyle, size, labelStyle, accessibilityLabel } = props

  const disabledColor = Colors.darkGray

  let checkboxColor
  switch (true) {
    case isSelected :
      checkboxColor = Colors.primary
      break
    case disabled :
      checkboxColor = Colors.darkGray
      break
    default :
      checkboxColor = Colors.darkGreyText
      break
  }

  const primaryTextColor = !disabled ? Colors.black : disabledColor
  const textButton = [styles.label, labelStyle, { color: primaryTextColor }]

  return (
    <View style={[styles.container, containerStyle]}>
      <TouchableOpacity
        style={[styles.touchableOpacity, containerStyle]}
        disabled={disabled}
        onPress={() => onPress(!isSelected)}
        accessibilityLabel={accessibilityLabel}
      >
        <Icon
          name={isSelected ? 'check-square' : 'square'}
          color={checkboxColor}
          type={IconTypes.FEATHER}
          size={size || 20}
        />
        {typeof text === 'string' &&
          <Text style={textButton}>
            {text}
          </Text>}
      </TouchableOpacity>
      {typeof text !== 'string' &&
        <Text style={[textButton, { marginLeft: 10, marginRight: 10 }]}>
          {text}
        </Text>}
    </View>
  )
}
