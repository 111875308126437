import { StyleSheet } from 'react-native'

import { Colors } from '@neo-commons/styles'

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  offerValue: {
    color: Colors.lightGray,
    textDecorationLine: 'line-through',
  },
  offerText: {
    fontWeight: 'bold',
    paddingHorizontal: 4,
  },
  valueLabel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
  },
  labelStyle: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    paddingLeft: 16,
  },
})
