import React, { useLayoutEffect, useMemo, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Container, Content } from 'rsuite'
import { Payments } from 'UI/Pages/Payments/Payments'
import { Routes } from 'Routes/Routes'

import { useResponsive } from '@neo-web/hooks'
import { PrimaryMenu, PageHeader } from '@neo-web/componentsOld'

import { BankAccount } from '../../Pages/Synthesis/BankAccount/BankAccount'
import { BankAccountsList } from '../../Pages/Synthesis/BankAccountsList'
import { MyProfile } from '../../Pages/MyProfile/MyProfile'
import { Services } from '../../Pages/Services/Services'
import { Synthesis } from '../../Pages/Synthesis/Synthesis'
import { UserProfileCard } from '../../Pages/MyProfile/Components/UserProfileCard'

import './Logged.scss'

export type pageType = {
  path: string
  header?: React.ReactNode
  content: React.ReactNode
  title?: string,
  exact?: boolean
}

export const Logged: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const isTabletOrMobile = useResponsive()
  const { t } = useTranslation()

  useLayoutEffect(() => {
    document.body.classList.add('logged')
  }, [])

  const pages: pageType[] = useMemo(() => {
    return [
      {
        path: Routes.Synthesis.index.path,
        header: <BankAccountsList />,
        content: <Synthesis />,
        title: t('app:menu:synthesis'),
        exact: false,
      },
      {
        path: Routes.Profile.index.path,
        header: <UserProfileCard />,
        content: <MyProfile />,
        title: t('app:menu:myProfile'),
        exact: false,
      },
      {
        path: Routes.Payments.index.path,
        header: <BankAccountsList />,
        content: <Payments />,
        title: t('app:menu:payments'),
        exact: false,
      },
      {
        path: Routes.Services.index.path,
        header: <BankAccountsList />,
        content: <Services />,
        title: t('app:menu:settings'),
        exact: false,
      },
      {
        path: Routes.BankAccount.index.path,
        header: <BankAccountsList />,
        content: <BankAccount />,
        title: t('app:pages:synthesis:aboutAccount'),
        exact: false,
      },
    ]
  }, [])

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      {/* Redirections */}
      <Route
        exact
        path={Routes.Synthesis.index.path}
        component={() => <Redirect to={Routes.Synthesis.transactions.path} />}
      />
      <Route
        exact
        path={Routes.Origin.path}
        component={() => <Redirect to={Routes.Synthesis.transactions.path} />}
      />
      {/* Logged container */}
      <div className={'Logged show-container' + (isTabletOrMobile ? ' tabletOrMobile' : '')}>
        <PageHeader pages={pages} onPressBurger={toggleMenu} isTabletOrMobile={isTabletOrMobile} />

        <Container>
          <Content>
            <PrimaryMenu onClose={toggleMenu} isOpen={isOpen} isTabletOrMobile={isTabletOrMobile} />
            <div className='content'>
              {pages.map((page, index) => {
                return (
                  <Route
                    path={page.path}
                    exact={page.exact ?? true}
                    key={`Logged_${index}`}
                    render={() => page.content && <>{page.content}</>}
                  />
                )
              })}
            </div>
          </Content>
        </Container>
      </div>
    </>
  )
}
