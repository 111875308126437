
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, ButtonSkin } from '@neo-commons/components'
import { CardSelectors, PrepareCardOpposition } from '@neo-commons/store'
import { OppositionStep, OppositionSteps, StepComponentProps } from '@neo-commons/policies'

import { OppositionReasonDto } from '@afone/neo-core-client/dist/models'

import { TunnelContent, TunnelJustifyContent } from '@neo-web/componentsOld'

import './Reason.scss'

type ReasonProps = StepComponentProps<OppositionSteps[OppositionStep.Reason]>

export const Reason: React.FC<ReasonProps> = ({ nextStep }: ReasonProps) => {
  const { t } = useTranslation()
  const preparedCardOpposition: PrepareCardOpposition = useSelector(CardSelectors.preparedCardOpposition)

  const setOppositionReason = (reason: OppositionReasonDto) => {
    // We need to send fake secretQuestions because we bypass CBOppositionSecretQuestions screen
    // TODO: Remove when the secretQuestions will be optionals
    nextStep({
      preparedCardOpposition: {
        ...preparedCardOpposition,
        secretQuestions: [
          {
            uuid: '65fd7597-88bd-11eb-a740-040e3c148dcb',
            question: 'question',
            answer: 'answer',
            correct: true,
          },
          {
            uuid: '65fd7597-88bd-11eb-a740-040e3c148ecb',
            question: 'question',
            answer: 'answer',
            correct: true,
          },
          {
            uuid: '65fd7597-88bd-11eb-a740-040e3c148bcb',
            question: 'question',
            answer: 'answer',
            correct: true,
          },
        ],
        reason,
      },
    })
  }

  return (
    <TunnelContent
      justifyContent={TunnelJustifyContent.START}
      subtitle={t('app:pages:services:debitCard:opposition:reason:title')}
    >
      <div className='Reason_container'>
        <div className='Reason_container_button'>
          <Button
            skin={ButtonSkin.OUTLINE_PRIMARY}
            title={t('app:pages:services:debitCard:opposition:reason:cardLost')}
            onPress={() => {
              setOppositionReason(OppositionReasonDto.CARD_LOST)
            }}
          />
        </div>
        <div className='Reason_container_button'>
          <Button
            skin={ButtonSkin.OUTLINE_PRIMARY}
            title={t(
              'app:pages:services:debitCard:opposition:reason:cardStolen'
            )}
            onPress={() => {
              setOppositionReason(OppositionReasonDto.CARD_STOLEN)
            }}
          />
        </div>
        <div className='Reason_container_button'>
          <Button
            skin={ButtonSkin.OUTLINE_PRIMARY}
            title={t(
              'app:pages:services:debitCard:opposition:reason:cardNumbersStolen'
            )}
            onPress={() => {
              setOppositionReason(OppositionReasonDto.CARD_NUMBERS_STOLEN)
            }}
          />
        </div>
      </div>
    </TunnelContent>
  )
}
