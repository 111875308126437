import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { IconTypes, Icon, Button, ButtonSkin } from '@neo-commons/components'
import { Colors } from '@neo-commons/styles'
import { PosActions } from '@neo-commons/store'

import { PosReturnReasonDto } from '@afone/neo-core-client/dist/models'

import { JustifyContent, PageContent, PageDetails } from '@neo-web/componentsOld'

export const ClosePosAccount: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <PageDetails
      onBack={null}
      component={
        <PageContent
          justifyContent={JustifyContent.CENTER}
          footer={
            <Button
              skin={ButtonSkin.PRIMARY}
              title={t('app:pages:services:pos:closeProject:button')}
              onPress={async () => {
                await dispatch(PosActions.prepareReturn({ returnReason: PosReturnReasonDto.TERMINATION }))
                history.push(Routes.Services.PosReturnRoutes.index.path)
              }}
            />
          }
          icon={<Icon color={Colors.secondary} name='shipping-box' size={40} type={IconTypes.NEOFONT} />}
          title={t('app:pages:services:pos:closeProject:title')}
          description={t('app:pages:services:pos:closeProject:description')}
        />
      }
    />)
}
