import { Platform, StyleSheet } from 'react-native'

import { Colors, Typo } from '@neo-commons/styles'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
    marginTop: 32,
  },
  label: {
    position: 'absolute',
    left: 0,
    top: -15,
    display: 'flex',
    fontSize: 12,
    color: Colors.gray2,
    fontFamily: Typo.type.regular,
    ...Platform.select({
      web: {
        width: 'max-content',
      },
    }),
  },
  value: {
    fontSize: 16,
    color: Colors.trueBlack,
    fontFamily: Typo.type.medium,
  },
  labelError: {
    color: '#F42020',
  },
  textInput: {
    fontSize: 16,
    color: Colors.trueBlack,
    height: 24,
  },
  icon: {
    position: 'absolute',
    right: 16,
    top: '50%',
    marginTop: -13,
  },
})

export default styles
