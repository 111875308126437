import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography, Button, ButtonSkin } from '@neo-commons/components'

import './DisplayCodePin.scss'
import { PlasticStatusDto } from '@afone/neo-core-client/dist/models'

interface DisplayCodePinProps {
  pin: string,
  cardStatus: PlasticStatusDto
  onEditPinClick: () => void
}
export const DisplayCodePin: FunctionComponent<DisplayCodePinProps> = ({ pin, cardStatus, onEditPinClick }) => {
  const { t } = useTranslation()

  const isDisabled = () => cardStatus !== PlasticStatusDto.ACTIVATED

  return (
    <>
      <Typography typeface='bigTitle'>
        {t('app:pages:debitCard:services:cardInfos:hereIsYourPinCode')}
      </Typography>
      <div className='DisplayCodePin_container'>
        <Typography
          typeface='pinText'
        >
          {pin}
        </Typography>
      </div>
      <div className='DisplayCodePin_action'>
        <Button
          onPress={onEditPinClick}
          disabled={isDisabled()}
          overrideStyleButton={{ height: 48 }}
          title={isDisabled() ? t('app:pages:debitCard:services:cardInfos:cantEditPin') : t('app:pages:debitCard:services:cardInfos:changeMyPinCode')}
          skin={ButtonSkin.OUTLINE_PRIMARY}
        />
      </div>
    </>
  )
}
