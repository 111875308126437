import React, { useMemo } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'Routes/Routes'
import { store } from 'Store'

import { ClientActions, SubscriptionActions } from '@neo-commons/store'
import { useWizardPolicy } from '@neo-commons/hooks'
import { IndividualSubscriptionStep, IndividualSubscriptionPolicy } from '@neo-commons/policies'

import { Tunnel } from '@neo-web/componentsOld'

import { SubscriptionSuccess } from './SubscriptionSuccess/SubscriptionSuccess'
import {
  ElectronicSignatureDocumentsChoice,
} from './ElectronicSignatureDocumentsChoice/ElectronicSignatureDocumentsChoice'
import { Offers } from './Offers/Offers'
import { EuropeanTaxResident } from './EuropeanTaxResident/EuropeanTaxResident'
import { TaxResidence } from './TaxResidence/TaxResidence'
import { USPerson } from './USPerson/USPerson'
import { DocumentChoice } from './DocumentChoice/DocumentChoice'
import { AddressScreen } from './Address/Address'
import { NotEuropeanTaxResident } from './NotEuropeanTaxResident/NotEuropeanTaxResident'
import { ElectronicSignature } from './ElectronicSignature/ElectronicSignature'
import { KYCPedagogy } from './KYCPedagogy/KYCPedagogy'
import { KeepInformed } from './KeepInformed/KeepInformed'
import { DocumentsInvalid } from './DocumentsInvalid/DocumentsInvalid'
import { USPersonFallBack } from './USPersonFallback/USPersonFallback'

export const Individual: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentStep, steps: wizardSteps } = useWizardPolicy(store, IndividualSubscriptionPolicy, {
    [IndividualSubscriptionStep.Offers]: Offers,
    [IndividualSubscriptionStep.KYCPedagogy]: KYCPedagogy,
    [IndividualSubscriptionStep.EuropeanTaxResident]: EuropeanTaxResident,
    [IndividualSubscriptionStep.TaxResidence]: TaxResidence,
    [IndividualSubscriptionStep.USPerson]: USPerson,
    [IndividualSubscriptionStep.DocumentsChoice]: DocumentChoice,
    [IndividualSubscriptionStep.Address]: AddressScreen,
    [IndividualSubscriptionStep.ElectronicSignature]: ElectronicSignature,
    [IndividualSubscriptionStep.SubscriptionSuccess]: SubscriptionSuccess,
  }, {
    onDone: async () => {
      history.replace(Routes.Origin.path)
    },
  })

  const wizardStepsPages = useMemo(() => {
    return wizardSteps.map((value, index) => {
      return (
        <Route
          key={index}
          exact
          path={value.path}
          component={value.component}
        />
      )
    })
  }, [])

  const onBack = () => {
    if (currentStep.key === IndividualSubscriptionStep.Offers) {
      dispatch(SubscriptionActions.reset())
      dispatch(ClientActions.reset())
      history.push(Routes.Subscription.subscriptionChoice.path)
    } else {
      history.goBack()
    }
  }

  return (
    <Tunnel
      overrideStyle={{ backgroundColor: '#fff' }}
      onBack={currentStep?.meta?.canGoBack ? onBack : undefined}
      component={
        <>
          {currentStep?.path &&
            <Redirect
              to={{
                pathname: currentStep?.path,
              }}
            />}
          {wizardStepsPages}
          <Route
            path={Routes.Subscription.notEuropeanTaxResident.path}
            component={NotEuropeanTaxResident}
            exact
          />
          <Route
            path={Routes.Subscription.keepInformed.path}
            component={KeepInformed}
            exact
          />
          <Route
            path={Routes.Subscription.usPersonFallback.path}
            component={USPersonFallBack}
            exact
          />
          <Route
            path={Routes.Subscription.documentsInvalid.path}
            component={DocumentsInvalid}
            exact
          />
          <Route
            path={Routes.Subscription.electronicSignatureDocumentsChoice.path}
            component={ElectronicSignatureDocumentsChoice}
            exact
          />
        </>
      }
    />
  )
}
