import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Routes } from 'Routes/Routes'

import { Typography } from '@neo-commons/components'
import { DeviceActions, DeviceSelectors, DeviceTypes } from '@neo-commons/store'

import { DeviceStatusDto } from '@afone/neo-core-client/dist/models'

import { DeviceUtils } from '@neo-web/utils'
import { JustifyContent, LazyLoadingContainer, PageContent, PageDetails } from '@neo-web/componentsOld'

import { DevicesList } from '../../Components/DevicesList/DevicesList'

import './DeviceAndBrowser.scss'

export const DeviceAndBrowser = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const deviceList = useSelector(DeviceSelectors.list)

  useEffect(() => {
    (async () => {
      try {
        if (!deviceList.length) await dispatch(DeviceActions.list())
      } catch (_) {}
    })()
  }, [])

  return (
    <PageDetails
      title={t('app:pages:myProfile:preferenceAndSecurity:devices:title')}
      onBack={() => history.push(Routes.Profile.preference.path)}
      component={
        <LazyLoadingContainer events={[DeviceTypes.LIST_DEVICE_REQUEST]}>
          <PageContent
            fullWidth
            justifyContent={JustifyContent.START}
          >
            <div className='DeviceAndBrowser_title'>
              <Typography typeface='header'>
                {t('app:pages:myProfile:preferenceAndSecurity:deviceAndBrowser:devicesAuthorized')}
              </Typography>
            </div>

            <DevicesList devices={DeviceUtils.groupDevices(deviceList, { status: DeviceStatusDto.ACTIVE })} />

            <div className='DeviceAndBrowser_title'>
              <Typography typeface='header'>
                {t('app:pages:myProfile:preferenceAndSecurity:deviceAndBrowser:devicesBlocked')}
              </Typography>
            </div>

            {DeviceUtils.groupDevices(deviceList, { status: DeviceStatusDto.LOCKED }).length > 0
              ? <DevicesList devices={DeviceUtils.groupDevices(deviceList, { status: DeviceStatusDto.LOCKED })} />
              : <>{t('app:pages:myProfile:preferenceAndSecurity:deviceAndBrowser:emptyDevicesBlocked')}</>}
          </PageContent>
        </LazyLoadingContainer>
      }
    />
  )
}
